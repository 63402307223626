var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.title, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.title))]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "wiki-log-popup__content-container" }, [
        _c(
          "div",
          {},
          [
            _vm._l(_vm.logsToDisplay, function (wikiLog, i) {
              return _c("wiki-action-log-admin", {
                key: i,
                attrs: { "wiki-log": wikiLog },
              })
            }),
            _vm._v(" "),
            !_vm.logsToDisplay.length && !_vm.loading
              ? _c("div", { staticClass: "alert alert-info" }, [
                  _vm._v("\n                No logs yet\n            "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.loading && !_vm.initialized
              ? _c(
                  "div",
                  { staticClass: "row margin-15-top color-tag-gray-dark" },
                  [
                    _c("div", { staticClass: "col-sm-12 text-center" }, [
                      _c("div", [
                        _c("i", {
                          staticClass: "fas fa-spinner fa-spin fa-4x",
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n                        Loading...\n                    "
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("infinite-loading", { on: { infinite: _vm.loadInfinite } }, [
              _c("span", { attrs: { slot: "no-more" }, slot: "no-more" }),
              _vm._v(" "),
              _c("span", { attrs: { slot: "no-results" }, slot: "no-results" }),
            ]),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          { staticClass: "btn btn-mm btn-green", on: { click: _vm.close } },
          [_vm._v("OK")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }