<template>
    <div class="event-members-page">
        <div class="" v-show="admins.count">
            <div class="event-members-page__header">
                Admins
            </div>
            <div class="">
                <vue-stuff-preview
                        :stuff-item="member"
                        v-for="member in admins.list"
                        :key="member.id"
                        stuff-type="user"
                        :deletable="false"
                        @delete="deleteMember(member)"
                        :animation="true"
                        :online-mark="member.online"
                        :size="70"
                ></vue-stuff-preview>
            </div>
            <div class="pointer text-right"
                  v-if="admins.list.length<admins.count"
                  @click="loadMoreMembers('admins')"
            >Show {{admins.count - admins.list.length}} more</div>
        </div>
        <div class="" v-show="going.count">
            <div class="event-members-page__header">
                Going
            </div>
            <div class="">
                <vue-stuff-preview
                        :stuff-item="member"
                        v-for="member in going.list"
                        :key="member.id"
                        stuff-type="user"
                        :deletable="false"
                        @delete="deleteMember(member)"
                        :animation="true"
                        :online-mark="member.online"
                ></vue-stuff-preview>
            </div>
            <div class="pointer text-right"
                  v-if="going.list.length<going.count"
                  @click="loadMoreMembers('going')"
            >Show {{going.count - going.list.length}} more</div>
        </div>

        <div class="" v-show="not_going.count">
            <div class="event-members-page__header">
                Not Going
            </div>
            <div class="">
                <vue-stuff-preview
                        :stuff-item="member"
                        v-for="member in not_going.list"
                        :key="member.id"
                        stuff-type="user"
                        :deletable="member.deletable"
                        @delete="deleteMember(member)"
                        :animation="true"
                        :online-mark="member.online"
                ></vue-stuff-preview>
            </div>
            <div class="pointer text-right"
                  v-if="not_going.list.length<not_going.count"
                  @click="loadMoreMembers('not_going')"
            >Show {{not_going.count - not_going.list.length}} more</div>
        </div>

        <div class="" v-show="maybe_going.count">
            <div class="event-members-page__header">
                Maybe going
            </div>
            <div class="">
                <vue-stuff-preview
                        :stuff-item="member"
                        v-for="member in maybe_going.list"
                        :key="member.id"
                        stuff-type="user"
                        :deletable="member.deletable"
                        @delete="deleteMember(member)"
                        :animation="true"
                        :online-mark="member.online"
                ></vue-stuff-preview>
            </div>
            <div class="pointer text-right"
                  v-if="maybe_going.list.length<maybe_going.count"
                  @click="loadMoreMembers('maybe_going')"
            >Show {{maybe_going.count - maybe_going.list.length}} more</div>
        </div>

        <div class="" v-show="other.count">
            <div class="event-members-page__header">
                Other
            </div>
            <div class="">
                <vue-stuff-preview
                        :stuff-item="member"
                        v-for="member in other.list"
                        :key="member.id"
                        stuff-type="user"
                        :deletable="member.deletable"
                        @delete="deleteMember(member)"
                        :animation="true"
                        :online-mark="member.online"
                ></vue-stuff-preview>
            </div>
            <div v-infinite-scroll="loadMoreMembers"></div>
        </div>
        <event-detail-page-period-selector-popup
                ref="join-type-selector"></event-detail-page-period-selector-popup>

    </div>
</template>

<script>
    import {DjangoUrls} from 'DjangoUrls';
    import Vue from 'vue';
    import EventDetailSubPageMixin from "./EventDetailSubPageMixin";
    import {VISUAL_DATE_FORMAT} from "../new_calendar/config";
    import EventDetailPagePeriodSelectorPopup from "./EventDetailPagePeriodSelectorPopup.vue";
    import {EVENTS_BUS_EVENTS, eventsBus} from "./eventsBus";

    let loadingBlock = false;
    export default {
        name: "EventDetailPageMembers",
        mixins: [EventDetailSubPageMixin],
        components: {EventDetailPagePeriodSelectorPopup},
        data() {
            return {
                admins: {
                    count: 0,
                    list: []
                },
                going: {
                    count: 0,
                    list: []
                },
                maybe_going: {
                    count: 0,
                    list: []
                },
                not_going: {
                    count: 0,
                    list: []
                },
                other: {
                    count: 0,
                    list: []
                },
            };
        },
        computed: {
        },
        methods: {
            loadInitialMembers() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
                $rootScope.show_dimmer();
                loadingBlock = true;
                CalendarEventsResource.get_event_members(this.periodInfo.event_id, this.dateMoment.format(VISUAL_DATE_FORMAT))
                    .then(resp => {
                        this.admins = resp.data.admins;
                        this.going = resp.data.members.going;
                        this.maybe_going = resp.data.members.maybe_going;
                        this.not_going = resp.data.members.not_going;
                        this.other = resp.data.members.other;
                    }, err => {
                        notifications.error(err || 'Error');
                    })
                    .finally(() => {
                        $rootScope.hide_dimmer();
                        loadingBlock = false;
                    });
            },
            loadMoreMembers(group = 'other') {
                if (loadingBlock) {
                    return;
                }
                if (this[group].count <= this[group].list.length) {
                    return;
                }
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
                $rootScope.show_dimmer(false);
                loadingBlock = true;
                CalendarEventsResource.load_more_event_members(
                    this.periodInfo.event_id,
                    this.dateMoment.format(VISUAL_DATE_FORMAT),
                    32,
                    this[group].list.length,
                    group
                )
                    .then(({data}) => {
                        this[group].count = data.count;
                        this[group].list = this[group].list.concat(...data.results);
                    }, err => {
                        notifications.error(err || 'Error');
                    })
                    .finally(() => {
                        $rootScope.hide_dimmer();
                        loadingBlock = false;
                    });
            },
            doRemoveMember(member, only_one_date, date) {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
                $rootScope.show_dimmer();
                CalendarEventsResource.remove_member(this.periodInfo.event_id, member.id, date, only_one_date)
                    .then(resp => {
                        notifications.success('Removed');
                        this.members = this.members.filter(m => m.id !== member.id);
                    }, err => {
                        notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);

            },
            showDeleteConfirmationPopupAndDelete(member, whole = true) {
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                let message;
                if (whole) {
                    if (this.periodInfo.is_periodic) {
                        message = `Are you sure you want to remove <strong>${member.name}</strong> from every occurrence of this Event?`;
                    } else {
                        message = `Are you sure you want to remove <strong>${member.name}</strong> from this event?`;
                    }

                } else {
                    message = `Are you sure you want to remove <strong>${member.name}</strong> from this Event on <strong>${this.dateMoment.format(VISUAL_DATE_FORMAT)}</strong>?`;
                }
                simplePopupFactory.show_popup('Remove Member', message, 'Remove', 'Cancel', 'btn-red')
                    .then(y => {
                        this.doRemoveMember(member, !whole, this.dateMoment.format(VISUAL_DATE_FORMAT));
                    }, n => {

                    });
            },
            deleteMember(member) {
                if (this.periodInfo.is_periodic && member.exact_date_deletion_available) {
                    this.$refs['join-type-selector'].show(this.dateMoment,
                        'Remove Member From Event',
                        `This is a repeated Event.
                            You can remove this Member from every occurrence of this
                            Event or only from this occurrence
                        `)
                        .then(type => { // 'exact' or 'whole'
                            if (type === 'exact') {
                                this.showDeleteConfirmationPopupAndDelete(member, false);
                            }
                            if (type === 'whole') {
                                this.showDeleteConfirmationPopupAndDelete(member);
                            }
                        });
                } else {
                    this.showDeleteConfirmationPopupAndDelete(member);
                }
            }
        },
        mounted() {
            this.loadInitialMembers();
        },
        beforeDestroy() {

        }
    };
</script>

<style scoped lang="scss">
    .event-members-page {
        &__header {
            font-size: 22px;
            font-weight: bolder;
        }
    }
</style>