import {WIKI_PERMISSION_LEVEL, WIKI_PERMISSION_VALUE} from "../../../wiki_page/common/wikiPermissions";

export default {
    props: {
        inheritedPermissionLevel: {
            type: String,
            required: false,
            default: WIKI_PERMISSION_VALUE.UNSET,
        },
        permissionsList: {
            type: Array
        },
    },
    data() {
        return {};
    },
    computed: {
        ownPermissionLevel() {
            const ownPermission = this.permissionsList.filter(p => (p.level === WIKI_PERMISSION_LEVEL.SLIDE && p.target_id === this.slide.id))[0];
            return ownPermission?.value || WIKI_PERMISSION_VALUE.UNSET;
        },
        resultPermissionLevel() {
            if (this.ownPermissionLevel === WIKI_PERMISSION_VALUE.UNSET) {
                return this.inheritedPermissionLevel;
            } else {
                return this.ownPermissionLevel;
            }
        },
    }
};