import { render, staticRenderFns } from "./ChatStyleComment.vue?vue&type=template&id=79d08876&scoped=true&"
import script from "./ChatStyleComment.vue?vue&type=script&lang=js&"
export * from "./ChatStyleComment.vue?vue&type=script&lang=js&"
import style0 from "./ChatStyleComment.vue?vue&type=style&index=0&id=79d08876&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79d08876",
  null
  
)

export default component.exports