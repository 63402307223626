visibilityDetector.$inject = ['$rootScope', '$window'];

function visibilityDetector($rootScope, $window) {

    let tabIsVisible = true;

    $rootScope.isTabVisibleNow = function () {
        return tabIsVisible;
    };

    let hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
        visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
    }

    function handleVisibilityChange() {
        if (document[hidden]) {
            tabIsVisible = false;
            $rootScope.$broadcast('tabVisibilityChanged', tabIsVisible);
        } else {
            tabIsVisible = true;
            $rootScope.$broadcast('tabVisibilityChanged', tabIsVisible);
        }
    }

    if (typeof document.addEventListener === "undefined" || hidden === undefined) {
        console.log("This requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
    } else {
        document.addEventListener(visibilityChange, handleVisibilityChange, false);
        handleVisibilityChange();
    }


}

export default visibilityDetector;