var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "community-page-alternative-header",
        { attrs: { title: "Our Groups", "left-part-size": 10 } },
        [
          _vm.viewerIsAdmin
            ? _c("vue-folder-button", {
                attrs: {
                  "icon-class": "fa-plus",
                  tabindex: "0",
                  "aria-label": "Create Group",
                  role: "button",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.createNewGroup.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.groups, function (group) {
        return _c(
          "a",
          {
            key: group.id,
            staticClass: "hvr-float-shadow",
            attrs: { href: _vm._f("backHref")(group.link) },
          },
          [
            _c("vue-stuff-preview", {
              attrs: {
                "stuff-item": group,
                animation: false,
                deletable: _vm.viewerIsAdmin,
                "stuff-type": "group",
                size: 120,
                "show-additional-mark": group.is_private,
                "additional-mark": "fa-eye-slash",
                "show-additional-mark2": group.is_buddy_group,
                "additional-mark-custom-icon2": "buddy-group",
              },
              on: {
                delete: function ($event) {
                  return _vm.deleteGroup(group)
                },
              },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }