var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "inline-block switch-with-label" },
    [
      _c("vue-switch", {
        ref: "switch",
        staticClass: "vertical-middle switch-with-label__switch",
        class: { "mobile-collapse": _vm.collapseOnMobile },
        attrs: {
          name: _vm.name,
          value: _vm.value,
          disabled: _vm.disabled,
          "aria-label": _vm.label,
        },
        on: { input: _vm.on_input },
      }),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "vertical-middle" },
        [
          _vm._t("label", function () {
            return [_vm._v(_vm._s(_vm.label))]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.helpText && !_vm.isMobile
        ? _c("i", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.hover",
                value: _vm.helpText,
                expression: "helpText",
                modifiers: { hover: true },
              },
            ],
            staticClass:
              "fal fa-question-circle switch-with-label__help-icon pointer vertical-middle",
            attrs: { "show-delay": 400, "hide-delay": 800 },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.helpText && _vm.isMobile
        ? _c("i", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.outside-click",
                value: _vm.helpText,
                expression: "helpText",
                modifiers: { "outside-click": true },
              },
            ],
            staticClass:
              "fal fa-question-circle switch-with-label__help-icon pointer vertical-middle",
            attrs: { "show-delay": 400, "hide-delay": 800 },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }