var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.header)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", [
              _vm.max_count === 1
                ? _c("span", [_vm._v("Youtube link:")])
                : _c("span", [_vm._v("Youtube links:")]),
            ]),
            _vm._v(" "),
            _vm.max_count === 1
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.links,
                      expression: "links",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    required: "",
                    placeholder: "https://www.youtube.com/watch?v=jito_NaFAtA",
                  },
                  domProps: { value: _vm.links },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.links = $event.target.value
                    },
                  },
                })
              : _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.links,
                      expression: "links",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    required: "",
                    rows: "5",
                    id: "submitProviderInfo",
                    placeholder:
                      "https://www.youtube.com/watch?v=jito_NaFAtA\nhttps://www.youtube.com/watch?v=8-hqiTDp4_A",
                  },
                  domProps: { value: _vm.links },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.links = $event.target.value
                    },
                  },
                }),
          ]),
          _vm._v(" "),
          _vm.max_count > 1
            ? _c("span", [
                _vm._v("You can add multiple links (one link per row)."),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "col-sm-5 text-left" },
            [
              _vm.allowSaveToMedia
                ? _c("vue-switch-with-label", {
                    attrs: {
                      "collapse-on-mobile": false,
                      name: "save_to_media",
                      label: "Save to My Media",
                    },
                    model: {
                      value: _vm.saveToMedia,
                      callback: function ($$v) {
                        _vm.saveToMedia = $$v
                      },
                      expression: "saveToMedia",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-7 text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.back },
              },
              [_vm._v("Back")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-green",
                attrs: { type: "button" },
                on: { click: _vm.submit },
              },
              [_vm._v("Add")]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }