<template>
    <div class="chat-style-comment-linked-entry">
        <span class="chat-style-comment-linked-entry__text"
              :class="{'chat-style-comment-linked-entry__text_white': moveAvatarToRight}">
            <strong>
                <simple-link :target="comment.userprofile"></simple-link>
            </strong> has linked an
            <strong v-if="!blogEntry.accessible">Entry</strong>
            <strong v-else><a :href="blogEntry.link">Entry</a></strong> to
            <span
                    v-if="blogEntry.linked_by.id!==blogEntry.data.blog_owner.id"
            >
                <strong>
                    <simple-link :target="blogEntry.data.blog_owner"></simple-link>
                </strong>'s
            </span>
            <span v-else>their</span>
            <span v-if="!blogEntry.accessible">Diary:</span>
            <strong v-else>
                <a :href="blogEntry.link_to_diary">Diary</a>:
            </strong>
            <br/>
        </span>

        <div>
            <br>
            <render-quote :limit-size="true" :quote-border-color="null">
                <div class="" v-if="blogEntry.data.emotion">
                    <entry-emotion :emotion="blogEntry.data.emotion" :size="30"></entry-emotion>
                </div>
                <render-activity-post
                        :activity-post="blogEntry.data.activity_post"></render-activity-post>
            </render-quote>

        </div>
    </div>
</template>

<script>
    import RenderActivityPost from "../../activity/renderActivity/render_activity_post_directive/renderActivityPost";
    import RenderQuote from "shared/quote_directive/renderQuote";
    import EntryEmotion from "../../blogolog/emotion_directive/EntryEmotion";

    export default {
        name: "ChatStyleCommentLinkedBlogEntry",
        components: {EntryEmotion, RenderQuote, RenderActivityPost},
        props: {
            blogEntry: Object,
            comment: Object,
            moveAvatarToRight: {
                type: Boolean,
                default: false
            }
        }
    };
</script>

<style scoped lang="scss">
  .chat-style-comment-linked-entry {
    margin: 15px;
    &__text {

      font-size: 16px;

      &_white {
        color: #fff;
        &::v-deep {
          a {
            color: #fff !important;
          }
        }
      }
    }
  }
</style>