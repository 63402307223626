var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "story-list-item__container",
      class: {
        "story-list-item__container_under-drag": _vm.isCurrentlyUnderDrag,
        "story-list-item__container_edit-mode": _vm.editMode,
      },
      style: _vm.draggingStyle,
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.editMode,
              expression: "editMode",
            },
          ],
          staticClass: "story-list-item__buttons-panel",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "story-list-item__button story-list-item__button_updown",
              attrs: { tabindex: "0" },
              on: {
                click: _vm.moveUp,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.moveUp.apply(null, arguments)
                },
              },
            },
            [_c("i", { staticClass: "fa fa-arrow-up" })]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "story-list-item__button story-list-item__button_drag",
              style: _vm.dragGripStyle,
              on: {
                mousedown: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.dragGripClicked.apply(null, arguments)
                },
                touchstart: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.dragGripTouched.apply(null, arguments)
                },
              },
            },
            [_c("i", { staticClass: "fa fa-arrows-alt" })]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "story-list-item__button story-list-item__button_pencil",
              attrs: { tabindex: "0" },
              on: {
                click: _vm.edit,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.edit.apply(null, arguments)
                },
              },
            },
            [_c("i", { staticClass: "fa fa-pencil" })]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "story-list-item__button story-list-item__button_trash",
              attrs: { tabindex: "0" },
              on: {
                click: _vm.del,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.del.apply(null, arguments)
                },
              },
            },
            [_c("i", { staticClass: "fa fa-trash" })]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "story-list-item__button story-list-item__button_updown",
              attrs: { tabindex: "0" },
              on: {
                click: _vm.moveDown,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.moveDown.apply(null, arguments)
                },
              },
            },
            [_c("i", { staticClass: "fa fa-arrow-down" })]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "story-list-item__content" },
        [
          _c("render-post", {
            attrs: {
              "max-media-height": "60vh",
              post: _vm.item,
              "lazy-load": true,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "story-list-item__bottom-panel" }, [
        _vm.allowLike
          ? _c(
              "span",
              {
                staticClass: "story-list-item__likes-panel",
                class: {
                  "story-list-item__likes-panel_liked": _vm.item.liked_by_me,
                },
              },
              [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.item.likes_count,
                        expression: "item.likes_count",
                      },
                    ],
                    staticClass: "story-list-item__likes-counter",
                    attrs: {
                      tabindex: "0",
                      "aria-label": "Show liked",
                      role: "button",
                    },
                    on: {
                      click: _vm.showWhoLiked,
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.showWhoLiked.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.item.likes_count))]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "story-list-item__likes-icon",
                    attrs: {
                      tabindex: "0",
                      "aria-label": "Like",
                      role: "button",
                    },
                    on: {
                      click: _vm.toggleLike,
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.toggleLike.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fa",
                      class: {
                        "fa-heart": _vm.item.liked_by_me,
                        "fa-heart-o": !_vm.item.liked_by_me,
                      },
                    }),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }