/**
 * Created by orion on 28.05.17.
 */
CollectionsListController.$inject = ['$scope', '$preloaded', '$q', '$log', '$timeout', 'simplePopupFactory', 'notifications', '$rootScope', 'CollectionsResource', 'createNewCollectionFactory'];
let angular = require('angular');
let _ = require('lodash');

function CollectionsListController($scope, $preloaded, $q, $log, $timeout, simplePopupFactory, notifications, $rootScope, CollectionsResource, createNewCollectionFactory) {
    let self = this;

    self.available_modes = [
        {type: 'all', name: "Show All"},
    ];
    self.active_mode = self.available_modes[0];

    self.hover_animation = false;
    self.collections_count = 0;
    const LIMIT = 70;
    self.loading_block = false;
    let loaded_all = false;
    let LAST_QUERY = undefined;

    self.collections = [];

    function init() {
        self.is_owner = $preloaded.is_owner;
        self.dependants_names = $preloaded.dependants_names;
        angular.forEach($preloaded.dependants_names, (name, i) => {
            self.available_modes.push({type: name, name: `${name}'s collections`});
        });
        fetch_collections();
    }

    self.search = function (query) {
        self.hover_animation = false;
        LAST_QUERY = query;
        fetch_collections(query, true);
    };

    self.cancel_search = function () {
        self.hover_animation = false;
        LAST_QUERY = undefined;
        fetch_collections(undefined, true);
    };

    self.create_collection = function () {
        $log.debug('create_collection');
        createNewCollectionFactory.create_collection(self.dependants_names)
            .then(new_collection => {
                $timeout($rootScope.show_dimmer,300);
                notifications.success('Created');
                document.location.href = new_collection.link;
            }, () => new Promise(()=>{}));

    };

    self.change_filter = function () {
        self.hover_animation = false;
        fetch_collections(LAST_QUERY, true);
    };

    self.load_more = function () {
        if (loaded_all) {
            return;
        }
        fetch_collections(LAST_QUERY, false);
    };

    function fetch_collections(query, reset_list = false) {
        if (self.loading_block) {
            return
        }
        self.loading_block = true;
        $timeout(() => {  // coz animation freezing
            if (reset_list) {
                loaded_all = false;
                self.collections = [];
            }
            CollectionsResource.get_my_collections({
                type: self.active_mode.type,
                limit: LIMIT,
                offset: self.collections.length,
                query
            })
                .then(resp => {
                    self.collections = _.concat(self.collections, resp.data.results);
                    self.collections_count = resp.data.count;
                    if (self.collections_count === self.collections.length) {
                        loaded_all = true;
                    }
                }).finally(() => {
                self.loading_block = false;
                $timeout(() => {
                    self.hover_animation = true;
                }, 300);

            });
        }, 50);
    }

    init();


}

export default CollectionsListController;