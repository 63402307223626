<template>
    <div class="vue-youtube-inline center-block embed-responsive in-vue">
        <LazyYoutubeVideo
                :src="videoSrc"
                :autoplay="autoplay"
                class="embed-responsive-item"
                v-if="lazyLoad"
                :webp="false"
                :thumbnailListeners="thumbnailListeners"
                injectPlayerScript
                enablejsapi
                ref="youtubeHolder"
                v-on:load:iframe="onIframeInit"
                v-on:init:player="onPlayerInit"
        />
        <iframe :src='videoSrc'
                v-else
                wmode="opaque"
                class="embed-responsive-item"
                frameborder="0"
                allowfullscreen></iframe>
    </div>
</template>

<script>
    import LazyYoutubeVideo from 'vue-lazy-youtube-video/dist/vue-lazy-youtube-video.min';
    import 'vue-lazy-youtube-video/dist/style.css';

    export default {
        name: "VueInlineMediaYoutube",
        components: {LazyYoutubeVideo},
        props: {
            lazyLoad: {
                type: Boolean,
                default: false
            },
            item: {
                type: Object
            },
            autoplay: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            thumbnailListeners() {
                return {
                    load: (e) => {
                        if (e.target?.naturalWidth) {
                            if (e.target.naturalWidth < 150) {
                                const exSrc = e.target?.src;
                                if (exSrc) {
                                    if (e.target.src.indexOf('maxresdefault') !== -1) {
                                        e.target.src = e.target.src.replace('maxresdefault', 'hqdefault');
                                    }
                                }
                            }
                        }
                    },
                };
            },
            videoSrc() {
                if (!this.item.youtube_video.video_url) {
                    return;
                }
                let video_url = this.item.youtube_video.video_url + "wmode=transparent";
                if (this.autoplay) {
                    video_url += '&autoplay=1';
                }
                return video_url;
            }
        },
        methods: {
            togglePlay() {
                if (this.lazyLoad) {
                    const holder = this.$refs.youtubeHolder;
                    if (holder) {
                        if (holder.activated) {
                            if (this.$_player) {
                                const VIDEO_PLAYING_STATE = 1;
                                if (this.$_player.getPlayerState() === VIDEO_PLAYING_STATE) {
                                    this.$_player.pauseVideo();
                                } else {
                                    this.$_player.playVideo();
                                }
                            }
                        } else {
                            holder.clickHandler();
                        }
                    }
                }

            },
            onIframeInit(youtubeIframe) {
            },
            onPlayerInit(youtubePlayer) {
                this.$_player = youtubePlayer.instance;
            }
        }
    };
</script>

<style scoped lang="scss">
  .vue-youtube-inline ::v-deep {
    .y-video__inner {
      padding-bottom: 0 !important;
    }
  }

</style>