/**
 * Created by orion on 09.05.17.
 */

import angular from 'angular';

// mainFilters
import main_filters from '../shared/filters/filters_module';
import search_input from '../shared/inputs/search_input/search_input_module';
import preload from '../preload/preload';
//shared modules

import simplePopups from '../shared/simple_popups/simple_popup_module';
import notifications from '../shared/notifications/notifications_module';
import infiniteScroll from '../shared/infiniteScroll_directive';
import selectFactory from '../shared/select_factory/selectFactory_module';


//app modules
import add_media from '../add_media/add_media_module';
import maps from '../maps_module/maps_module';
import view_media from '../view_media/view_media_module';


//pages
import NewCalendarController from "./new_calendar/NewCalendarController";
import EventDetailVueController from "./new_event/EventDetailVueController";
//resources
import UserEventsResource from './user_events_recource';
import CalendarEventsResource from "./calendar_events_recource";

//directives

//factories
import CalendarListResource from './calendar_list_resource';
import CommentsInfiniteScrollResourceFactory from "../shared/comments/resource_infinite_scroll_factory";
import CalendarEventGoalsResource from "./calendar_event_goals_recource";
import CalendarEventBlogEntriesResource from "./calendar_event_blog_entries_recource";
import AttachmentsResourceFactory from "../shared/attachments/attachments_resource_factory";
import CalendarEventTaskLinksResource from "./calendar_event_task_links_recource";
import CalendarEventsPublicResource from "./calendar_events_public_resource";


angular.module('mmApp.events',
    ['ui.bootstrap',
        preload, simplePopups, maps, notifications, add_media, view_media, main_filters, search_input, infiniteScroll, selectFactory])
    .controller('EventDetailVueController', EventDetailVueController)
    .controller('NewCalendarController', NewCalendarController)
    .factory('EventCommentsResourceInfiniteScrollResource', CommentsInfiniteScrollResourceFactory('event_comments_infinite_scroll'))
    .factory('UserEventsResource', UserEventsResource)
    .factory('CalendarListResource', CalendarListResource)
    .factory('CalendarEventsPublicResource', CalendarEventsPublicResource)
    .factory('CalendarEventsResource', CalendarEventsResource)
    .factory('CalendarEventGoalsResource', CalendarEventGoalsResource)
    .factory('CalendarEventBlogEntriesResource', CalendarEventBlogEntriesResource)
    .factory('CalendarEventTaskLinksResource', CalendarEventTaskLinksResource)
    .factory('EventAttachmentsResource', AttachmentsResourceFactory('event_attachments'))

;


export default 'mmApp.events';