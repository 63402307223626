import {customTermFilter} from "../../../vue/filters/CustomTerm";

const DASHBOARD_ITEMS = {
    ABOUT: 'about',
    WIKI: 'wiki',
    CIRCLE: 'circle',
    CALENDAR: 'calendar',
    DIARY: 'diary',
    MEDIA: 'media',
    GOALS: 'goals',
};
const DASHBOARD_ITEMS_LIST = [DASHBOARD_ITEMS.ABOUT, DASHBOARD_ITEMS.WIKI, DASHBOARD_ITEMS.CIRCLE, DASHBOARD_ITEMS.CALENDAR, DASHBOARD_ITEMS.DIARY, DASHBOARD_ITEMS.MEDIA, DASHBOARD_ITEMS.GOALS];

const iconsPrefix = '/static/images/new_daschboard_icons/2020/';
const DASHBOARD_ICONS = {};
DASHBOARD_ICONS[DASHBOARD_ITEMS.ABOUT] = `${iconsPrefix}aboutme.png`;
DASHBOARD_ICONS[DASHBOARD_ITEMS.WIKI] = `${iconsPrefix}mywiki-small.png`;
DASHBOARD_ICONS[DASHBOARD_ITEMS.CIRCLE] = `${iconsPrefix}mycircle.png`;
DASHBOARD_ICONS[DASHBOARD_ITEMS.CALENDAR] = `${iconsPrefix}mycalendar_empty.png`;
DASHBOARD_ICONS[DASHBOARD_ITEMS.DIARY] = `${iconsPrefix}mydiary.png`;
DASHBOARD_ICONS[DASHBOARD_ITEMS.MEDIA] = `${iconsPrefix}mymedia.png`;
DASHBOARD_ICONS[DASHBOARD_ITEMS.GOALS] = `${iconsPrefix}mygoals.png`;

const DASHBOARD_ICONS_WHITE = {};
DASHBOARD_ICONS_WHITE[DASHBOARD_ITEMS.ABOUT] = `${iconsPrefix}aboutme-white.png`;
DASHBOARD_ICONS_WHITE[DASHBOARD_ITEMS.WIKI] = `${iconsPrefix}mywiki-small-white.png`;
DASHBOARD_ICONS_WHITE[DASHBOARD_ITEMS.CIRCLE] = `${iconsPrefix}mycircle-white.png`;
DASHBOARD_ICONS_WHITE[DASHBOARD_ITEMS.CALENDAR] = `${iconsPrefix}mycalendar-white.png`;
DASHBOARD_ICONS_WHITE[DASHBOARD_ITEMS.DIARY] = `${iconsPrefix}mydiary-white.png`;
DASHBOARD_ICONS_WHITE[DASHBOARD_ITEMS.MEDIA] = `${iconsPrefix}mymedia-white.png`;
DASHBOARD_ICONS_WHITE[DASHBOARD_ITEMS.GOALS] = `${iconsPrefix}mygoals-white.png`;

const DASHBOARD_ITEMS_NAMES_SINGLE = {};
DASHBOARD_ITEMS_NAMES_SINGLE[DASHBOARD_ITEMS.ABOUT] = `About Me`;
DASHBOARD_ITEMS_NAMES_SINGLE[DASHBOARD_ITEMS.WIKI] = `My Wiki`;
DASHBOARD_ITEMS_NAMES_SINGLE[DASHBOARD_ITEMS.CIRCLE] = `My Circle`;
DASHBOARD_ITEMS_NAMES_SINGLE[DASHBOARD_ITEMS.CALENDAR] = `My Calendar`;
DASHBOARD_ITEMS_NAMES_SINGLE[DASHBOARD_ITEMS.DIARY] = `My Diary`;
DASHBOARD_ITEMS_NAMES_SINGLE[DASHBOARD_ITEMS.MEDIA] = `My Media`;
DASHBOARD_ITEMS_NAMES_SINGLE[DASHBOARD_ITEMS.GOALS] = `My ${customTermFilter('Goals')}`;

const DASHBOARD_ITEMS_NAMES_PLURAL = {};
DASHBOARD_ITEMS_NAMES_PLURAL[DASHBOARD_ITEMS.ABOUT] = `About Me`;
DASHBOARD_ITEMS_NAMES_PLURAL[DASHBOARD_ITEMS.WIKI] = `Wikis`;
DASHBOARD_ITEMS_NAMES_PLURAL[DASHBOARD_ITEMS.CIRCLE] = `Circles`;
DASHBOARD_ITEMS_NAMES_PLURAL[DASHBOARD_ITEMS.CALENDAR] = `Calendars`;
DASHBOARD_ITEMS_NAMES_PLURAL[DASHBOARD_ITEMS.DIARY] = `Diaries`;
DASHBOARD_ITEMS_NAMES_PLURAL[DASHBOARD_ITEMS.MEDIA] = `Media`;
DASHBOARD_ITEMS_NAMES_PLURAL[DASHBOARD_ITEMS.GOALS] = `${customTermFilter('Goals')}`;

const DASHBOARD_ITEMS_COLORS = {};
DASHBOARD_ITEMS_COLORS[DASHBOARD_ITEMS.ABOUT] = `#00d4f1`;
DASHBOARD_ITEMS_COLORS[DASHBOARD_ITEMS.WIKI] = `#00d4f1`;
DASHBOARD_ITEMS_COLORS[DASHBOARD_ITEMS.CIRCLE] = `#ffd200`;
DASHBOARD_ITEMS_COLORS[DASHBOARD_ITEMS.CALENDAR] = `#24c761`;
DASHBOARD_ITEMS_COLORS[DASHBOARD_ITEMS.DIARY] = `#ff1c1a`;
DASHBOARD_ITEMS_COLORS[DASHBOARD_ITEMS.MEDIA] = `#9a4ecf`;
DASHBOARD_ITEMS_COLORS[DASHBOARD_ITEMS.GOALS] = `#3c45bf`;

export {
    DASHBOARD_ITEMS,
    DASHBOARD_ICONS,
    DASHBOARD_ICONS_WHITE,
    DASHBOARD_ITEMS_NAMES_SINGLE,
    DASHBOARD_ITEMS_LIST,
    DASHBOARD_ITEMS_NAMES_PLURAL,
    DASHBOARD_ITEMS_COLORS
};