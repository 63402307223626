var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "vue-simple-header",
        { attrs: { "left-part-size": 8, "right-part-size": 4 } },
        [
          !_vm.buddyMode
            ? _c(
                "span",
                { attrs: { slot: "headerTitle" }, slot: "headerTitle" },
                [_vm._v(_vm._s(_vm.headerLabel))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.buddyMode
            ? _c(
                "span",
                { attrs: { slot: "headerTitle" }, slot: "headerTitle" },
                [
                  _c("simple-link-with-avatar", {
                    attrs: {
                      target: _vm.profile,
                      "postfix-label": "'s Calendar",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "panelRight" }, slot: "panelRight" },
            [
              _c("vue-search-input", {
                staticClass: "vertical-top visible-xs-inline-block",
                on: { search: _vm.onSearch, cancel: _vm.onSearchCancel },
              }),
              _vm._v(" "),
              _c("vue-folder-button", {
                staticClass: "vertical-top",
                attrs: {
                  "no-text-on-mobile": true,
                  text: "Back",
                  "icon-class": "fa-reply",
                  top: -3,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.goBack.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {},
        [
          _c("div", { staticClass: "row margin-10-bottom" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "form-inline" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.mode,
                        expression: "mode",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { name: "", id: "" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.mode = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "all" } }, [
                      _vm._v("All events"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "in_calendar" } }, [
                      _vm._v(_vm._s(_vm.inCalendarLabel)),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "not_in_calendar" } }, [
                      _vm._v(_vm._s(_vm.outOfCalendarLabel)),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "past_events" } }, [
                      _vm._v(_vm._s(_vm.pastEventsLabel)),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-6 text-right" },
              [
                _c("vue-search-input", {
                  staticClass: "vertical-top hidden-xs",
                  on: { search: _vm.onSearch, cancel: _vm.onSearchCancel },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm._l(_vm.events, function (event) {
            return _c("calendar-page-search-event", {
              key: event.id,
              attrs: {
                profile: _vm.profile,
                event: event,
                "is-my-calendar": _vm.isMyCalendar,
              },
            })
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.allLoaded && !_vm.events.length,
                  expression: "allLoaded&&!events.length",
                },
              ],
              staticClass: "text-center",
              staticStyle: { "font-size": "21px" },
            },
            [_vm._v("\n            No results...\n        ")]
          ),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "infinite-scroll",
                rawName: "v-infinite-scroll",
                value: _vm.loadMore,
                expression: "loadMore",
              },
            ],
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }