<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 aria-label="Capture Picture Dialog"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ title }}</h3>
        </div>

        <video ref="webcam" autoplay playsinline style="width: 100%; height: auto"></video>
        <canvas ref="canvas" class="d-none"></canvas>
        <audio ref="snapSound" src="/static/audio/shutter.mp3" preload="auto"></audio>

        <div slot="footer">
            <button class="btn btn-mm  btn-primary"
                    @click="back"
            >Back</button>
            <button class="btn btn-mm btn-green"
                    @click="takePhoto"
                    ref="takePhotoButton"
                    @keydown.tab.stop.prevent="focusToClose"
            >Take Photo</button>
        </div>
    </basic-modal>
</template>

<script>

    import BasicModal from "../../../vue/common_components/BasicModal";
    import PopupPromiseMixin from "../../../vue/mixins/PopupPromiseMixin";

    export default {
        name: "AvatarWebcamComponent",
        components: {BasicModal},
        mixins: [PopupPromiseMixin],
        data() {
            return {
                title: ''
            };
        },
        computed: {
            firstFocusEl() {
                return this.$refs.takePhotoButton;
            }
        },
        methods: {
            setInitial(title = 'Change Avatar') {
                this.title = title;
                this.initialize();
            },
            resetData() {
                this.webcam.stop();
            },
            back() {
                this.close('back');
            },
            initialize() {
                const webcamElement = this.$refs.webcam;
                const canvasElement = this.$refs.canvas;
                const snapSoundElement = this.$refs.snapSound;

                import(/* webpackChunkName: "recorders" */ 'webcam-easy')
                    .then(Webcam => {
                        this.webcam = new Webcam.default(webcamElement, 'user', canvasElement, snapSoundElement);
                        this.webcam.start()
                            .then(result => {
                                this.$nextTick(() => {
                                    setTimeout(() => {
                                        this.$refs.takePhotoButton?.focus();
                                    }, 100);
                                });
                            })
                            .catch(err => {
                                this.reject();
                            });
                    });

            },
            takePhoto() {
                const picture = this.webcam.snap();
                this.save(picture);
            }
        },

    };
</script>

<style scoped>
    .d-none {
        display: none;
    }
</style>