import {DjangoUrls} from 'DjangoUrls';

CalendarEventsPublicResource.$inject = ['$http'];
const PREFIX = "api.v1:calendar_events_public-";

function CalendarEventsPublicResource($http) {
    return {
        get_event,
        get_add_to_google_calendar_link,
        get_add_to_icalendar_file,
    };

    function get_event(eventId, date,) {
        const url = DjangoUrls[PREFIX + "get-event"](eventId);
        return $http({
            method: 'GET',
            url: url,
            params: {
                date
            }
        });
    }

    function get_add_to_google_calendar_link(eventId, date, type) {
        const url = DjangoUrls[PREFIX + "get-add-to-google-calendar-link"](eventId);
        return $http({
            method: 'GET',
            url: url,
            params: {
                date,
                type
            }
        });
    }
    function get_add_to_icalendar_file(eventId, date, type) {
        const url = DjangoUrls[PREFIX + "get-add-to-icalendar-file"](eventId);
        return $http({
            method: 'GET',
            url: url,
            params: {
                date,
                type
            }
        });
    }
}

export default CalendarEventsPublicResource;