<template>
    <div
            :id="'select-item-' + item.id"
            tabindex="0"
            v-tooltip="itemTooltip"
            tooltip-enable="item_for_select.name.length>34"
            class="esp__item-container">
        <div class="esp__list-item-main">
            <div class="esp____online-indicator" v-if="item.online"></div>
            <img v-if="item.super_avatar" :src="item.super_avatar" alt="avatar"
                 class="esp__super-avatar">
            <div class="esp__main-photo">
                <div
                        class="esp__main-photo-preview"
                        :style="mainPhotoPreviewStyle"
                        :class="{checked, unconfirmed}"
                >
                    <div class="esp__completed-mark" v-if="item.completed">
                        <span class="esp__complete-label">Complete</span>
                    </div>

                </div>
                <div :class="{unconfirmed}"
                     v-if="checked"
                     class="esp__main-check-mark"><i class="fa fa-check"
                                                     aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="esp__label">{{ item.name | cutName(34) }}</div>
        <div class="esp__extra-label" v-if="item.extra_text"><i>{{ item.extra_text }}</i></div>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default {
        name: "EndpointSelectorPopupItem",
        props: {
            item: Object,
            checked: Boolean,
            unconfirmed: Boolean,
            noBorderRadiusOnDefaultAvatar: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {};
        },
        computed: {
            itemTooltip() {
                if (this.item.name?.length > 34) {
                    return this.item.name;
                }
            },
            mainPhotoPreviewStyle() {
                const mediaContentUtils = Vue.getAngularModule('mediaContentUtils');
                let content_samples = [];
                if (this.item.members_avatars) {
                    content_samples = this.item.members_avatars;
                }
                const style = mediaContentUtils.generate_group_background(this.item.avatar, content_samples);
                // debugger
                if (this.noBorderRadiusOnDefaultAvatar) {
                    if (!content_samples.length) {
                        if (this.item.avatar?.indexOf('/static/')!==-1) {
                            style['border-radius'] = 0;
                            style['background-size'] = 'contain';
                        }
                    }
                }
                return style;
            },

        }


    };
</script>

<style scoped lang="scss">
  @import "../../../styles/const";

  .esp {
    &__item-container {
      &:focus, &:hover, &:active {
        .esp__main-photo-preview {
          filter: brightness(1.3);
        }

        //box-shadow: 0px 0px 2px 2px rgba(11, 149, 255, 1);
      }

      display: inline-block;
      vertical-align: top;
      display: inline-block;
      margin: 2px 3px;
      cursor: pointer;
      width: 96px;
      height: 160px;
      overflow: hidden;
    }

    &__list-item-main {
      position: relative;
      text-align: center;
    }

    &__online-indicator {
      position: absolute;
      right: 5px;
      bottom: 0;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: inline-block;
      background-color: $online-green;
      border: solid 2px white;
      z-index: 1;
    }

    &__super-avatar {
      position: absolute;
      border-radius: 50%;
      width: 35px;
      z-index: 1;
      border: solid 2px white;
    }

    &__main-photo {
      position: relative;
      display: inline-block;
    }

    &__main-photo-preview {
      width: 96px;
      height: 96px;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      position: relative;

      &.checked {
        border: solid 2px $grass-green;

        &.unconfirmed {
          border: solid 2px $yellow;
        }
      }
    }

    &__completed-mark {
      position: absolute;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      background-color: rgba(101, 189, 100, 0.4);
      color: white;
      line-height: 100%;
    }

    &__complete-label {
      font-weight: bolder;
      position: relative;
      top: 45%;
    }

    &__main-check-mark {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: $grass-green;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      color: white;

      &.unconfirmed {
        background-color: $yellow;
      }
    }

    &__label {
      width: 100%;
      text-align: center;
      overflow: hidden;
      font-size: 13px;
      line-height: 15px;
      margin-top: -3px;
    }

    &__extra-label {
      width: 100%;
      text-align: center;
      overflow: hidden;
      font-size: 11px;
    }

  }

</style>