var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("community-page-alternative-header", { attrs: { title: "Settings" } }),
      _vm._v(" "),
      _c("div", {}, [
        _c("table", { staticClass: "table table-striped" }, [
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v("Community name")]),
              _vm._v(" "),
              _c("th", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.mainSettings.name,
                      expression: "mainSettings.name",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.mainSettings.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.mainSettings, "name", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v("Is Searchable")]),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("vue-switch", {
                    attrs: { name: "is_searchable" },
                    model: {
                      value: _vm.mainSettings.is_searchable,
                      callback: function ($$v) {
                        _vm.$set(_vm.mainSettings, "is_searchable", $$v)
                      },
                      expression: "mainSettings.is_searchable",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.canUseCommunityWiki
              ? _c("tr", [
                  _c(
                    "th",
                    [
                      _vm._v("Use\n                    "),
                      _c(
                        "router-link",
                        {
                          staticClass: "color-blue",
                          attrs: { to: { name: _vm.wikiPageName } },
                        },
                        [_vm._v("Wiki")]
                      ),
                      _vm._v(
                        "\n                    as About Us page\n                "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("vue-switch", {
                        attrs: { name: "uses_community_wiki" },
                        model: {
                          value: _vm.mainSettings.uses_community_wiki,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.mainSettings,
                              "uses_community_wiki",
                              $$v
                            )
                          },
                          expression: "mainSettings.uses_community_wiki",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _vm.canUseCommunityWiki
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.mainSettings.uses_community_wiki,
                        expression: "mainSettings.uses_community_wiki",
                      },
                    ],
                    staticClass: "alert alert-info",
                  },
                  [
                    _c("strong", [_vm._v("Attention!")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v('Using Wiki as "About Us" page '),
                      _c("strong", [_vm._v("does not")]),
                      _vm._v(
                        " make Wiki public accessible automatically. Please make sure you have correctly configured access rights for "
                      ),
                      _c("strong", [_vm._v("Public")]),
                      _vm._v(" on "),
                      _c(
                        "strong",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "color-blue",
                              attrs: {
                                to: {
                                  name: "community-wiki-access-page",
                                  query: { from: "settings" },
                                },
                              },
                            },
                            [_vm._v("Wiki Access")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" page."),
                    ]),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "button",
              { staticClass: "btn btn-mm", on: { click: _vm.changeAvatar } },
              [_vm._v("Change Avatar")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-green",
                attrs: { disabled: !_vm.allowSave },
                on: {
                  click: function ($event) {
                    return _vm.saveMainSettings()
                  },
                },
              },
              [_vm._v("Update\n                ")]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }