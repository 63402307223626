<template>
    <div class="inline-block media-request__container" tabindex="0" role="button" :aria-label="text">
        <div class="media-request__preview-image" :class="{'no-border':noBorder, 'active':highLight}">
            <i class="fa fa-times media-request__preview-image__delete_button" v-if="allowDelete" @click="del"></i>
            <i class="fa fa-pencil media-request__preview-image__edit_button" v-if="allowEdit" @click="edit"></i>
            <i class="fa" :class="iconClass" aria-hidden="true"></i>
        </div>
        <div class="media-request__preview-image__text">{{text}}</div>
    </div>

</template>

<script>
    export default {
        props: {
            text: {
                type: String
            },
            iconClass: {
                type: String,
            },
            noBorder: {
                type: Boolean,
                default: false
            },
            allowDelete: {
                type: Boolean,
                default: false
            },
            allowEdit: {
                type: Boolean,
                default: false
            },
            highLight: {
                type: Boolean,
                default: false
            }
        },
        name: "MediaPreviewAddRequest"
    };
</script>

<style scoped lang="scss">
    @import "../../angular/app/styles/const";

    $media-holder-size: 300px;
    .media-request {
        &__container {
            cursor: pointer;
            text-align: center;
        }

        &__preview-image {
            vertical-align: top;
            width: 100px;
            height: 70px;
            border-radius: 5px;
            background-color: white;
            text-align: center;

            .fa {
                font-size: 45px;
                line-height: 70px;
            }

            transition: box-shadow 0.2s linear;

            @mixin iconOverMedia {
                position: absolute;
                cursor: pointer;
                display: block;
                width: 30px;
                height: 30px;
                text-align: center;
                font-size: 30px;
                right: 0;
            }

            &__delete_button {
                @include iconOverMedia;
                color: $red;
                top: -2px;
            }

            &__edit_button {
                @include iconOverMedia;
                color: $dark-blue;
                bottom: 0;
            }

            &__text {
                font-size: 14px;
                font-weight: bolder;
            }

            &:not(.no-border) {
                box-shadow: rgba(0, 0, 0, 0.498039) 0px 0px 10px;

            }

            border: solid 1px;

            &.active {
                box-shadow: $blue 0px 0px 10px;
                border: solid 1px $blue;
            }
        }


    }


</style>