<template>
    <div class="task-steps">
        <goal-task-steps-step
                :step="step"
                v-for="(step, $index) in steps"
                :task-id="taskId"
                :completable="completable"
                :small="small"
                :index="$index+1"
                :key="step.id"

                :allow-set-assistance-options="allowSetAssistanceOptions"
                :availableCompletionOptions="availableCompletionOptions"

                @taskCompleted="taskCompleted"
                @taskInCompleted="taskInCompleted"
        ></goal-task-steps-step>
    </div>
</template>

<script>
    import GoalTaskStepsStep from './GoalTaskStepsStep.vue';


    export default {
        components: {
            GoalTaskStepsStep
        },
        props: {
            allowSetAssistanceOptions: Boolean,
            availableCompletionOptions: Array,

            steps: Array,
            taskId: Number,
            completable: Boolean,
            small: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            taskCompleted(...rest) {
                this.$emit('taskCompleted', ...rest);
            },
            taskInCompleted(...rest) {
                this.$emit('taskInCompleted', ...rest);
            }
        },

        name: "GoalTaskSteps"
    };
</script>
