import Vue from 'vue';
import WikiResource from '../wiki/wiki_main_resource';
import { simplePopupFactory } from 'shared/simple_popups/simplePopupFactory';
import AccessLinkConsentPopup from './access_link_auth/AccessLinkConsentPopup.vue';

const ACCESS_STATES = {
    NOT_FOUND: 0,
    EXPIRED: 1,
    VALID_PASSWORD_PROTECTED: 2,
    VALID_NOT_PASSWORD_PROTECTED: 3,
};

const ACCESS_LINK_TOKEN_IN_STORAGE_NAME = 'rwalt';

export default {
    data () {
        return {
            accessLinkPasswordRequired: false,
            accessCodeExpired: false,
            accessCodeError: false,
        };
    },
    props: {
        wikiId: {
            type: String
        },
        accessCode: {
            type: String,
            required: false
        }
    },
    async mounted () {
        if (this.accessCode) {
            this.$loadingSpinner.show('al_check');
            const { data: accessCodeState } = await WikiResource().check_access_link_state(this.wikiId, this.accessCode);
            if (accessCodeState === ACCESS_STATES.VALID_PASSWORD_PROTECTED) {
                if (sessionStorage.getItem(ACCESS_LINK_TOKEN_IN_STORAGE_NAME)) {
                    const token = sessionStorage.getItem(ACCESS_LINK_TOKEN_IN_STORAGE_NAME);
                    try {
                        await WikiResource().check_access_link_token(this.wikiId, this.accessCode, token);
                        await this.setAccessParamsAndInitialize(token);
                        this.$loadingSpinner.hide('al_check');
                        return;
                    } catch (e) {
                        sessionStorage.removeItem(ACCESS_LINK_TOKEN_IN_STORAGE_NAME);
                    }
                }
                this.accessLinkPasswordRequired = true;
            } else if (accessCodeState === ACCESS_STATES.VALID_NOT_PASSWORD_PROTECTED) {
                await this.setAccessParamsAndInitialize();
            } else if (accessCodeState === ACCESS_STATES.EXPIRED) {
                this.accessCodeExpired = true;
            } else if (accessCodeState === ACCESS_STATES.NOT_FOUND) {
                this.accessCodeError = true;
            }
            this.$loadingSpinner.hide('al_check');
        } else {
            await this.setAccessParamsAndInitialize();
        }
    },
    beforeDestroy () {
        this.$store.commit('WikiStore/resetLoadedState');
    },
    methods: {
        async tokenObtained (accessToken) {
            const {
                popupComponent,
                vm
            } = simplePopupFactory.mount_vue_popup(AccessLinkConsentPopup);
            const confirmed = await popupComponent.show().catch(() => {
            });
            vm?.$destroy();
            if (confirmed) {
                sessionStorage.setItem(ACCESS_LINK_TOKEN_IN_STORAGE_NAME, accessToken);
                this.accessLinkPasswordRequired = false;
                await this.setAccessParamsAndInitialize(accessToken);
            }
        },
        setAccessParamsAndInitialize (accessToken = undefined) {
            this.$store.commit('WikiStore/setAccessParams', {
                wikiId: this.wikiId,
                accessCode: this.accessCode,
                accessToken,
            });
            this.$store.dispatch('WikiStore/getWikiMembershipState')
              .then(() => {
                  this.onPermissionsLoaded();
              });
        },
        onPermissionsLoaded () {

        }
    }
};
