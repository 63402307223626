/**
 * Created by orion on 6/24/17.
 */

UserProfileSettingsResource.$inject = ['$http'];
import urls from 'DjangoUrls';

let {DjangoUrls} = urls;

function UserProfileSettingsResource($http) {
    return {
        get_user_settings,
        update_first_and_last_name,
        postpone_first_and_last_name_update,
        postpone_fill_profile_story,
        postpone_weak_password_reminder,
        postpone_add_avatar,
        update_email_settings,
        update_main_settings,
        update_profile_visibility,
        change_password,
        close_account,
        cancel_account_deletion,
        resend_close_account_confirmation_email
    };

    ///////////////////

    function get_user_settings(user_id, data) {
        const url = DjangoUrls["api.v1:user_settings-get-user-settings"](user_id);
        return $http({
            method: 'GET',
            url
        });
    }

    function postpone_first_and_last_name_update() {
        const url = DjangoUrls["api.v1:user_settings-postpone-first-and-last-name-update"]();
        return $http({
            method: 'POST',
            url
        });
    }
    function postpone_fill_profile_story() {
        const url = DjangoUrls["api.v1:user_settings-postpone-fill-profile-story"]();
        return $http({
            method: 'POST',
            url
        });
    }

    function postpone_weak_password_reminder(userId) {
        const url = DjangoUrls["api.v1:user_settings-postpone-weak-password-reminder"](userId);
        return $http({
            method: 'POST',
            url
        });
    }

    function postpone_add_avatar() {
        const url = DjangoUrls["api.v1:user_settings-postpone-add-avatar"]();
        return $http({
            method: 'POST',
            url
        });
    }

    function update_first_and_last_name(user_id, data) {
        const url = DjangoUrls["api.v1:user_settings-update-first-and-last-name"](user_id);
        return $http({
            method: 'PATCH',
            url,
            data
        });
    }

    function update_email_settings(user_id, data) {
        const url = DjangoUrls["api.v1:user_settings-update-email-settings"](user_id);
        return $http({
            method: 'PATCH',
            url,
            data
        });
    }

    function update_main_settings(user_id, data) {
        const url = DjangoUrls["api.v1:user_settings-update-main-settings"](user_id);
        return $http({
            method: 'PATCH',
            url,
            data
        });
    }

    function update_profile_visibility(user_id, is_searchable) {
        const url = DjangoUrls["api.v1:user_settings-update-profile-visibility"](user_id);
        return $http({
            method: 'PATCH',
            url,
            data: {is_searchable}
        });
    }

    function change_password(user_id, data) {
        const url = DjangoUrls["api.v1:user_settings-change-password"](user_id);
        return $http({
            method: 'PATCH',
            url,
            data
        });
    }

    function close_account(user_id) {
        const url = DjangoUrls["api.v1:user_settings-close-account"](user_id);
        return $http({
            method: 'PATCH',
            url
        });
    }

    function cancel_account_deletion(user_id) {
        const url = DjangoUrls["api.v1:user_settings-cancel-account-deletion"](user_id);
        return $http({
            method: 'PATCH',
            url
        });
    }

    function resend_close_account_confirmation_email(user_id) {
        const url = DjangoUrls["api.v1:user_settings-resend-close-account-confirmation-email"](user_id);
        return $http({
            method: 'PATCH',
            url
        });
    }

}

export default UserProfileSettingsResource;