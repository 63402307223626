<style lang="scss" scoped>
  @import "../../../src/angular/app/styles/const";

  .header-panel {
    min-height: 50px;
    overflow: hidden;

    &.with-background {
      background-color: #E2E2E2;
      padding: 5px;
    }

    margin-bottom: 10px;

    &__header_title {
      font-size: 24px;
      font-weight: bold;
      vertical-align: middle;

      &.underlined {
        text-decoration: underline;
      }
    }

    &__panel-right {
      @media (max-width: $screen-xs-max) {
        margin-top: 5px;
      }
    }
  }
</style>
<template>
    <div class="header-panel" :class="{'with-background':withBackground}">
        <div class="row">
            <div :class="get_left_part_size" style="padding-left: 22px; padding-right: 0;" slot="panelLeft">
                <slot name="panelLeft">
                    <div class="header-panel__header_title"><slot
                            name="headerTitle"></slot></div>
                    <div>
                     <slot name="headerLabel"></slot>
                    </div>
                </slot>
            </div>
            <div :class="get_right_part_classes" class="header-panel__panel-right text-right no-padding"
                 style="padding-left: 0">
                <slot name="panelRight"></slot>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'VueSimpleHeader',
        data() {
            return {};
        },
        props: {
            withBackground: {
                type: Boolean,
                default: true
            },
            leftPartSize: {
                required: false,
                default: 6,
                type: Number
            },
            rightPartSize: {
                required: false,
                default: 6,
                type: Number
            },
            separationMode: {
                required: false,
                type: String,
                default: 'sm' // sm or xs
            },
            rightPartClasses: {
                type: Array,
                default: () => ([]),
            }
        },
        computed: {
            get_left_part_size() {
                return `col-${this.separationMode}-${this.leftPartSize}`;
            },
            get_right_part_classes() {
                return [`col-${this.separationMode}-${this.rightPartSize}`].concat(this.rightPartClasses);
            }
        }
    };
</script>