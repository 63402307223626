var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-sm-5" }, [
        _c("div", { staticClass: "users-media-logo" }, [
          !_vm.viewedByOtherUser
            ? _c(
                "div",
                {
                  staticClass: "users-media-logo__icon pointer",
                  on: { click: _vm.goToMedia },
                },
                [
                  _c("img", {
                    staticClass: "users-media-logo__icon__image",
                    attrs: {
                      src: "/static/images/new_daschboard_icons/2020/mymedia-white.png",
                      alt: "",
                    },
                  }),
                  _c("span", { staticClass: "align-helper" }),
                ]
              )
            : _c(
                "div",
                {
                  staticClass: "users-media-logo__icon avatar-as-logo pointer",
                  on: { click: _vm.goToMedia },
                },
                [
                  _c("img", {
                    staticClass: "users-media-logo__icon__avatar",
                    attrs: { src: _vm.userAvatarUrl, alt: "" },
                  }),
                  _c("span", { staticClass: "align-helper" }),
                ]
              ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "users-media-logo__label",
              class: { "with-username": _vm.viewedByOtherUser },
              staticStyle: { "vertical-align": "top" },
            },
            [
              !_vm.viewedByOtherUser
                ? _c(
                    "span",
                    { staticClass: "pointer", on: { click: _vm.goToMedia } },
                    [_vm._v("Media")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.viewedByOtherUser
                ? _c(
                    "span",
                    [
                      _c("simple-link", {
                        staticClass: "vertical-middle",
                        attrs: { "cut-name": 15, target: _vm.currentUser },
                      }),
                      _c("span", { staticClass: "vertical-middle" }, [
                        _vm._v("'s Media"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.foldersPath, function (folder, index) {
                return _c(
                  "div",
                  {
                    staticClass: "users-media-logo__label__sub",
                    style: _vm.getSubFolderStyle(folder),
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function ($event) {
                            return _vm.goToFolder(folder)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-folder-open-o",
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(" "),
                        _vm.viewedAsSharedFolder && index === 0
                          ? _c("span", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("cutName")(folder.owner.name, 15)
                                  ) + "'s"
                                ),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("cutName")(folder.name, 33)) +
                                  "\n                        "
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm._f("cutName")(folder.name, 33)) +
                                  "\n                        "
                              ),
                            ]),
                      ]
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-7 folder_buttons_panel text-right" }, [
        _vm.massActionsState.deleteMode
          ? _c(
              "div",
              {
                staticClass:
                  "folder_buttons_panel__alert alert alert-danger inline-block",
                staticStyle: { "margin-bottom": "0", width: "100%" },
              },
              [
                _c("div", {}, [
                  _c("strong", {
                    domProps: { innerHTML: _vm._s(_vm.deleteLabel) },
                  }),
                ]),
                _vm._v(" "),
                _c("div", {}, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-mm-small btn-red",
                      attrs: {
                        disabled:
                          _vm.massActionsState.selectedToDelete.length === 0,
                      },
                      on: { click: _vm.deleteSelected },
                    },
                    [_vm._v("\n                    Delete\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-mm-small btn-lblue",
                      on: { click: _vm.disableEditMode },
                    },
                    [_vm._v("Cancel")]
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.massActionsState.moveMode
          ? _c(
              "div",
              {
                staticClass:
                  "folder_buttons_panel__alert alert alert-info inline-block",
                staticStyle: { "margin-bottom": "0", width: "100%" },
              },
              [
                _c("div", {}, [
                  _c("strong", {
                    domProps: { innerHTML: _vm._s(_vm.moveLabel) },
                  }),
                ]),
                _vm._v(" "),
                _c("div", {}, [
                  _c(
                    "form",
                    {
                      staticClass: "form-inline inline-block",
                      attrs: { action: "" },
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.targetFolderToMove,
                              expression: "targetFolderToMove",
                            },
                          ],
                          staticClass:
                            "form-control folder_buttons_panel__select1",
                          attrs: {
                            disabled: !_vm.foldersAvailableToMove.length,
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.targetFolderToMove = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        _vm._l(_vm.foldersAvailableToMove, function (folder) {
                          return _c(
                            "option",
                            { key: folder.id, domProps: { value: folder } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm._f("cutName")(folder.name, 25)) +
                                  "\n                        "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-mm-small btn-green",
                      attrs: {
                        disabled:
                          _vm.massActionsState.selectedToMove.length === 0 ||
                          !_vm.targetFolderToMove,
                      },
                      on: { click: _vm.moveSelected },
                    },
                    [_vm._v("\n                    Move\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-mm-small btn-lblue",
                      on: { click: _vm.disableMoveMode },
                    },
                    [_vm._v("Cancel")]
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.specModeEnabled,
                expression: "!specModeEnabled",
              },
            ],
            staticClass: "inline-block folder_buttons_panel__buttons",
          },
          [
            _c("vue-search-input", {
              staticStyle: { "vertical-align": "top" },
              attrs: { collapsable: true, initialQuery: _vm.initialQuery },
              on: { search: _vm.onSearch, cancel: _vm.onCancel },
            }),
            _vm._v(" "),
            _vm.isSharableByViewer
              ? _c("vue-folder-button", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.editMode,
                      expression: "!editMode",
                    },
                  ],
                  attrs: {
                    "no-text-on-mobile": true,
                    text: "Share",
                    "icon-class": "fa-share-square",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.shareFolder.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isEditableByViewer
              ? _c("vue-folder-button", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.editMode,
                      expression: "!editMode",
                    },
                  ],
                  attrs: {
                    "no-text-on-mobile": true,
                    text: "Add Media",
                    "icon-class": "fa-plus",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addMedia.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.creationAllowed
              ? _c("vue-folder-button", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.editMode,
                      expression: "!editMode",
                    },
                  ],
                  attrs: {
                    "no-text-on-mobile": true,
                    text: "Create Folder",
                    "icon-class": "fa-folder",
                    top: -2,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.createFolder.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("vue-folder-button", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.allowMakeMeAdmin,
                  expression: "allowMakeMeAdmin",
                },
              ],
              attrs: {
                "no-text-on-mobile": true,
                text: "Make Me Admin",
                "icon-class": "fa-user-secret",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.makeMeAdmin.apply(null, arguments)
                },
              },
            }),
            _vm._v(" "),
            _vm.editMode
              ? _c("vue-folder-button", {
                  attrs: {
                    "no-text-on-mobile": true,
                    color: "blue",
                    text: "Move",
                    "icon-class": "fa-arrows-alt",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.enableMoveMode.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.editMode
              ? _c("vue-folder-button", {
                  attrs: {
                    "no-text-on-mobile": true,
                    color: "red",
                    text: "Delete",
                    "icon-class": "fa-trash-o",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.enableDeleteMode.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.settingsAllowed
              ? _c("vue-folder-button", {
                  attrs: {
                    "no-text-on-mobile": true,
                    text: "Settings",
                    "icon-class": "fa-cogs",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.folderSettingsOpenModal.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "vue-folder-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isEditableByViewer,
                    expression: "isEditableByViewer",
                  },
                ],
                attrs: { "no-text-on-mobile": true, text: _vm.editIconText },
                nativeOn: {
                  click: function ($event) {
                    return _vm.toggleEditMode.apply(null, arguments)
                  },
                },
              },
              [
                _vm.editMode
                  ? _c(
                      "span",
                      {
                        staticClass: "fa-stack",
                        staticStyle: { width: "32px" },
                        attrs: { slot: "icon" },
                        slot: "icon",
                      },
                      [
                        _c("i", {
                          staticClass: "far fa-pencil fa-stack-1x",
                          staticStyle: { position: "absolute" },
                        }),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "far fa-slash fa-stack-1x fa-inverse",
                          staticStyle: { position: "absolute" },
                        }),
                      ]
                    )
                  : _c(
                      "span",
                      {
                        staticClass: "fa-stack",
                        staticStyle: { width: "32px" },
                        attrs: { slot: "icon" },
                        slot: "icon",
                      },
                      [
                        _c("i", {
                          staticClass: "far fa-pencil fa-stack-1x",
                          staticStyle: { position: "absolute" },
                        }),
                      ]
                    ),
                _vm._v(" "),
                _vm.editMode
                  ? _c("span", { attrs: { slot: "text" }, slot: "text" }, [
                      _vm._v("\n                Finish"),
                      _c("br"),
                      _vm._v("Editing\n            "),
                    ])
                  : _c("span", { attrs: { slot: "text" }, slot: "text" }, [
                      _vm._v("\n                Edit\n            "),
                    ]),
              ]
            ),
            _vm._v(" "),
            _vm.manageAccessAvailable
              ? _c("vue-folder-button", {
                  attrs: {
                    "no-text-on-mobile": true,
                    color: "brown",
                    text: "Manage Access",
                    "icon-class": "fa-key",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.manageAccess.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.backAvailable
              ? _c("vue-folder-button", {
                  attrs: {
                    "no-text-on-mobile": true,
                    text: "Back",
                    "icon-class": "fa-reply",
                    color: "blue",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.goBack.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: { title: "Folder Settings", transition: 0 },
          model: {
            value: _vm.modalFolderSettingsOpened,
            callback: function ($$v) {
              _vm.modalFolderSettingsOpened = $$v
            },
            expression: "modalFolderSettingsOpened",
          },
        },
        [
          _c("div", { staticClass: "div" }, [
            _c("div", { staticClass: "form" }, [
              _c("label", { attrs: { for: "renameFolder" } }, [
                _vm._v("Folder name:"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newFolderName,
                    expression: "newFolderName",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", maxlength: "127", id: "renameFolder" },
                domProps: { value: _vm.newFolderName },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    _vm.newFolderName.length > 1 &&
                    _vm.newFolderName !== _vm.activeFolder.name
                      ? _vm.folderSettingsSave()
                      : _vm.noop()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.newFolderName = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-red",
                on: { click: _vm.folderSettingsDelete },
              },
              [_vm._v("Delete this Folder\n            ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-mm",
                on: { click: _vm.folderSettingsCancel },
              },
              [_vm._v("Cancel\n            ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-green",
                attrs: {
                  disabled:
                    _vm.newFolderName.length < 2 ||
                    (_vm.activeFolder &&
                      _vm.newFolderName === _vm.activeFolder.name),
                },
                on: { click: _vm.folderSettingsSave },
              },
              [_vm._v("Save\n            ")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }