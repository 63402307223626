var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wiki-tree-slide-attachment" }, [
    _c("i", {
      staticClass: "fa fa-eye",
      class: _vm.viewButtonClasses,
      on: { click: _vm.toggleLinkAttachmentLevelPermission },
    }),
    _vm._v(" "),
    _c("i", {
      staticClass: "fa fa-link vertical-middle",
      attrs: { "aria-hidden": "true" },
    }),
    _vm._v(" "),
    _c("a", { attrs: { href: _vm.link.url, target: "_blank" } }, [
      _c("span", { staticClass: "vertical-middle" }, [
        _vm._v(_vm._s(_vm._f("cutName")(_vm.link.name, 22))),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }