var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.header)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", {
          staticClass: "col-sm-12",
          domProps: { innerHTML: _vm._s(_vm.message) },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _vm.element === _vm.POSSIBLE_ELEMTNTS.INPUT
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputText,
                    expression: "inputText",
                  },
                ],
                ref: "textInput",
                staticClass: "form-control",
                attrs: { maxlength: _vm.maxLength, type: "text" },
                domProps: { value: _vm.inputText },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.ok.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.inputText = $event.target.value
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.element === _vm.POSSIBLE_ELEMTNTS.TEXTAREA
            ? _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputText,
                    expression: "inputText",
                  },
                ],
                ref: "textInput",
                staticClass: "form-control",
                attrs: { rows: "8", maxlength: _vm.maxLength, type: "text" },
                domProps: { value: _vm.inputText },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    if (!$event.ctrlKey) return null
                    return _vm.ok.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.inputText = $event.target.value
                  },
                },
              })
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _vm.buttonNo
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-mm btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.no },
                  },
                  [_vm._v(_vm._s(_vm.buttonNo) + "\n                ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                ref: "saveButton",
                staticClass: "btn btn-mm btn-green",
                attrs: { type: "button", disabled: !_vm.inputText.length },
                on: { click: _vm.ok },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.buttonYes) +
                    "\n                "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "span",
              {
                staticClass: "focus-catch-link",
                attrs: { tabindex: "0", "aria-hidden": "true" },
                on: { focus: _vm.focusToClose },
              },
              [_vm._v(" ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }