/**
 * Created by orion on 13.03.17.
 */

import urls from 'DjangoUrls';
let {DjangoUrls} = urls;

angular.module('mmApp.urlResolve', [])
    .factory('urlResolve', [function () {
    return {
        userprofile_by_name: function (name) {
            return DjangoUrls["user_detail"](name);
        },
        folder_by_slug: function (slug) {
            return DjangoUrls["view_folder"](slug);
        },
        story_by_owner_id_and_slug: function (owner_id, slug) {
            return DjangoUrls["view_story"](owner_id, slug);
        }
    }

}]);

export default 'mmApp.urlResolve'