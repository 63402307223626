export default {
    computed: {
        currentSection() {
            return this.$store.getters['WikiStore/currentSection'];
        },
        wikiOwner() {
            return this.$store.getters['WikiStore/wikiOwner'];
        }
    },
    methods: {
    }
};