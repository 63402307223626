var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("community-page-alternative-header", { attrs: { title: "About Us" } }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12 text-right" },
          [
            _vm.viewerIsAdmin
              ? _c("vue-switch-with-label", {
                  attrs: { label: "Edit Mode", name: "edit_mode" },
                  model: {
                    value: _vm.editMode,
                    callback: function ($$v) {
                      _vm.editMode = $$v
                    },
                    expression: "editMode",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c(
              "story-list",
              {
                attrs: {
                  "story-resource": _vm.storyResource,
                  "target-id": _vm.community.id,
                  "allow-add-content": _vm.viewerIsAdmin,
                  "edit-mode": _vm.editMode,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "alert-info alert",
                    attrs: { slot: "empty" },
                    slot: "empty",
                  },
                  [
                    _vm._v(
                      "There has been nothing added to this Community's story\n                    yet\n                "
                    ),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }