import urls from 'DjangoUrls';
import axios from 'axios';

let { DjangoUrls } = urls;
const PREFIX = 'api.v1:blog_permissions-';

ManageDiaryPermissionsResource.$inject = ['$http'];

function ManageDiaryPermissionsResource($http) {
    return {
        get_diary_locations,
        get_member_permissions,
        set_location_permission,
        set_blog_permission,
        make_admin,
        unmake_admin,
        update_access_link_term,
        protect_link_by_password,
    };

    function get_member_permissions(memberId) {
        const url = DjangoUrls[`${PREFIX}get-member-permissions`](memberId);
        return axios({
            method: 'GET',
            url,
        });
    }

    function get_diary_locations(memberId) {
        const url = DjangoUrls[`${PREFIX}get-diary-locations`](memberId);
        return axios({
            method: 'GET',
            url,
        });
    }

    function set_location_permission(memberId, data) {
        const url = DjangoUrls[`${PREFIX}set-location-permission`](memberId);
        return axios({
            method: 'POST',
            url,
            data
        });
    }

    function set_blog_permission(memberId, data) {
        const url = DjangoUrls[`${PREFIX}set-blog-permission`](memberId);
        return axios({
            method: 'POST',
            url,
            data
        });
    }

    function make_admin(memberId) {
        const url = DjangoUrls[`${PREFIX}make-admin`](memberId);
        return axios({
            method: 'POST',
            url
        });
    }

    function unmake_admin(memberId) {
        const url = DjangoUrls[`${PREFIX}unmake-admin`](memberId);
        return axios({
            method: 'POST',
            url
        });
    }

    function update_access_link_term(memberId, data) {
        const url = DjangoUrls[`${PREFIX}update-access-link-term`](memberId);
        return axios({
            method: 'PATCH',
            url,
            data
        });
    }

    function protect_link_by_password(memberId) {
        const url = DjangoUrls[`${PREFIX}protect-link-by-password`](memberId);
        return axios({
            method: 'PATCH',
            url,
        });
    }
}

export default ManageDiaryPermissionsResource;
