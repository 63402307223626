var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a", {
    attrs: {
      id: "close_popup",
      role: "button",
      "aria-label": "Close dialog",
      tabindex: "0",
    },
    on: {
      click: _vm.close,
      keydown: [
        function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.close.apply(null, arguments)
        },
        function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
          )
            return null
          if (!$event.shiftKey) return null
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.onReturnFocus.apply(null, arguments)
        },
      ],
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }