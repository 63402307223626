/**
 * Created by orion on 6/14/17.
 */

import Vue from "vue";
import VueRouter from "vue-router";
import StoryCommentsPage from "./StoryCommentsPage.vue";

StoryCommentsController.$inject = ['$preloaded', '$scope'];

function StoryCommentsController($preloaded, $scope) {


    function initVue() {
        const vm = new Vue({
            el: '#story_comments',
            router: new VueRouter({
                mode: 'history',
                routes: []
            }),
            render(createElement) {
                const attrs = {};
                attrs['storyId'] = $preloaded.story.id;
                attrs['isAdmin'] = $preloaded.is_admin;
                return createElement(StoryCommentsPage, {attrs});
            },
        });

        $scope.$on('$destroy', () => {
            if (vm) {
                vm.$destroy();
            }
        });
    }

    initVue();
}

export default StoryCommentsController;