<template>
    <modal v-model="opened" :transition='0' :backdrop="false">
        <div slot="header">
            <a id="close_popup" @click="closePopup"></a>
            <h3 class="modal-title">Change Event recurrence</h3>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <form class="form">
                    <span>Since Date:</span>
                    <dropdown class="form-group" ref="start-picker">
                        <div class="input-group">
                            <input class="form-control" type="text" v-model="startDate"
                                   @click="showStartPicker">
                            <div class="input-group-btn">
                                <btn class="dropdown-toggle create-event-date-picker"><i
                                        class="glyphicon glyphicon-calendar"></i></btn>
                            </div>
                        </div>
                        <template slot="dropdown">
                            <li>
                                <date-picker v-model="startDate"
                                             :limit-from="limitStartFrom"
                                             :limit-to="limitStartTo"
                                             :date-parser="dateParser"
                                             format="dd/MM/yyyy"
                                             :week-starts-with="1"
                                             :week-numbers="true"
                                             :width="pickerWidth"

                                />
                            </li>
                        </template>
                    </dropdown>
                </form>
            </div>
            <div class="col-sm-6">
                <span>Repeat:</span>
                <select class="form-control inline-block" v-model="repeatOption" @change="onRepeatTypeChange">
                    <option :value="repeatOption.code" v-for="repeatOption in repeatOptions">{{ repeatOption.label }}
                    </option>
                </select>
            </div>
        </div>
        <div class="row" v-if="showDaysOfWeekSelector">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
                <span>Days of the week:</span>
                <multi-select
                        v-show="showDaysOfWeekSelector"
                        v-model="multiDaysSelected"
                        :options="multiDaysOptions"
                        :block="true"
                        :collapse-selected="true"/>
            </div>
        </div>
        <div class="row" v-if="showSkipRepeatPeriodSelector">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
                <span>Number:</span>
                <select
                        name="skipRepeatPeriodSelector"
                        id="skipRepeatPeriodSelector"
                        v-model="skipRepeatPeriod"
                        class="form-control"
                >
                    <option :value="option.value" v-for="option in skipPeriodOptions">{{ option.text }}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <span>End on:</span>
                <form action="" class="">
                    <select name="" id="" class="form-control" v-model="periodicEventTermination">
                        <option value="never">Never</option>
                        <option value="after">After</option>
                        <option value="onDate">On Date</option>
                    </select>
                </form>
            </div>
            <div class="col-sm-6">
                <form action="" class="" v-if="periodicEventTermination!=='onDate'">
                    <span>&nbsp;</span>
                    <div class="row">
                        <div class="col-xs-4">
                            <select class="form-control" v-model="periodicEventAmountBeforeTermination"
                                    v-show="periodicEventTermination==='after'">
                                <option :value="afterAmountOption" v-for="afterAmountOption in afterAmountOptions">
                                    {{ afterAmountOption }}
                                </option>
                            </select>
                        </div>
                        <div class="col-xs-8">
                            <select class="form-control" v-model="periodicEventTerminationUnit"
                                    v-show="periodicEventTermination==='after'">
                                <option :value="repeatUnit.code" v-for="repeatUnit in repeatUnits">
                                    {{ repeatUnit.label }}
                                </option>
                            </select>
                        </div>
                    </div>
                </form>
                <form v-if="periodicEventTermination==='onDate'">
                    <span>&nbsp;</span>
                    <dropdown class="form-group" v-show="periodicEventTermination==='onDate'" ref="period-end-picker">
                        <div class="input-group">
                            <input class="form-control" type="text" v-model="periodEndDate"
                                   @click="showPeriodEndPicker">
                            <div class="input-group-btn">
                                <btn class="dropdown-toggle create-event-date-picker"><i
                                        class="glyphicon glyphicon-calendar"></i></btn>
                            </div>
                        </div>
                        <template slot="dropdown">
                            <li>
                                <date-picker v-model="periodEndDate"
                                             :limit-from="startDate"
                                             format="dd/MM/yyyy"
                                             :date-parser="dateParser"
                                             :width="pickerWidth"
                                             :week-starts-with="1"
                                             :week-numbers="true"
                                />
                            </li>
                        </template>
                    </dropdown>

                </form>
                <span v-show="calculatedEndDate">
                        End Date: {{ calculatedEndDateFormatted }}
                </span>
            </div>
        </div>
        <div slot="footer">
            <button class="btn btn-mm" @click="closePopup">Cancel</button>
            <button class="btn btn-mm btn-green save-button"
                    @click="save"
                    :disabled="!allowSave">
                Save
            </button>
        </div>
    </modal>
</template>

<script>
    import moment from 'moment';
    import {VISUAL_DATE_FORMAT} from "../new_calendar/config";
    import CreateEditPopupPeriodMixin from "./CreateEditPopupPeriodMixin";
    import ScreenWidthAwareMixin from "../../../../vue/mixins/ScreenWidthAwareMixin";
    import {EVENT_PERIODS} from "../new_calendar/common";


    export default {
        name: "EditEventPeriodPopup",
        mixins: [ScreenWidthAwareMixin, CreateEditPopupPeriodMixin],
        data() {
            return {
                opened: false,
                startDate: undefined,
                eventStartDate: undefined,
                eventEndDate: undefined,
                skipRepeatPeriod: undefined,
            };
        },
        computed: {
            showDaysOfWeekSelector() {
                return this.repeatOption === EVENT_PERIODS.WEEKLY_BY_DAY_NUMBERS;
            },
            showSkipRepeatPeriodSelector() {
                return this.repeatOption === EVENT_PERIODS.ONCE_EVERY_N_WEEKS;
            },
            pickerWidth() {
                return Math.min(340, this.windowWidth - 40);
            },
            limitStartFrom() {
                return this.eventStartDate;
            },
            limitStartTo() {
                return moment(this.eventEndDate, VISUAL_DATE_FORMAT).add(1, 'd').format(VISUAL_DATE_FORMAT);
            },
            startDateMoment() {
                return moment(this.startDate, VISUAL_DATE_FORMAT);
            },
            allowSave() {
                if (this.periodicEventTermination !== 'never') {
                    if (this.periodicEventTermination === 'onDate') {
                        if (!this.isPeriodEndDateValid) {
                            return false;
                        }
                    } else {
                        if (!this.calculatedEndDate) {
                            return false;
                        }
                    }
                }
                if (this.repeatOption === EVENT_PERIODS.WEEKLY_BY_DAY_NUMBERS && this.multiDaysSelected.length === 0) {
                    return false;
                }
                if (this.repeatOption === EVENT_PERIODS.ONCE_EVERY_N_WEEKS && !this.skipRepeatPeriod) {
                    return false;
                }
                return true;
            },
        },
        methods: {
            show(startDate, periodData, periodInfo) {
                const self = this;
                this.startDate = startDate;
                this.repeatOption = periodData.repeat_type;
                this.eventStartDate = periodInfo.start_date;
                this.eventEndDate = periodInfo.end_date;
                this.skipRepeatPeriod = periodInfo.skip_repeat_period;
                this.multiDaysSelected = periodData.days_of_week ? periodData.days_of_week.map(d => d + 1) : [];
                if (this.eventEndDate) {
                    this.periodEndDate = this.eventEndDate;
                    this.periodicEventTermination = 'onDate';
                }
                this.opened = true;
                return new Promise((resolve, reject) => {
                    self.resolve = resolve;
                    self.reject = reject;
                });
            },
            closePopup() {
                this.opened = false;
                this.reject('closed');
            },
            save() {
                const res = {since_date: this.startDate};
                res.repeat_type = this.repeatOption;
                res.days_of_week = this.multiDaysSelected;
                if (this.periodicEventTermination !== 'never') {
                    if (this.periodicEventTermination === 'onDate') {
                        res.period_end_date = this.periodEndDate;
                    } else {
                        res.period_end_date = this.calculatedEndDateFormatted;
                    }
                }
                if (this.repeatOption === EVENT_PERIODS.ONCE_EVERY_N_WEEKS) {
                    res['skip_repeat_period'] = this.skipRepeatPeriod;
                }
                this.resolve(res);
                this.opened = false;
            },
            onRepeatTypeChange() {
                this.$nextTick(() => {
                    if ((this.repeatOption === EVENT_PERIODS.ONCE_EVERY_N_WEEKS) && !this.skipRepeatPeriod) {
                        this.skipRepeatPeriod = 2;
                    }
                });
            }
        }

    };
</script>

<style scoped>
    .create-event-date-picker {
        background-image: unset;
        background-color: white;
    }

    .btn-sm {
        font-size: 14px;
    }
</style>