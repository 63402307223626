var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": "Crop Picture Dialog", role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.title))]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.opened
        ? _c("Cropper", {
            ref: "cropper",
            attrs: {
              classname: "cropper",
              "stencil-component": _vm.stencilComponent,
              "stencil-props": _vm.stencilProps,
              src: _vm.image,
              "max-width": 4800,
              "max-height": 4800,
              restrictions: _vm.pixelsRestriction,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-5 text-left" },
            [
              _vm.allowSaveToMedia
                ? _c("vue-switch-with-label", {
                    attrs: {
                      "collapse-on-mobile": false,
                      name: "save_to_media",
                      label: "Save to My Media",
                    },
                    model: {
                      value: _vm.saveToMedia,
                      callback: function ($$v) {
                        _vm.saveToMedia = $$v
                      },
                      expression: "saveToMedia",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-7 text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-primary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.close()
                  },
                },
              },
              [_vm._v("Cancel\n                ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                ref: "selectButton",
                staticClass: "btn btn-mm btn-green",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.select()
                  },
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                    )
                      return null
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.focusToClose.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Select\n                ")]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }