function cutNameFilter() {
    return function (name, len) {
        let new_name;
        if (name && name.length > len) {
            new_name = name.substring(0, len - 3) + '...';
        } else {
            new_name = name;
        }
        return new_name;
    };
}

export default cutNameFilter;