/**
 * Created by orion on 04.05.17.
 */
let angular = require('angular');
import STICKERS from '../../../../../../js/stickers.json'

angular.forEach(STICKERS, (sticker_group, sticker_group_index) => {
    let current_folder = sticker_group.folder;
    angular.forEach(sticker_group.stickers, (sticker, sticker_index) => {
        sticker.url = get_sticker_url(current_folder, sticker.file_name);
        sticker.folder = current_folder;
    });
    sticker_group.title_sticker_href = get_sticker_url(current_folder, sticker_group.title_sticker);
});

function get_sticker_url(folder, file_name) {
    /**
     * @param {string} folder 'hands'||'monsters'||'teddys'
     * @param {string} file_name - sticker in group name
     */
    return `/static/images/stickers/${folder}/${file_name}`;
}

export default STICKERS;