var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wiki-breadcrumbs-component" }, [
    _c("div", {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.avatarTooltipText,
          expression: "avatarTooltipText",
        },
      ],
      staticClass:
        "breadcrumb-image border-group pointer wiki-breadcrumbs-component__user-profile-link",
      style: _vm.mainSectionAvatarStyle,
      on: { click: _vm.mainSectionClick },
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "breadcrumb-circles-container" },
      [
        _vm.section &&
        _vm.section.parent_section &&
        _vm.section.parent_section.parent_section
          ? _c("wiki-breadcrumbs-section", {
              attrs: {
                section: _vm.section.parent_section.parent_section,
                size: _vm.size,
              },
              on: { sectionClick: _vm.sectionClick },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.section && _vm.section.parent_section
          ? _c("wiki-breadcrumbs-section", {
              attrs: { section: _vm.section.parent_section, size: _vm.size },
              on: { sectionClick: _vm.sectionClick },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.section
          ? _c("wiki-breadcrumbs-section", {
              attrs: {
                section: _vm.section,
                size: _vm.size,
                isActive: _vm.activePart === "section",
              },
              on: { sectionClick: _vm.sectionClick },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "breadcrumb-label" }, [
      _vm._v(_vm._s(_vm.breadcrumbLabel)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }