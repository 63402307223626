<template>
    <div class="spf">
        <div class="row text-center">
            <div class="col-sm-12">
                <div class="row text-center" v-for="buttonRow in buttonRows">
                    <div class="col-sm-12">
                        <div
                                v-for="symbol in buttonRow"
                                class="spf__symbol-item"
                                :class="getSymbolClasses(symbol)"
                                @click="onSymbolClick(symbol)"
                                @keydown.enter.stop.prevent="onSymbolClick(symbol)"
                                tabindex="0"
                        >
                            <span class="spf__input-numbers">{{ getInputNumbers(symbol) }}</span>
                            <i
                                    :class="symbol.cls"
                                    :style="{color: symbol.clr}"
                                    class="fa spf__symbol-icon"
                                    aria-hidden="true"
                            ></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {SYMBOL_PASSWORD_BUTTONS} from './common';

    export default {
        name: "symbolPasswordForm",
        props: {
            value: {
                type: Array,
                default: () => [],
            },
            maxSymbolsCount: {
                type: Number,
                default: 12
            }
        },
        computed: {
            buttonRows() {
                return SYMBOL_PASSWORD_BUTTONS;
            },
            maxCountReached() {
                return this.value.length >= this.maxSymbolsCount;
            },
        },
        methods: {
            onSymbolClick(symbol) {
                const symbolUsedCount = this.value.filter(s => s === symbol).length;
                if (symbolUsedCount > 3) {
                    this.$notifications.warning('You can\'t use one symbol more than 4 times.');
                    return;
                }
                if (this.maxCountReached) {
                    this.$notifications.warning('Too much Symbols');
                    return;
                }
                this.$emit('input', [...this.value, symbol]);
            },
            getSymbolClasses(symbol) {
                const classes = [];
                const symbolUsedCount = this.value.filter(s => s === symbol).length;
                if (symbolUsedCount > 0) {
                    if (symbolUsedCount < 4) {
                        classes.push(`spf__symbol-item_pressed`);
                        classes.push(`spf__symbol-item_input-${symbolUsedCount}`);
                    } else {
                        classes.push('spf__symbol-item_pressed');
                        classes.push('spf__symbol-item_disabled');
                    }
                }
                return classes;
            },
            getInputNumbers(symbol) {
                const allIndexes = [];
                for (let i = 0; i < this.value.length; i++) {
                    if (this.value[i] === symbol) {
                        allIndexes.push(i + 1);
                    }
                }
                return allIndexes.join(', ');
            },
        },
    };
</script>

<style scoped lang="scss">
  @import "../../styles/mixins";

  .spf {
    @include selectForbidden;

    &__symbol-icon {
      margin-top: 13px;
      font-size: 48px;
      opacity: 0.7;
      @media (max-width: 490px) {
        margin-top: 5px;
        font-size: 37px;
      }
    }

    &__input-numbers {
      position: absolute;
      top: 1px;
      left: 3px;
      word-break: break-word;
      display: inline-block;
      color: white;
      font-weight: bold;
      text-align: left;
      z-index: 2;

      font-size: 21px;
      line-height: 35px;
      @media (max-width: 490px) {
        font-size: 14px;
        line-height: 24px;
      }
    }

    &__symbol-item {
      text-align: center;
      margin: 5px;
      position: relative;
      background-color: #8ff1eb;
      border-radius: 0.5em;
      padding: 0;
      cursor: pointer;
      display: inline-block;

      width: 70px;
      height: 70px;
      @media (max-width: 490px) {
        width: 48px;
        height: 48px;
        margin: 2px;
      }

      &_pressed {
        i {
          opacity: 0.4;
          z-index: 1;
          color: black !important;
        }
      }

      &_input-1 {
        background-color: #868685;

        &:hover, &:focus {
          background-color: #818180 !important;
        }

        &:active {
          background-color: #7c7c7b;
        }
      }

      &_input-2 {
        background-color: #696968;

        &:hover, &:focus {
          background-color: #636362 !important;;
        }

        &:active {
          background-color: #5e5e5d;
        }
      }

      &_input-3 {
        background-color: #555554;

        &:hover, &:focus {
          background-color: #4f4f4e !important;;
        }

        &:active {
          background-color: #4b4b4a;
        }
      }

      &_disabled {
        cursor: not-allowed;
        background-color: rgb(65, 65, 65);

        &:hover, &:focus {
          background-color: rgb(65, 65, 65) !important;
        }
      }

      &:hover, &:focus {
        background-color: #7fd7d1;
      }

      &:active {
        background-color: #75c9c3;
      }

    }

  }
</style>