<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="title"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ title }}</h3>
        </div>

        <div v-for="selectedDocument of selectedDocuments" style="font-size: 18px">
            <img :src="selectedDocument.iconUrl" :alt="selectedDocument.name">
            {{ selectedDocument.name }}
        </div>
        <div class="" slot="footer">
            <div class="row">
                <div class="col-sm-5 text-left">
                    <vue-switch-with-label :collapse-on-mobile="false" name="save_to_media" v-model="saveToMedia"
                                           label="Save to My Media"
                                           v-if="allowSaveToMedia"></vue-switch-with-label>
                </div>
                <div class="col-sm-7 text-right">
                    <button class="btn btn-mm btn-primary" @click="back">Back</button>
                    <button
                            class="btn-mm btn btn-green"
                            ref="addButton"
                            @click="addFiles">
                        Add Files
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <span tabindex="0" aria-hidden="true" class="focus-catch-link" @focus="focusToClose">&nbsp;</span>
                </div>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import BasicModal from "../../../vue/common_components/BasicModal";
    import PopupPromiseMixin from "../../../vue/mixins/PopupPromiseMixin";

    export default {
        name: "AddFromGoogleDrivePopup",
        mixins: [PopupPromiseMixin],
        components: {BasicModal},
        data() {
            return {
                pickerApiLoaded: false,
                selectedDocuments: [],

                saveToMedia: true,
                config: {},
            };
        },
        computed: {
            title() {
                if (this.config.max_count > 1) {
                    return 'Add Documents from Google Drive';
                } else {
                    return 'Add Document from Google Drive';
                }
            },
            firstFocusEl() {
                return this.$refs.addButton;
            },
            allowSaveToMedia() {
                return this.config.allow_save_to_folders;
            }
        },
        methods: {
            setInitial(config) {
                this.config = config;
            },
            processButtonClick() {
                window.gapi.auth2.authorize({
                    client_id: GOOGLE_CLIENT_ID,
                    scope: 'https://www.googleapis.com/auth/drive.file'
                }, this.handleAuthResult);
            },
            handleAuthResult(authResult) {
                if (authResult && !authResult.error) {
                    this.oauthToken = authResult.access_token;
                    this.createPicker();
                } else {
                    return console.warn(authResult.details);
                }
            },
            createPicker() {
                if (this.pickerApiLoaded && this.oauthToken) {
                    const view = new google.picker.DocsView(google.picker.ViewId.DOCUMENTS).setIncludeFolders(true);
                    const picker = new google.picker.PickerBuilder()
                        .addView(view)
                        .setOAuthToken(this.oauthToken)
                        .setCallback(this.pickerCallback)
                        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
                        .build();
                    picker.setVisible(true);
                }
            },
            pickerCallback(data) {
                if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
                    this.tmpShow();
                    for (const doc of data.docs) {
                        this.selectedDocuments.push(doc);
                    }
                    this.$emit("picked", data);
                    this.$_catchFocus(this.firstFocusEl);
                } else if (data[google.picker.Response.ACTION] === google.picker.Action.CANCEL) {
                    this.back();
                }
            },
            loadApi() {
                if (!window.gapi)
                    return console.warn('Google API not loaded');
                window.gapi.load('auth2');
                window.gapi.load('picker', () => {
                    this.pickerApiLoaded = true;
                    this.processButtonClick();
                });
            },
            back() {
                this.close('back');
            },
            addFiles() {
                this.save({
                    save_to_media: this.saveToMedia,
                    documents: this.selectedDocuments,
                });
            }
        },
        mounted() {
            this.tmpHide();
            this.loadApi();
        }
    };
</script>