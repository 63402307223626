<template>
  <basic-modal
      @hide="close"
      v-model="opened"
      :aria-label="header"
      role="dialog"
  >
    <div slot="header">
      <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
      <h3 class="modal-title">{{ header }}</h3>
    </div>

    <div class="row">
      <div class="col-sm-12">
        Address line 1<span class="color-red">*</span>:
        <input type="text" class="form-control" v-model="event.address_line_1">
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        Address line 2:
        <input type="text" class="form-control" v-model="event.address_line_2">
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        Region:
        <input type="text" class="form-control" v-model="event.region">
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        Country:
        <input type="text" class="form-control" v-model="event.country">
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        City:
        <input type="text" class="form-control" v-model="event.city">
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        Postcode:
        <input type="text" class="form-control" v-model="event.postcode">
      </div>
    </div>

    <div slot="footer" class="row">
      <div class="col-sm-12 text-right">
        <button class="btn btn-mm" @click="close">Cancel</button>
        <button class="btn btn-mm btn-green" :disabled="!event.address_line_1" @click="create">{{ popup_config.button_yes ? popup_config.button_yes : "OK" }}</button>
      </div>
    </div>
  </basic-modal>
</template>

<script>
import BasicModal from "../../../../vue/common_components/BasicModal";
import PopupPromiseMixin from "../../../../vue/mixins/PopupPromiseMixin";

export default {
  name: "FullAddressPopup",
  mixins: [PopupPromiseMixin],
  components: {BasicModal},
  props: {
    initialData: Object,
    popup_config: Object,
  },
  data() {
    return {
      header: "Location Details",
      event: undefined,
    }
  },
  created() {
    this.event = Object.assign({}, this.initialData);
  },
  methods: {
    create() {
      this.save(this.event);
    }
  }
}
</script>

<style scoped>

</style>
