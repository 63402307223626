<template>
    <div class="">
        <template v-if="!membershipLoaded">
            <template v-if="accessLinkPasswordRequired">
                <access-link-auth-form
                        :access-code="accessCode"
                        :id="wikiId"
                        :access-link-check-endpoint="accessLinkCheckEndpoint"
                        @tokenObtained="tokenObtained"
                ></access-link-auth-form>
            </template>
            <template v-else-if="accessCodeError">
                <div class="alert alert-danger">
                    Sorry, you do not have access to this Wiki
                </div>
            </template>
            <template v-else-if="accessCodeExpired">
                <div class="alert alert-danger">
                    Sorry, your access link has expired. Please ask for the link to be extended, or to be sent a new
                    one.
                </div>
            </template>
            <template v-else> Loading...</template>
        </template>
        <template v-else>
            <slot name="header"></slot>
            <div v-if="membershipAccessGranted">
                <router-view
                        v-on="$listeners"
                        v-bind="$attrs"
                ></router-view>
            </div>
            <div class="" v-if="membershipAccessError">
                <div class="alert alert-warning" role="alert">
                    There has been no content on this Wiki shared with you, please contact the Wiki owner and request
                    that
                    they give you access to Wiki content.
                </div>
            </div>
        </template>

            <img
                    v-if="wikiLogo"
                    alt="Logo"
                    class="logo-image"
                    :height="60"
                    :src="wikiLogo"
            >

    </div>
</template>

<script>
    import membershipActionsMixin from './membershipActionsMixin';
    import { mapGetters } from 'vuex';
    import AccessLinkAuthForm from './access_link_auth/AccessLinkAuthForm.vue';
    import WikiResource from '../wiki/wiki_main_resource';

    export default {
        name: 'WikiPageAbstract',
        components: { AccessLinkAuthForm },
        mixins: [membershipActionsMixin],
        computed: {
            ...mapGetters('WikiStore', ['membershipAccessGranted', 'membershipAccessError', 'membershipLoaded']),
            accessLinkCheckEndpoint() {
                return WikiResource().authenticate_access_link;
            },
            wikiLogo() {
                return this.$store.getters['WikiStore/wikiTemplate']?.logo || this.$store.getters['WikiStore/wikiLogo'];
            },
        },
        beforeDestroy() {
            this.$store.commit('WikiStore/toggleEditMode', false);
        },
        props: {
            wikiId: {
                type: [String, Number],
            },
            accessCode: {
                type: String,
                required: false
            }
        },
        methods: {
            onPermissionsLoaded() {
                this.$emit('onWikiLoaded');
            }
        }
    };
</script>

<style scoped lang="scss">
  .logo-image {
    position: absolute;
    bottom: 15px;
    left: 15px;
    border-radius: 5px;
  }
</style>
