import Vue from 'vue';
import VueInlineMedia from './VueInlineMediaFunc.js';

mediaItemInline.$inject = [];

function mediaItemInline() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            item: '=',
            autoplay: '=?'
        },
        template: `<div></div>`,
        link: function ($scope, element, attrs, controller) {

            const vm = new Vue({
                el: element[0],
                render(createElement) {
                    const attrs = {
                        item: $scope.item,
                        autoplay: !!$scope.autoplay,
                    };
                    return createElement(VueInlineMedia, {attrs});
                },
                name: 'MediaItemInline'
            });

            $scope.$watch('item', (n, o) => {
                if (n.id && o.id && (n.id !== o.id)) {
                    vm.item = n;
                    vm.$forceUpdate();
                }
            }, true);

            $scope.$on('$destroy', () => {
                if (vm) {
                    vm.$destroy();
                }
            });
        }
    };
}

angular.module('mmApp.view_media.mediaItemInline', []).directive('mediaItemInline', mediaItemInline);
export default 'mmApp.view_media.mediaItemInline';