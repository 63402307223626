var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "calendar-page" },
    [
      _vm.initialized
        ? _c("router-view", { attrs: { membershipState: _vm.membershipState } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }