var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "weeks-navigation" }, [
    _c(
      "div",
      { staticClass: "row margin-10-bottom weeks-navigation__mobile" },
      [
        _c("div", { staticClass: "col-xs-6 text-left" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-mm-small background-tag-gray",
              on: { click: _vm.previousWeek },
            },
            [_vm._v("\n                Week Before\n            ")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-6 text-right" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-mm-small background-tag-gray",
              on: { click: _vm.nextWeek },
            },
            [_vm._v("\n                Week After\n            ")]
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row margin-10-bottom weeks-navigation__desktop" },
      [
        _c("div", { staticClass: "col-xs-3 text-left" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-mm-small background-tag-gray",
              on: { click: _vm.previousWeek },
            },
            [_vm._v("\n                Week Before\n            ")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col-xs-6 text-center weeks-navigation__desktop__label",
          },
          [
            _vm._v(
              "\n            " + _vm._s(_vm.loadedWeekLabel) + "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-3 text-right" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-mm-small background-tag-gray",
              on: { click: _vm.nextWeek },
            },
            [_vm._v("\n                Week After\n            ")]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }