<template>
    <a :href="link" class="dashboard-list-item hvr-float-shadow">
        <div class="dashboard-list-item__image mobile" :class="{'enlarged':enlarged}">
        </div>
        <div class="dashboard-list-item__image desktop" :class="{'enlarged':enlarged}">
            <img :src="currentUserAvatar" alt="" class="dashboard-list-item__image__avatar-inside" v-if="enlarged">
        </div>
        <div class="dashboard-list-item__label" :class="{'enlarged':enlarged}">{{label}}</div>
    </a>
</template>

<script>
    import Vue from 'vue';

    export default {
        name: "dashboard-list-item-wiki",
        props: {
            label: {
                type: String,
            },
            image: {
                type: String
            },
            link: {
                type: String
            },
            enlarged: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            avatarStyle() {
                return {};
            },
            currentUserAvatar() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                return CurrentUser.avatar;
            }
        }
    };
</script>

<style scoped lang="scss">
    .dashboard-list-item {
        vertical-align: top;
        @media (max-width: 600px) {
            display: inline-block;
        }
        @media (min-width: 601px) {
            display: block;
        }

        position: relative;
        cursor: pointer;
        text-decoration: none;
        color: #333;
        @media (min-width: 1000px) {
            margin-left: 40px;
            margin-right: 40px;
            margin-top: 50px;
        }
        @media (min-width: 842px) and (max-width: 999px) {
            margin-left: 50px;
            margin-right: 50px;
            margin-top: 50px;
        }
        @media (min-width: 601px) and (max-width: 841px) {
            margin-left: 30px;
            margin-right: 30px;
            margin-top: 40px;
        }
        @media (min-width: 560px) and (max-width: 600px) {
            margin-left: 30px;
            margin-right: 30px;
            margin-top: 30px;
        }
        @media (min-width: 410px) and (max-width: 559px) {
            margin-left: 30px;
            margin-right: 30px;
            margin-top: 30px;
        }
        @media (min-width: 320px) and (max-width: 409px) {
            margin-left: 5px;
            margin-right: 5px;
            margin-top: 30px;
        }

        &__image {
            &.mobile {
                background-image: url('/static/images/new_daschboard_icons/2020/mywiki-small-white.png');
                background-color: #00D4F1;
                border-radius: 10px;
                background-size: 90%;
                @media (min-width: 700px) {
                    display: none;
                }
            }

            &.desktop:not(.enlarged) {
                background-color: #00D4F1;
                border-radius: 10px;
                background-image: url('/static/images/new_daschboard_icons/2020/mywiki-small-white.png');
                background-size: 95%;
                @media (max-width: 699px) {
                    display: none;
                }
            }

            &.desktop.enlarged {
                @media (min-width: 1000px) {
                    background-image: url('/static/images/new_daschboard_icons/2020/mywiki-big.png');
                }
                @media (min-width: 700px) and (max-width: 999px) {
                    background-image: url('/static/images/new_daschboard_icons/2020/mywiki-big-bolder.png');
                }
                @media (max-width: 699px) {
                    display: none;
                }
            }

            position: relative;
            margin: auto;
            width: 128px;
            height: 128px;
            @media (min-width: 1000px) {
                &.enlarged {
                    width: 382px;
                    height: 311px;
                }
            }
            @media (min-width: 700px) and (max-width: 999px) {
                &.enlarged {
                    width: 224px;
                    height: 190px;
                }
            }

            &__avatar-inside {
                border-radius: 50%;
                position: absolute;
                @media (min-width: 1000px) {
                    top: 62px;
                    left: 97px;
                    width: 188px;
                    height: 187px;
                }
                @media (min-width: 700px) and (max-width: 999px) {
                    top: 40px;
                    left: 57px;
                    width: 110px;
                    height: 110px;
                }
                background-color: white;
            }

            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        &__label {
            text-align: center;
            font-size: 17px;

            &.enlarged {
                @media (min-width: 1000px) {
                    font-size: 35px;
                }
            }

            font-weight: bolder;
        }
    }
</style>