<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="header"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ header }}</h3>
        </div>

        <div class="row margin-5-bottom" v-show="info">
            <div class="col-sm-12" v-html="info" style="font-size: 18px">
            </div>
        </div>
        <div class="row margin-15-bottom margin-10-top" v-if="allowEmail">
            <div class="col-sm-12">
                <div :class="{'has-error':!emailIsValid&&!emailIsEmpty, 'has-success':emailIsValid}">
                    <label>Email: <span
                            :class="{'color-gray':!highLight.email, 'color-red':highLight.email}">(required)</span></label>
                    <input ref="emailField" type="email" class="form-control" v-model="email"
                           placeholder="email@example.com" required/>
                </div>
            </div>
        </div>
        <div class="row margin-15-bottom" v-if="allowCategory">
            <div class="col-sm-12">
                <label>Select category: <span
                        :class="{'color-gray':!highLight.category, 'color-red':highLight.category}">(required)</span></label>
                <circle-member-category-selector
                        v-model="selectedCategory"
                        @change="focusToSaveButton"
                ></circle-member-category-selector>
            </div>
        </div>
        <div class="row margin-15-bottom" v-if="opened&&!isRoleDisabled">
            <div class="col-sm-12">
                <label>What role does this person play?</label>
                <input type="text" class="form-control" id="role-input"
                       v-model="role"
                       :disabled="isRoleDisabled"
                       :placeholder="rolePlaceholder">
                <typeahead v-model="role" target="#role-input" :data="roles"></typeahead>
            </div>
        </div>

        <div class="row" v-if="opened&&allowTerm">
            <div class="col-sm-12">
                <div class="row margin-15-bottom">
                    <div class="col-sm-12">
                        <div>
                            <strong>Link expires in:</strong>
                            <select class="form-control" v-model="validityTerm">
                                <option :value="term.val" v-for="term in accessLinkPossibleTerms" :key="term.val">{{
                                        term.name
                                    }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div slot="footer">
            <button class="btn btn-mm" @click="close">Cancel</button>
            <button class="btn btn-mm btn-green save-button"
                    ref="saveButton"
                    :disabled="saveDisabled"
                    :class="{disabled: saveDisabled}"
                    @click="send"
                    v-tooltip="saveButtonTooltip">
                {{ button_yes }}
            </button>
            <a href="/" @focus="focusToClose" aria-hidden="true">&nbsp;</a>
        </div>
    </basic-modal>

</template>

<script>
    import UserCategoriesMixin from '../../../vue/mixins/UserCategoriesMixin';
    import CircleMemberCategorySelector from './CircleMemberCategorySelector.vue';
    import PopupPromiseMixin from '../../mixins/PopupPromiseMixin';
    import BasicModal from '../BasicModal';

    const DEFAULT_POPUP_CONFIG = {
        header: 'Invite By Email',
        info: '',
        button_yes: 'Send Invitation'
    };
    const DEFAULT_DATA = {
        role: '',
        category: '',
        email: ''
    };

    const DEFAULT_FIELDS = ['email', 'role', 'category'];

    const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
    export default {
        name: 'EmailRoleCategoryPopup',
        mixins: [PopupPromiseMixin, UserCategoriesMixin],
        components: {
            BasicModal,
            CircleMemberCategorySelector
        },
        data () {
            return {
                highLight: {
                    email: false,
                    category: false,
                },
                opened: false,
                fields: DEFAULT_FIELDS,
                resolve: undefined,
                reject: undefined,
                info: DEFAULT_POPUP_CONFIG.info,
                role: DEFAULT_DATA.role,
                email: DEFAULT_DATA.email,
                button_yes: DEFAULT_POPUP_CONFIG.button_yes,
                header: DEFAULT_POPUP_CONFIG.header,
                roles: ['Teacher', 'Parent', 'Friend', 'Doctor', 'Family', 'Social Worker', 'Other'],

                validityTerm: 14,
            };
        },
        computed: {
            firstFocusEl () {
                if (!this.saveDisabled) {
                    return this.$refs.saveButton;
                }
                if (this.allowEmail) {
                    return this.$refs.emailField;
                }
                return this.$refs.closeButton;
            },
            errorInEmail () {
                return (this.allowEmail && !this.emailIsValid);
            },
            errorInCategory () {
                return (this.allowCategory && !this.selectedCategory);
            },
            saveButtonTooltip () {
                if (this.errorInEmail) {
                    return `Field "Email" is required`;
                }
                if (this.errorInCategory) {
                    return `Please Select this user's Category`;
                }
            },
            saveDisabled () {
                if (this.errorInEmail || this.errorInCategory) {
                    return true;
                }
                if (this.allowCategory && !this.selectedCategory) {
                    return true;
                }
                return false;
            },
            allowEmail () {
                return this.fields.indexOf('email') !== -1;
            },
            allowTerm () {
                return this.fields.indexOf('term') !== -1;
            },
            allowCategory () {
                return this.fields.indexOf('category') !== -1;
            },
            rolePlaceholder () {
                let defaultValue = 'Teacher, Doctor, Social Worker, Parent';
                return defaultValue;
                if (!this.allowCategory) {
                    return defaultValue;
                }
                if (this.selectedCategory === 'friends') {
                    return 'Friend';
                }
                if (this.selectedCategory === 'family') {
                    return 'Parent, Sister, Brother';
                }
                if (this.selectedCategory === 'professionals') {
                    return 'Teacher, Doctor, Social Worker';
                }
                return defaultValue;
            },
            isRoleDisabled () {
                if (this.allowCategory) {
                    return this.selectedCategory === 'friends';
                }
                return this.fields.indexOf('role') === -1;
            },
            emailIsValid () {
                return (this.email === '') ? false : (emailReg.test(this.email));
            },
            emailIsEmpty () {
                return (this.email === '');
            },
            accessLinkPossibleTerms () {
                return [
                    {
                        name: 'never',
                        val: null
                    },
                    {
                        name: '3 days',
                        val: 3
                    },
                    {
                        name: '1 week',
                        val: 7
                    },
                    {
                        name: '2 weeks',
                        val: 14
                    },
                    {
                        name: '1 month',
                        val: 30
                    },
                ];
            },
        },
        methods: {
            focusToSaveButton () {
                if (!this.saveDisabled) {
                    return this.$refs.saveButton?.focus();
                }
            },
            onSecondEnter (value) {
                this.selectedCategory = value;
                this.send();
            },
            resetData () {
                this.role = '';
                this.selectedCategory = '';
                this.email = '';

                this.header = DEFAULT_POPUP_CONFIG.header;
                this.info = DEFAULT_POPUP_CONFIG.info;
                this.button_yes = DEFAULT_POPUP_CONFIG.button_yes;
            },
            setInitial (popupConfig, fields = undefined, initial = DEFAULT_DATA) {
                this.resetData();

                this.header = popupConfig.header || DEFAULT_POPUP_CONFIG.header;
                this.info = popupConfig.info || DEFAULT_POPUP_CONFIG.info;
                this.button_yes = popupConfig.button_yes || DEFAULT_POPUP_CONFIG.button_yes;

                this.fields = fields || DEFAULT_FIELDS;

                this.email = initial.email || DEFAULT_DATA.email;
                this.selectedCategory = initial.category || DEFAULT_DATA.category;
                this.role = initial.role || DEFAULT_DATA.role;
            },
            highLightRequiredFields () {
                this.highLight.category = this.errorInCategory;
                this.highLight.email = this.errorInEmail;
            },
            send () {
                this.highLightRequiredFields();
                if (this.saveDisabled) {
                    return;
                }
                const res = { role: this.role };
                if (this.allowCategory) {
                    res['category'] = this.selectedCategory;
                }
                if (this.allowEmail) {
                    res['email'] = this.email;
                }
                if (this.allowTerm) {
                    res['term_days'] = this.validityTerm;
                }
                this.save(res);
            }
        },
        watch: {
            email () {
                this.highLight.email = false;
            },
            selectedCategory (n) {
                this.highLight.category = false;
            }
        }
    };
</script>

<style scoped lang="scss">
  .save-button {
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
</style>
