var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.title, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.title))]),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.selectedDocuments, function (selectedDocument) {
        return _c("div", { staticStyle: { "font-size": "18px" } }, [
          _c("img", {
            attrs: {
              src: selectedDocument.iconUrl,
              alt: selectedDocument.name,
            },
          }),
          _vm._v("\n        " + _vm._s(selectedDocument.name) + "\n    "),
        ])
      }),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-5 text-left" },
            [
              _vm.allowSaveToMedia
                ? _c("vue-switch-with-label", {
                    attrs: {
                      "collapse-on-mobile": false,
                      name: "save_to_media",
                      label: "Save to My Media",
                    },
                    model: {
                      value: _vm.saveToMedia,
                      callback: function ($$v) {
                        _vm.saveToMedia = $$v
                      },
                      expression: "saveToMedia",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-7 text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-primary",
                on: { click: _vm.back },
              },
              [_vm._v("Back")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                ref: "addButton",
                staticClass: "btn-mm btn btn-green",
                on: { click: _vm.addFiles },
              },
              [_vm._v("\n                    Add Files\n                ")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "span",
              {
                staticClass: "focus-catch-link",
                attrs: { tabindex: "0", "aria-hidden": "true" },
                on: { focus: _vm.focusToClose },
              },
              [_vm._v(" ")]
            ),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }