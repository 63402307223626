import Vue from 'vue';
import StoriesList from './StoriesList.vue';

storiesList.$inject = [];

function storiesList() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            storiesListEndpoint: '=',
            allowCreate: '=',
            allowFilter: '=',
            createStoryEndpoint: '=',
            filterCategories: '=?',
        },
        link: function ($scope, element, attrs, controller) {
            let vm = new Vue({
                el: element[0],
                components: {StoriesList},
                template: `
                  <stories-list
                      :allowCreate="allowCreate"
                      :allowFilter="allowFilter"
                      :filterCategories="filterCategories"
                      :storiesListEndpoint="storiesListEndpoint"
                      :createStoryEndpoint="createStoryEndpoint"
                  ></stories-list>`,
                name: 'storiesListDirective',
                data: {
                    allowCreate: $scope.allowCreate,
                    allowFilter: $scope.allowFilter,
                    filterCategories: $scope.filterCategories || [],
                    storiesListEndpoint: $scope.storiesListEndpoint,
                    createStoryEndpoint: $scope.createStoryEndpoint,
                }
            });

            $scope.$on('$destroy', function () {
                if (vm) {
                    vm.$destroy();
                }
            });

        }
    };
}

export default storiesList;