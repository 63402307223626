import Vue from "vue";

export default {
    namespaced: true,
    state: {
        community: {},
        story: {},
        event: {},
        communitySettings: {},
        viewerIsAdmin: false,
        viewerIsMember: false,
        aboutPageIsWiki: false,
        communityWikiId: undefined,
        communityWikiSubsectionsDisabled: undefined,
        subscriptionState: undefined, // true, false, undefined
    },
    mutations: {
        setCommunity(state, val) {
            state.community = val;
        },
        setCommunitySubscriptionState(state, val) {
            state.subscriptionState = val;
        },
        setStory(state, val) {
            state.story = val;
        },
        setEvent(state, val) {
            state.event = val;
        },
        setViewerState(state, {isAdmin, isMember}) {
            state.viewerIsAdmin = isAdmin;
            state.viewerIsMember = isMember;
        },
        setAboutPage(state, isWiki) {
            state.aboutPageIsWiki = isWiki;
        },
        setCommunitySettings(state, val) {
            state.communitySettings = val;
        },
        setCommunityWikiId(state, val) {
            state.communityWikiId = val;
        },
        setCommunityWikiSubsectionsDisabled(state, val) {
            state.communityWikiSubsectionsDisabled = val;
        },
        updateCommunityAvatar(state, val) {
            state.community.avatar = val;
        },
        updateCommunityName(state, val) {
            state.community.name = val;
        },
    },
    getters: {
        viewerIsAdmin(state, getters, rootState) {
            return state.viewerIsAdmin;
        },
        community(state, getters, rootState) {
            return state.community;
        },
        story(state, getters, rootState) {
            return state.story;
        },
        event(state, getters, rootState) {
            return state.event;
        },
        aboutPageIsWiki(state, getters, rootState) {
            return state.aboutPageIsWiki;
        },
        communityWikiId(state, getters, rootState) {
            return state.communityWikiId;
        },
        communityWikiSubsectionsDisabled(state, getters, rootState) {
            return state.communityWikiSubsectionsDisabled;
        },
        subscriptionState(state, getters, rootState) {
            return state.subscriptionState;
        }
    },
    actions: {
        getSubscriptionState({state, commit}) {
            if (!state.community) {
                return;
            }
            const CurrentUser = Vue.getAngularModule('CurrentUser');

            if (!CurrentUser.is_authenticated()) {
                return;
            }
            const CommunitySubscriptionsResource = Vue.getAngularModule('CommunitySubscriptionsResource');
            CommunitySubscriptionsResource.get_subscription_state(state.community.id)
                .then(resp => {
                    commit('setCommunitySubscriptionState', resp.data);
                });
        },
        subscribeToCommunityUpdates({state, commit}) {
            const $rootScope = Vue.getAngularModule('$rootScope');
            $rootScope.show_dimmer();
            const CommunitySubscriptionsResource = Vue.getAngularModule('CommunitySubscriptionsResource');
            CommunitySubscriptionsResource.subscribe(state.community.id)
                .then(resp => {
                    commit('setCommunitySubscriptionState', true);
                    Vue.notifications.success('Successfully subscribed');
                })
                .finally($rootScope.hide_dimmer);
        },
        unSubscribeFromCommunityUpdates({state, commit}) {
            const $rootScope = Vue.getAngularModule('$rootScope');
            const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
            $rootScope.show_dimmer();
            const CommunitySubscriptionsResource = Vue.getAngularModule('CommunitySubscriptionsResource');
            CommunitySubscriptionsResource.unsubscribe(state.community.id)
                .then(resp => {
                    if (resp.data.ok) {
                        commit('setCommunitySubscriptionState', false);
                        Vue.notifications.success('Successfully unsubscribed');
                    } else {
                        simplePopupFactory.show_popup('Can\'t unsubscribe', 'You cant switch off this community subscription as it has been added by your Portal, please contact them to change this.', 'OK', null);
                    }

                })
                .finally($rootScope.hide_dimmer);
        }
    }
};
