<template>
    <span v-if="sectionSnapshotBefore">
        <span>deleted <span
                v-if="sectionSnapshotBefore.is_submenu">submenu</span> section </span>
        <strong>
            #{{ sectionSnapshotBefore.id }} "{{
                sectionSnapshotBefore.caption
            }}"
        </strong>
        <span v-if="sectionSnapshotBefore.parent_section_name">
            in <strong>#{{ sectionSnapshotBefore.parent_section_id }}
            <wiki-log-possible-link
                    :target="wikiLog.parent_wiki_section"
                    :label="sectionSnapshotBefore.parent_section_name"
            ></wiki-log-possible-link>
        </strong>
        </span>
    </span>
</template>

<script>
    import SnapshotLogMixin from "./common/SnapshotLogMixin";

    export default {
        name: "WikiSectionDeleted",
        mixins:[SnapshotLogMixin],
    };
</script>

<style scoped>

</style>