/**
 * Created by orion on 31.05.17.
 */
import {customTermFilter} from "../../../../../vue/filters/CustomTerm";

let angular = require('angular');
let _ = require('lodash');
import urls from 'DjangoUrls';

let {DjangoUrls} = urls;
CollectionMembersController.$inject = ['CurrentUser', '$q', 'simplePopupFactory', 'notifications', '$rootScope', '$preloaded', '$log', 'selectFactory', 'CollectionMembersResource'];

function CollectionMembersController(CurrentUser, $q, simplePopupFactory, notifications, $rootScope, $preloaded, $log, selectFactory, CollectionMembersResource) {
    let self = this;
    self.members = [];

    function init() {
        self.is_admin = $preloaded.is_admin;
        self.is_member = $preloaded.is_member;
        self.collection = $preloaded.collection;
        fetch_members();
    }

    self.can_invite_users = _.once(can_invite_users);

    function can_invite_users() {
        return CurrentUser.has_permission('invite-users-to-join-collection') && self.is_member;
    }


    function fetch_members() {
        $rootScope.show_dimmer();
        CollectionMembersResource.get_members_for_members_page(self.collection.id)
            .then(resp => {
                self.members = resp.data;
            }, err => notifications.error(err || 'Error'))
            .finally($rootScope.hide_dimmer);
    }

    self.get_admins = function () {
        return self.members.filter(m => m.is_admin);
    };

    self.get_not_admins = function () {
        return self.members.filter(m => !m.is_admin);
    };

    self.delete_member = function (member) {
        simplePopupFactory.show_popup('Delete Member', `Are you sure you want to delete user '${member.name}' from this collection?`, 'Delete', 'Cancel')
            .then(() => {
                $rootScope.show_dimmer();
                return CollectionMembersResource.delete_member(self.collection.id, member.id);
            }, () => new Promise(()=>{}))
            .then(resp => {
                notifications.success('Deleted');
                self.members.splice(self.members.indexOf(member), 1);
            }, err => notifications.error(err || 'Error'))
            .finally($rootScope.hide_dimmer);
    };

    self.manage_admins = function () {
        selectFactory
            .endpoint_selector(_.partial(CollectionMembersResource.get_admins_for_manage, self.collection.id), true, {
                header: 'Manage Admins',
                info: 'Select or de-select user from list to make them admins',
                button_yes: 'Save',
                button_no: 'Cancel',
                empty: 'You do not have any users to select'
            })
            .then(res => {
                let new_checked_ids = res[0];
                let new_unchecked_ids = res[1];
                $rootScope.show_dimmer();
                return CollectionMembersResource.update_admins(self.collection.id, new_checked_ids, new_unchecked_ids)
            }, () => new Promise(()=>{}))
            .then(resp => {
                fetch_members();
            }, err => {
                $rootScope.hide_dimmer();
                notifications.error(err || 'Error');
            })
    };

    self.invite_users = function () {
        selectFactory
            .endpoint_selector(_.partial(CollectionMembersResource.get_users_for_invite, self.collection.id), true, {
                header: 'Invite Users',
                info: `Select or de-select user from list to invite them to this ${customTermFilter('Goal')}`,
                button_yes: 'Invite',
                button_no: 'Cancel',
                empty: 'You do not have any users to select'
            })
            .then(res => {
                let new_checked_ids = res[0];
                let new_unchecked_ids = res[1];
                $rootScope.show_dimmer();
                return CollectionMembersResource.invite_users(self.collection.id, new_checked_ids, new_unchecked_ids);
            }, () => new Promise(()=>{}))
            .then(resp => {
                notifications.success('Invited');
            }, err => {
                notifications.error(err || 'Error');
            }).finally($rootScope.hide_dimmer)
    };

    init();

}

export default CollectionMembersController