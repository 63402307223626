var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "subsection" }, [
    _c("i", {
      staticClass: "fa",
      class: _vm.chevronClasses,
      on: { click: _vm.toggleSubsectionsExpand },
    }),
    _vm._v(" "),
    _vm.allowAssignEditPermission
      ? _c("i", {
          staticClass: "fa fa-pencil",
          class: _vm.editButtonClasses,
          on: { click: _vm.toggleSectionLevelEditPermission },
        })
      : _vm._e(),
    _vm._v(" "),
    _c("i", {
      staticClass: "fa fa-eye",
      class: _vm.viewButtonClasses,
      on: { click: _vm.toggleSectionLevelViewPermissionLevel },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "subsection__section-avatar border-group",
        style: _vm.sectionAvatarStyle,
      },
      [
        _vm.isAvatarEmpty
          ? _c("i", { staticClass: "fa fa-picture-o" })
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c("span", [
      _c("a", { attrs: { href: _vm.section.link, target: "_blank" } }, [
        _c("strong", [
          _vm._v(_vm._s(_vm._f("cutName")(_vm.section.caption, 30))),
        ]),
      ]),
      _vm._v(" "),
      _vm.accessCode && _vm.isClipboardSupported && _vm.sectionIsAvailable
        ? _c("i", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: "Copy direct access code link",
                expression: "'Copy direct access code link'",
              },
            ],
            ref: "accessCodeIcon" + _vm.section.id,
            staticClass: "fa fa-clipboard pointer color-dark-blue",
            staticStyle: { "font-size": "14px" },
            attrs: { "data-clipboard-text": _vm.directAccessLink },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.copySectionAccessCode.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.subsectionsExpanded
      ? _c(
          "div",
          [
            _vm._l(_vm.section.subsections, function (sect) {
              return _c("wiki-tree-section", {
                key: sect.id,
                attrs: {
                  section: sect,
                  accessCode: _vm.accessCode,
                  domain: _vm.domain,
                  inheritedPermissionLevel: _vm.resultPermissionLevel,
                  permissionsList: _vm.permissionsList,
                  allowAssignEditPermission: _vm.allowAssignEditPermission,
                },
              })
            }),
            _vm._v(" "),
            _vm._l(_vm.section.slides, function (slide) {
              return _c("wiki-tree-section-slide", {
                key: slide.id,
                attrs: {
                  slide: slide,
                  permissionsList: _vm.permissionsList,
                  inheritedPermissionLevel: _vm.resultPermissionLevel,
                },
              })
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }