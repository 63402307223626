const ScreenWidthAwareMixin = {
    data() {
        return {
            windowWidth: undefined,
            windowHeight: undefined,
        };
    },
    methods: {
        additionalSizeCalculations() {

        },
        debouncedUpdateWindowWidth() {
            this.windowWidth = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
            this.windowHeight = document.documentElement.clientHeight || document.body.clientHeight || window.innerHeight;
            this.additionalSizeCalculations();
        },
        updateWindowWidth() {
            this.debouncedUpdateWindowWidth.apply(this);
        }
    },
    mounted() {
        this.updateWindowWidth();
        window.addEventListener('resize', this.updateWindowWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateWindowWidth);
    }
};


export default ScreenWidthAwareMixin;