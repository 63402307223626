<template>
    <div class="timeline">
        <div class="timeline__scroll-box__container vue-timeline-component__noselect"
             :style="containerStyle" ref="container"
             @mousedown.stop.prevent="mousedownOnTimeline"
             @touchstart.stop.prevent="touchstartOnTimeline"
        >
            <div class="timeline__scroll-box__content" :style="contentStyle">
                <line-drawer ref="lineDrawer"></line-drawer>

                <calendar-page-day-timeline-hour v-for="hour in hours"
                                                 :key="hour"
                                                 :hour="hour"
                                                 :date="day.date"
                                                 :width="contentWidth/24"
                                                 :height="containerHeight"></calendar-page-day-timeline-hour>

                <template v-for="(eventsTier,i) in eventsTiers">
                    <calendar-page-day-timeline-event :tier="i"
                                                      :event="event"
                                                      :profile="profile"
                                                      v-for="(event,j) in eventsTier"
                                                      :index="i*eventsTiers.length+j+1"
                                                      :key="event.id+day.date"
                                                      :date="day.date"
                    ></calendar-page-day-timeline-event>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import CalendarPageDayTimelineHour from './CalendarPageDayTimelineHour.vue';
    import CalendarPageDayTimelineEvent from './CalendarPageDayTimelineEvent.vue';
    import MouseEventsMixin from "../../../../vue/mixins/MouseEventsMixin";
    import * as config from './config';
    import LineDrawer from '../../../../vue/common_components/LineDrawer.vue';
    import {eventsBus, EVENTS_BUS_EVENTS} from './eventsBus';
    import moment from 'moment';

    export default {
        mixins: [MouseEventsMixin],
        components: {CalendarPageDayTimelineHour, CalendarPageDayTimelineEvent, LineDrawer},
        props: {
            day: Object,
            width: Number,
            height: Number,
            eventsTiers: Array,
            profile: Object,
        },
        data() {
            return {
                offset: 0,
                hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],

                contentClicked: false,
                previousMousePositionX: 0,
            };
        },
        computed: {
            isToday() {
                return this.day.date === moment().tz('Europe/London').format(config.VISUAL_DATE_FORMAT);
            },
            containerWidth() {
                return this.width;
            },
            containerHeight() {
                return this.height;
            },
            containerStyle() {
                const style = {
                    width: `${this.containerWidth}px`,
                    height: `${this.containerHeight}px`,
                };
                if (this.contentClicked) {
                    style['cursor'] = 'grabbing';
                } else {
                    style['cursor'] = 'grab';
                }
                return style;
            },
            contentWidth() {
                return config.SIZES.TIMELINE_SIZE;
            },
            contentStyle() {
                return {
                    width: `${this.contentWidth}px`,
                    height: `${this.containerHeight}px`,
                };
            },
            maxOffset() {
                return this.contentWidth - this.containerWidth;
            },
        },
        methods: {
            processContentDragging(coords) {
                let coordsDifference = coords.x - this.previousMousePositionX;
                let offset = this.offset - coordsDifference;
                if (offset < 0) {
                    offset = 0;
                }
                if (offset > this.maxOffset) {
                    offset = this.maxOffset;
                }
                this.offset = offset;
                this.previousMousePositionX = coords.x;
            },
            mousedownOnTimeline(e) {
                this.contentClicked = true;
                this.previousMousePositionX = e.clientX;
            },
            touchstartOnTimeline(e) {
                if (e.targetTouches.length !== 1) {
                    return;
                }
                this.contentClicked = true;
                this.previousMousePositionX = e.targetTouches[0].clientX;
            },
            onPointerMove(coords) {
                if (this.contentClicked) {
                    this.processContentDragging(coords);
                }
            },
            onMouseUp() {
                this.contentClicked = false;
            },
            clearEventLines(date) {
                if (date === this.day.date) {
                    this.$refs['lineDrawer'].clearLines();
                }
            },
            drawEventLines(date, eventid, eventCoords) {
                if (date === this.day.date) {
                    this.$refs['lineDrawer'].clearLines();
                    this.$refs['lineDrawer'].setBorderStyle('dashed 2px #00b3ee');
                    this.$refs['lineDrawer'].setZIndex(15);
                    this.$refs['lineDrawer'].setOpacity(0);
                    const leftCorrection = -1;
                    const rightCorrection = 1;

                    const drawLine = this.$refs['lineDrawer'].drawLine;

                    if (eventCoords.required.left === eventCoords.real.left) {
                        drawLine(eventCoords.required.left + leftCorrection, 0, eventCoords.real.left + leftCorrection, eventCoords.real.bottom);
                    } else {
                        drawLine(eventCoords.required.left + leftCorrection, 0, eventCoords.required.left + leftCorrection, config.SIZES.TIMELINE_HEADER_HEIGHT); // top
                        drawLine(eventCoords.real.left + leftCorrection, eventCoords.real.top - 5, eventCoords.real.left + leftCorrection, eventCoords.real.bottom);
                        drawLine(eventCoords.real.left + leftCorrection, eventCoords.real.top - 5, eventCoords.required.left + leftCorrection, config.SIZES.TIMELINE_HEADER_HEIGHT);
                    }

                    if (eventCoords.required.right === eventCoords.real.right) {
                        drawLine(eventCoords.required.right + rightCorrection, 0, eventCoords.real.right + rightCorrection, eventCoords.real.bottom);
                    } else {
                        drawLine(eventCoords.required.right + rightCorrection, 0, eventCoords.required.right + rightCorrection, config.SIZES.TIMELINE_HEADER_HEIGHT);
                        drawLine(eventCoords.real.right + rightCorrection, eventCoords.real.top - 5, eventCoords.real.right + rightCorrection, eventCoords.real.bottom);
                        drawLine(eventCoords.required.right + rightCorrection, config.SIZES.TIMELINE_HEADER_HEIGHT, eventCoords.real.right + rightCorrection, eventCoords.real.top - 5);
                    }

                    this.$refs['lineDrawer'].setBorderStyle('solid 20px rgba(0, 179, 238, 0.2)');
                    drawLine(eventCoords.required.left + leftCorrection, 0, eventCoords.required.right + rightCorrection, 0);


                    setTimeout(() => {
                        this.$refs['lineDrawer'].setOpacity(1);
                    }, 200);

                }

            }
        },
        watch: {
            offset(n, o) {
                this.$refs.container.scrollLeft = n;
            },
        },
        mounted() {
            this.$nextTick(() => {
                // this.offset = (this.contentWidth - this.containerWidth) / 2;
                const hourWidth = (this.contentWidth / 24);
                const defaultOffset = hourWidth * 9;
                if (this.isToday) {
                    const defaultLeftBorder = defaultOffset;
                    const defaultRightBorder = defaultOffset + this.containerWidth;

                    const now = moment().tz('Europe/London');
                    const nowHours = now.hour();
                    const nowMinutes = new Date().getMinutes();
                    const nowArrowOffset = hourWidth * nowHours + (hourWidth / 60) * nowMinutes;

                    if (defaultLeftBorder > nowArrowOffset || defaultRightBorder < nowArrowOffset) {
                        const offsetToSet = nowArrowOffset - this.containerWidth / 2; // put offset to middle if possible
                        this.offset = Math.min(offsetToSet, this.maxOffset);
                    }else{
                        this.offset = defaultOffset;
                    }
                } else {
                    this.offset = defaultOffset;
                }

            });
            eventsBus.$on(EVENTS_BUS_EVENTS.EVENT_FOCUSED, this.drawEventLines);
            eventsBus.$on(EVENTS_BUS_EVENTS.EVENT_UNFOCUSED, this.clearEventLines);
        },
        beforeDestroy() {
            eventsBus.$off(EVENTS_BUS_EVENTS.EVENT_FOCUSED, this.drawEventLines);
            eventsBus.$off(EVENTS_BUS_EVENTS.EVENT_UNFOCUSED, this.clearEventLines);
        },
        name: "CalendarPageDayTimeline"
    };
</script>

<style scoped lang="scss">
    @import "../../../app/styles/const";
    @import "../../../app/styles/mixins";

    .timeline {
        &__scroll-box {
            &__container {
                width: 100%;
                position: relative;
                margin: auto;
                overflow-x: hidden;
                overflow-y: hidden;

            }

            &__content {
                position: relative;
            }
        }
    }
</style>