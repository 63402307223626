var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { transition: 0 },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          attrs: { id: "close_popup" },
          on: { click: _vm.close_popup },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [_vm._v("Delete Event")]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row", staticStyle: { "margin-bottom": "15px" } },
        [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("div", {}, [
              _c("p", { staticClass: "color-red" }, [
                _vm._v(
                  "\n                    NOTE: Deleting this Event will remove data such as Event details and comments etc... However,\n                    all user’s Diary entries and " +
                    _vm._s(_vm._f("customTerm")("Goals")) +
                    " linked to the Event will remain intact.\n                "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                    This is a repeated Event. You have three delete options:\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "margin-5-top" }, [
              _vm._v(
                "\n                1) Delete the whole chain of Events. Every occurrence of this Event past, present and future will be deleted.\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "margin-5-top" }, [
              _vm._v(
                "\n                2) Delete this date only. Repeat Events will occur as defined, but this exact event (on "
              ),
              _c("strong", [_vm._v(_vm._s(_vm.formattedDate))]),
              _vm._v(
                ") will be deleted.\n                Only data related to this particular event will be lost.\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "margin-5-top" }, [
              _vm._v(
                "\n                3) Finish event. Events will not repeat/will be deleted after this date.\n                Data on and before "
              ),
              _c("strong", [_vm._v(_vm._s(_vm.formattedDate))]),
              _vm._v(
                " will remain, data from Event occurrences after this date will be lost.\n            "
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "select-menu-container center" }, [
        _c("div", { staticClass: "select-menu-item" }, [
          _c("div", { staticClass: "panel panel-danger" }, [
            _c(
              "div",
              {
                staticClass: "panel-heading shortcuts",
                on: {
                  click: function ($event) {
                    return _vm.select("whole")
                  },
                },
              },
              [
                _c("h3", { staticClass: "panel-title" }, [
                  _vm._v("Every"),
                  _c("br"),
                  _vm._v("occurrence"),
                ]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "fa fa-repeat select-menu-item__main-icon",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "select-menu-item" }, [
          _c("div", { staticClass: "panel panel-primary" }, [
            _c(
              "div",
              {
                staticClass: "panel-heading shortcuts",
                on: {
                  click: function ($event) {
                    return _vm.select("exact")
                  },
                },
              },
              [
                _c("h3", { staticClass: "panel-title" }, [
                  _vm._v("Delete"),
                  _c("br"),
                  _vm._v(_vm._s(_vm.formattedDate)),
                ]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "fa fa-calendar-o select-menu-item__main-icon",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "select-menu-item" }, [
          _c("div", { staticClass: "panel panel-primary" }, [
            _c(
              "div",
              {
                staticClass: "panel-heading shortcuts",
                on: {
                  click: function ($event) {
                    return _vm.select("since")
                  },
                },
              },
              [
                _c("h3", { staticClass: "panel-title" }, [
                  _vm._v("Finish"),
                  _c("br"),
                  _vm._v("Event"),
                ]),
                _vm._v(" "),
                _c("i", {
                  staticClass:
                    "fa fa-flag-checkered select-menu-item__main-icon",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }