var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "media-folders-list__container",
      class: { "blocked-for-deletion": _vm.blocked },
    },
    [
      _vm.fakePreviousFolder
        ? _c("users-media-page-folder", {
            key: _vm.fakePreviousFolder.id,
            attrs: {
              folder: _vm.fakePreviousFolder,
              currentlyDraggedMedia: _vm.currentlyDraggedMedia,
              currentlyUnderDragFolder: _vm.currentlyUnderDragFolder,
            },
            on: {
              resetCurrentlyUnderDragFolder: _vm.resetCurrentlyUnderDragFolder,
              setCurrentlyUnderDragFolder: _vm.setCurrentlyUnderDragFolder,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.goBack.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.foldersToShow, function (folder) {
        return _c("users-media-page-folder", {
          key: folder.id,
          attrs: {
            folder: folder,
            editMode: _vm.editMode,
            currentUser: _vm.currentUser,
            currentlyDraggedMedia: _vm.currentlyDraggedMedia,
            currentlyUnderDragFolder: _vm.currentlyUnderDragFolder,
          },
          on: {
            resetCurrentlyUnderDragFolder: _vm.resetCurrentlyUnderDragFolder,
            setCurrentlyUnderDragFolder: _vm.setCurrentlyUnderDragFolder,
            hideFolder: function ($event) {
              return _vm.hideFolder(folder)
            },
          },
          nativeOn: {
            click: function ($event) {
              return _vm.selectFolder(folder)
            },
          },
        })
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hiddenFoldersCount,
              expression: "hiddenFoldersCount",
            },
          ],
        },
        [
          _c(
            "strong",
            { staticClass: "pointer", on: { click: _vm.showMoreFolders } },
            [
              _vm._v("Show "),
              _c("strong", [_vm._v(_vm._s(_vm.hiddenFoldersCount))]),
              _vm._v(" more..."),
            ]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }