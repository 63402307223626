<template>
    <div class="table-responsive">
        <table class="table table-bordered">
            <thead>
            <tr>
                <th>Shared with</th>
                <th>Role</th>
                <th>Date Invited</th>
                <th>Notify me</th>
                <th>Is Admin</th>
                <th>Edit Access</th>
                <th>Remove Access</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="member in members">
                <members-table-member
                        :member="member"
                        :owner="owner"
                        :key="member.id"
                        @openPermissionsTree="openPermissionsTree"
                        @onDelete="onMemberDelete"
                        :isOwner="member.userprofile&&member.userprofile.id===owner.id"
                ></members-table-member>
                <tr v-if="member.tree_opened">
                    <td colspan="7">
                        <media-tree :member="member" :key="member.id" :owner="owner"></media-tree>
                    </td>
                </tr>
            </template>
            </tbody>
        </table>
    </div>
</template>

<script>
    import MembersTableMember from './MembersTableMember.vue';
    import Vue from 'vue';
    import MediaTree from './MediaTree.vue';

    export default {
        name: "MembersTable",
        components: {
            MembersTableMember, MediaTree
        },
        props: {
            members: {
                type: Array
            },
            owner: {
                type: Object
            }
        },
        methods: {
            openPermissionsTree(member) {
                if (member.is_admin) {
                    return;
                }
                for (let i_member of this.members) {
                    if (i_member.tree_opened && i_member.id !== member.id) {
                        i_member.tree_opened = false;
                    }
                }
                Vue.set(member, 'tree_opened', !member.tree_opened);
            },
            onMemberDelete(member) {
                this.members.splice(this.members.indexOf(member), 1);
            }
        }
    };
</script>

<style scoped>

</style>