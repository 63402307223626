<template>
    <div>
        <community-page-about-us-story ></community-page-about-us-story>
    </div>
</template>

<script>
    import Vue from 'vue';
    import CommunityPageAboutUsWiki from './CommunityPageAboutUsWiki.vue';
    import CommunityPageAboutUsStory from './CommunityPageAboutUsStory.vue';
    import {getMainWikiPageName} from "../wiki_page/WikiChildrenPages";
    import {COMMUNITY_WIKI_ROUTER_PREFIX} from "./config";

    export default {
        name: "CommunityPageAboutUs",
        components: {CommunityPageAboutUsStory, CommunityPageAboutUsWiki},
        data() {
            return {};
        },
        computed: {
            aboutPageIsWiki() {
                return this.$store.getters['communityPage/aboutPageIsWiki'];
            },
            wikiId() {
                return this.$store.getters['communityPage/communityWikiId'];
            },
            community() {
                return this.$store.getters['communityPage/community'];
            },
            communityWikiSubsectionsDisabled() {
                return this.$store.getters['communityPage/communityWikiSubsectionsDisabled'];
            },

        },
        methods: {},
        created() {
        },
        beforeMount() {
            if (this.aboutPageIsWiki) {
                this.$router.replace({
                    name: getMainWikiPageName(COMMUNITY_WIKI_ROUTER_PREFIX)
                });
            }
        }
    };
</script>

<style scoped>

</style>