var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.header)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {},
        [
          _vm.warning
            ? _c("span", [
                _vm._v(
                  " You can't add more files here. (max: " +
                    _vm._s(_vm.max_count) +
                    ")"
                ),
              ])
            : _vm._e(),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _vm._l(_vm.files, function (item, index) {
            return _c("div", [
              _c("span", [_vm._v(_vm._s(item.file.name))]),
              _vm._v(" ("),
              _c(
                "a",
                {
                  attrs: { href: "javascript:" },
                  on: {
                    click: function ($event) {
                      return _vm.removeFromQueue(index)
                    },
                  },
                },
                [_vm._v("remove")]
              ),
              _vm._v(")\n            "),
              _c(
                "div",
                [
                  _c("progress-bar", {
                    attrs: {
                      type: item.uploaded ? "success" : undefined,
                      striped: "",
                      active: item.uploading,
                    },
                    model: {
                      value: item.progress,
                      callback: function ($$v) {
                        _vm.$set(item, "progress", $$v)
                      },
                      expression: "item.progress",
                    },
                  }),
                ],
                1
              ),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-5 text-left" },
            [
              _vm.allow_save_to_folders
                ? _c("vue-switch-with-label", {
                    attrs: {
                      "collapse-on-mobile": false,
                      name: "save_to_media",
                      label: "Save to My Media",
                    },
                    model: {
                      value: _vm.save_to_library,
                      callback: function ($$v) {
                        _vm.save_to_library = $$v
                      },
                      expression: "save_to_library",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-7 text-right" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-mm btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.cancel()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                            Cancel\n                        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-mm btn-green",
                    attrs: { type: "button", disabled: _vm.upload_started },
                    on: {
                      click: function ($event) {
                        return _vm.start_upload()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                            Upload\n                        "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }