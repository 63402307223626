CommunityStoriesResource.$inject = ['$http'];
import urls from 'DjangoUrls';

let {DjangoUrls} = urls;

function CommunityStoriesResource($http) {
    const PREFIX = `api.v1:community_stories-`;
    return {
        get_stories,
        create_story,
        update_story,
        get_story,
        publish_story,
        unpublish_story,
        delete_story,
    };


    function get_stories(community_id, params) {
        const url = DjangoUrls[`${PREFIX}get-stories`](community_id);
        return $http({
            method: 'GET',
            url,
            params
        });
    }

    function get_story(community_id, slug) {
        const url = DjangoUrls[`${PREFIX}get-story`](community_id);
        return $http({
            method: 'GET',
            url,
            params: {slug}
        });
    }

    function create_story(community_id, data) {
        const url = DjangoUrls[`${PREFIX}create-story`](community_id);
        return $http({
            method: 'POST',
            url,
            data
        });
    }

    function update_story(community_id, story_id, data) {
        const url = DjangoUrls[`${PREFIX}update-story`](community_id);
        return $http({
            method: 'POST',
            url,
            data: Object.assign({id: story_id}, data)
        });
    }

    function delete_story(community_id, story_id) {
        const url = DjangoUrls[`${PREFIX}delete-story`](community_id);
        return $http({
            method: 'PATCH',
            url,
            data: {id: story_id}
        });
    }

    function publish_story(community_id, story_id) {
        const url = DjangoUrls[`${PREFIX}publish-story`](community_id);
        return $http({
            method: 'PATCH',
            url,
            data: {id: story_id}
        });
    }

    function unpublish_story(community_id, story_id) {
        const url = DjangoUrls[`${PREFIX}unpublish-story`](community_id);
        return $http({
            method: 'PATCH',
            url,
            data: {id: story_id}
        });
    }
}

export default CommunityStoriesResource;