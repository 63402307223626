<template>
    <basic-modal
            @hide="close"
            v-model="opened"
            :aria-label="header"
            aria-describedby="simple-popup-content"
            :size="size"
            role="dialog">
        <div slot="header">
            <a id="close_popup"
               ref="closeButton"
               @click="close"
               @keydown.enter="close"
               @keydown.tab.shift.stop.prevent="focusToClose"
               role="button"
               aria-label="Close dialog"
               tabindex="0"></a>
            <h3 class="modal-title">{{ header }}</h3>
        </div>
        <div class="row">
            <div class="col-sm-12" v-html="message" style="font-size: 16px;" id="simple-popup-content"></div>
        </div>
        <div slot="footer">
            <div class="row">
                <div class="col-sm-12">
                    <button
                            ref="buttonNo"
                            v-if="buttonNo"
                            class="btn btn-mm  btn-primary"
                            type="button"
                            @click="no"
                    >{{ buttonNo }}
                    </button>
                    <button
                            ref="buttonYes"
                            v-if="buttonYes"
                            class="btn btn-mm"
                            :class="buttonYesClass"
                            type="button"
                            @click="ok"
                    >{{ buttonYes }}
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <span tabindex="0" aria-hidden="true" class="focus-catch-link" @focus="focusToClose">&nbsp;</span>
                </div>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import BasicModal from "../../../../vue/common_components/BasicModal";
    import PopupPromiseMixin from "../../../../vue/mixins/PopupPromiseMixin";

    export default {
        name: "SimplePopup",
        components: {BasicModal},
        mixins: [PopupPromiseMixin],
        data() {
            return {
                size: undefined,

                header: '',
                message: '',
                buttonYes: '',
                buttonNo: '',
                buttonYesClass: 'btn-green',
            };
        },
        computed: {
            firstFocusEl() {
                return this.$refs.buttonYes || this.$refs.buttonNo || this.$refs.closeButton;
            }
        },
        methods: {
            setInitial(header, message, buttonYes, buttonNo, buttonYesClass = 'btn-green', size = undefined) {
                this.header = header;
                this.message = message;
                this.buttonYes = buttonYes;
                this.buttonNo = buttonNo;
                this.buttonYesClass = buttonYesClass;
                this.size = size;
            },
            ok() {
                this.save();
            },
            no() {
                this.close();
            }
        }
    };
</script>