var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "wiki-detail-page-slide-description",
      style: _vm.descriptionStyle,
    },
    [
      _c(
        "div",
        { staticClass: "wiki-detail-page-slide-description__icons-panel" },
        [
          _c("div", [
            _c("div", [
              _vm.editMode
                ? _c("i", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: "Edit Description",
                        expression: "'Edit Description'",
                      },
                    ],
                    staticClass:
                      "far fa-pencil wiki-detail-page-slide-description__icon vertical-middle",
                    staticStyle: { "font-size": "27px" },
                    on: { click: _vm.editDescription },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.audioDescription
              ? _c(
                  "div",
                  {},
                  [
                    _c("audio-toggle", {
                      ref: "audioDescription",
                      staticClass:
                        "wiki-detail-page-slide-description__icons-panel__audio-toggle",
                      staticStyle: {
                        "min-width": "27px",
                        display: "inline-block",
                      },
                      attrs: {
                        "audio-caption": _vm.audioDescription,
                        "fa-class": "far",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", {}, [
              _vm.descriptionIsLarge
                ? _c("i", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value:
                          "This slide contains text best viewed in a pop up, click here to see all the text",
                        expression:
                          "'This slide contains text best viewed in a pop up, click here to see all the text'",
                      },
                    ],
                    staticClass:
                      "far fa-comment-alt-exclamation color-red wiki-detail-page-slide-description__icon vertical-middle",
                    staticStyle: {
                      "font-size": "29px",
                      position: "relative",
                      top: "-6px",
                    },
                    on: { click: _vm.showDescriptionInPopup },
                  })
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-center", on: { click: _vm.editDescription } },
        [
          _c(
            "div",
            {
              staticClass:
                "wiki-detail-page-slide-description__inner-container text-left hidden-scroll",
              style: _vm.innerDescriptionStyle,
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "inline-block wiki-detail-page-slide-description__caption__container",
                },
                [
                  _c("span", {
                    staticClass:
                      "wiki-detail-page-slide-description__caption vertical-middle",
                    domProps: { innerHTML: _vm._s(_vm.descriptionText) },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }