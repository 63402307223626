<template>
    <div class="main-container" :style="containerSizeOffsetStyle"

    >
        <div class="main-container__image" :style="[imageStyle]" @click="onCreate">
            <i class="fa fa-plus"></i>
        </div>
    </div>
</template>

<script>
    import WikiSectionMixin from './WikiSectionMixin';

    export default {
        name: "create-section",
        mixins: [WikiSectionMixin],
        computed: {
            _reducedCircleSize() {
                return Math.max(parseInt(this._circleSize / 3), 30);
            },
            imageStyle() {
                let resultStyle = {
                    width: `${this._reducedCircleSize}px`,
                    height: `${this._reducedCircleSize}px`,
                };

                if (this.isTextOnSide) {
                    if (this.side === 'left') {
                        resultStyle['float'] = 'right';
                        resultStyle['margin-right'] = `${(this._circleSize / 2) - this._reducedCircleSize / 2}px`;
                    }
                    if (this.side === 'right') {
                        resultStyle['float'] = 'left';
                        resultStyle['margin-left'] = `${(this._circleSize / 2) - this._reducedCircleSize / 2}px`;
                    }
                    resultStyle['margin-top'] = `${(this.height - this._reducedCircleSize) / 2}px`;


                } else {
                    resultStyle['margin'] = `auto`;
                    resultStyle['margin-top'] = `${this._circleSize / 2 - this._reducedCircleSize / 2}px`;
                }
                resultStyle['line-height'] = `${this._reducedCircleSize}px`;
                resultStyle['font-size'] = `${this._reducedCircleSize - 2}px`;
                return resultStyle;
            },
        },
        methods: {
            onCreate() {
                this.$emit('onCreate');
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../styles/const.scss";

    .main-container {
        &__image {
            display: inline-block;
            background-color: $grass-green;
            border-radius: 50%;
            color: whitesmoke;
            font-weight: bolder;
            text-align: center;
            cursor: pointer;
            transition: box-shadow 0.2s ease-in-out;
            &:hover {
                box-shadow: 0 0 25px $grass-green;

            }
        }
    }

</style>