<template>
    <modal v-model="opened" :transition='0' :backdrop="false">
        <div slot="header">
            <a id="close_popup" @click="closePopup"></a>
            <h3 class="modal-title">Event recurrence Info</h3>
        </div>

        <div class="row" v-if="formattedPatterns.length">
            <div class="col-sm-12">
                <span class="recurrence-popup__header">Recurrence:</span>
            </div>
        </div>
        <div class="row" v-if="formattedPatterns.length">
            <div class="col-sm-12">
                <div class="">
                    <div class="margin-5-bottom" v-for="formattedPattern in formattedPatterns">
                        <strong>{{formattedPattern.date}}</strong>
                        <span>{{formattedPattern.text}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="formattedDeletedPeriods.length">
            <div class="col-sm-12">
                <span class="recurrence-popup__header">Canceled dates:</span>
            </div>
        </div>
        <div class="row" v-if="formattedDeletedPeriods.length">
            <div class="col-sm-12">
                <div>
                    <strong>{{formattedDeletedPeriods.join(', ')}}</strong>
                </div>
            </div>
        </div>

        <div class="row" v-if="formattedHolidays.length">
            <div class="col-sm-12">
                <span class="recurrence-popup__header">Holidays:</span>
            </div>
        </div>
        <div class="row" v-if="formattedHolidays.length">
            <div class="col-sm-12">
                <div>
                    <span v-for="holidaysItem in holidays">
                        <strong>{{holidaysItem.start}} - {{holidaysItem.end}}</strong><span v-if="holidaysItem.name" class="color-black"> ({{holidaysItem.name}})</span>,
                    </span>
                </div>
            </div>
        </div>

        <div slot="footer">
            <button class="btn btn-mm" @click="closePopup">OK</button>
        </div>
    </modal>
</template>

<script>
    import Vue from 'vue';
    import {VISUAL_DATE_FORMAT} from "../new_calendar/config";
    import {getPeriodTextRepresentation} from "../new_calendar/common";

    export default {
        name: "EventRecurrenceInfoPopup",
        data() {
            return {
                opened: false,
                eventId: undefined,
                calendarOwnerId: undefined,
                dateMoment: undefined,

                patterns: [],
                deleted_periods: [],
                holidays: [],
            };
        },
        computed: {
            formattedPatterns() {
                let res = [];
                for (let rawPattern of this.patterns) {
                    let formattedPattern = {};
                    formattedPattern.date = `${rawPattern.period_start_date} - ${rawPattern.period_end_date ? rawPattern.period_end_date : '...'}`;
                    formattedPattern.text = `${getPeriodTextRepresentation(rawPattern.period_start_date, rawPattern.repeat_type, rawPattern.days_of_week, rawPattern.skip_repeat_period)}`;
                    res.push(formattedPattern);
                }
                return res;
            },
            formattedDeletedPeriods() {
                let res = [];
                for (let deletedPeriod of this.deleted_periods) {
                    if (deletedPeriod.deleted_period_start === deletedPeriod.deleted_period_end) {
                        res.push(deletedPeriod.deleted_period_start);
                    } else {
                        res.push(`${deletedPeriod.deleted_period_start} - ${deletedPeriod.deleted_period_end}`);
                    }
                }
                return res;
            },
            formattedHolidays() {
                let res = [];
                for (let holidaysItem of this.holidays) {
                    let h = `${holidaysItem.start} - ${holidaysItem.end}`;
                    if (holidaysItem.name) {
                        res.push(`${holidaysItem.name} (${h})`);

                    } else {
                        res.push(h);
                    }
                }
                return res;
            },

        },
        methods: {
            loadData() {
                const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                CalendarEventsResource.get_event_recurrence_info(this.eventId, this.calendarOwnerId, this.dateMoment.format(VISUAL_DATE_FORMAT))
                    .then(resp => {
                            this.opened = true;
                            this.patterns = resp.data.patterns;
                            this.deleted_periods = resp.data.deleted;
                            this.holidays = resp.data.holidays;
                        },
                        err => {
                            Vue.notifications.error(err || 'Error');
                        })
                    .finally($rootScope.hide_dimmer);
            },
            show(eventId, calendarOwnerId, dateMoment) {
                this.eventId = eventId;
                this.calendarOwnerId = calendarOwnerId;
                this.dateMoment = dateMoment;
                this.loadData();
                return new Promise((res, rej) => {
                    this.resolve = res;
                    this.reject = rej;
                });
            },
            closePopup() {
                this.opened = false;
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../styles/const";

    .recurrence-popup {
        &__header {
            font-size: 16px;
            padding: 10px 0;
            font-style: italic;
            font-weight: bold;
            color: $tag-gray;
        }
    }
</style>