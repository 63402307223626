import symbolPasswordPopup from './symbolPasswordPopup.vue';
import {simplePopupFactory} from '../simple_popups/simplePopupFactory';

const picturePasswordFactory = {
    input_password(conf) {
        return new Promise((resolve, reject) => {
            const {fEl, popupComponent, vm} = simplePopupFactory.mount_vue_popup(symbolPasswordPopup);
            popupComponent.show(conf)
                .then(result => {
                    let s = result.map(s => s.value).join('');
                    if (result.length === 3) { // old weird bug!
                        s += 'undefined';
                    }
                    resolve(s);
                }, () => {
                    reject();
                })
                .finally(() => {
                    vm?.$destroy();
                    fEl?.focus();
                });
        });
    },
    inputPicturePassword(conf) {
        return new Promise((resolve, reject) => {
            const {fEl, popupComponent, vm} = simplePopupFactory.mount_vue_popup(symbolPasswordPopup);
            popupComponent.show(conf)
                .then(result => {
                    resolve(result);
                }, () => {
                    reject();
                })
                .finally(() => {
                    vm?.$destroy();
                    fEl?.focus();
                });
        });
    }
};

export default picturePasswordFactory;