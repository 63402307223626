var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "community-page-alternative-header",
        {
          attrs: {
            "scale-by-icons-size": "",
            "icons-count": 1 + _vm.viewerIsAdmin,
          },
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _c(
              "span",
              [
                _c("span", { staticClass: "vertical-middle" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("cutName")(_vm.story ? _vm.story.name : "", 40)
                    )
                  ),
                ]),
                _vm._v(" "),
                _vm.story && _vm.story.audio_caption
                  ? _c("audio-toggle", {
                      staticClass: "vertical-middle",
                      attrs: { "audio-caption": _vm.story.audio_caption },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticStyle: {
                  "font-size": "14px",
                  display: "block",
                  "text-decoration": "none",
                  "line-height": "18px",
                  "font-weight": "normal",
                  "font-style": "normal",
                  position: "relative",
                  top: "5px",
                },
              },
              [
                _c("span", { staticClass: "inline-block" }, [
                  _vm._v(_vm._s(_vm.story ? _vm.story.created_at : "")),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.viewerIsAdmin
            ? _c("vue-folder-button", {
                attrs: { "icon-class": "fa-gears" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.editStory.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.backUrl
            ? _c(
                "span",
                [
                  _c(
                    "router-link",
                    {
                      staticStyle: { "text-decoration": "none" },
                      attrs: { to: { name: "community-stories" } },
                    },
                    [
                      _c("vue-folder-button", {
                        attrs: { "icon-class": "fa-reply" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "a",
                {
                  staticStyle: { "text-decoration": "none" },
                  attrs: { href: _vm.backUrl },
                },
                [
                  _c("vue-folder-button", {
                    attrs: { "icon-class": "fa-reply" },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.notFound
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("\n        Story not found\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.story && !_vm.story.published && _vm.viewerIsAdmin
        ? _c("div", { staticClass: "alert alert-info" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-mm btn-green",
                    on: { click: _vm.publishStory },
                  },
                  [_vm._v("Publish")]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.story && _vm.story.published && _vm.viewerIsAdmin
        ? _c("div", { staticClass: "alert alert-success" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-mm btn-green",
                    on: { click: _vm.unpublishStory },
                  },
                  [_vm._v("Hide")]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.initialized
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-12 text-right" },
              [
                _vm.viewerIsAdmin
                  ? _c("vue-switch-with-label", {
                      attrs: { label: "Edit Mode", name: "edit_mode" },
                      model: {
                        value: _vm.editMode,
                        callback: function ($$v) {
                          _vm.editMode = $$v
                        },
                        expression: "editMode",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.initialized
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c(
                  "story-list",
                  {
                    attrs: {
                      "target-id": _vm.story.id,
                      "story-resource": _vm.storyResource,
                      "allow-add-content": _vm.viewerIsAdmin,
                      "allow-bulk-create": true,
                      "edit-mode": _vm.editMode,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "alert alert-info",
                        attrs: { slot: "empty" },
                        slot: "empty",
                      },
                      [_vm._v("There has been nothing added to this story yet")]
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("create-story-popup", { ref: "create-popup" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("strong", [_vm._v("Attention.")]),
        _vm._v("\n                This Story is "),
        _c("strong", [_vm._v("not")]),
        _vm._v(" published, so only Community admins could see it. Press "),
        _c("strong", [_vm._v("Publish")]),
        _vm._v(
          "\n                to make story visible for all users including unauthenticated.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("strong", [_vm._v("Attention.")]),
        _vm._v(
          "\n                This Story is currently published, so everyone including unauthenticated users have access to it.\n                Press "
        ),
        _c("strong", [_vm._v("Hide")]),
        _vm._v(
          "\n                to make story visible for Community admins only.\n            "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }