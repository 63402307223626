import EventDetailPageInfo from './EventDetailPageInfo.vue';
import EventDetailPageMembers from './EventDetailPageMembers.vue';
import EventDetailPageGoals from './EventDetailPageGoals.vue';
import EventDetailPageComments from './EventDetailPageComments.vue';
import EventDetailErrorDeleted from './EventDetailErrorDeleted.vue';
import EventDetailErrorNoAccess from './EventDetailErrorNoAccess.vue';

const EVENT_DETAIL_PAGE_CHILDREN = [
    {name: 'info', path: 'info', component: EventDetailPageInfo, props: true, meta: {name: 'info'}},
    {name: 'members', path: 'members', component: EventDetailPageMembers, props: true},
    {name: 'goals', path: 'goals', component: EventDetailPageGoals, props: true},
    {name: 'comments', path: 'comments', component: EventDetailPageComments, props: true},
    {name: 'event_no_access_page', path: 'access-restricted', component: EventDetailErrorNoAccess, props: true},
    {name: 'event_does_not_occur', path: 'empty', component: EventDetailErrorDeleted, props: true},
    {path: '', redirect: 'info', props: true},
];
export default EVENT_DETAIL_PAGE_CHILDREN;