<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 aria-label="Read Receipts"
                 role="dialog">
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">Read Receipts</h3>
        </div>
        <div class="receipt__content-container">


            <table class="table table-striped">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Sent</th>
                    <th>Link</th>
                    <th>State</th>
                    <th>Completed</th>
                </tr>
                </thead>
                <tbody>
                <template
                        v-for="receipt in receipts"

                >
                    <tr :key="'m'+receipt.id"
                        :class="{'receipt__row_active': (activeReceipt === receipt)&&activeReceiptDetails}">
                        <td>
                            <span
                                    class="pointer"
                                    tabindex="0"
                                    role="button"
                                    aria-label="Show Receipt Details"
                                    @click="showReceiptDetails(receipt)"
                            >{{ receipt.name }}</span>
                        </td>
                        <td>{{ formatTime(receipt.sent_at) }}</td>
                        <td>
                            <i
                                    class="far fa-copy pointer"
                                    v-tooltip="'Copy to Clipboard'"
                                    :data-clipboard-text="getFullReceiptLink(receipt)"
                                    ref="membersReceiptLink"
                            ></i>
                        </td>
                        <td>
                            <i class="far fa-times color-footer-red" v-if="receipt.rejected_at"></i>
                            <i class="far fa-check color-grass-green" v-else-if="receipt.completed_at"></i>
                            <i class="far fa-ellipsis-h color-blue" v-else></i>
                        </td>
                        <td>{{ formatTime(receipt.completed_at || receipt.rejected_at) }}</td>
                    </tr>
                    <tr v-if="(activeReceipt === receipt)&&activeReceiptDetails" :key="'d'+receipt.id">
                        <td colspan="4">
                            <wiki-read-receipt-details
                                    :receipt="activeReceiptDetails"
                            ></wiki-read-receipt-details>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
        <div slot="footer" class="row">
            <div class="col-sm-12 text-right">
                <button class="btn btn-mm btn-green" @click="close">OK</button>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import PopupPromiseMixin from "../../../../vue/mixins/PopupPromiseMixin";
    import moment from "moment";
    import wiki_read_receipts_multiple_resource from "../wiki_settings_page/wiki_read_receipts_multiple_resource";
    import WikiReadReceiptDetails from "./WikiReadReceiptDetails";
    import Clipboard from "clipboard";
    import Vue from "vue";

    export default {
        name: "ReadReceiptsListPopup",
        components: {WikiReadReceiptDetails},
        mixins: [PopupPromiseMixin],
        data() {
            return {
                receipts: [],
                activeReceipt: undefined,
                activeReceiptDetails: undefined,
            };
        },
        methods: {
            formatTime(time) {
                if (time) {
                    return moment.tz(time, 'Europe/London').local().format('DD/MM/YY HH:mm');
                }
                return '-';
            },
            setInitial(receipts) {
                this.receipts = receipts;
            },
            async showReceiptDetails(receipt) {
                if (this.activeReceipt === receipt) {
                    this.activeReceipt = undefined;
                    this.activeReceiptDetails = undefined;
                    return;
                }
                this.activeReceipt = receipt;
                this.$loadingSpinner.show();
                try {
                    const {data} = await wiki_read_receipts_multiple_resource.get_receipt(receipt.wiki_id, receipt.id);
                    this.activeReceiptDetails = data;
                } catch (e) {
                    this.$notifications.error(e);
                } finally {
                    this.$loadingSpinner.hide();
                }
            },
            bindCopyLinks() {
                let clipboardIcons = this.$refs.membersReceiptLink;
                for (let clipboardIcon of clipboardIcons) {
                    let clipboard = new Clipboard(clipboardIcon);
                    clipboard.on('success', e => {
                        Vue.notifications.success('Copied');
                    });

                    clipboard.on('error', e => {
                        Vue.notifications.error('You browser doesn\'t support auto copy.');
                    });
                }
            },
            getFullReceiptLink(receipt) {
                return window.$site + receipt.link;
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.bindCopyLinks();
            });
        }
    };
</script>

<style scoped lang="scss">
  .receipt {
    &__row {
      &_active {
        font-weight: bold;
      }
    }

    &__content-container {
      max-height: 65vh;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
</style>