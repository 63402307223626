<template>
    <div class="wiki-tree-slide-attachment">
        <i class="fa fa-eye" :class="viewButtonClasses" @click="toggleLinkAttachmentLevelPermission"></i>
        <i class="fa fa-link vertical-middle" aria-hidden="true"></i>
        <a :href="link.url" target="_blank">
            <span class="vertical-middle">{{ link.name | cutName(22) }}</span>
        </a>
    </div>
</template>

<script>
    import wikiMemberPermissionBus from './wikiMemberPermissionBus';
    import editViewButtonsClassesMixin from './editViewButtonsClassesMixin';
    import {
        WIKI_PERMISSION_LEVEL,
        WIKI_PERMISSION_SIGNAL,
        WIKI_PERMISSION_VALUE
    } from "../../wiki_page/common/wikiPermissions";
    export default {
        name: "wiki-tree-section-slide-attached-link",
        mixins: [editViewButtonsClassesMixin],
        props: {
            link: {
                type: Object
            },
            permissionsList: {
                type: Array
            },
            inheritedPermissionLevel: {
                type: String,
                required: false,
                default: WIKI_PERMISSION_VALUE.UNSET
            }
        },
        computed: {
            ownPermissionLevel() {
                const ownPermissionIndex = _.findIndex(this.permissionsList, p => (p.level === WIKI_PERMISSION_LEVEL.LINK && p.target_id === this.link.id));
                if (ownPermissionIndex !== -1) {
                    return this.permissionsList[ownPermissionIndex].value;
                }
                return WIKI_PERMISSION_VALUE.UNSET;
            },
            resultPermissionLevel() {
                if (this.ownPermissionLevel === WIKI_PERMISSION_VALUE.UNSET) {
                    return this.inheritedPermissionLevel;
                } else {
                    return this.ownPermissionLevel;
                }
            },
            canOwnEditPermissionBeChanged() {
                return false;
            },
            canOwnViewPermissionBeChanged() {
                return this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.VIEW;
            }
        },
        methods:{
            toggleLinkAttachmentLevelPermission() {
                if (!this.canOwnViewPermissionBeChanged) {
                    return;
                }
                if (this.ownPermissionLevel === WIKI_PERMISSION_VALUE.UNSET) {
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.UNSET) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.LINK, WIKI_PERMISSION_VALUE.VIEW, this.link.id);
                    }
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.VIEW) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.LINK, WIKI_PERMISSION_VALUE.NONE, this.link.id);
                    }
                }
                if (this.ownPermissionLevel === WIKI_PERMISSION_VALUE.NONE) {
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.UNSET) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.LINK, WIKI_PERMISSION_VALUE.UNSET, this.link.id);
                    }
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.VIEW) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.LINK, WIKI_PERMISSION_VALUE.UNSET, this.link.id);
                    }
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    .wiki-tree-slide-attachment {
        margin: 20px 0;
        .fa-eye {
            &.disabled {
                opacity: (0.5);
            }
        }
        .fa-pencil {
            &.disabled {
                opacity: (0.5);
            }

        }
        .fa {
            vertical-align: middle;
            font-size: 25px;
            margin: 0 4px;
        }
    }
</style>