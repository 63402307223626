var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { transition: 0, "append-to-body": true, backdrop: false },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          attrs: { id: "close_popup" },
          on: { click: _vm.closePopup },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.header))]),
      ]),
      _vm._v(" "),
      _vm._l(_vm.results, function (note) {
        return _c(
          "event-detail-page-info-entry-list-show-all-popup-note-preview",
          { key: note.id, attrs: { note: note } }
        )
      }),
      _vm._v(" "),
      !_vm.allLoaded
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c("div", { staticClass: "col-xs-12 text-center" }, [
              _c(
                "span",
                { staticClass: "pointer", on: { click: _vm.loadMore } },
                [
                  _vm._v("Show "),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.totalCount - _vm.results.length)),
                  ]),
                  _vm._v(" More..."),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          { staticClass: "btn btn-mm", on: { click: _vm.closePopup } },
          [_vm._v("Close")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }