<template>
    <selector-popup
            ref="popup"
            :header="header"
            :items="items">
        <template slot="footer">
            <button @click="back" type="button" class="btn btn-mm  btn-primary">Back</button>
        </template>
    </selector-popup>
</template>

<script>
    import SelectorPopup from "~/vue/common_components/SelectorPopup/SelectorPopup.vue";

    export default {
        name: "MediaShareWhereListPopup",
        components: {SelectorPopup},
        data() {
            return {
                header: '',
                shareState: {},
                allowShareToCommunity: false,
            };
        },
        computed: {
            items() {
                const items = [
                    {
                        label: 'Keep Private',
                        color: 'red',
                        iconClass: 'fa-hand-paper-o',
                        action: 'select',
                        selection: 'private',
                        checkMark: !this.shareState.friends?.shared && !this.shareState.friends?.has_unapproved && !this.shareState?.groups?.shared && !this.shareState?.groups?.has_unapproved && !this.shareState?.communities?.shared && !this.shareState?.communities?.has_unapproved,
                        checkMarkYellow: this.shareState.has_redefined_in_children,
                    },
                    {
                        label: 'My Circle',
                        color: 'yellow',
                        customIcon: 'circle',
                        action: 'select',
                        selection: 'circle',
                        checkMark: this.shareState.friends?.shared || this.shareState.friends?.has_unapproved,
                        checkMarkYellow: this.shareState.friends?.has_unapproved,
                    },
                    {
                        label: 'My Groups',
                        color: 'lightblue',
                        customIcon: 'groups',
                        action: 'select',
                        selection: 'groups',
                        checkMark: this.shareState.groups?.shared || this.shareState.groups?.has_unapproved,
                        checkMarkYellow: this.shareState.groups?.has_unapproved,
                    }];
                if (this.allowShareToCommunity) {
                    items.push({
                        label: 'My<br>Communities',
                        color: 'green',
                        customIcon: 'communities',
                        action: 'select',
                        selection: 'communities',
                        checkMark: this.shareState.communities?.shared || this.shareState.communities?.has_unapproved,
                        checkMarkYellow: this.shareState.communities?.has_unapproved,
                    });
                }
                return items;
            }
        },
        methods: {
            show(header, shareState, allowShareToCommunity) {
                this.header = header;
                this.shareState = shareState;
                this.allowShareToCommunity = allowShareToCommunity;
                return new Promise((resolve, reject) => {
                    this.reject = reject;
                    this.resolve = resolve;
                    this.$refs.popup.show()
                        .then(item => {
                            resolve(item.selection);
                        }, reason => {
                            reject(reason);
                        });
                });
            },
            back() {
                this.reject('back');
            }
        }
    }
</script>

