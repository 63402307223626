/**
 * Created by orion on 12.06.17.
 */

import angular from 'angular';
import 'angular-ui-bootstrap'

import simplePopups from '../../shared/simple_popups/simple_popup_module';
import notifications from '../../shared/notifications/notifications_module';
// import infiniteScroll from '../shared/infiniteScroll_directive'
import selectFactory from '../../shared/select_factory/selectFactory_module'

//app modules
import add_media from '../../add_media/add_media_module'
import view_media from '../../view_media/view_media_module'

import attachmentFactory from './attachmentFactory'


angular.module('mmApp.attachments', ['ui.bootstrap', simplePopups, notifications, selectFactory, add_media, view_media])
    .factory('attachmentFactory', attachmentFactory)

;

export default 'mmApp.attachments'