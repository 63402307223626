<template>
    <div class="media-folders-folder inline-block" @click="clickOnFolder" :style="[dragStyle]">
        <img :src="folder.owner.avatar" v-if="isShared" width="20" class="img-circle vertical-middle" alt="">
        <div class="media-folders-folder__icon inline-block vertical-middle">
            <i class="fa fa-folder-o"></i>
        </div>
        <div v-if="!folder.isFake" class="media-folders-folder__name inline-block vertical-middle">{{folder.name |
            cutName(25)}}
        </div>
        <div v-if="folder.isFake" class="media-folders-folder__name inline-block vertical-middle"><i
                class="fa fa-level-up"></i></div>

        <div v-if="isShared&&editMode"
             class="media-folders-folder__name inline-block vertical-middle color-red"
             @click.stop.prevent="hideFolder"
        ><i class="fa fa-eye-slash hide-folder-icon"></i></div>
    </div>
</template>

<script>
    import MouseEventsMixin
        from '../../../../../vue/mixins/MouseEventsMixin.js';
    import FolderDragAspectMixin from './drag_aspect/FolderDragAspectMixin.js';

    export default {
        mixins: [MouseEventsMixin, FolderDragAspectMixin],
        props: {
            folder: Object,
            currentUser: Object,
            editMode: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            isShared() {
                return this.folder.owner && (!this.currentUser || (this.folder.owner.id !== this.currentUser.id));
            }
        },
        methods: {
            clickOnFolder() {

            },
            hideFolder() {
                this.$emit('hideFolder');
            }
        },
        name: "UsersMediaPageFolder"
    };
</script>

<style scoped lang="scss">
    @import "../../../styles/const";

    .hide-folder-icon {
        font-size: 20px;
        line-height: 20px;
        margin-left: 5px;
        margin-right: 5px
    }

    .media-folders-folder {
        background-color: white;
        border-radius: 5px;
        padding: 5px;
        border: solid 2px $blue;
        cursor: pointer;
        margin-left: 10px;
        margin-bottom: 5px;

        &__icon {
            color: $blue;
            font-size: 18px;
        }

        &__name {
            font-size: 18px;
        }
    }
</style>