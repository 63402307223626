<template>
    <div class="event-linked-entry-preview">
        <div class="event-linked-entry-preview__text">
            <div class="" v-if="notePreview.text">
                <i class="fal fa-quote-left"></i>
                {{notePreview.text|cutName(180)}}
                <i class="fal fa-quote-right"></i>
            </div>
            <div class="event-linked-entry-preview__text__author">
                In <strong>{{notePreview.logName}}</strong> Log by <strong v-if="notePreview.author.link">
                <simple-link :target="notePreview.author" style="color: #333;"></simple-link></strong>
                <strong v-else>{{notePreview.author.name}}</strong> on {{formattedDate}}.
                <strong><a :href="note.link|backHref">Open Details</a></strong>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import {VISUAL_DATE_FORMAT, URL_DATE_FORMAT} from "../new_calendar/config";

    export default {
        name: "EventDetailPageInfoEntryListShowAllPopupNotePreview",
        props: {
            note: Object
        },
        computed: {
            formattedDate() {
                if (!this.note || !this.note.date) return;
                return moment(this.note.date, URL_DATE_FORMAT).format(VISUAL_DATE_FORMAT);
            },
            notePreview() {
                if (!this.note) return;
                const res = {
                    text: this.note.answers?.[0],
                    link: this.note.link,
                    author: {name: this.note.member_name},
                    logName: this.note.location_name,
                };
                if (this.note.author.userprofile && !this.note.author.userprofile.deleted) {
                    res.author.link = this.note.author.userprofile.link;
                }
                return res;
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../styles/const";
    .event-linked-entry-preview {
        &:not(:last-child){
            border-bottom: 1px solid $lightgray;
        }
        font-size: 0;
        padding-bottom: 5px;
        margin-bottom: 5px;
        a{
            color: #333;
        }

        &__text {
            vertical-align: top;
            font-size: 14px;
            padding-left: 5px;
            font-style: italic;

            &__author {
                font-style: normal;
                margin-top: 5px;
            }
        }
    }
</style>