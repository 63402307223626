<template>
    <modal v-model="opened" :transition='0' :append-to-body="true" :backdrop="false">
        <div slot="header">
            <a id="close_popup" @click="closePopup"></a>
            <h3 class="modal-title">{{ header }}</h3>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <form class="form">
                    <span>Start Date:</span>
                    <datepicker v-model="taskStartDate"
                                :disabled-dates="disabledDatesStart"
                                @keydown.native.delete="startDateClear"
                                @keydown.native.backspace="startDateClear"
                                :format="customFormatter"
                                input-class="create-event-date-picker"
                                :bootstrap-styling="true"
                                :typeable="false"
                                :highlighted="highlightedDates"
                                ref="start-date-picker"
                    >
                        <div slot="afterDateInput" class="input-group-btn"
                             @click.stop.prevent="toggleStartDateCalendar">
                            <btn class="dropdown-toggle create-event-date-picker"><i
                                    class="glyphicon glyphicon-calendar"></i></btn>
                        </div>
                    </datepicker>
                </form>
                <hr class="hr10">
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <form class="form">
                    <span>End Date:</span>
                    <datepicker v-model="taskEndDate"
                                :disabled-dates="disabledDatesEnd"
                                @keydown.native.delete="endDateClear"
                                @keydown.native.backspace="endDateClear"
                                :format="customFormatter"
                                input-class="create-event-date-picker"
                                :bootstrap-styling="true"
                                :typeable="false"
                                :highlighted="highlightedDates"
                                ref="end-date-picker"
                    >
                        <div slot="afterDateInput" class="input-group-btn"
                             @click.stop.prevent="toggleEndDateCalendar">
                            <btn class="dropdown-toggle create-event-date-picker"><i
                                    class="glyphicon glyphicon-calendar"></i></btn>
                        </div>
                    </datepicker>
                </form>
                <hr class="hr10">
            </div>
        </div>
        <div class="row" v-if="relatedToEvent">
            <div class="col-sm-12">
                <hr class="hr10">
            </div>
        </div>
        <div class="row" v-if="allowCreateSteps">
            <div class="col-sm-12">
                <vue-switch-with-label v-model="createSteps" name="create-steps"
                                       label="Create Sub-steps"></vue-switch-with-label>
                <hr class="hr10">
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <vue-switch-with-label v-model="autoComplete" name="auto-complete" :disabled="timeOptionsDisabled"
                                       :label="autoCompleteLabel"></vue-switch-with-label>
                <hr class="hr10">
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <vue-switch-with-label v-model="notifyWhenDue" name="notify-due" :disabled="timeOptionsDisabled"
                                       label="Notify assigned user when finished"></vue-switch-with-label>
            </div>
        </div>
        <div slot="footer">
            <button class="btn btn-mm" @click="closePopup">{{ cancelButton }}</button>
            <button class="btn btn-mm btn-green save-button"
                    @click="save">
                {{ saveButton }}
            </button>
        </div>
        <event-detail-page-period-selector-popup-with-another-date-option
                ref="period-selector"></event-detail-page-period-selector-popup-with-another-date-option>
        <event-find-occurrence-popup :allow-multiple="true" ref="find-occurrence-popup"></event-find-occurrence-popup>
    </modal>
</template>

<script>
    import Vue from 'vue';
    import Datepicker from 'vuejs-datepicker';
    import moment from 'moment';
    import {TIME_ZONE, URL_DATE_FORMAT, VISUAL_DATE_FORMAT} from "../new_calendar/config";
    import EventDetailPagePeriodSelectorPopup from "./EventDetailPagePeriodSelectorPopup.vue";
    import EventDetailPagePeriodSelectorPopupWithAnotherDateOption
        from "./EventDetailPagePeriodSelectorPopupWithAnotherDateOption.vue";
    import EventFindOccurrencePopup from './EventFindOccurrencePopup.vue';

    export default {
        name: "EventTaskDatesPopup",
        components: {
            Datepicker,
            EventDetailPagePeriodSelectorPopupWithAnotherDateOption,
            EventDetailPagePeriodSelectorPopup,
            EventFindOccurrencePopup,
        },
        mixins: [],
        data() {
            return {
                taskStartDate: undefined,
                taskEndDate: undefined,
                autoComplete: false,
                notifyWhenDue: false,
                linkToEvent: false,
                linkToEventMode: undefined,
                linkToEventDates: [],
                linkToEventWasChanged: false,
                createSteps: false,

                allowCreateSteps: false,

                isPeriodic: false,
                eventStartDate: undefined,
                eventEndDate: undefined,

                opened: false,

                header: `${this.$customTerm('Task')} Schedule`,
                saveButton: 'Save',
                cancelButton: 'Cancel',

                occurrencesLoading: false,
                relatedToEvent: false,
                eventData: undefined,
            };
        },
        computed: {
            autoCompleteLabel() {
                return `Auto complete ${this.$customTerm('Task')} when finished`;
            },
            highlightedDates() {
                return {
                    dates: [moment.tz(TIME_ZONE).toDate()],
                    includeDisabled: true,
                };
            },
            startDateMoment() {
                if (!this.taskStartDate) {
                    return;
                }
                return moment(this.taskStartDate);
            },
            endDateMoment() {
                if (!this.taskStartDate) {
                    return;
                }
                return moment(this.taskEndDate);
            },
            disabledDatesStart() {
                if (this.taskEndDate) {
                    return {
                        from: this.taskEndDate
                    };
                }
            },
            disabledDatesEnd() {
                if (this.taskStartDate) {
                    return {to: this.taskStartDate};
                }
            },
            timeOptionsDisabled() {
                return this.taskEndDate === undefined;
            },
            linkedOccurrencesLabel() {
                if (!this.isPeriodic || !this.linkToEvent || this.linkToEventMode !== 'exact') {
                    return;
                }
                const thisOccurrenceIsLinked = this.linkToEventDates.includes(this.eventData.startDate);
                if (thisOccurrenceIsLinked) {
                    if (this.linkToEventDates.length === 1) {
                        return `${this.$customTerm('Task')} is linked to this Event occurrence (<strong>${this.eventData.startDate}</strong>).`;
                    } else {
                        return `${this.$customTerm('Task')} is linked to this Event occurrence (<strong>${this.eventData.startDate}</strong>) and <strong>${this.linkToEventDates.length - 1}</strong> other occurrence${((this.linkToEventDates.length - 1) > 1) ? 's' : ''}.`;
                    }
                } else {
                    return `${this.$customTerm('Task')} is linked to <strong>${this.linkToEventDates.length}</strong> occurrence${this.linkToEventDates.length > 1 ? 's' : ''} of this Event.`;
                }
            }
        },
        methods: {
            startDateClear() {
                this.taskStartDate = undefined;
            },
            endDateClear() {
                this.taskEndDate = undefined;
            },
            editLinkedOccurrences() {
                this.opened = false;
                this.$refs['find-occurrence-popup'].show(this.eventData.calendarOwnerId, this.eventData.eventId, moment(this.eventStartDate), this.linkToEventDates)
                    .then(dates => {
                        if (!_.isEqual(this.linkToEventDates, dates)) {
                            this.linkToEventDates = dates;
                            this.linkToEventWasChanged = true;
                        }
                        this.opened = true;
                    }, cancel => {
                        this.opened = true;
                    });
            },
            askAboutLinkingPeriod() {
                this.opened = false;
                return this.$refs['period-selector'].show(
                    moment(this.eventStartDate),
                    'Link Event',
                    'This is a repeated Event. You can link every occurrence of this Event or only one date',
                    this.eventData.eventId,
                    this.eventData.calendarOwnerId,
                    this.eventData.addedToCalendar
                )
                    .then(res => {
                        if (res.result === 'whole') {
                            this.linkToEventMode = 'whole';
                        }
                        if (res.result === 'exact') {
                            this.linkToEventMode = 'exact';
                            this.linkToEventDates = [moment(this.eventStartDate).format(VISUAL_DATE_FORMAT)];
                        }
                        if (res.result === 'another') {
                            this.linkToEventMode = 'exact';
                            this.linkToEventDates = res.dates;
                        }
                        this.opened = true;
                    }, cl => {
                        this.linkToEvent = false;
                        this.opened = true;
                    });
            },
            resetData() {
                this.taskStartDate = undefined;
                this.taskEndDate = undefined;
                this.autoComplete = false;
                this.notifyWhenDue = false;
                this.linkToEvent = false;
                this.linkToEventMode = undefined;
                this.linkToEventDates = [];
                this.linkToEventWasChanged = false;
                this.createSteps = false;
            },
            customFormatter(date) {
                return moment(date).format(VISUAL_DATE_FORMAT);
            },
            loadInfoAboutLinkedOccurrences() {
                this.occurrencesLoading = true;
                const CalendarEventTaskLinksResource = Vue.getAngularModule('CalendarEventTaskLinksResource');
                CalendarEventTaskLinksResource.load_info_about_linked_occurrences(this.eventData.calendarOwnerId, this.eventData.eventId, this.eventData.startDate, this.task.id)
                    .then(resp => {
                        this.linkToEventMode = resp.data.target_event_is_linked ? 'whole' : 'exact';
                        this.linkToEventDates = resp.data.linked_occurrences_dates;
                        this.occurrencesLoading = false;
                    });
            },
            show(eventData = undefined, task, popupConfig = {
                header: `${this.$customTerm('Task')} Schedule`,
                save_button: 'Save',
                cancel_button: 'Cancel',
            }) {
                this.header = popupConfig.header;
                this.saveButton = popupConfig.save_button;
                this.cancelButton = popupConfig.cancel_button;
                this.allowCreateSteps = popupConfig.allow_create_steps;

                //----
                this.resetData();
                this.task = task;
                if (eventData) {
                    this.eventData = eventData;
                    this.isPeriodic = eventData.isPeriodic;
                    if (eventData.startDate) {
                        this.eventStartDate = moment(eventData.startDate, VISUAL_DATE_FORMAT).toDate();
                    }
                    if (eventData.endDate) {
                        this.eventEndDate = moment(eventData.endDate, VISUAL_DATE_FORMAT).toDate();
                    }
                    if (this.isPeriodic && task.link_info && task.link_info.any_occurrences_linked) {
                        this.linkToEvent = true;
                        this.loadInfoAboutLinkedOccurrences();
                    }
                    this.relatedToEvent = true;
                } else {
                    this.relatedToEvent = false;
                }


                if (task.start_date) {
                    this.taskStartDate = moment(task.start_date, URL_DATE_FORMAT).toDate();
                }
                if (task.end_date) {
                    this.taskEndDate = moment(task.end_date, URL_DATE_FORMAT).toDate();
                }
                if (task.notify_when_due) {
                    this.notifyWhenDue = true;
                }
                if (task.auto_complete_when_due) {
                    this.autoComplete = true;
                }

                const self = this;
                this.opened = true;
                return new Promise((resolve, reject) => {
                    self.resolve = resolve;
                    self.reject = reject;
                });
            },
            save() {
                this.resolve({
                    start_date: this.taskStartDate ? this.startDateMoment.format(URL_DATE_FORMAT) : undefined,
                    end_date: this.taskEndDate ? this.endDateMoment.format(URL_DATE_FORMAT) : undefined,
                    notify_when_due: this.notifyWhenDue,
                    auto_complete_when_due: this.autoComplete,
                    createSteps: this.createSteps,
                    linkToEvent: {
                        isLinked: this.linkToEvent,
                        mode: this.linkToEventMode,
                        dates: this.linkToEventDates,
                        wasChanged: this.linkToEventWasChanged,  // detect
                    }
                });
                this.opened = false;
                this.resetData();
            },
            closePopup() {
                this.opened = false;
                this.resetData();
            },

            toggleStartDateCalendar() {
                this.$refs['start-date-picker'].showCalendar();
            },
            toggleEndDateCalendar() {
                this.$refs['end-date-picker'].showCalendar();
            },
            toggleLinkToEvent(n) {
                this.linkToEventWasChanged = true;
                if (n) {
                    if (this.isPeriodic) {
                        this.askAboutLinkingPeriod()
                            .then(r => {
                                if (this.linkToEventMode === 'exact') {
                                    if (!this.taskStartDate) {
                                        this.taskStartDate = new Date(Math.min(...this.linkToEventDates.map(d => moment(d, VISUAL_DATE_FORMAT).toDate())));
                                    }
                                    if (!this.taskEndDate) {
                                        this.taskEndDate = new Date(Math.max(...this.linkToEventDates.map(d => moment(d, VISUAL_DATE_FORMAT).toDate())));
                                    }
                                } else {
                                    if (!this.taskStartDate) {
                                        this.taskStartDate = moment(this.eventData.startDate, VISUAL_DATE_FORMAT).toDate();
                                    }
                                }
                            });
                    } else {
                        this.linkToEventMode = 'whole';
                        this.linkToEventDates = [moment(this.eventStartDate).format(VISUAL_DATE_FORMAT)];

                        if (this.eventStartDate && this.eventEndDate) {
                            if (!this.taskStartDate) {
                                this.taskStartDate = this.eventStartDate;
                            }
                            if (!this.taskEndDate) {
                                this.taskEndDate = this.eventEndDate;
                            }
                        }
                    }
                } else {
                    this.linkToEventMode = undefined;
                    this.linkToEventDates = [];
                }
            }
        },
        watch: {}
    };
</script>

<style scoped>

</style>