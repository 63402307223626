var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "table-member__valid-label",
        class: {
          "table-member__valid-label_error":
            _vm.accessLinkValidityState.expired,
          "table-member__valid-label_warning":
            _vm.accessLinkValidityState.expiring,
          "table-member__valid-label_success": !(
            _vm.accessLinkValidityState.expired ||
            _vm.accessLinkValidityState.expiring
          ),
        },
      },
      [
        _c("i", [_vm._v("Valid until:")]),
        _vm._v(" "),
        _vm.member.access_code_valid_till
          ? _c("span", [
              _vm._v(
                _vm._s(_vm._f("gbdate")(_vm.member.access_code_valid_till))
              ),
            ])
          : _c("span", [_vm._v("forever")]),
        _vm._v(" "),
        _c("i", {
          staticClass:
            "fa fa-redo pointer color-dark-blue table-member__action-icon",
          attrs: {
            tabindex: "0",
            role: "button",
            "aria-label": "update access link",
          },
          on: { click: _vm.updateAccessLinkTerm },
        }),
      ]
    ),
    _vm._v(" "),
    _c("div", [
      _c("a", { attrs: { href: _vm.member.access_link, target: "_blank" } }, [
        _vm._v("access link"),
      ]),
      _vm._v(" "),
      _c("i", {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: "Copy link to clipboard",
            expression: "'Copy link to clipboard'",
          },
        ],
        ref: "membersAccessLink",
        staticClass:
          "fa fa-clipboard pointer color-dark-blue table-member__action-icon",
        attrs: {
          "data-clipboard-text": _vm.member.access_link,
          tabindex: "0",
          role: "button",
          "aria-label": "Copy link to clipboard",
        },
      }),
    ]),
    _vm._v(" "),
    !_vm.member.access_code_password
      ? _c("div", [
          _c("br"),
          _vm._v(" "),
          _c(
            "strong",
            {
              staticClass: "pointer",
              on: { click: _vm.protectAccessLinkByPassword },
            },
            [_vm._v("Protect by password")]
          ),
          _vm._v(" "),
          _c("i", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: "Enable password protection",
                expression: "'Enable password protection'",
              },
            ],
            staticClass:
              "fa fa-key-skeleton pointer color-dark-blue table-member__action-icon",
            attrs: {
              tabindex: "0",
              role: "button",
              "aria-label": "Enable password protection",
            },
            on: { click: _vm.protectAccessLinkByPassword },
          }),
          _c("br"),
          _vm._v(" "),
          _c("i", [
            _vm._v(
              "Password protection will be applied automatically on 08/05/23"
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.member.access_code_password
      ? _c("div", [
          _vm._m(0),
          _vm._v(" "),
          _c("i", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: "Copy password to clipboard",
                expression: "'Copy password to clipboard'",
              },
            ],
            ref: "membersAccessPassword",
            staticClass:
              "fa fa-clipboard pointer color-dark-blue table-member__action-icon",
            attrs: {
              "data-clipboard-text": _vm.member.access_code_password,
              tabindex: "0",
              role: "button",
              "aria-label": "Copy password to clipboard",
            },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", [_vm._v("access link password")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }