var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "center-block vue-inline-audio" }, [
    _c("span", { staticClass: "align-helper" }),
    _vm._v(" "),
    _c(
      "span",
      {
        staticClass: "vertical-middle vue-inline-audio__main-part inline-block",
      },
      [
        !_vm.audioSrc
          ? _c("span", [_vm._v("This Audio is not ready yet...")])
          : _vm._e(),
        _vm._v(" "),
        _vm.audioSrc
          ? _c(
              "audio",
              {
                ref: "audio",
                staticClass: "inline_audio_container hidden-print",
                attrs: {
                  controls: "",
                  autoplay: _vm.autoplay,
                  preload: "metadata",
                  controlsList: "nodownload",
                },
              },
              [
                _c("source", {
                  ref: "audioSource",
                  attrs: { src: _vm.audioSrc, type: "audio/mpeg" },
                }),
                _vm._v(
                  "\n            Your browser does not support the audio tag.\n        "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("span", { staticClass: "visible-print" }, [
          _c("i", { staticClass: "fa fa-volume-up fa-2" }),
          _vm._v(" " + _vm._s(_vm.item.name)),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }