var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "hotkey",
          rawName: "v-hotkey",
          value: _vm.keymap,
          expression: "keymap",
        },
      ],
    },
    [
      _vm.wikiEditor
        ? _c(
            "alert",
            {
              staticClass: "margin-15-top margin-15-bottom",
              attrs: { type: "info" },
            },
            [
              _vm._v("Editing session is being conducted by "),
              _c("b", [_vm._v(_vm._s(_vm.wikiEditor.name))]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("wiki-main-menu", {
        attrs: {
          isTopSection: true,
          mainSectionText: _vm.mainSectionText,
          mainSectionAvatar: _vm.mainSectionAvatar,
          pentagramMode: _vm.pentagramMode,
          mainSectionAudioCaption: _vm.mainSectionAudioCaption,
          sections: _vm.sections,
          containerHeight: _vm.containerHeight,
          containerWidth: _vm.containerWidth,
          editMode: _vm.editMode,
          allowCreate: _vm.allowCreate,
          allowDrag: _vm.allowDrag,
          "active-section-number": _vm.activeSectionNumber,
        },
        on: {
          onSectionCreate: _vm.onSectionCreate,
          onSectionDelete: _vm.onSectionDelete,
          onSectionEdit: _vm.onSectionEdit,
          onSwitchSections: _vm.onSwitchSections,
          onSectionOpen: _vm.onSectionOpen,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }