import angular from 'angular';

// mainFilters
import main_filters from '../shared/filters/filters_module';
import search_input from '../shared/inputs/search_input/search_input_module';
import preload from '../preload/preload';
//shared modules

import simplePopups from '../shared/simple_popups/simple_popup_module';
import notifications from '../shared/notifications/notifications_module';
import infiniteScroll from '../shared/infiniteScroll_directive';


//app modules
import add_media from '../add_media/add_media_module';
import view_media from '../view_media/view_media_module';


//pages
import UserActivityPostController from './user_activity_post/UserActivityPostController';
import UserActivityPostCommentsController from './user_activity_post/UserActivityPostComments';

//resources
import activityPostResourceFactory from '../shared/activity_post/activityPostResourceFactory';
import likesResourceFactory from '../shared/common/likesResourceFactory';

//directives
import renderActivityPost from './renderActivity/render_activity_post_directive/directive';

//factories
import SiteActivityResource from './activity_resource';

// activity with controllers
import LikeStoryResourceFactory from "../shared/story/resource_factory";
import CommentsInfiniteScrollResourceFactory from "../shared/comments/resource_infinite_scroll_factory";


angular.module('mmApp.activity',
    ['ui.bootstrap',
        preload, renderActivityPost, simplePopups, notifications, add_media, view_media, main_filters, search_input, infiniteScroll])
    .controller('UserActivityPostController', UserActivityPostController)
    .controller('UserActivityPostCommentsController', UserActivityPostCommentsController)
    .factory('SiteActivityResource', SiteActivityResource)
    .factory('UserActivityPostManageResource', activityPostResourceFactory('user_activity_post_manage'))  // TODO: Remove excessive endpoints
    .factory('UserActivityPostStoryResource', LikeStoryResourceFactory('user_activity_post_story'))
    .factory('UserActivityPostLikesResource', likesResourceFactory('user_activity_post_likes'))
    .factory('UserActivityPostCommentsResource', CommentsInfiniteScrollResourceFactory('user_activity_post_comments'))

;


export default 'mmApp.activity';