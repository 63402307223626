import {DjangoUrls} from 'DjangoUrls';
import axios from "axios";

regValidationResource.$inject = ['$http',];

function regValidationResource($http) {
    return {
        username(username) {
            const url = DjangoUrls[`api.v1:reg_validation-username`]();
            return axios({
                method: 'GET',
                url,
                params: {username}
            });
        },

        email(email) {
            const url = DjangoUrls[`api.v1:reg_validation-email`]();
            return axios({
                method: 'GET',
                url,
                params: {email}
            });
        },
        password(password) {
            const url = DjangoUrls[`api.v1:reg_validation-password`]();
            return axios({
                method: 'POST',
                url,
                data: {password}
            });
        },
    };
}

export default regValidationResource;