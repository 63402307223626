import {DjangoUrls} from 'DjangoUrls';

FriendRequestResource.$inject = ['$http'];

function FriendRequestResource($http) {
    return {
        get_common_groups(request_id, params = {}) {
            const url = DjangoUrls["api.v1:friend_request-get-common-groups"](request_id);
            return $http({
                method: 'GET',
                url,
                params
            });
        }
    };
}

export default FriendRequestResource;