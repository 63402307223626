<template>
    <div class="render-activity-post-component">
        <div v-if="isOneAttachmentPost">
            <render-post :post="simplePseudoPost" max-media-height="60vh"></render-post>
        </div>
        <div v-else>
            <div class="row" v-if="activityPost.sticker">
                <div class="col-sm-12 text-center">
                    <render-sticker :sticker="activityPost.sticker"></render-sticker>
                </div>
            </div>
            <div class="row" v-if="activityPost.text">
                <div class="col-sm-12">
                    <div class="render-activity-post-component__text-container">
                        <p v-if="!activityPost.is_header"
                           style="text-align: left; font-size: 18px; white-space: pre-line;"
                           v-html="linkedText">
                        </p>
                        <p v-if="activityPost.is_header" v-html="linkedText"
                           style="font-size: 24px; white-space: pre-line;">
                        </p>
                    </div>
                </div>
            </div>
            <div class="render-activity-post-component__image-container pointer"
                 v-if="activityPost.preview_url||notDisplayedItemsCount"
                 @click="showMediaItems"
                 @keydown.enter="showMediaItems"
                 tabindex="0"
                 role="button"
            >
                <img class="img-responsive" style="max-height: 500px; margin: auto"
                     alt="Entry Preview."
                     :src="activityPost.preview_url"/>
                <span v-if="activityPost.preview_url&&notDisplayedItemsCount"
                      :aria-label="`${notDisplayedItemsCount} more images`"
                      class="render-activity-post-component__media-counter">+{{ notDisplayedItemsCount }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Autolinker from "autolinker";
    import RenderPost from "shared/render_post/RenderPost";
    import RenderSticker from "../../../add_media/stickers/render_sticker_directive/RenderSticker";

    export default {
        name: "renderActivityPost",
        components: {RenderSticker, RenderPost},
        props: {
            activityPost: Object,
        },
        computed: {
            isOneAttachmentPost() {
                return this.activityPost?.items.length === 1 && this.activityPost.items[0]?.media_attachment;
            },
            simplePseudoPost() {
                if (!this.isOneAttachmentPost) {
                    return;
                }
                return Object.assign({}, this.activityPost.items[0], this.activityPost);
            },
            notDisplayedItemsCount() {
                return this.activityPost?.items.length > 1 ? this.activityPost.items.length - 1 : 0;
            },
            linkedText() {
                if (this.activityPost.text) {
                    return Autolinker.link(this.activityPost.text, {truncate: 40});
                }
                return '';
            },
        },
        methods: {
            showMediaItems() {
                const viewMediaFactory = Vue.getAngularModule('viewMediaFactory');
                // const pureItems = this.activityPost.items.map(i => Object.assign(i.media_attachment, {
                //     description: i.text,
                //     is_header: i.is_header,
                //     sticker: i.sticker
                // }));
                viewMediaFactory.show_posts(this.activityPost.items);
            }
        }
    };
</script>

<style scoped lang="scss">
  @import "../../../styles/const";

  .render-activity-post-component {
    &__text-container {
      border: solid 1px $lightgray;
      padding: 10px;
      margin: 5px;
      border-radius: 5px;
      font-size: 18px;
      text-align: justify;
      word-break: break-word;

      span, h3 {
        white-space: pre-line;
      }
    }

    &__media-counter {
      width: 100px;
      height: 100px;
      text-align: center;
      display: block;
      position: absolute;
      top: 45%;
      top: calc(50% - 50px);
      left: 47%;
      left: calc(50% - 50px);
      margin: auto;
      font-size: 60px;
      font-weight: bolder;
      color: $red;
    }

    &__image-container {
      text-align: center;
      position: relative;
      margin-bottom: 10px;

      img {
        border-radius: 5px;
      }
    }
  }
</style>