<template>
    <a :href="link" class="dashboard-list-item hvr-float-shadow">
        <div class="dashboard-list-item__image" :style="imageStyle" :class="{'enlarged':enlarged}"></div>
        <div class="dashboard-list-item__label">{{label}}</div>
    </a>
</template>

<script>
    export default {
        name: "dashboard-list-item",
        props: {
            label: {
                type: String,
            },
            image: {
                type: String
            },
            whiteImage: {
                type: String
            },
            link: {
                type: String
            },
            enlarged: {
                type: Boolean,
                default: false
            },
            mainColor: {
                type: String,
            },
            backgroundSize: {
                type: String,
                default: 'contain'
            },
            backgroundSizeWhite: {
                type: String,
                default: 'contain'
            },
            backgroundPosition: {
                type: String,
                default: 'bottom'
            },
            white: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            imageStyle() {
                if (this.white) {
                    return {
                        'background-image': `url('${this.whiteImage}')`,
                        'background-size': this.backgroundSizeWhite,
                        'background-position': 'center',
                        'background-color': this.mainColor,
                        'border-radius': '10px'
                    };
                } else {
                    return {
                        'background-image': `url('${this.image}')`,
                        'background-size': this.backgroundSize,
                        'background-position': this.backgroundPosition,
                    };
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    .dashboard-list-item {
        vertical-align: top;
        @media (max-width: 600px) {
            display: inline-block;
        }
        @media (min-width: 601px) {
            display: block;
        }

        position: relative;
        cursor: pointer;
        text-decoration: none;
        color: #333;
        @media (min-width: 1000px) {
            margin-left: 40px;
            margin-right: 40px;
            margin-top: 50px;
        }
        @media (min-width: 842px) and (max-width: 999px) {
            margin-left: 50px;
            margin-right: 50px;
            margin-top: 50px;
        }
        @media (min-width: 601px) and (max-width: 841px) {
            margin-left: 30px;
            margin-right: 30px;
            margin-top: 40px;
        }
        @media (min-width: 560px) and (max-width: 600px) {
            margin-left: 30px;
            margin-right: 30px;
            margin-top: 30px;
        }
        @media (min-width: 410px) and (max-width: 559px) {
            margin-left: 30px;
            margin-right: 30px;
            margin-top: 30px;
        }
        @media (min-width: 320px) and (max-width: 409px) {
            margin-left: 5px;
            margin-right: 5px;
            margin-top: 30px;
        }

        &__image {
            margin: auto;
            width: 128px;
            height: 128px;
            @media (min-width: 1000px) {
                &.enlarged {
                    width: 240px;
                    height: 230px;
                }
            }
            @media (min-width: 700px) and (max-width: 999px) {
                &.enlarged {
                    width: 170px;
                    height: 165px;
                }
            }
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        &__label {
            text-align: center;
            font-size: 17px;
            font-weight: bolder;
        }
    }
</style>