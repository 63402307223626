var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "task-steps__task",
      class: {
        completed: _vm.step.completed,
        small: _vm.small,
        "not-achieved": _vm.isCompletedButNotAchieved,
      },
      style: _vm.stepStyle,
    },
    [
      _c("i", {
        ref: "complete-checkbox-desktop",
        staticClass: "far fa-check-square task-steps__task__checkbox",
        class: { pointer: _vm.completable },
        on: { click: _vm.onCompleteCheckboxClicked },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "task-steps__task__name" }, [
        _vm._v(
          _vm._s(_vm.index ? _vm.index + ". " : "") +
            _vm._s(_vm._f("cutName")(_vm.step.name, 450))
        ),
      ]),
      _vm._v(" "),
      _c("goal-task-complete-dropdown", { ref: "complete-dropdown" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }