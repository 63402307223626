import urls from 'DjangoUrls';
import StorySettingsOrDeletePopup
    from "~/angular/app/stories/story_settings_or_delete_popup/StorySettingsOrDeletePopup.vue";

let {DjangoUrls} = urls;
storyDetailController.$inject = ['$scope', '$q', '$location', 'notifications', '$rootScope',
    '$preloaded', '$log', '$templateCache', 'backUrlGenerator', 'shareFactory', 'CurrentUser',
    'simplePopupFactory', 'StoryResource', 'StoryMembersResource'];

function storyDetailController($scope, $q, $location, notifications, $rootScope,
                               $preloaded, $log, $templateCache, backUrlGenerator, shareFactory, CurrentUser,
                               simplePopupFactory, StoryResource, StoryMembersResource) {
    let self = this;
    self.go_back = backUrlGenerator.go_back;

    $templateCache.put('storyAbout.html', require('./storyAbout/storyAbout.html'));
    $templateCache.put('storyComments.html', require('./storyComments/storyComments.html'));
    $templateCache.put('storyMembers.html', require('./storyMembers/storyMembers.html'));
    // $templateCache.put('eventSettings.html', require('./eventSettings/eventSettings.html'));

    let templates = {
        '/members/': 'storyMembers.html',
        // '/settings': 'eventSettings.html',
        '/about/': 'storyAbout.html',
        '/comments/': 'storyComments.html',
    };

    self.check_t = function (n) {
        self.active_template = templates[n];
        $location.path(`${n}`);
    };

    function init() {
        self.story = $preloaded.story;
        self.is_member = $preloaded.is_member;
        self.is_admin = $preloaded.is_admin;
        self.has_access = $preloaded.has_access;
        self.owner = $preloaded.owner;
        self.is_join_request_pending = $preloaded.is_join_request_pending;
        self.is_activity_post_story = $preloaded.is_activity_post_story;
        if (!self.has_access) {
            return;
        }
        if ($location.path() && templates[$location.path()] !== undefined) {
            self.active_template = templates[$location.path()];
        } else {
            self.active_template = templates['/about/'];
        }
    }

    init();

    $scope.$on('user_unbecome_admin', (e) => {
        self.is_admin = false;
        $preloaded.is_admin = false;
    });

    $scope.$on('user_deleted_himself', function (e) {
        let back_url = backUrlGenerator.get_from_url();
        if (!back_url) {
            back_url = DjangoUrls['stories_my_stories']();
        }
        $rootScope.show_dimmer();
        document.location.href = back_url;
    });

    self.can_join = function () {
        return CurrentUser.is_guardian_of(self.owner.id);
    };

    self.send_join_request = function () {

    };

    //
    self.can_make_myself_admin = function () {
        return !self.is_admin && CurrentUser.is_guardian_of(self.owner.id);
    };
    //
    self.make_myself_admin = function () {
        $rootScope.show_dimmer();
        StoryMembersResource.make_me_admin(self.story.id)
            .then(resp => {
                self.is_admin = true;
                $preloaded.is_admin = true;
                notifications.success('You are admin of this Story now.');
                $scope.$broadcast('user_become_admin', CurrentUser.id);
                if (!self.is_member) {
                    document.location.reload();
                }
            }, err => notifications.error(err || 'Error'))
            .finally($rootScope.hide_dimmer);
    };

    self.allow_share = function () {
        if (!self.is_member) {
            return false;
        }
        if (CurrentUser.id === self.owner.id && CurrentUser.has_permission('share')) {
            return true;
        }
        if (CurrentUser.is_guardian_of(self.owner.id)) {
            return true;
        }
        return false;
    };

    self.buttons_expanded = false;

    self.expand_button_required = function(){
        return self.is_admin
    }();

    self.share_story = function () {
        shareFactory.share_this('story', self.story.id, [], self.owner.id, self.owner.name, false);
    };

    self.share_allowed = (function () {
        return CurrentUser.has_permission('share');
    })();

    self.show_settings_popup = show_settings_popup;
    self.go_back = function (event) {
        backUrlGenerator.go_back(event, DjangoUrls['stories_my_stories']());
    };

    function show_settings_popup() {
        const {popupComponent, vm} = simplePopupFactory.mount_vue_popup(StorySettingsOrDeletePopup);
        popupComponent.show({story_name: self.story.name})
            .then(res => {
                // res.action in ['rename','remove']. if rename then res.name defined
                if (res.action === 'rename') {
                    $rootScope.show_dimmer();
                    StoryResource.rename_story(self.story.id, res.name)
                        .then(resp => {
                            notifications.success('Renamed');
                            self.story.name = res.name;
                            $rootScope.hide_dimmer();
                        }, err => {
                            notifications.error(err || 'Error');
                            $rootScope.hide_dimmer();
                        });
                }
                if (res.action === 'remove') {
                    simplePopupFactory.show_popup('Remove Story', `Are you sure you want to remove Story '${self.story.name}'?`, 'Remove', 'Cancel')
                        .then(() => {
                            $rootScope.show_dimmer();
                            return StoryResource.remove_story(self.story.id);
                        }, () => new Promise(()=>{}))
                        .then(resp => {
                            notifications.success('Deleted');
                            let back_url = backUrlGenerator.get_from_url();
                            if (!back_url) {
                                back_url = DjangoUrls['stories_my_stories']();
                            }
                            $rootScope.show_dimmer();
                            document.location.href = back_url;
                        }, err => {
                            notifications.error(err || 'Error');
                            $rootScope.hide_dimmer();
                        });
                }
            }, () => {
                vm?.$destroy();
                return new Promise(() => {
                })
            })
            .finally(() =>  vm?.$destroy());
    }

    self.get_back_button_text = function get_back_button_text() {
        return 'Back'
    };

    self.on_back_button_pressed = function ($event) {
        if (self.active_template === templates['/about/']) {
            self.go_back($event);
        } else {
            $event.preventDefault();
            $event.stopPropagation();
            self.check_t('/about/');
        }
    };

}

export default storyDetailController;
