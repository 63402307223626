<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="header"
                 size="lg"
                 role="dialog">
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ header }}</h3>
        </div>
        <div class="add-media-from-folder-popup">
            <div class="row">
                <div class="col-sm-12">
                    <form class="form-inline" role="form" aria-label="Select media source panel.">
                        <div class="form-group" style="margin-bottom: 5px">
                            <label for="folders_mode">From:</label>
                            <select role="combobox" id="folders_mode" class="form-control"
                                    ref="folderModeSelect"
                                    @change="setActiveFolderMode"
                                    v-model="folderMode">
                                <option :value="FOLDER_MODES.MY">My Media</option>
                                <option :value="FOLDER_MODES.SHARED">Shared Media</option>
                            </select>
                        </div>
                        <div class="form-group" style="margin-bottom: 5px" v-if="folderMode===FOLDER_MODES.SHARED">
                            <label for="active_user_pk">&nbsp;&nbsp;User:</label>
                            <select id="active_user_pk" class="form-control" @change="setActiveSharer"
                                    v-model="activeSharerId">
                                <option :value="undefined">All Users</option>
                                <option v-for="sharer in availableSharers" :value="sharer.id">
                                    {{ sharer.name | cutName(26) }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group" style="margin-bottom: 5px">
                            <label for="active_folder_pk">&nbsp;&nbsp;Folder:</label>
                            <select id="active_folder_pk" class="form-control" @change="setActiveFolder"
                                    v-model="activeFolderId">
                                <option :value="undefined">All folders</option>
                                <option v-for="folder in availableFolders" :value="folder.id">
                                    {{ folder.name | cutName(26) }}
                                </option>
                            </select>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <h4>Click to items you want add</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div
                            class="folder-items-container"
                            :style="itemsContainerStyle"
                            ref="itemsContainer"
                    >
                        <span class="folder-items-container__holder" v-if="holder" :style="holderStyle">
                            {{ holder }}
                        </span>
                        <div class="folder-item__wrapper"
                             tabindex="0"
                             @click="toggleItemSelect(folderItem)"
                             @keydown.enter="toggleItemSelect(folderItem)"
                             @keydown.ctrl.enter.stop.prevent="checkAndAddItem(folderItem)"
                             :class="{'folder-item__wrapper_selected': isSelected(folderItem)}"
                             v-for="folderItem in items"
                             :key="folderItem.id"
                        >
                            <div class="folder-item__checked-mark"
                                 v-if="isSelected(folderItem)"
                                 :style="checkMarkStyle"
                            >
                                <i class="fa fa-check"></i>
                            </div>
                            <folder-item
                                    tabindex="-1"
                                    :attachment="folderItem"
                                    :size="folderItemSize"
                                    style="vertical-align: top;margin: 0;"
                                    :show-tooltip="false"
                            ></folder-item>
                        </div>
                        <div class="folder-item__fake-item"
                             :style="fakeItemStyle"
                             v-for="fakeItem in fakeItems"
                             aria-hidden="true"
                             :key="fakeItem"
                        ></div>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <div class="row">
                    <div class="col-sm-6 text-left">
                        <pagination
                                v-if="totalPage>1"
                                v-model="currentPage"
                                :total-page="totalPage"
                                :max-size="3"
                                @change="onPageChanged"
                        />
                    </div>
                    <div class="col-sm-6 text-right add-media-from-folder-popup__right-buttons-panel">
                        <button class="btn btn-mm  btn-primary" type="button" @click="close" role="button">Cancel
                        </button>
                        <button class="btn btn-mm  btn-green"
                                :disabled="!selectedItemsIdList.length"
                                type="button"
                                role="button"
                                @click="add">Add Media
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <span tabindex="0" aria-hidden="true" class="focus-catch-link"
                              @focus="focusToClose">&nbsp;</span>
                    </div>
                </div>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import Vue from 'vue';
    import PopupPromiseMixin from "../../../../vue/mixins/PopupPromiseMixin";
    import BasicModal from "../../../../vue/common_components/BasicModal";
    import SelfWidthAwareMixin from "../../../../vue/mixins/SelfWidthAwareMixin";
    import AddFromMyMedia from "./AddFromMyMedia";

    const FOLDER_MODES = {
        MY: 'my',
        SHARED: 'shared',
    };

    export default {
        name: "AddMediaFromFolderPopup",
        components: {BasicModal},
        mixins: [PopupPromiseMixin, SelfWidthAwareMixin],
        data() {
            return {
                filter: 'all',
                limit: 1,

                currentPage: 1,

                resultsCount: 0,

                FOLDER_MODES,
                activeFolderId: undefined,
                folderMode: FOLDER_MODES.MY,
                activeSharerId: undefined,

                availableFolders: [],
                availableSharers: [],

                selectedItems: [],

                loading: false,

                items: [],
                holder: '',
            };
        },
        computed: {
            firstFocusEl() {
                return this.$refs.folderModeSelect;
            },
            selectedItemsIdList() {
                return this.selectedItems.map(i => i.id);
            },
            totalPage() {
                return Math.ceil(this.resultsCount / this.itemsPerPage);
            },
            fakeItems() {
                const res = [];
                if (this.items.length) {
                    const countOfFakeItems = this.itemsPerPage - this.items.length;
                    for (let i = 0; i < countOfFakeItems; i++) {
                        res.push(i);
                    }
                }
                return res;
            },
            fakeItemStyle() {
                return {
                    'display': 'inline-block',
                    'width': `${this.folderItemSize + 10}px`,
                    'height': `${this.folderItemSize + 10}px`,
                };
            },
            itemsContainerStyle() {
                return {
                    'min-height': `${(this.folderItemSize + 10) * 2 + 8}px`,
                };
            },
            holderStyle() {
                return {
                    'line-height': `${(this.folderItemSize + 10) * 2 - 30}px`
                };
            },
            checkMarkStyle() {
                return {
                    'font-size': `${Math.floor((this.folderItemSize) / 3 * 2)}px`,
                    'line-height': `${this.folderItemSize}px`,
                };
            },
            swamElForMeasure() {
                return this.$refs.itemsContainer;
            },
            folderItemSize() {
                const corrector = 14;//(this.elWidth > 400) ? 20 : 10;
                const size = Math.floor(this.elWidth / (this.itemsPerPage / 2) - corrector);
                return Math.min(size, 180);
            },
            header() {
                if (!this.filter || this.filter === 'all') {
                    return 'Add media from My Media';
                } else {
                    return `Add ${this.filter} from My Media`;
                }
            },
            itemsPerPage() {
                if (this.elWidth < 300) {
                    return 4;
                }
                if (this.elWidth < 600) {
                    return 6;
                }
                return 8;
            },
        },
        methods: {
            resetData() {
                this.selectedItems = [];
                this.currentPage = 1;
                this.folderMode = FOLDER_MODES.MY;
                this.resultsCount = 0;
                this.activeFolderId = undefined;
                this.activeSharerId = undefined;
            },
            setInitial(filter, limit) {
                this.filter = filter;
                this.limit = limit;
            },
            $_loadItems(pageNum = 1) {
                this.loading = true;
                const AddFromMyMedia = Vue.getAngularModule('AddFromMyMedia');

                const offset = pageNum * this.itemsPerPage - this.itemsPerPage;
                this.items = [];
                this.holder = 'Loading...';


                let BASE_MEDIA_REQUEST;
                if (this.activeFolderId) {
                    BASE_MEDIA_REQUEST = AddFromMyMedia.get_folder_media(this.activeFolderId, this.filter, this.itemsPerPage, offset);
                } else {
                    if (this.folderMode === FOLDER_MODES.MY) {
                        BASE_MEDIA_REQUEST = AddFromMyMedia.get_my_all_media(this.filter, this.itemsPerPage, offset);
                    } else {
                        if (this.activeSharerId) {
                            BASE_MEDIA_REQUEST = AddFromMyMedia.get_shared_with_me_sharers_media(this.activeSharerId, this.filter, this.itemsPerPage, offset);
                        } else {
                            BASE_MEDIA_REQUEST = AddFromMyMedia.get_shared_with_me_all_media(this.filter, this.itemsPerPage, offset);
                        }
                    }
                }

                BASE_MEDIA_REQUEST.then((resp) => {
                    this.resultsCount = resp.data.count;
                    this.items = resp.data.results;
                    if (!this.items.length) {
                        this.holder = 'Nothing to add from here...';
                    } else {
                        this.holder = '';
                    }
                }, (err) => {
                    Vue.notifications.error(err || 'Error');
                })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            toggleItemSelect(item) {
                if (this.selectedItemsIdList.includes(item.id)) {
                    this.selectedItems = this.selectedItems.filter(i => i.id !== item.id);
                } else {
                    if (this.selectedItemsIdList.length === this.limit) {
                        Vue.notifications.error(`You can't add more then ${this.limit} item${(this.limit > 1) ? 's' : ''} here.`);
                        return;
                    }
                    this.selectedItems.push(item);
                }
            },
            checkAndAddItem(item) {
                if (!this.selectedItemsIdList.includes(item.id)) {
                    if (this.selectedItemsIdList.length === this.limit) {
                        Vue.notifications.error(`You can't add more then ${this.limit} item${(this.limit > 1) ? 's' : ''} here.`);
                        return;
                    }
                    this.selectedItems.push(item);
                }
                this.add();
            },
            isSelected(item) {
                return this.selectedItemsIdList.includes(item.id);
            },
            setActiveFolderMode() {
                this.currentPage = 1;
                this.activeFolderId = undefined;
                this.activeSharerId = undefined;
                this.availableFolders = [];
                this.availableSharers = [];
                if (this.folderMode === FOLDER_MODES.MY) {
                    this.$_getMyFolders();
                } else {
                    this.$_getSharers();
                    this.$_getMainSharedFolders();
                }
                this.$_loadItems();
            },
            setActiveFolder() {
                this.currentPage = 1;
                this.$_loadItems();
            },
            setActiveSharer() {
                this.currentPage = 1;
                this.activeFolderId = undefined;
                this.$_loadItems();
                if (this.activeSharerId) {
                    this.$_getSharersFolders(this.activeSharerId);
                } else {
                    this.$_getMainSharedFolders();
                }
            },
            elWidthEstablished() {
                this.$_loadItems();
            },
            onPageChanged() {
                this.$_loadItems(this.currentPage);
            },
            add() {
                this.save(this.selectedItems);
            },
            $_getSharers() {
                const $http = Vue.getAngularModule('$http');
                const AddFromMyMediaResource = AddFromMyMedia($http);
                AddFromMyMediaResource.get_sharers()
                    .then(resp => {
                        this.availableSharers = resp.data;
                    });
            },
            $_getMainSharedFolders() {
                const $http = Vue.getAngularModule('$http');
                const AddFromMyMediaResource = AddFromMyMedia($http);
                AddFromMyMediaResource.get_main_shared_folders()
                    .then(resp => {
                        this.availableFolders = resp.data;
                    });
            },
            $_getMyFolders() {
                const $http = Vue.getAngularModule('$http');
                const AddFromMyMediaResource = AddFromMyMedia($http);
                AddFromMyMediaResource.get_my_folders()
                    .then(resp => {
                        this.availableFolders = resp.data;
                    });
            },
            $_getSharersFolders() {
                const $http = Vue.getAngularModule('$http');
                const AddFromMyMediaResource = AddFromMyMedia($http);
                AddFromMyMediaResource.get_sharers_folders(this.activeSharerId)
                    .then(resp => {
                        this.availableFolders = resp.data;
                    });
            },
        },
        mounted() {
            this.$_getMyFolders();
        },
        watch: {
            // itemsPerPage(n) {
            //     this.currentPage = 1;
            //     this.$_loadItems(this.currentPage);
            // }
        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";

  .add-media-from-folder-popup {
    &__right-buttons-panel {
      @media (min-width: $screen-sm-min) {
        padding-top: 20px;
      }
    }

    &__pagination {
      &::v-deep {
        li {
          display: inline-block;
          margin: -5px 0 -1px;
          vertical-align: top;
        }
      }
    }
  }

  .folder-item {
    &__wrapper {
      position: relative;
      display: inline-block;
      padding: 5px;
      border-radius: 5px;
      margin: 2px;

      &_selected {
        background-color: $blue;
      }
    }

    &__checked-mark {
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      display: block;
      position: absolute;
      border-radius: 5px;
      pointer-events: none;
      top: 5px;
      left: 5px;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 1;

      //font-size: 40%;
      color: $red;
      text-align: center;
      //padding-top: 10%;
    }
  }

  .folder-items-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: flex-start;
    align-items: flex-start;

    &__holder {
      width: 100%;
      text-align: center;
      font-size: 21px;
      line-height: 100%;
      color: $tag-gray;
    }
  }
</style>