var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.slideSnapshotBefore
    ? _c("span", [
        _c("span", [_vm._v("deleted page ")]),
        _vm._v(" "),
        _c("strong", [
          _vm._v("\n        #" + _vm._s(_vm.slideSnapshotBefore.id) + " "),
          _vm.slideSnapshotBefore.caption
            ? _c("span", [
                _vm._v('"' + _vm._s(_vm.slideSnapshotBefore.caption) + '"'),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.slideSnapshotBefore.section_name
          ? _c("span", [
              _vm._v("\n        in "),
              _c(
                "strong",
                [
                  _vm._v(
                    "#" + _vm._s(_vm.slideSnapshotBefore.section) + "\n        "
                  ),
                  _c("wiki-log-possible-link", {
                    attrs: {
                      target: _vm.wikiLog.target_wiki_section,
                      label: _vm.slideSnapshotBefore.section_name,
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }