<template>
    <span v-if="sectionSnapshotAfter">
        <span>
            moved
            <span v-if="sectionSnapshotAfter.is_submenu">submenu</span> section </span>
            <strong>
                #{{ sectionSnapshotAfter.id }}
                <wiki-log-possible-link
                :target="wikiLog.target_wiki_section"
                :label="sectionSnapshotAfter.caption"
                ></wiki-log-possible-link>
            </strong>
            to
            <strong>
                #{{ sectionSnapshotAfter.parent_section }}
                <wiki-log-possible-link
                    :target="wikiLog.parent_wiki_section"
                    :label="sectionSnapshotAfter.parent_section_name"
                ></wiki-log-possible-link>
            </strong>
    </span>
</template>

<script>
    import SnapshotLogMixin from "./common/SnapshotLogMixin";

    export default {
        name: "WikiSectionUpdatedMoved",
        mixins: [SnapshotLogMixin]
    };
</script>

<style scoped>

</style>