<template>
    <selector-popup
            ref="popup"
            :header="header"
            :items="items">
        <template slot="footer">
        </template>
        <i slot="descriptionTop">What sort of event do you want to create?</i>
    </selector-popup>
</template>

<script>
    import SelectorPopup from "../../../../vue/common_components/SelectorPopup/SelectorPopup";

    export default {
        name: "CreateEventTargetSelectorPopup",
        components: {SelectorPopup},
        data() {
            return {
                header: 'Create event',
                showCommunity: false,
                showGroup: false,
            };
        },
        computed: {
            items() {
                const res = [
                    {
                        label: 'Personal<br>event',
                        color: 'yellow',
                        customIcon: 'user',
                        action: 'select',
                        selection: 'circle',
                    },
                ];
                if (this.showGroup) {
                    res.push({
                        label: 'Group<br>event',
                        color: 'lightblue',
                        customIcon: 'groups',
                        action: 'select',
                        selection: 'group',
                    });
                }
                if (this.showCommunity) {
                    res.push({
                        label: 'Community<br>event',
                        color: 'green',
                        customIcon: 'communities',
                        action: 'select',
                        selection: 'community',
                    });
                }
                return res;
            }
        },
        methods: {
            show(config) {
                this.showCommunity = config.community;
                this.showGroup = config.group;
                return new Promise((resolve, reject) => {
                    this.reject = reject;
                    this.resolve = resolve;
                    this.$refs.popup.show()
                        .then(item => {
                            resolve(item.selection);
                        }, reason => {
                            reject(reason);
                        });
                });
            },
            back() {
                this.$refs.popup?.close('back');
            },
        }
    };
</script>