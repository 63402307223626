<template>
    <a id="close_popup"
       @click="close"
       @keydown.enter.stop.prevent="close"
       @keydown.tab.shift.stop.prevent="onReturnFocus"
       role="button"
       aria-label="Close dialog"
       tabindex="0"></a>
</template>

<script>
    export default {
        name: "BasicModalCloseButton",
        props: {
            returnFocus: {
                type: Function,
                required: false,
            }
        },
        methods: {
            close() {
                this.$parent.$parent.$parent.close();
            },
            focus() {
                this.$el.focus();
            },
            onReturnFocus() {
                if (this.returnFocus) {
                    this.returnFocus();
                } else {
                    this.$parent.$parent.$parent.focusToClose();
                }
            }
        }
    };
</script>
