<template>
    <router-view></router-view>
</template>

<script>
    import FAVICONS from "shared/favicons";

    export default {
        name: "WikiMainRouter",
        metaInfo() {
            return {
                title: 'Calendar',
                titleTemplate: `%s - Multi Me`,
                link: [
                    {rel: 'icon', href: FAVICONS.calendar, type: 'image/x-icon', vmid: 'favicon',}
                ],
            };
        }
    };
</script>

<style scoped>

</style>