const WIKI_PERMISSION_VALUE = {
    UNSET: 'unset',
    NONE: 'none',
    EDIT: 'edit',
    VIEW: 'view',
};

const WIKI_PERMISSION_LEVEL = {
    SECTION: 'section',
    WHOLE_WIKI: 'whole-wiki',
    SLIDE: 'slide',
    FILE_ATTACHMENT: 'file-attachment',
    LINK: 'link',
};

const WIKI_PERMISSION_SIGNAL = {
    SET_WIKI_PERMISSION: 'setWikiPermission',
};

export {WIKI_PERMISSION_VALUE, WIKI_PERMISSION_LEVEL, WIKI_PERMISSION_SIGNAL};