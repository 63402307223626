<template>
    <div class="community-page-menu">
        <div class="community-page-menu__avatar_and_logo">
            <router-link :to="{name:'community-info'}" style="color: #fff; text-decoration: none">
                <div class="community-page-menu__avatar_and_logo__avatar pointer" :style="avatarStyle"></div>
            </router-link>
            <div class="community-page-menu__avatar_and_logo__name-with-subscription">
                <div class="community-page-menu__avatar_and_logo__name-with-subscription__name"
                     :class="{unauth:!isAuthenticated}">
                    <router-link :to="{name:'community-info'}" style="color: #fff; text-decoration: none">{{ name |
                        cutName(30) }}
                    </router-link>
                    <router-link v-if="showSettings" :to="{name: 'community-settings'}" style="color: #fff"
                                 :class="{unauth:!isAuthenticated}"><i
                            class="fa fa-gear hidden-sm hidden-xs"></i>
                    </router-link>
                </div>
                <div class="community-page-menu__avatar_and_logo__name-with-subscription__subscription pointer" :class="{unauth:!isAuthenticated}">
                    <span v-if="subscriptionState===true" @click="showUnSubscribeConfirmation"><i
                            class="fa fa-check"></i> You are subscribed for Community updates</span>
                    <span v-if="subscriptionState===false" @click="showSubscribeConfirmation"><i
                            class="fa fa-times"></i> You are not subscribed for Community updates</span>
                    <span v-if="subscriptionState===undefined"><i class="fa fa-spinner fa-spin"></i> Getting your subscription state</span>
                </div>
            </div>
        </div>
        <div class="community-page-menu__icons">
            <div class="community-page-menu__icons__icon-container"
                 v-if="!icon.mobileOnly"
                 @click="onIconClick(icon)"
                 v-for="icon in icons"
                 :key="icon.classes"
                 :class="{active: (icon.to&&($route.name===icon.to.name))}"
            >
                <div class="community-page-menu__icons__icon-container__icon"><i :class="icon.classes"></i></div>
                <div>{{icon.label}}</div>
            </div>
        </div>
        <div class="community-page-menu__toggle-button" @click="isCollapsed=!isCollapsed"
             :class="{'pushed':isCollapsed}">
            <i class="fa fa-bars"></i>
        </div>
        <div class="community-page-menu__mobile-menu" v-if="isCollapsed">
            <div class="community-page-menu__mobile-menu__item" v-for="icon in icons" :key="icon.label"
                 @click="onIconClick(icon)">
                <div class="community-page-menu__mobile-menu__item__icon">
                    <i :class="icon.classes"></i>
                </div>
                <div class="community-page-menu__mobile-menu__item__label">{{icon.label}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default {
        name: "CommunityPageMenuVertical",
        props: {
            avatar: String,
            name: String,
            showSettings: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isCollapsed: false,
                // icons: [],
            };
        },
        beforeMount() {
            // this.icons = this.getIcons();
        },
        computed: {
            icons() {
                return this.getIcons();
            },
            subscriptionState() {
                return this.$store.getters['communityPage/subscriptionState'];
            },

            avatarStyle() {
                return `background-image: url('${this.avatar}')`;
            },
            longName() {
                return this.name.length > 20;
            },
            isAuthenticated() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                return CurrentUser.is_authenticated();
            }
        },
        methods: {
            showSubscribeConfirmation() {
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                simplePopupFactory.show_popup('Subscribe', 'Are you sure you want to subscribe to Community updates?', 'Yes', 'Cancel')
                    .then(y => {
                        this.$store.dispatch('communityPage/subscribeToCommunityUpdates');
                    });
            },
            showUnSubscribeConfirmation() {
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                simplePopupFactory.show_popup('Unsubscribe', 'Are you sure you want to unsubscribe from Community updates?', 'Yes', 'Cancel')
                    .then(y => {
                        this.$store.dispatch('communityPage/unSubscribeFromCommunityUpdates');
                    });
            },
            onSubscriptionStateChangeRequest() {
                if (this.subscriptionState) {
                    this.showUnSubscribeConfirmation();
                } else {
                    this.showSubscribeConfirmation();
                }
            },
            getIcons() {
                const icons = [
                    {
                        label: 'About Us',
                        type: 'link',
                        classes: 'far fa-info',
                        to: {name: 'community-info'},
                    },
                    {
                        label: 'Stories',
                        type: 'link',
                        classes: 'far fa-book-open',
                        to: {name: 'community-stories'},
                    },
                    {
                        label: 'Events',
                        type: 'link',
                        classes: 'far fa-calendar',
                        to: {name: 'community-events'},
                    },
                    {
                        label: 'Groups',
                        type: 'link',
                        classes: 'far fa-users',
                        to: {name: 'community-groups'},
                    },
                    {
                        label: 'Share',
                        type: 'event',
                        classes: 'far fa-share-square',
                        event: 'onShare'
                    },
                ];
                if (this.showSettings) {
                    icons.splice(4, 0, {
                        label: 'Settings',
                        type: 'link',
                        mobileOnly: true,
                        classes: 'fa fa-gear',
                        to: {name: 'community-settings'},
                    });
                }
                if (this.isAuthenticated && this.subscriptionState !== undefined) {
                    icons.push(
                        {
                            label: this.subscriptionState ? 'Subscribed' : 'NotSubscribed',
                            type: 'action',
                            mobileOnly: true,
                            classes: this.subscriptionState ? 'far fa-check-square' : 'fa fa-times',
                            action: 'onSubscriptionStateChangeRequest',
                        }
                    );
                }
                return icons;
            },
            onIconClick(icon) {
                this.isCollapsed = false;
                if (icon.type === 'link') {
                    this.$router.push(icon.to);
                }
                if (icon.type === 'event') {
                    this.$emit(icon.event);
                }
                if (icon.type === 'action') {
                    this[icon.action]();
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../styles/const";

    $menu-item-width: 90px;
    $menu-items-count: 5;
    $menu-item-margin: 15px;
    .community-page-menu {
        height: 64px;
        width: 100%;
        background-color: #0065b0;
        margin-bottom: 5px;
        position: relative;

        &__avatar_and_logo {
            display: inline-block;
            height: 100%;
            @media (min-width: $screen-md-min) {
                width: calc(100% - #{($menu-item-width + $menu-item-margin) * $menu-items-count});
            }
            @media (max-width: $screen-sm-max) {
                width: calc(100% - 65px);
            }

            vertical-align: top;

            &__avatar {
                display: inline-block;
                background-size: cover;
                border: 3px solid lightgray;
                border-radius: 50%;
                margin-top: 2px;
                margin-left: 2px;
                width: 60px;
                height: 60px;
                vertical-align: top;
            }

            &__name {
                width: calc(100% - 70px);
                vertical-align: top;
                display: inline-block;
                color: white;
                height: 100%;
                font-size: 21px;
                line-height: 64px;
                overflow: hidden;

                @media (max-width: $help-panel-mobile-max) {
                    &.longName {
                        font-size: 16px;
                        line-height: unset;
                    }
                    font-size: 18px;

                }
            }

            &__name-with-subscription {
                width: calc(100% - 70px);
                vertical-align: top;
                display: inline-block;
                color: white;
                height: 100%;
                font-size: 21px;
                line-height: 64px;

                &__name {
                    height: 32px;
                    line-height: 32px;
                    @media (max-width: $help-panel-mobile-max) {
                        font-size: 16px;
                        line-height: 30px !important;
                    }

                    @media (max-width: $screen-sm-max) {
                        height: 64px;
                        line-height: 64px;
                    }

                    &.unauth {
                        height: 64px;
                        line-height: 64px;
                    }

                }

                &__subscription {
                    height: 32px;
                    line-height: 32px;
                    font-size: 14px;
                    @media (max-width: $screen-sm-max) {
                        display: none;
                    }

                    &.unauth {
                        display: none;
                    }
                }
            }
        }

        &__icons {
            width: ($menu-item-width + $menu-item-margin) * $menu-items-count;

            @media (min-width: $screen-md-min) {
                display: inline-block;
            }
            @media (max-width: $screen-sm-max) {
                display: none;
            }

            text-align: right;
            color: white;
            height: 100%;
            float: right;

            &__icon-container {
                &.active {
                    background-color: #2c85bf;
                }

                cursor: pointer;
                color: white;
                padding-top: 5px;
                height: 100%;
                width: $menu-item-width;

                &:not(:first-child) {
                    margin-left: $menu-item-margin;
                }

                display: inline-block;
                text-align: center;

                &__icon {
                    font-size: 22px;
                }
            }

        }

        &__toggle-button {
            @media (min-width: $screen-md-min) {
                display: none;
            }
            @media (max-width: $screen-sm-max) {
                display: inline-block;
            }
            vertical-align: top;
            float: right;
            background-color: #005ea9;
            transition: background-color 0.2s ease;

            &.pushed {
                background-color: #004b92;
            }

            font-size: 35px;
            border: solid 1px #333;
            border-radius: 5px;
            padding: 5px;
            margin: 5px;
            margin-top: 10px;
            width: 45px;
            height: 45px;
            color: white;

            i {
                vertical-align: top;
            }


        }

        &__mobile-menu {
            z-index: 5;
            position: absolute;
            width: 100%;
            top: 64px;
            left: 0;
            background-color: #0065b0;
            padding-top: 10px;
            padding-bottom: 10px;

            &__item {
                /*border-top: 1px solid #333;*/
                font-size: 0;
                padding: 2px 0;
                min-height: 50px;
                $icon-sector-width: 50px;
                width: 100%;

                &__icon {
                    line-height: $icon-sector-width;
                    vertical-align: middle;
                    width: $icon-sector-width;
                    color: #fff;
                    font-size: 22px;
                    display: inline-block;
                    height: 100%;
                    text-align: center;
                }

                &__label {
                    vertical-align: middle;
                    display: inline-block;
                    width: calc(100% - #{$icon-sector-width});
                    font-size: 22px;
                    color: #fff;
                    /*font-weight: bolder;*/
                }
            }
        }
    }
</style>