var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    _vm._g(
      _vm._b(
        {
          staticClass: "button",
          attrs: { role: "button" },
          on: { click: _vm.onClick },
        },
        "button",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }