const prefix = '/static/images/favicons/';
const FAVICONS = {
    default: `${prefix}default_128.png`,
    circle: `${prefix}mycircle_128.png`,
    goals: `${prefix}mygoals_128.png`,
    about: `${prefix}aboutme_128.png`,
    group: `${prefix}groups_128.png`,

    wiki: `${prefix}mywiki_128.png`,
    wiki_apple: `${prefix}mywiki_180_1.png`,
    wiki_android: `${prefix}mywiki_192.png`,

    chats: `${prefix}chats_128.png`,
    insights: `${prefix}insights_128.png`,
    media: `${prefix}mymedia_128.png`,
    diary: `${prefix}mydiary_128.png`,
    calendar: `${prefix}calendar_128.png`,
};

export default FAVICONS;