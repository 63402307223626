var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { transition: 0 },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          attrs: { id: "close_popup" },
          on: { click: _vm.close_popup },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [_vm._v("Update Event")]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row", staticStyle: { "margin-bottom": "15px" } },
        [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("div", {}, [
              _vm._v(
                "\n                This is a repeated Event. You have three options:\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "margin-5-top" }, [
              _vm._v(
                "\n                1) Update the whole chain of Events, past and future.\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "margin-5-top" }, [
              _vm._v(
                "\n                2) Update this Event on this date only.\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "margin-5-top" }, [
              _vm._v(
                "\n                3) Update this and future Events only.\n            "
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "select-menu-container center" }, [
        _c("div", { staticClass: "select-menu-item" }, [
          _c("div", { staticClass: "panel panel-danger" }, [
            _c(
              "div",
              {
                staticClass: "panel-heading shortcuts",
                on: {
                  click: function ($event) {
                    return _vm.select("whole")
                  },
                },
              },
              [
                _c("h3", { staticClass: "panel-title" }, [
                  _vm._v("Every"),
                  _c("br"),
                  _vm._v("occurrence"),
                ]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "fa fa-repeat select-menu-item__main-icon",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "select-menu-item" }, [
          _c("div", { staticClass: "panel panel-primary" }, [
            _c(
              "div",
              {
                staticClass: "panel-heading shortcuts",
                on: {
                  click: function ($event) {
                    return _vm.select("exact")
                  },
                },
              },
              [
                _c("h3", { staticClass: "panel-title" }, [
                  _vm._v("Only"),
                  _c("br"),
                  _vm._v(_vm._s(_vm.formattedDate)),
                ]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "fa fa-calendar-o select-menu-item__main-icon",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "select-menu-item" }, [
          _c("div", { staticClass: "panel panel-primary" }, [
            _c(
              "div",
              {
                staticClass: "panel-heading shortcuts",
                on: {
                  click: function ($event) {
                    return _vm.select("since")
                  },
                },
              },
              [
                _c("h3", { staticClass: "panel-title" }, [
                  _vm._v("Future"),
                  _c("br"),
                  _vm._v("Events"),
                ]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "fa fa-arrow-right select-menu-item__main-icon",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }