var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _vm.isSyncPossible
      ? _c("div", { staticClass: "row margin-5-top" }, [
          _c(
            "div",
            { staticClass: "col-sm-12 text-right" },
            [
              _c("vue-switch-with-label", {
                attrs: {
                  label: "Sync Logs with same names ",
                  name: "sync_logs",
                },
                model: {
                  value: _vm.syncLogs,
                  callback: function ($$v) {
                    _vm.syncLogs = $$v
                  },
                  expression: "syncLogs",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticStyle: {
          "max-height": "335px",
          "overflow-y": "auto",
          "overflow-x": "hidden",
        },
      },
      _vm._l(_vm.editableLocationsData, function (profileLocationsData) {
        return _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _vm.editableLocationsData.length > 1 ||
              (_vm.editableLocationsData.length === 1 &&
                _vm.editableLocationsData[0].profile.id !==
                  _vm.calendarOwner.id)
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("img", {
                        staticClass: "img-circle vertical-middle",
                        attrs: {
                          src: profileLocationsData.profile.avatar,
                          alt: "",
                          width: "40",
                          height: "40",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "strong",
                        { staticClass: "vertical-middle" },
                        [
                          _c("simple-link", {
                            attrs: { target: profileLocationsData.profile },
                          }),
                        ],
                        1
                      ),
                      _vm._v(":\n                    "),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(profileLocationsData.locations, function (location) {
                return _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-xs-12",
                          staticStyle: { height: "22px" },
                        },
                        [
                          _c("span", { staticClass: "align-helper" }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { staticStyle: { "vertical-align": "bottom" } },
                            [_vm._v(_vm._s(location.name))]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: location.text,
                          expression: "location.text",
                        },
                      ],
                      staticClass: "form-control modal-text-input-textarea",
                      attrs: { rows: "5" },
                      domProps: { value: location.text },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(location, "text", $event.target.value)
                          },
                          function ($event) {
                            return _vm.textChanged(
                              location.id,
                              location.name,
                              location.text
                            )
                          },
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "color-red" }, [
                      _vm._v(
                        "Note: Once you save this answer a copy will be kept on multi me for audit purposes even if deleted from this Dairy"
                      ),
                    ]),
                  ]),
                ])
              }),
            ],
            2
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }