<template>
    <div>
        <div class="row">
            <div class="col-sm-12">
                <div class="alert alert-info  margin-15-top margin-15-bottom" v-if="!links.length">
                    There are no Goals linked to this Diary Entry yet.
                </div>

                <div class="alert alert-info margin-15-top margin-15-bottom"
                     v-if="addToSuDiaries&&groupedByCalendarOwnerLinks.length>1">
                    Supported Users' Goal {{ 'Tasks' | customTerm }} linked to this Event will automatically be linked
                    to this Diary entry.
                </div>

                <div v-for="linkGroup in groupedByCalendarOwnerLinks" :key="linkGroup.calendar_owner.id">
                    <div class="row"
                         v-if="groupedByCalendarOwnerLinks.length>1||((groupedByCalendarOwnerLinks.length===1)&&(groupedByCalendarOwnerLinks[0].calendar_owner.id!==calendarOwner.id))">
                        <div class="col-sm-12">
                            <img :src="linkGroup.calendar_owner.avatar" alt="" class="img-circle vertical-middle"
                                 width="40" height="40">
                            <strong class="vertical-middle">
                                <simple-link :target="linkGroup.calendar_owner"></simple-link>
                            </strong>:
                        </div>
                    </div>

                    <div class="row" style="margin-bottom: 5px; padding-top: 5px;"
                         :style="{'border-top':i===0?'none':'solid 1px lightgrey','padding-top':i===0?0:'5px'}"
                         v-for="(link,i) in linkGroup.links" :key="link.id">
                        <div class="col-sm-3 text-center">
                            <a :href="link.goal.link" target="_blank">
                                <img class="img-circle border-goal" style="width: 100px; height: 100px;"
                                     :src="link.goal.avatar">
                            </a>
                        </div>
                        <div class="col-sm-9 no-left-padding-desktop">
                            <div class="row">
                                <div class="col-xs-9 no-left-padding-desktop">
                                    <strong>
                                        <simple-link :target="link.goal" new-tab></simple-link>
                                    </strong><br>
                                    <span v-if="link.goal.description"><small><i>{{
                                            link.goal.description | cutName(255)
                                        }}</i></small><br/></span>
                                    <small v-show="link.tasks.length">
                                        <span v-if="link.tasks.length===1">1 Linked {{ 'Task' | customTerm }}:</span>
                                        <span v-else-if="link.tasks.length>1">{{
                                                link.tasks.length
                                            }} Linked {{ 'Tasks' | customTerm }}:</span>
                                        <strong v-for="(task,j) in link.tasks" :key="task.id">{{
                                                task.name | cutName(35)
                                            }}
                                            <a href="javascript:;" class="color-red pointer"
                                               v-show="taskIsDeletable(task)"
                                               @click="removeTaskFromGoal(link, task)"><i
                                                    class="fa fa-trash-o remove-task-trash-icon"></i></a>
                                            <span v-show="j!==(link.tasks.length-1)">, </span>
                                        </strong>

                                    </small>
                                    <strong><a href="javascript:;" @click="addTaskToGoal(link.goal)">+ Add
                                        {{ 'Task' | customTerm }}</a></strong>
                                </div>
                                <div class="col-xs-3 text-right">
                                    <vue-folder-button color="red" text="Delete" icon-class="fa-trash"
                                                       v-show="goalIsDeletable(link.goal)"
                                                       @click.native="deleteLinkedGoal(link)"></vue-folder-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 text-right">
                <a href="javascript:;" @click.stop="linkGoal" class="link-to-goal-task-label">+ Link to
                    {{ 'Goal'|customTerm }}
                    {{ 'Task'|customTerm }}</a>
            </div>
        </div>


    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';
    import { GoalFactory } from '~/angular/app/projects/goal_settings_factory/goalFactory';
    import { selectFactory } from '~/angular/app/shared/select_factory/select_factory';
    import CalendarEventGoalsResource from '~/angular/app/events/calendar_event_goals_recource';
    import GoalTaskResource from '~/angular/app/projects/project_task_resource';

    export default {
        name: 'EventDetailPageCreateDiaryEntryPopupGoalPart',
        props: {
            calendarOwner: Object,
            eventId: Number,
            buddyMode: Boolean,
            calendarOwnerIsOwnEventsAdmin: Boolean,
            addToSuDiaries: Boolean,
            profilesToCreateProxyEntries: Array,
        },
        data() {
            return {
                links: []
            };
        },
        computed: {
            linkedTasksCount() {
                let count = 0;
                for (const link of this.links) {
                    count += link.tasks.length;
                }
                return count;
            },
            goalsForLinkEndpoint() {
                return _.partial(CalendarEventGoalsResource().get_users_goals_for_link_diary_entry, this.eventId, this.calendarOwner.id, this.profilesToCreateProxyEntries.map(p => p.id));
            },
            tasksForLinkEndpoint() {
                return _.partial(CalendarEventGoalsResource().get_users_tasks_for_link_in_goal, this.eventId, this.calendarOwner.id);
            },

            groupedByCalendarOwnerLinks() {
                const linksGroups = [];
                const addLink = (lnk) => {
                    let found = false;
                    for (const links_group of linksGroups) {
                        if (links_group.calendar_owner.id === lnk.calendar_owner.id) {
                            found = true;
                            links_group['links'].push(lnk);
                        }
                    }
                    if (!found) {
                        linksGroups.push({
                            'calendar_owner': lnk.calendar_owner,
                            'links': [lnk]
                        });
                    }
                };
                for (const link of this.links) {
                    addLink(link);
                }
                return linksGroups;
            }

        },
        methods: {
            addLinks(links) {
                this.links = links;
            },
            addTasksToLink(goal, tasksList, isPreloaded = false) {
                //I do not want to be able to remove goals or tasks from the diary entry dialogue. I can add new ones and remove those. https://trello.com/c/jSuejrGd/472-if-i-am-not-admin-of-my-own-calendar-as-a-su-i-do-not-want-to-see-the-following-options-on-events-that-i-have-not-created-myself
                let goalFound = false;
                for (let link of this.links) {
                    if (link.goal.id === goal.id) {
                        goalFound = true;
                        if (isPreloaded) {
                            link.isPreloaded = true;
                            for (let task of tasksList) {
                                task.isPreloaded = true;
                                link.tasks.push(task);
                            }
                        } else {
                            link.tasks = link.tasks.concat(tasksList);
                        }
                    }
                }
                if (!goalFound) {
                    if (isPreloaded) {
                        this.links.push({
                            id: Math.random(),
                            calendar_owner: goal.owner,
                            goal: _.merge({ isPreloaded: true }, goal),
                            tasks: tasksList.map(t => {
                                t.isPreloaded = isPreloaded;
                                return t;
                            })
                        });
                    } else {
                        this.links.push({
                            id: Math.random(),
                            calendar_owner: goal.owner,
                            goal,
                            tasks: tasksList
                        });
                    }
                }
            },
            taskIsDeletable(task) {
                return this.buddyMode || this.calendarOwnerIsOwnEventsAdmin || !task.isPreloaded;
            },
            goalIsDeletable(goal) {
                return this.buddyMode || this.calendarOwnerIsOwnEventsAdmin || !goal.isPreloaded;
            },
            createTask(goal) {
                const goalTaskFactory = Vue.getAngularModule('goalTaskFactory');
                goalTaskFactory.show_create_goal_task_wizard(goal.id)
                    .then(res => {
                        GoalTaskResource().create_task(goal.id, res)
                            .then(success => {
                                this.showTasksSelector(goal);
                            }, err => {
                                this.$notifications.error(err || 'Error');
                            });
                    }, cancel => {
                        this.showTasksSelector(goal);
                    });
            },
            showTasksSelector(goal) {//get_users_tasks_for_link_in_goal
                let POPUP_CONFIG = {
                    header: `Select ${this.$customTerm('Task')}`,
                    info: `Select or de-
                    select ${this.$customTerm('Task')}
                    from list`,
                    button_yes: 'Select',
                    button_no: 'Cancel',
                    empty: `You do not have any ${this.$customTerm('Tasks')} to select`,
                    additional_buttons: []
                };
                if (goal.viewer_can_create_tasks) {
                    POPUP_CONFIG.additional_buttons.push(
                        {
                            text: `+ New ${this.$customTerm('Task')}`,
                            classes: 'btn btn-mm btn-green',
                            result_code: 'create_task'
                        }
                    );
                }
                let linkedTasksIdList = [];

                for (let link of this.links) {
                    if (goal.id === link.goal.id) {
                        linkedTasksIdList = link.tasks.map(t => t.id);
                    }
                }

                selectFactory.endpoint_selector(_.partial(this.tasksForLinkEndpoint, goal.id, linkedTasksIdList),
                    true, POPUP_CONFIG, false, false, true)
                    .then(result => {
                        if (result === 'create_task') {
                            this.createTask(goal);
                        } else {
                            const tasksList = result[0];
                            this.addTasksToLink(goal, tasksList);
                            this.$emit('show');
                        }
                    }, cancel => {
                        this.$emit('show');
                    });

            },
            showGoalsSelector(allowCreateGoal = true, promiseToResolve) {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                const $q = Vue.getAngularModule('$q');
                const goalDeferred = promiseToResolve || $q.defer();
                let POPUP_CONFIG = {
                    header: `Select ${this.$customTerm('Goal')}`,
                    info: `Select or de-
                    select ${this.$customTerm('Goal')}
                    from list`,
                    button_yes: 'Select',
                    button_no: 'Cancel',
                    empty: `You do not have any ${this.$customTerm('Goals')} to select`,
                    additional_buttons: []
                };
                if (allowCreateGoal) {
                    POPUP_CONFIG.additional_buttons.push(
                        {
                            text: `+ New ${this.$customTerm('Goal')}`,
                            classes: 'btn btn-mm btn-green',
                            result_code: 'create_goal'
                        }
                    );
                }

                const additionalFilters = [];
                additionalFilters.push({
                    name: 'show_archived_goals',
                    text: `Show hidden ${this.$customTerm('Goals')}`,
                    inputType: 'switch',
                    filter: true,
                    value: false,
                    paramsWhenTrue: {
                        show_archived_goals: true
                    }
                });

                POPUP_CONFIG['additional_filters'] = additionalFilters;

                let goal_selector = selectFactory.endpoint_selector(this.goalsForLinkEndpoint,
                    false, POPUP_CONFIG, false, false, true);

                goal_selector.then(result => {
                    if (result === 'create_goal') {
                        let auto_create = true;
                        const gf = new GoalFactory();
                        if (this.buddyMode) {
                            gf.setForUserCreationForced(this.calendarOwner.name);
                        }
                        gf.startGoalCreation()
                            .then(res => {
                                if (res.goalData.goal_to_copy) {
                                    auto_create = false;
                                }
                                $rootScope.show_dimmer();
                                return res.goalData;
                            }, reject => {
                                this.showGoalsSelector(allowCreateGoal, goalDeferred);
                                return new Promise(() => {
                                });
                            })
                            .then(goalData => {
                                goalDeferred.resolve(goalData);
                                notifications.success(`${this.$customTerm('Goal')} Created`);
                                $rootScope.hide_dimmer();
                            }, err => {
                                $rootScope.hide_dimmer();
                                notifications.error(err || 'Error');
                                return new Promise(() => {
                                });
                            });
                    } else {
                        goalDeferred.resolve(result);
                        // goal_id itself then
                    }
                }, () => {
                    this.$emit('show');
                    goalDeferred.reject();
                });

                return goalDeferred.promise;
            },
            linkGoal() {
                this.$emit('hide');
                this.showGoalsSelector()
                    .then(selectedGoal => {
                        this.showTasksSelector(selectedGoal);
                    });
            },
            deleteLinkedGoal(link) {
                this.links.splice(this.links.indexOf(link), 1);
            },
            removeTaskFromGoal(link, task) {
                link.tasks = link.tasks.filter(t => t.id !== task.id);
            },
            addTaskToGoal(goal) {
                this.$emit('hide');
                this.showTasksSelector(goal);
            },
            dumpData() {
                let tasksToLink = {};
                if (this.addToSuDiaries) {
                    for (let group of this.groupedByCalendarOwnerLinks) {
                        tasksToLink[group.calendar_owner.id] = [];
                        for (let lnk of group.links) {
                            tasksToLink[group.calendar_owner.id].push({
                                goal: lnk.goal.id,
                                tasks: lnk.tasks.map(t => t.id)
                            });
                        }
                    }
                } else {
                    tasksToLink[this.calendarOwner.id] = [];
                    for (let lnk of this.links) {
                        tasksToLink[this.calendarOwner.id].push({
                            goal: lnk.goal.id,
                            tasks: lnk.tasks.map(t => t.id)
                        });
                    }
                }

                return { tasks_to_link: tasksToLink };
            },
            resetData() {
                this.links = [];
            }
        },
        watch: {
            linkedTasksCount(n, o) {
                this.$emit('linkedTasksCountChanged', n);
            }
        }
    };
</script>

<style scoped lang="scss">
  .link-to-goal-task-label {
    cursor: pointer;
    font-size: 16px;
  }

  .remove-task-trash-icon {
    font-size: 16px;
  }

</style>
