<template>
  <basic-modal @hide="close"
               v-model="opened"
               aria-label="Wiki Editor Name"
               role="dialog">
      <div slot="header">
          <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
          <h3 class="modal-title">I am helping {{ userReadableName }}</h3>
      </div>

      <div class="row">
          <div class="col-sm-12">
             <alert type="info">
                 If you are is supporting the person to edit their Wiki, please confirm your details here
             </alert>
          </div>
      </div>


      <div class="row">
          <div class="col-sm-12">
              <span>I am from the person's Circle on Multi Me</span>
              <div>
                  <input autocomplete="off" id="input-fn" class="form-control" type="text" placeholder="Select user">
                  <typeahead v-model="wikiEditor"  class="user-selector-input" target="#input-fn" :data="fullNameCircleMembers" item-key="fullName"/>
              </div>
          </div>
      </div>

      <div class="row margin-15-top">
          <div class="col-sm-12">
              <span>I am someone else</span>
              <div>
                  <input id="first_name"
                         class="form-control"
                         type="text"
                         placeholder="First Name"
                         :disabled="!!wikiEditorId"
                         v-model="wikiEditorFirstName"
                         autocomplete="given-name"
                         maxlength="63"
                  >
              </div>
          </div>
      </div>

      <div class="row margin-10-top">
          <div class="col-sm-12">
              <input id="last_name"
                     class="form-control"
                     type="text"
                     placeholder="Last Name"
                     :disabled="!!wikiEditorId"
                     v-model="wikiEditorLastName"
                     autocomplete="family-name"
                     maxlength="63"
                  >
          </div>
      </div>

      <div class="row margin-15-top">
          <div class="col-sm-12">
              <span>What is your relationship to the person?</span>
              <input id="role"
                     class="form-control"
                     type="text"
                     placeholder="Add role"
                     v-model="wikiEditorRole"
                     maxlength="127"
              >
          </div>
      </div>

      <div class="row margin-15-top">
          <div class="col-sm-12">
              <vue-switch-with-label name="has_consent" v-model="haveConsent"
                                     :label="`I have consent to edit ${username}'s Wiki `">
              </vue-switch-with-label>
<!--              <a href="https://multime.freshdesk.com/a/solutions/articles/44001799650" target="_blank">-->
<!--                  <i class="fa fa-info-circle"></i>-->
<!--              </a>-->
          </div>
      </div>

      <div slot="footer">
          <button class="btn btn-mm btn-green"
                  :disabled="(!wikiEditorId && !wikiEditorLastName && !wikiEditorFirstName) || !haveConsent"
                  @click="saveChanges">
              Confirm
          </button>
      </div>
  </basic-modal>
</template>

<script>
    import PopupPromiseMixin from "~/vue/mixins/PopupPromiseMixin";
    import BasicModal from "~/vue/common_components/BasicModal.vue";
    import BasicModalCloseButton from "~/vue/common_components/BasicModalCloseButton.vue";
    import {CurrentUserClass} from "~/angular/app/shared/CurrentUser";
    import WikiResource from "~/angular/app/wiki/wiki_main_resource";

export default {
    name: "WikiEditorNamePopup",
    components: {BasicModalCloseButton, BasicModal},
    mixins: [PopupPromiseMixin],
    data() {
        return {
            search: "",
            wikiEditor: undefined,
            wikiEditorId: undefined,
            wikiEditorName: undefined,
            usersCircleMembers: [],
            wikiId: undefined,
            wikiEditorFirstName: '',
            wikiEditorLastName: '',
            wikiEditorRole: '',
            haveConsent: false,
        };
    },
    computed: {
        fullNameCircleMembers() {
            return this.usersCircleMembers.map((member) => ({
                ...member,
                fullName: `${member.name.toLowerCase()} ${member.username.toLowerCase()}`
            }));
        },
        userReadableName() {
            const cu = new CurrentUserClass();
            return `${cu?.first_name} ${cu?.last_name}`;
        },
        username() {
            const cu = new CurrentUserClass();
            return cu.username;
        }
    },
    watch: {
        wikiEditor(newVal) {
            this.wikiEditorId = newVal.id;
        }
    },
    methods: {
        async showedHook(){
            try {
                const res = await WikiResource().get_potential_editors(this.wikiId);
                this.usersCircleMembers = res.data;
            } catch (error) {
                console.log(error);
            }
        },
        setInitial(wikiId) {
            this.wikiId = wikiId;
        },
        async saveChanges() {
            const data = {};
            if (this.wikiEditorId) {
                data.id = this.wikiEditorId;
                data.name = this.usersCircleMembers.filter((member) => member.id === this.wikiEditorId)[0].name;
            } else {
                data.id = null;
                data.name = (this.wikiEditorFirstName + ' ' + this.wikiEditorLastName).trim();
            }
            data.role = this.wikiEditorRole;
            this.save(data);
        },
    },
};
</script>

<style scoped>
    .user-selector-input{
        &::v-deep(.dropdown-menu){
            width: 100%;
        }
    }

    .current-user-button {
        text-align: start;
    }
</style>
