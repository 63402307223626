<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="header"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ header }}</h3>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div>
                    <span>Give your Story a title:</span>
                    <input ref="nameInput" v-model="name" maxlength="100" type="text" class="form-control"
                           placeholder="Story Name"
                           required/>
                </div>
            </div>
        </div>
        <div class="row margin-15-top">
            <div class="col-sm-12">
                <media-preview
                        v-if="mediaForAvatar"
                        :no-border="true"
                        :item="mediaForAvatar"
                        :allow-delete="true"
                        :show-name="true"
                        @onDelete="cleanAvatar"
                ></media-preview>
                <media-preview-add-request
                        v-if="!mediaForAvatar"
                        :no-border="true"
                        @click.native="addAvatar"
                        @keydown.enter.native="addAvatar"
                        text="+ Picture"
                        icon-class="fa-picture-o"
                ></media-preview-add-request>

                <!--                <media-preview-add-request :no-border="true" text="+ Location" @click.native="addLocation"-->
                <!--                                           icon-class="fa-map-marker" v-if="!locationData"></media-preview-add-request>-->
                <!--                <media-preview :no-border="true" @onDelete="cleanLocation" :show-name="true" :item="locationPreviewSrc"-->
                <!--                               :text='locationCaption' v-if="locationData" :allow-delete="true"></media-preview>-->

                <media-preview :no-border="true" :item="mediaForAudioCaption" :allow-delete="true"
                               v-if="mediaForAudioCaption"
                               @onDelete="cleanAudioCaption" :show-name="true"></media-preview>
                <media-preview-add-request
                        v-if="!mediaForAudioCaption"
                        :no-border="true"
                        text="+ Audio"
                        icon-class="fa-volume-down"
                        @click.native="addAudio"
                        @keydown.enter.native="addAudio"
                ></media-preview-add-request>

            </div>
        </div>
        <div slot="footer">
            <div class="row">
                <div class="col-xs-3 text-left">
                    <button class="btn btn-mm btn-red" @click="deleteStory" v-if="editMode">Delete</button>
                </div>
                <div class="col-xs-9 ">
                    <button class="btn btn-mm" @click="close">Cancel</button>
                    <button class="btn btn-mm btn-green save-button" :disabled="!allowSave" @click="saveStory">
                        {{ saveButtonText }}
                    </button>
                </div>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import AddMediaAudioLocationInPopupMixin from "../../../../vue/mixins/AddMediaAudioLocationInPopupMixin";
    import PopupPromiseMixin from "../../../../vue/mixins/PopupPromiseMixin";
    import BasicModal from "../../../../vue/common_components/BasicModal";

    export default {
        name: "CreateStoryPopup",
        components: {BasicModal},
        mixins: [PopupPromiseMixin, AddMediaAudioLocationInPopupMixin],
        data() {
            return {
                opened: false,
                editingStory: undefined,
                editMode: false,
                header: 'Create Story',
                name: '',
                saveButtonText: 'Create',
            };
        },
        computed: {
            firstFocusEl() {
                return this.$refs.nameInput;
            },
            allowSave() {
                if (this.name.length < 2) {
                    return false;
                }
                if (this.editMode && this.editingStory) {
                    if (this.name !== this.editingStory.name) {
                        return true;
                    }
                    if (!!this.mediaForAvatar !== !!this.editingStory.avatar_media) {
                        return true;
                    }
                    if (!!this.mediaForAvatar && !!this.editingStory.avatar_media) {
                        if (this.mediaForAvatar.id !== this.editingStory.avatar_media.id) {
                            return true;
                        }
                    }
                    if (!!this.mediaForAudioCaption !== !!this.editingStory.audio_caption) {
                        return true;
                    }
                    if (!!this.mediaForAudioCaption && !!this.editingStory.audio_caption) {
                        if (this.mediaForAudioCaption.id !== this.editingStory.audio_caption.id) {
                            return true;
                        }
                    }
                    return false;
                }
                return true;
            }
        },
        methods: {
            resetData() {
                this.$nextTick(() => {
                    this.name = '';
                    this.mediaForAvatar = undefined;
                    this.mediaForAudioCaption = undefined;
                    this.editingStory = undefined;
                });
            },
            deleteStory() {
                this.close('delete');
            },
            setInitial(story = undefined) {
                this.editingStory = story;
                this.editMode = !!story;
                this.header = story ? 'Edit Story' : 'Create Story';
                this.saveButtonText = story ? 'Save' : 'Create';
                if (story) {
                    this.name = story.name;
                    this.mediaForAvatar = story.avatar_media;
                    this.mediaForAudioCaption = story.audio_caption;
                }
            },
            saveStory() {
                const res = {
                    name: this.name
                };
                if (this.mediaForAvatar) {
                    res['media_for_avatar_id'] = this.mediaForAvatar.pk;
                }
                if (this.mediaForAudioCaption) {
                    res['media_for_audio_caption_id'] = this.mediaForAudioCaption.pk;
                }
                this.save(res);
            }
        }
    };
</script>