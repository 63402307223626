import axios from 'axios';
import Vue from 'vue';
import Sentry from '../../../raven/raven';

const CancelToken = axios.CancelToken;

function sleep(s) {
    return new Promise(resolve => setTimeout(resolve, s * 1000));
}

class FileToDirectUpload {
    constructor(file, profile, anonymous_token) {
        this.file = new File([file], file.name, {
            lastModified: new Date(),
            type: file.type
        });
        this.name = file.name;
        this.profile = profile;
        this.anonymous_token = anonymous_token;
        this.uploadUrls = [];
        this.lastNotUploadedByte = 0;
        this.progress = 0;
        this.uploaded = false;
        this.uploading = false;
        this.canceler = undefined;
        this.uploadingChunkNumber = 0;
        this.chunkSize = 0;
        this.uploadId = undefined;
        this.uploadedETags = [];
        this.encodingId = undefined;
        this.canceled = false;
        this.error = false;
    }

    async authUpload() {
        try {
            const { data } = await axios({
                method: 'POST',
                url: DjangoUrls['api.v1:media_direct_upload-create-upload'](),
                data: {
                    filename: this.file.name,
                    filesize: this.file.size,
                    content_type: this.file.type,
                    profile: this.profile,
                    anonymous_token: this.anonymous_token,
                }
            });
            this.uploadUrls = data['urls'];
            this.uploadId = data['id'];
            this.chunkSize = data['size'];
        } catch (e) {
            Vue.notifications.error(e.response);
            throw new Error(e.response.error || 'Error when authorising upload');
        }
    }

    async uploadChunks() {
        for (const url of this.uploadUrls) {
            const stopByte = Math.min(this.lastNotUploadedByte + this.chunkSize, this.file.size);
            const chunk = this.file.slice(this.lastNotUploadedByte, stopByte);
            try {
                const awsResp = await this._uploadChunk(
                    this.uploadUrls[this.uploadingChunkNumber],
                    chunk,
                );
                this.uploadedETags.push(awsResp.headers.etag.replaceAll('"', ''));
                this.uploadingChunkNumber = this.uploadingChunkNumber + 1;
                this.lastNotUploadedByte = stopByte;
            } catch (e) {
                this.error = true;
            }
        }
    }

    async _uploadChunk(uploadUrl, chunk, attempt = 1) {
        if (this.canceled) {
            return;
        }
        if (this.error) {
            return;
        }
        const self = this;
        const headers = {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/octet-stream'
        };
        try {
            return await axios({
                method: 'PUT',
                url: uploadUrl,
                data: chunk,
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    self.canceler = c;
                }),
                headers,
                onUploadProgress(e) {
                    if (e.lengthComputable) {
                        self.progress = Math.round(((self.lastNotUploadedByte + e.loaded) / self.file.size) * 100);
                    }
                }
            });
        } catch (e) {
            if (attempt < 50) {
                await sleep(1 + Math.min(attempt, 3));
                return await this._uploadChunk(uploadUrl, chunk, attempt + 1);
            } else {
                Sentry.captureException(e);
                throw e;
            }
        }
    }

    async finishUpload() {
        const { data } = await axios({
            method: 'PATCH',
            url: DjangoUrls['api.v1:media_direct_upload-complete-upload'](this.uploadId),
            data: {
                etags: this.uploadedETags,
                anonymous_token: this.anonymous_token,
            }
        });
        return data;
    }

    async startUpload() {
        this.uploading = true;
        await this.authUpload();
        await this.uploadChunks();
        if (!this.canceled && !this.error) {
            this.encodingId = await this.finishUpload();
            return this.encodingId;
        } else {
            throw new Error('Upload error');
        }
    }

    async cancelUpload() {
        this.canceled = true;
        if (this.canceler !== undefined) {
            this.canceler();
        }
    }

}

export default FileToDirectUpload;
