var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "vue-youtube-inline center-block embed-responsive in-vue inline-vimeo__container",
    },
    [
      !_vm.playButtonClicked
        ? _c("img", {
            staticClass: "inline-vimeo__preview-image",
            attrs: {
              src: _vm.item.vimeo_video.preview_url,
              alt: "Video preview",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.playButtonClicked
        ? _c(
            "div",
            {
              staticClass: "inline-vimeo__play-button",
              on: { click: _vm.onPlayClick },
            },
            [
              _c("i", {
                staticClass: "far fa-play inline-vimeo__play-button-icon",
              }),
            ]
          )
        : _c("vue-vimeo-player", {
            attrs: { autoplay: "", "video-id": _vm.item.vimeo_video.video_id },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }