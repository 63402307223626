let angular = require('angular');
let _ = require('lodash');
function DragDropUtils() {
    /**
     * Factory for reusable code;
     */
    return {
        onDropComplete: _.throttle(function (new_index, obj, $event, process_flag, collection, callback) {
            /**
             * @params new_index, obj, $event - standart
             * @param edit_mode {Boolean} flag to process
             * @param profile_stories {Array}  - container for obj
             *
             * @callback
             * calls function with one argument - collection of objects in new order                 *
             */
            if (!process_flag) {
                // message?
                return;
            }
            var oldIndex = collection.indexOf(obj);
            var move_forward = (oldIndex < new_index);
            var new_slides = [];
            if (move_forward) {
                angular.forEach(collection, function (slide, i) {
                    if (i < oldIndex || i > new_index) {
                        new_slides.push(slide);
                        return
                    }
                    if (i < new_index) {
                        new_slides.push(collection[i + 1]);
                        return
                    }
                    if (i == new_index) {
                        new_slides.push(obj);
                    }
                });
                collection = new_slides;
            } else {
                angular.forEach(collection, function (slide, i) {
                    if (i < new_index || i > oldIndex) {
                        new_slides.push(slide);
                        return
                    }
                    if (i > new_index) {
                        new_slides.push(collection[i - 1]);
                        return
                    }
                    if (i == new_index) {
                        new_slides.push(obj);
                    }
                });

            }
            callback(new_slides);

        }, 500, {trailing: false})
    }

}


angular.module('mmApp.shared.DragDropUtils', [])
    .factory('DragDropUtils', DragDropUtils);

export default 'mmApp.shared.DragDropUtils'