var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm.audioCaptionUrl
      ? _c("i", {
          ref: "icon",
          staticClass: "pointer",
          class: _vm.iconClasses,
          attrs: {
            role: "button",
            "aria-label": "Play Audio Caption",
            tabindex: "0",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.toggleSound.apply(null, arguments)
            },
            touchstart: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.toggleSound.apply(null, arguments)
            },
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.toggleSound.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar",
                  ])
                )
                  return null
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.toggleSound.apply(null, arguments)
              },
            ],
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }