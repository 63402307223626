LogoutConfirmationController.$inject = ['$scope', 'simplePopupFactory'];
import urls from 'DjangoUrls';

let {DjangoUrls} = urls;

function LogoutConfirmationController($scope, simplePopupFactory) {

    $scope.logout = function (e) {
        e.preventDefault();
        e.stopPropagation();
        simplePopupFactory.show_popup('Logout', 'Are you sure you want to logout from Multi Me?', 'Yes', 'Cancel')
            .then(y => {
                document.location = DjangoUrls['auth_logout']();
            }, c => {
            });
        return false;
    }

}

export default LogoutConfirmationController