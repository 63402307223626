/**
 * Created by orion on 07.03.17.
 */
import angular from 'angular';
import addMedia from './add_media_factory';
import addMediaFromFolderFactory from './from_folder/add_media_from_folder_factory';
import notifications from './../shared/notifications/notifications_module';
import simplePopups from '../shared/simple_popups/simple_popup_module';
import AddFromMyMedia from './from_folder/AddFromMyMedia';
import addMediaResources from './add_media_resources';
import stickersFactory from './stickers/sticker_factory';
import 'angular-ui-switch';
import createPostFactory from './create_post_factory/createStoryLikePostFactory';


//main shared modules
import 'angular-ui-bootstrap';
import "angular-file-upload";

//directives

import renderStickerDirective from './stickers/render_sticker_directive/renderStickerDirective';

import app_filters from '../shared/filters/filters_module';
import stickersResources from "./stickers/users_stickers_resource";

angular.module('mmApp.add_media', [notifications, simplePopups, app_filters, 'angularFileUpload', 'ui.bootstrap', 'uiSwitch'])
    .factory('AddFromMyMedia', AddFromMyMedia)
    .factory('addMediaFromFolderFactory', addMediaFromFolderFactory)
    .factory('addMediaResources', addMediaResources)
    .factory('addMedia', addMedia)
    .factory('createPostFactory', createPostFactory)
    .factory('stickersResources', stickersResources)
    .factory('stickersFactory', stickersFactory)
    .directive("renderSticker", renderStickerDirective)
;

export default 'mmApp.add_media';
