import MobileDetect from "mobile-detect";
import Modernizr from "../../../3dparty/modernizr-custom";

function detectInputDevice(callback, kind) {
    try {
        let md = navigator.mediaDevices;
        if (!md || !md.enumerateDevices) return callback(false);
        md.enumerateDevices().then(devices => {
            callback(devices.some(device => 'videoinput' === device.kind));
        });

    } catch (e) {
        console.error(e);
    }
}

export default {
    computed: {
        allowCapture() {
            if (!this.config.allow_capture) {
                return false;
            }

            const isIpadOS = navigator.maxTouchPoints &&
                navigator.maxTouchPoints > 2 &&
                /MacIntel/.test(navigator.platform);

            const isIPadPro = /Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;

            const FROM_MOBILE = (new MobileDetect(window.navigator.userAgent).mobile() || isIpadOS || isIPadPro);
            return Modernizr.capture || FROM_MOBILE;
        },
    },
    methods: {
        $_detectMediaDeviseSupport(kind) { // 'video'||'audio'
            return new Promise((res, rej) => {
                const mediaDevisesSupported = navigator.mediaDevices && navigator.mediaDevices.getUserMedia && window.MediaRecorder;
                if (mediaDevisesSupported) {
                    detectInputDevice((supported) => {
                        res(supported);
                    }, `${kind}input`);
                }
            });
        }
    }
};