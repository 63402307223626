var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table-responsive" }, [
    _c("table", { staticClass: "table table-bordered" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        [
          _vm._l(_vm.members, function (member) {
            return [
              _c("members-table-member", {
                key: member.id,
                attrs: {
                  member: member,
                  owner: _vm.owner,
                  isOwner:
                    member.userprofile &&
                    member.userprofile.id === _vm.owner.id,
                },
                on: {
                  openPermissionsTree: _vm.openPermissionsTree,
                  onDelete: _vm.onMemberDelete,
                },
              }),
              _vm._v(" "),
              member.tree_opened
                ? _c("tr", [
                    _c(
                      "td",
                      { attrs: { colspan: "7" } },
                      [
                        _c("media-tree", {
                          key: member.id,
                          attrs: { member: member, owner: _vm.owner },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Shared with")]),
        _vm._v(" "),
        _c("th", [_vm._v("Role")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date Invited")]),
        _vm._v(" "),
        _c("th", [_vm._v("Notify me")]),
        _vm._v(" "),
        _c("th", [_vm._v("Is Admin")]),
        _vm._v(" "),
        _c("th", [_vm._v("Edit Access")]),
        _vm._v(" "),
        _c("th", [_vm._v("Remove Access")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }