<template>
    <div class="whole-day-event" :style="eventStyle">
        <div class="whole-day-event__avatar inline-block" :style="avatarStyle"></div>
        <div class="whole-day-event__name inline-block">
            <strong>
                <router-link :to="eventLink|backHrefQuery" class="vertical-middle">
                <span><i v-if="event.is_periodic" class="fa fa-repeat color-blue"></i> {{
                        event.event_data.name|cutName(amountOfLettersInName)
                    }}</span>
                </router-link>
            </strong>
            <div class="inline-block vertical-middle" v-if="event.event_data.audio_caption" style="min-width: 33px">
                <audio-toggle
                        @click.native.stop
                        :audio-caption="event.event_data.audio_caption"
                        style="font-size: 22px; margin: 0 5px"
                        class="vertical-middle"
                        fa-class="fal"
                ></audio-toggle>
            </div>

            <router-link :to="(eventLink+'comments')|backHrefQuery" v-if="event.comments_count>0"
                         class="vertical-middle color-black">
                <i class="fal fa-comment vertical-middle"></i>
                <span class="vertical-middle" style="position: relative; left: -2px;">{{ event.comments_count }}</span>
            </router-link>
            <i class="fal fa-user-check vertical-middle" v-if="event.going_count>0"></i>
            <span class="vertical-middle margin-5-right" style="position: relative; left: -2px;"
                  v-if="event.going_count>0">
                {{ event.going_count }}
            </span>
            <i class="fal fa-bullseye-pointer vertical-middle" v-if="event.linked_goals_count>0"></i>
            <span class="vertical-middle margin-5-right" style="position: relative; left: -2px;"
                  v-if="event.linked_goals_count>0">
                {{ event.linked_goals_count }}
            </span>
        </div>

    </div>
</template>

<script>
    import Vue from 'vue';
    import * as config from './config';
    import moment from 'moment';
    import {getEventUrl} from "./common";
    import ScreenWidthAwareMixin from "../../../../vue/mixins/ScreenWidthAwareMixin";

    export default {
        props: {
            event: Object,
            index: Number,
            date: String,
            profile: Object
        },
        data() {
            return {
            };
        },
        mixins: [ScreenWidthAwareMixin],
        computed: {
            amountOfLettersInName() {
                if (this.windowWidth > 600) {
                    return 40;
                } else {
                    if (this.windowWidth > 400) {
                        return 20;
                    } else {
                        return 16;
                    }
                }
            },
            dateMoment() {
                return moment(this.date, config.VISUAL_DATE_FORMAT);
            },
            eventLink() {
                const formattedDate = this.dateMoment.format(config.URL_DATE_FORMAT);
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                const calendarOwner = (this.profile && CurrentUser.id !== this.profile.id) ? this.profile : undefined;
                return getEventUrl(this.event, formattedDate, calendarOwner);
            },
            eventStyle() {
                return {
                    height: `${config.SIZES.WHOLE_DAY_EVENT_HEIGHT}px`,
                    'background-color': config.COLORS.getColorForEvent(this.event)
                };
            },
            avatarStyle() {
                return {
                    'background-image': `url('${this.event.event_data.avatar_url}')`,
                    'height': `${config.SIZES.WHOLE_DAY_EVENT_HEIGHT - 10}px`,
                    'width': `${config.SIZES.WHOLE_DAY_EVENT_HEIGHT - 10}px`,
                };
            }
        },
        name: "CalendarPageDayTimelineWholeDayEvent"
    };
</script>

<style scoped lang="scss">
    .whole-day-event {
        margin-bottom: 5px;
        border-radius: 3px;
        padding: 3px;

        &__avatar {
            vertical-align: middle;
            border-radius: 3px;
            background-repeat: no-repeat;
            background-size: contain;
        }

        &__name {
            @media (min-width: 375px) {
                font-size: 16px;
                i.fa {
                    font-size: 22px;
                }
            }

            vertical-align: middle;
            max-width: calc(100% - 30px);
            overflow-x: hidden;
            overflow-y: hidden;
            max-height: 100%;

            a {
                color: #333;
                text-transform: capitalize;
            }
        }
    }
</style>