<template>
    <basic-modal
            @hide="close"
            v-model="opened"
            :aria-label="header"
            class="edd-emotion-popup"
            role="dialog"
    >
        <div slot="header">
            <a id="close_popup"
               ref="closeButton"
               @click="close"
               @keydown.enter="close"
               @keydown.tab.shift.stop.prevent="focusToClose"
               role="button"
               aria-label="Close dialog"
               tabindex="0"></a>
            <h3 id="modal-header"
                @click="setFocusOnFirstSticker"
                @keydown.enter="setFocusOnFirstSticker"
                class="modal-title select-sticker-popup__modal-title"
                tabindex="-1"
            >{{ header }}</h3>
        </div>
        <div class="select-sticker-popup">
            <tabs v-model="activeTab" :justified="true" area-label="Select sticker to add">
                <tab :index="i" v-for="(stickerGroup, i) in visibleStickerGroups" :key="i">
                    <div slot="title">
                        <img
                                :src="stickerGroup.title_sticker_href"
                                class="select-sticker-popup__tab-header-image"
                                :aria-label="stickerGroup.name"
                                role="tab"
                                :alt="stickerGroup.name"
                        />
                    </div>
                    <div class="select-sticker-popup__stickers-container"
                         aria-label="Select Sticker to add."
                         aria-live="polite"
                         ref="stickersContainer"
                    >
                        <div class="select-sticker-popup__stickers-inner-container"
                             v-if="activeTab === visibleStickerGroups.indexOf(stickerGroup)">
                            <div
                                    tabindex="0"
                                    ref="sticker"
                                    class="select-sticker-popup__sticker-wrapper"
                                    :class="{'select-sticker-popup__sticker-wrapper_active':selectedSticker===sticker}"
                                    @click="setSelectedSticker(sticker)"
                                    @keydown.enter="setSelectedSticker(sticker)"
                                    v-for="sticker in getVisibleStickers(stickerGroup)"
                                    :aria-label="sticker.file_name"
                            >
                                <render-sticker
                                        aria-hidden="true"
                                        :responsive="true"
                                        :sticker="sticker"
                                ></render-sticker>
                            </div>
                        </div>
                    </div>
                </tab>
            </tabs>
        </div>
        <div slot="footer">
            <div class="row">
                <div class="col-sm-12">
                    <button
                            class="btn btn-mm"
                            ref="selectButton"
                            :disabled="!selectedSticker"
                            @click="select"
                            role="button"
                    >Select
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <span tabindex="0" aria-hidden="true" class="focus-catch-link" @focus="focusToClose">&nbsp;</span>
                </div>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import BasicModal from "../../../../vue/common_components/BasicModal";
    import PopupPromiseMixin from "../../../../vue/mixins/PopupPromiseMixin";
    import STICKERS from "./stickers_list";
    import RenderSticker from "./render_sticker_directive/RenderSticker";

    export default {
        name: "AddStickerPopup",
        components: {RenderSticker, BasicModal},
        mixins: [PopupPromiseMixin],
        data() {
            return {
                header: 'Select Sticker',
                selectedSticker: undefined,
                activeTab: 0,
                recentStickersGroup: undefined,
            };
        },
        computed: {
            firstFocusEl() {
                return this.$refs.sticker[0] || this.$refs.closeButton;
            },
            visibleStickerGroups() {
                const visibleGroups = [];
                if (this.recentStickersGroup?.stickers?.length) {
                    visibleGroups.push(this.recentStickersGroup);
                }
                for (let group of this.stickers.filter(g => !g.is_legacy)) {
                    visibleGroups.push(group);
                }
                return visibleGroups;
            },
            stickers() {
                return STICKERS;
            },
        },
        methods: {
            setInitial(recentStickersGroup) {
                this.recentStickersGroup = recentStickersGroup;
            },
            setSelectedSticker(sticker) {
                this.selectedSticker = sticker;
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.$refs.selectButton?.focus();
                    }, 50);
                });
            },
            select() {
                this.save(this.selectedSticker);
            },
            getVisibleStickers(stickerGroup) {
                // stickerGroup.name==='Recent'
                return stickerGroup.stickers.filter(s => !s.is_legacy);
            },
            setFocusOnFirstSticker() {
                this.setSelectedSticker(this.getVisibleStickers(this.visibleStickerGroups[this.activeTab])[0]);
                this.$refs.stickersContainer[this.activeTab].scrollTop=0;
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.$_catchFocus(this.$refs.sticker[0]);
                    }, 50);
                });
            },
        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";

  .select-sticker-popup {
    &__modal-title {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    &__tab-header-image {
      height: 30px;
      @media (min-width: $screen-mobile-m-min) {
        height: 35px;
      }
    }

    &__stickers-inner-container {
      padding: 3px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      align-content: stretch;
    }

    &__stickers-container {

      @media (min-width: $screen-md-min) {
        height: 300px;
      }
      @media (max-width: $screen-sm-max) {
        height: 250px;
      }
      overflow-y: auto;
    }

    &__sticker-wrapper {
      display: inline-block;
      cursor: pointer;
      opacity: 0.6;

      &:hover, &.active, &.focus, &_active {
        opacity: 1;
      }

      &_active {
        border-radius: 5px;
        box-shadow: 0px 0px 2px 2px rgba(11, 149, 255, 1);
      }
    }

    &::v-deep {
      @media all and (max-width: $screen-xs-max) {
        .nav {
          &::before, &::after {
            content: none;
            display: none;
          }

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: stretch;
          align-content: stretch;
          flex-wrap: nowrap;
        }

        .nav > li {
          display: inline-block;
        }
        .nav > li > a {
          padding: 3px 3px;
        }
      }
    }
  }
</style>