/**
 * Created by orion on 13.05.17.
 */
locationsPopup.$inject = ['$scope', '$uibModalInstance', '$q', '$sce', 'Maps', 'locations', 'ALLOW_ADD', 'ALLOW_REMOVE'];

function locationsPopup($scope, $uibModalInstance, $q, $sce, Maps, locations, ALLOW_ADD, ALLOW_REMOVE) {

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.close = function () {
        $uibModalInstance.dismiss('close');
    };

    $scope.add_location = function () {
        $uibModalInstance.close({do: 'add location'});
    };

    $scope.get_location_href = Maps.get_gmaps_href;


    $scope.delete_location = function (location) {
        $uibModalInstance.close({do: 'delete location', location_id: location.id});
    };

    $scope.get_location_preview_url = Maps.get_static_map_href;

    $scope.locations = locations;
    $scope.ALLOW_ADD = ALLOW_ADD;
    $scope.ALLOW_REMOVE = ALLOW_REMOVE;
}


export default locationsPopup
