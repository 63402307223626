var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": "Send Read Receipt", role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _c("span", { staticClass: "vertical-middle" }, [
              _vm._v("Send Read Receipt"),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://multime.freshdesk.com/a/solutions/articles/44002368928",
                  target: "_blank",
                },
              },
              [
                _c("i", {
                  staticClass: "far fa-info pointer color-blue vertical-middle",
                }),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "receipt-name" } }, [_vm._v("Name:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name",
                },
              ],
              ref: "receiptNameInput",
              staticClass: "form-control",
              attrs: { type: "text", id: "receipt-name", maxlength: "255" },
              domProps: { value: _vm.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.name = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "receipt-comment" } }, [
              _vm._v("Message:"),
            ]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.senderComment,
                  expression: "senderComment",
                },
              ],
              ref: "receiptCommentInput",
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "receipt-comment",
                placeholder: "Add a message for user",
                maxlength: "1023",
              },
              domProps: { value: _vm.senderComment },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.senderComment = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("wiki-receipt-tree", {
              attrs: {
                sections: _vm.sections,
                "permissions-list": _vm.permissionsList,
                "included-stuff-config": _vm.includedStuffConfig,
              },
              on: { input: _vm.onIncludedStuffConfigUpdate },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("div", { staticClass: "col-sm-12 text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-green",
                attrs: { disabled: !_vm.allowSend },
                on: { click: _vm.ok },
              },
              [_vm._v("Send")]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }