<template>
    <modal v-model="opened" :transition='0' :append-to-body="true" :backdrop="false">
        <div slot="header">
            <a id="close_popup" @click="close_popup"></a>
            <h3 class="modal-title">{{header}}</h3>
        </div>
        <div class="row" style="margin-bottom: 15px">
            <div class="col-sm-12">
                <i>
                    {{text}}
                    (<strong>{{formattedDate}}</strong>).
                </i>
            </div>
        </div>
        <div class="select-menu-container center">
            <div class="select-menu-item">
                <div class="panel panel-primary">
                    <div class="panel-heading shortcuts" @click="select('whole')">
                        <h3 class="panel-title">Every<br>occurrence</h3>
                        <i class="fa fa-repeat select-menu-item__main-icon" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="select-menu-item">
                <div class="panel panel-primary">
                    <div class="panel-heading shortcuts" @click="select('exact')">
                        <h3 class="panel-title">Just<br>{{ formattedDate }}</h3>
                        <i class="fa fa-calendar select-menu-item__main-icon" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer"></div>
    </modal>
</template>

<script>

    import PopupMixin from "../../../../vue/mixins/PopupMixin";

    export default {

        name: "EventDetailPagePeriodSelectorPopup",
        mixins: [PopupMixin],
        data() {
            return {
                opened: false,
                resolve: undefined,
                reject: undefined,
                date: undefined,

                header: '',
                text: ''
            };
        },
        computed: {
            formattedDate() {
                if (!this.date) {
                    return;
                }
                return this.date.format('D MMM');
            },
        },
        methods: {
            show(date, header = 'Add Event to Calendar', text = 'This is a repeated event. You can add every occurrence of this Event to your Calendar, or just this date') {
                this.date = date;
                this.header = header;
                this.text = text;
                const self = this;
                this.opened = true;
                return new Promise((resolve, reject) => {
                    self.resolve = resolve;
                    self.reject = reject;
                });
            },
            select(what) {
                this.resolve(what);
                this.info = '';
                this.close();
            },
            close_popup() {
                this.info = '';
                this.reject('close');
                this.close();
            }
        }

    };
</script>

<style scoped>

</style>