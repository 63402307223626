var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chat-style-comment-reply", style: _vm.commentStyle },
    [
      _vm.showAuthor
        ? _c("div", [
            _c("strong", [_vm._v(_vm._s(_vm.authorName))]),
            _vm._v(" has linked an Entry:\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.comment.linked_blog_entry.activity_post &&
      _vm.comment.linked_blog_entry.activity_post.sticker
        ? _c(
            "div",
            { staticStyle: { "font-size": "0" } },
            [
              _c("render-sticker", {
                attrs: {
                  sticker: _vm.comment.linked_blog_entry.activity_post.sticker,
                  "default-size": 50,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.commentTextPreview
        ? _c("div", [_vm._v(_vm._s(_vm.commentTextPreview))])
        : _vm._e(),
      _vm._v(" "),
      _vm.firstMedia
        ? _c(
            "div",
            { staticStyle: { color: "#333" } },
            [
              _c("media-preview", {
                attrs: { item: _vm.firstMedia, size: 80 },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.comment.linked_blog_entry && _vm.comment.linked_blog_entry.emotion
        ? _c(
            "div",
            [
              _c("entry-emotion", {
                attrs: {
                  emotion: _vm.comment.linked_blog_entry.emotion,
                  size: 30,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }