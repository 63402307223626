<template>
    <div class="chat-style-comment-reply" :style="commentStyle">
        <div v-if="showAuthor"><strong>{{ authorName }}</strong> has linked Diary Log entry:</div>
        <div v-if="commentTextPreview">{{ commentTextPreview }}</div>
    </div>
</template>

<script>
    import {getAuthorNameFromComment} from '../../shared/comments/common';
    import RenderSticker from "../../add_media/stickers/render_sticker_directive/RenderSticker";
    import EntryEmotion from "../../blogolog/emotion_directive/EntryEmotion";

    export default {
        name: "ChatStyleCommentReplyLinkedBlogAnswer",
        components: {EntryEmotion, RenderSticker},
        props: {
            comment: Object,
            noBorder: {
                type: Boolean,
                default: false
            },
            showAuthor: {
                type: Boolean,
                default: true
            },
            whiteBorder: {
                type: Boolean,
                default: false
            },
        },
        computed: {
            authorName() {
                return getAuthorNameFromComment(this.comment);
            },
            commentTextPreview() {
                if (this.comment.linked_blog_answer?.answers?.length) {
                    const answer = this.comment.linked_blog_answer.answers.join('; ');
                    return this.$options.filters.cutName(answer, 15);
                }
            },
            commentStyle() {
                if (this.noBorder) {
                    return {
                        'margin-left': 0,
                    };
                }
                if (this.whiteBorder) {
                    return {
                        'border-left': '3px solid #fff'
                    };
                } else {
                    return {
                        'border-left': '3px solid #333'
                    };
                }
            }
        }
    };
</script>

<style scoped lang="scss">
  .chat-style-comment-reply {
    margin-left: 15px;
    padding: 5px;
  }
</style>