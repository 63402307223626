var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "media-tree" }, [
    _vm.allowAssignEditPermission
      ? _c("i", {
          staticClass: "fa fa-pencil",
          class: _vm.editButtonClasses,
          on: {
            click: function ($event) {
              return _vm.toggleMediaLevelEditPermission()
            },
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _c("i", {
      staticClass: "fa fa-eye",
      class: _vm.viewButtonClasses,
      on: {
        click: function ($event) {
          return _vm.toggleMediaLevelViewPermissionLevel()
        },
      },
    }),
    _vm._v(" "),
    _c("i", { staticClass: "fa fa-folder" }),
    _vm._v(
      " " +
        _vm._s(_vm.currentUser.name) +
        "'s Media (" +
        _vm._s(_vm.mediaCount) +
        " Media files inside)\n    "
    ),
    _c(
      "div",
      { staticClass: "media-tree__subfolders-list" },
      _vm._l(_vm.folders, function (folder) {
        return _c("media-tree-folder", {
          key: folder.id,
          attrs: {
            "current-user": _vm.currentUser,
            "permissions-list": _vm.permissionsList,
            "allow-assign-edit-permission": _vm.allowAssignEditPermission,
            member: _vm.member,
            folder: folder,
            inheritedPermissionLevel: _vm.resultPermissionLevel,
          },
          on: { setFolderLevelPermission: _vm.setFolderLevelPermission },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }