const DraggableMethodsMixin = {
    props: {},
    computed: {},
    data() {
        return {
            dragOn: false,
            isDragged: false,
            dragCounter: 0
        };
    },
    methods: {
        onDragEnter() {
            this.dragCounter++;
            if (this.dragCounter > 0) {
                this.dragOn = true;
            }

        },
        onDragLeave() {
            if(this.dragCounter>0){
                this.dragCounter--;
            }
            if (this.dragCounter === 0) {
                this.dragOn = false;
            }
        },
        onDragStart(e) {
            e.dataTransfer.setData('Text', `${this.id}`);
            this.isDragged = true;
            this.$emit('onDragStart');
        },
        onDragEnd() {
            this.isDragged = false;
            this.dragCounter = 0;
            this.dragOn = false;
            this.$emit('onDragEnd');
        },
        onDrop(e) {
            this.isDragged = false;
            this.dragCounter = 0;
            this.dragOn = false;
            this.$emit('onDrop');
        },
    }
};

export default DraggableMethodsMixin;