/**
 * Created by orion on 08.06.17.
 */

import urls from 'DjangoUrls';

let {DjangoUrls} = urls;

function MembersResourceFactory(prefix) {

    let members_resource = function ($http) {
        return {
            get_members_for_members_page(target_id) {
                const url = DjangoUrls[`api.v1:${prefix}-get-members-for-members-page`](target_id);
                return $http({
                    method: 'GET',
                    url
                })
            },
            get_users_for_invite(target_id, params) {
                const url = DjangoUrls[`api.v1:${prefix}-get-users-for-invite`](target_id);
                return $http({
                    method: 'GET',
                    url,
                    params
                })
            },
            invite_users: function (target_id, new_checked_ids, new_unchecked_ids) {
                const url = DjangoUrls[`api.v1:${prefix}-invite-users`](target_id);
                return $http({
                    method: 'POST',
                    url,
                    data: {
                        new_checked_ids, new_unchecked_ids
                    }
                })
            },
            join: function (target_id) {
                const url = DjangoUrls[`api.v1:${prefix}-join`](target_id);
                return $http({
                    method: 'POST',
                    url
                })
            },
            get_admins_for_manage(target_id, params) {
                const url = DjangoUrls[`api.v1:${prefix}-get-admins-for-manage`](target_id);
                return $http({
                    method: 'GET',
                    url,
                    params
                })
            },
            update_admins: function (target_id, new_checked_ids, new_unchecked_ids) {
                const url = DjangoUrls[`api.v1:${prefix}-update-admins`](target_id);
                return $http({
                    method: 'POST',
                    url,
                    data: {
                        new_checked_ids, new_unchecked_ids
                    }
                })
            },

            delete_member: function (target_id, user_id) {
                const url = DjangoUrls[`api.v1:${prefix}-delete-member`](target_id);
                return $http({
                    method: 'POST',
                    url,
                    data: {
                        id: user_id
                    }
                })
            },
        }
    }
    members_resource.$inject = ['$http'];

    return members_resource;
}

export default MembersResourceFactory
