<template>
    <div class="row">
        <div class="col-sm-12 text-center pointer">
            <strong @click="toggleChildrenTasks">
                <span v-if="value"><i class="far fa-angle-double-down"></i> Show {{duplicationsCount}} {{duplicationPlural}} <i
                        class="far fa-angle-double-down"></i></span>
                <span v-else><i class="far fa-angle-double-up"></i> Hide {{duplicationsCount}} {{duplicationPlural}} <i
                        class="far fa-angle-double-up"></i></span>
            </strong>
        </div>
    </div>
</template>

<script>
    export default {
        name: "GoalTaskDuplicationsToggle",
        props: {
            value: Boolean,
            duplicationsCount: Number
        },
        computed: {
            duplicationPlural() {
                if (this.duplicationsCount === 1) {
                    return 'duplication';
                } else {
                    return 'duplications';
                }
            }
        },
        methods: {
            toggleChildrenTasks() {
                this.$emit('input', !this.value);
            }
        }
    };
</script>

<style scoped>

</style>