var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.value > 0,
          expression: "value>0",
        },
      ],
      staticClass: "icon-counter",
    },
    [_vm._v(_vm._s(_vm.value))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }