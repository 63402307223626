let angular = require('angular');
import './style.scss';
import regValidationResource from '../registration_resource';
import picturePasswordFactory from 'shared/picture_password/picture_password_factory';

registrationForm.$inject = ['$rootScope', '$timeout', 'regValidationResource', 'simplePopupFactory'];
const PASSWORD_STRENGTH_LABELS = ['Terrible!', 'Bad', 'OK', 'Good', 'Perfect!'];
const PASSWORD_STRENGTH_CLASSES = ['terrible', 'bad', 'ok', 'good', 'perfect'];

function registrationForm($rootScope, $timeout, regValidationResource, simplePopupFactory) {
    return {
        restrict: 'E',
        replace: true,
        transclude: {
            additionalInfo: '?additionalInfo',
            additionalInfoTop: '?additionalInfoTop',
        },
        scope: {
            onSave: '='
        },
        template: require('./template.html'),
        link: function ($scope, element, attrs, controller) {
            $scope.terms_accepted = false;
            $scope.register_data = {
                use_picture_password: false,
                name: {
                    value: '',
                    validated: false,
                    has_error: false,
                    has_success: false,
                    message: ''
                },
                email: {
                    value: '',
                    validated: false,
                    has_error: false,
                    has_success: false,
                    message: ''
                },
                first_name: { value: '' },
                last_name: { value: '' },
                password: {
                    value: '',
                    validated: false,
                    has_error: false,
                    has_success: false,
                    message: ''
                },
                passwordconfirmation: {
                    value: '',
                    validated: false,
                    has_error: false,
                    has_success: false,
                    message: ''
                }
            };

            // <validate universal section>
            function set_validation_values(param, available, message) {
                $scope.$apply(() => {
                    $scope.register_data[param].message = message;
                    $scope.register_data[param].has_success = available;
                    $scope.register_data[param].has_error = !available;
                    $scope.register_data[param].validated = true;
                });

            }

            function reset_validation_values(param) {
                $scope.register_data[param].message = '';
                $scope.register_data[param].has_success = false;
                $scope.register_data[param].has_error = false;
                $scope.register_data[param].validated = false;

            }

            // </validate universal section>

            // <validate username section>

            let _validate_username = function (username) {
                regValidationResource.username(username).then(function successCallback(response) {
                    set_validation_values('name', response.data.available, response.data.message);
                }, function errorCallback(result) {
                    set_validation_values('name', false, result.response.data.username[0]);
                });
            };

            let validate_username_promise;

            $scope.validate_username = function () {
                reset_validation_values('name');
                if (angular.isDefined(validate_username_promise)) {
                    $timeout.cancel(validate_username_promise);
                }
                validate_username_promise = $timeout(_validate_username, 500, true, $scope.register_data.name.value);
            };

            // </ validate username section >

            $scope.checkPasswordStrength = () => {
                $scope.passwordStrengthLabel = 'Checking...';
                $scope.passwordStrengthClass = 'checking';
                regValidationResource.password($scope.register_data.password.value)
                    .then(resp => {
                        $scope.passwordStrengthClass = PASSWORD_STRENGTH_CLASSES[resp.data];
                        $scope.passwordStrengthLabel = PASSWORD_STRENGTH_LABELS[resp.data];
                    });
            };

            $scope.password_changed = function () {
                $scope.validate_password();
                $scope.register_data.use_picture_password = false;
                if ($scope.register_data.password.value) {
                    const value = $scope.register_data.password.value;
                    if (value.length < 3) {
                        $scope.passwordStrengthLabel = 'Checking...';
                        return;
                    }
                    if ($scope.checkingUsernameTimeoutHandle !== undefined) {
                        clearTimeout($scope.checkingUsernameTimeoutHandle);
                    }
                    $scope.checkingUsernameTimeoutHandle = setTimeout($scope.checkPasswordStrength, 600);
                }
            };

            $scope.showPasswordStrength = function () {
                return (!$scope.register_data.use_picture_password) && $scope.register_data.password.value && ($scope.register_data.password.value.length > 3);
            };

            $scope.SHOW_PASSWORD = false;
            $scope.toggle_password_show_mode = function () {
                $scope.SHOW_PASSWORD = !$scope.SHOW_PASSWORD;
            };

            $scope.reset_passwords = function () {

                reset_validation_values('password');
                reset_validation_values('passwordconfirmation');
                $scope.register_data.passwordconfirmation.value = '';
                $scope.register_data.password.value = '';
                $scope.register_data.use_picture_password = false;
            };

            // <validate password section>
            $scope.validate_password = function () {
                if ($scope.register_data.password.value && $scope.register_data.password.value.length) {
                    if ($scope.register_data.password.value.length >= 8) {
                        set_validation_values('password', true, '');
                    } else {
                        set_validation_values('password', false, 'At least 8 characters');
                    }

                } else {
                    set_validation_values('password', false, 'Required');
                }
            };

            let validate_password_confirmation_promise;

            let _validate_password_confirmation = function (passwordconfirmation) {
                if ($scope.register_data.password.value === passwordconfirmation) {
                    set_validation_values('passwordconfirmation', true, '');
                    set_validation_values('password', true, '');
                } else {
                    set_validation_values('password', false, '');
                    set_validation_values('passwordconfirmation', false, 'Password doesn\'t match confirmation');
                }
            };

            $scope.validate_password_confirmation = function () {

                if (angular.isDefined(validate_password_confirmation_promise)) {
                    $timeout.cancel(validate_password_confirmation_promise);
                }
                validate_password_confirmation_promise = $timeout(_validate_password_confirmation, 800, true, $scope.register_data.passwordconfirmation.value);
            };

            // </validate password section>

            // <validate email section>

            let _validate_email = function (email) {
                function validateEmail(email) {
                    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(email);
                }

                set_validation_values('email', validateEmail(email), '');
            };

            let validate_email_promise;

            $scope.validate_email = function () {
                reset_validation_values('email');
                if (angular.isDefined(validate_email_promise)) {
                    $timeout.cancel(validate_email_promise);
                }
                validate_email_promise = $timeout(_validate_email, 500, true, $scope.register_data.email.value);
            };

            // </ validate email section >

            $scope.is_registration_possible = function () {
                if (!$scope.terms_accepted) {
                    return false;
                }
                if (!($scope.register_data.email.has_success && $scope.register_data.name.has_success)) {
                    return false;
                }
                if ($scope.register_data.password.has_success && ($scope.register_data.passwordconfirmation.has_success || $scope.SHOW_PASSWORD)) {
                    return true;
                }
                return false;
            };

            $scope.show_symbol_password_form = function () {
                if ($scope.register_data.passwordconfirmation.value) {
                    $scope.reset_passwords();
                }

                picturePasswordFactory.input_password()
                    .then(pass => {
                        $scope.register_data.password.value = pass;
                        return picturePasswordFactory.input_password({
                            header: 'Confirm Password'
                        });
                    })
                    .then(pass => {
                        $scope.register_data.passwordconfirmation.value = pass;
                        if ($scope.register_data.passwordconfirmation.value == $scope.register_data.password.value) {
                            set_validation_values('passwordconfirmation', true, '');
                            set_validation_values('password', true, '');
                            $scope.register_data.use_picture_password = true;
                            $scope.$apply();
                        } else {
                            simplePopupFactory.show_popup('Error', 'Password doesn\'t match confirmation.', 'OK');
                            $scope.reset_passwords();
                        }
                    });
            };

            $scope.registration_data_send = function (e) {
                e.preventDefault();
                e.stopPropagation();
                let reg_data = {
                    username: $scope.register_data.name.value,
                    password: $scope.register_data.password.value,
                    email: $scope.register_data.email.value,
                    first_name: $scope.register_data.first_name.value,
                    last_name: $scope.register_data.last_name.value,
                };
                $scope.onSave(reg_data);
            };
        }
    };
}

angular.module('mmApp.shared.registrationForm', [])
    .factory('regValidationResource', regValidationResource)
    .directive('registrationForm', registrationForm);

export default 'mmApp.shared.registrationForm';
