import {WIKI_PERMISSION_LEVEL, WIKI_PERMISSION_VALUE} from "../../../wiki_page/common/wikiPermissions";

export default {
    props: {

    },
    data() {
        return {
        };
    },
    computed: {
        inheritedPermissionLevel() {
            return WIKI_PERMISSION_VALUE.UNSET;
        },
        ownPermissionLevel() {
            const ownPermission = this.permissionsList.filter(p => p.level === WIKI_PERMISSION_LEVEL.WHOLE_WIKI)[0];
            return ownPermission?.value || WIKI_PERMISSION_VALUE.UNSET;
        },
        resultPermissionLevel() {
            if (this.ownPermissionLevel === WIKI_PERMISSION_VALUE.UNSET) {
                return this.inheritedPermissionLevel;
            } else {
                return this.ownPermissionLevel;
            }
        },
    }
};