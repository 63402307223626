<template>
    <div class="vue-folder-item__container"
         tabindex="0"
         role="figure"
         @keydown.enter="itemClick"
         :style="containerSize"
         :aria-label="attachment.name"
    >
        <div class="vue-folder-item__delete-button hidden-print"
             @click.prevent.stop="deleteClick"
             aria-label="Delete"
             role="button"
             tabindex="0"
             @keydown.enter.stop.prevent="deleteClick"
             v-if="allowDelete">
            <i class="fa fa-trash-o" aria-hidden="true"></i>
        </div>
        <div class="vue-folder-item" v-tooltip="tooltipText">
            <div class="vue-folder-item__preview text-center" :style="previewStyle"
                 @click="itemClick"
            >
                <img v-if="!iconNeeded" class="visible-print vue-folder-item__attachment-print-image"
                     :src="attachment.thumbnail" alt="">
                <i v-if="iconNeeded"
                   class="fa vue-folder-item__preview__icon"
                   :class="iconClass"
                   :style="iconStyle"
                   aria-hidden="true"></i>
            </div>
            <div class="vue-folder-item__footer">
                <div class="vue-folder-item__footer__name" :id="`vue-folder-item__name_${attachment.id}`">
                    <span>{{attachment.name}}</span>
                </div>
                <div class="vue-folder-item__footer__icon">
                    <i :class="iconClass" class="fa" aria-hidden="true"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    const ICON_NEEDED_CHECKERS = {
        'folder': f => f.media_content_samples.length === 0,
        'uploaded_document': f => true,
        'uploaded_audio': f => true,
        'recorded_audio': f => true,
        'location_point': f => true,
        'link': f => !f.link.image_url,
        'upl_video': f => !f.upl_video.encoding_done,
        'image': f => false,
        'youtube_video': f => false,
        'vimeo_video': f => false,
    };

    const ICONS = {
        'image': 'fa-picture-o',
        'upl_video': 'fa-video-camera',
        'folder': 'fa-folder',
        'youtube_video': 'fa-youtube',
        'vimeo_video': 'fa-vimeo',
        'uploaded_document': 'fa-file-text-o',
        'link': 'fa-globe',
        'location_point': 'fa-map-marker',
        'uploaded_audio': 'fa-volume-up',
        'recorded_audio': 'fa-volume-up',
    };

    export default {
        name: "FolderItem",
        props: {
            attachment: Object,
            size: {
                default: 152,
                type: Number,
            },
            allowDelete: {
                type: Boolean,
                default: false,
            },
            showTooltip: {
                type: Boolean,
                default: true,
            },
        },
        computed: {
            tooltipText() {
                if (this.showTooltip) {
                    return this.attachment.name;
                }
            },
            currentSize() {
                return this.size;
            },
            iconNeeded() {
                return ICON_NEEDED_CHECKERS[this.attachment.type](this.attachment);
            },
            iconClass() {
                return ICONS[this.attachment.type];
            },
            containerSize() {
                return {
                    width: `${this.currentSize}px`,
                    height: `${this.currentSize}px`,
                };
            },
            previewStyle() {
                if (this.attachment.thumbnail) {
                    return {
                        'background-image': `url('${this.attachment.thumbnail}')`,
                        'background-size': 'cover',
                        'background-repeat': 'no-repeat',
                        'background-position': 'center'
                    };
                }
            },
            iconStyle() {
                return {
                    'font-size': `${this.currentSize / 4 * 2.5}px`,
                    'margin-top': `${this.currentSize / 16}px`,
                };
            }
        },
        methods: {
            itemClick() {
                this.$emit('click');
            },
            deleteClick() {
                this.$emit('delete');
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../angular/app/styles/const";
    @import "../../angular/app/styles/mixins";

    .vue-folder-item {
        font-size: 14px;
        border-radius: 5px;
        background-color: #FFF;
        overflow: hidden;
        cursor: pointer;
        border: solid 1px $tag-gray;
        height: 100%;
        @media print {
            border: solid 1px #333;
        }
        &__container {
            position: relative;

            margin: 10px;
            @media (max-width: $screen-xs-max) {
                margin: 5px;
            }
            display: inline-block;
            border-radius: 5px;
            vertical-align: middle;
        }
        &__preview {
            height: 75%;
            overflow: hidden;
            &__icon {
                color: $tag-gray;
            }
        }
        &__footer {
            height: 25%;
            &__name {
                display: inline-block;
                height: 100%;
                width: calc(100% - 25px);
                overflow: hidden;
                vertical-align: top;
            }
            &__icon {
                height: 100%;
                display: inline-block;
                width: 20px;
                vertical-align: top;
                font-size: 18px;
            }
        }
        &__delete-button {
            @include circleDeleteButton;
        }

        &__attachment-print-image {
            max-width: 100%;
            /*max-height: 100%;*/
            margin: auto;
            vertical-align: middle;
        }

    }
</style>
