<template>
    <div class="section-background">
        <div class="section-background__none" v-if="samplesCount===0">
            <i class="fa fa-image"></i>
        </div>
        <div class="section-background__single" v-if="samplesCount===1" :style="firstBackgroundImage"></div>
        <div class="section-background__double" v-if="samplesCount===2">
            <div class="section-background__double__first" :style="firstBackgroundImage"></div>
            <div class="section-background__double__second" :style="secondBackgroundImage"></div>
        </div>
        <div class="section-background__triple" v-if="samplesCount===3">
            <div class="section-background__triple__right" :style="firstBackgroundImage">
            </div>
            <div class="section-background__triple__left">
                <div class="section-background__triple__left__second" :style="secondBackgroundImage"></div>
                <div class="section-background__triple__left__third" :style="thirdBackgroundImage"></div>
            </div>
        </div>
        <div class="section-background__quadruple" v-if="samplesCount===4">
            <div class="section-background__quadruple__left">
                <div class="section-background__quadruple__left__first" :style="firstBackgroundImage"></div>
                <div class="section-background__quadruple__left__second" :style="secondBackgroundImage"></div>
            </div>
            <div class="section-background__quadruple__right">
                <div class="section-background__quadruple__right__third" :style="thirdBackgroundImage"></div>
                <div class="section-background__quadruple__right__fourth" :style="fourthBackgroundImage"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            contentSamples: {
                type: Array,
                default: []
            }
        },
        computed: {
            samplesCount() {
                return Math.min(this.contentSamples.length, 4);
            },
            firstBackgroundImage() {
                if (this.samplesCount > 0) {
                    return {
                        'background-image': `url('${this.contentSamples[0]}')`
                    };
                }
            },
            secondBackgroundImage() {
                if (this.samplesCount > 1) {
                    return {
                        'background-image': `url('${this.contentSamples[1]}')`
                    };
                }
            },
            thirdBackgroundImage() {
                if (this.samplesCount > 2) {
                    return {
                        'background-image': `url('${this.contentSamples[2]}')`
                    };
                }
            },
            fourthBackgroundImage() {
                if (this.samplesCount > 3) {
                    return {
                        'background-image': `url('${this.contentSamples[3]}')`
                    };
                }
            }
        },
        name: "WikiSectionPageSectionBackground"
    };
</script>

<style scoped lang="scss">
    @import "../styles/const";

    .section-background {
        height: 100%;
        width: 100%;
        /*background-color: firebrick;*/
        position: relative;
        font-size: 0;

        @mixin coverBackground() {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }

        @mixin fillParent() {
            height: 100%;
            width: 100%;
        }

        @mixin horizontalSplit {
            display: block;
            height: 50%;
            width: 100%;
        }
        @mixin verticalSplit {
            display: inline-block;
            height: 100%;
            width: 50%;
        }

        &__none {
            background-image: url("/static/images/icons/picture-placeholder.svg");
            background-size: 60%;
            background-repeat: no-repeat;
            background-position: center;
            @include fillParent;
            font-size: 30%;
            text-align: center;
            line-height: 100%;
            color: $black;
        }

        &__single {
            @include fillParent;
            @include coverBackground;
        }

        &__double {
            @include fillParent;

            &__first {
               @include verticalSplit;
            }

            &__second {
                @include verticalSplit;
            }
        }

        &__triple {
            @include fillParent;

            &__right {
                @include verticalSplit;

                &__first {
                    @include coverBackground;
                    @include verticalSplit;
                }
            }

            &__left {
                @include verticalSplit;

                &__second {
                    @include coverBackground;
                    @include horizontalSplit;
                }

                &__third {
                    @include coverBackground;
                    @include horizontalSplit;
                }
            }
        }

        &__quadruple {
            @include fillParent;

            &__left {
                @include verticalSplit;

                &__first {
                    @include coverBackground;
                    @include horizontalSplit;
                }

                &__second {
                    @include coverBackground;
                    @include horizontalSplit;
                }
            }

            &__right {
                @include verticalSplit;

                &__third {
                    @include coverBackground;
                    @include horizontalSplit;
                }

                &__fourth {
                    @include coverBackground;
                    @include horizontalSplit;
                }
            }
        }
    }

</style>