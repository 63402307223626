var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.actualConfig.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.actualConfig.header)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "hotkey",
              rawName: "v-hotkey",
              value: _vm.keymap,
              expression: "keymap",
            },
          ],
          staticClass: "espc",
        },
        [
          _vm.actualConfig.info && (_vm.allow_all || _vm.actualConfig.long_text)
            ? _c("div", { staticClass: "row margin-5-bottom" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _vm._v(_vm._s(_vm.actualConfig.info)),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row", staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "div",
                { staticClass: "col-sm-7" },
                [
                  _vm.actualConfig.info &&
                  !_vm.allow_all &&
                  !_vm.actualConfig.long_text
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-12" }, [
                          _vm._v(_vm._s(_vm.actualConfig.info)),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(
                    _vm.additionalFiltersLeftSlot,
                    function (additionalFilter) {
                      return _c(
                        "div",
                        { staticClass: "row espc__additional-filter" },
                        [
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: additionalFilter.value,
                                    expression: "additionalFilter.value",
                                  },
                                ],
                                staticClass: "form-control vertical-middle",
                                staticStyle: { height: "40px" },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        additionalFilter,
                                        "value",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function ($event) {
                                      return _vm.onAdditionalFilterChange(
                                        additionalFilter
                                      )
                                    },
                                  ],
                                },
                              },
                              _vm._l(
                                additionalFilter.options,
                                function (option) {
                                  return _c(
                                    "option",
                                    { domProps: { value: option.value } },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(option.name) +
                                          "\n                            "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ]
                      )
                    }
                  ),
                  _vm._v(" "),
                  _vm.allow_all
                    ? _c("vue-switch", {
                        ref: "selectAllSwitch",
                        attrs: { name: "all" },
                        model: {
                          value: _vm.allChecked,
                          callback: function ($$v) {
                            _vm.allChecked = $$v
                          },
                          expression: "allChecked",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.allow_all
                    ? _c("span", {
                        staticClass: "after-switch-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.actualConfig.select_all_html),
                        },
                      })
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              !_vm.actualConfig.disable_search
                ? _c(
                    "div",
                    { staticClass: "col-sm-5 text-right" },
                    [
                      _c("vue-search-input", {
                        attrs: { "min-letters-count": 2 },
                        on: { search: _vm.search, cancel: _vm.cancelSearch },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm._l(
            _vm.additionalFiltersDefaultSlot,
            function (additionalFilter, i) {
              return _c(
                "div",
                { staticClass: "row", staticStyle: { "margin-bottom": "5px" } },
                [
                  _c(
                    "div",
                    { staticClass: "col-sm-12" },
                    [
                      additionalFilter.component
                        ? _c(
                            additionalFilter.component,
                            _vm._b(
                              {
                                tag: "component",
                                on: {
                                  onCustomFilterChanged:
                                    _vm.onCustomFilterChanged,
                                },
                              },
                              "component",
                              additionalFilter.componentAttrs,
                              false
                            )
                          )
                        : _c("div", [
                            _c(
                              "div",
                              [
                                _c("vue-switch", {
                                  staticClass: "vertical-middle",
                                  attrs: {
                                    "aria-label": additionalFilter.text,
                                    name: `af-${i}`,
                                    disabled:
                                      _vm.isFilterDisabled(additionalFilter),
                                    tabindex: "0",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onAdditionalFilterChange(
                                        additionalFilter
                                      )
                                    },
                                  },
                                  model: {
                                    value: additionalFilter.value,
                                    callback: function ($$v) {
                                      _vm.$set(additionalFilter, "value", $$v)
                                    },
                                    expression: "additionalFilter.value",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "vertical-middle",
                                    class: {
                                      "color-tag-gray":
                                        _vm.isFilterDisabled(additionalFilter),
                                    },
                                  },
                                  [_vm._v(_vm._s(additionalFilter.text))]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            additionalFilter.disabledMessage &&
                            _vm.isFilterDisabled(additionalFilter)
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "alert alert-warning margin-15-top",
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          additionalFilter.disabledMessage
                                        ) +
                                        "\n                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                    ],
                    1
                  ),
                ]
              )
            }
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", { staticClass: "espc__items-list" }, [
                _vm.searchExecuting
                  ? _c(
                      "span",
                      { staticClass: "espc__empty-list-holder" },
                      [_vm._v("Loading"), _c("typing-dots")],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.itemsForSelect.length && !_vm.searchExecuting
                  ? _c("span", { staticClass: "espc__empty-list-holder" }, [
                      _vm._v(_vm._s(_vm.emptyMessage)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.searchExecuting
                  ? _c(
                      "div",
                      {
                        staticClass: "espc__items-list-inner",
                        attrs: { "aria-live": "polite" },
                      },
                      [
                        _vm._l(_vm.itemsForSelect, function (item) {
                          return _c("endpoint-selector-popup-item", {
                            key: item.id,
                            ref: "item",
                            refInFor: true,
                            attrs: {
                              "aria-label": item.name,
                              "aria-checked": _vm.isItemChecked(item),
                              role: "checkbox",
                              item: item,
                              checked: _vm.isItemChecked(item),
                              unconfirmed: _vm.isItemUnconfirmed(item),
                              "no-border-radius-on-default-avatar":
                                _vm.actualConfig
                                  .no_border_radius_on_default_avatar,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.toggleCheck(item)
                              },
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.toggleCheckByEnter(item)
                              },
                            },
                          })
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.pseudoItems, function (item) {
                          return _c("div", { staticClass: "espc__pseudo-item" })
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-12 text-right" },
              [
                _c("pagination", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.totalPage > 1,
                      expression: "totalPage>1",
                    },
                  ],
                  staticClass: "espc__pagination",
                  attrs: {
                    role: "pagination",
                    "total-page": _vm.totalPage,
                    "max-size": 3,
                  },
                  on: { change: _vm.onPageChanged },
                  model: {
                    value: _vm.currentPage,
                    callback: function ($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _vm._l(_vm.actualConfig.additional_buttons, function (button) {
                return _c(
                  "button",
                  {
                    class: button.classes,
                    staticStyle: { margin: "5px 0 5px 5px" },
                    on: {
                      click: function ($event) {
                        return _vm.additional_button_pressed(button.result_code)
                      },
                    },
                  },
                  [_vm._v(_vm._s(button.text) + "\n                ")]
                )
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-mm btn-primary",
                  staticStyle: { margin: "5px 0 5px 5px" },
                  attrs: { type: "button" },
                  on: { click: _vm.back },
                },
                [
                  _vm._v(
                    _vm._s(_vm.actualConfig.button_no) + "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.actualConfig.button_yes
                ? _c(
                    "button",
                    {
                      ref: "saveButton",
                      staticClass: "btn btn-mm btn-green",
                      staticStyle: { margin: "5px 0 5px 5px" },
                      attrs: {
                        id: "esp-save",
                        disabled: _vm.nothingSelected && !_vm.allow_null,
                        type: "button",
                      },
                      on: { click: _vm.yes },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.actualConfig.button_yes) +
                          "\n                "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "span",
              {
                staticClass: "focus-catch-link",
                attrs: { tabindex: "0", "aria-hidden": "true" },
                on: { focus: _vm.focusToClose },
              },
              [_vm._v(" ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }