var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "selector-popup",
    {
      ref: "popup",
      attrs: { header: _vm.header, items: _vm.items },
      on: { selected: _vm.onSelected },
    },
    [
      _vm.info
        ? _c(
            "div",
            { attrs: { slot: "descriptionTop" }, slot: "descriptionTop" },
            [_vm._v("\n        " + _vm._s(_vm.info) + "\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.webcamStateDetected
        ? _c(
            "template",
            { slot: "invisibleForms" },
            [
              _c("form", { attrs: { enctype: "multipart/form-data" } }, [
                _c("input", {
                  ref: "fileCapture",
                  staticStyle: { display: "none" },
                  attrs: {
                    type: "file",
                    name: "file",
                    accept: "image/*",
                    capture: "camera",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.showCropper($event)
                    },
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  ref: "fileUpload",
                  staticStyle: { display: "none" },
                  attrs: { type: "file", name: "file", accept: "image/*" },
                  on: {
                    change: function ($event) {
                      return _vm.showCropper($event)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("avatar-crop-popup", { ref: "AvatarCropPopup" }),
              _vm._v(" "),
              _c("picture-from-webcam-component", {
                ref: "PictureFromWebcamComponent",
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _vm.cancelButton
          ? _c(
              "button",
              {
                staticClass: "btn btn-mm btn-default",
                on: { click: _vm.closePopup },
              },
              [
                _c("i", { staticClass: "fa fa-times" }),
                _vm._v(
                  "\n            " + _vm._s(_vm.cancelButton) + "\n        "
                ),
              ]
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }