<template>
    <modal v-model="opened" :transition='0'>
        <div slot="header">
            <a id="close_popup" @click="close"></a>
            <h3 class="modal-title">{{header}}</h3>
        </div>
        <div class="row" style="margin-bottom: 15px">
            <div class="col-sm-12">

            </div>
        </div>
        <div class="select-menu-container center">
            <div class="select-menu-item">
                <div class="panel panel-primary">
                    <div class="panel-heading shortcuts" @click="select('copy')">
                        <h3 class="panel-title">Copy<br>link</h3>
                        <i class="far fa-copy select-menu-item__main-icon" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="select-menu-item">
                <div class="panel panel-primary">
                    <div class="panel-heading shortcuts" @click="select('facebook')">
                        <h3 class="panel-title">Share on<br>Facebook</h3>
                        <i class="fab fa-facebook-f select-menu-item__main-icon" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer"></div>
    </modal>
</template>

<script>
    import SelectorPopupMixin from "../../../vue/mixins/SelectorPopupMixin";

    export default {
        name: "WayToShareSelectorPopup",
        mixins: [SelectorPopupMixin],
        props: {
            link: String,
        },
        data() {
            return {
                header: '',
                href: '',
            };
        },
        methods: {
            show(href, header = 'Share Community') {
                const self = this;
                this.header = header;
                this.href = href;
                this.opened = true;
                return new Promise((resolve, reject) => {
                    self.resolve = resolve;
                    self.reject = reject;
                });
            },
            select(what) {
                if (what === 'copy') {
                    const el = document.createElement('textarea');
                    el.value = this.href;
                    el.setAttribute('readonly', '');
                    el.style.position = 'absolute';
                    el.style.left = '-9999px';
                    document.body.appendChild(el);
                    el.select();
                    document.execCommand('copy');
                    document.body.removeChild(el);
                }
                this.resolve(what);
                this.close();
            },
        }
    };
</script>

<style scoped>

</style>