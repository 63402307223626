<template>
    <div class="render-question margin-15-top">
        <div style="font-weight: bold;"
             v-if="!question.is_event_question"

        >
            <i class="fas fa-exchange fa-rotate-90 color-blue" v-if="showReorderMark"></i>
            <span class="render-question__question-text" v-html="questionText"></span>
        </div>
        <p
                style="font-style: italic"
                v-if="question.additional"
                v-html="questionAdditional"
        >{{ question.additional }}</p>
        <div v-if="editMode&&!question.with_possible_answers">

            <form class="form" v-if="question.answer_type==='date'">
                <datepicker
                        v-model="answerText"
                        :highlighted="highlightedDates"
                        @keydown.native.delete="answerText=''"
                        @keydown.native.backspace="answerText=''"
                        :format="customFormatter"
                        input-class="create-event-date-picker"
                        :bootstrap-styling="true"
                        :typeable="false"
                        ref="date-picker"
                >
                    <div slot="afterDateInput" class="input-group-btn"
                         @click.stop.prevent="toggleDateCalendar">
                        <btn class="dropdown-toggle create-event-date-picker"><i
                                class="glyphicon glyphicon-calendar"></i></btn>
                    </div>
                </datepicker>
            </form>
            <form v-else-if="question.answer_type==='time'">
                <div class="row">
                    <div class="col-sm-6">
                        <select class="form-control inline-block" @input="onStartHourInput"
                                :value="answerText.split(':')[0]">
                            <option :value="hour.value" v-for="hour in startHours">{{ hour.label }}</option>
                        </select>
                    </div>
                    <div class="col-sm-6">
                        <select class="form-control inline-block" :value="answerText.split(':')[1]"
                                @input="onStartMinuteInput"
                        >
                            <option :value="minute" v-for="minute in startMinutes">{{ minute }}</option>
                        </select>
                    </div>
                </div>
            </form>
            <textarea
                    v-else
                    class="form-control"
                    rows="3"
                    autofocus
                    v-model="answerText"
            ></textarea>
        </div>

        <div v-if="editMode&&question.with_possible_answers">
            <div
                    v-if="question.allow_multiple_select"
            >
                <div class="checkbox"
                     v-for="possibleAnswer in possibleAnswers"
                >
                    <label v-if="possibleAnswer.text">
                        <input
                                type="checkbox"
                                v-model="checkedAnswers"
                                :value="possibleAnswer.text"
                        > {{ possibleAnswer.text }}
                    </label>
                    <div
                            v-if="possibleAnswer.sub_answers&&possibleAnswer.sub_answers.length&&(checkedAnswers.includes(possibleAnswer.text))"
                    >
                        <div v-for="subAnswer in possibleAnswer.sub_answers" class="margin-5-left side-padding-15">
                            <label v-if="subAnswer.text">
                                <input
                                        name="optionsRadiosSub"
                                        type="checkbox"
                                        v-model="checkedSubAnswers"
                                        :value="`${possibleAnswer.text}${SUB_ANSWER_PREFIX_SEPARATOR}${subAnswer.text}`"
                                > {{ subAnswer.text }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div
                        class="radio"
                        v-for="possible_answer in possibleAnswers"
                >
                    <label v-if="possible_answer.text">
                        <input
                                name="optionsRadios"
                                type="radio"
                                v-model="selectedAnswer"
                                :value="possible_answer.text"
                        > {{ possible_answer.text }}
                    </label>
                </div>
            </div>
            <span
                    v-if="question.allow_comment"
            >Comment:</span>
            <textarea
                    rows="3"
                    class="form-control"
                    v-model="answerComment"
                    v-if="question.allow_comment"
            ></textarea>
        </div>
        <div v-if="attachments.length" class="margin-15-bottom">
            <div>Attachments:</div>
            <media-preview
                    v-for="attachment in attachments"
                    :key="attachment.id"
                    :item='attachment'
                    allow-delete
                    @onDelete="deleteAttachment(attachment)"
                    style="display: inline-block; margin-right: 5px; margin-bottom: 5px"
            ></media-preview>
        </div>
        <div class="">
            <div v-if="linkedTasks.length">Linked
                {{ 'Tasks' | customTerm }}:
            </div>
            <span v-for="(task, i) in linkedTasks">
                <strong>{{ task.name }}</strong>
                <i
                        class="fa fa-trash-o pointer color-red"
                        @click="unlinkTask(task)"
                ></i><span v-if="i!==(linkedTasks.length-1)">, </span>
            </span>
        </div>
        <div class="margin-15-top" v-if="allowAttachments&&editMode">
            <span style="margin-right: 5px">
                <a
                        href='javascript:;'
                        @click="addAttachment"
                >
                    <span><i class="fa fa-paperclip"></i> + Add Attachment</span>
                </a>
            </span>
            <span style="margin-right: 5px">
                <a href='javascript:;'
                   @click="linkTask"
                >
                    <i class="fa fa-bullseye-arrow"></i> + Link {{ 'Goal' | customTerm }}
                </a>
            </span>
        </div>
        <div v-if="editMode&&(showSaveButton||showSaveAlarm)" class="margin-15-top">
            <div v-if="showSaveAlarm">
                <span class="color-red">Note: Once you save an entry here a copy will be kept for audit purposes even if deleted from the Diary</span>
            </div>
            <span
                    class="render-question__save-button"
                    :class="{'render-question__save-button_disabled': !allowSave}"
                    v-if="showSaveButton"
                    @click="saveAnswer"
                    tabindex="0"
                    role="button"
                    aria-label="Save Answer"
            >
                <i class="fa fa-save" aria-hidden="true"></i> Save
            </span>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Autolinker from 'autolinker';
    import _ from 'lodash';
    import Datepicker from 'vuejs-datepicker';
    import diary_logs_resource from '../../diary_page/diary_logs_resource';
    import moment from 'moment';
    import { TIME_ZONE, VISUAL_DATE_FORMAT } from '../../events/new_calendar/config';
    import { getHrsLabel, nullify } from '../../events/new_calendar/common';

    const SUB_ANSWER_PREFIX_SEPARATOR = '__$sub$__';

    export default {
        name: 'RenderQuestion',
        components: { Datepicker },
        props: {
            question: Object,
            location: {
                type: Object,
            },
            editMode: {
                type: Boolean,
                default: false,
            },
            allowAttachments: {
                type: Boolean,
                default: false,
            },
            showSaveButton: {
                type: Boolean,
                default: true,
            },
            showSaveAlarm: {
                type: Boolean,
                default: true,
            },
            showReorderMark: {
                type: Boolean,
                default: false,
            },
            schemaVersion: {
                type: Number,
                default: 1,
            },
        },
        data() {
            return {
                answerText: '',
                answerComment: '',
                selectedAnswer: undefined,
                checkedAnswers: [],
                checkedSubAnswers: [],
                attachments: [],
                linkedTasks: [],

                modalOpened: false,
            };
        },
        computed: {
            SUB_ANSWER_PREFIX_SEPARATOR(){
                return SUB_ANSWER_PREFIX_SEPARATOR
            },
            highlightedDates() {
                return {
                    dates: [moment.tz(TIME_ZONE).toDate()],
                    includeDisabled: true,
                };
            },
            possibleAnswers() {
                const res = [];
                for (let a of (this.question.possible_answers || [])) {
                    if (_.isString(a)) {
                        res.push({
                            text: a,
                            sub_answers: [],
                        });
                    } else {
                        res.push(
                            Object.assign({}, {
                                sub_answers: [],
                            }, a)
                        );
                    }
                }
                return res;
            },
            startHours() {
                const hours = [];

                for (let i = 0; i < 24; i++) {
                    hours.push({
                        value: nullify(i),
                        label: getHrsLabel(i)
                    });
                }
                return hours;
            },
            startMinutes() {
                const hours = [];
                for (let i = 0; i < 60; i++) {
                    hours.push(nullify(i));
                }
                return hours;
            },

            allowSave() {
                return this.answerText || this.answerComment || this.selectedAnswer || this.checkedAnswers?.length || this.attachments?.length || this.linkedTasks?.length;
            },
            questionText() {
                if (this.question?.text) {
                    return Autolinker.link(this.question.text, { truncate: 30 });
                }
            },
            questionAdditional() {
                if (this.question?.additional) {
                    return Autolinker.link(this.question.additional, { truncate: 30 });
                }
            }
        },
        methods: {
            customFormatter(date) {
                return moment(date).format(VISUAL_DATE_FORMAT);
            },
            toggleDateCalendar() {
                this.$refs['date-picker'].showCalendar();
            },
            resetData() {
                this.answerText = '';
                this.answerComment = '';
                this.selectedAnswer = undefined;
                this.checkedAnswers = [];
                this.attachments = [];
                this.linkedTasks = [];
            },
            dumpDataV1() {
                const data = {
                    answerText: this.answerText,
                    answerComment: this.answerComment,
                    selectedAnswer: this.selectedAnswer,
                    checkedAnswers: this.checkedAnswers,
                    attachments: this.attachments,
                    linkedTasks: this.linkedTasks,
                };
                if (this.question.answer_type === 'date') {
                    data.answerText = this.customFormatter(data.answerText);
                }
                return data;
            },
            dumpDataV3() {
                const checkedAnswers = [];
                if (this.question.with_possible_answers && this.question.allow_multiple_select) {
                    for (let possibleAnswer of this.possibleAnswers) {
                        if (this.checkedAnswers.includes(possibleAnswer.text)) {
                            const ans = { text: possibleAnswer.text };
                            const respectiveSubAnswers = this.checkedSubAnswers.filter(sa => sa.split(SUB_ANSWER_PREFIX_SEPARATOR)[0] === possibleAnswer.text).map(sa => sa.split(SUB_ANSWER_PREFIX_SEPARATOR)[1]);
                            if (respectiveSubAnswers.length) {
                                ans.subAnswers = respectiveSubAnswers.map(sa => ({ text: sa }));
                            }
                            checkedAnswers.push(ans);
                        }
                    }
                }
                const data = {
                    answerText: this.answerText,
                    answerComment: this.answerComment,
                    selectedAnswer: this.selectedAnswer,
                    checkedAnswers: checkedAnswers,
                    attachments: this.attachments,
                    linkedTasks: this.linkedTasks,
                };
                if (this.question.answer_type === 'date') {
                    data.answerText = this.customFormatter(data.answerText);
                }
                return data;
            },
            dumpRawData() {
                const {
                    answerText,
                    answerComment,
                    selectedAnswer,
                    checkedAnswers,
                    checkedSubAnswers,
                    attachments,
                    linkedTasks,
                } = this;
                return _.merge({}, {
                    answerText,
                    answerComment,
                    selectedAnswer,
                    checkedAnswers,
                    checkedSubAnswers,
                    attachments,
                    linkedTasks,
                }); // deep
            },
            unDumpRawData(data) {
                const keys = Object.keys(data);
                for (let i = 0; i < keys.length; i++) {
                    let k = keys[i];
                    Vue.set(this, k, data[k]);
                }
            },
            dumpData() {
                if (this.schemaVersion === 1) {
                    return this.dumpDataV1();
                } else if (this.schemaVersion === 3) {
                    return this.dumpDataV3();
                }
            },
            saveAnswer() {
                if (!this.allowSave) {
                    return;
                }
                this.$emit('save', this.dumpData());
                this.resetData();
            },
            linkTask(task) {
                this.modalOpened = true;
                const linkToGoalFactory = Vue.getAngularModule('linkToGoalFactory');
                linkToGoalFactory.link_to_goal(
                    diary_logs_resource,
                    this.location.id,
                    false,
                    undefined,
                    true
                )
                    .then(res => {
                        for (let linkedTask of res[0]) {
                            this.linkedTasks.push(linkedTask);
                        }
                        this.modalOpened = false;
                    }, closed => {
                        this.modalOpened = false;
                    });

            },
            unlinkTask(task) {
                this.linkedTasks.splice(this.linkedTasks.indexOf(task), 1);
            },
            addAttachment() {
                if (this.attachments.length >= 25) {
                    Vue.notifications.warning('Can\'t add more');
                }
                this.modalOpened = true;
                const addMedia = Vue.getAngularModule('addMedia');
                addMedia.add_media(
                    {
                        allow_add_from_library: true,
                        max_count: 25,
                        popup_header: 'What sort of media would you like to add?'
                    },
                    {
                        default_callback: (attachments) => {
                            for (let attachment of attachments) {
                                this.attachments.push(attachment);
                            }
                            this.modalOpened = false;
                        },
                        cancel_callback: () => {
                            this.modalOpened = false;
                        }
                    }
                );
            },
            deleteAttachment(attachment) {
                this.attachments.splice(this.attachments.indexOf(attachment), 1);
            },
            onStartHourInput(e) {
                const v = (e.srcElement || e.target).value;
                const minutes = this.answerText.split(':')[1];
                if (minutes) {
                    this.answerText = `${v}:${minutes}`;
                } else {
                    this.answerText = `${v}:00`;
                }
            },
            onStartMinuteInput(e) {
                const v = (e.srcElement || e.target).value;
                const hours = this.answerText.split(':')[0];
                if (hours) {
                    this.answerText = `${hours}:${v}`;
                } else {
                    this.answerText = `:${v}`;
                }
            },
        },
        watch: {
            allowSave(n, o) {
                this.$emit('allowSaveValueChanged', n);
            },
            modalOpened(n, o) {
                this.$emit('modalOpenedValueChanged', n);
            },
            checkedAnswers(n, o) {
            },
        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";

  .render-question {
    &__question-text {
      font-size: $bigger-font-size;
    }

    &__save-button {
      cursor: pointer;
      transition: opacity 0.2s ease;

      &:hover, &:focus {
        text-decoration: underline;
      }

      &_disabled {
        cursor: not-allowed;
        opacity: 0.7;

        &:hover, &:focus {
          text-decoration: none;
        }
      }
    }
  }
</style>
