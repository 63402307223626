<template>
    <basic-modal
            @hide="close"
            v-model="opened"
            :aria-label="header"
            role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ header }}</h3>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <strong>Consent Form</strong>
                 
                <p>Data privacy is very important to us at Multi Me. GDPR requires us by law to get your consent to our
                    Privacy and Cookie policy. You can read more about Multi Me and GDPR <strong><a
                            target="_blank"
                            href="https://multime.freshdesk.com/support/solutions/articles/44001795709-a-guide-to-gdpr-and-using-the-wikime-and-multime-platforms">
                        here</a></strong></p>

                <p>GDPR is built around the principles of transparency and control, something Multi Me has always taken
                    pride in offering. So, to help you retain control of your data, our policies offer details on what
                    we collect, how we use it, and your rights. At Multi Me we use personal data to improve our service
                    and offer better communication to new and existing customers. Your trust is important to us, so
                    keeping
                    your personal information safe and secure remains our top priority.</p>

                <strong>Privacy Policy</strong>
                <p>Please take some time to read over our Privacy Policy <strong><a
                        href="https://www.multime.com/privacy-policy"
                        target="_blank">here</a></strong>. You can view and Easy Read version <strong>
                    <a target="_blank"
                       href="https://static1.squarespace.com/static/5d82253bd4f4f8126bb934e7/t/5ed7635b1a40f37954101810/1591173985354/4+-+Looking+after+your+personal+information.pdf">
                        here</a></strong></p>

                <strong>Cookies</strong>
                <p>Multi Me uses some Cookies to make your experience of using the site smoother. You can read more and
                    make your cookie choices <strong><a href="https://www.multime.com/cookie-policy" target="_blank">
                        here</a></strong>. By continuing to use this site without changing your browser Cookie Settings
                    you
                    are giving us your consent to use Cookies on your device.</p>
            </div>
        </div>
        <div class="row" style="margin-top: 10px">

            <div class="col-sm-12">
                <vue-switch-with-label
                        label="I Confirm I accept Multi Me’s Privacy Policy"
                        name="agree_with_privacy_policy"
                        v-model="agree_with_privacy_policy"
                        class="inline-block"
                ></vue-switch-with-label>
            </div>

        </div>
        <div class="row" style="margin-top: 10px">

            <div class="col-sm-12">
                <vue-switch-with-label
                        label="I Confirm I accept Multi Me’s Cookie Policy"
                        name="agree_with_cookie_policy"
                        v-model="agree_with_cookie_policy"
                ></vue-switch-with-label>
            </div>

        </div>

        <div slot="footer" class="row">
            <div class="col-sm-12 text-right">
                <button class="btn btn-mm btn-green" :disabled="!acceptIsPossible" @click="acceptPolicy">
                    Continue to log me in
                </button>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import PopupPromiseMixin from "../../../vue/mixins/PopupPromiseMixin";

    export default {
        name: "GDPRPopup",
        mixins: [PopupPromiseMixin],
        data() {
            return {
                header: "Your Privacy Matters",
                agree_with_cookie_policy: false,
                agree_with_privacy_policy: false
            }
        },
        computed: {
            acceptIsPossible() {
                return this.agree_with_cookie_policy && this.agree_with_privacy_policy;
            },
        },
        methods: {
            acceptPolicy() {
                this.save('ACCEPT');
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
