export default {
    data() {
        return {
            initialReplyTo: undefined,
        };
    },
    methods: {
        $_initReplyTo() {
            if (this.$route.query.reply) {
                this.initialReplyTo = parseInt(this.$route.query.reply);
                const newQuery = Object.assign({}, this.$route.query);
                if (this.$route.query.reply) {
                    delete newQuery.reply;
                }
                this.$router.replace({query: newQuery});
            }
        }
    },
    beforeMount() {
        this.$_initReplyTo();
    }
};