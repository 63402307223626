<template>
    <div
            class="alert receipt-navigator__alert"
            :class="{
               'alert-info': !allRead,
               'alert-success': allRead,
            }"
    >
        <div v-if="receipt">
            <span>Read receipt from <strong><simple-link :target="receipt.creator"></simple-link></strong></span>
            <render-quote v-if="showSenderComment">
                {{ receipt.sender_comment }}
            </render-quote>
            <br v-else>
            <span v-if="wikiIncluded"
                  class="receipt-navigator__link"
                  :class="{
                        'receipt-navigator__link_active':isMainSectionOpened
                  }"
            >
                <i class="far" :class="{
                    'fa-check':!wikiIncluded.is_read,
                    'fa-check-double':wikiIncluded.is_read
                }"></i>
                <a
                        href="#"
                        @click.stop.prevent="openMainWiki"
                >Wiki Content Page</a><span v-if="sectionsIncluded.length||slidesGrouped.length">, </span>
            </span>
            <span v-for="(sectionIncluded, i) in sectionsIncluded">
            <i class="far" :class="{
                    'fa-check':!sectionIncluded.is_read,
                    'fa-check-double':sectionIncluded.is_read
            }"></i>
                <span
                        class="receipt-navigator__link"
                        :class="{
                        'receipt-navigator__link_active':isSectionActive(sectionIncluded)
                    }">
                    Section
                    <a
                            href="#"
                            @click.stop.prevent="openSection(sectionIncluded)"
                    >{{ sectionIncluded.name }}</a><span v-if="slidesGrouped.length||(i!==(sectionsIncluded.length-1))">, </span>
                </span>
            </span>

            <span
                    v-for="(slide, i) in slidesGrouped"
                    class="receipt-navigator__link"
                    :class="{
                        'receipt-navigator__link_active':isSlideActive(slide)
                    }"
            >
                    <i class="far" :class="{
                        'fa-check':!slide.is_read,
                        'fa-check-double':slide.is_read
                         }">
                    </i>
                    Page
                    <a
                            href="#"
                            @click.stop.prevent="openSlide(slide.wiki_section, slide)"
                    >{{ slide.name }}</a><span v-if="i!==(slidesGrouped.length-1)">, </span>
            </span>
            <div>
                <br>
                <span
                        class="pointer"
                        tabindex="0"
                        role="checkbox"
                        aria-label="I have read and understood the information provided in the Wiki"
                        @click="read=!read"
                        @keydown.enter="read=!read"
                >
                    <i
                            class="far fa-check-square vertical-middle"
                            :class="{
                                'fa-check-square': read,
                                'fa-square': !read,
                            }"
                    ></i>
                    <span class="vertical-middle">I have read and understood the information provided in the Wiki</span>
                </span>
                <br>
                <textarea
                        class="form-control"
                        maxlength="1023"
                        v-if="read"
                        placeholder="Add a comment"
                        v-model="receiverComment"
                ></textarea>
                <div class="text-right margin-10-top" v-if="read">
                    <button class="button btn-mm btn-green" @click="completeReceipt">Send</button>
                </div>
            </div>
        </div>
        <div v-else>
            Loading...
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import RenderQuote from "shared/quote_directive/renderQuote";

    export default {
        name: "WikiReadReceiptPageNavigation",
        components: {RenderQuote},

        props: {},
        data() {
            return {
                read: false,
                receiverComment: '',
            };
        },
        computed: {
            receipt() {
                return this.$store.getters['WikiStore/readReceipt'];
            },
            accessParams() {
                return this.$store.getters['WikiStore/accessParams'];
            },
            currentSection() {
                return this.$store.getters['WikiStore/currentSection'];
            },
            activeSlide() {
                return this.$store.getters['WikiStore/activeSlide'];
            },
            showSenderComment() {
                return this.receipt?.sender_comment && this.accessParams.accessCode;
            },
            wikiIncluded() {
                return this.receipt?.wikis[0];
            },
            sectionsIncluded() {
                return this.receipt?.wiki_sections || [];
            },
            slidesGrouped() {
                if (!this.receipt?.wiki_slides) {
                    return [];
                }
                return _.orderBy(this.receipt.wiki_slides, s => s.wiki_section.id);
            },
            isMainSectionOpened() {
                return this.$store.getters['WikiStore/isMainSectionOpened'];
            },
            allRead() {
                if (!this.receipt) {
                    return false;
                }
                if (this.wikiIncluded && !this.wikiIncluded.is_read) {
                    return false;
                }
                for (const i of this.sectionsIncluded) {
                    if (!i.is_read) {
                        return false;
                    }
                }
                for (const i of this.slidesGrouped) {
                    if (!i.is_read) {
                        return false;
                    }
                }
                return true;
            },
        },
        methods: {
            openSection(section) {
                this.$emit('onSectionOpen', section);
            },
            openMainWiki() {
                this.$emit('onMainSectionOpen');
            },
            openSlide(section, slide) {
                this.$emit('onSlideOpen', {section, slide});
            },
            isSectionActive(section) {
                return this.currentSection && section?.id && (section.id === this.currentSection?.id);
            },
            isSlideActive(slide) {
                return this.activeSlide && slide?.id && (slide.id === this.activeSlide?.id);
            },
            completeReceipt() {
                this.$store.dispatch('WikiStore/completeReadReceipt', this.receiverComment);
            },
        },
        watch: {
            currentSection(n) {
                if (n) {
                    this.$store.commit('WikiStore/markSectionAsReadAsReadInReceipt', n);
                }
            },
            activeSlide(n) {
                if (n) {
                    this.$store.commit('WikiStore/markSlideAsReadAsReadInReceipt', n);
                }
            },
            isMainSectionOpened(n) {
                if (n) {
                    this.$store.commit('WikiStore/markWikiAsReadAsReadInReceipt', n);
                }
            },
            receipt(n) {
                if (n) {
                    if (this.isMainSectionOpened) {
                        this.$store.commit('WikiStore/markWikiAsReadAsReadInReceipt');
                    }
                }
            },
        },
        mounted() {
        }
    };
</script>

<style scoped lang="scss">
  .receipt-navigator {
    &__link {
      &_active {
        font-weight: bold;
      }
    }

    &__alert {
      transition: all .5s ease;
      font-size: 15px;
    }
  }
</style>