var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { transition: 0, backdrop: false },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          attrs: { id: "close_popup" },
          on: { click: _vm.closePopup },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [
          _vm._v("Event recurrence Info"),
        ]),
      ]),
      _vm._v(" "),
      _vm.formattedPatterns.length
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("span", { staticClass: "recurrence-popup__header" }, [
                _vm._v("Recurrence:"),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.formattedPatterns.length
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c(
                "div",
                {},
                _vm._l(_vm.formattedPatterns, function (formattedPattern) {
                  return _c("div", { staticClass: "margin-5-bottom" }, [
                    _c("strong", [_vm._v(_vm._s(formattedPattern.date))]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(formattedPattern.text))]),
                  ])
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.formattedDeletedPeriods.length
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("span", { staticClass: "recurrence-popup__header" }, [
                _vm._v("Canceled dates:"),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.formattedDeletedPeriods.length
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", [
                _c("strong", [
                  _vm._v(_vm._s(_vm.formattedDeletedPeriods.join(", "))),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.formattedHolidays.length
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("span", { staticClass: "recurrence-popup__header" }, [
                _vm._v("Holidays:"),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.formattedHolidays.length
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c(
                "div",
                _vm._l(_vm.holidays, function (holidaysItem) {
                  return _c("span", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(holidaysItem.start) +
                          " - " +
                          _vm._s(holidaysItem.end)
                      ),
                    ]),
                    holidaysItem.name
                      ? _c("span", { staticClass: "color-black" }, [
                          _vm._v(" (" + _vm._s(holidaysItem.name) + ")"),
                        ])
                      : _vm._e(),
                    _vm._v(",\n                "),
                  ])
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          { staticClass: "btn btn-mm", on: { click: _vm.closePopup } },
          [_vm._v("OK")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }