<template>
    <modal v-model="opened" :transition='0' :backdrop="false">
        <div slot="header">
            <a id="close_popup" @click="closePopup"></a>
            <h3 class="modal-title">Apply Wiki Template</h3>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="alert alert-warning">
                    <strong>Attention!</strong>
                    This wiki has <strong>{{slidesCount}}</strong> Wiki slides in <strong>{{sectionsCount}}</strong>
                    wiki sections.
                    Applying this template will cause deletion of all data in that Wiki such as Sections, Slides,
                    Attachments.
                </div>
            </div>
        </div>
        <div class="row" v-if="templates.length">
            <div class="col-sm-12">
                <div>
                    <span>Wiki Template:</span>
                    <select class="form-control" v-model="selectedTemplate">
                        <option :value="template" v-for="template in templates" :key="template.id">{{template.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div slot="footer">
            <button class="btn btn-mm btn-green" @click="applyTemplate" :disabled="!allowApply">Apply Template</button>
        </div>
    </modal>
</template>

<script>
    export default {
        name: "ApplyTemplateToWikiPopup",
        data() {
            return {
                opened: false,
                templates: [],
                selectedTemplate: undefined,
                slidesCount: 0,
                sectionsCount: 0,
            };
        },
        computed: {
            allowApply() {
                return this.selectedTemplate;
            }
        },
        methods: {
            applyTemplate() {
                this.resolve(this.selectedTemplate);
                this.opened = false;
            },
            show(sectionsCount, slidesCount, templates = [],) {
                this.templates = templates;
                this.sectionsCount = sectionsCount;
                this.slidesCount = slidesCount;
                this.opened = true;
                return new Promise((res, rej) => {
                    this.resolve = res;
                    this.reject = rej;
                });
            },
            closePopup() {
                this.opened = false;
                this.reject();
            },
        }
    };
</script>

<style scoped>

</style>