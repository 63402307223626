var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "commonGroupsListPopup",
      attrs: { transition: 0 },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          attrs: { id: "close_popup" },
          on: { click: _vm.close_popup },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [
          _vm._v("Common Groups List"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          _vm._l(_vm.groupContainers, function (groupContainer) {
            return _c(
              "span",
              [
                _c(
                  "strong",
                  [
                    _c("simple-link", {
                      attrs: { target: groupContainer.target },
                    }),
                  ],
                  1
                ),
                _vm._v(":"),
                _c("br"),
                _vm._v(" "),
                _vm._l(groupContainer.groups, function (group, i) {
                  return _c("span", [
                    _c(
                      "strong",
                      [
                        _c("simple-link", {
                          attrs: { target: group, "new-tab": true },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: i < groupContainer.groups.length - 1,
                            expression: "i<(groupContainer.groups.length-1)",
                          },
                        ],
                      },
                      [_vm._v(", ")]
                    ),
                  ])
                }),
                _vm._v(" "),
                _c("br"),
                _c("br"),
              ],
              2
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-green",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.close_popup()
              },
            },
          },
          [_vm._v("OK")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }