<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="header"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ header }}</h3>
        </div>
        <div class="image-upload-popup__files-container" ref="filesContainer">
            <div class="row image-upload-popup__file-row" v-for="(file, index) in files" :key="file.name"
                 :ref="'file'+index">
                <div class="col-sm-12" v-if="file.loaded">
                    <div>
                        <div class="row">
                            <div class="col-xs-6 text-left">
                                <img :src="file.previewUrl" height="100" :alt="file.name"
                                     onerror="this.src='/static/images/icons/picture-placeholder.svg'"
                                     class="inline-block vertical-middle"/>
                            </div>
                            <div class="col-xs-6 text-right">
                                <span class="image-upload-popup__delete-image-icon vertical-middle"
                                      @click="deleteFileFromList(file)"><i
                                        class="far fa-trash"></i></span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <span class="upload-file-name">{{ file.name }}</span>
                    </div>
                    <div>
                        <progress-bar
                                v-model="file.progress"
                                :type="file.uploaded?'success':undefined"
                                striped
                                :active="file.uploading"/>
                    </div>
                </div>
            </div>
            <div class="margin-5-top alert alert-info" v-for="unsupportedImageMessage in unsupportedImageMessages">
                <strong>{{ unsupportedImageMessage }}</strong> - Not an image or the format of the image is not
                supported
            </div>
        </div>
        <div slot="footer">
            <div class="row">
                <div class="col-sm-6 text-left">
                    <vue-switch-with-label :collapse-on-mobile="false" name="save_to_media" v-model="saveToMedia"
                                           label="Save to My Media"
                                           v-if="allowSaveToMedia"></vue-switch-with-label>
                </div>
                <div class="col-sm-6">
                    <button @click="cancel()" type="button" class="btn btn-mm btn-default">
                        Cancel
                    </button>
                    <button
                            @click="startUpload()"
                            ref="startUploadButton"
                            type="button"
                            class="btn btn-mm  btn-green"
                            :disabled="uploadButtonDisabled"
                    >
                        Upload
                    </button>
                </div>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import { DjangoUrls } from 'DjangoUrls';
    import PopupPromiseMixin from '../../../../vue/mixins/PopupPromiseMixin';
    import BasicModal from '../../../../vue/common_components/BasicModal';
    import axios from 'axios';

    const isImage = (file) => {
        const type = '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
        // return file.type.indexOf('image')!==-1;
        return '|jpg|png|jpeg|bmp|gif|webp|tiff|heic|heif|avif|'.indexOf(type) !== -1;
    };

    class ImageForUpload {
        constructor(file, anonymous_token) {
            this.file = file;
            this.loaded = false;
            this.progress = 0;
            this.anonymous_token = anonymous_token;
            this.uploaded = false;
            this._uploading = false;
            this.canceller = new AbortController();
            const reader = new FileReader();
            reader.onloadend = () => {
                this._previewUrl = reader.result;
                this.loaded = true;
            };
            reader.readAsDataURL(file);
        }

        get previewUrl() {
            return this._previewUrl;
        }

        get name() {
            return this.file.name;
        }

        get uploading() {
            return this._uploading;
        }

        set uploading(val) {
            return this._uploading = val;
        }

        upload(vm) {
            const self = this;
            const payload = new FormData();
            payload.append('file', this.file);
            payload.append('save_to_library', vm.allowSaveToMedia && vm.saveToMedia);

            const headers = {};

            if (this.anonymous_token) {
                headers['X-A-Token'] = this.anonymous_token;
            }

            return axios.post(DjangoUrls['api.v1:media_upload']('image'), payload, {
                onUploadProgress: (progressEvent) => {
                    self.uploading = true;
                    self.progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                },
                headers,
                signal: this.canceller.signal,
            });
        }
    }

    export default {
        name: 'ImageUploadPopup',
        components: { BasicModal },
        mixins: [PopupPromiseMixin],
        data() {
            return {
                opened: false,
                files: [],
                createdMediaItems: [],
                header: 'Upload Images',
                cancelled: false,
                uploading: false,

                allowSaveToMedia: false,
                saveToMedia: true,

                unsupportedImageMessages: [],

                anonymous_token: undefined,
            };
        },
        computed: {
            firstFocusEl() {
                return this.$refs.startUploadButton;
            },
            uploadButtonDisabled() {
                return this.uploading || !this.files.length;
            }
        },
        methods: {
            startUpload() {
                let fileToUploadIndex = 0;
                const uploadNextFile = () => {
                    if (this.cancelled) {
                        return;
                    }
                    if (this.files.length > 3 && this.$refs.filesContainer?.scrollTo) {
                        this.$refs.filesContainer.scrollTo({
                            top: 165 * fileToUploadIndex,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }
                    this.files[fileToUploadIndex].uploading = true;
                    this.files[fileToUploadIndex].upload(this)
                        .then(resp => {
                            this.files[fileToUploadIndex].uploaded = true;
                            this.files[fileToUploadIndex].uploading = false;
                            this.createdMediaItems.push(resp.data);
                            fileToUploadIndex++;
                            if (this.files.length > fileToUploadIndex) {
                                uploadNextFile();
                            } else {
                                this.allUploaded();
                            }
                        }, err => {
                            this.$notifications.error(err);
                            this.files[fileToUploadIndex].uploading = false;
                            this.close('error');
                        });
                };
                uploadNextFile();
            },
            allUploaded() {
                this.resolve(this.createdMediaItems);
            },
            setInitial(files = [], allowSaveToMedia = false, config = {}) {
                if (config.anonymous_token) {
                    this.anonymous_token = config.anonymous_token;
                }
                this.allowSaveToMedia = allowSaveToMedia;
                for (let i = 0; i < files.length; i++) {
                    if (i >= 50) {
                        continue;
                    }
                    if (isImage(files[i])) {
                        this.files.push(new ImageForUpload(files[i], this.anonymous_token));
                    } else {
                        this.unsupportedImageMessages.push(files[i].name);
                    }
                }
            },
            deleteFileFromList(file) {
                this.files.splice(this.files.indexOf(file), 1);
                if (this.files.length === 0) {
                    this.close('back');
                }
            },
            cancel() {
                this.cancelled = true;
                for (let file of this.files) {
                    if (file.uploading) {
                        file.canceller.abort();
                    }
                }
                this.close('cancelled');
            },
        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";

  .image-upload-popup {
    &__files-container {
      max-height: 500px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    &__file-row {
      max-height: 165px;
    }

    &__delete-image-icon {
      color: $red;
      font-size: 40px;
      line-height: 100px;
      cursor: pointer;
      margin-right: 20px;
    }

  }

  .upload-file-name {
    font-size: 18px;
  }
</style>
