<template>
    <div class="main-container" :style="[containerStyle]"
         @dragenter.prevent="onDragEnter"
         @dragover.prevent=""
         @dragstart.prevent=""
         @dragleave="onDragLeave"
         @dragend="onDragEnd"
         @drop.prevent="onDrop"
    >


        <a
                v-if="section"
                href="javascript:;"
                class="main-container__image border-group"
                :style="[imageStyle]"
                :class="{'pointer':allowOpen, 'main-container__image_active': isActive}"
                tabindex="0"
                ref="sectionAvatar"
                :aria-label="section.caption"
                @click="openSection"
                @keydown.enter.stop.prevent="openSection"
                @focus="activateSection"
        >
            <div
                    v-if="section&&(editMode&&section.is_editable||section.audio_caption)"
                    class="main-container__delete_button"
                    @click.stop.prevent="editOrAudioClick"
                    @touchstart.stop.prevent="editOrAudioClick"
                    :style="deleteMarkStyle">
                <audio-toggle
                        ref="sectionAudioToggle"
                        v-if="!editMode"
                        :audio-caption="section.audio_caption"
                        fa-class="far"></audio-toggle>
                <i class="far fa-pencil pointer" v-if="editMode&&section.is_editable"
                ></i>
            </div>
            <div
                    class="main-container-image__unread-mark"
                    :style="changedMarkStyle"
                    v-if="showNewMark||showChangedMark||showBothMarks"
                    :class="{
                        'main-container-image__unread-mark_changed': showChangedMark,
                        'main-container-image__unread-mark_new': showNewMark,
                        'main-container-image__unread-mark_both': showBothMarks,
                    }"
            ><span v-if="showChangedMark">UPD</span><span v-if="showNewMark">NEW</span></div>
            <i class="fa fa-image main-container-image__empty-icon" v-if="!avatar&&!mediaContentSamples.length"></i>
        </a>
    </div>
</template>

<script>
    import _ from 'lodash';
    import DraggableMethodsMixin from "./DraggableMethodsMixin";
    import WikiSectionMixin from "./WikiSectionMixin";
    import mediaContentUtils from "../../../../angular/app/view_media/mediaContentUtils";

    export default {
        mixins: [DraggableMethodsMixin, WikiSectionMixin],
        props: {
            height: {
                type: Number,

            },
            width: {
                type: Number
            },
            text: {
                type: String
            },
            avatar: {
                type: String
            },
            isTopSection: {
                type: Boolean
            },
            mediaContentSamples: {
                type: Array,
                required: false,
                default: () => [],
                validator: function (value) {
                    return value.length <= 4;
                }
            },
            allowOpen: {
                type: Boolean,
                default: false
            },
            editMode: {
                type: Boolean,
                default: false
            },
            containerWidth: {
                type: Number
            },
            section: {
                type: Object
            }
        },
        data() {
            return {};
        },
        name: "wiki-main-section",
        methods: {
            onDragEnter() {
                if (this.isTopSection) {
                    return false;
                }
                this.dragCounter++;
                if (this.dragCounter > 0) {
                    this.dragOn = true;
                }
            },
            openSection() {
                if (!this.allowOpen) {
                    return;
                }
                this.$emit('onOpen');
            },
            onEdit() {
                this.$emit('onEdit');
            },
            activateSection() {
                this.$emit('activateSection');
            }
        },

        computed: {
            $_circleSize() {
                return this.$_bigCircleSize;
            },
            $_fontSize() {
                return 14;
            },
            containerStyle() {
                let resultStyle = {
                    width: `${this.width}px`,
                    height: `${this.height}px`,
                };
                return resultStyle;
            },
            imageStyle() {
                let resultStyle = {
                    width: `${this.$_circleSize}px`,
                    height: `${this.$_circleSize}px`,
                };

                if (this.avatar || this.mediaContentSamples.length) {
                    _.merge(resultStyle, new mediaContentUtils().generate_group_background(this.avatar, this.mediaContentSamples));
                } else {
                    resultStyle['background-color'] = '#f2f4f9';
                    resultStyle['line-height'] = `${this.$_circleSize * 0.9}px`;
                    resultStyle['font-size'] = `${this.$_circleSize * 0.6}px`;
                }

                if (this.dragOn && !this.isDragged) {
                    resultStyle['box-shadow'] = '#64BD63 0 0 20px';
                    resultStyle['border-color'] = '#64BD63';
                }
                if (!this.isTextOnSide) {
                    resultStyle['position'] = 'relative';
                    resultStyle['top'] = `-${this.$_smallCircleSize / 2}px`;
                }

                return resultStyle;
            },
            textStyle() {
                let resultStyle = {
                    'font-size': `${this.$_fontSize}px`
                };
                if (!this.isTextOnSide) {
                    resultStyle['position'] = 'relative';
                    resultStyle['top'] = `-${this.$_smallCircleSize / 2}px`;
                } else {
                    resultStyle['margin-top'] = '14px';
                }
                return resultStyle;
            },
            deleteMarkStyle() {
                const smallCircleRadius = this.$_circleSize / 3;
                const r = this.$_circleSize / 2;
                const correction = r / Math.sqrt(2);
                const zeroPoint = this.$_circleSize / 2 - smallCircleRadius / 2;
                return {
                    position: 'absolute',
                    width: `${smallCircleRadius}px`,
                    height: `${smallCircleRadius}px`,
                    'line-height': `${smallCircleRadius}px`,
                    'font-size': `${smallCircleRadius / 1.8}px`,
                    left: `${zeroPoint + correction}px`,
                    top: `${zeroPoint - correction}px`,
                };
            },
            changedMarkStyle() {
                const smallCircleRadius = this.$_circleSize / 3;
                const r = this.$_circleSize / 2;
                const correction = r / Math.sqrt(2);
                const zeroPoint = this.$_circleSize / 2 - smallCircleRadius / 2;
                return {
                    position: 'absolute',
                    width: `${smallCircleRadius}px`,
                    height: `${smallCircleRadius}px`,
                    'font-size': `${smallCircleRadius / 3}px`,
                    left: `${zeroPoint - correction}px`,
                    top: `${zeroPoint - correction}px`,
                };
            },
        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";

  .main-container {


    &__image {
      background-color: $main-bg-color;
      position: relative;
      display: inline-block;
      border-radius: 50%;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center center;

      &_active {
        box-shadow: 0 0 2px 2px rgba(11, 149, 255, 1);
      }
    }

    &__delete_button {
      position: absolute;
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.85);
      color: $black;
      text-align: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      line-height: 50px;
      font-size: 25px;
    }
  }

  .main-container-image {
    &__unread-mark {
      cursor: pointer;
      border-radius: 50%;
      text-align: center;
      border: solid 3px #fff;
      opacity: 0.85;
      color: #FFF;

      display: flex;
      align-items: center;
      justify-content: center;


      &_changed {
        background-color: $blue;
      }

      &_new {
        background-color: $online-green;
      }

      &_both {
        background: $online-green;
        background: linear-gradient(90deg, $online-green 50%, $blue 50%);
      }
    }
  }

</style>