import {CurrentUserClass} from "shared/CurrentUser";

const CurrentUser = new CurrentUserClass();

const TRANSLATIONS = CurrentUser.ah_terms();

function customTermFilter(term) {
    if (!term || !term.length) {
        return '';
    }
    let firstUpper = true;
    if (term[0].toLowerCase() === term[0]) {
        firstUpper = false;
    }
    let translation = TRANSLATIONS[term.toLowerCase()];
    if (!translation) {
        return term;
    }
    if (firstUpper) {
        translation = translation[0].toUpperCase() + translation.substr(1);
    }
    return translation;
}

const CustomTermPlugin = {
    install(Vue) {
        Vue.prototype.$customTerm = customTermFilter;
    }
};

export {customTermFilter, CustomTermPlugin};