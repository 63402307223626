var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.answer.answers_checked
      ? _c("div", [
          _c("span", {
            staticClass: "render-location-answer__text",
            domProps: { innerHTML: _vm._s(_vm.answersWithLinks[0]) },
          }),
        ])
      : _c(
          "div",
          _vm._l(_vm.answersWithLinks, function (checkedAnswer) {
            return _c("p", { key: checkedAnswer }, [
              _c("i", { staticClass: "fa fa-check" }),
              _vm._v(" " + _vm._s(checkedAnswer)),
            ])
          }),
          0
        ),
    _vm._v(" "),
    _vm.commentWithLinks
      ? _c("div", { staticClass: "render-location-answer__text" }, [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.commentWithLinks) } }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isEmpty ? _c("div", [_c("i", [_vm._v("*no content*")])]) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }