<style lang="scss">
  .switch-with-label {
    font-size: 14px;

    &__help-icon {
      @media (max-width: 600px) {
        font-size: 22px;
      }
    }

    &__switch {
      &.mobile-collapse {
        @media (max-width: 600px) {
          display: block;
          margin: auto;
        }
      }
    }
  }
</style>
<template>
    <div class="inline-block switch-with-label">
        <vue-switch
                :name="name"
                :value="value"
                :disabled="disabled"
                :aria-label="label"
                class="vertical-middle switch-with-label__switch"
                @input="on_input"
                :class="{'mobile-collapse':collapseOnMobile}"
                ref="switch"
        ></vue-switch>
        <span class="vertical-middle"><slot name="label">{{ label }}</slot>

        </span>
        <i v-if="helpText&&!isMobile"
           class="fal fa-question-circle switch-with-label__help-icon pointer vertical-middle"
           v-tooltip.hover="helpText"
           :show-delay="400"
           :hide-delay="800"></i>
        <i v-if="helpText&&isMobile"
           class="fal fa-question-circle switch-with-label__help-icon pointer vertical-middle"
           v-tooltip.outside-click="helpText"
           :show-delay="400"
           :hide-delay="800"></i>
    </div>
</template>
<script>
    import Switch from './Switch.vue';
    import MobileDetect from "mobile-detect";

    export default {
        data() {
            return {};
        },
        computed: {
            isMobile() {
                return new MobileDetect(window.navigator.userAgent).mobile();
            }
        },
        components: {
            'vue-switch': Switch
        },
        props: {
            collapseOnMobile: {
                type: Boolean,
                default: false
            },
            name: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: true
            },
            helpText: {
                type: String,
                required: false
            },
            disabled: {
                type: Boolean,
                required: false
            },
            value: Boolean
        },
        methods: {
            on_input(val) {
                this.$emit('input', Boolean(val));
                this.$emit('changed', Boolean(val));
            },
            focus() {
                this.$refs.switch?.focus();
            }
        }
    };
</script>