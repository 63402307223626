var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "category-selector",
      attrs: { role: "radiogroup", "aria-label": "Select Category" },
    },
    _vm._l(_vm.userCategories, function (category) {
      return category.icon
        ? _c(
            "div",
            {
              staticClass: "category-selector__icon inline-block",
              style: _vm.getCategoryAvatarStyle(category),
              attrs: {
                tabindex: "0",
                role: "menuitemradio",
                "aria-checked": _vm.isCategoryActive(category),
                "aria-label": category.name,
              },
              on: {
                click: function ($event) {
                  return _vm.selectCategory(category)
                },
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.selectCategory(category)
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isCategoryActive(category),
                      expression: "isCategoryActive(category)",
                    },
                  ],
                  staticClass: "category-selector__icon__tick",
                  attrs: { "aria-hidden": "true" },
                },
                [_c("i", { staticClass: "fa fa-check" })]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "category-selector__label",
                  attrs: { "aria-hidden": "true" },
                },
                [_vm._v(_vm._s(category.name))]
              ),
            ]
          )
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }