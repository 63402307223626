/**
 * Created by orion on 29.05.17.
 */
OptonalRoleSetPopupController.$inject = ['$scope', '$uibModalInstance'];

function OptonalRoleSetPopupController($scope, $uibModalInstance) {
    $scope.close = function () {
        $uibModalInstance.dismiss('closed');
    };
    $scope.roles = ['Teacher', 'Parent', 'Friend', 'Doctor', 'Family', 'Social Worker', 'Other'];

    $scope.invite = function () {
        $uibModalInstance.close($scope.role);
    };

}

export default OptonalRoleSetPopupController;