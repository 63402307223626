var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "spf" }, [
    _c("div", { staticClass: "row text-center" }, [
      _c(
        "div",
        { staticClass: "col-sm-12" },
        _vm._l(_vm.buttonRows, function (buttonRow) {
          return _c("div", { staticClass: "row text-center" }, [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              _vm._l(buttonRow, function (symbol) {
                return _c(
                  "div",
                  {
                    staticClass: "spf__symbol-item",
                    class: _vm.getSymbolClasses(symbol),
                    attrs: { tabindex: "0" },
                    on: {
                      click: function ($event) {
                        return _vm.onSymbolClick(symbol)
                      },
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.onSymbolClick(symbol)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "spf__input-numbers" }, [
                      _vm._v(_vm._s(_vm.getInputNumbers(symbol))),
                    ]),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "fa spf__symbol-icon",
                      class: symbol.cls,
                      style: { color: symbol.clr },
                      attrs: { "aria-hidden": "true" },
                    }),
                  ]
                )
              }),
              0
            ),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }