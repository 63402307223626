<template>
    <div class="text-center media-renderer-inline" v-if="item">
        <component
                :is="componentType"
                :item="item"
                v-bind="$attrs"
                :autoplay="autoplay"
                :max-height="maxHeight"
                ref="mediaComponent"
        ></component>
    </div>

</template>

<script>
    import VueInlineMediaImage from './VueInlineMediaImage.vue';
    import VueInlineMediaTMP from './VueInlineMediaTMP.vue';
    import VueInlineMediaDocument from './VueInlineMediaDocument.vue';
    import VueInlineMediaAudio from './VueInlineMediaAudio.vue';
    import VueInlineMediaYoutube from './VueInlineMediaYoutube.vue';
    import VueInlineMediaVideo from './VueInlineMediaVideo.vue';
    import VueInlineMediaVimeo from './VueInlineMediaVimeo.vue';
    import VueInlineMediaMap from './VueInlineMediaMap.vue';

    export default {
        name: "VueInlineMedia",
        components: {
            VueInlineMediaTMP,
            VueInlineMediaImage,
            VueInlineMediaDocument,
            VueInlineMediaAudio,
            VueInlineMediaYoutube,
            VueInlineMediaVideo,
            VueInlineMediaMap,
        },
        props: {
            item: Object,
            autoplay: {
                type: Boolean,
                default: false
            },
            maxHeight: {
                type: String,
                required: false
            },
        },
        created() {
        },
        computed: {
            componentType() {
                if (this.item.type === 'image') {
                    return VueInlineMediaImage;
                }
                if (this.item.type === 'uploaded_document') {
                    return VueInlineMediaDocument;
                }
                if (this.item.type === 'youtube_video') {
                    return VueInlineMediaYoutube;
                }
                if (this.item.type === 'vimeo_video') {
                    return VueInlineMediaVimeo;
                }
                if (this.item.type === 'upl_video') {
                    return VueInlineMediaVideo;
                }
                if (this.item.type === 'location_point') {
                    return VueInlineMediaMap;
                }
                if (this.item.type === 'uploaded_audio' || this.item.type === 'recorded_audio') {
                    return VueInlineMediaAudio;
                }
                return VueInlineMediaTMP;
            }
        },
        methods: {
            canPlay() {
                return !!this.$refs.mediaComponent?.togglePlay;
            },
            togglePlay() {
                this.$refs.mediaComponent?.togglePlay();
            },
        }
    };
</script>

<style scoped lang="scss">
  .media-renderer-inline {
    white-space: nowrap;
    overflow: hidden;
    height: 100%;
    width: 100%;
    max-height: inherit;

    div {
      height: 100%;
    }

  }

</style>
