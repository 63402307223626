var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "group-event-item", staticStyle: { position: "relative" } },
    [
      _c("div", { staticClass: "group-event-item__preview" }, [
        _c(
          "div",
          { staticClass: "group-event-item__avatar", style: _vm.avatarStyle },
          [
            _vm.showPeriodicMark
              ? _c("i", {
                  staticClass:
                    "fa fa-repeat group-event-item__avatar__repeat-mark",
                })
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "group-event-item__date" }, [
          _vm._v(_vm._s(_vm.greenBoxDateLabel)),
        ]),
      ]),
      _vm._v(" "),
      _vm.deletable
        ? _c("span", {
            staticClass: "group-event-item__icon-delete pointer",
            on: {
              click: function ($event) {
                return _vm.deleteEvent()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "group-event-item__name" },
        [
          _c(
            "router-link",
            { attrs: { to: _vm._f("backHrefQuery")(_vm.eventLink) } },
            [_vm._v(_vm._s(_vm.event.event_data.name))]
          ),
          _vm._v(" "),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.event.is_private,
                expression: "event.is_private",
              },
            ],
            staticClass: "fa fa-eye-slash color-dark-blue",
          }),
        ],
        1
      ),
      _c("br"),
      _vm._v(" "),
      _c("strong", [_vm._v(_vm._s(_vm.inListTimeRepresentationLabel) + " ")]),
      _c("br"),
      _vm._v(" "),
      _vm.event.owner
        ? _c("div", [
            _c("div", {
              staticClass:
                "inline-block vertical-middle event-belonging-preview",
              style: _vm.getGroupEventPreviewBackground(_vm.event.owner),
            }),
            _vm._v(" "),
            _c(
              "strong",
              { staticClass: "inline-block vertical-middle" },
              [_c("simple-link", { attrs: { target: _vm.event.owner } })],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.event.group
        ? _c("div", [
            _c("div", {
              staticClass:
                "inline-block vertical-middle event-belonging-preview",
              style: _vm.getGroupEventPreviewBackground(_vm.event.group),
            }),
            _vm._v(" "),
            _c(
              "strong",
              { staticClass: "inline-block vertical-middle" },
              [_c("simple-link", { attrs: { target: _vm.event.group } })],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.event.is_member,
              expression: "event.is_member",
            },
          ],
        },
        [
          _c("span", [
            _c("i", { staticClass: "fa fa-check color-grass-green" }),
            _vm._v(" This Event has been added to "),
            _vm.isMyCalendar
              ? _c("span", [_vm._v("your")])
              : _c("span", [
                  _c("strong", [_vm._v(_vm._s(_vm.profile.name))]),
                  _vm._v("'s"),
                ]),
            _vm._v(" Calendar."),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.event.is_member,
              expression: "!event.is_member",
            },
          ],
        },
        [
          _c("span", [
            _c("i", { staticClass: "fa fa-times color-tag-gray" }),
            _vm._v(" This Event has not been added to "),
            _vm.isMyCalendar
              ? _c("span", [_vm._v("your")])
              : _c("span", [
                  _c("strong", [_vm._v(_vm._s(_vm.profile.name))]),
                  _vm._v("'s"),
                ]),
            _vm._v(" Calendar."),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("p", [
        _c("i", [
          _vm._v(
            _vm._s(_vm._f("cutName")(_vm.event.event_data.description, 300))
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }