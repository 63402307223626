<template>
    <span class="icon-counter" v-show="value>0">{{ value }}</span>
</template>

<script>
    export default {
        name: "SiteHeaderNotificationsCounter",
        props: {
            value: Number,
        },
    };
</script>

<style scoped lang="scss">
    .icon-counter {
        border-radius: 50%;
        width: 28px;
        height: 28px;
        position: absolute;
        background-color: #E63D24;
        color: white;
        text-align: center;
        line-height: 28px;
        right: -10px;
        top: 0;
    }
</style>