<template>
    <router-link v-bind="$attrs" v-on="$listeners" :to="to">
        <slot></slot>
    </router-link>
</template>

<script>
    export default {
        name: "SimpleWrapperLinkA",
        props: {
            to: [String, Object],
        }
    };
</script>