<template>
    <modal v-model="opened" :transition='0' :backdrop="false">
        <div slot="header">
            <a id="close_popup" @click="closePopup"></a>
            <h3 class="modal-title">Event Settings</h3>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div>
                    <span>Event title:</span>
                    <input ref="name-input" v-model="name" maxlength="127" type="text" class="form-control"
                           placeholder="Event Name"
                           required/>
                </div>
            </div>
        </div>
        <div class="row margin-15-top">
            <div class="col-sm-12">
                <div>
                    <span>Event Description:</span>
                    <textarea v-model="description" maxlength="3000" type="text" class="form-control"
                              placeholder="Event Description"></textarea>
                </div>
            </div>
        </div>
        <div class="row margin-15-top">
            <div class="col-sm-12">
                <media-preview :no-border="true" :item="avatar" :allow-delete="true" v-if="avatar"
                               @onDelete="cleanAvatar" :show-name="true"></media-preview>
                <media-preview-add-request :no-border="true" @click.native="addAvatar" text="+ Picture"
                                           icon-class="fa-picture-o"
                                           v-if="!avatar"></media-preview-add-request>

                <media-preview-add-request :no-border="true" text="+ Location" @click.native="addLocation"
                                           icon-class="fa-map-marker"
                                           v-if="!location&&!locationData"></media-preview-add-request>
                <media-preview :no-border="true" @onDelete="cleanLocation" :show-name="true" :item="locationPreviewSrc"
                               :text='locationCaption' v-if="location||locationData"
                               :allow-delete="true"></media-preview>

                <media-preview :no-border="true" :item="audio_caption" :allow-delete="true"
                               v-if="audio_caption"
                               @onDelete="cleanAudioCaption" :show-name="true"></media-preview>
                <media-preview-add-request :no-border="true" text="+ Audio"
                                           icon-class="fa-volume-down"
                                           @click.native="addAudio"
                                           v-if="!audio_caption"></media-preview-add-request>
            </div>
        </div>
        <div class="row margin-15-top enlarged-calendar-14" v-if="!is_periodic">
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-12">
                        <form class="form">
                            <span>Start Date:</span>
                            <dropdown class="form-group" ref="start-picker">
                                <div class="input-group">
                                    <input class="form-control" type="text" v-model="startDate"
                                           @click="showStartPicker">
                                    <div class="input-group-btn">
                                        <btn class="dropdown-toggle create-event-date-picker"><i
                                                class="glyphicon glyphicon-calendar"></i></btn>
                                    </div>
                                </div>
                                <template slot="dropdown">
                                    <li>
                                        <date-picker v-model="startDate"
                                                     :limit-to="limitStartTo"
                                                     :date-parser="dateParser"
                                                     format="dd/MM/yyyy"
                                                     :week-starts-with="1"
                                                     :week-numbers="true"
                                                     :width="pickerWidth"
                                        />
                                    </li>
                                </template>
                            </dropdown>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-12">
                        <form class="form">
                            <span>Finish Date:</span>
                            <dropdown class="form-group" ref="end-picker">
                                <div class="input-group">
                                    <input class="form-control" type="text" v-model="endDate" @click="showEndPicker">
                                    <div class="input-group-btn">
                                        <btn class="dropdown-toggle create-event-date-picker"><i
                                                class="glyphicon glyphicon-calendar"></i></btn>
                                    </div>
                                </div>
                                <template slot="dropdown">
                                    <li>
                                        <date-picker v-model="endDate"
                                                     :limit-from="limitEndFrom"
                                                     format="dd/MM/yyyy"
                                                     :date-parser="dateParser"
                                                     :week-starts-with="1"
                                                     :week-numbers="true"
                                                     :width="pickerWidth"
                                        />
                                    </li>
                                </template>
                            </dropdown>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <event-time-editor
                v-if="!advancedTimeScheduleEnabled"
                class="margin-15-top"
                v-model="time"
                :disabled="whole_day"
        ></event-time-editor>

        <div class="alert alert-info margin-15-top"
             v-if="!whole_day&&allowToggleAdvancedTimeSchedule&&advancedTimeScheduleEnabled">
            Advanced Time schedule enabled. <strong class="pointer" @click="showAdvancedTimeSchedulePopup"><i
                class="fa fa-pencil"></i>Edit schedule</strong>
        </div>

        <div class="row margin-15-top" v-show="!advancedMode">
            <div class="col-sm-12">
                <a href="javascript:;" class="pointer" @click="enableAdvancedMode">Advanced Options</a>
            </div>
        </div>
        <div class="row margin-15-top" v-show="advancedMode">
            <div class="col-sm-12">
                <span>Settings:</span>
                <div class="margin-5-top">
                    <vue-switch-with-label @input="toggleAdvancedTimeScheduleEnabled"
                                           :value="advancedTimeScheduleEnabled"
                                           :disabled="whole_day"
                                           name="advanced-schedule"
                                           v-if="allowToggleAdvancedTimeSchedule"
                                           label="Advanced Schedule"
                                           help-text="Allows set Start and Finish time separately for each day of week. Available only for 'Days of week' repeat type."
                    ></vue-switch-with-label>
                </div>
                <div class="margin-5-top">
                    <vue-switch-with-label v-model="whole_day" name="whole-day"
                                           label="All day"></vue-switch-with-label>
                </div>
                <div class="margin-5-top">
                    <vue-switch-with-label v-model="is_private" name="private-event"
                                           label="Make this a Private Event."></vue-switch-with-label>
                </div>
                <div class="margin-5-top">
                    <vue-switch-with-label v-model="veto_conversation_posts" name="approve-messages"
                                           label="Approve messages"></vue-switch-with-label>
                </div>
            </div>
            <advanced-time-schedule-popup ref="advanced-time-schedule-popup"></advanced-time-schedule-popup>
        </div>
        <div slot="footer">
            <button class="btn btn-mm btn-red" @click="deleteEvent">Delete Event</button>
            <button class="btn btn-mm" @click="closePopup">Cancel</button>
            <button class="btn btn-mm btn-green save-button" :disabled="!allowSave" @click="save">
                Save
            </button>
        </div>
    </modal>
</template>

<script>
    import Vue from 'vue';
    import moment from 'moment';
    import _ from 'lodash';
    import ScreenWidthAwareMixin from "../../../../vue/mixins/ScreenWidthAwareMixin";
    import {VISUAL_DATE_FORMAT} from "../new_calendar/config";
    import EventTimeEditor from "./EventTimeEditor.vue";
    import AdvancedTimeSchedulePopup from "./AdvancedTimeSchedulePopup.vue";
    import {nullify} from "../new_calendar/common";
    import FullAddressPopup from "../create_new_event/FullAddressPopup";

    const dateParser = strVal => {
        if (!strVal)
            return;
        const d = moment.utc(strVal, VISUAL_DATE_FORMAT).unix() * 1000; //.add(moment().utcOffset(), 'm')
        return d;
    };


    const DEFAULT_TIME = {
        start: {
            hour: 12,
            minute: '00',
        },
        end: {
            hour: 13,
            minute: '00',
        }
    };


    const DEFAULT_CONFIG = {
        is_periodic: false,

        is_private: false,
        veto_conversation_posts: false,

        name: '',
        description: '',
        avatar: undefined,
        audio_caption: undefined,

        startDate: undefined,
        endDate: undefined,

        whole_day: false,


        time: _.merge({}, DEFAULT_TIME),

        location: undefined,  // difference is was set id initially(location) or added in popup(locationData)
        locationData: undefined,

        advancedMode: false,

        advancedTimeScheduleEnabled: false,
        advancedTimeSchedule: {},
        allowToggleAdvancedTimeSchedule: false,
        daysOfWeekForAdvancedSchedule: [],
    };

    export default {
        name: "EditEventDataPopup",
        mixins: [ScreenWidthAwareMixin],
        components: {EventTimeEditor, AdvancedTimeSchedulePopup},
        data() {
            return _.merge({
                opened: false,
                dateParser,
            }, DEFAULT_CONFIG);
        },
        computed: {
            pickerWidth() {
                return Math.min(340, this.windowWidth - 40);
            },
            locationPreviewSrc() {
                if (!(this.locationData || this.location)) {
                    return;
                }
                const Maps = Vue.getAngularModule('Maps');
                const point = this.locationData ? this.locationData : undefined || {
                    lat: this.location.latitude,
                    lng: this.location.longitude,
                    zoom: this.location.zoom,
                };
                return Maps.get_static_map_href(point, "100x70");
            },
            locationCaption() {
                if (!this.location && !this.locationData) {
                    return;
                }
                return this.location ? this.location.address_line_1 : undefined || this.locationData ? this.locationData.address_line_1 : undefined;
            },
            allowSave() {
                if (!this.whole_day && !this.advancedTimeScheduleEnabled) {
                    const startHour = parseInt(this.time.start.hour);
                    const endHour = parseInt(this.time.end.hour);
                    if (endHour < startHour) {
                        return false;
                    }
                    if (endHour === startHour) {
                        const startMinute = parseInt(this.time.start.minute);
                        const endMinute = parseInt(this.time.end.minute);
                        if (endMinute < (startMinute + 1)) {
                            return false;
                        }
                    }
                }
                return this.name.length > 2;
            },
            startDateMoment() {
                return moment(this.startDate, VISUAL_DATE_FORMAT);
            },
            endDateMoment() {
                return moment(this.endDate, VISUAL_DATE_FORMAT);
            },
            limitStartFrom() {
                return moment().format(VISUAL_DATE_FORMAT);
            },
            limitStartTo() {
                if (this.is_periodic) {
                    return;
                }
                if (this.endDate) {
                    return this.endDateMoment.clone().add(1, 'd').format(VISUAL_DATE_FORMAT);
                }
            },
            limitEndFrom() {
                if (this.startDate) {
                    return this.startDateMoment.format(VISUAL_DATE_FORMAT);
                } else {
                    return moment().format(VISUAL_DATE_FORMAT);
                }
            },
        },
        methods: {
            enableAdvancedMode() {
                this.advancedMode = true;
            },
            getInitialAdvancedTimeScheduleConfig() {
                let res = {};
                for (let key of this.daysOfWeekForAdvancedSchedule.map(weekdayNum => 1 + weekdayNum)) {// 0-6 to 1-7
                    if (this.advancedTimeSchedule && this.advancedTimeSchedule[key]) {
                        res[key] = {
                            start: {
                                hour: nullify(this.advancedTimeSchedule[key].start.hour),
                                minute: nullify(this.advancedTimeSchedule[key].start.minute),
                            },
                            end: {
                                hour: nullify(this.advancedTimeSchedule[key].end.hour),
                                minute: nullify(this.advancedTimeSchedule[key].end.minute),
                            }
                        };
                    } else {
                        res[key] = _.merge({}, DEFAULT_TIME);
                    }
                }
                return res;
            },
            showAdvancedTimeSchedulePopup() {
                this.opened = false;
                this.$refs['advanced-time-schedule-popup'].open(this.getInitialAdvancedTimeScheduleConfig())
                    .then(res => {
                        this.advancedTimeScheduleEnabled = true;
                        this.advancedTimeSchedule = res;
                        this.opened = true;
                    }, rej => {
                        this.opened = true;
                    });
            },
            toggleAdvancedTimeScheduleEnabled(val) {
                if (val) {
                    this.showAdvancedTimeSchedulePopup();
                } else {
                    this.advancedTimeScheduleEnabled = val;
                    this.advancedTimeSchedule = {};
                }
            },
            editEventData(initial) {
                const self = this;
                this.opened = true;

                for (let key in DEFAULT_CONFIG) {
                    const value = initial[key] || DEFAULT_CONFIG[key];
                    if (_.isObject(value)) {
                        this[key] = _.merge((_.isArray(value) ? [] : {}), value);
                    } else {
                        this[key] = value;
                    }
                }

                return new Promise((resolve, reject) => {
                    self.resolve = resolve;
                    self.reject = reject;
                });
            },
            closePopup() {
                this.opened = false;
                this.reject('closed');
            },
            deleteEvent() {
                this.opened = false;
                this.reject('delete_event');
            },
            save() {
                this.opened = false;
                const res = {
                    name: this.name,
                    description: this.description,
                    is_private: this.is_private,
                    whole_day: this.whole_day,
                    approve_messages: this.veto_conversation_posts,

                };
                if (this.avatar) {
                    res['media_for_avatar_id'] = this.avatar.pk;
                }
                if (this.audio_caption) {
                    res['media_for_audio_caption_id'] = this.audio_caption.pk;
                }
                if (this.locationData) {
                    res['location_data'] = this.locationData;
                }
                if (this.location) {
                    res['location_id'] = this.location.id;
                }

                if (!this.is_periodic && this.endDate && this.startDate) {
                    res.end_date = this.endDate;
                    res.start_date = this.startDate;
                }
                if (!this.whole_day) {
                    res.start_time = `${this.time.start.hour}:${this.time.start.minute}`;
                    res.end_time = `${this.time.end.hour}:${this.time.end.minute}`;
                    if (this.advancedTimeScheduleEnabled && Object.keys(this.advancedTimeSchedule).length > 0) {
                        res.advanced_time_schedule = this.advancedTimeSchedule;
                        res.advanced_time_schedule_enabled = true;
                    } else {
                        res.advanced_time_schedule_enabled = false;
                    }
                } else {
                    res.advanced_time_schedule_enabled = false;
                }

                this.resolve(res);
                this.opened = false;
            },
            show() {
                this.opened = true;
            },
            addAvatar() {
                const addMedia = Vue.getAngularModule('addMedia');
                this.opened = false;

                let show_add_media_menu = (default_callback, cancel_callback) => {
                    addMedia.add_media(
                        {
                            allow_add_from_library: true,
                            max_count: 1,
                            type: 'image',
                            popup_header: 'How you would like to add a picture?'
                        },
                        {
                            default_callback,
                            cancel_callback
                        }
                    );
                };

                let add_media_items = (items_list) => {
                    this.avatar = items_list[0];
                    this.show();
                };

                show_add_media_menu(add_media_items, () => {
                    this.show();
                });
            },
            addAudio() {
                const addMedia = Vue.getAngularModule('addMedia');
                this.opened = false;
                let show_add_media_menu = () => {
                    addMedia.add_media(
                        {
                            allow_add_from_library: true,
                            max_count: 1,
                            type: 'audio',
                            popup_header: 'What sort of media would you like to add?'
                        },
                        {
                            default_callback: (items_list) => {
                                this.audio_caption = items_list[0];
                                this.show();
                            },
                            cancel_callback() {
                                this.show();
                            }
                        }
                    );
                };
                show_add_media_menu();
            },
            addLocation() {
                const Maps = Vue.getAngularModule('Maps');
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                const $q = Vue.getAngularModule('$q');
                this.opened = false;
                let address_line_1, address_line_2, region, country, city, postcode, lat, lng, zoom;

                function location_options(initial_data, popup_config = {}) {

                    let DEFAULT_POPUP_CONFIG = {
                        button_yes: 'OK',
                    };
                  const {popupComponent, vm} = simplePopupFactory.mount_vue_popup(FullAddressPopup, {
                    initialData: initial_data,
                    popup_config: _.merge({}, DEFAULT_POPUP_CONFIG, popup_config)
                  });
                  return new Promise((resolve) => {
                    popupComponent.show().then((popup_result)=>{
                      vm?.$destroy();
                      resolve(popup_result);
                    });
                  })
                }

                Maps.find_location_no_create()
                    .then(
                        foundPoint => {
                            zoom = foundPoint.zoom;
                            lat = foundPoint.point.lat;
                            lng = foundPoint.point.lng;
                            if (foundPoint.addressComponents !== undefined) {
                                ({
                                    postcode,
                                    country,
                                    region,
                                    city
                                } = Maps.get_data_from_address_components(foundPoint.addressComponents));
                                address_line_1 = foundPoint.address;
                            } else {
                                let address_components = _.split(foundPoint.address, ', ');
                                if (address_components.length > 1) {
                                    country = address_components.pop();
                                }
                                if (address_components.length > 1) {
                                    city = address_components.pop();
                                }
                                address_line_1 = _.join(address_components, ', ');
                            }
                            return location_options({address_line_1, country, city, postcode, region});
                        }, () => new Promise(()=>{})
                    )
                    .then(location_details => {
                        ({address_line_1, address_line_2, region, country, city, postcode} = location_details);
                        this.locationData = {
                            address_line_1,
                            address_line_2,
                            region,
                            country,
                            city,
                            postcode,
                            lat,
                            lng,
                            zoom,
                        };
                    }, () => new Promise(()=>{}))
                    .finally(this.show);
            },
            cleanLocation() {
                this.location = undefined;
                this.locationData = undefined;
            },
            cleanAvatar() {
                this.avatar = undefined;
            },
            cleanAudioCaption() {
                this.audio_caption = undefined;
            },
            showStartPicker() {
                this.$refs['start-picker'].toggle();
            },
            showEndPicker() {
                this.$refs['end-picker'].toggle();
            },
        },
        watch: {}
    };
</script>

<style scoped>

</style>
