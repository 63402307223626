var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "dashboard-list-item hvr-float-shadow",
      attrs: { href: _vm.link },
    },
    [
      _c("div", {
        staticClass: "dashboard-list-item__image mobile",
        class: { enlarged: _vm.enlarged },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dashboard-list-item__image desktop",
          class: { enlarged: _vm.enlarged },
        },
        [
          _vm.enlarged
            ? _c("img", {
                staticClass: "dashboard-list-item__image__avatar-inside",
                attrs: { src: _vm.currentUserAvatar, alt: "" },
              })
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dashboard-list-item__label",
          class: { enlarged: _vm.enlarged },
        },
        [_vm._v(_vm._s(_vm.label))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }