<template>
    <div class="quote-component" :class="{'limit-size':limitSize}">
        <div>
            <slot name="comment"></slot>
        </div>

        <div class="quote-component__quote" :style="quoteBorderStyle">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "renderQuote",
        props: {
            limitSize: {
                type: Boolean,
                default: false,
            },
            quoteBorderColor: {
                type: [String, null],
                default: '#9c9c9c'
            }
        },
        computed: {
            quoteBorderStyle() {
                if (this.quoteBorderColor) {
                    return {
                        'border': `1px solid ${this.quoteBorderColor}`,
                        'padding': '10px 15px',
                    };
                }
            }
        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";

  .quote-component {
    $mobile-size: 480px;

    position: relative;
    max-width: 100%;
    margin: auto;
    border-radius: 3px;

    @media (min-width: $mobile-size + 1px) {
      &.limit-size {
        max-width: 60%;
      }
      img {
        max-width: 80%;
        max-height: 300px;
      }
    }

    &__quote {
      border-radius: 0 10px 10px 10px;
    }
  }
</style>