<template>
    <div class="">
        <div class="row" v-if="sticker">
            <div class="col-sm-12 text-center">
                <div class="create_like_story_post_popup__sticker_container">
                    <div class="create_like_story_post_popup__remove-sticker-cross" @click="removeSticker">x</div>
                    <render-sticker :sticker="sticker"></render-sticker>
                </div>
            </div>
        </div>
        <div class="row margin-15-top" v-if="emotion">
            <div class="col-sm-12">
                <entry-emotion :emotion="emotion"></entry-emotion>
                <i @click="resetEmotion()" class="fa fa-times margin-5-left pointer"
                   style="vertical-align: top; font-size: 22px; color: #8e8e8e"></i>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-xs-12" style="height: 32px">
                        <span class="align-helper"></span>
                        <label style="vertical-align: bottom">Add text entry below...</label>
                    </div>
                </div>
                <textarea class="form-control modal-text-input-textarea" v-model="text" rows="5"></textarea>
            </div>
        </div>
        <div class="row smile-panel">
            <div class="col-sm-6 add_activity_input_form__button_panel">
                <div class="add_activity_input_form__attach_button green"
                     :class="{disabled:isMediaLimitReached}"
                     @click="addMedia"
                     @keydown.enter="addMedia"
                     role="button"
                     aria-label="Add Media"
                     tabindex="0"
                >
                    <i class="fa fa-film vertical-middle"></i>
                    <i class="fab fa-itunes-note vertical-middle"></i>
                </div>
                <div class="add_activity_input_form__attach_button blue"
                     @click="addSticker()"
                     @keydown.enter="addSticker()"
                     role="button"
                     aria-label="Add Sticker"
                     tabindex="0"
                >
                    <i class="far fa-thumbs-up vertical-middle"></i>
                    <i class="far fa-heart vertical-middle"></i>
                </div>
                <div class="add_activity_input_form__attach_button yellow"
                     @click="addEmotion()"
                     @keydown.enter="addEmotion()"
                     role="button"
                     aria-label="Add Emotion"
                     tabindex="0"
                >
                    <i class="far fa-smile-beam vertical-middle"></i>
                    <i class="far fa-frown vertical-middle"></i>
                </div>
            </div>
            <div class="col-sm-6">

            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div style="display: inline-block; margin-top: 5px; margin-left: 3px; position: relative; vertical-align: top;"
                     v-for="(postItem, i) in visibleMediaItems">
                    <div :style="invisibleCounterStyle"
                         class="create_like_story_post_popup__additional_count_indicator"
                         v-if="(i===(visibleMediaItems.length-1))&&countOfInvisibleMediaItems">
                        +{{ countOfInvisibleMediaItems }}
                    </div>
                    <media-preview
                            :allow-delete="true"
                            :allow-edit="false"
                            @onDelete="deletePostItem(postItem)"
                            @onEdit="editPostItem(postItem)"
                            :item='postItem.mediaAttachment'
                            :no-border="true"
                    ></media-preview>
                </div>
                <div style="display: inline-block; width: 100%; height: 1px;"></div>
            </div>
        </div>
        <div class="row text-right" v-if="!!countOfInvisibleMediaItems&&!showAllPostItems">
            <div class="col-sm-12"><a href="javascript:;" @click="expandPostItems">+ Show All</a></div>
        </div>
        <div id="save_to_diary_toggle" class="row" style="margin-top: 5px;" v-show="allowShareWithCircle">
            <div class="col-sm-12">
                <div class="inline-block">
                    <vue-switch-with-label v-model="saveToDiary" :label="shareLabel"
                                           name="saveToDiary"></vue-switch-with-label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div id="otherUsersEntry" class="row" style="margin-top: 5px;" v-show="canPostInOtherDiaries">
                    <div class="col-sm-12">
                        <div class="inline-block">
                            <vue-switch-with-label :value="addToSuDiaries" @input="switchAddToSuDiaries"
                                                   label="Add to user Diaries"
                                                   name="switchAddToSuDiaries"></vue-switch-with-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row margin-15-top">
            <div class="col-sm-12">
                <create-blog-entry-popup-users-list
                        :users-list="usersToAddToDiary"
                        :all-users-selected="allUsersSelected"
                ></create-blog-entry-popup-users-list>
            </div>
        </div>
        <div class="margin-5-top" v-if="!allowShareWithCircle&&!membershipStatus.owner_has_access_to_event">
            <strong>{{ calendarOwner.name }}</strong> will not see this Entry as they do not have access to this Event.
        </div>
    </div>
</template>

<script>
    import RenderSticker from "../../add_media/stickers/render_sticker_directive/RenderSticker.vue";
    //  import EmotionsPanel from './EmotionsPanel.vue';
    import Vue from 'vue';
    import _ from 'lodash';
    import CreateBlogEntryPopupUsersList from "../../diary_page/popups/CreateBlogEntryPopupUsersList.vue";
    import {VISUAL_DATE_FORMAT} from "../new_calendar/config";
    import EmotionsFactory from "../../diary_page/common/blog_entry_emotions/emotionsFactory";
    import EntryEmotion from "../../blogolog/emotion_directive/EntryEmotion";
    import {simplePopupFactory} from "shared/simple_popups/simplePopupFactory";
    import CreateEditPostPopup from "../../add_media/CreateEditPostPopup";

    const MAX_MEDIA_ITEMS = 50;


    export default {
        name: "EventDetailPageCreateDiaryEntryPopupBlogPart",
        components: {EntryEmotion, RenderSticker, CreateBlogEntryPopupUsersList},
        props: {
            membershipStatus: Object,
            calendarOwner: Object,
            periodInfo: Object,
            dateMoment: Object,
            buddyMode: Boolean,
            canPostInOtherDiaries: Boolean,
        },
        data() {
            return {
                text: '',
                sticker: undefined,
                allPostItems: [],
                emotion: undefined,
                saveToDiary: false,
                addToSuDiaries: false,
                showAllPostItems: false,
                countOfPreviews: 5,

                usersToAddToDiary: [],
                allUsersSelected: false,
            };
        },
        computed: {
            shareLabel() {
                if (this.buddyMode) {
                    return `Share with ${this.calendarOwner.name}'s Circle`;
                } else {
                    return 'Share with my Circle';
                }
            },
            allowShareWithCircle() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                return this.membershipStatus.added_to_calendar && CurrentUser.has_permission('share');
            },
            isMediaLimitReached() {
                return this.allPostItems.length >= MAX_MEDIA_ITEMS;
            },
            visibleMediaItems() {
                return _.take(this.allPostItems, this.countOfPreviews);
            },
            countOfInvisibleMediaItems() {
                return this.allPostItems.length - this.visibleMediaItems.length;
            },
            invisibleCounterStyle() {
                if (this.countOfInvisibleMediaItems === 0) {
                    return {};
                }
                if (this.countOfInvisibleMediaItems < 10) {
                    return {left: '20px'};
                }
                if (this.countOfInvisibleMediaItems >= 10) {
                    return {left: '6px'};
                }
            },
            isCreateButtonEnabled() {
                return !!(this.text || this.sticker || this.emotion || this.allPostItems.length);
            }
        },
        created() {
        },
        methods: {
            expandPostItems() {
                this.showAllPostItems = true;
                this.countOfPreviews = 50;
            },
            setUsersToAddToDiary(profilesList) {
                this.usersToAddToDiary = profilesList;
                if (profilesList.length) {
                    this.addToSuDiaries = true;
                } else {
                    this.addToSuDiaries = false;
                }
            },
            removeSticker() {
                this.sticker = undefined;
            },
            resetEmotion() {
                this.emotion = undefined;
            },
            addMedia() {
                const addMedia = Vue.getAngularModule('addMedia');
                this.$emit('hide');
                const add_media_items = (itemsList) => {
                    for (let mediaItem of itemsList) {
                        this.allPostItems.push({
                            mediaAttachment: mediaItem,
                        });
                    }
                    this.$emit('show');
                };
                const show_create_popup = () => {
                    this.$emit('show');
                };
                addMedia.add_media(
                    {
                        allow_add_from_library: true,
                        max_count: MAX_MEDIA_ITEMS,
                        types: ['video', 'audio', 'image', 'document'],
                        popup_header: 'What sort of media would you like to add?'
                    },
                    {
                        default_callback: add_media_items,
                        cancel_callback: show_create_popup
                    }
                );
            },
            addSticker() {
                this.$emit('hide');
                const show_create_popup = () => {
                    this.$emit('show');
                };
                const stickersFactory = Vue.getAngularModule('stickersFactory');
                stickersFactory.select_sticker().then((sticker) => {
                    this.sticker = sticker;
                    show_create_popup();
                }, show_create_popup);
            },
            addEmotion() {
                this.$emit('hide');
                const show_create_popup = () => {
                    this.$emit('show');
                };
                new EmotionsFactory().showAddEmotionsPopup()
                    .then(emotion => {
                        this.emotion = emotion.code;
                    }, () => {
                    })
                    .finally(show_create_popup);
            },
            editPostItem(postItem) {
                this.$emit('hide');
                const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(CreateEditPostPopup);
                popupComponent.show({
                    header: 'Edit Post',
                    button_no: 'Cancel',
                    button_yes: 'Save',
                }, postItem)
                    .then(editedData => {
                        for (let key of Object.keys(editedData)) {
                            postItem[key] = editedData[key];
                        }
                        this.$emit('show');
                    }, () => {
                        this.$emit('show');
                    })
                    .finally(() => {
                        vm?.$destroy();
                        fEl?.focus();
                    });
            },
            deletePostItem(postItem) {
                this.allPostItems.splice(this.allPostItems.indexOf(postItem), 1);
            },
            dumpData() {
                const stickersFactory = Vue.getAngularModule('stickersFactory');
                let entry_data = {};
                entry_data.text = this.text;
                entry_data.emotion = this.emotion;
                entry_data.post_items = this.allPostItems.map(postItem => ({
                    media_attachment_id: postItem.mediaAttachment.id,
                    text: postItem.text,
                    sticker: stickersFactory.get_sticker_code(postItem.sticker),
                    is_header: postItem.isHeader,
                }));
                entry_data.sync_with_diary = this.saveToDiary;
                if (this.sticker) {
                    const stickersFactory = Vue.getAngularModule('stickersFactory');
                    entry_data.sticker = stickersFactory.get_sticker_code(this.sticker);
                }
                if (this.canPostInOtherDiaries && this.addToSuDiaries) {
                    entry_data.add_to_diaries = true;
                    entry_data.all_diaries_selected = this.allUsersSelected;
                    entry_data.selected_users_ids = this.usersToAddToDiary.map(u => u.id);
                }
                return entry_data;
            },
            resetData() {
                this.text = '';
                this.sticker = undefined;
                this.emotion = undefined;
                this.allPostItems = [];
                this.saveToDiary = false;
                this.allUsersSelected = false;
                this.usersToAddToDiary = [];
                this.addToSuDiaries = false;

            },
            showDiariesSelector() {
                const selectFactory = Vue.getAngularModule('selectFactory');
                const CalendarEventBlogEntriesResource = Vue.getAngularModule('CalendarEventBlogEntriesResource');

                return selectFactory
                    .endpoint_selector(_.partial(CalendarEventBlogEntriesResource.get_blogs_i_can_create_entries, this.periodInfo.event_id, this.calendarOwner.id, this.dateMoment.format(VISUAL_DATE_FORMAT)),
                        true,
                        {
                            header: 'Create Diary entries',
                            info: 'Select or de-select multi Supported Users from the list to create entries in their Diaries.',
                            button_yes: 'Select',
                            button_no: 'Cancel',
                            empty: 'You do not have any users to select',
                            long_text: false,
                            select_all_html: 'Select all<br>Supported Users.'
                        }, false, false, true)
                    .then(([new_checked, new_unchecked, all_selected]) => {
                        this.$emit('show');
                        this.allUsersSelected = all_selected;
                        // this.usersToAddToDiary = this.usersToAddToDiary.filter(u => !new_unchecked.map(u => u.id).includes(u.id));
                        this.usersToAddToDiary = new_checked;
                        this.$emit('selectedSUListUpdated', new_checked);
                    }, err => {
                        this.$emit('hide');
                        this.allUsersSelected = false;
                        this.usersToAddToDiary = [];
                        this.$emit('selectedSUListUpdated', []);
                    });
            },
            switchAddToSuDiaries(val) {
                this.addToSuDiaries = val;
                this.$emit('addToSuDiariesChanged', val);
                if (val) {
                    this.$emit('hide');
                    this.showDiariesSelector()
                        .finally(() => {
                            this.$emit('show');
                        });
                } else {
                    this.allUsersSelected = false;
                    this.usersToAddToDiary = [];
                    this.$emit('selectedSUListUpdated', []);
                }
            }
        },
        watch: {
            isCreateButtonEnabled(enabled) {
                this.$emit('toggleSaveReady', enabled);
            }
        }
    };
</script>

<style scoped>

</style>
