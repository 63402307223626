<template>
    <div class="media-folders__container__wrap">
        <div class="media-folders__container">
            <users-media-page-header
                    :folders-path="foldersPath"
                    :currentUser="currentUser"
                    :folders="folders"
                    :isEditableByViewer="isEditableByViewer"
                    :initialQuery="filter.query"
                    :viewerStatus="viewerStatus"
                    :editMode="editMode"
                    :allowMakeMeAdmin="allowMakeMeAdmin"

                    :massActionsState="massActionsState"
                    :from-url="urlFrom"

                    @deleteFolder="deleteFolder"
                    @renameFolder="renameFolder"
                    @addMedia="addMedia"
                    @makeMeAdmin="makeMeAdmin"
                    @createFolder="createFolder"
                    @goToFolder="selectFolder"
                    @goToMedia="goToMedia"
                    @goBack="historyBack"
                    @manageAccess="manageAccess"
                    @onSearch="onSearch"
                    @onCancelSearch="onCancelSearch"
                    @toggleEditMode="toggleEditMode"
            ></users-media-page-header>
            <div class="row margin-5-top">
                <users-media-page-filter-panel
                        :massActionsState="massActionsState"
                        :noMedia="!media.length&&!filter.query&&!filter.type"

                        :filter="filter"
                        :isMyMedia="isMyMedia"
                        :isRootFolder="isRootFolder"
                        @setFilter="setFilterType"
                        @changeSharedMode="changeSharedMode"
                ></users-media-page-filter-panel>
                <div class="col-md-10 media-folders__items-container">
                    <div class="" style="margin-left: 10px; margin-bottom: 5px">
                        <span v-show="fakePreviousFolder||folders.length||managedFoldersExists">
                            <strong>Folders:</strong>
                            <span v-if="managedFoldersExists&&!filter.managed"
                                  class="media-folders__show-managed-toggle" @click="changeManagedMode(true)">(Show Folders I manage)</span>
                            <span v-if="managedFoldersExists&&filter.managed" class="media-folders__show-managed-toggle"
                                  @click="changeManagedMode(false)">(Hide Folders I manage)</span>
                        </span>
                    </div>
                    <users-media-page-folders-container
                            :massActionsState="massActionsState"

                            :fakePreviousFolder="fakePreviousFolder"
                            :currentUser="currentUser"
                            :folders="folders"
                            :editMode="editMode"

                            :currentlyDraggedMedia="currentlyDraggedMedia"
                            :currentlyUnderDragFolder="currentlyUnderDragFolder"
                            @resetCurrentlyUnderDragFolder="resetCurrentlyUnderDragFolder"
                            @setCurrentlyUnderDragFolder="setCurrentlyUnderDragFolder"

                            @goBack="goBack"
                            @selectFolder="selectFolder"
                            @hideFolder="hideFolder"
                    >
                    </users-media-page-folders-container>
                    <div class="" style="margin-left: 10px">
                        <strong v-show="media.length">Media:</strong>
                    </div>
                    <users-media-page-folder-item
                            :massActionsState="massActionsState"

                            :currentlyDraggedMedia="currentlyDraggedMedia"
                            :currentlyUnderDragFolder="currentlyUnderDragFolder"
                            @setCurrentlyDraggedMedia="setCurrentlyDraggedMedia"
                            @resetCurrentlyDraggedMedia="resetCurrentlyDraggedMedia"
                            @resetCurrentlyUnderDragFolder="resetCurrentlyUnderDragFolder"
                            @dropMedia="dropMedia"

                            v-for="mediaItem in media"
                            :allowDelete="editMode&&isItemEditionAllowed(mediaItem)"
                            :allowDrag="editMode&&isItemEditionAllowed(mediaItem)"
                            :allow-edit="editMode&&isItemEditionAllowed(mediaItem)"
                            :attachment="mediaItem"
                            :key="mediaItem.pk"
                            :size="itemSize"
                            :showTooltip="false"
                            @click="onMediaClick(mediaItem)"
                            @delete="deleteMedia(mediaItem.pk)"
                            @editName="editName(mediaItem)"
                    ></users-media-page-folder-item>
                    <div class="alert alert-warning text-center" v-if="!media.length">
                        No Media found...
                    </div>
                </div>
            </div>
            <div v-infinite-scroll="loadMore"></div>
        </div>
    </div>

</template>

<script>
    import UsersMediaPageHeader from './UsersMediaPageHeader.vue';
    import UsersMediaPageFoldersContainer from './UsersMediaPageFoldersContainer.vue';

    import UsersMediaPageFilterPanel from './UsersMediaPageFilterPanel.vue';
    import Vue from 'vue';
    import AngularInjectorMixin from "../../../../../vue/mixins/AngularInjectorMixin";
    import AddMediaMixin from './AddMediaMixin';
    import MEDIA_CONFIG from '../config.js';
    import ScreenWidthAwareMixin from "../../../../../vue/mixins/ScreenWidthAwareMixin";
    import UsersMediaPageFolderItem from './UsersMediaPageFolderItem.vue';
    import MediaPageDragAspectMixin from './drag_aspect/MediaPageDragAspectMixin';
    import deleteAndMoveAspectMixin from './delete_and_move_aspect/deleteAndMoveAspectMixin';
    import deleteOrMoveMenuPopupController from './delete_and_move_aspect/deleteFolderPopup/controller';
    import BackButtonMixin from "../../../../../vue/mixins/BackButtonMixin";

    let loadingBlock = false;
    let initialized = false;

    export default {
        components: {
            UsersMediaPageHeader,
            UsersMediaPageFilterPanel,
            UsersMediaPageFoldersContainer,
            UsersMediaPageFolderItem
        },
        mixins: [deleteAndMoveAspectMixin, AddMediaMixin, AngularInjectorMixin, MediaPageDragAspectMixin, ScreenWidthAwareMixin, BackButtonMixin],
        props: {
            path: {
                type: String,
                required: false
            },
            accessCode: {
                type: String,
                required: false
            },
            username: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                angularModulesNames: ['$rootScope', 'MediaFoldersResource', 'simplePopupFactory', '$q', 'CurrentUser', 'notifications'],

                currentUser: undefined,

                activeFolderSlug: undefined,

                foldersPath: [],
                isEditableByViewer: false,
                managedFoldersExists: false,

                media: [],
                totalCount: undefined,
                allLoaded: false,

                editMode: false,

                folders: [],

                viewerStatus: {
                    isAdmin: false,
                    isBuddy: false,
                    isMember: false,
                    canEditMainMedia: false
                },
                filter: {
                    type: undefined,
                    query: undefined,
                    shared: true,
                    managed: false,
                }
            };
        },
        computed: {
            isRootFolder() {
                return !this.foldersPath.length;
            },
            isMyMedia() {
                let CurrentUser = Vue.getAngularModule('CurrentUser');
                return this.currentUser && CurrentUser.id === this.currentUser.id;
            },
            itemSize() {
                if (this.windowWidth > 1200) {
                    return 168;
                } else if (this.windowWidth > 1000) {
                    return 160;
                } else {
                    const MARGIN = (this.windowWidth >= 768) ? 20 : 12;
                    const AVAILABLE_SPACE = this.windowWidth - 50;
                    const MIN_SIZE = 118;
                    const SIZE_WITH_MARGIN = (MIN_SIZE + MARGIN);
                    const AMOUNT_TO_APPEND = Math.floor(AVAILABLE_SPACE / SIZE_WITH_MARGIN);
                    const TOOK_SPACE = AMOUNT_TO_APPEND * SIZE_WITH_MARGIN;
                    const REST_SPACE = AVAILABLE_SPACE - TOOK_SPACE;

                    // const min_possible_size = 134;
                    // return (this.windowWidth-50)
                    if (REST_SPACE > 0) {
                        return MIN_SIZE + Math.floor(REST_SPACE / AMOUNT_TO_APPEND) - 1;
                    } else {
                        return MIN_SIZE - 1;
                    }
                }

            },
            accessManageLink() {
                return `${MEDIA_CONFIG.MAIN_MEDIA_PREFIX}${this.currentUser.name}/${this.currentUser.id}/settings/`;
            },
            activeFolder() {
                if (this.foldersPath.length) {
                    return this.foldersPath[this.foldersPath.length - 1];
                }
            },
            fakePreviousFolder() {
                if (this.foldersPath.length) {
                    const fakeFolder = {name: '⮢', id: -1, isFake: true};
                    if (this.foldersPath.length === 1) {
                        fakeFolder.is_editable_by_viewer = this.viewerStatus.canEditMainMedia;
                    } else {
                        fakeFolder.is_editable_by_viewer = this.foldersPath[this.foldersPath.length - 2].is_editable_by_viewer;
                        fakeFolder.id = this.foldersPath[this.foldersPath.length - 2].id;
                    }
                    return fakeFolder;
                }
            },
            queryParams() {
                return {
                    edit: this.editMode ? this.editMode : undefined,
                    query: this.filter.query,
                    type: this.filter.type ? this.filter.type : undefined,
                    shared: !this.filter.shared ? false : undefined,
                    managed: this.filter.managed ? true : undefined,
                    // access_code: this.accessCode,
                };
            },
            allowMakeMeAdmin() {
                return this.viewerStatus.isBuddy && !this.viewerStatus.isAdmin;
            }
        },
        methods: {
            historyBack() {
                if (this.urlFrom) {
                    this.$_goBack();
                    return;
                }
                this.$router.back();
            },
            isItemEditionAllowed(mediaItem) {
                if (this.viewerStatus.isAdmin) {
                    return true;
                }
                return this.isEditableByViewer;
            },
            createFolder() {
                let simplePopupFactory = this.angularModules['simplePopupFactory'];
                let MediaFoldersResource = this.angularModules['MediaFoldersResource'];
                let $rootScope = this.angularModules['$rootScope'];
                let $q = Vue.getAngularModule('$q');
                simplePopupFactory.simple_text_input_popup('Create Folder', 'Folder Name:', 'Create', 'Cancel', '', 127)
                    .then(name => {
                        $rootScope.show_dimmer();
                        return MediaFoldersResource.create_folder(this.currentUser.id, name, this.activeFolderSlug);
                    }, cancel => {
                        return new Promise(() => {
                        });
                    })
                    .then(resp => {
                        this.folders.unshift(resp.data);
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            moveMediaToFolder(mediaToMoveId, targetFolderId) {
                let MediaFoldersResource = this.angularModules['MediaFoldersResource'];
                let $rootScope = this.angularModules['$rootScope'];
                $rootScope.show_dimmer();
                MediaFoldersResource.move_media_to_folder(this.currentUser.id, [mediaToMoveId], targetFolderId)
                    .then(resp => {
                        this.media = this.media.filter(m => m.pk !== mediaToMoveId);
                        Vue.notifications.success('Moved');
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);

            },
            deleteMedia(mediaId) {
                let MediaFoldersResource = this.angularModules['MediaFoldersResource'];
                let $rootScope = this.angularModules['$rootScope'];
                let $q = this.angularModules['$q'];
                let simplePopupFactory = this.angularModules['simplePopupFactory'];
                simplePopupFactory.show_popup('Delete', 'Are you sure you want to delete this media item?', 'Delete', 'No', 'btn-red')
                    .then(y => {
                        $rootScope.show_dimmer();
                        return MediaFoldersResource.remove_media(this.currentUser.id, [mediaId]);
                    }, () => new Promise(() => {
                    }))
                    .then(resp => {
                        this.media = this.media.filter(m => m.pk !== mediaId);
                        Vue.notifications.success('Deleted');
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            hideFolder(folder) {
                let $rootScope = this.angularModules['$rootScope'];
                let simplePopupFactory = this.angularModules['simplePopupFactory'];
                let MediaFoldersResource = this.angularModules['MediaFoldersResource'];
                simplePopupFactory.show_popup('Hide Folder', `Are you sure you want to hide folder "<strong>${folder.name}</strong>" from your shared folders list?`, 'Hide', 'No', 'btn-red')
                    .then(y => {
                        $rootScope.show_dimmer();
                        return MediaFoldersResource.hide_shared_folder(this.currentUser.id, folder.id);
                    }, n => new Promise(() => {
                    }))
                    .then(resp => {
                        this.folders = this.folders.filter(f => f.id !== folder.id);
                        Vue.notifications.success('Folder successfully hidden');
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            selectFolder(folder) {
                this.activeFolderSlug = folder.slug;

                let path;
                if (this.accessCode) {
                    path = `${MEDIA_CONFIG.MAIN_MEDIA_PREFIX}access-code/${this.accessCode}/${this.currentUser.name}/${folder.url_path}/`;
                } else {
                    path = `${MEDIA_CONFIG.MAIN_MEDIA_PREFIX}${this.currentUser.name}/${folder.url_path}/`;
                }
                this.$router.replace({path, query: this.queryParams});
                this.getFoldersAndMediaData();
            },
            onCancelSearch() {
                this.resetLoadedData();
                this.filter.query = undefined;
                this.getMedia(true);
                this.$router.replace({query: this.queryParams});
            },
            onSearch(val) {
                this.resetLoadedData();
                this.filter.query = val;
                this.getMedia(true);
                this.$router.replace({query: this.queryParams});
            },
            goBack() {
                if (this.foldersPath.length > 1) {
                    let previousFolder = this.foldersPath[this.foldersPath.length - 2];
                    this.activeFolderSlug = previousFolder.slug;
                    let path;
                    if (this.accessCode) {
                        path = `${MEDIA_CONFIG.MAIN_MEDIA_PREFIX}access-code/${this.accessCode}/${this.currentUser.name}/${previousFolder.url_path}/`;
                    } else {
                        path = `${MEDIA_CONFIG.MAIN_MEDIA_PREFIX}${this.currentUser.name}/${previousFolder.url_path}/`;
                    }
                    this.$router.replace({path, query: this.queryParams});
                    this.getFoldersAndMediaData();
                } else {
                    this.goToMedia();
                }
            },
            goToMedia() {
                this.activeFolderSlug = undefined;

                let path;
                if (this.accessCode) {
                    path = `${MEDIA_CONFIG.MAIN_MEDIA_PREFIX}access-code/${this.accessCode}/${this.currentUser.name}/`;
                } else {
                    path = `${MEDIA_CONFIG.MAIN_MEDIA_PREFIX}${this.currentUser.name}/`;
                }
                this.$router.replace({
                    path,
                    query: this.queryParams
                });
                this.getFoldersAndMediaData();
            },
            manageAccess() {
                this.$router.replace(this.accessManageLink);
            },
            getFoldersData(showDimmer = false) {
                let $rootScope = Vue.getAngularModule('$rootScope');
                if (showDimmer) {
                    $rootScope.show_dimmer();
                }
                let MediaFoldersResource = Vue.getAngularModule('MediaFoldersResource');

                let foldersDataResource;
                if (this.activeFolderSlug) {
                    foldersDataResource = MediaFoldersResource.get_in_folder_folders_data;
                } else {
                    foldersDataResource = MediaFoldersResource.get_main_folders_data;
                }

                return foldersDataResource(this.currentUser.id, this.activeFolderSlug, this.accessCode, this.filter.shared, this.filter.managed)
                    .then(resp => {
                            this.folders = resp.data.folders;

                            if (this.activeFolderSlug) {
                                this.isEditableByViewer = resp.data.is_editable_by_viewer;
                                this.foldersPath = resp.data.folders_path;
                                this.managedFoldersExists = false;
                            } else {
                                this.isEditableByViewer = this.viewerStatus.canEditMainMedia;
                                this.foldersPath = [];
                                this.managedFoldersExists = resp.data.managed_folders_exists;
                            }

                            if (this.editMode && !this.isEditableByViewer) {
                                this.editMode = false;
                            }
                        },
                        err => {
                            Vue.notifications.error(err || 'Error - can\'t load folders');
                        })
                    .finally(() => {
                        if (showDimmer) {
                            $rootScope.hide_dimmer();
                        }
                    });
            },
            getMedia(showDimmer = false) {
                if (loadingBlock || this.allLoaded) {
                    return;
                } else {
                    loadingBlock = true;
                }
                let MediaFoldersResource = Vue.getAngularModule('MediaFoldersResource');
                let $rootScope = Vue.getAngularModule('$rootScope');
                if (showDimmer) {
                    $rootScope.show_dimmer();
                }
                return MediaFoldersResource.get_users_media(this.currentUser.id, this.activeFolderSlug, {
                    type: this.filter.type,
                    query: this.filter.query,
                    limit: 30,
                    offset: this.media.length
                }, this.accessCode)
                    .then(resp => {
                            this.media = this.media.concat(resp.data.results);
                            this.totalCount = resp.data.count;
                            this.allLoaded = this.media.length >= this.totalCount;
                        },
                        err => {
                            Vue.notifications.error(err || 'Error - can\'t load media');
                        })
                    .finally(() => {
                        if (showDimmer) {
                            $rootScope.hide_dimmer();
                        }
                        loadingBlock = false;
                    });
            },
            resetLoadedData() {
                this.media = [];
                this.totalCount = undefined;
                this.allLoaded = false;
            },
            loadMore() {
                if (!initialized || this.allLoaded) {
                    return;
                }
                this.getMedia(true);
            },
            getFoldersAndMediaData() {
                let $rootScope = this.angularModules['$rootScope'];
                let $q = this.angularModules['$q'];
                $rootScope.show_dimmer();
                this.resetLoadedData();
                $q.all([this.getFoldersData(), this.getMedia()])
                    .then(() => {
                        },
                        err => {

                        })
                    .finally(() => {
                        initialized = true;
                        this.$nextTick($rootScope.hide_dimmer);
                    });
            },
            getMyStatus() {
                let MediaFoldersResource = Vue.getAngularModule('MediaFoldersResource');
                return MediaFoldersResource.get_my_status(this.username, this.accessCode)
                    .then(resp => {
                        this.viewerStatus = resp.data;
                        this.currentUser = resp.data.currentUser;
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                    });
            },
            makeMeAdmin() {
                let $rootScope = this.angularModules['$rootScope'];
                $rootScope.show_dimmer();
                let MediaFoldersResource = this.angularModules['MediaFoldersResource'];
                MediaFoldersResource.join_as_buddy(this.currentUser.id)
                    .then(resp => {
                        this.initialLoad();
                        Vue.notifications.success('You are admin of this Media now.');
                    }, err => {
                        $rootScope.hide_dimmer();
                        Vue.notifications.error(err || 'Error');
                    });

            },
            showBuddysProposal() {
                let simplePopupFactory = this.angularModules['simplePopupFactory'];
                let $rootScope = this.angularModules['$rootScope'];
                let profile = this.currentUser;
                $rootScope.show_dimmer();
                simplePopupFactory.show_popup('Join Media', `Do you want to join <strong>${profile.name}</strong>'s Media?`, 'Join', 'Cancel')
                    .then(y => {
                        this.makeMeAdmin();
                    }, n => {
                        this.$router.back();
                    });
            },
            initialLoad() {
                let $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                this.getMyStatus()
                    .then(() => {
                        if (this.viewerStatus.isMember) {
                            return this.getFoldersAndMediaData();
                        } else {
                            if (this.viewerStatus.isBuddy) {
                                this.showBuddysProposal();
                                $rootScope.hide_dimmer();
                            } else {
                                alert('Access denied');
                                $rootScope.hide_dimmer();
                            }
                        }
                    });
            },
            setFilterType(type) {
                this.filter.type = type;
                this.resetLoadedData();
                this.getMedia(true);
                this.$router.replace({query: this.queryParams});
            },
            changeSharedMode(mode) {
                this.filter.shared = mode;
                this.getFoldersData(true);
                this.$router.replace({query: this.queryParams});
            },
            changeManagedMode(mode) {
                this.filter.managed = mode;
                this.getFoldersData(true);
                this.$router.replace({query: this.queryParams});
            },
            viewMediaItem(mediaItem) {
                if (mediaItem.type === 'uploaded_document') {
                    let openInNewTab = function (url) {
                        let win = window.open(url, '_blank');
                        win.focus();
                    };

                    openInNewTab(mediaItem.uploaded_document.document_url);
                    return;
                }
                let encoding_media = mediaItem.upl_video || mediaItem.recorded_audio;
                if ((mediaItem.type === 'upl_video' || mediaItem.type === 'recorded_audio') && !encoding_media.encoding_done) {
                    Vue.notifications.error('Please wait until the status of the media is ready.');
                    return;
                }
                let pure_items = this.media.filter(m => ['location_point', 'upl_video', 'image', 'youtube_video', 'vimeo_video', 'recorded_video', 'recorded_audio', 'uploaded_audio'].indexOf(m.type) !== -1);
                const viewMediaFactory = Vue.getAngularModule('viewMediaFactory');
                viewMediaFactory.show_media(pure_items, pure_items.indexOf(mediaItem));
            },


            toggleEditMode() {
                this.editMode = !this.editMode;
                this.$router.replace({query: this.queryParams});
            },
            evaluateQuery() {
                if (this.$route.query['type']) {
                    this.filter.type = this.$route.query['type'];
                }
                if (this.$route.query['query']) {
                    this.filter.query = this.$route.query['query'];
                }
                if (this.$route.query['edit']) {
                    this.editMode = true;
                }
                if (this.$route.query['shared']) {
                    this.filter.shared = this.$route.query['shared'] !== 'false';
                }
            },
            renameFolder({folderId, newName}) {
                const MediaFoldersResource = Vue.getAngularModule('MediaFoldersResource');
                const notifications = Vue.getAngularModule('notifications');
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                MediaFoldersResource.rename_folder(this.currentUser.id, folderId, newName)
                    .then(resp => {
                        const updatedFolderData = resp.data;
                        if (this.foldersPath.length) {
                            this.foldersPath[this.foldersPath.length - 1].name = updatedFolderData.name;
                        }
                        // this.foldersPath = updatedFolderData.folders_path;
                        let path = `${MEDIA_CONFIG.MAIN_MEDIA_PREFIX}${this.currentUser.name}/${updatedFolderData.url_path}/`;
                        this.$router.replace({
                            path,
                            query: this.queryParams
                        });
                        notifications.success('Renamed');
                    }, err => {
                        notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            deleteFolder(folder) {
                const MediaFoldersResource = this.angularModules['MediaFoldersResource'];
                const notifications = this.angularModules['notifications'];
                const $rootScope = this.angularModules['$rootScope'];
                const simplePopupFactory = this.angularModules['simplePopupFactory'];
                const $q = this.angularModules['$q'];
                const select = $q.defer();

                if (this.folders.length || this.media.length) {
                    simplePopupFactory.create_popup(deleteOrMoveMenuPopupController, require('./delete_and_move_aspect/deleteFolderPopup/template.html'), {})
                        .then(res => {
                            if (res === 'delete') {
                                select.resolve({folder, mode: 'delete'});
                            }
                            if (res === 'move') {
                                select.resolve({folder, mode: 'move'});
                            }
                        });
                } else {
                    select.resolve({folder, mode: 'delete'});
                }

                select.promise
                    .then(({folder, mode}) => {
                        $rootScope.show_dimmer();
                        return MediaFoldersResource.remove_folder(this.currentUser.id, folder.id, mode);
                    }, cancel => {

                    })
                    .then(resp => {
                        if (this.foldersPath.length >= 2) {
                            this.selectFolder(this.foldersPath[this.foldersPath.length - 2]);
                        } else {
                            this.goToMedia();
                        }
                        notifications.success('Deleted');
                    }, err => {
                        notifications.error(err || 'Error');
                    });

            }
        },
        name: "UsersMediaPage",
        watch: {

            // path(val) {
            //     console.log('path changed', val);
            // }
        },
        created() {
            initialized = false;
            loadingBlock = false;
            // this.$router.push({ path: '', query: { plan: 'private' }});
            if (this.path) {
                let foldersInPathSlugs = this.path.split('/');
                this.activeFolderSlug = foldersInPathSlugs.pop();
            }

            this.evaluateQuery();
            this.initialLoad();
        },
        metaInfo() {
            let title = 'Media';
            if (this.isMyMedia) {
                title = 'My Media';
            } else {
                if (this.currentUser) {
                    title = `Media - ${this.currentUser.name}`;
                }
            }
            return {
                title,
            };
        }
    };
</script>

<style scoped lang="scss">
  @import "../../../styles/const";

  .media-folders {
    &__show-managed-toggle {
      cursor: pointer;
      color: $tag-gray;
    }

    &__items-container {
      @media (min-width: $screen-md-min) {
        padding-left: 0;;
      }
    }

    &__container {
      background-color: #E2E2E2;
      padding: 10px;
      margin-bottom: 40px;

      width: 100%;

      &__wrap {
        min-height: 100%;
        margin-bottom: -50px;
      }
    }
  }
</style>
