<template>
    <div>
        <div class="wiki-detail-page-slide-container" :style="containerStyle" v-hotkey="keymap">
            <div class="wiki-detail-page-slide-container__left-panel" :style="arrowsContainerStyle">
                <div class="wiki-detail-page-slide-container__left-panel__arrow"
                     :style="arrowStyle"
                     @click="prevSlide()">
                    <i class="far fa-chevron-left vertical-top"></i>
                </div>
            </div>
            <div class="wiki-detail-page-slide-container__inner-container"
                 v-if="activeSlide"
                 v-touch:swipe.right="prevSlide"
                 v-touch:swipe.left="nextSlide"
            >
                <div class="wiki-detail-page-slide-container__inner-container__slide-wrapper">
                    <div class="wiki-detail-page-slide-container__inner-container__header" :style="headerStyle">
                        <wiki-detail-page-slide-header
                                :edit-mode="editMode"
                                :slide="activeSlide"
                                :height="$_headerHeight"
                                ref="slideHeader"
                        ></wiki-detail-page-slide-header>
                    </div>
                    <div class="wiki-detail-page-slide-container__inner-container__content" :style="contentStyle">

                        <div class="wiki-detail-page-slide-container__inner-container__content__wrapper"
                             v-if="activeSlide&&activeSlide.media_content"
                             style="padding: 0 15px">
                            <vue-inline-media
                                    :autoplay="false"
                                    :item="activeSlide.media_content"
                                    :full-size="true"
                                    :lazy-load="true"
                                    :key="activeSlide.media_content.id"
                                    ref="inlineMedia"
                            ></vue-inline-media>
                            <div class="wiki-detail-page-slide-container__inner-container__content__edit-button"
                                 @click="editSlideMedia"
                                 v-if="editMode">
                                <i class="far fa-pencil"></i>
                            </div>
                        </div>
                        <div class="wiki-detail-page-slide-container__inner-container__content__no-media-placeholder"
                             v-if="activeSlide&&!activeSlide.media_content&&!editMode">
                            <div class="wiki-detail-page-slide-container__inner-container__content__no-media-placeholder__icon"
                                 :style="noMediaIconStyle"
                            >
                                <i class="fa fa-picture-o"></i>
                            </div>
                            <div class="wiki-detail-page-slide-container__inner-container__content__no-media-placeholder__label">
                                There is no media content on this slide...
                            </div>
                        </div>
                        <wiki-detail-page-slide-container-empty-slide-edit-holder
                                v-if="activeSlide&&!activeSlide.media_content&&editMode"
                                :containerHeight="$_containerHeight"
                                :slide="activeSlide"
                        ></wiki-detail-page-slide-container-empty-slide-edit-holder>
                    </div>
                    <div class="wiki-detail-page-slide-container__inner-container__description">
                        <wiki-detail-page-slide-description
                                :slide="activeSlide"
                                :edit-mode="editMode"
                                :height="$_descriptionHeight"
                                ref="slideDescription"
                        ></wiki-detail-page-slide-description>
                    </div>
                </div>
            </div>
            <div class="wiki-detail-page-slide-container__inner-container wiki-detail-page-slide-container__inner-container_empty" v-else :style="contentStyle">
                <span>there are no pages yet</span>
            </div>
            <div class="wiki-detail-page-slide-container__right-panel" :style="arrowsContainerStyle">
                <div class="wiki-detail-page-slide-container__right-panel__buttons-panel__button create"
                     v-tooltip.left="'Page log'"
                     v-if="logAvailable"
                     @click="showWikiLog">
                    <i class="far fa-file-contract"></i>
                </div>

                <div class="wiki-detail-page-slide-container__right-panel__buttons-panel" v-if="editMode">
                    <div class="guidance"
                         v-if="isTemplate||hasGuidanceToRead"
                         v-tooltip.left="'Filling Guidance'"
                         @click="showGuidanceDialog">
                        <i class="far fa-lightbulb"></i>
                    </div>
                    <div class="wiki-detail-page-slide-container__right-panel__buttons-panel__button create"
                         v-if="slides.length<100"
                         v-tooltip.left="'Add Page'"
                         @click="createSlide">
                        <i class="far fa-plus-square"></i>
                    </div>
                    <div v-if="(slides.length>1)&&!activeSlide?.is_fixed" class="wiki-detail-page-slide-container__right-panel__buttons-panel__button delete"
                         v-tooltip.left="'Remove Page'">
                        <i class="far fa-trash"
                           @click="removeActiveSlide"></i>
                    </div>
                </div>
                <div class="wiki-detail-page-slide-container__right-panel__arrow"
                     :style="arrowStyle"
                     @click="nextSlide()">
                    <i class="far fa-chevron-right vertical-top"></i>
                </div>
            </div>
        </div>
        <div class="wiki-detail-page-slide-container__mobile-panel">
            <div class="text-left" :class="{'col-xs-3':editMode || logAvailable,'col-xs-6':!editMode}">
                <i class="far fa-chevron-left" @click="prevSlide"></i>
            </div>

            <div class="buttons col-xs-6"
                 :class="{'single': activeSlide?.is_fixed && !(isTemplate||hasGuidanceToRead)}"
                 v-if="editMode"
            >
                <div v-if="!activeSlide?.is_fixed">
                    <i class="far fa-trash" @click="removeActiveSlide"></i>
                </div>

                <div>
                    <i class="far fa-plus-square"
                       @click="createSlide">
                    </i>
                </div>

                <div class="guidance" v-if="isTemplate||hasGuidanceToRead">
                    <i class="far fa-lightbulb"
                       @click="showGuidanceDialog"></i>
                </div>
            </div>

            <div class="col-xs-6 single-button"
                 v-if="logAvailable"
                 @click="showWikiLog">
                <i class="far fa-file-contract"></i>
            </div>

            <div class="text-right" :class="{'col-xs-3':editMode || logAvailable,'col-xs-6':!editMode}">
                <i class="far fa-chevron-right" @click="nextSlide"></i>
            </div>

        </div>
    </div>

</template>

<script>
    import VueInlineMedia from "../view_media/vue_inline_media_render_directive/VueInlineMedia";
    import WikiDetailPageSlideHeader from "./WikiDetailPageSlideHeader";
    import WikiDetailPageSlideDescription from "./WikiDetailPageSlideDescription";
    import ScreenWidthAwareMixin from "../../../vue/mixins/ScreenWidthAwareMixin";
    import WikiDetailPageSlideContainerEmptySlideEditHolder
        from "./WikiDetailPageSlideContainerEmptySlideEditHolder/WikiDetailPageSlideContainerEmptySlideEditHolder";
    import {mapGetters} from "vuex";
    import captionPlayerBus from "shared/captionPlayer";
    import {CurrentUserClass} from "~/angular/app/shared/CurrentUser";
    import { simplePopupFactory } from '~/angular/app/shared/simple_popups/simplePopupFactory';
    import FillingGuidanceDialog from '~/angular/app/wiki_page/popups/FillingGuidanceDialog.vue';


    const SCALING = true;

    export default {
        name: "WikiDetailPageSlideContainer",
        mixins: [ScreenWidthAwareMixin],
        props: {
            activeSlide: {
                type: Object,
                required: false
            },
            editMode: {
                type: Boolean,
                default: false
            },
            fullScreen: {
                type: Boolean,
                default: false
            }
        },
        components: {
            WikiDetailPageSlideContainerEmptySlideEditHolder,
            WikiDetailPageSlideDescription, WikiDetailPageSlideHeader, VueInlineMedia
        },
        data() {
            return {
                audioCaptionPlayed: false,
                audioDescriptionPlayed: false,
            };
        },
        computed: {
            ...mapGetters('WikiStore', ['wikiTemplate', 'slides']),
            keymap() {
                return {
                    'k': this.prevSlide,
                    'j': this.nextSlide,
                    'alt+a': this.playSlideSounds,
                    'space': {
                        keydown: this.playSlideMainMedia
                    },
                };
            },
            $_slideHasCaption() {
                if (!this.activeSlide) {
                    return;
                }
                return this.activeSlide.caption || this.activeSlide.audio_caption;
            },
            $_slideHasDescription() {
                if (!this.activeSlide) {
                    return;
                }
                return this.activeSlide.description || this.activeSlide.audio_description;
            },
            $_containerHeight() {
                let height;
                if (this.fullScreen && this.windowHeight >= 1600) {
                    height = 1200;
                } else if (this.fullScreen && this.windowHeight >= 1200) {
                    height = 800;
                } else if (this.fullScreen && this.windowHeight >= 1000) {
                    height = 600;
                } else if (this.windowHeight >= 900) {
                    height = 500;
                } else if (this.windowHeight >= 800) {
                    height = 400;
                } else {
                    height = 350;
                }
                if (SCALING) {
                    if (!this.editMode && !this.$_slideHasCaption) {
                        height += 50;
                    }
                    if (!this.editMode && !this.$_slideHasDescription) {
                        height += 110;
                    }
                }

                return height;
            },
            $_headerHeight() {
                let height = 50;
                if (SCALING && !this.editMode && !this.$_slideHasCaption) {
                    height = 0;
                }
                return height;
            },
            $_descriptionHeight() {
                let height = 110;
                if (SCALING && !this.editMode && !this.$_slideHasDescription) {
                    height = 0;
                }
                return height;
            },
            $_arrowSize() {
                return 100;
            },
            noMediaIconStyle() {
                const height = this.$_containerHeight / 2;
                return {
                    'font-size': `${height}px`,
                    'line-height': `${height + 20}px`,
                    'margin-top': `${this.$_containerHeight / 2 - height / 2 - 50}px`
                };
            },
            arrowStyle() {
                return {
                    height: `${this.$_arrowSize}px`,
                    'margin-top': `${(this.$_containerHeight + this.$_headerHeight + this.$_descriptionHeight) / 2 - this.$_arrowSize / 2}px`
                };
            },
            headerStyle() {
                return {
                    'height': `${this.$_headerHeight}px`,
                    'overflow': 'hidden'
                };
            },
            contentStyle() {
                return {
                    'height': `${this.$_containerHeight}px`,
                    'overflow': 'hidden'
                };
            },
            arrowsContainerStyle() {
                return {
                    'height': `${this.$_containerHeight + this.$_headerHeight}px`
                };
            },
            containerStyle() {
                return {
                    'min-height': `${this.$_containerHeight + this.$_headerHeight}px`
                };
            },
            logAvailable() {
                if (this.editMode) {
                    return  false
                }
                return new CurrentUserClass().is_authenticated();
            },
            hasGuidanceToRead(){
              return this.activeSlide?.has_guidance;
            },
            isTemplate(){
              return !!this.wikiTemplate;
            },
        },
        methods: {
            prevSlide() {
                this.$store.commit('WikiStore/prevSlide');
            },
            nextSlide() {
                this.$store.commit('WikiStore/nextSlide');
            },
            createSlide() {
                this.$store.dispatch('WikiStore/createSlide');
            },
            removeActiveSlide() {
                this.$store.dispatch('WikiStore/removeActiveSlide');
            },
            editSlideMedia() {
                this.$store.dispatch('WikiStore/editSlideMedia', this.activeSlide);
            },
            async showGuidanceDialog() {
              const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(FillingGuidanceDialog)
              const res = await popupComponent.show({
                slide: this.activeSlide,
                editable: this.isTemplate,
              })
                  .catch(e => {
                  });
              vm?.$destroy();
              fEl?.focus();
            },
            playSlideSounds() {
                const hasCaption = this.$refs.slideHeader?.hasAudioCaption();
                const hasDescription = this.$refs.slideDescription?.hasAudioCaption();
                if (hasCaption && hasDescription) {
                    if (this.audioCaptionPlayed && this.audioDescriptionPlayed) {
                        this.audioCaptionPlayed = false;
                        this.audioDescriptionPlayed = false;
                        this.$refs.slideDescription?.stopSound();
                        this.$refs.slideHeader?.stopSound();
                    } else if (this.audioCaptionPlayed) {
                        this.audioDescriptionPlayed = true;
                        this.$refs.slideDescription?.toggleSound();
                    } else {
                        this.audioCaptionPlayed = true;
                        this.$refs.slideHeader?.toggleSound();
                    }
                } else if (hasCaption) {
                    this.$refs.slideHeader?.toggleSound();
                } else if (hasDescription) {
                    this.$refs.slideDescription?.toggleSound();
                }
            },
            playSlideMainMedia(){
                if(this.$refs.inlineMedia?.canPlay()){
                    this.$refs.inlineMedia.togglePlay();
                }
            },
            showWikiLog() {
                this.$store.dispatch('WikiStore/showWikiLog', { source: 'fromCurrentSlide' });
            },
        },
        watch: {
            activeSlide(n) {
                this.audioCaptionPlayed = false;
                this.audioDescriptionPlayed = false;
            }
        }
    };
</script>

<style scoped lang="scss">
  @import "../../../angular/app/styles/const";

  $sidePanelSize: 60px;
  $mobile-threshold: 1060px;
  $arrow-size: 100px;

  @mixin arrowStyle() {
    @media (max-width: $mobile-threshold) {
      display: none;
    }
    vertical-align: top;
    width: $sidePanelSize;
    height: 100%;
    display: inline-block;
    font-size: $arrow-size;
    &__arrow {
      color: $tag-gray-dark;
      transition: color 0.2s;
      height: $arrow-size;
      cursor: pointer;

      &:hover, &:active {
        color: $black;
      }
    }
  }

  .wiki-detail-page-slide-container {
    font-size: 0;
    width: 100%;
    margin-bottom: 10px;

    img {
      max-height: 100%;
    }

    video {
      max-height: 100%;
    }

    &__left-panel {
      text-align: left;
      @include arrowStyle;
    }

    &__right-panel {
      @include arrowStyle;
      text-align: right;
      position: relative;

      &__buttons-panel {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__button {
          cursor: pointer;
          font-size: 35px;
          color: $tag-gray-dark;
          transition: color 0.2s;

          &:hover, &:active {
            &.create {
              color: $grass-green
            }

            &.delete {
              color: $red
            }

          }
        }

        .guidance {
          margin-bottom: 8px;
        }
      }
    }

    &__inner-container {
      vertical-align: top;
      @media (min-width: $mobile-threshold) {
        width: calc(100% - #{$sidePanelSize*2});;
      }
      width: 100%;
      height: 100%;
      display: inline-block;
      font-size: 50px;
      background-color: white;

      &_empty{
        font-size: 30px;
        text-align: center;
        line-height: 100px;
      }

      &__content {
        position: relative;

        &__edit-button {
          cursor: pointer;
          position: absolute;
          border-radius: 50%;
          width: 80px;
          height: 80px;
          line-height: 80px;
          text-align: center;
          font-size: 40px;
          top: calc(50% - 40px);
          left: calc(50% - 40px);

          background-color: #eeeeee;

          &:hover, &:active {
            background-color: #fff;
          }

          -webkit-box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.75);
          box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.75);
        }

        &__wrapper {
          position: relative;
          padding: 15px 0;
          width: 100%;
          height: 100%;
        }

        &__no-media-placeholder {
          width: 100%;
          height: 100%;
          color: $tag-gray-dark;

          &__icon {
            /*margin-top: 30px;*/
            /*font-size: 200px;*/
            /*line-height: 220px;*/
            text-align: center;
          }

          &__label {
            font-size: 20px;
            text-align: center;
          }
        }
      }
    }

    &__mobile-panel {
      color: $tag-gray-dark;
      @media (min-width: $mobile-threshold) {
        display: none;
      }
      margin-bottom: 10px;
      height: 45px;
      width: 100%;
      /*background-color: #fff;*/
      line-height: 45px;
      font-size: 35px;

      & .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

  }
  .guidance {
    width: 50px;
    height: 50px;
    cursor: pointer;
    font-size: 35px;
    color: $black;
    background-color: $color-story;
    border-radius: 50%;
    transition: background-color 0.2s;
    text-align: center;

    &:hover, &:active {
      background-color: #FFEE58;
    }
  }

  .single-button {
    text-align: center;
  }

  .single {
    justify-content: center !important;
  }

</style>
