/**
 * Created by orion on 29.05.17.
 */
createNewCollectionPopupController.$inject = ['$scope', '$uibModalInstance', '$log', 'dependants_names', 'collection_data'];
function createNewCollectionPopupController($scope, $uibModalInstance, $log, dependants_names, collection_data) {
    $scope.close = function () {
        $uibModalInstance.dismiss('closed');
    };
    $scope.collection_name = collection_data.name;
    $scope.for_user = collection_data.for_user;
    $scope.dependants_names = dependants_names;
    $scope.selected_username = collection_data.username;

    $scope.create = create;

    function dump_collection_data() {
        return {
            name: $scope.collection_name,
            for_user: $scope.for_user,
            username: $scope.selected_username
        }
    }

    function create() {
        $uibModalInstance.close({
            type: 'create_collection',
            collection_data: dump_collection_data()
        });
    }
}

export default createNewCollectionPopupController