import Vue from 'vue';
import * as config from '../new_calendar/config';

export default {
    computed: {
        joinRequestSent() {
            return this.membershipStatus.join_request_sent;
        },
        joinerId() {
            return this.calendarOwner.id;
        },
    },
    methods: {
        sendJoinRequest(id, date, join_only_one_date = false) {
            const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
            const $rootScope = Vue.getAngularModule('$rootScope');
            const notifications = Vue.getAngularModule('notifications');
            $rootScope.show_dimmer();
            return CalendarEventsResource.join_event(id, this.joinerId, date, join_only_one_date)
                .then(resp => {
                    const result = resp.data; // 'joined', 'wait_for_approval'
                    if (result === 'joined') {
                        notifications.success('Event successfully added');
                        if (this.membershipStatus) {
                            this.membershipStatus.added_to_calendar = true;
                            this.membershipStatus.owner_has_access_to_event = true;
                            this.membershipStatus.can_create_blog_entry = true;
                            if (!join_only_one_date) {
                                this.membershipStatus.target_added_to_calendar = true;
                            }
                        }
                        this.$emit('onEventJoined');
                        $rootScope.hide_dimmer();
                        return;
                    }
                    if (result === 'wait_for_approval') {
                        notifications.success('Add request successfully sent');
                        this.$emit('onJoinRequestSent');
                        $rootScope.hide_dimmer();
                    }
                }, err => {
                    notifications.error(err || "Error");
                    $rootScope.hide_dimmer();
                });
        },
        onJoinEventClick() {
            if (this.periodInfo.is_periodic && this.dateMoment) {
                this.$refs['join-type-selector'].show(this.dateMoment)
                    .then(type => { // 'exact' or 'whole'
                        if (type === 'exact') {
                            this.sendJoinRequest(this.periodInfo.event_id, this.dateMoment.format(config.VISUAL_DATE_FORMAT), true);
                        }
                        if (type === 'whole') {
                            this.sendJoinRequest(this.periodInfo.event_id);
                        }
                    });
            }
            if (!this.periodInfo.is_periodic) {
                this.sendJoinRequest(this.periodInfo.event_id);
            }
        }
    }
};