<template>
    <div class="inline-block vue-media-preview__container" :aria-label="visibleName" role="figure">
        <div class="vue-media-preview__image"
             :style="[slidePreviewStyle, imageSize, highLightStyle]">
            <i class="fa fa-times vue-media-preview__image__delete_button"
               v-if="allowDelete"
               @click="del"
               @keydown.enter="del"
               tabindex="0"
               role="button"
               aria-label="Delete Media"
               :style="iconSize"
            ></i>
            <i class="fa fa-pencil vue-media-preview__image__edit_button"
               v-if="allowEdit"
               @click="edit"
               tabindex="0"
               aria-label="Edit Media"
               role="button"
               @keydown.enter="edit"
               :style="iconSize"
            ></i>
            <i v-show="!item" class="fa fa-question"
               aria-hidden="true"></i>
            <i v-show="isSlideEncodingProgressNow"
               class="fa fa-hourglass-half" aria-hidden="true"></i>
            <i v-show="isSlideTypeAudio"
               class="fa fa-volume-up" aria-hidden="true"></i>
            <i v-show="isSlideTypeDocument"
               class="fa fa-file-text-o" aria-hidden="true"></i>
            <i v-show="isSlideTypeMap"
               class="fa fa-map-marker" aria-hidden="true"></i>
            <i v-show="isSlideTypeVideo&&isSlideEncodingProgressNow"
               class="fa fa-video-camera" aria-hidden="true"></i>
        </div>
        <div v-if="showName" class="vue-media-preview__text" :style="textStyle" v-html="visibleName"></div>
    </div>

</template>

<script>
    import _ from 'lodash';
    import Vue from 'vue';

    export default {
        props: {
            item: {},
            noBorder: {
                type: Boolean,
                default: false
            },
            allowDelete: {
                type: Boolean,
                default: false
            },
            allowEdit: {
                type: Boolean,
                default: false
            },
            highLight: {
                type: Boolean,
                default: false
            },
            highLightColor: {
                type: String,
                default: '#00b3ee',
            },
            showName: {
                type: Boolean,
                default: false
            },
            text: {
                type: String,
                required: false
            },
            size: {
                type: Number,
                default: 100
            }
        },
        emits: ['onDelete', 'onEdit'],
        computed: {
            visibleName() {
                const vName = this.text || this.item.name;
                return this.$options.filters.cutName(vName, 15);
            },
            imageSize() {
                const width = this.size;
                const height = Math.floor(this.size / 10 * 7);
                return {
                    'width': `${width}px`,
                    'height': `${height}px`,
                    'line-height': `${height}px`,
                    'font-size': `${Math.floor(height / 2)}px`,
                };
            },
            textStyle() {
                return {
                    'max-width': `${this.size}px`,
                    'overflow': 'hidden'
                };
            },
            iconSize() {
                const height = Math.floor(this.size / 10 * 7);
                const iconSize = Math.floor(height / 2) - 5;
                return {
                    width: `${iconSize}px`,
                    height: `${iconSize}px`,
                    'font-size': `${iconSize}px`,
                };
            },
            highLightStyle() {
                if (this.highLight) {
                    return {
                        'box-shadow': `${this.highLightColor} 0px 0px 10px`,
                        border: `solid 4px ${this.highLightColor}`
                    };
                } else {
                    if (!this.noBorder) {
                        return {
                            'box-shadow': 'rgba(0, 0, 0, 0.498039) 0px 0px 10px'
                        };
                    }
                }
            },
            slidePreviewStyle() {
                const mediaContentUtils = Vue.getAngularModule('mediaContentUtils');

                if (_.isString(this.item)) {
                    return {
                        'background-image': 'url(' + this.item + ')',
                        'background-position': 'center',
                        'background-size': 'cover',
                        'background-repeat': 'no-repeat'
                    };
                }
                let res = {};
                if (this.allowDelete) {
                    res['position'] = 'relative';
                }
                return _.merge(res, mediaContentUtils.generate_background(this.item));
            },
            isSlideEncodingProgressNow() {
                if (!this.item) {
                    return;
                }
                const mediaContentUtils = Vue.getAngularModule('mediaContentUtils');
                return mediaContentUtils.is_media_encoding_processing_now(this.item);
            },
            isSlideTypeAudio() {
                if (!this.item) {
                    return;
                }
                return (this.item.type === 'uploaded_audio' || this.item.type === 'recorded_audio');
            },
            isSlideTypeMap() {
                if (!this.item) {
                    return;
                }
                return this.item.type === 'location_point';
            },
            isSlideTypeDocument() {
                if (!this.item) {
                    return;
                }
                return this.item && this.item.type === 'uploaded_document';
            },
            isSlideTypeVideo() {
                if (!this.item) {
                    return;
                }
                return this.item.type === 'upl_video';
            }
        },
        methods: {
            del() {
                if (this.allowDelete) {
                    this.$emit('onDelete');
                }
            },
            edit() {
                if (this.allowEdit) {
                    this.$emit('onEdit');
                }
            },
        },
        name: 'MediaPreview',

    };
</script>

<style scoped lang="scss">

  @import "../../../src/angular/app/styles/const";

  .vue-media-preview {
    &__container {
      vertical-align: top;
      cursor: pointer;
      display: inline-block;
      text-align: center;
      overflow-x: hidden;
    }

    @mixin iconOverMedia {
      position: absolute;
      cursor: pointer;
      display: block;

      text-align: center;
      right: 0;
    }

    &__text {
      text-align: center;
      font-size: 14px;
      line-height: 18px;
      font-weight: bolder;
    }

    &__image {
      overflow: hidden;
      position: relative;
      vertical-align: top;
      border-radius: 2px;
      background-color: white;
      text-align: center;
      transition: box-shadow 0.2s linear;

      &__delete_button {
        @include iconOverMedia;
        color: $red;
        top: -2px;
      }

      &__edit_button {
        @include iconOverMedia;
        color: $dark-blue;
        bottom: 0;
      }

      border: solid 1px;

    }
  }
</style>
