<template>
    <div class="event-linked-entry-preview">
        <a :href="entry.link|backHref" style="text-decoration: none">
            <div :style="entryPreview.avatarStyle"
                 class="event-linked-entry-preview__avatar">
                <render-sticker v-if="entryPreview.sticker" :sticker="entryPreview.sticker"
                                style="width: 45px; height: 45px; position: absolute; bottom: 0; right: 0;"></render-sticker>
            </div>
        </a>
        <div class="event-linked-entry-preview__text">
            <div class="" v-if="entryPreview.text||entry.emotion">
                <i class="fal fa-quote-left"></i>
                {{(entryPreview.text||entry.emotion)|cutName(180)}}
                <i class="fal fa-quote-right"></i>
            </div>
            <div class="event-linked-entry-preview__text__author">
                {{formattedDate}} by <strong>
                <simple-link :target="entryPreview.author" style="color: #333"></simple-link>
            </strong>.

                <strong><a :href="entry.link|backHrefQuery">Open Details</a></strong>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import RenderSticker from "../../add_media/stickers/render_sticker_directive/RenderSticker";
    import {VISUAL_DATE_FORMAT, URL_DATE_FORMAT} from "../new_calendar/config";
    import {getEmotionByCode} from "../../diary_page/common/blog_entry_emotions/diaryEntryEmotions";

    export default {
        name: "EventDetailPageInfoEntryListShowAllPopupEntryPreview.vue",
        components: {RenderSticker},
        props: {
            entry: Object
        },
        computed: {
            formattedDate() {
                if (!this.entry || !this.entry.date) return;
                return moment(this.entry.date, URL_DATE_FORMAT).format(VISUAL_DATE_FORMAT);
            },
            entryPreview() {
                if (!this.entry) return;
                return {
                    avatarStyle: {
                        'background-image': `url(${this.entry.activity_post.thumbnail_url || this.entry.activity_post.thumbnail_url.preview_url})`
                    },
                    sticker: this.entry.activity_post ? this.entry.activity_post.sticker : undefined,
                    text: this.entry.activity_post ? this.entry.activity_post.text : undefined,
                    author: this.entry.author,
                    link: this.entry.link,
                    emotion: this.entry.emotion ? getEmotionByCode(this.entry.emotion) : undefined,
                };
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../styles/const";
    .event-linked-entry-preview {
        &:not(:last-child){
            border-bottom: 1px solid $lightgray;
        }
        font-size: 0;
        padding-bottom: 5px;
        margin-bottom: 5px;
        a{
            color: #333;
        }

        &__avatar {
            vertical-align: top;
            display: inline-block;
            position: relative;
            width: 100px;
            height: 100px;
            background-size: cover;
            border-radius: 5px;
        }

        &__text {
            vertical-align: top;
            display: inline-block;
            width: calc(100% - 100px);
            font-size: 14px;
            padding-left: 5px;
            font-style: italic;

            &__author {
                font-style: normal;
                margin-top: 5px;
            }
        }
    }
</style>