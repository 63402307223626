var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wiki-tree" }, [
    _c("div", { staticClass: "wiki-tree-main-section" }, [
      _c(
        "div",
        { staticClass: "wiki-tree-main-section__expand-icon-container" },
        [
          _c("i", {
            staticClass: "far wiki-tree-main-section__icon pointer",
            class: {
              "fa-caret-down": _vm.mainSectionExpanded,
              "fa-caret-right": !_vm.mainSectionExpanded,
            },
            on: { click: _vm.toggleMainSectionExpand },
          }),
        ]
      ),
      _vm._v(" "),
      _c("i", {
        staticClass: "far fa-check wiki-tree-main-section__icon",
        class: _vm.checkButtonClasses,
        on: { click: _vm.toggleWikiIncluded },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "wiki-tree-main-section__owners-avatar",
        style: _vm.ownerAvatarStyle,
      }),
      _vm._v(" "),
      _c("strong", [_vm._v(_vm._s(_vm.mainTitle))]),
    ]),
    _vm._v(" "),
    _vm.mainSectionExpanded
      ? _c(
          "div",
          { staticClass: "wiki-tree-subsections__container" },
          _vm._l(_vm.sections, function (section) {
            return _c("wiki-receipt-tree-section", {
              key: section.id,
              attrs: {
                section: section,
                "inherited-permission-level": _vm.resultPermissionLevel,
                "permissions-list": _vm.permissionsList,
                "included-stuff-config": _vm.includedStuffConfig,
              },
              on: { input: _vm.onIncludedStuffConfigUpdate },
            })
          }),
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }