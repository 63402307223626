<template>
    <div class="" v-hotkey="keymap">
        <comments-input
                v-if="allowAdd"
                @addMedia="addMedia"
                @uploadPicture="uploadPicture"
                @recordAudio="recordAudio"
                @deleteMedia="deleteMedia"
                @addMessage="addMessage"
                @cancelReply="cancelReply"
                :replyTo="replyTo"
                ref="CommentsInput"
                :mediaAttachment="mediaAttachment"

                :emit-typing-events="emitTypingEvents"
                @typingStateChanged="typingStateChanged"
        ></comments-input>
        <div style="min-height: 36px; padding-top: 5px; padding-bottom: 5px; font-size: 18px;">
            <slot name="underInputContent"></slot>
        </div>
        <chat-style-comment
                v-for="comment in results"
                :key="comment.id"
                :comment="comment"
                :author=comment.userprofile
                :is-comment-right-side="isCommentRightSide"
                :selected-high-light="keyboardSelectedItem && (keyboardSelectedItem===comment)"
                :ref="`comment-${comment.id}`"
        >
            <div slot="bottomPanel" style="margin-top: 5px">
                <div class="bottom-panel-item hidden-print"
                     v-if="allowLike"
                     :class="{'color-gray':!comment.liked_by_me, 'color-blue':comment.liked_by_me}"
                     :style="{'cursor':allowLike?'pointer':'default'}">
                    <i class="fa"
                       @click="toggleLike(comment)"
                       @keydown.enter="toggleLike(comment)"
                       role="button"
                       tabindex="0"
                       aria-label="Like Comment"
                       :class="{'fa-heart-o':!comment.liked_by_me,'fa-heart':comment.liked_by_me}"
                       v-tooltip.bottom="'Likes'"></i>
                    <span v-show="comment.likes_count"> {{ comment.likes_count }}</span>
                </div>
                <div class="bottom-panel-item hidden-print" v-if="allowAdd">
                    <i class="fa fa-reply color-grass-green"
                       @click="reply(comment)"
                       @keydown.enter="reply(comment)"
                       role="button"
                       aria-label="Reply to comment"
                       tabindex="0"
                       v-tooltip.bottom="'Reply'"
                    ></i>
                </div>
                <div class="bottom-panel-item hidden-print"
                     v-if="isCommentDeletable(comment)">
                    <i class="fa fa-trash-o color-red"
                       @click="del(comment)"
                       @keydown.enter="del(comment)"
                       role="button"
                       aria-label="Delete Comment"
                       tabindex="0"
                       v-tooltip.bottom="'Delete'"
                    ></i>
                </div>
            </div>
            <chat-style-comment-reply-linked-entry
                    slot="reply"
                    v-if="comment.reply_to&&comment.reply_to.linked_blog_entry"
                    :comment="comment.reply_to"
                    :white-border="isCommentRightSide(comment)"
            ></chat-style-comment-reply-linked-entry>
            <chat-style-comment-reply-linked-blog-answer
                    slot="reply"
                    v-else-if="comment.reply_to&&comment.reply_to.linked_blog_answer"
                    :comment="comment.reply_to"
                    :white-border="isCommentRightSide(comment)"
            ></chat-style-comment-reply-linked-blog-answer>
            <chat-style-comment-reply
                    slot="reply"
                    v-else-if="comment.reply_to"
                    :comment="comment.reply_to"
                    :white-border="isCommentRightSide(comment)"
                    :allow-expand="allowReplyExpand"
            ></chat-style-comment-reply>
            <chat-style-comment-reply
                    slot="reply"
                    v-else-if="comment.is_reply_to"
                    :show-author="false"
                    :comment="{text: 'Deleted'}"
                    :white-border="isCommentRightSide(comment)"
            ></chat-style-comment-reply>
        </chat-style-comment>
        <slot name="emptyMessage" v-if="allLoaded&&!results.length">
            <div class="alert alert-info">
                There are no comments yet.
            </div>
        </slot>
        <div v-infinite-scroll="loadMore"></div>
    </div>
</template>

<script>
    import ChatStyleComment from 'shared/comments/ChatStyleComment';
    import CommentsInput from 'shared/comments/CommentsInput';
    import Vue from 'vue';
    import questionPollChatMainResource from '../../chats/question_poll_chat_main_resource';
    import _ from 'lodash';
    import { CurrentUserClass } from 'shared/CurrentUser';
    import ChatStyleCommentReply from 'shared/comments/ChatStyleCommentReply';
    import ChatStyleCommentReplyLinkedEntry from 'shared/comments/ChatStyleCommentReplyLinkedEntry';
    import ChatStyleCommentReplyLinkedBlogAnswer from 'shared/comments/ChatStyleCommentReplyLinkedBlogAnswer';
    import InfiniteScrollMixin from '../../../../vue/mixins/InfiniteScrollMixin';
    import KeyboardNavigationMixin from '../../../../vue/mixins/KeyboardNavigationMixin';
    import captionPlayerBus from 'shared/captionPlayer';
    import { addWsEventListener, removeWsEventListener } from '~/instant_messages/centrifugo_worker';
    import { WSEventCode } from '~/instant_messages/event_codes';

    class UnreadMessagesHeaderCountIndicator {
        constructor($rootScope) {
            this.initialPageTitle = document.title;
            this._timer = undefined;
            this.unreadCount = 0;
            this._titlePathed = false;
            this.$rootScope = $rootScope;
        }

        setUnreadCount(n) {
            this.unreadCount = n;
            if (n === 0) {
                document.title = this.initialPageTitle;
                this._titlePathed = false;
                if (this._timer !== undefined) {
                    clearInterval(this._timer);
                }
            } else {
                clearInterval(this._timer);
                this._timer = setInterval(() => {
                    this._worker.call(this);
                }, 500);
            }
        }

        increaseUnreadCountIfNotInFocus() {
            if (this.$rootScope.isTabVisibleNow()) {
                return;
            } else {
                this.setUnreadCount(this.unreadCount + 1);
            }
        }

        resetUnreadCount() {
            this.setUnreadCount(0);
        }

        _worker() {
            if (this._titlePathed) {
                this._titlePathed = false;
                document.title = this.initialPageTitle;
            } else {
                this._titlePathed = true;
                document.title = `(${this.unreadCount}) ${this.initialPageTitle}`;
            }
        }
    }

    export default {
        name: 'CommentsListInfiniteScroll',
        components: {
            ChatStyleCommentReplyLinkedBlogAnswer,
            ChatStyleCommentReplyLinkedEntry,
            ChatStyleCommentReply,
            CommentsInput,
            ChatStyleComment
        },
        mixins: [InfiniteScrollMixin, KeyboardNavigationMixin],
        props: {
            targetId: [Number, String],
            commentsResource: Object,
            commentsAdditionalParams: {
                type: Object,
                default() {
                    return {};
                }
            },

            allowAdd: {
                type: Boolean,
                default: false,
            },
            allowLike: {
                type: Boolean,
                default: false,
            },
            allowDelete: {
                type: Boolean,
                default: false,
            },
            allowDeleteOwn: {
                type: Boolean,
                default: false,
            },
            allowReplyExpand: {
                type: Boolean,
                default: false,
            },

            initialReplyTo: {
                type: Number,
                required: false,
            },
            allowedMediaTypes: {
                type: Array,
                default: () => ['video', 'audio', 'image'],
                validator: arr => {
                    for (let val of arr) {
                        if (!['video', 'audio', 'image', 'document'].includes(val)) {
                            return false;
                        }
                    }
                    return true;
                }
            },

            markNewMessagesAsRead: {
                type: Boolean,
                default: true
            },

            onMessageAddedEventCode: {
                type: String,
                required: false,
            },
            onMessageLikesCountChangedEventCode: {
                type: String,
                required: false,
            },
            onMessageDeletedEventCode: {
                type: String,
                required: false,
            },
            onMessageApprovedEventCode: {
                type: String,
                required: false,
            },
            onNewMessageLiveEventCode: {
                type: String,
                required: false,
            },
            emitTypingEvents: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                mediaAttachment: undefined,
                replyTo: undefined,

                unreadComments: [],

                popupOpened: false,
            };
        },
        computed: {
            keymap() {
                return {
                    'alt+t': {
                        keydown: this.focusToTextInput,
                    },
                    'alt+u': {
                        keydown: this.uploadPicture,
                    },
                    'alt+z': {
                        keydown: this.recordAudio,
                    },
                    'j': {
                        keydown: this.keyboardPrevItem,
                    },
                    'k': {
                        keydown: this.keyboardNextItem,
                    },
                    'alt+a': {
                        keydown: this.playAudioOnActiveItem,
                    },
                };
            },
            isCommentDeletable() {
                return comment => {
                    return this.allowDelete || (this.allowDeleteOwn && (comment.userprofile.id === new CurrentUserClass().id));
                };
            },
            isCommentRightSide() {
                return comment => {
                    if (comment.userprofile && comment.userprofile.id && (comment.userprofile.id === new CurrentUserClass().id)) {
                        return true;
                    }
                    return false;
                };
            }
        },
        methods: {
            getElementForKeyboardItem(activity) {
                return this.$refs[`comment-${activity.id}`][0]?.$el;
            },
            focusToTextInput() {
                this.$refs.CommentsInput?.setFocus();
            },
            $_clearInputForm() {
                this.$refs.CommentsInput.clearFields();
                this.mediaAttachment = undefined;
                this.replyTo = undefined;
            },
            getListEndpoint() {
                return this.commentsResource.get_list;
            },
            getListEndpointArgs() {
                return [this.targetId];
            },
            getListEndpointAdditionalParams() {
                return this.commentsAdditionalParams;
            },
            addMedia() {
                if (this.popupOpened) {
                    return;
                }
                this.popupOpened = true;
                const addMedia = Vue.getAngularModule('addMedia');
                addMedia.add_media(
                    {
                        allow_add_from_library: true,
                        allow_save_to_folders: false,
                        max_count: 1,
                        types: this.allowedMediaTypes,
                        popup_header: 'What sort of media would you like to add?'
                    },
                    {
                        default_callback: (media_items_list) => {
                            this.mediaAttachment = media_items_list[0];
                            this.popupOpened = false;
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    this.$refs.CommentsInput?.setFocusOnSendButton();
                                }, 10);
                            });
                        },
                        cancel_callback: () => {
                            this.popupOpened = false;
                        }
                    }
                );
            },
            uploadPicture() {
                if (this.popupOpened) {
                    return;
                }
                this.popupOpened = true;
                const addMedia = Vue.getAngularModule('addMedia');
                addMedia.add_media(
                    {
                        allow_add_from_library: true,
                        allow_save_to_folders: false,
                        max_count: 1,
                        types: this.allowedMediaTypes,
                        popup_header: 'What sort of media would you like to add?'
                    },
                    {
                        default_callback: (media_items_list) => {
                            this.mediaAttachment = media_items_list[0];
                            this.popupOpened = false;
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    this.$refs.CommentsInput?.setFocusOnSendButton();
                                }, 10);
                            });
                        },
                        cancel_callback: () => {
                            this.popupOpened = false;
                        }
                    },
                    'image_upload',
                );
            },
            recordAudio() {
                if (this.popupOpened) {
                    return;
                }
                this.popupOpened = true;
                const addMedia = Vue.getAngularModule('addMedia');
                addMedia.add_media(
                    {
                        allow_add_from_library: true,
                        allow_save_to_folders: false,
                        max_count: 1,
                        types: this.allowedMediaTypes,
                        popup_header: 'What sort of media would you like to add?'
                    },
                    {
                        default_callback: (media_items_list) => {
                            this.mediaAttachment = media_items_list[0];
                            this.popupOpened = false;
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    this.$refs.CommentsInput?.setFocusOnSendButton();
                                }, 10);
                            });
                        },
                        cancel_callback: () => {
                            this.popupOpened = false;
                        }
                    },
                    'record_audio',
                );
            },
            deleteMedia() {
                this.mediaAttachment = undefined;
            },
            reply(comment) {
                let authorName = 'Anonymous User';
                if (comment.userprofile) {
                    authorName = comment.userprofile.name;
                } else if (comment.anonymous_user && comment.anonymous_user.name) {
                    authorName = comment.anonymous_user.name;
                }
                let messagePreview = '';
                if (comment.text) {
                    messagePreview = this.$options.filters.cutName(comment.text, 50);
                } else if (comment.sticker) {
                    messagePreview = '[Sticker]';
                } else if (comment.media_attachment) {
                    messagePreview = '[Media]';
                }

                this.replyTo = {
                    commentId: comment.id,
                    author: {
                        name: authorName,
                    },
                    messagePreview: messagePreview,
                };
                this.$refs.CommentsInput.setFocus();
            },
            cancelReply() {
                this.replyTo = undefined;
            },
            addMessage(messageData) {
                if (this.blockMessageSending) {
                    return;
                }
                const $rootScope = Vue.getAngularModule('$rootScope');
                const $http = Vue.getAngularModule('$http');
                $rootScope.show_dimmer();
                this.blockMessageSending = true;
                this.commentsResource.add(this.targetId, Object.assign({}, this.commentsAdditionalParams, messageData))
                    .then(resp => {
                        this.totalCount = resp.data.count;
                        this.results.unshift(resp.data.comment);
                        Vue.notifications.success('Sent');
                        this.$_clearInputForm();
                        this.$emit('onCommentSent', resp.data.comment);
                        this.$emit('onCommentsCountChanged', resp.data.count);
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                    })
                    .finally(() => {
                        this.blockMessageSending = false;
                        $rootScope.hide_dimmer();
                    });

            },
            playAudioOnActiveItem() {
                if (this.keyboardSelectedItem) {
                    const currentCommentElement = this.getElementForKeyboardItem(this.keyboardSelectedItem);
                    if (currentCommentElement) {
                        if (this.elementPartInViewport(currentCommentElement)) {
                            const audio = currentCommentElement.querySelector('audio'); // dirty trick
                            if (audio) {
                                audio.paused ? audio.play() : audio.pause();
                            }
                        }
                    }
                }
            },
            toggleLike(comment) {
                if (comment.liked_by_me) {
                    this.commentsResource.unlike(this.targetId, Object.assign({ id: comment.id }, this.commentsAdditionalParams)).then(function (resp) {
                        comment.likes_count = resp.data.likes_count;
                        comment.liked_by_me = false;
                        Vue.notifications.success('Unliked');
                    }, function (resp) {
                        Vue.notifications.error(resp.data);
                    });
                } else {
                    this.commentsResource.like(this.targetId, Object.assign({ id: comment.id }, this.commentsAdditionalParams)).then(function (resp) {
                        comment.likes_count = resp.data.likes_count;
                        comment.liked_by_me = true;
                        Vue.notifications.success('Liked');
                    }, function (resp) {
                        Vue.notifications.error(resp.data);
                    });
                }
            },
            del(comment) {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const $http = Vue.getAngularModule('$http');
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                simplePopupFactory.show_popup('Delete Comment', 'Are you sure you want to delete this Comment?', 'Delete', 'Cancel', 'btn-red')
                    .then(() => {
                        $rootScope.show_dimmer();
                        return this.commentsResource.remove(this.targetId, _.merge({ id: comment.id }, this.commentsAdditionalParams));
                    }, () => new Promise(() => {
                    }))
                    .then(resp => {
                        this.results = this.results.filter(c => c.id !== comment.id);
                        for (let c of this.results) {
                            if (c.reply_to?.id === comment.id) {
                                c.reply_to = null;
                            }
                        }
                        this.totalCount = resp.data.count;
                        Vue.notifications.success('Deleted');
                        this.$emit('onCommentRemoved', comment);
                        this.$emit('onCommentsCountChanged', resp.data.count);
                    }, err => Vue.notifications.error(err || 'Error'))
                    .finally($rootScope.hide_dimmer);
            },

            typingStateChanged(state) {
                this.$emit('typingStateChanged', state);
            },

            $_markAllUnreadCommentsAsRead() {
                if (this.markNewMessagesAsRead && this.unreadComments.length) {
                    this.commentsResource.mark_posts_as_read(this.targetId);
                }
                for (let comment of this.unreadComments) {
                    setTimeout(() => {
                        Vue.set(comment, 'new', false);
                    }, 7000);
                }
            },
            async $_loadNewMessages(silent = false) {
                const $rootScope = Vue.getAngularModule('$rootScope');
                let audio = new Audio('/static/audio/message_sound.mp3');
                const playNotification = () => {
                    audio.load();
                    try {
                        audio.play(); // ios no interaction error message possible.
                    } catch (e) {
                    }
                };
                if (this.$_loadingNewMessages) {
                    return;
                }
                this.$_loadingNewMessages = true;
                let maxTime;
                for (const r of this.results) {
                    if (!maxTime || (r.time_created_or_approved > maxTime)) {
                        maxTime = r.created_at;
                    }
                }
                try {
                    let unreadComents = [];
                    const resp = await this.commentsResource.load_since_time(this.targetId, maxTime);
                    if (resp.data.count >= this.limit) {
                        this.resetResults();
                        await this.getResults(true); // reload whole page - too much changes
                        return;
                    }
                    for (const newMessage of _.reverse(resp.data.results)) {
                        if (_.findIndex(this.results, r => r.id == newMessage.id) == -1) {
                            newMessage.new = !silent;
                            this.results.unshift(newMessage);
                            unreadComents.push(newMessage);
                            this.totalCount += 1;
                        }
                    }
                    for (const c of unreadComents) {
                        this.unreadComments.push(c);
                    }
                    if (!silent) {
                        playNotification();
                        if ($rootScope.isTabVisibleNow()) {
                            this.$_markAllUnreadCommentsAsRead();
                        } else {
                            this.$_unreadHeaderIndicator.increaseUnreadCountIfNotInFocus();
                        }
                    }
                } catch (e) {
                    console.debug(e);
                    this.$notifications.error('error');
                } finally {
                    this.$_loadingNewMessages = false;
                }
            },
            async $_handleNewWebsocketMessage(e) {
                if (e.conversation_id == this.targetId) {
                    this.$nextTick(() => {
                        this.$_loadNewMessages();
                    });
                    return true;
                }
                return false;
            },
            $_handleLikesCountChanged(e) {
                /**
                 * @param {LiveEventConversationMessageLikeOrUnlike} e
                 */
                if (e.conversation_id != this.targetId) {
                    return;
                }
                let message_id = parseInt(e.message_id);
                let likes_count = parseInt(e.likes_count);
                this.results.filter(c => c.id === message_id).map(c => {
                    Vue.set(c, 'likes_count', likes_count);
                });
            },
            $_handleMessageDeleted(e) {
                /**
                 * @param {LiveEventConversationMessageDeleted} e
                 */
                if (e.conversation_id != this.targetId) {
                    return;
                }
                let message_id = parseInt(e.message_id);
                this.results = this.results.filter(c => c.id !== message_id);
                for (let c of this.results) {
                    if (c.reply_to?.id === message_id) {
                        c.reply_to = null;
                    }
                }
                const initialUnreadCount = this.unreadComments.length;
                this.unreadComments = this.unreadComments.filter(c => c.id !== message_id);
                if (this.unreadComments.length < initialUnreadCount) {
                    this.$_unreadHeaderIndicator.setUnreadCount(this.unreadComments.length);
                }
            },
            $_handleNewMessageLive(e) {
                /**
                 * @param {LiveEventConversationNewMessageLive} e
                 */
                if (e.conversation_id != this.targetId) {
                    return;
                }
                if (e.userprofile_id != new CurrentUserClass().id) {
                    return;
                }
                setTimeout(() => {
                    if (this.results.map(m => m.id).includes(e.message_id)) {
                        return;
                    }
                    this.$_loadNewMessages(true);
                }, 1000);
            },
            $_handleMessageApproved(e) {
                /**
                 * @param {LiveEventConversationMessageApproved} e
                 */
                if (e.conversation_id != this.targetId) {
                    return;
                }
                let message_id = parseInt(e.message_id);
                let comments_search_result = this.results.filter(c => c.id === message_id);
                if (comments_search_result.length !== 1) {
                    return;
                }
                let comment_to_lift = comments_search_result[0];
                if (comment_to_lift.approved) {
                    return;
                }
                this.results = this.results.filter(c => c.id !== message_id);
                Vue.set(comment_to_lift, 'approved', true);
                Vue.set(comment_to_lift, 'new', true);
                this.$nextTick(() => {
                    this.results.unshift(comment_to_lift);
                    setTimeout(() => {
                        this.$nextTick(() => {
                            Vue.set(comment_to_lift, 'new', false);
                        });
                    }, 5000);
                });

            },
            $_loadCommentForReply(commentId) {
                this.commentsResource.get_one(this.targetId, { id: commentId })
                    .then(({ data }) => {
                        this.$nextTick(() => {
                            setTimeout(() => {
                                this.reply(data);
                            }, 100);
                        });
                    }, err => {
                        Vue.notifications.error('Comment was deleted');
                    });
            },
            $_bindNewMessageListener() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                addWsEventListener(this.onMessageAddedEventCode, this.$_handleNewWebsocketMessage);

                this.$_unreadHeaderIndicator = new UnreadMessagesHeaderCountIndicator($rootScope);
                this.$_tabVisibilityChangeHandler = $rootScope.$on('tabVisibilityChanged', (e, isVisible) => {
                    if (isVisible) {
                        this.$_unreadHeaderIndicator.resetUnreadCount();
                        this.$_markAllUnreadCommentsAsRead();
                    }
                });
            },
            $_unbindNewMessageListener() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                this.$_unreadHeaderIndicator = undefined;
                removeWsEventListener(this.onMessageAddedEventCode, this.$_handleNewWebsocketMessage);
                this.$_tabVisibilityChangeHandler();
            },
            $_bindMessageLikesCountChangeListener() {
                addWsEventListener(this.onMessageLikesCountChangedEventCode, this.$_handleLikesCountChanged);
            },
            $_unbindMessageLikesCountChangeListener() {
                removeWsEventListener(this.onMessageLikesCountChangedEventCode, this.$_handleLikesCountChanged);
            },
            $_bindMessageDeletedListener() {
                addWsEventListener(this.onMessageDeletedEventCode, this.$_handleMessageDeleted);
            },
            $_unbindMessageDeletedListener() {
                removeWsEventListener(this.onMessageDeletedEventCode, this.$_handleMessageDeleted);
            },
            $_bindMessageApprovedListener() {
                addWsEventListener(this.onMessageApprovedEventCode, this.$_handleMessageApproved);
            },
            $_unbindMessageApprovedListener() {
                removeWsEventListener(this.onMessageApprovedEventCode, this.$_handleMessageApproved);
            },
            $_bindNewMessageLiveListener() {
                addWsEventListener(this.onNewMessageLiveEventCode, this.$_handleNewMessageLive);
            },
            $_unbindNewMessageLiveListener() {
                removeWsEventListener(this.onNewMessageLiveEventCode, this.$_handleNewMessageLive);
            },
        },
        mounted() {
            if (this.initialReplyTo) {
                this.$_loadCommentForReply(this.initialReplyTo);
            }
            if (this.onMessageAddedEventCode) {
                this.$_bindNewMessageListener();
            }
            if (this.onMessageLikesCountChangedEventCode) {
                this.$_bindMessageLikesCountChangeListener();
            }
            if (this.onMessageDeletedEventCode) {
                this.$_bindMessageDeletedListener();
            }
            if (this.onMessageApprovedEventCode) {
                this.$_bindMessageApprovedListener();
            }
            if (this.onNewMessageLiveEventCode) {
                this.$_bindNewMessageLiveListener();
            }

            this.$nextTick(() => {
                this.focusToTextInput();
            });
        },
        beforeDestroy() {
            if (this.onMessageAddedEventCode) {
                this.$_unbindNewMessageListener();
            }
            if (this.onMessageLikesCountChangedEventCode) {
                this.$_unbindMessageLikesCountChangeListener();
            }
            if (this.onMessageDeletedEventCode) {
                this.$_unbindMessageDeletedListener();
            }
            if (this.onMessageApprovedEventCode) {
                this.$_unbindMessageApprovedListener();
            }
            if (this.onNewMessageLiveEventCode) {
                this.$_unbindNewMessageLiveListener();
            }
        }

    };
</script>

<style scoped lang="scss">
  .bottom-panel-item {
    display: inline-block;
    font-size: 33px;
    line-height: 30px;
    cursor: pointer;
    margin-right: 25px;
  }
</style>
