UserActivityPostController.$inject = ['$preloaded', 'UserActivityPostManageResource', 'UserActivityPostLikesResource',
    'activityPostLocationsFactory', 'MMCommon', 'activityPostGoalsFactory', 'simplePopupFactory',
    '$q', 'UserProfileActivityResource', 'notifications', '$rootScope', 'CurrentUser', 'UserActivityPostStoryResource', 'backUrlGenerator'];
import urls from 'DjangoUrls';

let {DjangoUrls} = urls;

function UserActivityPostController($preloaded, UserActivityPostManageResource, UserActivityPostLikesResource,
                                    activityPostLocationsFactory, MMCommon, activityPostGoalsFactory, simplePopupFactory,
                                    $q, UserProfileActivityResource, notifications, $rootScope, CurrentUser, UserActivityPostStoryResource, backUrlGenerator) {
    let self = this;

    function init() {
        self.user_post_resource = UserActivityPostStoryResource;
        self.user_activity_post = $preloaded.user_activity_post;
        self.is_owner = $preloaded.is_owner;
        self.edit_mode = false;
        self.buttonsExpanded = false;
    }

    init();

    self.go_back = backUrlGenerator.go_back;

    self.allow_remove = (function () {
        return ((CurrentUser.id === self.user_activity_post.userprofile.id) || CurrentUser.is_guardian_of(self.user_activity_post.userprofile.id));
    })();

    self.update_post = function (data) {
        $rootScope.show_dimmer();
        UserActivityPostManageResource.update_post(self.user_activity_post.id, data)
            .then(resp => {
                notifications.success('Updated');
            }, err => {
                notifications.error(err || 'Error');
            })
            .finally($rootScope.hide_dimmer);
    };

    self.post_linked_locations = new activityPostLocationsFactory.UserActivityPostLocations(
        self.user_activity_post.id,
        self.user_activity_post.location_points,
        self.allow_remove
    );

    self.post_linked_goals = new activityPostGoalsFactory.UserActivityPostGoals(
        self.user_activity_post.id,
        self.user_activity_post.goals_links_count,
        self.allow_remove
    );

    self.post_comments = new MMCommon.Commentable(
        self.user_activity_post.comments_count,
        self.user_activity_post.comments_link
    );

    self.post_likes = new MMCommon.Likeable(
        UserActivityPostLikesResource,
        self.user_activity_post.id,
        self.user_activity_post.liked_by_me,
        self.user_activity_post.likes_count
    );

    self.remove_post = function () {
        simplePopupFactory.show_popup('Remove Post', 'Are you sure you want to remove this post', 'Remove', 'Cancel')
            .then(yes => {
                $rootScope.show_dimmer();
                return UserProfileActivityResource.remove_post(self.user_activity_post.userprofile.id, self.user_activity_post.id);
            }, () => new Promise(()=>{}))
            .then(resp => {
                notifications.success('Deleted');
                document.location.href = DjangoUrls['index']();
            }, err => {
                notifications.error(err || 'Error');
                $rootScope.hide_dimmer();
            });
    };

    self.isExpandButtonNeeded = function () {
        let zeroIconsCount = 0;
        if (self.post_comments.get_comments_count() === 0) {
            zeroIconsCount++;
        }
        if (self.post_linked_goals.get_linked_tasks_count() === 0) {
            zeroIconsCount++;
        }
        if (self.post_linked_locations.get_linked_locations_count() === 0) {
            zeroIconsCount++;
        }
        return (zeroIconsCount > 0 && self.allow_remove) || (zeroIconsCount > 1);
    };

    self.expandButtons = function () {
        self.buttonsExpanded = true;
    };

}

export default UserActivityPostController;