<template>
    <div class="wiki-tree-slide">
        <i class="fa fa-eye"
           :class="viewButtonClasses"
           @click="toggleSlideLevelViewPermission"
        ></i>
        <div class="wiki-tree-slide__slide-avatar" :style="avatarStyle">
            <i class="fa" v-if="!avatarUrl" :class="holderIconClass"></i>
        </div>
        <div class="wiki-tree-slide__title">
            <a :href="slide.link" target="_blank"><strong>{{ slideName | cutName(30) }}</strong></a>
        </div>
        <div class="wiki-tree-slide__attachments">
            <wiki-tree-section-slide-attached-document
                    :permissionsList="permissionsList"
                    :inheritedPermissionLevel="resultPermissionLevel"
                    :attachedDocument="attached_document"
                    v-for="attached_document in slide.attached_documents" :key="attached_document.id"
            ></wiki-tree-section-slide-attached-document>
            <wiki-tree-section-slide-attached-link
                    :permissionsList="permissionsList"
                    :inheritedPermissionLevel="resultPermissionLevel"
                    :link="attached_link"
                    v-for="attached_link in slide.attached_links" :key="attached_link.id"
            ></wiki-tree-section-slide-attached-link>
        </div>
    </div>
</template>

<script>
    import mediaContentUtils from "../../view_media/mediaContentUtils";
    import wikiMemberPermissionBus from './wikiMemberPermissionBus';
    import editViewButtonsClassesMixin from './editViewButtonsClassesMixin';
    import WikiTreeSectionSlideAttachedDocument from './WikiTreeSectionSlideAttachedDocument.vue';
    import WikiTreeSectionSlideAttachedLink from './WikiTreeSectionSlideAttachedLink.vue';
    import {
        WIKI_PERMISSION_LEVEL,
        WIKI_PERMISSION_SIGNAL,
        WIKI_PERMISSION_VALUE
    } from "../../wiki_page/common/wikiPermissions";
    import TreePermissionDetectionMixinWikiSlide
        from "./tree_permission_detection_mixins/TreePermissionDetectionMixinWikiSlide";

    const mcu = mediaContentUtils();

    export default {
        name: "wiki-tree-section-slide",
        mixins: [editViewButtonsClassesMixin, TreePermissionDetectionMixinWikiSlide],
        components: {WikiTreeSectionSlideAttachedDocument, WikiTreeSectionSlideAttachedLink},
        props: {
            slide: {
                type: Object
            },
        },
        computed: {
            slideName() {
                return this.slide.position + 1 + '. ' + (this.slide.caption || 'Untitled');
            },
            avatarUrl() {
                return mcu.get_img_url(this.slide.media_content);
            },
            avatarStyle() {
                if (this.avatarUrl) {
                    return mcu.generate_group_background(this.avatarUrl, []);
                }
                return {};
            },
            holderIconClass() {
                return mcu.get_fa_icon_class(this.slide.media_content);
            },
            canOwnEditPermissionBeChanged() {
                return false;
            },
            canOwnViewPermissionBeChanged() {
                return this.inheritedPermissionLevel !== WIKI_PERMISSION_VALUE.EDIT;

            },
            hasRedefinedInChildEditProperty() {
                let triggerPermissions = this.triggerEditPermissions;
                let attachedDocumentIdList = [];
                for (let attachedDocument of this.slide.attached_documents) {
                    attachedDocumentIdList.push(attachedDocument.id);
                }
                for (let attachedDocumentId of attachedDocumentIdList) {
                    if (_.findIndex(this.permissionsList, p => (p.level === WIKI_PERMISSION_LEVEL.FILE_ATTACHMENT && p.target_id === attachedDocumentId && triggerPermissions.includes(p.value))) !== -1) {
                        return true;
                    }
                }
                let attachedLinksIdList = [];
                for (let attachedLink of this.slide.attached_links) {
                    attachedLinksIdList.push(attachedLink.id);
                }
                for (let attachedLinksId of attachedLinksIdList) {
                    if (_.findIndex(this.permissionsList, p => (p.level === WIKI_PERMISSION_LEVEL.LINK && p.target_id === attachedLinksId && triggerPermissions.includes(p.value))) !== -1) {
                        return true;
                    }
                }
                return false;
            },
            hasRedefinedInChildViewProperty() {
                let triggerPermissions = this.triggerViewPermissions;
                let attachedDocumentIdList = [];
                for (let attachedDocument of this.slide.attached_documents) {
                    attachedDocumentIdList.push(attachedDocument.id);
                }
                for (let attachedDocumentId of attachedDocumentIdList) {
                    if (_.findIndex(this.permissionsList, p => (p.level === WIKI_PERMISSION_LEVEL.FILE_ATTACHMENT && p.target_id === attachedDocumentId && triggerPermissions.includes(p.value))) !== -1) {
                        return true;
                    }
                }
                let attachedLinksIdList = [];
                for (let attachedLink of this.slide.attached_links) {
                    attachedLinksIdList.push(attachedLink.id);
                }
                for (let attachedLinksId of attachedLinksIdList) {
                    if (_.findIndex(this.permissionsList, p => (p.level === WIKI_PERMISSION_LEVEL.LINK && p.target_id === attachedLinksId && triggerPermissions.includes(p.value))) !== -1) {
                        return true;
                    }
                }
                return false;
            },
        },
        methods: {
            toggleSlideLevelViewPermission() {
                if (!this.canOwnViewPermissionBeChanged) {
                    return;
                }
                if (this.ownPermissionLevel === WIKI_PERMISSION_VALUE.UNSET) {
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.UNSET) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SLIDE, WIKI_PERMISSION_VALUE.VIEW, this.slide.id);
                    }
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.VIEW) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SLIDE, WIKI_PERMISSION_VALUE.NONE, this.slide.id);
                    }
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.NONE) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SLIDE, WIKI_PERMISSION_VALUE.VIEW, this.slide.id);
                    }
                }
                if (this.ownPermissionLevel === WIKI_PERMISSION_VALUE.VIEW) {
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.UNSET) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SLIDE, WIKI_PERMISSION_VALUE.UNSET, this.slide.id);
                    }
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.VIEW) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SLIDE, WIKI_PERMISSION_VALUE.NONE, this.slide.id);
                    }
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.NONE) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SLIDE, WIKI_PERMISSION_VALUE.UNSET, this.slide.id);
                    }
                }
                if (this.ownPermissionLevel === WIKI_PERMISSION_VALUE.NONE) {
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.UNSET) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SLIDE, WIKI_PERMISSION_VALUE.UNSET, this.slide.id);
                    }
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.VIEW) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SLIDE, WIKI_PERMISSION_VALUE.UNSET, this.slide.id);
                    }
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.NONE) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SLIDE, WIKI_PERMISSION_VALUE.VIEW, this.slide.id);
                    }
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    .wiki-tree-slide {
        .fa-eye {
            &.disabled {
                opacity: (0.5);
            }
        }
        .fa-pencil {
            &.disabled {
                opacity: (0.5);
            }

        }
        .fa {
            vertical-align: middle;
            font-size: 25px;
            margin: 0 4px;
        }
        margin-top: 6px;
        margin-bottom: 6px;
        margin-left: 116px;
        &__slide-avatar {
            text-align: center;
            vertical-align: middle;
            width: 60px;
            height: 50px;
            border-radius: 4px;
            border: solid 1px #333;
            display: inline-block;
            .fa {
                line-height: 45px;
                font-size: 30px;
            }
        }
        &__title {
            display: inline-block;
            vertical-align: middle;
        }
        &__attachments {
            margin-left: 33px;
            &__attachment {
                margin: 20px 0;
            }
        }
    }

</style>