/**
 * Created by orion on 7/1/17.
 */
let angular = require('angular');

function add_back_url(url) {
    if (!url) {
        return; // prevent interpolation errors
    }
    let full_path = document.location.href.substr(document.location.href.indexOf(document.location.pathname) + 1);
    let separator = '#!/';
    if (url.indexOf(separator) != -1) {
        let query_symbol = '?';
        if (url.split(separator)[1].indexOf('?') != -1) {
            query_symbol = '&';
        }
        return `${url}${query_symbol}from=/${escape(encodeURIComponent(full_path))}`;
    } else {
        return `${url}#!/?from=/${escape(encodeURIComponent(full_path))}`;
    }

}

function backHrefQuery(url, additionalParams = undefined) {
    if (!url) {
        return url; // prevent interpolation errors
    }
    let full_path = document.location.href.substr(document.location.href.indexOf(document.location.pathname) + 1);
    let query_symbol = '?';
    if (url.indexOf('?') != -1) {
        query_symbol = '&';
    }
    if (additionalParams) {
        for (let k of Object.keys(additionalParams)) {
            if (full_path.indexOf(`${k}=`) !== -1) {
                const start = full_path.lastIndexOf(`${k}=`) - 1;
                let stop = full_path.length - 1;
                if (full_path.lastIndexOf('&') < stop && full_path.lastIndexOf('&') !== -1) {
                    stop = full_path.lastIndexOf('&');
                }
                full_path = full_path.replace(full_path.slice(start, stop + 1), '');
            }
            const additioanalQuerySymbol = full_path.indexOf('?' === -1) ? '?' : '&';
            full_path += `${additioanalQuerySymbol}${k}=${encodeURIComponent(additionalParams[k])}`;
        }
    }
    let result = `${url}${query_symbol}from=/${escape(encodeURIComponent(full_path))}`;

    return result;
}

function decodeBackUrl(url) {
    return decodeURIComponent(unescape(url));
}

backUrlGenerator.$inject = ['$location', '$rootScope', '$log'];

function backUrlGenerator($location, $rootScope, $log) {

    let fact = {
        backHrefQuery,
        add_back_url,
        decode_function(from_url) {
            return decodeBackUrl(from_url);
        },
        get_from_url() {
            let from_url = $location.search().from;
            if (from_url) {
                from_url = this.decode_function(from_url);
            }
            return from_url;
        },
        go_back(event, fallback_url = undefined) {
            let raw_from_url = fact.get_from_url();
            let from_url = ((typeof (raw_from_url) === 'string') && raw_from_url !== 'true' ? raw_from_url : undefined) || fallback_url;
            if (from_url) {
                $rootScope.show_dimmer();
                if (event) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                document.location.href = from_url;
            } else {
                window.history.back();
            }
        }
    };

    return fact;
}

angular.module('mmApp.shared.backUrlGenerator', []).factory('backUrlGenerator', backUrlGenerator);

export default 'mmApp.shared.backUrlGenerator';
export {add_back_url as abu, backHrefQuery, decodeBackUrl};