MediaToAdminUploadedFilesNotificationController.$inject = ['$scope', 'MediaFoldersResource', '$rootScope', 'viewMediaFactory', 'notifications'];

function MediaToAdminUploadedFilesNotificationController($scope, MediaFoldersResource, $rootScope, viewMediaFactory, notifications) {
    let self = this;
    self.show_files = function (task) {
        const context_list = task.context_list;
        const owner = task.context_userprofile;
        $rootScope.show_dimmer();
        MediaFoldersResource.get_media_files_to_view_in_task(owner.id, context_list)
            .then(resp => {
                const pure_items = resp.data;
                viewMediaFactory.show_media(pure_items, 0);
            }, err => {
                notifications.error(err || 'Error');
            })
            .finally($rootScope.hide_dimmer);
    };
}

export default MediaToAdminUploadedFilesNotificationController;