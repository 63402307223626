var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "community-page-alternative-header",
        { attrs: { title: "Wiki Access", "left-part-size": 7, scaling: "xs" } },
        [
          _c("vue-folder-button", {
            attrs: { "icon-class": "fa-user-plus" },
            nativeOn: {
              click: function ($event) {
                return _vm.inviteUsers.apply(null, arguments)
              },
            },
          }),
          _vm._v(" "),
          _vm.viewerIsAdmin
            ? _c(
                "router-link",
                { attrs: { to: { name: _vm.backRouteName } } },
                [
                  _c("vue-folder-button", {
                    attrs: { color: "blue", "icon-class": "fa-reply" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "wiki-access-table" },
        [
          _c("wiki-members-list-table", {
            attrs: {
              members: _vm.members,
              sections: _vm.sections,
              owner: _vm.owner,
            },
            on: {
              openTreeOnMember: _vm.openTreeOnMember,
              toggleMemberAdminStatus: _vm.toggleMemberAdminStatus,
              removeMember: _vm.removeMember,
              sendReadReceipt: _vm.sendReadReceipt,
              showReadReceipts: _vm.showReadReceipts,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }