var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "create-holidays-popup",
      attrs: { transition: 0, "append-to-body": true, backdrop: false },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          attrs: { id: "close_popup" },
          on: { click: _vm.closePopup },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.header))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("i", [
            _vm._v(
              "By creating a Holiday you can snooze all repeated Events in a Group for a selected date\n                range."
            ),
          ]),
          _c("br"),
          _vm._v(" "),
          _c("i", [
            _c("strong", [_vm._v("Attention!")]),
            _vm._v(
              " All date-specific data (e.g. comments, attendance information,\n                invitations) will be lost in Group Events during this Holiday."
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c(
            "form",
            { staticClass: "form" },
            [
              _c("strong", [_vm._v("Start Date:")]),
              _vm._v(" "),
              _c(
                "datepicker",
                {
                  ref: "start-date-picker",
                  attrs: {
                    "disabled-dates": _vm.disabledDatesStart,
                    format: _vm.customFormatter,
                    width: _vm.pickerWidth,
                    "input-class": "create-event-date-picker",
                    "bootstrap-styling": true,
                    typeable: false,
                    highlighted: _vm.highlightedDates,
                  },
                  nativeOn: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                          "Backspace",
                          "Delete",
                          "Del",
                        ])
                      )
                        return null
                      return _vm.startDateClear.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.startDate,
                    callback: function ($$v) {
                      _vm.startDate = $$v
                    },
                    expression: "startDate",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "input-group-btn",
                      attrs: { slot: "afterDateInput" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.toggleStartDateCalendar.apply(
                            null,
                            arguments
                          )
                        },
                      },
                      slot: "afterDateInput",
                    },
                    [
                      _c(
                        "btn",
                        {
                          staticClass:
                            "dropdown-toggle create-event-date-picker",
                        },
                        [
                          _c("i", {
                            staticClass: "glyphicon glyphicon-calendar",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-6" }, [
          _c(
            "form",
            { attrs: { action: "" } },
            [
              _c("strong", [_vm._v("Finish Date:")]),
              _vm._v(" "),
              _c(
                "datepicker",
                {
                  ref: "end-date-picker",
                  attrs: {
                    "disabled-dates": _vm.disabledDatesEnd,
                    format: _vm.customFormatter,
                    width: _vm.pickerWidth,
                    "input-class": "create-event-date-picker",
                    "bootstrap-styling": true,
                    typeable: false,
                    highlighted: _vm.highlightedDates,
                  },
                  nativeOn: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                          "Backspace",
                          "Delete",
                          "Del",
                        ])
                      )
                        return null
                      return _vm.endDateClear.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.endDate,
                    callback: function ($$v) {
                      _vm.endDate = $$v
                    },
                    expression: "endDate",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "input-group-btn",
                      attrs: { slot: "afterDateInput" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.toggleEndDateCalendar.apply(
                            null,
                            arguments
                          )
                        },
                      },
                      slot: "afterDateInput",
                    },
                    [
                      _c(
                        "btn",
                        {
                          staticClass:
                            "dropdown-toggle create-event-date-picker",
                        },
                        [
                          _c("i", {
                            staticClass: "glyphicon glyphicon-calendar",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("strong", [_vm._v("Holiday Name:")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name",
              },
            ],
            staticClass: "form-control create-holidays-popup__name-input",
            attrs: {
              type: "text",
              placeholder: "Name",
              maxlength: "100",
              name: "holiday_name",
            },
            domProps: { value: _vm.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.name = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.descriptionFieldIsVisible,
                  expression: "!descriptionFieldIsVisible",
                },
              ],
              staticStyle: {
                "font-size": "13px",
                "line-height": "18px",
                "min-height": "20px",
              },
            },
            [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.name,
                      expression: "name",
                    },
                  ],
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.showDescriptionField.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n                    + Add Description")]
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.descriptionFieldIsVisible
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("strong", [_vm._v("Add description:")]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.description,
                    expression: "description",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  name: "",
                  id: "",
                  cols: "30",
                  rows: "3",
                  placeholder: "Description",
                  maxlength: "3000",
                },
                domProps: { value: _vm.description },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.description = $event.target.value
                  },
                },
              }),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.allowCreateInAnotherGroups
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c(
              "div",
              { staticClass: "col-sm-6" },
              [
                _c("vue-switch-with-label", {
                  attrs: {
                    label: "Include other Groups",
                    value: _vm.addInAnotherGroups,
                    name: "addInAnotherGroups",
                  },
                  on: { input: _vm.onAddInAnotherGroupsChanged },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.groupsToAddIdList.length
              ? _c(
                  "div",
                  {
                    staticClass: "col-sm-6",
                    staticStyle: { "line-height": "31px" },
                  },
                  [
                    _vm._v("\n            Holiday will be copied in "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.groupsToAddIdList.length)),
                    ]),
                    _vm._v(" Group"),
                    _vm.groupsToAddIdList.length > 1
                      ? _c("span", [_vm._v("s")])
                      : _vm._e(),
                    _vm._v(".\n        "),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.allowSyncWithAnotherGroups
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c(
              "div",
              { staticClass: "col-sm-6" },
              [
                _c("vue-switch-with-label", {
                  attrs: {
                    label: "Sync with other Groups",
                    value: _vm.syncWithAnotherGroups,
                    name: "syncWithAnotherGroups",
                  },
                  on: { input: _vm.onSyncWithAnotherGroupsChanged },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.groupsToSyncIdList.length || _vm.groupsToSyncAllSelected
              ? _c(
                  "div",
                  {
                    staticClass: "col-sm-6",
                    staticStyle: { "line-height": "31px" },
                  },
                  [
                    _vm._v(
                      "\n            Holiday will be synced with\n            "
                    ),
                    _vm.groupsToSyncAllSelected
                      ? _c("strong", [_vm._v("all")])
                      : _c("strong", [
                          _vm._v(_vm._s(_vm.groupsToSyncIdList.length)),
                        ]),
                    _vm._v(" Group"),
                    _vm.groupsToSyncAllSelected ||
                    _vm.groupsToSyncIdList.length > 1
                      ? _c("span", [_vm._v("s")])
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.allowCreateInAnotherGroups &&
      ((_vm.groupsToAddIdList && _vm.groupsToAddIdList.length) ||
        _vm.groupsToAddAllSelected)
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _vm._v("\n            Holiday will be created in\n            "),
              !_vm.groupsToAddAllSelected
                ? _c("strong", [_vm._v(_vm._s(_vm.groupsToAddIdList.length))])
                : _c("strong", [_vm._v("all")]),
              _vm._v("\n            Group"),
              _vm.groupsToAddAllSelected || _vm.groupsToAddIdList.length > 1
                ? _c("span", [_vm._v("s")])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.allowSyncWithAnotherGroups &&
      ((_vm.groupsToSyncIdList && _vm.groupsToSyncIdList.length) ||
        _vm.groupsToSyncAllSelected)
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _vm._v("\n            Holiday will be synced with\n            "),
              !_vm.groupsToSyncAllSelected
                ? _c("strong", [_vm._v(_vm._s(_vm.groupsToSyncIdList.length))])
                : _c("strong", [_vm._v("all")]),
              _vm._v("\n            Group"),
              _vm.groupsToSyncAllSelected || _vm.groupsToSyncIdList.length > 1
                ? _c("span", [_vm._v("s")])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          { staticClass: "btn btn-mm", on: { click: _vm.closePopup } },
          [_vm._v("Cancel")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-green save-button",
            attrs: { disabled: !_vm.allowSave },
            on: { click: _vm.save },
          },
          [_vm._v("\n            " + _vm._s(_vm.saveLabel) + "\n        ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }