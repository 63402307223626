/**
 * Created by orion on 6/24/17.
 */
/**
 * Created by orion on 28.05.17.
 */

stuffPreview.$inject = ['$log', 'mediaContentUtils'];
let _ = require('lodash');
import Vue from 'vue';

function stuffPreview($log, mediaContentUtils) {
    return {
        restrict: 'E',
        scope: {
            stuffItem: '=',
            animation: '=?',
            deletable: '=?',
            size: '=?',
            mediaContentSamples: '=?',
            displayName: '=?',
            unreadMark: '=?',
            onlineMark: '=?',
            showAdditionalMark: '=?',
            additionalMark: '@?',
            additionalMarkColor: '@?',
            additionalMarkCustomIcon: '@?',
            showAdditionalMark2: '=?',
            additionalMark2: '@?',
            additionalMarkColor2: '@?',
            additionalMarkCustomIcon2: '@?',
            deleteButton: '@?',
            stuffType: '@',
            onDelete: '&?',
            onAdditionalMarkClick: '&?',
        },
        link: function ($scope, element, attrs, controller) {
            const vm = new Vue({
                el: element[0],
                name: 'NGstuffPreview',
                data() {
                    return {
                        stuffItem: $scope.stuffItem,
                        size: $scope.size,
                        animation: true,
                        deletable: $scope.deletable,
                        mediaContentSamples: $scope.mediaContentSamples,
                        stuffType: $scope.stuffType,
                        displayName: $scope.displayName,
                        deleteButton: $scope.deleteButton,
                        unreadMark: $scope.unreadMark,
                        onlineMark: $scope.onlineMark,
                        additionalMark: $scope.additionalMark,
                        additionalMarkCustomIcon: $scope.additionalMarkCustomIcon,
                        additionalMarkColor: $scope.additionalMarkColor,
                        showAdditionalMark: $scope.showAdditionalMark,
                        additionalMark2: $scope.additionalMark2,
                        additionalMarkCustomIcon2: $scope.additionalMarkCustomIcon2,
                        additionalMarkColor2: $scope.additionalMarkColor2,
                        showAdditionalMark2: $scope.showAdditionalMark2,
                    };

                },
                template: `<vue-stuff-preview   ng-non-bindable                     
                        :stuffItem="stuffItem"
                        :size="size"
                        :animation="animation"
                        :deletable="deletable"
                        :mediaContentSamples="mediaContentSamples"
                        :stuffType="stuffType" 
                        :deleteButton="deleteButton"
                        :unreadMark="unreadMark"
                        :onlineMark="onlineMark"
                        :additionalMark="additionalMark"
                        :additionalMarkCustomIcon="additionalMarkCustomIcon"
                        :additionalMarkColor="additionalMarkColor"
                        :showAdditionalMark="showAdditionalMark"
                        :additionalMark2="additionalMark2"
                        :additionalMarkCustomIcon2="additionalMarkCustomIcon2"
                        :additionalMarkColor2="additionalMarkColor2"
                        :showAdditionalMark2="showAdditionalMark2"
                        @delete="on_delete"
                        @additionalMarkClick="onAdditionalMarkClick"
                        @click.native="onClick"                        
                        ></vue-stuff-preview>`,
                methods: {
                    on_delete() {
                        ($scope.onDelete || _.noop)();
                    },
                    onAdditionalMarkClick() {
                        ($scope.onAdditionalMarkClick || _.noop)();
                    },
                    onClick() {
                        element[0]?.click();
                    }
                }
            });
            $scope.$watch('deletable', function (n, o) {
                vm.deletable = n;
            });
            $scope.$watch('showAdditionalMark', function (n, o) {
                vm.showAdditionalMark = n;
            });
        }
    };
}

angular.module('mmApp.stuff_previews.stuffPreview', [])
    .directive('stuffPreview', stuffPreview);

export default 'mmApp.stuff_previews.stuffPreview';