<template>
    <div class="wiki-detail-page-slide-description" :style="descriptionStyle">
        <div class="wiki-detail-page-slide-description__icons-panel">
            <div>
                <div>
                    <i class="far fa-pencil wiki-detail-page-slide-description__icon vertical-middle"
                       v-if="editMode"
                       @click="editDescription"
                       style="font-size: 27px"
                       v-tooltip="'Edit Description'"></i>
                </div>
                <div class="" v-if="audioDescription">
                    <audio-toggle
                            class="wiki-detail-page-slide-description__icons-panel__audio-toggle"
                            :audio-caption="audioDescription"
                            fa-class="far"
                            style="min-width: 27px; display: inline-block"
                            ref="audioDescription"
                    ></audio-toggle>
                </div>
                <div class="">
                    <i class="far fa-comment-alt-exclamation color-red wiki-detail-page-slide-description__icon vertical-middle"
                       v-if="descriptionIsLarge"
                       @click="showDescriptionInPopup"
                       style="font-size: 29px; position: relative; top: -6px;"
                       v-tooltip="'This slide contains text best viewed in a pop up, click here to see all the text'"></i>
                </div>
            </div>
        </div>
        <div class="text-center" @click="editDescription">
            <div class="wiki-detail-page-slide-description__inner-container text-left hidden-scroll"
                 :style="innerDescriptionStyle">
                <div class="inline-block wiki-detail-page-slide-description__caption__container">
                    <span class="wiki-detail-page-slide-description__caption vertical-middle"
                          v-html="descriptionText"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    let Autolinker = require('autolinker');
    export default {
        name: "WikiDetailPageSlideDescription",
        props: {
            slide: Object,
            editMode: {
                type: Boolean,
                default: false
            },
            height: Number
        },
        computed: {
            descriptionIsLarge() {
                if (this.slide && this.slide.description) {
                    return (this.slide.description.length > 300) || this.slide.description.split('\n').length > 3;
                }

            },
            audioDescription() {
                if (!this.slide) {
                    return;
                }
                if (this.slide.audio_description) {
                    return this.slide.audio_description;
                }
            },
            descriptionStyle() {
                // if(this.height===0){
                //     return {'display':'none'}
                // }
                return {
                    height: `${this.height}px`
                };
            },
            innerDescriptionStyle() {
                return {
                    height: `${this.height - 10}px`
                };
            },
            descriptionText() {
                if (!this.slide) {
                    return;
                }
                if (this.slide.description) {
                    return Autolinker.link(this.slide.description, {truncate: 30});
                }
                if (this.editMode) {
                    return 'Add more words here...';
                } else {
                    return ``;
                }
            }
        },
        methods: {
            showDescriptionInPopup() {
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                simplePopupFactory.show_popup('Description', `<span style="white-space: pre-line;">${this.slide.description}</span>`, 'ok', undefined, undefined, 'lg');
            },
            editDescription() {
                if (!this.editMode) {
                    return;
                }
                this.$store.dispatch('WikiStore/editSlideDescription', this.slide);
            },
            hasAudioCaption() {
                return !!this.$refs.audioDescription;
            },
            toggleSound() {
                this.$refs.audioDescription?.toggleSound();
            },
            stopSound() {
                this.$refs?.audioDescription?.stopSound();
            },
        }
    };
</script>

<style scoped lang="scss">
  .wiki-detail-page-slide-description {
    position: relative;

    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 15px;
    margin-right: 35px;

    /*background-color: #777777;*/

    .fa {
      font-size: 38px;
    }

    &__inner-container {
      position: relative;
      overflow-x: hidden;
      overflow-y: auto;
    }

    &__caption {
      white-space: pre-line;

      &__container {
        font-size: 20px;
        line-height: 22px;
        width: 100%;
      }

      //font-weight: bold;
    }

    &__icons-panel {
      font-size: 32px;
      line-height: 37px;
      //font-weight: bold;
      cursor: pointer;
      position: absolute;
      right: -33px;
      top: 0;
    }

    &__volume-sub-panel {
      &.edit-mode {
        .fa-pencil {
          margin-left: 15px;
        }
      }
    }
  }
</style>