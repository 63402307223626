/**
 * Created by orion on 28.05.17.
 */
StoriesListController.$inject = ['$scope', '$preloaded', '$q', '$log', '$timeout', 'simplePopupFactory', 'notifications', '$rootScope', 'StoryResource'];
let _ = require('lodash');

function StoriesListController($scope, $preloaded, $q, $log, $timeout, simplePopupFactory, notifications, $rootScope, StoryResource) {
    let self = this;

    self.hover_animation = false;
    self.stories_count = 0;
    const LIMIT = 70;
    self.loading_block = false;
    let loaded_all = false;
    let LAST_QUERY = undefined;

    self.stories = [];

    function init() {
        fetch_stories();
    }

    self.search = function (query) {
        self.hover_animation = false;
        LAST_QUERY = query;
        fetch_stories(query, true);
    };

    self.cancel_search = function () {
        self.hover_animation = false;
        LAST_QUERY = undefined;
        fetch_stories(undefined, true);
    };

    self.create_story = function () {
        simplePopupFactory.simple_text_input_popup('Create New Story', 'Story name:', 'Create', 'Cancel')
            .then(name => {
                $rootScope.show_dimmer();
                return StoryResource.create_story({name})
            }, () => new Promise(()=>{}))
            .then(resp => {
                document.location.href = resp.data.link;
            }, err => {
                notifications.error(err || 'Error');
                $rootScope.hide_dimmer();
            })
    };

    self.load_more = function () {
        if (loaded_all) {
            return;
        }
        fetch_stories(LAST_QUERY, false);
    };

    function fetch_stories(query, reset_list = false) {
        if (self.loading_block) {
            return
        }
        self.loading_block = true;
        $timeout(() => {  // coz animation freezing
            if (reset_list) {
                loaded_all = false;
                self.stories = [];
            }
            StoryResource.get_my_stories({
                limit: LIMIT,
                offset: self.stories.length,
                query
            })
                .then(resp => {
                    self.stories = _.concat(self.stories, resp.data.results);
                    self.stories_count = resp.data.count;
                    if (self.stories_count === self.stories.length) {
                        loaded_all = true;
                    }
                }).finally(() => {
                self.loading_block = false;
                $timeout(() => {
                    self.hover_animation = true;
                }, 300);

            });
        }, 50);
    }

    init();


}

export default StoriesListController;