<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 aria-label="Send Read Receipt"
                 role="dialog">
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title"><span class="vertical-middle">Send Read Receipt</span>
                <a href="https://multime.freshdesk.com/a/solutions/articles/44002368928" target="_blank">
                    <i class="far fa-info pointer color-blue vertical-middle"></i>
                </a>
            </h3>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group">
                    <label for="receipt-name">Name:</label>
                    <input
                            type="text"
                            id="receipt-name"
                            maxlength="255"
                            class="form-control"
                            ref="receiptNameInput"
                            v-model="name"
                    >
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group">
                    <label for="receipt-comment">Message:</label>
                    <textarea
                            type="text"
                            id="receipt-comment"
                            class="form-control"
                            ref="receiptCommentInput"
                            v-model="senderComment"
                            placeholder="Add a message for user"
                            maxlength="1023"
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <wiki-receipt-tree
                        :sections="sections"
                        :permissions-list="permissionsList"
                        :included-stuff-config="includedStuffConfig"
                        @input="onIncludedStuffConfigUpdate"
                ></wiki-receipt-tree>
            </div>
        </div>
        <div slot="footer" class="row">
            <div class="col-sm-12 text-right">
                <button class="btn btn-mm btn-green" :disabled="!allowSend" @click="ok">Send</button>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import BasicModal from "../../../../../vue/common_components/BasicModal";
    import PopupPromiseMixin from "../../../../../vue/mixins/PopupPromiseMixin";
    import WikiReceiptTree from "./WikiReceiptTree";

    export default {
        name: "CreateReceiptPopup",
        components: {WikiReceiptTree, BasicModal},
        mixins: [PopupPromiseMixin],
        data() {
            return {
                sections: [],
                permissionsList: [],
                includedStuffConfig: {
                    whole_wiki: false,
                    sections: [],
                    slides: [],
                },
                name: '',
                senderComment: '',
            };
        },
        computed: {
            firstFocusEl() {
                return this.$refs.receiptNameInput;
            },
            allowSend() {
                return this.name && (this.includedStuffConfig.whole_wiki || this.includedStuffConfig.sections.length || this.includedStuffConfig.slides.length);
            },
        },
        methods: {
            setInitial(sections, permissionsList, suggestedName) {
                this.name = suggestedName;
                this.sections = sections;
                this.permissionsList = permissionsList;
            },
            ok() {
                this.save(Object.assign({
                    name: this.name,
                    sender_comment: this.senderComment
                }, this.includedStuffConfig));
            },
            onIncludedStuffConfigUpdate(includedStuffConfig) {
                this.includedStuffConfig = includedStuffConfig;
            },
        }
    };
</script>

<style scoped>

</style>