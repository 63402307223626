<template>
    <div class="event-detail-info">
        <div class="event-detail-info__grid__container">
            <div class="event-detail-info__grid__info-part">
                <div class="event-detail-info__avatar" :style="avatarStyle"></div>
                <div class="event-detail-info__start-date-label margin-15-bottom">{{ startDateLabel }}</div>
                <event-detail-page-info-create-entry-button
                        :disabled="!membershipStatus.can_create_blog_entry"
                        @click="showCreateEntryPopup"
                ></event-detail-page-info-create-entry-button>
                <event-detail-page-info-entry-list
                        ref="event-detail-page-info-entry-list"></event-detail-page-info-entry-list>
            </div>
            <div class="event-detail-info__grid__content-part">
                <div class="event-detail-info__data">
                    <div class="event-detail-info__data__description">

                        <div class="event-detail-info__data__description__time-label">
                            <div class="" v-if="event.event_data.location">
                                <strong>Location:</strong> {{ locationLabel }}
                                <location-with-marker :location="event.event_data.location"></location-with-marker>
                            </div>

                            <div class="" v-if="event.is_periodic">
                                <strong>Repeat:</strong> <span>{{ repeatLabel }}</span> <i
                                    class="fa fa-pencil pointer" @click="editEventPeriod" v-if="isAdmin"></i>
                                <span class="color-tag-gray">(since {{ sinceLabel }}<span
                                        v-if="additionalPeriodInfoAvailable"> <i
                                        class="fal fa-history pointer" @click="showAdditionalPeriodInfo"
                                ></i></span>)</span>
                            </div>
                            <div class="">
                                <strong>Start:</strong> <span>{{ startTimeLabel }}</span>
                            </div>
                            <div class="">
                                <strong>Finish:</strong> <span>{{ finishTimeLabel }}</span>
                            </div>

                            <div class="" v-if="event.event_data.advanced_time_schedule_enabled">
                                <strong>Schedule: <i class="fa fa-pencil pointer" @click="quickEditSchedule"
                                                     v-if="isAdmin"></i></strong>
                                <div class="" style="margin-left: 3px">
                                    <div :class="{'color-gray':!scheduleItem.isActive}"
                                         v-for="scheduleItem in periodicEventScheduleItems">
                                        <span>{{ scheduleItem.weekdayLabel }}:</span>
                                        <span>{{ scheduleItem.timeLabel }}</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="event-detail-info__data__description__header" v-show="event.event_data.description">
                            <strong>Description:</strong>
                        </div>
                        <div class="event-detail-info__data__description__text">
                            {{ event.event_data.description }}
                        </div>
                        <div class="margin-15-top" v-show="isAdmin||attachments.length">
                            <strong>Attachments:</strong>
                        </div>
                        <attachments-panel
                                ref="attachments-panel"
                                :can-edit-attachments="isAdmin"
                                :target-id="targetEventId"
                                :attachments-resource="attachmentsResource"
                                :size="40"
                                :margin="0"
                        ></attachments-panel>
                        <event-detail-page-info-add-to-calendar-panel
                                class="margin-15-top"
                                v-if="membershipStatus.owner_has_access_to_event"
                                ref="addToCalendarPanel"
                        ></event-detail-page-info-add-to-calendar-panel>
                        <event-reminder-panel
                                class="margin-15-top"
                                v-if="membershipStatus.owner_has_access_to_event"
                                :event-id="eventId"
                                :date-for-api="dateForApi"
                                :date-moment="dateMoment"
                                :is-periodic="periodInfo.is_periodic"
                                :calendar-owner="calendarOwner"
                                @reminderCreated="onReminderCreated"
                        ></event-reminder-panel>
                        <div v-else class="margin-15-top">
                            {{ calendarOwner.name }} doesn't have access to this event. <strong><a href="javascript:;"
                                                                                                   @click.stop.prevent="addOwnerToEvent">Add
                            {{ calendarOwner.name }} to this event</a></strong>
                        </div>
                        <event-detail-page-info-attendance-panel
                                class="margin-15-top"
                                v-if="membershipStatus.added_to_calendar"
                                :attendance="membershipStatus.attendance"
                                @setAttendance="setAttendance"
                                @unsetAttendance="unsetAttendance"
                        ></event-detail-page-info-attendance-panel>
                        <div class="margin-15-top">
                            <div class="" v-if="goingMembers.length">
                                <div class=""><strong>Going</strong></div>
                                <div class="">
                                    <vue-stuff-preview
                                            v-for="member in goingMembers"
                                            :stuff-item="member"
                                            :size="60"
                                            :display-name="false"
                                            stuff-type="user"
                                            :deletable="false"
                                            :animation="false"
                                            :online-mark="member.online"
                                            :key="member.id"
                                    ></vue-stuff-preview>
                                </div>
                            </div>
                            <add-to-outer-calendars-panel
                                    :event-id="eventId"
                                    :date-for-api="dateForApi"
                                    :is-periodic="periodInfo.is_periodic"
                                    :date-moment="dateMoment"
                                    class="margin-10-bottom"
                            ></add-to-outer-calendars-panel>
                            <div class="event-detail-info__see-members-label margin-15-top">
                                <router-link
                                        style="text-decoration: underline; color: #333"
                                        :to="{name:'members',params:{date,calendarOwnerId:calendarOwner.id,calendarOwnerName:calendarOwner.name}, hash:$route.hash}"
                                ><strong>See all members</strong></router-link>
                                <span class="event-detail-info__see-members-label__invite-label">
                                    <a href="javascript:;" v-show="allowInviteMember" @click.stop.prevent="inviteMember"
                                       style="margin-left: 15px"> <i
                                            class="fa fa-user-plus event-detail-info__see-members-label__invite-label__icon"></i><span
                                            style="text-decoration: none"> </span>Invite somebody</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <edit-event-period-popup ref="edit-event-period-popup"></edit-event-period-popup>
        <event-detail-page-create-diary-entry-popup
                ref="create-entry-popup"></event-detail-page-create-diary-entry-popup>
        <event-recurrence-info-popup ref="event-recurrence-info-popup"></event-recurrence-info-popup>
        <advanced-time-schedule-popup ref="advanced-time-schedule-popup"></advanced-time-schedule-popup>
        <update-repeated-event-type-selector-popup
                ref="update-repeat-event-popup"></update-repeated-event-type-selector-popup>
    </div>
</template>

<script>
    import eventCommonFieldsMixin from "../eventCommonFieldsMixin";
    import * as config from '../new_calendar/config';
    import Vue from 'vue';
    import EditEventPeriodPopup from './EditEventPeriodPopup.vue';
    import AttachmentsPanel from './AttachmentsPanel.vue';
    import EventDetailPageInfoAttendancePanel from './EventDetailPageInfoAttendancePanel.vue';
    import EventDetailPageInfoAddToCalendarPanel from './EventDetailPageInfoAddToCalendarPanel.vue';
    import {EVENTS_BUS_EVENTS, eventsBus} from './eventsBus';
    import ScreenWidthAwareMixin from "../../../../vue/mixins/ScreenWidthAwareMixin";
    import EventDetailSubPageMixin from "./EventDetailSubPageMixin";
    import EventDetailPageCreateDiaryEntryPopup from './EventDetailPageCreateDiaryEntryPopup.vue';
    import EventDetailPageJoinMixin from "./EventDetailPageJoinMixin";
    import EventRecurrenceInfoPopup from './EventRecurrenceInfoPopup.vue';
    import AdvancedTimeSchedulePopup from "./AdvancedTimeSchedulePopup.vue";
    import UpdateRepeatedEventTypeSelectorPopup from './UpdateRepeatedEventTypeSelectorPopup.vue';
    import {nullify} from "../new_calendar/common";
    import EventDetailPageInfoEntryList from './EventDetailPageInfoEntryList.vue';
    import EventDetailPageInfoCreateEntryButton from './EventDetailPageInfoCreateEntryButton.vue';
    import EventInfoMixin from "./EventInfoMixin";
    import AddToOuterCalendarsPanel from "../add_to_outer_calendars/AddToOuterCalendarsPanel.vue";
    import EventReminderPanel from "../event_reminders/eventReminderPanel";

    export default {
        name: "EventDetailPageInfo",
        mixins: [EventInfoMixin, EventDetailSubPageMixin, eventCommonFieldsMixin, ScreenWidthAwareMixin, EventDetailPageJoinMixin],
        components: {
            EventReminderPanel,
            AddToOuterCalendarsPanel,
            EditEventPeriodPopup,
            AttachmentsPanel,
            EventDetailPageInfoAttendancePanel,
            EventDetailPageInfoAddToCalendarPanel,
            EventDetailPageCreateDiaryEntryPopup,
            EventRecurrenceInfoPopup,
            AdvancedTimeSchedulePopup,
            UpdateRepeatedEventTypeSelectorPopup,
            EventDetailPageInfoEntryList,
            EventDetailPageInfoCreateEntryButton,
        },
        props: {
            eventId: String,
            date: String
        },
        data() {
            return {
                goingMembers: [],
            };
        },
        computed: {
            attachmentsResource() {
                return Vue.getAngularModule('EventAttachmentsResource');
            },
            allowInviteMember() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                return CurrentUser.has_permission("invite-users-to-join-events");
            },
            dateForApi() {
                return this.dateMoment.format(config.VISUAL_DATE_FORMAT);
            }
        },
        methods: {
            onReminderCreated(periodType) {
                if (!this.membershipStatus.added_to_calendar) {
                    this.$refs?.addToCalendarPanel?.sendJoinRequest(
                        this.periodInfo.event_id, this.dateForApi, periodType === 'exact'
                    );
                }
            },
            updateEvent(eventId, typeData, eventData) {
                const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                $rootScope.show_dimmer();
                CalendarEventsResource.update_advanced_time_schedule(eventId, typeData, eventData)
                    .then(resp => {
                        this.event.event_data = resp.data.event_data;    // ???
                        notifications.success('Updated');
                    }, err => {
                        notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            getInitialAdvancedTimeScheduleConfig() {
                let res = {};
                let advancedTimeSchedule = this.event.event_data.advanced_time_schedule;

                for (let key of Object.keys(advancedTimeSchedule)) {
                    res[key] = {
                        start: {
                            hour: nullify(advancedTimeSchedule[key].start.hour),
                            minute: nullify(advancedTimeSchedule[key].start.minute),
                        },
                        end: {
                            hour: nullify(advancedTimeSchedule[key].end.hour),
                            minute: nullify(advancedTimeSchedule[key].end.minute),
                        }
                    };
                }
                return res;
            },
            quickEditSchedule() {
                this.$refs['advanced-time-schedule-popup'].open(this.getInitialAdvancedTimeScheduleConfig())
                    .then(advanced_time_schedule => {
                        return this.$refs['update-repeat-event-popup'].show(this.startDateMoment)
                            .then(choice => { // 'whole' or 'since' or 'exact'
                                this.updateEvent(this.event.id, {
                                    type: choice,
                                    date: this.dateMoment.format(config.VISUAL_DATE_FORMAT)
                                }, {advanced_time_schedule, advanced_time_schedule_enabled: true});
                            });
                    }, () => {
                    })
                    .then(res => {

                    }, () => {
                    });
            },
            showAdditionalPeriodInfo() {
                this.$refs['event-recurrence-info-popup'].show(this.eventId, this.calendarOwner.id, this.dateMoment);
            },
            addOwnerToEvent() {
                this.sendJoinRequest(this.periodInfo.event_id);
            },
            setAttendance(val) {
                this.$emit('setAttendance', val);
            },
            unsetAttendance() {
                this.$emit('unsetAttendance');
            },
            editEventPeriod() {
                const currentDateFormatted = this.dateMoment.format(config.VISUAL_DATE_FORMAT);
                this.$refs['edit-event-period-popup'].show(currentDateFormatted, this.$parent.event.period_data, this.$parent.periodInfo)
                    .then(recurrenceData => {
                        recurrenceData['current_date'] = currentDateFormatted;
                        eventsBus.$emit(EVENTS_BUS_EVENTS.UPDATE_EVENT_RECURRENCE_DATA, recurrenceData);
                    }, () => {
                    });
            },
            onCommentSent() {
            },
            informEntriesPreviewThatLastEntryWasPossiblyChanged() {
                this.$refs['event-detail-page-info-entry-list'].onPossiblyChanged();
            },
            createEntry(entryData) {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                const CalendarEventBlogEntriesResource = Vue.getAngularModule('CalendarEventBlogEntriesResource');
                $rootScope.show_dimmer();
                CalendarEventBlogEntriesResource.create_entry(this.eventId, this.calendarOwner.id, this.dateMoment.format(config.VISUAL_DATE_FORMAT), entryData)
                    .then(resp => {
                        notifications.success('Created');
                        this.informEntriesPreviewThatLastEntryWasPossiblyChanged();
                    }, err => {
                        notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);

            },
            showCreateEntryPopup() {
                if (!this.membershipStatus.can_create_blog_entry) {
                    return;
                }
                this.$refs['create-entry-popup'].showSilent()
                    .then(
                        (entry_data) => {
                            this.createEntry(Object.assign({}, entry_data, {
                                date: this.dateMoment.format(config.URL_DATE_FORMAT)  // just to reuse serializer/ same as in from diary
                            }));
                        },
                        () => {
                        }
                    );
            },
            loadGoingMembers() {
                const notifications = Vue.getAngularModule('notifications');
                const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
                CalendarEventsResource.get_going_members(this.eventId, this.calendarOwner.id, this.dateMoment.format(config.VISUAL_DATE_FORMAT))
                    .then(resp => {
                        this.goingMembers = resp.data.users;
                    }, err => {
                        notifications.error(err || 'Error');
                    });
            },

            inviteMember() {
                eventsBus.$emit(EVENTS_BUS_EVENTS.INVITE_MEMBER);
            }
        },
        mounted() {
            this.loadAttachments();
            this.loadGoingMembers();
        },
        beforeDestroy() {

        },
        watch: {
            periodInfo(n) {
                console.log('periodInfo', n);
            }
        }
    };
</script>

<style scoped lang="scss" src="./EventDetailPageStyle.scss"></style>