import {createTags, createTag} from '@johmun/vue-tags-input';
import Vue from 'vue';

export default {
    props: {
        tagsInProject: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            tag: '',
            tags: []
        };
    },
    computed: {
        autocompleteItems() {
            return this.tagsInProject.filter(t => t.indexOf(this.tag) === 0).map(t => ({text: t}));
        },
    },
    methods: {
        tagsChanged(tags) {
            this.tags = tags;
            this.task.tags = tags.map(t => t.text);
        },
        onAddTag({taskId, tagName}) {
            if (this.syncEnabled) {
                if (this.task.children && this.task.children.length) {
                    for (let child of this.task.children) {
                        if (this.syncedChildrenIdList.includes(child.id)) {
                            if (!child.tags.includes(tagName)) {
                                child.tags.push(tagName);
                            }
                        }
                    }
                }
            }
            const GoalTaskTagsResource = Vue.getAngularModule('GoalTaskTagsResource');
            const notifications = Vue.getAngularModule('notifications');
            GoalTaskTagsResource.add_tag(taskId, tagName, this.syncedChildrenIdList)
                .then(resp => {
                    notifications.success('Added');
                }, err => {
                    notifications.error(err || 'Error');
                });
        },
        onDeleteTag({taskId, tagName}) {
            if (this.syncEnabled) {
                if (this.task.children && this.task.children.length) {
                    for (let child of this.task.children) {
                        if (this.syncedChildrenIdList.includes(child.id)) {
                            if (child.tags.includes(tagName)) {
                                child.tags = child.tags.filter(t => t !== tagName);
                            }
                        }
                    }
                }
            }
            const GoalTaskTagsResource = Vue.getAngularModule('GoalTaskTagsResource');
            const notifications = Vue.getAngularModule('notifications');
            GoalTaskTagsResource.delete_tag(taskId, tagName, this.syncedChildrenIdList)
                .then(resp => {
                    notifications.success('Deleted');
                }, err => {
                    notifications.error(err || 'Error');
                });
        },
        beforeAddingTag({tag, addTag}) {
            if (this.task.tags.length >= 20) {
                Vue.notifications.error('You can\'t add more tags.');
                return;
            }
            if (this.task.tags.indexOf(tag.text) !== -1) {
                Vue.notifications.error('You can\'t add same tag twice.');
                return;
            }
            this.onAddTag({taskId: this.task.id, tagName: tag.text});
            addTag(tag);

        },
        beforeDeletingTag({index, tag, deleteTag}) {
            this.onDeleteTag({taskId: this.task.id, tagName: tag.text});
            deleteTag(tag);
        }
    },
    watch: {
        editMode(n, o) {
            if (n) {
                this.tags = createTags(this.task.tags);
            }
        },
        'task.tags': function (n, o) {
            this.tags = createTags(this.task.tags);
        }
    },
    beforeMount() {
        this.tags = createTags(this.task.tags);
    },
};