<template>
    <basic-modal
            @hide="close"
            v-model="opened"
            :aria-label=header
            role="dialog">
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ header }}</h3>
        </div>
        <symbol-password-form
                v-model="inputedSymbols"
                :max-symbols-count="maxSymbolsCount"
        ></symbol-password-form>
        <div slot="footer">
            <div class="col-xs-6 text-left">
                <button type="button"
                        :disabled="resetButtonDisabled"
                        @click="reset"
                        class="btn btn-mm btn-default">Reset
                </button>
            </div>
            <div class="col-xs-6 text-right">
                <button type="button" :disabled="okButtonDisabled" @click="ok"
                        class="btn btn-mm btn-green">OK
                </button>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import BasicModal from "../../../../vue/common_components/BasicModal";
    import PopupPromiseMixin from "../../../../vue/mixins/PopupPromiseMixin";
    import SymbolPasswordForm from "shared/picture_password/symbolPasswordForm.vue";

    export default {
        name: "symbolPasswordPopup",
        mixins: [PopupPromiseMixin],
        components: {SymbolPasswordForm, BasicModal},
        data() {
            return {
                header: 'Input password',
                maxSymbolsCount: 12,
                inputedSymbols: [],
            };
        },
        computed: {
            okButtonDisabled() {
                return this.inputedSymbols.length < 3;
            },
            resetButtonDisabled() {
                return !this.inputedSymbols.length;
            },
        },
        methods: {
            setInitial(config = {
                header: 'Input password',
                maxSymbolsCount: 12,
            }) {
                if (config.header !== undefined) {
                    this.header = config.header;
                }
                if (config.maxSymbolsCount !== undefined) {
                    this.maxSymbolsCount = config.maxSymbolsCount;
                }
            },
            ok() {
                this.save(this.inputedSymbols);
            },
            reset() {
                this.inputedSymbols = [];
            },
        }

    };
</script>

<style scoped>

</style>