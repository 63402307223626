import {DjangoUrls} from 'DjangoUrls';
import axios from "axios";

const PREFIX = "api.v1:wiki_logs-";

const wiki_logs_resource = {get_logs};

function get_logs(wikiId, params) {
    const url = DjangoUrls[`${PREFIX}get-logs`](wikiId);
    return axios({
        method: 'get',
        url,
        params,
    });
}

export default wiki_logs_resource;