<template>
    <div class="vue-inline-map">
        <span class="align-helper"></span>
        <img id="inline-render-media-image"
             v-show="imageLoaded"
             ref="mapImage"
             :class="{'full-size':fullSize}"
             class="img-responsive inline_image_container center-block"
             :style="[maxImageHeightStyle]"
             :src="staticMapHref"
             :alt="item.location_point.address"
        >
        <span class="vertical-middle" style="font-size: 18px;" v-show="!imageLoaded"
              aria-hidden="true">loading...</span>
        <vue-folder-button icon-class="fa-route" color="blue" :target="mapHref"
                           class="vue-inline-map__router-button"></vue-folder-button>
    </div>
</template>

<script>
    export default {
        name: 'VueInlineMediaMap.vue',
        props: {
            item: {
                type: Object,
                required: true,
            },
            fullSize: {
                type: Boolean,
                default: false
            },
            maxHeight: {
                type: String,
                required: false
            }
        },
        data () {
            return {
                imageLoaded: false
            };
        },
        computed: {
            mapHref () {
                const location = this.item.location_point;
                return 'https://www.google.com/maps/place/' + location.lat + ',' + location.lng + '/@' + location.lat + ',' + location.lng + ',' + location.zoom + 'z';
            },
            staticMapHref () {
                const location = this.item.location_point;
                const url = 'https://maps.googleapis.com/maps/api/staticmap?';
                const params = {
                    center: location.lat + ',' + location.lng,
                    zoom: location.zoom,
                    size: '640x600',
                    format: 'JPEG',
                    language: 'en',
                    markers: 'color:red|' + location.lat + ',' + location.lng
                };
                if (GOOGLE_API_KEY) {  // defined in webpack.config
                    params.key = GOOGLE_API_KEY;
                }
                const serializedParams = Object.keys(params).map(function (key) {
                    return key + '=' + encodeURIComponent(params[key]);
                }).join('&');
                return url + serializedParams;
            },
            maxImageHeightStyle () {
                if (this.maxHeight) {
                    return { 'max-height': this.maxHeight };
                }
            },
        },
        mounted () {
            this.$refs.mapImage.addEventListener('load', () => {
                this.imageLoaded = true;
            });
        },
    };
</script>

<style scoped lang="scss">
  .vue-inline-map {
    font-size: 0;
    max-height: inherit;

    &__router-button {
      position: absolute;
      right: 3%;
      top: 3%;
    }
  }

  img {
    max-height: inherit;
    width: auto;

    &.full-size {
      max-height: 100%;
    }

    @media print {
      max-height: 400px !important;
      &.full-size {
        max-height: 100% !important;
      }
    }


    @media (max-width: 1000px) {
      max-width: 100%;
    }
    @media (min-width: 1001px) {
      max-width: 80%;
      &.full-size {
        max-width: 100%;
      }
    }

  }

  .inline_image_container {
    vertical-align: middle;
    display: inline-block;
  }
</style>
