<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="actualConfig.header"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ actualConfig.header }}</h3>
        </div>

        <div class="espc" v-hotkey="keymap">

            <div class="row margin-5-bottom" v-if="actualConfig.info&&(allow_all||actualConfig.long_text)">
                <div class="col-sm-12">{{ actualConfig.info }}</div>
            </div>

            <div class="row" style="margin-bottom: 10px;">
                <div class="col-sm-7">
                    <div class="row" v-if="actualConfig.info&&(!allow_all&&!actualConfig.long_text)">
                        <div class="col-sm-12">{{ actualConfig.info }}</div>
                    </div>
                    <div class="row espc__additional-filter"
                         v-for="additionalFilter in additionalFiltersLeftSlot">
                        <div class="col-sm-12">
                            <select style="height: 40px"
                                    v-model="additionalFilter.value"
                                    class="form-control vertical-middle"
                                    @change="onAdditionalFilterChange(additionalFilter)">
                                <option :value="option.value" v-for="option in additionalFilter.options">
                                    {{ option.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <vue-switch
                            v-if="allow_all"
                            v-model="allChecked"
                            ref="selectAllSwitch"
                            name="all"
                    ></vue-switch>
                    <span class="after-switch-text" v-if="allow_all" v-html="actualConfig.select_all_html"></span>
                </div>
                <div class="col-sm-5 text-right" v-if="!actualConfig.disable_search">
                    <vue-search-input
                            @search="search"
                            @cancel="cancelSearch"
                            :min-letters-count="2"
                    ></vue-search-input>
                </div>
            </div>
            <div class="row" v-for="(additionalFilter, i) in additionalFiltersDefaultSlot"
                 style="margin-bottom: 5px">
                <div class="col-sm-12">
                    <component
                            v-if="additionalFilter.component"
                            :is="additionalFilter.component"
                            v-bind="additionalFilter.componentAttrs"
                            @onCustomFilterChanged="onCustomFilterChanged"
                    ></component>
                    <div v-else>
                        <div>
                            <vue-switch
                                    :aria-label="additionalFilter.text"
                                    :name="`af-${i}`"
                                    :disabled="isFilterDisabled(additionalFilter)"
                                    tabindex="0"
                                    @change="onAdditionalFilterChange(additionalFilter)"
                                    v-model="additionalFilter.value"
                                    class="vertical-middle"
                            ></vue-switch>
                            <span class="vertical-middle"
                                  :class="{'color-tag-gray':isFilterDisabled(additionalFilter)}">{{
                                    additionalFilter.text
                                }}</span>
                        </div>
                        <div v-if="additionalFilter.disabledMessage&&isFilterDisabled(additionalFilter)"
                             class="alert alert-warning margin-15-top">
                            {{ additionalFilter.disabledMessage }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="espc__items-list">
                        <span v-if="searchExecuting" class="espc__empty-list-holder">Loading<typing-dots></typing-dots></span>
                        <span class="espc__empty-list-holder"
                              v-if="!itemsForSelect.length&&!searchExecuting">{{ emptyMessage }}</span>
                        <div v-if="!searchExecuting" class="espc__items-list-inner" aria-live="polite">
                            <endpoint-selector-popup-item
                                    v-for="item in itemsForSelect"
                                    :key="item.id"

                                    :aria-label="item.name"
                                    :aria-checked="isItemChecked(item)"
                                    role="checkbox"
                                    ref="item"
                                    :item="item"
                                    :checked="isItemChecked(item)"
                                    :unconfirmed="isItemUnconfirmed(item)"
                                    :no-border-radius-on-default-avatar="actualConfig.no_border_radius_on_default_avatar"
                                    @click.native="toggleCheck(item)"
                                    @keydown.enter.native="toggleCheckByEnter(item)"
                            ></endpoint-selector-popup-item>
                            <div class="espc__pseudo-item" v-for="item of pseudoItems"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 text-right">
                    <pagination
                            role="pagination"
                            class="espc__pagination"
                            v-show="totalPage>1"
                            v-model="currentPage"
                            :total-page="totalPage"
                            :max-size="3"
                            @change="onPageChanged"
                    />
                </div>
            </div>
        </div>

        <div slot="footer">
            <div class="row">
                <div class="col-sm-12">
                    <button
                            v-for="button in actualConfig.additional_buttons"
                            :class="button.classes"
                            style="margin: 5px 0 5px 5px"
                            @click="additional_button_pressed(button.result_code)"
                    >{{ button.text }}
                    </button>

                    <button
                            class="btn btn-mm  btn-primary"
                            type="button"
                            style="margin: 5px 0 5px 5px"
                            @click="back"
                    >{{ actualConfig.button_no }}
                    </button>

                    <button
                            ref="saveButton"
                            v-if="actualConfig.button_yes"
                            class="btn btn-mm  btn-green"
                            style="margin: 5px 0 5px 5px"
                            id="esp-save"
                            :disabled="nothingSelected&&!allow_null"
                            type="button" @click="yes">{{ actualConfig.button_yes }}
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <span tabindex="0" aria-hidden="true" class="focus-catch-link" @focus="focusToClose">&nbsp;</span>
                </div>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';
    import BasicModal from '../../../../../vue/common_components/BasicModal';
    import PopupPromiseMixin from '../../../../../vue/mixins/PopupPromiseMixin';
    import EndpointSelectorPopupItem from 'shared/select_factory/endpoint_selector_popup/EndpointSelectorPopupItem';
    import angular from 'angular';
    import ScreenWidthAwareMixin from '../../../../../vue/mixins/ScreenWidthAwareMixin';
    import TypingDots from 'shared/comments/TypingDots';

    const DEFAULT_CONFIG = {
        header: 'Select item',
        info: 'Select or de-select item from list',
        button_yes: 'Select',
        button_no: 'Cancel',
        empty: 'You do not have any items to select',
        select_all_html: 'Share with all My Circle,<br>and new people I add',
        disable_search: false,
        list_mode: false,
        long_text: false,
        precheck_all: false,
        preaprove_all: false,
        no_border_radius_on_default_avatar: false,
        additional_buttons: [], // {text:string, classes:string, result_code}
        additional_filters: [], // {text:string, value:Boolean, type:string[switch], paramsWhenTrue: Object}
    };

    export default {
        name: 'EndpointSelectorPopup',
        components: {
            TypingDots,
            EndpointSelectorPopupItem,
            BasicModal
        },
        mixins: [PopupPromiseMixin, ScreenWidthAwareMixin],
        data () {
            return {
                page_query_endpoint: undefined,
                config: Object.assign({}, DEFAULT_CONFIG),
                allow_multiple: false,
                allow_null: false,
                allow_all: false,
                return_object: false,

                searchExecuting: false,

                items: [],
                checkedItem: undefined,
                newCheckedItems: [],
                newUncheckedItems: [],
                allChecked: false,
                allCheckedBackend: false,
                allApproved: false,

                resultsCount: 0,
                currentPage: 1,
                query: '',
                initialLoadDone: false,

                emptyMessageFromBackend: '',

                customFiltersValues: {},

            };
        },
        computed: {
            keymap () {
                return {
                    'alt+s': this.selectIfPossible,
                    'alt+a': this.toggleAllIfPossible,
                };
            },
            catchFocusMultiAttempts () {
                return false;
            },
            firstFocusEl () {
                return this.$refs.selectAllSwitch || this.$refs.closeButton;
            },
            totalPage () {
                return Math.ceil(this.resultsCount / this.itemsPerPage);
            },
            itemsPerPage () {
                if (this.windowWidth > 580) {
                    return 10;
                } else if (this.windowWidth > 470) {
                    return 8;
                } else if (this.windowWidth > 370) {
                    return 6;
                }
                return 4;
            },
            pseudoItems () {
                if (this.searchExecuting || !this.items.length) {
                    return [];
                }
                const res = [];
                const pseudoItemsCount = this.itemsPerPage - this.items.length;
                for (let i = 0; i < pseudoItemsCount; i++) {
                    res.push(i);
                }
                return res;
            },
            actualConfig () {
                return Object.assign({}, DEFAULT_CONFIG, this.config);
            },
            nothingSelected () {
                if (this.allow_multiple) {
                    if (this.allChecked) {
                        return false;
                    }
                    return this.newCheckedItems.length === 0 && this.newUncheckedItems.length === 0;
                } else {
                    return !this.checkedItem;
                }
            },
            additionalFiltersLeftSlot () {
                return this.actualConfig.additional_filters.filter(f => (f.slot === 'left-from-search'));
            },
            additionalFiltersDefaultSlot () {
                return this.actualConfig.additional_filters.filter(f => (!f.slot || f.slot === 'default'));
            },
            itemsForSelect () {
                return this.items;
            },
            emptyMessage () {
                return this.emptyMessageFromBackend || this.actualConfig.empty || 'You do not have any items to select';
            },
            allLoadedItemsIdList () {
                return this.allLoadedItems.map(i => i.id);
            },
            initiallyCheckedItemsIdList () {
                return this.initiallyCheckedItems.map(i => i.id);
            },
            newCheckedItemsIdList () {
                return this.newCheckedItems.map(i => i.id);
            },
            newUncheckedItemsIdList () {
                return this.newUncheckedItems.map(i => i.id);
            },
            additionalFiltersValues () {
                const res = {};
                for (let additionalFilter of this.actualConfig.additional_filters) {
                    if (!this.isFilterDisabled(additionalFilter)) {
                        res[additionalFilter.name] = additionalFilter.value;
                    }
                }
                return res;
            },
            checkResult () {
                if (!this.allow_multiple) {
                    if (this.return_object) {
                        return this.checkedItem || null;
                    } else {
                        return this.checkedItem?.id || null;
                    }
                } else {
                    if (!this.return_object) {
                        return [
                            this.newCheckedItemsIdList,
                            this.newUncheckedItemsIdList,
                            this.allChecked,
                            this.additionalFiltersValues
                        ];
                    } else {
                        return [
                            this.newCheckedItems,
                            this.newUncheckedItems,
                            this.allChecked,
                            this.additionalFiltersValues
                        ];
                    }
                }
            },
        },
        methods: {
            selectIfPossible () {
                if (!(this.nothingSelected && !this.allow_null)) {
                    this.yes();
                }
            },
            toggleAllIfPossible () {
                if (this.allow_all) {
                    this.allChecked = !this.allChecked;
                }
            },
            toggleCheck (item) {
                if (this.actualConfig.list_mode) { // not really check. just open. see list of likes
                    let openInNewTab = (url) => {
                        let win = window.open(url, '_blank');
                        win?.focus();
                    };
                    openInNewTab(item.link);
                    return;
                }
                if (this.allow_all && this.allChecked) {
                    return; // when all checked - no need to do anything
                }

                if (!this.allow_multiple) {
                    if (this.checkedItem?.id === item.id) {
                        this.checkedItem = undefined;
                    } else {
                        this.checkedItem = item;
                    }
                } else {
                    if (item.checked) {
                        if (this.newUncheckedItemsIdList.includes(item.id)) {
                            this.newUncheckedItems = this.newUncheckedItems.filter(i => i.id !== item.id);
                        } else {
                            this.newUncheckedItems.push(item);
                        }
                    } else {
                        if (this.newCheckedItemsIdList.includes(item.id)) {
                            this.newCheckedItems = this.newCheckedItems.filter(i => i.id !== item.id);
                        } else {
                            this.newCheckedItems.push(item);
                        }
                    }
                }
            },
            toggleCheckByEnter (item) {
                this.toggleCheck(item);
                if (!this.allow_multiple) {
                    this.$nextTick(() => {
                        this.$refs.saveButton?.focus();
                    });
                }
            },
            isItemChecked (item) {
                if (this.allow_multiple) {
                    if (this.allChecked) {
                        return true;
                    }
                    if (item.checked) {
                        return !this.newUncheckedItemsIdList.includes(item.id);
                    } else {
                        return this.newCheckedItemsIdList.includes(item.id);
                    }
                } else {
                    return item.id === this.checkedItem?.id;
                }
            },
            isItemUnconfirmed (item) {
                if (this.isItemChecked(item)) {
                    if (this.allChecked) {
                        if (this.allApproved) {
                            return false;
                        } else {
                            return item.unapproved || !item.checked;
                        }
                    } else {
                        return item.unapproved;
                    }
                }
            },
            setInitial (page_query_endpoint, allow_multiple, config, allow_null, allow_all, return_object) {
                this.page_query_endpoint = page_query_endpoint;
                this.allow_multiple = allow_multiple;
                this.config = config;
                this.allow_null = allow_null;
                this.allow_all = allow_all;
                this.return_object = return_object;
            },
            additional_button_pressed (result_code) {
                this.save(result_code);
            },
            yes () {
                this.save(this.checkResult);
            },
            back () {
                this.close('back');
            },
            resetChecked () {
                this.allChecked = false;
                this.allApproved = false;
                this.checkedItem = undefined;
                this.newCheckedItems = [];
                this.newUncheckedItems = [];
            },
            $_loadItems () {
                const $http = Vue.getAngularModule('$http');

                this.searchExecuting = true;
                let endpointPromise;
                const queryParams = {
                    page: this.currentPage,
                    query: this.query,
                    ipp: this.itemsPerPage,
                    ...this.customFiltersValues,
                };
                for (let additionalFilter of this.actualConfig.additional_filters) {
                    if (additionalFilter.value) {
                        if (_.isObject(additionalFilter.paramsWhenTrue)) {
                            Object.assign(queryParams, additionalFilter.paramsWhenTrue);
                        }
                        if (_.isString(additionalFilter.paramsWhenTrue)) {
                            queryParams[additionalFilter.paramsWhenTrue] = additionalFilter.value;
                        }
                    }
                }
                if (_.isString(this.page_query_endpoint)) {
                    endpointPromise = $http({
                        url: this.page_query_endpoint,
                        method: 'GET',
                        params: queryParams
                    });
                } else {
                    if (_.isFunction(this.page_query_endpoint)) {
                        endpointPromise = this.page_query_endpoint(queryParams);
                    } else {
                        console.error('Error 100. Wrong query endpoint.');
                    }
                }
                endpointPromise
                  .then(resp => {
                        if (this.allow_all && (resp.data.all_selected !== undefined) && (resp.data.all_selected !== null)) {
                            if (!this.initialLoadDone) {
                                this.allChecked = resp.data.all_selected;
                            }
                            this.allCheckedBackend = resp.data.all_selected;
                            this.allApproved = resp.data.all_approved;
                        }
                        this.items = resp.data.results;
                        this.emptyMessageFromBackend = resp.data.empty_message;
                        this.resultsCount = resp.data.count;
                        if (!this.initialLoadDone) {
                            this.initialLoadDone = true;
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    if (this.$refs.item?.length) {
                                        this.$_catchFocus(this.$refs.item[0].$el);
                                    } else {
                                        this.$_catchFocus(this.firstFocusEl);
                                    }
                                }, 100);
                            });
                        }
                    },
                    err => {
                        Vue.notifications.error(err || 'Error');
                    })
                  .finally(() => {
                      this.searchExecuting = false;
                  });
            },

            onAdditionalFilterChange (filter) {
                if (!filter.filter) {
                    return;
                }
                this.resetChecked();
                this.currentPage = 1;
                this.$_loadItems();
            },

            onCustomFilterChanged ({
                name,
                value,
                reloadRequired,
            }) {
                this.customFiltersValues[name] = value;
                if (reloadRequired) {
                    this.resetChecked();
                    this.currentPage = 1;
                    this.$_loadItems();
                }
            },

            onPageChanged () {
                this.$_loadItems();
            },

            search (query) {
                this.currentPage = 1;
                this.query = query;
                this.$_loadItems();
            },
            cancelSearch () {
                this.currentPage = 1;
                this.query = undefined;
                this.$_loadItems();
            },
            isFilterDisabled (filter) {
                if (filter.disabled) {
                    return true;
                }
                if (!filter.required) {
                    return false;
                }
                for (let requeiredFilter of filter.required) {
                    for (let additionalFilter of this.actualConfig.additional_filters) {
                        if (additionalFilter.name === requeiredFilter && !additionalFilter.value) {
                            return true;
                        }
                    }
                }
                return false;
            },
            showedHook () {
                this.$_loadItems();
            },

        }
    };
</script>

<style scoped lang="scss">
  @import "../../../styles/const";
  @import "../../../styles/mixins";

  .espc {

    &__additional-filter {
      margin-bottom: 10px;
      @media all and(max-width: $screen-xs-max) {
        margin-bottom: 10px;
      }
    }

    &__items-list {
      display: inline-block;
      width: 100%;
      height: 335px;
      background-color: rgba(21, 198, 249, 0.2);
      border-radius: 5px;
      overflow: auto;
      padding: 3px;
    }

    &__items-list-inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: flex-start;
      flex-direction: row;
    }

    &__empty-list-holder {
      font-size: 16px;
      color: $tag-gray-dark;
      display: inline-block;

      text-align: center;
      width: 100%;
      margin-top: 130px;
    }

    &__pseudo-item {
      display: inline-block;
      width: 96px;
      height: 160px;
    }

    &__pagination {
      &::v-deep {
        > ul {
          margin: 5px 0;
        }
      }
    }
  }


</style>
