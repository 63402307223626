/**
 * Created by orion on 05.06.17.
 */
import './style.scss';

ShareWhereListPopupController.$inject = ['$scope', '$uibModalInstance', 'sharing_status', 'additional_messages', 'behalf_of_user', 'allow_share_with_communities'];

function ShareWhereListPopupController($scope, $uibModalInstance, sharing_status, additional_messages, behalf_of_user, allow_share_with_communities) {
    $scope.close = function () {
        $uibModalInstance.dismiss();
    };
    $scope.select = function (select) { // private / friends / groups / public
        $uibModalInstance.close({select}); // share paremetr now required only for story
    };

    $scope.additional_messages = additional_messages;

    $scope.sharing_status = sharing_status;
    $scope.allow_share_with_communities = allow_share_with_communities;
    $scope.behalf_of_user = behalf_of_user;
}

export default ShareWhereListPopupController;