<template>
    <div>
        <div v-for="line in lines" :key="line.key" :style="line.style"></div>
    </div>
</template>

<script>
    const DEFAULT_BORDER_STYLE = '3px dotted #909090';
    import Vue from 'vue';

    function getStyle(coordsToDraw, borderStyle, zIndex, opacity) {
        return {
            position: 'absolute',
            top: `${coordsToDraw.y}px`,
            left: `${coordsToDraw.x}px`,
            transform: `rotate(${coordsToDraw.rotateRadian}rad)`,
            'border-top': borderStyle,
            width: `${coordsToDraw.len}px`,
            'z-index': zIndex,
            'transition': 'opacity ease 0.2s',
            'opacity': opacity,
        };
    }

    export default {
        data() {
            return {
                lines: [],
                borderStyle: DEFAULT_BORDER_STYLE,
                zIndex: 0,
                opacity: 1,
            };
        },
        methods: {
            drawLine(x1, y1, x2, y2, key = Math.random()) {
                const catLength = Math.max(y1, y2) - Math.min(y1, y2);
                const baseLength = Math.max(x1, x2) - Math.min(x1, x2);
                const hypLength = Math.sqrt(Math.pow(baseLength, 2) + Math.pow(catLength, 2));
                const sinA = catLength / hypLength;
                const rotateRadian = Math.asin(sinA);
                const coordsToDraw = {};
                coordsToDraw.x = Math.min(x1, x2) - (hypLength - baseLength) / 2;
                coordsToDraw.y = y2 >= y1 ? y1 + catLength / 2 : y1 - catLength / 2;
                coordsToDraw.len = parseInt(hypLength);
                coordsToDraw.rotateRadian = y1 <= y2 ? rotateRadian : -rotateRadian;
                this.lines.push({key, style: getStyle(coordsToDraw, this.borderStyle, this.zIndex, this.opacity)});
            },
            clearLines() {
                this.lines = [];
            },
            setBorderStyle(style) {
                this.borderStyle = style;
            },
            setZIndex(zIndex) {
                this.zIndex = zIndex;
            },
            setOpacity(opacity) {
                this.opacity = opacity;
                for (let i in this.lines) {
                    Vue.set(this.lines[i].style, 'opacity', this.opacity);
                }
            }
        },
        name: "line-drawer"
    };
</script>

<style scoped>

</style>