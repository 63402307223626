/**
 * Created by orion on 06.04.17.
 */
import urls from 'DjangoUrls';
let {DjangoUrls} = urls;

BlogEntryItemResource.$inject = ['$http', '$log'];
function BlogEntryItemResource($http, $log) {
    return {
        del,
        update
    };


    function del(item_id) {
        const url = DjangoUrls["api.v1:blog_entry_item-detail"](item_id);
        return $http({
            method: 'DELETE',
            url: url
        })
    }

    function update(item_id, data) {
        const url = DjangoUrls["api.v1:blog_entry_item-detail"](item_id);
        return $http({
            method: 'PATCH',
            url: url,
            data
        })
    }

}

export default BlogEntryItemResource