var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wiki-tree-slide" }, [
    _c("i", {
      staticClass: "fa fa-eye",
      class: _vm.viewButtonClasses,
      on: { click: _vm.toggleSlideLevelViewPermission },
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "wiki-tree-slide__slide-avatar", style: _vm.avatarStyle },
      [
        !_vm.avatarUrl
          ? _c("i", { staticClass: "fa", class: _vm.holderIconClass })
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "wiki-tree-slide__title" }, [
      _c("a", { attrs: { href: _vm.slide.link, target: "_blank" } }, [
        _c("strong", [_vm._v(_vm._s(_vm._f("cutName")(_vm.slideName, 30)))]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "wiki-tree-slide__attachments" },
      [
        _vm._l(_vm.slide.attached_documents, function (attached_document) {
          return _c("wiki-tree-section-slide-attached-document", {
            key: attached_document.id,
            attrs: {
              permissionsList: _vm.permissionsList,
              inheritedPermissionLevel: _vm.resultPermissionLevel,
              attachedDocument: attached_document,
            },
          })
        }),
        _vm._v(" "),
        _vm._l(_vm.slide.attached_links, function (attached_link) {
          return _c("wiki-tree-section-slide-attached-link", {
            key: attached_link.id,
            attrs: {
              permissionsList: _vm.permissionsList,
              inheritedPermissionLevel: _vm.resultPermissionLevel,
              link: attached_link,
            },
          })
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }