<template>
    <router-link :to="target" v-bind="$attrs" v-on="$listeners">
        <slot></slot>
    </router-link>
</template>

<script>
    export default {
        name: "FolderButtonRouterLink",
        props: {
            target: [String, Object],
        }
    };
</script>

<style scoped>

</style>