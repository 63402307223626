let _ = require('lodash');
// import createNewGoalPopupController from './goal_task_options_popup'
import urls from 'DjangoUrls';

let {DjangoUrls} = urls;

import GroupMainOptionsPopupController from './options_popup/controller'

createEditGroupFactory.$inject = ['simplePopupFactory', '$q', 'addMedia', '$log'];

function createEditGroupFactory(simplePopupFactory, $q, addMedia, $log) {

    let DEFAULT_GROUP_DATA = {
        name: '',
        description: '',
        media_for_avatar: undefined,
        avatar: '',
        veto_conversation_posts: false,
        veto_joins: true,
        veto_new_conversations: false,
        veto_activity_posts: false,
        veto_share: false,
        auto_avatar: true,
        is_private: false,
    };

    function show_add_media_menu(default_callback, cancel_callback) {
        addMedia.add_media(
            {
                allow_add_from_library: true,
                max_count: 1,
                type: 'image',
                popup_header: 'What sort of media would you like to add?'
            },
            {
                default_callback,
                cancel_callback
            }
        )
    }

    function main_group_options(initial, popup_config = {}, promise_to_resolve, is_circle_group = false) {
        /**
         * @param initial {Object}
         * {name:string,
         * description: string,
         * avatar: string?,
         * media_for_avatar: MediaContent,
         * veto_conversation_posts: bool
         * veto_joins: bool
         * veto_new_conversations: bool
         * veto_activity_posts: bool
         * auto_avatar: bool
         * is_private: bool
         * }
         * @return {Object} as above but filled\changed
         */
        let DEFAULT_POPUP_CONFIG = {
            header: 'Create a Group',
            save_button: 'Create',
            cancel_button: 'Cancel'
        };
        let actual_popup_config = _.merge({}, DEFAULT_POPUP_CONFIG, popup_config);
        let initial_data = _.merge({}, DEFAULT_GROUP_DATA, initial);
        let options_result = promise_to_resolve === undefined ? $q.defer() : promise_to_resolve;
        $log.debug(options_result);
        let popup_result = simplePopupFactory.create_popup(GroupMainOptionsPopupController, require('./options_popup/template.html'), {
            initial_data,
            popup_config: actual_popup_config,
            is_circle_group
        });

        let add_media_items = (items_list) => {
            initial_data.media_for_avatar = items_list[0];
            main_group_options(initial_data, popup_config, options_result, is_circle_group);
        };


        popup_result.then(res => {
            if (res.type === 'add_media') {
                initial_data = res.data;
                show_add_media_menu(add_media_items, () => {
                    main_group_options(initial_data, popup_config, options_result, is_circle_group)
                });
            }
            if (res.type === 'save') {
                let data_to_resolve = res.data;
                if (!(res.data.avatar || res.data.media_for_avatar)) {
                    data_to_resolve.reset_avatar = true;
                }
                if (res.data.media_for_avatar) {
                    data_to_resolve.media_for_avatar_id = res.data.media_for_avatar.pk;
                }
                options_result.resolve(data_to_resolve);
            }
        });

        return options_result.promise;
    }

    return {main_group_options}
}


export default createEditGroupFactory