/**
 * Created by orion on 6/24/17.
 */
import urls from 'DjangoUrls';
import _ from 'lodash';

let {DjangoUrls} = urls;
CalendarEventsResource.$inject = ['$http'];
const PREFIX = "api.v1:calendar_events-";

function CalendarEventsResource($http) {
    return {
        create_calendar_event,
        get_event,
        get_event_period_info,
        join_event,
        remove_from_calendar,
        remove_member,
        delete_event,
        update_event_data,
        update_advanced_time_schedule,
        update_event_recurrence,
        get_event_members,
        load_more_event_members,
        get_event_recurrence_info,
        set_attendance,
        unset_attendance,
        find_nearest_event_date,
        check_access_and_join_ability,
        get_users_for_invite,
        invite_users,
        get_going_members,
        get_event_occurrences_in_month,

        get_groups_to_create_event,
        get_groups_to_create_circle_group_event,
        get_communities_to_create_event,
    };

    function create_calendar_event(targetData, eventData) {
        const url = DjangoUrls[PREFIX + "create-calendar-event"]();
        return $http({
            method: 'POST',
            url: url,
            data: _.merge({}, targetData, eventData)
        });
    }


    function find_nearest_event_date(eventId) {
        const url = DjangoUrls[PREFIX + "find-nearest-event-date"](eventId);
        return $http({
            method: 'GET',
            url: url,
        });
    }

    function get_event(eventId, date, calendarOwnerId) {
        const url = DjangoUrls[PREFIX + "get-event"](eventId);
        return $http({
            method: 'GET',
            url: url,
            params: {
                date,
                id: calendarOwnerId
            }
        });
    }

    function get_event_period_info(id, date) {
        const url = DjangoUrls[PREFIX + "get-event-period-info"](id);
        return $http({
            method: 'GET',
            url: url,
            params: {date}
        });
    }


    function join_event(id, calendar_owner_id, date, only_one_date = false) {
        const url = DjangoUrls[PREFIX + "join-event"](id);
        return $http({
            method: 'POST',
            url: url,
            data: {
                date,
                only_one_date,
                calendar_owner_id,
            }
        });
    }

    function remove_from_calendar(id, calendar_owner_id, date, only_one_date = false) {
        const url = DjangoUrls[PREFIX + "remove-from-calendar"](id);
        return $http({
            method: 'POST',
            url: url,
            data: {
                date,
                only_one_date,
                calendar_owner_id,
            }
        });
    }

    function remove_member(id, memberId, date, only_one_date = false) {
        const url = DjangoUrls[PREFIX + "remove-member"](id);
        return $http({
            method: 'POST',
            url: url,
            data: {
                date,
                only_one_date,
                id: memberId,
            }
        });
    }

    function delete_event(id, data) {
        const url = DjangoUrls[PREFIX + "delete-event"](id);
        return $http({
            method: 'POST',
            url: url,
            data
        });
    }

    function update_event_data(id, typeData, eventData) {
        const url = DjangoUrls[PREFIX + "update-event-data"](id);
        return $http({
            method: 'POST',
            url: url,
            data: _.merge({}, typeData, eventData)
        });
    }

    function update_advanced_time_schedule(id, typeData, scheduleData) {
        const url = DjangoUrls[PREFIX + "update-advanced-time-schedule"](id);
        return $http({
            method: 'POST',
            url: url,
            data: _.merge({}, typeData, scheduleData)
        });
    }

    function update_event_recurrence(id, recurrenceData) {
        const url = DjangoUrls[PREFIX + "update-event-recurrence"](id);
        return $http({
            method: 'POST',
            url: url,
            data: recurrenceData
        });
    }

    function get_event_recurrence_info(eventId, calendar_owner_id, event_date) {
        const url = DjangoUrls[PREFIX + "get-event-recurrence-info"](eventId);
        return $http({
            method: 'GET',
            url: url,
            params: {
                event_date,
                calendar_owner_id
            }
        });
    }

    function get_event_members(id, date) {
        const url = DjangoUrls[PREFIX + "get-event-members"](id);
        return $http({
            method: 'GET',
            url: url,
            params: {
                date
            }
        });
    }

    function load_more_event_members(id, date, limit, offset, group) {
        const url = DjangoUrls[PREFIX + "load-more-event-members"](id);
        return $http({
            method: 'GET',
            url: url,
            params: {
                date,
                limit,
                offset,
                group
            }
        });
    }

    function set_attendance(eventId, date, calendar_owner_id, attendance) {
        const url = DjangoUrls[PREFIX + "set-attendance"](eventId);
        return $http({
            method: 'POST',
            url: url,
            data: {
                date,
                calendar_owner_id,
                attendance
            }
        });
    }

    function unset_attendance(eventId, date, calendar_owner_id) {
        const url = DjangoUrls[PREFIX + "unset-attendance"](eventId);
        return $http({
            method: 'POST',
            url: url,
            data: {
                date,
                calendar_owner_id,
            }
        });
    }

    function check_access_and_join_ability(eventId, date) {
        const url = DjangoUrls[PREFIX + "check-access-and-join-ability"](eventId);
        return $http({
            method: 'GET',
            url: url,
            params: {
                date
            }
        });
    }

    function get_users_for_invite(eventId, date, only_one_date = false, params) {
        const url = DjangoUrls[PREFIX + "get-users-for-invite"](eventId);
        return $http({
            method: 'GET',
            url,
            params: _.merge({date, only_one_date}, params)
        });
    }

    function invite_users(eventId, id_list, date, only_one_date = false) {
        const url = DjangoUrls[PREFIX + "invite-users"](eventId);
        return $http({
            method: 'POST',
            url,
            data: {
                id_list,
                date,
                only_one_date
            }
        });
    }

    function get_going_members(eventId, calendarOwnerId, date) {
        const url = DjangoUrls[PREFIX + "get-going-members"](eventId);
        return $http({
            method: 'GET',
            url,
            params: {
                date,
                calendar_owner_id: calendarOwnerId,
            }
        });
    }

    function get_event_occurrences_in_month(eventId, calendarOwnerId, eventDate, dateInRequestedMonth) {
        const url = DjangoUrls[PREFIX + "get-event-occurrences-in-month"](eventId);
        return $http({
            method: 'GET',
            url,
            params: {
                date: dateInRequestedMonth,
                event_date: eventDate,
                calendar_owner_id: calendarOwnerId,
            }
        });
    }

    function get_groups_to_create_event(params) {
        const url = DjangoUrls[PREFIX + "get-groups-to-create-event"]();
        return $http({
            method: 'GET',
            url,
            params
        });
    }

    function get_groups_to_create_circle_group_event(circleOwnerId, params) {
        const url = DjangoUrls[PREFIX + "get-groups-to-create-circle-group-event"]();
        return $http({
            method: 'GET',
            url,
            params: Object.assign({id: circleOwnerId}, params)
        });
    }

    function get_communities_to_create_event(params) {
        const url = DjangoUrls[PREFIX + "get-communities-to-create-event"]();
        return $http({
            method: 'GET',
            url,
            params
        });
    }


}

export default CalendarEventsResource;