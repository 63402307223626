<template>
    <div class="chat-style-comment-reply" :style="commentStyle">
        <div v-if="showAuthor">
            <slot name="header"><strong>{{ authorName }}</strong> <slot name="actionDescription">wrote:</slot></slot>
        </div>
        <div v-if="comment.sticker" style="font-size: 0">
            <render-sticker :sticker="comment.sticker" :default-size="50"></render-sticker>
        </div>
        <div v-if="commentTextPreview" style="white-space: pre-line">{{ commentTextPreview }}</div>
        <div v-if="comment.media_attachment" style="color: #333;">
            <media-preview :item="comment.media_attachment" :size="80"></media-preview>
        </div>
        <div v-if="comment.emotion">
            <entry-emotion :emotion="comment.emotion" :size="30"></entry-emotion>
        </div>
        <div class="chat-style-comment-reply__expand-button" v-if="allowExpand&&!expanded&&expandRequired">
            <a href="javascript:;" @click="showAll"><i class="fal fa-expand-alt"></i> show all</a>
        </div>
    </div>
</template>

<script>
    import {getAuthorNameFromComment} from '../../shared/comments/common';
    import RenderSticker from "../../add_media/stickers/render_sticker_directive/RenderSticker";
    import EntryEmotion from "../../blogolog/emotion_directive/EntryEmotion";

    export default {
        name: "ChatStyleCommentReply",
        components: {EntryEmotion, RenderSticker},
        props: {
            comment: Object,
            noBorder: {
                type: Boolean,
                default: false
            },
            showAuthor: {
                type: Boolean,
                default: true
            },
            whiteBorder: {
                type: Boolean,
                default: false
            },
            cutTextLength: {
                type: Number,
                default: 15,
            },
            allowExpand: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                expanded: false,
            };
        },
        computed: {
            authorName() {
                return getAuthorNameFromComment(this.comment);
            },
            expandRequired() {
                return this.comment.text?.length > this.cutTextLength;
            },
            commentTextPreview() {
                if (this.comment.text) {
                    if (!this.expanded) {
                        return this.$options.filters.cutName(this.comment.text, this.cutTextLength);
                    } else {
                        return this.comment.text;
                    }
                }
            },
            commentStyle() {
                if (this.noBorder) {
                    return {
                        'margin-left': 0,
                    };
                }
                if (this.whiteBorder) {
                    return {
                        'border-left': '3px solid #fff'
                    };
                } else {
                    return {
                        'border-left': '3px solid #333'
                    };
                }
            }
        },
        methods: {
            showAll() {
                this.expanded = true;
            }
        }
    };
</script>

<style scoped lang="scss">
  .chat-style-comment-reply {
    margin-left: 15px;
    padding: 5px;

    &__expand-button {
      font-size: 14px;
      border-top: 1px solid black;
      display: inline-block;
      margin-top: 10px;
    }
  }
</style>
