var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "event-goals-goal" },
    [
      _c(
        "goals-list-item",
        {
          key: _vm.goal.id,
          attrs: {
            goal: _vm.goal.goal,
            progress: _vm.goalProgress,
            "goal-name-is-link":
              _vm.goal.viewer_membership_status.can_create_tasks,
            "allow-archive-goal": false,
          },
        },
        [
          _c(
            "span",
            { attrs: { slot: "buttonsPanel" }, slot: "buttonsPanel" },
            [
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isDeletionAvailable && _vm.editMode,
                    expression: "isDeletionAvailable&&editMode",
                  },
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.editLinkedTasksLabel,
                    expression: "editLinkedTasksLabel",
                  },
                ],
                staticClass: "fa fa-link event-goals-goal__button",
                class: {
                  "deletion-available": _vm.isDeletionAvailable,
                  "edit-available": _vm.isEditModeAvailable,
                },
                on: { click: _vm.editLinkedTasks },
              }),
              _vm._v(" "),
              _c("i", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.editModeTooltip,
                    expression: "editModeTooltip",
                  },
                ],
                staticClass: "fa event-goals-goal__button",
                class: {
                  "fa-pencil": !_vm.editMode,
                  "fa-eye": _vm.editMode,
                  "deletion-available": _vm.isDeletionAvailable,
                },
                on: { click: _vm.toggleEditMode },
              }),
              _vm._v(" "),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isDeletionAvailable && _vm.editMode,
                    expression: "isDeletionAvailable&&editMode",
                  },
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Remove link to Goal",
                    expression: "'Remove link to Goal'",
                  },
                ],
                staticClass:
                  "fa fa-times event-goals-goal__button delete-button",
                on: { click: _vm.unlinkGoal },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.goal.viewer_membership_status.can_create_tasks
        ? _c("goal-create-panel", {
            attrs: { "allow-filter": false },
            on: {
              run_wizard: _vm.runWizard,
              quick_create: _vm.quickCreateTask,
            },
          })
        : _c(
            "div",
            {
              staticClass: "text-center",
              staticStyle: { "margin-bottom": "10px" },
            },
            [
              _c("strong", [
                _vm._v(
                  "You cannot create " +
                    _vm._s(_vm._f("customTerm")("Tasks")) +
                    " in this " +
                    _vm._s(_vm._f("customTerm")("Goal")) +
                    " as you are not a\n            Member."
                ),
              ]),
              _vm._v(" "),
              _vm.goal.viewer_membership_status.is_goal_owners_circle_member
                ? _c("span", [
                    !_vm.goal.viewer_membership_status.is_join_request_pending
                      ? _c("strong", [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.sendRequestToJoinGoal.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v("Request to Join")]
                          ),
                        ])
                      : _c("strong", [
                          _vm._v(
                            "Join request was sent. You will join that " +
                              _vm._s(_vm._f("customTerm")("Goal")) +
                              " after approval."
                          ),
                        ]),
                  ])
                : _vm._e(),
            ]
          ),
      _vm._v(" "),
      _vm.goal.viewer_membership_status.only_calendar_owners_tasks_are_showed
        ? _c(
            "div",
            {
              staticClass: "text-center",
              staticStyle: { "margin-bottom": "10px" },
            },
            [
              _c("strong", [
                _vm._v(
                  "Only " +
                    _vm._s(_vm._f("customTerm")("Tasks")) +
                    " assigned to " +
                    _vm._s(_vm.calendarOwnerName) +
                    " are displayed since\n            " +
                    _vm._s(_vm._f("customTerm")("Goal")) +
                    " admin has hidden Goal\n            Tasks."
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.tasks, function (task) {
        return _c("goal-task-desktop", {
          key: task.id,
          attrs: {
            "hide-completed-children": !_vm.showCompleted,
            "initial-task": task,
            layout: _vm.goalTaskLayout,
            "is-completed-mark-editable": _vm.isTaskCompletedMarkEditable,
            "allow-set-assistance-options": _vm.allowSetAssistanceOptions(task),
            "is-editable": _vm.isTaskEditable(task),
            "allow-drag": false,
            "allow-multiple-assign":
              _vm.goal.viewer_membership_status.is_admin &&
              !task.is_child &&
              _vm.goal.whole_goal_linked,
            "is-assignee-editable": _vm.isTaskAssigneeEditable(task),
            "edit-mode": _vm.editMode,
            "allow-link-to-event": true,
            "event-params-for-tasks": _vm.eventParamsForTasks,
            goal: _vm.goal.goal,
            availableCompletionOptions: _vm.availableCompletionOptions,
          },
          on: {
            completedStateUpdated: function ($event) {
              return _vm.completedStateUpdated(task)
            },
          },
        })
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.flattenedCount > _vm.tasks.length,
              expression: "flattenedCount>tasks.length",
            },
          ],
          staticClass: "row",
        },
        [
          _c("div", { staticClass: "col-sm-12 text-center" }, [
            _c(
              "strong",
              { staticClass: "pointer", on: { click: _vm.showMore } },
              [
                _vm._v(
                  "Show " +
                    _vm._s(_vm.flattenedCount - _vm.tasks.length) +
                    " More"
                ),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text-right" }, [
        _c(
          "strong",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showCompleted && _vm.completedCount,
                expression: "!showCompleted&&completedCount",
              },
            ],
            staticClass: "pointer margin-5-right",
            on: { click: _vm.showCompletedTasks },
          },
          [
            _vm._v(
              "Show completed\n            " +
                _vm._s(_vm._f("customTerm")("Tasks")) +
                "(" +
                _vm._s(_vm.completedCount) +
                ")"
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("event-task-dates-popup", { ref: "edit-task-dates-popup" }),
      _vm._v(" "),
      _c("event-detail-page-period-selector-popup-with-another-date-option", {
        ref: "period-selector",
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }