var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.title, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.title))]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "share-target-selector__container" }, [
        _c(
          "div",
          {
            staticClass: "share-target-selector__select-menu-container center",
          },
          [
            _c(
              "div",
              {
                ref: "keepPrivateButton",
                staticClass:
                  "share-target-selector__select-menu-item share-target-selector__select-menu-item_private",
                class: {
                  "share-target-selector__select-menu-item_two-lines":
                    _vm.twoLines,
                },
                staticStyle: { "z-index": "4" },
                attrs: {
                  tabindex: "0",
                  role: "button",
                  "aria-label": "Keep Private",
                },
                on: {
                  click: function ($event) {
                    return _vm.select("private")
                  },
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.select("private")
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "share-target-selector__item-caption",
                    class: {
                      "share-target-selector__item-caption_two-lines":
                        _vm.twoLines,
                    },
                    attrs: { "aria-hidden": "true" },
                  },
                  [
                    _vm._v("Keep"),
                    _c("br"),
                    _vm._v("Private\n                "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "share-target-selector__item-icon",
                    class: {
                      "share-target-selector__item-icon_two-lines":
                        _vm.twoLines,
                    },
                  },
                  [_c("i", { staticClass: "fa fa-hand-paper-o" })]
                ),
                _vm._v(" "),
                _vm.keepPrivate
                  ? _c(
                      "div",
                      {
                        staticClass: "share-target-item__shared-mark",
                        class: { unconfirmed: _vm.keepPrivateUnconfirmed },
                      },
                      [_c("i", { staticClass: "fa fa-check" })]
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "share-target-selector__select-menu-item share-target-selector__select-menu-item_circle",
                class: {
                  "share-target-selector__select-menu-item_two-lines":
                    _vm.twoLines,
                },
                staticStyle: { "z-index": "3" },
                attrs: {
                  tabindex: "0",
                  role: "button",
                  "aria-label": "Share to My Circle",
                },
                on: {
                  click: function ($event) {
                    return _vm.select("circle")
                  },
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.select("circle")
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "share-target-selector__item-caption",
                    class: {
                      "share-target-selector__item-caption_two-lines":
                        _vm.twoLines,
                    },
                  },
                  [_vm._v("My"), _c("br"), _vm._v("Circle\n                ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "share-target-selector__item-icon",
                    class: {
                      "share-target-selector__item-icon_two-lines":
                        _vm.twoLines,
                    },
                  },
                  [
                    _c("div", {
                      staticClass:
                        "share-target-item__custom-icon share-target-item__custom-icon_circle",
                      class: {
                        "share-target-item__custom-icon_two-lines":
                          _vm.twoLines,
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _vm.sharingStatus.friends.shared
                  ? _c(
                      "div",
                      {
                        staticClass: "share-target-item__shared-mark",
                        class: {
                          unconfirmed: _vm.sharingStatus.friends.has_unapproved,
                        },
                      },
                      [_c("i", { staticClass: "fa fa-check" })]
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _vm.twoLines ? _c("div", {}) : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "share-target-selector__select-menu-item share-target-selector__select-menu-item_groups",
                class: {
                  "share-target-selector__select-menu-item_two-lines":
                    _vm.twoLines,
                },
                staticStyle: { "z-index": "2" },
                attrs: {
                  "aria-label": "Share to my groups",
                  tabindex: "0",
                  role: "button",
                },
                on: {
                  click: function ($event) {
                    return _vm.select("groups")
                  },
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.select("groups")
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "share-target-selector__item-caption",
                    class: {
                      "share-target-selector__item-caption_two-lines":
                        _vm.twoLines,
                    },
                    attrs: { "aria-hidden": "true" },
                  },
                  [_vm._v("My"), _c("br"), _vm._v("Groups\n                ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "share-target-selector__item-icon",
                    class: {
                      "share-target-selector__item-icon_two-lines":
                        _vm.twoLines,
                    },
                  },
                  [
                    _c("div", {
                      staticClass:
                        "share-target-item__custom-icon share-target-item__custom-icon_groups",
                      class: {
                        "share-target-item__custom-icon_two-lines":
                          _vm.twoLines,
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _vm.sharingStatus.groups.shared
                  ? _c(
                      "div",
                      {
                        staticClass: "share-target-item__shared-mark",
                        class: {
                          unconfirmed: _vm.sharingStatus.groups.has_unapproved,
                        },
                      },
                      [_c("i", { staticClass: "fa fa-check" })]
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _vm.allowShareToCommunities
              ? _c(
                  "div",
                  {
                    staticClass:
                      "share-target-selector__select-menu-item share-target-selector__select-menu-item_communities",
                    class: {
                      "share-target-selector__select-menu-item_two-lines":
                        _vm.twoLines,
                    },
                    staticStyle: { "z-index": "1" },
                    attrs: {
                      role: "button",
                      tabindex: "0",
                      "aria-label": "Share to my Communities",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.select("communities")
                      },
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select("communities")
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "share-target-selector__item-caption",
                        class: {
                          "share-target-selector__item-caption_two-lines":
                            _vm.twoLines,
                        },
                        attrs: { "aria-hidden": "true" },
                      },
                      [
                        _vm._v("My"),
                        _c("br"),
                        _vm._v("Communities\n                "),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "share-target-selector__item-icon",
                        class: {
                          "share-target-selector__item-icon_two-lines":
                            _vm.twoLines,
                        },
                      },
                      [
                        _c("div", {
                          staticClass:
                            "share-target-item__custom-icon share-target-item__custom-icon_communities",
                          class: {
                            "share-target-item__custom-icon_two-lines":
                              _vm.twoLines,
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.sharingStatus.communities.shared
                      ? _c(
                          "div",
                          {
                            staticClass: "share-target-item__shared-mark",
                            class: {
                              unconfirmed:
                                _vm.sharingStatus.communities.has_unapproved,
                            },
                          },
                          [_c("i", { staticClass: "fa fa-check" })]
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }