import { render, staticRenderFns } from "./EventDetailErrorDeleted.vue?vue&type=template&id=e4ab2f36&scoped=true&"
import script from "./EventDetailErrorDeleted.vue?vue&type=script&lang=js&"
export * from "./EventDetailErrorDeleted.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4ab2f36",
  null
  
)

export default component.exports