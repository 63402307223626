<template>
    <div class="inline-block">
        <div class="breadcrumb-separator"></div>
        <div
                class="breadcrumb-image text-center pointer"
                v-tooltip="section.caption"
                :class="{'active':isActive}"
                @click="sectionClick" :style="sectionAvatarStyle">
            <i class="fa fa-picture-o" v-if="!section.avatar&&!section.media_content_samples.length"></i>
        </div>
    </div>
</template>

<script>
    import mediaContentUtils from "../../../../angular/app/view_media/mediaContentUtils";

    export default {
        name: "wiki-breadcrumbs-section",
        props: {
            section: {
                type: Object
            },
            isActive: {
                type: Boolean,
                default: false
            },
            size: {
                type: Number,
                default: 40
            }
        },
        computed: {
            sectionAvatarStyle() {
                const size = this.isActive ? this.size * 1.125 : this.size;
                let resultStyle = {
                    width: `${size}px`,
                    height: `${size}px`,
                };
                let avatarUrl = (this.section && this.section.avatar) ? this.section.avatar.image.thumbnail_url : undefined;
                if (avatarUrl || this.section.media_content_samples.length) {
                    _.merge(resultStyle, new mediaContentUtils().generate_group_background(avatarUrl, this.section.media_content_samples));
                }
                return resultStyle;
            }
        },
        methods: {
            sectionClick() {
                this.$emit('sectionClick', this.section);
            }
        }
    };
</script>

<style>
</style>