<template>
    <div class="site-footer" id="site-footer">
        <div id="footer-top" class="full-height">
            <div class="container-fluid full-height">
                <div class="row full-height site-footer__logos-row">
                    <div class="col-xs-6 text-left site-footer__logos">
                        <div class="site-footer__mm-white-logo"></div>
                        <a class="site-footer__log-in" :href="loginLink" v-if="showLoginLink">
                            <i class="far fa-sign-in"></i> sign in
                        </a>
                    </div>
                    <div class="col-xs-6 text-right site-footer__logos">
                        <div class="site-footer__uel-logo" v-if="showRixLogos"></div>
                        <div class="site-footer__rix-logo" v-if="showRixLogos"></div>
                    </div>
                    <a href="javascript:scrollTo(0, 0);" class="up-arrow" aria-label="Go Up"></a>
                </div>
            </div>
        </div>
        <!--Footer top arrow Ends Here-->
        <footer><!--Footer Section Starts Here-->
            <div class="container site-footer__copyright-panel">
                <div class="row">
                    <div class="col-xs-6">
                    <span class="hidden-print">
                        <a href="https://www.multime.com/terms">Terms</a> |
                        <a href="https://www.multime.com/privacy-policy">Privacy Policy</a> |
                        <a href="https://www.multime.com/contact-1" style="white-space: nowrap;">Contact Us</a>
                    </span>

                    </div>
                    <div class="col-xs-6" style="padding-right: 25px;">
                        <p class="pull-right">&copy; 2020 Copyright Multi-Me<span
                                v-if="showRixLogos"> and Rix Media</span>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    import {DjangoUrls} from 'DjangoUrls';

    export default {
        name: "SiteFooter",
        data() {
            return {
                showRixLogos: true,
                showLoginLink: false,
            };
        },
        computed: {
            loginLink() {
                return DjangoUrls['auth_login']();
            },
        },
        methods: {
            toggleAdditionalFooters(show) {
                // this.showRixLogos = show;
            },
            toggleFooterLoginLink(show) {
                this.showLoginLink = show;
            }
        }
    };
</script>

<style scoped lang="scss">

  footer {
    background: #0367ad;
    padding: 15px 0 15px 0;
    font-size: 18px;
    color: #FFF;
  }

  footer a {
    color: #FFF;
    text-decoration: none;
    border-bottom: 1px solid #FFF;
    margin: 0 10px;
  }

  footer a:hover {
    color: #FFF;
    font-weight: bolder;
  }

  .full-height {
    height: 100%;
  }

  #footer-top {
    height: 80px;
    background: #0367ad;
    text-align: center;
  }

  #footer-top .up-arrow {
    width: 108px;
    height: 108px;
    background-size: 108px 108px;
    background-position: center;
    display: block;
    position: absolute;
    top: -45px;
    left: 47%;
    left: calc(50% - 54px);
    background-image: url(go_to_top.png);
  }

  .site-footer {
    @mixin footer-logo-mixin() {
      display: inline-block;
      vertical-align: middle;
      background-size: cover;
      background-repeat: no-repeat;
    }

    @mixin footer-logo-size-mixin($proportion) {
      @media (min-width: 1050px) {
        $logo-height: 65px;
        height: $logo-height;
        width: $logo-height * $proportion;
        margin-right: 20px;
      }
      @media (min-width: 950px) and (max-width: 1049px) {
        $logo-height: 60px;
        height: $logo-height;
        width: $logo-height * $proportion;
        margin-right: 30px;
      }
      @media (min-width: 860px) and (max-width: 949px) {
        $logo-height: 55px;
        height: $logo-height;
        width: $logo-height * $proportion;
        margin-right: 20px;
      }
      @media (min-width: 650px) and (max-width: 859px) {
        $logo-height: 40px;
        height: $logo-height;
        width: $logo-height * $proportion;
        margin-right: 10px;
      }
    }

    &__logos-row {
      position: relative;
    }

    &__logos {
      padding-top: 10px;
    }

    &__mm-white-logo {
      @include footer-logo-mixin;
      @include footer-logo-size-mixin(3.736);
      background-image: url("/static/images/footer-logos/mme_white_logo.png");
    }

    &__log-in {
      color: white;
      line-height: 60px;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      text-decoration: none;
      font-size: 26px;
      @media (min-width: 1050px) {
        font-size: 46px;
      }
      @media (min-width: 950px) and (max-width: 1049px) {
        font-size: 36px;
      }
      @media (min-width: 860px) and (max-width: 949px) {
        font-size: 30px;
      }
      @media (max-width: 599px) {
        position: relative;
        top: -15px;
        left: -15px;
        font-size: 25px;
        /*display: none;*/
      }
    }

    &__rix-logo {
      @include footer-logo-mixin;
      @include footer-logo-size-mixin(2.31);
      background-image: url("/static/images/footer-logos/rix-logo.png");
    }

    &__uel-logo {
      @include footer-logo-mixin;
      @include footer-logo-size-mixin(3.35);
      background-image: url("/static/images/footer-logos/uel-logo.png");
    }

    &__copyright-panel {
      @media (max-width: 599px) {
        display: none;
      }
    }
  }
</style>