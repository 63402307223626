import * as _ from 'lodash';
import UserActivityPostCommentsController from "../../activity/user_activity_post/UserActivityPostComments";

MMCommon.$inject = ['selectFactory', 'notifications'];

function MMCommon(selectFactory, notifications) {
    /**
     * @property Likeable: {Object}
     * @property Commentable: {Object}
     */

    class Commentable {
        constructor(comments_count, comments_link) {
            this.comments_count = comments_count;
            this.comments_link = comments_link;
        }

        get_comments_count() {
            return this.comments_count;
        }

        get_comments_link() {
            return this.comments_link;
        }
    }

    class Likeable {
        constructor(entrypoint, id, liked_by_me, likes_count) {
            /**
             *  Entrypoint should support methods like, unlike, get_liked_users
             */
            this.liked_by_me = liked_by_me;
            this.entrypoint = entrypoint;
            this.likes_count = likes_count;
            this.id = id;
        }

        like() {
            this.liked_by_me = true;
            this.likes_count++;
            this.entrypoint.like(this.id)
                .then(r => {
                        notifications.success('Liked');
                        this.likes_count = r.data.likes_count;
                        this.liked_hook(this.likes_count);
                    },
                    err => {
                        notifications.error(err || 'Error');
                        this.liked_by_me = false;
                        this.likes_count--;
                    }
                );
        }

        liked_hook(likes_count) {

        }

        unliked_hook(likes_count) {

        }

        unlike() {
            this.liked_by_me = false;
            this.likes_count--;
            this.entrypoint.unlike(this.id)
                .then(r => {
                        notifications.success('Unliked');
                        this.likes_count = r.data.likes_count;
                        this.unliked_hook(this.likes_count);
                    },
                    err => {
                        notifications.error(err || 'Error');
                        this.liked_by_me = true;
                        this.likes_count++;
                    }
                );
        }

        toggle_like() {
            (this.is_liked_by_me() ? this.unlike : this.like).call(this);
        }

        is_liked_by_me() {
            return this.liked_by_me;
        }

        show_who_liked() {
            selectFactory.endpoint_selector(_.partial(this.entrypoint.get_liked_users, this.id), false, {
                button_no: 'Close',
                button_yes: false,
                info: false,
                disable_search: true,
                list_mode: true,
                header: 'Likes',
                empty:'There are not any likes yet'

            });
        }
    }

    return {Likeable, Commentable};
}

export default MMCommon;