var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "container",
      staticClass: "empty-slide-edit-holder",
      class: { "under-drag": _vm.underDrag },
      on: {
        dragenter: function ($event) {
          $event.preventDefault()
          return _vm.onDragEnter.apply(null, arguments)
        },
        dragover: function ($event) {
          $event.preventDefault()
        },
        dragleave: _vm.onDragLeave,
        dragend: _vm.onDragEnd,
        drop: function ($event) {
          $event.preventDefault()
          return _vm.onDrop.apply(null, arguments)
        },
      },
    },
    [
      _vm.onlyMedia && !_vm.underDrag
        ? _c(
            "div",
            {
              staticClass:
                "empty-slide-edit-holder__only-add-media text-center pointer",
              on: { click: _vm.editSlideMedia },
            },
            [
              _c("div", {
                staticClass: "media-icon",
                style: _vm.onlyMediaIconStyle,
              }),
              _vm._v(" "),
              _c("div", { staticClass: "empty-slide-edit-holder__label" }, [
                _vm._v("Click to add media"),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.onlyMedia && !_vm.underDrag
        ? _c(
            "div",
            {
              staticClass:
                "empty-slide-edit-holder__add-media-and-drag-drop text-center pointer",
              on: { click: _vm.editSlideMedia },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-xs-5 text-center" }, [
                  _c("div", {
                    staticClass: "drop-icon",
                    style: _vm.mediaAndDropIconStyle,
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "empty-slide-edit-holder__label" }, [
                    _vm._v(
                      "Drag and\n                    Drop Pictures here\n                "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-xs-2 text-center" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "inline-block empty-slide-edit-holder__label",
                      style: _vm.orLabelStyle,
                    },
                    [_vm._v("or\n                ")]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-xs-5 text-center" }, [
                  _c("div", {
                    staticClass: "media-icon",
                    style: _vm.mediaAndDropIconStyle,
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "empty-slide-edit-holder__label" }, [
                    _vm._v(
                      "Click to\n                    add media\n                "
                    ),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.underDrag
        ? _c(
            "div",
            {
              staticClass:
                "empty-slide-edit-holder__drop-receiver text-center pointer",
              style: _vm.underDragLabelStyle,
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "empty-slide-edit-holder__label empty-slide-edit-holder__drop-receiver__drop-here-label",
                },
                [
                  _vm._v(
                    "Drop\n            Images here to create new Slides.\n        "
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }