import angular from 'angular';
import urls from 'DjangoUrls';
let {DjangoUrls} = urls;
UserProfileActivityResource.$inject = ['$http'];

function UserProfileActivityResource($http) {
    return {
        create_post,
        remove_post,
    };

    function create_post(user_id, data) {
        const url = DjangoUrls["api.v1:user_activity_post-create-post"](user_id);
        return $http({
            method: 'POST',
            url,
            data
        });
    }

    function remove_post(user_id, id) {
        const url = DjangoUrls["api.v1:user_activity_post-remove-post"](user_id);
        return $http({
            method: 'POST',
            url,
            data: {id}
        });
    }


}

export default UserProfileActivityResource;

