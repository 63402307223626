import Vue from 'vue';

const massActionsBus = new Vue();
const MASS_ACTION_BUS_ACTIONS = {
    ENABLE_DELETE_MODE: 'ENABLE_DELETE_MODE',
    DISABLE_DELETE_MODE: 'DISABLE_DELETE_MODE',
    ENABLE_MOVE_MODE: 'ENABLE_MOVE_MODE',
    DISABLE_MOVE_MODE: 'DISABLE_MOVE_MODE',
    DELETE_SELECTED: 'DELETE_SELECTED',
    MOVE_SELECTED: 'MOVE_SELECTED',
    MOVE_COMPLETED: 'MOVE_COMPLETED',
};

export {massActionsBus, MASS_ACTION_BUS_ACTIONS};