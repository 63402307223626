<template>
    <div class="stuff_preview"
         :class="classObject"
         :style="containerStyle"
    >
        <div class="stuff_preview__image_container" v-tooltip="displayName?undefined:stuffItem.name"
             :style="[styleObject, imageContainerStyle]">
            <div v-if="onlineMark"
                 class="stuff_preview__unread_mark"
                 :style="onlineMarkStyle">
            </div>
            <div v-if="showAdditionalMark" @click.stop.prevent="additional_mark"
                 :class="['stuff_preview__additional_mark', additionalMarkCustomIcon, additionalMarkColor]"
                 :style="additionalMarkStyle">
                <i v-if="additionalMark" class="fa" :class="additionalMark"></i>
            </div>
            <div v-if="showAdditionalMark2" @click.stop.prevent="additional_mark2"
                 :class="['stuff_preview__additional_mark', additionalMarkCustomIcon2, additionalMarkColor2]"
                 :style="additionalMark2Style">
                <i v-if="additionalMark2" class="fa" :class="additionalMark2"></i>
            </div>
            <div v-if="unreadMark"
                 class="stuff_preview__unread_mark"
                 :style="additionalMarkStyle">
                <i>NEW</i>
            </div>
            <div v-if="deletable" class="stuff_preview__delete_button"
                 :style="deleteMarkStyle"
                 @click.stop.prevent="del"
                 @keydown.enter.prevent="del"
                 tabindex="0"
                 role="button"
                 aria-label="Delete"
            >
                <i class="fa fa-times" v-if="deleteButton==='close'"></i>
                <i class="fa fa-sign-out" v-if="deleteButton==='leave'"></i>
                <i class="fa fa-trash-o" v-if="deleteButton==='delete'"></i>
            </div>
        </div>
        <div class="stuff_preview__text_container text-center" :style="textContainerStyle" v-if="displayName">
            {{ stuffItem.name | cutName(53) }}
        </div>
    </div>
</template>
<script>
    import mediaContentUtils from "../../angular/app/view_media/mediaContentUtils";


    export default {
        data() {
            return {};
        },
        name: 'VueStuffPreview',
        props: {
            stuffItem: {
                type: Object,
                required: true
            },
            size: {
                required: false,
                type: Number,
                default: 123
            },
            animation: {
                type: Boolean,
                required: false,
                default: true
            },
            deletable: {
                type: Boolean,
                required: false,
                default: false
            },
            displayName: {
                type: Boolean,
                required: false,
                default: true
            },
            unreadMark: {
                type: Boolean,
                required: false,
                default: false
            },
            onlineMark: {
                type: Boolean,
                required: false,
                default: false
            },
            mediaContentSamples: {
                type: Array,
                required: false,
                default: () => [],
                validator: function (value) {
                    return value.length <= 4;
                }
            },
            stuffType: {
                type: String,
                required: true,
                validator: function (value) {
                    return ['user', 'story', 'goal', 'collection', 'community', 'group', 'event', 'folder'].indexOf(value) !== -1;
                }
            },
            deleteButton: {
                type: String,
                required: false,
                default: 'close',
                validator: function (value) {
                    return ['close', 'leave', 'delete'].indexOf(value) !== -1;
                }
            },
            additionalMark: {
                type: String,
                required: false,
            },
            additionalMarkColor: {
                type: String,
                required: false,
                default: 'dark-blue'
            },
            additionalMarkCustomIcon: {
                type: String,
                required: false,
            },
            showAdditionalMark: {
                type: Boolean,
                required: false,
                default: false
            },
            additionalMark2: {
                type: String,
                required: false,
            },
            additionalMarkColor2: {
                type: String,
                required: false,
                default: 'dark-blue'
            },
            additionalMarkCustomIcon2: {
                type: String,
                required: false,
            },
            showAdditionalMark2: {
                type: Boolean,
                required: false,
                default: false
            },
            margin: {
                type: Number,
                default: 9,
            }
        },
        computed: {
            containerStyle() {
                return {
                    'margin': `${this.margin}px`,
                };
            },
            classObject() {
                let classes = [this.stuffType];
                if (this.animation) {
                    classes.push('hvr-float-shadow');
                }
                return classes;
            },
            styleObject() {
                return new mediaContentUtils().generate_group_background(this.stuffItem.avatar, this.mediaContentSamples);

            },
            textContainerStyle() {
                return {
                    width: `${this.size}px`,
                    'font-size': `${Math.min(16, parseInt(this.size / 6))}px`,
                };
            },
            imageContainerStyle() {
                return {
                    position: 'relative',
                    height: `${this.size - 4}px`,
                    width: `${this.size - 4}px`
                };
            },
            deleteMarkStyle() {
                return {
                    position: 'absolute',
                    right: `${parseInt(this.size / 4) - 30}px`,
                    top: `${parseInt(this.size / 4) - 25}px`,
                    width: `30px`,
                    height: `30px`,
                    'line-height': `30px`,
                    'font-size': `20px`,
                };
            },
            additionalMarkStyle() {
                return {
                    position: 'absolute',
                    left: `${parseInt(this.size / 4) - 30}px`,
                    top: `${parseInt(this.size / 4) - 25}px`,
                    width: `30px`,
                    height: `30px`,
                };
            },
            onlineMarkStyle() {
                return {
                    position: 'absolute',
                    right: `${parseInt(this.size / 4) - 30}px`,
                    bottom: `${parseInt(this.size / 4) - 25}px`,
                    border: 'solid 2px white',
                    width: `30px`,
                    height: `30px`,
                };
            },
            additionalMark2Style() {
                const offset = parseInt(this.size / 4) + (this.showAdditionalMark ? 6 : -25);
                return {
                    position: 'absolute',
                    left: `${parseInt(this.size / 4) - 30}px`,
                    top: `${offset}px`,
                    width: `30px`,
                    height: `30px`,
                };
            },
        },
        methods: {
            del() {
                this.$emit('delete');
            },
            additional_mark() {
                this.$emit('additionalMarkClick');
            },
            additional_mark2() {
                this.$emit('additionalMark2Click');
            }
        }
    };
</script>

<style lang="scss">
  @import "../../angular/app/styles/const";
  @import "../../angular/app/styles/mixins";

  .stuff_preview {
    position: relative;
    display: inline-block;
    cursor: pointer;
    vertical-align: top !important;

    &.user {
      .stuff_preview__image_container {
        border-color: $color-user;
      }
    }

    &.story {
      .stuff_preview__image_container {
        border-color: $color-story;
      }
    }

    &.goal {
      .stuff_preview__image_container {
        border-color: $color-goal;
      }
    }

    &.collection {
      .stuff_preview__image_container {
        border-color: $color-collection;
      }
    }

    &.community {
      .stuff_preview__image_container {
        border-color: $color-community;
      }
    }

    &.group {
      .stuff_preview__image_container {
        border-color: $color-group;
      }
    }

    &.event {
      .stuff_preview__image_container {
        border-color: $color-event;
      }
    }

    &.folder {
      .stuff_preview__image_container {
        border-color: $color-folder;
      }
    }

    .stuff_preview__image_container {
      border-radius: 50%;
      margin: auto;
      background-size: cover;
      border: solid 3px;
    }

    &__text_container {
      margin-top: 8px;
      font-weight: bolder;
      word-wrap: break-word;
      line-height: 18px;
    }

    &__delete_button {
      border-radius: 50%;
      background-color: #E6493B;
      color: white;
      text-align: center;
    }

    &__additional_mark {
      border-radius: 50%;
      color: white;
      text-align: center;
      font-size: 20px;
      line-height: 30px;
      @include mm-icons;
      background-size: 79%;
      background-repeat: no-repeat;
      background-position: center;

      &.dark-blue {
        background-color: $dark-blue;
      }

      &.blue {
        background-color: $blue;
      }

      &.green {
        background-color: $green;
      }

      &.grass-green {
        background-color: $grass-green;
      }

      &.yellow {
        background-color: $yellow;
      }

      &.red {
        background-color: $red;
      }

      &.online-green {
        background-color: $online-green;
      }
    }

    &__unread_mark {
      border-radius: 50%;
      color: white;
      text-align: center;
      font-size: 10px;
      line-height: 30px;
      background-color: $online-green;
    }

  }
</style>