activityPostDetailedFactory.$inject = ['notifications', 'stickersFactory', 'addMedia', '$rootScope', 'simplePopupFactory', '$log', '$q', 'DragDropUtils', 'createPostFactory'];

function activityPostDetailedFactory(notifications, stickersFactory, addMedia, $rootScope, simplePopupFactory, $log, $q, DragDropUtils, createPostFactory) {


    class ActivityPostDetailedItem {
        constructor(post_item, edit_post_resource, post) {
            this.post_item = post_item;
            this.edit_post_resource = edit_post_resource;
            this.post = post;
        }

        get id() {
            return this.post_item.id;
        }

        get text() {
            return this.post_item.text;
        }

        set text(val) {
            return this.post_item.text = val;
        }

        get sticker() {
            return this.post_item.sticker;
        }

        set sticker(val) {
            return this.post_item.sticker = val;
        }

        get is_header() {
            return this.post_item.is_header;
        }

        set is_header(val) {
            return this.post_item.is_header = val;
        }

        get media_attachment() {
            return this.post_item.media_attachment;
        }

        del() {
            this.post.remove_item(this);
        }

        move_up() {
            this.post.move_entry(this, 'up');
        }

        move_down() {
            this.post.move_entry(this, 'down');
        }


        edit_post_item() {
            createPostFactory.edit_post_through_popup(this, {allow_header: true, button_yes: 'Save'})
                .then(post_data => {
                    console.log('post_data', post_data);
                    let post_create_data = {
                        text: post_data.text,
                        is_header: post_data.is_header,
                    };
                    if (post_data.sticker) {
                        post_create_data.sticker = post_data.sticker;
                    }
                    if (post_data.media_item_id_list.length === 1) {
                        post_create_data.media_attachment_id = post_data.media_item_id_list[0];
                    }
                    $rootScope.show_dimmer();
                    return this.edit_post_resource.edit_post_item(this.post.container_id, this.id, post_create_data);
                }, () => new Promise(()=>{}))
                .then(resp => {
                    this.post_item = resp.data;
                    notifications.success('Updated');
                }, err => {
                    notifications.error(err || 'Error');
                })
                .finally($rootScope.hide_dimmer);

            // addMedia.add_text({text: this.text, is_header: this.is_header})
            //     .then((res) => {
            //         $rootScope.show_dimmer();
            //         return this.edit_post_resource.edit_post_item(this.post.container_id, this.id, res);
            //     }, () => new Promise(()=>{}))
            //     .then((resp) => {
            //         this.post_item.is_header = resp.data.is_header;
            //         this.post_item.text = resp.data.text;
            //         notifications.success('Updated');
            //
            //     }, err => notifications.error(err || 'Error'))
            //     .finally(() => $rootScope.hide_dimmer());
        }

    }


    class ActivityPostDetailed {
        constructor(edit_post_resource, activity_post_container, editable) {
            this.container_id = activity_post_container.id;
            this.activity_post = activity_post_container.activity_post;
            this.edit_post_resource = edit_post_resource;
            this.editable = editable;
            this.items = this.activity_post.items.map(i => new ActivityPostDetailedItem(i, edit_post_resource, this));

            this.edit_mode = false;
        }

        enable_edit_mode() {
            this.edit_mode = true;
        }

        disable_edit_mode() {
            this.edit_mode = false;
        }

        drag_drop_callback(items) {
            this.items = items;
            $rootScope.show_dimmer();
            this.edit_post_resource.reorder_items(this.container_id, items.map(item => item.id))
                .then(function (resp) {
                    notifications.success('Items was successfully reordered');
                }, err => {
                    notifications.error(err || 'Error');
                })
                .finally(() => $rootScope.hide_dimmer());
        };


        move_entry(entry, target) {
            /**
             * @param {object} entry - day entry
             * @param {string} target - 'up'||'down'
             */
            let entry_index = this.items.indexOf(entry);
            if (entry_index === 0 && target === 'up') {
                notifications.error('Media item is already first');
            }
            if (entry_index === this.items - 1 && target === 'down') {
                notifications.error('Media item is already last');
            }
            if (target === 'up') {
                entry_index--;
            } else {
                if (target === 'down') {
                    entry_index++;
                }
            }
            DragDropUtils.onDropComplete(entry_index, entry, undefined, true, this.items, (...rest) => {
                this.drag_drop_callback.call(this, ...rest);
            });
        }

        _add_media_callback(media_items) {
            let self = this;
            if (media_items.length === 1) {
                simplePopupFactory.show_popup('Description', 'Do you want to add description to your media?', 'Yes', 'Skip')
                    .then(function yes() {
                        return addMedia.add_text();
                    }, function no() {
                        return {text: undefined, is_header: undefined};
                    })
                    .then(function (res) {
                        $rootScope.show_dimmer();
                        return self.edit_post_resource.add_media_item(self.container_id, {
                            media_attachment_id: media_items[0].pk,
                            text: res.text,
                            is_header: res.is_header
                        });
                    }, err => {
                    })
                    .then(resp => {
                        self.items.push(new ActivityPostDetailedItem(resp.data, this.edit_post_resource, self));
                        notifications.success('Media item created');
                    }, err => notifications.error(err || 'Error'))
                    .catch(err => {
                        $log.error(err);
                    })
                    .finally(() => $rootScope.hide_dimmer());

                //....
            } else {
                $rootScope.show_dimmer();
                self.edit_post_resource.bulk_media_upload(self.container_id, media_items.map(item => item.pk))
                    .then(resp => {
                        resp.data.map(i => self.items.push(new ActivityPostDetailedItem(i, this.edit_post_resource, self)));
                        notifications.success(`Created ${resp.data.length} media items.`);
                    }, err => notifications.error(err || 'Error'))
                    .finally(() => $rootScope.hide_dimmer());
            }
        }

        add_media() {
            let self = this;
            addMedia.add_media({
                allow_add_from_library: true,
                max_count: 25,
                types: ['video', 'audio', 'image'],
                popup_header: 'What sort of media would you like to add?'
            }, {
                default_callback: function (m) {
                    self._add_media_callback.call(self, m);
                }
            });

        }

        change_text() {
            addMedia.add_text({text: this.text, is_header: this.is_header}, true)
                .then(res => {
                    this.text = res.text;
                    this.is_header = res.is_header;
                    this.edit_post_resource.update_post(this.container_id, res)
                        .then(resp => {
                            notifications.success('Updated');
                        }, err => notifications.error(err || 'Error'));
                });
        }

        remove_sticker() {
            let sticker_backup = this.sticker;
            this.sticker = undefined;
            this.edit_post_resource.update_post(this.container_id, {'sticker': null})
                .then(resp => {
                    notifications.success('Sticker removed');
                }, err => {
                    this.sticker = sticker_backup;
                })
                .catch(err => {

                });
        }

        remove_item(post_item) {
            simplePopupFactory.show_popup('Remove Media Item', 'Are you sure you want to remove this media item?', 'Remove', 'Cancel')
                .then(yes => {
                    $rootScope.show_dimmer();
                    return this.edit_post_resource.remove_post_item(this.container_id, post_item.id);
                }, () => new Promise(()=>{}))
                .then(resp => {
                    _.remove(this.items, {id: post_item.id});
                    notifications.success('Removed');
                }, err => notifications.error(err || 'Error'))
                .finally($rootScope.hide_dimmer);
        }

        change_sticker() {
            stickersFactory.select_sticker()
                .then(sticker => {
                    this.sticker = sticker;
                    this.edit_post_resource.update_post(this.container_id, {sticker: sticker.code})
                        .then(resp => {
                                notifications.success('Updated');
                            }, err => {
                                notifications.error(err || 'Error');
                            }
                        );
                });
        }

        get text() {
            return this.activity_post.text;
        }

        set text(val) {
            return this.activity_post.text = val;
        }

        get sticker() {
            return this.activity_post.sticker;
        }

        set sticker(val) {
            return this.activity_post.sticker = val;
        }

        get is_header() {
            return this.activity_post.is_header;
        }

        set is_header(val) {
            return this.activity_post.is_header = val;
        }
    }

    return {
        ActivityPostDetailed
    };

}

export default activityPostDetailedFactory;