<template>
    <div class="chat-style-comment-linked-blog-answer">
        <span class="chat-style-comment-linked-blog-answer__text"
              :class="{'chat-style-comment-linked-blog-answer__text_white': moveAvatarToRight}">
            <strong>
                <simple-link :target="comment.userprofile"></simple-link>
            </strong> has linked
            <strong v-if="!blogAnswer.accessible">Diary Log entry to this {{ 'Task' | customTerm }}:</strong>
            <strong v-else>
                <a :href="blogAnswer.link">Diary Log entry to this {{ 'Task' | customTerm }}:</a>
            </strong>
            <br/>
        </span>
        <div>
            <br>
            <render-quote :limit-size="true" :quote-border-color="moveAvatarToRight?'#fff':undefined">
                <strong v-if="questionPreview">{{ questionPreview }}</strong>
                <diary-location-answer
                        :answer="blogAnswer.data"
                ></diary-location-answer>
            </render-quote>
        </div>
        <div v-if="blogAnswer.attachments&&blogAnswer.attachments.length" class="margin-15-top">
            <div><i class="fa fa-paperclip"></i> Attachments ({{ blogAnswer.attachments.length }}):</div>
            <div>
                <media-preview
                        v-for="(attachment, $index) in blogAnswer.attachments"
                        :key="attachment.id"
                        :item='attachment'
                        no-border
                        :high-light="false"
                        style="display: inline-block; margin-right: 5px; margin-bottom: 5px"
                        @click.native="showAttachments($index)"
                ></media-preview>
            </div>
        </div>
    </div>
</template>

<script>
    import DiaryLocationAnswer from '../../diary_page/DiaryLogAnswer';
    import RenderQuote from 'shared/quote_directive/renderQuote';
    import viewMediaFactory from '~/angular/app/view_media/view_media_facory';

    export default {
        name: 'ChatStyleCommentLinkedBlogAnswer',
        components: {
            RenderQuote,
            DiaryLocationAnswer
        },
        props: {
            comment: Object,
            blogAnswer: Object,
            moveAvatarToRight: {
                type: Boolean,
                default: false
            }
        },
        mounted() {
            console.log(this.blogAnswer);
        },
        computed: {
            questionPreview() {
                return this.blogAnswer.data?.question?.text;
            }
        },
        methods: {
            showAttachments(index = 0) {
                viewMediaFactory().show_media(this.blogAnswer.attachments, index);
            },
        }
    };
</script>

<style scoped lang="scss">
  .chat-style-comment-linked-blog-answer {
    margin: 15px;

    &__text {
      font-size: 16px;

      &_white {
        color: #fff;

        &::v-deep {
          a {
            color: #fff !important;
          }
        }
      }
    }
  }
</style>
