let angular = require('angular');
// import './style.scss'
function usersListPanel() {
    return {
        restrict: 'E',
        replace: true,
        transclude: {
            detailLink: '?detailLink'
        },
        scope: {
            usersList: '=',
            usersListTitle: '@',
        },
        template: require('./template.html')
        ,
        link: function ($scope, element, attrs, controller) {
            $scope.get_user_preview_style = function (user) {
                return {
                    'background-image': 'url(' + user.avatar + ')',
                    'background-position': 'center',
                    'background-size': 'cover',
                    'background-repeat': 'no-repeat'
                }
            }

        }
    };
}

angular.module('mmApp.shared.leftPanel.renderLeftPanel.usersListPanel', [])
    .directive('usersListPanel', usersListPanel);

export default 'mmApp.shared.leftPanel.renderLeftPanel.usersListPanel'