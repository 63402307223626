<template>
    <div class="">
        <vue-simple-header :left-part-size="buddyMode?5:4" :right-part-size="buddyMode?7:8"
                           :right-part-classes="['no-left-padding-desktop']" separation-mode="lg">
            <span slot="headerTitle" class="pointer" @click="loadEventsWeek()"
                  v-if="!buddyMode">{{ headerLabel }}</span>
            <span slot="headerTitle" class="" v-if="buddyMode">
               <simple-link-with-avatar :target="profile" postfix-label="'s Calendar"></simple-link-with-avatar>
            </span>
            <span slot="headerLabel" v-if="buddyMode">
                <vue-switch-with-label
                        class="hidden-xs"
                        :collapse-on-mobile="true"
                        v-model="calendarOwnerIsOwnEventsAdmin"
                        label="Is Admin"
                        name="is_admin"
                        style="margin: 5px 0"
                        @input="changeAdminStatus"
                        help-text="Make username Admin of their own Calendar so that they have full access to all Calendar events (including private ones)"
                ></vue-switch-with-label>
            </span>
            <div slot="panelRight">
                <vue-switch-with-label
                        v-if="buddyMode"
                        class="visible-xs-inline-block"
                        :collapse-on-mobile="true"
                        v-model="calendarOwnerIsOwnEventsAdmin"
                        label="Is Admin"
                        name="is_admin"
                        style="float: left"
                        @input="changeAdminStatus"
                        help-text="Make username Admin of their own Calendar so that they have full access to all Calendar events (including private ones)"
                ></vue-switch-with-label>

                <vue-folder-button :no-text-on-mobile="true" text="Find events" icon-class="fa-search" :top="-3"
                                   @click.native="goToSearch"></vue-folder-button>

                <vue-folder-button :no-text-on-mobile="true" text="New event" icon-class="fa-plus" :top="-3"
                                   :class="{'hidden-xs':$route.name==='search'}"
                                   @click.native="createEvent"></vue-folder-button>

                <vue-folder-button :no-text-on-mobile="true" text="Create Group Holiday"
                                   icon-class="fa-star-christmas"
                                   v-if="isGroupsAdmin&&!buddyMode"
                                   :class="{'hidden-xs':$route.name==='search'}"
                                   @click.native="createGroupHoliday"></vue-folder-button>

                <vue-folder-button :no-text-on-mobile="true" text="Manage Subscriptions" icon-class="fa-users-cog fas"
                                   color="brown"
                                   v-if="canManageSubscriptions"
                                   :class="{'hidden-xs':$route.name==='search'}"
                                   @click.native="manageGroupSubscriptions"></vue-folder-button>
                <vue-folder-button :no-text-on-mobile="true" icon-class="fa-calendar"
                                   text="Go to date"
                                   color="blue"
                                   class="diary-detail__go-to-date-button"
                                   :class="{'hidden-xs':$route.name==='search'}"
                                   @click.native="showDateSelector"
                ></vue-folder-button>

                <vue-folder-button :no-text-on-mobile="true"
                                   v-if="showBackToCalendarsButton"
                                   icon-class="fa-reply"
                                   text="Back to Calendars"
                                   color="blue"
                                   @click.native="backToCalendars"
                ></vue-folder-button>
            </div>
        </vue-simple-header>
        <div class="" v-show="loaded">
            <calendar-page-weeks-navigation
                    :loaded-day="loadedDay"
                    @previousWeek="previousWeek"
                    @nextWeek="nextWeek"
            ></calendar-page-weeks-navigation>


            <calendar-page-day
                    @onHolidayChanged="onHolidayChanged"
                    :day="day"
                    v-for="day in days"
                    :profile="profile"
                    :key="day.date"></calendar-page-day>

            <calendar-page-weeks-navigation
                    :loaded-day="loadedDay"
                    @previousWeek="previousWeek"
                    @nextWeek="nextWeek"
            ></calendar-page-weeks-navigation>
        </div>
        <create-event-popup ref="create-event-popup"></create-event-popup>
        <group-events-page-add-holiday-popup v-if="isGroupsAdmin&&!buddyMode"
                                             ref='group-events-page-add-holiday-popup'></group-events-page-add-holiday-popup>
        <create-event-target-selector-popup
                ref="create-event-target-selector-popup"></create-event-target-selector-popup>
    </div>

</template>

<script>
    import CreateEventPopup from '../new_event/CreateEventPopup.vue';
    import {URL_DATE_FORMAT, VISUAL_DATE_FORMAT} from "./config";
    import * as config from './config';
    import Vue from 'vue';
    import CalendarPageWeeksNavigation from './CalendarPageWeeksNavigation.vue';
    import CalendarPageDay from './CalendarPageDay.vue';
    import moment from 'moment';
    import {eventsBus, EVENTS_BUS_EVENTS} from './eventsBus';
    import {DjangoUrls} from 'DjangoUrls';
    import GroupEventsPageAddHolidayPopup from "../../groups/group_events/GroupEventsPageAddHolidayPopup.vue";
    import CreateEventTargetSelectorPopup from './CreateEventTargetSelectorPopup.vue';
    import {simplePopupFactory} from "shared/simple_popups/simplePopupFactory";
    import DateSelectorPopup from "../../diary_page/popups/DateSelectorPopup";

    export default {
        name: "CalendarPageCalendar",
        components: {
            CalendarPageWeeksNavigation,
            CalendarPageDay,
            CreateEventPopup,
            GroupEventsPageAddHolidayPopup,
            CreateEventTargetSelectorPopup
        },
        props: {
            membershipState: Object
        },
        data() {
            return {
                days: [],
                loadedDay: undefined,
                todayDateMoment: moment().tz('Europe/London'),
                loaded: false,

                showBackToCalendarsButton: false,
            };
        },
        computed: {
            buddyMode() {
                return this.membershipState.buddyMode;
            },
            profile() {
                return this.membershipState.profile;
            },
            calendarOwnerIsOwnEventsAdmin() {
                return this.membershipState.calendarOwnerIsOwnEventsAdmin;
            },
            isGroupsAdmin() {
                return this.membershipState.isGroupsAdmin;
            },
            isCommunitiesAdmin() {
                return this.membershipState.isCommunitiesAdmin;
            },
            headerLabel() {
                if (!this.buddyMode) {
                    return 'My Calendar';
                } else {
                    return `${this.profile.name}'s Calendar`;
                }
            },
            canManageSubscriptions() {
                return this.calendarOwnerIsOwnEventsAdmin || this.buddyMode;
            },
            showAddUserInCreatePopup() {
                return this.buddyMode && !this.calendarOwnerIsOwnEventsAdmin;
            }
        },
        methods: {
            onHolidayChanged() {
                this.loadEventsWeek(this.loadedDay);
            },
            loadEventsWeek(date = this.todayDateMoment) {
                const UserEventsResource = Vue.getAngularModule('UserEventsResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                $rootScope.show_dimmer();
                UserEventsResource.get_users_calendar_week(this.profile.id, date.format(config.VISUAL_DATE_FORMAT))
                    .then(resp => {
                        this.days = resp.data.days;
                        this.loadedDay = date;
                        this.loaded = true;
                    }, err => {
                        notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            nextWeek() {
                const dateToLoad = this.loadedDay.clone().add(1, 'w');
                this.loadEventsWeek(dateToLoad);
            },
            previousWeek() {
                const dateToLoad = this.loadedDay.clone().subtract(1, 'w');
                this.loadEventsWeek(dateToLoad);
            },
            goToSearch() {
                console.log(this.$router.resolve({
                    name: 'CalendarPageSearch',
                    params: {
                        userId: this.$route.params.userId
                    },
                    hash: this.$route.hash,
                    query: this.$route.query,
                }));
                this.$router.push({
                    name: 'CalendarPageSearch',
                    params: {
                        userId: this.$route.params.userId
                    },
                    hash: this.$route.hash,
                    query: this.$route.query,
                });
            },
            backToCalendars() {
                this.$router.replace({name: 'CalendarsIManage'});
                // document.location.href = DjangoUrls['calendars_list_i_manage']();
            },
            createEvent(initial = {}) {
                if (this.buddyMode) {
                    if (this.isGroupsAdmin) {
                        this.$refs['create-event-target-selector-popup'].show({
                            community: false,
                            group: this.isGroupsAdmin
                        }, () => {
                        })
                            .then(res => {
                                if (res === 'circle') {
                                    this.createCircleEvent(initial);
                                } else if (res === 'group') {
                                    this.createGroupEvent(this.profile.id, initial);
                                }
                            });
                    } else {
                        this.createCircleEvent(initial);
                    }
                } else {
                    if (this.isGroupsAdmin || this.isCommunitiesAdmin) {
                        this.$refs['create-event-target-selector-popup'].show({
                            community: this.isCommunitiesAdmin,
                            group: this.isGroupsAdmin
                        }, () => {
                        })
                            .then(res => {
                                //res in ['circle','group','community']
                                if (res === 'circle') {
                                    this.createCircleEvent(initial);
                                } else if (res === 'group') {
                                    this.createGroupEvent(undefined, initial);
                                } else if (res === 'community') {
                                    this.showCommunitySelector(initial);
                                }
                            });
                    } else {
                        this.createCircleEvent(initial);
                    }
                }
            },
            createCircleEvent(initial) {
                const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
                const notifications = Vue.getAngularModule('notifications');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const createPopupResult = this.$refs['create-event-popup'].show(
                    _.merge({privateEvent: true}, initial),
                    {
                        header: this.buddyMode ? `Create new event in ${this.profile.name}'s Calendar` : 'Create new event',
                        showAddToEventSwitch: this.showAddUserInCreatePopup,
                        username: this.profile.name,
                    }
                );

                createPopupResult.then(eventData => {
                    $rootScope.show_dimmer();
                    return CalendarEventsResource.create_calendar_event({
                        target: 'profile',
                        profile_id: this.profile.id
                    }, eventData);
                }, () => new Promise(() => {
                }))
                    .then(resp => {
                        this.loadEventsWeek(this.loadedDay);
                        notifications.success('Created');
                    }, (err) => {
                        $rootScope.hide_dimmer();
                        notifications.error(err || 'Error');
                    });
            },
            createGroupHoliday() {
                const GroupEventsHolidaysResource = Vue.getAngularModule('GroupEventsHolidaysResource');
                const selectFactory = Vue.getAngularModule('selectFactory');
                const $rootScope = Vue.getAngularModule('$rootScope');
                selectFactory.endpoint_selector(_.partial(GroupEventsHolidaysResource.get_groups_where_i_can_add_holidays, undefined), true, {
                    header: `Add a Holiday to Group Events`,
                    info: `Select or de-select Groups where you are admin to add a holiday to.`,
                    button_yes: 'OK',
                    button_no: 'Cancel',
                    empty: `You do not have any Groups to select`,
                }, false, false, false)
                    .then(([id_list, ...rest]) => {
                            return this.$refs['group-events-page-add-holiday-popup'].create(false, {
                                groupsToAddIdList: id_list,
                                addInAnotherGroups: true
                            });
                        },
                        cancel => {
                            return new Promise(() => {
                            });
                        })
                    .then(({startDate, endDate, name, description, addToGroupsIdList}) => {
                            $rootScope.show_dimmer();
                            return GroupEventsHolidaysResource.create_events_holidays_in_groups(addToGroupsIdList, {
                                start_date: moment(startDate).format(VISUAL_DATE_FORMAT),
                                end_date: moment(endDate).format(VISUAL_DATE_FORMAT),
                                name: name,
                                description: description,
                            });
                        },
                        cancel => {
                            return new Promise(() => {
                            });
                        })
                    .then(resp => {
                        this.loadEventsWeek(this.loadedDay);
                        Vue.notifications.success('Created');
                    }, err => {
                        $rootScope.hide_dimmer();
                        Vue.notifications.error(err || 'Error');
                    });
            },
            showCommunitySelector(initial = {}) {
                const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
                const notifications = Vue.getAngularModule('notifications');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const selectFactory = Vue.getAngularModule('selectFactory');

                let communityId;
                let communityName;

                selectFactory.endpoint_selector(CalendarEventsResource.get_communities_to_create_event, false, {
                    header: 'Create community event',
                    info: 'Select Community from list to create event',
                    button_yes: 'Select',
                    button_no: 'Cancel',
                    empty: 'You do not have any communities to select',
                }, false, false, true)
                    .then(community => {
                        communityId = community.id;
                        communityName = community.name;
                        return this.$refs['create-event-popup'].show(Object.assign({}, {privateEvent: false}, initial), {
                            header: `Create community event in ${communityName}`,
                            privateLabel: `Make this a Private event. (Hide from un-invited community members)`,
                            privateHelpText: null
                        });
                    }, () => new Promise(() => {
                    }))
                    .then(eventData => {
                        $rootScope.show_dimmer();
                        return CalendarEventsResource.create_calendar_event({
                            target: 'community',
                            community_id: communityId,
                        }, eventData);
                    }, () => new Promise(() => {
                    }))
                    .then(resp => {
                        this.loadEventsWeek(this.loadedDay);
                        notifications.success('Created');
                    }, (err) => {
                        $rootScope.hide_dimmer();
                        notifications.error(err || 'Error');
                    });
            },
            createGroupEvent(circleOwnerId = undefined, initial = {}) {
                const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
                const notifications = Vue.getAngularModule('notifications');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const selectFactory = Vue.getAngularModule('selectFactory');

                let groupId;
                let groupName;
                let apiEndpoint;
                if (circleOwnerId) {
                    apiEndpoint = _.partial(CalendarEventsResource.get_groups_to_create_circle_group_event, circleOwnerId);
                } else {
                    apiEndpoint = CalendarEventsResource.get_groups_to_create_event;
                }

                selectFactory.endpoint_selector(apiEndpoint, false, {
                    header: 'Create Group event',
                    info: 'Select Group from list to create event',
                    button_yes: 'Select',
                    button_no: 'Cancel',
                    empty: 'You do not have any Groups to select',
                }, false, false, true)
                    .then(group => {
                        groupId = group.id;
                        groupName = group.name;
                        return this.$refs['create-event-popup'].show(Object.assign({}, initial, {privateEvent: false}), {
                            header: `Create Group event in ${groupName}`,
                            privateLabel: `Make this a Private event. (Hide from un-invited Group members)`,
                            privateHelpText: null
                        });
                    }, () => new Promise(() => {
                    }))
                    .then(eventData => {
                        $rootScope.show_dimmer();
                        return CalendarEventsResource.create_calendar_event({
                            target: 'group',
                            group_id: groupId,
                        }, eventData);
                    }, () => new Promise(() => {
                    }))
                    .then(resp => {
                        this.loadEventsWeek(this.loadedDay);
                        notifications.success('Created');
                    }, (err) => {
                        $rootScope.hide_dimmer();
                        notifications.error(err || 'Error');
                    });

            },
            showDateSelector() {
                const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(DateSelectorPopup);
                popupComponent.show({
                    maxDate: new Date(2200, 1, 1),
                    minDate: new Date(1900, 1, 1),
                    highLightedDates: [],
                })
                    .then(formattedDate => {
                        this.loadEventsWeek(moment(formattedDate).tz('Europe/London'));
                    }, () => {
                    })
                    .finally(() => {
                        vm?.$destroy();
                        fEl?.focus();
                    });
            },
            changeAdminStatus(value) {
                const UserEventsResource = Vue.getAngularModule('UserEventsResource');
                const notifications = Vue.getAngularModule('notifications');
                let endpoint;
                if (value) {
                    endpoint = UserEventsResource.make_user_calendar_admin;
                } else {
                    endpoint = UserEventsResource.unmake_user_calendar_admin;
                }
                endpoint(this.profile.id)
                    .then(resp => {
                        this.calendarOwnerIsOwnEventsAdmin = resp.data;
                        notifications.success('Updated');
                    }, err => {
                        notifications.error(err || 'Error');
                    });
            },
            manageGroupSubscriptions() {
                const UserEventsResource = Vue.getAngularModule('UserEventsResource');
                const selectFactory = Vue.getAngularModule('selectFactory');
                const $q = Vue.getAngularModule('$q');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                let POPUP_CONFIG = {
                    header: 'Select Groups',
                    info: 'Select or de-select Groups to subscribe or un-subscribe to Group events. Subscribed events will appear on this Calendar.',
                    button_yes: 'Select',
                    button_no: 'Cancel',
                    empty: 'You do not have any Groups to select',
                    additional_buttons: [],
                    long_text: false
                };

                let groups_selector = selectFactory.endpoint_selector(
                    _.partial(UserEventsResource.get_group_subscriptions, this.profile.id),
                    true, POPUP_CONFIG);
                groups_selector
                    .then(([new_checked_id_list, new_unchecked_id_list]) => {
                        $rootScope.show_dimmer();
                        return UserEventsResource.update_group_events_subscription(this.profile.id, new_checked_id_list, new_unchecked_id_list);
                    }, n => {
                        return new Promise(() => {
                        });
                    })
                    .then(resp => {
                        notifications.success('Subscription updated');
                        this.loadEventsWeek(this.loadedDay);
                    }, err => {
                        notifications.error(err || 'Error');
                        $rootScope.hide_dimmer();
                    });
            }
        },
        created() {
            const $location = Vue.getAngularModule('$location');
            const dateInUrl = $location.search()['date'];
            let dateInUrlMoment;
            if (dateInUrl) {
                dateInUrlMoment = moment(dateInUrl, URL_DATE_FORMAT);
            }

            if (this.$route.query.fl !== undefined) {
                this.showBackToCalendarsButton = true;
            }

            this.loadEventsWeek(dateInUrlMoment);
            eventsBus.$on(EVENTS_BUS_EVENTS.LOAD_EVENTS_WEEK, this.loadEventsWeek);
            eventsBus.$on(EVENTS_BUS_EVENTS.CREATE_EVENT_IN_DATE_AND_HOUR, this.createEvent);
        },
        beforeDestroy() {
            eventsBus.$off(EVENTS_BUS_EVENTS.LOAD_EVENTS_WEEK, this.loadEventsWeek);
            eventsBus.$off(EVENTS_BUS_EVENTS.CREATE_EVENT_IN_DATE_AND_HOUR, this.createEvent);
        },
        watch: {
            loadedDay(n) {
                const $location = Vue.getAngularModule('$location');
                if (this.todayDateMoment.format(URL_DATE_FORMAT) !== this.loadedDay.format(URL_DATE_FORMAT)) {
                    $location.search('date', this.loadedDay.format(URL_DATE_FORMAT));
                } else {
                    $location.search('date', null);
                }
            }
        },
        metaInfo() {
            let title = 'Calendar';
            if (this.profile && this.profile.name) {
                if (!this.buddyMode) {
                    title = 'My Calendar';
                } else {
                    title = `Calendar - ${this.profile.name}`;
                }
            }
            return {title};
        }
    };
</script>

<style scoped>

</style>