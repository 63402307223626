var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "attendance_panel" }, [
    _c("div", {}, [_c("strong", [_vm._v(_vm._s(_vm.attendanceLabel) + ":")])]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "attendance_panel__item-container",
        class: {
          selected: _vm.attendance === "yes",
          "something-selected": _vm.attendance,
        },
        on: {
          click: function ($event) {
            return _vm.setAttendance("yes")
          },
        },
      },
      [
        _c("div", { staticClass: "attendance_panel__item yes" }),
        _vm._v(" "),
        _c("div", { staticClass: "attendance_panel__label text-center" }, [
          _vm._v("Yes"),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "attendance_panel__item-container",
        class: {
          selected: _vm.attendance === "maybe",
          "something-selected": _vm.attendance,
        },
        on: {
          click: function ($event) {
            return _vm.setAttendance("maybe")
          },
        },
      },
      [
        _c("div", { staticClass: "attendance_panel__item maybe" }),
        _vm._v(" "),
        _c("div", { staticClass: "attendance_panel__label text-center" }, [
          _vm._v("Maybe"),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "attendance_panel__item-container",
        class: {
          selected: _vm.attendance === "no",
          "something-selected": _vm.attendance,
        },
        on: {
          click: function ($event) {
            return _vm.setAttendance("no")
          },
        },
      },
      [
        _c("div", { staticClass: "attendance_panel__item no" }),
        _vm._v(" "),
        _c("div", { staticClass: "attendance_panel__label text-center" }, [
          _vm._v("No"),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }