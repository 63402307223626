// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./np_film_1383923_676269 (1) (1).png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./np_drag-and-drop_934946_676269 (2).png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".media-icon[data-v-60a5cf32]{display:inline-block;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover}.drop-icon[data-v-60a5cf32]{display:inline-block;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:cover}.empty-slide-edit-holder[data-v-60a5cf32]{height:100%}.empty-slide-edit-holder__label[data-v-60a5cf32]{color:#828282;font-size:20px}.empty-slide-edit-holder__only-add-media[data-v-60a5cf32]{height:100%}.empty-slide-edit-holder__add-media-and-drag-drop[data-v-60a5cf32]{height:100%}.empty-slide-edit-holder__drop-receiver[data-v-60a5cf32]{height:100%}.empty-slide-edit-holder__drop-receiver__drop-here-label[data-v-60a5cf32]{pointer-events:none;font-size:22px}\n", ""]);
// Exports
module.exports = exports;
