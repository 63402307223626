CommunityResource.$inject = ['$http'];
import urls from 'DjangoUrls';

let {DjangoUrls} = urls;

function CommunityResource($http) {
    return {
        my_communities_list,
        get_searchable_communities,
        get_events,
        get_shared_stories,
        get_shared_collections,
        get_shared_media_folders,
        unshare,
        get_community_groups,
        create_group,
        delete_group,
        update_admins,
        delete_member,
        invite_users,
        join,
        invite_by_email,
        reg_by_invite,
        leave_community,

        // settings

        get_community_settings,
        save_main_settings,
        save_ip_settings,
        update_avatar,
        delete_community

    };

    ///////////////////

    function my_communities_list(params) {
        const url = DjangoUrls["api.v1:community-my-communities-list"]();
        return $http({
            method: 'GET',
            url,
            params
        });
    }

    function get_searchable_communities(params) {
        const url = DjangoUrls["api.v1:community-get-searchable-communities"]();
        return $http({
            method: 'GET',
            url,
            params
        });
    }

    function delete_group(community_id, group_id) {
        const url = DjangoUrls["api.v1:community-delete-group"](community_id);
        return $http({
            method: 'PATCH',
            url,
            data: {
                id: group_id
            }
        });
    }

    function invite_users(community_id, new_checked_ids, new_unchecked_ids) {
        const url = DjangoUrls["api.v1:community-invite-users"](community_id);
        return $http({
            method: 'POST',
            url,
            data: {
                new_checked_ids, new_unchecked_ids
            }
        })
    }


    function join(id) {
        const url = DjangoUrls["api.v1:community-join"](id);
        return $http({
            method: 'POST',
            url
        })
    }

    function get_events(id, actual = true, params) {
        const url = DjangoUrls["api.v1:community-get-events"](id);
        return $http({
            method: 'GET',
            url,
            params: Object.assign({actual}, params)
        });
    }


    function update_admins(community_id, new_checked_ids, new_unchecked_ids) {
        const url = DjangoUrls["api.v1:community-update-admins"](community_id);
        return $http({
            method: 'PATCH',
            url,
            data: {
                new_checked_ids, new_unchecked_ids
            }
        })

    }

    function delete_member(community_id, user_id) {
        const url = DjangoUrls["api.v1:community-delete-member"](community_id);
        return $http({
            method: 'PATCH',
            url,
            data: {
                id: user_id
            }
        })
    }

    function get_shared_stories(id) {
        const url = DjangoUrls["api.v1:community-get-shared-stories"](id);

        return $http({
            method: 'GET',
            url: url
        });
    }

    function get_shared_collections(id) {
        const url = DjangoUrls["api.v1:community-get-shared-collections"](id);

        return $http({
            method: 'GET',
            url: url
        });
    }

    function get_shared_media_folders(id) {
        const url = DjangoUrls["api.v1:community-get-shared-media-folders"](id);

        return $http({
            method: 'GET',
            url: url
        });
    }

    function get_community_groups(id, params) {
        const url = DjangoUrls["api.v1:community-get-community-groups"](id);

        return $http({
            method: 'GET',
            url,
            params
        });
    }

    function unshare(community_id, what, id) {
        const url = DjangoUrls["api.v1:community-unshare"](community_id);

        return $http({
            method: 'PATCH',
            url: url,
            data: {
                what, id
            }
        });
    }

    function create_group(community_id, data) {
        const url = DjangoUrls["api.v1:community-create-group"](community_id);

        return $http({
            method: 'PATCH',
            url: url,
            data
        });
    }

    function invite_by_email(community_id, email) {
        const url = DjangoUrls["api.v1:community-invite-by-email"](community_id);
        return $http({
            method: 'PATCH',
            url: url,
            data: {email}
        });
    }

    function reg_by_invite(target_id, data) {
        const url = DjangoUrls[`api.v1:community_reg-reg-by-invite`](target_id);
        return $http({
            method: 'POST',
            url,
            data
        })
    }

    function get_community_settings(target_id) {
        const url = DjangoUrls[`api.v1:community_settings-get-community-settings`](target_id);
        return $http({
            method: 'GET',
            url
        })
    }

    function save_main_settings(target_id, data) {
        const url = DjangoUrls[`api.v1:community_settings-save-main-settings`](target_id);
        return $http({
            method: 'PATCH',
            url,
            data
        })
    }

    function save_ip_settings(target_id, ip_list) {
        const url = DjangoUrls[`api.v1:community_settings-save-ip-settings`](target_id);
        return $http({
            method: 'PATCH',
            url,
            data: {ip_list}
        })
    }

    function update_avatar(target_id, media_content_pk) {
        const url = DjangoUrls["api.v1:community_settings-update-avatar"](target_id);

        return $http({
            method: 'PATCH',
            url: url,
            data: {media_content_pk: media_content_pk}
        });
    }

    function delete_community(target_id) {
        const url = DjangoUrls["api.v1:community_settings-delete-community"](target_id);

        return $http({
            method: 'PATCH',
            url: url,
        });
    }

    function leave_community(target_id) {
        const url = DjangoUrls["api.v1:community-leave-community"](target_id);

        return $http({
            method: 'POST',
            url: url,
        });
    }
}


export default CommunityResource