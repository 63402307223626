var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "selector-popup",
    { ref: "popup", attrs: { header: _vm.header, items: _vm.items } },
    [
      _vm.config.allow_remove
        ? _c("template", { slot: "footer" }, [
            _c(
              "button",
              { staticClass: "btn btn-mm btn-red", on: { click: _vm.remove } },
              [
                _c("i", {
                  staticClass: "far fa-trash",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Remove\n            Media\n        "),
              ]
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }