<template>
    <modal v-model="opened" :transition='0' :backdrop="false">
        <div slot="header">
            <a id="close_popup" @click="closePopup"></a>
            <h3 class="modal-title">Start a new edit session on this Wiki</h3>
        </div>

        <h4>
            If somebody is supporting you to edit this Wiki, please confirm who is helping
        </h4>

        <div class="select-menu-container center">
            <div class="select-menu-item" style="min-width: 200px;">
                <div class="panel panel-primary">
                    <div class="panel-heading shortcuts" @click="select('me')">
                        <h3 class="panel-title">It’s just me <br> editing it on my own</h3>
                        <img class="margin-10-top img-circle" :src="currentUserAvatar" alt="My Avatar" :height="100"/>
                    </div>
                </div>
            </div>
            <div class="select-menu-item" style="min-width: 200px">
                <div class="panel panel-primary">
                    <div class="panel-heading shortcuts" @click="select('other')">
                        <h3 class="panel-title">Someone is helping me <br> to edit my wiki</h3>
                        <i class="margin-10-top far fa-user-friends select-menu-item__main-icon" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <button class="btn btn-mm btn-default" @click="closePopup">
                Cancel
            </button>
        </div>
    </modal>
</template>

<script>
    import { CurrentUserClass } from '~/angular/app/shared/CurrentUser';

    export default {
        name: 'WikiAddAttachmentTypeSelectPopup',
        data() {
            return {
                opened: false
            };
        },
        computed: {
            currentUserAvatar() {
                return new CurrentUserClass().avatar;
            }
        },
        methods: {
            show() {
                this.opened = true;
                return new Promise((res, rej) => {
                    this.resolve = res;
                    this.reject = rej;
                });
            },
            select(what) {
                this.opened = false;
                this.resolve(what);
            },
            closePopup() {
                this.opened = false;
                this.reject('closed');
            }
        }
    };
</script>

<style scoped lang="scss">
  .button-label {
    text-transform: none
  }

  .select-menu-item {
    &:hover {
      opacity: 0.9;
    }
  }
</style>
