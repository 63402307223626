<template>
    <comments-list-infinite-scroll
            :target-id="storyId"
            :comments-resource="commentsResource"

            :allow-add="allowAdd"
            :allow-delete-own="true"
            :allow-like="true"
            :allow-delete="isAdmin"
            :allow-reply-expand="true"

            :initial-reply-to="initialReplyTo"
    ></comments-list-infinite-scroll>
</template>

<script>
    import Vue from 'vue';
    import CommentsListInfiniteScroll from "shared/comments/CommentsListInfiniteScroll";
    import CommentsInfiniteScrollResourceFactory from "shared/comments/resource_infinite_scroll_factory";
    import {CurrentUserClass} from "shared/CurrentUser";
    import ActivityReplyToSupportMixin from "../../../../vue/mixins/ActivityReplyToSupportMixin";

    export default {
        name: "StoryCommentsPage",
        components: {CommentsListInfiniteScroll},
        mixins: [ActivityReplyToSupportMixin],
        props: {
            storyId: Number,
            isAdmin: Boolean,
        },
        computed: {
            allowAdd() {
                return new CurrentUserClass().has_permission('post-comments');
            },
            commentsResource() {
                const $http = Vue.getAngularModule('$http');
                return CommentsInfiniteScrollResourceFactory('story_comments')($http);
            }
        }
    };
</script>

<style scoped>

</style>