var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "dashboard-list-item hvr-float-shadow",
      attrs: { href: _vm.link },
    },
    [
      _c("div", {
        staticClass: "dashboard-list-item__image",
        class: { enlarged: _vm.enlarged },
        style: _vm.imageStyle,
      }),
      _vm._v(" "),
      _c("div", { staticClass: "dashboard-list-item__label" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }