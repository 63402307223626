<template>
    <div class="" v-hotkey="keymap">
        <alert type="info" class="margin-15-top margin-15-bottom" v-if="wikiEditor">Editing session is being conducted by <b>{{ wikiEditor.name }}</b></alert>
        <wiki-main-menu
                :isTopSection="true"
                :mainSectionText="mainSectionText"
                :mainSectionAvatar="mainSectionAvatar"
                :pentagramMode="pentagramMode"
                :mainSectionAudioCaption="mainSectionAudioCaption"
                :sections="sections"
                :containerHeight="containerHeight"
                :containerWidth="containerWidth"
                :editMode="editMode"
                :allowCreate="allowCreate"
                :allowDrag="allowDrag"
                :active-section-number="activeSectionNumber"
                @onSectionCreate="onSectionCreate"
                @onSectionDelete="onSectionDelete"
                @onSectionEdit="onSectionEdit"
                @onSwitchSections="onSwitchSections"
                @onSectionOpen="onSectionOpen"
        ></wiki-main-menu>
    </div>
</template>

<script>
    import Vue from 'vue';
    import WikiMainMenu from '../wiki/wiki_main_page/WikiMainMenu.vue';

    export default {
        name: 'WikiMainPageMenu',
        components: { WikiMainMenu },
        mixins: [],
        props: {
            wikiId: {
                type: [String, Number],
            },
            accessCode: {
                type: String,
                required: false
            },
            fullScreen: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                permissionsLoaded: false,
                loaded: false,

                windowHeight: window.innerHeight,
                containerWidth: window.innerWidth,
            };
        },
        computed: {
            keymap() {
                return {
                    'j': this.activateNextSection,
                    'k': this.activatePrevSection,
                };
            },
            pentagramMode() {
                return this.$store.getters['WikiStore/sections'].length === 6;
            },
            sections() {
                return this.$store.getters['WikiStore/sectionsAsObject'];
            },
            wikiEditor() {
                return this.$store.getters['WikiStore/wikiEditor'];
            },
            activeSectionNumber() {
                return this.$store.getters['WikiStore/activeSectionNumber'];
            },
            nextSectionNumber() {
                return this.$store.getters['WikiStore/nextSectionNumber'];
            },
            prevSectionNumber() {
                return this.$store.getters['WikiStore/prevSectionNumber'];
            },
            wikiOwner() {
                return this.$store.getters['WikiStore/wikiOwner'];
            },
            isEditable() {
                return this.$store.getters['WikiStore/isEditable'];
            },
            accessParams() {
                return this.$store.getters['WikiStore/accessParams'];
            },
            editMode() {
                return this.$store.getters['WikiStore/editMode'];
            },
            allowDrag() {
                return this.isEditable;
            },
            allowCreate() {
                return this.isEditable;
            },
            mainSectionAudioCaption() {
                if (this.sections && this.sections['current_section'] && this.sections['current_section'].audio_caption) {
                    return this.sections['current_section'].audio_caption;
                }
            },
            mainSectionText() {
                if (this.sections && this.sections['current_section']) {
                    return this.sections['current_section'].caption;
                }
                return 'About Me';
            },
            mainSectionAvatar() {
                if (this.sections && this.sections['current_section'] && this.sections['current_section'].avatar) {
                    return this.sections['current_section'].avatar.image.thumbnail_url;
                }
                return this.wikiOwner ? this.wikiOwner.avatar : undefined;
            },

            containerHeight() {
                if (this.fullScreen) {
                    return Math.min(this.windowHeight, 1600) - 250;
                }
                const container_height = Math.max(this.windowHeight, 780);
                const heightCorrectorConst = container_height >= 600 ? 310 : 210;
                let realContainerHeight = container_height - heightCorrectorConst;
                if (realContainerHeight > 530) {
                    realContainerHeight = 530;
                }
                return realContainerHeight;
            },
        },
        methods: {
            onSectionCreate() {
                this.$store.dispatch('WikiStore/createSection');
            },
            onSectionEdit(position) {
                this.$store.dispatch('WikiStore/editSection', this.sections[position]);
            },
            onSectionDelete(position) {
                this.$store.dispatch('WikiStore/deleteSection', this.sections[position]);
            },
            onSwitchSections({
                from,
                to
            }) {
                if(this.sections[to]?.is_fixed){
                    this.$notifications.error('Section is fixed');
                    return
                }
                this.$store.dispatch('WikiStore/onSwitchSections', {
                    from: this.sections[from],
                    to: this.sections[to]
                });
            },
            onResize() {
                this.windowHeight = window.innerHeight;
                this.containerWidth = this.$el.offsetWidth;
            },
            onSectionOpen(section) {
                this.$emit('onSectionOpen', section);
            },
            activateNextSection() {
                this.$store.commit('WikiStore/setActiveSectionNumber', this.nextSectionNumber);
            },
            activatePrevSection() {
                this.$store.commit('WikiStore/setActiveSectionNumber', this.prevSectionNumber);
            },
            $_loadMainSection() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                this.$store.dispatch('WikiStore/loadMainSections')
                    .finally(() => {
                        this.loaded = true;
                        $rootScope.hide_dimmer();
                    });
            },
        },
        mounted() {
            this.onResize();
            this.$_loadMainSection();
            window.addEventListener('resize', this.onResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        }
    };
</script>

<style scoped>

</style>
