
entryEmotionDirective.$inject = [];
import EntryEmotion from './EntryEmotion.vue';
import Vue from 'vue';

function entryEmotionDirective() {
    return {
        restrict: 'E',
        scope: {
            emotion: '=',
        },
        link: function ($scope, element, attrs, controller) {
            const vm = new Vue({
                el: element[0],
                name: 'entryEmotionDirective',
                components: {
                    EntryEmotion
                },
                data() {
                    return {
                        emotion: $scope.emotion,
                    };
                },
                template: `<entry-emotion :emotion="emotion"></entry-emotion>`,
            });
            $scope.$watch('emotion',(n,o)=>{
                vm.emotion=n;
            })
            $scope.$on('$destroy', ()=> {
                if (vm) {
                    vm.$destroy();
                }
            });
        },
        template: '<div ng-non-bindable></div>'
    };
}

export default entryEmotionDirective;