var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "media-folders__container__wrap" }, [
    _c(
      "div",
      { staticClass: "media-folders__container" },
      [
        _c("users-media-page-header", {
          attrs: {
            "folders-path": _vm.foldersPath,
            currentUser: _vm.currentUser,
            folders: _vm.folders,
            isEditableByViewer: _vm.isEditableByViewer,
            initialQuery: _vm.filter.query,
            viewerStatus: _vm.viewerStatus,
            editMode: _vm.editMode,
            allowMakeMeAdmin: _vm.allowMakeMeAdmin,
            massActionsState: _vm.massActionsState,
            "from-url": _vm.urlFrom,
          },
          on: {
            deleteFolder: _vm.deleteFolder,
            renameFolder: _vm.renameFolder,
            addMedia: _vm.addMedia,
            makeMeAdmin: _vm.makeMeAdmin,
            createFolder: _vm.createFolder,
            goToFolder: _vm.selectFolder,
            goToMedia: _vm.goToMedia,
            goBack: _vm.historyBack,
            manageAccess: _vm.manageAccess,
            onSearch: _vm.onSearch,
            onCancelSearch: _vm.onCancelSearch,
            toggleEditMode: _vm.toggleEditMode,
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row margin-5-top" },
          [
            _c("users-media-page-filter-panel", {
              attrs: {
                massActionsState: _vm.massActionsState,
                noMedia:
                  !_vm.media.length && !_vm.filter.query && !_vm.filter.type,
                filter: _vm.filter,
                isMyMedia: _vm.isMyMedia,
                isRootFolder: _vm.isRootFolder,
              },
              on: {
                setFilter: _vm.setFilterType,
                changeSharedMode: _vm.changeSharedMode,
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-10 media-folders__items-container" },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "10px",
                      "margin-bottom": "5px",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.fakePreviousFolder ||
                              _vm.folders.length ||
                              _vm.managedFoldersExists,
                            expression:
                              "fakePreviousFolder||folders.length||managedFoldersExists",
                          },
                        ],
                      },
                      [
                        _c("strong", [_vm._v("Folders:")]),
                        _vm._v(" "),
                        _vm.managedFoldersExists && !_vm.filter.managed
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "media-folders__show-managed-toggle",
                                on: {
                                  click: function ($event) {
                                    return _vm.changeManagedMode(true)
                                  },
                                },
                              },
                              [_vm._v("(Show Folders I manage)")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.managedFoldersExists && _vm.filter.managed
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "media-folders__show-managed-toggle",
                                on: {
                                  click: function ($event) {
                                    return _vm.changeManagedMode(false)
                                  },
                                },
                              },
                              [_vm._v("(Hide Folders I manage)")]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("users-media-page-folders-container", {
                  attrs: {
                    massActionsState: _vm.massActionsState,
                    fakePreviousFolder: _vm.fakePreviousFolder,
                    currentUser: _vm.currentUser,
                    folders: _vm.folders,
                    editMode: _vm.editMode,
                    currentlyDraggedMedia: _vm.currentlyDraggedMedia,
                    currentlyUnderDragFolder: _vm.currentlyUnderDragFolder,
                  },
                  on: {
                    resetCurrentlyUnderDragFolder:
                      _vm.resetCurrentlyUnderDragFolder,
                    setCurrentlyUnderDragFolder:
                      _vm.setCurrentlyUnderDragFolder,
                    goBack: _vm.goBack,
                    selectFolder: _vm.selectFolder,
                    hideFolder: _vm.hideFolder,
                  },
                }),
                _vm._v(" "),
                _c("div", { staticStyle: { "margin-left": "10px" } }, [
                  _c(
                    "strong",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.media.length,
                          expression: "media.length",
                        },
                      ],
                    },
                    [_vm._v("Media:")]
                  ),
                ]),
                _vm._v(" "),
                _vm._l(_vm.media, function (mediaItem) {
                  return _c("users-media-page-folder-item", {
                    key: mediaItem.pk,
                    attrs: {
                      massActionsState: _vm.massActionsState,
                      currentlyDraggedMedia: _vm.currentlyDraggedMedia,
                      currentlyUnderDragFolder: _vm.currentlyUnderDragFolder,
                      allowDelete:
                        _vm.editMode && _vm.isItemEditionAllowed(mediaItem),
                      allowDrag:
                        _vm.editMode && _vm.isItemEditionAllowed(mediaItem),
                      "allow-edit":
                        _vm.editMode && _vm.isItemEditionAllowed(mediaItem),
                      attachment: mediaItem,
                      size: _vm.itemSize,
                      showTooltip: false,
                    },
                    on: {
                      setCurrentlyDraggedMedia: _vm.setCurrentlyDraggedMedia,
                      resetCurrentlyDraggedMedia:
                        _vm.resetCurrentlyDraggedMedia,
                      resetCurrentlyUnderDragFolder:
                        _vm.resetCurrentlyUnderDragFolder,
                      dropMedia: _vm.dropMedia,
                      click: function ($event) {
                        return _vm.onMediaClick(mediaItem)
                      },
                      delete: function ($event) {
                        return _vm.deleteMedia(mediaItem.pk)
                      },
                      editName: function ($event) {
                        return _vm.editName(mediaItem)
                      },
                    },
                  })
                }),
                _vm._v(" "),
                !_vm.media.length
                  ? _c(
                      "div",
                      { staticClass: "alert alert-warning text-center" },
                      [
                        _vm._v(
                          "\n                    No Media found...\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", {
          directives: [
            {
              name: "infinite-scroll",
              rawName: "v-infinite-scroll",
              value: _vm.loadMore,
              expression: "loadMore",
            },
          ],
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }