/**
 * Created by orion on 09.05.17.
 */

import angular from 'angular'

// mainFilters
import main_filters from '../shared/filters/filters_module'
import search_input from '../shared/inputs/search_input/search_input_module'
import preload from '../preload/preload'
//shared modules

import simplePopups from '../shared/simple_popups/simple_popup_module';
import notifications from '../shared/notifications/notifications_module';
import infiniteScroll from '../shared/infiniteScroll_directive'
import selectFactory from '../shared/select_factory/selectFactory_module'


//app modules
import add_media from '../add_media/add_media_module'
import view_media from '../view_media/view_media_module'
import groups from '../groups/groups_module'


//pages

//resources
import CommunityResource from './community_resource'
import CommunityStoriesResource from './community_stories_resource'

//directives
import copyCommunityUrlButton from "./copy_button_directive/directive";

//factories
import LikeStoryResourceFactory from '../shared/story/resource_factory'
import CommunityPageController from "../community_page/CommunityPageController";
import CommunitySubscriptionsResource from "./communitu_subscriptions_resource";



angular.module('mmApp.communities',
    ['ui.bootstrap',
        preload, simplePopups, notifications, groups, add_media, view_media, main_filters, search_input, infiniteScroll, selectFactory])
    .factory('CommunityStoryResource', LikeStoryResourceFactory('community_story'))
    .factory('CommunityStoriesStoryResource', LikeStoryResourceFactory('community_stories_story'))
    .factory('CommunityResource', CommunityResource)
    .factory('CommunityStoriesResource', CommunityStoriesResource)
    .factory('CommunitySubscriptionsResource', CommunitySubscriptionsResource)
    .directive('copyCommunityUrlButton', copyCommunityUrlButton)

    .controller('CommunityPageController', CommunityPageController)
;


export default 'mmApp.communities'