var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "receipt-details__alert" }, [
    _vm.receipt
      ? _c(
          "div",
          [
            _c("span", [
              _vm._v("Created by "),
              _c(
                "strong",
                [_c("simple-link", { attrs: { target: _vm.receipt.creator } })],
                1
              ),
            ]),
            _c("br"),
            _vm._v(" "),
            _vm.wikiIncluded
              ? _c("span", { staticClass: "receipt-details__link" }, [
                  _c("i", {
                    staticClass: "far",
                    class: {
                      "fa-check": !_vm.wikiIncluded.is_read,
                      "fa-check-double": _vm.wikiIncluded.is_read,
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Wiki Content Page")]),
                  _vm.sectionsIncluded.length || _vm.slidesGrouped.length
                    ? _c("span", [_vm._v(", ")])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.sectionsIncluded, function (sectionIncluded, i) {
              return _c("span", [
                _c("i", {
                  staticClass: "far",
                  class: {
                    "fa-check": !sectionIncluded.is_read,
                    "fa-check-double": sectionIncluded.is_read,
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "receipt-details__link" }, [
                  _vm._v("\n                Section\n                "),
                  _c("span", [_vm._v(_vm._s(sectionIncluded.name))]),
                  _vm.slidesGrouped.length ||
                  i !== _vm.sectionsIncluded.length - 1
                    ? _c("span", [_vm._v(", ")])
                    : _vm._e(),
                ]),
              ])
            }),
            _vm._v(" "),
            _vm._l(_vm.slidesGrouped, function (slide, i) {
              return _c("span", { staticClass: "receipt-details__link" }, [
                _c("i", {
                  staticClass: "far",
                  class: {
                    "fa-check": !slide.is_read,
                    "fa-check-double": slide.is_read,
                  },
                }),
                _vm._v("\n                Page\n                "),
                _c("span", [_vm._v(_vm._s(slide.name))]),
                i !== _vm.slidesGrouped.length - 1
                  ? _c("span", [_vm._v(", ")])
                  : _vm._e(),
              ])
            }),
            _vm._v(" "),
            _vm.receipt.sender_comment
              ? _c(
                  "div",
                  [
                    _c("br"),
                    _vm._v("\n            Comment:\n            "),
                    _c("render-quote", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.receipt.sender_comment) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.receipt.receiver_comment
              ? _c(
                  "div",
                  [
                    _vm._v("\n            Response:\n            "),
                    _c("render-quote", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.receipt.receiver_comment) +
                          "\n            "
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }