var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.attachmentSnapshot
    ? _c("span", [
        _c("span", [_vm._v("added a new location ")]),
        _vm._v(" "),
        _c("strong", [
          _vm._v("\n        #" + _vm._s(_vm.attachmentSnapshot.id) + " "),
          _vm.attachmentSnapshot.location_address
            ? _c("span", [
                _vm._v(
                  '"' + _vm._s(_vm.attachmentSnapshot.location_address) + '"'
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.attachmentSnapshot.slide_name
          ? _c("span", [
              _vm._v("\n        to page "),
              _c(
                "strong",
                [
                  _vm._v(
                    "#" + _vm._s(_vm.attachmentSnapshot.slide) + "\n        "
                  ),
                  _c("wiki-log-possible-link", {
                    attrs: {
                      target: _vm.wikiLog.target_wiki_slide,
                      label: _vm.attachmentSnapshot.slide_name,
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }