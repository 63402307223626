const defaultSharingState = {
    friends: {
        shared: false,
        has_unapproved: false,
    },
    groups: {
        shared: false,
        has_unapproved: false,
    },
    communities: {
        shared: false,
        has_unapproved: false,
    },
    has_redefined_in_children: false, // matter when private
};

export {defaultSharingState};