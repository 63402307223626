var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "create-entry",
      class: { disabled: _vm.disabled },
      on: { click: _vm.onClick },
    },
    [
      _c("div", { staticClass: "create-entry__icon-part" }),
      _vm._v(" "),
      _c("div", { staticClass: "create-entry__text-part" }, [
        _vm._v("Create a Diary entry about this event\n    "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }