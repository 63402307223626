<template>
    <div class="">
        <vue-simple-header :left-part-size="8" :right-part-size="4">
            <span slot="headerTitle" class="" v-if="!buddyMode">{{ headerLabel }}</span>
            <span slot="headerTitle" class="" v-if="buddyMode">
                <simple-link-with-avatar :target="profile" postfix-label="'s Calendar"></simple-link-with-avatar>
            </span>
            <div slot="panelRight">
                <vue-search-input @search="onSearch" @cancel="onSearchCancel"
                                  class="vertical-top visible-xs-inline-block"></vue-search-input>
                <vue-folder-button :no-text-on-mobile="true" text="Back" icon-class="fa-reply" :top="-3"
                                   class="vertical-top"
                                   @click.native="goBack"></vue-folder-button>
            </div>
        </vue-simple-header>
        <div class="">
            <div class="row margin-10-bottom">
                <div class="col-sm-6">
                    <div class="form-inline">
                        <select v-model="mode" name="" id="" class="form-control">
                            <option value="all">All events</option>
                            <option value="in_calendar">{{ inCalendarLabel }}</option>
                            <option value="not_in_calendar">{{ outOfCalendarLabel }}</option>
                            <option value="past_events">{{ pastEventsLabel }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-6 text-right">
                    <vue-search-input @search="onSearch" @cancel="onSearchCancel"
                                      class="vertical-top hidden-xs"></vue-search-input>
                </div>
            </div>

            <calendar-page-search-event :profile="profile"
                                        :event="event"
                                        v-for="event in events"
                                        :is-my-calendar="isMyCalendar"
                                        :key="event.id"></calendar-page-search-event>
            <div class="text-center" v-show="allLoaded&&!events.length" style="font-size: 21px">
                No results...
            </div>
            <div v-infinite-scroll="loadMore"></div>
        </div>
    </div>

</template>

<script>
    import Vue from 'vue';
    import infiniteScrollMixin from "../../../../vue/mixins/InfiniteScrollMixin";
    import CalendarPageSearchEvent from './CalendarPageSearchEvent.vue';
    import backButtonMixin from "../../../../vue/mixins/BackButtonMixin";
    import {DjangoUrls} from "DjangoUrls";
    import {CurrentUserClass} from "shared/CurrentUser";

    export default {
        name: "CalendarPageSearch",
        mixins: [infiniteScrollMixin, backButtonMixin],
        components: {CalendarPageSearchEvent},
        props: {
            membershipState: Object
        },
        data() {
            return {
                totalCount: [],
                mode: 'all',
            };
        },
        computed: {
            defaultBackUrl() {
                let id = 'my';
                if (new CurrentUserClass().id !== this.profile.id) {
                    id = this.profile.id;
                }
                return DjangoUrls['users_calendar'](id);
            },
            buddyMode() {
                return this.membershipState.buddyMode;
            },
            profile() {
                return this.membershipState.profile;
            },
            headerLabel() {
                if (!this.buddyMode) {
                    return 'My Calendar';
                } else {
                    return `${this.profile.name}'s Calendar`;
                }
            },
            isMyCalendar() {
                return new CurrentUserClass().id === this.profile.id;
            },
            inCalendarLabel() {
                if (this.isMyCalendar) {
                    return 'Events in my Calendar';
                } else {
                    return `Events in ${this.profile.name}'s Calendar`;
                }
            },
            outOfCalendarLabel() {
                if (this.isMyCalendar) {
                    return 'Events not in my Calendar';
                } else {
                    return `Events not in ${this.profile.name}'s Calendar`;
                }
            },
            pastEventsLabel() {
                return 'Archived Events';
            },
            events() {
                return this.results;
            }
        },
        methods: {
            goToCalendar() {
                this.$router.replace({
                    name: 'calendar',
                    params: {
                        date: this.date,
                    },
                    hash: this.$route.hash,
                    query: this.$route.query,
                });
            },
            getListEndpoint() {
                const UserEventsResource = Vue.getAngularModule('UserEventsResource');
                return UserEventsResource.search_events;
            },
            getListEndpointArgs() {
                return [this.profile.id, this.mode];
            }
        },
        watch: {
            mode(n, o) {
                this.onSearch(this.query);
            }
        }
    };
</script>

<style scoped lang="scss">

</style>