var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { transition: 0, "append-to-body": true, backdrop: false },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          attrs: { id: "close_popup" },
          on: { click: _vm.closePopup },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.header))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c(
            "form",
            { staticClass: "form" },
            [
              _c("span", [_vm._v("Start Date:")]),
              _vm._v(" "),
              _c(
                "datepicker",
                {
                  ref: "start-date-picker",
                  attrs: {
                    "disabled-dates": _vm.disabledDatesStart,
                    format: _vm.customFormatter,
                    "input-class": "create-event-date-picker",
                    "bootstrap-styling": true,
                    typeable: false,
                    highlighted: _vm.highlightedDates,
                  },
                  nativeOn: {
                    keydown: [
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "delete",
                            [8, 46],
                            $event.key,
                            ["Backspace", "Delete", "Del"]
                          )
                        )
                          return null
                        return _vm.startDateClear.apply(null, arguments)
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "backspace",
                            undefined,
                            $event.key,
                            undefined
                          )
                        )
                          return null
                        return _vm.startDateClear.apply(null, arguments)
                      },
                    ],
                  },
                  model: {
                    value: _vm.taskStartDate,
                    callback: function ($$v) {
                      _vm.taskStartDate = $$v
                    },
                    expression: "taskStartDate",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "input-group-btn",
                      attrs: { slot: "afterDateInput" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.toggleStartDateCalendar.apply(
                            null,
                            arguments
                          )
                        },
                      },
                      slot: "afterDateInput",
                    },
                    [
                      _c(
                        "btn",
                        {
                          staticClass:
                            "dropdown-toggle create-event-date-picker",
                        },
                        [
                          _c("i", {
                            staticClass: "glyphicon glyphicon-calendar",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("hr", { staticClass: "hr10" }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c(
            "form",
            { staticClass: "form" },
            [
              _c("span", [_vm._v("End Date:")]),
              _vm._v(" "),
              _c(
                "datepicker",
                {
                  ref: "end-date-picker",
                  attrs: {
                    "disabled-dates": _vm.disabledDatesEnd,
                    format: _vm.customFormatter,
                    "input-class": "create-event-date-picker",
                    "bootstrap-styling": true,
                    typeable: false,
                    highlighted: _vm.highlightedDates,
                  },
                  nativeOn: {
                    keydown: [
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "delete",
                            [8, 46],
                            $event.key,
                            ["Backspace", "Delete", "Del"]
                          )
                        )
                          return null
                        return _vm.endDateClear.apply(null, arguments)
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "backspace",
                            undefined,
                            $event.key,
                            undefined
                          )
                        )
                          return null
                        return _vm.endDateClear.apply(null, arguments)
                      },
                    ],
                  },
                  model: {
                    value: _vm.taskEndDate,
                    callback: function ($$v) {
                      _vm.taskEndDate = $$v
                    },
                    expression: "taskEndDate",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "input-group-btn",
                      attrs: { slot: "afterDateInput" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.toggleEndDateCalendar.apply(
                            null,
                            arguments
                          )
                        },
                      },
                      slot: "afterDateInput",
                    },
                    [
                      _c(
                        "btn",
                        {
                          staticClass:
                            "dropdown-toggle create-event-date-picker",
                        },
                        [
                          _c("i", {
                            staticClass: "glyphicon glyphicon-calendar",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("hr", { staticClass: "hr10" }),
        ]),
      ]),
      _vm._v(" "),
      _vm.relatedToEvent
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("hr", { staticClass: "hr10" }),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.allowCreateSteps
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("vue-switch-with-label", {
                  attrs: { name: "create-steps", label: "Create Sub-steps" },
                  model: {
                    value: _vm.createSteps,
                    callback: function ($$v) {
                      _vm.createSteps = $$v
                    },
                    expression: "createSteps",
                  },
                }),
                _vm._v(" "),
                _c("hr", { staticClass: "hr10" }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("vue-switch-with-label", {
              attrs: {
                name: "auto-complete",
                disabled: _vm.timeOptionsDisabled,
                label: _vm.autoCompleteLabel,
              },
              model: {
                value: _vm.autoComplete,
                callback: function ($$v) {
                  _vm.autoComplete = $$v
                },
                expression: "autoComplete",
              },
            }),
            _vm._v(" "),
            _c("hr", { staticClass: "hr10" }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("vue-switch-with-label", {
              attrs: {
                name: "notify-due",
                disabled: _vm.timeOptionsDisabled,
                label: "Notify assigned user when finished",
              },
              model: {
                value: _vm.notifyWhenDue,
                callback: function ($$v) {
                  _vm.notifyWhenDue = $$v
                },
                expression: "notifyWhenDue",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          { staticClass: "btn btn-mm", on: { click: _vm.closePopup } },
          [_vm._v(_vm._s(_vm.cancelButton))]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-green save-button",
            on: { click: _vm.save },
          },
          [_vm._v("\n            " + _vm._s(_vm.saveButton) + "\n        ")]
        ),
      ]),
      _vm._v(" "),
      _c("event-detail-page-period-selector-popup-with-another-date-option", {
        ref: "period-selector",
      }),
      _vm._v(" "),
      _c("event-find-occurrence-popup", {
        ref: "find-occurrence-popup",
        attrs: { "allow-multiple": true },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }