<template>
    <div
            class="receipt-details__alert"
    >
        <div v-if="receipt">
            <span>Created by <strong><simple-link :target="receipt.creator"></simple-link></strong></span><br/>
            <span v-if="wikiIncluded"
                  class="receipt-details__link"
            >
                <i class="far" :class="{
                    'fa-check':!wikiIncluded.is_read,
                    'fa-check-double':wikiIncluded.is_read
                }"></i>
                <span>Wiki Content Page</span><span v-if="sectionsIncluded.length||slidesGrouped.length">, </span>
            </span>
            <span v-for="(sectionIncluded, i) in sectionsIncluded">
            <i class="far" :class="{
                    'fa-check':!sectionIncluded.is_read,
                    'fa-check-double':sectionIncluded.is_read
            }"></i>
                <span class="receipt-details__link">
                    Section
                    <span>{{ sectionIncluded.name }}</span><span
                        v-if="slidesGrouped.length||(i!==(sectionsIncluded.length-1))">, </span>
                </span>
            </span>

            <span
                    v-for="(slide, i) in slidesGrouped"
                    class="receipt-details__link"
            >
                    <i class="far" :class="{
                        'fa-check':!slide.is_read,
                        'fa-check-double':slide.is_read
                         }">
                    </i>
                    Page
                    <span>{{ slide.name }}</span><span v-if="i!==(slidesGrouped.length-1)">, </span>
            </span>
            <div v-if="receipt.sender_comment">
                <br>
                Comment:
                <render-quote>
                    {{ receipt.sender_comment }}
                </render-quote>
                <br>
            </div>
            <div v-if="receipt.receiver_comment">
                Response:
                <render-quote>
                    {{ receipt.receiver_comment }}
                </render-quote>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import RenderQuote from "shared/quote_directive/renderQuote";

    export default {
        name: "WikiReadReceiptDetails",
        components: {RenderQuote},
        props: {
            receipt: Object,
        },
        data() {
            return {
                read: false,
                receiverComment: '',
            };
        },
        computed: {
            wikiIncluded() {
                return this.receipt?.wikis[0];
            },
            sectionsIncluded() {
                return this.receipt?.wiki_sections || [];
            },
            slidesGrouped() {
                if (!this.receipt?.wiki_slides) {
                    return [];
                }
                return _.orderBy(this.receipt.wiki_slides, s => s.wiki_section.id);
            },
            allRead() {
                if (!this.receipt) {
                    return false;
                }
                if (this.wikiIncluded && !this.wikiIncluded.is_read) {
                    return false;
                }
                for (const i of this.sectionsIncluded) {
                    if (!i.is_read) {
                        return false;
                    }
                }
                for (const i of this.slidesGrouped) {
                    if (!i.is_read) {
                        return false;
                    }
                }
                return true;
            },
        },
        methods: {},
    };
</script>

<style scoped lang="scss">
  .receipt-details {
    &__link {
    }

    &__alert {
      transition: all .5s ease;
      font-size: 15px;
    }
  }
</style>