/**
 * Created by orion on 06.03.17.
 */

function config_app(mmApp) {
    mmApp.config([
        '$httpProvider', function (http_provider) {
            http_provider.defaults.xsrfCookieName = CSRF_COOKIE_NAME;
            http_provider.defaults.xsrfHeaderName = 'X-CSRFToken';
            return http_provider;
        }
    ]);
    mmApp.config(['$interpolateProvider', function ($interpolateProvider) {
        return $interpolateProvider.startSymbol('{$').endSymbol('$}');

    }]);
    mmApp.config(['$locationProvider', function ($locationProvider) {
        return $locationProvider.html5Mode({
            enabled: window.HTML5_MODE === undefined ? false : true,
            requireBase: false,
            rewriteLinks: false
        });

    }]);
    mmApp.config(['$touchProvider', function ($touchProvider) {
        return $touchProvider.ngClickOverrideEnabled(false);
    }]);

    mmApp.config(['$qProvider', function ($qProvider) {
        $qProvider.errorOnUnhandledRejections(false);
    }]);

    mmApp.config(['$httpProvider', function ($httpProvider) {
        $httpProvider.defaults.headers.common['Cache-Control'] = 'no-cache';
        $httpProvider.defaults.cache = false;
    }]);

    mmApp.config(['$logProvider', function ($logProvider) {
        $logProvider.debugEnabled(DEBUG_LOG_ENABLED);
    }]);

}

export default config_app;
