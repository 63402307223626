<template>
    <basic-modal
            @hide="close"
            v-model="opened"
            aria-label="Confidentiality agreement"
            role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">Confidentiality agreement</h3>
        </div>
        <div class="row margin-15-bottom">
            <div class="col-sm-12">
                Welcome to our software. As part of our commitment to protecting RIX Software users’ privacy, we require
                all
                users
                to comply with the Confidentiality NHS Code of Practice. By logging in, you confirm that you will not
                share
                any
                patient information with unauthorized individuals. Please take a moment to review NHS
                <strong><a
                        href="https://www.gov.uk/government/publications/confidentiality-nhs-code-of-practice"
                >
                    confidentiality policies</a></strong>
                and
                contact our <strong><a href="mailto:support@multime.com">support team</a></strong> if you have any questions or concerns.
                Thank you for your cooperation in maintaining
                patient confidentiality
            </div>
        </div>
        <div class="row margin-30-top">
            <div class="col-sm-12">
                <vue-switch-with-label
                        v-model="confirmed"
                        label="I have read and understood this confidentiality agreement"
                        name="confirmed"/>
            </div>
        </div>
        <div slot="footer" class="row">
            <div class="col-xs-12 text-right">
                <button class="btn btn-mm btn-green" :disabled="!confirmed" @click="logIn">Log in</button>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import BasicModalCloseButton from '../../../../vue/common_components/BasicModalCloseButton.vue';
    import BasicModal from '../../../../vue/common_components/BasicModal.vue';
    import popupPromiseMixin from '../../../../vue/mixins/PopupPromiseMixin';

    export default {
        name: 'AccessLinkConsentPopup',
        components: {
            BasicModal,
            BasicModalCloseButton
        },
        mixins: [popupPromiseMixin],
        data () {
            return {
                confirmed: false,
            };
        },
        methods: {
            logIn () {
                this.save(true);
            }
        },

    };
</script>

<style scoped>

</style>
