import { DjangoUrls } from 'DjangoUrls';
import axios from 'axios';
import { ACCESS_LINK_TOKEN_HEADER_NAME } from '../wiki/common';

const PREFIX = 'api.v1:diary_logs-';

const diary_logs_resource = {
    get_log_day(locationId, params, accessToken) {
        const url = DjangoUrls[`${PREFIX}get-log-day`](locationId);
        const headers = {};
        if (accessToken) {
            headers[ACCESS_LINK_TOKEN_HEADER_NAME] = accessToken;
        }
        return axios({
            method: 'get',
            url,
            params,
            headers,
        });
    },

    get_filled_form_details(locationId, answerId, accessCode, accessToken) {
        const url = DjangoUrls[`${PREFIX}get-filled-form-details`](locationId);
        const headers = {};
        if (accessToken) {
            headers[ACCESS_LINK_TOKEN_HEADER_NAME] = accessToken;
        }
        return axios({
            method: 'get',
            url,
            params: {
                access_code: accessCode,
                id: answerId,
            },
            headers,
        });
    },

    get_users_to_alert_about_form(locationId, id_list, params) {
        const url = DjangoUrls[`${PREFIX}get-users-to-alert-about-form`](locationId);
        return axios({
            method: 'GET',
            url,
            params: {
                id_list,
                ...params
            }
        });
    },

    get_users_goals_for_link(locationId, params) {
        const url = DjangoUrls[`${PREFIX}get-users-goals-for-link`](locationId);
        return axios({
            method: 'GET',
            url,
            params
        });
    },

    get_users_tasks_for_link_in_goal(locationId, goal_id, params) {
        const url = DjangoUrls[`${PREFIX}get-users-tasks-for-link-in-goal`](locationId);
        return axios({
            method: 'GET',
            url,
            params: Object.assign({}, { id: goal_id }, params)
        });
    },

};

export default diary_logs_resource;
