var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(
              _vm._s(_vm.createMode ? "Create" : "Update") +
                " " +
                _vm._s(_vm._f("customTerm")("Goal"))
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.forUserForcedUsername
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", { staticClass: "alert alert-info" }, [
                _c("strong", [_vm._v("Attention!")]),
                _vm._v(
                  " You are creating a " +
                    _vm._s(_vm._f("customTerm")("Goal")) +
                    " for "
                ),
                _c("strong", [_vm._v(_vm._s(_vm.forUserForcedUsername))]),
                _vm._v(".\n                You will be the admin, "),
                _c("strong", [_vm._v(_vm._s(_vm.forUserForcedUsername))]),
                _vm._v(" will be added as member.\n            "),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", [
            _c("span", [_vm._v(_vm._s(_vm.goalNameLabel) + ":")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.goalName,
                  expression: "goalName",
                },
              ],
              ref: "nameInput",
              staticClass: "form-control",
              attrs: {
                maxlength: "100",
                type: "text",
                placeholder: _vm.goalNameLabel,
                disabled: _vm.saving,
                required: "",
              },
              domProps: { value: _vm.goalName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.goalName = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-5-top" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", [
            _c("span", [
              _vm._v(_vm._s(_vm._f("customTerm")("Goal")) + " Category:"),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedCategory,
                    expression: "selectedCategory",
                  },
                ],
                staticClass: "form-control",
                attrs: { name: "", id: "" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedCategory = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              _vm._l(_vm.availableCategories, function (category) {
                return _c(
                  "option",
                  {
                    key: category.id,
                    attrs: { disabled: _vm.saving },
                    domProps: { value: category.id },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(category.name) +
                        "\n                    "
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      !_vm.descriptionExpanded
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-12", staticStyle: { margin: "15px 0" } },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "#", role: "button" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        _vm.descriptionExpanded = true
                      },
                    },
                  },
                  [_vm._v("Add description")]
                ),
              ]
            ),
          ])
        : _c("div", { staticClass: "row margin-15-top" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _vm._v("\n            Description:\n            "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.goalDescription,
                    expression: "goalDescription",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  placeholder: _vm.goalDescriptionPlaceholder,
                  minlength: "2",
                  maxlength: "4000",
                  rows: "4",
                  disabled: _vm.saving,
                },
                domProps: { value: _vm.goalDescription },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.goalDescription = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("hr"),
            ]),
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        !_vm.goalAvatar
          ? _c("div", { staticClass: "col-sm-4" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-mm btn-blue text-left",
                  staticStyle: { width: "173px", padding: "7px 15px 13px 0" },
                  attrs: {
                    "aria-label": "Add a picture",
                    disabled: _vm.saving,
                  },
                  on: { click: _vm.addAvatar },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "vertical-middle",
                      staticStyle: {
                        "text-align": "center",
                        display: "inline-block",
                      },
                      attrs: { "aria-hidden": "true" },
                    },
                    [_c("i", { staticClass: "fa fa-image fa-2" })]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "vertical-middle" }, [
                    _vm._v(" + Add Picture"),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.goalAvatar
          ? _c("div", { staticClass: "col-sm-8 hidden-xs" }, [
              _vm._v("\n            Add a picture.\n        "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.goalAvatar
          ? _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _vm._v("\n            Avatar:"),
                _c("br"),
                _vm._v(" "),
                _c("media-preview", {
                  attrs: { item: _vm.goalAvatar, "allow-delete": "" },
                  on: { onDelete: _vm.resetAvatar },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        !_vm.goalAudioCaption
          ? _c("div", { staticClass: "col-sm-4" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-mm btn-blue text-left",
                  staticStyle: { width: "173px", padding: "7px 15px 13px 0" },
                  attrs: {
                    "aria-label": "Add a picture",
                    disabled: _vm.saving,
                  },
                  on: { click: _vm.addAudio },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "vertical-middle",
                      staticStyle: {
                        "text-align": "center",
                        display: "inline-block",
                      },
                      attrs: { "aria-hidden": "true" },
                    },
                    [_c("i", { staticClass: "fa fa-microphone fa-2" })]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "vertical-middle" }, [
                    _vm._v(" + Add Audio"),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.goalAudioCaption
          ? _c("div", { staticClass: "col-sm-8 hidden-xs" }, [
              _vm._v(
                "\n            Add an audio caption so users can listen to the name.\n        "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.goalAudioCaption
          ? _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _vm._v("\n            Audio caption:"),
                _c("br"),
                _vm._v(" "),
                _c("media-preview", {
                  attrs: {
                    item: _vm.goalAudioCaption,
                    "allow-delete": "",
                    "show-name": "",
                  },
                  on: { onDelete: _vm.resetAudio },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c(
          "div",
          { staticClass: "col-sm-5" },
          [
            _c("vue-switch-with-label", {
              attrs: { label: _vm.hideTasksLabel, name: "hideTasks" },
              model: {
                value: _vm.hideTasks,
                callback: function ($$v) {
                  _vm.hideTasks = $$v
                },
                expression: "hideTasks",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-7" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm._f("customTerm")("Tasks")) +
              " will be visible only to their assignee. Admins will see\n            all " +
              _vm._s(_vm._f("customTerm")("Tasks")) +
              " anyway.\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.editMode
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c(
              "div",
              { staticClass: "col-sm-5" },
              [
                _c("vue-switch-with-label", {
                  attrs: { label: _vm.finishGoalLabel, name: "finishGoal" },
                  model: {
                    value: _vm.finishGoal,
                    callback: function ($$v) {
                      _vm.finishGoal = $$v
                    },
                    expression: "finishGoal",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-7" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("customTerm")("Goal")) +
                  " will be marked as finished for everyone.\n        "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.createMode && _vm.forUserCreationAllowed
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c(
              "div",
              { staticClass: "col-sm-4" },
              [
                _c("vue-switch-with-label", {
                  attrs: { label: "Create for user", name: "hideTasks" },
                  model: {
                    value: _vm.isForUser,
                    callback: function ($$v) {
                      _vm.isForUser = $$v
                    },
                    expression: "isForUser",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-8" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("customTerm")("Goal")) +
                  " will belong to selected user. You will be that " +
                  _vm._s(_vm._f("customTerm")("Goal")) +
                  " admin.\n            User will be added as member.\n        "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isForUser && _vm.forUserCreationAllowed && _vm.createMode
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _vm._v("\n            For User:\n            "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.forUser,
                      expression: "forUser",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { "aria-label": "For user" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.forUser = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.forUserUsernamesList, function (username) {
                  return _c(
                    "option",
                    { key: username, domProps: { value: username } },
                    [_vm._v(_vm._s(username) + "\n                ")]
                  )
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xs-4 text-left" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-red",
                attrs: { disabled: _vm.saving },
                on: { click: _vm.deleteGoal },
              },
              [_vm._v("\n                    Delete\n                ")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-8" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-default",
                attrs: { disabled: _vm.saving },
                on: { click: _vm.close },
              },
              [_vm._v("\n                    Cancel\n                ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-green save-button",
                staticStyle: { "min-width": "80px" },
                attrs: { disabled: _vm.saving || !_vm.goalName },
                on: { click: _vm.ok },
              },
              [
                !_vm.saving
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.editMode ? "Save" : "Create")),
                    ])
                  : _c("span", [
                      _c("i", { staticClass: "far fa-spinner fa-spin" }),
                    ]),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }