<template>
    <div class="slide" :style="[draggingStyle]" :class="{underDrag}">
        <media-preview
                :item="slide.media_content"
                :text="slideName"
                :show-name="true"
                :size="100"
                :high-light="isActive||underDrag"
                :high-light-color="underDrag?'#64bd63':undefined"
                @click.native="onClick"
        ></media-preview>
        <i class="fa fa-arrows-alt slide__dragdrop-grip"
           @mousedown.stop.prevent="dragGripClicked"
           @mouseenter
           @touchstart.stop.prevent="dragGripTouched"
           v-show="editMode && !slide.is_fixed"
           :style="dragGripStyle"
           @click.stop.prevent></i>
        <transition name="fade-out">
            <div
                    v-if="(showChangedMark||showNewMark)&&!wasActive&&!editMode"
                    class="slide__unread-mark"
                    :class="{
                        'slide__unread-mark_changed': showChangedMark,
                        'slide__unread-mark_new': showNewMark,
                    }"
            ><span v-if="showChangedMark">UPD</span><span v-if="showNewMark">NEW</span></div>
        </transition>
        <i class="fas fa-arrow-from-top fa-rotate-180 slide__move-icon"
           :class="{
            'slide__move-icon_selected-to-move': isSelectedToMove
           }"
           v-show="editMode&&wikiEditable && !slide.is_fixed"
           @click.stop.prevent="toggleSlideSelect"
        ></i>
    </div>
</template>

<script>
    import MouseEventsMixin from "../../../vue/mixins/MouseEventsMixin";

    const MAX_TITLE_LENGTH = 10;
    export default {
        name: "WikiDetailPageSliderSlide",
        mixins: [MouseEventsMixin],
        props: {
            slide: Object,
            slides: Array,
            activeSlide: Object,
            editMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                offsetBeforeDrag: undefined,
                positionBeforeDragX: undefined,
                positionBeforeDragY: undefined,
                currentPositionX: undefined,
                currentPositionY: undefined,
                offset: 0,

                wasActive: false,
            };
        },
        computed: {
            isSelectedToMove() {
                return this.$store.getters['WikiStore/selectedToMoveSlidesIdList'].includes(this.slide.id);
            },
            underDrag() {
                return this.$store.getters['WikiStore/underDragSlide'] === this.slide;
            },
            isDragged() {
                return this.$store.getters['WikiStore/draggedSlide'] === this.slide;
            },
            wikiEditable() {
                return this.$store.getters['WikiStore/wikiEditable'];
            },
            currentSectionLastViewTime() {
                return this.$store.getters['WikiStore/currentSection'].last_view_time;
            },
            updateBadgesVisible() {
                return this.$store.getters['WikiStore/viewerState'].canSeeUpdateBadges;
            },
            showNewMark() {
                if (this.updateBadgesVisible) {
                    if (!this.currentSectionLastViewTime || (this.slide.created_at > this.currentSectionLastViewTime)) {
                        return true;
                    }
                }
            },
            showChangedMark() {
                if (this.updateBadgesVisible && !this.showNewMark) {
                    if (!this.currentSectionLastViewTime || (this.slide.last_updated_time > this.currentSectionLastViewTime)) {
                        return true;
                    }
                }
            },
            isActive() {
                return this.slide === this.activeSlide;
            },
            slideName() {
                const slideNum = this.slides.indexOf(this.slide);
                let name = '';
                if (!this.slide.caption) {
                    name = 'Untitled';
                } else {
                    name = this.$options.filters.cutName(this.slide.caption, MAX_TITLE_LENGTH);
                }
                return slideNum + 1 + '.&nbsp;' + name;
            },
            draggingStyle() {
                const style = {};
                if (!this.isDragged) {
                    return style;
                }
                if (!this.offsetBeforeDrag) {
                    this.offsetBeforeDrag = this.offset;
                }
                const xCoord = this.currentPositionX - this.positionBeforeDragX - (this.offsetBeforeDrag - this.offset);
                return {
                    'z-index': 9999,
                    'pointer-events': 'none',
                    'cursor': 'grabbing',
                    'opacity': '0.7',
                    'transform': `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, ${xCoord}, ${this.currentPositionY - this.positionBeforeDragY}, 0, 1)`
                };
            },
            dragGripStyle() {
                const style = {'cursor': 'grab'};
                if (this.isDragged) {
                    style['cursor'] = 'grabbing !important';
                }
                return style;
            }
        },
        methods: {
            onClick() {
                this.$emit('onClick', this.slide);
            },
            toggleSlideSelect() {
                this.$store.commit('WikiStore/toggleSelectToMoveSlidesId', this.slide.id);
            },
            handleDragStart(coords) {
                this.$store.commit('WikiStore/setDraggedSlide', this.slide);
                this.currentPositionX = this.positionBeforeDragX = coords.x;
                this.currentPositionY = this.positionBeforeDragY = coords.y;
            },
            dragGripClicked(e) {
                this.handleDragStart({x: e.clientX, y: e.clientY});
            },
            dragGripTouched(e) {
                if (e.targetTouches.length !== 1) {
                    return;
                }
                this.handleDragStart({x: e.targetTouches[0].clientX, y: e.targetTouches[0].clientY});
            },
            onMouseUp() {
                if (this.isDragged) {
                    this.$store.dispatch('WikiStore/slideDropped')
                        .then(() => {
                            this.$store.commit('WikiStore/setDraggedSlide', undefined);
                            this.$store.commit('WikiStore/setUnderDragSlide', undefined);
                        });
                }
            },
            onPointerMove(coords) {
                if (this.isDragged) {
                    this.currentPositionX = coords.x;
                    this.currentPositionY = coords.y;
                }
                if (!this.isDragged && this.$store.getters['WikiStore/draggedSlide']) {
                    let rect = this.$el.getBoundingClientRect();
                    const inY = (rect.y < coords.y) && ((rect.y + rect.height) > coords.y);
                    const inX = (rect.x < coords.x) && ((rect.x + rect.width) > coords.x);
                    if (inX && inY) {
                        if (this.$store.getters['WikiStore/underDragSlide'] !== this.slide) {
                            this.$store.commit('WikiStore/setUnderDragSlide', this.slide);
                        }
                    } else {
                        if (this.$store.getters['WikiStore/underDragSlide'] === this.slide) {
                            this.$store.commit('WikiStore/setUnderDragSlide', undefined);
                        }
                    }
                }
            },
            onTouchEnd(e) {
                if (!this.isDragged) {
                    return;
                }
                if (e.changedTouches.length !== 1) {
                    return;
                }
                let coords = {x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY};
                let el = document.elementFromPoint(coords.x, coords.y);
                let x = document.createEvent("MouseEvent");
                x.initMouseEvent("mouseup", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                el.dispatchEvent(x);
            }
        },
        watch: {
            editMode(n) {
                if (!n) {
                    this.$store.commit('WikiStore/clearSelectedToMoveSlidesIdList');
                }
            },
            isActive(n) {
                if (n) {
                    this.wasActive = true;
                }
            },
        },
        mounted() {
            this.$store.commit('WikiStore/clearSelectedToMoveSlidesIdList');
            if (this.isActive) {
                this.wasActive = true;
            }
        },
        beforeDestroy() {
            this.$store.commit('WikiStore/clearSelectedToMoveSlidesIdList');
        }
    };
</script>

<style scoped lang="scss">
  @import "../styles/const";

  .slide {
    display: inline-block;
    margin: 5px;
    position: relative;

    &__unread-mark {
      position: absolute;
      left: 2px;
      top: 2px;
      line-height: 19px;
      display: inline-block;

      border: solid 3px #fff;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      text-align: center;
      font-size: 8px;
      color: #fff;

      &_changed {
        background-color: $blue;
      }

      &_new {
        background-color: $online-green;
      }
    }

    &__dragdrop-grip {
      position: absolute;
      left: 2px;
      top: 2px;
      line-height: 19px;
      cursor: grabbing !important;
      border: 3px solid #148ccb;
      display: inline-block;
      background-color: white;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      text-align: center;
      font-size: 17px;
      color: $dark-blue;
    }

    &__move-icon {
      position: absolute;
      cursor: pointer;
      right: 2px;
      top: 2px;
      border: 3px solid #148ccb;
      display: inline-block;
      background-color: white;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      text-align: center;
      font-size: 18px;
      line-height: 22px;
      color: $gray;

      &_selected-to-move {
        color: $grass-green;
      }
    }


    &__title {
      font-size: 12pt;
      width: 100px;
      height: 40px;
      overflow: hidden;
      word-wrap: break-word;
    }
  }

  .fade-out-leave-active {
    transition: opacity 3s ease;
  }

  .fade-out-leave-to {
    opacity: 0;
  }


</style>