<template>
    <div class="">
        <div v-if="allLoaded&&!results.length" class="margin-15-top">
            <slot name="empty"></slot>
        </div>
        <story-list-item
                :item="item"
                :edit-mode="editMode"
                :allow-like="allowLike"
                v-for="item in results"
                :key="item.id"
                @onDrop="onDrop"
                @del="del(item)"
                @edit="editStoryItem(item)"
                @moveUp="moveUp(item)"
                @moveDown="moveDown(item)"

                @like="like(item)"
                @unlike="unlike(item)"
                @showWhoLiked="showWhoLiked(item)"
        ></story-list-item>
        <div class="row" v-if="allowAddContent">
            <div class="col-sm-12 text-right">
                <button v-if="allowBulkCreate"
                        class="btn btn-mm btn-default margin-5-top"
                        @click="bulkUpload"><i class="fa fa-plus"></i> Bulk Media Upload
                </button>
                <button class="btn btn-mm btn-green margin-5-top" @click="addPost"><i class="fa fa-plus"></i> {{
                        addButtonText
                    }}
                </button>
            </div>
        </div>
        <div v-infinite-scroll="loadMore"></div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import InfiniteScrollMixin from '../../../../vue/mixins/InfiniteScrollMixin';
    import StoryListItem from 'shared/story/StoryListItem';
    import { simplePopupFactory } from '../../shared/simple_popups/simplePopupFactory';
    import CreateEditPostPopup from '../../add_media/CreateEditPostPopup';
    import _ from 'lodash';
    import { ACCESS_LINK_TOKEN_HEADER_NAME } from '../../wiki/common';

    export default {
        name: 'StoryList',
        components: { StoryListItem },
        mixins: [InfiniteScrollMixin],
        props: {
            targetId: {
                type: [Number, String]
            },
            storyResource: {
                type: Object
            },
            editMode: {
                type: Boolean,
                default: false,
            },
            allowAddContent: {
                type: Boolean,
                default: false,
            },
            allowBulkCreate: {
                type: Boolean,
                default: false,
            },
            allowLike: {
                type: Boolean,
                default: false,
            },
            likesResource: {
                type: Object,
                required: false,
            },
            addButtonText: {
                type: String,
                default: 'Add Post',
            },
            accessCode: {
                type: String,
                required: false,
            },
            accessToken: {
                type: String,
                required: false,
            },
        },
        data () {
            return {
                limit: 20,
                showDimmerOnLoadMore: true,

                likePendingBlock: false,
            };
        },
        methods: {
            getListEndpointAdditionalParams () {
                if (this.accessCode) {
                    return {
                        'access_code': this.accessCode
                    };
                }
                return {};
            },
            getListEndpointAdditionalHeaders () {
                if (this.accessToken) {
                    return {
                        [ACCESS_LINK_TOKEN_HEADER_NAME]: this.accessToken
                    };
                }
                return {};
            },
            onDrop (postToMoveId, targetPostId) {
                const postToMoveIndex = this.results.map(t => t.id).indexOf(postToMoveId);
                const postToMove = this.results[postToMoveIndex];
                const targetPostIndex = this.results.map(t => t.id).indexOf(targetPostId);
                const correction = postToMoveIndex > targetPostIndex ? 0 : 1;
                this.results.splice(postToMoveIndex, 1);
                const newTargetPostIndex = this.results.map(t => t.id).indexOf(targetPostId);
                this.results.splice(newTargetPostIndex + correction, 0, postToMove);

                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                this.storyResource.reorder_items_in_infinite_list(this.targetId, this.results.map(item => item.id))
                  .then(function (resp) {
                      Vue.notifications.success('Items was successfully reordered');
                  }, err => {
                      Vue.notifications.error(err || 'Error');
                  })
                  .finally($rootScope.hide_dimmer);
            },
            like (item) {
                if (this.likePendingBlock) {
                    return;
                }
                item.liked_by_me = true;
                item.likes_count += 1;
                this.likePendingBlock = true;
                this.likesResource.like(item.id)
                  .then(resp => {
                      item.likes_count = resp.data.likes_count;
                      this.likePendingBlock = false;
                      Vue.notifications.success('Liked');
                  }, err => {
                      item.liked_by_me = false;
                      item.likes_count -= 1;
                      Vue.notificataions(err || 'Error');
                      this.likePendingBlock = false;
                  });
            },
            unlike (item) {
                if (this.likePendingBlock) {
                    return;
                }
                item.liked_by_me = false;
                item.likes_count -= 1;
                this.likePendingBlock = true;
                this.likesResource.unlike(item.id)
                  .then(resp => {
                      item.likes_count = resp.data.likes_count;
                      this.likePendingBlock = false;
                      Vue.notifications.success('Unliked');
                  }, err => {
                      item.liked_by_me = true;
                      item.likes_count += 1;
                      Vue.notificataions(err || 'Error');
                      this.likePendingBlock = false;
                  });
            },
            showWhoLiked (item) {
                const selectFactory = Vue.getAngularModule('selectFactory');
                selectFactory.endpoint_selector(_.partial(this.likesResource.get_liked_users, item.id), false, {
                    button_no: 'Close',
                    button_yes: false,
                    info: false,
                    disable_search: true,
                    list_mode: true,
                    header: 'Likes',
                    empty: 'There are not any likes yet',
                }).then(_.noop, _.noop);
            },
            del (item) {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                simplePopupFactory.show_popup('Delete Item', 'Are you sure you want to delete this entry?', 'Delete', 'Cancel', 'btn-red')
                  .then(() => {
                      $rootScope.show_dimmer();
                      return this.storyResource.remove_item(this.targetId, item.id);
                  }, () => new Promise(() => {
                  }))
                  .then(resp => {
                      this.results = this.results.filter(i => i.id !== item.id);
                      this.totalCount--;
                      Vue.notifications.success('Deleted');
                  }, err => Vue.notifications.error(err || 'Error'))
                  .finally($rootScope.hide_dimmer);
            },
            moveDown (item) {
                const itemIndex = this.results.indexOf(item);
                if (itemIndex === (this.results.length - 1)) {
                    Vue.notifications.warning('Entry is already last');
                    return;
                } else {
                    this.onDrop(this.results[itemIndex].id, this.results[itemIndex + 1].id);
                }
            },
            moveUp (item) {
                const itemIndex = this.results.indexOf(item);
                if (itemIndex === 0) {
                    Vue.notifications.warning('Entry is already first');
                    return;
                } else {
                    this.onDrop(this.results[itemIndex].id, this.results[itemIndex - 1].id);
                }
            },
            editStoryItem (item) {
                const {
                    vm,
                    popupComponent,
                    fEl
                } = simplePopupFactory.mount_vue_popup(CreateEditPostPopup);
                const stickersFactory = Vue.getAngularModule('stickersFactory');
                const $rootScope = Vue.getAngularModule('$rootScope');
                popupComponent.show({
                    header: 'Edit Post',
                    button_no: 'Cancel',
                    button_yes: 'Save',
                }, item)
                  .then(updatedData => {
                      const dataToPost = {
                          text: updatedData.text,
                          is_header: updatedData.isHeader,
                      };
                      if (updatedData.sticker) {
                          dataToPost.sticker = stickersFactory.get_sticker_code(updatedData.sticker);
                      }
                      if (updatedData.mediaAttachment) {
                          dataToPost.media_attachment_id = updatedData.mediaAttachment.id;
                      }
                      $rootScope.show_dimmer();
                      return this.storyResource.update_item(this.targetId, item.id, dataToPost);
                  }, cancel => {
                      vm?.$destroy();
                      fEl?.focus();
                      return new Promise(() => {
                      });
                  })
                  .then(resp => {
                      item.text = resp.data.text;
                      item.is_header = resp.data.is_header;
                      item.sticker = resp.data.sticker;
                      item.media_attachment = resp.data.media_attachment;
                      Vue.notifications.success('Updated');
                  }, err => {
                      Vue.notifications.error(err || 'Error');
                  })
                  .finally(() => {
                      vm?.$destroy();
                      fEl?.focus();
                      $rootScope.hide_dimmer();
                  });
            },
            addPost () {
                const {
                    vm,
                    popupComponent,
                    fEl
                } = simplePopupFactory.mount_vue_popup(CreateEditPostPopup);
                const stickersFactory = Vue.getAngularModule('stickersFactory');
                const $rootScope = Vue.getAngularModule('$rootScope');
                popupComponent.show({
                    header: 'Add Post',
                    button_no: 'Cancel',
                    button_yes: 'Add',
                }, {})
                  .then(updatedData => {
                      const dataToPost = {
                          text: updatedData.text,
                          is_header: updatedData.isHeader,
                      };
                      if (updatedData.sticker) {
                          dataToPost.sticker = stickersFactory.get_sticker_code(updatedData.sticker);
                      }
                      if (updatedData.mediaAttachment) {
                          dataToPost.media_attachment_id = updatedData.mediaAttachment.id;
                      }
                      $rootScope.show_dimmer();
                      return this.storyResource.add_to_story(this.targetId, dataToPost);
                  }, cancel => {
                      vm?.$destroy();
                      fEl?.focus();
                      return new Promise(() => {
                      });
                  })
                  .then(resp => {
                      this.results.push(resp.data.item);
                      this.totalCount++;
                      Vue.notifications.success('Added');
                  }, err => {
                      Vue.notifications.error(err || 'Error');
                  })
                  .finally(() => {
                      vm?.$destroy();
                      fEl?.focus();
                      $rootScope.hide_dimmer();
                  });
            },
            bulkUpload () {
                const addMedia = Vue.getAngularModule('addMedia');
                const $rootScope = Vue.getAngularModule('$rootScope');

                const mediaBulkUpload = items => {
                    $rootScope.show_dimmer();
                    return this.storyResource.bulk_create_from_media(this.targetId, items.map(i => i.pk))
                      .then(resp => {
                          this.allLoaded = false;
                          this.loadMore();
                      }, err => {
                          Vue.notifications.error(err || 'Error');
                          $rootScope.hide_dimmer();
                      });
                };

                addMedia.add_media(
                  {
                      allow_add_from_library: true,
                      max_count: 20,
                      types: ['video', 'audio', 'image'],
                      popup_header: 'What sort of media would you like to add?'
                  },
                  {
                      default_callback: mediaBulkUpload,
                      cancel_callback: () => {
                      }
                  }
                );
            },
            getListEndpoint () {
                return this.storyResource.get_story_infinite;
            },
            getListEndpointArgs () {
                return [this.targetId];
            }
        }
    };
</script>

<style scoped lang="scss">

</style>
