var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row row-centered" }, [
      _c("div", { staticClass: "col-sm-3 col-centered" }),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6 col-centered" }, [
        _c(
          "div",
          { staticClass: "panel panel-primary login-page-box margin-5-bottom" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "panel-body" }, [
              _c("div", { staticClass: "alert alert-info margin-15-bottom" }, [
                _vm._v(
                  "\n                        This access link is password protected. The password is included in the email sent to you\n                        by Multi Me. If someone else shared this link with you then please ask them for the password.\n                    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      class: {
                        "has-error": _vm.error,
                        "has-feedback": _vm.error,
                      },
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "password" },
                        },
                        [_vm._v("Password:")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.password,
                            expression: "password",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          required: "",
                          type: "password",
                          id: "password",
                          "aria-describedby": "helpBlock2",
                          autofocus: "",
                        },
                        domProps: { value: _vm.password },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.password = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.error
                        ? _c(
                            "span",
                            {
                              staticClass: "help-block",
                              attrs: { id: "helpBlock2" },
                            },
                            [_vm._v(_vm._s(_vm.error))]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._m(1),
                ]
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-3 col-centered" }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h3", { staticClass: "panel-title" }, [_vm._v("Password Required")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-12 text-right" }, [
        _c(
          "button",
          { staticClass: "btn btn-mm btn-green", attrs: { type: "submit" } },
          [_vm._v("Sign in")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }