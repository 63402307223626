import Vue from 'vue';

const eventsBus = new Vue();
const EVENTS_BUS_EVENTS = {
    UPDATE_EVENT_RECURRENCE_DATA: 'UPDATE_EVENT_RECURRENCE_DATA',
    EVENT_PERIOD_INFO_UPDATED_FOR_COMMENTS: 'EVENT_PERIOD_INFO_UPDATED_FOR_COMMENTS',
    LINK_GOAL: 'LINK_GOAL',
    INVITE_MEMBER: 'INVITE_MEMBER',
};

export {EVENTS_BUS_EVENTS, eventsBus};