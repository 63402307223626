var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _vm.currentComponent
      ? _c("div", { staticClass: "wikiLog" }, [
          _c("img", {
            staticClass: "wikiLog__avatar",
            attrs: { src: _vm.wikiLog.actor.avatar, alt: "avatar" },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "wikiLog__content" },
            [
              _c("div", { staticClass: "wikiLog__details" }, [
                _c(
                  "div",
                  [
                    _c("simple-link", { attrs: { target: _vm.wikiLog.actor } }),
                    _vm._v(" "),
                    _vm.wikiLog.actor_assistant?.name
                      ? _c("i", { staticClass: "text--secondary" }, [
                          _vm._v(
                            " supported by " +
                              _vm._s(_vm.wikiLog.actor_assistant?.name)
                          ),
                          _vm.wikiLog.actor_assistant_role
                            ? _c("span", [
                                _vm._v(
                                  " (" +
                                    _vm._s(_vm.wikiLog.actor_assistant_role) +
                                    ")"
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.wikiLog.actor_assistant_name
                      ? _c("i", { staticClass: "text--secondary" }, [
                          _vm._v(
                            " supported by " +
                              _vm._s(_vm.wikiLog.actor_assistant_name)
                          ),
                          _vm.wikiLog.actor_assistant_role
                            ? _c("span", [
                                _vm._v(
                                  " (" +
                                    _vm._s(_vm.wikiLog.actor_assistant_role) +
                                    ")"
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("i", { staticClass: "text--secondary text-caption" }, [
                  _vm._v(" " + _vm._s(_vm.formattedTime) + " "),
                ]),
              ]),
              _vm._v(" "),
              _c(_vm.currentComponent, {
                tag: "component",
                attrs: { wikiLog: _vm.wikiLog },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }