<template>
    <div>
        <div class="event-detail-info__entry-list" v-show="totalEntriesCount>0">
            <div class="event-detail-info__entry-list__last-entry-preview" v-if="entryPreview">
                <a :href="entryPreview.link | backHref" style="text-decoration: none">
                    <div :style="entryPreview.avatarStyle"
                         class="event-detail-info__entry-list__last-entry-preview__avatar">
                        <render-sticker v-if="entryPreview.sticker" :sticker="entryPreview.sticker"
                                        style="width: 45px; height: 45px; position: absolute; bottom: 0; right: 0;"></render-sticker>
                    </div>
                </a>
                <div class="event-detail-info__entry-list__last-entry-preview__text">
                    <a :href="entryPreview.link | backHref" style="text-decoration: none; color: #333;"
                       v-if="entryPreview.text||entryPreview.emotion">
                        <i class="fal fa-quote-left"></i>
                        {{ (entryPreview.text || entryPreview.emotion)|cutName(110) }}
                        <i class="fal fa-quote-right"></i>
                    </a>
                    <div class="event-detail-info__entry-list__last-entry-preview__text__author">
                        by <strong>
                        <simple-link :target="entryPreview.author"></simple-link>
                    </strong>
                    </div>
                </div>
            </div>
            <div class="event-detail-info__entry-list__more-link text-center pointer" v-show="totalEntriesCount>1"
                 @click="showAllEntries">
                Show {{ totalEntriesCount - 1 }} more...
            </div>

        </div>

        <div class="event-detail-info__notes-list" v-if="totalNotesCount>0">
            <a :href="notePreview.link | backHref" style="text-decoration: none; color: #333;"
               v-if="notePreview.text">
                <i class="fal fa-quote-left"></i>
                {{ notePreview.text|cutName(105) }}
                <i class="fal fa-quote-right"></i>
            </a>
            <div class="event-detail-info__notes-list__author">
                In <strong>{{ notePreview.logName }}</strong> Log by
                <strong>
                    <simple-link :target="notePreview.author" v-if="notePreview.author.link"></simple-link>
                    <span v-else>{{ notePreview.author.name }}</span>
                </strong>
            </div>
            <div class="event-detail-info__notes-list__more-link text-center pointer" v-show="totalNotesCount>1"
                 @click="showAllNotes">
                Show {{ totalNotesCount - 1 }} more...
            </div>
        </div>
        <event-detail-page-info-entry-list-show-all-popup
                v-if="showAllEntriesPopupOpened"
                :event="event"
                :calendar-owner="calendarOwner"
                :date-moment="dateMoment"
                ref="show-all-entries-popup"></event-detail-page-info-entry-list-show-all-popup>
        <event-detail-page-info-entry-list-show-all-notes-popup
                v-if="showAllNotesPopupOpened"
                :event="event"
                :calendar-owner="calendarOwner"
                :date-moment="dateMoment"
                ref="show-all-notes-popup"
        ></event-detail-page-info-entry-list-show-all-notes-popup>
    </div>
</template>

<script>
    import Vue from 'vue';
    import EventDetailSubPageMixin from "./EventDetailSubPageMixin";
    import {VISUAL_DATE_FORMAT} from "../new_calendar/config";
    import RenderSticker from "../../add_media/stickers/render_sticker_directive/RenderSticker";
    import EventDetailPageInfoEntryListShowAllPopup from './EventDetailPageInfoEntryListShowAllPopup.vue';
    import EventDetailPageInfoEntryListShowAllNotesPopup from './EventDetailPageInfoEntryListShowAllNotesPopup.vue';
    import {getEmotionByCode} from "../../diary_page/common/blog_entry_emotions/diaryEntryEmotions";

    export default {
        name: "EventDetailPageInfoEntryList.vue",
        components: {
            RenderSticker,
            EventDetailPageInfoEntryListShowAllPopup,
            EventDetailPageInfoEntryListShowAllNotesPopup
        },
        mixins: [EventDetailSubPageMixin],
        props: {},
        data() {
            return {
                visible: false,
                lastEntry: undefined,
                lastNote: undefined,
                totalEntriesCount: 0,
                totalNotesCount: 0,
                showAllEntriesPopupOpened: false,
                showAllNotesPopupOpened: false,
            };
        },
        computed: {
            entryPreview() {
                if (!this.lastEntry) return;
                return {
                    avatarStyle: {
                        'background-image': `url(${this.lastEntry.activity_post.thumbnail_url || this.lastEntry.activity_post.thumbnail_url.preview_url})`
                    },
                    sticker: this.lastEntry.activity_post ? this.lastEntry.activity_post.sticker : undefined,
                    text: this.lastEntry.activity_post ? this.lastEntry.activity_post.text : undefined,
                    author: this.lastEntry.author,
                    emotion: this.lastEntry.emotion ? getEmotionByCode(this.lastEntry.emotion).description : undefined,
                    link: this.lastEntry.link,
                };
            },
            notePreview() {
                if (!this.lastNote) return;
                const res = {
                    text: this.lastNote.answers?.[0] || '',
                    link: this.lastNote.link,
                    author: {name: this.lastNote.member_name},
                    logName: this.lastNote.location_name,
                };
                if (this.lastNote.author.userprofile && !this.lastNote.author.userprofile.deleted) {
                    res.author.link = this.lastNote.author.userprofile.link;
                }
                return res;
            }
        },
        methods: {
            loadInfoAboutLinkedEntries() {
                if (!this.membershipStatus.added_to_calendar) {
                    return;
                }
                const notifications = Vue.getAngularModule('notifications');
                const CalendarEventBlogEntriesResource = Vue.getAngularModule('CalendarEventBlogEntriesResource');
                CalendarEventBlogEntriesResource.get_info_about_linked_entries(this.event.id, this.calendarOwner.id, this.dateMoment.format(VISUAL_DATE_FORMAT))
                    .then(resp => {
                        if (resp.data.status === 'OK') {
                            this.lastEntry = resp.data.last_entry;
                            this.lastNote = resp.data.last_note;
                            this.totalEntriesCount = resp.data.entries_count;
                            this.totalNotesCount = resp.data.notes_count;
                        }
                    }, err => {
                        notifications.error('Can\'t load info about linked Diary Entries');
                    });
            },
            showAllEntries() {
                this.showAllEntriesPopupOpened = true;
                this.$nextTick(() => {
                    this.$refs['show-all-entries-popup'].open()
                        .then(() => {
                        }, () => {
                        })
                        .finally(() => {
                            this.showAllEntriesPopupOpened = false;
                        });
                });
            },
            showAllNotes() {
                this.showAllNotesPopupOpened = true;
                this.$nextTick(() => {
                    this.$refs['show-all-notes-popup'].open()
                        .then(() => {
                        }, () => {
                        })
                        .finally(() => {
                            this.showAllNotesPopupOpened = false;
                        });
                });
            },
            onPossiblyChanged() {
                this.loadInfoAboutLinkedEntries();
            }
        },
        created() {
            this.loadInfoAboutLinkedEntries();
        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";

  @mixin redBorder() {
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 10px;
    border: solid 3px #d26951;
    background-color: white;
    @media (min-width: $screen-sm-min) {
      width: 160px;
      margin-right: 15px;
    }
  }

  .event-detail-info {
    &__notes-list {
      @include redBorder;

      &__author {
        font-size: 13px;
        font-style: normal;
        text-align: right;
      }

      &__more-link {
        font-size: 13px;
        margin-top: 5px;
      }
    }

    &__entry-list {
      @include redBorder;

      &__last-entry-preview {
        &__avatar {
          position: relative;
          width: 80px;
          height: 80px;
          background-size: cover;
          border-radius: 5px;
          margin: auto;
        }

        &__text {
          font-size: 13px;
          font-style: italic;

          &__author {
            font-style: normal;
            text-align: right;
          }
        }
      }

      &__more-link {
        font-size: 13px;
        margin-top: 5px;
      }

    }
  }

</style>