var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": "New Wiki", role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [_vm._v("Select Date")]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12 date-selector-popup__calendar-container" },
          [
            _c("datepicker", {
              attrs: {
                "disabled-dates": _vm.disabledDates,
                highlighted: _vm.highLightedDates,
                inline: "",
              },
              model: {
                value: _vm.selectedDate,
                callback: function ($$v) {
                  _vm.selectedDate = $$v
                },
                expression: "selectedDate",
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }