/**
 * Created by orion on 07.03.17.
 */

import urls from 'DjangoUrls';

let {DjangoUrls} = urls;

addMediaResources.$inject = ['$uibModal', '$http', '$rootScope'];

function addMediaResources($uibModal, $http, $rootScope) {
    return {
        check_encoding,
        add_recorded_video,
        add_recorded_audio,
        add_picture_by_base_value,
        add_uploaded_video,
        add_uploaded_audio,
        add_uploaded_media,
        add_recorded_audio_by_id,
        add_recorded_video_by_id,
        add_youtube_videos,
        add_upl_file_from_gdrive,
        create_media_content_item_from_file,
        check_link_url,
        add_link,
    };

    function add_uploaded_video(encoding_id, name, save_to_library, anonymous_token = undefined) {
        const url = DjangoUrls["api.v1:media-add-uploaded-video"]();
        return $http({
            method: 'POST',
            url: url,
            data: {encoding_id, name, save_to_library, anonymous_token}
        });
    }

    function add_uploaded_audio(encoding_id, name, save_to_library, anonymous_token = undefined) {
        const url = DjangoUrls["api.v1:media-add-uploaded-audio"]();
        return $http({
            method: 'POST',
            url: url,
            data: {encoding_id, name, save_to_library, anonymous_token}
        });
    }

    function add_uploaded_media(encoding_id, name, save_to_library, anonymous_token = undefined) {
        const url = DjangoUrls["api.v1:media-add-uploaded-media"]();
        return $http({
            method: 'POST',
            url: url,
            data: {encoding_id, name, save_to_library, anonymous_token}
        });
    }

    function check_link_url(link_url) {
        const url = DjangoUrls["api.v1:check_link_url"]();
        return $http({
            method: 'POST',
            url: url,
            data: {url: link_url}
        });
    }

    function create_media_content_item_from_file(file, token = undefined) {
        $rootScope.show_dimmer();
        let fd = new FormData();
        fd.append('file', file);
        const uploadUrl = DjangoUrls["api.v1:media_upload"]('image');
        const requestParams = {
            url: uploadUrl,
            data: fd,
            method: 'POST',
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined,
            }
        };
        if (token) {
            requestParams.headers['X-A-Token'] = token;
        }
        return $http(requestParams);
    }

    function add_upl_file_from_gdrive(data) {
        const url = DjangoUrls["api.v1:media-add-upl-file-from-gdrive"]();
        return $http({
            method: 'POST',
            url,
            data
        });
    }

    function add_youtube_videos(links, save_to_library, anonymous_token = undefined) {
        const url = DjangoUrls["api.v1:media-add-youtube-videos"]();
        const req = {
            method: 'POST',
            url: url,
            data: {links: links, save_to_library: save_to_library},
        };
        if (anonymous_token) {
            req.data.anonymous_token = anonymous_token;
        }
        return $http(req);
    }

    function add_picture_by_base_value(picture, save_to_media, anonymous_token = undefined) {
        const url = DjangoUrls["api.v1:media-add-picture-by-base-value"]();
        return $http({
            method: 'POST',
            url: url,
            data: {picture, save_to_media, anonymous_token}
        });
    }

    function add_recorded_audio(stream_name, save_to_library) {
        const url = DjangoUrls["api.v1:media-add-recorded-audio"]();
        let data = {stream_name: stream_name, save_to_library: save_to_library};
        return $http({
            method: 'POST',
            url: url,
            data: data
        });
    }

    function add_recorded_video(stream_name, save_to_library) {
        const url = DjangoUrls["api.v1:media-add-recorded-video"]();
        let data = {stream_name: stream_name, save_to_library: save_to_library};
        return $http({
            method: 'POST',
            url: url,
            data: data
        });
    }

    function add_recorded_audio_by_id(recording_id, save_to_media, anonymous_token = undefined) {
        const url = DjangoUrls["api.v1:media-add-recorded-audio-by-id"]();
        return $http({
            method: 'POST',
            url: url,
            data: {recording_id, save_to_media, anonymous_token}
        });
    }

    function add_recorded_video_by_id(recording_id, save_to_media, anonymous_token = undefined) {
        const url = DjangoUrls["api.v1:media-add-recorded-video-by-id"]();
        return $http({
            method: 'POST',
            url: url,
            data: {recording_id, save_to_media, anonymous_token}
        });
    }

    function add_link(data) {
        const url = DjangoUrls["api.v1:media-add-link"]();
        return $http({
            method: 'POST',
            url,
            data
        });
    }

    function check_encoding(encoding_id, type) {
        const url = DjangoUrls["api.v1:media-check-encoding"]();
        return $http({
            method: 'GET',
            url: url,
            params: {encoding_id, type}
        });
    }
}

export default addMediaResources;