<template>
    <span v-if="attachmentSnapshot">
        <span>added a new link </span>
        <strong>
            #{{ attachmentSnapshot.id }} <span v-if="attachmentSnapshot.name">"{{ attachmentSnapshot.name }}"</span>
        </strong>
        <span v-if="attachmentSnapshot.slide_name">
            to page <strong>#{{ attachmentSnapshot.slide }}
            <wiki-log-possible-link
                    :target="wikiLog.target_wiki_slide"
                    :label="attachmentSnapshot.slide_name"
            ></wiki-log-possible-link>
        </strong>
        </span>
    </span>
</template>

<script>
    import SnapshotLogMixin from "./common/SnapshotLogMixin";

    export default {
        name: "WikiSlideAttachmentCreatedLink",
        mixins: [SnapshotLogMixin]
    };
</script>

<style scoped>

</style>