var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.header)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("strong", [_vm._v("Consent Form")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Data privacy is very important to us at Multi Me. GDPR requires us by law to get your consent to our\n                Privacy and Cookie policy. You can read more about Multi Me and GDPR "
            ),
            _c("strong", [
              _c(
                "a",
                {
                  attrs: {
                    target: "_blank",
                    href: "https://multime.freshdesk.com/support/solutions/articles/44001795709-a-guide-to-gdpr-and-using-the-wikime-and-multime-platforms",
                  },
                },
                [_vm._v("\n                    here")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "GDPR is built around the principles of transparency and control, something Multi Me has always taken\n                pride in offering. So, to help you retain control of your data, our policies offer details on what\n                we collect, how we use it, and your rights. At Multi Me we use personal data to improve our service\n                and offer better communication to new and existing customers. Your trust is important to us, so\n                keeping\n                your personal information safe and secure remains our top priority."
            ),
          ]),
          _vm._v(" "),
          _c("strong", [_vm._v("Privacy Policy")]),
          _vm._v(" "),
          _c("p", [
            _vm._v("Please take some time to read over our Privacy Policy "),
            _c("strong", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.multime.com/privacy-policy",
                    target: "_blank",
                  },
                },
                [_vm._v("here")]
              ),
            ]),
            _vm._v(". You can view and Easy Read version "),
            _c("strong", [
              _c(
                "a",
                {
                  attrs: {
                    target: "_blank",
                    href: "https://static1.squarespace.com/static/5d82253bd4f4f8126bb934e7/t/5ed7635b1a40f37954101810/1591173985354/4+-+Looking+after+your+personal+information.pdf",
                  },
                },
                [_vm._v("\n                    here")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("strong", [_vm._v("Cookies")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Multi Me uses some Cookies to make your experience of using the site smoother. You can read more and\n                make your cookie choices "
            ),
            _c("strong", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.multime.com/cookie-policy",
                    target: "_blank",
                  },
                },
                [_vm._v("\n                    here")]
              ),
            ]),
            _vm._v(
              ". By continuing to use this site without changing your browser Cookie Settings\n                you\n                are giving us your consent to use Cookies on your device."
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row", staticStyle: { "margin-top": "10px" } }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("vue-switch-with-label", {
              staticClass: "inline-block",
              attrs: {
                label: "I Confirm I accept Multi Me’s Privacy Policy",
                name: "agree_with_privacy_policy",
              },
              model: {
                value: _vm.agree_with_privacy_policy,
                callback: function ($$v) {
                  _vm.agree_with_privacy_policy = $$v
                },
                expression: "agree_with_privacy_policy",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row", staticStyle: { "margin-top": "10px" } }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("vue-switch-with-label", {
              attrs: {
                label: "I Confirm I accept Multi Me’s Cookie Policy",
                name: "agree_with_cookie_policy",
              },
              model: {
                value: _vm.agree_with_cookie_policy,
                callback: function ($$v) {
                  _vm.agree_with_cookie_policy = $$v
                },
                expression: "agree_with_cookie_policy",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("div", { staticClass: "col-sm-12 text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-green",
                attrs: { disabled: !_vm.acceptIsPossible },
                on: { click: _vm.acceptPolicy },
              },
              [_vm._v("\n                Continue to log me in\n            ")]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }