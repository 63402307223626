<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="popupConfig.header"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ popupConfig.header }}</h3>
        </div>

        <div class="row" v-if="sticker">
            <div class="col-sm-12 text-center">
                <render-sticker
                        :sticker="sticker"
                        :allow-delete="true"
                        @onDelete="removeSticker"
                ></render-sticker>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-xs-7" style="height: 32px">
                        <span class="align-helper"></span>
                        <label style="vertical-align: bottom">Add text entry below...</label>
                    </div>
                    <div class="col-xs-5 text-right">
                        <vue-switch-with-label label="Header" name="is_header"
                                               v-model="isHeader"></vue-switch-with-label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <textarea
                                ref="textArea"
                                class="form-control modal-text-input-textarea cepp__textarea"
                                :class="{'cepp__textarea_header':isHeader}"
                                v-model="text"
                                rows="5"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="row add-stuff-panel">
            <div class="col-sm-12 create_like_story_post_popup__button_panel justify">
                <div class="create_like_story_post_popup__attach_button green"
                     :class="{disabled:!!mediaAttachment}" @click="addMedia" @keydown.enter="addMedia" tabindex="0">
                    <i class="far fa-film"></i>
                    <i class="fab fa-itunes-note"></i>
                    <br>
                    <span class="create_like_story_post_popup__attach_button__label">
                            + <span class="create_like_story_post_popup__attach_button__label__add-word">Add</span> Media
                        </span>
                </div>
                <div class="create_like_story_post_popup__attach_button blue"
                     :class="{disabled:!!sticker}"
                     @click="addSticker"
                     @keydown.enter="addSticker" tabindex="0">
                    <i class="far fa-thumbs-up"></i>
                    <i class="far fa-heart"></i>
                    <br>
                    <span class="create_like_story_post_popup__attach_button__label">
                            + <span class="create_like_story_post_popup__attach_button__label__add-word">Add</span> Sticker
                        </span>
                </div>
            </div>
        </div>
        <div class="row" v-if="mediaAttachment">
            <div class="col-sm-12">
                <media-preview
                        :allow-delete="true"
                        @onDelete="deleteMediaAttachment"
                        :item='mediaAttachment'
                        :no-border="true"
                ></media-preview>
            </div>
        </div>
        <div slot="footer">
            <div class="row">
                <div class="col-sm-12">
                    <button @click="close" class="btn btn-mm">{{ popupConfig.button_no }}</button>
                    <div class="inline-block" data-role="trigger">
                        <button @click="ok" :disabled="!isSaveButtonEnabled"
                                class="btn btn-mm btn-green">{{ popupConfig.button_yes }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <span tabindex="0" aria-hidden="true" class="focus-catch-link" @focus="focusToClose">&nbsp;</span>
                </div>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import BasicModal from "../../../vue/common_components/BasicModal";
    import PopupPromiseMixin from "../../../vue/mixins/PopupPromiseMixin";
    import RenderSticker from "./stickers/render_sticker_directive/RenderSticker";
    import Vue from "vue";
    import _ from "lodash";

    const DEFAULT_POPUP_CONFIG = {
        max_media_count: 1,
        allow_header: false,
        header: 'Add Post',
        button_no: 'Cancel',
        button_yes: 'Add',
        allow_emotion: false,
    };

    export default {
        name: "CreateEditPostPopup",
        mixins: [PopupPromiseMixin],
        components: {RenderSticker, BasicModal},
        data() {
            return {
                popupConfig: Object.assign({}, DEFAULT_POPUP_CONFIG),

                mediaAttachment: undefined,
                sticker: undefined,
                text: '',
                isHeader: false,
            };
        },
        computed: {
            firstFocusEl() {
                return this.$refs.textArea;
            },
            isSaveButtonEnabled() {
                return this.text || this.sticker || this.mediaAttachment;
            }
        },
        methods: {
            setInitial(popupConfig = {}, post = {}) {
                this.popupConfig = Object.assign({}, DEFAULT_POPUP_CONFIG, popupConfig);
                this.mediaAttachment = post.media_attachment || post.mediaAttachment;
                this.sticker = post.sticker;
                this.text = post.text;
                this.isHeader = post.is_header || post.isHeader;
            },
            removeSticker() {
                this.sticker = null;
            },
            deleteMediaAttachment() {
                this.mediaAttachment = undefined;
            },
            ok() {
                this.save({
                    text: this.text,
                    isHeader: this.isHeader,
                    sticker: this.sticker,
                    mediaAttachment: this.mediaAttachment,
                });
            },
            addMedia() {
                if (this.mediaAttachment) {
                    Vue.notifications.warning('You can\'t add more then one media here');
                    return;
                }
                this.tmpHide();
                const addMedia = Vue.getAngularModule('addMedia');
                const add_media_items = (itemsList) => {
                    this.mediaAttachment = itemsList[0];
                    this.tmpShow();
                };
                const show_create_popup = () => {
                    this.tmpShow();
                };
                addMedia.add_media(
                    {
                        allow_add_from_library: true,
                        max_count: 1,
                        types: ['video', 'audio', 'image', 'document'],
                        popup_header: 'What sort of media would you like to add?'
                    },
                    {
                        default_callback: add_media_items,
                        cancel_callback: show_create_popup
                    }
                );
            },
            addSticker() {
                if (this.sticker) {
                    Vue.notifications.warning('You can\'t add more then one sticker');
                    return;
                }
                this.tmpHide();
                const show_create_popup = () => {
                    this.tmpShow();
                };
                const stickersFactory = Vue.getAngularModule('stickersFactory');
                stickersFactory.select_sticker().then((sticker) => {
                    this.sticker = sticker;
                    show_create_popup();
                }, show_create_popup);
            }
        }

    };
</script>

<style scoped lang="scss">
  .cepp {
    &__textarea {
      height: 125px;

      &_header {
        font-size: 21px;
        font-weight: bolder;
      }
    }
  }
</style>