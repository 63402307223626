var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: {
        "aria-label": _vm.header,
        size: _vm.items.length > 3 ? "lg" : undefined,
        role: "dialog",
      },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.header)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("descriptionTop"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sp__items-container" },
        _vm._l(_vm.items, function (item) {
          return _c(
            "selector-popup-item",
            _vm._b(
              {
                key: item.label,
                ref: "item",
                refInFor: true,
                attrs: { item: item },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onItemSelect(item)
                  },
                },
              },
              "selector-popup-item",
              item.attrs,
              false
            )
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("div", [_vm._t("invisibleForms")], 2),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "div", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [_vm._t("footer")], 2),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }