var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "selector-popup",
    {
      ref: "popup",
      attrs: {
        header: "How you would like to add document?",
        items: _vm.items,
      },
      on: { selected: _vm.onSelected },
    },
    [
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-primary",
            attrs: { type: "button" },
            on: { click: _vm.back },
          },
          [_vm._v("Back")]
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "invisibleForms" }, [
        _c(
          "form",
          {
            staticStyle: { display: "none" },
            attrs: { enctype: "multipart/form-data" },
          },
          [
            _vm.config.max_count > 1
              ? _c("input", {
                  ref: "multiFileUploadInput",
                  attrs: {
                    id: "multi-file-upload-input",
                    accept: ".pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.odf,.ods",
                    type: "file",
                    multiple: "",
                  },
                  on: { change: _vm.onUploadFileFieldChanged },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.config.max_count === 1
              ? _c("input", {
                  ref: "singleFileUploadInput",
                  attrs: {
                    id: "single-file-upload-input",
                    accept: ".pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.odf,.ods",
                    type: "file",
                  },
                  on: { change: _vm.onUploadFileFieldChanged },
                })
              : _vm._e(),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }