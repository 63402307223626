/**
 * Created by orion on 29.05.17.
 */
GroupMainOptionsPopupController.$inject = ['$scope', '$uibModalInstance', '$timeout', '$log', 'initial_data', 'popup_config', 'is_circle_group'];

function GroupMainOptionsPopupController($scope, $uibModalInstance, $timeout, $log, initial_data, popup_config, is_circle_group) {
    $scope.close = function () {
        $uibModalInstance.dismiss('closed');
    };
    $scope.name = initial_data.name;
    $scope.description = initial_data.description;
    $scope.media_for_avatar = initial_data.media_for_avatar;
    $scope.avatar = initial_data.avatar;
    $scope.auto_avatar = initial_data.auto_avatar || false;
    $scope.is_private = initial_data.is_private || false;
    $scope.veto_conversation_posts = initial_data.veto_conversation_posts;
    $scope.veto_joins = initial_data.veto_joins;
    $scope.veto_share = initial_data.veto_share;
    $scope.veto_new_conversations = initial_data.veto_new_conversations;
    $scope.veto_activity_posts = initial_data.veto_activity_posts;

    $scope.popup_config = popup_config;

    $scope.create = create;
    $scope.add_avatar = add_avatar;
    $scope.reset_avatar = reset_avatar;
    $scope.is_circle_group = is_circle_group;

    function dump_data() {
        return {
            name: $scope.name,
            description: $scope.description,
            media_for_avatar: $scope.media_for_avatar,
            avatar: $scope.avatar,
            veto_conversation_posts: $scope.veto_conversation_posts,
            veto_joins: $scope.veto_joins,
            veto_share: $scope.veto_share,
            veto_new_conversations: $scope.veto_new_conversations,
            veto_activity_posts: $scope.veto_activity_posts,
            auto_avatar: $scope.auto_avatar,
            is_private: $scope.is_private,
        }
    }

    function create() {
        $uibModalInstance.close({
            type: 'save',
            data: dump_data()
        });
    }

    function add_avatar() {
        $uibModalInstance.close({
            type: 'add_media',
            data: dump_data()
        });
    }

    function reset_avatar() {
        $scope.media_for_avatar = undefined;
        $scope.avatar = undefined;
    }
}

export default GroupMainOptionsPopupController