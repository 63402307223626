/**
 * Created by orion on 11.04.17.
 */
import urls from 'DjangoUrls';
import axios from "axios";

let {DjangoUrls} = urls;
const  PREFIX = 'api.v1:blog_settings-';
BlogSettingsResource.$inject = ['$http'];

function BlogSettingsResource($http) {
    return {
        get_users_to_add,
        add_registered_user_to_blog,
        get_manage_access_page_main_data,
        remove_member_from_blog,
        add_user_to_circle_by_email,
        create_protected_access_link,
        create_protected_access_link_and_send_to_email
    };

    function get_users_to_add(blog_id, params) {
        const url = DjangoUrls[`${PREFIX}get-users-to-add`](blog_id);
        return axios({
            method: 'GET',
            url,
            params
        });
    }

    function add_registered_user_to_blog(blog_id, user_id) {
        const url = DjangoUrls[`${PREFIX}add-registered-user-to-blog`](blog_id);
        return axios({
            method: 'POST',
            url: url,
            data: {
                id: user_id
            }
        });
    }

    function add_user_to_circle_by_email(blog_id, data) {
        /**
         * @description
         * add user to blog using email
         * @param {number} Blog id
         * @param {string} email
         * @param {string} Role Name
         * @return {Object} Serialized blog membership
         */
        const url = DjangoUrls[`${PREFIX}add-user-to-circle-by-email`](blog_id);
        return axios({
            method: 'POST',
            url: url,
            data
        });
    }

    function create_protected_access_link(blogId, data) {
        const url = DjangoUrls[`${PREFIX}create-protected-access-link`](blogId);
        return axios({
            method: "post",
            url,
            data
        });
    }

    function create_protected_access_link_and_send_to_email(blogId, data) {
        const url = DjangoUrls[`${PREFIX}create-protected-access-link-and-send-to-email`](blogId);
        return axios({
            method: "post",
            url,
            data
        });
    }

    function get_manage_access_page_main_data(blog_id) {
        const url = DjangoUrls[`${PREFIX}get-manage-access-page-main-data`](blog_id);
        return axios({
            method: 'GET',
            url,
        });
    }

    function remove_member_from_blog(blog_id, member_id) {
        const url = DjangoUrls[`${PREFIX}remove-member-from-blog`](blog_id);
        return axios({
            method: 'POST',
            url: url,
            data: {
                id: member_id
            }
        });
    }

}

export default BlogSettingsResource;
