var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "edit-slide-popup",
      attrs: { transition: 0, backdrop: false },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          attrs: { id: "close_popup" },
          on: { click: _vm.closePopup },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.header))]),
      ]),
      _vm._v(" "),
      _vm.editMode === _vm.EDIT_MODES.ALL ||
      _vm.editMode === _vm.EDIT_MODES.TITLE
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("strong", [_vm._v("Title")]),
              _vm._v(":\n            "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.caption,
                    expression: "caption",
                  },
                ],
                ref: "caption",
                staticClass: "form-control",
                attrs: {
                  id: "caption",
                  maxlength: "63",
                  type: "text",
                  placeholder: "Give this slide a title",
                },
                domProps: { value: _vm.caption },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.caption = $event.target.value
                  },
                },
              }),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.editMode === _vm.EDIT_MODES.ALL ||
      _vm.editMode === _vm.EDIT_MODES.TITLE
        ? _c("div", { staticClass: "row margin-15-top" }, [
            !_vm.audio_caption
              ? _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-blue text-left",
                      staticStyle: {
                        width: "173px",
                        "text-align": "left",
                        padding: "7px 15px 13px 0",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addAudioCaption()
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "vertical-middle",
                          staticStyle: {
                            width: "37px",
                            "margin-left": "4px",
                            "text-align": "center",
                            display: "inline-block",
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fa fa-microphone fa-2 vertical-middle",
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "vertical-middle" }, [
                        _vm._v(" + Add Audio"),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.audio_caption
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-sm-8 edit-slide-popup__action-description",
                  },
                  [
                    _vm._v(
                      "\n            Add an audio caption so users can listen to the name.\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.audio_caption
              ? _c("div", { staticClass: "col-sm-12" }, [
                  _c("strong", [_vm._v("Audio Caption:")]),
                  _c("br"),
                  _vm._v(" "),
                  _c("strong", [_vm._v(_vm._s(_vm.audio_caption.name))]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "color-red pointer",
                      staticStyle: {
                        "vertical-align": "top",
                        "font-weight": "bold",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.resetAudioCaption()
                        },
                      },
                    },
                    [_vm._v(" x")]
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.editMode === _vm.EDIT_MODES.ALL
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              !_vm.media_content
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-blue text-left",
                          staticStyle: {
                            width: "173px",
                            "text-align": "left",
                            padding: "7px 15px 13px 0",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addMediaContent()
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "vertical-middle",
                              staticStyle: {
                                width: "37px",
                                "margin-left": "4px",
                                "text-align": "center",
                                display: "inline-block",
                              },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-picture-o fa-2 vertical-middle",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "vertical-middle" }, [
                            _vm._v(" + Add Media"),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-sm-8 edit-slide-popup__action-description",
                      },
                      [
                        _vm._v(
                          "\n                    Add Media which is main part of your slide.\n                "
                        ),
                      ]
                    ),
                  ])
                : _c(
                    "div",
                    [
                      _c("strong", [_vm._v("Media Content:")]),
                      _c("br"),
                      _vm._v(" "),
                      _c("media-preview", {
                        attrs: {
                          item: _vm.media_content,
                          "allow-delete": true,
                          "show-name": true,
                        },
                        on: { onDelete: _vm.resetMediaContent },
                      }),
                    ],
                    1
                  ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.editMode === _vm.EDIT_MODES.ALL ||
      _vm.editMode === _vm.EDIT_MODES.DESCRIPTION
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("strong", {}, [_vm._v("Add more words under your media:")]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.description,
                    expression: "description",
                  },
                ],
                ref: "description",
                staticClass: "form-control",
                attrs: { rows: "5", maxlength: "8191", type: "text" },
                domProps: { value: _vm.description },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.description = $event.target.value
                  },
                },
              }),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.editMode === _vm.EDIT_MODES.ALL ||
      _vm.editMode === _vm.EDIT_MODES.DESCRIPTION
        ? _c("div", { staticClass: "row margin-15-top" }, [
            !_vm.audio_description
              ? _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-blue text-left",
                      staticStyle: {
                        width: "173px",
                        "text-align": "left",
                        padding: "7px 15px 13px 0",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addAudioDescription()
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "vertical-middle",
                          staticStyle: {
                            width: "37px",
                            "margin-left": "4px",
                            "text-align": "center",
                            display: "inline-block",
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fa fa-microphone fa-2 vertical-middle",
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "vertical-middle" }, [
                        _vm._v(" + Add Audio"),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.audio_description
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-sm-8 edit-slide-popup__action-description",
                  },
                  [
                    _vm._v(
                      "\n            Add an audio description so users can listen to it.\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.audio_description
              ? _c("div", { staticClass: "col-sm-12" }, [
                  _c("strong", [_vm._v("Audio Description:")]),
                  _c("br"),
                  _vm._v(" "),
                  _c("strong", [_vm._v(_vm._s(_vm.audio_description.name))]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "color-red pointer",
                      staticStyle: {
                        "vertical-align": "top",
                        "font-weight": "bold",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.resetAudioDescription()
                        },
                      },
                    },
                    [_vm._v(" x")]
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-primary",
            attrs: { type: "button" },
            on: { click: _vm.closePopup },
          },
          [_vm._v("Cancel")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-green",
            attrs: { type: "button" },
            on: { click: _vm.save },
          },
          [_vm._v("Save")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }