var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "event-users-calendar" }, [
    _c(
      "div",
      { staticClass: "event-users-calendar__image-part" },
      [
        _c("router-link", { attrs: { to: _vm.link + "?fl" } }, [
          _c("img", {
            staticClass: "img-circle event-users-calendar__image-part__image",
            attrs: { src: _vm.user.avatar },
          }),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "event-users-calendar__content-part" },
      [
        _c("router-link", { attrs: { to: _vm.link } }, [
          _c("img", {
            staticClass: "event-users-calendar__content-part__icon",
            attrs: {
              src: "/static/images/new_daschboard_icons/calendar_600_1.png",
              alt: "",
            },
          }),
        ]),
        _vm._v(" "),
        _c("router-link", { attrs: { to: _vm.link } }, [
          _vm._v(
            "\n            " + _vm._s(_vm.user.name) + "'s Calendar\n        "
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }