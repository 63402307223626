import urls from 'DjangoUrls';

let {DjangoUrls} = urls;
MediaMembershipResource.$inject = ['$http'];

function MediaMembershipResource($http) {
    return {
        get_members(userId) {
            const url = DjangoUrls["api.v1:media_membership-get-members"](userId);
            return $http({
                method: 'GET',
                url: url
            });
        },

        get_users_to_invite(userId, params) {
            const url = DjangoUrls["api.v1:media_membership-get-users-to-invite"](userId);
            return $http({
                method: 'GET',
                url: url,
                params
            });
        },

        invite_users(userId, data) {
            const url = DjangoUrls["api.v1:media_membership-invite-users"](userId);
            return $http({
                method: 'post',
                url,
                data
            });
        },

        get_folders(userId, membershipId, folderSlug = null) {
            const url = DjangoUrls["api.v1:media_membership-get-folders"](userId);
            return $http({
                method: 'get',
                url,
                params: {id: membershipId, folder: folderSlug}
            });
        },

        make_admin(userId, membershipId) {
            const url = DjangoUrls["api.v1:media_membership-make-admin"](userId);
            return $http({
                method: 'PATCH',
                url,
                data: {id: membershipId}
            });
        },

        unmake_admin(userId, membershipId) {
            const url = DjangoUrls["api.v1:media_membership-unmake-admin"](userId);
            return $http({
                method: 'PATCH',
                url,
                data: {id: membershipId}
            });
        },

        enable_notifications(userId, membershipId) {
            const url = DjangoUrls["api.v1:media_membership-enable-notifications"](userId);
            return $http({
                method: 'PATCH',
                url,
                data: {id: membershipId}
            });
        },

        disable_notifications(userId, membershipId) {
            const url = DjangoUrls["api.v1:media_membership-disable-notifications"](userId);
            return $http({
                method: 'PATCH',
                url,
                data: {id: membershipId}
            });
        },

        remove_member(userId, membershipId) {
            const url = DjangoUrls["api.v1:media_membership-remove-member"](userId);
            return $http({
                method: 'POST',
                url,
                data: {id: membershipId}
            });
        },

        set_permission(userId, membershipId, target_id, level, value) {
            const url = DjangoUrls["api.v1:media_membership-set-permission"](userId);
            return $http({
                method: 'POST',
                url,
                data: {
                    id: membershipId, target_id, level, value
                }
            });
        },

        create_access_link(userId, name) {
            const url = DjangoUrls["api.v1:media_membership-create-access-link"](userId);
            return $http({
                method: 'POST',
                url,
                data: {
                    name
                }
            });
        },
        send_access_link(userId, data) {
            const url = DjangoUrls["api.v1:media_membership-send-access-link"](userId);
            return $http({
                method: 'POST',
                url,
                data
            });
        },
        send_circle_invitation(userId, data) {
            const url = DjangoUrls["api.v1:media_membership-send-circle-invitation"](userId);
            return $http({
                method: 'POST',
                url,
                data
            });
        },

        get_members_permissions(userId, membershipId) {
            const url = DjangoUrls["api.v1:media_membership-get-members-permissions"](userId);
            return $http({
                method: 'GET',
                url,
                params: {
                    id: membershipId,
                }
            });
        }
    };
}

export default MediaMembershipResource;