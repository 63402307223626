/**
 * Created by orion on 6/24/17.
 */

UserProfileDeleteAccountResource.$inject = ['$http'];
import urls from 'DjangoUrls';

let {DjangoUrls} = urls;

function UserProfileDeleteAccountResource($http) {
    return {
        confirm,
        cancel,
    };

    ///////////////////

    function confirm(request_id, code, password) {
        const url = DjangoUrls["api.v1:delete_account_request-confirm"](request_id);
        return $http({
            method: 'PATCH',
            url,
            data: {code, password}
        });
    }

    function cancel(request_id, code, password) {
        const url = DjangoUrls["api.v1:delete_account_request-cancel"](request_id);
        return $http({
            method: 'PATCH',
            url,
            data: {code, password}
        });
    }

}

export default UserProfileDeleteAccountResource;