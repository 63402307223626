<template>
    <div class="row community-page__container">
        <div class="col-sm-12">
            <div class="community-page__menu">
                <community-page-menu-vertical
                        :avatar="community.avatar"
                        :name="community.name"
                        :show-settings="isAdmin"
                        @onShare="shareCommunityLink"
                ></community-page-menu-vertical>
            </div>
            <div class="community-page__content">
                <router-view
                ></router-view>
            </div>
        </div>
        <way-to-share-selector-popup
                ref="share-selector-popup"
                :link="community.link"
        ></way-to-share-selector-popup>

    </div>
</template>

<script>
    import Vue from 'vue';
    import CommunityPageMenu from './CommunityPageMenu.vue';
    import CommunityPageMenuVertical from './CommunityPageMenuVertical.vue';
    import WayToShareSelectorPopup from './WayToShareSelectorPopup.vue';

    import captionPlayerBus from '../../../angular/app/shared/captionPlayer';


    export default {
        components: {CommunityPageMenuVertical, CommunityPageMenu, WayToShareSelectorPopup},
        name: "CommunityPage",
        data() {
            return {};
        },
        computed: {
            isAdmin() {
                return this.$store.getters['communityPage/viewerIsAdmin'];
            },
            community() {
                return this.$store.getters['communityPage/community'];
            },
            story() {
                return this.$store.getters['communityPage/story'];
            },
            event() {
                return this.$store.getters['communityPage/event'];
            }
        },
        created() {
        },
        mounted() {
            this.injectFacebookShareButton();
        },
        methods: {
            shareCommunityLink() {
                let header, avatar, href, title;
                if (this.$route.name === 'community-stories-story' && this.story && this.story.published) {
                    header = 'Share Story';
                    href = document.location.href;
                    title = this.story.name;
                    avatar = this.story.avatar;
                } else if (this.$route.params.eventId && this.event && !this.event.is_private) {
                    href = `${document.location.origin}${document.location.pathname.replace('info','')}`;
                    header = 'Share Event';
                    title = this.event.event_data.name;
                    avatar = this.event.event_data.avatar_url;
                } else {
                    href = `${document.location.origin}${this.community.link}`;
                    header = 'Share Community';
                    title = this.community.name;
                    avatar = this.community.avatar;
                }
                this.$refs['share-selector-popup'].show(href, header)
                    .then(res => {
                        if (res === 'facebook') {
                            FB.init({
                                appId: FACEBOOK_APP_ID,
                                status: true,
                                cookie: true,
                                version: 'v2.10'
                            });

                            FB.ui({
                                method: 'share_open_graph',
                                action_type: 'og.shares',
                                display: 'popup',
                                action_properties: JSON.stringify({
                                    object: {
                                        'og:url': href,
                                        'og:title': title,
                                        // 'og:description': overrideDescription,
                                        'og:image': avatar
                                    }
                                })
                            });
                            // FB.ui({
                            //     method: 'share',
                            //     href: href,
                            //     picture: avatar,
                            // }, function (response) {
                            // });
                        }
                        if (res === 'copy') {
                            Vue.notifications.success('Link Copied');
                        }
                    });
            },
            injectFacebookShareButton() {
                (function (d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) return;
                    js = d.createElement(s);
                    js.id = id;
                    js.src = "https://connect.facebook.net/en_UK/sdk.js#xfbml=1&version=v6.0&appId=" + FACEBOOK_APP_ID + "&autoLogAppEvents=1";
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));
            }
        }
        ,
        beforeRouteUpdate(to, from, next) {
            captionPlayerBus.$emit('stopSound');
            next();
        },

    };
</script>

<style scoped lang="scss">
    @import "../styles/const";

    $panel-size: 280px;
    $panel-margin: 25px;
    .community-page {
        &__menu {
            vertical-align: top;
            font-size: 18px;
            @media (min-width: $screen-sm-min) {
                /*<!--width: $panel-size;-->*/
                /*display: inline-block;*/
                /*<!--margin-right: $panel-margin;-->*/
            }
        }

        &__content {
            vertical-align: top;
            font-size: 18px;
            @media (min-width: $screen-sm-min) {
                /* <!--width: calc(100% - #{$panel-size} - #{$panel-margin});-->/*
                 /*<!--display: inline-block;-->*/
            }
        }

        &__container {
            font-size: 0;
        }
    }
</style>