import CreateGoalTypeSelectorPopup from '~/angular/app/projects/goal_settings_factory/CreateGoalTypeSelectorPopup.vue';
import { simplePopupFactory } from '~/angular/app/shared/simple_popups/simplePopupFactory';
import selectFactory from '~/angular/app/shared/select_factory/select_factory';
import { customTermFilter } from '~/vue/filters/CustomTerm';
import GoalResource from '~/angular/app/projects/project_resource';
import Vue from 'vue';
import GoalEditCreatePopup from '~/angular/app/projects/goal_settings_factory/GoalEditCreatePopup.vue';

export class GoalFactory {

    constructor() {
        this.availableCategories = [];
        this.isForUserCreationAllowed = false;
        this.forUserForcedUsername = undefined;
        this.isTasksHiddenByDefault = false;
    }

    allowCreationForUser(forUserUsernamesList) {
        this.forUserUsernamesList = forUserUsernamesList;
        this.isForUserCreationAllowed = true;
        return this;
    }

    setForUserCreationForced(username) {
        this.forUserForcedUsername = username;
        return this;
    }

    setTasksIsHiddenByDefault() {
        this.isTasksHiddenByDefault = true;
    }

    get modifiedAvailableCategories() {

        return this.availableCategories.map(c => {
            const modifiedCategory = Object.assign({}, c);
            if (c.name === 'Default') {
                c.name = 'Select Category/Vision';
            }
            if (c.is_global) {
                modifiedCategory.name += ' (global)';
            }
            return modifiedCategory;
        });
    }

    async getGoalDataToCopy() {
        const goalId = await selectFactory().endpoint_selector(GoalResource().get_goals_to_copy, false, {
            header: `Select ${customTermFilter('Goal')} to copy data`,
            info: `Select ${customTermFilter('Goal')}
                   from which you want to copy data and ${customTermFilter('Tasks')}.`,
            button_yes: 'Select',
            button_no: 'Cancel',
            empty: `You do not have any ${customTermFilter('Goal')} to select.`,
            no_border_radius_on_default_avatar: true,
        }, false);
        if (goalId) {
            const resp = await GoalResource().get_goal_data_to_copy(goalId);
            return resp.data;
        } else {
            return {};
        }
    }

    async selectCreationType() {
        const {
            popupComponent,
            vm
        } = simplePopupFactory.mount_vue_popup(CreateGoalTypeSelectorPopup);
        const selected = await popupComponent.show().catch(() => {
        });
        vm.$destroy();
        return selected;
    }

    async showCreateGoalPopup(initialData) {
        const {
            popupComponent,
            vm
        } = simplePopupFactory.mount_vue_popup(GoalEditCreatePopup, {
            editMode: false,
            availableCategories: this.modifiedAvailableCategories,
            forUserCreationAllowed: this.isForUserCreationAllowed,
            forUserUsernamesList: this.forUserUsernamesList,
            forUserForcedUsername: this.forUserForcedUsername,
        });

        const res = await popupComponent.show(initialData).catch(() => {
        });
        vm.$destroy();
        if (res) {
            this.res(res);
        } else {
            this.rej();
        }
    }

    async showEditGoalPopup(initialData, goalId) {
        const {
            popupComponent,
            vm
        } = simplePopupFactory.mount_vue_popup(GoalEditCreatePopup, {
            editMode: true,
            availableCategories: this.modifiedAvailableCategories,
            sourceGoalId: goalId
        });

        const res = await popupComponent.show(initialData).catch(() => {
        });
        vm.$destroy();
        if (res) {
            return res;
        }
    }

    async loadGoalCategories(username = undefined) {
        const categoriesFilter = {};
        if (username) {
            categoriesFilter['username'] = username;
        }
        try {
            const resp = await GoalResource().get_available_categories(categoriesFilter);
            this.availableCategories = resp.data;
        } catch (e) {
            Vue.notifications.error(e);
        } finally {
            Vue.loadingSpinner.hide();
        }
    }

    adjustInitialData(initialData, categoryId) {
        initialData.category = categoryId || initialData.category || null;
        if (!this.availableCategories.map(c => c.id).includes(initialData.category)) {
            initialData.category = null;
        }
        if (this.isTasksHiddenByDefault) {
            initialData.hide_tasks = true;
        }
        return initialData;
    }

    startGoalCreation(categoryId = null) {
        const self = this;
        return new Promise(async (res, rej) => {
            self.res = res;
            self.rej = rej;
            const creationType = await this.selectCreationType();
            if (!creationType) {
                self.rej('closed');
                return;
            }
            let initialData = {};
            if (creationType === 'copy') {
                try {
                    initialData = await this.getGoalDataToCopy();
                } catch (e) {
                    self.rej('closed');
                    return;
                }
            }
            await this.loadGoalCategories(this.forUserForcedUsername);
            return await this.showCreateGoalPopup(this.adjustInitialData(initialData, categoryId));
        });

    }

    editGoal(initialData) {
        return new Promise(async (resolve, reject) => {
            await this.loadGoalCategories(initialData.owner.name);
            const res = await this.showEditGoalPopup(initialData)
            if(res){
                console.log(res);
                resolve(res);
            }
        });
    }
}
