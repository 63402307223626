<template>
    <modal v-model="opened" :transition='0' :backdrop="false" class="edit-slide-popup">
        <div slot="header">
            <a id="close_popup" @click="closePopup"></a>
            <h3 class="modal-title">{{header}}</h3>
        </div>
        <div class="row" v-if="editMode===EDIT_MODES.ALL||editMode===EDIT_MODES.TITLE">
            <div class="col-sm-12">
                <strong>Title</strong>:
                <input id="caption" ref="caption" maxlength="63" type="text" v-model="caption" class="form-control"
                       placeholder="Give this slide a title">
            </div>
        </div>
        <div class="row margin-15-top" v-if="editMode===EDIT_MODES.ALL||editMode===EDIT_MODES.TITLE">
            <div class="col-sm-4" v-if="!audio_caption">
                <button class="btn btn-blue text-left" style="width: 173px;text-align: left; padding: 7px 15px 13px 0;"
                        @click="addAudioCaption()">
                <span class="vertical-middle" style="    width: 37px; margin-left: 4px;
                        text-align: center;
                        display: inline-block;">
                    <i class="fa fa-microphone fa-2 vertical-middle"></i>
                </span>
                    <span class="vertical-middle"> + Add Audio</span>
                </button>
            </div>
            <div class="col-sm-8  edit-slide-popup__action-description" v-if="!audio_caption">
                Add an audio caption so users can listen to the name.
            </div>
            <div class="col-sm-12" v-if="audio_caption">
                <strong>Audio Caption:</strong><br>
                <strong>{{audio_caption.name}}</strong>
                <span @click="resetAudioCaption()" style="vertical-align: top; font-weight: bold"
                      class="color-red pointer"> x</span>
            </div>
        </div>
        <div class="row margin-15-top" v-if="editMode===EDIT_MODES.ALL">
            <div class="col-sm-12">
                <div class="row" v-if="!media_content">
                    <div class="col-sm-4">
                        <button class="btn btn-blue text-left"
                                style="width: 173px;text-align: left; padding: 7px 15px 13px 0;"
                                @click="addMediaContent()">
                        <span class="vertical-middle" style="width: 37px; margin-left: 4px;
                                text-align: center;
                                display: inline-block;">
                            <i class="fa fa-picture-o fa-2 vertical-middle"></i>
                        </span>
                            <span class="vertical-middle"> + Add Media</span>
                        </button>
                    </div>
                    <div class="col-sm-8 edit-slide-popup__action-description">
                        Add Media which is main part of your slide.
                    </div>
                </div>
                <div v-else>
                    <strong>Media Content:</strong><br>
                    <media-preview
                            :item="media_content"
                            :allow-delete="true"
                            :show-name="true"
                            @onDelete="resetMediaContent"
                    ></media-preview>
                </div>
            </div>
        </div>

        <div class="row margin-15-top" v-if="editMode===EDIT_MODES.ALL||editMode===EDIT_MODES.DESCRIPTION">
            <div class="col-sm-12">
                <strong class="">Add more words under your media:</strong>
                <textarea ref="description" rows="5" maxlength="8191" type="text" class="form-control"
                          v-model="description"></textarea>
            </div>
        </div>
        <div class="row margin-15-top" v-if="editMode===EDIT_MODES.ALL||editMode===EDIT_MODES.DESCRIPTION">
            <div class="col-sm-4" v-if="!audio_description">
                <button class="btn btn-blue text-left" style="width: 173px;text-align: left; padding: 7px 15px 13px 0;"
                        @click="addAudioDescription()">
                <span class="vertical-middle" style="    width: 37px; margin-left: 4px;
                        text-align: center;
                        display: inline-block;">
                    <i class="fa fa-microphone fa-2 vertical-middle"></i>
                </span>
                    <span class="vertical-middle"> + Add Audio</span>
                </button>
            </div>
            <div class="col-sm-8 edit-slide-popup__action-description" v-if="!audio_description">
                Add an audio description so users can listen to it.
            </div>
            <div class="col-sm-12" v-if="audio_description">
                <strong>Audio Description:</strong><br>
                <strong>{{audio_description.name}}</strong>
                <span @click="resetAudioDescription()" style="vertical-align: top; font-weight: bold"
                      class="color-red pointer"> x</span>
            </div>
        </div>
        <div slot="footer">
            <button type="button" @click="closePopup" class="btn btn-mm  btn-primary">Cancel</button>
            <button type="button" @click="save" class="btn btn-mm  btn-green">Save</button>
        </div>
    </modal>
</template>

<script>
    import Vue from 'vue';
    import VueInlineMedia from "../../view_media/vue_inline_media_render_directive/VueInlineMedia";

    const EDIT_MODES = {
        ALL: 'ALL',
        TITLE: 'TITLE',
        DESCRIPTION: 'DESCRIPTION'
    };

    const DEFAULT_CONTENT = {
        audio_description: undefined,
        audio_caption: undefined,
        caption: '',
        description: '',
        media_content: undefined
    };

    export default {
        name: "WikiCreateEditSlidePopup",
        components: {VueInlineMedia},
        data() {
            const data = {
                opened: false,
                header: undefined,
                editMode: EDIT_MODES.ALL,
            };
            Object.assign(data, DEFAULT_CONTENT);
            return data;
        },
        computed: {
            EDIT_MODES() {
                return EDIT_MODES;
            }
        },
        methods: {
            $_resetData() {
                for (let key of Object.keys(DEFAULT_CONTENT)) {
                    this[key] = DEFAULT_CONTENT[key];
                }
            },
            $_show() {
                this.opened = true;
                const AUTOFOCUS_REFS = {};
                AUTOFOCUS_REFS[EDIT_MODES.ALL] = this.$refs.caption;
                AUTOFOCUS_REFS[EDIT_MODES.TITLE] = this.$refs.caption;
                AUTOFOCUS_REFS[EDIT_MODES.DESCRIPTION] = this.$refs.description;
                this.$nextTick(() => {
                    AUTOFOCUS_REFS[this.editMode]?.focus();
                });
            },
            resetAudioDescription() {
                this.audio_description = undefined;
            },
            resetAudioCaption() {
                this.audio_caption = undefined;
            },
            resetMediaContent() {
                this.media_content = undefined;
            },
            addAudioCaption() {
                const addMedia = Vue.getAngularModule('addMedia');
                this.opened = false;
                addMedia.add_media({
                    allow_add_from_library: true,
                    type: 'audio',
                    max_count: 1
                }, {
                    'default_callback': (media_list) => {
                        this.audio_caption = media_list[0];
                        this.opened = true;
                    },
                    'cancel_callback': () => {
                        this.opened = true;
                    }
                });
            },
            addAudioDescription() {
                const addMedia = Vue.getAngularModule('addMedia');
                this.opened = false;
                addMedia.add_media({
                    allow_add_from_library: true,
                    type: 'audio',
                    max_count: 1
                }, {
                    'default_callback': (media_list) => {
                        this.audio_description = media_list[0];
                        this.opened = true;
                    },
                    'cancel_callback': () => {
                        this.opened = true;
                    }
                });

            },
            addMediaContent() {
                const addMedia = Vue.getAngularModule('addMedia');
                this.opened = false;
                addMedia.add_media({
                    allow_add_from_library: true,
                    types: ['video', 'audio', 'image'],
                    max_count: 1
                }, {
                    'default_callback': (media_list) => {
                        this.media_content = media_list[0];
                        this.opened = true;
                    },
                    'cancel_callback': () => {
                        this.opened = true;
                    }
                });
            },
            createSlide() {
                this.$_resetData();
            },
            editSlide(initialData = {}, editMode = EDIT_MODES.ALL, header = 'Edit Slide') {
                this.$_resetData();
                this.editMode = editMode;
                this.header = header;
                for (let key of Object.keys(DEFAULT_CONTENT)) {
                    if (Object.keys(initialData).includes(key)) {
                        this[key] = initialData[key];
                    }
                }
                this.$_show();

                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            closePopup() {
                this.opened = false;
                this.reject('closed');
            },
            save() {
                const resultSlide = {};
                for (let key of Object.keys(DEFAULT_CONTENT)) {
                    resultSlide[key] = this[key];
                }
                this.opened = false;
                this.resolve(resultSlide);
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../styles/const";

    .edit-slide-popup {
        &__action-description {
            font-size: $bigger-font-size;
        }

    }

</style>