<template>
    <div class="media-users-list">
        <div class="media-users-list__image-part">
            <router-link :to="link">
                <img :src="user.avatar" class="img-circle media-users-list__image-part__image" :alt="user.name"/>
            </router-link>
        </div>
        <div class="media-users-list__content-part">
            <router-link :to="link">
                <img :src="icon"
                     class="media-users-list__content-part__icon" :alt="user.name">
            </router-link>
            <router-link :to="link">{{ user.name }}'s Media</router-link>
        </div>
    </div>
</template>

<script>
    import {DjangoUrls} from 'DjangoUrls';
    import {DASHBOARD_ICONS, DASHBOARD_ITEMS} from "../../dashboard/dashboard";

    export default {
        name: "FoldersIManageListUser",
        props: {
            user: {
                type: Object
            }
        },
        computed: {
            icon() {
                return DASHBOARD_ICONS[DASHBOARD_ITEMS.MEDIA];
            },
            link() {
                return DjangoUrls['users_media'](this.user.name);
            }
        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/mixins.scss";

  .media-users-list {
    @include managedUserInList();
  }
</style>