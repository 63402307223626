import Vue from 'vue';
import { DjangoUrls } from 'DjangoUrls';
import { decodeBackUrl } from 'shared/utils/back_url_generator';

export default {
    data() {
        return {
            urlFrom: undefined,
        };
    },
    computed: {
        defaultBackUrl() {
            return DjangoUrls['index']();
        },
        backUrlAvailableInRouter() {
            if (this.$router && this.$router.resolve && this.$router.resolve(this.backUrl).resolved.matched) {
                const matched = this.$router.resolve(this.backUrl).resolved.matched;
                if (!matched.length) {
                    return false;
                } else {
                    for (const mi of matched) {
                        if (!mi.meta.isErrorPage) {
                            return true;
                        }
                    }
                }
            }
            return false;
        },
        backUrl() {
            if (this.urlFrom) {
                return this.urlFrom;
            }
            return this.defaultBackUrl;
        },
        backHrefFromParam() {
            if (this.$router) {
                return this.$route.fullPath;
            }
            let full_path = document.location.href.substr(document.location.href.indexOf(document.location.pathname) + 1);
            return '/' + encodeURIComponent(full_path);
        },
    },
    methods: {
        $_goBack() {
            if (this.backUrlAvailableInRouter) {
                this.$router.replace(this.backUrl);
            } else {
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                document.location.href = this.backUrl;
            }
        },
        goBack() {
            this.$_goBack();
        },
    },
    mounted() {
        const hash = this.$route.hash;
        const query = this.$route.query;
        if (query['from']) {
            this.urlFrom = decodeBackUrl(query['from']);
        } else if (hash.indexOf('from') !== -1) {
            const from_url = hash.substr(hash.indexOf('from') + 5);
            this.urlFrom = decodeBackUrl(from_url);
        }
    }
};
