/**
 * Created by orion on 06.04.17.
 */
import axios from 'axios';
import urls from 'DjangoUrls';
import { ACCESS_LINK_TOKEN_HEADER_NAME } from '../wiki/common';

let { DjangoUrls } = urls;

BlogResource.$inject = ['$http'];

function BlogResource ($http) {
    return {
        get_main_info_and_viewer_state,
        get_blogs_i_manage,
        get_shared_view_only_blogs,
        get_blogs_i_can_create_entries,
        check_ability_to_create_proxy_entries,
        get_data_for_date_selector,
        get_diary_days,
        create_entry,
        reorder_items,
        make_me_admin,
        get_editable_diary_locations,

        check_access_link_state,
        authenticate_access_link,
        check_access_link_token,
    };

    function get_main_info_and_viewer_state (blogId, params, accessToken) {
        let url = DjangoUrls['api.v1:blog-get-main-info-and-viewer-state'](blogId);
        const headers = {};
        if (accessToken) {
            headers[ACCESS_LINK_TOKEN_HEADER_NAME] = accessToken;
        }
        return axios({
            method: 'GET',
            url,
            params,
            headers,
        });
    }

    function get_blogs_i_manage (order, params) {
        let url = DjangoUrls['api.v1:blog-get-blogs-i-manage']();
        return axios({
            method: 'GET',
            url: url,
            params: Object.assign({ order }, params)
        });
    }

    function get_shared_view_only_blogs (order, params) {
        let url = DjangoUrls['api.v1:blog-get-shared-view-only-blogs']();
        return axios({
            method: 'GET',
            url: url,
            params: Object.assign({ order }, params)
        });
    }

    function get_blogs_i_can_create_entries (blogId, preSelectParams = {}, params = {}) {
        let url = DjangoUrls['api.v1:blog-get-blogs-i-can-create-entries'](blogId);
        return axios({
            method: 'GET',
            url: url,
            params: Object.assign({}, preSelectParams, params)
        });
    }

    function check_ability_to_create_proxy_entries (blogId, query, limit, offset) {
        let url = DjangoUrls['api.v1:blog-check-ability-to-create-proxy-entries'](blogId);
        return axios({
            method: 'GET',
            url: url,
            params: {
                query,
                limit,
                offset
            }
        });
    }

    function get_data_for_date_selector (blog_id, params) {
        let url = DjangoUrls['api.v1:blog-get-data-for-date-selector'](blog_id);
        return axios({
            method: 'GET',
            url,
            params
        });
    }

    function get_diary_days (blog_id, params, accessToken) {
        let url = DjangoUrls['api.v1:blog-get-diary-days'](blog_id);
        const headers = {};
        if (accessToken) {
            headers[ACCESS_LINK_TOKEN_HEADER_NAME] = accessToken;
        }
        return axios({
            method: 'GET',
            url,
            params,
            headers,
        });
    }

    function create_entry (blog_id, entry_data) {
        let url = DjangoUrls['api.v1:blog-create-entry'](blog_id);
        return axios({
            method: 'POST',
            url: url,
            data: entry_data
        });
    }

    function reorder_items (blog_pk, date, ordered_pk_list) {
        const url = DjangoUrls['api.v1:blog-reorder-slides'](blog_pk);
        return axios({
            method: 'PATCH',
            url: url,
            data: {
                date: date,
                ordered_pk_list: ordered_pk_list
            }
        });
    }

    function make_me_admin (blog_pk) {
        const url = DjangoUrls['api.v1:blog-make-me-admin'](blog_pk);
        return axios({
            method: 'POST',
            url: url
        });
    }

    function get_editable_diary_locations (profilesIdListToCheck) {
        const url = DjangoUrls['api.v1:blog-get-editable-diary-locations']();
        return axios({
            method: 'GET',
            url: url,
            params: { id_list: profilesIdListToCheck }
        });
    }

    function check_access_link_state (id, access_code) {
        const url = DjangoUrls[`api.v1:blog-check-access-link-state`](id);
        return axios({
            method: 'get',
            url,
            params: { access_code }
        });
    }

    function authenticate_access_link (id, access_code, password) {
        const url = DjangoUrls[`api.v1:blog-authenticate-access-link`](id);
        return axios({
            method: 'POST',
            url,
            data: {
                access_code,
                password
            },
            noRedirectOnError: true,
        });
    }

    function check_access_link_token (id, access_code, token) {
        const url = DjangoUrls[`api.v1:blog-check-access-link-token`](id);
        const headers = {};
        headers[ACCESS_LINK_TOKEN_HEADER_NAME] = token;
        return axios({
            method: 'POST',
            url,
            data: { access_code },
            noRedirectOnError: true,
            headers
        });
    }

}

export default BlogResource;
