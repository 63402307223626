var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "community-page-menu" }, [
      _c("div", {
        staticClass: "community-page-menu__avatar",
        style: _vm.avatarStyle,
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "community-page-menu__name" },
        [
          _vm._v(_vm._s(_vm.name) + "\n            "),
          _vm.showSettings
            ? _c(
                "router-link",
                {
                  staticStyle: { color: "#fff" },
                  attrs: { to: { name: "community-settings" } },
                },
                [_c("i", { staticClass: "fa fa-gear" })]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "community-page-menu__buttons-panel" },
        _vm._l(_vm.icons, function (icon) {
          return _c(
            "div",
            { staticClass: "community-page-menu__buttons-panel__button" },
            [
              icon.to && !icon.onlyMobile
                ? _c("router-link", { attrs: { to: icon.to } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "community-page-menu__buttons-panel__button__image",
                      },
                      [
                        icon.type === "fa"
                          ? _c("i", { class: icon.classes })
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "community-page-menu__buttons-panel__button__label",
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(icon.label) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ])
                : !icon.to && !icon.onlyMobile
                ? _c("div", {}, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "community-page-menu__buttons-panel__button__image",
                      },
                      [
                        icon.type === "fa"
                          ? _c("i", { class: icon.classes })
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "community-page-menu__buttons-panel__button__label",
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(icon.label) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "community-page-menu__mobile" }, [
      _c(
        "div",
        {
          staticClass: "community-page-menu__mobile__header",
          class: { opened: _vm.isCollapsed },
        },
        [
          _c("div", {
            staticClass: "community-page-menu__mobile__header__avatar",
            style: _vm.avatarStyle,
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "community-page-menu__mobile__header__name" },
            [_vm._v(_vm._s(_vm.name))]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "community-page-menu__mobile__header__toggle-button",
              class: { pushed: _vm.isCollapsed },
              on: {
                click: function ($event) {
                  _vm.isCollapsed = !_vm.isCollapsed
                },
              },
            },
            [_c("i", { staticClass: "fa fa-bars" })]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isCollapsed
        ? _c(
            "div",
            { staticClass: "community-page-menu__mobile__menu" },
            _vm._l(_vm.icons, function (icon) {
              return _c(
                "div",
                {
                  key: icon.label,
                  staticClass: "community-page-menu__mobile__menu__item",
                  on: {
                    click: function ($event) {
                      return _vm.openPage(icon.to)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "community-page-menu__mobile__menu__item__icon",
                    },
                    [_c("i", { class: icon.classes })]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "community-page-menu__mobile__menu__item__label",
                    },
                    [_vm._v(_vm._s(icon.label))]
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }