var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c("div", {}, [
      _vm.loaded
        ? _c("div", { staticClass: "row margin-10-bottom" }, [
            _vm.otherUsersWhoseGoalsAreAvailable.length
              ? _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedGoalsFilter,
                          expression: "selectedGoalsFilter",
                        },
                      ],
                      staticClass: "form-control",
                      staticStyle: { "margin-bottom": "5px" },
                      attrs: {
                        name: "selectedGoalsFilter",
                        id: "selectedGoalsFilter",
                      },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedGoalsFilter = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    _vm._l(_vm.availableFilters, function (availableFilter) {
                      return _c(
                        "option",
                        { domProps: { value: availableFilter.code } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(availableFilter.label) +
                              "\n                    "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "text-right",
                class: {
                  "col-sm-8": _vm.otherUsersWhoseGoalsAreAvailable.length,
                  "col-sm-12": !_vm.otherUsersWhoseGoalsAreAvailable.length,
                },
              },
              [
                _c("strong", [
                  _c(
                    "a",
                    {
                      staticStyle: {
                        "text-decoration": "none",
                        "margin-right": "15px",
                      },
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.onLinkGoalClick.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-link" }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticStyle: { "text-decoration": "underline" } },
                        [_vm._v("Link " + _vm._s(_vm._f("customTerm")("Goal")))]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("strong", [
                  _c(
                    "a",
                    {
                      staticStyle: { "text-decoration": "none" },
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.createGoal.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-plus" }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticStyle: { "text-decoration": "underline" } },
                        [
                          _vm._v(
                            "Create " + _vm._s(_vm._f("customTerm")("Goal"))
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "div",
            {},
            [
              _vm._l(_vm.groupedByCalendarOwnerLinks, function (linkGroup) {
                return _c(
                  "div",
                  [
                    _vm.targetCalendarOwnerId !== linkGroup.calendarOwner.id
                      ? _c("div", { staticClass: "calendar-owner-header" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm._f("customTerm")("Goals")) +
                              " linked by "
                          ),
                          _c("strong", [
                            _vm._v(_vm._s(linkGroup.calendarOwner.name)),
                          ]),
                          _vm._v(":\n                "),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(linkGroup.links, function (goalLink) {
                      return _c("event-detail-page-goals-goal", {
                        key: _vm.getLinkKey(goalLink),
                        attrs: {
                          "calendar-owner-id": linkGroup.calendarOwner.id,
                          calendarOwnerName: linkGroup.calendarOwner.name,
                          "event-id": _vm.eventId,
                          goalTaskLayout: _vm.goalTaskLayout,
                          goal: goalLink,
                          availableCompletionOptions:
                            _vm.availableCompletionOptions,
                        },
                        on: {
                          unlinkGoal: function ($event) {
                            return _vm.unlinkClicked(goalLink)
                          },
                          editLinkedTasks: function ($event) {
                            return _vm.editLinkedTasks(goalLink)
                          },
                        },
                      })
                    }),
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _vm.links.length === 0
                ? _c("div", { staticClass: "alert alert-warning" }, [
                    _vm._v(
                      "\n                There are no Linked Goals yet.\n            "
                    ),
                  ])
                : _vm._e(),
            ],
            2
          )
        : _c("div", { staticClass: "text-center" }, [
            _vm._v("\n            Loading...\n        "),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }