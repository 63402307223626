var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.attachments, function (attachment) {
        return _c("folder-item", {
          key: attachment.id,
          attrs: {
            attachment: attachment.media_attachment,
            size: 90,
            "allow-delete": _vm.canEditAttachments,
          },
          on: {
            delete: function ($event) {
              return _vm.del(attachment)
            },
            click: function ($event) {
              return _vm.showAttachment(attachment.media_attachment)
            },
          },
        })
      }),
      _vm._v(" "),
      _vm.canEditAttachments
        ? _c("div", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: "Add Attachment",
                expression: "'Add Attachment'",
              },
            ],
            staticClass: "hidden-print mm-icon add-attachment",
            style: _vm.iconStyle,
            on: { click: _vm.addAttachment },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }