import Vue from 'vue';
import {DjangoUrls} from 'DjangoUrls';
import {simplePopupFactory} from "shared/simple_popups/simplePopupFactory";
import CreateReceiptPopup from "./wiki_settings_page/wiki_read_receipt/CreateReceiptPopup";
import WikiMembershipResource from "./wiki_settings_page/wiki_membership_resource";
import {WIKI_PERMISSION_LEVEL, WIKI_PERMISSION_VALUE} from "../wiki_page/common/wikiPermissions";
import moment from "moment";
import wiki_read_receipts_multiple_resource from "./wiki_settings_page/wiki_read_receipts_multiple_resource";
import ReadReceiptsListPopup from "./wiki_read_receipts_common/ReadReceiptsListPopup";

export default {
    data() {
        return {};
    },
    computed: {},
    methods: {
        openTreeOnMember(member) {
            if (member.tree_opened) {
                member.tree_opened = false;
                this.openedMember = undefined;
                return;
            }
            if (this.openedMember) {
                Vue.set(this.openedMember, 'tree_opened', false);
            }
            this.openedMember = member;
            Vue.set(this.openedMember, 'tree_opened', true);
        },
        toggleMemberAdminStatus(member) {
            const $rootScope = Vue.getAngularModule('$rootScope');
            const $q = Vue.getAngularModule('$q');
            const notifications = Vue.getAngularModule('notifications');
            const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
            const WikiMembershipResource = Vue.getAngularModule('WikiMembershipResource');
            const CurrentUser = Vue.getAngularModule('CurrentUser');
            let resource;
            let unmakeSelfAdmin = false;
            if (!member.is_admin) {
                resource = WikiMembershipResource.unmake_admin;
                if (member.userprofile.id === CurrentUser.id) {
                    unmakeSelfAdmin = true;
                }
            } else {
                resource = WikiMembershipResource.make_admin;
                if (member === this.openedMember) {
                    Vue.set(this.openedMember, 'tree_opened', false);
                }
            }

            let confirmation = $q.defer();

            if (unmakeSelfAdmin) {
                simplePopupFactory.show_popup('Warning!', 'Warning, you are about to remove yourself as Admin for this Wiki. This cannot be undone, are you sure?', 'Yes', 'Cancel')
                    .then(() => {
                        confirmation.resolve();
                    }, () => {
                        member.is_admin = true;
                        confirmation.reject();
                        return new Promise(() => {
                        });
                    });
            } else {
                confirmation.resolve();
            }
            confirmation.promise.then(() => {
                $rootScope.show_dimmer();
                return resource(member.id);
            }, () => new Promise(() => {
            }))
                .then(resp => {
                    notifications.success('Updated');
                    if (unmakeSelfAdmin) {
                        document.location.href = DjangoUrls['users_wiki'](this.wikiId);
                    }
                }, err => notifications.error(err || 'Error'))
                .finally($rootScope.hide_dimmer);
        },
        async sendReadReceipt(member) {
            let resp;
            const fakeAdminsPermissions = [{
                level: WIKI_PERMISSION_LEVEL.WHOLE_WIKI,
                value: WIKI_PERMISSION_VALUE.VIEW
            }];
            let permissionsList;

            if (member.is_admin) {
                permissionsList = fakeAdminsPermissions;
            } else {
                try {
                    this.$loadingSpinner.show();
                    resp = await WikiMembershipResource().get_members_permissions(member.id);
                    permissionsList = resp.data;
                } catch (e) {
                    this.$notifications.error(e);
                } finally {
                    this.$loadingSpinner.hide();
                }
            }
            if (!permissionsList) {
                return;
            }
            const suggestedName = `Read Receipt ${moment().format('DD.MM.YYYY')}`;
            const {popupComponent, fEl, vm} = simplePopupFactory.mount_vue_popup(CreateReceiptPopup);
            let receiptParams;
            try {
                receiptParams = await popupComponent.show(this.sections, permissionsList, suggestedName);
            } catch (e) {

            } finally {
                vm?.$destroy();
            }
            if (!receiptParams) {
                return;
            }
            receiptParams.id = member.id;
            try {
                this.$loadingSpinner.show();
                const {data: createdReceiptData} = await wiki_read_receipts_multiple_resource.create_receipt(this.wikiId, receiptParams);
                Vue.set(member, 'received_read_receipts_count', createdReceiptData.received_read_receipts_count);
                this.$notifications.success('Sent');
            } catch (e) {
                this.$notifications.error(e);
            } finally {
                this.$loadingSpinner.hide();
            }
        },
        async showReadReceipts(member) {
            let receipts;
            try {
                this.$loadingSpinner.show();
                ({data: receipts} = await wiki_read_receipts_multiple_resource.get_receipts(this.wikiId, member.id));
                this.$loadingSpinner.hide();
            } catch (e) {
                this.$notifications.error(e);
            } finally {
                this.$loadingSpinner.hide();
            }
            if (!receipts) {
                return;
            }
            const {popupComponent, fEl, vm} = simplePopupFactory.mount_vue_popup(ReadReceiptsListPopup);
            try {
                await popupComponent.show(receipts);
            } catch (e) {

            } finally {
                vm?.$destroy();
                fEl?.focus();
            }
        },
        removeMember(member) {
            const $rootScope = Vue.getAngularModule('$rootScope');
            const notifications = Vue.getAngularModule('notifications');
            const WikiMembershipResource = Vue.getAngularModule('WikiMembershipResource');
            const CurrentUser = Vue.getAngularModule('CurrentUser');
            let removeSelf = false;
            if (member.userprofile && member.userprofile.id === CurrentUser.id) {
                removeSelf = true;
            }
            simplePopupFactory.show_popup('Remove Member', 'Are you sure you want to remove this member from Wiki?', 'Remove', 'Cancel', 'btn-red')
                .then(y => {
                    $rootScope.show_dimmer();
                    return WikiMembershipResource.remove_member(member.id);
                }, () => new Promise(() => {
                }))
                .then(resp => {
                    this.members.splice(this.members.indexOf(member), 1);
                    notifications.success('Removed');
                    if (removeSelf) {
                        document.location.href = DjangoUrls['users_wiki'](this.wikiId);
                    }
                }, err => notifications.error(err || 'Error'))
                .finally($rootScope.hide_dimmer);
        },
    },
};
