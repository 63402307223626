var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "calendar-page-event-holidays", style: _vm.holidayStyle },
    [
      _vm.holidays.editable
        ? _c(
            "div",
            { staticClass: "calendar-page-event-holidays__edit-panel" },
            [
              _c("i", {
                staticClass: "fa fa-pencil color-dark-blue pointer",
                staticStyle: { "margin-right": "5px" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.editHolidays.apply(null, arguments)
                  },
                },
              }),
              _vm._v(" "),
              _c("i", {
                staticClass: "fa fa-trash-o color-red pointer",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.deleteHolidays.apply(null, arguments)
                  },
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("i", {
        staticClass: "fa fa-star-christmas color-event vertical-middle",
      }),
      _vm._v(" "),
      _c("span", { staticClass: "vertical-middle" }, [
        _c("strong", {}, [
          _vm._v(
            "\n            " +
              _vm._s(_vm._f("cutName")(_vm.holidays.holidays.name, 40)) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("span", {}, [_vm._v("Holiday")]),
        _vm._v(" in "),
        _c(
          "strong",
          { staticClass: "pointer", on: { click: _vm.showGroups } },
          [_vm._v("Group Events")]
        ),
      ]),
      _vm._v(" "),
      _c("group-events-page-add-holiday-popup", {
        ref: "group-events-page-add-holiday-popup",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }