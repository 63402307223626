let angular = require('angular');
let _ = require('lodash');
storyList.$inject = ['createPostFactory', '$q', 'DragDropUtils', 'simplePopupFactory', 'notifications', '$rootScope', '$log', '$location', 'addMedia'];

function storyList(createPostFactory, $q, DragDropUtils, simplePopupFactory, notifications, $rootScope, $log, $location, addMedia) {
    return {
        restrict: 'E',
        replace: true,
        transclude: {
            empty: '?storyListEmpty'
        }, //for empty story message
        scope: {
            title: '@',
            targetId: '=',
            storyResource: '=',
            editable: '=',
            allowAddContent: '=',
            allowBulkCreate: '=',
            editMode: '=?',
            additionalParams: '=?', // for get story by code for now.
            headerStyle: '@?'
        },
        template: require('./storyList.html'),
        link: function ($scope, element, attrs, controller) {
            if (!$scope.headerStyle) {
                $scope.headerStyle = 'medium';
            }

            $scope.$watch('editMode', (n, o) => {
                $scope.edit_mode = n;
            })

            $scope.ITEMS_PER_PAGE = 20;
            $scope.edit_mode = false;
            $scope.story_items = [];
            $scope.total_count = 0;
            $scope.initialized = false;

            function init() {
                fetch_items($location.search()['page'] || 1);
            }

            function fetch_items(page) {
                $scope.current_page = parseInt(page);
                $rootScope.show_dimmer();
                $scope.storyResource.get_story($scope.targetId, $scope.current_page, $scope.additionalParams)
                    .then(resp => {
                        $scope.current_page = parseInt(page);
                        $scope.story_items = resp.data.results;
                        $scope.total_count = resp.data.count;
                        $scope.initialized = true;
                    })
                    .finally($rootScope.hide_dimmer);
            }

            init();

            $scope.page_changed = function () {
                if (!$scope.initialized) {
                    return
                }
                $location.search('page', $scope.current_page);
                fetch_items($scope.current_page);
            }

            function media_bulk_upload(items_list) {
                $rootScope.show_dimmer();
                return $scope.storyResource.bulk_create_from_media($scope.targetId, items_list.map(i => i.pk))
                    .then(resp => {
                        fetch_items(resp.data.last_page);
                    }, err => {
                        notifications.error(err || 'Error');
                        $rootScope.hide_dimmer();
                    });
            }

            $scope.add_post = function () {
                createPostFactory.create_post_through_popup({allow_header: true})
                    .then(post_data => {
                        $log.debug(post_data);
                        //* media_item_id_list:{Array} of {Number} - list of media id to add
                        let post_create_data = {
                            text: post_data.text,
                            is_header: post_data.is_header,
                        };
                        if (post_data.sticker) {
                            post_create_data.sticker = post_data.sticker;
                        }
                        if (post_data.media_item_id_list.length === 1) {
                            post_create_data.media_attachment_id = post_data.media_item_id_list[0];
                        }
                        $rootScope.show_dimmer();
                        return $scope.storyResource.add_to_story($scope.targetId, post_create_data);
                    }, () => new Promise(()=>{}))
                    .then(resp => {
                        $scope.total_count = resp.data.count;
                        if (resp.data.last_page == $scope.current_page) {
                            $scope.story_items.push(resp.data.item);
                            notifications.success('Added');
                        } else {
                            fetch_items(resp.data.last_page);
                        }

                    }, err => notifications.error(err || 'Error'))
                    .finally($rootScope.hide_dimmer);
            };

            $scope.onDropComplete = DragDropUtils.onDropComplete;

            $scope.move_entry = move_entry;

            function move_entry(entry, target) {
                /**
                 * @param {object} entry - day entry
                 * @param {string} target - 'up'||'down'
                 */
                let entry_index = $scope.story_items.indexOf(entry);
                if (entry_index === 0 && target === 'up') {
                    notifications.error('Entry is already first');
                    return;
                }
                if (entry_index === $scope.story_items.length - 1 && target === 'down') {
                    notifications.error('Entry is already last');
                    return;
                }
                if (target === 'up') {
                    entry_index--;
                } else {
                    if (target === 'down') {
                        entry_index++;
                    }
                }
                DragDropUtils.onDropComplete(entry_index, entry, undefined, true, $scope.story_items, $scope.drag_drop_callback);
            }

            $scope.edit_entry = function (entry) {
                createPostFactory.edit_post_through_popup(entry, {allow_header: true, button_yes: 'Save'})
                    .then(post_data => {
                        let post_create_data = {
                            text: post_data.text,
                            is_header: post_data.is_header,
                        };
                        if (post_data.sticker) {
                            post_create_data.sticker = post_data.sticker;
                        }
                        if (post_data.media_item_id_list.length === 1) {
                            post_create_data.media_attachment_id = post_data.media_item_id_list[0];
                        }
                        return $scope.storyResource.update_item($scope.targetId, entry.id, post_create_data);
                    }, () => new Promise(()=>{}))
                    .then(resp => {
                        entry.text = resp.data.text;
                        entry.is_header = resp.data.is_header;
                        entry.sticker = resp.data.sticker;
                        entry.media_attachment = resp.data.media_attachment;
                        notifications.success('Updated');
                    }, err => notifications.error(err || 'Error'))
                    .finally($rootScope.hide_dimmer);
            };

            $scope.delete_entry = function (entry) {
                simplePopupFactory.show_popup('Delete Item', 'Are you sure you want to delete this entry?', 'Delete', 'Cancel')
                    .then(() => {
                        return $scope.storyResource.remove_item($scope.targetId, entry.id);
                    }, () => new Promise(()=>{}))
                    .then(resp => {
                        if ($scope.current_page == resp.data.last_page) {
                            fetch_items(resp.data.last_page);
                        } else {
                            if ($scope.story_items.length == 1) {
                                fetch_items(resp.data.last_page);
                            } else {
                                fetch_items($scope.current_page);
                                $scope.total_count = resp.data.count;
                            }
                        }
                        notifications.success('Deleted');
                    }, err => notifications.error(err || 'Error'));
            };

            $scope.drag_drop_callback = function (entry_items) {
                $scope.story_items = entry_items;
                $rootScope.show_dimmer();
                $scope.storyResource.reorder_items($scope.targetId, entry_items.map(item => item.id))
                    .then(function (resp) {
                        notifications.success('Items was successfully reordered');
                    }, err => {
                        notifications.error(err || 'Error')
                    })
                    .finally($rootScope.hide_dimmer);
            };

            $scope.bulk_upload = function () {
                addMedia.add_media(
                    {
                        allow_add_from_library: true,
                        max_count: 20,
                        types: ['video', 'audio', 'image'],
                        popup_header: 'What sort of media would you like to add?'
                    },
                    {
                        default_callback: media_bulk_upload,
                        cancel_callback: _.noop
                    }
                )
            }

        }
    };
}

angular.module('mmApp.shared.storyList', [])
    .directive('storyList', storyList);

export default 'mmApp.shared.storyList'