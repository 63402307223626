var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "hotkey",
          rawName: "v-hotkey",
          value: _vm.keymap,
          expression: "keymap",
        },
      ],
    },
    [
      _c(
        "community-page-alternative-header",
        { attrs: { title: "Our Wiki", "left-part-size": 6 } },
        [
          _c("vue-folder-button", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.editMode && !_vm.isMainSectionOpened && _vm.wikiEditable,
                expression: "editMode&&!isMainSectionOpened&&wikiEditable",
              },
            ],
            attrs: {
              tabindex: "0",
              "no-text-on-mobile": true,
              disabled: _vm.moveSectionsOrSlidesButtonDisabled,
              "icon-class": "fas fa-arrow-from-top fa-rotate-180",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.moveSectionsOrSlides.apply(null, arguments)
              },
            },
          }),
          _vm._v(" "),
          _c("vue-folder-button", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.editMode && _vm.createSectionVisible,
                expression: "editMode&&createSectionVisible",
              },
            ],
            attrs: {
              "no-text-on-mobile": true,
              "icon-class": "fa-plus",
              tabindex: "0",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.createSection.apply(null, arguments)
              },
            },
          }),
          _vm._v(" "),
          _vm.viewerIsAdmin && !_vm.editMode
            ? _c("vue-folder-button", {
                attrs: {
                  target: { name: "community-wiki-access-page" },
                  "is-router-link": true,
                  "icon-class": "fa-key",
                  color: "brown",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.viewerIsAdmin && !_vm.editMode
            ? _c("vue-folder-button", {
                attrs: { tabindex: "0", "icon-class": "fa-file-contract" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.showWikiLog.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.viewerIsAdmin && !_vm.editMode
            ? _c("vue-folder-button", {
                attrs: { "icon-class": "fa-gear" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.editWiki.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isEditable
            ? _c("vue-folder-button", {
                attrs: {
                  tabindex: "0",
                  "icon-class": _vm.editMode ? "fa-eye" : "fa-pencil",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.toggleEditMode.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isMainSectionOpened
            ? _c("vue-folder-button", {
                attrs: {
                  tabindex: "0",
                  color: "blue",
                  "icon-class": "fa-reply",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.goBack.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.readReceiptMode
        ? _c("wiki-read-receipt-page-navigation", {
            staticClass: "margin-15-top margin-10-bottom",
            on: {
              onSectionOpen: _vm.onSectionOpen,
              onSlideOpen: _vm.onSlideOpen,
              onMainSectionOpen: _vm.onMainSectionOpen,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("wiki-page-abstract", {
        attrs: { "wiki-id": _vm.wikiId, getSectionLink: _vm.getSectionLink },
        on: {
          onMainSectionOpen: _vm.onMainSectionOpen,
          onSectionOpen: _vm.onSectionOpen,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }