<template>
    <span style="display: inline-block; min-width: 30px; text-align: left;"></span>
</template>

<script>
    export default {
        name: "TypingDots",
        mounted() {
            this.$_dots = window.setInterval(() => {
                let wait = this.$el;
                if (wait.innerHTML.length >= 3)
                    wait.innerHTML = "";
                else
                    wait.innerHTML += ".";
            }, 250);
        },
        beforeDestroy() {
            clearInterval(this.$_dots);
        }
    };
</script>