<template>
    <div class="">
        <div class="row margin-5-top" v-if="isSyncPossible">
            <div class="col-sm-12 text-right">
                <vue-switch-with-label
                        label="Sync Logs with same names "
                        name="sync_logs"
                        v-model="syncLogs"
                ></vue-switch-with-label>
            </div>
        </div>
        <div class="" style="max-height: 335px; overflow-y: auto; overflow-x: hidden;">
            <div class="row" v-for="profileLocationsData in editableLocationsData">
                <div class="col-sm-12">
                    <div class="row"
                         v-if="editableLocationsData.length>1||((editableLocationsData.length===1)&&(editableLocationsData[0].profile.id!==calendarOwner.id))">
                        <div class="col-sm-12">
                            <img :src="profileLocationsData.profile.avatar" alt="" class="img-circle vertical-middle"
                                 width="40" height="40">
                            <strong class="vertical-middle">
                                <simple-link :target="profileLocationsData.profile"></simple-link>
                            </strong>:
                        </div>
                    </div>
                    <div class="row" v-for="location in profileLocationsData.locations">
                        <div class="col-sm-12">
                            <div class="row">
                                <div class="col-xs-12" style="height: 22px">
                                    <span class="align-helper"></span>
                                    <label style="vertical-align: bottom">{{location.name}}</label>
                                </div>
                            </div>
                            <textarea
                                    class="form-control modal-text-input-textarea"
                                    v-model="location.text"
                                    @input="textChanged(location.id, location.name, location.text)"
                                    rows="5"></textarea>
                            <span class="color-red">Note: Once you save this answer a copy will be kept on multi me for audit purposes even if deleted from this Dairy</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "EventDetailPageCreateDiaryEntryPopupLogsPart",
        props: {
            calendarOwner: Object,
        },
        data() {
            return {
                editableLocationsData: [],
                syncLogs: false,
            };
        },
        computed: {
            isSyncPossible() {
                let foundLogsNames = [];
                if (this.editableLocationsData.length < 2) {
                    return false;
                }
                for (let profileLocationsData of this.editableLocationsData) {
                    for (let location of profileLocationsData.locations) {
                        if (foundLogsNames.indexOf(location.name) !== -1) {
                            return true;
                        } else {
                            foundLogsNames.push(location.name);
                        }
                    }
                }
            }
        },
        methods: {
            textChanged(logId, logName, val) {
                if (!this.syncLogs || !this.isSyncPossible) {
                    return;
                }
                for (let profileLocationsData of this.editableLocationsData) {
                    for (let location of profileLocationsData.locations) {
                        if (logId !== location.id && logName === location.name) {
                            location.text = val;
                        }
                    }
                }
            },
            setLocations(editableLocationsData) {
                this.editableLocationsData = editableLocationsData;
            },
            resetData() {
                this.editableLocationsData = [];
            },
            onLocationInput(val) {

            },
            dumpData() {
                let res = {
                    locations_id_list: [],
                    locations_text_list: [],
                };
                for (let profileLocationsData of this.editableLocationsData) {
                    for (let location of profileLocationsData.locations) {
                        res.locations_id_list.push(location.id);
                        res.locations_text_list.push(location.text);
                    }
                }
                return res;
            }
        }
    };
</script>

<style scoped>

</style>