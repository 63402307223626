var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item
    ? _c(_vm.item.component, {
        tag: "component",
        attrs: {
          label: _vm.item.label,
          image: _vm.item.image,
          whiteImage: _vm.item.whiteImage,
          link: _vm.item.link,
          enlarged: _vm.item.enlarged,
          "main-color": _vm.item.mainColor,
          white: _vm.white || _vm.item.white,
          "white-mode": _vm.item.whiteMode,
          backgroundSize: _vm.item.backgroundSize,
          backgroundSizeWhite: _vm.item.backgroundSizeWhite,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }