const SelfWidthAwareMixin = {
    data() {
        return {
            elWidth: undefined,
            elWidthFound: false,
        };
    },
    computed: {
        swamElForMeasure() {
            return this.$el;
        },
        swamItemSize() {

        },
        $_swamItemsPerRow() {
            if (this.swamItemSize && this.elWidthFound) {
                return Math.floor(this.elWidth / this.swamItemSize);
            }
        },
        swamAvailableSpace(){
            return this.elWidth;
        },
        swamFakeIconStyle() {
            return {
                'display': 'inline-block',
                'width': `${this.swamItemSize}px`,
                'height': `${this.swamItemSize}px`,
            };
        },
        swamMinItemSize() {

        },
        swamMargin() {
            return 10;
        },
        swamRecommendedItemSize() {
            if (!this.swamMinItemSize) {
                return;
            }
            const MARGIN = this.swamMargin;
            const AVAILABLE_SPACE = this.swamAvailableSpace;
            const MIN_SIZE = this.swamMinItemSize;
            const SIZE_WITH_MARGIN = (MIN_SIZE + MARGIN);
            const AMOUNT_TO_APPEND = Math.floor(AVAILABLE_SPACE / SIZE_WITH_MARGIN);
            const TOOK_SPACE = AMOUNT_TO_APPEND * SIZE_WITH_MARGIN;
            const REST_SPACE = AVAILABLE_SPACE - TOOK_SPACE;

            if (REST_SPACE > 0) {
                return MIN_SIZE + Math.floor(REST_SPACE / AMOUNT_TO_APPEND) - 1;
            } else {
                return MIN_SIZE - 1;
            }
        },
    },
    methods: {
        elWidthEstablished() {

        },
        $_swamGetFakeItemsCount(itemsCount) {
            if (this.$_swamItemsPerRow) {
                const restItems = itemsCount % this.$_swamItemsPerRow;
                if (restItems) {
                    return this.$_swamItemsPerRow - restItems;
                }
            }
            return 0;
        },
        swamGetFakeItems(totalIconsCount) {
            const fakeIconsCount = this.$_swamGetFakeItemsCount(totalIconsCount);
            const res = [];
            for (let i = 0; i < fakeIconsCount; i++) {
                res.push({id: `${i}_${Math.random()}`});
            }
            return res;
        },
        updateElWidth() {
            const elWidth = this.swamElForMeasure?.getBoundingClientRect()?.width;
            if (elWidth) {
                if (!this.elWidthFound) {
                    this.elWidthFound = true;
                    this.$nextTick(() => {
                        this.elWidthEstablished();
                    });
                }
            }
            this.elWidth = elWidth || 300;
        },
        $_attemptToCorrectElWidth(attempt = 1) {
            if (this.elWidthFound) {
                return;
            }
            this.$nextTick(() => {
                setTimeout(() => {
                    this.updateElWidth();
                    if (!this.elWidthFound && attempt < 30) {
                        this.$_attemptToCorrectElWidth(attempt + 1);
                    }
                }, 50);
            });
        }
    },
    mounted() {
        this.updateElWidth();
        this.$_attemptToCorrectElWidth();
        window.addEventListener('resize', () => {
            this.updateElWidth();
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', () => {
            this.updateElWidth();
        });
    }
};


export default SelfWidthAwareMixin;