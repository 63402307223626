<template>
    <div class="inline-block">
        <a :href="target.link" style="text-decoration: none">
            <img :src="target.avatar" width="40" height="40" class="img-circle vertical-middle" alt="Avatar">
            <strong class="vertical-middle">{{target.name}}</strong>
        </a><span class="vertical-middle" v-if="postfixLabel">{{postfixLabel}}</span>
    </div>
</template>

<script>
    import SimpleLinkMixin from "./SimpleLinkMixin";

    export default {
        name: "SimpleLinkWithAvatar",
        mixins: [SimpleLinkMixin],
    };
</script>