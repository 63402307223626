<template>
    <div class="event-goals-goal">

        <goals-list-item :goal="goal.goal"
                         :progress="goalProgress"
                         :goal-name-is-link="goal.viewer_membership_status.can_create_tasks"
                         :allow-archive-goal="false"
                         :key="goal.id">
            <span slot="buttonsPanel">
                <i class="fa fa-link event-goals-goal__button"
                   @click="editLinkedTasks"
                   v-show="isDeletionAvailable&&editMode"
                   :class="{'deletion-available': isDeletionAvailable, 'edit-available':isEditModeAvailable}"
                   v-tooltip="editLinkedTasksLabel"
                ></i>
                <i class="fa event-goals-goal__button"
                   @click="toggleEditMode"
                   :class="{'fa-pencil':!editMode,'fa-eye':editMode, 'deletion-available':isDeletionAvailable,}"
                   v-tooltip="editModeTooltip"
                ></i>
                <i class="fa fa-times event-goals-goal__button delete-button"
                   @click="unlinkGoal"
                   v-show="isDeletionAvailable&&editMode"
                   v-tooltip="'Remove link to Goal'"></i>
            </span>
        </goals-list-item>
        <goal-create-panel :allow-filter="false"
                           @run_wizard="runWizard"
                           @quick_create="quickCreateTask"
                           v-if="goal.viewer_membership_status.can_create_tasks"
        ></goal-create-panel>
        <div class="text-center" v-else style="margin-bottom: 10px">
            <strong>You cannot create {{ "Tasks"|customTerm }} in this {{ "Goal"|customTerm }} as you are not a
                Member.</strong>
            <span v-if="goal.viewer_membership_status.is_goal_owners_circle_member">
                <strong v-if="!goal.viewer_membership_status.is_join_request_pending">
                    <a href="javascript:;" @click.prevent.stop="sendRequestToJoinGoal">Request to Join</a>
                </strong>
                <strong v-else>Join request was sent. You will join that {{
                        "Goal"|customTerm
                    }} after approval.</strong>
            </span>
        </div>
        <div class="text-center" v-if='goal.viewer_membership_status.only_calendar_owners_tasks_are_showed'
             style="margin-bottom: 10px">
            <strong>Only {{ "Tasks"|customTerm }} assigned to {{ calendarOwnerName }} are displayed since
                {{ "Goal"|customTerm }} admin has hidden Goal
                Tasks.</strong>
        </div>
        <goal-task-desktop
                :hide-completed-children="!showCompleted"
                :initial-task="task"
                :key="task.id"
                v-for="task in tasks"
                :layout="goalTaskLayout"
                :is-completed-mark-editable="isTaskCompletedMarkEditable"
                :allow-set-assistance-options="allowSetAssistanceOptions(task)"

                :is-editable="isTaskEditable(task)"
                :allow-drag="false"
                :allow-multiple-assign="goal.viewer_membership_status.is_admin&&!task.is_child&&goal.whole_goal_linked"
                :is-assignee-editable="isTaskAssigneeEditable(task)"
                :edit-mode="editMode"

                :allow-link-to-event="true"
                :event-params-for-tasks="eventParamsForTasks"

                :goal="goal.goal"

                :availableCompletionOptions="availableCompletionOptions"

                @completedStateUpdated="completedStateUpdated(task)"
        >
        </goal-task-desktop>
        <div class="row" v-show="flattenedCount>tasks.length">
            <div class="col-sm-12 text-center">
                <strong class="pointer" @click="showMore">Show {{ flattenedCount - tasks.length }} More</strong>
            </div>
        </div>
        <div class="text-right">
            <strong class="pointer margin-5-right"
                    v-show="!showCompleted&&completedCount"
                    @click="showCompletedTasks">Show completed
                {{ "Tasks"|customTerm }}({{ completedCount }})</strong>
        </div>
        <event-task-dates-popup ref="edit-task-dates-popup"></event-task-dates-popup>
        <event-detail-page-period-selector-popup-with-another-date-option
                ref="period-selector"></event-detail-page-period-selector-popup-with-another-date-option>
    </div>
</template>

<script>
    import {taskActionsBus, TASK_ACTION_BUS_ACTIONS} from '../../goals/taskActionsBus';
    import Vue from 'vue';
    import GoalsListItem from "../../projects/goal_list_page/GoalsListItem.vue";
    import GoalTaskDesktop from "../../goals/goalTaskComponents/GoalTask.vue";
    import GoalCreatePanel from "../../goals/GoalCreatePanel.vue";

    import EventTaskDatesPopup from './EventTaskDatesPopup.vue';
    import EventDetailPagePeriodSelectorPopupWithAnotherDateOption
        from './EventDetailPagePeriodSelectorPopupWithAnotherDateOption.vue';
    import EventDetailSubPageMixin from "./EventDetailSubPageMixin";
    import {VISUAL_DATE_FORMAT} from "../new_calendar/config";

    export default {
        name: "EventDetailPageGoalsGoal",
        mixins: [EventDetailSubPageMixin],
        components: {
            GoalsListItem,
            GoalTaskDesktop,
            GoalCreatePanel,
            EventTaskDatesPopup,
            EventDetailPagePeriodSelectorPopupWithAnotherDateOption
        },
        props: {
            goal: Object,
            goalTaskLayout: String,
            calendarOwnerId: Number,
            calendarOwnerName: String,
            eventId: String,
            availableCompletionOptions:Array,
        },
        data() {
            return {
                showCompleted: false,
                tasks: [],
                totalCount: 0,
                flattenedCount: 0,
                completedCount: 0,

                editMode: false,
            };
        },
        computed: {
            editLinkedTasksLabel() {
                return `Edit linked ${this.$customTerm('Tasks')}`;
            },
            editModeTooltip() {
                if (this.editMode) {
                    return 'View Mode';
                } else {
                    return 'Edit Mode';
                }
            },
            isEditModeAvailable() {
                if (this.tasks.length === 0) {
                    return false;  // nothing to edit then
                }
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                if (this.goal.viewer_membership_status.is_admin) {
                    return true;
                }
                for (let task of this.tasks) {
                    if (task.created_by.id === CurrentUser.id) {
                        return true;
                    }
                }
                return false;
            },
            isDeletionAvailable() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                if (this.calendarOwnerIsOwnEventsAdmin || this.buddyMode || this.goal.linked_by === CurrentUser.id) {
                    return true;
                }
                return false;
            },
            tasksIdList() {
                return this.tasks.map(t => t.id);
            },
            goalProgress() {
                if (!this.totalCount) {
                    return 0;
                }
                return Math.round((this.completedCount / this.totalCount) * 100);
            },
            eventParamsForTasks() {
                return {
                    eventId: this.periodInfo.event_id,  // for cloning
                    eventDateMoment: this.dateMoment, // for cloning
                    calendarOwnerId: this.calendarOwnerId, //for cloning

                    startDate: this.event.start_date, // for linking tasks
                    endDate: this.event.end_date, // for linking tasks
                    isPeriodic: this.periodInfo.is_periodic, // for linking tasks
                    addedToCalendar: this.membershipStatus.target_added_to_calendar,
                };
            }
        },
        methods: {
            allowSetAssistanceOptions(task) {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                const goalOwner = this.goal.goal.owner;
                const taskAssignee = task.assignee;
                /*
                so conditions stack: 1) assignee is SU 2) buddy is goal admin 3) buddy marks task completed. if all 3 are true - then show criteria menu - else just mark completed with status "signed off by X"
                 */
                if (CurrentUser.is_guardian_of(goalOwner.id)) {
                    return true;
                }
                if (taskAssignee && CurrentUser.is_guardian_of(taskAssignee.id)) {
                    return true;
                }
                return false;
            },
            editTaskDates(...rest) {
                return this.$refs['edit-task-dates-popup'].show(...rest);
            },
            isTaskEditable(task) {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                return this.goal.viewer_membership_status.is_admin || task.created_by.id === CurrentUser.id;
            },
            isTaskAssigneeEditable(task) {
                if (this.goal.viewer_membership_status.is_admin) {
                    return true;
                }
                if (this.isTaskEditable(task)) {
                    if (this.goal.hide_tasks) {
                        return false;
                    } else {
                        return true;
                    }
                }
                return false;
            },
            sendRequestToJoinGoal() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                const GoalResource = Vue.getAngularModule('GoalResource');
                const $q = Vue.getAngularModule('$q');

                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');

                simplePopupFactory.show_popup(`Join ${this.$customTerm('Goal')}`, `A you sure you want to join ${this.$customTerm('Goal')} '<strong>${this.goal.goal.name}</strong>'?`, 'Join', 'Cancel')
                    .then(y => {
                        $rootScope.show_dimmer();
                        return GoalResource.join_goal(this.goal.goal.id);
                    }, n => {
                        return $q.defer().promise;
                    })
                    .then(resp => {
                        const status = resp.data.status;
                        if (status === 'joined') {
                            notifications.success('Joined');
                            this.goal.viewer_membership_status.can_create_tasks = true;
                        }
                        if (status === 'wait_confirmation') {
                            $rootScope.hide_dimmer();
                            this.goal.viewer_membership_status.is_join_request_pending = true;
                            notifications.success('You will join Goal after approval. We will notify you.');
                        }
                    }, err => {
                        notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            showMore() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                const CalendarEventGoalsResource = Vue.getAngularModule('CalendarEventGoalsResource');
                $rootScope.show_dimmer();
                CalendarEventGoalsResource.load_tasks_from_goal(this.calendarOwnerId, this.eventId, this.event.start_date, this.goal.goal.id, this.showCompleted, 10, this.tasks.length)
                    .then(resp => {
                            this.tasks = this.tasks.concat(resp.data.results);
                            this.totalCount = resp.data.count;
                        },
                        err => {
                            notifications.error(err || 'Error');
                        })
                    .finally($rootScope.hide_dimmer);
            },
            showCompletedTasks() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                const CalendarEventGoalsResource = Vue.getAngularModule('CalendarEventGoalsResource');
                $rootScope.show_dimmer();
                CalendarEventGoalsResource.load_tasks_from_goal(this.calendarOwnerId, this.eventId, this.event.start_date, this.goal.goal.id, true, 10, 0)
                    .then(resp => {
                            this.tasks = resp.data.results;
                            this.totalCount = resp.data.count;
                        },
                        err => {
                            notifications.error(err || 'Error');
                        })
                    .finally($rootScope.hide_dimmer);
                this.showCompleted = true;
            },
            $_addTaskToLink(taskId) {
                const CalendarEventGoalsResource = Vue.getAngularModule('CalendarEventGoalsResource');
                CalendarEventGoalsResource.add_task_to_link(this.calendarOwnerId, this.periodInfo.event_id, this.goal.goal.id, taskId)
                    .then(resp => {

                    }, err => {
                        Vue.notifications.error(err || 'Error');
                    });
            },
            createTask(taskData) {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                $rootScope.show_dimmer();
                Vue.getAngularModule('GoalTaskResource').create_task(this.goal.goal.id, taskData)
                    .then(resp => {
                        const newTask = resp.data.created_task;
                        this.$_addTaskToLink(newTask.id);
                        notifications.success('Created');
                        this.tasks.unshift(newTask);
                        this.totalCount++;
                        this.flattenedCount++;
                        return new Promise((resolve, reject) => {
                            if (taskData.linkToEvent && taskData.linkToEvent.wasChanged) {
                                const CalendarEventTaskLinksResource = Vue.getAngularModule('CalendarEventTaskLinksResource');
                                CalendarEventTaskLinksResource.link_task_to_event(
                                    this.calendarOwnerId,
                                    this.periodInfo.event_id,
                                    this.event.start_date,
                                    taskData.linkToEvent.dates,
                                    taskData.linkToEvent.mode,
                                    newTask.id,
                                )
                                    .then(resp => {
                                        Vue.set(newTask, 'is_linked', resp.data);  //FIXME
                                        resolve();
                                    }, err => {
                                        notifications.error(err || 'Error');
                                        reject(err);
                                    });
                            } else {
                                resolve();
                            }
                        });
                    }, err => {
                        notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            completedStateUpdated(task) {
                if (task.completed) {
                    this.completedCount++;
                    if (!this.showCompleted) {
                        if (!task.children || task.children.filter(c => !c.completed).length === 0) { // does not has children or all children completed
                            if (this.tasks.indexOf(task) !== -1) {
                                this.tasks.splice(this.tasks.indexOf(task), 1);
                                this.flattenedCount--;
                            }
                        }
                    }
                } else {
                    this.completedCount--;
                }
            },
            editLinkedTasks() {
                this.$emit('editLinkedTasks');
            },
            toggleEditMode() {
                this.editMode = !this.editMode;
            },
            runWizard() {
                const goalTaskFactory = Vue.getAngularModule('goalTaskFactory');
                const $q = Vue.getAngularModule('$q');
                goalTaskFactory.show_create_goal_task_wizard(this.goal.goal.id, this.goal.viewer_membership_status.is_admin || !this.goal.hide_tasks, _.partial(this.editTaskDates, {
                    is_periodic: this.periodInfo.is_periodic,
                    start_date: this.event.start_date,
                    end_date: this.event.end_date,
                }))
                    .then(taskData => {
                        this.createTask(taskData);
                        // store.dispatch('create_task', taskData);
                    }, () => new Promise(()=>{}));
            },
            quickCreateTask(taskName) {
                this.createTask({name: taskName});
            },
            isTaskCompletedMarkEditable(task) {
                const currentUserId = Vue.getAngularModule('CurrentUser').id;
                return this.goal.viewer_membership_status.is_admin || (task.assignee && currentUserId === task.assignee.id) || task.created_by.id === currentUserId;
            },
            unlinkGoal() {
                this.$emit('unlinkGoal');
            },

            deleteTaskListener(task) {
                if (!this.tasksIdList.includes(task.id)) {
                    return;
                }

                const notifications = Vue.getAngularModule('notifications');

                this.tasks = this.tasks.filter(t => t.id !== task.id);
                this.totalCount--;
                if (task.completed && !(task.children && task.children.length)) {
                    this.completedCount--;
                }
                notifications.success('Deleted');
            },
            bindListeners() {
                taskActionsBus.$on(TASK_ACTION_BUS_ACTIONS.DELETE_TASK, this.deleteTaskListener);
            },
            unBindListeners() {
                taskActionsBus.$off(TASK_ACTION_BUS_ACTIONS.DELETE_TASK, this.deleteTaskListener);
            }
        },
        mounted() {
            this.tasks = this.goal.tasks;
            this.totalCount = this.goal.total_amount;
            this.flattenedCount = this.goal.flattened_amount;
            this.completedCount = this.goal.completed_amount;
            this.bindListeners();
        },
        beforeDestroy() {
            this.unBindListeners();
        },
        // watch: {
        //     'goal.tasks'(n) {
        //         this.tasks = n;
        //     }
        // }
    };
</script>

<style scoped lang="scss">
    @import "../../styles/const";
    @import "../../styles/mixins";

    .event-goals-goal {
        position: relative;
        background-color: #e2e2e2;
        padding: 10px 0;
        margin-bottom: 30px;

        &__button {
            cursor: pointer;
            color: $tag-gray-dark;
            font-size: 24px;
            min-width: 30px;
            display: inline-block;

            &.delete-button {
                color: $red;
                font-size: 25px;
            }
        }
    }
</style>