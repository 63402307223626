var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.header)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "filesContainer",
          staticClass: "image-upload-popup__files-container",
        },
        [
          _vm._l(_vm.files, function (file, index) {
            return _c(
              "div",
              {
                key: file.name,
                ref: "file" + index,
                refInFor: true,
                staticClass: "row image-upload-popup__file-row",
              },
              [
                file.loaded
                  ? _c("div", { staticClass: "col-sm-12" }, [
                      _c("div", [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-xs-6 text-left" }, [
                            _c("img", {
                              staticClass: "inline-block vertical-middle",
                              attrs: {
                                src: file.previewUrl,
                                height: "100",
                                alt: file.name,
                                onerror:
                                  "this.src='/static/images/icons/picture-placeholder.svg'",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-xs-6 text-right" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "image-upload-popup__delete-image-icon vertical-middle",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteFileFromList(file)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "far fa-trash" })]
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", { staticClass: "upload-file-name" }, [
                          _vm._v(_vm._s(file.name)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("progress-bar", {
                            attrs: {
                              type: file.uploaded ? "success" : undefined,
                              striped: "",
                              active: file.uploading,
                            },
                            model: {
                              value: file.progress,
                              callback: function ($$v) {
                                _vm.$set(file, "progress", $$v)
                              },
                              expression: "file.progress",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            )
          }),
          _vm._v(" "),
          _vm._l(
            _vm.unsupportedImageMessages,
            function (unsupportedImageMessage) {
              return _c(
                "div",
                { staticClass: "margin-5-top alert alert-info" },
                [
                  _c("strong", [_vm._v(_vm._s(unsupportedImageMessage))]),
                  _vm._v(
                    " - Not an image or the format of the image is not\n            supported\n        "
                  ),
                ]
              )
            }
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-6 text-left" },
            [
              _vm.allowSaveToMedia
                ? _c("vue-switch-with-label", {
                    attrs: {
                      "collapse-on-mobile": false,
                      name: "save_to_media",
                      label: "Save to My Media",
                    },
                    model: {
                      value: _vm.saveToMedia,
                      callback: function ($$v) {
                        _vm.saveToMedia = $$v
                      },
                      expression: "saveToMedia",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-6" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-default",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.cancel()
                  },
                },
              },
              [_vm._v("\n                    Cancel\n                ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                ref: "startUploadButton",
                staticClass: "btn btn-mm btn-green",
                attrs: { type: "button", disabled: _vm.uploadButtonDisabled },
                on: {
                  click: function ($event) {
                    return _vm.startUpload()
                  },
                },
              },
              [_vm._v("\n                    Upload\n                ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }