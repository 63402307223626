<template>
    <div class="wiki-receipt-tree-section">
        <div class="wiki-receipt-tree-section__expand-icon-container">
            <i class="far wiki-receipt-tree-section__icon"
               :class="chevronClasses"
               @click="toggleContentExpand"
            ></i>
        </div>

        <i class="far wiki-receipt-tree-section__icon"
           :class="checkButtonClasses"
           @click="toggleSectionIncluded"
        ></i>
        <div class="wiki-receipt-tree-section__avatar border-group"
             :style="sectionAvatarStyle">
            <i class="fa fa-picture-o wiki-receipt-tree-section__avatar-icon" v-if="isAvatarEmpty"></i>
        </div>
        <span>
            <a :href="section.link" target="_blank"><strong>{{ section.caption | cutName(30) }}</strong></a>
        </span>
        <div class="wiki-receipt-tree-section__children-container" v-if="subsectionsExpanded">
            <wiki-receipt-tree-section
                    :section="section"
                    :inherited-permission-level="resultPermissionLevel"
                    :permissions-list="permissionsList"
                    :included-stuff-config="includedStuffConfig"
                    @input="onIncludedStuffConfigUpdate"
                    v-for="section in section.subsections"
                    :key="section.id"
            ></wiki-receipt-tree-section>
            <wiki-receipt-tree-slide
                    :slide="slide"
                    :inherited-permission-level="resultPermissionLevel"
                    :included-stuff-config="includedStuffConfig"
                    :permissions-list="permissionsList"
                    @input="onIncludedStuffConfigUpdate"
                    v-for="slide in section.slides"
                    :key="slide.id"
            ></wiki-receipt-tree-slide>
        </div>
    </div>
</template>

<script>
    import mediaContentUtils from "../../../view_media/mediaContentUtils";
    import WikiReceiptTreeSlide from "./WikiReceiptTreeSlide";
    import TreePermissionDetectionMixinWikiSection
        from "../tree_permission_detection_mixins/TreePermissionDetectionMixinWikiSection";
    import {WIKI_PERMISSION_LEVEL, WIKI_PERMISSION_VALUE} from "../../../wiki_page/common/wikiPermissions";

    export default {
        name: "WikiReceiptTreeSection",
        components: {WikiReceiptTreeSlide},
        mixins: [TreePermissionDetectionMixinWikiSection],
        props: {
            section: Object,
            includedStuffConfig: Object,
        },
        data() {
            return {
                subsectionsExpanded: false,
            };
        },
        computed: {
            chevronClasses() {
                const classes = [];
                if (!(this.section.slides.length || this.section.subsections.length)) {
                    classes.push('color-gray');
                }
                if (this.subsectionsExpanded) {
                    classes.push('fa-caret-down');
                } else {
                    classes.push('fa-caret-right');
                }
                return classes;
            },
            currentSectionChecked() {
                return this.includedStuffConfig.sections.includes(this.section.id);
            },
            checkButtonDisabled() {
                return ![WIKI_PERMISSION_VALUE.VIEW, WIKI_PERMISSION_VALUE.EDIT].includes(this.resultPermissionLevel);
            },
            checkButtonClasses() {
                const classes = [];
                if (this.checkButtonDisabled) {
                    classes.push('fa-eye-slash');
                    if (this.hasRedefinedInChildPermission) {
                        classes.push('color-yellow');
                    } else {
                        classes.push('color-gray');
                    }
                } else {
                    classes.push('fa-check');
                    classes.push('pointer');
                    if (this.currentSectionChecked) {
                        classes.push('color-grass-green');
                    }
                }
                return classes;
            },
            sectionAvatarStyle() {
                let resultStyle = {};
                let avatarUrl = mediaContentUtils().get_img_url(this.section.avatar);
                if (avatarUrl || (this.section.media_content_samples && this.section.media_content_samples.length)) {
                    Object.assign(resultStyle, mediaContentUtils().generate_group_background(avatarUrl, this.section.media_content_samples));
                }
                return resultStyle;
            },
            isAvatarEmpty() {
                return !(this.section.avatar || this.section.media_content_samples?.length);
            },
            hasRedefinedInChildPermission() {
                if (!this.checkButtonDisabled) {
                    return false;
                }
                const getAllDescendantSectionsIds = (section) => {
                    const res = [];
                    res.push(...section.subsections.map(s => s.id));
                    for (let i in section.subsections) {
                        const sub = section.subsections[i];
                        res.push(...getAllDescendantSectionsIds(sub));
                    }
                    return res;
                };
                const getAllDescendantSlidesIds = (section) => {
                    const res = [];
                    res.push(...section.slides.map(s => s.id));
                    for (let i in section.subsections) {
                        const sub = section.subsections[i];
                        res.push(...getAllDescendantSlidesIds(sub));
                    }
                    return res;
                };
                const allDescendantSectionsId = getAllDescendantSectionsIds(this.section);
                const allDescendantSlidesId = getAllDescendantSlidesIds(this.section);
                for (let i in this.permissionsList) {
                    const p = this.permissionsList[i];
                    if (
                        p.level === WIKI_PERMISSION_LEVEL.SECTION
                        &&
                        [WIKI_PERMISSION_VALUE.VIEW, WIKI_PERMISSION_VALUE.EDIT].includes(p.value)
                        &&
                        allDescendantSectionsId.includes(p.target_id)
                    ) {
                        return true;
                    }
                }
                for (let i in this.permissionsList) {
                    const p = this.permissionsList[i];
                    if (
                        p.level === WIKI_PERMISSION_LEVEL.SLIDE
                        &&
                        [WIKI_PERMISSION_VALUE.VIEW, WIKI_PERMISSION_VALUE.EDIT].includes(p.value)
                        &&
                        allDescendantSlidesId.includes(p.target_id)
                    ) {
                        return true;
                    }
                }
                return false;
            },
        },
        methods: {
            toggleContentExpand() {
                if (!(this.section.subsections.length || this.section.slides.length)) {
                    return;
                }
                this.subsectionsExpanded = !this.subsectionsExpanded;
            },
            toggleSectionIncluded() {
                if (this.checkButtonDisabled) {
                    return;
                }
                if (this.currentSectionChecked) {
                    this.includedStuffConfig.sections = this.includedStuffConfig.sections.filter(sId => sId !== this.section.id);
                } else {
                    this.includedStuffConfig.sections.push(this.section.id);
                }
                this.onIncludedStuffConfigUpdate(this.includedStuffConfig);
            },
            onIncludedStuffConfigUpdate(includedStuffConfig) {
                this.$emit('input', includedStuffConfig);
            },
        }
    };
</script>

<style scoped lang="scss">
  .wiki-receipt-tree-section {
    margin-top: 6px;
    margin-bottom: 6px;

    $circle-size: 50px;

    &__avatar {
      display: inline-block;
      vertical-align: middle;
      background-size: cover;
      width: $circle-size;
      height: $circle-size;
      border-radius: 50%;
      text-align: center;
    }

    &__icon {
      vertical-align: middle;
      font-size: $circle-size / 2;
      margin: 0 4px;
      transition: color 0.2s ease;
    }

    &__avatar-icon {
      vertical-align: middle;
      font-size: $circle-size / 1.5;
      margin: 0 4px;
      cursor: pointer;
      line-height: $circle-size / 1.1;

    }

    &__expand-icon-container {
      vertical-align: middle;
      display: inline-block;
      min-width: 30px;
    }

    &__children-container {
      padding-left: 36px;
    }
  }
</style>