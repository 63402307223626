<template>
    <div class="event-users-calendar">
        <div class="event-users-calendar__image-part">
            <router-link :to="link+'?fl'">
                <img :src="user.avatar" class="img-circle event-users-calendar__image-part__image"/></router-link>
        </div>
        <div class="event-users-calendar__content-part">
            <router-link :to="link">
                <img src="/static/images/new_daschboard_icons/calendar_600_1.png"
                     class="event-users-calendar__content-part__icon" alt=""></router-link>
            <router-link :to="link">
                {{ user.name }}'s Calendar
            </router-link>
        </div>
    </div>
</template>

<script>
    import {DjangoUrls} from 'DjangoUrls';

    export default {
        props: {
            user: {
                type: Object
            }
        },
        computed: {
            link() {
                return DjangoUrls['users_calendar'](this.user.id) + '?fl';
            }
        },
        name: "event-users-calendar"
    };
</script>

<style scoped lang="scss">
    @import "../styles/mixins.scss";

    .event-users-calendar {
        @include managedUserInList();
    }

</style>