var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "stories-list" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-5 text-left" },
          [
            _vm._t("header", function () {
              return [
                _c("strong", { staticStyle: { "font-size": "28px" } }, [
                  _vm._v(_vm._s(_vm.header)),
                ]),
              ]
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-7 text-right" },
          [
            _vm.allowCreate
              ? _c("vue-folder-button", {
                  attrs: {
                    text: "Create Story",
                    color: "background-color-story",
                    "custom-icon": "new-story",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.createStory.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("vue-search-input", {
              class: _vm.searchFieldClasses,
              attrs: {
                "min-letters-count": 2,
                "initial-query": _vm.initialQuery,
              },
              on: { search: _vm.startSearch, cancel: _vm.cancelSearch },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.allowCreate
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "infinite-scroll",
              rawName: "v-infinite-scroll",
              value: _vm.loadMore,
              expression: "loadMore",
            },
          ],
          staticClass: "stories-list__stories-container",
        },
        [
          _vm._l(_vm.stories, function (story) {
            return _c("rectangle-stuff-preview", {
              key: story.content_type + story.id,
              attrs: {
                "audio-caption": story.audio_caption,
                to: _vm.wrapLink(story.link),
                "color-class": story.content_type,
                name: story.name,
                avatar: story.avatar,
                size: _vm.swamItemSize,
              },
            })
          }),
          _vm._v(" "),
          _vm._l(_vm.swamGetFakeItems(_vm.stories.length), function (fakeItem) {
            return _c("div", { key: fakeItem.id, style: _vm.swamFakeIconStyle })
          }),
        ],
        2
      ),
      _vm._v(" "),
      !_vm.stories.length && !_vm.showSpinner && _vm.lastQuery
        ? _vm._t("emptyMessageSearch", function () {
            return [_vm._m(0)]
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.stories.length && !_vm.showSpinner && !_vm.lastQuery
        ? _vm._t("emptyMessage", function () {
            return [_vm._m(1)]
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "50px", "margin-top": "40px" } }, [
        _vm.showSpinner
          ? _c("div", { staticClass: "text-center" }, [
              _c("span", [_vm._v("Loading...")]),
            ])
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12 text-center" }, [
        _c("span", { staticStyle: { "font-size": "20px" } }, [
          _vm._v("No Results..."),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12 text-center" }, [
        _c("span", { staticStyle: { "font-size": "20px" } }, [
          _vm._v("No Posts..."),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }