import urls from 'DjangoUrls';

let {DjangoUrls} = urls;
GroupActivityPostResource.$inject = ['$http'];
const PREFIX = `api.v1:group_activity_post-`;

function GroupActivityPostResource($http) {
    return {
        get_post,
        get_post_locations,
        create_post,
        remove_post,
    };

    function get_post(post_id) {
        const url = DjangoUrls[`${PREFIX}get-post`](post_id);
        return $http({
            method: 'GET',
            url,
        });
    }

    function get_post_locations(post_id) {
        const url = DjangoUrls[`${PREFIX}get-post-locations`](post_id);
        return $http({
            method: 'GET',
            url,
        });
    }

    function create_post(group_id, data) {
        const url = DjangoUrls[`${PREFIX}create-post`]();
        return $http({
            method: 'POST',
            url,
            data: Object.assign({id: group_id}, data)
        });
    }

    function remove_post(group_id, id) {
        const url = DjangoUrls[`${PREFIX}remove-post`](id);
        return $http({
            method: 'DELETE',
            url
        });
    }
}

export default GroupActivityPostResource;

