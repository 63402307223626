var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "wlip-image-container" }, [
    _c("img", {
      staticClass: "img-thumbnail",
      attrs: {
        src: _vm.wikiLog.image_preview,
        alt: "Slide Preview",
        width: "150",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }