let Clipboard = require('clipboard');
copyCommunityUrlButton.$inject = ['notifications', '$templateCache'];

function copyCommunityUrlButton(notifications, $templateCache) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            url: '@'
        },
        template: `
                <div class="inline-block" data-clipboard-text="{$ url $}" id="copyCommunityUrlButton" >
                    <folder-button
                    ng-show="copy_supported"
                    icon-class="fa-link"
                    color="blue"
                    text="Copy public URL" 
                    top="1px"
                    help-text="Copy the URL to the public About Page of this Community so that you can share the link and people can request to sign up and join the Community"
                    help-text-info-icon="true"
                    ></folder-button>
                </div>
`,
        link: function ($scope, element, attrs, controller) {
            // $scope.copyCommunityUrlButtonHTML = `<span><i class='fa fa-info-circle'></i> Copy the URL to the public About Page of this Community so that you can share the link and people can request to sign up and join the Community</span>`;
            $scope.copy_supported = Clipboard.isSupported();
            if ($scope.copy_supported) {
                let clipboard = new Clipboard(element[0]);
                clipboard.on('success', e => {
                    $scope.$apply(() => {
                        notifications.success('URL Copied to Clipboard');
                    });
                });

                clipboard.on('error', e => $scope.$apply(() => {
                    notifications.error('You browser doesn\'t support auto copy. Please copy link manually');

                }));
            }

        }
    };
}

export default copyCommunityUrlButton;