var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fgi" },
    [
      _vm.editable
        ? _c("i", {
            staticClass: "far fa-trash fgi__delete-button",
            attrs: {
              tabindex: "0",
              role: "button",
              "aria-label": "delete item",
            },
            on: {
              click: _vm.removeItem,
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.removeItem.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.post.media_attachment
        ? _c("vue-inline-media", {
            staticStyle: { "min-height": "300px", "aspect-ratio": "16/9" },
            attrs: { item: _vm.post.media_attachment },
          })
        : _c("div", { staticClass: "fgi__text" }, [
            _vm._v("\n        " + _vm._s(_vm.post.text) + "\n    "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }