/**
 * Created by orion on 16.03.17.
 */
import './style.scss';
import UserProfileResource from "../userprofile_resource";
import UserProfileSettingsResource from "../userprofile_settings_resource";

deleteAccountNotification.$inject = ['$log', '$rootScope', '$sce', 'simplePopupFactory', '$q', 'notifications', 'UserProfileSettingsResource', 'CurrentUser'];

function deleteAccountNotification($log, $rootScope, $sce, simplePopupFactory, $q, notifications, UserProfileSettingsResource, CurrentUser) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            // notificationText: '@',
        },
        template: `<div ng-show="show_deletion_alert" class="container-fluid" id="delete_account_notification" ng-cloak>
                        This account is scheduled to delete. 
                        <span ng-show="show_cancel_panel">
                        You can delete it immediately by clicking the link sent to your email. (<a ng-click="resend_email()" href="javascript:;">Click here</a> to send new confirmation email - (this will invalidate previous email) to delete your account or <a ng-click="cancel_deletion_request()" href="javascript:;">cancel deletion</a>.
                        </span>
                   </div>`,
        link: function ($scope, element, attrs, controller) {
            $scope.cancelled = false;
            $scope.show_deletion_alert = CurrentUser.pending_to_delete;

            $scope.$on('accountDeletionCancelled', v => {
                $scope.show_deletion_alert = false;
                CurrentUser.pending_to_delete = false;
            });

            $scope.resend_email = function () {
                UserProfileSettingsResource.resend_close_account_confirmation_email(CurrentUser.id)
                    .then(resp => {
                        notifications.success('You will receive an email with a confirmation link shortly.', 5000);
                    }, err => {
                        notifications.error(err || 'Error');
                    });
            };
            $scope.show_cancel_panel = !CurrentUser.is_circle_user();

            $scope.cancel_deletion_request = function () {
                simplePopupFactory.show_popup('Cancel account deletion', 'Are you sure you want to cancel deletion this account?', 'Cancel Deletion', 'No')
                    .then(y => {
                        return UserProfileSettingsResource.cancel_account_deletion(CurrentUser.id);
                    }, () => new Promise(()=>{}))
                    .then(resp => {
                        $rootScope.$broadcast('accountDeletionCancelled', true);
                        notifications.success(`Account deletion has been canceled`);
                    }, err => notifications.error(err || 'Error'))

                    .finally($rootScope.hide_dimmer);
            };
        }


    };
}

export default deleteAccountNotification;
