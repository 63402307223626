/**
 * Created by orion on 08.03.17.
 */
import angular from 'angular';
import 'angular-ui-bootstrap'

import viewMediaFactory from './view_media_facory'


//directives
import folderItem from './folderItem_directive/folderItem'
import FolderItemResources from './folderItem_directive/folderItem_resources'
import mediaItemInline from './vue_inline_media_render_directive/inline_render_media'
import mediaPreview from './media_preview_directive/media_preview'

//main modules
import notifications from './../shared/notifications/notifications_module'
import simplePopups from '../shared/simple_popups/simple_popup_module';
//3dparty

//utils
import mediaContentUtils from './mediaContentUtils'


angular.module('mmApp.view_media', ['ui.bootstrap', notifications, simplePopups, mediaItemInline])
    .factory('viewMediaFactory', viewMediaFactory)
    .directive("folderItem", folderItem)
    .directive("mediaPreview", mediaPreview)
    .factory('FolderItemResources', FolderItemResources)
    .factory('mediaContentUtils', mediaContentUtils);


export default 'mmApp.view_media'