var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "community-page-alternative-header",
        { attrs: { title: "Our Stories", "left-part-size": 10 } },
        [
          _vm.viewerIsAdmin
            ? _c("vue-folder-button", {
                attrs: { "icon-class": "fa-plus" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.createNewStory()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.allLoaded && !_vm.stories.length
        ? _c("div", { staticClass: "alert alert-info" }, [
            _c("span", [
              _vm._v("This Community has not published any stories yet"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {},
        _vm._l(_vm.stories, function (story) {
          return _c(
            "router-link",
            {
              key: story.id,
              staticStyle: { "text-decoration": "none" },
              attrs: {
                to: {
                  name: "community-stories-story",
                  params: { storySlug: story.slug },
                },
              },
            },
            [_c("story-preview", { attrs: { story: story } })],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("create-story-popup", { ref: "createStoryPopup" }),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "infinite-scroll",
            rawName: "v-infinite-scroll",
            value: _vm.loadMore,
            expression: "loadMore",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }