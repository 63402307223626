var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "selector-popup",
    {
      ref: "popup",
      attrs: { header: "How you would like to add video?", items: _vm.items },
    },
    [
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-primary",
            attrs: { type: "button" },
            on: { click: _vm.back },
          },
          [_vm._v("Back")]
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "invisibleForms" }, [
        _c("form", { attrs: { enctype: "multipart/form-data" } }, [
          _c("input", {
            ref: "recordVideoHiddenButton",
            staticStyle: { display: "none" },
            attrs: {
              type: "file",
              name: "video",
              accept: "video/*",
              capture: "user",
              id: "record_video",
            },
            on: { change: _vm.onFileFieldChanged },
          }),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }