<template>
    <div class="c-day">
        <div class="c-day__day-info-part" :style="infoPartStyle">
            <div class="c-day__day-info-part__today-mark" v-if="isToday">
                Today
            </div>
            <div class="c-day__day-info-part__weekday-icon" :style="weekdayIconStyle">

            </div>
            <div class="c-day__day-info-part__weekday-label" v-show="isDesktopVersion">
                {{ weekDayLabel }}
            </div>
            <div class="c-day__day-info-part__day-number-label" v-show="isDesktopVersion">
                {{ dayNumberLabel }} {{ monthLabel }}
            </div>
            <div class="c-day__day-info-part__mobile-date-label" v-show="!isDesktopVersion">
                <!--{{ mobileDateLabel }}-->
                {{ weekDayLabel }}, <br> {{ dayNumberLabel }} {{ monthLabel }}
            </div>
            <!--<div class="c-day__day-info-part__month-label" v-show="isDesktopVersion">-->
            <!---->
            <!--</div>-->

        </div>
        <div class="c-day__events-part" :style="eventsPartStyle">
            <div class="">
                <calendar-page-day-holidays
                        v-for="holidaysItem in day.holidays"
                        :key="holidaysItem.id"
                        :holidays="holidaysItem"
                        @holidayChanged="onHolidayChanged"
                ></calendar-page-day-holidays>
                <calendar-page-day-timeline-whole-day-event
                        :event="event"
                        :profile="profile"
                        v-for="(event,i) in wholeDayEvents"
                        :index="i"
                        :key="event.id"
                        :date="day.date"
                ></calendar-page-day-timeline-whole-day-event>
            </div>
            <calendar-page-day-timeline :events-tiers="eventsTiers"
                                        :width="eventsPartWidth"
                                        :height="timeLineHeight"
                                        :profile="profile"
                                        :day="day"
            ></calendar-page-day-timeline>
        </div>
    </div>
</template>

<script>
    import CalendarPageDayTimeline from './CalendarPageDayTimeline.vue';
    import CalendarPageDayTimelineWholeDayEvent from './CalendarPageDayTimelineWholeDayEvent.vue';
    import CalendarPageDayHolidays from './CalendarPageDayHolidays.vue';
    import ScreenWidthAwareMixin from "../../../../vue/mixins/ScreenWidthAwareMixin";
    import _ from 'lodash';
    import moment from 'moment';
    import * as config from './config';

    const WEEKDAY_ICONS = [
        'icons8-sunday-96.png',
        'icons8-monday-96.png',
        'icons8-tuesday-96.png',
        'icons8-wednesday-96.png',
        'icons8-thursday-96.png',
        'icons8-friday-96.png',
        'icons8-saturday-96.png',

    ];

    export default {
        components: {CalendarPageDayTimeline, CalendarPageDayTimelineWholeDayEvent, CalendarPageDayHolidays},
        mixins: [ScreenWidthAwareMixin],
        data() {
            return {
                dayContainerWidth: undefined
            };
        },
        props: {
            day: Object,
            profile: Object,
        },
        computed: {
            isToday() {
                return this.day.date === moment().format(config.VISUAL_DATE_FORMAT);
            },
            timeLineHeight() {
                let height = this.eventsTiers.length * config.SIZES.TIER_HEIGHT + config.SIZES.TIMELINE_HEADER_HEIGHT;
                if (this.isDesktopVersion && this.isToday && !this.wholeDayEvents.length) {
                    height += 30;
                }
                height += config.SIZES.TOP_OFFSET_FOR_LINES;
                return height;
            },
            currentDateMoment() {
                return moment(this.day.date, config.VISUAL_DATE_FORMAT);
            },
            weekDayLabel() {
                return this.currentDateMoment.format('dddd');
            },
            weekdayIconStyle() {
                const style = {};
                style['background-image'] = `url('/static/images/weekdays/${WEEKDAY_ICONS[this.currentDateMoment.format('d')]}')`;
                if (this.isDesktopVersion) {
                    style['margin'] = 'auto';
                } else {
                    style['position'] = 'absolute';
                    style['left'] = '0';
                    style['top'] = '0';
                }
                return style;
            },
            dayNumberLabel() {
                return this.currentDateMoment.format('D');
            },
            monthLabel() {
                return this.currentDateMoment.format('MMM');
            },
            mobileDateLabel() {
                return `${this.weekDayLabel}, ${this.dayNumberLabel} ${this.monthLabel}`;
            },
            wholeDayEvents() {
                return _.orderBy(this.day.events.filter(e => e.event_data.whole_day), e => e.id);
            },
            eventsTiers() {
                const tiers = [[]];

                const getOrCreateLayerForEvent = (eventToInsert) => {
                    for (let i in tiers) {
                        let tier = tiers[i];
                        let isFree = true;
                        for (let j in tier) {
                            let tierEvent = tier[j];
                            if (
                                eventToInsert.event_data.start_time_in_minutes < tierEvent.event_data.end_time_in_minutes &&
                                eventToInsert.event_data.end_time_in_minutes > tierEvent.event_data.start_time_in_minutes
                            ) {
                                isFree = false;
                                break;
                            }
                        }
                        if (isFree) {
                            return tiers.indexOf(tier);
                        }
                    }
                    tiers.push([]);
                    return tiers.length - 1;
                };

                const eventsList = _.orderBy(this.day.events.filter(e => !e.event_data.whole_day), e => e.event_data.start_time_in_minutes);

                for (let i in eventsList) {
                    const event = eventsList[i];
                    tiers[getOrCreateLayerForEvent(event)].push(event);
                }
                return tiers;
            },
            isDesktopVersion() {
                return this.windowWidth >= 600;
            },
            infoPartWidth() {
                if (this.isDesktopVersion) {
                    return 100;
                } else {
                    return this.dayContainerWidth;
                }
            },
            infoPartStyle() {
                return {
                    width: `${this.infoPartWidth}px`
                };
            },
            eventsPartWidth() {
                if (this.isDesktopVersion) {
                    return this.dayContainerWidth - 110;
                } else {
                    return this.dayContainerWidth;
                }
            },
            eventsPartStyle() {
                return {
                    width: `${this.eventsPartWidth}px`
                };
            }
        },
        methods: {
            onHolidayChanged() {
                this.$emit('onHolidayChanged');
            },
            onWindowResize() {
                this.dayContainerWidth = this.$el.offsetWidth;
            }
        },
        mounted() {
            this.onWindowResize();
            window.addEventListener('resize', this.onWindowResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onWindowResize);
        },
        name: "CalendarPageDay"
    };
</script>

<style scoped lang="scss">
    @import "../../../app/styles/const";
    @import "../../../app/styles/mixins";

    .c-day {
        position: relative;
        @include wide_item_container(unset);

        &__timeline-container {

        }

        &__day-info-part {
            vertical-align: top;
            @media (min-width: $help-panel-desktop-min) {
                display: inline-block;
            }
            @media (max-width: $help-panel-mobile-max) {
                display: block;
            }
            text-align: center;

            &__today-mark {
                width: 80px;
                padding: 5px;
                background-color: $red;
                margin: auto;
                margin-top: 5px;
                color: white;
                font-weight: bolder;
            }

            &__weekday-label {
                text-align: center;
                font-weight: bold;
                color: $tag-gray;
            }

            &__weekday-icon {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                height: 50px;
                width: 50px;
            }

            &__day-number-label {
                text-align: center;
                font-weight: bold;
                color: $tag-gray;
            }

            &__mobile-date-label {
                text-align: center;
                font-weight: bold;
                color: $tag-gray;
                margin: 5px;
            }

            &__month-label {
                text-align: center;
                /*font-weight: bold;*/
                color: $tag-gray;
            }
        }

        &__events-part {
            @media (min-width: $help-panel-desktop-min) {
                display: inline-block;
            }
            @media (max-width: $help-panel-mobile-max) {
                display: block;
            }
            vertical-align: bottom;
            /*height: 100px;*/
        }
    }
</style>