import {massActionsBus, MASS_ACTION_BUS_ACTIONS} from './massActionsBus';
import Vue from 'vue';

const vm = {
    data() {
        return {
            massActionsState: {
                deleteMode: false,
                moveMode: false,
                selectedToDelete: [],
                selectedToMove: [],
            }
        };
    },
    methods: {
        enableDeleteMode() {
            this.massActionsState.deleteMode = true;
        },
        disableDeleteMode() {
            this.massActionsState.selectedToDelete = [];
            this.massActionsState.deleteMode = false;
        },
        enableMoveMode() {
            this.massActionsState.moveMode = true;
        },
        disableMoveMode() {
            this.massActionsState.selectedToMove = [];
            this.massActionsState.moveMode = false;
        },
        onMediaClick(mediaItem) {
            if (!this.massActionsState.deleteMode && !this.massActionsState.moveMode) {
                this.viewMediaItem(mediaItem);
            } else {
                if (this.massActionsState.deleteMode) {
                    if (this.massActionsState.selectedToDelete.indexOf(mediaItem.pk) !== -1) {
                        this.massActionsState.selectedToDelete.splice(this.massActionsState.selectedToDelete.indexOf(mediaItem.pk), 1);
                    } else {
                        this.massActionsState.selectedToDelete.push(mediaItem.pk);
                    }
                }
                if (this.massActionsState.moveMode) {
                    if (this.massActionsState.selectedToMove.indexOf(mediaItem.pk) !== -1) {
                        this.massActionsState.selectedToMove.splice(this.massActionsState.selectedToMove.indexOf(mediaItem.pk), 1);
                    } else {
                        this.massActionsState.selectedToMove.push(mediaItem.pk);
                    }
                }
            }
        },
        editName(mediaItem) {
            let simplePopupFactory = this.angularModules['simplePopupFactory'];
            let MediaFoldersResource = this.angularModules['MediaFoldersResource'];
            let notifications = this.angularModules['notifications'];
            let $q = this.angularModules['$q'];
            let $rootScope = this.angularModules['$rootScope'];
            simplePopupFactory.simple_text_input_popup('Rename Media', 'Input new name for this Media', 'Rename', 'Cancel', mediaItem.name, 127)
                .then(newName => {
                    $rootScope.show_dimmer();
                    return MediaFoldersResource.rename_media(this.currentUser.id, mediaItem.pk, newName);
                }, () => new Promise(()=>{}))
                .then(resp => {
                    mediaItem.name = resp.data.name;
                    notifications.success('Renamed');
                }, err => {
                    notifications.error(err || 'Error');
                })
                .finally($rootScope.hide_dimmer);
        },
        deleteSelected() {
            let MediaFoldersResource = this.angularModules['MediaFoldersResource'];
            let $rootScope = this.angularModules['$rootScope'];
            let notifications = this.angularModules['notifications'];
            let $q = this.angularModules['$q'];
            let simplePopupFactory = this.angularModules['simplePopupFactory'];
            simplePopupFactory.show_popup('Delete', 'Are you sure you want to delete these items?', 'Delete', 'No', 'btn-red')
                .then(y => {
                    $rootScope.show_dimmer();
                    return MediaFoldersResource.remove_media(this.currentUser.id, this.massActionsState.selectedToDelete);
                }, () => new Promise(()=>{}))
                .then(resp => {
                    this.media = this.media.filter(m => this.massActionsState.selectedToDelete.indexOf(m.pk) === -1);
                    notifications.success('Deleted');
                    this.disableDeleteMode();
                }, err => {
                    Vue.notifications.error(err || 'Error');
                })
                .finally($rootScope.hide_dimmer);
        },
        moveSelected(targetFolder) {
            let MediaFoldersResource = this.angularModules['MediaFoldersResource'];
            let $rootScope = this.angularModules['$rootScope'];
            $rootScope.show_dimmer();

            MediaFoldersResource.move_media_to_folder(this.currentUser.id, this.massActionsState.selectedToMove, targetFolder.id)
                .then(resp => {
                    this.media = this.media.filter(m => this.massActionsState.selectedToMove.indexOf(m.pk) === -1);
                    Vue.notifications.success('Moved');
                    this.disableMoveMode();
                    massActionsBus.$emit(MASS_ACTION_BUS_ACTIONS.MOVE_COMPLETED);

                }, err => {
                    Vue.notifications.error(err || 'Error');
                })
                .finally($rootScope.hide_dimmer);
        }
    },
    mounted() {
        massActionsBus.$on(MASS_ACTION_BUS_ACTIONS.ENABLE_DELETE_MODE, () => {
            this.enableDeleteMode();
        });
        massActionsBus.$on(MASS_ACTION_BUS_ACTIONS.DISABLE_DELETE_MODE, () => {
            this.disableDeleteMode();
        });
        massActionsBus.$on(MASS_ACTION_BUS_ACTIONS.ENABLE_MOVE_MODE, () => {
            this.enableMoveMode();
        });
        massActionsBus.$on(MASS_ACTION_BUS_ACTIONS.DISABLE_MOVE_MODE, () => {
            this.disableMoveMode();
        });
        massActionsBus.$on(MASS_ACTION_BUS_ACTIONS.DELETE_SELECTED, () => {
            this.deleteSelected();
        });
        massActionsBus.$on(MASS_ACTION_BUS_ACTIONS.MOVE_SELECTED, (targetFolder) => {
            this.moveSelected(targetFolder);
        });
    },
    beforeDestroy() {
        massActionsBus.$off(MASS_ACTION_BUS_ACTIONS.ENABLE_DELETE_MODE);
        massActionsBus.$off(MASS_ACTION_BUS_ACTIONS.DISABLE_DELETE_MODE);
        massActionsBus.$off(MASS_ACTION_BUS_ACTIONS.ENABLE_MOVE_MODE);
        massActionsBus.$off(MASS_ACTION_BUS_ACTIONS.DISABLE_MOVE_MODE);
        massActionsBus.$off(MASS_ACTION_BUS_ACTIONS.DELETE_SELECTED);
    }
};

export default vm;