StoryStoryController.$inject = ['$preloaded', '$log', 'StoryStoryResource', 'StoryActivityPostResource', '$rootScope', 'notifications'];

function StoryStoryController($preloaded, $log, StoryStoryResource, StoryActivityPostResource, $rootScope, notifications) {
    let self = this;

    function init() {
        self.story_id = $preloaded.story.id;
        // console.debug('self.story_id',self.story_id);
        // self.community_name = $preloaded.community_name;
        self.is_admin = $preloaded.is_admin;
        self.is_activity_post_story = $preloaded.is_activity_post_story;
        self.activity_post = $preloaded.activity_post;
        self.StoryResource = self.is_activity_post_story ? StoryActivityPostResource : StoryStoryResource;
    }

    init();


    self.update_post = function (data) {
        $rootScope.show_dimmer();
        StoryActivityPostResource.update_post(self.story_id, data)
            .then(resp => {
                notifications.success('Updated');
            }, err => {
                notifications.error(err || 'Error');
            })
            .finally($rootScope.hide_dimmer);
    };
}

export default StoryStoryController;