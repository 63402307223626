import {PROVIDED_ASSISTANCE_OPTIONS_VALUES} from "../../../../config";

const MAX_VISIBLE_DESCRIPTION_LENGTH = 400;
import {taskActionsBus, TASK_ACTION_BUS_ACTIONS} from '../../../../../goals/taskActionsBus';
import config from '../../../../../goals/config';
import Vue from 'vue';
import _ from 'lodash';
import {VISUAL_DATE_FORMAT} from "../../../../../events/new_calendar/config";
import {customTermFilter} from "../../../../../../../vue/filters/CustomTerm";
import {getTaskBackgroundColor, getTaskStatusCode} from "../../../common";
import {simplePopupFactory} from "shared/simple_popups/simplePopupFactory";
import EventTaskDatesPopup from "../../../../../events/new_event/EventTaskDatesPopup";

export default {
    props: {
        isCompletedMarkEditable: {
            type: Function,
            default: false,
        },
        isAdmin: {
            type: Boolean,
            default: false,
        },
        editMode: {
            type: Boolean,
            default: false,
        },
        isEditable: {
            type: Boolean,
            default: false,
        },
        isAssigneeEditable: {
            type: Boolean,
            default: false,
        },
        allowMultipleAssign: {
            type: Boolean,
            default: false,
        },
        allowDrag: {
            type: Boolean,
            default: false,
        },
        eventParamsForTasks: {
            type: Object,
            required: false,
        },
        layout: {
            type: String
        },
        deleteForbidden: {
            type: Boolean,
            default: false,
        },

        allowLinkToEvent: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            descriptionExpanded: false
        };
    },
    computed: {
        statusLabel() {
            return config.TASK_TEXT_STATUS[this.verbalStatus];
        },
        verbalStatus() {
            return getTaskStatusCode(this.task);
        },
        statusLabelVisible() {
            return this.statusLabel !== undefined;
        },
        statusColor() {
            if (this.statusLabelVisible) {
                return {
                    'color': config.TASK_STATUS_LABEL_COLOR_TABLE[this.verbalStatus],
                    'border-color': config.TASK_STATUS_LABEL_COLOR_TABLE[this.verbalStatus],
                };
            }
        },
        isDescriptionLargerThenPossible() {
            return !this.descriptionExpanded && this.task.description && (this.task.description.length > MAX_VISIBLE_DESCRIPTION_LENGTH);
        },
        taskDescription() {
            if (this.isDescriptionLargerThenPossible) {
                return this.$options.filters.cutName(this.task.description, MAX_VISIBLE_DESCRIPTION_LENGTH);
            }
            return this.task.description;
        },
        completedMarkHintText() {
            if (this.task.status === 'completed') {
                return 'Sign off as incomplete';
            } else {
                return 'Sign off as complete';
            }
        },
        taskAvatarStyle() {
            return {
                'background-image': `url('${this.task.avatar}')`,
            };
        },

        taskBackgroundColor() {
            return {'background-color': getTaskBackgroundColor(this.task)};
        },

        checkBoxStyle() {
            const style = {};
            if (this.task.status !== 'completed') {
                style['color'] = '#9c9c9c';
            } else {
                style['color'] = '#51ac51';
            }
            return style;
        },
        buttonsPanelStyle() {
            let style = {};
            let amountOfEnabledButtons = [this.allowDelete, this.allowDrag, this.allowMultipleAssign].filter(i => i === true).length;
            style['width'] = `${36 * amountOfEnabledButtons + 3}px`;
            return style;
        },
        allowDelete() {
            if (this.deleteForbidden) {
                return false;
            }
            return this.isEditable;
        },
        UPDATE_CHILDREN_NEEDED() {
            return this.task.children && this.task.children.length && this.syncedChildrenIdList && this.syncedChildrenIdList.length;
        }
    },
    methods: {
        markTaskAsCompleted(task, withAssistanceParams = false, providedAssistance = []) {
            const GoalTaskResource = Vue.getAngularModule('GoalTaskResource');
            const $rootScope = Vue.getAngularModule('$rootScope');
            $rootScope.show_dimmer();
            GoalTaskResource.mark_task_as_completed(task.id, withAssistanceParams, withAssistanceParams ? providedAssistance : undefined)
                .then(resp => {
                    this.task.status = resp.data.task.status;
                    this.task.completed = resp.data.task.completed;
                    this.task.provided_assistance = resp.data.task.provided_assistance;
                    Vue.notifications.success('Updated');
                    this.$emit('completedStateUpdated', task);
                }, err => {
                    Vue.notifications.error(err || 'Error');
                })
                .finally(() => $rootScope.hide_dimmer());
        },
        updateCompletionOptions(task, withSupport, providedAssistance = []) {
            const GoalTaskResource = Vue.getAngularModule('GoalTaskResource');
            const $rootScope = Vue.getAngularModule('$rootScope');
            $rootScope.show_dimmer();
            GoalTaskResource.update_task_completion_params(task.id, withSupport, withSupport ? providedAssistance : undefined)
                .then(resp => {
                    this.task.status = resp.data.task.status;
                    this.task.completed = resp.data.task.completed;
                    this.task.provided_assistance = resp.data.task.provided_assistance;
                    Vue.notifications.success('Updated');
                }, err => {
                    Vue.notifications.error(err || 'Error');
                })
                .finally(() => $rootScope.hide_dimmer());
        },
        markTaskAsIncompleted(task) {
            const GoalTaskResource = Vue.getAngularModule('GoalTaskResource');
            const $rootScope = Vue.getAngularModule('$rootScope');
            $rootScope.show_dimmer();
            GoalTaskResource.mark_task_as_incompleted(task.id)
                .then(resp => {
                    this.task.status = resp.data.task.status;
                    this.task.completed = resp.data.task.completed;
                    this.task.provided_assistance = [];
                    Vue.notifications.success('Updated');
                    this.$emit('completedStateUpdated', task);
                }, err => {
                    Vue.notifications.error(err || 'Error');
                })
                .finally(() => $rootScope.hide_dimmer());
        },
        showCompletionOptions() {
            this.$refs['complete-dropdown'].show(false, this.task.provided_assistance, this.availableCompletionOptions)
                .then(({result, withSupport, options}) => {
                    this.markTaskAsCompleted(this.task, withSupport, options);
                });

        },
        showIncompletionOptions() {
            this.$refs['complete-dropdown'].show(true, this.task.provided_assistance, this.availableCompletionOptions)
                .then(({result, withSupport, options}) => {
                    if (result === 'complete') {
                        this.updateCompletionOptions(this.task, withSupport, options);
                    } else {
                        this.markTaskAsIncompleted(this.task);
                    }
                });
        },
        onCompleteCheckboxClicked() {
            if (this.allowSetAssistanceOptions(this.task)) {
                if (this.task.completed) {
                    this.showIncompletionOptions();
                } else {
                    this.showCompletionOptions();
                }

            } else {
                this.toggleCompletedStatus();
            }
        },

        onTaskCompletedByStep(taskData) {
            this.task.status = taskData.task.status;
            if (this.task.completed === false) {
                this.task.completed = true;
                this.$emit('completedStateUpdated', this.task);
            }

        },
        onTaskInCompletedByStep(taskData) {
            this.task.status = taskData.task.status;
            if (this.task.completed === true) {
                this.task.completed = false;
                this.$emit('completedStateUpdated', this.task);
            }
        },
        showWholeDescription() {
            this.descriptionExpanded = true;
        },
        toggleCompletedStatus() {
            if (this.task.completed) {
                this.markTaskAsIncompleted(this.task);
            } else {
                this.markTaskAsCompleted(this.task);
            }
        },
        deleteTask() {
            const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
            const GoalTaskResource = Vue.getAngularModule('GoalTaskResource');
            const $rootScope = Vue.getAngularModule('$rootScope');
            const notifications = Vue.getAngularModule('notifications');
            const $q = Vue.getAngularModule('$q');
            let message = `A you sure you want to delete ${customTermFilter('Task')} <strong>'${this.task.name}'</strong>?`;
            if (this.task.children && this.task.children.length) {
                message = `A you sure you want to delete ${customTermFilter('Task')} <strong>'${this.task.name}'</strong>' and <strong>all</strong> its duplications?`;
            }
            simplePopupFactory.show_popup(`Delete ${customTermFilter('Task')}`,
                message,
                'Delete',
                'Cancel',
                'btn-red')
                .then(() => {
                    $rootScope.show_dimmer();
                    return GoalTaskResource.delete_task(this.task.id);
                }, () => new Promise(() => {
                }))
                .then(resp => {
                    if (!this.parentTask) {
                        taskActionsBus.$emit(TASK_ACTION_BUS_ACTIONS.DELETE_TASK, this.task);
                    } else {
                        this.$emit('childDeleted', this.task);
                    }
                }, err => notifications.error(err || "Error"))
                .finally($rootScope.hide_dimmer);
        },
        editTask() {
            const goalTaskFactory = Vue.getAngularModule('goalTaskFactory');
            const $rootScope = Vue.getAngularModule('$rootScope');
            const GoalTaskResource = Vue.getAngularModule('GoalTaskResource');
            const notifications = Vue.getAngularModule('notifications');
            const $q = Vue.getAngularModule('$q');


            goalTaskFactory.edit_main_options(this.task)
                .then(res => {
                    let data_for_save = {
                        name: res.name,
                        description: res.description,
                    };

                    if (!res.media_for_avatar && !res.avatar) {
                        data_for_save.reset_avatar = true;
                    } else {
                        if (res.media_for_avatar) {
                            data_for_save.media_id_for_avatar = res.media_for_avatar.pk;
                        }
                    }
                    if (this.UPDATE_CHILDREN_NEEDED) {
                        data_for_save['children_to_update_id_list'] = this.syncedChildrenIdList;
                    }
                    $rootScope.show_dimmer();
                    return GoalTaskResource.update_main_options(this.task.id, data_for_save);
                }, () => new Promise(() => {
                }))
                .then(resp => {
                    const possiblyUpdatedData = {
                        name: resp.data.name,
                        description: resp.data.description,
                        avatar: resp.data.avatar,
                        uses_default_avatar: resp.data.uses_default_avatar,
                        uses_generated_avatar: resp.data.uses_generated_avatar,
                    };
                    _.merge(this.task, possiblyUpdatedData);
                    if (this.UPDATE_CHILDREN_NEEDED) {
                        for (let child of this.task.children) {
                            if (this.syncedChildrenIdList.includes(child.id)) {
                                _.merge(child, possiblyUpdatedData);
                            }
                        }
                    }
                    notifications.success('Updated');
                }, err => notifications.error(err || 'Error'))
                .finally($rootScope.hide_dimmer);
        },
        editOrOpenTask(e) {
            e.stopPropagation();
            e.preventDefault();
            if (this.editMode && this.isEditable) {
                this.editTask();
            } else {
                if (this.routerNavigation) {
                    this.$router.push((e.target || e.srcElement).getAttribute('href'));
                } else {
                    document.location.href = (e.target || e.srcElement).getAttribute('href');
                }
            }
        },

        editDates() {
            const $rootScope = Vue.getAngularModule('$rootScope');
            const GoalTaskResource = Vue.getAngularModule('GoalTaskResource');
            const CalendarEventTaskLinksResource = Vue.getAngularModule('CalendarEventTaskLinksResource');
            const notifications = Vue.getAngularModule('notifications');
            const {popupComponent, vm, fEl} = simplePopupFactory.mount_vue_popup(EventTaskDatesPopup);
            popupComponent.show(this.eventParamsForTasks, this.task)
                .then(res => {
                    vm?.$destroy();
                    if (this.UPDATE_CHILDREN_NEEDED) {
                        res['children_to_update_id_list'] = this.syncedChildrenIdList;
                    }
                    $rootScope.show_dimmer();
                    return new Promise((resolve, reject) => {
                        if (this.eventParamsForTasks && res.linkToEvent && res.linkToEvent.wasChanged) {
                            let linkOrUnlinkPromise;
                            if (res.linkToEvent.isLinked) {
                                linkOrUnlinkPromise = CalendarEventTaskLinksResource.link_task_to_event(
                                    this.eventParamsForTasks.calendarOwnerId,
                                    this.eventParamsForTasks.eventId,
                                    this.eventParamsForTasks.startDate,
                                    res.linkToEvent.dates,
                                    res.linkToEvent.mode,
                                    this.task.id,
                                    this.syncedChildrenIdList,
                                );
                            } else {
                                linkOrUnlinkPromise = CalendarEventTaskLinksResource.unlink_task_from_event(
                                    this.eventParamsForTasks.calendarOwnerId,
                                    this.eventParamsForTasks.eventId,
                                    this.eventParamsForTasks.startDate,
                                    this.task.id,
                                    this.syncedChildrenIdList,
                                );
                            }
                            linkOrUnlinkPromise
                                .then(resp => {
                                    this.task.link_info = resp.data.updated_link_info;
                                    const updated_tasks_id_list = Object.keys(resp.data.updated_children_link_info);
                                    if (updated_tasks_id_list.length) {
                                        for (let child of this.task.children) {
                                            if (updated_tasks_id_list.includes(child.id + '')) {
                                                child.link_info = resp.data.updated_children_link_info[child.id + ''];
                                            }
                                        }
                                    }
                                    resolve(GoalTaskResource.update_date_options(this.task.id, res));
                                }, err => {
                                    reject(err);
                                })
                                .catch(console.error);
                        } else {
                            resolve(GoalTaskResource.update_date_options(this.task.id, res));
                        }
                    });
                }, () => new Promise(() => {
                    vm?.$destroy();
                }))
                .then(resp => {
                    let possiblyChangedData = {
                        start_date: resp.data.start_date,
                        end_date: resp.data.end_date,
                        // status: resp.data.status,
                        auto_complete_when_due: resp.data.auto_complete_when_due,
                        notify_when_due: resp.data.notify_when_due,
                    };
                    _.merge(this.task, resp.data);
                    if (this.UPDATE_CHILDREN_NEEDED) {
                        for (let child of this.task.children) {
                            if (this.syncedChildrenIdList.includes(child.id)) {
                                _.merge(child, possiblyChangedData);
                            }
                        }
                    }
                    notifications.success('Updated');
                }, err => notifications.error(err || 'Error'))
                .finally(() => {
                    $rootScope.hide_dimmer();
                });
        },
        editAssignee() {
            const $q = Vue.getAngularModule('$q');
            const $rootScope = Vue.getAngularModule('$rootScope');
            const goalTaskFactory = Vue.getAngularModule('goalTaskFactory');
            const GoalTaskResource = Vue.getAngularModule('GoalTaskResource');
            const notifications = Vue.getAngularModule('notifications');

            const additionalFilters = [];
            additionalFilters.push({
                name: 'members_type',
                filter: true,
                slot: 'left-from-search',
                value: 'all',
                options: [
                    {value: 'all', 'name': 'All Users'},
                    {value: 'non_su', 'name': 'Non Supported Users'},
                    {value: 'su', 'name': 'Supported Users'},
                    // {value: 'buddy', 'name': 'Buddies'},
                ],
                paramsWhenTrue: 'members_type'
            });
            if (this.eventParamsForTasks) {
                additionalFilters.push({
                    name: 'members_only',
                    text: 'Show members of this Event only',
                    inputType: 'switch',
                    filter: true,
                    value: true,
                    paramsWhenTrue: {
                        from_event: this.eventParamsForTasks.eventId,
                        event_date: this.eventParamsForTasks.eventDateMoment.format(VISUAL_DATE_FORMAT),
                    }
                });
            }


            goalTaskFactory.assignee_options(this.goal.id, {
                additional_filters: additionalFilters,
                info: ' '
            }).then(res => {
                $rootScope.show_dimmer();
                return GoalTaskResource.update_assignee_options(this.task.id, {id: res});
            }, () => new Promise(() => {
            }))
                .then(resp => {
                    _.merge(this.task, resp.data.updated_task_data);
                    notifications.success('Updated');
                }, err => notifications.error(err || 'Error'))
                .finally($rootScope.hide_dimmer);
        }
    },
    name: 'GoalTaskDesktop',
    updated() {
        this.$nextTick(() => {
            setTimeout(() => {
                if (this.$refs['complete-dropdown']) {
                    const ignoredElements = [this.$refs['complete-dropdown'].$refs.dropdown.$el, this.$refs['complete-checkbox']];
                    this.$refs['complete-dropdown'].addIgnoredElements(ignoredElements);
                }
            }, 100);
        });
    },
    beforeDestroy() {
        if (this.$refs['complete-dropdown']) {
            this.$refs['complete-dropdown'].resetIgnoredElements();
        }
    }
};