<template>
    <div class="" v-show="loaded">
        <div class="alert alert-warning" v-if="items.length===0">
            Nothing was added to your Dashboard
        </div>
        <div class="dashboard-list__container" v-if="desktopMode">
            <div class="inline-block">
                <dashboard-list-item-wrapper :item="items[0]"></dashboard-list-item-wrapper>
                <dashboard-list-item-wrapper :item="items[3]"></dashboard-list-item-wrapper>
            </div>
            <div class="inline-block">
                <dashboard-list-item-wrapper :item="items[1]"></dashboard-list-item-wrapper>
                <dashboard-list-item-wrapper :item="items[5]"></dashboard-list-item-wrapper>
            </div>
            <div class="inline-block">
                <dashboard-list-item-wrapper :item="items[2]"></dashboard-list-item-wrapper>
                <dashboard-list-item-wrapper :item="items[4]"></dashboard-list-item-wrapper>
            </div>
        </div>
        <div class="dashboard-list__container" v-else>
            <dashboard-list-item-wrapper :item="items[1]" white></dashboard-list-item-wrapper>
            <dashboard-list-item-wrapper :item="items[0]" white></dashboard-list-item-wrapper>
            <dashboard-list-item-wrapper :item="items[2]" white></dashboard-list-item-wrapper>
            <dashboard-list-item-wrapper :item="items[3]" white></dashboard-list-item-wrapper>
            <dashboard-list-item-wrapper :item="items[4]" white></dashboard-list-item-wrapper>
            <dashboard-list-item-wrapper :item="items[5]" white></dashboard-list-item-wrapper>
        </div>
    </div>
</template>

<script>
    // NOT v-for coz weird bug breaks justification
    import Vue from 'vue';
    import DashboardListItem from "./DashboardListItem.vue";
    import DashboardListItemWiki from "./DashboardListItemWiki.vue";
    import DashboardListItemCalendar from "./DashboardListItemCalendar.vue";
    import DashboardListItemWrapper from "./DashboardListItemWrapper";
    import {DjangoUrls} from 'DjangoUrls';
    import ScreenWidthAwareMixin from "../../../vue/mixins/ScreenWidthAwareMixin";
    import {CurrentUserClass} from "../shared/CurrentUser";
    import {
        DASHBOARD_ITEMS,
        DASHBOARD_ICONS,
        DASHBOARD_ITEMS_NAMES_SINGLE,
        DASHBOARD_ITEMS_NAMES_PLURAL, DASHBOARD_ICONS_WHITE, DASHBOARD_ITEMS_COLORS
    } from "./dashboard";

    const ADMIN = 'admin';
    const SHARED = 'shared';

    const CurrentUser = new CurrentUserClass();

    export default {
        components: {DashboardListItem, DashboardListItemWiki, DashboardListItemCalendar, DashboardListItemWrapper},
        mixins: [ScreenWidthAwareMixin],
        name: "dashboard-list",
        props: {},
        data() {
            return {
                show: false,
                dashboardState: {},
                loaded: false,
            };
        },
        computed: {
            desktopMode() {
                return this.windowWidth > 600;
            },
            profile() {
                return CurrentUser;
            },
            showWiki() {
                if (!this.dashboardState[DASHBOARD_ITEMS.WIKI]) {
                    return false;
                }
                return CurrentUser.wiki_enabled || this.dashboardState[DASHBOARD_ITEMS.WIKI][ADMIN] || this.dashboardState[DASHBOARD_ITEMS.WIKI][SHARED];
            },

            items() {

                const DASHBOARD_ABOUT_ME = {
                    link: DjangoUrls['user_detail']('me') + 'profile',
                    image: DASHBOARD_ICONS[DASHBOARD_ITEMS.ABOUT],
                    whiteImage: DASHBOARD_ICONS_WHITE[DASHBOARD_ITEMS.ABOUT],
                    label: DASHBOARD_ITEMS_NAMES_SINGLE[DASHBOARD_ITEMS.ABOUT],
                    component: DashboardListItem,
                    backgroundSize: '94%',
                    white: !CurrentUser.is_circle_user(),
                    mainColor: DASHBOARD_ITEMS_COLORS[DASHBOARD_ITEMS.ABOUT],
                    backgroundSizeWhite: '80%'

                };

                const MULTIPLE_CIRCLES = this.dashboardState[DASHBOARD_ITEMS.CIRCLE] && this.dashboardState[DASHBOARD_ITEMS.CIRCLE][ADMIN];

                const DASHBOARD_CIRCLE = {
                    link: MULTIPLE_CIRCLES ? DjangoUrls['circles_list_i_manage']() : (DjangoUrls['user_detail']('me') + 'circle'),
                    image: DASHBOARD_ICONS[DASHBOARD_ITEMS.CIRCLE],
                    whiteImage: DASHBOARD_ICONS_WHITE[DASHBOARD_ITEMS.CIRCLE],
                    mainColor: DASHBOARD_ITEMS_COLORS[DASHBOARD_ITEMS.CIRCLE],
                    white: !CurrentUser.is_circle_user(),
                    label: MULTIPLE_CIRCLES ? DASHBOARD_ITEMS_NAMES_PLURAL[DASHBOARD_ITEMS.CIRCLE] : DASHBOARD_ITEMS_NAMES_SINGLE[DASHBOARD_ITEMS.CIRCLE],
                    component: DashboardListItem,
                    backgroundSizeWhite: '80%'
                };

                const MULTIPLE_CALENDARS = this.dashboardState[DASHBOARD_ITEMS.CALENDAR] && this.dashboardState[DASHBOARD_ITEMS.CALENDAR][ADMIN];

                const DASHBOARD_CALENDAR = {
                    link: MULTIPLE_CALENDARS ? DjangoUrls['calendars_list_i_manage']() : (DjangoUrls['users_calendar']('my')),
                    image: DASHBOARD_ICONS[DASHBOARD_ITEMS.CALENDAR],
                    backgroundSizeWhite: '80%',
                    mainColor: DASHBOARD_ITEMS_COLORS[DASHBOARD_ITEMS.CALENDAR],
                    white: !CurrentUser.is_circle_user(),
                    whiteImage: DASHBOARD_ICONS_WHITE[DASHBOARD_ITEMS.CALENDAR],
                    label: MULTIPLE_CALENDARS ? DASHBOARD_ITEMS_NAMES_PLURAL[DASHBOARD_ITEMS.CALENDAR] : DASHBOARD_ITEMS_NAMES_SINGLE[DASHBOARD_ITEMS.CALENDAR],
                    component: DashboardListItemCalendar
                };

                const MULTIPLE_DIARIES = this.dashboardState[DASHBOARD_ITEMS.DIARY] && (this.dashboardState[DASHBOARD_ITEMS.DIARY][ADMIN] || this.dashboardState[DASHBOARD_ITEMS.DIARY][SHARED]);

                const DASHBOARD_DIARY = {
                    link: MULTIPLE_DIARIES ? DjangoUrls['my_diaries']() : DjangoUrls['blog_detail'](CurrentUser.blog_id),
                    image: DASHBOARD_ICONS[DASHBOARD_ITEMS.DIARY],
                    whiteImage: DASHBOARD_ICONS_WHITE[DASHBOARD_ITEMS.DIARY],
                    label: MULTIPLE_DIARIES ? DASHBOARD_ITEMS_NAMES_PLURAL[DASHBOARD_ITEMS.DIARY] : DASHBOARD_ITEMS_NAMES_SINGLE[DASHBOARD_ITEMS.DIARY],
                    component: DashboardListItem,
                    backgroundSize: '94%',
                    backgroundSizeWhite: '80%',
                    white: !CurrentUser.is_circle_user(),
                    mainColor: DASHBOARD_ITEMS_COLORS[DASHBOARD_ITEMS.DIARY],
                };

                const MULTIPLE_MEDIAS = this.dashboardState[DASHBOARD_ITEMS.MEDIA] && (this.dashboardState[DASHBOARD_ITEMS.MEDIA][ADMIN]);

                const DASHBOARD_MEDIA = {
                    link: MULTIPLE_MEDIAS ? DjangoUrls['folders_list_i_manage']() : (DjangoUrls['users_media'](CurrentUser.name)),
                    image: DASHBOARD_ICONS[DASHBOARD_ITEMS.MEDIA],
                    whiteImage: DASHBOARD_ICONS_WHITE[DASHBOARD_ITEMS.MEDIA],
                    label: MULTIPLE_MEDIAS ? DASHBOARD_ITEMS_NAMES_PLURAL[DASHBOARD_ITEMS.MEDIA] : DASHBOARD_ITEMS_NAMES_SINGLE[DASHBOARD_ITEMS.MEDIA],
                    component: DashboardListItem,
                    backgroundSizeWhite: '80%',
                    white: !CurrentUser.is_circle_user(),
                    mainColor: DASHBOARD_ITEMS_COLORS[DASHBOARD_ITEMS.MEDIA]
                };
                const MULTIPLE_GOALS = this.dashboardState[DASHBOARD_ITEMS.GOALS] && this.dashboardState[DASHBOARD_ITEMS.GOALS][ADMIN];
                const DASHBOARD_GOALS = {
                    link: MULTIPLE_GOALS ? DjangoUrls['users_whose_goals_i_manage_list']() : (DjangoUrls['projects']()),
                    image: DASHBOARD_ICONS[DASHBOARD_ITEMS.GOALS],
                    whiteImage: DASHBOARD_ICONS_WHITE[DASHBOARD_ITEMS.GOALS],
                    label: MULTIPLE_GOALS ? DASHBOARD_ITEMS_NAMES_PLURAL[DASHBOARD_ITEMS.GOALS] : DASHBOARD_ITEMS_NAMES_SINGLE[DASHBOARD_ITEMS.GOALS],
                    component: DashboardListItem,
                    backgroundSizeWhite: '80%',
                    white: !CurrentUser.is_circle_user(),
                    mainColor: DASHBOARD_ITEMS_COLORS[DASHBOARD_ITEMS.GOALS]
                };

                const MULTIPLE_WIKI = this.dashboardState[DASHBOARD_ITEMS.WIKI]
                    && (this.dashboardState[DASHBOARD_ITEMS.WIKI][ADMIN]
                        || (this.dashboardState[DASHBOARD_ITEMS.WIKI]['my'] > 1)
                        || CurrentUser.is_ah()
                        || (this.dashboardState[DASHBOARD_ITEMS.WIKI][SHARED] && !CurrentUser.is_circle_user())
                    );
                const DASHBOARD_WIKI = {
                    link: MULTIPLE_WIKI ? DjangoUrls['wikis_list_i_manage']() : (DjangoUrls['users_wiki'](CurrentUser.wiki_id)),
                    label: MULTIPLE_WIKI ? DASHBOARD_ITEMS_NAMES_PLURAL[DASHBOARD_ITEMS.WIKI] : DASHBOARD_ITEMS_NAMES_SINGLE[DASHBOARD_ITEMS.WIKI],
                    enlarged: CurrentUser.is_circle_user(),
                    component: DashboardListItemWiki
                };

                const res = [
                    // DASHBOARD_ABOUT_ME,
                    // DASHBOARD_CIRCLE,
                    // DASHBOARD_CALENDAR,
                    // DASHBOARD_DIARY,
                    // DASHBOARD_MEDIA,
                    // DASHBOARD_GOALS,
                ];

                const DASHBOARD_CONFIG = CurrentUser.dashboard_config;
                if (DASHBOARD_CONFIG[DASHBOARD_ITEMS.ABOUT] && !this.showWiki) {
                    res.push(DASHBOARD_ABOUT_ME);
                }
                if (DASHBOARD_CONFIG[DASHBOARD_ITEMS.CIRCLE]) {
                    res.push(DASHBOARD_CIRCLE);
                }
                if (DASHBOARD_CONFIG[DASHBOARD_ITEMS.CALENDAR]) {
                    res.push(DASHBOARD_CALENDAR);
                }
                if (DASHBOARD_CONFIG[DASHBOARD_ITEMS.DIARY]) {
                    res.push(DASHBOARD_DIARY);
                }
                if (DASHBOARD_CONFIG[DASHBOARD_ITEMS.GOALS]) {
                    res.push(DASHBOARD_GOALS);
                }
                if (DASHBOARD_CONFIG[DASHBOARD_ITEMS.MEDIA]) {
                    res.push(DASHBOARD_MEDIA);
                }


                if (DASHBOARD_CONFIG[DASHBOARD_ITEMS.WIKI] && this.showWiki) {
                    if (res.length > 0) {
                        res.splice(1, 0, DASHBOARD_WIKI);
                    } else {
                        res[1] = DASHBOARD_WIKI;
                    }

                }

                return res;
            }
        },
        methods: {
            fetchState() {
                const UserProfileResource = Vue.getAngularModule('UserProfileResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                UserProfileResource.get_my_dashboard_state(CurrentUser.id)
                    .then(resp => {
                        this.dashboardState = resp.data;
                        this.loaded = true;
                    })
                    .finally($rootScope.hide_dimmer);
            }
        },
        created() {
            this.fetchState();
        }
    };
</script>

<style scoped lang="scss">
    .dashboard-list__container {
        @media (min-width: 602px) {
            text-align: justify;
        }
        @media (max-width: 601px) {
            text-align: center;
        }
        width: 100%;

        .inline-block {
            vertical-align: top;
        }
    }

    .dashboard-list__container:before {
        content: '';
        display: block;
        width: 100%;
        margin-bottom: -1.2em;
    }

    .dashboard-list__container:after {
        content: '';
        display: inline-block;
        width: 100%;
    }

    .dashboard-list__show-more-button {
        font-size: 14px;
        font-weight: bolder;
        color: #555;
        cursor: pointer;
    }

</style>