<template>
    <div class="" v-hotkey="keymap">
        <community-page-alternative-header
                title="Our Wiki"
                :left-part-size="6"
        >
            <vue-folder-button
                    tabindex="0"
                    :no-text-on-mobile="true"
                    v-show="editMode&&!isMainSectionOpened&&wikiEditable"
                    @click.native="moveSectionsOrSlides"
                    :disabled="moveSectionsOrSlidesButtonDisabled"
                    icon-class="fas fa-arrow-from-top fa-rotate-180"
            ></vue-folder-button>
            <vue-folder-button
                    :no-text-on-mobile="true"
                    v-show="editMode&&createSectionVisible"
                    @click.native="createSection"
                    icon-class="fa-plus"
                    tabindex="0"
            ></vue-folder-button>
            <vue-folder-button
                    :target="{name:'community-wiki-access-page'}"
                    :is-router-link="true"
                    icon-class="fa-key"
                    color="brown"
                    v-if="viewerIsAdmin&&!editMode"
            ></vue-folder-button>
            <vue-folder-button
                    tabindex="0"
                    icon-class="fa-file-contract"
                    @click.native="showWikiLog"
                    v-if="viewerIsAdmin&&!editMode"
            ></vue-folder-button>
            <vue-folder-button
                    icon-class="fa-gear"
                    v-if="viewerIsAdmin&&!editMode"
                    @click.native="editWiki"
            ></vue-folder-button>
            <vue-folder-button
                    tabindex="0"
                    :icon-class="editMode?'fa-eye':'fa-pencil'"
                    @click.native="toggleEditMode"
                    v-if="isEditable"
            ></vue-folder-button>
            <vue-folder-button
                    tabindex="0"
                    color="blue"
                    icon-class="fa-reply"
                    @click.native="goBack"
                    v-if="!isMainSectionOpened"
            ></vue-folder-button>
        </community-page-alternative-header>
        <wiki-read-receipt-page-navigation
                v-if="readReceiptMode"
                class="margin-15-top margin-10-bottom"
                @onSectionOpen="onSectionOpen"
                @onSlideOpen="onSlideOpen"
                @onMainSectionOpen="onMainSectionOpen"
        ></wiki-read-receipt-page-navigation>
        <wiki-page-abstract
                :wiki-id="wikiId"
                @onMainSectionOpen="onMainSectionOpen"
                @onSectionOpen="onSectionOpen"
                :getSectionLink="getSectionLink"
        ></wiki-page-abstract>
    </div>

</template>

<script>
    import CommunityPageAlternativeHeader from "./CommunityPageAlternativeHeader.vue";
    import WikiPageAbstract from "../wiki_page/WikiPageAbstract";
    import {
        getMainWikiPageName,
        getSubsectionDetailWikiPageName,
        getSubsectionWikiPageName
    } from "../wiki_page/WikiChildrenPages";
    import {COMMUNITY_WIKI_ROUTER_PREFIX} from "./config";
    import {mapGetters} from "vuex";
    import WikiReadReceiptPageNavigation from "../wiki/wiki_read_receipts_common/WikiReadReceiptPageNavigation";
    import BackButtonMixin from "../../../vue/mixins/BackButtonMixin";

    export default {
        name: "CommunityPageAboutUsWiki",
        props: {
            wikiId: Number,
            subsectionsDisabled: Boolean,
            community: Object,
        },
        components: {WikiReadReceiptPageNavigation, CommunityPageAlternativeHeader, WikiPageAbstract},
        mixins: [BackButtonMixin],
        data() {
            return {
                angularModulesNames: ['$rootScope', '$location', 'notifications'],
            };

        },
        computed: {
            ...mapGetters('WikiStore', ['currentSection', 'wikiOwner', 'isMainSectionOpened', 'editMode', 'createSectionVisible', 'wikiEditable', 'isEditable', 'moveSectionsOrSlidesButtonDisabled', 'readReceiptMode']),
            viewerIsAdmin() {
                return this.$store.getters['communityPage/viewerIsAdmin'];
            },
            keymap() {
                return {
                    'alt+b': this.goBack
                };
            }

        },
        methods: {
            toggleEditMode() {
                this.$store.commit('WikiStore/toggleEditMode', !this.editMode);
            },
            editWiki() {
                this.$store.dispatch('WikiStore/editWiki');
            },
            showWikiLog() {
                this.$store.dispatch('WikiStore/showWikiLog');
            },
            moveSectionsOrSlides() {
                this.$store.dispatch('WikiStore/moveSectionsOrSlides', this.currentSection.id);
            },
            onMainSectionOpen() {
                this.$router.push({
                    name: getMainWikiPageName(COMMUNITY_WIKI_ROUTER_PREFIX),
                    params: {wikiId: this.wikiId},
                    query: this.$route.query
                });
            },
            getSectionLink(section, slide=undefined) {
                const params = {
                    sectionId: section.id,
                    wikiId: this.wikiId,
                };
                if (slide) {
                    params.slideId = slide.id;
                }
                if (section.is_submenu) {
                    return {
                        name: getSubsectionWikiPageName(COMMUNITY_WIKI_ROUTER_PREFIX),
                        params,
                        query: this.$route.query
                    };
                } else {
                    return {
                        name: getSubsectionDetailWikiPageName(COMMUNITY_WIKI_ROUTER_PREFIX),
                        params,
                        query: this.$route.query
                    };
                }
            },
            onSectionOpen(section) {
                this.$router.push(this.getSectionLink(section));
            },
            onSlideOpen({section, slide}){
                if (slide.id === this.activeSlide?.id) {
                    return;
                }
                this.$router.push(this.getSectionLink(section, slide));
            },
            goBack() {
                if (this.urlFrom) {
                    this.$_goBack();
                    return;
                }
                if (this.isMainSectionOpened) {
                    return;
                }
                if (this.currentSection) {
                    if (this.currentSection.parent_section) {
                        this.$router.push({
                            name: getSubsectionWikiPageName(COMMUNITY_WIKI_ROUTER_PREFIX),
                            params: {
                                sectionId: this.currentSection.parent_section.id
                            },
                            query: this.$route.query
                        });
                    } else {
                        this.$router.push({
                            name: getMainWikiPageName(COMMUNITY_WIKI_ROUTER_PREFIX),
                            params: {wikiId: this.wikiId},
                            query: this.$route.query
                        });
                    }
                }
            },
            createSection() {
                this.$store.dispatch('WikiStore/createSection');
            },
            async loadReadReceiptIfRequired() {
                const receiptCode = this.$route.query?.rr;
                if (receiptCode) {
                    await this.$store.dispatch('WikiStore/loadReadReceipt', receiptCode);
                }
            },

        },
        mounted() {
            this.loadReadReceiptIfRequired();
        }
    };
</script>

<style scoped lang="scss">
</style>
