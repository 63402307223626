var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "whole-day-event", style: _vm.eventStyle }, [
    _c("div", {
      staticClass: "whole-day-event__avatar inline-block",
      style: _vm.avatarStyle,
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "whole-day-event__name inline-block" },
      [
        _c(
          "strong",
          [
            _c(
              "router-link",
              {
                staticClass: "vertical-middle",
                attrs: { to: _vm._f("backHrefQuery")(_vm.eventLink) },
              },
              [
                _c("span", [
                  _vm.event.is_periodic
                    ? _c("i", { staticClass: "fa fa-repeat color-blue" })
                    : _vm._e(),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("cutName")(
                          _vm.event.event_data.name,
                          _vm.amountOfLettersInName
                        )
                      )
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.event.event_data.audio_caption
          ? _c(
              "div",
              {
                staticClass: "inline-block vertical-middle",
                staticStyle: { "min-width": "33px" },
              },
              [
                _c("audio-toggle", {
                  staticClass: "vertical-middle",
                  staticStyle: { "font-size": "22px", margin: "0 5px" },
                  attrs: {
                    "audio-caption": _vm.event.event_data.audio_caption,
                    "fa-class": "fal",
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.event.comments_count > 0
          ? _c(
              "router-link",
              {
                staticClass: "vertical-middle color-black",
                attrs: {
                  to: _vm._f("backHrefQuery")(_vm.eventLink + "comments"),
                },
              },
              [
                _c("i", { staticClass: "fal fa-comment vertical-middle" }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "vertical-middle",
                    staticStyle: { position: "relative", left: "-2px" },
                  },
                  [_vm._v(_vm._s(_vm.event.comments_count))]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.event.going_count > 0
          ? _c("i", { staticClass: "fal fa-user-check vertical-middle" })
          : _vm._e(),
        _vm._v(" "),
        _vm.event.going_count > 0
          ? _c(
              "span",
              {
                staticClass: "vertical-middle margin-5-right",
                staticStyle: { position: "relative", left: "-2px" },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.event.going_count) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.event.linked_goals_count > 0
          ? _c("i", { staticClass: "fal fa-bullseye-pointer vertical-middle" })
          : _vm._e(),
        _vm._v(" "),
        _vm.event.linked_goals_count > 0
          ? _c(
              "span",
              {
                staticClass: "vertical-middle margin-5-right",
                staticStyle: { position: "relative", left: "-2px" },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.event.linked_goals_count) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }