<template>
    <modal v-model="opened" :transition='0' :append-to-body="true" :backdrop="false">
        <div slot="header">
            <a id="close_popup" @click="closePopup"></a>
            <h3 class="modal-title">Please review your Profile</h3>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <p class="popup-paragraph">Hello,</p>

                <p class="popup-paragraph">Your profile is currently set to ‘<strong>public</strong>’ which means other users on Multi Me can search for you and invite you to join their Circles or Groups.</p>

                <p class="popup-paragraph">You can change this now to a private setting by choosing to ‘<strong>hide your profile</strong>’ using the switch below, or if you are happy for people to search and invite you please just close this pop up.</p>

                <p class="popup-paragraph">
                    You can update your profile settings at any time by going to your <strong><a
                        :href="accountSettingsLink">Account Settings</a></strong> page.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 margin-15-top">
                <vue-switch-with-label label="Hide my Profile" name="hide_my_profile" v-model="value"
                                       @input="onInput"></vue-switch-with-label>
            </div>
        </div>
        <div slot="footer">
            <button class="btn btn-mm btn-green save-button" @click="save">
                OK
            </button>
        </div>
    </modal>
</template>

<script>
    import Vue from 'vue';
    import {DjangoUrls} from 'DjangoUrls';
    export default {
        name: "VisibilityChangesAcceptPopup",
        data() {
            return {
                opened: false,
                value: false,
                userId: undefined,
            };
        },
        computed:{
          accountSettingsLink(){
              return DjangoUrls['user_update_settings']();
          }
        },
        methods: {
            show(userId, value) {
                this.userId = userId;
                this.value = value;
                this.opened = true;
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            closePopup() {
                this.opened = false;
                this.reject();
            },
            save() {
                this.opened = false;
                this.resolve();
            },
            onInput(hideProfile) {
                const UserProfileSettingsResource = Vue.getAngularModule('UserProfileSettingsResource');
                UserProfileSettingsResource.update_profile_visibility(this.userId, !hideProfile)
                    .then(resp => {
                        Vue.notifications.success('Profile settings successfully updated');
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                    });
            }
        }
    };
</script>

<style scoped>
    .popup-paragraph {
        margin-bottom: 10px;
        line-height: 20px;
    }

</style>