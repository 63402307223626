<template>
    <a v-bind="$attrs" v-on="$listeners" :href="target">
        <slot></slot>
    </a>
</template>

<script>
    export default {
        name: "FolderButtonLink",
        props: {
            target: String,
        }
    };
</script>

<style scoped>

</style>