import {
    WikiActionsSubtypesVerbal
} from '~/angular/app/tasks/renderTask/task_components/WikiActionLogChunkReady/log_specific_components_for_all/common';
import WikiLogPossibleLink from "./WikiLogPossibleLink";

export default {
    components:{
        WikiLogPossibleLink
    },
    computed: {
        verbalChangesSubtypes() {
            if (this.wikiLog.changes_subtypes?.length) {
                return this.wikiLog.changes_subtypes.map(s => WikiActionsSubtypesVerbal[s]).join(', ');
            } else {
                return '';
            }
        },
        wikiSnapshotAfter() {
            return this.wikiLog.wiki_snapshot_after_action;
        },
        wikiSnapshotBefore() {
            return this.wikiLog.wiki_snapshot_before_action;
        },
        sectionSnapshotAfter() {
            return this.wikiLog.wiki_section_snapshot_after_action;
        },
        sectionSnapshotBefore() {
            return this.wikiLog.wiki_section_snapshot_before_action;
        },
        slideSnapshotAfter() {
            return this.wikiLog.wiki_slide_snapshot_after_action;
        },
        slideSnapshotBefore() {
            return this.wikiLog.wiki_slide_snapshot_before_action;
        },
        attachmentSnapshot() {
            return this.wikiLog.wiki_slide_attachment_snapshot;
        },
    },
    props: {
        wikiLog: Object,
    },
};
