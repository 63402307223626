var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "spi__container inline-block",
      class: _vm.colorClass,
      attrs: {
        href: "javascript:",
        tabindex: "0",
        role: "button",
        "aria-label": _vm.item.label,
      },
    },
    [
      _vm.showCheckMark
        ? _c(
            "div",
            {
              staticClass: "spi__check-mark",
              class: { "spi__check-mark_yellow": _vm.checkMarkYellow },
            },
            [
              _c("i", {
                staticClass: "fa fa-check",
                attrs: { "aria-hidden": "true" },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "spi__button" }, [
        _c("div", {
          staticClass: "spi__title",
          domProps: { innerHTML: _vm._s(_vm.item.label) },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "spi__icon-section", class: _vm.item.customIcon },
          [
            _vm.item.iconClass
              ? _c("i", {
                  staticClass: "fa spi__fa-icon",
                  class: _vm.item.iconClass,
                  attrs: { "aria-hidden": "true" },
                })
              : _vm._e(),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }