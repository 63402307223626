var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    _vm._g(
      _vm._b(
        {
          attrs: { transition: 0, "append-to-body": true, backdrop: false },
          on: { show: _vm.onShowed },
        },
        "modal",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [_vm._t("header")],
        2
      ),
      _vm._v(" "),
      _c("div", [_vm._t("default")], 2),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _vm._t("footer"),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col-sm-12",
                staticStyle: { "line-height": "2px" },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "focus-catch-link",
                    attrs: { tabindex: "0", "aria-hidden": "true" },
                    on: { focus: _vm.$parent.focusToClose },
                  },
                  [_vm._v(" ")]
                ),
              ]
            ),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }