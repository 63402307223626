var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: {
        "aria-label": _vm.header,
        "aria-describedby": "simple-popup-content",
        size: _vm.size,
        role: "dialog",
      },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          ref: "closeButton",
          attrs: {
            id: "close_popup",
            role: "button",
            "aria-label": "Close dialog",
            tabindex: "0",
          },
          on: {
            click: _vm.close,
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.close.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                )
                  return null
                if (!$event.shiftKey) return null
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.focusToClose.apply(null, arguments)
              },
            ],
          },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.header))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", {
          staticClass: "col-sm-12",
          staticStyle: { "font-size": "16px" },
          attrs: { id: "simple-popup-content" },
          domProps: { innerHTML: _vm._s(_vm.message) },
        }),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _vm.buttonNo
              ? _c(
                  "button",
                  {
                    ref: "buttonNo",
                    staticClass: "btn btn-mm btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.no },
                  },
                  [_vm._v(_vm._s(_vm.buttonNo) + "\n                ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.buttonYes
              ? _c(
                  "button",
                  {
                    ref: "buttonYes",
                    staticClass: "btn btn-mm",
                    class: _vm.buttonYesClass,
                    attrs: { type: "button" },
                    on: { click: _vm.ok },
                  },
                  [_vm._v(_vm._s(_vm.buttonYes) + "\n                ")]
                )
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "span",
              {
                staticClass: "focus-catch-link",
                attrs: { tabindex: "0", "aria-hidden": "true" },
                on: { focus: _vm.focusToClose },
              },
              [_vm._v(" ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }