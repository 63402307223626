/**
 * Created by orion on 08.06.17.
 */

import urls from 'DjangoUrls';

let { DjangoUrls } = urls;
import _ from 'lodash';
import axios from 'axios';

function LikeStoryResourceFactory (prefix) {

    let like_story_resource = function ($http) {
        return {
            update_post: function (id, data) {
                const url = DjangoUrls[`api.v1:${prefix}-update-post`](id);
                return axios({
                    method: 'POST',
                    url,
                    data
                });
            },

            get_story: function (target_id, page = 1, additionalParams = {}) {
                const url = DjangoUrls[`api.v1:${prefix}-get-story`](target_id);
                return axios({
                    method: 'GET',
                    url,
                    params: _.merge({}, {
                        page
                    }, additionalParams)
                });
            },

            get_story_infinite: function (target_id, params, headers = {}) {
                const url = DjangoUrls[`api.v1:${prefix}-get-story-infinite`](target_id);
                return axios({
                    method: 'GET',
                    url,
                    params,
                    headers,
                });
            },

            add_to_story: function (target_id, data) {
                const url = DjangoUrls[`api.v1:${prefix}-add-to-story`](target_id);
                return axios({
                    method: 'POST',
                    url,
                    data
                });
            },

            bulk_create_from_media: function (target_id, id_list) {
                const url = DjangoUrls[`api.v1:${prefix}-bulk-create-from-media`](target_id);
                return axios({
                    method: 'POST',
                    url,
                    data: {
                        id_list
                    }
                });
            },

            update_item: function (target_id, item_id, data) {
                let data_for_sent = _.merge({}, data, { id: item_id });
                const url = DjangoUrls[`api.v1:${prefix}-update-item`](target_id);
                return axios({
                    method: 'PATCH',
                    url,
                    data: data_for_sent
                });
            },

            reorder_items: function (target_id, ordered_pk_list) {
                const url = DjangoUrls[`api.v1:${prefix}-reorder-items`](target_id);
                return axios({
                    method: 'PATCH',
                    url,
                    data: {
                        ordered_pk_list
                    }
                });
            },

            reorder_items_in_infinite_list: function (target_id, ordered_pk_list) {
                const url = DjangoUrls[`api.v1:${prefix}-reorder-items-in-infinite-list`](target_id);
                return axios({
                    method: 'PATCH',
                    url,
                    data: {
                        ordered_pk_list
                    }
                });
            },

            remove_item: function (target_id, item_id) {
                const url = DjangoUrls[`api.v1:${prefix}-remove-item`](target_id);
                return axios({
                    method: 'PATCH',
                    url,
                    data: {
                        id: item_id
                    }
                });
            },
        };
    };
    like_story_resource.$inject = ['$http'];

    return like_story_resource;
}

export default LikeStoryResourceFactory;
