var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "media-folders-folder inline-block",
      style: [_vm.dragStyle],
      on: { click: _vm.clickOnFolder },
    },
    [
      _vm.isShared
        ? _c("img", {
            staticClass: "img-circle vertical-middle",
            attrs: { src: _vm.folder.owner.avatar, width: "20", alt: "" },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      !_vm.folder.isFake
        ? _c(
            "div",
            {
              staticClass:
                "media-folders-folder__name inline-block vertical-middle",
            },
            [_vm._v(_vm._s(_vm._f("cutName")(_vm.folder.name, 25)) + "\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.folder.isFake
        ? _c(
            "div",
            {
              staticClass:
                "media-folders-folder__name inline-block vertical-middle",
            },
            [_c("i", { staticClass: "fa fa-level-up" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isShared && _vm.editMode
        ? _c(
            "div",
            {
              staticClass:
                "media-folders-folder__name inline-block vertical-middle color-red",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.hideFolder.apply(null, arguments)
                },
              },
            },
            [_c("i", { staticClass: "fa fa-eye-slash hide-folder-icon" })]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "media-folders-folder__icon inline-block vertical-middle",
      },
      [_c("i", { staticClass: "fa fa-folder-o" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }