/**
 * Created by orion on 06.04.17.
 */
import urls from 'DjangoUrls';

let { DjangoUrls } = urls;
import _ from 'lodash';
import { ACCESS_LINK_TOKEN_HEADER_NAME } from '../wiki/common';
import axios from 'axios';

BlogEntryGoalsResource.$inject = ['$http'];

function BlogEntryGoalsResource($http) {
    return {
        get_linked_goals,
        get_users_goals_for_link,
        link_to_task,
        get_users_tasks_for_link_in_goal,
        unlink_all_blog_tasks
    };

    function get_linked_goals(entry_id, params, accessToken) {
        const url = DjangoUrls['api.v1:blog_entry_goals-get-linked-goals'](entry_id);
        const headers = {};
        if (accessToken) {
            headers[ACCESS_LINK_TOKEN_HEADER_NAME] = accessToken;
        }
        return axios({
            method: 'GET',
            url,
            headers,
            params
        });
    }

    function get_users_goals_for_link(entry_id, params) {
        const url = DjangoUrls['api.v1:blog_entry_goals-get-users-goals-for-link'](entry_id);
        return axios({
            method: 'GET',
            url: url,
            params
        });
    }

    function get_users_tasks_for_link_in_goal(entry_id, goal_id, params) {
        const url = DjangoUrls['api.v1:blog_entry_goals-get-users-tasks-for-link-in-goal'](entry_id);
        return axios({
            method: 'GET',
            url: url,
            params: _.merge({}, { id: goal_id }, params)
        });
    }

    function link_to_task(entry_id, new_checked_ids, new_unchecked_ids) {
        const url = DjangoUrls['api.v1:blog_entry_goals-link-to-task'](entry_id);
        return axios({
            method: 'POST',
            url: url,
            data: {
                new_checked_ids,
                new_unchecked_ids
            }
        });
    }

    function unlink_all_blog_tasks(entry_id, goal_id) {
        const url = DjangoUrls['api.v1:blog_entry_goals-unlink-all-blog-tasks'](entry_id);
        return axios({
            method: 'POST',
            url: url,
            data: {
                id: goal_id
            }
        });
    }

}

export default BlogEntryGoalsResource;
