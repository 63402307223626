import Vue from 'vue';
import { LEVELS } from './constants';
import { CurrentUserClass } from '../shared/CurrentUser';
import captionPlayerBus from '../shared/captionPlayer';
import WikiCreateEditSlidePopup from './popups/WikiCreateEditSlidePopup.vue';
import WikiAddAttachmentTypeSelectPopup from './popups/WikiAddAttachmentTypeSelectPopup.vue';
import CreateNewWikiPopup from './popups/CreateNewWikiPopup.vue';
import ApplyTemplateToWikiPopup from './popups/ApplyTemplateToWikiPopup.vue';
import WikiSettingsPopup from './popups/WikiSettingsPopup.vue';
import Raven from '../../../raven/raven';
import _ from 'lodash';
import selectFactory from 'shared/select_factory/select_factory';
import WikiSectionResource from '../wiki/wiki_main_page/wiki_section_resource';
import { simplePopupFactory } from 'shared/simple_popups/simplePopupFactory';
import WikiLogPopup from './popups/wiki_log/WikiActionLogPopup';
import wiki_read_receipts_track_resource from '../wiki/wiki_settings_page/wiki_read_receipts_track_resource';
import WikiResource from '../wiki/wiki_main_resource';
import AddWebsiteLinkPopup from '../add_media/popups/AddWebsiteLinkPopup.vue';
import WikiEditorNamePopup from '~/angular/app/wiki_page/popups/WikiEditorNamePopup.vue';
import WikiStartEditSessionPopup from '~/angular/app/wiki_page/popups/WikiStartEditSessionPopup.vue';

export default {
    namespaced: true,
    state: {
        accessParams: {
            wikiId: undefined,
            accessCode: undefined,
            accessToken: undefined,
        },
        viewerState: {
            isWikiAdmin: false,
            personalMembershipId: undefined,
            canBecomeWikiAdmin: false,
            canManageWikiTemplate: false,
            canChangeWikiPriority: false,
            canSeeUpdateBadges: false,
        },

        membershipLoaded: false,
        membershipAccessGranted: false,
        membershipAccessError: false,

        readReceiptMode: false,
        readReceipt: undefined,

        wikiOwner: {},
        wikiName: 'Wiki',
        wikiLogo: undefined,
        wikiDescription: '',
        wikiShowContentCounters: false,
        wikiTemplate: null, // Wiki is template(source of template)
        subsectionDisabled: false,
        wikiEditable: false,
        currentSectionEditable: false,
        isCommunityWiki: false,
        isMainWiki: true,
        editMode: false,
        wikiEditor: null,
        sections: [],
        slides: [],
        activeSlide: undefined,
        activeSectionNumber: undefined,
        currentLevel: LEVELS.MAIN,
        currentSection: undefined,
        popupOpened: false,

        selectedToMoveSectionsIdList: [],
        selectedToMoveSlidesIdList: [],

        draggedSlide: undefined,
        underDragSlide: undefined,
        draggedSection: undefined,
        underDragSection: undefined,
    },
    mutations: {
        setPopupOpened(state, popupOpened) {
            state.popupOpened = popupOpened;
        },
        toggleReadReceiptMode(state, value) {
            state.readReceiptMode = value;
        },
        setReadReceipt(state, readReceipt) {
            state.readReceipt = readReceipt;
        },
        markSlideAsReadAsReadInReceipt(state, slide) {
            if (state.readReceipt?.wiki_slides) {
                for (const slideInReceipt of state.readReceipt?.wiki_slides) {
                    if (slideInReceipt.id === slide.id) {
                        slideInReceipt.is_read = true;
                    }
                }
            }
        },
        markWikiAsReadAsReadInReceipt(state) {
            console.log(state.readReceipt?.wikis);
            if (state.readReceipt?.wikis) {
                for (const wiki of state.readReceipt?.wikis) {
                    console.log(wiki);
                    wiki.is_read = true;
                }
            }
        },
        markSectionAsReadAsReadInReceipt(state, slide) {
            if (state.readReceipt?.wiki_slides) {
                for (const sectionInReceipt of state.readReceipt?.wiki_sections) {
                    if (sectionInReceipt.id === slide.id) {
                        sectionInReceipt.is_read = true;
                    }
                }
            }
        },
        resetLoadedState(state) {
            state.membershipLoaded = false;
            state.membershipAccessGranted = false;
            state.membershipAccessError = false;
            //
            // state.wikiOwner = undefined;
            // state.wikiEditable = false;
            // state.isCommunityWiki = false;
            //
            // state.accessParams = {
            //     wikiId: undefined,
            //     accessCode: undefined,
            // };
            // state.viewerState = {
            //     isWikiAdmin: false,
            //     canBecomeWikiAdmin: false,
            // };
        },
        setActiveSectionNumber(state, number) {
            state.activeSectionNumber = number;
        },
        toggleSelectToMoveSectionsId(state, sectionId) {
            if (state.selectedToMoveSectionsIdList.indexOf(sectionId) !== -1) {
                state.selectedToMoveSectionsIdList.splice(state.selectedToMoveSectionsIdList.indexOf(sectionId), 1);
            } else {
                state.selectedToMoveSectionsIdList.push(sectionId);
            }
        },
        toggleSelectToMoveSlidesId(state, slideId) {
            if (state.selectedToMoveSlidesIdList.indexOf(slideId) !== -1) {
                state.selectedToMoveSlidesIdList.splice(state.selectedToMoveSlidesIdList.indexOf(slideId), 1);
            } else {
                state.selectedToMoveSlidesIdList.push(slideId);
            }
        },
        clearSelectedToMoveSectionsIdList(state) {
            state.selectedToMoveSectionsIdList = [];
        },
        clearSelectedToMoveSlidesIdList(state) {
            state.selectedToMoveSlidesIdList = [];
        },
        setDraggedSlide(state, slide) {
            state.draggedSlide = slide;
        },
        setUnderDragSlide(state, slide) {
            state.underDragSlide = slide;
        },
        setDraggedSection(state, section) {
            state.draggedSection = section;
        },
        setUnderDragSection(state, section) {
            state.underDragSection = section;
        },
        setActiveSlide(state, slide) {
            state.activeSlide = slide;
        },
        addNewSlide(state, slide) {
            state.slides.push(slide);
            state.activeSlide = slide;
        },
        removeActiveSlide(state) {
            const currentIndex = state.slides.indexOf(state.activeSlide);
            const slidesCount = state.slides.length;
            if (slidesCount === 1) {//is last slide
                state.activeSlide = undefined;
            } else if (currentIndex === 0) { // is first - move to next
                state.activeSlide = state.slides[currentIndex + 1];
            } else { // move to prev
                state.activeSlide = state.slides[currentIndex - 1];
            }
            state.slides.splice(currentIndex, 1);
        },
        nextSlide(state) {
            const currentIndex = state.slides.indexOf(state.activeSlide);
            if (currentIndex === (state.slides.length - 1)) {
                state.activeSlide = state.slides[0];
            } else {
                state.activeSlide = state.slides[currentIndex + 1];
            }
        },
        prevSlide(state) {
            const currentIndex = state.slides.indexOf(state.activeSlide);
            if (currentIndex === 0) {
                state.activeSlide = state.slides[state.slides.length - 1];
            } else {
                state.activeSlide = state.slides[currentIndex + -1];
            }
        },
        setCurrentLevel(state, level) {
            state.currentLevel = level;
        },
        setSubsectionDisabled(state, disabled) {
            state.subsectionDisabled = disabled;
        },
        setCurrentSection(state, section) {
            state.currentSection = section;
            if ((section && !section.is_editable) && state.editMode) {
                state.editMode = false;
            }
            if ((!section && !state.wikiEditable) && state.editMode) {
                state.editMode = false;
            }
        },
        setAccessParams(state, {
            wikiId,
            accessCode,
            accessToken
        }) {
            state.accessParams.wikiId = parseInt(wikiId);
            state.accessParams.accessCode = accessCode;
            state.accessParams.accessToken = accessToken;
        },
        toggleEditMode(state, value) {
            if (!value) {
                state.editMode = value;
                state.wikiEditor = null;
                return;
            }
            const CurrentUser = new CurrentUserClass();
            if (!CurrentUser.is_circle_user()) {
                state.editMode = value;
                state.wikiEditor = null;
                return;
            }

            const {
                vm,
                popupComponent,
                fEl
            } = simplePopupFactory.mount_vue_popup(WikiStartEditSessionPopup);

            return popupComponent.show()
                .then(res => {
                    if (res === 'me') {
                        const data = {};
                        const cu = new CurrentUserClass();
                        data.id = cu.id;
                        data.isMe = true;
                        state.wikiEditor = data;
                        state.editMode = value;
                    } else if (res === 'other') {

                        const {
                            vm,
                            popupComponent,
                            fEl
                        } = simplePopupFactory.mount_vue_popup(WikiEditorNamePopup);
                        return popupComponent.show(state.accessParams.wikiId)
                            .then(res => {
                                console.log(res);
                                state.wikiEditor = res;
                                state.editMode = value;
                            }, () => {
                            })
                            .catch(() => {
                            })
                            .finally(() => {
                                vm?.$destroy();
                                fEl?.focus();
                            });
                    }
                }, () => {
                })
                .catch(() => {
                })
                .finally(() => {
                    vm?.$destroy();
                    fEl?.focus();
                });

        },
        setSections(state, sections) {
            state.sections = sections;
        },
        setSlides(state, slides) {
            state.slides = slides;
        },
        replaceSection(state, {
            sectionId,
            updatedSection
        }) {
            let index = -1;
            for (let i = 0; i < state.sections.length; i++) {
                if (state.sections[i].id === sectionId) {
                    index = i;
                    break;
                }
            }
            if (index !== -1) {
                Vue.set(state.sections, index, updatedSection);
            }
        },
        replaceSlide(state, {
            slideId,
            updatedSlide
        }) {
            const isActive = state.activeSlide.id === slideId;
            let index = -1;
            for (let i = 0; i < state.slides.length; i++) {
                if (state.slides[i].id === slideId) {
                    index = i;
                    break;
                }
            }
            if (index !== -1) {
                Vue.set(state.slides, index, updatedSlide);
                if (isActive) {
                    state.activeSlide = updatedSlide;
                }
            }
        },
        updateSection(state, {
            section,
            updatedData
        }) {
            // _.merge(section,updatedData)
            Object.assign(section, updatedData);
        },

        addSection(state, section) {
            state.sections.push(section);
        },
        deleteSection(state, section) {
            state.sections.splice(state.sections.indexOf(section), 1);
        },
        deleteSectionsList(state, sectionsIdList) {
            state.sections = state.sections.filter(s => !sectionsIdList.includes(s.id));
        },
        deleteSlidesList(state, slidesIdList) {
            state.slides = state.slides.filter(s => !slidesIdList.includes(s.id));
        }
    },
    getters: {
        membershipAccessGranted(state, getters, rootState) {
            return state.membershipAccessGranted;
        },
        membershipAccessError(state, getters, rootState) {
            return state.membershipAccessError;
        },
        membershipLoaded(state, getters, rootState) {
            return state.membershipLoaded;
        },
        readReceiptMode(state, getters, rootState) {
            return state.readReceiptMode;
        },
        readReceipt(state, getters, rootState) {
            return state.readReceipt;
        },
        batchUploadHidden(state, getters, rootState) {
            const currentUser = new CurrentUserClass();
            return currentUser.is_circle_user();
        },
        createSectionVisible(state, getters, rootState) {
            let creationPossible = getters.isEditable && (state.currentLevel === LEVELS.MAIN || state.currentLevel === LEVELS.SUB);
            if (state.currentLevel === LEVELS.MAIN) {
                if (getters.sections.length >= 7) {
                    creationPossible = false;
                }
            }
            if (state.currentLevel === LEVELS.SUB) {
                if (getters.sections.length >= 50) {
                    creationPossible = false;
                }
            }
            return creationPossible;

        },
        isMainSectionOpened(state, getters, rootState) {
            return state.currentLevel === LEVELS.MAIN;
        },
        isWikiSharable(state, getters, rootState) {
            if (state.isCommunityWiki) {
                return false;
            }
            const CurrentUser = new CurrentUserClass();
            if (state.wikiOwner && CurrentUser.has_permission('share')) {
                if ((CurrentUser.id === state.wikiOwner.id) || CurrentUser.is_guardian_of(state.wikiOwner.id)) {
                    return true;
                }
            }
        },
        isCommunityWiki(state, getters, rootState) {
            return state.isCommunityWiki;
        },
        wikiOwner(state, getters, rootState) {
            return state.wikiOwner;
        },
        wikiName(state, getters, rootState) {
            return state.wikiName;
        },
        wikiLogo(state, getters, rootState) {
            return state.wikiLogo;
        },
        wikiDescription(state, getters, rootState) {
            return state.wikiDescription;
        },
        wikiShowContentCounters(state, getters, rootState) {
            return state.wikiShowContentCounters;
        },
        viewerState(state, getters, rootState) {
            return state.viewerState;
        },
        accessParams(state, getters, rootState) {
            return state.accessParams;
        },
        wikiEditable(state) {
            return state.wikiEditable;
        },
        wikiTemplate(state) {
            return state.wikiTemplate;
        },
        isEditable(state, getters, rootState) {
            if (getters.isMainSectionOpened) {
                return state.wikiEditable;
            } else {
                if (getters.currentSection) {
                    return getters.currentSection.is_editable;
                }
            }
            return false;
        },
        editMode(state, getters, rootState) {
            return state.editMode;
        },
        selectedToMoveSectionsIdList(state, getters, rootState) {
            return state.selectedToMoveSectionsIdList;
        },
        selectedToMoveSlidesIdList(state, getters, rootState) {
            return state.selectedToMoveSlidesIdList;
        },
        moveSectionsOrSlidesButtonDisabled(state, getters, rootState) {
            if (state.currentLevel === LEVELS.SUB) {
                return state.selectedToMoveSectionsIdList.length === 0;
            } else if (state.currentLevel === LEVELS.DETAIL) {
                return state.selectedToMoveSlidesIdList.length === 0;
            }
        },
        sections(state, getters, rootState) {
            return state.sections;
        },
        activeSectionNumber(state, getters, rootState) {
            return state.activeSectionNumber;
        },
        sectionPositions(state, getters, rootState) {
            return {
                1: [0],
                2: [0, 2],
                3: [0, 2, 5],
                4: [0, 1, 6, 7],
                5: [0, 1, 3, 4, 6],
                6: [0, 1, 3, 7, 4, 6],
                7: [0, 1, 2, 3, 4, 5, 6],
                8: [0, 1, 2, 3, 7, 4, 5, 6],
            };
        },
        nextSectionNumber(state, getters, rootState) {
            if (getters.isMainSectionOpened) {
                // main section is "roundabout"
                if (state.activeSectionNumber === undefined) {
                    return 0;
                } else {
                    const positionsRow = getters.sectionPositions[state.sections.length];
                    const indexOfCurrentSection = positionsRow.indexOf(getters.activeSectionNumber);
                    if (indexOfCurrentSection === (positionsRow.length - 1)) {
                        return 0;
                    } else {
                        return positionsRow[indexOfCurrentSection + 1];
                    }
                }
            } else {
                // from zero then since just sections in row
                if ((state.activeSectionNumber === undefined) || (state.activeSectionNumber === (state.sections.length - 1))) {
                    return 0;
                } else {
                    return state.activeSectionNumber + 1;
                }
            }

        },
        prevSectionNumber(state, getters, rootState) {
            if (getters.isMainSectionOpened) {
                if (state.activeSectionNumber === undefined) {
                    return 0;
                } else {
                    const positionsRow = getters.sectionPositions[state.sections.length];
                    const indexOfCurrentSection = positionsRow.indexOf(getters.activeSectionNumber);
                    if (indexOfCurrentSection === 0) {
                        return positionsRow[positionsRow.length - 1];
                    } else {
                        return positionsRow[indexOfCurrentSection - 1];
                    }
                }
            } else {
                // from zero then since just sections in row
                if (state.activeSectionNumber === undefined) {
                    return 0;
                } else {
                    if (state.activeSectionNumber === 0) {
                        return state.sections.length - 1;
                    } else {
                        return state.activeSectionNumber - 1;
                    }
                }
            }
        },
        sectionsAsObject(state, getters, rootState) {
            const POSITIONS = getters.sectionPositions;
            const sectionsList = getters.sections;
            const sectionsObject = {};
            sectionsObject['current_section'] = sectionsList[0];
            for (let i = 0; i < sectionsList.length; i++) {
                const pos = Math.min(sectionsList.length, 8);
                sectionsObject['' + POSITIONS[pos][i]] = sectionsList[i];
            }
            return sectionsObject;
        },
        slides(state, getters, rootState) {
            return state.slides;
        },
        currentSection(state, getters, rootState) {
            return state.currentSection;
        },
        activeSlide(state, getters, rootState) {
            return state.activeSlide;
        },
        draggedSlide(state, getters, rootState) {
            return state.draggedSlide;
        },
        underDragSlide(state, getters, rootState) {
            return state.underDragSlide;
        },
        draggedSection(state, getters, rootState) {
            return state.draggedSection;
        },
        underDragSection(state, getters, rootState) {
            return state.underDragSection;
        },
        isLastSlideActive(state, getters, rootState) {
            return getters.slides.indexOf(getters.activeSlide) === (getters.slides.length - 1);
        },
        isActiveSlideEmpty(state, getters, rootState) {
            return (getters.activeSlide && !getters.activeSlide.media_content && !getters.activeSlide.caption && !getters.activeSlide.description && !getters.activeSlide.audio_caption);
        },
        wikiEditor(state, getters, rootState) {
            return state.wikiEditor;
        },
        assistant(state, rootState, rootGetters) {
            const data = {};
            const CurrentUser = new CurrentUserClass();
            if (state.wikiEditor) {
                if (state.wikiEditor.id) {
                    if (state.wikiEditor.id !== CurrentUser.id) {
                        data.assistant = state.wikiEditor.id;
                    }
                } else if (state.wikiEditor.name) {
                    data.assistant_name = state.wikiEditor.name;
                }
                if (state.wikiEditor.role) {
                    data.assistant_role = state.wikiEditor.role;
                }
            }
            return data;
        },
    },
    actions: {
        changeWikiTemplate({
            state,
            commit
        }, wikiId) {
            return new Promise((res, rej) => {
                const WikiResource = Vue.getAngularModule('WikiResource');
                const WikiTemplatesResource = Vue.getAngularModule('WikiTemplatesResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                $rootScope.show_dimmer();
                const wikiState = WikiResource.get_wiki_data_for_template_applying(wikiId);
                const availableTemplates = WikiTemplatesResource.get_available_wiki_templates();

                function applyTemplate(template) {
                    $rootScope.show_dimmer();
                    WikiResource.apply_template_to_wiki(wikiId, template.id)
                        .then(resp => {
                            Vue.notifications.success('Applied');
                            res(template);
                        }, err => {
                            Vue.notifications.error(err || 'Error');
                            rej();
                        })
                        .finally($rootScope.hide_dimmer);
                }

                Promise.all([wikiState, availableTemplates])
                    .then(([wikiStateResponse, availableTemplatesResponse]) => {
                        $rootScope.hide_dimmer();
                        const {
                            vm,
                            popupComponent
                        } = simplePopupFactory.mount_vue_popup(ApplyTemplateToWikiPopup);
                        return popupComponent.show(wikiStateResponse.data.sections_count, wikiStateResponse.data.slides_count, availableTemplatesResponse.data)
                            .then(template => {
                                applyTemplate(template);
                            }, () => {
                                rej();
                            });
                    }, err => {
                        rej();
                        Vue.notifications.error(err || 'Error');
                        $rootScope.hide_dimmer();
                    });
            });
        },
        createWiki({
            state,
            commit
        }) {
            return new Promise((res, rej) => {
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                const WikiTemplatesResource = Vue.getAngularModule('WikiTemplatesResource');
                const WikisListResource = Vue.getAngularModule('WikisListResource');
                const WikiResource = Vue.getAngularModule('WikiResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                let dependentsListPromise;
                if (new CurrentUserClass().is_buddy()) {
                    dependentsListPromise = new Promise((res, rej) => {
                        WikisListResource.get_all_dependents_list()
                            .then(resp => {
                                res(resp.data);
                            }, err => {
                                rej();
                            });
                    });
                } else {
                    dependentsListPromise = new Promise((res, rej) => {
                        res([]);
                    });
                }
                const templatesPromise = new Promise((res, rej) => {
                    WikiTemplatesResource.get_available_wiki_templates()
                        .then(resp => {
                            res(resp.data);
                        }, err => {
                            rej();
                        });
                });
                Promise.all([dependentsListPromise, templatesPromise])
                    .then(([dependents, templates]) => {
                        $rootScope.hide_dimmer();
                        const {
                            vm,
                            popupComponent
                        } = simplePopupFactory.mount_vue_popup(CreateNewWikiPopup);
                        popupComponent.show(templates, dependents)
                            .then(({
                                name,
                                template,
                                description,
                                forUser,
                                show_content_counters
                            }) => {
                                $rootScope.show_dimmer();
                                WikiResource.create_new_wiki({
                                    name,
                                    description,
                                    show_content_counters,
                                    template: template ? template.id : undefined,
                                    for_user: forUser ? forUser.id : undefined,
                                })
                                    .then(resp => {
                                        $rootScope.hide_dimmer();
                                        res(resp.data.id);
                                    }, err => {
                                        Vue.notifications.error(err || 'Error');
                                        $rootScope.hide_dimmer();
                                    });
                            })
                            .catch((err) => {
                                rej();
                            });
                    }, err => {
                        rej();
                    })
                    .finally($rootScope.hide_dimmer);
            });
        },
        loadMainSections({
            state,
            commit
        }) {
            commit('setSections', []);
            commit('setCurrentSection', undefined);
            commit('setActiveSectionNumber', undefined);
            const WikiResource = Vue.getAngularModule('WikiResource');
            return WikiResource.retrieve_sections_as_list(state.accessParams.wikiId, state.accessParams.accessCode, state.accessParams.accessToken)
                .then(resp => {
                    commit('setSlides', []);
                    commit('setSections', resp.data.sections);
                    commit('setCurrentLevel', LEVELS.MAIN);
                }, err => {
                    Raven.captureException(new Error('wiki_page.store.loadMainSections'), {
                        level: 'warning',
                        extra: err
                    });
                    Vue.notifications.error(err || 'Error');
                });
        },
        loadSubsection({
            state,
            commit
        }, sectionId) {
            commit('setSections', []);
            commit('setActiveSlide', undefined);
            commit('setActiveSectionNumber', undefined);
            const WikiSectionResource = Vue.getAngularModule('WikiSectionResource');
            return WikiSectionResource.retrieve_child_sections(sectionId, state.accessParams.accessCode, state.accessParams.accessToken)
                .then(resp => {
                    commit('setSections', resp.data.sections);
                    commit('setSlides', []);
                    commit('setCurrentLevel', LEVELS.SUB);
                    commit('setCurrentSection', resp.data.current_section);
                }, err => {
                    Raven.captureException(new Error('wiki_page.store.loadSubsection'), {
                        level: 'warning',
                        extra: err
                    });
                    Vue.notifications.error(err || 'Error');
                });
        },
        loadSectionDetail({
            state,
            commit,
            getters
        }, {
            sectionId,
            slideId
        }) {
            commit('setSections', []);
            commit('setActiveSlide', undefined);
            const WikiSectionResource = Vue.getAngularModule('WikiSectionResource');
            return WikiSectionResource.retrieve_section_and_slides(sectionId, state.accessParams.accessCode, state.accessParams.accessToken)
                .then(resp => {
                    commit('setCurrentLevel', LEVELS.DETAIL);
                    commit('setCurrentSection', resp.data.current_section);
                    commit('setSlides', resp.data.slides);
                    let slideToBeActiveByDefault;
                    if (slideId) {
                        slideToBeActiveByDefault = getters.slides.filter(s => s.id === parseInt(slideId))[0];
                    }
                    if (!slideToBeActiveByDefault) {
                        slideToBeActiveByDefault = getters.slides[0];
                    }
                    commit('setActiveSlide', slideToBeActiveByDefault);
                }, err => {
                    Vue.raven.endpointException('wiki_page.store.loadSectionDetail', err);
                });
        },

        async loadReadReceipt({
            state,
            commit
        }, receiptCode) {
            commit('toggleReadReceiptMode', true);
            try {
                const { data: readReceipt } = await wiki_read_receipts_track_resource.load_read_receipt(state.accessParams.wikiId, receiptCode, state.accessParams.accessCode, state.accessParams.accessToken);
                commit('setReadReceipt', readReceipt);
            } catch (e) {
                commit('toggleReadReceiptMode', false);
                // Vue.notifications.error(e);
            }
        },

        async completeReadReceipt({
            state,
            commit
        }, comment) {
            Vue.loadingSpinner.show();
            try {
                await wiki_read_receipts_track_resource.complete_receipt(state.accessParams.wikiId, state.readReceipt.code, comment, state.accessParams.accessCode, state.accessParams.accessToken);
                commit('setReadReceipt', undefined);
                commit('toggleReadReceiptMode', false);
                Vue.notifications.success('Sent');
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.$broadcast('update_tasks_counters');
                return true;
            } catch (e) {
                Vue.notifications.error(e);
            } finally {
                Vue.loadingSpinner.hide();
            }
            return false;
        },

        getWikiMembershipState({
            state,
            commit
        }) {
            if (state.membershipLoaded) {
                return Promise.resolve();
            }
            return new Promise((res, rej) => {
                console.log('state.accessParams.accessToken', state.accessParams.accessToken);
                WikiResource().get_wiki_membership_state(state.accessParams.wikiId, state.accessParams.accessCode, state.accessParams.accessToken)
                    .then(resp => {
                        res(resp.data);
                        commit('setSubsectionDisabled', resp.data.subsections_disabled);
                        state.wikiOwner = resp.data.owner;
                        state.wikiName = resp.data.name;
                        state.wikiLogo = resp.data.logo;
                        state.wikiDescription = resp.data.description;
                        state.wikiShowContentCounters = resp.data.show_content_counters;
                        state.wikiEditable = resp.data.wiki_editable;
                        state.viewerState.isWikiAdmin = resp.data.is_wiki_admin;
                        state.viewerState.canBecomeWikiAdmin = resp.data.can_become_wiki_admin;
                        state.viewerState.canManageWikiTemplate = resp.data.can_manage_wiki_template;
                        state.isMainWiki = resp.data.is_main_wiki;
                        state.viewerState.canChangeWikiPriority = resp.data.can_change_wiki_priority;
                        state.viewerState.canSeeUpdateBadges = resp.data.can_see_update_budges;
                        state.wikiTemplate = resp.data.wiki_template;
                        state.isCommunityWiki = resp.data.is_community_wiki;
                        state.viewerState.personalMembershipId = resp.data.personal_membership_id;
                        state.membershipLoaded = true;
                        if (resp.data.wiki_visible) {
                            state.membershipAccessGranted = true;
                            state.membershipAccessError = false;
                        } else {
                            state.membershipAccessGranted = false;
                            state.membershipAccessError = true;
                        }
                    }, err => {
                        Vue.raven.endpointException('wiki_page.store.getWikiMembershipState', err);
                    });
            });
        },
        shareWikiOrSection({
            state,
            commit,
            getters
        }) {
            if (state.popupOpened || state.editMode) {
                return;
            }
            commit('setPopupOpened', true);
            const wikiShareFactory = Vue.getAngularModule('wikiShareFactory');
            if (getters.isMainSectionOpened) {
                wikiShareFactory.shareWiki(getters.accessParams.wikiId)
                    .finally(() => {
                        commit('setPopupOpened', false);
                    });
            } else {
                wikiShareFactory.shareSection(getters.currentSection.id)
                    .finally(() => {
                        commit('setPopupOpened', false);
                    });
            }
        },
        makeMeAdmin({
            state,
            commit,
            getters,
            dispatch
        }) {
            const $rootScope = Vue.getAngularModule('$rootScope');
            const WikiResource = Vue.getAngularModule('WikiResource');
            $rootScope.show_dimmer();
            return WikiResource.make_me_admin(getters.accessParams.wikiId)
                .then(resp => {
                    state.membershipLoaded = false;
                    return dispatch('getWikiMembershipState');
                }, err => {
                    Vue.raven.endpointException('wiki_page.store.makeMeAdmin', err);
                    Vue.notifications.error(err || 'Error');
                    $rootScope.hide_dimmer();
                });
        },
        showWikiLog({
            state,
            commit,
            getters
        }, payload) {
            const {
                vm,
                popupComponent,
                fEl
            } = simplePopupFactory.mount_vue_popup(WikiLogPopup);

            let slideId = undefined;

            if (payload?.source === 'fromCurrentSlide') {
                slideId = getters.activeSlide.id;
            }

            return popupComponent.show(state.accessParams.wikiId, getters.currentSection?.id, slideId)
                .catch(() => {
                })
                .finally(() => {
                    vm?.$destroy();
                    fEl?.focus();
                });
        },
        makeSubmenuFromSection({
            state,
            commit,
            getters
        }, section) {
            const $rootScope = Vue.getAngularModule('$rootScope');
            const createEditWikiSectionFactory = Vue.getAngularModule('createEditWikiSectionFactory');
            const WikiResource = Vue.getAngularModule('WikiResource');
            let exPosition = section.position;

            createEditWikiSectionFactory.main_options({
                caption: section.caption,
                is_submenu: true
            }, {
                header: 'Convert section into subsection',
                save_button: 'Convert',
                additional_info: `A new Section will be created. It will contain the section "${section.caption}" as a subsection.`,
                allow_create_subsections: true,
                create_subsection_switch_disabled: true
            })
                .then(sectionData => {
                    $rootScope.show_dimmer();
                    let dataToCreateSection = sectionData;
                    dataToCreateSection['include_sections'] = [section.id];
                    if (getters.isMainSectionOpened) {
                        dataToCreateSection.position = exPosition;
                    }
                    if (getters.currentSection) {
                        dataToCreateSection.parent_section = getters.currentSection.id;
                    }
                    return WikiResource.create_section(getters.accessParams.wikiId, dataToCreateSection, getters.assistant);
                })
                .then(resp => {
                    commit('replaceSection', {
                        sectionId: section.id,
                        updatedSection: resp.data.created
                    });
                    Vue.notifications.success('Converted');
                }, err => {
                    Vue.raven.endpointException('wiki_page.store.makeSubmenuFromSection', err);
                    Vue.notifications.error(err || 'Error');
                })
                .finally($rootScope.hide_dimmer);
        },
        editWiki({
            state,
            commit,
            getters,
            dispatch
        }) {
            let resolve, reject;
            const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
            const $rootScope = Vue.getAngularModule('$rootScope');
            const WikiResource = Vue.getAngularModule('WikiResource');
            const WikiTemplatesResource = Vue.getAngularModule('WikiTemplatesResource');
            const {
                vm,
                popupComponent
            } = simplePopupFactory.mount_vue_popup(WikiSettingsPopup);
            const createWikiTemplate = (wikiId, templateData) => {
                return new Promise((res, rej) => {
                    WikiTemplatesResource.create_template(wikiId, templateData)
                        .then(resp => {
                            res(resp.data);
                        }, err => {
                            Vue.raven.endpointException('wiki_page.store.editWiki.createWikiTemplate', err);
                            rej(err.data);
                        });
                });
            };

            const updateWikiTemplate = (id, templateData) => {
                return new Promise((res, rej) => {
                    WikiTemplatesResource.update_template(id, templateData)
                        .then(resp => {
                            res(resp.data);
                        }, err => {
                            Vue.raven.endpointException('wiki_page.store.editWiki.updateWikiTemplate', err);
                            rej(err.data);
                        });
                });
            };

            const deleteWikiTemplate = (id) => {
                return new Promise((res, rej) => {
                    WikiTemplatesResource.delete_template(id)
                        .then(resp => {
                            res();
                        }, err => {
                            Vue.raven.endpointException('wiki_page.store.editWiki.deleteWikiTemplate', err);
                            rej(err.data);
                        });
                });
            };

            const noAction = () => {
                return new Promise((res, rej) => {
                    res(state.wikiTemplate);
                });
            };

            let additionalAction = noAction;

            popupComponent.show(
                getters.wikiName,
                getters.wikiDescription,
                state.viewerState.canManageWikiTemplate,
                state.wikiTemplate,
                (!state.isMainWiki) && (!state.isCommunityWiki) && state.viewerState.canChangeWikiPriority,
                getters.wikiShowContentCounters,
                !state.isCommunityWiki
            )
                .then(res => {
                    if (res.action === 'save') {
                        $rootScope.show_dimmer();
                        if (!!res.templateData !== !!state.wikiTemplate) {
                            if (!!res.templateData) {
                                // need to create template
                                additionalAction = () => {
                                    return createWikiTemplate(getters.accessParams.wikiId, res.templateData);
                                };
                            } else {
                                // need to delete template
                                additionalAction = () => {
                                    return deleteWikiTemplate(state.wikiTemplate.id);
                                };
                            }
                        } else if (!!res.templateData) {
                            const needToUpdateTemplate = (o, n) => {
                                return (o.name !== n.name || o.description !== n.description || n.logo64 || o.logo && n.reset_logo || o.is_fixed !== n.is_fixed || o.is_top_level_fixed_only !== n.is_top_level_fixed_only);
                            };
                            if (needToUpdateTemplate(state.wikiTemplate, res.templateData)) {
                                additionalAction = () => {
                                    return updateWikiTemplate(state.wikiTemplate.id, res.templateData);
                                };
                            }
                        } else {
                            //no actions with template needed
                        }

                        WikiResource.update_main_wiki_settings(getters.accessParams.wikiId, res.data, getters.assistant)
                            .then(resp => {
                                    state.wikiName = res.data.name;
                                    state.wikiDescription = res.data.description;
                                    state.wikiShowContentCounters = res.data.show_content_counters;
                                    return additionalAction();
                                },
                                err => {
                                    Vue.raven.endpointException('wiki_page.store.editWiki', err);
                                    Vue.notifications.error(err || 'Error');
                                    reject();
                                })
                            .then(wikiTemplate => {
                                state.wikiTemplate = wikiTemplate;
                                Vue.notifications.success('Saved');
                                resolve('saved');
                            }, err => {
                                console.log(err);
                                Vue.notifications.error(err || 'Error');
                                reject();
                            })
                            .finally($rootScope.hide_dimmer);
                    }
                    if (res.action === 'make-main') {
                        $rootScope.show_dimmer();
                        WikiResource.make_wiki_main(getters.accessParams.wikiId, getters.assistant)
                            .then(resp => {
                                    Vue.notifications.success('Made main');
                                    resolve('saved');
                                    state.isMainWiki = true;
                                },
                                err => {
                                    Vue.notifications.error(err || 'Error');
                                })
                            .finally($rootScope.hide_dimmer);
                    }
                    if (res.action === 'delete') {
                        simplePopupFactory.show_popup(
                            'Delete Wiki',
                            'Warning! You are about to delete this whole Wiki. The wiki and all its content will be lost forever',
                            'Yes, delete Wiki',
                            'Cancel',
                            'btn-red'
                        )
                            .then(y => {
                                $rootScope.show_dimmer();
                                WikiResource.delete_wiki(getters.accessParams.wikiId, getters.assistant)
                                    .then(resp => {
                                            Vue.notifications.success('Deleted');
                                            resolve('deleted');
                                        },
                                        err => {
                                            Vue.raven.endpointException('wiki_page.store.editWiki', err);
                                            Vue.notifications.error(err || 'Error');
                                        })
                                    .finally($rootScope.hide_dimmer);
                            }, () => {
                            });
                    }
                }, () => {

                })
                .finally(() => {
                    if (vm) {
                        vm.$destroy();
                    }
                });
            return new Promise((res, rej) => {
                resolve = res;
                reject = rej;
            });
        },
        editSection({
            state,
            commit,
            getters,
            dispatch
        }, section) {
            const createEditWikiSectionFactory = Vue.getAngularModule('createEditWikiSectionFactory');
            const WikiSectionResource = Vue.getAngularModule('WikiSectionResource');
            const $rootScope = Vue.getAngularModule('$rootScope');
            const $q = Vue.getAngularModule('$q');
            const sectionIsEmptySubmenu = section.is_submenu && section.child_sections_count === 0; // if submenu is empty it could be converted to usual section
            const sectionIsSubmenu = section.is_submenu;
            const isZeroSection = getters.isMainSectionOpened && section.position === 0; // zero(central) section cannot be submenu;
            const maxLevelReached = getters.currentSection && getters.currentSection.parent_section;

            let sectionWasMadeSubmenu = false;

            createEditWikiSectionFactory.main_options(section, {
                header: 'Edit Wiki Section',
                save_button: 'Save',
                cancel_button: 'Cancel',
                allow_delete: (section.position !== 0) && (!section.is_fixed),
                allow_create_subsections: !state.subsectionsDisabled && !isZeroSection && (sectionIsEmptySubmenu || (!maxLevelReached && !sectionIsSubmenu)),
                allow_change_name: !section.is_fixed
            })
                .then(result => {
                    if (result === 'delete') {
                        dispatch('deleteSection', section);
                        return new Promise(() => {
                        });
                    }
                    $rootScope.show_dimmer();
                    sectionWasMadeSubmenu = result.is_submenu && !sectionIsSubmenu;
                    return WikiSectionResource.edit_section(section.id, result, getters.assistant);
                }, () => new Promise(() => {
                }))
                .then(resp => {
                    commit('replaceSection', {
                        sectionId: section.id,
                        updatedSection: resp.data
                    });
                    Vue.notifications.success('Saved');
                    $rootScope.hide_dimmer();
                }, err => {
                    Vue.raven.endpointException('wiki_page.store.editSection', err);
                    Vue.notifications.error(err || 'Error');
                    $rootScope.hide_dimmer();
                    return new Promise(() => {
                    });
                })
                .then(r => {
                    if (sectionWasMadeSubmenu) {
                        dispatch('makeSubmenuFromSection', section);
                    }
                });
        },
        createSection({
            state,
            commit,
            getters
        }) {
            const createEditWikiSectionFactory = Vue.getAngularModule('createEditWikiSectionFactory');
            const $rootScope = Vue.getAngularModule('$rootScope');
            const notifications = Vue.getAngularModule('notifications');
            const WikiResource = Vue.getAngularModule('WikiResource');
            const maxLevelReached = getters.currentSection && getters.currentSection.parent_section;

            createEditWikiSectionFactory.main_options({},
                { allow_create_subsections: !state.subsectionsDisabled && !maxLevelReached }
            )
                .then(sectionData => {
                    $rootScope.show_dimmer();
                    let dataToCreateSection = sectionData;
                    if (getters.currentSection) {
                        dataToCreateSection['parent_section'] = getters.currentSection.id;
                    }
                    return WikiResource.create_section(getters.accessParams.wikiId, dataToCreateSection, getters.assistant);
                }, () => new Promise(() => {
                }))
                .then(resp => {
                    commit('addSection', resp.data.created);
                    notifications.success('Created');
                }, err => {
                    Vue.raven.endpointException('wiki_page.store.createSection', err);
                    notifications.error(err || 'Error');
                })
                .finally($rootScope.hide_dimmer);
            captionPlayerBus.$emit('stopSound');
        },
        deleteSection({
            state,
            commit,
            getters
        }, section) {
            const $rootScope = Vue.getAngularModule('$rootScope');
            const $q = Vue.getAngularModule('$q');
            const notifications = Vue.getAngularModule('notifications');
            const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
            const WikiSectionResource = Vue.getAngularModule('WikiSectionResource');
            simplePopupFactory.show_popup('Delete Wiki section', 'Are you sure you want to delete this Wiki section?', 'Delete', 'Cancel', 'btn-red')
                .then(yes => {
                    $rootScope.show_dimmer();
                    return WikiSectionResource.delete_section(section.id, getters.assistant);
                }, () => new Promise(() => {
                }))
                .then(resp => {
                    commit('deleteSection', section);
                    notifications.success('Deleted');
                }, err => {
                    Vue.raven.endpointException('wiki_page.store.deleteSection', err);
                    notifications.error(err || 'Error');
                })
                .finally($rootScope.hide_dimmer);
            captionPlayerBus.$emit('stopSound');
        },
        onSwitchSections({
            state,
            commit,
            getters
        }, {
            from: fromSection,
            to: toSection
        }) {
            const $rootScope = Vue.getAngularModule('$rootScope');
            const WikiSectionResource = Vue.getAngularModule('WikiSectionResource');
            const WikiResource = Vue.getAngularModule('WikiResource');
            const notifications = Vue.getAngularModule('notifications');
            const createEditWikiSectionFactory = Vue.getAngularModule('createEditWikiSectionFactory');
            const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');

            const switchSections = (fromSection, toSection) => {
                $rootScope.show_dimmer();
                WikiSectionResource.set_new_section_position(fromSection.id, { position: toSection.position }, getters.assistant
                )
                    .then(resp => {
                        const indexOfFromSection = getters.sections.indexOf(fromSection);
                        const indexOfToSection = getters.sections.indexOf(toSection);
                        Vue.set(state.sections, indexOfFromSection, toSection);
                        Vue.set(state.sections, indexOfToSection, fromSection);
                        notifications.success('Switched');
                    }, err => {
                        Vue.raven.endpointException('wiki_page.store.onSwitchSections.switchSections', err);
                        notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
                captionPlayerBus.$emit('stopSound');
            };

            const moveSection = (fromSection, toSection) => {
                $rootScope.show_dimmer();
                WikiSectionResource.set_section_parent(fromSection.id, toSection.id, getters.assistant)
                    .then(resp => {
                        commit('deleteSection', fromSection);
                        if (toSection.visible_content_count !== undefined) {
                            toSection.visible_content_count += 1;
                        }
                        notifications.success('Moved');
                    }, err => {
                        Vue.raven.endpointException('wiki_page.store.loadSectionDetail.moveSection', err);
                        notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
                captionPlayerBus.$emit('stopSound');
            };

            const resetSection = (fromSection) => { // move section to main menu
                $rootScope.show_dimmer();
                WikiSectionResource.reset_section_parent(fromSection.id, getters.assistant)
                    .then(resp => {
                        getters.currentSection.child_sections_count -= 1;
                        notifications.success('Moved');
                    }, err => {
                        Vue.raven.endpointException('wiki_page.store.loadSectionDetail.resetSection', err);
                        notifications.error(err || 'Error');
                    }).finally($rootScope.hide_dimmer);
                captionPlayerBus.$emit('stopSound');
            };
            const unitSectionsInNewSubsection = (fromSection, toSection) => {
                captionPlayerBus.$emit('stopSound');
                return createEditWikiSectionFactory.main_options({
                    caption: toSection.caption,
                    is_submenu: true
                }, {
                    allow_create_subsections: !this.CURRENT_SECTION,
                    create_subsection_switch_disabled: true
                })
                    .then(sectionData => {
                        $rootScope.show_dimmer();
                        let dataToCreateSection = Object.assign(sectionData, {
                            position: toSection.position,
                            include_sections: [toSection.id, fromSection.id],
                            parent_section: getters.currentSection ? getters.currentSection.id : undefined
                        });
                        return WikiResource.create_section(getters.accessParams.wikiId, dataToCreateSection, getters.assistant);
                    }, () => new Promise(() => {
                    }))
                    .then(resp => {
                        Vue.set(state.sections, state.sections.indexOf(toSection), resp.data.created);
                        if (resp.data.moved) {
                            Vue.set(state.sections, state.sections.indexOf(fromSection), undefined);
                        }
                        notifications.success('Created');
                    })
                    .finally($rootScope.hide_dimmer);
            };

            const maxLevelReached = getters.currentSection && getters.currentSection.parent_section;
            const allowCreateSubsections = !state.subsectionsDisabled && !maxLevelReached;
            const toSectionIsSubmenu = toSection.is_submenu;
            const fromSectionIsSubmenu = fromSection.is_submenu;
            if (allowCreateSubsections) {
                if (toSectionIsSubmenu && !fromSectionIsSubmenu) { // move into or switch possible;
                    simplePopupFactory.showSelectFromActionsPopup([
                        {
                            classes: 'fa fa-retweet',
                            label: 'Switch',
                            action: 'switch'
                        },
                        {
                            classes: 'fa fa-level-down',
                            label: 'Move',
                            action: 'move'
                        }
                    ])
                        .then(action => {
                            if (action === 'move') {
                                moveSection(fromSection, toSection);
                            } else if (action === 'switch') {
                                switchSections(fromSection, toSection);
                            }
                        })
                        .catch(console.error);
                } else if (!toSectionIsSubmenu && !fromSectionIsSubmenu && !maxLevelReached) {
                    simplePopupFactory.showSelectFromActionsPopup([
                        {
                            classes: 'fa fa-retweet',
                            label: 'Switch',
                            action: 'switch'
                        },
                        {
                            classes: 'fa fa-plus-circle',
                            label: 'Create New',
                            action: 'create'
                        }
                    ])
                        .then(action => {
                            console.log(action);
                            if (action === 'create') {
                                unitSectionsInNewSubsection(fromSection, toSection);
                            } else if (action === 'switch') {
                                switchSections(fromSection, toSection);
                            }
                        })
                        .catch(console.error);
                } else {
                    switchSections(fromSection, toSection);
                }
            } else {
                switchSections(fromSection, toSection);
            }

        },

        removeActiveSlide({
            state,
            commit,
            getters
        }) {
            const $rootScope = Vue.getAngularModule('$rootScope');
            const $q = Vue.getAngularModule('$q');
            const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
            const WikiSlideResource = Vue.getAngularModule('WikiSlideResource');

            simplePopupFactory.show_popup('Remove Page', 'Are you sure you want to remove this Page?', 'Remove', 'Cancel', 'btn-red')
                .then(yes => {
                    $rootScope.show_dimmer();
                    return WikiSlideResource.delete_slide(getters.activeSlide.id, getters.assistant);
                }, () => new Promise(() => {
                }))
                .then(resp => {
                    commit('removeActiveSlide');
                }, err => {
                    Vue.raven.endpointException('wiki_page.store.removeActiveSlide', err);
                })
                .catch(console.error)
                .finally($rootScope.hide_dimmer);
        },
        createSlide({
            state,
            commit,
            getters
        }) {
            const $rootScope = Vue.getAngularModule('$rootScope');
            const $q = Vue.getAngularModule('$q');
            const WikiSlideResource = Vue.getAngularModule('WikiSlideResource');
            $rootScope.show_dimmer();
            WikiSlideResource.create_slide(getters.currentSection.id, getters.assistant)
                .then(resp => {
                    Vue.notifications.success('Created');
                    commit('addNewSlide', resp.data);
                }, err => {
                    Vue.raven.endpointException('wiki_page.store.createSlide', err);
                    Vue.notifications.error(err || 'Error');
                })
                .finally($rootScope.hide_dimmer);
        },
        updateSlide({
            state,
            commit,
            getters
        }, {
            slide,
            updatedData
        }) {
            const $rootScope = Vue.getAngularModule('$rootScope');
            const WikiSlideResource = Vue.getAngularModule('WikiSlideResource');
            $rootScope.show_dimmer();
            return WikiSlideResource.update_slide(slide.id, { ...updatedData }, getters.assistant)
                .then(resp => {
                    commit('replaceSlide', {
                        slideId: slide.id,
                        updatedSlide: resp.data
                    });
                    Vue.notifications.success('Updated');
                }, err => {
                    Vue.raven.endpointException('wiki_page.store.updateSlide', err);
                    Vue.notifications.error(err || 'Error');
                })
                .finally(() => {
                    $rootScope.hide_dimmer();
                });
        },
        editSlideTitle({
            state,
            commit,
            getters,
            dispatch
        }, slide) {
            const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
            const {
                vm,
                popupComponent
            } = simplePopupFactory.mount_vue_popup(WikiCreateEditSlidePopup);
            const popupHeader = slide.caption ? 'Edit Title' : 'Add Title';
            popupComponent.editSlide(slide, 'TITLE', popupHeader).then(updatedData => {
                if (vm) {
                    vm.$destroy();
                }
                dispatch('updateSlide', {
                    slide,
                    updatedData
                });
            }, () => {
            });
        },
        editSlideDescription({
            state,
            commit,
            getters,
            dispatch
        }, slide) {
            const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
            const {
                vm,
                popupComponent
            } = simplePopupFactory.mount_vue_popup(WikiCreateEditSlidePopup);
            const popupHeader = slide.description ? 'Edit Description' : 'Add Description';
            popupComponent.editSlide(slide, 'DESCRIPTION', popupHeader).then(updatedData => {
                if (vm) {
                    vm.$destroy();
                }
                dispatch('updateSlide', {
                    slide,
                    updatedData
                });
            }, () => {
            });
        },
        editSlideMedia({
            state,
            commit,
            getters,
            dispatch
        }, slide) {
            const addMedia = Vue.getAngularModule('addMedia');
            addMedia.add_media({
                allow_add_from_library: true,
                types: ['video', 'audio', 'image'],
                max_count: 1,
                allow_remove: slide.media_content,
            }, {
                'default_callback': (media_list) => {
                    slide.media_content = media_list[0];
                    dispatch('updateSlide', {
                        slide,
                        updatedData: slide
                    });
                },
                'cancel_callback': (res) => {
                    if (res === 'remove') {
                        slide.media_content = undefined;
                        dispatch('updateSlide', {
                            slide,
                            updatedData: slide
                        });
                    }
                }
            });
        },
        slideDropped({
            state,
            commit,
            getters,
            dispatch
        }) {
            return new Promise((res, rej) => {
                if (getters.draggedSlide && getters.underDragSlide) {
                    if (getters.underDragSlide.is_fixed) {
                        Vue.notifications.error('Page is fixed');
                        res();
                        return;
                    }
                    const $rootScope = Vue.getAngularModule('$rootScope');
                    const WikiSlideResource = Vue.getAngularModule('WikiSlideResource');
                    $rootScope.show_dimmer();
                    const underDragSlideIndex = state.slides.indexOf(getters.underDragSlide);
                    const draggedSlideIndex = state.slides.indexOf(getters.draggedSlide);
                    if (underDragSlideIndex > draggedSlideIndex) {
                        //moved right
                        state.slides.splice(underDragSlideIndex + 1, 0, getters.draggedSlide);
                        state.slides.splice(draggedSlideIndex, 1);
                    } else {
                        //moved left
                        state.slides.splice(underDragSlideIndex, 0, getters.draggedSlide);
                        state.slides.splice(draggedSlideIndex + 1, 1);
                    }
                    const slidesIdList = state.slides.map(s => s.id);
                    return WikiSlideResource.reorder_slides(getters.currentSection.id, slidesIdList, getters.assistant)
                        .then(resp => {
                            Vue.notifications.success('Reordered');
                        }, err => {
                            Vue.raven.endpointException('wiki_page.store.slideDropped', err);
                            Vue.notifications.error(err || 'Error');
                        })
                        .finally(() => {
                            res();
                            $rootScope.hide_dimmer();
                        });
                } else {
                    res();
                }

            });

        },
        sectionDropped({
            state,
            commit,
            getters,
            dispatch
        }) {
            return new Promise((res, rej) => {
                if (getters.draggedSection && getters.underDragSection) {
                    const $rootScope = Vue.getAngularModule('$rootScope');
                    const WikiSectionResource = Vue.getAngularModule('WikiSectionResource');
                    $rootScope.show_dimmer();
                    const underDragSectionIndex = state.sections.indexOf(getters.underDragSection);
                    const draggedSectionIndex = state.sections.indexOf(getters.draggedSection);
                    if (underDragSectionIndex > draggedSectionIndex) {
                        //moved right
                        state.sections.splice(underDragSectionIndex + 1, 0, getters.draggedSection);
                        state.sections.splice(draggedSectionIndex, 1);
                    } else {
                        //moved left
                        state.sections.splice(underDragSectionIndex, 0, getters.draggedSection);
                        state.sections.splice(draggedSectionIndex + 1, 1);
                    }
                    const sectionsIdList = state.sections.map(s => s.id);
                    return WikiSectionResource.reorder_subsections(getters.currentSection.id, sectionsIdList, getters.assistant)
                        .then(resp => {
                            Vue.notifications.success('Reordered');
                        }, err => {
                            Vue.raven.endpointException('wiki_page.store.sectionDropped', err);
                            Vue.notifications.error(err || 'Error');
                        })
                        .finally(() => {
                            res();
                            $rootScope.hide_dimmer();
                        });
                } else {
                    res();
                }

            });
        },
        async moveSections({
            state,
            commit,
            getters,
            dispatch
        }, currentSectionId) {
            if (getters.selectedToMoveSectionsIdList.length) {
                const popupConfig = {
                    header: 'Move section',
                    info: 'Select Wiki section to move selected sections into. Please note that you can only move to another section if it supports subsections.',
                    button_yes: 'Move Sections',
                    long_text: true,
                    button_no: 'Cancel',
                    empty: 'No possible target Sections found',
                    no_border_radius_on_default_avatar: true,
                };
                const targetSectionId = await selectFactory().endpoint_selector(
                    _.partial(
                        WikiSectionResource().get_target_sections_for_sections_move, currentSectionId
                    ),
                    false,
                    popupConfig
                );
                try {
                    Vue.loadingSpinner.show();
                    await WikiSectionResource().move_sections_to_subsection(targetSectionId, getters.selectedToMoveSectionsIdList, getters.assistant);
                    commit('deleteSectionsList', getters.selectedToMoveSectionsIdList);
                    commit('clearSelectedToMoveSectionsIdList');
                    Vue.notifications.success(`Section${getters.selectedToMoveSectionsIdList.length > 1 ? 's' : ''} moved`);
                } catch (e) {
                    Vue.notifications.error(e || 'Error');
                }
                Vue.loadingSpinner.hide();
            } else {
                Vue.notifications.warning('Select Sections to move please.');
            }
        },
        async moveSlides({
            state,
            commit,
            getters,
            dispatch
        }, currentSectionId) {
            if (getters.selectedToMoveSlidesIdList.length) {
                const popupConfig = {
                    header: 'Move slides',
                    info: 'Select Wiki section to move selected slides into',
                    button_yes: 'Move Slides',
                    button_no: 'Cancel',
                    empty: 'No possible target Sections found',
                    no_border_radius_on_default_avatar: true,
                };
                const targetSectionId = await selectFactory().endpoint_selector(
                    _.partial(
                        WikiSectionResource().get_target_sections_for_slides_move, currentSectionId
                    ),
                    false,
                    popupConfig);
                try {
                    Vue.loadingSpinner.show();
                    await WikiSectionResource().move_slides_to_section(targetSectionId, getters.selectedToMoveSlidesIdList, getters.assistant);
                    Vue.notifications.success(`Slide${getters.selectedToMoveSlidesIdList.length > 1 ? 's' : ''} moved`);
                    commit('deleteSlidesList', getters.selectedToMoveSlidesIdList);
                    commit('clearSelectedToMoveSlidesIdList');
                } catch (e) {
                    Vue.notifications.error(e || 'Error');
                }
                Vue.loadingSpinner.hide();
            } else {
                Vue.notifications.warning('Select Slides to move please.');
            }
        },
        async moveSectionsOrSlides({
            state,
            commit,
            getters,
            dispatch
        }, currentSectionId) {
            if (state.currentLevel === LEVELS.SUB) {
                return await dispatch('moveSections', currentSectionId);
            } else if (state.currentLevel === LEVELS.DETAIL) {
                return await dispatch('moveSlides', currentSectionId);
            }
        },
        uploadFilesByDragDrop({
            state,
            commit,
            getters,
            dispatch
        }, files) {
            const addMedia = Vue.getAngularModule('addMedia');
            addMedia.addImagesByDragDrop(files)
                .then(mediaItems => {
                    dispatch('createMultipleSlidesFromMediaItems', { mediaItems });
                });
        },
        createMultipleSlidesFromMediaItems({
            state,
            commit,
            getters,
            dispatch
        }, { mediaItems }) {
            const WikiSlideResource = Vue.getAngularModule('WikiSlideResource');
            const $rootScope = Vue.getAngularModule('$rootScope');
            $rootScope.show_dimmer();
            let slideToReplaceId;
            if (getters.isLastSlideActive && getters.isActiveSlideEmpty) {
                slideToReplaceId = getters.activeSlide.id;
            }
            WikiSlideResource.create_slides_from_media(getters.currentSection.id, mediaItems.map(mi => mi.id), slideToReplaceId, getters.assistant)
                .then(resp => {
                    for (let slide of resp.data.created_slides) {
                        state.slides.push(slide);
                    }
                    if (resp.data.deleted_slide) {
                        state.slides = state.slides.filter(s => s.id !== resp.data.deleted_slide);
                    }
                    state.activeSlide = state.slides[state.slides.length - 1];
                    Vue.notifications.success('Created');
                }, err => {
                    Vue.raven.endpointException('wiki_page.store.createMultipleSlidesFromMediaItems', err);
                    Vue.notifications.error('Error');
                })
                .finally($rootScope.hide_dimmer);
        },
        batchUpload({
            state,
            commit,
            getters,
            dispatch
        }) {
            const addMedia = Vue.getAngularModule('addMedia');
            addMedia.add_media({
                allow_add_from_library: true,
                allow_capture: false,
                type: 'all',
                max_count: 30,
                types: ['video', 'audio', 'image']
            }, {
                'default_callback': (mediaItems) => {
                    dispatch('createMultipleSlidesFromMediaItems', { mediaItems });
                }
            });
        },
        addSlideAttachment({
            state,
            commit,
            getters,
            dispatch
        }) {
            const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
            const activityPostLocationsFactory = Vue.getAngularModule('activityPostLocationsFactory');
            const WikiSlideResource = Vue.getAngularModule('WikiSlideResource');
            const addMedia = Vue.getAngularModule('addMedia');
            const $rootScope = Vue.getAngularModule('$rootScope');

            let attachLocation = () => { // hidden push. probably not best way.
                new activityPostLocationsFactory.UserActivityPostLocations(
                    getters.activeSlide.id,
                    [],
                    true,
                    WikiSlideResource
                ).add_place(getters.assistant)
                    .then(location => {
                        getters.activeSlide.attached_locations.push(location);
                        Vue.notifications.success('Attached');
                    }, err => {
                        Vue.raven.endpointException('wiki_page.store.addSlideAttachment', err);
                    });
            };

            const attachFile = () => {
                addMedia.add_media({
                    allow_add_from_library: true,
                    type: 'document',
                    max_count: 1,
                }, {
                    'default_callback': (mediaItemsList) => {
                        $rootScope.show_dimmer();
                        WikiSlideResource.attach_document(getters.activeSlide.id, mediaItemsList[0].id, getters.assistant)
                            .then(resp => {
                                getters.activeSlide.attached_documents.push(resp.data);
                                Vue.notifications.success('Attached');
                            }, err => {
                                Vue.raven.endpointException('wiki_page.store.addSlideAttachment', err);
                                Vue.notifications.error(err || 'Error');
                            })
                            .finally($rootScope.hide_dimmer);
                    }
                });
            };

            const attachLink = () => {
                const {
                    fEl,
                    popupComponent,
                    vm
                } = simplePopupFactory.mount_vue_popup(AddWebsiteLinkPopup);
                popupComponent.show()

                    .then(res => {
                        let {
                            name,
                            link: url
                        } = res;
                        $rootScope.show_dimmer();
                        WikiSlideResource.attach_link(getters.activeSlide.id, url, name, getters.assistant)
                            .then(resp => {
                                    getters.activeSlide.attached_links.push(resp.data);
                                    Vue.notifications.success('Attached');
                                },
                                err => {
                                    Vue.raven.endpointException('wiki_page.store.addSlideAttachment', err);
                                    Vue.notifications.error(err || 'Error');
                                })
                            .finally($rootScope.hide_dimmer);
                    }, () => {
                    })
                    .finally(() => {
                        fEl?.focus();
                        vm?.$destroy();
                    });
            };

            const {
                vm,
                popupComponent
            } = simplePopupFactory.mount_vue_popup(WikiAddAttachmentTypeSelectPopup);
            popupComponent.show()
                .then(selected => {
                    ({
                        'file': attachFile,
                        'link': attachLink,
                        'location': attachLocation,

                    })[selected]();
                }, () => {
                })
                .finally(() => {
                    if (vm) {
                        vm.$destroy();
                    }
                });

        },
        deleteSlideAttachment({
            state,
            commit,
            getters,
            dispatch
        }, {
            attachmentType,
            attachmentId
        }) {
            const WikiSlideResource = Vue.getAngularModule('WikiSlideResource');
            const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
            const $rootScope = Vue.getAngularModule('$rootScope');

            const resources = {
                'file': WikiSlideResource.delete_attachment,
                'link': WikiSlideResource.delete_attached_link,
                'location': WikiSlideResource.unlink_location,
            };

            const fields = {
                'file': 'attached_documents',
                'link': 'attached_links',
                'location': 'attached_locations',
            };

            simplePopupFactory.show_popup('Remove attachment', 'Are you sure you want to remove this attachment?', 'Remove', 'Cancel', 'btn-red')
                .then(y => {
                    $rootScope.show_dimmer();
                    resources[attachmentType](getters.activeSlide.id, attachmentId, getters.assistant)
                        .then(resp => {
                            getters.activeSlide[fields[attachmentType]] = getters.activeSlide[fields[attachmentType]].filter(a => a.id !== attachmentId);
                            Vue.notifications.success('Removed');
                        }, err => {
                            Vue.raven.endpointException('wiki_page.store.deleteSlideAttachment', err);
                            Vue.notifications.error(err || 'Error');
                        })
                        .finally($rootScope.hide_dimmer);
                }, n => {
                });
        }
    },
};
