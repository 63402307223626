import CatchFocusMixin from "../../../../vue/mixins/CatchFocusMixin";

const TOP_BOTTOM_ROWS_POSITION_CORRECTOR = 30; //px

const WikiSectionMixin = {
    mixins: [CatchFocusMixin],
    props: {
        height: {
            type: Number,

        },
        width: {
            type: Number
        },
        side: {
            type: String,
            validator: value => {
                return ['left', 'right', 'center'].indexOf(value) !== -1;
            }
        },
        row: {
            type: String,
            default: 'center',
            validator: value => {
                return ['top', 'bottom', 'center'].indexOf(value) !== -1;
            }
        },
        pentagramMode: {
            type: Boolean,
            default: true
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        showCounters: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        isTextOnSide() {
            return (this.width > this.height + 40) && this.side !== 'center';
        },
        $_basicSize() {
            return Math.min(this.width / 2, this.height);
        },
        $_bigCircleSize() {
            const basicSize = Math.min(this.width, this.height);
            const realSize = this.containerWidth >= 738 ? basicSize * 1.1 : basicSize;
            return realSize - 3;
        },
        $_smallCircleSize() {
            return Math.min(this.$_basicSize, this.height * 0.8) - 3;
        },
        $_circleSize() {
            return this.$_smallCircleSize;
        },
        $_bottomSectionCorrection() {
            if (this.pentagramMode) {
                return Math.min(200, this.$_circleSize - 40);
            } else {
                return 40;
            }
        },
        containerSizeOffsetStyle() {
            let resultStyle = {
                width: `${this.width}px`,
                height: `${this.height}px`,
                position: 'relative'
            };
            if (this.pentagramMode) {
                if (this.row === 'top') {
                    if (this.side === 'right') {
                        resultStyle['left'] = '-30px';
                    }
                    if (this.side === 'left') {
                        resultStyle['left'] = '30px';
                    }
                }
                if (this.row === 'bottom' && ['left', 'right'].includes(this.side)) {
                    resultStyle['top'] = '-40px';
                }
            } else {
                if (this.row !== 'center') {
                    if (this.side === 'right') {
                        resultStyle['left'] = '-30px';
                    }
                    if (this.side === 'left') {
                        resultStyle['left'] = '30px';
                    }
                }
            }

            if (this.isTextOnSide) {
                if (this.side === 'right') {
                    resultStyle['text-align'] = 'left';
                }
                if (this.side === 'left') {
                    resultStyle['text-align'] = 'right';
                }
            }

            if (this.side === 'center' && this.row === 'bottom') {
                resultStyle['top'] = `${this.$_bottomSectionCorrection}px`;
            }

            if (!this.side !== 'center') {
                resultStyle['z-index'] = 2;
            }
            return resultStyle;
        },
        updateBadgesVisible() {
            return this.$store.getters['WikiStore/viewerState'].canSeeUpdateBadges;
        },
        showNewMark() {
            if (this.updateBadgesVisible) {
                if (this.section.updates_state === 'NEW') {
                    return true;
                }
            }
        },
        showChangedMark() {
            if (this.updateBadgesVisible) {
                if (this.section.updates_state === 'UPDATED') {
                    return true;
                }
            }
        },
        showBothMarks() {
            if (this.updateBadgesVisible) {
                if (this.section.updates_state === 'NEW_AND_UPD') {
                    return true;
                }
            }
        },
    },
    methods: {
        focus() {
            this.$_catchFocus(this.$refs.sectionAvatar);
        },
        editOrAudioClick() {
            if (this.editMode) {
                this.onEdit();
            } else {
                this.toggleSound();
            }
        },
        toggleSound() {
            this.$refs.sectionAudioToggle?.toggleSound();
        },
        getSectionCoords() {
            let x, y;
            if (this.row === 'top') {
                y = 0;
            }
            if (this.row === 'center') {
                y = this.height;
            }
            if (this.row === 'bottom') {
                y = this.height * 2;
            }
            if (this.side === 'left') {
                if (this.row === 'center') {
                    x = 0;
                } else {
                    x = TOP_BOTTOM_ROWS_POSITION_CORRECTOR;
                    if (this.pentagramMode && this.row === 'bottom') {
                        x = 0;
                        y -= (this.$_bottomSectionCorrection) / 2;
                    }
                }
            }

            if (this.side === 'right') {
                if (this.row === 'center') {
                    x = this.width * 2;
                } else {
                    x = this.width * 2 - TOP_BOTTOM_ROWS_POSITION_CORRECTOR;
                    if (this.pentagramMode && this.row === 'bottom') {
                        x += TOP_BOTTOM_ROWS_POSITION_CORRECTOR;
                        y -= (this.$_bottomSectionCorrection) / 2;
                    }
                }
            }
            if (this.side === 'center') {
                if (this.row === 'bottom') {
                    x = this.width;
                }
            }
            return {x, y};
        },
        getCircleCenterCoords() {
            let x_coord;
            if (this.side === 'right') {
                x_coord = this.$_circleSize / 2;
            } else if (this.side === 'left') {
                x_coord = this.width - this.$_circleSize / 2;
            } else if (this.side === 'center') {
                x_coord = this.width / 2;
            }
            let y_coord = this.height / 2;

            if (this.side === 'center' && this.pentagramMode) {
                y_coord = y_coord + this.$_bottomSectionCorrection;
            }

            const inSectionCoords = {x: parseInt(x_coord), y: parseInt(y_coord)};
            const sectionCoords = this.getSectionCoords();
            // console.log([inSectionCoords.x, sectionCoords.x, inSectionCoords.y, sectionCoords.y]);
            return {x: inSectionCoords.x + sectionCoords.x, y: inSectionCoords.y + sectionCoords.y};
        }
    },
    watch: {
        isActive(n) {
            if (n) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.focus();
                    }, 10);
                });
            }
        },
    }
};

export default WikiSectionMixin;