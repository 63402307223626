/**
 * Created by orion on 13.03.17.
 */


sharedFolder.$inject = ['mediaContentUtils', 'urlResolve'];
function sharedFolder(mediaContentUtils, urlResolve) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            share: '=',//'folder' or ....
            editMode: '=',
            unshareFunction: '='
        },
        template: require('./shared_folder.html'),
        link: function ($scope, element, attrs, controller) {
            $scope.get_link_to_sharer = urlResolve.userprofile_by_name;
            $scope.get_link_to_folder = urlResolve.folder_by_slug;
            $scope.generate_folder_background = mediaContentUtils.generate_folder_background;

            $scope.open_folder = function () {
                document.location.href = $scope.get_link_to_folder($scope.share.ifolder.slug);
            }
        }
    };
}

export default sharedFolder