var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      !_vm.membershipLoaded
        ? [
            _vm.accessLinkPasswordRequired
              ? [
                  _c("access-link-auth-form", {
                    attrs: {
                      "access-code": _vm.accessCode,
                      id: _vm.wikiId,
                      "access-link-check-endpoint": _vm.accessLinkCheckEndpoint,
                    },
                    on: { tokenObtained: _vm.tokenObtained },
                  }),
                ]
              : _vm.accessCodeError
              ? [
                  _c("div", { staticClass: "alert alert-danger" }, [
                    _vm._v(
                      "\n                Sorry, you do not have access to this Wiki\n            "
                    ),
                  ]),
                ]
              : _vm.accessCodeExpired
              ? [
                  _c("div", { staticClass: "alert alert-danger" }, [
                    _vm._v(
                      "\n                Sorry, your access link has expired. Please ask for the link to be extended, or to be sent a new\n                one.\n            "
                    ),
                  ]),
                ]
              : [_vm._v(" Loading...")],
          ]
        : [
            _vm._t("header"),
            _vm._v(" "),
            _vm.membershipAccessGranted
              ? _c(
                  "div",
                  [
                    _c(
                      "router-view",
                      _vm._g(
                        _vm._b({}, "router-view", _vm.$attrs, false),
                        _vm.$listeners
                      )
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.membershipAccessError
              ? _c("div", {}, [
                  _c(
                    "div",
                    {
                      staticClass: "alert alert-warning",
                      attrs: { role: "alert" },
                    },
                    [
                      _vm._v(
                        "\n                There has been no content on this Wiki shared with you, please contact the Wiki owner and request\n                that\n                they give you access to Wiki content.\n            "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ],
      _vm._v(" "),
      _vm.wikiLogo
        ? _c("img", {
            staticClass: "logo-image",
            attrs: { alt: "Logo", height: 60, src: _vm.wikiLogo },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }