import {WIKI_PERMISSION_VALUE} from "../../wiki_page/common/wikiPermissions";

export default {
    computed: {
        triggerEditPermissions() {
            let triggerPermissions = [];
            if (this.resultPermissionLevel === WIKI_PERMISSION_VALUE.EDIT) {
                triggerPermissions = [WIKI_PERMISSION_VALUE.VIEW, WIKI_PERMISSION_VALUE.NONE];
            }
            if (this.resultPermissionLevel === WIKI_PERMISSION_VALUE.VIEW) {
                triggerPermissions = [WIKI_PERMISSION_VALUE.EDIT];
            }
            if (this.resultPermissionLevel === WIKI_PERMISSION_VALUE.UNSET) {
                triggerPermissions = [WIKI_PERMISSION_VALUE.EDIT];
            }
            if (this.resultPermissionLevel === WIKI_PERMISSION_VALUE.NONE) {
                triggerPermissions = [WIKI_PERMISSION_VALUE.EDIT];
            }
            return triggerPermissions;
        },
        triggerViewPermissions() {
            let triggerPermissions = [];
            if (this.resultPermissionLevel === WIKI_PERMISSION_VALUE.UNSET) {
                triggerPermissions = [WIKI_PERMISSION_VALUE.VIEW, WIKI_PERMISSION_VALUE.EDIT];
            }
            if (this.resultPermissionLevel === WIKI_PERMISSION_VALUE.VIEW) {
                triggerPermissions = [WIKI_PERMISSION_VALUE.NONE];
            }
            if (this.resultPermissionLevel === WIKI_PERMISSION_VALUE.EDIT) {
                triggerPermissions = [WIKI_PERMISSION_VALUE.NONE];
            }
            if (this.resultPermissionLevel === WIKI_PERMISSION_VALUE.NONE) {
                triggerPermissions = [WIKI_PERMISSION_VALUE.EDIT, WIKI_PERMISSION_VALUE.VIEW];
            }
            return triggerPermissions;
        },
        editButtonClasses() {
            let res = [];
            if (this.canOwnEditPermissionBeChanged) {
                res.push('pointer');
            } else {
                res.push('disabled');
            }
            if (this.hasRedefinedInChildEditProperty) {
                res.push('color-yellow');
                return res;
            }
            if (this.resultPermissionLevel === WIKI_PERMISSION_VALUE.EDIT) {
                res.push('color-grass-green');
            }
            return res;
        },
        viewButtonClasses() {
            let res = [];
            if (this.canOwnViewPermissionBeChanged) {
                res.push('pointer');
            } else {
                res.push('disabled');
            }
            if (this.hasRedefinedInChildViewProperty) {
                res.push('color-yellow');
                return res;
            }
            if (this.resultPermissionLevel === WIKI_PERMISSION_VALUE.VIEW || this.resultPermissionLevel === WIKI_PERMISSION_VALUE.EDIT) {
                res.push('color-grass-green');
            }
            return res;
        }
    }
};