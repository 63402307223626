const ICONS = [
    {code: 'awesome', iconClass: 'fa-grin-tongue-squint', description: 'Awesome'},
    {code: 'great', iconClass: 'fa-smile-beam', description: 'Great'},
    {code: 'happy', iconClass: 'fa-smile', description: 'Happy'},
    {code: 'excited', iconClass: 'fa-grin-stars', description: 'Excited'},
    {code: 'in-love', iconClass: 'fa-grin-hearts', description: 'In love'},
    {code: 'surprised', iconClass: 'fa-surprise', description: 'Surprised'},
    {code: 'neutral', iconClass: 'fa-meh', description: 'Ok'},
    {code: 'sad-tears', iconClass: 'fa-sad-tear', description: 'Sad'},
    {code: 'sad', iconClass: 'fa-frown', description: 'Upset'},
    {code: 'sick', iconClass: 'fa-flushed', description: 'Sick'},
    {code: 'scared', iconClass: 'fa-grimace', description: 'Anxious'},
    {code: 'bored', iconClass: 'fa-meh-rolling-eyes', description: 'Bored'},
    {code: 'tired', iconClass: 'fa-snooze', description: 'Tired'},
    {code: 'annoyed', iconClass: 'fa-frown-open', description: 'Annoyed'},
    {code: 'angry', iconClass: 'fa-angry', description: 'Angry'},
    {code: 'in-pain', iconClass: 'fa-tired', description: 'In pain'},
];

function getIconClassByCode(code) {
    for (let icon of ICONS) {
        if (icon.code === code) {
            return icon.iconClass;
        }
    }
}

function getIconDescriptionByCode(code) {
    for (let icon of ICONS) {
        if (icon.code === code) {
            return icon.description;
        }
    }
}
function getEmotionByCode(code) {
    for (let icon of ICONS) {
        if (icon.code === code) {
            return icon;
        }
    }
}

export {ICONS, getIconClassByCode, getIconDescriptionByCode, getEmotionByCode};