<template>
    <modal v-model="opened" :transition='0' :append-to-body="true" :backdrop="false">
        <div slot="header">
            <a id="close_popup" @click="closePopup"></a>
            <h3 class="modal-title">{{header}}</h3>
        </div>
        <div class="" v-for="day in days">
            <strong>{{dayToLabel(day)}}</strong>:
            <event-time-editor
                    class="margin-15-bottom"
                    :value="advancedTimeSchedule[day]"
                    @input="timeChanged(day,$event)"
            ></event-time-editor>
        </div>
        <div slot="footer">
            <button class="btn btn-mm" @click="closePopup">Cancel</button>
            <button class="btn btn-mm btn-green save-button" :disabled="!allowSave()" @click="save">
                OK
            </button>
        </div>
    </modal>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';
    import EventTimeEditor from './EventTimeEditor.vue';
    import {DAYS_OF_WEEK} from "../new_calendar/common";

    export default {
        components: {EventTimeEditor},
        name: "AdvancedTimeSchedulePopup",
        data() {
            return {
                opened: false,
                header: 'Advanced Time Schedule',
                advancedTimeSchedule: {},
            };
        },
        computed: {
            days() {
                return Object.keys(this.advancedTimeSchedule);
            },
        },
        methods: {
            allowSave() {
                for (let k in this.advancedTimeSchedule) {
                    const time = this.advancedTimeSchedule[k];
                    const startHour = parseInt(time.start.hour);
                    const endHour = parseInt(time.end.hour);
                    if (endHour < startHour) {
                        return false;
                    }
                    if (endHour === startHour) {
                        const startMinute = parseInt(time.start.minute);
                        const endMinute = parseInt(time.end.minute);
                        if (endMinute < (startMinute + 1)) {
                            return false;
                        }
                    }
                }
                return true;
            },
            timeChanged(d, val) {
                Vue.set(this.advancedTimeSchedule, d, val);
                this.$forceUpdate();
            },
            dayToLabel(dayNumber) {
                return DAYS_OF_WEEK[dayNumber - 1];
            },
            open(initial) {
                this.resetData();
                _.merge(this.advancedTimeSchedule, initial);

                this.opened = true;
                return new Promise((res, rej) => {
                    this.resolve = res;
                    this.reject = rej;
                });
            },
            resetData() {
                this.advancedTimeSchedule = {};
            },
            closePopup() {
                this.opened = false;
                this.resetData();
                this.reject('closed');
            },
            save() {
                this.opened = false;
                this.resolve(this.advancedTimeSchedule);
                this.resetData();
            }
        }
    };
</script>

<style scoped>

</style>