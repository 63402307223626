import VueInlineMediaImage from './VueInlineMediaImage.vue';
import VueInlineMediaTMP from './VueInlineMediaTMP.vue';
import VueInlineMediaDocument from './VueInlineMediaDocument.vue';
import VueInlineMediaAudio from './VueInlineMediaAudio.vue';
import VueInlineMediaYoutube from './VueInlineMediaYoutube.vue';
import VueInlineMediaVideo from './VueInlineMediaVideo.vue';

const COMPONENTS_PER_TYPE = {
    'image': VueInlineMediaImage,
    'uploaded_document': VueInlineMediaDocument,
    'youtube_video': VueInlineMediaYoutube,
    'upl_video': VueInlineMediaVideo,
    'uploaded_audio': VueInlineMediaAudio,
    'recorded_audio': VueInlineMediaAudio,
};

export default {
    name: "VueInlineMedia",
    components: {
        VueInlineMediaTMP,
        VueInlineMediaImage,
        VueInlineMediaDocument,
        VueInlineMediaAudio,
        VueInlineMediaYoutube,
        VueInlineMediaVideo
    },
    functional: true,
    props: {
        item: Object,
        autoplay: {
            type: Boolean,
            default: false
        }
    },
    render(createElement, context) {
        return createElement(
            'div',
            {
                class: {
                    'text-center': true,
                    'media-renderer-inline': true,
                },
            },
            [createElement(COMPONENTS_PER_TYPE[context.props.item.type], {
                props: {
                    item: context.props.item,
                    autoplay: context.props.autoplay,
                }
            })]
        );
    }
};