<template>
    <div class="">
        <div class="wikiLog" v-if="currentComponent">
                <img class="wikiLog__avatar"
                     :src="wikiLog.actor.avatar"
                     alt="avatar"
                >
            <div class="wikiLog__content">
                <div class="wikiLog__details">
                    <div>
                        <simple-link :target="wikiLog.actor"></simple-link>
                        <i
                                v-if="wikiLog.actor_assistant?.name"
                                class="text--secondary"
                        > supported by {{ wikiLog.actor_assistant?.name }}<span v-if="wikiLog.actor_assistant_role"> ({{wikiLog.actor_assistant_role}})</span></i>
                        <i
                                v-if="wikiLog.actor_assistant_name"
                                class="text--secondary"
                        > supported by {{ wikiLog.actor_assistant_name }}<span v-if="wikiLog.actor_assistant_role"> ({{wikiLog.actor_assistant_role}})</span></i>
                    </div>
                    <i class="text--secondary text-caption"> {{ formattedTime }} </i>
                </div>
                <component
                        :is="currentComponent"
                        :wikiLog="wikiLog"
                ></component>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import WikiSectionCreated from "./log_specific_components_for_admins/WikiSectionCreated";
    import WikiSectionDeleted from "./log_specific_components_for_admins/WikiSectionDeleted";
    import WikiSectionUpdatedParamsChanged from "./log_specific_components_for_admins/WikiSectionUpdatedParamsChanged";
    import WikiSectionUpdatedContentReordered
        from "./log_specific_components_for_admins/WikiSectionUpdatedContentReordered";
    import WikiSectionUpdatedMoved from "./log_specific_components_for_admins/WikiSectionUpdatedMoved";
    import WikiSlideCreated from "./log_specific_components_for_admins/WikiSlideCreated";
    import WikiSlideDeleted from "./log_specific_components_for_admins/WikiSlideDeleted";
    import WikiSlideUpdatedMoved from "./log_specific_components_for_admins/WikiSlideUpdatedMoved";
    import WikiSlideUpdatedParamsChanged from "./log_specific_components_for_admins/WikiSlideUpdatedParamsChanged";
    import WikiSlideAttachmentCreatedDocument
        from "./log_specific_components_for_admins/WikiSlideAttachmentCreatedDocument";
    import WikiSlideAttachmentCreatedLink from "./log_specific_components_for_admins/WikiSlideAttachmentCreatedLink";
    import WikiSlideAttachmentCreatedLocation
        from "./log_specific_components_for_admins/WikiSlideAttachmentCreatedLocation";
    import WikiSlideAttachmentDeletedDocument
        from "./log_specific_components_for_admins/WikiSlideAttachmentDeletedDocument";
    import WikiSlideAttachmentDeletedLink from "./log_specific_components_for_admins/WikiSlideAttachmentDeletedLink";
    import WikiSlideAttachmentDeletedLocation
        from "./log_specific_components_for_admins/WikiSlideAttachmentDeletedLocation";
    import WikiUpdatedContentReordered from "./log_specific_components_for_admins/WikiUpdatedContentReordered";
    import WikiDeleted from "./log_specific_components_for_admins/WikiDeleted";
    import WikiCreated from "./log_specific_components_for_admins/WikiCreated";
    import WikiUpdatedParamsChanged from "./log_specific_components_for_admins/WikiUpdatedParamsChanged";

    const COMPONENTS = {
        'WikiSection.Created': WikiSectionCreated,
        'WikiSection.Deleted': WikiSectionDeleted,
        'WikiSection.Updated.ParamsChanged': WikiSectionUpdatedParamsChanged,
        'WikiSection.Updated.ContentReordered': WikiSectionUpdatedContentReordered,
        'WikiSection.Updated.Moved': WikiSectionUpdatedMoved,

        'WikiSlide.Created': WikiSlideCreated,
        'WikiSlide.Deleted': WikiSlideDeleted,
        'WikiSlide.Updated.Moved': WikiSlideUpdatedMoved,
        'WikiSlide.Updated.ParamsChanged': WikiSlideUpdatedParamsChanged,

        'WikiSlideAttachment.Created.Document': WikiSlideAttachmentCreatedDocument,
        'WikiSlideAttachment.Created.Link': WikiSlideAttachmentCreatedLink,
        'WikiSlideAttachment.Created.Location': WikiSlideAttachmentCreatedLocation,
        'WikiSlideAttachment.Deleted.Document': WikiSlideAttachmentDeletedDocument,
        'WikiSlideAttachment.Deleted.Link': WikiSlideAttachmentDeletedLink,
        'WikiSlideAttachment.Deleted.Location': WikiSlideAttachmentDeletedLocation,

        'Wiki.Created': WikiCreated,
        'Wiki.Deleted': WikiDeleted,
        'Wiki.Updated.ContentReordered': WikiUpdatedContentReordered,
        'Wiki.Updated.ParamsChanged': WikiUpdatedParamsChanged,

    };

    export default {
        name: "WikiActionLogAdmin",
        props: {
            wikiLog: Object,
        },
        computed: {
            currentComponent() {
                return COMPONENTS[this.wikiLog.action_type];
            },
            formattedTime() {
                return moment.tz(this.wikiLog.action_time, 'Europe/London').local().format('DD/MM/YY HH:mm');
            }
        },
    };
</script>

<style scoped lang="scss">
  .wikiLog {
    padding: 8px 8px 8px 0;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ddd;

    &__avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 8px;
    }

    &__content {
      width: 100%;
    }

    &__details {
      display: flex;
      justify-content: space-between;

      @media (max-width: 599px) {
        flex-direction: column;
      }
    }
  }

</style>
