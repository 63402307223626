var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "chat-style-comment-component",
      attrs: { id: `comment-${_vm.comment.id}` },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xs-12" }, [
          _c("div", { staticClass: "chat-style-comment-component__comment" }, [
            _c(
              "div",
              {
                staticClass: "chat-style-comment-component__avatar-part",
                class: {
                  "chat-style-comment-component__avatar-part_right":
                    _vm.moveAvatarToRight,
                },
              },
              [
                _vm.author.link
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.author.link,
                          "aria-label": _vm.author.name,
                          tabindex: "-1",
                        },
                      },
                      [
                        _c("img", {
                          staticClass:
                            "img-circle chat-style-comment-component__avatar",
                          attrs: { src: _vm.author.avatar },
                        }),
                      ]
                    )
                  : _c("span", [
                      _c("img", {
                        staticClass:
                          "img-circle chat-style-comment-component__avatar",
                        attrs: { src: _vm.author.avatar, alt: _vm.author.name },
                      }),
                    ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "chat-style-comment-component__content-part" },
              [
                _vm.moveAvatarToRight
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "chat-style-comment-component__author-and-time chat-style-comment-component__author-and-time_right",
                      },
                      [
                        !_vm.comment.approved
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "chat-style-comment-component__approval-message",
                              },
                              [
                                _c("br"),
                                _c("i", [_vm._v("Waiting for approval.")]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "chat-style-comment-component__created-time",
                          },
                          [
                            _vm._v(
                              "(" +
                                _vm._s(
                                  _vm._f("GBDateTime")(_vm.comment.created_at)
                                ) +
                                ")"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "strong",
                          [
                            _vm.author.link
                              ? _c("simple-link", {
                                  attrs: { target: _vm.author },
                                })
                              : _c("span", [_vm._v(_vm._s(_vm.author.name))]),
                          ],
                          1
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "chat-style-comment-component__author-and-time",
                      },
                      [
                        _c(
                          "strong",
                          [
                            _vm.author.link
                              ? _c("simple-link", {
                                  attrs: { target: _vm.author },
                                })
                              : _c("span", [_vm._v(_vm._s(_vm.author.name))]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "chat-style-comment-component__created-time",
                          },
                          [
                            _vm._v(
                              "(" +
                                _vm._s(
                                  _vm._f("GBDateTime")(_vm.comment.created_at)
                                ) +
                                ")"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        !_vm.comment.approved
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "chat-style-comment-component__approval-message",
                              },
                              [
                                _c("br"),
                                _c("i", [_vm._v("Waiting for approval.")]),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "chat-style-comment-component__content",
                    class: {
                      "chat-style-comment-component__content_my":
                        _vm.moveAvatarToRight,
                      "chat-style-comment-component__content_new":
                        _vm.comment.new,
                      "chat-style-comment-component__content_selected-highlight":
                        _vm.activeClass,
                      "chat-style-comment-component__content_selected-active":
                        _vm.isActive,
                    },
                  },
                  [
                    _vm._t("postContent", function () {
                      return [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-sm-12" },
                            [
                              _vm.comment.linked_blog_entry
                                ? _c("chat-style-comment-linked-blog-entry", {
                                    attrs: {
                                      comment: _vm.comment,
                                      moveAvatarToRight: _vm.moveAvatarToRight,
                                      "blog-entry":
                                        _vm.comment.linked_blog_entry,
                                    },
                                  })
                                : _vm.comment.linked_user_activity_post
                                ? _c(
                                    "chat-style-comment-linked-user-activity-post",
                                    {
                                      attrs: {
                                        comment: _vm.comment,
                                        moveAvatarToRight:
                                          _vm.moveAvatarToRight,
                                        "user-activity-post":
                                          _vm.comment.linked_user_activity_post,
                                      },
                                    }
                                  )
                                : _vm.comment.linked_blog_answer
                                ? _c("chat-style-comment-linked-blog-answer", {
                                    attrs: {
                                      comment: _vm.comment,
                                      "blog-answer":
                                        _vm.comment.linked_blog_answer,
                                      moveAvatarToRight: _vm.moveAvatarToRight,
                                    },
                                  })
                                : _vm.comment.linked_wiki_slide
                                ? _c("chat-style-comment-linked-wiki-slide", {
                                    attrs: {
                                      comment: _vm.comment,
                                      "wiki-slide":
                                        _vm.comment.linked_wiki_slide,
                                      moveAvatarToRight: _vm.moveAvatarToRight,
                                    },
                                  })
                                : _c("render-post", {
                                    staticStyle: {
                                      "max-height": "100%",
                                      padding: "0 15px",
                                    },
                                    attrs: {
                                      post: _vm.comment,
                                      "max-media-height": "500px",
                                      "add-border-around-text":
                                        "WITH_OTHER_CONTENT",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._t("reply"),
                      ]
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "chat-style-comment-component__bottom-panel" },
                  [_vm._t("bottomPanel")],
                  2
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }