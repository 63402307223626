/**
 * Created by orion on 06.03.17.
 */
import urls from 'DjangoUrls';
window.DjangoUrls = urls.DjangoUrls;

import onDocumentReady from "shared/onDocumentReady";

onDocumentReady(function() {

    // Detect ios 11_0_x affected
    // NEED TO BE UPDATED if new versions are affected
    let ua = navigator.userAgent,
    iOS = /iPad|iPhone|iPod/.test(ua),
    iOS11 = /OS 11_0_1|OS 11_0_2|OS 11_0_3|OS 11_1|OS 11_1_1|OS 11_1_2|OS 11_2|OS 11_2_1/.test(ua);

    // ios 11 bug caret position
    if ( iOS && iOS11 ) {
        // Add CSS class to body
        document.body.classList.add('iosBugFixCaret');
    }

});

export default '';