const WikiActionsSubtypes = {
    ADDED_TITLE: 'Added.Title',
    ADDED_TITLE_AUDIO: 'Added.Title.Audio',
    ADDED_DESCRIPTION: 'Added.Description',
    ADDED_DESCRIPTION_AUDIO: 'Added.Description.Audio',
    ADDED_MEDIA: 'Added.Media',
    ADDED_MEDIA_PICTURE: 'Added.Media.Picture',
    ADDED_MEDIA_VIDEO: 'Added.Media.Video',
    ADDED_MEDIA_AUDIO: 'Added.Media.Audio',

    UPDATED_TITLE: 'Updated.Title',
    UPDATED_TITLE_AUDIO: 'Updated.Title.Audio',
    UPDATED_DESCRIPTION: 'Updated.Description',
    UPDATED_DESCRIPTION_AUDIO: 'Updated.Description.Audio',
    UPDATED_MEDIA: 'Updated.Media',
    UPDATED_MEDIA_PICTURE: 'Updated.Media.Picture',
    UPDATED_MEDIA_VIDEO: 'Updated.Media.Video',
    UPDATED_MEDIA_AUDIO: 'Updated.Media.Audio',

    REMOVED_TITLE: 'Removed.Title',
    REMOVED_TITLE_AUDIO: 'Removed.Title.Audio',
    REMOVED_DESCRIPTION: 'Removed.Description',
    REMOVED_DESCRIPTION_AUDIO: 'Removed.Description.Audio',
    REMOVED_MEDIA: 'Removed.Media',
};

const WikiActionsSubtypesVerbal = {
    [WikiActionsSubtypes.ADDED_TITLE]: 'Added title',
    [WikiActionsSubtypes.ADDED_TITLE_AUDIO]: 'Added audio title',
    [WikiActionsSubtypes.ADDED_DESCRIPTION]: 'Added description',
    [WikiActionsSubtypes.ADDED_DESCRIPTION_AUDIO]: 'Added audio description',
    [WikiActionsSubtypes.ADDED_MEDIA]: 'Added media',
    [WikiActionsSubtypes.ADDED_MEDIA_PICTURE]: 'Added picture',
    [WikiActionsSubtypes.ADDED_MEDIA_VIDEO]: 'Added video',
    [WikiActionsSubtypes.ADDED_MEDIA_AUDIO]: 'Added audio',

    [WikiActionsSubtypes.UPDATED_TITLE]: 'Updated title',
    [WikiActionsSubtypes.UPDATED_TITLE_AUDIO]: 'Updated audio title',
    [WikiActionsSubtypes.UPDATED_DESCRIPTION]: 'Updated description',
    [WikiActionsSubtypes.UPDATED_DESCRIPTION_AUDIO]: 'Updated audio description',
    [WikiActionsSubtypes.UPDATED_MEDIA]: 'Updated media',
    [WikiActionsSubtypes.UPDATED_MEDIA_PICTURE]: 'Updated picture',
    [WikiActionsSubtypes.UPDATED_MEDIA_VIDEO]: 'Updated video',
    [WikiActionsSubtypes.UPDATED_MEDIA_AUDIO]: 'Updated audio',

    [WikiActionsSubtypes.REMOVED_TITLE]: 'Removed title',
    [WikiActionsSubtypes.REMOVED_TITLE_AUDIO]: 'Removed audio title',
    [WikiActionsSubtypes.REMOVED_DESCRIPTION]: 'Removed description',
    [WikiActionsSubtypes.REMOVED_DESCRIPTION_AUDIO]: 'Removed audio description',
    [WikiActionsSubtypes.REMOVED_MEDIA]: 'Removed media',
};

export {WikiActionsSubtypes, WikiActionsSubtypesVerbal};