var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "inline-block" }, [
    _c("div", { staticClass: "breadcrumb-separator" }),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.section.caption,
            expression: "section.caption",
          },
        ],
        staticClass: "breadcrumb-image text-center pointer",
        class: { active: _vm.isActive },
        style: _vm.sectionAvatarStyle,
        on: { click: _vm.sectionClick },
      },
      [
        !_vm.section.avatar && !_vm.section.media_content_samples.length
          ? _c("i", { staticClass: "fa fa-picture-o" })
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }