/**
 * Created by orion on 08.03.17.
 */

// import showPostsPopupController from './popups/showPostsPopup/controller';
import showPostsPopup from "./popups/showPostsPopup";
import {simplePopupFactory} from "shared/simple_popups/simplePopupFactory";

viewMediaFactory.$inject = [];

function viewMediaFactory() {

    let show_media = function show_media(items, index) {
        const prepared = [];
        for (let item of items) {
            prepared.push({
                media_attachment: item,
                text: item.text,
                is_header: item.is_header,
                sticker: item.sticker,
            });
        }
        return show_posts(prepared, index);
    };

    function show_posts(posts, index = 0) {
        /**
         * @param posts - {Array} of {Object}s
         * post:
         * {
         * text: {String}
         * sticker: {String}
         * media_attachment: {Object} Media item
         * }
         */
            // let modalInstance = simplePopupFactory.create_popup(
            //     showPostsPopupController,
            //     require('./popups/showPostsPopup/template.html'),
            //     {posts, index},
            //     false,
            //     false,
            //     'xlg'
            // );
            // return modalInstance.promise

        const {popupComponent, vm, fEl} = simplePopupFactory.mount_vue_popup(showPostsPopup);
        return popupComponent.show(posts, index)
            .catch(err => {
            })
            .finally(() => {
                setTimeout(() => {
                    fEl?.focus();
                }, 1);
                if (vm) {
                    vm.$destroy();
                }
            });
    }

    return {
        show_media,
        show_posts,
    };
}

export default viewMediaFactory;