<template>
    <div class="wiki-tree">
        <div class="wiki-tree__section">
            <i class="fa" :class="{'fa-chevron-down':mainSectionExpanded,'fa-chevron-right':!mainSectionExpanded}"
               @click="toggleMainSectionExpand"></i>
            <i class="fa fa-pencil" v-if="allowAssignEditPermission" :class="editButtonClasses"
               @click="toggleWikiLevelEditPermission()"></i>
            <i class="fa fa-eye" :class="viewButtonClasses" @click="toggleWikiLevelViewPermissionLevel()"></i>
            <div class="wiki-tree__section__owners-avatar " :style="ownerAvatarStyle"></div>
            <strong>{{ mainTitle }}</strong>
        </div>
        <div class="" v-if="mainSectionExpanded">
            <wiki-tree-section
                    :avatarUrl="(section.position===0&&!section.avatar)?owner.avatar:undefined"
                    :section="section"
                    :domain="member.domain"
                    :access-code="accessCodeIfAccessCodeMembership"
                    :allowAssignEditPermission="allowAssignEditPermission"
                    :permissionsList="permissionsList"
                    :inheritedPermissionLevel="resultPermissionLevel"
                    v-for="section in sections" :key="section.id"
            ></wiki-tree-section>
        </div>
    </div>
</template>

<script>
    import WikiTreeSection from './WikiTreeSection.vue';
    import _ from 'lodash';
    import Vue from 'vue';
    import editViewButtonsClassesMixin from './editViewButtonsClassesMixin';
    import wikiMemberPermissionBus from './wikiMemberPermissionBus';
    import {
        WIKI_PERMISSION_LEVEL,
        WIKI_PERMISSION_SIGNAL,
        WIKI_PERMISSION_VALUE
    } from '../../wiki_page/common/wikiPermissions';
    import TreePermissionDetectionMixinWholeWiki
        from './tree_permission_detection_mixins/TreePermissionDetectionMixinWholeWiki';
    import WikiMembershipResource from '~/angular/app/wiki/wiki_settings_page/wiki_membership_resource';

    export default {
        name: 'WikiTree',
        components: {
            WikiTreeSection
        },
        mixins: [editViewButtonsClassesMixin, TreePermissionDetectionMixinWholeWiki],
        props: {
            sections: {
                type: Array
            },
            owner: {
                type: Object
            },
            member: {
                type: Object
            },
            mainTitle: {
                type: String,
                default: 'Wiki Content Page'
            }
        },
        data() {
            return {
                mainSectionExpanded: true,
                permissionsList: [],
            };
        },
        computed: {
            allowAssignEditPermission() {
                return !!this.member.userprofile;
            },
            isAccessCodeMembership() {
                return this.member.membership_type === 'access_link';
            },
            accessCodeIfAccessCodeMembership() {
                if (!this.isAccessCodeMembership) {
                    return;
                }
                return this.member.access_code;
            },
            ownerAvatarStyle() {
                return {
                    'background-image': `url('/static/images/new_daschboard_icons/wiki4f1.png')`
                };
            },
            canOwnEditPermissionBeChanged() {
                return true;
            },
            canOwnViewPermissionBeChanged() {
                return this.ownPermissionLevel !== WIKI_PERMISSION_VALUE.EDIT;
            },
            hasRedefinedInChildViewProperty() {
                let triggerPermissions = this.triggerViewPermissions;
                if (_.findIndex(this.permissionsList, p => p.level === WIKI_PERMISSION_LEVEL.SECTION && triggerPermissions.includes(p.value)) !== -1) {
                    return true;
                }
                return _.findIndex(this.permissionsList, p => p.level === WIKI_PERMISSION_LEVEL.SLIDE && triggerPermissions.includes(p.value)) !== -1;

            },
            hasRedefinedInChildEditProperty() {
                let triggerPermissions = this.triggerEditPermissions;
                if (_.findIndex(this.permissionsList, p => p.level === WIKI_PERMISSION_LEVEL.SECTION && triggerPermissions.includes(p.value)) !== -1) {
                    return true;
                }
                return _.findIndex(this.permissionsList, p => p.level === WIKI_PERMISSION_LEVEL.SLIDE && triggerPermissions.includes(p.value)) !== -1;

            }
        },
        methods: {
            setWikiPermission(levelCode, value, id = undefined) {
                // levelCode in ['whole-wiki', 'section', 'slide', 'file-attachment', 'link']

                this.$loadingSpinner.show();
                WikiMembershipResource().set_permission(this.member.id, levelCode, value, id)
                    .then(
                        resp => {
                            this.$notifications.success('Updated');
                            this.permissionsList = resp.data;
                            this.member.has_assigned_permissions = !!this.permissionsList.filter(p => p.value !== WIKI_PERMISSION_VALUE.NONE).length;
                        }, err => this.$notifications.error(err || 'Error')
                    )
                    .finally(() => this.$loadingSpinner.hide());
            },
            toggleMainSectionExpand() {
                this.mainSectionExpanded = !this.mainSectionExpanded;
            },
            toggleWikiLevelEditPermission() {
                if (this.ownPermissionLevel === WIKI_PERMISSION_VALUE.EDIT) {
                    wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.WHOLE_WIKI, WIKI_PERMISSION_VALUE.VIEW);
                }
                if (this.ownPermissionLevel === WIKI_PERMISSION_VALUE.UNSET || this.ownPermissionLevel === WIKI_PERMISSION_VALUE.VIEW || this.ownPermissionLevel === WIKI_PERMISSION_VALUE.NONE) {
                    wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.WHOLE_WIKI, WIKI_PERMISSION_VALUE.EDIT);
                }
            },
            toggleWikiLevelViewPermissionLevel() {
                if (this.ownPermissionLevel === WIKI_PERMISSION_VALUE.EDIT) {
                    return;
                }
                if (this.ownPermissionLevel === WIKI_PERMISSION_VALUE.VIEW) {
                    wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.WHOLE_WIKI, WIKI_PERMISSION_VALUE.NONE);
                }
                if (this.ownPermissionLevel === WIKI_PERMISSION_VALUE.UNSET || this.ownPermissionLevel === WIKI_PERMISSION_VALUE.NONE) {
                    wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.WHOLE_WIKI, WIKI_PERMISSION_VALUE.VIEW);
                }
            },
            getPermissionsList() {
                this.$loadingSpinner.show();
                WikiMembershipResource().get_members_permissions(this.member.id)
                    .then(
                        resp => {
                            this.permissionsList = resp.data;
                        },
                        err => this.$notifications.error(err || 'Error')
                    )
                    .finally(() => this.$loadingSpinner.hide());
            },
            bindWikiPermissionsBusListener() {
                wikiMemberPermissionBus.$on(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, this.setWikiPermission);
            },
            unBindWikiPermissionsBusListener() {
                wikiMemberPermissionBus.$off(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, this.setWikiPermission);
            }
        },
        mounted() {
            this.getPermissionsList();
            this.bindWikiPermissionsBusListener();
        },
        beforeDestroy() {
            this.unBindWikiPermissionsBusListener();
        }
    };
</script>

<style scoped lang="scss">
  .wiki-tree {
    .fa-chevron-right {
      width: 25px;
      text-align: center;
    }

    .fa-chevron-right, .fa-chevron-down {
      cursor: pointer;
    }

    .fa-eye {
      &.disabled {
        opacity: (0.5);
      }
    }

    .fa-pencil {
      &.disabled {
        opacity: (0.5);
      }

    }

    &__section {
      margin: 4px 0;

      .fa {
        vertical-align: middle;
        font-size: 25px;
        margin: 0 4px;
      }

      &__owners-avatar {
        display: inline-block;
        vertical-align: middle;
        background-size: cover;
        width: 50px;
        height: 50px;
        //border-radius: 50%;
      }

      &__section-avatar {
        text-align: center;

        .fa {
          line-height: 45px;
          font-size: 30px;
        }

      }
    }
  }
</style>
