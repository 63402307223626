/**
 * Created by orion on 07.03.17.
 */

import angular from 'angular';

import stuffPreview from './rounded_stuff_preview/directive'


import simpleLink from './simple_link_directive/simpleLink'

angular.module('mmApp.stuff_previews', [simpleLink, stuffPreview]);

export default 'mmApp.stuff_previews'