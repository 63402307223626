<template>
    <div class="event-detail-page" v-if="loaded">
        <vue-simple-header>
            <span slot="headerTitle">{{ event.event_data.name }}
                <audio-toggle :audio-caption="event.event_data.audio_caption"></audio-toggle>
                <i @click="editEventData" class="fa fa-pencil pointer" v-if="isAdmin"></i>
            </span>
            <span slot="headerLabel">
                <span v-if="event.creator" style="font-size: 14px">
                    Event created by <strong><simple-link :target="event.creator"></simple-link></strong>
                    <span v-if="event.group"> in Group <strong><simple-link
                            :target="event.group"></simple-link></strong></span>
                    <span v-if="event.community"> in Community <strong><simple-link
                            :target="event.community"></simple-link></strong></span>
                    <span v-if="event.owner&&event.owner.id!==event.creator.id"> for <strong><simple-link
                            :target="event.owner"></simple-link></strong></span>
                    on {{ event.created_at }}.
                </span>
            </span>
            <div slot="panelRight">
                <vue-folder-button
                        v-show="showInviteMemberIcon"
                        text="Invite Users"
                        color="dark-blue"
                        icon-class="fa-user-plus"
                        @click.native="inviteMember"
                        style="margin-right: 16px"
                ></vue-folder-button>
                <router-link v-if="eventAccessStatusIsOK"
                             :to="{name:'info',params:{date,calendarOwnerId,calendarOwnerName}, hash:$route.hash}">
                    <vue-folder-button
                            text="Info"
                            icon-class="fa-info"
                            :active="openedPage==='info'"

                    ></vue-folder-button>
                </router-link>
                <router-link :to="{name:'goals',params:{date,calendarOwnerId,calendarOwnerName}, hash:$route.hash}"
                             v-if="eventAccessStatusIsOK">
                    <vue-folder-button
                            :text="linkedGoalsIconText"
                            custom-icon="goal"
                            :active="openedPage==='goals'"
                    ></vue-folder-button>
                </router-link>
                <router-link :to="{name:'comments',params:{date,calendarOwnerId,calendarOwnerName}, hash:$route.hash}"
                             v-if="eventAccessStatusIsOK">
                    <vue-folder-button
                            :text="commentsIconText"
                            icon-class="fa-comments-o"
                            :active="openedPage==='comments'"
                    ></vue-folder-button>
                </router-link>

                <a :href="backUrl" v-if="!backUrlAvailableInRouter">
                    <vue-folder-button text="Back" icon-class="fa-reply" color="blue"
                    ></vue-folder-button>
                </a>
                <router-link :to="backUrl" v-else>
                    <vue-folder-button text="Back" icon-class="fa-reply" color="blue"
                    ></vue-folder-button>
                </router-link>
            </div>
        </vue-simple-header>
        <div class="alert alert-info" v-if="buddyMode">
            <strong>Attention</strong>. You are in <strong>{{ calendarOwner.name }}</strong>'s Calendar now.
        </div>
        <router-view
                @unsetAttendance="unsetAttendance"
                @setAttendance="setAttendance"
                @onEventJoined="onEventJoined"
                @updateGoalsCounter="updateGoalsCounter"
                @increaseCommentsCounter="increaseCommentsCounter"
                @decreaseCommentsCounter="decreaseCommentsCounter"
        ></router-view>
        <delete-repeated-event-type-selector-popup
                ref="delete-repeat-event-popup"></delete-repeated-event-type-selector-popup>
        <update-repeated-event-type-selector-popup
                ref="update-repeat-event-popup"></update-repeated-event-type-selector-popup>
        <edit-event-data-popup ref="edit-event-data-popup"></edit-event-data-popup>
        <event-detail-page-period-selector-popup
                ref="join-type-selector"></event-detail-page-period-selector-popup>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';
    import moment from 'moment';
    import * as config from '../new_calendar/config';
    // import EventDetailPageJoinForm from './EventDetailPageJoinForm.vue';
    import DeleteRepeatedEventTypeSelectorPopup from './DeleteRepeatedEventTypeSelectorPopup.vue';
    import UpdateRepeatedEventTypeSelectorPopup from './UpdateRepeatedEventTypeSelectorPopup.vue';
    import EditEventDataPopup from './EditEventDataPopup.vue';

    import {EVENTS_BUS_EVENTS, eventsBus} from './eventsBus';
    import {VISUAL_DATE_FORMAT} from "../new_calendar/config";
    import BackButtonMixin from "../../../../vue/mixins/BackButtonMixin";
    import EventDetailPagePeriodSelectorPopup from "./EventDetailPagePeriodSelectorPopup.vue";
    import {EVENT_PERIODS} from "../new_calendar/common";
    import {DjangoUrls} from "DjangoUrls";

    export default {
        name: "EventDetailPage",
        components: {
            // EventDetailPageJoinForm,
            DeleteRepeatedEventTypeSelectorPopup,
            EditEventDataPopup,
            UpdateRepeatedEventTypeSelectorPopup,
            EventDetailPagePeriodSelectorPopup,
        },
        mixins: [BackButtonMixin],
        props: {
            eventId: [String, Number],
            date: String,
            calendarOwnerId: [String, Number],
            calendarOwnerName: String,
        },
        data() {
            return {
                event: {},
                membershipStatus: {},
                periodInfo: {},

                eventAccessStatus: undefined,
                loaded: false,
                selectedViewer: 'default',

                counters: {
                    linkedGoals: 0,
                    comments: 0,
                }
            };
        },
        computed: {
            defaultBackUrl() {
                return DjangoUrls['users_calendar']('my');
            },
            commentsIconText() {
                if (!this.counters.comments) {
                    return 'Comments';
                } else {
                    return `Comments (${this.counters.comments})`;
                }
            },
            linkedGoalsIconText() {
                if (!this.counters.linkedGoals) {
                    return this.$customTerm('Goals');
                } else {
                    return `${this.$customTerm('Goals')} (${this.counters.linkedGoals})`;
                }
            },
            calendarOwnerIsOwnEventsAdmin() {
                return this.membershipStatus.owner_is_admin_of_own_events;
            },
            eventAccessStatusIsOK() {
                return this.eventAccessStatus === 'OK';
            },
            calendarOwner() {
                if (this.calendarOwnerId) {
                    if (this.selectedViewer === 'buddy') {
                        return this.buddyProfile;
                    } else {
                        return this.suProfile;
                    }
                } else {
                    return this.buddyProfile;
                }
            },
            suProfile() {
                return {
                    id: parseInt(this.calendarOwnerId),
                    name: this.calendarOwnerName,
                };
            },
            buddyProfile() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                return {
                    id: CurrentUser.id,
                    name: CurrentUser.name,
                };
            },
            buddyMode() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                return this.calendarOwnerId && CurrentUser.id !== parseInt(this.calendarOwnerId);
            },
            behalfMode() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                return this.calendarOwner.id !== CurrentUser.id;
            },
            openedPage() {
                return this.$route.name;
            },
            showInviteMemberIcon() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                return this.openedPage === 'members' && CurrentUser.has_permission("invite-users-to-join-events");
            },
            viewerIsMember() {
                return this.membershipStatus.viewer_is_member;
            },
            allowInviteMember() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                return CurrentUser.has_permission("invite-users-to-join-events");
            },
            isAdmin() {
                return this.membershipStatus.is_admin;
            },
            dateMoment() {
                if (!this.date) {
                    return;
                }
                return moment(this.date, config.URL_DATE_FORMAT);
            },
            daysOfWeekForAdvancedSchedule() {
                if (!this.loaded) {
                    return [];
                }
                if (!this.event.period_data) {
                    return [];
                }
                if (this.event.period_data.repeat_type !== EVENT_PERIODS.WEEKLY_BY_DAY_NUMBERS) {
                    return [];
                }
                return this.event.period_data.days_of_week;
            },
            allowToggleAdvancedTimeSchedule() {
                return this.loaded && this.periodInfo.is_periodic && this.daysOfWeekForAdvancedSchedule.length > 1;
            },
        },
        methods: {
            updateGoalsCounter(n) {
                this.counters.linkedGoals = n;
            },
            increaseCommentsCounter() {
                this.counters.comments++;
            },
            decreaseCommentsCounter() {
                this.counters.comments--;
            },
            setAttendance(val) {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
                $rootScope.show_dimmer();
                CalendarEventsResource.set_attendance(
                    this.eventId,
                    this.dateMoment ? this.dateMoment.format(config.VISUAL_DATE_FORMAT) : undefined,
                    this.calendarOwnerId || CurrentUser.id,
                    val
                )
                    .then(resp => {
                        Vue.set(this.membershipStatus, 'attendance', val);
                        notifications.success('Attendance updated');
                    }, err => {
                        notifications.error('Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            unsetAttendance() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
                $rootScope.show_dimmer();
                CalendarEventsResource.unset_attendance(
                    this.eventId,
                    this.dateMoment ? this.dateMoment.format(config.VISUAL_DATE_FORMAT) : undefined,
                    this.calendarOwnerId || CurrentUser.id
                )
                    .then(resp => {
                        Vue.set(this.membershipStatus, 'attendance', null);
                        notifications.success('Attendance updated');
                    }, err => {
                        notifications.error('Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            onEventJoined() {
                this.loadEventData();
            },
            redirectToEventDeletedPage() {
                this.$router.replace({
                    name: 'event_does_not_occur',
                    params: {
                        date: this.date,
                        calendarOwnerId: this.calendarOwnerId,
                        calendarOwnerName: this.calendarOwnerName
                    },
                    hash: this.$route.hash
                });
            },
            redirectToNoAccessPage() {
                this.$router.replace({
                    name: 'event_no_access_page',
                    params: {
                        date: this.date,
                        calendarOwnerId: this.calendarOwnerId,
                        calendarOwnerName: this.calendarOwnerName
                    },
                    hash: this.$route.hash
                });
            },
            loadEventData() {
                this.loaded = false;
                const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                $rootScope.show_dimmer();
                CalendarEventsResource.get_event(
                    this.eventId,
                    this.dateMoment ? this.dateMoment.format(config.VISUAL_DATE_FORMAT) : undefined,
                    this.calendarOwnerId || CurrentUser.id
                )
                    .then(resp => {
                        this.event = resp.data.event_data.event;
                        this.membershipStatus = resp.data.event_data.membership_status;
                        this.eventAccessStatus = resp.data.status;
                        this.periodInfo = resp.data.event_data.period_info;
                        if (resp.data.status === 'OK' && resp.data.event_data.counters) {
                            this.counters.linkedGoals = resp.data.event_data.counters.linked_goals;
                            this.counters.comments = resp.data.event_data.counters.comments;
                            if (this.$store) {
                                this.$store.commit('communityPage/setEvent', this.event);
                            }
                        }
                        if (resp.data.status === 'DOES_NOT_OCCUR') {
                            this.redirectToEventDeletedPage();
                        }
                        if (resp.data.status === 'NO_ACCESS') {
                            this.redirectToNoAccessPage();
                        }
                        this.loaded = true;
                    }, err => {
                        notifications.error(err || 'Error');
                        $rootScope.hide_dimmer();
                    })
                    .catch(console.error)
                    .finally($rootScope.hide_dimmer);
            },
            loadEvent() {
                if (!this.dateMoment) {
                    const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
                    const $rootScope = Vue.getAngularModule('$rootScope');
                    const notifications = Vue.getAngularModule('notifications');
                    $rootScope.show_dimmer();
                    CalendarEventsResource.find_nearest_event_date(this.eventId)
                        .then(resp => {
                            const data = resp.data;
                            if (data.result === 'OCCURRENCE_FOUND' || data.result === 'ONE_TIME') {
                                const date = data.date;
                                this.$router.replace({
                                    name: 'info',
                                    params: {
                                        date: date,
                                        calendarOwnerId: this.calendarOwnerId,
                                        calendarOwnerName: this.calendarOwnerName
                                    },
                                    hash: this.$route.hash
                                });
                                this.$nextTick(this.loadEventData);
                            } else {
                                if (data.result === 'NO_OCCURRENCES') {
                                    this.redirectToEventDeletedPage();
                                }
                            }
                        }, err => {
                            notifications.error(err || 'Error');
                        });
                } else {
                    this.loadEventData();
                }
            },
            deleteEvent(data) {
                const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
                const notifications = Vue.getAngularModule('notifications');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const eventToDeleteId = this.periodInfo.event_id;
                $rootScope.show_dimmer();
                CalendarEventsResource.delete_event(eventToDeleteId, data)
                    .then(resp => {
                        this.goBack();
                    }, err => {
                        notifications.error(err || 'Error');
                        $rootScope.hide_dimmer();
                    });
            },
            showDeleteSelectorPopup() {
                return this.$refs['delete-repeat-event-popup'].show(this.dateMoment)
                    .then(choice => { // 'whole' or 'since' or 'exact'
                        this.deleteEvent({type: choice, date: this.dateMoment.format(config.VISUAL_DATE_FORMAT)});
                    });
            },
            onDeleteEvent() {
                if (this.periodInfo.is_periodic) {
                    this.showDeleteSelectorPopup();
                } else {
                    // const notifications = Vue.getAngularModule('notifications');
                    const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                    simplePopupFactory.show_popup('Delete Event', 'Are you sure you want to delete this Event?', 'Delete', 'Cancel', 'btn-red')
                        .then(y => {
                            this.deleteEvent({type: 'whole', date: this.dateMoment.format(config.VISUAL_DATE_FORMAT)});
                        });
                }
            },
            updateEvent(eventId, typeData, eventData) {
                const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                $rootScope.show_dimmer();
                CalendarEventsResource.update_event_data(eventId, typeData, eventData)
                    .then(resp => {
                        this.event.event_data = resp.data.event_data;
                        this.event.is_private = resp.data.is_private;
                        this.event.veto_conversation_posts = resp.data.veto_conversation_posts;
                        if (!this.event.is_periodic) {
                            this.event.start_date = resp.data.start_date;
                            this.event.end_date = resp.data.end_date;
                        }
                        notifications.success('Updated');
                    }, err => {
                        notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            editEventData() {
                const initialPreparedData = {
                    is_private: this.event.is_private,
                    is_periodic: this.event.is_periodic,
                    veto_conversation_posts: this.event.veto_conversation_posts,
                    endDate: this.event.end_date,
                    startDate: this.event.start_date,

                    allowToggleAdvancedTimeSchedule: this.allowToggleAdvancedTimeSchedule,
                    advancedTimeScheduleEnabled: this.event.event_data.advanced_time_schedule_enabled,
                    advancedTimeSchedule: this.event.event_data.advanced_time_schedule,
                    daysOfWeekForAdvancedSchedule: this.daysOfWeekForAdvancedSchedule,
                };
                if (this.event.event_data.start_time && this.event.event_data.end_time) {
                    initialPreparedData.time = {
                        start: {
                            hour: this.event.event_data.start_time.split(':')[0],
                            minute: this.event.event_data.start_time.split(':')[1],
                        },
                        end: {
                            hour: this.event.event_data.end_time.split(':')[0],
                            minute: this.event.event_data.end_time.split(':')[1],
                        }
                    };
                }
                this.$refs['edit-event-data-popup'].editEventData(
                    _.merge(initialPreparedData, this.event.event_data)
                )
                    .then((eventData) => {
                        const eventToUpdateId = this.periodInfo.event_id;
                        if (this.event.is_periodic) {
                            this.$refs['update-repeat-event-popup'].show(this.dateMoment)
                                .then(choice => { // 'whole' or 'since' or 'exact'
                                    this.updateEvent(eventToUpdateId, {
                                        type: choice,
                                        date: this.dateMoment.format(config.VISUAL_DATE_FORMAT)
                                    }, eventData);
                                });
                        } else {
                            this.updateEvent(eventToUpdateId, {
                                type: 'whole',
                                date: this.dateMoment.format(config.VISUAL_DATE_FORMAT)
                            }, eventData);
                        }
                    }, closed => {
                        if (closed === 'delete_event') {
                            this.onDeleteEvent();
                        }
                    });

            },
            onEventRecurrenceDataUpdate(recurrenceData) {
                const eventId = this.periodInfo.event_id;
                const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                $rootScope.show_dimmer();
                CalendarEventsResource.update_event_recurrence(eventId, recurrenceData)
                    .then(resp => {
                        if (!resp.data.is_current_date_match) {
                            this.goBack();
                        } else {
                            this.event.period_data = resp.data.period_data;
                            this.event.event_data = resp.data.event_data;
                            this.periodInfo.start_date = resp.data.start_date;
                            this.periodInfo.end_date = resp.data.end_date;
                            this.periodInfo.additional_period_info_available = resp.data.additional_period_info_available;
                            $rootScope.hide_dimmer();
                            notifications.success('Updated');
                        }
                    }, err => {
                        notifications.error('Error');
                        $rootScope.hide_dimmer();
                    });
            },
            linkGoal() {
                eventsBus.$emit(EVENTS_BUS_EVENTS.LINK_GOAL);
            },
            showInvitationSelector(whole = true) {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
                const $q = Vue.getAngularModule('$q');
                const selectFactory = Vue.getAngularModule('selectFactory');
                const popupConfig = {
                    header: 'Invite Users',
                    info: 'Select or de-select user from list to invite them to this event',
                    button_yes: 'Invite',
                    button_no: 'Cancel',
                    empty: 'You do not have any users to select'
                };
                if (this.isAdmin && this.event.community) {
                    popupConfig.additional_filters = [
                        {
                            text: 'Show all public Users',
                            value: false,
                            type: 'switch',
                            filter: true,
                            paramsWhenTrue: {
                                all_public: true
                            }
                        }
                    ];
                }
                selectFactory
                    .endpoint_selector(_.partial(CalendarEventsResource.get_users_for_invite, this.periodInfo.event_id, this.dateMoment.format(VISUAL_DATE_FORMAT), !whole), true, popupConfig)
                    .then(res => {
                        let new_checked_ids = res[0];
                        $rootScope.show_dimmer();
                        return CalendarEventsResource.invite_users(this.periodInfo.event_id, new_checked_ids, this.dateMoment.format(VISUAL_DATE_FORMAT), !whole);
                    }, () => new Promise(() => {
                    }))
                    .then(resp => {
                        notifications.success('Invited');
                    }, err => {
                        notifications.error(err || 'Error');
                    }).finally($rootScope.hide_dimmer);
            },
            inviteMember() {
                if (this.periodInfo.is_periodic) {
                    this.$refs['join-type-selector'].show(this.dateMoment,
                        'Invite members to Event',
                        `This is a repeated Event.
                            You can invite Users to every occurrence of this
                            Event or just this one
                        `)
                        .then(type => { // 'exact' or 'whole'
                            if (type === 'exact') {
                                this.showInvitationSelector(false);
                            }
                            if (type === 'whole') {
                                this.showInvitationSelector();
                            }
                        }, close => {
                        });
                } else {
                    this.showInvitationSelector();
                }
            }
        },
        mounted() {
            this.loadEvent();
            eventsBus.$on(EVENTS_BUS_EVENTS.UPDATE_EVENT_RECURRENCE_DATA, this.onEventRecurrenceDataUpdate);
            eventsBus.$on(EVENTS_BUS_EVENTS.INVITE_MEMBER, this.inviteMember);
        },
        beforeDestroy() {
            eventsBus.$off(EVENTS_BUS_EVENTS.UPDATE_EVENT_RECURRENCE_DATA, this.onEventRecurrenceDataUpdate);
            eventsBus.$off(EVENTS_BUS_EVENTS.INVITE_MEMBER, this.inviteMember);
        },
        metaInfo() {
            let title = 'Event';
            if (this.event && this.event.event_data && this.event.event_data.name) {
                title = this.event.event_data.name + ' - Event';
            }
            return {title};
        }
    };
</script>

<style scoped lang="scss">
  .event-detail-page {

  }
</style>