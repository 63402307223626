/**
 * Created by orion on 19.02.17.
 */
import _ from 'lodash';
import Vue from 'vue';
import simpleTextInputPopup from './simpleTextInputPopup.vue';
import OptonalRoleSetPopupController from "./role_popup/controller";
import SelectFromActionsPopup from './SelectFromActionsPopup.vue';
import SimplePopup from './SimplePopup';

simplePopupFactory.$inject = ['$uibModal', '$q'];

function simplePopupFactory($uibModal, $q) {
    return {
        show_popup, // +
        create_popup,
        simple_text_input_popup, // +
        optional_role_popup,
        mount_vue_popup, // +
        showSelectFromActionsPopup
    };

    function mount_vue_popup(popupComponent, attrs = {}, listeners = {}) {
        const fEl = document.activeElement;
        const divForPopup = document.createElement("div");
        document.body.appendChild(divForPopup);
        let el;
        const vm = new Vue({
            el: divForPopup,
            render(createElement) {
                el = createElement(popupComponent, {attrs, listeners});
                return el;
            },
            destroyed() {
            }
        });
        return {vm, popupComponent: el.componentInstance, fEl};
    }

    function object_to_resolve(obj) {
        /**
         * wraps object values to function which returns this values
         * @type {{}}
         * @example
         * {a:3} ==> {a:function(){return 3}}
         *
         */
        let resolve_obj = {};
        if (_.isObject(obj)) {
            _.forOwn(obj, (v, k) => {
                resolve_obj[k] = () => v;
            });
        } else {
            return;
        }
        return resolve_obj;
    }


    function create_popup(controller, template, resolve = undefined, animation = false, backdrop = 'static', size = undefined, controllerAs = undefined, options = {}) {

        let result = $q.defer();
        let modalInstance = $uibModal.open(Object.assign({
            animation,
            template,
            controller,
            resolve: object_to_resolve(resolve),
            size,
            backdrop,
            controllerAs,
            openedClass: 'modal-open-angular'  // no conflict with uiv
        }, options));
        modalInstance.result.then(
            (close_res) => {
                result.resolve(close_res);
            },
            (reason) => {
                result.reject(reason);
            });
        return result.promise;
    }

    function simple_text_input_popup(header, message, buttonYes, buttonNo, initial, maxLength, element = 'input', firstFocusToSave = false) { //element: input or textarea
        return new Promise((resolve, reject) => {
            const {vm, popupComponent, fEl} = mount_vue_popup(simpleTextInputPopup);
            popupComponent.show(header, message, buttonYes, buttonNo, initial, maxLength, element, firstFocusToSave)
                .then(text => {
                    resolve(text);
                }, c => {
                    reject();
                })
                .finally(() => {
                    fEl?.focus();
                    vm?.$destroy();
                });
        });
    }

    function optional_role_popup() { //element: input or textarea
        return create_popup(OptonalRoleSetPopupController, require('./role_popup/template.html'), {});
    }

    function show_popup(header, message, button_yes, button_no=undefined, button_yes_class = 'btn-green', size = undefined) {
        const {vm, popupComponent, fEl} = mount_vue_popup(SimplePopup);
        return new Promise((resolve, reject) => {
            popupComponent.show(header, message, button_yes, button_no, button_yes_class, size)
                .then(y => {
                    resolve(true);
                }, c => {
                    reject();
                })
                .finally(() => {
                    fEl?.focus();
                    vm?.$destroy();
                });
        });
    }


    function showSelectFromActionsPopup(actions, header) {
        let resolve, reject;

        const {vm, popupComponent} = mount_vue_popup(SelectFromActionsPopup);
        popupComponent.show(actions, header)
            .then(action => {
                resolve(action);
            }, reason => {
                reject(reason);
            })
            .finally(() => {
                if (vm) {
                    vm.$destroy();
                }
            });

        return new Promise((res, rej) => {
            resolve = res;
            reject = rej;
        });
    }
}

const spfInstance = simplePopupFactory();
export {spfInstance as simplePopupFactory};
export default simplePopupFactory;
