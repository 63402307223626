/**
 * Created by orion on 12.03.17.
 */
import urls from 'DjangoUrls';
import _ from 'lodash';

let {DjangoUrls} = urls;

Sharing.$inject = ['$http', '$log'];

function Sharing($http, $log) {
    return {
        get_sharing_main_status: get_sharing_main_status,
        get_friends_for_share: get_friends_for_share,
        make_private: make_private,
        get_communities_for_share: get_communities_for_share,
        share_with_friends: share_with_friends,
        share_with_communities: share_with_communities,
        share_with_groups: share_with_groups,
        get_groups_for_share: get_groups_for_share
    };

    function share_with_communities(sharer_id, what_type, what_pk, new_shares, remove_shares) {
        let data = {
            type: what_type,
            pk: what_pk,
            new_shares: new_shares,
            remove_shares: remove_shares
        };
        const url = DjangoUrls["api.v1:share-share-with-communities"](sharer_id);
        return $http({
            method: 'POST',
            url: url,
            data: data
        })
    }

    function share_with_groups(sharer_id, what_type, what_pk, new_shares, remove_shares) {
        let data = {
            type: what_type,
            pk: what_pk,
            new_shares: new_shares,
            remove_shares: remove_shares
        };
        const url = DjangoUrls["api.v1:share-share-with-groups"](sharer_id);
        return $http({
            method: 'POST',
            url: url,
            data: data
        })
    }

    function share_with_friends(sharer_id, what_type, what_pk, new_shares, remove_shares, all_friends) {
        let data = {
            type: what_type,
            pk: what_pk,
            new_shares: new_shares,
            remove_shares: remove_shares
        };
        if (all_friends !== undefined) {  // undef means that value have not been changed. else can be true or false
            data.all_friends = all_friends;
        }
        const url = DjangoUrls["api.v1:share-share-with-friends"](sharer_id);
        return $http({
            method: 'POST',
            url: url,
            data: data
        })
    }

    function get_communities_for_share(sharer_id, what_type, what_pk, params) {
        let data = {
            type: what_type,
            pk: what_pk
        };
        const url = DjangoUrls["api.v1:share-get-communities-for-share"](sharer_id);
        return $http({
            method: 'GET',
            url: url,
            params: _.merge(data, params)
        })
    }

    function get_groups_for_share(sharer_id, what_type, what_pk, params) {
        let data = {
            type: what_type,
            pk: what_pk
        };
        const url = DjangoUrls["api.v1:share-get-groups-for-share"](sharer_id);
        return $http({
            method: 'GET',
            url: url,
            params: _.merge(data, params)
        })
    }

    function make_private(sharer_id, what_type, what_pk) {
        let data = {
            type: what_type,
            pk: what_pk
        };
        const url = DjangoUrls["api.v1:share-make-private"](sharer_id);
        return $http({
            method: 'POST',
            url: url,
            data: data
        })
    }

    function get_friends_for_share(sharer_id, what_type, what_pk, params) {
        let data = {
            type: what_type,
            pk: what_pk
        };
        const url = DjangoUrls["api.v1:share-get-friends-for-share"](sharer_id);
        return $http({
            method: 'GET',
            url: url,
            params: _.merge(data, params)
        })
    }

    function get_sharing_main_status(sharer_id, what_type, what_pk) {
        let data = {
            type: what_type,
            pk: what_pk
        };
        const url = DjangoUrls["api.v1:share-get-sharing-main-status"](sharer_id);
        return $http({
            method: 'GET',
            url: url,
            params: data
        })
    }

}

export default Sharing