var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "main-container",
      style: [_vm.containerStyle],
      on: {
        dragenter: function ($event) {
          $event.preventDefault()
          return _vm.onDragEnter.apply(null, arguments)
        },
        dragover: function ($event) {
          $event.preventDefault()
        },
        dragstart: function ($event) {
          $event.preventDefault()
        },
        dragleave: _vm.onDragLeave,
        dragend: _vm.onDragEnd,
        drop: function ($event) {
          $event.preventDefault()
          return _vm.onDrop.apply(null, arguments)
        },
      },
    },
    [
      _vm.section
        ? _c(
            "a",
            {
              ref: "sectionAvatar",
              staticClass: "main-container__image border-group",
              class: {
                pointer: _vm.allowOpen,
                "main-container__image_active": _vm.isActive,
              },
              style: [_vm.imageStyle],
              attrs: {
                href: "javascript:;",
                tabindex: "0",
                "aria-label": _vm.section.caption,
              },
              on: {
                click: _vm.openSection,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.openSection.apply(null, arguments)
                },
                focus: _vm.activateSection,
              },
            },
            [
              _vm.section &&
              ((_vm.editMode && _vm.section.is_editable) ||
                _vm.section.audio_caption)
                ? _c(
                    "div",
                    {
                      staticClass: "main-container__delete_button",
                      style: _vm.deleteMarkStyle,
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.editOrAudioClick.apply(null, arguments)
                        },
                        touchstart: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.editOrAudioClick.apply(null, arguments)
                        },
                      },
                    },
                    [
                      !_vm.editMode
                        ? _c("audio-toggle", {
                            ref: "sectionAudioToggle",
                            attrs: {
                              "audio-caption": _vm.section.audio_caption,
                              "fa-class": "far",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.editMode && _vm.section.is_editable
                        ? _c("i", { staticClass: "far fa-pencil pointer" })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showNewMark || _vm.showChangedMark || _vm.showBothMarks
                ? _c(
                    "div",
                    {
                      staticClass: "main-container-image__unread-mark",
                      class: {
                        "main-container-image__unread-mark_changed":
                          _vm.showChangedMark,
                        "main-container-image__unread-mark_new":
                          _vm.showNewMark,
                        "main-container-image__unread-mark_both":
                          _vm.showBothMarks,
                      },
                      style: _vm.changedMarkStyle,
                    },
                    [
                      _vm.showChangedMark
                        ? _c("span", [_vm._v("UPD")])
                        : _vm._e(),
                      _vm.showNewMark ? _c("span", [_vm._v("NEW")]) : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.avatar && !_vm.mediaContentSamples.length
                ? _c("i", {
                    staticClass: "fa fa-image main-container-image__empty-icon",
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }