<template>
    <vue-simple-header separation-mode="sm" :right-part-size="6" :left-part-size="6" v-hotkey="keymap">
            <span slot="panelLeft">
                <slot></slot>
            </span>
        <span slot="panelRight">

                <vue-folder-button
                        v-if="groupButtonsNeeded&&!buttonsExpanded&&!editMode"
                        :no-text-on-mobile="true"
                        text="Show More"
                        icon-class="fa-ellipsis-h"
                        @click.native="buttonsExpanded=true"
                ></vue-folder-button>

                <vue-folder-button
                        :no-text-on-mobile="true"
                        text="Exports"
                        icon-class="fa-arrow-alt-down"
                        v-if="exportsAvailable&&!editMode"
                        v-show="buttonsExpanded||!groupButtonsNeeded"
                        :target="{name:'MainWikiPageReports',query:{from:backHrefFromParam}}"
                        is-router-link
                ></vue-folder-button>
                <vue-folder-button
                        :no-text-on-mobile="true"
                        text="Show Log"
                        icon-class="fa-file-contract"
                        @click.native="showWikiLog"
                        v-if="logAvailable&&!editMode"
                        v-show="buttonsExpanded||!groupButtonsNeeded"
                ></vue-folder-button>
                <vue-folder-button
                        color="brown"
                        :no-text-on-mobile="true"
                        :max-width="92"
                        text="Manage Access"
                        icon-class="fa-key"
                        @click.native="goToManageAccess"
                        v-if="manageAccessAvailable&&!editMode"
                        v-show="buttonsExpanded||!groupButtonsNeeded"
                ></vue-folder-button>

                <vue-folder-button
                        :no-text-on-mobile="true"
                        v-show="editMode&&!isMainSectionOpened&&wikiEditable"
                        @click.native="moveSectionsOrSlides"
                        :disabled="moveSectionsOrSlidesButtonDisabled"
                        text="Move"
                        icon-class="fas fa-arrow-from-top fa-rotate-180"
                ></vue-folder-button>
                <vue-folder-button
                        :no-text-on-mobile="true"
                        v-show="editMode&&createSectionVisible"
                        @click.native="createSection"
                        text="Create"
                        icon-class="fa-plus"
                ></vue-folder-button>
                <vue-folder-button
                        :no-text-on-mobile="true"
                        v-show="isWikiSharable&&!editMode"
                        @click.native="shareSection"
                        :text="shareButtonText"
                        icon-class="fa-share-square"
                ></vue-folder-button>
                <vue-folder-button
                        :no-text-on-mobile="true"
                        v-show="isEditable"
                        @click.native="toggleEditMode"
                        :text="editIconText"
                        :icon-class="editIconClass"
                        icon-prefix="far"
                >
                    <span
                        v-if="editMode"
                        slot="icon"
                        class="fa-stack"
                        style="width: 32px;"
                      >
                        <i class="far fa-pencil fa-stack-1x" style="position: absolute" />
                        <i class="far fa-slash fa-stack-1x fa-inverse" style="position: absolute" />
                      </span>
                </vue-folder-button>
                <vue-folder-button
                        color="blue"
                        text="Make Me Admin"
                        :no-text-on-mobile="true"
                        icon-class="fa-user-secret"
                        v-show="canBecomeWikiAdmin"
                        @click.native="makeMeAdmin()"
                ></vue-folder-button>

                <vue-folder-button
                        :no-text-on-mobile="true"
                        v-show="showBackButton"
                        @click.native="back"
                        color="blue"
                        text="Back"
                        icon-class="fa-reply"></vue-folder-button>
            </span>
    </vue-simple-header>
</template>

<script>
    import Vue from 'vue';
    import { CurrentUserClass } from '../shared/CurrentUser';
    import BackButtonMixin from '~/vue/mixins/BackButtonMixin';

    export default {
        name: 'WikiMainPageHeader',
        props: {
            showBackButton: {
                type: Boolean,
                default: false
            },
            allowCreate: {
                type: Boolean,
                default: true,
            }
        },
        mixins: [BackButtonMixin],
        components: {},
        data(){
          return {
              buttonsExpanded: false,
          }
        },
        computed: {
            keymap() {
                return {
                    'alt+s': this.shareSection,
                    'alt+b': this.back,
                };
            },
            editMode() {
                return this.$store.getters['WikiStore/editMode'];
            },
            moveSectionsOrSlidesButtonDisabled() {
                return this.$store.getters['WikiStore/moveSectionsOrSlidesButtonDisabled'];
            },
            createSectionVisible() {
                return this.$store.getters['WikiStore/createSectionVisible'];
            },
            viewerState() {
                return this.$store.getters['WikiStore/viewerState'];
            },
            wikiEditable() {
                return this.$store.getters['WikiStore/wikiEditable'];
            },
            isMainSectionOpened() {
                return this.$store.getters['WikiStore/isMainSectionOpened'];
            },
            isEditable() {
                return this.$store.getters['WikiStore/isEditable'];
            },
            currentSection() {
                return this.$store.getters['WikiStore/currentSection'];
            },
            isWikiSharable() {
                return this.$store.getters['WikiStore/isWikiSharable'];
            },
            canBecomeWikiAdmin() {
                return this.$store.getters['WikiStore/viewerState'].canBecomeWikiAdmin;
            },
            isSuperUser() {
                return new CurrentUserClass().is_superuser();
            },
            manageAccessAvailable() {
                const cu = new CurrentUserClass();
                if (cu.is_circle_user()) {
                    return false;
                }
                return this.viewerState.isWikiAdmin && this.isMainSectionOpened;
            },
            logAvailable() {
                return this.viewerState.isWikiAdmin;
            },
            exportsAvailable() {
                return this.manageAccessAvailable;
            },
            groupButtonsNeeded(){
              return this.logAvailable && this.exportsAvailable && this.manageAccessAvailable;
            },
            editIconClass() {
                return this.editMode ? 'fa-eye' : 'fa-pencil';
            },
            editIconText() {
                return this.editMode ? 'Finish Editing' : 'Edit';
            },
            shareButtonText() {
                if (!this.isWikiSharable) {
                    return '';
                }
                if (this.isMainSectionOpened) {
                    return 'Share';
                } else {
                    return 'Share';
                }
            },
        },
        methods: {
            back() {
                if (this.showBackButton) {
                    this.$emit('goBack');
                }
            },
            shareSection() {
                this.$store.dispatch('WikiStore/shareWikiOrSection');
            },
            makeMeAdmin() {
                this.$store.dispatch('WikiStore/makeMeAdmin');
            },
            createSection() {
                this.$store.dispatch('WikiStore/createSection');
            },
            showWikiLog() {
                this.$store.dispatch('WikiStore/showWikiLog');
            },
            goToManageAccess() {
                this.$emit('goToManageAccess');
            },
            moveSectionsOrSlides() {
                this.$store.dispatch('WikiStore/moveSectionsOrSlides', this.currentSection.id);
            },
            toggleEditMode() {
                this.$store.commit('WikiStore/toggleEditMode', !this.editMode);
            }
        },
        mounted() {
        }

    };
</script>

<style scoped>

</style>
