import Vue from 'vue';
// Is used on events goal page as well
const TASK_ACTION_BUS_ACTIONS = {
    QUICK_CREATE: 'quick_create',
    RUN_WIZARD: 'run_wizard',
    DELETE_TASK: 'delete_task',
    SET_ASSIGNEE: 'set_assignee',
    CLONE_FOR_MULTIPLE_ASSIGNEE: 'clone_for_multiple_assignee',
    SET_ASSIGNEES_IN_FILTER: 'set_assignees_in_filter',
    SET_TAGS_IN_FILTER: 'set_tags_in_filter',
};

const taskActionsBus = new Vue();
export {taskActionsBus, TASK_ACTION_BUS_ACTIONS};