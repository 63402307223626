<template>
    <div class="wiki-tree">
        <div class="wiki-tree-main-section">
            <div class="wiki-tree-main-section__expand-icon-container">
                <i
                        class="far wiki-tree-main-section__icon pointer"
                        :class="{
                            'fa-caret-down':mainSectionExpanded,
                            'fa-caret-right':!mainSectionExpanded,
                        }"
                        @click="toggleMainSectionExpand"
                ></i>
            </div>
            <i
                    class="far fa-check wiki-tree-main-section__icon"
                    :class="checkButtonClasses"
                    @click="toggleWikiIncluded"
            ></i>
            <div
                    class="wiki-tree-main-section__owners-avatar"
                    :style="ownerAvatarStyle"
            ></div>
            <strong>{{ mainTitle }}</strong>
        </div>
        <div class="wiki-tree-subsections__container"
             v-if="mainSectionExpanded"
        >
            <wiki-receipt-tree-section
                    :section="section"
                    :inherited-permission-level="resultPermissionLevel"
                    :permissions-list="permissionsList"
                    :included-stuff-config="includedStuffConfig"
                    @input="onIncludedStuffConfigUpdate"
                    v-for="section in sections"
                    :key="section.id"
            ></wiki-receipt-tree-section>
        </div>
    </div>
</template>

<script>
    import WikiReceiptTreeSection from "./WikiReceiptTreeSection";
    import TreePermissionDetectionMixinWholeWiki
        from "../tree_permission_detection_mixins/TreePermissionDetectionMixinWholeWiki";
    import {WIKI_PERMISSION_LEVEL, WIKI_PERMISSION_VALUE} from "../../../wiki_page/common/wikiPermissions";

    export default {
        name: "WikiReceiptTree",
        components: {WikiReceiptTreeSection},
        mixins: [TreePermissionDetectionMixinWholeWiki],
        props: {
            sections: {
                type: Array,
            },
            permissionsList: Array,
            includedStuffConfig: Object,
        },
        data() {
            return {
                mainSectionExpanded: true,

            };
        },
        computed: {
            mainTitle() {
                return 'Wiki Content Page';
            },
            ownerAvatarStyle() {
                return {
                    'background-image': `url('/static/images/new_daschboard_icons/wiki4f1.png')`
                };
            },
            checkButtonDisabled() {
                return ![WIKI_PERMISSION_VALUE.VIEW, WIKI_PERMISSION_VALUE.EDIT].includes(this.resultPermissionLevel);
            },
            checkButtonClasses() {
                const classes = [];
                if (this.checkButtonDisabled) {
                    classes.push('fa-eye-slash');
                    if (this.hasRedefinedInChildPermission) {
                        classes.push('color-yellow');
                    } else {
                        classes.push('color-gray');
                    }
                } else {
                    classes.push('fa-check');
                    classes.push('pointer');
                    if (this.includedStuffConfig.whole_wiki) {
                        classes.push('color-grass-green');
                    }
                }
                return classes;
            },
            hasRedefinedInChildPermission() {
                if (this.checkButtonDisabled) {
                    for (const p of this.permissionsList) {
                        if (
                            [WIKI_PERMISSION_LEVEL.SECTION, WIKI_PERMISSION_LEVEL.SLIDE].includes(p.level)
                            &&
                            [WIKI_PERMISSION_VALUE.VIEW, WIKI_PERMISSION_VALUE.EDIT].includes(p.value)
                        ) {
                            return true;
                        }
                    }
                }
                return false;
            },
        },
        methods: {
            toggleWikiIncluded() {
                if (this.checkButtonDisabled) {
                    return;
                }
                this.includedStuffConfig.whole_wiki = !this.includedStuffConfig.whole_wiki;
                this.onIncludedStuffConfigUpdate(this.includedStuffConfig);
            },
            toggleMainSectionExpand() {
                this.mainSectionExpanded = !this.mainSectionExpanded;
            },
            onIncludedStuffConfigUpdate(includedStuffConfig) {
                this.$emit('input', includedStuffConfig);
            },
        }
    };
</script>

<style scoped lang="scss">
  .wiki-tree-main-section {
    &__owners-avatar {
      display: inline-block;
      vertical-align: middle;
      background-size: cover;
      width: 50px;
      height: 50px;
    }

    &__icon {
      vertical-align: middle;
      font-size: 25px;
      margin: 0 4px;
      transition: color 0.2s ease;
    }

    &__expand-icon-container {
      vertical-align: middle;
      display: inline-block;
      min-width: 25px;
      text-align: center;
    }
  }

  .wiki-tree-subsections {
    &__container {
      padding-left: 36px;
    }
  }
</style>