var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.header)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("symbol-password-form", {
        attrs: { "max-symbols-count": _vm.maxSymbolsCount },
        model: {
          value: _vm.inputedSymbols,
          callback: function ($$v) {
            _vm.inputedSymbols = $$v
          },
          expression: "inputedSymbols",
        },
      }),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "col-xs-6 text-left" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-mm btn-default",
              attrs: { type: "button", disabled: _vm.resetButtonDisabled },
              on: { click: _vm.reset },
            },
            [_vm._v("Reset\n            ")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-6 text-right" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-mm btn-green",
              attrs: { type: "button", disabled: _vm.okButtonDisabled },
              on: { click: _vm.ok },
            },
            [_vm._v("OK\n            ")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }