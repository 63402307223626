<template>
    <dropdown ref="dropdown"
              :not-close-elements="ele"
              v-model="opened"
              class="task-complete__dropdown-form"
              style="display: block"

    >
        <template slot="dropdown">
            <div class="text-center task-complete__dropdown-form__header" style="padding: 3px">
                {{ "Task"|customTerm }} completed
            </div>
            <li class="task-complete__dropdown-form__option"
                :tabindex="0"
                v-for="(supportOption, i) in supportOptions"
                :ref="'supportOption'+i"
                :class="{active:getIsActive(supportOption)}"
                role="menuitemcheckbox"
                :aria-checked="getIsActive(supportOption)?'true':'false'"
                @click="toggleOption(supportOption)"
                @keydown.enter="toggleOption(supportOption)"
            >
                <i class="fal "
                   :class="{'fa-square':!getIsActive(supportOption),'fa-check-square':getIsActive(supportOption)}"
                ></i>
                <span>
                    {{ supportOption.name }}
                </span>
            </li>
            <li class="task-complete__dropdown-form__option task-complete__save-button"
                :class="{'task-complete__save-button_disabled': saveDisabled}"
                tabindex="0"
                @click="save(true)"
                @keydown.enter="save(true)"
            >
                <i class="fal fa-check"></i>
                <span>
                    Save
                </span>
            </li>
            <li v-show="allowIncomplete" class="task-complete__dropdown-form__option"
                tabindex="0"

                @click="incomplete(true)"
                @keydown.enter="incomplete(true)"
            >
                <i class="fal fa-times"></i>
                <span>
                    Not completed
                </span>
            </li>
        </template>
    </dropdown>
</template>

<script>

    import {
        PROVIDED_ASSISTANCE_OPTIONS_LIST,
        PROVIDED_ASSISTANCE_OPTIONS_LABELS,
        ASSISTANCE_EXCLUSIVE_GROUP, PROVIDED_ASSISTANCE_OPTIONS_VALUES
    } from "../../../../config";

    export default {
        name: "GoalTaskCompleteDropdown",
        props: {
            checkBoxStyle: Object,

        },
        data() {
            return {
                opened: false,
                ele: [],
                selectedSupportOptions: [],
                PROVIDED_ASSISTANCE_OPTIONS_LABELS,
                allowIncomplete: false,

                availableCompletionOptions: [],
                providedAssistance: [],
            };
        },
        mounted() {
            //this.ele.push(this.$refs.dropdown.$el);
        },
        computed: {
            saveDisabled() {
                return !this.selectedSupportOptions.includes(PROVIDED_ASSISTANCE_OPTIONS_VALUES.TASK_ACHIEVED) &&
                    !this.selectedSupportOptions.includes(PROVIDED_ASSISTANCE_OPTIONS_VALUES.TASK_EXCEEDED) &&
                    !this.selectedSupportOptions.includes(PROVIDED_ASSISTANCE_OPTIONS_VALUES.TASK_NOT_ACHIEVED);
            },
            supportedOptionsSelected() {
                return this.selectedSupportOptions.length > 0;
            },
            supportOptions() {
                const res = [];
                for (const option of this.availableCompletionOptions) {
                    res.push({
                        code: option.code,
                        name: option.name || PROVIDED_ASSISTANCE_OPTIONS_LABELS[option.code]
                    });
                }
                const availableCompletionOptionsCodes = this.availableCompletionOptions.map(o => o.code);
                for (const option of this.providedAssistance) {
                    if (!availableCompletionOptionsCodes.includes(option.code)) {
                        res.push({
                            code: option.code,
                            name: option.name || PROVIDED_ASSISTANCE_OPTIONS_LABELS[option.code]
                        });
                    }
                }
                return res;
            }
        },
        methods: {
            focusOnFirstOption(){
                this.$refs.supportOption0[0]?.focus();
            },
            resetData() {
                this.selectedSupportOptions = [];
            },
            show(allowIncomplete = false, providedAssistance = [], availableCompletionOptions = []) {
                // if (!this.ele.includes(triggerEl)) {
                //     this.ele.push(triggerEl);
                // }
                // this.resetData();
                this.availableCompletionOptions = availableCompletionOptions;
                this.providedAssistance = providedAssistance;
                this.allowIncomplete = allowIncomplete;
                this.selectedSupportOptions = providedAssistance ? providedAssistance.map(o => o.code) : [];
                this.$refs.dropdown.toggle(true);
                // console.log('show');
                // this.$nextTick(() => {
                //     this.opened = true;
                // });
                // this.opened = true;
                return new Promise((res, rej) => {
                    this.resolve = res;
                    this.reject = rej;
                });
            },
            hide() {
                this.$refs.dropdown.toggle(false);
            },
            toggleOption(supportOption) {
                if (this.selectedSupportOptions.includes(supportOption.code)) {
                    this.selectedSupportOptions.splice(this.selectedSupportOptions.indexOf(supportOption.code), 1);
                } else {
                    if (ASSISTANCE_EXCLUSIVE_GROUP.includes(supportOption.code)) {
                        for (let option of ASSISTANCE_EXCLUSIVE_GROUP) {
                            const index = this.selectedSupportOptions.indexOf(option);
                            if (index !== -1) {
                                this.selectedSupportOptions.splice(index, 1);
                            }
                        }
                    }
                    this.selectedSupportOptions.push(supportOption.code);
                }
            },
            getIsActive(supportOption) {
                return this.selectedSupportOptions.includes(supportOption.code);
            },
            save(withSupport) {
                if(this.saveDisabled){
                    return;
                }
                this.opened = false;
                this.resolve(
                    {
                        result: 'complete',
                        withSupport,
                        options: this.selectedSupportOptions.map(o => o),
                    }
                );
            },
            incomplete(withSupport) {
                this.opened = false;
                this.resolve(
                    {
                        result: 'incomplete',
                    }
                );
            },
            addIgnoredElements(ignoredElements) {
                for (let triggerEl of ignoredElements) {
                    if (!this.ele.includes(triggerEl)) {
                        this.ele.push(triggerEl);
                    }
                }
            },
            resetIgnoredElements() {
                this.ele = [];
            }
        }
    };
</script>

<style lang="scss">
    @import "../../../../../styles/const";

    .task-complete {

        &__save-button{
            &_disabled{
                color: $gray;
                cursor: not-allowed !important;
                &:hover, &:active {
                    background-color: $tag-gray!important;
                    color: white;
                }
            }
        }

        &__dropdown-form {
            color: $black;
            z-index: 1022;

            &__header {
                border-bottom: solid 1px $tag-gray;
                background-color: $lightgray;
                border-radius: 5px 5px 0 0;
            }

            .dropdown-menu {
                /*padding: 5px;*/
                margin-top: -3px;
                font-size: 16px;
                width: 230px !important;
                padding: 0 0 5px 0;
                font-weight: bolder;
            }

            &.btn-group.open {
                .dropdown-toggle {
                    box-shadow: none;
                }
            }

            &__option {
                .fal {
                    width: 14px;
                    text-align: center;
                }

                display: block;
                width: 100%;
                min-height: 40px;

                &:not(:last-child) {
                    border-bottom: solid 1px $tag-gray;
                }

                cursor: pointer;

                &:hover, &:active {
                    background-color: $dark-blue;
                    color: white;
                }

                &.active {
                    font-weight: bolder;
                }

                font-weight: normal;
                padding: 5px 5px 0 5px;
            }
        }
    }
</style>