import urls from 'DjangoUrls';
import axios from "axios";
let _ = require('lodash');
let {DjangoUrls} = urls;
emailValidationResource.$inject = ['$http', '$log'];

function emailValidationResource($http, $log) {
    return {
        check_email_is_taken(email) {
            const url = DjangoUrls[`api.v1:reg_validation-email`]();
            return axios({
                method: 'GET',
                url,
                params: {email}
            })
        },
    };
}

export default emailValidationResource
