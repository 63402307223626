export default {
    methods: {
        getRouterAvailability(link) {
            if (this.$router?.resolve && this.$router.resolve(link)?.resolved?.matched) {
                const matched = this.$router.resolve(link).resolved.matched;
                if (!matched.length) {
                    return false;
                } else {
                    for (const mi of matched) {
                        if (!mi.meta.isErrorPage) {
                            return true;
                        }
                    }
                }
            }
            return false;
        }
    }
};