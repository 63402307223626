var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.slideSnapshotAfter
    ? _c(
        "span",
        [
          _c("span", [_vm._v("updated page ")]),
          _vm._v(" "),
          _c("strong", [
            _vm._v(
              "\n        #" + _vm._s(_vm.slideSnapshotAfter.id) + "\n        "
            ),
            _vm.slideSnapshotAfter.caption
              ? _c(
                  "span",
                  [
                    _c("wiki-log-possible-link", {
                      attrs: {
                        target: _vm.wikiLog.target_wiki_slide,
                        label: _vm.slideSnapshotAfter.caption,
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "span",
                  [
                    _c("wiki-log-possible-link", {
                      attrs: {
                        target: _vm.wikiLog.target_wiki_slide,
                        label: `#${_vm.slideSnapshotAfter.id}`,
                        "no-quotes": "",
                      },
                    }),
                  ],
                  1
                ),
          ]),
          _vm._v(" "),
          _vm.slideSnapshotAfter.section_name
            ? _c("span", [
                _vm._v("\n        in "),
                _c(
                  "strong",
                  [
                    _vm._v(
                      "#" +
                        _vm._s(_vm.slideSnapshotAfter.section) +
                        "\n        "
                    ),
                    _c("wiki-log-possible-link", {
                      attrs: {
                        target: _vm.wikiLog.target_wiki_section,
                        label: _vm.slideSnapshotAfter.section_name,
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.verbalChangesSubtypes
            ? _c("span", [
                _vm._v("(" + _vm._s(_vm.verbalChangesSubtypes) + ")"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.wikiLog.image_preview
            ? _c("wiki-log-image-preview", {
                attrs: { "wiki-log": _vm.wikiLog },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }