import urls from 'DjangoUrls';

let {DjangoUrls} = urls;
CirclesListResource.$inject = ['$http'];

function CirclesListResource($http) {
    return {
        get_circles_i_manage,
    };

    function get_circles_i_manage(params) {
        const url = DjangoUrls["api.v1:buddy_users_lists-get-circles-i-manage"]();
        return $http({
            method: 'GET',
            url,
            params
        });
    }
}


export default CirclesListResource;