import AddFromMyMedia from "./AddFromMyMedia";
import addMediaFromFolderPopup from "./addMediaFromFolderPopup";

addMediaFromFolderFactory.$inject = ['$rootScope', 'AddFromMyMedia', 'simplePopupFactory'];

function addMediaFromFolderFactory($rootScope, AddFromMyMedia, simplePopupFactory) {

    function add_media(callback, filter, limit, cancelCallback = _.noop) {
        const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(addMediaFromFolderPopup);
        popupComponent.show(filter, limit)
            .then(
                res => {
                    callback(res);
                },
                cancel => {
                    cancelCallback();
                }
            )
            .finally(() => {
                fEl?.focus();
                vm?.$destroy();
            });
    }

    return {
        add_media: add_media  // filter in [ 'audio', 'image', 'document', 'video', 'folder' ] . limit in 1...~8 . callback returns list of media items.
    };
}

export default addMediaFromFolderFactory;