var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chat-style-comment-reply", style: _vm.commentStyle },
    [
      _vm.showAuthor
        ? _c(
            "div",
            [
              _vm._t("header", function () {
                return [
                  _c("strong", [_vm._v(_vm._s(_vm.authorName))]),
                  _vm._v(" "),
                  _vm._t("actionDescription", function () {
                    return [_vm._v("wrote:")]
                  }),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.comment.sticker
        ? _c(
            "div",
            { staticStyle: { "font-size": "0" } },
            [
              _c("render-sticker", {
                attrs: { sticker: _vm.comment.sticker, "default-size": 50 },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.commentTextPreview
        ? _c("div", { staticStyle: { "white-space": "pre-line" } }, [
            _vm._v(_vm._s(_vm.commentTextPreview)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.comment.media_attachment
        ? _c(
            "div",
            { staticStyle: { color: "#333" } },
            [
              _c("media-preview", {
                attrs: { item: _vm.comment.media_attachment, size: 80 },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.comment.emotion
        ? _c(
            "div",
            [
              _c("entry-emotion", {
                attrs: { emotion: _vm.comment.emotion, size: 30 },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.allowExpand && !_vm.expanded && _vm.expandRequired
        ? _c(
            "div",
            { staticClass: "chat-style-comment-reply__expand-button" },
            [
              _c(
                "a",
                { attrs: { href: "javascript:;" }, on: { click: _vm.showAll } },
                [
                  _c("i", { staticClass: "fal fa-expand-alt" }),
                  _vm._v(" show all"),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }