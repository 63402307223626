import Vue from "vue";
import VueRouter from "vue-router";
import UserActivityPostCommentsPage from "./UserActivityPostCommentsPage.vue";

UserActivityPostCommentsController.$inject = ['$scope', '$preloaded'];

function UserActivityPostCommentsController($scope, $preloaded) {

    function initVue() {
        const vm = new Vue({
            el: '#story_comments',
            router: new VueRouter({
                mode: 'history',
                routes: [],
            }),
            render(createElement) {
                const attrs = {};
                attrs['postId'] = $preloaded.post_id;
                attrs['isAdmin'] = $preloaded.is_owner;
                return createElement(UserActivityPostCommentsPage, {attrs});
            },
        });
        $scope.$on('$destroy', () => {
            if (vm) {
                vm.$destroy();
            }
        });
    }
    initVue();
}

export default UserActivityPostCommentsController;