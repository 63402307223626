<template>
    <div class="container">
        <div class="row row-centered">
            <div class="col-sm-3 col-centered"></div>
            <div class="col-sm-6 col-centered">
                <div class="panel panel-primary login-page-box margin-5-bottom">
                    <div class="panel-heading">
                        <h3 class="panel-title">Password Required</h3>
                    </div>
                    <div class="panel-body">
                        <div class="alert alert-info margin-15-bottom">
                            This access link is password protected. The password is included in the email sent to you
                            by Multi Me. If someone else shared this link with you then please ask them for the password.
                        </div>
                        <form @submit.stop.prevent="submit">
                            <div :class="{'has-error':error,'has-feedback':error}"
                                 class="form-group">
                                <label class="control-label" for="password">Password:</label>
                                <input required v-model="password" type="password" class="form-control" id="password"
                                       aria-describedby="helpBlock2" autofocus>
                                <span id="helpBlock2" v-if="error" class="help-block">{{ error }}</span>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 text-right">
                                    <button type="submit" class="btn btn-mm btn-green">Sign in</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            <div class="col-sm-3 col-centered">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AccessLinkAuthForm',
        props: {
            id: {
                type: [String, Number],
                required: true
            },
            accessCode: {
                type: String,
                required: true
            },
            accessLinkCheckEndpoint: {
                type: Function,
                required: true,
            }
        },
        data () {
            return {
                password: '',
                error: undefined,
                loggingIn: false,
            };
        },
        methods: {
            async submit () {
                this.$loadingSpinner.show('loggingIn');
                try {
                    this.loggingIn = true;
                    const resp = await this.accessLinkCheckEndpoint(this.id, this.accessCode, this.password);
                    if (resp.data.token) {
                        this.$emit('tokenObtained', resp.data.token);
                    }
                } catch (e) {
                    if (e.response?.data?.detail) {
                        this.error = e.response.data.detail;
                    }
                }
                this.loggingIn = false;
                this.$loadingSpinner.hide('loggingIn');
            },
            async resetErrors () {
                this.error = undefined;
            },
            async onFieldChange () {
                await this.resetErrors();
            },
        }
    };
</script>

<style scoped>

</style>
