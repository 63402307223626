<template>
    <div :class="{'active-filter': isActive}"
         class="folder-filter__container">
        <div class="folder-filter__icon">
            <i class="fa" :class="icon" aria-hidden="true"></i>
        </div>
        <div class="folder-filter__label hidden-xs">
            {{ text }}
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            isActive: Boolean,
            icon: String,
            text: String,
        },
        name: "UsersMediaPageFilterPanelButton"
    };
</script>

<style scoped lang="scss">
    @import "../../../styles/const";

    $icon_size: 60px;
    $icon_mobile: 45px;

    .folder-filter {
        &__container {
            vertical-align: top;
            @media (min-width: 1200px) {
                margin: 0 5px 5px 5px;
            }
            @media (max-width: 1199px) {
                margin: 2px;
            }

            display: inline-block;
            cursor: pointer;
            &.active-filter {
                .folder-filter__icon {
                    color: #fff;
                    background-color: $grass-green;
                    border-color: $grass-green-dark;
                }
                .folder-filter__label {
                    font-weight: bolder;
                }
            }
        }

        &__icon {
            margin: auto;
            @media all and (min-width: 520px) {
                width: $icon_size;
                height: $icon_size;
            }
            @media all and (max-width: 519px) {
                width: $icon_mobile;
                height: $icon_mobile;
            }
            background-color: #fff;
            border-radius: 5px;
            border-style: solid;
            border-width: 2px;
            border-color: $tag-gray-dark;
            color: $tag-gray;
            /*transition: all ease 0.2s;*/
            @media all and (min-width: 520px) {
                font-size: $icon_size - 22px;
            }
            @media all and (max-width: 519px) {
                font-size: $icon_mobile - 17px;
            }
            text-align: center;

        }
        &__label {
            width: 100%;
            text-align: center;
        }
    }

</style>