<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="header"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ header }}</h3>
        </div>
        <div class="alert alert-info" v-if="isFutureEvent">
            <strong>Note:</strong> You are creating a Diary entry for a <strong>future</strong> Event, the entry will
            appear in the Diary on <strong>today's</strong> date. A link to the entry will also appear on the Event
            page.
        </div>
        <div class="alert alert-info" v-if="isPastEvent">
            <strong>Note:</strong> You are creating a Diary entry for a <strong>past</strong> Event, the entry will
            appear in the Diary on the <strong>date of the Event</strong>. A link to the entry will also appear on the
            Event page.
        </div>
        <div class="create-entry-popup">
            <tabs v-model="openedTab">
                <tab title="Multimedia Blog">
                    <div class="">
                        <event-detail-page-create-diary-entry-popup-blog-part
                                @hide="tmpHide"
                                @show="tmpShow"
                                @toggleSaveReady="toggleSaveReady"
                                @selectedSUListUpdated="selectedSUListUpdated"
                                @addToSuDiariesChanged="addToSuDiariesChanged"
                                :membership-status="membershipStatus"
                                :calendar-owner="calendarOwner"
                                :period-info="periodInfo"
                                :date-moment="dateMoment"
                                :buddy-mode="buddyMode"
                                :can-post-in-other-diaries="canPostInOtherDiaries"
                                ref="blog-part"
                        ></event-detail-page-create-diary-entry-popup-blog-part>
                    </div>
                </tab>
                <tab :title="tabGoalsTitle">
                    <div class="create-entry-popup__content-container">
                        <event-detail-page-create-diary-entry-popup-goals-part
                                @linkedTasksCountChanged="linkedTasksCountChanged"
                                @hide="tmpHide"
                                @show="tmpShow"
                                ref="goals-part"
                                :calendar-owner="calendarOwner"
                                :event-id="periodInfo.event_id"
                                :buddy-mode="buddyMode"
                                :calendar-owner-is-own-events-admin="calendarOwnerIsOwnEventsAdmin"
                                :add-to-su-diaries="addToSuDiaries"
                                :profilesToCreateProxyEntries="profilesToCreateProxyEntries"
                        ></event-detail-page-create-diary-entry-popup-goals-part>
                    </div>
                </tab>
                <tab title="Log Notes" v-if="editableLocationsData.length">
                    <div class="create-entry-popup__content-container">
                        <event-detail-page-create-diary-entry-popup-logs-part
                                :calendar-owner="calendarOwner"
                                ref="locations-part"></event-detail-page-create-diary-entry-popup-logs-part>
                    </div>
                </tab>
            </tabs>
        </div>
        <div slot="footer">
            <button @click="close" class="btn btn-mm">Cancel</button>
            <div class="inline-block" data-role="trigger" v-tooltip="saveButtonTooltip">
                <button @click="saveEntry" :disabled="!saveButtonEnabled()"
                        class="btn btn-mm btn-green">Create Entry
                </button>
            </div>

        </div>
    </basic-modal>
</template>

<script>
    import Vue from 'vue';
    import moment from 'moment';
    import EventDetailPageCreateDiaryEntryPopupBlogPart from './EventDetailPageCreateDiaryEntryPopupBlogPart.vue';
    import EventDetailPageCreateDiaryEntryPopupLogsPart from './EventDetailPageCreateDiaryEntryPopupLogsPart.vue';
    import EventDetailPageCreateDiaryEntryPopupGoalsPart from './EventDetailPageCreateDiaryEntryPopupGoalsPart.vue';
    import EventDetailSubPageMixin from "./EventDetailSubPageMixin";
    import {TIME_ZONE, URL_DATE_FORMAT, VISUAL_DATE_FORMAT} from "../new_calendar/config";
    import {TODAY} from "../new_calendar/common";
    import * as config from "../new_calendar/config";
    import PopupPromiseMixin from "../../../../vue/mixins/PopupPromiseMixin";
    import BasicModal from "../../../../vue/common_components/BasicModal";

    export default {
        name: "EventDetailPageCreateDiaryEntryPopup",
        components: {
            BasicModal,
            EventDetailPageCreateDiaryEntryPopupBlogPart,
            EventDetailPageCreateDiaryEntryPopupLogsPart,
            EventDetailPageCreateDiaryEntryPopupGoalsPart
        },
        mixins: [EventDetailSubPageMixin, PopupPromiseMixin],
        data() {
            return {
                date: undefined,

                editableLocationsData: [],
                diaryPartReadyToSave: false,
                openedTab: 0,

                canPostInOtherDiaries: false,
                addToSuDiaries: false,
                profilesToCreateProxyEntries: [],

                linkedTasksCount: 0,

            };
        },
        computed: {
            firstFocusEl() {
                return this.$refs.closeButton;
            },
            tabGoalsTitle() {
                let title = `Linked ${this.$customTerm('Goals')}`;
                if (this.linkedTasksCount) {
                    title += ` (${this.linkedTasksCount})`;
                }
                return title;
            },
            todayDateMoment() {
                return moment.tz(TODAY, URL_DATE_FORMAT, TIME_ZONE);
            },
            isFutureEvent() {
                return moment.tz(this.event.start_date, VISUAL_DATE_FORMAT, TIME_ZONE).diff(this.todayDateMoment) > 0;
            },
            isPastEvent() {
                return moment.tz(this.event.end_date, VISUAL_DATE_FORMAT, TIME_ZONE).diff(this.todayDateMoment) < 0;
            },
            dateMomentToCreateEntry() {
                if (this.isPastEvent) {
                    return moment.tz(this.event.start_date, VISUAL_DATE_FORMAT, TIME_ZONE);
                } else {
                    return this.todayDateMoment;
                }
            },
            header() {
                if (this.buddyMode) {
                    return `Create Entry in ${this.calendarOwner.name}'s Diary`;
                } else {
                    return `Create Diary Entry`;
                }
            },
            saveButtonTooltip() {
                if (!this.saveButtonEnabled()) {
                    if (this.editableLocationsData.length && this.$refs['locations-part'] !== undefined) {
                        return 'Add some content to Multimedia Blog or Log Notes';
                    }
                    if (!this.diaryPartReadyToSave) {
                        return `Add some content to Multimedia Blog`;
                    }
                }
            }

        },
        methods: {
            addToSuDiariesChanged(val) {
                this.addToSuDiaries = val;
            },
            linkedTasksCountChanged(val) {
                this.linkedTasksCount = val;
            },
            saveButtonEnabled() {
                if (this.diaryPartReadyToSave) {
                    return true;
                }
                if (this.editableLocationsData.length && this.$refs['locations-part'] !== undefined) {
                    let locationsData = this.$refs['locations-part'].dumpData();
                    for (let i in locationsData.locations_text_list) {
                        if (locationsData.locations_text_list[i]) {
                            return true;
                        }
                    }
                }
                return false;
            },
            toggleSaveReady(isReady) {
                this.diaryPartReadyToSave = isReady;
            },
            loadUsersToCreateDiaryEntries() {
                return new Promise((res, rej) => {
                    const CurrentUser = Vue.getAngularModule('CurrentUser');
                    if (!this.buddyMode && CurrentUser.can_create_diary_proxy_entries) {
                        const CalendarEventBlogEntriesResource = Vue.getAngularModule('CalendarEventBlogEntriesResource');
                        CalendarEventBlogEntriesResource.get_all_users_to_create_proxy_entries_in_event(this.periodInfo.event_id, this.calendarOwner.id, this.dateMoment.format(config.VISUAL_DATE_FORMAT))
                            .then(resp => {
                                res(resp.data);
                            }, err => {
                                Vue.notifications.error(err || 'Error');
                                rej('cant load supported users');
                            });
                    } else {
                        res([]);
                    }
                });
            },
            loadPreLinkedTasks(profilesIdList) {
                if (!this.membershipStatus.owner_has_access_to_event) {
                    return;
                }
                const CalendarEventTaskLinksResource = Vue.getAngularModule('CalendarEventTaskLinksResource');
                CalendarEventTaskLinksResource.load_pre_linked_tasks(this.calendarOwner.id, this.periodInfo.event_id, this.dateMoment.format(VISUAL_DATE_FORMAT), profilesIdList)
                    .then(resp => {
                        this.$refs['goals-part'].addLinks(resp.data);
                    });
            },
            loadAvailableLogs(additionalIdList = []) {
                const BlogResource = Vue.getAngularModule('BlogResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                $rootScope.show_dimmer();
                const self = this;
                BlogResource.get_editable_diary_locations([this.calendarOwner.id, ...additionalIdList])
                    .then(resp => {

                        this.editableLocationsData = resp.data;
                        this.$nextTick(() => {
                            this.tmpShow();
                            if (self.$refs['locations-part']) {
                                self.$refs['locations-part'].setLocations(resp.data);
                            }
                        });
                        $rootScope.hide_dimmer();

                    }, err => {
                        notifications.error(err || 'Error');
                        $rootScope.hide_dimmer();
                    })
                    .catch(console.error);
            },
            selectedSUListUpdated(usersList) {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                this.loadAvailableLogs(usersList.map(u => u.id));
                this.loadPreLinkedTasks([CurrentUser.id, ...usersList.map(u => u.id)]);
                this.profilesToCreateProxyEntries = usersList;
            },
            showedHook() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                this.loadUsersToCreateDiaryEntries()
                    .then(profilesToCreateProxyEntries => {
                        const CurrentUser = Vue.getAngularModule('CurrentUser');
                        const idListToLoadPreLinkedUsers = [CurrentUser.id, ...profilesToCreateProxyEntries.map(p => p.id)];
                        this.$refs['blog-part'].setUsersToAddToDiary(profilesToCreateProxyEntries);
                        this.profilesToCreateProxyEntries = profilesToCreateProxyEntries;
                        this.canPostInOtherDiaries = !!profilesToCreateProxyEntries.length;
                        this.addToSuDiaries = !!profilesToCreateProxyEntries.length;
                        this.loadAvailableLogs(profilesToCreateProxyEntries.map(u => u.id));
                        this.loadPreLinkedTasks(idListToLoadPreLinkedUsers);
                    }, err => {
                        $rootScope.hide_dimmer();
                    });
            },
            saveEntry() {
                this.save(Object.assign({},
                    this.$refs['blog-part'].dumpData(),
                    this.$refs['locations-part'] ? this.$refs['locations-part'].dumpData() : {
                        locations_id_list: [],
                        locations_text_list: []
                    },
                    this.$refs['goals-part'].dumpData()
                ));
            },
            resetData() {
                this.$refs['blog-part'].resetData();
                this.$refs['goals-part'].resetData();
                if (this.$refs['locations-part']) {
                    this.$refs['locations-part'].resetData();
                }
                this.openedTab = 0;
                this.addToSuDiaries = false;
            }
        }
    };
</script>

<style scoped lang="scss">
  .create-entry-popup {
    &__content-container {
      min-height: 279px;
      max-height: 375px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

</style>