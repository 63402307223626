<template>
    <modal v-model="opened" :transition='0' :backdrop="false">
        <div slot="header">
            <a id="close_popup" @click="closePopup"></a>
            <h3 class="modal-title">What would you like to attach?</h3>
        </div>
        <div class="select-menu-container center">
            <div class="select-menu-item">
                <div class="panel panel-primary">
                    <div class="panel-heading shortcuts" @click="select('file')">
                        <h3 class="panel-title">File</h3>
                        <i class="far fa-file-alt select-menu-item__main-icon" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="select-menu-item">
                <div class="panel panel-primary">
                    <div class="panel-heading shortcuts" @click="select('link')">
                        <h3 class="panel-title">Link</h3>
                        <i class="far fa-link select-menu-item__main-icon" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="select-menu-item">
                <div class="panel panel-primary">
                    <div class="panel-heading shortcuts" @click="select('location')">
                        <h3 class="panel-title">Location</h3>
                        <i class="far fa-map-marker select-menu-item__main-icon" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer"></div>
    </modal>
</template>

<script>
    export default {
        name: "WikiAddAttachmentTypeSelectPopup",
        data() {
            return {
                opened: false
            };
        },
        methods: {
            show() {
                this.opened = true;
                return new Promise((res, rej) => {
                    this.resolve = res;
                    this.reject = rej;
                });
            },
            select(what) {
                this.opened = false;
                this.resolve(what);
            },
            closePopup() {
                this.opened = false;
                this.reject('closed');
            }
        }
    };
</script>

<style scoped>

</style>