/**
 * Created by orion on 28.05.17.
 */
import urls from 'DjangoUrls';

let {DjangoUrls} = urls;
BuddyResource.$inject = ['$http'];

function BuddyResource($http) {
    return {
        set_permissions(user_id, permissions) {
            const url = DjangoUrls["api.v1:buddy-set-user-permissions"](user_id);
            return $http({
                method: 'POST',
                url,
                data: {permissions}
            });
        },

        get_permissions(user_id) {
            const url = DjangoUrls["api.v1:buddy-get-user-permissions"](user_id);
            return $http({
                method: 'GET',
                url
            });
        },
        get_user_dashboard_config(userId) {
            const url = DjangoUrls["api.v1:buddy-get-user-dashboard-config"](userId);
            return $http({
                method: 'GET',
                url
            });
        },
        update_user_dashboard_config(userId, config) {
            const url = DjangoUrls["api.v1:buddy-update-user-dashboard-config"](userId);
            return $http({
                method: 'POST',
                url,
                data: config
            });
        },
        change_password(user_id, new_password) {
            const url = DjangoUrls["api.v1:buddy-change-user-password"](user_id);
            return $http({
                method: 'POST',
                url,
                data: {new_password}
            });
        }
    };
}

export default BuddyResource;