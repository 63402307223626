//when used you must specify:
// keyboardItemsForSelect = results/set of items; [default] = results
// getElementForKeyboardItem = function to convert an item from set to its element
// keyboardSelectedItem ? [optional] - for additional methods like a like or comment. [caveat] Must be used as is if it could be changed from using component

export default {
    data() {
        return {
            keyboardSelectedItem: undefined
        };
    },
    computed: {
        keyboardItemsForSelect() {
            return this.results;
        }
    },
    methods: {
        elementPartInViewport(el) {
            let top = el.offsetTop;
            let left = el.offsetLeft;
            let width = el.offsetWidth;
            let height = el.offsetHeight;

            while (el.offsetParent) {
                el = el.offsetParent;
                top += el.offsetTop;
                left += el.offsetLeft;
            }

            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        },
        getElementForKeyboardItem(activity) {
            return this.$refs[`activity-${activity.id}`][0]?.$el;
        },
        findNearestKeyboardItem() {
            for (let keyboardItem of this.keyboardItemsForSelect) {
                const keyboardItemElement = this.getElementForKeyboardItem(keyboardItem);
                if (keyboardItemElement) {
                    if ((keyboardItemElement.getBoundingClientRect().top > 0) && this.keyboardSelectedItem !== keyboardItem) {
                        return keyboardItem;
                    }
                }
            }
        },
        $_ensureKeyboardItemInViewPort() {
            if (this.keyboardSelectedItem) {
                const currentKeyboardSelectedItemElement = this.getElementForKeyboardItem(this.keyboardSelectedItem);
                if (currentKeyboardSelectedItemElement) {
                    if (!this.elementPartInViewport(currentKeyboardSelectedItemElement)) {
                        this.keyboardSelectedItem = this.findNearestKeyboardItem();
                    }
                }
            } else {
                const nearestKeyboardItem = this.findNearestKeyboardItem();
                if (nearestKeyboardItem) {
                    if (this.keyboardItemsForSelect.indexOf(nearestKeyboardItem) !== 0) {
                        this.keyboardSelectedItem = nearestKeyboardItem;
                    }
                }
            }
        },
        keyboardNextItem() {
            this.$_ensureKeyboardItemInViewPort();

            if (!this.keyboardSelectedItem) {
                this.keyboardSelectedItem = this.keyboardItemsForSelect[0];
            } else {
                const keyboardSelectedItemIndex = this.keyboardItemsForSelect.indexOf(this.keyboardSelectedItem);
                if (keyboardSelectedItemIndex !== (this.keyboardItemsForSelect.length - 1)) {
                    this.keyboardSelectedItem = this.keyboardItemsForSelect[keyboardSelectedItemIndex + 1];
                }
            }
        },
        keyboardPrevItem() {
            this.$_ensureKeyboardItemInViewPort();

            if (!this.keyboardSelectedItem) {
                this.keyboardSelectedItem = this.keyboardItemsForSelect[0];
            } else if (this.keyboardItemsForSelect.indexOf(this.keyboardSelectedItem) !== 0) {
                this.keyboardSelectedItem = this.keyboardItemsForSelect[this.keyboardItemsForSelect.indexOf(this.keyboardSelectedItem) - 1];
            }
        },
    },
};