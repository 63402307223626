import urls from 'DjangoUrls';

let {DjangoUrls} = urls;

function wikiSharingResourceFactory(prefix) {
    WikiSlideSharingResource.$inject = ['$http', '$log'];

    function WikiSlideSharingResource($http, $log) {
        return {
            get_sharing_main_status,
            get_friends_for_share,
            get_groups_for_share,
            get_communities_for_share,
            share_with_communities,
            share_with_friends,
            share_with_groups,
            keep_private,
        };

        function get_sharing_main_status(id) {
            const url = DjangoUrls["api.v1:" + prefix + "-get-sharing-main-status"](id);
            return $http({
                method: 'GET',
                url: url
            });
        }

        function get_friends_for_share(id, params) {
            const url = DjangoUrls["api.v1:" + prefix + "-get-friends-for-share"](id);
            return $http({
                method: 'GET',
                url: url,
                params
            });
        }

        function get_groups_for_share(id, params) {
            const url = DjangoUrls["api.v1:" + prefix + "-get-groups-for-share"](id);
            return $http({
                method: 'GET',
                url: url,
                params
            });
        }

        function get_communities_for_share(id, params) {
            const url = DjangoUrls["api.v1:" + prefix + "-get-communities-for-share"](id);
            return $http({
                method: 'GET',
                url: url,
                params
            });
        }

        function share_with_friends(id, data) {
            const url = DjangoUrls["api.v1:" + prefix + "-share-with-friends"](id);
            return $http({
                method: 'POST',
                url,
                data
            });
        }

        function share_with_groups(id, data) {
            const url = DjangoUrls["api.v1:" + prefix + "-share-with-groups"](id);
            return $http({
                method: 'POST',
                url,
                data
            });
        }

        function share_with_communities(id, data) {
            const url = DjangoUrls["api.v1:" + prefix + "-share-with-communities"](id);
            return $http({
                method: 'POST',
                url,
                data
            });
        }

        function keep_private(id) {
            const url = DjangoUrls["api.v1:" + prefix + "-keep-private"](id);
            return $http({
                method: 'PATCH',
                url
            });
        }

    }

    return WikiSlideSharingResource;
}


export default wikiSharingResourceFactory;