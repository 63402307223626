<template>
    <component :is="wrapperComponent" v-bind="$attrs" v-on="$listeners" :to="to">
        <slot></slot>
    </component>
</template>

<script>
    import SimpleWrapperLinkRouter from "./SimpleWrapperLinkRouter";
    import SimpleWrapperLinkA from "./SimpleWrapperLinkA";
    import RouterCheckAvailabilityMixin from "../RouterCheckAvailabilityMixin";

    export default {
        name: "SimpleWrapperLink",
        mixins: [RouterCheckAvailabilityMixin],
        props: {
            to: {
                type: [Object, String]
            }
        },
        computed: {
            availableInRouter() {
                return this.getRouterAvailability(this.to);
            },
            wrapperComponent() {
                if (typeof this.to === 'string') {
                    if (this.availableInRouter) {
                        return SimpleWrapperLinkRouter;
                    } else {
                        return SimpleWrapperLinkA;
                    }
                } else {
                    return SimpleWrapperLinkRouter;
                }
            }
        }
    };
</script>