/**
 * Created by orion on 06.04.17.
 */
import urls from 'DjangoUrls';
let {DjangoUrls} = urls;

MapResource.$inject = ['$http', '$log'];
function MapResource($http, $log) {
    return {
        create_location_point: function (data) {
            const url = DjangoUrls["api.v1:create_location_point"]();
            return $http({
                method: 'POST',
                url: url,
                data: data
            })
        }
    };


}

export default MapResource