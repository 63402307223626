var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { transition: 0, backdrop: false },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          attrs: { id: "close_popup" },
          on: { click: _vm.closePopup },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [
          _vm._v("Apply Wiki Template"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "alert alert-warning" }, [
            _c("strong", [_vm._v("Attention!")]),
            _vm._v("\n                This wiki has "),
            _c("strong", [_vm._v(_vm._s(_vm.slidesCount))]),
            _vm._v(" Wiki slides in "),
            _c("strong", [_vm._v(_vm._s(_vm.sectionsCount))]),
            _vm._v(
              "\n                wiki sections.\n                Applying this template will cause deletion of all data in that Wiki such as Sections, Slides,\n                Attachments.\n            "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.templates.length
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", [
                _c("span", [_vm._v("Wiki Template:")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedTemplate,
                        expression: "selectedTemplate",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedTemplate = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.templates, function (template) {
                    return _c(
                      "option",
                      { key: template.id, domProps: { value: template } },
                      [_vm._v(_vm._s(template.name) + "\n                    ")]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-green",
            attrs: { disabled: !_vm.allowApply },
            on: { click: _vm.applyTemplate },
          },
          [_vm._v("Apply Template")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }