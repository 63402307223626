var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "goal-list-item",
      class: {
        archived: _vm.goal.is_finished_by_page_owner || _vm.goal.is_finished,
      },
    },
    [
      _c(
        "div",
        { staticClass: "goal-list-item__avatar", style: _vm.goalAvatarStyle },
        [
          _c("div", {
            staticClass: "goal-list-item__avatar__owner-avatar",
            style: _vm.goalOwnerAvatarStyle,
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "goal-list-item__info" },
        [
          _c(
            "div",
            {
              staticClass: "goal-list-item__info__desktop-data",
              class: { "mobile-hidden": _vm.longNameOrDescription },
            },
            [
              _c("div", { staticClass: "goal-list-item__info__title" }, [
                _c(
                  "span",
                  {
                    staticClass: "vertical-top",
                    staticStyle: { float: "right", display: "inline-block" },
                  },
                  [_vm._t("buttonsPanel")],
                  2
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "vertical-top" },
                  [
                    _vm.routerNavigation && _vm.goalNameIsLink
                      ? _c("router-link", { attrs: { to: _vm.goalLink } }, [
                          _vm._v(_vm._s(_vm._f("cutName")(_vm.goal.name, 140))),
                        ])
                      : _vm.goalNameIsLink
                      ? _c("a", { attrs: { href: _vm.goalLink } }, [
                          _vm._v(_vm._s(_vm._f("cutName")(_vm.goal.name, 140))),
                        ])
                      : _c("span", [
                          _vm._v(_vm._s(_vm._f("cutName")(_vm.goal.name, 140))),
                        ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.goal.is_finished ||
                              _vm.goal.is_finished_by_page_owner,
                            expression:
                              "goal.is_finished||goal.is_finished_by_page_owner",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          "(Finished " +
                            _vm._s(_vm._f("customTerm")("Goal")) +
                            ")"
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "goal-list-item__info__description" }, [
                _vm._v(_vm._s(_vm._f("cutName")(_vm.goal.description, 255))),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("simple-progress-bar", {
            staticClass: "goal-list-item__info__progress-bar",
            class: { "with-margin": _vm.longNameOrDescription },
            attrs: {
              val: _vm.displayedProgress,
              size: "large",
              "bar-color": "#51ac51",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-xs-3 vertical-top" }, [
              _vm.goal.total_tasks_count
                ? _c("span", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.goal.completed_tasks_count) +
                        "/" +
                        _vm._s(_vm.goal.total_tasks_count) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.finishGoalSwitchIsVisible
              ? _c("div", { staticClass: "col-xs-9 vertical-top" }, [
                  !_vm.goal.is_finished_by_page_owner
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "text-right goal-list-item__archive-goal",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "pointer",
                              on: { click: _vm.archiveGoal },
                            },
                            [
                              _vm._v(
                                "Hide this " +
                                  _vm._s(_vm._f("customTerm")("Goal")) +
                                  "?"
                              ),
                            ]
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "text-right goal-list-item__archive-goal pointer",
                          on: { click: _vm.unArchiveGoal },
                        },
                        [
                          _vm._v(
                            "\n                    Unhide this " +
                              _vm._s(_vm._f("customTerm")("Goal")) +
                              "\n                "
                          ),
                        ]
                      ),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.longNameOrDescription,
              expression: "longNameOrDescription",
            },
          ],
          staticClass: "goal-list-item__info__mobile-data",
        },
        [
          _c(
            "div",
            { staticClass: "goal-list-item__info__title" },
            [
              _vm.routerNavigation
                ? _c("router-link", { attrs: { to: _vm.goalLink } }, [
                    _vm._v(_vm._s(_vm._f("cutName")(_vm.goal.name, 140))),
                  ])
                : _c("a", { attrs: { href: _vm.goalLink } }, [
                    _vm._v(_vm._s(_vm._f("cutName")(_vm.goal.name, 140))),
                  ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.goal.is_finished ||
                        _vm.goal.is_finished_by_page_owner,
                      expression:
                        "goal.is_finished||goal.is_finished_by_page_owner",
                    },
                  ],
                },
                [
                  _vm._v(
                    "(Finished " + _vm._s(_vm._f("customTerm")("Goal")) + ")"
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "goal-list-item__info__description" }, [
            _vm._v(_vm._s(_vm._f("cutName")(_vm.goal.description, 255))),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }