var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.loaded,
          expression: "loaded",
        },
      ],
    },
    [
      _vm.items.length === 0
        ? _c("div", { staticClass: "alert alert-warning" }, [
            _vm._v("\n        Nothing was added to your Dashboard\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.desktopMode
        ? _c("div", { staticClass: "dashboard-list__container" }, [
            _c(
              "div",
              { staticClass: "inline-block" },
              [
                _c("dashboard-list-item-wrapper", {
                  attrs: { item: _vm.items[0] },
                }),
                _vm._v(" "),
                _c("dashboard-list-item-wrapper", {
                  attrs: { item: _vm.items[3] },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "inline-block" },
              [
                _c("dashboard-list-item-wrapper", {
                  attrs: { item: _vm.items[1] },
                }),
                _vm._v(" "),
                _c("dashboard-list-item-wrapper", {
                  attrs: { item: _vm.items[5] },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "inline-block" },
              [
                _c("dashboard-list-item-wrapper", {
                  attrs: { item: _vm.items[2] },
                }),
                _vm._v(" "),
                _c("dashboard-list-item-wrapper", {
                  attrs: { item: _vm.items[4] },
                }),
              ],
              1
            ),
          ])
        : _c(
            "div",
            { staticClass: "dashboard-list__container" },
            [
              _c("dashboard-list-item-wrapper", {
                attrs: { item: _vm.items[1], white: "" },
              }),
              _vm._v(" "),
              _c("dashboard-list-item-wrapper", {
                attrs: { item: _vm.items[0], white: "" },
              }),
              _vm._v(" "),
              _c("dashboard-list-item-wrapper", {
                attrs: { item: _vm.items[2], white: "" },
              }),
              _vm._v(" "),
              _c("dashboard-list-item-wrapper", {
                attrs: { item: _vm.items[3], white: "" },
              }),
              _vm._v(" "),
              _c("dashboard-list-item-wrapper", {
                attrs: { item: _vm.items[4], white: "" },
              }),
              _vm._v(" "),
              _c("dashboard-list-item-wrapper", {
                attrs: { item: _vm.items[5], white: "" },
              }),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }