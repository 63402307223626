<template>
    <modal v-model="opened" :transition='0' :append-to-body="true" :backdrop="false">
        <div slot="header">
            <a id="close_popup" @click="closePopup"></a>
            <h3 class="modal-title">{{header}}</h3>
        </div>
        <event-detail-page-info-entry-list-show-all-popup-entry-preview
                v-for="entry in results"
                :key="entry.id"
                :entry="entry"
        ></event-detail-page-info-entry-list-show-all-popup-entry-preview>
        <div class="row margin-15-top" v-if="!allLoaded">
            <div class="col-xs-12 text-center">
                <span class="pointer"
                      @click="loadMore">Show <strong>{{totalCount - results.length}}</strong> More...</span>
            </div>
        </div>
        <div slot="footer">
            <button class="btn btn-mm" @click="closePopup">Close</button>
        </div>
    </modal>
</template>

<script>
    import Vue from 'vue';
    import infiniteScrollMixin from "../../../../vue/mixins/InfiniteScrollMixin";
    import EventDetailPageInfoEntryListShowAllPopupEntryPreview
        from './EventDetailPageInfoEntryListShowAllPopupEntryPreview.vue';
    import {VISUAL_DATE_FORMAT} from "../new_calendar/config";

    export default {
        name: "EventDetailPageInfoEntryListShowAllPopup.vue",
        mixins: [infiniteScrollMixin],
        components: {EventDetailPageInfoEntryListShowAllPopupEntryPreview},
        props: {
            event: Object,
            calendarOwner: Object,
            dateMoment: Object,
        },
        data() {
            return {
                limit: 5,
                opened: false,
                header: 'Linked Diary Entries',
            };
        },
        methods: {
            getListEndpointArgs() {
                return [this.event.id, this.calendarOwner.id, this.dateMoment.format(VISUAL_DATE_FORMAT)];
            },
            getListEndpoint() {
                return Vue.getAngularModule('CalendarEventBlogEntriesResource').load_linked_entries;
            },
            // resetData() {
            // this.resetResults();
            // },
            open() {
                return new Promise((res, rej) => {
                    this.resolve = res;
                    this.reject = rej;
                });
            },
            closePopup() {
                this.opened = false;
                // this.resetData();
                this.reject('closed');
            },
        },
        mounted() {
        },
        watch: {
            totalCount(n, o) {  // kind of hack just to use InfiniteScrollMixin. opens popup when results are loaded by mixins mounted() listener
                this.opened = !!n;
            }
        }
    };
</script>

<style scoped>

</style>