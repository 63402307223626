var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "hotkey",
            rawName: "v-hotkey",
            value: _vm.keymap,
            expression: "keymap",
          },
        ],
        staticClass: "wiki-detail-page-slide-container",
        style: _vm.containerStyle,
      },
      [
        _c(
          "div",
          {
            staticClass: "wiki-detail-page-slide-container__left-panel",
            style: _vm.arrowsContainerStyle,
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "wiki-detail-page-slide-container__left-panel__arrow",
                style: _vm.arrowStyle,
                on: {
                  click: function ($event) {
                    return _vm.prevSlide()
                  },
                },
              },
              [_c("i", { staticClass: "far fa-chevron-left vertical-top" })]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.activeSlide
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "touch",
                    rawName: "v-touch:swipe.right",
                    value: _vm.prevSlide,
                    expression: "prevSlide",
                    arg: "swipe",
                    modifiers: { right: true },
                  },
                  {
                    name: "touch",
                    rawName: "v-touch:swipe.left",
                    value: _vm.nextSlide,
                    expression: "nextSlide",
                    arg: "swipe",
                    modifiers: { left: true },
                  },
                ],
                staticClass:
                  "wiki-detail-page-slide-container__inner-container",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "wiki-detail-page-slide-container__inner-container__slide-wrapper",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "wiki-detail-page-slide-container__inner-container__header",
                        style: _vm.headerStyle,
                      },
                      [
                        _c("wiki-detail-page-slide-header", {
                          ref: "slideHeader",
                          attrs: {
                            "edit-mode": _vm.editMode,
                            slide: _vm.activeSlide,
                            height: _vm.$_headerHeight,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "wiki-detail-page-slide-container__inner-container__content",
                        style: _vm.contentStyle,
                      },
                      [
                        _vm.activeSlide && _vm.activeSlide.media_content
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "wiki-detail-page-slide-container__inner-container__content__wrapper",
                                staticStyle: { padding: "0 15px" },
                              },
                              [
                                _c("vue-inline-media", {
                                  key: _vm.activeSlide.media_content.id,
                                  ref: "inlineMedia",
                                  attrs: {
                                    autoplay: false,
                                    item: _vm.activeSlide.media_content,
                                    "full-size": true,
                                    "lazy-load": true,
                                  },
                                }),
                                _vm._v(" "),
                                _vm.editMode
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "wiki-detail-page-slide-container__inner-container__content__edit-button",
                                        on: { click: _vm.editSlideMedia },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "far fa-pencil",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.activeSlide &&
                        !_vm.activeSlide.media_content &&
                        !_vm.editMode
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "wiki-detail-page-slide-container__inner-container__content__no-media-placeholder",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "wiki-detail-page-slide-container__inner-container__content__no-media-placeholder__icon",
                                    style: _vm.noMediaIconStyle,
                                  },
                                  [_c("i", { staticClass: "fa fa-picture-o" })]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "wiki-detail-page-slide-container__inner-container__content__no-media-placeholder__label",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            There is no media content on this slide...\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.activeSlide &&
                        !_vm.activeSlide.media_content &&
                        _vm.editMode
                          ? _c(
                              "wiki-detail-page-slide-container-empty-slide-edit-holder",
                              {
                                attrs: {
                                  containerHeight: _vm.$_containerHeight,
                                  slide: _vm.activeSlide,
                                },
                              }
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "wiki-detail-page-slide-container__inner-container__description",
                      },
                      [
                        _c("wiki-detail-page-slide-description", {
                          ref: "slideDescription",
                          attrs: {
                            slide: _vm.activeSlide,
                            "edit-mode": _vm.editMode,
                            height: _vm.$_descriptionHeight,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            )
          : _c(
              "div",
              {
                staticClass:
                  "wiki-detail-page-slide-container__inner-container wiki-detail-page-slide-container__inner-container_empty",
                style: _vm.contentStyle,
              },
              [_c("span", [_vm._v("there are no pages yet")])]
            ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "wiki-detail-page-slide-container__right-panel",
            style: _vm.arrowsContainerStyle,
          },
          [
            _vm.logAvailable
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.left",
                        value: "Page log",
                        expression: "'Page log'",
                        modifiers: { left: true },
                      },
                    ],
                    staticClass:
                      "wiki-detail-page-slide-container__right-panel__buttons-panel__button create",
                    on: { click: _vm.showWikiLog },
                  },
                  [_c("i", { staticClass: "far fa-file-contract" })]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.editMode
              ? _c(
                  "div",
                  {
                    staticClass:
                      "wiki-detail-page-slide-container__right-panel__buttons-panel",
                  },
                  [
                    _vm.isTemplate || _vm.hasGuidanceToRead
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.left",
                                value: "Filling Guidance",
                                expression: "'Filling Guidance'",
                                modifiers: { left: true },
                              },
                            ],
                            staticClass: "guidance",
                            on: { click: _vm.showGuidanceDialog },
                          },
                          [_c("i", { staticClass: "far fa-lightbulb" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.slides.length < 100
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.left",
                                value: "Add Page",
                                expression: "'Add Page'",
                                modifiers: { left: true },
                              },
                            ],
                            staticClass:
                              "wiki-detail-page-slide-container__right-panel__buttons-panel__button create",
                            on: { click: _vm.createSlide },
                          },
                          [_c("i", { staticClass: "far fa-plus-square" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.slides.length > 1 && !_vm.activeSlide?.is_fixed
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.left",
                                value: "Remove Page",
                                expression: "'Remove Page'",
                                modifiers: { left: true },
                              },
                            ],
                            staticClass:
                              "wiki-detail-page-slide-container__right-panel__buttons-panel__button delete",
                          },
                          [
                            _c("i", {
                              staticClass: "far fa-trash",
                              on: { click: _vm.removeActiveSlide },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "wiki-detail-page-slide-container__right-panel__arrow",
                style: _vm.arrowStyle,
                on: {
                  click: function ($event) {
                    return _vm.nextSlide()
                  },
                },
              },
              [_c("i", { staticClass: "far fa-chevron-right vertical-top" })]
            ),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "wiki-detail-page-slide-container__mobile-panel" },
      [
        _c(
          "div",
          {
            staticClass: "text-left",
            class: {
              "col-xs-3": _vm.editMode || _vm.logAvailable,
              "col-xs-6": !_vm.editMode,
            },
          },
          [
            _c("i", {
              staticClass: "far fa-chevron-left",
              on: { click: _vm.prevSlide },
            }),
          ]
        ),
        _vm._v(" "),
        _vm.editMode
          ? _c(
              "div",
              {
                staticClass: "buttons col-xs-6",
                class: {
                  single:
                    _vm.activeSlide?.is_fixed &&
                    !(_vm.isTemplate || _vm.hasGuidanceToRead),
                },
              },
              [
                !_vm.activeSlide?.is_fixed
                  ? _c("div", [
                      _c("i", {
                        staticClass: "far fa-trash",
                        on: { click: _vm.removeActiveSlide },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _c("i", {
                    staticClass: "far fa-plus-square",
                    on: { click: _vm.createSlide },
                  }),
                ]),
                _vm._v(" "),
                _vm.isTemplate || _vm.hasGuidanceToRead
                  ? _c("div", { staticClass: "guidance" }, [
                      _c("i", {
                        staticClass: "far fa-lightbulb",
                        on: { click: _vm.showGuidanceDialog },
                      }),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.logAvailable
          ? _c(
              "div",
              {
                staticClass: "col-xs-6 single-button",
                on: { click: _vm.showWikiLog },
              },
              [_c("i", { staticClass: "far fa-file-contract" })]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "text-right",
            class: {
              "col-xs-3": _vm.editMode || _vm.logAvailable,
              "col-xs-6": !_vm.editMode,
            },
          },
          [
            _c("i", {
              staticClass: "far fa-chevron-right",
              on: { click: _vm.nextSlide },
            }),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }