var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table-responsive" }, [
    _c("table", { staticClass: "table table-bordered" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        [
          _vm._l(_vm.sortedMembers, function (member) {
            return [
              _c("wiki-members-list-table-member", {
                key: member.id,
                attrs: {
                  member: member,
                  isOwner:
                    member.userprofile &&
                    member.userprofile.id === _vm.owner.id,
                },
                on: {
                  removeMember: _vm.removeMember,
                  updateAccessLinkTerm: _vm.updateAccessLinkTerm,
                  protectAccessLinkByPassword: _vm.protectAccessLinkByPassword,
                  toggleMemberAdminStatus: _vm.toggleMemberAdminStatus,
                  openPermissionsTree: _vm.openPermissionsTree,
                  sendReadReceipt: _vm.sendReadReceipt,
                  showReadReceipts: _vm.showReadReceipts,
                },
              }),
              _vm._v(" "),
              member.tree_opened
                ? _c("tr", [
                    _c(
                      "td",
                      { attrs: { colspan: "7" } },
                      [
                        _c("wiki-tree", {
                          key: member.id,
                          attrs: {
                            sections: _vm.sections,
                            owner: _vm.owner,
                            member: member,
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Shared with")]),
        _vm._v(" "),
        _c("th", [_vm._v("Role")]),
        _vm._v(" "),
        _c("th", [_vm._v("Read Receipts")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date Invited")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-center" }, [_vm._v("Is Admin")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-center" }, [_vm._v("Access given")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-center" }, [_vm._v("Remove Access")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }