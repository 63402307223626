<template>
    <div class="vue-wiki-slide-attachments">
        <div class="row" v-if="slide&&(editMode||attachmentsExists)">
            <div class="col-sm-12 text-center">
                <div class="vue-wiki-slide-attachments__inner-container">
                    <a :href="attached_document.media_content.uploaded_document.document_url"
                       target="_blank"
                       v-for="attached_document in slide.attached_documents"
                       style="text-decoration: none;"
                    >
                        <div class="vue-wiki-slide-attachments__attachment vue-wiki-slide-attachments__attachment__document vertical-middle">
                            <i
                                    class="fa fa-file-text vertical-middle" aria-hidden="true"></i> <span
                                class=" vertical-middle">{{ attached_document.media_content.name | cutName(22) }}</span>
                            <i v-show="editMode" class="fa fa-window-close color-red vertical-middle"
                               style="font-size: 26px; margin-left: 5px"
                               @click.stop.prevent="deleteAttachment('file',attached_document.id)"></i>
                        </div>
                    </a>
                    <a :href="attached_link.url"
                       target="_blank"
                       v-for="attached_link in slide.attached_links"
                       style="text-decoration: none;"
                    >
                        <div class="vue-wiki-slide-attachments__attachment vue-wiki-slide-attachments__attachment__link vertical-middle">
                            <i
                                    class="fa fa-link vertical-middle" aria-hidden="true"></i> <span
                                class=" vertical-middle">{{ attached_link.name | cutName(22) }}</span>
                            <i v-show="editMode" class="fa fa-window-close color-red vertical-middle"
                               style="font-size: 26px; margin-left: 5px"
                               @click.stop.prevent="deleteAttachment('link', attached_link.id)"></i>
                        </div>
                    </a>
                    <a :href="getGmapsHref(attached_location.location)"
                       target="_blank"
                       v-for="attached_location in slide.attached_locations"
                       style="text-decoration: none;"
                    >
                        <div class="vue-wiki-slide-attachments__attachment vue-wiki-slide-attachments__attachment__location vertical-middle">
                            <i
                                    class="far fa-map-marker-check vertical-middle" aria-hidden="true"></i> <span
                                class=" vertical-middle">{{ attached_location.location.address | cutName(22) }}</span>
                            <i v-show="editMode" class="fa fa-window-close color-red vertical-middle"
                               style="font-size: 26px; margin-left: 5px"
                               @click.stop.prevent="deleteAttachment('location', attached_location.id)"></i>
                        </div>
                    </a>
                    <a href="#" @click.stop.prevent="addAttachment" v-if="editMode"
                       class="vue-wiki-slide-attachments__add-link" style="white-space: nowrap"><i
                            class="far fa-paperclip"></i>&nbsp;Add&nbsp;attachment</a>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default {
        name: "WikiDetailPageSlideAttachments",
        props: {
            slide: Object,
            editMode: Boolean
        },
        computed: {
            attachmentsExists() {
                return this.slide.attached_documents.length || this.slide.attached_links.length || this.slide.attached_locations.length;
            }
        },
        methods: {
            getGmapsHref(location) {
                const Maps = Vue.getAngularModule('Maps');
                return Maps.get_gmaps_href(location);
            },
            deleteAttachment(attachmentType, attachmentId) {
                this.$store.dispatch('WikiStore/deleteSlideAttachment', {attachmentType, attachmentId});
            },
            addAttachment() {
                this.$store.dispatch('WikiStore/addSlideAttachment');
            }
        }

    };
</script>

<style scoped lang="scss">
    $mobile-threshold: 760px;
    @import "../../../angular/app/styles/const";
    .vue-wiki-slide-attachments {
        /*min-height: 50px;*/

        &__add-link {
            font-size: 20px;
            font-weight: bolder;
        }

        &__no-links {
            font-size: 20px;
            color: $tag-gray;
        }

        &__attachment {
            display: inline-block;
            @media (min-width: $mobile-threshold) {
                padding: 7px 15px;
                font-size: 16px;
            }
            @media (max-width: $mobile-threshold - 1px) {
                padding: 5px 10px;
                font-size: 14px;
            }

            background-color: white;
            border-radius: 20px;

            &__document {
                border: solid 2px $blue;
            }

            &__link {
                border: solid 2px $dark-blue;
            }

            &__location {
                border: solid 2px $grass-green;
            }

            margin-bottom: 5px;
        }

        &__inner-container {
            display: inline-block;
            text-align: left;
            width: 100%;
        }
    }
</style>