<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="title"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ title }}</h3>
        </div>

        <div class="wiki-log-popup__content-container">
            <div class="">
                <wiki-action-log-admin
                        :wiki-log="wikiLog"
                        v-for="(wikiLog, i) in logsToDisplay"
                        :key="i"
                ></wiki-action-log-admin>
                <div class="alert alert-info" v-if="!logsToDisplay.length && !loading">
                    No logs yet
                </div>

                <div class="row margin-15-top color-tag-gray-dark" v-if="loading && !initialized">
                    <div class="col-sm-12 text-center">
                        <div>
                            <i class="fas fa-spinner fa-spin fa-4x"></i>
                        </div>
                        <div>
                            Loading...
                        </div>
                    </div>
                </div>

                <infinite-loading @infinite="loadInfinite">
                    <span slot="no-more"></span>
                    <span slot="no-results"></span>
                </infinite-loading>
            </div>
        </div>

        <div slot="footer">
            <button class="btn btn-mm btn-green" @click="close">OK</button>
        </div>
    </basic-modal>
</template>

<script>
    import PopupPromiseMixin from "../../../../../vue/mixins/PopupPromiseMixin";
    import BasicModal from "../../../../../vue/common_components/BasicModal";
    import InfiniteScrollMixin from "../../../../../vue/mixins/InfiniteScrollMixin";
    import wiki_logs_resource from "./wiki_logs_resource";
    import WikiActionLogAdmin from "./WikiActionLogAdmin";

    export default {
        name: "WikiLogPopup",
        components: {WikiActionLogAdmin, BasicModal},
        mixins: [PopupPromiseMixin, InfiniteScrollMixin],
        data() {
            return {
                wikiId: undefined,
                sectionId: undefined,
                slideId: undefined,
                getResultsOnMount: false,
                limit: 100,
                showDimmerOnLoadMore: false,
            };
        },
        computed: {
            title() {
                return 'Wiki log';
            },
            logsToDisplay() {
                return this.results;
            },
        },
        methods: {
            getListEndpointArgs() {
                if (this.slideId) {
                    return [this.wikiId, { slide_id: this.slideId }];
                } else if (this.sectionId) {
                    return [this.wikiId, { section_id: this.sectionId }];
                } else {
                    return [this.wikiId];
                }
            },
            getListEndpoint() {
                return wiki_logs_resource.get_logs;
            },
            async showedHook() {
                this.getResults(false);
            },
            setInitial(wikiId, sectionId=undefined, slideId=undefined) {
                this.wikiId = wikiId;
                this.sectionId = sectionId;
                this.slideId = slideId;
            },
            async loadInfinite($state) {
                const resultsAmount = this.results.length;
                await this.getResults(false);
                if (this.results.length === resultsAmount) {
                    $state.complete();
                } else {
                    $state.loaded();
                }
            }
        },
        mounted() {
        }

    };
</script>

<style scoped lang="scss">
  .wiki-log-popup {
    &__content-container {
      max-height: 60vh;
      overflow-y: auto;
      overflow-x: hidden;

      &::v-deep {
        a {
          font-weight: bold;
        }
      }
    }
  }
</style>