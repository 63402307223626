var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "inline-block media-request__container",
      attrs: { tabindex: "0", role: "button", "aria-label": _vm.text },
    },
    [
      _c(
        "div",
        {
          staticClass: "media-request__preview-image",
          class: { "no-border": _vm.noBorder, active: _vm.highLight },
        },
        [
          _vm.allowDelete
            ? _c("i", {
                staticClass:
                  "fa fa-times media-request__preview-image__delete_button",
                on: { click: _vm.del },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.allowEdit
            ? _c("i", {
                staticClass:
                  "fa fa-pencil media-request__preview-image__edit_button",
                on: { click: _vm.edit },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("i", {
            staticClass: "fa",
            class: _vm.iconClass,
            attrs: { "aria-hidden": "true" },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "media-request__preview-image__text" }, [
        _vm._v(_vm._s(_vm.text)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }