var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "hotkey",
          rawName: "v-hotkey",
          value: _vm.keymap,
          expression: "keymap",
        },
      ],
      staticClass: "comments-input-form hidden-print",
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xs-12" }, [
          _c("form", { attrs: { id: "reply-textarea" } }, [
            _vm.replyTo
              ? _c(
                  "span",
                  { staticClass: "comments-input-form__reply-to-message" },
                  [
                    _vm._v("Reply to "),
                    _c("strong", [_vm._v(_vm._s(_vm.replyTo.author.name))]),
                    _vm._v("\n                    ("),
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.cancelReply.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("cancel")]
                    ),
                    _vm._v(") : "),
                    _c("i", [_vm._v(_vm._s(_vm.replyTo.messagePreview))]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.text,
                  expression: "text",
                },
              ],
              ref: "commentsInput",
              staticClass: "form-control comments-input-form__input-textarea",
              class: {
                active:
                  _vm.mediaAttachment || _vm.sticker || _vm.text || _vm.replyTo,
              },
              attrs: {
                id: "comments-input",
                "aria-label": "Add Comment",
                placeholder: "Type your comment here...",
                rows: "5",
                maxlength: "5000",
              },
              domProps: { value: _vm.text },
              on: {
                keydown: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    if (!$event.ctrlKey) return null
                    return _vm.addMessage.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "s",
                        undefined,
                        $event.key,
                        undefined
                      )
                    )
                      return null
                    if (!$event.altKey) return null
                    return _vm.addSticker.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "u",
                        undefined,
                        $event.key,
                        undefined
                      )
                    )
                      return null
                    if (!$event.altKey) return null
                    return _vm.uploadPicture.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "z",
                        undefined,
                        $event.key,
                        undefined
                      )
                    )
                      return null
                    if (!$event.altKey) return null
                    return _vm.recordAudio.apply(null, arguments)
                  },
                ],
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.text = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row bottom-buttons-panel-main" }, [
        _vm.allowAddSticker || _vm.allowAddMedia
          ? _c(
              "div",
              {
                staticClass: "col-sm-9 col-xs-7",
                staticStyle: { "padding-right": "0" },
              },
              [
                _c("add-media-sticker-emotion-panel", {
                  attrs: {
                    "allow-add-media": _vm.allowAddMedia,
                    "allow-add-emotion": _vm.allowAddEmotion,
                    "add-media-disabled": !!_vm.mediaAttachment,
                    "add-sticker-disabled": !!_vm.sticker,
                    "add-emotion-disabled": !!_vm.emotion,
                  },
                  on: {
                    addMedia: _vm.addMedia,
                    addSticker: _vm.addSticker,
                    addEmotion: _vm.addEmotion,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "col-sm-3 col-xs-5 text-right",
            staticStyle: { "margin-top": "5px" },
          },
          [
            _c(
              "button",
              {
                ref: "sendButton",
                staticClass:
                  "btn btn-mm btn-green comments-input-form__send-message-button",
                class: { visible: !_vm.postIsEmpty },
                attrs: {
                  "aria-hidden": _vm.postIsEmpty ? "true" : "false",
                  tabindex: _vm.postIsEmpty ? -1 : 0,
                  "aria-label": "Post",
                  role: "button",
                },
                on: { click: _vm.addMessage },
              },
              [
                _c("i", {
                  staticClass: "fa fa-paper-plane",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" Send\n            "),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.mediaAttachment || _vm.sticker || _vm.emotion
        ? _c(
            "div",
            { staticClass: "row", staticStyle: { "margin-top": "15px" } },
            [
              _c(
                "div",
                { staticClass: "col-sm-6" },
                [
                  _vm.mediaAttachment
                    ? _c("media-preview", {
                        staticStyle: {
                          display: "inline-block",
                          "margin-right": "15px",
                        },
                        attrs: {
                          item: _vm.mediaAttachment,
                          "allow-delete": true,
                        },
                        on: { onDelete: _vm.deleteMedia },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.sticker
                    ? _c(
                        "span",
                        [
                          _c("render-sticker", {
                            staticStyle: {
                              width: "70px",
                              height: "70px",
                              display: "inline-block",
                            },
                            attrs: {
                              "allow-delete": true,
                              sticker: _vm.sticker,
                            },
                            on: { onDelete: _vm.deleteSticker },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.emotion
                    ? _c(
                        "div",
                        [
                          _c("entry-emotion", {
                            attrs: {
                              emotion: _vm.emotion,
                              "show-delete": true,
                              "show-label": true,
                              size: 36,
                            },
                            on: { delete: _vm.deleteEmotion },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }