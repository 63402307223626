/**
 * Created by orion on 31.05.17.
 */
let angular = require('angular');
let _ = require('lodash');
import collectionSettingsOrDeletePopupController from '../collection_settings_or_delete_popup/controller'

collectionDetailController.$inject = ['$templateCache', '$location', 'backUrlGenerator', 'CollectionsResource', 'CurrentUser', '$q', 'simplePopupFactory', 'notifications', '$rootScope', '$preloaded', '$log', 'shareFactory'];

function collectionDetailController($templateCache, $location, backUrlGenerator, CollectionsResource, CurrentUser, $q, simplePopupFactory, notifications, $rootScope, $preloaded, $log, shareFactory) {
    let self = this;
    self.media_attachments = [];
    self.other_attachments = [];

    //*** micro-router

    $templateCache.put('CollectionMembers.html', require('./members/CollectionMembers.html'));
    $templateCache.put('CollectionAttachments.html', require('./attachments/CollectionAttachments.html'));


    let templates = {
        '/members/': 'CollectionMembers.html',
        '/attachments/': 'CollectionAttachments.html'
    };
    self.go_back = backUrlGenerator.go_back;
    self.check_t = function (n) {
        self.active_template = templates[n];
        $location.path(`${n}`);
    };

    function init() {
        self.collection_id = $preloaded.collection.id;
        self.collection_name = $preloaded.collection.name;
        self.is_admin = $preloaded.is_admin;
        self.is_member = $preloaded.is_member;
        self.is_join_request_pending = $preloaded.is_join_request_pending;
        if ($location.path() && templates[$location.path()] !== undefined) {
            self.active_template = templates[$location.path()]
        } else {
            self.check_t('/attachments/');
        }
    }

    init();

    //*** /micro-router

    self.share_allowed = (function () {
        return self.is_admin && CurrentUser.has_permission('share');
    })();

    self.go_back = backUrlGenerator.go_back;

    self.show_settings_popup = show_settings_popup;
    self.share_collection = share_collection;

    function show_settings_popup() {
        simplePopupFactory.create_popup(collectionSettingsOrDeletePopupController, require('../collection_settings_or_delete_popup/template.html'),
            {collection_name: self.collection_name})
            .then(res => {
                // res.action in ['rename','remove']. if rename then res.name defined
                if (res.action === 'rename') {
                    $rootScope.show_dimmer();
                    CollectionsResource.rename_collection(self.collection_id, res.name)
                        .then(resp => {
                            notifications.success('Renamed');
                            document.location.href = resp.data;

                        }, err => {
                            notifications.error(err || 'Error');
                            $rootScope.hide_dimmer();
                        })

                }
                if (res.action === 'remove') {
                    simplePopupFactory.show_popup('Remove Story', `Are you sure you want to remove collection '${self.collection_name}'?`, 'Remove', 'Cancel')
                        .then(() => {
                            $rootScope.show_dimmer();
                            return CollectionsResource.remove_collection(self.collection_id)
                        }, () => new Promise(()=>{}))
                        .then(resp => {
                            notifications.success('Deleted');
                            document.location.href = DjangoUrls['folders']();
                        }, err => {
                            notifications.error(err || 'Error');
                            $rootScope.hide_dimmer();
                        });
                }
            }, () => new Promise(()=>{}));
    }


    function share_collection() {
        shareFactory.share_this('collection', self.collection_id);
    }


}

export default collectionDetailController