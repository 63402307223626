<template>
    <selector-popup
            ref="popup"
            :header="header"
            :items="items"
    >
        <template slot="footer" v-if="config.allow_remove">
            <button class="btn btn-mm btn-red" @click="remove"><i class="far fa-trash" aria-hidden="true"></i> Remove
                Media
            </button>
        </template>
    </selector-popup>
</template>

<script>
    import SelectorPopup from "../../../../vue/common_components/SelectorPopup/SelectorPopup";

    export default {
        name: "AddMediaPopup",
        components: {SelectorPopup},
        data() {
            return {
                config: Object,
            };
        },
        computed: {
            items() {
                if (!this.config?.types?.length) {
                    return [];
                }
                const items = [];
                if (this.config.types.includes('video')) {
                    items.push({
                        label: 'Video',
                        iconClass: 'fa-video-camera',
                        action: 'select',
                        selection: 'video',
                    });
                }
                if (this.config.types.includes('audio')) {
                    items.push({
                        label: 'Audio',
                        iconClass: 'fa-volume-up',
                        action: 'select',
                        selection: 'audio',
                    });
                }
                if (this.config.types.includes('image')) {
                    items.push({
                        label: 'Picture',
                        iconClass: 'fa-camera-retro',
                        action: 'select',
                        selection: 'picture',
                    });
                }
                if (this.config.types.includes('document')) {
                    items.push({
                        label: 'Document',
                        iconClass: 'fa-file-text-o',
                        action: 'select',
                        selection: 'document',
                    });
                }
                if (this.config.types.includes('link')) {
                    items.push({
                        label: 'Web Page',
                        iconClass: 'fa-globe',
                        action: 'select',
                        selection: 'link',
                    });
                }
                return items;
            },
            header() {
                return this.config.popup_header;
            }
        },
        methods: {
            show(config) {
                this.config = config;
                return new Promise((resolve, reject) => {
                    this.reject = reject;
                    this.$refs.popup.show()
                        .then(item => {
                            if (item.action === 'select') {
                                resolve({selection: item.selection});
                            }
                        }, reason => {
                            reject(reason);
                        });
                });
            },
            remove() {
                this.reject('remove');
            }
        }
    };
</script>