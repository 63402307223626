var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "hotkey",
          rawName: "v-hotkey",
          value: _vm.keymap,
          expression: "keymap",
        },
      ],
      ref: "container",
      style: [_vm.containerStyle, _vm.gripStyle],
    },
    [
      _c(
        "div",
        [
          _vm.wikiEditor
            ? _c(
                "alert",
                {
                  staticClass: "margin-15-top margin-15-bottom",
                  attrs: { type: "info" },
                },
                [
                  _vm._v("Editing session is being conducted by "),
                  _c("b", [_vm._v(_vm._s(_vm.wikiEditor.name))]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sections-container" },
        [
          _vm._l(_vm.sections, function (section, i) {
            return _c("wiki-section-page-section", {
              key: section.id,
              ref: "wikiSection",
              refInFor: true,
              attrs: {
                "is-active": _vm.activeSectionNumber === i,
                to: _vm.getSectionLink(section),
                section: section,
                size: _vm.sectionSize,
                "is-ticked": _vm.selectedToMoveSectionsIdList.includes(
                  section.id
                ),
                tabindex: "0",
              },
              on: {
                toggleSectionSelect: function ($event) {
                  return _vm.toggleSectionSelect(section.id)
                },
              },
              nativeOn: {
                focus: function ($event) {
                  return _vm.activateSection(i)
                },
              },
            })
          }),
          _vm._v(" "),
          _vm._l(_vm.swamGetFakeItems(_vm.sections.length), function (it) {
            return _c("span", { key: it.id, style: _vm.swamFakeIconStyle })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.loaded && !_vm.sections.length
        ? _c("div", { staticClass: "alert alert-info" }, [
            _vm._v("\n        There are no sections here yet.\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c(
            "span",
            {
              staticClass: "focus-catch-link",
              attrs: { tabindex: "0", "aria-hidden": "true" },
              on: { focus: _vm.returnFocus },
            },
            [_vm._v(" ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }