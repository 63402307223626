<template>
    <div class="">
        <div>
            <alert type="info" class="margin-15-top margin-15-bottom" v-if="wikiEditor">Editing session is being conducted by <b>{{ wikiEditor.name }}</b></alert>
        </div>
        <wiki-detail-page-slide-container
                :full-screen="fullScreen"
                :active-slide="activeSlide"
                :edit-mode="editMode"
        >
        </wiki-detail-page-slide-container>
        <wiki-detail-page-slide-attachments
                :slide="activeSlide"
                :edit-mode="editMode"
        ></wiki-detail-page-slide-attachments>
        <wiki-detail-page-slider
                :edit-mode="editMode"
        ></wiki-detail-page-slider>
    </div>
</template>

<script>
    import WikiMainPageHeader from "./WikiMainPageHeader";
    import WikiBreadcrumbs from "../wiki/wiki_breadcrumbs/WikiBreadcrumbs";
    import breadcrumbsMixin from "./breadcrumbsMixin";
    import Vue from 'vue';
    import WikiDetailPageSlideContainer from "./WikiDetailPageSlideContainer";
    import WikiDetailPageSlider from "./WikiDetailPageSlider";
    import WikiDetailPageSlideAttachments from "./WikiDetailPageSlideAttachments";
    import wiki_read_receipts_track_resource from "../wiki/wiki_settings_page/wiki_read_receipts_track_resource";

    const POSSIBLE_TRACK_SLIDE_RESPONSES = {
        NO_RECEIPTS_IN_SECTION: 'NO_RECEIPTS_IN_SECTION',
        MARKED_AS_READ: 'MARKED_AS_READ',
        NO_ACTION: 'NO_ACTION',
    };

    export default {
        name: "WikiDetailPage",
        components: {
            WikiDetailPageSlideAttachments,
            WikiDetailPageSlider,
            WikiDetailPageSlideContainer,
            WikiMainPageHeader,
            WikiBreadcrumbs
        },
        mixins: [breadcrumbsMixin],
        props: {
            slideId: {
                type: [String, Number],
                required: false
            },
            sectionId: {
                type: [String, Number],
                required: true
            },
            fullScreen: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                slideOpeningTrackingStopped: false,
            };
        },
        computed: {
            accessParams() {
                return this.$store.getters['WikiStore/accessParams'];
            },
            wikiEditor() {
                return this.$store.getters['WikiStore/wikiEditor'];
            },
            viewerState() {
                return this.$store.getters['WikiStore/viewerState'];
            },
            activeSlide() {
                return this.$store.getters['WikiStore/activeSlide'];
            },
            slides() {
                return this.$store.getters['WikiStore/slides'];
            },
            editMode() {
                return this.$store.getters['WikiStore/editMode'];
            },
        },
        methods: {
            loadSlides() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                this.$store.dispatch('WikiStore/loadSectionDetail', {sectionId: this.sectionId, slideId: this.slideId})
                    .finally(() => {
                        this.loaded = true;
                        $rootScope.hide_dimmer();
                    });
            },
            goBack() {
                this.$emit('goBack');
            },
            async markSlideAsRead(slide) {
                if (!this.viewerState.personalMembershipId) {
                    return;
                }
                if (this.slideOpeningTrackingStopped) {
                    return;
                }
                try {
                    const {data} = await wiki_read_receipts_track_resource.track_slide_opened(
                        this.accessParams.wikiId,
                        this.viewerState.personalMembershipId,
                        this.sectionId,
                        slide.id,
                        this.accessParams.accessCode,
                        this.accessParams.accessToken,
                    );
                    if (data === POSSIBLE_TRACK_SLIDE_RESPONSES.NO_RECEIPTS_IN_SECTION) {
                        this.slideOpeningTrackingStopped = true;
                    }
                } catch (e) {

                }
            },
        },
        mounted() {
            this.loadSlides();
        },
        watch: {
            activeSlide(slide) {
                this.markSlideAsRead(slide);
                if (slide) {
                    this.$router.replace({
                        params: {slideId: slide.id},
                        query: this.$route.query
                    });
                }
            },
            sectionId() {
                this.loadSlides();
            },
            slideId(n) {
                for (const s of this.slides) {
                    if (parseInt(n) === s.id) {
                        if (this.activeSlide && (this.activeSlide?.id !== n)) {
                            this.$store.commit('WikiStore/setActiveSlide', s);
                        }
                    }
                }
            },
        }
    };
</script>

<style scoped>

</style>
