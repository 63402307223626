function getAuthorNameFromComment(comment) {
    if (!comment) {
        return '';
    }
    let name = 'Anonymous User';

    if (comment.userprofile) {
        name = comment.userprofile.name;
    } else if (comment.anonymous_user && comment.anonymous_user.name) {
        name = comment.anonymous_user.name;
    }
    return name;
}

export { getAuthorNameFromComment };