/**
 * Created by orion on 6/14/17.
 */
import './style.scss';

angular.module('mmApp.headerDirective', [])
    .directive("simpleHeader", ['$rootScope', '$log', function ($rootScope, $log) {
        return {
            restrict: 'E',
            replace: true,
            transclude: {
                headerTitle: '?headerTitle',
                panelRight: '?panelRight',
                headerLabel: '?headerLabel'
            },
            scope: {
                iconsScaled: '=',
                iconsCount: '=',

                leftPartSize: '@',
                rightPartSize: '@',
                avatarUrl: '@?',
                gridSize: '@?',
                disableTitle: '='
            },
            template: require('./template.html'),
            link: function ($scope, element, attrs, controller) {
                const gridSize = $scope.gridSize || 'sm';

                $scope.get_container_class = function () {
                    if ($scope.iconsScaled) {
                        return ['col-xs-12', '', `icons-${gridSize}-${$scope.iconsCount}`];
                    }
                };

                $scope.get_left_part_size = function () {
                    if ($scope.iconsScaled) {
                        return;
                    }
                    let size = $scope.leftPartSize || 6;
                    return `col-${gridSize}-${size}`;
                };
                $scope.get_right_part_size = function () {
                    if ($scope.iconsScaled) {
                        return;
                    }
                    let size = $scope.rightPartSize || 6;
                    return `col-${gridSize}-${size}`;
                };
            }
        };
    }
    ]);
export default 'mmApp.headerDirective';