<template>
    <span v-if="wikiSnapshotAfter">
        <span>updated Wiki </span>
        <strong>
            #{{ wikiSnapshotAfter.id }} "{{ wikiSnapshotAfter.name }}"
        </strong>
        <span v-if="verbalChangesSubtypes">({{ verbalChangesSubtypes }})</span>
    </span>
</template>

<script>
    import SnapshotLogMixin from "./common/SnapshotLogMixin";

    export default {
        name: "WikiUpdatedParamsChanged",
        mixins: [SnapshotLogMixin],
    };
</script>

<style scoped>

</style>