<template>
    <div class="goal-task-child" :style="[taskBackgroundColor]">
        <div class="goal-task-child__button-delete"
             v-tooltip="deleteTaskLabel"
             @click="deleteTask"
             v-if="editMode&&isEditable&&allowDelete">
            <i class="fa fa-trash-o"></i>
        </div>
        <div class="goal-task-child__main-part">
            <div class="goal-task-child__avatar-and-checkbox"
                 :class="{'no-avatar':task.uses_default_avatar&&!task.uses_generated_avatar, 'with-checkbox':task.has_steps||isCompletedMarkEditable(task)||(editMode&&isEditable)}">
                <div class="inline-block vertical-top">
                    <div class="goal-task-child__avatar-and-checkbox__sync-mark__container">
                        <i v-if="editMode&&isEditable"
                           class="fa fa-sync goal-task-child__avatar-and-checkbox__sync-mark"
                           :style="syncMarkStyle"
                           v-tooltip="'Apply parent task\'s changes.'"
                           @click="toggleSync"
                        ></i>
                    </div>
                    <div class="goal-task-child__avatar-and-checkbox__checkbox__container"
                         v-if="task.has_steps||isCompletedMarkEditable(task)">
                        <i class="fa fa-check-square-o goal-task-child__avatar-and-checkbox__checkbox pointer"
                           v-show="isCompletedMarkEditable(task)"
                           v-if="!task.has_steps"
                           :style="checkBoxStyle"
                           v-tooltip.bottom="completedMarkHintText"
                           @click="onCompleteCheckboxClicked"
                           ref="complete-checkbox-desktop"
                        ></i>
                        <i class="fal fa-list-ol goal-task-child__avatar-and-checkbox__checkbox"
                           v-if="task.has_steps"
                           :style="checkBoxStyle"
                        ></i>
                        <goal-task-complete-dropdown
                                v-if="allowSetAssistanceOptions(task)"
                                ref="complete-dropdown"
                        ></goal-task-complete-dropdown>
                    </div>
                </div>
                <img :src="task.avatar" width="80" height="80" class="img-rounded vertical-top" alt=""
                     v-if="!task.uses_default_avatar||task.uses_generated_avatar">
            </div>
            <div class="goal-task-child__info"
                 :class="{'no-avatar':task.uses_default_avatar&&!task.uses_generated_avatar, 'with-checkbox':task.has_steps||isCompletedMarkEditable(task)||(editMode&&isEditable)}">
                <div class="goal-task-child__info__name">
                    <div class="goal-task-child__status-mark" v-if="statusLabelVisible" :style="statusColor">
                        {{ statusLabel }}
                    </div>
                    <router-link :to="task.link |backHrefQuery"
                                 class="vertical-middle color-black"
                                 v-if="routerNavigation"
                                 @click="editOrOpenTask"
                                 v-tooltip="(isEditable&&editMode)?editTaskLabel:undefined"
                    >{{ task.name | cutName(50) }}
                    </router-link>
                    <a :href="task.link |backHref" v-else
                       class="vertical-middle color-black"
                       @click="editOrOpenTask"
                       v-tooltip="(isEditable&&editMode)?editTaskLabel:undefined"
                    >{{ task.name | cutName(50) }}</a>
                    <i
                            class="fa vertical-middle fa-pencil pointer edit-title-pencil"
                            v-show="editMode&&isEditable" v-tooltip="editTaskLabel"
                            @click="editTask"></i>
                </div>
                <goal-task-time-assignee-panel
                        :task="task"
                        :edit-mode="editMode"
                        :is-assignee-editable="isAssigneeEditable"
                        :is-editable="isEditable"
                        :layout="layout"
                        :in-child="true"
                        @editDates="editDates"
                        @editAssignee="editAssignee"
                >
                </goal-task-time-assignee-panel>
                <div class="div">
                    <span class="pointer" :class="{bold:showTags}" @click="toggleTags">
                    <i class="fa-tags" :class="{far:showTags,fal:!showTags}"></i>
                    <span>Tags ({{ task.tags.length }})</span>
                        <i class="fa-exclamation color-blue"
                           v-if="tagsDiffers"
                           :class="{far:showTags,fal:!showTags}"
                           v-tooltip="differsTaskLabel"
                        ></i>
                    </span>
                    <span class="pointer" :class="{bold:showDescription}" @click="toggleDescription">

                    <i class="fa-align-left" :class="{far:showDescription,fal:!showDescription}"></i>

                    <span>Description</span>
                        <i class="fa-exclamation color-blue"
                           v-if="descriptionDiffers"
                           :class="{far:showDescription,fal:!showDescription}"
                           v-tooltip="differsTaskLabel"
                        ></i>
                    </span>

                    <span class="pointer" :class="{bold:showSteps}" @click="toggleSteps" v-if="task.has_steps" style>
                        <span style="white-space: pre">
                            <i class="fa-list-ol" :class="{far:showSteps,fal:!showSteps}"></i>&nbsp;<span>Steps</span>
                        </span>
                    </span>
                </div>
            </div>
        </div>
        <div class="goal-task-child__optional-part" v-if="showTags||showDescription||showSteps">
            <div class="" v-if="showSteps">
                <goal-task-steps
                        :allow-set-assistance-options="allowSetAssistanceOptions(task)"

                        :small="true"
                        :steps="task.steps"
                        :task-id="task.id"
                        :completable="isCompletedMarkEditable(task)"
                        @taskCompleted="onTaskCompletedByStep"
                        @taskInCompleted="onTaskInCompletedByStep"
                        style="margin-left: 0"
                ></goal-task-steps>
            </div>
            <div class="" v-if="showTags">
                <div class="" v-if="editMode&&isEditable" style="margin: 5px 0">
                    <vue-tags-input
                            v-model="tag"
                            :max-tags="20"
                            :tags="tags"
                            @tags-changed="tagsChanged"
                            :autocomplete-items="autocompleteItems"
                            @before-adding-tag="beforeAddingTag"
                            @before-deleting-tag="beforeDeletingTag"
                    />
                </div>
                <div v-else style="margin: 5px 0">
                    <task-tags-list :tags="task.tags" v-if="task.tags&&task.tags.length"></task-tags-list>
                    <span v-else><i style="font-size: 14px">No tags added</i></span>
                </div>
            </div>
            <div class="" v-if="showDescription">
                <div class="goal-task-component-desktop__content__description">
                    <span v-if="taskDescription">{{ taskDescription }}</span>
                    <span v-else><i style="font-size: 14px">No description added</i></span>
                    <span v-if="taskDescription&&isDescriptionLargerThenPossible"
                          class="goal-task-component-desktop__content__description__show-all"
                          @click="showWholeDescription">show all</span>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import GoalTaskMixin from "./GoalTaskMixin";
    import GoalTaskTimeAssigneePanel from './GoalTaskTimeAssigneePanel.vue';
    import {VueTagsInput} from '@johmun/vue-tags-input';
    import TaskTagsList from '../../../TaskTagsList.vue';
    import GoalTaskTagsMixin from '../GoalTaskTagsMixin.js';
    import GoalTaskSteps from './GoalTaskSteps.vue';
    import GoalTaskCompleteDropdown from './GoalTaskCompleteDropdown.vue';
    import RouterNavigationMixin from "../../../../../../../vue/mixins/RouterNavigationMixin";

    export default {
        components: {
            VueTagsInput,
            GoalTaskTimeAssigneePanel,
            TaskTagsList,
            GoalTaskSteps,
            GoalTaskCompleteDropdown,
        },
        mixins: [GoalTaskTagsMixin, GoalTaskMixin, RouterNavigationMixin],
        props: {
            task: Object,
            parentTask: Object,

            editMode: Boolean,
            isAssigneeEditable: Boolean,
            isEditable: Boolean,
            syncIsPossible: Boolean,

            allowSetAssistanceOptions: Function,
            availableCompletionOptions: Array,

            isSynced: Boolean,
            goal: Object,
        },
        data() {
            return {
                showTags: false,
                showDescription: false,
                showSteps: false,
            };
        },
        computed: {
            editTaskLabel() {
                return `Edit ${this.$customTerm('Task')}`;
            },
            deleteTaskLabel() {
                return `Delete ${this.$customTerm('Task')}`;
            },
            differsTaskLabel() {
                return `Differs from the parent ${this.$customTerm('Task')}`;
            },
            syncMarkStyle() {
                const style = {'cursor': 'pointer'};
                if (!this.isSynced) {
                    style['color'] = '#9c9c9c';
                } else {
                    style['color'] = '#2a7bac';
                }
                return style;
            },
            descriptionDiffers() {
                return this.task.description !== this.parentTask.description;
            },
            tagsDiffers() {
                if (!_.isArray(this.task.tags) || !_.isArray(this.parentTask.tags)) {
                    return false;
                }
                return _.difference(this.task.tags, this.parentTask.tags).length > 0 || _.difference(this.parentTask.tags, this.task.tags).length > 0;
            }
        },

        name: "GoalTaskChild",
        methods: {
            toggleSync() {
                this.$emit('toggleSync', this.task);
            },
            toggleDescription() {
                this.showDescription = !this.showDescription;
            },
            toggleTags() {
                this.showTags = !this.showTags;
            },
            toggleSteps() {
                this.showSteps = !this.showSteps;
            }
        }
    };
</script>

<style scoped lang="scss">
  @import "../../../../../styles/const";

  $separator-border-style: solid 1px $tag-gray-dark;
  .vue-tags-input ::v-deep {
    max-width: 100% !important;
    border-radius: 5px !important;

    .input {
      border-radius: 5px !important;
    }

    .tag {
      background-color: #9c9c9c;
      font-size: 16px;
      border-radius: 5px;
    }
  }



  .goal-task-child {
    position: relative;

    &__status-mark {
      $size: 17px;
      vertical-align: middle;
      display: inline-block;
      min-height: $size;
      line-height: $size;
      font-size: $size/3*2;
      padding: 0 $size/2;
      border: 4px solid;
      border-radius: $size/3;
      font-weight: bold;
      text-align: center;
    }

    &__button-delete {
      position: absolute;
      color: $red;
      cursor: pointer;
      font-size: 25px;
      line-height: 25px;
      right: 5px;
      top: 5px;
    }

    &__link-switch {
      color: $gray;
      /*cursor: pointer;*/
      font-size: 20px;

      &.active {
        color: $blue;
      }
    }

    &__main-part {
      font-size: 0;
    }

    &__optional-part {
      padding: 5px;
    }

    background-color: white;

    &:first-child {
      border-top: $separator-border-style;
    }

    border-bottom: $separator-border-style;

    &__avatar-and-checkbox {
      vertical-align: top;
      /*background-color: #0064cd;*/
      display: inline-block;

      &.no-avatar {
        width: 0;
      }

      &.with-checkbox {
        width: 120px;

        &.no-avatar {
          width: 35px;
          margin-right: 0;
        }
      }

      min-height: 100px;
      padding: 5px;

      &__checkbox {
        font-size: 25px;
        line-height: 25px;
        /*vertical-align: middle;*/
        margin-right: 5px;
        margin-top: 5px;
      }

      &__sync-mark {
        font-size: 21px;
        line-height: 23px;
        margin-right: 5px;

        &__container {
          height: 25px;

          .fa {
            transition: color 0.2s ease;
          }
        }
      }
    }

    &__info {
      vertical-align: top;
      /*background-color: #9d261d;*/
      display: inline-block;
      width: calc(100% - 120px);

      &.no-avatar {
        width: 100%;

        &.with-checkbox {
          width: calc(100% - 35px);
        }

      }

      min-height: 100px;
      font-size: 14px;

      &__name {
        font-size: 18px;
        font-weight: bolder;
        line-height: 18px;
        margin: 5px 0;
      }
    }
  }
</style>