var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "col-md-2 folder_filters_panel",
      class: { "blocked-for-deletion": _vm.blocked },
    },
    [
      _vm._l(_vm.filterButtons, function (button) {
        return _c("users-media-page-filter-panel-button", {
          key: button.text,
          attrs: {
            icon: button.icon,
            "is-active": _vm.filter.type == button.value,
            text: button.text,
          },
          nativeOn: {
            click: function ($event) {
              return _vm.set_filter(button.value)
            },
          },
        })
      }),
      _vm._v(" "),
      _vm.isMyMedia && _vm.isRootFolder
        ? _c("users-media-page-filter-panel-button", {
            attrs: {
              icon: "fa-folder-open-o",
              "is-active": _vm.showShared,
              text: "Shared",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.changeSharedMode.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }