<template>
    <div class="">
        <community-page-alternative-header title="Our Events" :left-part-size="10">
            <vue-folder-button icon-class="fa-plus" @click.native="createNewEvent()"
                               v-if="viewerIsAdmin"></vue-folder-button>
        </community-page-alternative-header>
        <div class="alert alert-info" v-if="allLoaded&&!results.length">
            <span>This Community has not published any events yet</span>
        </div>
        <div class="margin-15-top">
            <group-events-page-event
                    :event="event"
                    v-for="event in results"
                    :key="event.id"
                    :router-navigation="true"
                    :show-membership-state="currentUserIsAuthenticated"
            ></group-events-page-event>
        </div>
        <div v-infinite-scroll="loadMore"></div>
        <create-event-popup ref="create-event-popup" v-if="viewerIsAdmin"></create-event-popup>
    </div>
</template>

<script>
    import CommunityPageAlternativeHeader from "./CommunityPageAlternativeHeader.vue";
    import CreateEventPopup from "../events/new_event/CreateEventPopup.vue";
    import Vue from 'vue';
    import InfiniteScrollMixin from "../../../vue/mixins/InfiniteScrollMixin";
    import GroupEventsPageEvent from "../groups/group_events/GroupEventsPageEvent.vue";

    export default {
        name: "CommunityPageEvents",
        components: {CommunityPageAlternativeHeader, CreateEventPopup, GroupEventsPageEvent},
        mixins: [InfiniteScrollMixin],
        data() {
            return {
                archive: false
            };
        },
        computed: {
            currentUserIsAuthenticated() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                return CurrentUser.is_authenticated();
            },
            community() {
                return this.$store.getters['communityPage/community'];
            },
            viewerIsAdmin() {
                return this.$store.getters['communityPage/viewerIsAdmin'];
            },
            subscriptionState(){
                return this.$store.getters['communityPage/subscriptionState'];
            }
        },
        methods: {
            getListEndpoint() {
                const CommunityResource = Vue.getAngularModule('CommunityResource');
                return CommunityResource.get_events;
            },
            getListEndpointArgs() {
                return [this.community.id, !this.archive];
            },
            createNewEvent() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const $filter = Vue.getAngularModule('$filter');
                const notifications = Vue.getAngularModule('notifications');
                const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
                this.$refs['create-event-popup'].show({privateEvent: false}, {
                    header: `Create Community Event in ${this.community.name}`,
                    privateLabel: `Make this a Private Event. (Hide from un-invited users).`,
                    privateHelpText: null,
                })
                    .then(eventData => {
                        // console.log(eventData);
                        $rootScope.show_dimmer();
                        return CalendarEventsResource.create_calendar_event({
                            target: 'community',
                            community_id: this.community.id
                        }, eventData);
                    }, () => new Promise(()=>{}))
                    .then(resp => {
                        this.$router.push($filter('backHref')(resp.data.link));
                        notifications.success('Created');
                    }, err => {
                        notifications.error(err || 'Error');
                        $rootScope.hide_dimmer();
                    })
                    .finally();
            }
        },
        watch:{
            subscriptionState(n,o){
                if(o!==undefined){
                    this.resetResults();
                    this.getResults(true)
                }
            }
        }
    };
</script>

<style scoped>

</style>