import CountDownPopup from './CountDownPopup.vue';
import moment from 'moment';

window.moment=moment;
class SessionHandler {
    constructor ($rootScope, $timeout, $interval, CurrentUser, sessionExpireResource, notifications, simplePopupFactory) {
        if (!CurrentUser.is_authenticated()) {
            return;
        }
        this.coundDownPopupIsVisible = false;
        this.sessionExpireResource = sessionExpireResource;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.$interval = $interval;
        this.notifications = notifications;
        this.simplePopupFactory = simplePopupFactory;
        this.COUNTDOWN_TIME = 5 * 60;
        this.CHECK_PERIOD_TIME_SECONDS = 2 * 60;
        this.bindVisibilityChangeListener();
        this.startPeriodicChecking().catch(() => {
        });
    }

    bindVisibilityChangeListener () {
        this.$rootScope.$on('tabVisibilityChanged', (e, isVisible) => {
            if (isVisible) {
                this.checkSessionStatus();
            }
        });
    }

    async startPeriodicChecking () {
        setInterval(() => {
            this.checkSessionStatus();
        }, this.CHECK_PERIOD_TIME_SECONDS * 1000);
    }

    async checkSessionStatus () {
        try {
            const resp = await this.sessionExpireResource.get_session_status();
            let {
                session_active,
                seconds_before_expiration
            } = resp.data;
            if (!session_active) {
                this.refreshPage();
            } else if (seconds_before_expiration <= this.COUNTDOWN_TIME) {
                if (!this.coundDownPopupIsVisible) {
                    this.showCountDownPopup(seconds_before_expiration).catch(() => {
                    });
                }
            } else {
                if (this.coundDownPopupIsVisible) {
                    this.closeCountDownPopup().catch(() => {
                    });
                }
            }
        } catch (e) {
            this.notifications.error(e);
        }
    }

    async showCountDownPopup (secondsBeforeSessionExpire) {
        this.coundDownPopupIsVisible = true;
        const {
            popupComponent,
            fEl,
            vm
        } = this.simplePopupFactory.mount_vue_popup(CountDownPopup);
        this.popupComponent = popupComponent;
        const res = await popupComponent.show(secondsBeforeSessionExpire).catch(() => {
        });
        this.coundDownPopupIsVisible = false;
        vm?.$destroy();
        fEl?.focus();
        if (res) {
            this.tryToPreventExpiration(false).catch(() => {
            });
        } else {
            setTimeout(() => {
                this.checkSessionStatus();
            }, 1000);
        }
    }

    async closeCountDownPopup () {
        this.popupComponent.close();
    }

    refreshPage () {
        document.location.reload();
    }

    async tryToPreventExpiration (silent = false) {
        if (!silent) {
            this.$rootScope.show_dimmer();
        }
        try {
            const resp = await this.sessionExpireResource.session_prevent_expire();
            const {
                session_active,
                seconds_before_expiration
            } = resp.data;
            if (!session_active) {
                this.refreshPage();
            } else if (seconds_before_expiration > 2) {
                this.$rootScope.hide_dimmer();
                if (!silent) {
                    this.notifications.success('Session successfully continued');
                }
            } else {
                this.refreshPage();
            }
        } catch (e) {
            this.notifications.error(e);
        }
    }

    start_session_auto_support () {
        this._support_interval = setInterval(() => {
            this.tryToPreventExpiration.call(this, [true]);
        }, 2 * 60 * 1000);
    }

    stop_session_auto_support () {
        if (this._support_interval !== undefined) {
            clearInterval(this._support_interval);
        }
    }

}

SessionHandler.$inject = ['$rootScope', '$timeout', '$interval', 'CurrentUser', 'sessionExpireResource', 'notifications', 'simplePopupFactory'];
export default SessionHandler;
