/**
 * Created by orion on 09.05.17.
 */

import angular from 'angular'

// mainFilters
import main_filters from '../shared/filters/filters_module'
import search_input from '../shared/inputs/search_input/search_input_module'
import preload from '../preload/preload'
//shared modules

import simplePopups from '../shared/simple_popups/simple_popup_module';
import notifications from '../shared/notifications/notifications_module';
import infiniteScroll from '../shared/infiniteScroll_directive'
import selectFactory from '../shared/select_factory/selectFactory_module'
import backUrlGenerator from '../shared/utils/back_url_generator'


//app modules
import add_media from '../add_media/add_media_module'
import view_media from '../view_media/view_media_module'
import sharing_module from '../sharing/sharing_module'


//pages
import storyDetailController from './storyDetailController'
import StoryStoryController from './StoryStoryController'
import StoriesListController from './StoriesListController'
import SharedStoriesListController from './SharedStoriesListController'
import StoryMembersController from './storyMembers/storyMembersController'
import StoryCommentsController from './storyComments/StoryCommentsController'

//resources
import StoryResource from './story_resource'
import StoryMembersResource from './story_members_resource'

//directives
import storiesList from './story_list_directive/directive'

//factories
import LikeStoryResourceFactory from '../shared/story/resource_factory'
import CommentsInfiniteScrollResourceFactory from '../shared/comments/resource_infinite_scroll_factory'


angular.module('mmApp.stories',
    ['ui.bootstrap',
        preload, simplePopups, backUrlGenerator, notifications, add_media, view_media, main_filters, search_input, infiniteScroll, selectFactory, sharing_module])
    .factory('StoryStoryResource', LikeStoryResourceFactory('story_story'))
    .factory('StoryActivityPostResource', LikeStoryResourceFactory('story_activity_post'))
    .factory('StoryCommentsResource', CommentsInfiniteScrollResourceFactory('story_comments'))
    .factory('StoryResource', StoryResource)
    .factory('StoryMembersResource', StoryMembersResource)
    .controller('StoryStoryController', StoryStoryController)
    .controller('StoriesListController', StoriesListController)
    .controller('StoryMembersController', StoryMembersController)
    .controller('SharedStoriesListController', SharedStoriesListController)
    .controller('StoryCommentsController', StoryCommentsController)
    .controller('storyDetailController', storyDetailController)
    .directive('storiesList', storiesList)
;


export default 'mmApp.stories'