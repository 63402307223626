var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chat-style-comment-linked-entry" }, [
    _c(
      "span",
      {
        staticClass: "chat-style-comment-linked-entry__text",
        class: {
          "chat-style-comment-linked-entry__text_white": _vm.moveAvatarToRight,
        },
      },
      [
        _c(
          "strong",
          [_c("simple-link", { attrs: { target: _vm.comment.userprofile } })],
          1
        ),
        _vm._v(" has linked an\n        "),
        !_vm.blogEntry.accessible
          ? _c("strong", [_vm._v("Entry")])
          : _c("strong", [
              _c("a", { attrs: { href: _vm.blogEntry.link } }, [
                _vm._v("Entry"),
              ]),
            ]),
        _vm._v(" to\n        "),
        _vm.blogEntry.linked_by.id !== _vm.blogEntry.data.blog_owner.id
          ? _c("span", [
              _c(
                "strong",
                [
                  _c("simple-link", {
                    attrs: { target: _vm.blogEntry.data.blog_owner },
                  }),
                ],
                1
              ),
              _vm._v("'s\n        "),
            ])
          : _c("span", [_vm._v("their")]),
        _vm._v(" "),
        !_vm.blogEntry.accessible
          ? _c("span", [_vm._v("Diary:")])
          : _c("strong", [
              _c("a", { attrs: { href: _vm.blogEntry.link_to_diary } }, [
                _vm._v("Diary"),
              ]),
              _vm._v(":\n        "),
            ]),
        _vm._v(" "),
        _c("br"),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("br"),
        _vm._v(" "),
        _c(
          "render-quote",
          { attrs: { "limit-size": true, "quote-border-color": null } },
          [
            _vm.blogEntry.data.emotion
              ? _c(
                  "div",
                  {},
                  [
                    _c("entry-emotion", {
                      attrs: { emotion: _vm.blogEntry.data.emotion, size: 30 },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("render-activity-post", {
              attrs: { "activity-post": _vm.blogEntry.data.activity_post },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }