<template>
    <span>
        <simple-link
                :target="target"
                v-if="target"
        ><span v-if="!noQuotes">"</span>{{ label  | cutName(60) }}<span v-if="!noQuotes">"</span></simple-link>
        <span v-else><span v-if="!noQuotes">"</span>{{ label  | cutName(60) }}<span v-if="!noQuotes">"</span></span>
    </span>
</template>

<script>
    export default {
        name: "WikiLogPossibleLink",
        props: {
            label: String,
            target: Object,
            noQuotes: {
                type: Boolean,
                default: false,
            }
        },
    };
</script>