<template>
    <span v-if="slideSnapshotBefore">
        <span>deleted page </span>
        <strong>
            #{{ slideSnapshotBefore.id }} <span v-if="slideSnapshotBefore.caption">"{{ slideSnapshotBefore.caption }}"</span>
        </strong>
        <span v-if="slideSnapshotBefore.section_name">
            in <strong>#{{ slideSnapshotBefore.section }}
            <wiki-log-possible-link
                :target="wikiLog.target_wiki_section"
                :label="slideSnapshotBefore.section_name"
            ></wiki-log-possible-link>
        </strong>
        </span>
    </span>
</template>

<script>
    import SnapshotLogMixin from "./common/SnapshotLogMixin";

    export default {
        name: "WikiSlideDeleted",
        mixins:[SnapshotLogMixin]
    };
</script>

<style scoped>

</style>