import Vue from 'vue';
import ManagedCirclesList from './ManagedCirclesList.vue';

function CirclesListAsAdminPageController() {
    const vm = new Vue({
        el: '#CirclesListAsAdminPageController',
        components: {ManagedCirclesList},
        template: `<managed-circles-list></managed-circles-list>`,
    });
}

export default CirclesListAsAdminPageController;