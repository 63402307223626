/**
 * Created by orion on 28.05.17.
 */
SharedStoriesListController.$inject = ['$preloaded', '$q', '$log', '$timeout', 'simplePopupFactory', 'notifications'];
function SharedStoriesListController($preloaded, $q, $log, $timeout, simplePopupFactory, notifications) {
    let self = this;
    function init() {
        self.stories = $preloaded.stories;
    }
    init();
}

export default SharedStoriesListController;