<template>
        <span
                class="wlip-image-container"
        >
            <img :src="wikiLog.image_preview" alt="Slide Preview" class="img-thumbnail" width="150">
        </span>
</template>

<script>
    export default {
        name: "WikiLogImagePreview",
        props: {
            wikiLog: Object
        }
    };
</script>

<style scoped>
    .wlip-image-container {
        display: block;
        text-align: left;
    }
</style>