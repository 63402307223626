var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.target
        ? _c("simple-link", { attrs: { target: _vm.target } }, [
            !_vm.noQuotes ? _c("span", [_vm._v('"')]) : _vm._e(),
            _vm._v(_vm._s(_vm._f("cutName")(_vm.label, 60))),
            !_vm.noQuotes ? _c("span", [_vm._v('"')]) : _vm._e(),
          ])
        : _c("span", [
            !_vm.noQuotes ? _c("span", [_vm._v('"')]) : _vm._e(),
            _vm._v(_vm._s(_vm._f("cutName")(_vm.label, 60))),
            !_vm.noQuotes ? _c("span", [_vm._v('"')]) : _vm._e(),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }