var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "entry-emotion inline-block",
      attrs: {
        tabindex: "-1",
        "aria-label": `I\'m Feeling ${_vm.emotionObject.description}`,
        role: "figure",
      },
    },
    [
      _vm.showLabel
        ? _c(
            "div",
            {
              staticClass: "entry-emotion__label vertical-middle inline-block",
              style: _vm.iconLabelStyle,
            },
            [_vm._v("I'm\n        Feeling:\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "entry-emotion__icon vertical-middle inline-block" },
        [
          _c(
            "div",
            {
              staticClass:
                "entry-emotion__icon__icon inline-block vertical-middle",
              style: _vm.iconStyle,
            },
            [
              _c("i", {
                staticClass: "fal",
                class: _vm.emotionObject.iconClass,
              }),
            ]
          ),
          _vm._v(" "),
          _vm.showLabel
            ? _c(
                "div",
                {
                  staticClass:
                    "entry-emotion__icon__label inline-block vertical-middle",
                  style: _vm.iconLabelStyle,
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.emotionObject.description) +
                      "\n        "
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.showDelete
        ? _c(
            "div",
            {
              staticClass: "entry-emotion__delete-icon color-red",
              on: { click: _vm.del },
            },
            [_c("i", { staticClass: "fa fa-times pointer color-red" })]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }