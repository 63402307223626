var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vue-wiki-slide-attachments" }, [
    _vm.slide && (_vm.editMode || _vm.attachmentsExists)
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12 text-center" }, [
            _c(
              "div",
              { staticClass: "vue-wiki-slide-attachments__inner-container" },
              [
                _vm._l(
                  _vm.slide.attached_documents,
                  function (attached_document) {
                    return _c(
                      "a",
                      {
                        staticStyle: { "text-decoration": "none" },
                        attrs: {
                          href: attached_document.media_content
                            .uploaded_document.document_url,
                          target: "_blank",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vue-wiki-slide-attachments__attachment vue-wiki-slide-attachments__attachment__document vertical-middle",
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-file-text vertical-middle",
                              attrs: { "aria-hidden": "true" },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "vertical-middle" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("cutName")(
                                    attached_document.media_content.name,
                                    22
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.editMode,
                                  expression: "editMode",
                                },
                              ],
                              staticClass:
                                "fa fa-window-close color-red vertical-middle",
                              staticStyle: {
                                "font-size": "26px",
                                "margin-left": "5px",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.deleteAttachment(
                                    "file",
                                    attached_document.id
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]
                    )
                  }
                ),
                _vm._v(" "),
                _vm._l(_vm.slide.attached_links, function (attached_link) {
                  return _c(
                    "a",
                    {
                      staticStyle: { "text-decoration": "none" },
                      attrs: { href: attached_link.url, target: "_blank" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vue-wiki-slide-attachments__attachment vue-wiki-slide-attachments__attachment__link vertical-middle",
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-link vertical-middle",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "vertical-middle" }, [
                            _vm._v(
                              _vm._s(_vm._f("cutName")(attached_link.name, 22))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editMode,
                                expression: "editMode",
                              },
                            ],
                            staticClass:
                              "fa fa-window-close color-red vertical-middle",
                            staticStyle: {
                              "font-size": "26px",
                              "margin-left": "5px",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.deleteAttachment(
                                  "link",
                                  attached_link.id
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]
                  )
                }),
                _vm._v(" "),
                _vm._l(
                  _vm.slide.attached_locations,
                  function (attached_location) {
                    return _c(
                      "a",
                      {
                        staticStyle: { "text-decoration": "none" },
                        attrs: {
                          href: _vm.getGmapsHref(attached_location.location),
                          target: "_blank",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vue-wiki-slide-attachments__attachment vue-wiki-slide-attachments__attachment__location vertical-middle",
                          },
                          [
                            _c("i", {
                              staticClass:
                                "far fa-map-marker-check vertical-middle",
                              attrs: { "aria-hidden": "true" },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "vertical-middle" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("cutName")(
                                    attached_location.location.address,
                                    22
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.editMode,
                                  expression: "editMode",
                                },
                              ],
                              staticClass:
                                "fa fa-window-close color-red vertical-middle",
                              staticStyle: {
                                "font-size": "26px",
                                "margin-left": "5px",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.deleteAttachment(
                                    "location",
                                    attached_location.id
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]
                    )
                  }
                ),
                _vm._v(" "),
                _vm.editMode
                  ? _c(
                      "a",
                      {
                        staticClass: "vue-wiki-slide-attachments__add-link",
                        staticStyle: { "white-space": "nowrap" },
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.addAttachment.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "far fa-paperclip" }),
                        _vm._v(" Add attachment"),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }