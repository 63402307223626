<template>
  <basic-modal
      @hide="close"
      v-model="opened"
      :aria-label="header"
      role="dialog"
  >
    <div slot="header">
      <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
      <h3 class="modal-title">{{ header }}</h3>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div v-bind:class="{ 'has-error': url_invalid }" class="form-group">
          <label class="control-label" for="url_addr">Paste your link here:</label>
          <input
              v-model="link"
              type="text"
              class="form-control"
              id="url_addr"
              placeholder="http://example.com"
              maxlength="255"
              @input="process_link">
          <span v-if="url_invalid" class="help-block">Incorrect link.</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div v-if="url_processing" class="col-sm-12 text-center">
        <i class="fa fa-refresh fa-spin fa-3x fa-fw" aria-hidden="true"></i>
      </div>
      <div v-if="url_processed" class="col-sm-12">
        <label class="control-label" for="site_name">Link name:</label>
        <input v-model="site_name" class="form-control" id="site_name">
      </div>
    </div>
    <div class="row" v-if="site_image">
      <div class="col-sm-12">
        <div class="" v-bind:style="get_image_style"></div>
      </div>
    </div>

    <div slot="footer" class="row">
      <div class="col-sm-12 text-right">
        <button type="button" class="btn btn-mm btn-primary" @click="back">Back</button>
        <button type="button" class="btn btn-mm btn-green" :disabled="!allow_add" @click="addLink">Add link</button>
      </div>
    </div>

  </basic-modal>
</template>

<script>
import PopupPromiseMixin from "../../../../vue/mixins/PopupPromiseMixin";
import axios from "axios";

export default {
  name: "AddWebsiteLinkPopup",
  mixins: [PopupPromiseMixin],
  data() {
    return {
      header: "Add link",
      allow_add: false,
      url_invalid: false,
      url_processing: false,
      url_processed: false,
      link: '',
      site_name: '',
      site_image: undefined,
    }
  },
  methods: {
    check_link_url(link_url) {
      const url = DjangoUrls["api.v1:check_link_url"]();
      return axios({
        method: 'POST',
        url: url,
        data: {url: link_url}
      });
    },
    isURL(str) {
      const urlPattern = /^(https?:\/\/)?(www\.)?\S+\.\S+$/i;
      return urlPattern.test(str);
    },
    get_image_style() {
      if (!this.site_image) {
        return;
      }
      return {
        'background-image': `url('${this.site_image}')`,
        'background-size': `contain`,
        'background-repeat': `no-repeat`,
        'background-position': `center`,
        'width': `100%`,
        'height': `200px`,
        'margin-top': '10px',
      };
    },
    reset_status() {
      this.url_processed = false;
      this.allow_add = false;
      this.url_invalid = false;
      this.url_processing = false;
      this.site_name = '';
      this.site_image = undefined;
    },
    process_link() {
      this.reset_status();
      if (this.link) {
        if (!this.isURL(this.link)) {
          this.url_invalid = true;
        } else {
          this.url_invalid = false;
          this.url_processing = true;
          this.check_link_url(this.link)
          .then((resp) => {
            this.url_processing = false;
            this.url_processed = true;
            this.url_invalid = false;
            this.site_name = resp.data.name;
            this.site_image = resp.data.image;
            this.allow_add = true;
          }, () => {
            this.url_processing = false;
            this.url_invalid = true;
          })
        }
      }
    },
    addLink() {
      const data = {
        link: this.link,
        name: this.site_name,
        images: this.site_image,
      };
      this.save(data);
    },
    back() {
      this.reject('back');
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
