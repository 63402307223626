import _ from 'lodash'
import angular from 'angular'
import emailValidationResource from './resource'

emailInput.$inject = ['$log', 'emailValidationResource']

function emailInput($log, emailValidationResource) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            email: '=',
            isValid: '=',
        },
        template: require('./template.html'),
        link: function ($scope, element, attrs, controller) {
            $scope.email_error = false;
            $scope.email_valid = false;
            $scope.email_message = undefined;

            function reset_email_valid_status() {
                $scope.email_error = false;
                $scope.email_valid = false;
                $scope.isValid = false;
                $scope.email_message = undefined;
            }

            let check_email_is_valid = _.debounce((email) => {
                $scope.email_message = 'Checking...';
                emailValidationResource.check_email_is_taken(email)
                    .then(resp => {
                        $scope.email_error = resp.data.is_taken;
                        $scope.email_valid = !resp.data.is_taken;
                        $scope.isValid = $scope.email_valid;
                        $scope.email_message = resp.data.is_taken ? 'User with this email already Registered' : '';
                    }, err => {
                        $scope.email_error = true;
                        $scope.email_message = err.data.email[0];
                    })
            }, 600);

            $scope.$watch('email', function (newVal, oldVal) {
                reset_email_valid_status();
                if (newVal) {
                    check_email_is_valid(newVal);
                }
            });
        }
    }
}

angular.module('mmApp.shared.forms.email_input', [])
    .factory('emailValidationResource', emailValidationResource)
    .directive('emailInput', emailInput)

export default 'mmApp.shared.forms.email_input'