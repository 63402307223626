<template>
    <h1>waiting for implementation....</h1>
</template>

<script>
    export default {
        name: "VueInlineMediaTMP"
    };
</script>

<style scoped>

</style>