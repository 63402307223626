<template>
    <community-page-about-us-wiki :wikiId="wikiId"
                                  :subsections-disabled="communityWikiSubsectionsDisabled"
                                  :community="community"></community-page-about-us-wiki>
</template>

<script>
    import CommunityPageAboutUsWiki from './CommunityPageAboutUsWiki.vue';

    export default {
        components: {CommunityPageAboutUsWiki},
        name: "CommunityPageWiki",
        computed: {
            aboutPageIsWiki() {
                return this.$store.getters['communityPage/aboutPageIsWiki'];
            },
            wikiId() {
                return this.$store.getters['communityPage/communityWikiId'];
            },
            communityWikiSubsectionsDisabled() {
                return this.$store.getters['communityPage/communityWikiSubsectionsDisabled'];
            },
            community() {
                return this.$store.getters['communityPage/community'];
            }
        },
    };
</script>

<style scoped>

</style>