<template>
    <div class="goal-task-assignee-and-time" :class="{'restrict-width':!inChild&&!task.uses_default_avatar||task.uses_generated_avatar}">
        <div class="goal-task-assignee-and-time__assignee-part"
             :class="{'goal-task-assignee-and-time__assignee-part_with-assignee':this.task.assignee}">
            Who's doing it?
            <div class="inline-block">
                <div class="inline-block">
                <span :class="{pointer:isAssigneeEditable&&editMode}" @click="setAssignee"
                      v-tooltip="isEditable&&editMode?'Edit Assignee':undefined">
                    <span v-if="!task.assignee" style="text-decoration: underline">Unassigned</span>
                    <span v-if="!!task.assignee" style="text-decoration: underline">{{ task.assignee.name }}</span>
                    <i class="fal fa-pencil" v-show="isAssigneeEditable&&editMode"></i>
                </span>
                </div>
                <div class="inline-block" v-if="task.assignee">
                    <div class="goal-task-assignee-and-time__assignee"
                         @click="setAssignee"
                         :class="{pointer:isAssigneeEditable&&editMode}"
                         v-tooltip="assigneeName">
                        <img :src="assigneeAvatar" alt="" class="img-circle" width="34" height="34"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="goal-task-assignee-and-time__time-part"
             :class="{'goal-task-assignee-and-time__time-part_with-assignee':this.task.assignee}">
            <span class="vertical-middle">When?</span>
            <div class="goal-task-assignee-and-time__time vertical-middle">
                <span
                        :class="{pointer:isEditable&&editMode}"
                        @click="editDates"
                        class="vertical-middle"
                        v-tooltip="isEditable&&editMode?'Edit Task Dates':undefined"
                        style="text-decoration: underline"
                >{{ timeLabel }}</span>
                <i class="fal fa-pencil" v-show="isEditable&&editMode"></i>

                <slot name="additional_counters"></slot>
            </div>
        </div>


    </div>
</template>

<script>
    import config from '../../../../../goals/config.js';
    import moment from 'moment';

    export default {
        props: {
            task: Object,
            editMode: Boolean,
            isAssigneeEditable: Boolean,
            isEditable: Boolean,
            layout: String,
            inChild: {
                type: Boolean,
                default: false
            },
            routerNavigation: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            assigneeAvatar() {
                if (this.task.assignee) {
                    return this.task.assignee.avatar;
                } else {
                    return config.EMPTY_ASSIGNEE_AVATAR;
                }
            },
            assigneeName() {
                let name;
                if (this.task.assignee) {
                    name = this.task.assignee.name;
                } else {
                    name = config.EMPTY_ASSIGNEE_NAME;
                }
                if (this.editMode && this.isAssigneeEditable) {
                    return `Change Assignee (${name})`;
                } else {
                    return name;
                }
            },
            isUnscheduled() {
                return (!this.task.start_date && !this.task.end_date);
            },
            timeLabel() {
                let format = this.layout === 'mobile' ? 'DD/MM/YY' : 'DD MMM YY';
                // let format = 'DD/MM/YY';
                if (this.isUnscheduled) {
                    return 'Unscheduled';
                }
                return `${this.task.start_date ? moment(this.task.start_date, config.API_DATE_FORMAT).format(format) : '???'} - ${this.task.end_date ? moment(this.task.end_date, config.API_DATE_FORMAT).format(format) : '???'}`;
            },
        },
        name: "GoalTaskTimeAssigneePanel",
        methods: {
            editDates() {
                if (!this.isEditable || !this.editMode) {
                    return;
                }
                this.$emit('editDates', this.task);
            },
            setAssignee() {
                if (!this.editMode || !this.isAssigneeEditable) {
                    return;
                }
                if (this.isAssigneeEditable && (!this.task.assignee || this.editMode)) {
                    this.$emit('editAssignee', this.task);
                }
            },
        }
    };
</script>

<style scoped lang="scss">
  .goal-task-assignee-and-time {
    &__assignee-part {

      @media (min-width: 600px) {
        display: inline-block;
        vertical-align: middle;
      }

      &_with-assignee {
        min-height: 35px;
      }
    }

    &__time-part {
      &_with-assignee {
        min-height: 14px;
        line-height: 13px;
        margin-bottom: 10px;
      }
    }

    &__time {
      display: inline-block;
      vertical-align: middle;
    }

    &.restrict-width {
      width: calc(100% - 120px);
    }

    @media (max-width: 599px) {
      display: inline-block;
      vertical-align: top;
    }
  }
</style>