/**
 * Created by orion on 06.04.17.
 */
import urls from 'DjangoUrls';

let {DjangoUrls} = urls;

BlogEntrySharingResource.$inject = ['$http'];

function BlogEntrySharingResource($http) {
    const PREFIX = 'api.v1:blog_entry_sharing-';
    return {
        get_sharing_main_status,
        get_friends_for_share_new_entry,
        get_friends_for_share_existed_entry,
        get_groups_for_share_new_entry,
        get_groups_for_share_existed_entry,
        update_entry_circle_sharing,
        update_entry_group_sharing,
        make_private,
    };

    function get_sharing_main_status(entryId) {
        const url = DjangoUrls[`${PREFIX}get-sharing-main-status`](entryId);
        return $http({
            method: 'GET',
            url: url,
        });
    }

    function get_friends_for_share_new_entry(mainParams, filterParams) {
        const url = DjangoUrls[`${PREFIX}get-friends-for-share-new-entry`]();
        return $http({
            method: 'POST',
            url: url,
            data: mainParams,
            params: filterParams
        });
    }

    function get_friends_for_share_existed_entry(entryId, filterParams) {
        const url = DjangoUrls[`${PREFIX}get-friends-for-share-existed-entry`](entryId);
        return $http({
            method: 'POST',
            url: url,
            params: filterParams
        });
    }

    function get_groups_for_share_new_entry(mainParams, filterParams) {
        const url = DjangoUrls[`${PREFIX}get-groups-for-share-new-entry`]();
        return $http({
            method: 'POST',
            url: url,
            data: mainParams,
            params: filterParams
        });
    }

    function get_groups_for_share_existed_entry(entryId, filterParams) {
        const url = DjangoUrls[`${PREFIX}get-groups-for-share-existed-entry`](entryId);
        return $http({
            method: 'POST',
            url: url,
            params: filterParams
        });
    }

    function update_entry_circle_sharing(entryId, all = false, new_checked_ids = [], new_unchecked_ids = []) {
        const url = DjangoUrls[`${PREFIX}update-entry-circle-sharing`](entryId);
        return $http({
            method: 'POST',
            url: url,
            data: {
                all,
                new_checked_ids,
                new_unchecked_ids,
            },
        });
    }

    function update_entry_group_sharing(entryId, new_checked_ids = [], new_unchecked_ids = []) {
        const url = DjangoUrls[`${PREFIX}update-entry-group-sharing`](entryId);
        return $http({
            method: 'POST',
            url: url,
            data: {
                all: false,
                new_checked_ids,
                new_unchecked_ids,
            },
        });
    }

    function make_private(entryId) {
        const url = DjangoUrls[`${PREFIX}make-private`](entryId);
        return $http({
            method: 'POST',
            url: url,
        });
    }


}

export default BlogEntrySharingResource;