var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": "Upload Video", role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [_vm._v("Upload Video")]),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.filesToUpload, function (file) {
        return _c("div", { key: file.name, staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("div", {}, [
              _vm._v(_vm._s(file.name)),
              file.error
                ? _c("span", { staticClass: "color-footer-red" }, [
                    _vm._v(" (File upload Error)"),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {},
              [
                _c("progress-bar", {
                  attrs: {
                    striped: !file.error,
                    active: file.uploading,
                    type: file.uploaded
                      ? "success"
                      : file.error
                      ? "danger"
                      : undefined,
                  },
                  model: {
                    value: file.progress,
                    callback: function ($$v) {
                      _vm.$set(file, "progress", $$v)
                    },
                    expression: "file.progress",
                  },
                }),
              ],
              1
            ),
          ]),
        ])
      }),
      _vm._v(" "),
      _c("input", {
        ref: "hidden-input",
        staticStyle: { display: "none" },
        attrs: {
          multiple: _vm.maxCount > 1,
          type: "file",
          accept: "video/mp4,video/x-m4v,video/*",
        },
        on: { change: _vm.filesChanged },
      }),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-5 text-left" },
            [
              _vm.allowSaveToMedia
                ? _c("vue-switch-with-label", {
                    attrs: {
                      "collapse-on-mobile": false,
                      name: "save_to_media",
                      label: "Save to My Media",
                    },
                    model: {
                      value: _vm.saveToMedia,
                      callback: function ($$v) {
                        _vm.saveToMedia = $$v
                      },
                      expression: "saveToMedia",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-7 text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-primary",
                on: {
                  click: function ($event) {
                    return _vm.back()
                  },
                },
              },
              [_vm._v("Back")]
            ),
            _vm._v(" "),
            _vm.uploadIsReady
              ? _c(
                  "button",
                  {
                    ref: "uploadButton",
                    staticClass: "btn-mm btn btn-green",
                    attrs: { disabled: _vm.uploadStarted },
                    on: { click: _vm.upload },
                  },
                  [_vm._v("\n                    Upload\n                ")]
                )
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "span",
              {
                staticClass: "focus-catch-link",
                attrs: { tabindex: "0", "aria-hidden": "true" },
                on: { focus: _vm.focusToClose },
              },
              [_vm._v(" ")]
            ),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }