let angular = require('angular');
import './style.scss';

function folderButton() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            text: '@',
            helpText: '@?',
            helpTextInfoIcon: '=?',
            iconClass: '@?',
            color: '@',
            customIcon: '@?',
            top: '@?',
            left: '@?',
            noTextOnMobile: '@?',
        },
        template: require('./folder_button_directive.html'),
        link: function ($scope, element, attrs, controller) {
            $scope.iconColor = () => ($scope.color || 'tag-gray');
            $scope.getFaIconStyle = function () {
                let style = {};
                if ($scope.top) {
                    style['top'] = $scope.top+'px';
                }
                if ($scope.left) {
                    style['left'] = $scope.left+'px';
                }
                return style;
            };

            $scope.getCustomIconStyle = function () {
                let style = {};
                if ($scope.top) {
                    style['background-position-y'] = $scope.top+'px';
                }
                if ($scope.left) {
                    style['background-position-x'] = $scope.left+'px';
                }
                return style;
            };
            $scope.getTooltipHTML = function () {

                if ($scope.helpTextInfoIcon) {
                    return `<i class='fa fa-info-circle'></i> ` + $scope.helpText;
                }
                return $scope.helpText;
            };
        }
    };
}

angular.module('mmApp.shared.folderButton', []).directive('folderButton', folderButton);

export default 'mmApp.shared.folderButton';