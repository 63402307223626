var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "inline-block column", style: _vm.columnStyle },
    [
      _vm.isCurrentHour
        ? _c("div", { staticClass: "today-line", style: _vm.lineOffsetStyle })
        : _vm._e(),
      _vm._v(" "),
      _vm.isCurrentHour
        ? _c("div", {
            staticClass: "today-triangle",
            style: _vm.triangleOffsetStyle,
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: { height: "60px", "text-align": "center" },
          style: _vm.hourStyle,
        },
        [
          _c(
            "div",
            {
              staticClass: "hour-label",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.createEventInThisHour.apply(null, arguments)
                },
                mouseenter: function ($event) {
                  _vm.createMode = true
                },
                mouseleave: function ($event) {
                  _vm.createMode = false
                },
                mousedown: function ($event) {
                  $event.stopPropagation()
                },
                touchstart: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.enableCreateModeOnTouch.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.createMode,
                      expression: "!createMode",
                    },
                  ],
                },
                [_vm._v(_vm._s(_vm.hourLabel))]
              ),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.createMode,
                    expression: "createMode",
                  },
                ],
                staticClass: "plus-grey create-event-button",
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }