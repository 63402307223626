<template>
    <div class="inline-block column" :style="columnStyle">
        <div class="today-line" :style="lineOffsetStyle" v-if="isCurrentHour"></div>
        <div class="today-triangle" :style="triangleOffsetStyle" v-if="isCurrentHour"></div>
        <div class="" style="height: 60px; text-align: center;" :style="hourStyle">
            <div class="hour-label"
                 @click.stop="createEventInThisHour"
                 @mouseenter="createMode=true"
                 @mouseleave="createMode=false"
                 @mousedown.stop
                 @touchstart.stop.prevent="enableCreateModeOnTouch"
            >
                <span v-show="!createMode">{{ hourLabel }}</span>
                <div v-show="createMode" class="plus-grey create-event-button"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import * as config from './config';
    import {eventsBus, EVENTS_BUS_EVENTS} from './eventsBus';
    import {nullify} from "./common";

    export default {
        name: "CalendarPageDayTimelineHour",
        props: {
            width: Number,
            height: Number,
            hour: Number,
            date: String
        },
        data() {
            return {
                nowMinutes: 0,
                isCurrentHour: false,

                createMode: false,
            };
        },
        computed: {
            triangleOffsetStyle() {
                return {
                    'left': `calc(${(this.nowMinutes / 60) * 100}% - 5px)`
                };
            },
            lineOffsetStyle() {
                return {
                    'left': `calc(${(this.nowMinutes / 60) * 100}% - 1px)`
                };
            },
            hourStyle() {
                const style = {};
                if (this.isCurrentHour) {
                    // style['background-color'] = 'green';
                    style['font-weight'] = 'bold';
                }
                return style;
            },
            hourLabel() {
                if (this.hour <= 12) {
                    if (this.hour === 12) {
                        return `${this.hour} pm`;
                    } else {
                        return `${this.hour} am`;
                    }
                } else {
                    return `${this.hour - 12} pm`;
                }
            },
            columnStyle() {
                return {
                    width: `${this.width}px`,
                    height: `${this.height}px`
                };
            }
        },
        methods: {
            arrowUpdate() {
                this.nowMinutes = new Date().getMinutes();
                const now = moment().tz('Europe/London');
                this.isCurrentHour = now.format(config.VISUAL_DATE_FORMAT) === this.date && now.hour() === this.hour;
            },
            createEventInThisHour() {
                if (!this.createMode) {
                    return;
                }
                eventsBus.$emit(EVENTS_BUS_EVENTS.CREATE_EVENT_IN_DATE_AND_HOUR,
                    {
                        startDate: moment(this.date, config.VISUAL_DATE_FORMAT).toDate(),
                        endDate: moment(this.date, config.VISUAL_DATE_FORMAT).toDate(),
                        startHour: nullify(this.hour),
                        endHour: this.hour === 23 ? 23 : this.hour + 1,
                        endMinute: this.hour === 23 ? '59' : '00'
                    }
                );
            },
            enableCreateModeOnTouch() {
                if (!this.createMode) {
                    this.createMode = true;
                    eventsBus.$emit(EVENTS_BUS_EVENTS.HOUR_SWITCHED_TO_CREATE_MODE, this.date, this.hour);


                } else {
                    this.createEventInThisHour();
                }
            },
            onHourSwitchedToCreateMode(date, hour) {
                if (this.date !== date || this.hour !== hour) {
                    this.createMode = false;
                }
            }
        },
        beforeMount() {
            this.arrowUpdate();
            if (moment().tz('Europe/London').format(config.VISUAL_DATE_FORMAT) === this.date) {
                this.arrowChecker = setInterval(() => {
                    this.arrowUpdate.call(this);
                }, 1000 * 60 * 5);
            }
            eventsBus.$on(EVENTS_BUS_EVENTS.HOUR_SWITCHED_TO_CREATE_MODE, this.onHourSwitchedToCreateMode);

        },
        beforeDestroy() {
            eventsBus.$off(EVENTS_BUS_EVENTS.HOUR_SWITCHED_TO_CREATE_MODE, this.onHourSwitchedToCreateMode);
            if (this.arrowChecker !== undefined) {
                clearInterval(this.arrowChecker);
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../../../angular/app/styles/const.scss";
    @import "../../../../angular/app/styles/mixins";

    .hour-label {
        font-weight: bolder;
        color: $tag-gray;
        height: 25px;
        /*cursor: crosshair;*/
        cursor: pointer;
        /*cursor: none;*/
    }


    .column {
        position: relative;
        vertical-align: top;
        border-left: solid 1px rgba(52, 52, 52, 0.17);
        /*background-color: #36a5ca;*/
    }

    .upper-label {
        display: block;
        height: 20px;
        font-size: 18px;
    }

    .today-line {
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 0;
        height: 100%;
        border-left: dashed 2px $footer-red;
        z-index: 4;
    }

    .today-triangle {
        pointer-events: none;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 5px 0 5px;
        border-color: $footer-red transparent transparent transparent;

        position: absolute;
        z-index: 5;
        top: 0;
    }

    .create-event-button {
        display: inline-block;
        width: 25px;
        height: 25px;
        background-size: cover;
        background-image: url('#{$iconsPath}/np_plus_755031_9C9C9C.png');
    }
</style>