<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 aria-label="New Wiki"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">New Wiki</h3>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div>
                    <span>Wiki Name:</span>
                    <input ref="name-input" v-model="name" maxlength="127" type="text" class="form-control"
                           placeholder="Wiki Name"
                           @keydown="nameManuallyChanged=true"
                           required/>
                </div>
            </div>
        </div>
        <div class="row margin-15-top">
            <div class="col-sm-12">
                <div>
                    <span>Wiki Description:</span>
                    <textarea v-model="description" maxlength="1023" type="text" class="form-control"
                              @keydown="descriptionManuallyChanged=true"
                              placeholder="Wiki Description"></textarea>
                </div>
            </div>
        </div>
        <div class="row margin-15-top" v-if="templates.length">
            <div class="col-sm-12">
                <div>
                    <span>Wiki Template:</span>
                    <select class="form-control" v-model="selectedTemplate">
                        <option :value="template" v-for="template in templates" :key="template.id">{{
                                getTemplateVisibleName(template)
                            }}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row margin-15-top">
            <div class="col-sm-5">
                <vue-switch-with-label name="for_user" v-model="showContentCounters"
                                       label="Show content count"></vue-switch-with-label>
            </div>
            <div class="col-sm-7">
                Show content count of each section.
            </div>
        </div>

        <div class="row margin-15-top" v-if="dependents.length">
            <div class="col-sm-5">
                <vue-switch-with-label name="for_user" v-model="forUser"
                                       label="Create for user"></vue-switch-with-label>
            </div>
            <div class="col-sm-7">
                Wiki will belong to selected user. You will be that Wiki's admin.
            </div>
        </div>
        <div class="row margin-15-top" v-if="forUser">
            <div class="col-sm-12">
                <select class="form-control" v-model="selectedUser">
                    <option :value="dependent" v-for="dependent in dependents" :key="dependent.id">{{
                            dependent.name
                        }}
                    </option>
                </select>
            </div>
        </div>

        <div slot="footer">
            <button class="btn btn-mm btn-green" @click="createWiki" :disabled="!allowCreate">Create Wiki</button>
        </div>
    </basic-modal>
</template>

<script>
    import BasicModal from "../../../../vue/common_components/BasicModal";
    import PopupPromiseMixin from "../../../../vue/mixins/PopupPromiseMixin";

    export default {
        name: "CreateNewWikiPopup",
        mixins: [PopupPromiseMixin],
        components: {BasicModal},
        data() {
            return {
                name: '',
                description: '',
                templates: [],
                dependents: [],
                selectedTemplate: undefined,
                selectedUser: undefined,
                forUser: false,
                nameManuallyChanged: false,
                descriptionManuallyChanged: false,
                showContentCounters: false,
            };
        },
        computed: {
            allowCreate() {
                return this.name.length > 3;
            },
            firstFocusEl() {
                return this.$refs['name-input'];
            },
        },
        methods: {
            getTemplateVisibleName(template) {
                let res = template.name;
                if (template.description) {
                    res += ` (${this.$options.filters.cutName(template.description, 25)})`;
                }
                return res;
            },
            setInitial(templates = [], dependents = []) {
                this.templates = templates;
                this.dependents = dependents;
                return new Promise((res, rej) => {
                    this.resolve = res;
                    this.reject = rej;
                });
            },
            createWiki() {
                const res = {
                    name: this.name,
                    description: this.description,
                    template: this.selectedTemplate,
                    show_content_counters: this.showContentCounters,
                };
                if (this.forUser) {
                    res['forUser'] = this.selectedUser;
                }
                this.save(res);
            }
        },
        watch: {
            selectedTemplate(n, o) {
                if (n) {
                    if (n.wiki_name && (!this.name || !this.nameManuallyChanged)) {
                        this.name = n.wiki_name;
                    }
                    if (n.wiki_description && (!this.description || !this.descriptionManuallyChanged)) {
                        this.description = n.wiki_description;
                    }
                }
            }
        }
    };
</script>

<style scoped lang="scss">

</style>