/**
 * Created by orion on 08.06.17.
 */

import urls from 'DjangoUrls';
import axios from 'axios';

let { DjangoUrls } = urls;

function CommentsInfiniteScrollResourceFactory(prefix) {

    let comments_resource = function ($http) {
        return {
            get_list(target_id, params) {
                const url = DjangoUrls[`api.v1:${prefix}-get-list`](target_id);
                return axios({
                    method: 'GET',
                    url,
                    params
                });
            },
            get_one(target_id, params) {
                const url = DjangoUrls[`api.v1:${prefix}-get-one`](target_id);
                return axios({
                    method: 'GET',
                    url,
                    params
                });
            },
            add(target_id, data) {
                const url = DjangoUrls[`api.v1:${prefix}-add`](target_id);
                return axios({
                    method: 'POST',
                    url,
                    data
                });
            },
            like(target_id, data) {
                const url = DjangoUrls[`api.v1:${prefix}-like`](target_id);
                return axios({
                    method: 'POST',
                    url,
                    data
                });
            },
            unlike(target_id, data) {
                const url = DjangoUrls[`api.v1:${prefix}-unlike`](target_id);
                return axios({
                    method: 'POST',
                    url,
                    data
                });
            },
            remove(target_id, data) {
                const url = DjangoUrls[`api.v1:${prefix}-remove`](target_id);
                return axios({
                    method: 'POST',
                    url,
                    data
                });
            },
            mark_posts_as_read(target_id, data = {}) {
                const url = DjangoUrls[`api.v1:${prefix}-mark-posts-as-read`](target_id);
                return axios({
                    method: 'PATCH',
                    url,
                    data
                });
            },
            load_since_time(target_id, time_offset) {
                const url = DjangoUrls[`api.v1:${prefix}-load-since-time`](target_id);
                return axios({
                    method: 'GET',
                    url,
                    params: {
                        time_offset
                    }
                });
            },
        };
    };
    comments_resource.$inject = ['$http'];

    return comments_resource;
}

export default CommentsInfiniteScrollResourceFactory;
