let angular = require('angular');
import './style.scss'
function sharedIcon() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            iconClass: '=',
            hasNew: '=',
            count: '=',
            text:'=',
            hiddenInShowMore:'='
        },
        template: require('./template.html')
        ,
        link: function ($scope, element, attrs, controller) {

        }
    };
}

angular.module('mmApp.shared.leftPanel.sharedIcon', [])
    .directive('sharedIcon', sharedIcon);

export default 'mmApp.shared.leftPanel.sharedIcon'
