import './style.scss';
import Vue from 'vue';
import SiteHeader from './SiteHeader.vue';

siteHeaderController.$inject = ['$scope'];

function siteHeaderController($scope) {
    const vm = new Vue({
        el: '#site-header-component',
        components: {SiteHeader},
        template: `
          <site-header ref="header"></site-header>`,
        name: `siteHeaderController`,
        methods: {
            toggleHeaderVisibility(show) {
                this.$refs.header.toggleHeaderVisibility(show);
            }
        }
    });

    $scope.$on('disableFooterLoginMode', () => {
        vm.toggleHeaderVisibility(true);
    });
    $scope.$on('enableFooterLoginMode', () => {
        vm.toggleHeaderVisibility(false);
    });
    $scope.$on('$destroy', () => {
        if (vm) {
            vm.$destroy();
        }
    });
}

export default siteHeaderController;