var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row add-post-buttons-panel" }, [
    _c(
      "div",
      { staticClass: "col-sm-12 add-post-buttons-panel__button_panel justify" },
      [
        _vm.allowAddMedia
          ? _c(
              "div",
              {
                staticClass:
                  "add-post-buttons-panel__attach_button add-post-buttons-panel__attach_button_green",
                class: {
                  "add-post-buttons-panel__attach_button_disabled":
                    _vm.addMediaDisabled,
                },
                attrs: {
                  role: "button",
                  "aria-label": "Add Media",
                  tabindex: "0",
                },
                on: {
                  click: _vm.addMedia,
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.addMedia.apply(null, arguments)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "far fa-film",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c("i", {
                  staticClass:
                    "fab fa-itunes-note add-post-buttons-panel__second-icon",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(0),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.allowAddSticker
          ? _c(
              "div",
              {
                staticClass:
                  "add-post-buttons-panel__attach_button add-post-buttons-panel__attach_button_blue",
                class: {
                  "add-post-buttons-panel__attach_button_disabled":
                    _vm.addStickerDisabled,
                },
                attrs: {
                  role: "button",
                  "aria-label": "Add Sticker",
                  tabindex: "0",
                },
                on: {
                  click: _vm.addSticker,
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.addSticker.apply(null, arguments)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "far fa-thumbs-up",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c("i", {
                  staticClass:
                    "far fa-heart add-post-buttons-panel__second-icon",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(1),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.allowAddEmotion
          ? _c(
              "div",
              {
                staticClass:
                  "add-post-buttons-panel__attach_button add-post-buttons-panel__attach_button_yellow",
                class: {
                  "add-post-buttons-panel__attach_button_disabled":
                    _vm.addEmotionDisabled,
                },
                attrs: {
                  role: "button",
                  "aria-label": "Add Emotion",
                  tabindex: "0",
                },
                on: {
                  click: _vm.addEmotion,
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.addEmotion.apply(null, arguments)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "far fa-smile-beam",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c("i", {
                  staticClass:
                    "far fa-frown add-post-buttons-panel__second-icon",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(2),
              ]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticClass: "add-post-buttons-panel__attach_button-label",
        attrs: { "aria-hidden": "true" },
      },
      [
        _vm._v("\n                        + "),
        _c("span", [_vm._v("Add")]),
        _vm._v(" Media\n                    "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticClass: "add-post-buttons-panel__attach_button-label",
        attrs: { "aria-hidden": "true" },
      },
      [
        _vm._v("\n                        + "),
        _c("span", [_vm._v("Add")]),
        _vm._v(" Sticker\n                    "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticClass: "add-post-buttons-panel__attach_button-label",
        attrs: { "aria-hidden": "true" },
      },
      [
        _vm._v("\n                        + "),
        _c("span", [_vm._v("Add")]),
        _vm._v(" Feeling\n                    "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }