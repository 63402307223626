/**
 * Created by orion on 07.03.17.
 */

import angular from 'angular';
import 'angular-ui-bootstrap'
import simplePopupFactory from './simplePopupFactory'

angular.module('mmApp.simple_popups', ['ui.bootstrap'])
    .factory('simplePopupFactory', simplePopupFactory);

export default 'mmApp.simple_popups'