/**
 * Created by orion on 28.05.17.
 */
import urls from 'DjangoUrls';
let {DjangoUrls} = urls;
function AttachmentsResourceFactory(prefix) {

    let attachments_resource = function ($http, $log) {

        return {
            get_attachments: function (target_id, access_code=undefined) {
                const url = DjangoUrls[`api.v1:${prefix}-get-attachments`](target_id);
                return $http({
                    method: 'GET',
                    url,
                    params:{
                        access_code
                    }
                })
            },

            attach_media: function (target_id, media_id_list) {
                const url = DjangoUrls[`api.v1:${prefix}-attach-media`](target_id);
                return $http({
                    method: 'POST',
                    url,
                    data: {
                        id_list: media_id_list
                    }
                })
            },

            attach_goals: function (target_id, goals_id_list) {
                const url = DjangoUrls[`api.v1:${prefix}-attach-goals`](target_id);
                return $http({
                    method: 'POST',
                    url,
                    data: {
                        id_list: goals_id_list
                    }
                })
            },
            attach_stories: function (target_id, stories_id_list) {
                const url = DjangoUrls[`api.v1:${prefix}-attach-stories`](target_id);
                return $http({
                    method: 'POST',
                    url,
                    data: {
                        id_list: stories_id_list
                    }
                })
            },
            delete_attachment: function (target_id, attachment_id) {
                $log.debug(target_id, attachment_id);
                const url = DjangoUrls[`api.v1:${prefix}-delete-attachment`](target_id);
                $log.debug(url);
                return $http({
                    method: 'POST',
                    url,
                    data: {
                        id: attachment_id
                    }
                })
            },

        }
    }
    attachments_resource.$inject = ['$http', '$log'];

    return attachments_resource;

}


export default AttachmentsResourceFactory