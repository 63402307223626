/**
 * Created by orion on 12.06.17.
 */
import urls from 'DjangoUrls';
let {DjangoUrls} = urls;
import selectFactory from '../select_factory/select_factory'
import attachToGoalPopupController from './attachmens_type_popup/controller'
import {customTermFilter} from "../../../../vue/filters/CustomTerm";
attachmentFactory.$inject = ['$q', '$rootScope', '$log', 'simplePopupFactory', 'addMedia', 'viewMediaFactory']
function attachmentFactory($q, $rootScope, $log, simplePopupFactory, addMedia, viewMediaFactory) {

    let result;
    let Resource
    let resource_target_id;

    function add_media_items(items_array) {
        $rootScope.show_dimmer();
        Resource.attach_media(resource_target_id, items_array.map(i => i.pk))
            .then(resp => {
                result.resolve([resp.data, []]);
            }, err => notifications.error(err || 'Error'))
            .finally($rootScope.hide_dimmer)
    }

    function show_media_select_menu() {
        addMedia.add_media({
                allow_add_from_library: true,
                max_count: 25,
                types: ['video', 'audio', 'image', 'document', 'link'],
                popup_header: 'What sort of media would you like to add?'
            },
            {
                default_callback: add_media_items,
                cancel_callback: _.noop
            })
    }

    function add_attachments(prefix, resource, target_id, only_media = false) {
        /**
         * @returns {Array} [[array of media_attachments],[array of other attachments]]
         */
        result = $q.defer();
        Resource = resource;
        resource_target_id = target_id;
        if(only_media){
            show_media_select_menu();
            return result.promise;
        }

        simplePopupFactory
            .create_popup(attachToGoalPopupController, require('./attachmens_type_popup/template.html'))
            .then(type => {
                /**
                 * @param type {string} 'goal' or 'story' or 'media'
                 */
                if (type === 'media') {
                    show_media_select_menu();
                }
                if (type === 'goal') {
                    $log.debug('goal');
                    const url = DjangoUrls[`api.v1:${prefix}-get-goals-to-attach-list`](resource_target_id);
                    $log.debug(url);
                    selectFactory().endpoint_selector(url, true, {
                        header: `Select ${customTermFilter('Goal')}`,
                        info: `Select or de-select ${customTermFilter('Goal')} from list`,
                        button_yes: 'Attach',
                        button_no: 'Cancel',
                        empty: `You do not have a ${customTermFilter('Goal')} to select`
                    })
                        .then(res => {
                            let checked_story_ids = res[0];
                            $rootScope.show_dimmer();
                            return Resource.attach_goals(resource_target_id, checked_story_ids)
                        }, e=>{
                            console.log(e);
                            return new Promise(()=>{});
                        })
                        .then(resp => {
                            result.resolve([[], resp.data]);
                        }, err => notifications.error(err || 'Error'))
                        .finally($rootScope.hide_dimmer)
                }
                if (type === 'story') {
                    $log.debug('story');
                    const url = DjangoUrls[`api.v1:${prefix}-get-stories-to-attach-list`](resource_target_id);
                    $log.debug(url);
                    selectFactory().endpoint_selector(url, true, {
                        header: 'Select Story',
                        info: 'Select or de-select story from list',
                        button_yes: 'Attach',
                        button_no: 'Cancel',
                        empty: 'You do not have a Story to select'
                    })
                        .then(res => {
                            let checked_ids = res[0];
                            $rootScope.show_dimmer();
                            return Resource.attach_stories(resource_target_id, checked_ids)
                        }, () => new Promise(()=>{}))
                        .then(resp => {
                            result.resolve([[], resp.data]);
                        }, err => notifications.error(err || 'Error'))
                        .finally($rootScope.hide_dimmer)
                }
            });

        return result.promise;
    }

    return {
        add_attachments
    }

}


export default attachmentFactory