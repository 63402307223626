/**
 * Created by orion on 6/24/17.
 */
import urls from 'DjangoUrls';
import _ from 'lodash';

let {DjangoUrls} = urls;
CalendarEventBlogEntriesResource.$inject = ['$http'];
const PREFIX = 'api.v1:calendar_event_blog_entries-';

function CalendarEventBlogEntriesResource($http) {
    return {
        create_entry,
        get_all_users_to_create_proxy_entries_in_event,
        get_blogs_i_can_create_entries,
        get_info_about_linked_entries,
        load_linked_entries,
        load_linked_notes,
    };

    function create_entry(eventId, calendarOwnerId, date, entryData) {
        const url = DjangoUrls[PREFIX + "create-entry"](eventId);
        return $http({
            method: 'POST',
            url: url,
            data: _.merge({id: calendarOwnerId, event_date: date}, entryData)
        });
    }

    function get_all_users_to_create_proxy_entries_in_event(eventId, calendarOwnerId, date) {
        const url = DjangoUrls[PREFIX + "get-all-users-to-create-proxy-entries-in-event"](eventId);
        return $http({
            method: 'GET',
            url: url,
            params: {calendar_owner_id: calendarOwnerId, event_date: date}
        });
    }

    function get_blogs_i_can_create_entries(eventId, calendarOwnerId, date, params) {
        const url = DjangoUrls[PREFIX + "get-blogs-i-can-create-entries"](eventId);
        return $http({
            method: 'GET',
            url: url,
            params: Object.assign({calendar_owner_id: calendarOwnerId, event_date: date}, params)
        });
    }

    function get_info_about_linked_entries(eventId, calendarOwnerId, date, params = {}) {
        const url = DjangoUrls[PREFIX + "get-info-about-linked-entries"](eventId);
        return $http({
            method: 'GET',
            url: url,
            params: Object.assign({calendar_owner_id: calendarOwnerId, event_date: date}, params)
        });
    }

    function load_linked_entries(eventId, calendarOwnerId, date, params = {}) {
        const url = DjangoUrls[PREFIX + "load-linked-entries"](eventId);
        return $http({
            method: 'GET',
            url: url,
            params: Object.assign({calendar_owner_id: calendarOwnerId, event_date: date}, params)
        });
    }

    function load_linked_notes(eventId, calendarOwnerId, date, params = {}) {
        const url = DjangoUrls[PREFIX + "load-linked-notes"](eventId);
        return $http({
            method: 'GET',
            url: url,
            params: Object.assign({calendar_owner_id: calendarOwnerId, event_date: date}, params)
        });
    }

}

export default CalendarEventBlogEntriesResource;