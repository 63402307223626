import Vue from 'vue';
import UsersMediaPage from './users_media/UsersMediaPage.vue';
import MediaManageAccessPage from './access_table/MediaManageAccessPage.vue';
import FoldersIManageList from './FoldersIManageList.vue';
import VueRouter from 'vue-router';
import MEDIA_CONFIG from "./config";
import MediaMainRouter from "./MediaMainRouter";

function UsersMediaPageController() {
    function initVue() {
        const router = new VueRouter({
            mode: 'history',
            routes: [
                {
                    path: MEDIA_CONFIG.MAIN_MEDIA_PREFIX + 'list/',
                    component: FoldersIManageList,
                    props: true
                },
                {
                    path: MEDIA_CONFIG.MAIN_MEDIA_PREFIX + ':username/',
                    component: UsersMediaPage,
                    props: true
                },
                {
                    path: MEDIA_CONFIG.MAIN_MEDIA_PREFIX + 'access-code/:accessCode/:username/',
                    component: UsersMediaPage,
                    props: true
                },
                {
                    path: MEDIA_CONFIG.MAIN_MEDIA_PREFIX + ':userName/:userId/settings/',
                    component: MediaManageAccessPage,
                    props: true
                },
                {
                    path: MEDIA_CONFIG.MAIN_MEDIA_PREFIX + 'access-code/:accessCode/:username/:path+/',
                    component: UsersMediaPage,
                    props: true
                },
                {
                    path: MEDIA_CONFIG.MAIN_MEDIA_PREFIX + ':username/:path+/',
                    component: UsersMediaPage,
                    props: true
                }
            ]
        });
        let vm = new Vue({
            router,
            el: '#UsersMediaPageController',
            render(createElement, context) {
                const {props: attrs} = this;
                return createElement(MediaMainRouter, {attrs});
            }
        });
    }

    initVue();
}

export default UsersMediaPageController;