<template>
    <div class="row add-post-buttons-panel">
        <div class="col-sm-12 add-post-buttons-panel__button_panel justify">
            <div class="add-post-buttons-panel__attach_button add-post-buttons-panel__attach_button_green"
                 v-if="allowAddMedia"
                 :class="{'add-post-buttons-panel__attach_button_disabled':addMediaDisabled}"
                 role="button"
                 aria-label="Add Media"
                 tabindex="0"
                 @click="addMedia"
                 @keydown.enter="addMedia"
            >
                <i class="far fa-film" aria-hidden="true"></i>
                <i class="fab fa-itunes-note add-post-buttons-panel__second-icon" aria-hidden="true"></i>
                <br>
                <span class="add-post-buttons-panel__attach_button-label" aria-hidden="true">
                            + <span>Add</span> Media
                        </span>
            </div>
            <div class="add-post-buttons-panel__attach_button add-post-buttons-panel__attach_button_blue"
                 v-if="allowAddSticker"
                 :class="{'add-post-buttons-panel__attach_button_disabled':addStickerDisabled}"
                 @click="addSticker"
                 @keydown.enter="addSticker"
                 role="button"
                 aria-label="Add Sticker"
                 tabindex="0"
            >
                <i class="far fa-thumbs-up" aria-hidden="true"></i>
                <i class="far fa-heart add-post-buttons-panel__second-icon" aria-hidden="true"></i>
                <br>
                <span class="add-post-buttons-panel__attach_button-label" aria-hidden="true">
                            + <span>Add</span> Sticker
                        </span>
            </div>
            <div class="add-post-buttons-panel__attach_button add-post-buttons-panel__attach_button_yellow"
                 v-if="allowAddEmotion"
                 :class="{'add-post-buttons-panel__attach_button_disabled':addEmotionDisabled}"
                 @click="addEmotion"
                 @keydown.enter="addEmotion"
                 role="button"
                 aria-label="Add Emotion"
                 tabindex="0"
            >
                <i class="far fa-smile-beam" aria-hidden="true"></i>
                <i class="far fa-frown add-post-buttons-panel__second-icon" aria-hidden="true"></i>
                <br>
                <span class="add-post-buttons-panel__attach_button-label" aria-hidden="true">
                            + <span>Add</span> Feeling
                        </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AddMediaStickerEmotionPanel",
        props: {
            allowAddMedia: {
                type: Boolean,
                default: true,
            },
            allowAddSticker: {
                type: Boolean,
                default: true,
            },
            allowAddEmotion: {
                type: Boolean,
                default: false,
            },
            addMediaDisabled: {
                type: Boolean,
                default: false,
            },
            addStickerDisabled: {
                type: Boolean,
                default: false,
            },
            addEmotionDisabled: {
                type: Boolean,
                default: false,
            }
        },
        methods: {
            addEmotion() {
                this.$emit('addEmotion');
            },
            addSticker() {
                this.$emit('addSticker');
            },
            addMedia() {
                this.$emit('addMedia');
            },

        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const.scss";

  .add-post-buttons-panel {

    &__button_panel {
      font-size: 0;
    }

    &__attach_button {
      padding: 3px;

      height: 45px;
      width: 45px;
      line-height: 35px;

      &:not(:last-child) {
        margin-right: 10px;
      }

      @media (min-width: 360px) and (max-width: 414px) {
        height: 45px;
        width: 57px;
        line-height: 35px;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }

      @media (min-width: 415px) and (max-width: $screen-xs-max) {
        height: 45px;
        width: 69px;
        line-height: 35px;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }

      @media (min-width: $screen-sm-min) {
        line-height: 29px;
        height: 70px;
        width: 160px;
        margin-right: 15px;
      }


      display: inline-block;
      border-radius: 5px;
      cursor: pointer;
      margin-top: 5px;
      text-align: center;
      font-weight: bolder;
      vertical-align: top;
      font-size: 20px;

      &_disabled {
        opacity: 0.6;
        cursor: not-allowed;
        pointer-events: none;
      }

      &_green {
        color: $grass-green;
        border: solid 3px $grass-green;
      }

      &_blue {
        color: $blue;
        border: solid 3px $blue;
      }

      &_yellow {
        color: $color-story-dark;
        border: solid 3px $color-story-dark;
      }
    }

    &__second-icon {
      display: none;
      @media (min-width: 415px) {
        display: inline-block;
      }
    }

    &__attach_button-label {
      font-size: 19px;
      display: none;
      @media (min-width: $screen-sm-min) {
        display: inline-block;
      }
    }
  }
</style>