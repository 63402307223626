import Vue from 'vue';

export default {
    props: {
        target: Object,
        newTab: {
            type: Boolean,
            default: false
        },
        addBackHref: {
            type: Boolean,
            default: false
        },
        addBackHrefQuery: {
            type: Boolean,
            default: false
        },
        cutName: {
            type: Number,
            default: 60
        },
        postfixLabel: {
            type: String,
            required: false
        },
        tabIndex: {
            type: [Number, String],
            default: 0,
        }
    },
    computed: {
        tooltip() {
            if (this.target.deleted) {
                return 'This user has deleted their account';
            }
        },
        targetLink() {
            if (this.addBackHref) {
                return Vue.options.filters.backHref(this.target.link);
            } else if (this.addBackHrefQuery) {
                return Vue.options.filters.backHrefQuery(this.target.link);
            } else {
                return this.target.link;
            }
        }
    },
    mounted() {
        if (this.newTab) {
            this.$refs['link'].target = '_blank';
        }
    }
};