<template>
    <div class="">
        <community-page-alternative-header
                title="Our Groups"
                :left-part-size="10"
        >
            <vue-folder-button
                    icon-class="fa-plus"
                    @click.native="createNewGroup"
                    v-if="viewerIsAdmin"
                    tabindex="0"
                    aria-label="Create Group"
                    role="button"
            ></vue-folder-button>
        </community-page-alternative-header>
        <a
                :href="group.link | backHref"
                v-for="group in groups"
                :key="group.id"
                class="hvr-float-shadow"
        >
            <vue-stuff-preview
                    :stuff-item="group"
                    :animation="false"
                    :deletable="viewerIsAdmin"
                    @delete="deleteGroup(group)"
                    stuff-type="group"
                    :size="120"
                    :show-additional-mark="group.is_private"
                    additional-mark="fa-eye-slash"
                    :show-additional-mark2="group.is_buddy_group"
                    additional-mark-custom-icon2="buddy-group"
            ></vue-stuff-preview>
        </a>
    </div>
</template>

<script>
    import Vue from 'vue';
    import CommunityPageAlternativeHeader from "./CommunityPageAlternativeHeader.vue";
    import {simplePopupFactory} from "shared/simple_popups/simplePopupFactory";
    import GroupSettingsPopup from "../group_page/popups/GroupSettingsPopup";
    import CommunityResource from "../communities/community_resource";

    export default {
        name: "CommunityPageGroups",
        components: {CommunityPageAlternativeHeader},
        data() {
            return {
                groups: [],
            };
        },
        computed: {
            community() {
                return this.$store.getters['communityPage/community'];
            },
            viewerIsAdmin() {
                return this.$store.getters['communityPage/viewerIsAdmin'];
            }
        },
        methods: {
            loadGroups() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const CommunityResource = Vue.getAngularModule('CommunityResource');
                $rootScope.show_dimmer();
                CommunityResource.get_community_groups(this.community.id, {query: ''})
                    .then(resp => {
                        this.groups = resp.data;
                    })
                    .finally($rootScope.hide_dimmer);
            },
            deleteGroup(group) {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const $q = Vue.getAngularModule('$q');
                const CommunityResource = Vue.getAngularModule('CommunityResource');
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                simplePopupFactory.show_popup(`Delete Group`, `Are you sure you want to delete this Group?`, 'Yes', 'No')
                    .then(r => {
                        $rootScope.show_dimmer();
                        return CommunityResource.delete_group(this.community.id, group.id);
                    }, () => new Promise(() => {
                    }))
                    .then(resp => {
                        this.groups.splice(this.groups.indexOf(group), 1);
                        Vue.notifications.success('Deleted');
                    }, err => Vue.notifications.error(err || 'Error'))
                    .finally($rootScope.hide_dimmer);
            },
            createNewGroup() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const $http = Vue.getAngularModule('$http');
                const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(GroupSettingsPopup);
                popupComponent.show()
                    .then(group_data => {
                        $rootScope.show_dimmer();
                        if (group_data)
                            return CommunityResource($http).create_group(this.community.id, group_data);
                    }, () => new Promise(() => {
                        vm?.$destroy();
                        fEl?.focus();
                    }))
                    .then(resp => {
                        document.location.href = this.$options.filters.backHref(resp.data.link);
                    }, err => {
                        $rootScope.hide_dimmer();
                        Vue.notifications.error(err || 'Error');
                    })
                    .finally(() => {
                        vm?.$destroy();
                        fEl?.focus();
                    });
            },
        },
        mounted() {
            this.loadGroups();
        }
    };
</script>

<style scoped>

</style>
