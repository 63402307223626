var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search_input__search-block", style: _vm.inputStyle },
    [
      _c(
        "div",
        {
          staticClass: "search_input__search-block__input",
          style: _vm.inputStyle,
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search_query,
                expression: "search_query",
              },
            ],
            staticClass: "form-control",
            attrs: {
              id: "search_query",
              maxlength: "100",
              placeholder: "Search",
              type: "search",
            },
            domProps: { value: _vm.search_query },
            on: {
              blur: _vm.onBlur,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.search_query = $event.target.value
              },
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "search_input__search-block__button",
          on: { click: _vm.searchIconClicked },
        },
        [_vm._m(0)]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "search_input__action-button__icon search_input__search-block__button__icon action-button-blue",
      },
      [
        _c("i", {
          staticClass: "fa fa-search",
          attrs: { "aria-hidden": "true" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }