var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vue-youtube-inline center-block embed-responsive in-vue" },
    [
      _vm.lazyLoad
        ? _c("LazyYoutubeVideo", {
            ref: "youtubeHolder",
            staticClass: "embed-responsive-item",
            attrs: {
              src: _vm.videoSrc,
              autoplay: _vm.autoplay,
              webp: false,
              thumbnailListeners: _vm.thumbnailListeners,
              injectPlayerScript: "",
              enablejsapi: "",
            },
            on: {
              "load:iframe": _vm.onIframeInit,
              "init:player": _vm.onPlayerInit,
            },
          })
        : _c("iframe", {
            staticClass: "embed-responsive-item",
            attrs: {
              src: _vm.videoSrc,
              wmode: "opaque",
              frameborder: "0",
              allowfullscreen: "",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }