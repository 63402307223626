<template>
    <li class="header-search__result">
        <a class="header-search__result__link" :href="result.value">
            <span class="inline-block vertical-middle header-search__result__link__avatar" :style="previewStyle"
            ></span>
            <span class=" vertical-middle" v-html="highlight(result.label, query)"></span></a>
    </li>
</template>

<script>
    import Vue from 'vue';

    function escapeRegexp(queryToEscape) {
        // Regex: capture the whole query string and replace it with the string that will be used to match
        // the results, for example if the capture is "a" the result will be \a
        return queryToEscape.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    }

    export default {
        name: "SiteHeaderSearchResult",
        props: {
            query: String,
            result: Object,
        },
        computed: {
            previewStyle() {
                const mediaContentUtils = Vue.getAngularModule('mediaContentUtils');
                return mediaContentUtils.generate_group_background(this.result.img_url, this.result.content_samples);
            }
        },
        methods: {
            highlight(matchItem, query) {
                matchItem = query ? ('' + matchItem).replace(new RegExp(escapeRegexp(query), 'gi'), '<strong>$&</strong>') : matchItem; // Replaces the capture string with a the same string inside of a "strong" tag
                return matchItem;
            }
        }
    };
</script>

<style scoped lang="scss">
    .header-search {
        &__result {
            overflow: hidden;
            font-size: 16px;
            &__link {
                &__avatar {
                    $size: 40px;
                    width: $size;
                    height: $size;
                    border-radius: 50%;
                    border: solid 1px #e2e2e2;
                }

            }
        }
    }

</style>