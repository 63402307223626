/**
 * Created by orion on 09.05.17.
 */

import angular from 'angular'

// mainFilters
import main_filters from '../shared/filters/filters_module'
import search_input from '../shared/inputs/search_input/search_input_module'
import preload from '../preload/preload'
//shared modules

import simplePopups from '../shared/simple_popups/simple_popup_module';
import notifications from '../shared/notifications/notifications_module';
import infiniteScroll from '../shared/infiniteScroll_directive'
import selectFactory from '../shared/select_factory/selectFactory_module'


//app modules
import add_media from '../add_media/add_media_module'
import view_media from '../view_media/view_media_module'


//pages

//resources

//directives
import renderTask from './renderTask/renderTask_directive'
import taskRequest from './renderTask/task_request_directive'
import MediaToAdminUploadedFilesNotificationController
    from "./renderTask/tesk_templates/MediaToAdminUploadedFilesNotification";

//factories


angular.module('mmApp.tasks',
    ['ui.bootstrap',
        preload, simplePopups, notifications, add_media, view_media, main_filters, search_input, infiniteScroll, selectFactory])
    .directive('renderTask', renderTask)
    .directive('taskRequest', taskRequest)
    .controller('MediaToAdminUploadedFilesNotificationController', MediaToAdminUploadedFilesNotificationController)
;


export default 'mmApp.tasks'