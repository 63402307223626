
deleteOrMoveMenuPopupController.$inject = ['$scope', '$uibModalInstance', '$timeout', '$log'];
function deleteOrMoveMenuPopupController($scope, $uibModalInstance) {
    $scope.close = function () {
        $uibModalInstance.dismiss('closed');
    };

    $scope.select = function (type) {
        $uibModalInstance.close(type);
    };
}

export default deleteOrMoveMenuPopupController;