<template>
    <div class="header-search" :class="{'header-search_visible':searchIsVisible}">
        <div class="header-search__block-input" v-if="searchIsVisible">
            <dropdown ref="dropdownResults" :not-close-elements="ele" v-model="resultsOpened"
                      :disabled="dropDownDisabled" style="width: 100%">
                <div class="input-group header-search__input-group">
                    <span class="input-group-addon header-search__input-addon">
                        <i class="fal fa-search  header-search__input-addon__search-icon"
                           ref="dropdownToggleIcon"></i>
                    </span>
                    <input
                            type="text"
                            class="form-control header-search__input"
                            placeholder="Search users and groups"
                            v-model="search_query"
                            @blur="onBlur"
                            ref="search-input"
                    >
                    <span class="input-group-addon header-search__input-addon">
                        <i class="fal fa-times   header-search__input-addon__close-icon"
                           @click.stop.prevent="close"></i>
                    </span>
                </div>
                <template slot="dropdown">
                    <li class="text-center header-search__tech-label" v-if="searchPerforming"><i
                            class="fas fa-spinner fa-spin"></i> Searching...
                    </li>
                    <li class="text-center header-search__tech-label" v-if="noResults">No Results</li>
                    <site-header-search-result :query="search_query" :result="result" v-for="result in results"
                                               :key="result.value"></site-header-search-result>
                </template>
            </dropdown>
            <!--            <input v-model="search_query" @blur="onBlur" maxlength="100"-->
            <!--                   class="form-control header-search__input" placeholder="Search users and groups">-->
        </div>
    </div>

</template>

<script>
    import Vue from 'vue';

    const minLettersCount = 2;
    import {DjangoUrls} from 'DjangoUrls';

    import SiteHeaderSearchResult from './SiteHeaderSearchResult.vue';

    export default {
        name: "SiteHeaderSearch",
        components: {SiteHeaderSearchResult},
        data() {
            return {

                resultsOpened: false,
                ele: [],
                searchIsVisible: false,
                dropDownDisabled: true,

                search_query: '',
                searchPerforming: false,
                noResults: false,
                results: [],
            };
        },
        methods: {
            show() {
                this.searchIsVisible = true;
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.$refs['search-input']?.focus();
                    }, 300);
                });
            },
            close() {
                this.dropDownDisabled = true;
                this.hideDropdownAndResetResults();
                this.searchIsVisible = false;
                this.search_query = '';
            },
            onBlur() {

            },
            showDropdown() {
                this.dropDownDisabled = false;
                this.$nextTick(() => {
                    this.$refs['dropdownResults'].toggle(true);
                });
            },

            hideDropdownAndResetResults() {
                if (this.requestCanceler !== undefined) {
                    this.requestCanceler.resolve();
                }
                this.results = [];
                this.searchPerforming = false;
                this.$nextTick(() => {
                    this.$refs['dropdownResults'].toggle(false);
                    this.dropDownDisabled = true;
                });
            },

            addIgnoredElements(ignoredElements) {
                for (let triggerEl of ignoredElements) {
                    if (!this.ele.includes(triggerEl)) {
                        this.ele.push(triggerEl);
                    }
                }
            },
            searchChanged(forced_execute = false) {
                this.hideDropdownAndResetResults();
                if (this.search_query.length === 0) {
                    if (this._search_timeout_id !== undefined) {
                        clearTimeout(this._search_timeout_id);
                    }
                    return;
                }
                if (this.search_query.length < minLettersCount || this.search_query.length > 100) {
                    return;
                }
                if (this._search_timeout_id !== undefined) {
                    clearTimeout(this._search_timeout_id);
                }
                if (forced_execute) {
                    this.execQuery(this.search_query);
                } else {
                    this._search_timeout_id = setTimeout(() => {
                        this.execQuery(this.search_query);
                    }, 700);
                }
            },
            execQuery(query) {
                this.showDropdown();
                this.searchPerforming = true;
                this.noResults = false;
                const $http = Vue.getAngularModule('$http');
                const $q = Vue.getAngularModule('$q');

                this.requestCanceler = $q.defer();

                $http({
                    method: 'GET',
                    url: DjangoUrls["api.v1:search_autocomplete"](),
                    params: {q: query},
                    cache: false,
                    timeout: this.requestCanceler.promise,
                })
                    .then(resp => {
                        this.noResults = resp.data.length === 0;
                        this.results = resp.data;
                    })
                    .finally(() => {
                        this.searchPerforming = false;
                    });
            }
        },
        mounted() {
            if (this.$refs['dropdownResults']) {
                const ignoredElements = [this.$refs['dropdownToggleIcon']];
                this.addIgnoredElements(ignoredElements);
            }
        },
        watch: {
            search_query(nval, oldval) {
                if (!oldval && nval === "") {
                    return;
                }
                this.searchChanged();
            }
        },
    };
</script>

<style lang="scss">
  @import "../styles/const";

  .header-search {
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    transition: top ease 0.2s;

    &_visible {
      top: 0;
      @media all and (min-width: 600px) {
        top: 15px;
      }
    }

    &__tech-label {
      font-size: 22px;
    }

    &__block-input {
      .dropdown-menu {
        width: 100% !important;


      }

      .dropdown-menu > li > a {
        &:hover,
        &:focus {
          text-decoration: none;
          background-color: $lightgray !important;
          background-image: none !important;
        }
      }
    }


    &__input-group {
      height: 50px;
    }

    &__input {
      height: 50px;
      border-right: none;
      border-left: none;
      /*height: 50px;*/
      /*background-color: white;*/

    }

    &__input-addon {
      background-color: white;

      &__search-icon {
        cursor: pointer;
        vertical-align: middle;
        font-size: 30px;
        /*margin-right: 15px;*/
      }

      &__close-icon {
        cursor: pointer;
        vertical-align: middle;
        font-size: 30px;
      }
    }
  }
</style>
