<template>
    <div class="calendar-page-event-holidays" :style="holidayStyle">
        <div class="calendar-page-event-holidays__edit-panel" v-if="holidays.editable">
            <i class="fa fa-pencil color-dark-blue pointer" style="margin-right:5px;"
               @click.prevent.stop="editHolidays"></i>
            <i class="fa fa-trash-o color-red pointer" @click.prevent.stop="deleteHolidays"></i>
        </div>
        <i class="fa fa-star-christmas color-event vertical-middle"></i>
        <span class="vertical-middle">
            <strong class="">
                {{holidays.holidays.name |cutName(40)}}
            </strong>
            <span class="">Holiday</span> in <strong class="pointer" @click="showGroups">Group Events</strong>

        </span>
        <group-events-page-add-holiday-popup
                ref="group-events-page-add-holiday-popup"></group-events-page-add-holiday-popup>
    </div>
</template>

<script>
    import moment from 'moment';
    import Vue from 'vue';
    import * as config from './config';
    import GroupEventsPageAddHolidayPopup from "../../groups/group_events/GroupEventsPageAddHolidayPopup.vue";
    import {VISUAL_DATE_FORMAT} from "./config";

    export default {
        name: "CalendarPageDayHolidays",
        components: {GroupEventsPageAddHolidayPopup},
        props: {
            holidays: Object,
        },
        data() {
            return {
                selectedGroupsIdList: [],
                allGroupsSelected: false,
                groupSelected: undefined,
            };
        },
        computed: {
            holidayStyle() {
                return {
                    height: `${config.SIZES.WHOLE_DAY_EVENT_HEIGHT}px`,
                    'line-height': `${config.SIZES.WHOLE_DAY_EVENT_HEIGHT - 11}px`,
                    'background-color': config.COLORS.getColorForEvent(this.holidays.holidays)
                };
            }
        },
        methods: {
            resetData() {
                this.selectedGroupsIdList = [];
                this.allGroupsSelected = false;
                this.groupSelected = undefined;
            },
            showGroups() {
                const GroupEventsHolidaysResource = Vue.getAngularModule('GroupEventsHolidaysResource');
                const selectFactory = Vue.getAngularModule('selectFactory');
                selectFactory.endpoint_selector(_.partial(GroupEventsHolidaysResource.get_my_groups_affected_by_holiday, this.holidays.holidays.id, false), false, {
                    button_no: 'Close',
                    button_yes: false,
                    info: false,
                    disable_search: true,
                    list_mode: true,
                    header: 'Group Events',
                    empty: 'There are not any Groups yet'

                });
            },
            showGroupsForEditOrDeleteSelector(mode = 'edit') {
                const GroupEventsHolidaysResource = Vue.getAngularModule('GroupEventsHolidaysResource');
                const selectFactory = Vue.getAngularModule('selectFactory');
                let info = 'This Holiday includes multiple Groups. Select with which ones you want to sync changes';
                let header = 'Edit Group Events Holiday';
                if (mode === 'delete') {
                    info = 'This Holiday includes multiple Groups. Select from which ones you want to delete Holiday';
                    header = 'Delete Group Events Holiday';
                }
                selectFactory.endpoint_selector(_.partial(GroupEventsHolidaysResource.get_my_groups_affected_by_holiday, this.holidays.holidays.id, true), true, {
                    button_no: 'Close',
                    button_yes: 'Select',
                    info: info,
                    header: header,
                    empty: 'There are not any Groups yet',
                    select_all_html: '<span style="font-size: 12px; line-height: 30px;">Select all</span>',

                }, false, true)
                    .then(([selected_id_list, _, all_selected]) => {
                        this.selectedGroupsIdList = selected_id_list;
                        this.allGroupsSelected = !!all_selected;
                        if (mode === 'edit') {
                            this.showEditPopup();
                        }
                        if (mode === 'delete') {
                            this.showDeletePopup();
                        }
                    }, cancel => {

                    });
            },
            showDeletePopup() {
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const GroupEventsHolidaysResource = Vue.getAngularModule('GroupEventsHolidaysResource');

                let message = 'Are you sure you want to delete this Holiday';
                if (this.allGroupsSelected) {
                    message += ' from <strong>all</strong> Groups';
                }
                if (this.selectedGroupsIdList.length) {
                    message += ` from <strong>${this.selectedGroupsIdList.length}</strong> Group${this.selectedGroupsIdList.length > 1 ? 's' : ''}`;
                }
                message += '?';

                simplePopupFactory.show_popup('Delete Holiday', message, 'Delete', 'Cancel', 'btn-red')
                    .then(y => {
                        let deleteParams = {
                            all: this.allGroupsSelected,
                        };
                        if (this.selectedGroupsIdList.length) {
                            deleteParams.id_list = this.selectedGroupsIdList;
                        } else {
                            if (!this.allGroupsSelected) {
                                deleteParams.id_list = [this.groupSelected.id];
                            }
                        }
                        return GroupEventsHolidaysResource.delete_events_holidays(this.holidays.holidays.id, deleteParams);
                    }, n => new Promise(() => {
                    }))
                    .then(resp => {
                        Vue.notifications.success('Deleted');
                        this.$emit('holidayChanged');
                    }, err => {
                        $rootScope.hide_dimmer();
                        Vue.notifications.error(err || 'Error');
                    });
            },
            showEditPopup() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const GroupEventsHolidaysResource = Vue.getAngularModule('GroupEventsHolidaysResource');
                return this.$refs['group-events-page-add-holiday-popup'].edit(this.holidays.holidays, false,
                    {
                        groupsToSyncIdList: this.selectedGroupsIdList,
                        syncWithAnotherGroups: this.selectedGroupsIdList.length > 0,
                        groupsToSyncAllSelected: this.allGroupsSelected,
                        header: this.groupSelected ? `Edit Holiday in Group ${this.groupSelected.name}` : 'Edit Holiday'
                    }
                )
                    .then(({startDate, endDate, name, description, syncWithGroupsIdList}) => {
                        $rootScope.show_dimmer();
                        let idList = this.groupSelected ? [this.groupSelected.id] : this.selectedGroupsIdList;
                        if (syncWithGroupsIdList && syncWithGroupsIdList.length) {
                            idList = idList.concat(syncWithGroupsIdList);
                        }
                        return GroupEventsHolidaysResource.update_event_holidays(this.holidays.holidays.id, {
                            start_date: moment(startDate).format(VISUAL_DATE_FORMAT),
                            end_date: moment(endDate).format(VISUAL_DATE_FORMAT),
                            name: name,
                            description: description,
                            id_list: idList,
                            all: this.allGroupsSelected,
                        });
                    }, () => new Promise(() => {
                    }))
                    .then(resp => {
                        Vue.notifications.success('Updated');
                        Object.assign(this.holidays.holidays, resp.data);
                        this.$emit('holidayChanged');
                    }, err => {
                        $rootScope.hide_dimmer();
                        Vue.notifications.error(err || 'Error');
                    });
            },
            editHolidays() {
                this.resetData();
                const GroupEventsHolidaysResource = Vue.getAngularModule('GroupEventsHolidaysResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                GroupEventsHolidaysResource.get_editable_group(this.holidays.holidays.id)
                    .then(resp => {
                        $rootScope.hide_dimmer();
                        if (resp.data.status === 'MORE_THEN_ONE') {
                            this.showGroupsForEditOrDeleteSelector('edit');
                        }
                        if (resp.data.status === 'OK') {
                            this.groupSelected = resp.data.group;
                            this.showEditPopup();
                        }
                        if (resp.data.status === 'NOT_FOUND') {
                            Vue.notifications.error('No editable Groups found');
                            this.holidays.editable = false;
                        }
                    }, err => {
                        $rootScope.hide_dimmer();
                        Vue.notifications.error(err || 'Error');
                    });
            },
            deleteHolidays() {
                this.resetData();
                const GroupEventsHolidaysResource = Vue.getAngularModule('GroupEventsHolidaysResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                GroupEventsHolidaysResource.get_editable_group(this.holidays.holidays.id)
                    .then(resp => {
                        $rootScope.hide_dimmer();
                        if (resp.data.status === 'MORE_THEN_ONE') {
                            this.showGroupsForEditOrDeleteSelector('delete');
                        }
                        if (resp.data.status === 'OK') {
                            this.groupSelected = resp.data.group;
                            this.showDeletePopup();
                        }
                        if (resp.data.status === 'NOT_FOUND') {
                            Vue.notifications.error('No deletable Groups found');
                            this.holidays.editable = false;
                        }
                    }, err => {
                        $rootScope.hide_dimmer();
                        Vue.notifications.error(err || 'Error');
                    });
            }

        }
    };
</script>

<style lang="scss">
    .calendar-page-event-holidays {
        &__edit-panel {
            position: absolute;
            right: 2px;
            top: 2px;
            padding: 3px;
            border-radius: 3px;
            background-color: rgba(255, 255, 255, 0.5);
        }

        position: relative;

        a {
            color: #333;
        }

        margin-bottom: 5px;
        border-radius: 3px;
        padding: 3px;
        @media (min-width: 375px) {
            font-size: 16px;

        }

        i.fa {
            font-size: 22px;
        }

        vertical-align: middle;
        overflow-x: hidden;
        overflow-y: hidden;

    }

</style>