/**
 * Created by orion on 09.03.17.
 */

FolderItemResources.$inject = ['$http'];
function FolderItemResources($http) {
    return {
        rename_media: function (media_item_pk, new_name) {
            const url = DjangoUrls["api.v1:media-rename-media"](media_item_pk);
            return $http({
                method: 'POST',
                url: url,
                data: {name: new_name}
            })
        }
    }
}

export default FolderItemResources