var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "site-footer", attrs: { id: "site-footer" } },
    [
      _c("div", { staticClass: "full-height", attrs: { id: "footer-top" } }, [
        _c("div", { staticClass: "container-fluid full-height" }, [
          _c("div", { staticClass: "row full-height site-footer__logos-row" }, [
            _c(
              "div",
              { staticClass: "col-xs-6 text-left site-footer__logos" },
              [
                _c("div", { staticClass: "site-footer__mm-white-logo" }),
                _vm._v(" "),
                _vm.showLoginLink
                  ? _c(
                      "a",
                      {
                        staticClass: "site-footer__log-in",
                        attrs: { href: _vm.loginLink },
                      },
                      [
                        _c("i", { staticClass: "far fa-sign-in" }),
                        _vm._v(" sign in\n                    "),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-xs-6 text-right site-footer__logos" },
              [
                _vm.showRixLogos
                  ? _c("div", { staticClass: "site-footer__uel-logo" })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showRixLogos
                  ? _c("div", { staticClass: "site-footer__rix-logo" })
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c("a", {
              staticClass: "up-arrow",
              attrs: {
                href: "javascript:scrollTo(0, 0);",
                "aria-label": "Go Up",
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("footer", [
        _c("div", { staticClass: "container site-footer__copyright-panel" }, [
          _c("div", { staticClass: "row" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-xs-6",
                staticStyle: { "padding-right": "25px" },
              },
              [
                _c("p", { staticClass: "pull-right" }, [
                  _vm._v("© 2020 Copyright Multi-Me"),
                  _vm.showRixLogos
                    ? _c("span", [_vm._v(" and Rix Media")])
                    : _vm._e(),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-xs-6" }, [
      _c("span", { staticClass: "hidden-print" }, [
        _c("a", { attrs: { href: "https://www.multime.com/terms" } }, [
          _vm._v("Terms"),
        ]),
        _vm._v(" |\n                    "),
        _c("a", { attrs: { href: "https://www.multime.com/privacy-policy" } }, [
          _vm._v("Privacy Policy"),
        ]),
        _vm._v(" |\n                    "),
        _c(
          "a",
          {
            staticStyle: { "white-space": "nowrap" },
            attrs: { href: "https://www.multime.com/contact-1" },
          },
          [_vm._v("Contact Us")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }