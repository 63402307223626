<template>
    <div class="attendance_panel">
        <div class="">
            <strong>{{attendanceLabel}}:</strong>
        </div>
        <div class="attendance_panel__item-container"
             @click="setAttendance('yes')"
             :class="{selected:attendance==='yes','something-selected':attendance}"
        >
            <div class="attendance_panel__item yes"></div>
            <div class="attendance_panel__label text-center">Yes</div>
        </div>
        <div class="attendance_panel__item-container"
             @click="setAttendance('maybe')"
             :class="{selected:attendance==='maybe','something-selected':attendance}"
        >
            <div class="attendance_panel__item maybe"></div>
            <div class="attendance_panel__label text-center">Maybe</div>
        </div>
        <div class="attendance_panel__item-container"
             @click="setAttendance('no')"
             :class="{selected:attendance==='no','something-selected':attendance}"
        >
            <div class="attendance_panel__item no"></div>
            <div class="attendance_panel__label text-center">No</div>
        </div>
    </div>
</template>

<script>
    import EventDetailSubPageMixin from "./EventDetailSubPageMixin";

    export default {
        name: "EventDetailPageInfoAttendancePanel",
        mixins: [EventDetailSubPageMixin],
        props: {
            attendance: undefined
        },
        computed: {
            attendanceLabel() {
                if (!this.buddyMode) {
                    return 'Are you going to this event?';
                } else {
                    return `Is ${this.calendarOwner.name} going to this event?`;
                }
            },
        },
        methods: {
            setAttendance(val) {
                if (this.attendance === val) {
                    this.$emit('unsetAttendance');
                } else {
                    this.$emit('setAttendance', val);
                }

            }
        }
    };
</script>

<style scoped lang="scss">
    .attendance_panel {
        text-align: justify;
        display: inline-block;

        &__item-container {
            display: inline-block;
            width: 60px;
            cursor: pointer;
            transition: opacity 0.2s;

            &:not(:first-child) {
                margin-left: 5px;
            }

            &:not(.selected) {
                opacity: 0.5;

                &:hover {
                    opacity: 0.8;
                }

                &.something-selected {
                    opacity: 0.2;

                    &:hover {
                        opacity: 0.6;
                    }
                }
            }

        }

        &__item {
            width: 60px;
            height: 64px;
            background-size: cover;
            background-repeat: no-repeat;

            &.yes {
                background-image: url('../icons/icon_event_yes.png');
            }

            &.no {
                background-image: url('../icons/icon_event_no.png');
            }

            &.maybe {
                background-image: url('../icons/icon_event_maybe.png');
            }
        }

        &__label {
            font-weight: bold;
        }
    }
</style>