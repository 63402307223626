import urls from 'DjangoUrls';

let {DjangoUrls} = urls;

function manageCircleMembershipResourceFactory(prefix) {
    function manageCircleMembershipResource($http) {
        return {
            delete_membership(membershipId) {
                const url = DjangoUrls[`api.v1:${prefix}-delete-membership`](membershipId);
                return $http({
                    method: 'DELETE',
                    url
                });
            },
            load_more_groups(membershipId, params) {
                const url = DjangoUrls[`api.v1:${prefix}-load-more-groups`](membershipId);
                return $http({
                    method: 'GET',
                    url,
                    params
                });
            },
            delete_group(membershipId, groupId) {
                const url = DjangoUrls[`api.v1:${prefix}-delete-group`](membershipId);
                return $http({
                    method: 'POST',
                    url,
                    data: {id: groupId}
                });
            },
            get_groups_to_add(membershipId, params) {
                const url = DjangoUrls[`api.v1:${prefix}-get-groups-to-add`](membershipId);
                return $http({
                    method: 'GET',
                    url,
                    params
                });
            },
            add_groups(membershipId, id_list) {
                const url = DjangoUrls[`api.v1:${prefix}-add-groups`](membershipId);
                return $http({
                    method: 'POST',
                    url,
                    data: {
                        id_list
                    }
                });
            },
            load_more_goals(membershipId, params) {
                const url = DjangoUrls[`api.v1:${prefix}-load-more-goals`](membershipId);
                return $http({
                    method: 'GET',
                    url,
                    params
                });
            },
            delete_goal(membershipId, groupId) {
                const url = DjangoUrls[`api.v1:${prefix}-delete-goal`](membershipId);
                return $http({
                    method: 'POST',
                    url,
                    data: {id: groupId}
                });
            },
            get_goals_to_add(membershipId, params) {
                const url = DjangoUrls[`api.v1:${prefix}-get-goals-to-add`](membershipId);
                return $http({
                    method: 'GET',
                    url,
                    params
                });
            },
            add_goals(membershipId, id_list) {
                const url = DjangoUrls[`api.v1:${prefix}-add-goals`](membershipId);
                return $http({
                    method: 'POST',
                    url,
                    data: {
                        id_list
                    }
                });
            },
            enable_media_membership(membershipId) {
                const url = DjangoUrls[`api.v1:${prefix}-enable-media-membership`](membershipId);
                return $http({
                    method: 'PATCH',
                    url
                });
            },
            disable_media_membership(membershipId) {
                const url = DjangoUrls[`api.v1:${prefix}-disable-media-membership`](membershipId);
                return $http({
                    method: 'PATCH',
                    url
                });
            },
            enable_diary_membership(membershipId) {
                const url = DjangoUrls[`api.v1:${prefix}-enable-diary-membership`](membershipId);
                return $http({
                    method: 'PATCH',
                    url
                });
            },
            disable_diary_membership(membershipId) {
                const url = DjangoUrls[`api.v1:${prefix}-disable-diary-membership`](membershipId);
                return $http({
                    method: 'PATCH',
                    url
                });
            },
            enable_wiki_membership(membershipId, wikiId) {
                const url = DjangoUrls[`api.v1:${prefix}-enable-wiki-membership`](membershipId);
                return $http({
                    method: 'PATCH',
                    url,
                    params: {
                        id: wikiId
                    }
                });
            },
            disable_wiki_membership(membershipId, wikiId) {
                const url = DjangoUrls[`api.v1:${prefix}-disable-wiki-membership`](membershipId);
                return $http({
                    method: 'PATCH',
                    url,
                    params: {
                        id: wikiId
                    }
                });
            },
            make_calendar_admin(membershipId) {
                const url = DjangoUrls[`api.v1:${prefix}-make-calendar-admin`](membershipId);
                return $http({
                    method: 'PATCH',
                    url
                });
            },
            make_calendar_not_admin(membershipId) {
                const url = DjangoUrls[`api.v1:${prefix}-make-calendar-not-admin`](membershipId);
                return $http({
                    method: 'PATCH',
                    url
                });
            },
            remove_explicit_calendar_admin_state(membershipId) {
                const url = DjangoUrls[`api.v1:${prefix}-remove-explicit-calendar-admin-state`](membershipId);
                return $http({
                    method: 'PATCH',
                    url
                });
            },
            make_user_buddy(membershipId) {
                const url = DjangoUrls[`api.v1:${prefix}-make-user-buddy`](membershipId);
                return $http({
                    method: 'PATCH',
                    url
                });
            },
            unmake_user_buddy(membershipId) {
                const url = DjangoUrls[`api.v1:${prefix}-unmake-user-buddy`](membershipId);
                return $http({
                    method: 'PATCH',
                    url
                });
            },
            set_circle_members_role(membershipId, {role}) {
                const url = DjangoUrls[`api.v1:${prefix}-set-circle-members-role`](membershipId);
                return $http({
                    method: 'POST',
                    url: url,
                    data: {
                        role
                    }
                });
            },
            set_circle_members_category(membershipId, category) {
                const url = DjangoUrls[`api.v1:${prefix}-set-circle-members-category`](membershipId);
                return $http({
                    method: 'POST',
                    url: url,
                    data: {
                        category
                    }
                });
            },
            resend_invitation(membershipId) {
                const url = DjangoUrls[`api.v1:${prefix}-resend-invitation`](membershipId);
                return $http({
                    method: 'PATCH',
                    url: url
                });
            },
            get_info_about_media_membership(membershipId) {
                const url = DjangoUrls[`api.v1:${prefix}-get-info-about-media-membership`](membershipId);
                return $http({
                    method: 'GET',
                    url: url
                });
            },
            get_info_about_diary_membership(membershipId) {
                const url = DjangoUrls[`api.v1:${prefix}-get-info-about-diary-membership`](membershipId);
                return $http({
                    method: 'GET',
                    url: url
                });
            },
            get_info_about_wiki_membership(membershipId, wikiId) {
                const url = DjangoUrls[`api.v1:${prefix}-get-info-about-wiki-membership`](membershipId);
                return $http({
                    method: 'GET',
                    url: url,
                    params: {
                        id: wikiId,
                    }
                });
            },
            get_diary_locations(membershipId) {
                const url = DjangoUrls[`api.v1:${prefix}-get-diary-locations`](membershipId);
                return $http({
                    method: 'GET',
                    url: url
                });
            },
            retrieve_membership_data(membershipId) {
                const url = DjangoUrls[`api.v1:${prefix}-retrieve-membership-data`](membershipId);
                return $http({
                    method: 'GET',
                    url: url
                });
            },

        };

    }

    manageCircleMembershipResource.$inject = ['$http'];
    return manageCircleMembershipResource;
}

export default manageCircleMembershipResourceFactory;