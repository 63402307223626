import urls from 'DjangoUrls';
import axios from 'axios';

let {DjangoUrls} = urls;

function likesResourceFactory(prefix) {
    likesResource.$inject = ['$http'];
    function likesResource($http) {
        function like(id) {
            const url = DjangoUrls[`api.v1:${prefix}-like`](id);
            return axios({
                method: 'PATCH',
                url: url
            })
        }

        function get_liked_users(id, params) {
            const url = DjangoUrls[`api.v1:${prefix}-get-liked-users`](id);
            return axios({
                method: 'GET',
                url: url,
                params
            })
        }

        function unlike(id) {
            const url = DjangoUrls[`api.v1:${prefix}-unlike`](id);
            return axios({
                method: 'PATCH',
                url: url
            })
        }
        return {like, unlike, get_liked_users}
    }

    return likesResource;
}

export default likesResourceFactory
