var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticStyle: { "z-index": "3", position: "relative" },
      attrs: { "aria-label": "Header navigation panel" },
    },
    [
      _c(
        "div",
        {
          staticClass: "container",
          staticStyle: { position: "relative", "z-index": "201" },
        },
        [_c("site-header-search", { ref: "search" })],
        1
      ),
      _vm._v(" "),
      _vm.show
        ? _c(
            "div",
            {
              staticClass: "site-header-component hidden-print",
              attrs: { id: "site-header-component" },
            },
            [
              _c(
                "a",
                {
                  staticClass: "site-header-component__site_logo_link",
                  attrs: {
                    href: _vm.homeLink,
                    "aria-label": _vm.ariaLabelActivity,
                    role: "link",
                  },
                  on: { click: _vm.onHomeLinkClick, blur: _vm.helloSaid },
                },
                [
                  _c("img", {
                    staticClass: "site-header-component__site_logo_image",
                    attrs: {
                      src: "/static/images/mm_icon.svg",
                      alt: "MultiMe logo",
                      "aria-label": "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.counters.activity > 0
                    ? _c("site-header-notifications-counter", {
                        attrs: { value: _vm.counters.activity },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.authenticated
                ? _c(
                    "a",
                    {
                      staticClass: "site-header-component__nav-item",
                      attrs: {
                        href: "javascript:;",
                        role: "link",
                        "aria-label": "Search.",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.onSearch.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._m(0), _vm._v(" "), _vm._m(1)]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated
                ? _c(
                    "a",
                    {
                      staticClass: "site-header-component__nav-item",
                      attrs: { href: _vm.stuffLink, "aria-label": "My Stuff." },
                    },
                    [_vm._m(2), _vm._v(" "), _vm._m(3)]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated
                ? _c(
                    "a",
                    {
                      staticClass: "site-header-component__nav-item",
                      attrs: {
                        href: _vm.chatsLink,
                        "aria-label": _vm.ariaLabelChats,
                      },
                      on: { click: _vm.onChatsClick },
                    },
                    [
                      _vm._m(4),
                      _vm._v(" "),
                      _vm._m(5),
                      _vm._v(" "),
                      _vm.counters.chats > 0
                        ? _c("site-header-notifications-counter", {
                            attrs: { value: _vm.counters.chats },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showInsightsLink
                ? _c(
                    "a",
                    {
                      staticClass: "site-header-component__nav-item",
                      attrs: {
                        href: _vm.insightsLink,
                        "aria-label": "Insights.",
                      },
                    },
                    [_vm._m(6), _vm._v(" "), _vm._m(7)]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated
                ? _c(
                    "a",
                    {
                      staticClass: "site-header-component__nav-item",
                      attrs: {
                        href: _vm.requestsLink,
                        "aria-label": _vm.ariaLabelRequests,
                      },
                    },
                    [
                      _vm._m(8),
                      _vm._v(" "),
                      _vm._m(9),
                      _vm._v(" "),
                      _vm.counters.tasks > 0
                        ? _c("site-header-notifications-counter", {
                            attrs: { value: _vm.counters.tasks },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated
                ? _c(
                    "span",
                    {
                      staticClass:
                        "site-header-component__user_avatar_link pointer",
                      staticStyle: { "z-index": "1" },
                      attrs: {
                        tabindex: "0",
                        role: "menu",
                        "aria-label": "My Account Dropdown menu.",
                        id: "avatar-dropdown",
                      },
                      on: {
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          _vm.dropDownOpened = true
                        },
                      },
                    },
                    [
                      _c(
                        "dropdown",
                        {
                          staticStyle: { "z-index": "200" },
                          attrs: { "menu-right": "" },
                          model: {
                            value: _vm.dropDownOpened,
                            callback: function ($$v) {
                              _vm.dropDownOpened = $$v
                            },
                            expression: "dropDownOpened",
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "site-header-component__user_avatar",
                            attrs: {
                              "aria-label": "",
                              src: _vm.avatarUrl,
                              id: "avatar_header_image",
                              alt: "Your avatar",
                            },
                          }),
                          _vm._v(" "),
                          _c("template", { slot: "dropdown" }, [
                            _c(
                              "li",
                              {
                                staticClass:
                                  "site-header-component__avatar-menu__link",
                                attrs: {
                                  "aria-label": "About Me",
                                  role: "menuitem",
                                  "aria-owns": "avatar-dropdown",
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: _vm.profileStoryLink },
                                    on: {
                                      click: _vm.onProfileStoryClick,
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "tab",
                                            9,
                                            $event.key,
                                            "Tab"
                                          )
                                        )
                                          return null
                                        if (!$event.shiftKey) return null
                                        _vm.dropDownOpened = false
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "fal fa-user" }),
                                    _vm._v(
                                      " About Me\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                staticClass:
                                  "site-header-component__avatar-menu__link",
                                attrs: {
                                  role: "menuitem",
                                  "aria-owns": "avatar-dropdown",
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: _vm.profileGroupsLink },
                                    on: { click: _vm.onProfileGroupsClick },
                                  },
                                  [
                                    _c("i", { staticClass: "fal fa-users" }),
                                    _vm._v(
                                      " My Groups\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                staticClass:
                                  "site-header-component__avatar-menu__link",
                                attrs: {
                                  role: "menuitem",
                                  "aria-owns": "avatar-dropdown",
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: _vm.profileCircleLink },
                                    on: { click: _vm.onProfileCircleClick },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fal fa-draw-circle",
                                    }),
                                    _vm._v(
                                      " My Circle\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.showMyWikiLink
                              ? _c(
                                  "li",
                                  {
                                    staticClass:
                                      "site-header-component__avatar-menu__link",
                                    attrs: {
                                      role: "menuitem",
                                      "aria-owns": "avatar-dropdown",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      { attrs: { href: _vm.myWikiLink } },
                                      [
                                        _c("i", {
                                          staticClass: "fal fa-user-circle",
                                        }),
                                        _vm._v(
                                          " My Wiki\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.showMyCalendarLink
                              ? _c(
                                  "li",
                                  {
                                    staticClass:
                                      "site-header-component__avatar-menu__link",
                                    attrs: {
                                      role: "menuitem",
                                      "aria-owns": "avatar-dropdown",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      { attrs: { href: _vm.myCalendarLink } },
                                      [
                                        _c("i", {
                                          staticClass: "fal fa-calendar-alt",
                                        }),
                                        _vm._v(
                                          " My Calendar\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.showMyDiaryLink
                              ? _c(
                                  "li",
                                  {
                                    staticClass:
                                      "site-header-component__avatar-menu__link",
                                    attrs: {
                                      role: "menuitem",
                                      "aria-owns": "avatar-dropdown",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      { attrs: { href: _vm.myDiaryLink } },
                                      [
                                        _c("i", { staticClass: "fal fa-edit" }),
                                        _vm._v(
                                          " My Diary\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.showMyGoalsLink
                              ? _c(
                                  "li",
                                  {
                                    staticClass:
                                      "site-header-component__avatar-menu__link",
                                    attrs: {
                                      role: "menuitem",
                                      "aria-owns": "avatar-dropdown",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      { attrs: { href: _vm.myGoalsLink } },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fal fa-bullseye-pointer",
                                        }),
                                        _vm._v(
                                          " My " +
                                            _vm._s(
                                              _vm._f("customTerm")("Goals")
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.showMyMediaLink
                              ? _c(
                                  "li",
                                  {
                                    staticClass:
                                      "site-header-component__avatar-menu__link",
                                    attrs: {
                                      role: "menuitem",
                                      "aria-owns": "avatar-dropdown",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      { attrs: { href: _vm.myMediaLink } },
                                      [
                                        _c("i", {
                                          staticClass: "fal fa-photo-video",
                                        }),
                                        _vm._v(
                                          " My Media\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                staticClass:
                                  "site-header-component__avatar-menu__link",
                                attrs: {
                                  role: "menuitem",
                                  "aria-owns": "avatar-dropdown",
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: _vm.profileSettingsLink },
                                    on: { click: _vm.onProfileSettingsClick },
                                  },
                                  [
                                    _c("i", { staticClass: "fal fa-cog" }),
                                    _vm._v(
                                      " Settings\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                staticClass:
                                  "site-header-component__avatar-menu__link",
                                attrs: {
                                  role: "menuitem",
                                  "aria-owns": "avatar-dropdown",
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "/accounts/logout/" },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "tab",
                                            9,
                                            $event.key,
                                            "Tab"
                                          )
                                        )
                                          return null
                                        _vm.dropDownOpened = false
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "fal fa-sign-out" }),
                                    _vm._v(
                                      " Log Out\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.authenticated && _vm.logInVisible
                ? _c(
                    "div",
                    { staticClass: "site-header-component__nav-item" },
                    [
                      _c("div", {}, [
                        _c("a", {
                          staticClass: "site-header-component__nav-icon login",
                          attrs: { href: _vm.loginLink, alt: "Log in" },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "site-header-component__nav-item__text",
                        },
                        [
                          _c("a", { attrs: { href: _vm.loginLink } }, [
                            _vm._v("Log in"),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c("span", { staticClass: "site-header-component__nav-icon search" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "site-header-component__nav-item__text" }, [
      _c("span", [_vm._v("Search")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c("span", {
        staticClass: "site-header-component__nav-icon stuff",
        attrs: { "aria-label": "My Stuff" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "site-header-component__nav-item__text" }, [
      _c("span", [_vm._v("My Stuff")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c("span", {
        staticClass: "site-header-component__nav-icon conversations",
        attrs: { "aria-label": "Chats" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "site-header-component__nav-item__text" }, [
      _c("span", [_vm._v("    Chats")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c("span", {
        staticClass: "site-header-component__nav-icon insights",
        attrs: { "aria-label": "Insights" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "site-header-component__nav-item__text" }, [
      _c("span", [_vm._v("Insights")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c("a", {
        staticClass: "site-header-component__nav-icon requests",
        attrs: { "aria-label": "Alerts" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "site-header-component__nav-item__text" }, [
      _c("a", [_vm._v("Alerts")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }