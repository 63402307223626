import angular from 'angular'
import activityPostDetailedFactory from './activityPostDetailedFactory'
import UserActivityPostGoalsResource from './UserActivityPostGoalsResource'
import activityPostGoalsFactory from './activityPostGoalsFactory'
import activityPostLocationsFactory from './activityPostLocationsFactory'

const MODULE_NAME = 'mmApp.shared.activityPost';
angular.module(MODULE_NAME, [])
    .factory('activityPostDetailedFactory', activityPostDetailedFactory)
    .factory('UserActivityPostGoalsResource', UserActivityPostGoalsResource)
    .factory('activityPostGoalsFactory', activityPostGoalsFactory)
    .factory('activityPostLocationsFactory', activityPostLocationsFactory)
;

export default MODULE_NAME