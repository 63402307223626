import urls from 'DjangoUrls';
import axios from 'axios';
import { ACCESS_LINK_TOKEN_HEADER_NAME } from '../common';

let { DjangoUrls } = urls;

const PREFIX = 'api.v1:wiki_read_receipts_track-';

const wiki_read_receipts_track_resource = {
    load_read_receipt,
    complete_receipt,
    track_slide_opened
};

function load_read_receipt (wikiId, receiptCode, accessCode = undefined, accessToken = undefined) {
    const url = DjangoUrls[`${PREFIX}load-read-receipt`](wikiId);
    const headers = {};
    if (accessToken) {
        headers[ACCESS_LINK_TOKEN_HEADER_NAME] = accessToken;
    }

    return axios({
        method: 'get',
        url,
        headers,
        params: {
            code: receiptCode,
            access_code: accessCode,
        },
    });
}

function complete_receipt (wikiId, receiptCode, comment = undefined, accessCode = undefined, accessToken = undefined) {
    const url = DjangoUrls[`${PREFIX}complete-receipt`](wikiId);
    const headers = {};
    if (accessToken) {
        headers[ACCESS_LINK_TOKEN_HEADER_NAME] = accessToken;
    }

    return axios({
          method: 'post',
          url,
          headers,
          params: {
              access_code: accessCode,
          },
          data: {
              comment,
              code: receiptCode,
          }
      },
    );
}

function track_slide_opened (wikiId, membershipId, sectionId, slideId, accessCode = undefined, accessToken = undefined) {
    const url = DjangoUrls[`${PREFIX}track-slide-opened`](wikiId);
    const headers = {};
    if (accessToken) {
        headers[ACCESS_LINK_TOKEN_HEADER_NAME] = accessToken;
    }

    return axios({
        method: 'post',
        url,
        headers,
        data: {
            wiki_membership_id: membershipId,
            wiki_section_id: sectionId,
            wiki_slide_id: slideId,
            access_code: accessCode,
        },
    });
}

export default wiki_read_receipts_track_resource;
