import _ from 'lodash';

export default {
    data() {
        return {
            isDragging: false,
            positionBeforeDragX: undefined,
            positionBeforeDragY: undefined,
            currentPositionX: undefined,
            currentPositionY: undefined,
            scrollBeforeDrag: 0,
            currentScroll: 0,
            dragOverMe: false
        };
    },
    computed: {
        dragStyle() {
            const style = {};
            // if (this.$store.state.timeline.draggedTask) {
            //     style['pointer-events'] = 'none';
            // }
            if (!this.isDragging) {
                return style;
            }
            if (this.isDragging) {
                style['opacity'] = '0.7';
            }

            const xCoord = this.currentPositionX - this.positionBeforeDragX;
            const yCoord = this.currentPositionY - this.positionBeforeDragY - (this.scrollBeforeDrag - this.currentScroll);
            return _.merge(style, {
                'z-index': 1,
                // 'pointer-events': 'none',
                'cursor': 'grabbing',
                'transform': `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, ${xCoord}, ${yCoord}, 0, 1)`
            });
        }
    },
    methods: {
        startOrContinueOffsetMove(direction) {
            if (this.moveintervalID !== undefined) {
                return;
            }
            this.moveintervalID = setInterval(() => {
                if (direction === 'top' && document.documentElement.scrollTop > 0) {
                    document.documentElement.scrollTop -= 1;
                }
                if (direction === 'bottom' && document.documentElement.scrollTop < (document.documentElement.scrollHeight - document.documentElement.offsetHeight)) {
                    document.documentElement.scrollTop += 1;
                }
            }, 5);
        },
        stopOffsetMoveIfStarted() {
            if (this.moveintervalID !== undefined) {
                clearInterval(this.moveintervalID);
            }
            this.moveintervalID = undefined;
        },
        handleDragStart(coords) {
            this.isDragging = true;
            // this.$store.commit('set_dragged_task', this.task);
            this.currentPositionX = this.positionBeforeDragX = coords.x;
            this.currentPositionY = this.positionBeforeDragY = coords.y;
            this.currentScroll = this.scrollBeforeDrag = document.documentElement.scrollTop;
            this.$store.commit('goalDetailPageStore/set_currently_dragged_task', this.task);
        },
        onPointerMove(pointerCoords) {
            if (this.isDragging) {
                this.currentPositionX = pointerCoords.x;
                this.currentPositionY = pointerCoords.y;
                if (pointerCoords.y >= 100 && pointerCoords.y <= (document.documentElement.offsetHeight - 100)) {
                    this.stopOffsetMoveIfStarted();
                } else {
                    if (pointerCoords.y < 100) {
                        this.startOrContinueOffsetMove('top');
                    } else {
                        this.startOrContinueOffsetMove('bottom');
                    }
                }
                return;
            }
            if (!this.$store?.state?.goalDetailPageStore?.currentlyDraggedTask) {
                return;
            }
            this.checkIfDragOver(pointerCoords);
        },
        checkIfDragOver(pointerCoords) {
            const myCoords = this.$el.getBoundingClientRect();
            if (myCoords.x < pointerCoords.x && (myCoords.x + myCoords.width) > pointerCoords.x && myCoords.y < pointerCoords.y && (myCoords.y + myCoords.height) > pointerCoords.y) {
                this.dragOverMe = true;
                if (this.$store.state.goalDetailPageStore.currentlyUnderDragTask !== this.task) {
                    this.$store.commit('goalDetailPageStore/set_currently_under_drag_task', this.task);
                }
            } else {
                this.dragOverMe = false;
                if (this.$store.state.goalDetailPageStore.currentlyUnderDragTask === this.task) {
                    this.$store.commit('goalDetailPageStore/reset_currently_under_drag_task');
                }
            }
        },
        dragGripClicked(e) {
            this.handleDragStart({x: e.clientX, y: e.clientY});
        },
        dragGripTouched(e) {
            if (e.targetTouches.length !== 1) {
                return;
            }
            this.handleDragStart({x: e.targetTouches[0].clientX, y: e.targetTouches[0].clientY});
        },
        onMouseUp() {
            if (this.$store.state?.goalDetailPageStore?.currentlyUnderDragTask) {
                this.$store.dispatch('goalDetailPageStore/drop_task');
            } else {
                if (this.$store.state.goalDetailPageStore?.currentlyDraggedTask) {
                    this.$store.commit('goalDetailPageStore/reset_currently_dragged_task');
                }
                if (this.$store.state.goalDetailPageStore?.currentlyUnderDragTask) {
                    this.$store.commit('goalDetailPageStore/reset_currently_under_drag_task');
                }
            }
            if (this.isDragging) {
                this.isDragging = false;
            }
            if (this.dragOverMe) {
                this.dragOverMe = false;
            }
            this.stopOffsetMoveIfStarted();
        },
        onMouseOut() {
            if (this.isDragging) {
                this.isDragging = false;
            }
            if (this.dragOverMe) {
                this.dragOverMe = false;
            }
            if (this.$store.state.goalDetailPageStore?.currentlyDraggedTask) {
                this.$store.commit('goalDetailPageStore/reset_currently_dragged_task');
            }
            if (this.$store.state.goalDetailPageStore?.currentlyUnderDragTask) {
                this.$store.commit('goalDetailPageStore/reset_currently_under_drag_task');
            }
            this.stopOffsetMoveIfStarted();
        },
        scrollChanged() {
            if (!this.$store.state.goalDetailPageStore?.currentlyDraggedTask) {
                return;
            }
            this.currentScroll = document.documentElement.scrollTop;
        }
    },
    mounted() {
        document.addEventListener('scroll', this.scrollChanged);
    },
    beforeDestroy() {
        document.removeEventListener('scroll', this.scrollChanged);
    }
};