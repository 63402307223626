var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "alert receipt-navigator__alert",
      class: {
        "alert-info": !_vm.allRead,
        "alert-success": _vm.allRead,
      },
    },
    [
      _vm.receipt
        ? _c(
            "div",
            [
              _c("span", [
                _vm._v("Read receipt from "),
                _c(
                  "strong",
                  [
                    _c("simple-link", {
                      attrs: { target: _vm.receipt.creator },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.showSenderComment
                ? _c("render-quote", [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.receipt.sender_comment) +
                        "\n        "
                    ),
                  ])
                : _c("br"),
              _vm._v(" "),
              _vm.wikiIncluded
                ? _c(
                    "span",
                    {
                      staticClass: "receipt-navigator__link",
                      class: {
                        "receipt-navigator__link_active":
                          _vm.isMainSectionOpened,
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "far",
                        class: {
                          "fa-check": !_vm.wikiIncluded.is_read,
                          "fa-check-double": _vm.wikiIncluded.is_read,
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.openMainWiki.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Wiki Content Page")]
                      ),
                      _vm.sectionsIncluded.length || _vm.slidesGrouped.length
                        ? _c("span", [_vm._v(", ")])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.sectionsIncluded, function (sectionIncluded, i) {
                return _c("span", [
                  _c("i", {
                    staticClass: "far",
                    class: {
                      "fa-check": !sectionIncluded.is_read,
                      "fa-check-double": sectionIncluded.is_read,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "receipt-navigator__link",
                      class: {
                        "receipt-navigator__link_active":
                          _vm.isSectionActive(sectionIncluded),
                      },
                    },
                    [
                      _vm._v("\n                Section\n                "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.openSection(sectionIncluded)
                            },
                          },
                        },
                        [_vm._v(_vm._s(sectionIncluded.name))]
                      ),
                      _vm.slidesGrouped.length ||
                      i !== _vm.sectionsIncluded.length - 1
                        ? _c("span", [_vm._v(", ")])
                        : _vm._e(),
                    ]
                  ),
                ])
              }),
              _vm._v(" "),
              _vm._l(_vm.slidesGrouped, function (slide, i) {
                return _c(
                  "span",
                  {
                    staticClass: "receipt-navigator__link",
                    class: {
                      "receipt-navigator__link_active":
                        _vm.isSlideActive(slide),
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "far",
                      class: {
                        "fa-check": !slide.is_read,
                        "fa-check-double": slide.is_read,
                      },
                    }),
                    _vm._v("\n                Page\n                "),
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.openSlide(slide.wiki_section, slide)
                          },
                        },
                      },
                      [_vm._v(_vm._s(slide.name))]
                    ),
                    i !== _vm.slidesGrouped.length - 1
                      ? _c("span", [_vm._v(", ")])
                      : _vm._e(),
                  ]
                )
              }),
              _vm._v(" "),
              _c("div", [
                _c("br"),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "pointer",
                    attrs: {
                      tabindex: "0",
                      role: "checkbox",
                      "aria-label":
                        "I have read and understood the information provided in the Wiki",
                    },
                    on: {
                      click: function ($event) {
                        _vm.read = !_vm.read
                      },
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        _vm.read = !_vm.read
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "far fa-check-square vertical-middle",
                      class: {
                        "fa-check-square": _vm.read,
                        "fa-square": !_vm.read,
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "vertical-middle" }, [
                      _vm._v(
                        "I have read and understood the information provided in the Wiki"
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.read
                  ? _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.receiverComment,
                          expression: "receiverComment",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        maxlength: "1023",
                        placeholder: "Add a comment",
                      },
                      domProps: { value: _vm.receiverComment },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.receiverComment = $event.target.value
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.read
                  ? _c("div", { staticClass: "text-right margin-10-top" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button btn-mm btn-green",
                          on: { click: _vm.completeReceipt },
                        },
                        [_vm._v("Send")]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            2
          )
        : _c("div", [_vm._v("\n        Loading...\n    ")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }