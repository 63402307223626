var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.header)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", [
            _c("span", [_vm._v("Give your Story a title:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name",
                },
              ],
              ref: "nameInput",
              staticClass: "form-control",
              attrs: {
                maxlength: "100",
                type: "text",
                placeholder: "Story Name",
                required: "",
              },
              domProps: { value: _vm.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.name = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _vm.mediaForAvatar
              ? _c("media-preview", {
                  attrs: {
                    "no-border": true,
                    item: _vm.mediaForAvatar,
                    "allow-delete": true,
                    "show-name": true,
                  },
                  on: { onDelete: _vm.cleanAvatar },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.mediaForAvatar
              ? _c("media-preview-add-request", {
                  attrs: {
                    "no-border": true,
                    text: "+ Picture",
                    "icon-class": "fa-picture-o",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addAvatar.apply(null, arguments)
                    },
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.addAvatar.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.mediaForAudioCaption
              ? _c("media-preview", {
                  attrs: {
                    "no-border": true,
                    item: _vm.mediaForAudioCaption,
                    "allow-delete": true,
                    "show-name": true,
                  },
                  on: { onDelete: _vm.cleanAudioCaption },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.mediaForAudioCaption
              ? _c("media-preview-add-request", {
                  attrs: {
                    "no-border": true,
                    text: "+ Audio",
                    "icon-class": "fa-volume-down",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addAudio.apply(null, arguments)
                    },
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.addAudio.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xs-3 text-left" }, [
            _vm.editMode
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-mm btn-red",
                    on: { click: _vm.deleteStory },
                  },
                  [_vm._v("Delete")]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-9" }, [
            _c(
              "button",
              { staticClass: "btn btn-mm", on: { click: _vm.close } },
              [_vm._v("Cancel")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-green save-button",
                attrs: { disabled: !_vm.allowSave },
                on: { click: _vm.saveStory },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.saveButtonText) +
                    "\n                "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }