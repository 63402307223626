var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.sectionSnapshotBefore
    ? _c("span", [
        _c("span", [
          _vm._v("deleted "),
          _vm.sectionSnapshotBefore.is_submenu
            ? _c("span", [_vm._v("submenu")])
            : _vm._e(),
          _vm._v(" section "),
        ]),
        _vm._v(" "),
        _c("strong", [
          _vm._v(
            "\n        #" +
              _vm._s(_vm.sectionSnapshotBefore.id) +
              ' "' +
              _vm._s(_vm.sectionSnapshotBefore.caption) +
              '"\n    '
          ),
        ]),
        _vm._v(" "),
        _vm.sectionSnapshotBefore.parent_section_name
          ? _c("span", [
              _vm._v("\n        in "),
              _c(
                "strong",
                [
                  _vm._v(
                    "#" +
                      _vm._s(_vm.sectionSnapshotBefore.parent_section_id) +
                      "\n        "
                  ),
                  _c("wiki-log-possible-link", {
                    attrs: {
                      target: _vm.wikiLog.parent_wiki_section,
                      label: _vm.sectionSnapshotBefore.parent_section_name,
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }