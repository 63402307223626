<template>
    <basic-modal
            @hide="close"
            v-model="opened"
            aria-label="New Wiki"
            role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">Select Date</h3>
        </div>
        <div class="row">
            <div class="col-sm-12 date-selector-popup__calendar-container">
                <datepicker
                        v-model="selectedDate"
                        :disabled-dates="disabledDates"
                        :highlighted="highLightedDates"
                        inline
                ></datepicker>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import moment from 'moment';
    import BasicModal from "../../../../vue/common_components/BasicModal";
    import PopupPromiseMixin from "../../../../vue/mixins/PopupPromiseMixin";
    import Datepicker from 'vuejs-datepicker';

    export default {
        name: "DateSelectorPopup",
        mixins: [PopupPromiseMixin],
        components: {BasicModal, Datepicker},
        data() {
            return {
                selectedDate: undefined,
                config: {
                    minDate: undefined,
                    maxDate: undefined,
                    highLightedDates: [],
                }
            };
        },
        computed: {
            highLightedDates() {
                return {
                    dates: this.config.highLightedDates,
                };
            },
            disabledDates() {
                const disabledDates = {};
                if (this.config.maxDate) {
                    disabledDates.from = this.config.maxDate;
                }
                if (this.config.minDate) {
                    disabledDates.to = this.config.minDate;
                }
                return disabledDates;
            }
        },
        methods: {
            setInitial(config = {
                minDate: undefined,
                maxDate: undefined,
                highLightedDates: [],
            }) {
                for (const key of Object.keys(config)) {
                    this.config[key] = config[key];
                }
            },
        },
        watch: {
            selectedDate(n) {
                this.save(n.toISOString().substr(0, 10));
            }
        }
    };
</script>

<style scoped lang="scss">
  @import '../../styles/const';

  .date-selector-popup {
    &__calendar-container {
      min-height: 380px;

      &::v-deep {
        .vdp-datepicker__calendar {
          margin: auto;
          max-width: 100%;
          width: 400px;
          @media (max-width: 370px) {
            font-size: 16px;
          }
          @media (min-width: 371px) and (max-width: 499px) {
            font-size: 19px;
          }
          @media (min-width: 500px) {
            font-size: 21px;
          }


          .cell {
            //font-size: 21px;
            height: 48px;
            line-height: 48px;
            //border: 1px solid $gray;

            &.day-header {
              //font-size: 16px;
            }

            &.highlighted {
              background: none;
              position: relative;
              border: 1px solid rgba(230, 73, 59, 0.3);

              &:after {
                content: ' ';
                top: 2px;
                right: 4px;
                position: absolute;
                background-color: $red;
                border-radius: 50%;

                width: 10px;
                height: 10px;
                @media (min-width: 500px) {
                  width: 14px;
                  height: 14px;
                }
                display: block;
                color: black;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
</style>