<template>
    <div class="">
        <community-page-alternative-header scale-by-icons-size :icons-count="1">
            <span slot="title">
                <span>
                    <span class="vertical-middle">{{(event?event.event_data.name:'')|cutName(40)}}</span>
                    <audio-toggle class="vertical-middle" :audio-caption="event.audio_caption"
                                  v-if="event&&event.audio_caption"></audio-toggle>
                </span>
                <span style="font-size: 14px; display: block; text-decoration: none; line-height: 18px; font-weight: normal; font-style: normal; position: relative; top: 5px;">
                    <span class="inline-block">{{event?event.created_at:''}}</span>
                </span>
            </span>
            <router-link :to="{name:'community-events'}" style="text-decoration: none">
                <vue-folder-button icon-class="fa-reply"></vue-folder-button>
            </router-link>
        </community-page-alternative-header>
        <div class="event-detail-info" v-if="loaded">
            <div class="event-detail-info__grid__container">
                <div class="event-detail-info__grid__info-part" style="margin-bottom: 10px">
                    <div class="event-detail-info__avatar" :style="avatarStyle"></div>
                    <div class="event-detail-info__start-date-label margin-15-bottom">{{startDateLabel}}</div>
                </div>
                <div class="event-detail-info__grid__content-part">
                    <div class="event-detail-info__data">
                        <div class="event-detail-info__data__description">
                            <div class="event-detail-info__data__description__time-label">
                                <div class="" v-if="event.event_data.location">
                                    <strong>Location:</strong> {{ locationLabel }}
                                    <location-with-marker :location="event.event_data.location"></location-with-marker>
                                </div>

                                <div class="" v-if="event.is_periodic">
                                    <strong>Repeat:</strong> <span>{{ repeatLabel }}</span>
                                    <span class="color-tag-gray">(since {{ sinceLabel }})</span>
                                </div>
                                <div class="">
                                    <strong>Start:</strong> <span>{{startTimeLabel}}</span>
                                </div>
                                <div class="">
                                    <strong>Finish:</strong> <span>{{finishTimeLabel}}</span>
                                </div>

                                <div class="" v-if="event.event_data.advanced_time_schedule_enabled">
                                    <strong>Schedule:</strong>
                                    <div class="" style="margin-left: 3px">
                                        <div :class="{'color-gray':!scheduleItem.isActive}"
                                             v-for="scheduleItem in periodicEventScheduleItems">
                                            <span>{{scheduleItem.weekdayLabel}}:</span>
                                            <span>{{scheduleItem.timeLabel}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="event-detail-info__data__description__header"
                                 v-show="event.event_data.description">
                                <strong>Description:</strong>
                            </div>
                            <div class="event-detail-info__data__description__text">
                                {{ event.event_data.description }}
                            </div>
                        </div>
                        <div class="" v-show="attachments.length">
                            <strong>Attachments:</strong>
                        </div>
                        <attachments-panel
                                ref="attachments-panel"
                                :can-edit-attachments="false"
                                :size="40"
                                :margin="0"
                        ></attachments-panel>
                        <add-to-outer-calendars-panel
                                :event-id="eventId"
                                :date-for-api="dateForApi"
                                :is-periodic="periodInfo.is_periodic"
                                :date-moment="dateMoment"
                        ></add-to-outer-calendars-panel>
                    </div>
                </div>
            </div>
        </div>
        <event-detail-page-period-selector-popup
                ref="add-to-calendar-type-selector"></event-detail-page-period-selector-popup>
    </div>
</template>

<script>
    import Vue from 'vue';
    import CommunityPageAlternativeHeader from "./CommunityPageAlternativeHeader.vue";
    import * as config from '../events/new_calendar/config';
    import moment from 'moment';
    import EventInfoMixin from "../events/new_event/EventInfoMixin";
    import eventCommonFieldsMixin from "../events/eventCommonFieldsMixin";
    import AttachmentsPanel from "../events/new_event/AttachmentsPanel.vue";
    import EventDetailPagePeriodSelectorPopup from "../events/new_event/EventDetailPagePeriodSelectorPopup";
    import AddToOuterCalendarsPanel from "../events/add_to_outer_calendars/AddToOuterCalendarsPanel.vue";

    export default {
        components: {
            AddToOuterCalendarsPanel,
            CommunityPageAlternativeHeader, AttachmentsPanel, EventDetailPagePeriodSelectorPopup},
        mixins: [EventInfoMixin, eventCommonFieldsMixin, ],
        name: "CommunityPageEventsUnauthView",
        data() {
            return {
                event: undefined,
                periodInfo: undefined,
                loaded: false,
            };
        },
        computed: {
            attachmentsResource() {
                return Vue.getAngularModule('EventAttachmentsResource');
            },
            dateMoment() {
                return moment(this.$route.params.date, config.URL_DATE_FORMAT);
            },
            dateForApi() {
                if (this.$route.params.date) {
                    return this.dateMoment.format(config.VISUAL_DATE_FORMAT);
                }
            },
            eventId(){
                return this.$route.params.eventId;
            }
        },
        methods: {
            loadEvent() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const CalendarEventsPublicResource = Vue.getAngularModule('CalendarEventsPublicResource');
                CalendarEventsPublicResource.get_event(this.$route.params.eventId, this.dateForApi)
                    .then(resp => {
                        if (resp.data.status === 'OK') {
                            this.event = resp.data.event_data.event;
                            this.periodInfo = resp.data.event_data.period_info;
                            this.loaded = true;
                            this.loadAttachments();
                            this.$store.commit('communityPage/setEvent', this.event);
                        }
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
        },
        mounted() {
            this.loadEvent();
        }
    };
</script>

<style scoped lang="scss">
    @import "../events/new_event/EventDetailPageStyle";
</style>