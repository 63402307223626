<template>
    <div class="" v-if="statusData">
        <div class="" v-if="statusData.status==='NOT_COMMUNITY_MEMBER'">
            <div class="alert alert-warning">
                Sorry you are not in the same Community as this Group event.
            </div>
        </div>

        <div class="" v-if="statusData.status==='NOT_GROUP_MEMBER'">
            <div class="event-join-form">
                <div v-if="!statusData.is_join_request_pending">
                    <div class=""><span>Sorry, this is a Group event from <strong><simple-link
                            :target="statusData.group"></simple-link></strong> and you are not a member of
                this Group.</span>
                        <span v-if="hasPermission('join-groups')">Request to join this Group to get access to this event.</span>
                    </div>
                    <div class="text-center margin-15-top" v-if="hasPermission('join-groups')">
                        <button class="btn btn-mm" @click="sendGroupJoinRequest">Request to join</button>
                    </div>
                </div>
                <div v-else>
                    <div class=""><span>Sorry, this is a Group event from
                        <strong>
                            <simple-link
                                    :target="statusData.group"></simple-link>
                        </strong>
                        and you are not a member of this Group.</span><br>
                        <span>You will join Group after approval. We will notify you.</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="" v-if="statusData.status==='NOT_CIRCLE_MEMBER'">
            <div class="event-join-form">
                <div v-if="!statusData.is_join_request_pending">
                    <div class=""><span>Sorry you can't access this event as you are not a member of the Event owner's Circle or invited to this Event</span>
                        <span v-if="hasPermission('add-friends')">Request to join <simple-link
                                :target="statusData.circle_owner"></simple-link> Circle and access this event</span>
                    </div>
                    <div class="text-center margin-15-top" v-if="hasPermission('add-friends')">
                        <button class="btn btn-mm" @click="sendCircleJoinRequest">Request to join</button>
                    </div>
                </div>
                <div v-else>
                    <div class="">
                        <span>Sorry you can't access this event as you are not a member of the Event owner's Circle or invited to this Event</span><br>
                        <span>You will join Circle after approval.</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="" v-if="statusData.status==='PRIVATE_EVENT'">
            <div class="event-join-form">
                <div v-if="!statusData.is_join_request_pending">
                    <div class=""><span>Sorry this is a private event that you do not have access to.</span>
                    </div>
                    <div class="text-center margin-15-top" v-if="hasPermission('join-events')">
                        <button class="btn btn-mm" @click="onJoinEventClick">Request access</button>
                    </div>
                </div>
                <div v-else>
                    <div class="">
                        <span>Sorry this is a private event that you do not have access to.</span><br>
                        <span>You will get access after approval.</span>
                    </div>
                </div>
            </div>
        </div>

        <email-role-category-popup ref="email-role-category-popup"></email-role-category-popup>
        <event-detail-page-join-form-period-selector-popup
                ref="join-type-selector"></event-detail-page-join-form-period-selector-popup>
    </div>
</template>

<script>
    import Vue from 'vue';

    import EventDetailPageJoinFormPeriodSelectorPopup from './EventDetailPagePeriodSelectorPopup.vue';
    import EventDetailSubPageMixin from "./EventDetailSubPageMixin";
    import EventDetailPageJoinMixin from "./EventDetailPageJoinMixin";
    import joinRequestsMixin from "./joinRequestsMixin";

    export default {
        name: "EventDetailErrorNoAccess",
        components: {EventDetailPageJoinFormPeriodSelectorPopup},
        mixins: [joinRequestsMixin, EventDetailPageJoinMixin, EventDetailSubPageMixin],
        props: {
            calendarOwnerId: String,
            calendarOwnerName: String,
            eventId: String,
            date: String,
        },
        data() {
            return {
                statusData: undefined
            }
        },
        methods: {
            redirectToInfoPage() {
                this.$router.replace({
                    name: 'info',
                    params: {
                        date: this.date,
                        calendarOwnerId: this.calendarOwnerId,
                        calendarOwnerName: this.calendarOwnerName
                    },
                    hash: this.$route.hash
                });
            },
            eventJoinedListener() {
                this.redirectToInfoPage();
            }
        },
        mounted() {
            const $rootScope = Vue.getAngularModule('$rootScope');
            this.checkJoinAbility(this.redirectToInfoPage);
            $rootScope.hide_dimmer();
        },
        beforeDestroy() {
        }
    };
</script>

<style scoped lang="scss">
    @import "../../../app/styles/const";
    @import "../../../app/styles/mixins";

    .event-join-form {
        @include wide_item_container(50px);
        padding: 10px;

        &__text {
            font-size: 16px;
        }
    }

</style>