import Vue from 'vue';

const AngularInjectorMixin = {
    data() {
        return {
            angularModulesNames: [],
            angularModules: {},
        };
    },
    created() {
        for (let moduleName of this.angularModulesNames) {
            this.angularModules[moduleName] = Vue.getAngularModule(moduleName);
        }
    }
};

export default AngularInjectorMixin;