var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-day" }, [
    _c(
      "div",
      { staticClass: "c-day__day-info-part", style: _vm.infoPartStyle },
      [
        _vm.isToday
          ? _c("div", { staticClass: "c-day__day-info-part__today-mark" }, [
              _vm._v("\n            Today\n        "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", {
          staticClass: "c-day__day-info-part__weekday-icon",
          style: _vm.weekdayIconStyle,
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isDesktopVersion,
                expression: "isDesktopVersion",
              },
            ],
            staticClass: "c-day__day-info-part__weekday-label",
          },
          [_vm._v("\n            " + _vm._s(_vm.weekDayLabel) + "\n        ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isDesktopVersion,
                expression: "isDesktopVersion",
              },
            ],
            staticClass: "c-day__day-info-part__day-number-label",
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.dayNumberLabel) +
                " " +
                _vm._s(_vm.monthLabel) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isDesktopVersion,
                expression: "!isDesktopVersion",
              },
            ],
            staticClass: "c-day__day-info-part__mobile-date-label",
          },
          [
            _vm._v("\n            " + _vm._s(_vm.weekDayLabel) + ", "),
            _c("br"),
            _vm._v(
              " " +
                _vm._s(_vm.dayNumberLabel) +
                " " +
                _vm._s(_vm.monthLabel) +
                "\n        "
            ),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "c-day__events-part", style: _vm.eventsPartStyle },
      [
        _c(
          "div",
          {},
          [
            _vm._l(_vm.day.holidays, function (holidaysItem) {
              return _c("calendar-page-day-holidays", {
                key: holidaysItem.id,
                attrs: { holidays: holidaysItem },
                on: { holidayChanged: _vm.onHolidayChanged },
              })
            }),
            _vm._v(" "),
            _vm._l(_vm.wholeDayEvents, function (event, i) {
              return _c("calendar-page-day-timeline-whole-day-event", {
                key: event.id,
                attrs: {
                  event: event,
                  profile: _vm.profile,
                  index: i,
                  date: _vm.day.date,
                },
              })
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("calendar-page-day-timeline", {
          attrs: {
            "events-tiers": _vm.eventsTiers,
            width: _vm.eventsPartWidth,
            height: _vm.timeLineHeight,
            profile: _vm.profile,
            day: _vm.day,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }