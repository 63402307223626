<template>
    <div :class="{'blocked-for-deletion':blocked}"
         class="media-folders-list__container">

        <users-media-page-folder
                :folder="fakePreviousFolder"
                :key="fakePreviousFolder.id"
                @click.native="goBack"
                v-if="fakePreviousFolder"

                :currentlyDraggedMedia="currentlyDraggedMedia"
                :currentlyUnderDragFolder="currentlyUnderDragFolder"
                @resetCurrentlyUnderDragFolder="resetCurrentlyUnderDragFolder"
                @setCurrentlyUnderDragFolder="setCurrentlyUnderDragFolder"
        ></users-media-page-folder>
        <users-media-page-folder
                :folder="folder"
                :editMode="editMode"
                :currentUser="currentUser"
                v-for="folder in foldersToShow"
                :key="folder.id"
                @click.native="selectFolder(folder)"

                :currentlyDraggedMedia="currentlyDraggedMedia"
                :currentlyUnderDragFolder="currentlyUnderDragFolder"
                @resetCurrentlyUnderDragFolder="resetCurrentlyUnderDragFolder"
                @setCurrentlyUnderDragFolder="setCurrentlyUnderDragFolder"
                @hideFolder="hideFolder(folder)"
        ></users-media-page-folder>
        <span v-show="hiddenFoldersCount"><strong @click="showMoreFolders" class="pointer">Show <strong>{{hiddenFoldersCount}}</strong> more...</strong></span>
    </div>
</template>

<script>
    import UsersMediaPageFolder from './UsersMediaPageFolder.vue';

    const COUNT_TO_SHOW_INCREASE_AMOUNT = 15;

    export default {
        components: {UsersMediaPageFolder},
        data() {
            return {
                foldersToShowCount: COUNT_TO_SHOW_INCREASE_AMOUNT
            };
        },
        props: {
            massActionsState: Object,

            fakePreviousFolder: Object,
            currentUser: Object,
            folders: Array,

            currentlyDraggedMedia: Object,
            currentlyUnderDragFolder: Object,
            editMode: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            blocked() {
                return this.massActionsState.deleteMode || this.massActionsState.moveMode;
            },
            foldersToShow() {
                return this.folders.slice(0, this.foldersToShowCount);
            },
            hiddenFoldersCount() {
                return this.folders.length - this.foldersToShow.length;
            }
        },
        methods: {
            selectFolder(folder) {
                if (this.blocked) {
                    return;
                }
                this.$emit('selectFolder', folder);
            },
            hideFolder(folder) {
                if (this.blocked) {
                    return;
                }
                this.$emit('hideFolder', folder);
            },
            showMoreFolders() {
                if (this.blocked) {
                    return;
                }
                this.foldersToShowCount += COUNT_TO_SHOW_INCREASE_AMOUNT;
            },
            goBack() {
                if (this.blocked) {
                    return;
                }
                this.$emit('goBack');
            },
            resetCurrentlyUnderDragFolder() {
                this.$emit('resetCurrentlyUnderDragFolder');
            },
            setCurrentlyUnderDragFolder(val) {
                this.$emit('setCurrentlyUnderDragFolder', val);
            }
        },
        watch: {
            folders(n) {
                this.foldersToShowCount = COUNT_TO_SHOW_INCREASE_AMOUNT;
            }
        },
        name: "UsersMediaPageFoldersContainer"
    };
</script>

<style scoped lang="scss">
    .media-folders-list {
        &__container {
            max-height: 178px;
            overflow-y: auto;
        }

        &__container {
            &.blocked-for-deletion {
                @media (max-width: 499px) {
                    display: none;
                }
                @media (min-width: 500px) {
                    opacity: 0.5;
                    cursor: not-allowed !important;
                }

            }
        }
    }

</style>