var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "center-block", staticStyle: { "font-size": "20px" } },
    [
      _c(
        "a",
        {
          attrs: {
            href: _vm.item.uploaded_document.document_url,
            target: "_blank",
          },
        },
        [
          _c("i", {
            staticClass: "fa fa-file-text-o",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v(" " + _vm._s(_vm._f("cutName")(_vm.item.name, 22)) + "\n    "),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }