var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "media-users-list" }, [
    _c(
      "div",
      { staticClass: "media-users-list__image-part" },
      [
        _c("router-link", { attrs: { to: _vm.link } }, [
          _c("img", {
            staticClass: "img-circle media-users-list__image-part__image",
            attrs: { src: _vm.user.avatar, alt: _vm.user.name },
          }),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "media-users-list__content-part" },
      [
        _c("router-link", { attrs: { to: _vm.link } }, [
          _c("img", {
            staticClass: "media-users-list__content-part__icon",
            attrs: { src: _vm.icon, alt: _vm.user.name },
          }),
        ]),
        _vm._v(" "),
        _c("router-link", { attrs: { to: _vm.link } }, [
          _vm._v(_vm._s(_vm.user.name) + "'s Media"),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }