var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.itemTooltip,
          expression: "itemTooltip",
        },
      ],
      staticClass: "esp__item-container",
      attrs: {
        id: "select-item-" + _vm.item.id,
        tabindex: "0",
        "tooltip-enable": "item_for_select.name.length>34",
      },
    },
    [
      _c("div", { staticClass: "esp__list-item-main" }, [
        _vm.item.online
          ? _c("div", { staticClass: "esp____online-indicator" })
          : _vm._e(),
        _vm._v(" "),
        _vm.item.super_avatar
          ? _c("img", {
              staticClass: "esp__super-avatar",
              attrs: { src: _vm.item.super_avatar, alt: "avatar" },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "esp__main-photo" }, [
          _c(
            "div",
            {
              staticClass: "esp__main-photo-preview",
              class: { checked: _vm.checked, unconfirmed: _vm.unconfirmed },
              style: _vm.mainPhotoPreviewStyle,
            },
            [
              _vm.item.completed
                ? _c("div", { staticClass: "esp__completed-mark" }, [
                    _c("span", { staticClass: "esp__complete-label" }, [
                      _vm._v("Complete"),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm.checked
            ? _c(
                "div",
                {
                  staticClass: "esp__main-check-mark",
                  class: { unconfirmed: _vm.unconfirmed },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-check",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "esp__label" }, [
        _vm._v(_vm._s(_vm._f("cutName")(_vm.item.name, 34))),
      ]),
      _vm._v(" "),
      _vm.item.extra_text
        ? _c("div", { staticClass: "esp__extra-label" }, [
            _c("i", [_vm._v(_vm._s(_vm.item.extra_text))]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }