// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./mini-wiki-icon-white-solid.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".wiki-breadcrumbs-component__user-profile-link{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-color:#00b3ee;background-size:contain !important;background-position:center;background-repeat:no-repeat}.wiki-breadcrumbs-component .breadcrumb-image{vertical-align:middle;display:inline-block;border-radius:50%;background-size:cover;background-repeat:no-repeat;font-size:25px;line-height:33px;border:solid 3px #00b3ee}.wiki-breadcrumbs-component .breadcrumb-image.active{border:solid 3px #57e69f;line-height:38px}.wiki-breadcrumbs-component .breadcrumb-separator{display:inline-block;vertical-align:middle;height:1px;border-bottom:dashed 5px #00b3ee}@media (min-width: 800px){.wiki-breadcrumbs-component .breadcrumb-separator{width:32px}}@media (max-width: 799px){.wiki-breadcrumbs-component .breadcrumb-separator{width:16px}}.wiki-breadcrumbs-component .breadcrumb-label{display:inline-block;font-weight:bolder;max-width:100px;vertical-align:middle}@media (min-width: 610px){.wiki-breadcrumbs-component .breadcrumb-label{font-size:15px;line-height:15px}}@media (max-width: 609px){.wiki-breadcrumbs-component .breadcrumb-label{font-size:17px;line-height:17px;font-weight:bolder}}.wiki-breadcrumbs-component .breadcrumb-circles-container{vertical-align:middle}@media (min-width: 660px){.wiki-breadcrumbs-component .breadcrumb-circles-container{display:inline-block}}@media (max-width: 659px){.wiki-breadcrumbs-component .breadcrumb-circles-container{display:none}}\n", ""]);
// Exports
module.exports = exports;
