/**
 * Created by orion on 6/24/17.
 */

import axios from 'axios';

UserProfileResource.$inject = ['$http'];
import urls from 'DjangoUrls';

let {DjangoUrls} = urls;

function UserProfileResource($http) {
    return {
        update_avatar,
        update_avatar_by_crop,
        update_email_settings,
        update_main_settings,
        close_account,
        get_user_shared_posts,
        get_my_posts,
        get_shared_collections,
        get_shared_goals,
        get_shared_media_folders,
        get_friends_and_buddies_and_dependants_list,
        add_as_friend,
        delete_friend,
        set_circle_members_role,
        sign_policy,
        sign_visibility_changes,
        cancel_friendship_request,
        get_my_potential_friends,
        get_users_communities_and_groups,
        get_unread_chats_count,
        get_unread_conversations_count,
        get_unread_activity_count,
        get_unread_tasks_count,
        get_my_header_counters,
        get_profiles_random_friends,
        get_profile_page_info,
        get_my_dashboard_state,
        get_users_visible_wikis
    };

    ///////////////////

    function update_main_settings(user_id, data) {
        const url = DjangoUrls["api.v1:user-update-main-settings"](user_id);
        return axios({
            method: 'PATCH',
            url,
            data
        });
    }

    function close_account(user_id) {
        const url = DjangoUrls["api.v1:user-close-account"](user_id);
        return axios({
            method: 'PATCH',
            url
        });
    }

    function update_email_settings(user_id, email_notifications, email_activity) {
        const url = DjangoUrls["api.v1:user-update-email-settings"](user_id);
        return axios({
            method: 'PATCH',
            url: url,
            data: {email_notifications, email_activity}
        });
    }

    function add_as_friend(user_id, data) {
        const url = DjangoUrls["api.v1:user-add-as-friend"](user_id);
        return axios({
            method: 'POST',
            url: url,
            data
        });
    }

    function cancel_friendship_request(user_id, request_id) {
        const url = DjangoUrls["api.v1:user-cancel-friendship-request"](user_id);
        return axios({
            method: 'POST',
            url: url,
            data: {id: request_id}
        });
    }

    function update_avatar(user_id, media_content_pk) {
        const url = DjangoUrls["api.v1:user-update-avatar"](user_id);

        return axios({
            method: 'PATCH',
            url: url,
            data: {media_content_pk: media_content_pk}
        });
    }

    function update_avatar_by_crop(user_id, avatar) {
        const url = DjangoUrls["api.v1:user-update-avatar-by-crop"](user_id);

        return axios({
            method: 'POST',
            url: url,
            data: {avatar}
        });
    }

    function sign_policy(user_id) {
        const url = DjangoUrls["api.v1:user-sign-policy"](user_id);

        return axios({
            method: 'PATCH',
            url
        });
    }

    function sign_visibility_changes(user_id) {
        const url = DjangoUrls["api.v1:user-sign-visibility-changes"](user_id);

        return axios({
            method: 'PATCH',
            url
        });
    }

    function get_user_shared_posts(user_id, params = {}) {
        const url = DjangoUrls["api.v1:user-get-user-shared-posts"](user_id);

        return axios({
            method: 'GET',
            url,
            params
        });
    }

    function get_my_posts(user_id, params = {}) {
        const url = DjangoUrls["api.v1:user-get-my-posts"](user_id);

        return axios({
            method: 'GET',
            url,
            params
        });
    }

    function get_users_communities_and_groups(user_id, params) {
        const url = DjangoUrls["api.v1:user-get-users-communities-and-groups"](user_id);
        return axios({
            method: 'GET',
            url,
            params
        });
    }

    function get_unread_conversations_count(user_id) {
        const url = DjangoUrls["api.v1:user-get-unread-conversations-count"](user_id);
        return axios({
            method: 'GET',
            url
        });
    }
    function get_unread_chats_count(user_id) {
        const url = DjangoUrls["api.v1:user-get-unread-chats-count"](user_id);
        return axios({
            method: 'GET',
            url
        });
    }

    function get_unread_activity_count(user_id) {
        const url = DjangoUrls["api.v1:user-get-unread-activity-count"](user_id);
        return axios({
            method: 'GET',
            url
        });
    }

    function get_unread_tasks_count(user_id) {
        const url = DjangoUrls["api.v1:user-get-unread-tasks-count"](user_id);
        return axios({
            method: 'GET',
            url
        });
    }

    function get_my_header_counters(user_id, run_on_feed_page) {
        const url = DjangoUrls["api.v1:user-get-my-header-counters"](user_id);
        return axios({
            method: 'GET',
            url,
            params: {run_on_feed_page}
        });
    }

    function get_my_dashboard_state(user_id, include_items = undefined) {
        const url = DjangoUrls["api.v1:user-get-my-dashboard-state"](user_id);
        return axios({
            method: 'GET',
            url,
            params: {include_items}
        });
    }

    function get_shared_collections(user_id) {
        const url = DjangoUrls["api.v1:user-get-shared-collections"](user_id);

        return axios({
            method: 'GET',
            url: url
        });
    }

    function get_shared_media_folders(user_id, params) {
        const url = DjangoUrls["api.v1:user-get-shared-media-folders"](user_id);
        return axios({
            method: 'GET',
            url,
            params
        });
    }

    function get_shared_goals(user_id, params) {
        const url = DjangoUrls["api.v1:user-get-shared-goals"](user_id);

        return axios({
            method: 'GET',
            url,
            params
        });
    }

    function get_friends_and_buddies_and_dependants_list(user_id) {
        const url = DjangoUrls["api.v1:user-get-friends-and-buddies-and-dependants-list"](user_id);
        return axios({
            method: 'GET',
            url: url
        });
    }

    function get_my_potential_friends(user_id, params) {
        const url = DjangoUrls["api.v1:user-get-my-potential-friends"]();
        return axios({
            method: 'GET',
            url: url,
            params: Object.assign({id: user_id}, params)
        });
    }

    function delete_friend(user_id, friend_id) {
        const url = DjangoUrls["api.v1:user-delete-friend"](user_id);
        return axios({
            method: 'PATCH',
            url: url,
            data: {
                id: friend_id
            }
        });
    }

    function set_circle_members_role(user_id, friend_id, {role, category}) {
        const url = DjangoUrls["api.v1:user-set-circle-members-role"](user_id);
        return axios({
            method: 'PATCH',
            url: url,
            data: {
                id: friend_id,
                role,
                category
            }
        });
    }

    function get_profiles_random_friends(user_id) {
        const url = DjangoUrls["api.v1:user-get-profiles-random-friends"](user_id);
        return axios({
            method: 'GET',
            url,
        });
    }

    function get_profile_page_info(username) {
        const url = DjangoUrls["api.v1:user-get-profile-page-info"]();
        return axios({
            method: 'GET',
            url,
            params: {username}
        });
    }

    function get_users_visible_wikis(user_id, params) {
        const url = DjangoUrls["api.v1:user-get-users-visible-wikis"](user_id);
        return axios({
            method: 'GET',
            url,
            params
        });
    }

}

export default UserProfileResource;
