<template>
    <div class="empty-slide-edit-holder"
         :class="{'under-drag':underDrag}"
         ref="container"
         @dragenter.prevent="onDragEnter"
         @dragover.prevent=""
         @dragleave="onDragLeave"
         @dragend="onDragEnd"
         @drop.prevent="onDrop"
    >
        <div class="empty-slide-edit-holder__only-add-media text-center pointer" @click="editSlideMedia"
             v-if="onlyMedia&&!underDrag">
            <div class=" media-icon" :style="onlyMediaIconStyle"></div>
            <div class="empty-slide-edit-holder__label">Click to add media</div>
        </div>
        <div class="empty-slide-edit-holder__add-media-and-drag-drop text-center pointer" @click="editSlideMedia"
             v-if="!onlyMedia&&!underDrag">

            <div class="row">
                <div class="col-xs-5 text-center">
                    <div class="drop-icon" :style="mediaAndDropIconStyle"></div>
                    <div class="empty-slide-edit-holder__label">Drag and
                        Drop Pictures here
                    </div>
                </div>
                <div class="col-xs-2 text-center">
                    <div class="inline-block empty-slide-edit-holder__label"
                         :style="orLabelStyle">or
                    </div>
                </div>
                <div class="col-xs-5 text-center">
                    <div class="media-icon"
                         :style="mediaAndDropIconStyle"></div>
                    <div class="empty-slide-edit-holder__label">Click to
                        add media
                    </div>
                </div>
            </div>
        </div>
        <div class="empty-slide-edit-holder__drop-receiver text-center pointer" v-if="underDrag"
             :style="underDragLabelStyle">
            <div class="empty-slide-edit-holder__label empty-slide-edit-holder__drop-receiver__drop-here-label">Drop
                Images here to create new Slides.
            </div>
        </div>
    </div>
</template>

<script>
    import MobileDetect from "mobile-detect";
    import ScreenWidthAwareMixin from "../../../../vue/mixins/ScreenWidthAwareMixin";

    export default {
        name: "WikiDetailPageSlideContainerEmptySlideEditHolder",
        props: {
            containerHeight: Number,
            slide: Object,
        },
        mixins: [ScreenWidthAwareMixin],
        data() {
            return {
                dragCounter: 0,
                underDrag: false,

                containerWidth: window.innerWidth,
            };
        },
        computed: {
            onlyMedia() {
                return !this.dragNDropSupported || (this.containerWidth < 500);
            },
            dragNDropSupported() {
                const isMobile = !!new MobileDetect(window.navigator.userAgent).mobile();
                const iOS = !!navigator.userAgent.match('iPhone OS') || !!navigator.userAgent.match('iPad');
                const div = document.createElement('div');
                return ('draggable' in div) && ('ondrop' in div) && ('ondragstart' in div) && !iOS && !!window.FileReader && !isMobile;
            },
            onlyMediaIconStyle() {
                const size = this.containerHeight / 2;
                return {
                    'width': `${size}px`,
                    'height': `${size}px`,
                    'margin-top': `${this.containerHeight / 2 - size / 2 - 50}px`
                };
            },
            mediaAndDropIconStyle() {
                const size = this.containerHeight / 3;
                return {
                    'width': `${size}px`,
                    'height': `${size}px`,
                    'margin-top': `${this.containerHeight / 2 - size / 2}px`
                };
            },
            orLabelStyle() {
                return {
                    'margin-top': `${this.containerHeight / 2}px`
                };
            },
            underDragLabelStyle() {
                return {
                    'padding-top': `${this.containerHeight / 2 - 10}px`
                };
            }
        },
        methods: {
            additionalSizeCalculations() { // called from ScreenWidthAwareMixin
                this.containerWidth = this.$refs.container.offsetWidth;
            },
            onSelectMenuClick() {
            },
            editSlideMedia() {
                this.$store.dispatch('WikiStore/editSlideMedia', this.slide);
            },
            onDragEnter() {
                this.underDrag = true;
            },
            onDragLeave() {
                this.underDrag = false;
            },
            onDragEnd() {
                this.underDrag = false;
            },
            onDrop(e) {
                this.$store.dispatch('WikiStore/uploadFilesByDragDrop', e.dataTransfer.files);
                this.underDrag = false;
            }
        },
        mounted() {
        }
    };
</script>

<style scoped lang="scss">
    @import "../../styles/const";

    .media-icon {
        display: inline-block;
        background-image: url('np_film_1383923_676269 (1) (1).png');
        background-size: cover;
    }

    .drop-icon {
        display: inline-block;
        background-image: url('np_drag-and-drop_934946_676269 (2).png');
        background-size: cover;
    }


    .empty-slide-edit-holder {
        height: 100%;

        &__label {
            color: $tag-gray-dark;
            font-size: 20px;
        }

        &__only-add-media {
            height: 100%;
        }

        &__add-media-and-drag-drop {
            height: 100%;
        }

        &__drop-receiver {
            height: 100%;

            &__drop-here-label {
                pointer-events: none;
                font-size: 22px;
            }
        }
    }
</style>