<template>
    <selector-popup
            ref="popup"
            header="How you would like to add document?"
            :items="items"
            @selected="onSelected"
    >
        <template slot="footer">
            <button @click="back" type="button" class="btn btn-mm  btn-primary">Back</button>
        </template>

        <template slot="invisibleForms">
            <form enctype="multipart/form-data" style="display:none;">
                <input
                        id="multi-file-upload-input"
                        ref="multiFileUploadInput"
                        accept=".pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.odf,.ods"
                        type="file"
                        multiple
                        v-if="config.max_count>1"
                        @change="onUploadFileFieldChanged"/>
                <input
                        ref="singleFileUploadInput"
                        id="single-file-upload-input"
                        accept=".pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.odf,.ods"
                        type="file"
                        v-if="config.max_count===1"
                        @change="onUploadFileFieldChanged"/>
            </form>
        </template>
    </selector-popup>
</template>

<script>
    import SelectorPopup from "../../../../vue/common_components/SelectorPopup/SelectorPopup";
    import AddMediaSelectorPopupMixin from "./AddMediaSelectorPopupMixin";

    export default {
        name: "AddDocumentPopup",
        components: {SelectorPopup},
        mixins: [AddMediaSelectorPopupMixin],
        data() {
            return {
                config: Object,
            };
        },
        computed: {
            items() {
                if (!this.config?.types?.length) {
                    return [];
                }
                const items = [];
                items.push({
                    label: 'Upload<br/>from device',
                    iconClass: 'fa-upload',
                    action: 'function',
                    function: 'uploadByDevice',
                    selection: 'device',
                    noClose: true,
                });
                if (this.config.allow_add_from_library) {
                    items.push({
                        label: 'Add from<br>My Media',
                        iconClass: 'fa-folder-o',
                        action: 'select',
                        selection: 'folder',
                    });
                }
                items.push({
                    label: 'Add from<br/>Google Drive',
                    iconClass: 'fa-google',
                    action: 'select',
                    selection: 'gdrive',
                });
                return items;
            },
        },
        methods: {
            onSelected(item) {
                if (item.action === 'select') {
                    this.resolve({
                        action: 'select',
                        selection: item.selection,
                    });
                } else if (item.action === 'function') {
                    this.uploadByDevice();
                }
            },
            show(config) {
                this.config = config;
                return new Promise((resolve, reject) => {
                    this.reject = reject;
                    this.resolve = resolve;
                    this.$refs.popup.show()
                        .then((i) => this.onSelected(i),
                          reason => {
                              reject(reason);
                        });
                });
            },
            onUploadFileFieldChanged(e) {
                this.resolve({
                    action: 'files',
                    files: e.target?.files
                });
                this.$refs.popup?.close();
            },
            uploadByDevice() {
                setTimeout(() => {
                    if (this.config.max_count === 1) {
                        this.$refs.singleFileUploadInput.click();
                    } else if (this.config.max_count > 1) {
                        this.$refs.multiFileUploadInput.click();
                    }
                }, 20);
            },

            back() {
                this.reject('back');
            }
        }
    };
</script>
