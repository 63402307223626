import _ from 'lodash';
import groupGroupsByContainer from "./group_groups_by_container";
import CommonGroupsListPopup from "./CommonGroupsListPopup.vue";

/**
 * Created by orion on 6/18/17.
 */
renderTask.$inject = ['$rootScope', '$log', 'urlResolve', 'viewMediaFactory', '$templateCache', 'FriendRequestResource', 'simplePopupFactory'];

function renderTask($rootScope, $log, urlResolve, viewMediaFactory, $templateCache, FriendRequestResource, simplePopupFactory) {
    return {
        restrict: 'E',
        scope: {
            task: '=',
        },
        link: function ($scope, element, attrs) {
            // $log.debug($scope.task);

            $templateCache.put('ProcessAuthBlockNotification.LoginBlocked.ToBuddyAndAH.html', require('./tesk_templates/ProcessAuthBlockNotification.LoginBlocked.ToBuddyAndAH.html'));
            $templateCache.put('ProcessAuthBlockNotification.LoginBlocked.ToUser.html', require('./tesk_templates/ProcessAuthBlockNotification.LoginBlocked.ToUser.html'));
            $templateCache.put('ProcessAuthBlockNotification.LoginBlocked.ToSuperAdmin.html', require('./tesk_templates/ProcessAuthBlockNotification.LoginBlocked.ToSuperAdmin.html'));
            $templateCache.put('NewProjectStory.toBuddy.approvePost.html', require('./tesk_templates/NewProjectStory.toBuddy.approvePost.html'));
            $templateCache.put('Invitation.Goal.ToAdmin.html', require('./tesk_templates/Invitation.Goal.ToAdmin.html'));
            $templateCache.put('Invitation.Goal.ToRecipient.html', require('./tesk_templates/Invitation.Goal.ToRecipient.html'));
            $templateCache.put('Invitation.Goal.ToRecipientBuddy.html', require('./tesk_templates/Invitation.Goal.ToRecipientBuddy.html'));
            $templateCache.put('Invitation.Goal.ToSenderBuddy.html', require('./tesk_templates/Invitation.Goal.ToSenderBuddy.html'));
            $templateCache.put('JoinRequest.Goal.ToAdmin.html', require('./tesk_templates/JoinRequest.Goal.ToAdmin.html'));
            $templateCache.put('JoinRequest.Goal.ToSenderBuddy.html', require('./tesk_templates/JoinRequest.Goal.ToSenderBuddy.html'));
            $templateCache.put('JoinRequest.Goal.Notification.ToUser.html', require('./tesk_templates/JoinRequest.Goal.Notification.ToUser.html'));
            $templateCache.put('GoalTaskAssignToUserRequest.UserAssigned.html', require('./tesk_templates/GoalTaskAssignToUserRequest.UserAssigned.html'));
            $templateCache.put('GoalTaskAssignToUserRequest.UserAccepted.html', require('./tesk_templates/GoalTaskAssignToUserRequest.UserAccepted.html'));
            $templateCache.put('GoalTaskAssignToUserRequest.UserAccepted.ToAssignee.html', require('./tesk_templates/GoalTaskAssignToUserRequest.UserAccepted.ToAssignee.html'));
            $templateCache.put('GoalTaskAssignToUserRequest.UserRejected.html', require('./tesk_templates/GoalTaskAssignToUserRequest.UserRejected.html'));
            $templateCache.put('GoalTaskAssignToUserRequest.AssignTask.ToSenderBuddy.html', require('./tesk_templates/GoalTaskAssignToUserRequest.AssignTask.ToSenderBuddy.html'));
            $templateCache.put('GoalTaskAssignToUserRequest.AssignTask.ToReceiverBuddy.html', require('./tesk_templates/GoalTaskAssignToUserRequest.AssignTask.ToReceiverBuddy.html'));
            $templateCache.put('GoalTaskAssignToUserRequest.RejectedBySenderBuddy.html', require('./tesk_templates/GoalTaskAssignToUserRequest.RejectedBySenderBuddy.html'));
            $templateCache.put('GoalTaskAssignToUserRequest.RejectedByReceiverBuddy.html', require('./tesk_templates/GoalTaskAssignToUserRequest.RejectedByReceiverBuddy.html'));

            $templateCache.put('ActionMessage.PostComment.ToSenderBuddy.html', require('./tesk_templates/ActionMessage.PostComment.ToSenderBuddy.html'));


            $templateCache.put('Share.ApproveShare.ToAllFriends.ToSharerBuddy.html', require('./tesk_templates/Share.ApproveShare.ToAllFriends.ToSharerBuddy.html'));
            $templateCache.put('Share.ApproveShare.ToFriend.ToSharerBuddy.html', require('./tesk_templates/Share.ApproveShare.ToFriend.ToSharerBuddy.html'));
            $templateCache.put('Share.ApproveShare.ToGroup.ToSharerBuddy.html', require('./tesk_templates/Share.ApproveShare.ToGroup.ToSharerBuddy.html'));
            $templateCache.put('Share.ApproveShare.ToGroup.ToAdmin.html', require('./tesk_templates/Share.ApproveShare.ToGroup.ToAdmin.html'));

            $templateCache.put('MediaSharingRequest.ApproveShare.MediaFolder.ToUserProfile.BuddyTask.html', require('./tesk_templates/MediaSharingRequest.ApproveShare.MediaFolder.ToUserProfile.BuddyTask.html'));
            $templateCache.put('MediaSharingRequest.ApproveShare.MediaFolder.ToGroup.BuddyTask.html', require('./tesk_templates/MediaSharingRequest.ApproveShare.MediaFolder.ToGroup.BuddyTask.html'));
            $templateCache.put('MediaSharingRequest.ApproveShare.MediaFolder.ToGroup.AdminTask.html', require('./tesk_templates/MediaSharingRequest.ApproveShare.MediaFolder.ToGroup.AdminTask.html'));
            $templateCache.put('MediaSharingRequest.ApproveShare.MediaFolder.ToWholeCircle.BuddyTask.html', require('./tesk_templates/MediaSharingRequest.ApproveShare.MediaFolder.ToWholeCircle.BuddyTask.html'));
            $templateCache.put('MediaSharingRequest.ApproveShare.MediaFolder.ToCommunity.BuddyTask.html', require('./tesk_templates/MediaSharingRequest.ApproveShare.MediaFolder.ToCommunity.BuddyTask.html'));
            $templateCache.put('MediaSharingRequest.ApproveShare.MediaFolder.ToCommunity.AdminTask.html', require('./tesk_templates/MediaSharingRequest.ApproveShare.MediaFolder.ToCommunity.AdminTask.html'));

            $templateCache.put('Media.ToAdmin.UploadedFilesNotification.html', require('./tesk_templates/Media.ToAdmin.UploadedFilesNotification.html'));
            $templateCache.put('Media.ToUser.EditAccessGiven.html', require('./tesk_templates/Media.ToUser.EditAccessGiven.html'));

            $templateCache.put('NewUserStory.AddPost.ToBuddy.html', require('./tesk_templates/NewUserStory.AddPost.ToBuddy.html'));

            $templateCache.put('FriendRequest.ApproveShare.ToRecepient.html', require('./tesk_templates/FriendRequest.ApproveShare.ToRecepient.html'));
            $templateCache.put('FriendRequest.ApproveShare.ToRecepientBuddy.html', require('./tesk_templates/FriendRequest.ApproveShare.ToRecepientBuddy.html'));
            $templateCache.put('FriendRequest.ApproveShare.ToSenderBuddy.html', require('./tesk_templates/FriendRequest.ApproveShare.ToSenderBuddy.html'));

            $templateCache.put('Conversation.ApproveCreate.ToBuddy.html', require('./tesk_templates/Conversation.ApproveCreate.ToBuddy.html'));
            $templateCache.put('ConversationMembership.ApproveAdd.ToBuddy.html', require('./tesk_templates/ConversationMembership.ApproveAdd.ToBuddy.html'));


            $templateCache.put('Invitation.Group.ToRecipient.html', require('./tesk_templates/Invitation.Group.ToRecipient.html'));
            $templateCache.put('Invitation.Group.View.ToSenderBuddy.html', require('./tesk_templates/Invitation.Group.View.ToSenderBuddy.html'));
            $templateCache.put('Invitation.Group.View.ToRecipient.html', require('./tesk_templates/Invitation.Group.View.ToRecipient.html'));
            $templateCache.put('Invitation.Group.ToRecipientBuddy.html', require('./tesk_templates/Invitation.Group.ToRecipientBuddy.html'));
            $templateCache.put('Invitation.Group.ToSenderBuddy.html', require('./tesk_templates/Invitation.Group.ToSenderBuddy.html'));


            $templateCache.put('Invitation.Story.ToRecipient.html', require('./tesk_templates/Invitation.Story.ToRecipient.html'));
            $templateCache.put('Invitation.Story.ToRecipientBuddy.html', require('./tesk_templates/Invitation.Story.ToRecipientBuddy.html'));
            $templateCache.put('Invitation.Story.ToSenderBuddy.html', require('./tesk_templates/Invitation.Story.ToSenderBuddy.html'));


            $templateCache.put('Invitation.AccountHolder.ToRecipient.html', require('./tesk_templates/Invitation.AccountHolder.ToRecipient.html'));


            $templateCache.put('Invitation.Event.ToRecipient.html', require('./tesk_templates/Invitation.Event.ToRecipient.html'));
            $templateCache.put('Invitation.Event.ToRecipientBuddy.html', require('./tesk_templates/Invitation.Event.ToRecipientBuddy.html'));
            $templateCache.put('Invitation.Event.ToSenderBuddy.html', require('./tesk_templates/Invitation.Event.ToSenderBuddy.html'));

            $templateCache.put('JoinRequest.Group.Notification.ToUser.html', require('./tesk_templates/JoinRequest.Group.Notification.ToUser.html'));
            $templateCache.put('JoinRequest.Group.ToAdmin.html', require('./tesk_templates/JoinRequest.Group.ToAdmin.html'));
            $templateCache.put('JoinRequest.Group.ToSenderBuddy.html', require('./tesk_templates/JoinRequest.Group.ToSenderBuddy.html'));
            $templateCache.put('GroupRegisterRequest.ToAdmin.html', require('./tesk_templates/GroupRegisterRequest.ToAdmin.html'));


            $templateCache.put('JoinRequest.Event.ToSenderBuddy.html', require('./tesk_templates/JoinRequest.Event.ToSenderBuddy.html'));
            $templateCache.put('JoinRequest.CalendarEvent.ToAdmin.html', require('./tesk_templates/JoinRequest.CalendarEvent.ToAdmin.html'));
            $templateCache.put('JoinRequest.CalendarEvent.ToSenderBuddy.html', require('./tesk_templates/JoinRequest.CalendarEvent.ToSenderBuddy.html'));

            $templateCache.put('Conversation.ApproveCreate.ToAdmin.html', require('./tesk_templates/Conversation.ApproveCreate.ToAdmin.html'));

            $templateCache.put('Invitation.Blog.ToRecipient.html', require('./tesk_templates/Invitation.Blog.ToRecipient.html'));
            $templateCache.put('Invitation.Blog.ToRecipientBuddy.html', require('./tesk_templates/Invitation.Blog.ToRecipientBuddy.html'));


            $templateCache.put('Task.Notification.Overdue.ToUser.html', require('./tesk_templates/Task.Notification.Overdue.ToUser.html'));
            $templateCache.put('NewStoryStory.ApprovePost.ToBuddy.html', require('./tesk_templates/NewStoryStory.ApprovePost.ToBuddy.html'));
            $templateCache.put('StoryComment.ApprovePost.ToSenderBuddy.html', require('./tesk_templates/StoryComment.ApprovePost.ToSenderBuddy.html'));


            $templateCache.put('EventComment.ApprovePost.ToAdmin.html', require('./tesk_templates/EventComment.ApprovePost.ToAdmin.html'));
            $templateCache.put('EventComment.ApprovePost.ToSenderBuddy.html', require('./tesk_templates/EventComment.ApprovePost.ToSenderBuddy.html'));
            $templateCache.put('Conversation.PostComment.ToSenderBuddy.html', require('./tesk_templates/Conversation.PostComment.ToSenderBuddy.html'));
            $templateCache.put('GroupConversation.PostComment.ToAdmin.html', require('./tesk_templates/GroupConversation.PostComment.ToAdmin.html'));
            $templateCache.put('GroupConversation.PostComment.ToSenderBuddy.html', require('./tesk_templates/GroupConversation.PostComment.ToSenderBuddy.html'));
            $templateCache.put('BlogEntryComment.PostComment.ToSenderBuddy.html', require('./tesk_templates/BlogEntryComment.PostComment.ToSenderBuddy.html'));
            $templateCache.put('BlogEntryGroupSharingRequest.ToGroupAdmin.html', require('./tesk_templates/BlogEntryGroupSharingRequest.ToGroupAdmin.html'));
            $templateCache.put('BlogEntrySharingRequest.ToWholeCircle.ToBuddy.html', require('./tesk_templates/BlogEntrySharingRequest.ToWholeCircle.ToBuddy.html'));
            $templateCache.put('BlogEntrySharingRequest.ToProfilesList.ToBuddy.html', require('./tesk_templates/BlogEntrySharingRequest.ToProfilesList.ToBuddy.html'));
            $templateCache.put('BlogEntrySharingRequest.ToGroupsList.ToBuddy.html', require('./tesk_templates/BlogEntrySharingRequest.ToGroupsList.ToBuddy.html'));


            $templateCache.put('NewGroupStory.ApprovePost.ToSenderBuddy.html', require('./tesk_templates/NewGroupStory.ApprovePost.ToSenderBuddy.html'));
            $templateCache.put('GroupActivityPostComment.PostComment.ToSenderBuddy.html', require('./tesk_templates/GroupActivityPostComment.PostComment.ToSenderBuddy.html'));
            $templateCache.put('GroupActivityPost.ApprovePost.ToBuddy.html', require('./tesk_templates/GroupActivityPost.ApprovePost.ToBuddy.html'));
            $templateCache.put('GroupActivityPost.ApprovePost.ToAdmin.html', require('./tesk_templates/GroupActivityPost.ApprovePost.ToAdmin.html'));
            $templateCache.put('GroupActivityPostComment.PostComment.ToAdmin.html', require('./tesk_templates/GroupActivityPostComment.PostComment.ToAdmin.html'));


            $templateCache.put('PassResetRequest.WaitingProcessing.html', require('./tesk_templates/PassResetRequest.WaitingProcessing.html'));
            $templateCache.put('Invitation.Wiki.ToRecipient.html', require('./tesk_templates/Invitation.Wiki.ToRecipient.html'));

            $templateCache.put('Invitation.Media.ToRecipient.html', require('./tesk_templates/Invitation.Media.ToRecipient.html'));

            $templateCache.put('WikiSharingRequest.ApproveShare.WikiSection.ToUserProfile.BuddyTask.html', require('./tesk_templates/WikiSharingRequest.ApproveShare.WikiSection.ToUserProfile.BuddyTask.html'));
            $templateCache.put('WikiSharingRequest.ApproveShare.WikiSection.ToGroup.BuddyTask.html', require('./tesk_templates/WikiSharingRequest.ApproveShare.WikiSection.ToGroup.BuddyTask.html'));
            $templateCache.put('WikiSharingRequest.ApproveShare.WikiSection.ToGroup.GroupAdminTask.html', require('./tesk_templates/WikiSharingRequest.ApproveShare.WikiSection.ToGroup.GroupAdminTask.html'));
            $templateCache.put('WikiSharingRequest.ApproveShare.WikiSection.ToWholeCircle.BuddyTask.html', require('./tesk_templates/WikiSharingRequest.ApproveShare.WikiSection.ToWholeCircle.BuddyTask.html'));

            $templateCache.put('WikiSharingRequest.ApproveShare.WholeWiki.ToUserProfile.BuddyTask.html', require('./tesk_templates/WikiSharingRequest.ApproveShare.WholeWiki.ToUserProfile.BuddyTask.html'));
            $templateCache.put('WikiSharingRequest.ApproveShare.WholeWiki.ToGroup.BuddyTask.html', require('./tesk_templates/WikiSharingRequest.ApproveShare.WholeWiki.ToGroup.BuddyTask.html'));
            $templateCache.put('WikiSharingRequest.ApproveShare.WholeWiki.ToGroup.GroupAdminTask.html', require('./tesk_templates/WikiSharingRequest.ApproveShare.WholeWiki.ToGroup.GroupAdminTask.html'));
            $templateCache.put('WikiSharingRequest.ApproveShare.WholeWiki.ToWholeCircle.BuddyTask.html', require('./tesk_templates/WikiSharingRequest.ApproveShare.WholeWiki.ToWholeCircle.BuddyTask.html'));


            $scope.getContentUrl = function () {
                return `${$scope.task.task_type_code}.html`;
            };

            $scope.allow = (task) => $log.debug('allow-task-', task);

            $scope.get_user_profile_href = urlResolve.userprofile_by_name;

            $scope.groupGroupsByContainer = _.once(groupGroupsByContainer);

            $scope.get_friend_request_data = function (request_id) {
                $rootScope.show_dimmer();
                FriendRequestResource.get_common_groups(request_id)
                    .then(resp => {
                        const {vm, popupComponent} = simplePopupFactory.mount_vue_popup(CommonGroupsListPopup);
                        popupComponent.show(resp.data);
                        $scope.$on('$destroy', () => {
                            if (vm) {
                                vm.$destroy();
                            }
                        });
                    })
                    .finally(() => {
                        $rootScope.hide_dimmer();
                    });
            };
        },
        template: '<div ng-include="getContentUrl()" style="padding-bottom: 10px;"></div>'
    };
}

export default renderTask;

