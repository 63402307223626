var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("community-page-about-us-wiki", {
    attrs: {
      wikiId: _vm.wikiId,
      "subsections-disabled": _vm.communityWikiSubsectionsDisabled,
      community: _vm.community,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }