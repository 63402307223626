const SelectorPopupMixin = {
    data() {
        return {
            opened: false
        };
    },
    methods: {
        show() {
            const self = this;
            this.opened = true;
            return new Promise((resolve, reject) => {
                self.resolve = resolve;
                self.reject = reject;
            });
        },
        select(what) {
            this.resolve(what);
            this.close();
        },
        close() {
            this.opened = false;
        }
    },

};

export default SelectorPopupMixin;