var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.header)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("label", [_vm._v("Story name:")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.story_name,
            expression: "story_name",
          },
        ],
        staticClass: "form-control",
        domProps: { value: _vm.story_name },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.story_name = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("div", { staticClass: "col-sm-5 text-left" }, [
            _c(
              "button",
              { staticClass: "btn btn-mm btn-red", on: { click: _vm.remove } },
              [_vm._v("Delete Story")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-7 text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-default",
                on: { click: _vm.close },
              },
              [_vm._v("Cancel")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-green",
                attrs: {
                  disabled:
                    _vm.story_name === _vm.initial_collection_name ||
                    !_vm.story_name,
                },
                on: { click: _vm.rename },
              },
              [_vm._v("Rename\n            ")]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }