var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("vue-simple-header", [
        _c("span", { attrs: { slot: "headerTitle" }, slot: "headerTitle" }, [
          _vm._v("Post Comments"),
        ]),
        _vm._v(" "),
        _c(
          "span",
          { attrs: { slot: "panelRight" }, slot: "panelRight" },
          [
            _c("vue-folder-button", {
              ref: "backButton",
              attrs: {
                color: "blue",
                target: _vm.backUrl,
                "icon-class": "fa-reply",
                text: "Back",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("comments-list-infinite-scroll", {
        attrs: {
          "target-id": _vm.postId,
          "comments-resource": _vm.commentsResource,
          "allow-add": _vm.allowAdd,
          "allow-like": true,
          "allow-delete-own": true,
          "allow-delete": _vm.isAdmin,
          "initial-reply-to": _vm.initialReplyTo,
        },
      }),
      _vm._v(" "),
      _c("a", {
        attrs: { href: "#", "aria-hidden": "true" },
        on: { focus: _vm.focusToBackButton },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }