var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "selector-popup",
    { ref: "popup", attrs: { header: _vm.header, items: _vm.items } },
    [
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-primary",
            attrs: { type: "button" },
            on: { click: _vm.cancel },
          },
          [_vm._v("Cancel")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }