var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vue-inline-map" },
    [
      _c("span", { staticClass: "align-helper" }),
      _vm._v(" "),
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.imageLoaded,
            expression: "imageLoaded",
          },
        ],
        ref: "mapImage",
        staticClass: "img-responsive inline_image_container center-block",
        class: { "full-size": _vm.fullSize },
        style: [_vm.maxImageHeightStyle],
        attrs: {
          id: "inline-render-media-image",
          src: _vm.staticMapHref,
          alt: _vm.item.location_point.address,
        },
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.imageLoaded,
              expression: "!imageLoaded",
            },
          ],
          staticClass: "vertical-middle",
          staticStyle: { "font-size": "18px" },
          attrs: { "aria-hidden": "true" },
        },
        [_vm._v("loading...")]
      ),
      _vm._v(" "),
      _c("vue-folder-button", {
        staticClass: "vue-inline-map__router-button",
        attrs: { "icon-class": "fa-route", color: "blue", target: _vm.mapHref },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }