var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { transition: 0, backdrop: false },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          attrs: { id: "close_popup" },
          on: { click: _vm.closePopup },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.header))]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "select-menu-container center" },
        _vm._l(_vm.actions, function (action) {
          return _c(
            "div",
            { key: action.label, staticClass: "select-menu-item" },
            [
              _c("div", { staticClass: "panel panel-primary" }, [
                _c(
                  "div",
                  {
                    staticClass: "panel-heading shortcuts",
                    on: {
                      click: function ($event) {
                        return _vm.select(action.action)
                      },
                    },
                  },
                  [
                    _c("h3", {
                      staticClass: "panel-title",
                      domProps: { innerHTML: _vm._s(action.label) },
                    }),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "select-menu-item__main-icon",
                      class: action.classes,
                      attrs: { "aria-hidden": "true" },
                    }),
                  ]
                ),
              ]),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          { staticClass: "btn btn-mm", on: { click: _vm.closePopup } },
          [_vm._v("Cancel")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }