var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "task-steps" },
    _vm._l(_vm.steps, function (step, $index) {
      return _c("goal-task-steps-step", {
        key: step.id,
        attrs: {
          step: step,
          "task-id": _vm.taskId,
          completable: _vm.completable,
          small: _vm.small,
          index: $index + 1,
          "allow-set-assistance-options": _vm.allowSetAssistanceOptions,
          availableCompletionOptions: _vm.availableCompletionOptions,
        },
        on: {
          taskCompleted: _vm.taskCompleted,
          taskInCompleted: _vm.taskInCompleted,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }