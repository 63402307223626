<template>
    <div class="chat-style-comment-reply" :style="commentStyle">
        <div v-if="showAuthor">
            <strong>{{ authorName }}</strong> has linked an Entry:
        </div>
        <div v-if="comment.linked_blog_entry.activity_post&&comment.linked_blog_entry.activity_post.sticker"
             style="font-size: 0">
            <render-sticker
                    :sticker="comment.linked_blog_entry.activity_post.sticker"
                    :default-size="50"></render-sticker>
        </div>
        <div v-if="commentTextPreview">{{ commentTextPreview }}</div>
        <div v-if="firstMedia" style="color: #333;">
            <media-preview :item="firstMedia" :size="80"></media-preview>
        </div>
        <div v-if="comment.linked_blog_entry&&comment.linked_blog_entry.emotion">
            <entry-emotion :emotion="comment.linked_blog_entry.emotion" :size="30"></entry-emotion>
        </div>
    </div>
</template>

<script>
    import {getAuthorNameFromComment} from '../../shared/comments/common';
    import RenderSticker from "../../add_media/stickers/render_sticker_directive/RenderSticker";
    import EntryEmotion from "../../blogolog/emotion_directive/EntryEmotion";

    export default {
        name: "ChatStyleCommentReplyLinkedEntry",
        components: {EntryEmotion, RenderSticker},
        props: {
            comment: Object,
            noBorder: {
                type: Boolean,
                default: false
            },
            showAuthor: {
                type: Boolean,
                default: true
            },
            whiteBorder: {
                type: Boolean,
                default: false
            },
        },
        computed: {
            authorName() {
                return getAuthorNameFromComment(this.comment);
            },
            commentTextPreview() {
                if (this.comment.linked_blog_entry?.activity_post?.text) {
                    return this.$options.filters.cutName(this.comment.linked_blog_entry.activity_post.text, 15);
                }
            },
            firstMedia() {
                if (this.comment.linked_blog_entry?.activity_post?.items?.length) {
                    return this.comment.linked_blog_entry.activity_post.items[0]?.media_attachment;
                }
            },
            commentStyle() {
                if (this.noBorder) {
                    return {
                        'margin-left': 0,
                    };
                }
                if (this.whiteBorder) {
                    return {
                        'border-left': '3px solid #fff'
                    };
                } else {
                    return {
                        'border-left': '3px solid #333'
                    };
                }
            }
        }
    };
</script>

<style scoped lang="scss">
  .chat-style-comment-reply {
    margin-left: 15px;
    padding: 5px;
  }
</style>