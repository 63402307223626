var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": "Inactivity", role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [_vm._v("Inactivity")]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _vm._v(
            "\n            Due to lack of activity the system will log you out in five minutes. If you want to stay logged into\n            Multi Me please click "
          ),
          _c("strong", [_vm._v("continue")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "div",
              {
                staticClass: "inline-block",
                attrs: { "data-role": "trigger" },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-mm btn-green",
                    on: {
                      click: function ($event) {
                        return _vm.save(1)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "Continue (" +
                        _vm._s(_vm.countDownTimeFormatted) +
                        ")\n                    "
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }