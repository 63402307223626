<template>
    <div class="wiki-page-header" :class="{row:!scaleByIconsSize}">
        <div class="side-padding-0" :class="leftClass" :style="leftPartStyle"><span class="wiki-page-header__label visible-xs">
            <slot name="title">{{title}}</slot>
        </span></div>
        <div class="side-padding-0 text-right" :class="rightClass" :style="rightPartStyle">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CommunityPageAlternativeHeader",
        props: {
            leftPartSize: {
                type: Number,
                default: 12
            },
            title: {
                type: String,
                required: false
            },
            scaling: {
                type: String,
                default: 'xs'
            },
            scaleByIconsSize: {
                type: Boolean,
                default: false
            },
            iconsCount: {
                type: Number
            },
            iconSize: {
                type: Number,
                default: 50
            }
        },
        computed: {
            leftClass() {
                if (!this.scaleByIconsSize) {
                    return `col-${this.scaling}-${this.leftPartSize}`;
                }
            },
            rightClass() {
                if (!this.scaleByIconsSize) {
                    return `col-${this.scaling}-${this.rightPartSize}`;
                }
            },
            leftPartStyle() {
                return {
                    'vertical-align':'top',
                    'display': 'inline-block',
                    'width': `calc(100% - ${this.iconsCount * this.iconSize}px)`
                };
            },
            rightPartStyle() {
                return {
                    'vertical-align':'top',
                    'display': 'inline-block',
                    'text-align':'right',
                    'width': `${this.iconsCount * this.iconSize}px`
                };
            },
            rightPartSize() {
                return 12 - this.leftPartSize;
            }
        }
    };
</script>

<style scoped lang="scss">
    .wiki-page-header {
        font-size: 0;
        /*border-bottom: solid 2px #333;*/
        padding-bottom: 5px;
        /*margin-bottom: 10px;*/
        margin-left: 0;
        margin-right: 0;
        /*margin-top: 10px;*/

        &__label {
            font-size: 28px;
            font-weight: 600;
            line-height: 28px;

            display: block;
            /*text-decoration: underline;*/
        }
    }
</style>