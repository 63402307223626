var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-container", style: _vm.containerSizeOffsetStyle },
    [
      _c(
        "div",
        {
          staticClass: "main-container__image",
          style: [_vm.imageStyle],
          on: { click: _vm.onCreate },
        },
        [_c("i", { staticClass: "fa fa-plus" })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }