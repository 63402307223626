var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "my-stuff-item-my" }, [
    _c(
      "div",
      { staticClass: "my-stuff-item-my__image-part" },
      [
        _vm.routerNavigation
          ? _c("router-link", { attrs: { to: _vm.link } }, [
              _c("img", {
                staticClass: "my-stuff-item-my__content-part__icon",
                attrs: { src: _vm.imageUrl, alt: "" },
              }),
            ])
          : _c("a", { attrs: { href: _vm.link } }, [
              _c("img", {
                staticClass: "my-stuff-item-my__content-part__icon",
                attrs: { src: _vm.imageUrl, alt: "" },
              }),
            ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "my-stuff-item-my__content-part" }, [
      _c("div", { staticClass: "my-stuff-item-my__content-part__text-block" }, [
        _c(
          "div",
          {},
          [
            _vm.routerNavigation
              ? _c("router-link", { attrs: { to: _vm.link } }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.name) +
                      "\n                "
                  ),
                ])
              : _c("a", { attrs: { href: _vm.link } }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.name) +
                      "\n                "
                  ),
                ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }