var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "users-circles-list" }, [
    _c("div", { staticClass: "users-circles-list__image-part" }, [
      _c(
        "a",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: "Go to About Page",
              expression: "'Go to About Page'",
            },
          ],
          attrs: { href: _vm.info_link },
        },
        [
          _c("img", {
            staticClass: "img-circle users-circles-list__image-part__image",
            attrs: { src: _vm.user.avatar },
          }),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "users-circles-list__content-part" }, [
      _c("a", { attrs: { href: _vm.circle_link } }, [
        _c("img", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: "Go to Circle Members Page",
              expression: "'Go to Circle Members Page'",
            },
          ],
          staticClass: "users-circles-list__content-part__icon",
          attrs: { src: _vm.icon, alt: "" },
        }),
      ]),
      _vm._v(" "),
      _c("a", { attrs: { href: _vm.circle_link } }, [
        _vm._v(
          "\n            " + _vm._s(_vm.user.name) + "'s Circle\n        "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }