var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": "Confidentiality agreement", role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v("Confidentiality agreement"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-bottom" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _vm._v(
            "\n            Welcome to our software. As part of our commitment to protecting RIX Software users’ privacy, we require\n            all\n            users\n            to comply with the Confidentiality NHS Code of Practice. By logging in, you confirm that you will not\n            share\n            any\n            patient information with unauthorized individuals. Please take a moment to review NHS\n            "
          ),
          _c("strong", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.gov.uk/government/publications/confidentiality-nhs-code-of-practice",
                },
              },
              [_vm._v("\n                confidentiality policies")]
            ),
          ]),
          _vm._v("\n            and\n            contact our "),
          _c("strong", [
            _c("a", { attrs: { href: "mailto:support@multime.com" } }, [
              _vm._v("support team"),
            ]),
          ]),
          _vm._v(
            " if you have any questions or concerns.\n            Thank you for your cooperation in maintaining\n            patient confidentiality\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-30-top" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("vue-switch-with-label", {
              attrs: {
                label:
                  "I have read and understood this confidentiality agreement",
                name: "confirmed",
              },
              model: {
                value: _vm.confirmed,
                callback: function ($$v) {
                  _vm.confirmed = $$v
                },
                expression: "confirmed",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("div", { staticClass: "col-xs-12 text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-green",
                attrs: { disabled: !_vm.confirmed },
                on: { click: _vm.logIn },
              },
              [_vm._v("Log in")]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }