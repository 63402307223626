<template>
    <button v-bind="$attrs" v-on="$listeners"
            role="button"
            class="button"
            @click="onClick"
    >
        <slot></slot>
    </button>
</template>

<script>
    export default {
        name: 'FolderButtonSpan',
        methods: {
            onClick() {
                this.$emit('onClick');
            },

        },
    };
</script>

<style scoped>
    .button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }
</style>
