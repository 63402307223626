var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("comments-list-infinite-scroll", {
    attrs: {
      "target-id": _vm.storyId,
      "comments-resource": _vm.commentsResource,
      "allow-add": _vm.allowAdd,
      "allow-delete-own": true,
      "allow-like": true,
      "allow-delete": _vm.isAdmin,
      "allow-reply-expand": true,
      "initial-reply-to": _vm.initialReplyTo,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }