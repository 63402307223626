<template>
    <div class="vue-youtube-inline center-block embed-responsive in-vue inline-vimeo__container">
        <img
                v-if="!playButtonClicked"
                :src="item.vimeo_video.preview_url"
                class="inline-vimeo__preview-image"
                alt="Video preview"/>
        <div
                v-if="!playButtonClicked"
                class="inline-vimeo__play-button"
                @click="onPlayClick"
        >
            <i class="far fa-play inline-vimeo__play-button-icon"></i>
        </div>
        <vue-vimeo-player
                v-else
                autoplay
                :video-id="item.vimeo_video.video_id"
        />
    </div>
</template>

<script>
    import { vueVimeoPlayer } from 'vue-vimeo-player';

    export default {
        name: 'VueInlineMediaVimeo',
        components: { vueVimeoPlayer },
        props: {
            lazyLoad: {
                type: Boolean,
                default: false
            },
            item: {
                type: Object
            },
            autoplay: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                playButtonClicked: false,
            };
        },
        computed: {},
        methods: {
            onPlayClick () {
                this.playButtonClicked = true;
            },
        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";

  .inline-vimeo {
    &__play-button {
      position: absolute;
      top: calc(50% - 35px);
      left: calc(50% - 35px);
      cursor: pointer;
      width: 70px;
      height: 70px;

      background-color: $blue;
      border-radius: 50%;
      transition: opacity ease 0.3s;

      &:hover {
        opacity: 0.9;
      }
    }

    &__play-button-icon {
      line-height: 70px;
      color: #fff;
      position: absolute;
      top:0;
      left: 22px;
      font-size: 40px;
    }

    &__container {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__preview-image{
      max-width: 100%;
    }
  }

</style>
