let angular = require('angular');
let _ = require('lodash');
let Autolinker = require('autolinker');
storyHeader.$inject = ['$q', '$sce', '$sanitize', 'notifications', '$rootScope', 'addMedia', 'stickersFactory'];
import './style.scss';

function storyHeader($q, $sce, $sanitize, notifications, $rootScope, addMedia, stickersFactory) {

    return {
        restrict: 'E',
        replace: true,
        transclude: {},
        scope: {
            editMode: '=',
            sticker: '=',
            text: '=',
            isHeader: '=',
            onUpdate: '='
        },
        template: require('./template.html'),
        link: function ($scope, element, attrs, controller) {

            function onUpdate() {
                ($scope.onUpdate || _.noop)({text: $scope.text, sticker: $scope.sticker, is_header: $scope.isHeader});
            }

            $scope.remove_sticker = function () {
                $scope.sticker = null;
                onUpdate();
            };

            $scope.change_text = function () {
                addMedia.add_text({text: $scope.text, is_header: $scope.isHeader}, true, true)
                    .then(res => {
                        $scope.text = res.text;
                        $scope.isHeader = res.is_header;
                        onUpdate();
                    }, () => new Promise(()=>{}));
            };

            $scope.add_sticker = function () {
                stickersFactory.select_sticker()
                    .then(sticker => {
                        $scope.sticker = sticker.code;
                        onUpdate();
                    }, () => new Promise(()=>{}));
            };

            $scope.getDisplayedText = function () {
                if ($scope.text) {
                    let linkedText = Autolinker.link($scope.text, {truncate: 30});
                    linkedText = $sce.trustAs($sce.HTML, $sanitize(linkedText));
                    return linkedText;
                }
                return $sce.trustAsHtml('Add Description');
            };


        }

    };
}

angular.module('mmApp.shared.storyHeader', [])
    .directive('storyHeader', storyHeader);

export default 'mmApp.shared.storyHeader';