/**
 * Created by orion on 12.03.17.
 */
import angular from 'angular';
import 'angular-ui-bootstrap'

//main modules
import notifications from './../shared/notifications/notifications_module'
import simplePopups from '../shared/simple_popups/simple_popup_module';
import urlResolve from '../shared/urlResolve/urlResolve_module';
import app_filters from '../shared/filters/filters_module'
import selectFactory from '../shared/select_factory/selectFactory_module'

//3dparty
import 'angular-ui-switch'
import shareFactory from './shareFactory'

import Sharing from './sharing_resources'

//popups
import shareFactoryWhereListPopupController from './popups/shareFactoryWhereListPopupController'

//styles
import './styles/sharing.scss'

//on profile page
import sharedFolder from './shared_folder_directive/sharedFolder_directive'

angular.module('mmApp.sharing', ['ui.bootstrap', 'uiSwitch', notifications, simplePopups, urlResolve, app_filters, selectFactory])
    .factory('shareFactory', shareFactory)
    .controller('shareFactoryWhereListPopupController', shareFactoryWhereListPopupController)
    .factory('Sharing', Sharing)
    .directive("sharedFolder", sharedFolder);

export default 'mmApp.sharing'