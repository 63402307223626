<template>
    <div class="center-block" style="font-size: 20px;">
        <a :href="item.uploaded_document.document_url" target="_blank">
            <i class="fa fa-file-text-o" aria-hidden="true"></i> {{ item.name | cutName(22)}}
        </a>
    </div>
</template>

<script>
    export default {
        name: "VueInlineMediaDocument",
        props: {
            item: Object
        }
    };
</script>

<style scoped lang="scss">
</style>