<template>
    <div class="" ref="container" :style="[containerStyle, gripStyle]" v-hotkey="keymap">
        <div>
            <alert type="info" class="margin-15-top margin-15-bottom" v-if="wikiEditor">Editing session is being conducted by <b>{{ wikiEditor.name }}</b></alert>
        </div>
        <div class="sections-container">
            <wiki-section-page-section
                    v-for="(section, i) in sections"
                    :is-active="activeSectionNumber===i"
                    :to="getSectionLink(section)"
                    :key="section.id"
                    :section="section"
                    :size="sectionSize"

                    :is-ticked="selectedToMoveSectionsIdList.includes(section.id)"
                    @focus.native="activateSection(i)"
                    @toggleSectionSelect="toggleSectionSelect(section.id)"
                    tabindex="0"
                    ref="wikiSection"
            ></wiki-section-page-section>
            <span :style="swamFakeIconStyle"
                  v-for="it in swamGetFakeItems(sections.length)"
                  :key="it.id"
            ></span>
        </div>
        <div class="alert alert-info" v-if="loaded&&!sections.length">
            There are no sections here yet.
        </div>
        <div class="row">
            <div class="col-sm-12">
                <span tabindex="0" aria-hidden="true" class="focus-catch-link" @focus="returnFocus">&nbsp;</span>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import WikiSectionPageSection from "./WikiSectionPageSection";
    import WikiMainPageHeader from "./WikiMainPageHeader";
    import breadcrumbsMixin from "./breadcrumbsMixin";
    import WikiBreadcrumbs from "../wiki/wiki_breadcrumbs/WikiBreadcrumbs";
    import SelfWidthAwareMixin from "../../../vue/mixins/SelfWidthAwareMixin";

    export default {
        components: {WikiMainPageHeader, WikiSectionPageSection, WikiBreadcrumbs},
        mixins: [breadcrumbsMixin, SelfWidthAwareMixin],
        props: {
            sectionId: {
                type: [String, Number]
            },
            fullScreen: {
                type: Boolean,
                default: false
            },
            getSectionLink: {
                type: Function,
                required: false
            }
        },
        data() {
            return {
                loaded: false,
                containerWidth: 500,
            };
        },
        computed: {
            keymap() {
                return {
                    'j': this.activateNextSection,
                    'k': this.activatePrevSection,
                    'alt+a': this.toggleSoundOnActiveSection,
                    'alt+o': this.openActiveSection,
                };
            },
            gripStyle() {
                if (this.$store.getters['WikiStore/draggedSection']) {
                    return {'cursor': 'grabbing'};
                }
            },
            containerStyle() {
                if (this.fullScreen) {
                    return {
                        height: `${this.windowHeight - 100}px`,
                        'overflow-y': 'auto',
                        'overflow-x': 'hidden',
                        'padding': '10px',
                    };
                }
            },
            swamMinItemSize() {
                let minSize;
                if (this.elWidth >= 1000) {
                    minSize = 182;
                } else if (this.elWidth >= 738) {
                    minSize = 150;
                } else if (this.elWidth >= 390) {
                    minSize = 130;
                } else {
                    minSize = 120;
                }
                return minSize;
            },
            swamMargin() {
                return this.elWidth >= 500 ? 10 : 6;
            },
            swamAvailableSpace() {
                const SCROLLBAR_CORRECTION = this.fullScreen ? 20 : 0;
                const CONTAINER_PADDING_CORRECTION = this.fullScreen ? 20 : 0;
                return this.elWidth - SCROLLBAR_CORRECTION - CONTAINER_PADDING_CORRECTION;
            },
            sectionSize() {
                return this.swamRecommendedItemSize;
            },
            swamItemSize() {
                return this.sectionSize;
            },
            selectedToMoveSectionsIdList() {
                return this.$store.getters['WikiStore/selectedToMoveSectionsIdList'];
            },
            wikiEditor() {
                return this.$store.getters['WikiStore/wikiEditor'];
            },
            editMode() {
                return this.$store.getters['WikiStore/editMode'];
            },
            sections() {
                return this.$store.getters['WikiStore/sections'];
            },
            isEditable() {
                return this.$store.getters['WikiStore/isEditable'];
            },
            activeSectionNumber() {
                return this.$store.getters['WikiStore/activeSectionNumber'];
            },
            nextSectionNumber() {
                return this.$store.getters['WikiStore/nextSectionNumber'];
            },
            prevSectionNumber() {
                return this.$store.getters['WikiStore/prevSectionNumber'];
            },
        },
        methods: {
            activateSection(n) {
                this.$store.commit('WikiStore/setActiveSectionNumber', n);
            },
            toggleSectionSelect(sectionId) {
                this.$store.commit('WikiStore/toggleSelectToMoveSectionsId', sectionId);
            },
            activateNextSection() {
                this.activateSection(this.nextSectionNumber);
            },
            activatePrevSection() {
                this.activateSection(this.prevSectionNumber);
            },
            toggleSoundOnActiveSection() {
                if (this.activeSectionNumber !== undefined) {
                    if (this.$refs.wikiSection && this.$refs.wikiSection[this.activeSectionNumber]) {
                        this.$refs.wikiSection[this.activeSectionNumber]?.toggleSound();
                    }
                }
            },
            openActiveSection() {
                if (this.activeSectionNumber !== undefined) {
                    if (this.$refs.wikiSection && this.$refs.wikiSection[this.activeSectionNumber]) {
                        let x = document.createEvent("MouseEvent");
                        x.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                        this.$refs.wikiSection[this.activeSectionNumber]?.$el?.dispatchEvent(x);
                    }
                }
            },
            additionalSizeCalculations() {
                // this.containerWidth = this.$el.offsetWidth;
            },
            returnFocus() {
                this.$refs.wikiSection[0]?.focus();
            },
            loadSection() {
                this.loaded = false;
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                this.$store.dispatch('WikiStore/loadSubsection', this.sectionId)
                    .then(() => {
                        this.loaded = true;
                        this.$nextTick(() => {
                            setTimeout(() => {
                                if (this.$refs.wikiSection?.length) {
                                    this.$refs.wikiSection[0].focusOnAudioOrElement();
                                }
                            }, 100);
                        });
                    })
                    .finally($rootScope.hide_dimmer);
            },
            openSection(section) {
                this.$emit('onSectionOpen', section);
            },
            goBack() {
                this.$emit('goBack');
            }
        },
        mounted() {
            this.loadSection();
            this.$store.commit('WikiStore/clearSelectedToMoveSectionsIdList');
        },
        beforeDestroy() {
            this.$store.commit('WikiStore/clearSelectedToMoveSectionsIdList');
        },
        watch: {
            sectionId(n) {
                this.loadSection();
            },
            editMode(n) {
                if (!n) {
                    this.$store.commit('WikiStore/clearSelectedToMoveSectionsIdList');
                }
            }
        },
        name: "WikiSectionPage"
    };
</script>

<style scoped lang="scss">
  .sections-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
    align-items: flex-start;

    //@media (min-width: 768px) {
    //  margin-right: -10px;
    //  margin-left: -10px;
    //}
    //@media (max-width: 767px) {
    //  margin-right: -6px;
    //  margin-left: -6px;
    //}
  }
</style>
