<template>
    <modal v-model="opened" :transition='0' :append-to-body="true" :backdrop="false">
        <div slot="header">
            <a id="close_popup" @click="close_popup"></a>
            <h3 class="modal-title">Find event Occurrence</h3>
        </div>
        <div class="row" v-if="opened">
            <div class="col-sm-12 text-center">
                <datepicker
                        v-model="eventDate"
                        :disabled-dates="disabledDatesPicker"
                        :format="customFormatter"
                        :inline="true"
                        :typeable="false"
                        :highlighted="highlighted"
                        :calendar-class="{'event-find-occurrence-popup__calendar':true, 'multiple':allowMultiple}"
                        :open-date="openDate"

                        @changedMonth="onChangedMonth"
                        @selected="onDateSelected"
                        ref="start-date-picker"
                >
                </datepicker>
            </div>
        </div>

        <div slot="footer">
            <button class="btn btn-mm" @click="close_popup">Cancel</button>
            <button class="btn btn-mm btn-green" :disabled="!allowSave" @click="save">OK</button>
        </div>
    </modal>
</template>

<script>
    import Vue from 'vue';
    import moment from 'moment';
    import _ from 'lodash';
    import Datepicker from 'vuejs-datepicker';
    import {TIME_ZONE, VISUAL_DATE_FORMAT} from "../new_calendar/config";

    export default {
        name: "EventFindOccurrencePopup",
        components: {Datepicker},
        props: {
            allowMultiple: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                eventDate: undefined,

                opened: false,

                eventId: undefined,
                calendarOwnerId: undefined,
                date: undefined,

                openDate: undefined,

                loading: false,
                availableDates: [],
                activeMonth: undefined,
                activeYear: undefined,

                selectedDates: [],
            };
        },
        computed: {
            highlighted() {
                if (this.allowMultiple) {
                    return {
                        dates: this.selectedDates.map(d => moment(d, VISUAL_DATE_FORMAT).toDate())
                    };
                } else {
                    return {
                        dates: [this.date.toDate()]
                    };
                }
            },
            disabledDatesPicker() {
                const dates = [];
                const dateInMonth = moment(new Date(this.activeYear, this.activeMonth, 1));
                while (dateInMonth.month() === this.activeMonth) {
                    if (!this.availableDates.includes(dateInMonth.format(VISUAL_DATE_FORMAT))) {
                        dates.push(dateInMonth.toDate());
                    }
                    dateInMonth.add(1, 'd');
                }
                return {
                    dates
                };
            },
            allowSave() {
                if (this.allowMultiple) {
                    return this.selectedDates.length;
                } else {
                    return true;
                }
            }
        },
        methods: {
            resetData() {
                this.eventDate = undefined;
                this.activeYear = undefined;
                this.activeMonth = undefined;
                this.openDate = undefined;
                this.loading = false;
                this.availableDates = [];
                this.selectedDates = [];
            },
            loadEventsInMonth(rawDate) {
                this.activeMonth = rawDate.getMonth();
                this.activeYear = rawDate.getFullYear();
                const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
                this.loading = true;
                CalendarEventsResource.get_event_occurrences_in_month(this.eventId, this.calendarOwnerId, this.date.format(VISUAL_DATE_FORMAT), moment(rawDate).format(VISUAL_DATE_FORMAT))
                    .then(resp => {
                        this.availableDates = resp.data;
                        this.loading = false;
                    });
            },
            show(calendarOwnerId, eventId, date, preSelectedDates = []) {
                this.eventId = eventId;
                this.selectedDates = preSelectedDates.map(d => d);
                this.date = date;
                this.openDate = date.toDate();
                this.calendarOwnerId = calendarOwnerId;
                if (!this.allowMultiple) {
                    this.eventDate = this.date.toDate();
                }
                this.opened = true;
                this.loadEventsInMonth(this.date.toDate());
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            close_popup() {
                this.opened = false;
                this.reject('closed');
                this.resetData();
            },
            onChangedMonth(data) {
                if (!this.opened) {
                    return;
                }
                let firstDateInMonth;
                if (_.isDate(data)) {
                    firstDateInMonth = data;
                } else {
                    if (data.timestamp) {
                        firstDateInMonth = new Date(data.timestamp);
                    }
                }
                this.loadEventsInMonth(firstDateInMonth);
            },
            customFormatter(date) {
                return moment(date).format(VISUAL_DATE_FORMAT);
            },
            save() {
                if (!this.allowMultiple) {
                    this.resolve(this.eventDate.format(VISUAL_DATE_FORMAT));
                } else {
                    this.resolve(this.selectedDates);
                }
                this.opened = false;
                this.resetData();
            },
            onDateSelected(date) {
                if (!this.allowMultiple) {
                    return;
                }
                if (date) {
                    const formattedDate = moment(date).format(VISUAL_DATE_FORMAT);
                    if (this.selectedDates.includes(formattedDate)) {
                        this.selectedDates.splice(this.selectedDates.indexOf(formattedDate), 1);
                    } else {
                        this.selectedDates.push(formattedDate);
                    }
                    this.$nextTick(() => {
                        this.$refs['start-date-picker'].selectedDate = null;
                    });
                }
            }
        }
    };
</script>

<style lang="scss">
    @import "../../styles/const";

    .event-find-occurrence-popup {
        &__calendar {
            margin: auto;

            &.multiple {
                span.cell.day.highlighted {
                    background-color: #4bd;
                    /*opacity: 0.7;*/
                    /*font-weight: bolder;*/
                    /*color: white;*/
                }
            }

        }
    }

</style>