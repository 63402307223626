/**
 * Created by orion on 6/15/17.
 */
let angular = require('angular');

angular.module('mmApp.shared.focusOn', [])
    .directive('focusOn', ['$timeout', function ($timeout) {
        return function (scope, elem, attr) {
            scope.$on(attr.focusOn, function (e) {
                $timeout(() => {
                    elem[0]?.focus();
                }, 100);
            });
        };
    }]);

export default 'mmApp.shared.focusOn'