<template>
    <tr :class="{'warning':!member.is_active,'active':member.is_active}">
        <td>
            <strong v-if="member.membership_type==='userprofile'">
                <img :src="member.userprofile.avatar" class="img-circle vertical-middle"
                     :alt="`${member.userprofile.name}'s avatar`" style="width: 40px; height: 40px;">
                <simple-link :target="member.userprofile" class="vertical-middle"></simple-link>
            </strong> <i v-if="isOwner">(Owner)</i>
            <strong v-if="member.membership_type==='access_link'">{{ member.email || member.name }}</strong>
            <div v-if="member.membership_type==='access_link'" class="margin-5-top">
                <div v-if="member.email">
                    <button class="button-without-style" @click="explainEmailUser"><strong>Email
                        access user <i class="fa fas fa-info color-dark-blue"></i></strong>
                    </button>
                </div>
                <div v-else-if="member.name">
                    <button class="button-without-style" @click="explainAccessLinkUser"><strong>Wiki access link <i class="fa fas fa-info color-dark-blue"></i></strong>
                    </button>
                </div>
            </div>
            <strong v-if="member.membership_type==='account-holder'"><strong>{{
                    member.account_holder.name
                }}</strong><br>(Portal)</strong>
            <strong v-if="member.membership_type==='circle'">My Circle</strong>
            <strong v-if="member.membership_type==='public'"><strong>Public <i
                    class="fal fa-globe-europe"></i></strong><br>(includes unauthenticated users)</strong>
            <strong v-if="member.membership_type==='group'"><strong><a
                    :href="member.group.link">{{ member.group.name }}</a></strong> (Group)</strong>
            <strong v-if="member.membership_type==='circle-invitation'"><strong>{{
                    member.invitation.email
                }}</strong><br> (Pending Circle Invitation)</strong>
            <strong v-if="member.membership_type==='friend-request'"><strong>
                <simple-link
                        :target="member.invitation.profile"></simple-link>
            </strong><br> (Pending Circle Invitation)</strong>
            <div v-if="member.membership_type==='access_link'">
                <member-access-link-field
                        :member="member"
                        @updateAccessLinkTerm="updateAccessLinkTerm"
                        @protectAccessLinkByPassword="protectAccessLinkByPassword"
                />
            </div>
            <div class="">
                <strong class="color-footer-red" v-if="!member.has_assigned_permissions">No access given</strong>
            </div>
        </td>
        <td>
            <span v-if="!member.role">-</span>
            <span v-if="member.role">{{ member.role }}</span>
        </td>
        <td>
            <span v-if="readReceiptApplicable">
                <i
                        class="fa fa-plus-circle wiki-table-member-read-receipts__add-icon"
                        @click="sendReadReceipt"
                        role="button"
                        aria-label="Create Read Receipt"
                ></i>
                <span
                        v-if="member.received_read_receipts_count"
                        class="wiki-table-member-read-receipts__counter"
                        role="button"
                        aria-label="Show Read Receipts"
                >
                    <span
                            @click="showReadReceipts"
                            role="button"
                            aria-label="Show Read Receipts"
                            tabindex="0"
                    >
                        {{ member.completed_read_receipts_count }}<span class="color-footer-red"
                                                                        v-if="member.rejected_read_receipts_count&&member.completed_read_receipts_count">+</span><span
                            class="color-footer-red"
                            v-if="member.rejected_read_receipts_count">{{ member.rejected_read_receipts_count }}</span>
                        /
                        {{ member.received_read_receipts_count }}
                    </span>
                </span>
            </span>
            <span v-else>-</span>

        </td>
        <td><span v-if="member.membership_type!=='public'">{{ member.created_at | gbdate }}</span>
            <span v-else>-</span>
            <small v-show="!member.is_active"><br><i>(Waiting confirmation)</i></small>
        </td>
        <td class="text-center">
            <vue-switch name="is_admin" v-model="member.is_admin" v-show="member.userprofile"
                        @input="toggleMemberAdminStatus(member)"></vue-switch>
            <span v-if="!member.userprofile">-</span>
        </td>
        <td class="text-center">
            <button
                    :disabled="member.is_admin"
                    class="button-without-style"
                    @click="openPermissionsTree"
            >
                <i class="fa fa-pencil fa-2"
                   :class="{'pointer':!member.is_admin,'color-gray':member.is_admin,'color-footer-red':!member.has_assigned_permissions}"
                ></i>
            </button>
        </td>
        <td class="text-center">
            <button
                    v-if="!isOwner&&!(member.membership_type==='public')&&!(member.membership_type==='account-holder')"
                    class="button-without-style"
                    @click="removeMember(member)"
                    aria-label="Remove Member"
            >
                <i class="fa fa-trash-o fa-2 color-red"></i>
            </button>

        </td>
    </tr>
</template>

<script>

    import MemberAccessLinkField from 'shared/memberships/MemberAccessLinkField.vue';
    import { simplePopupFactory } from '~/angular/app/shared/simple_popups/simplePopupFactory';

    export default {
        name: 'wiki-members-list-table-member',
        components: { MemberAccessLinkField },
        props: {
            member: {
                type: Object
            },
            isOwner: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {};
        },
        computed: {
            readReceiptApplicable() {
                if (!['access_link', 'userprofile'].includes(this.member.membership_type)) {
                    return false;
                }
                if (this.member.membership_type === 'userprofile' && !this.member.is_active) {
                    return false;
                }
                return true;
            },
        },
        async mounted() {
        },
        methods: {
            explainEmailUser() {
                simplePopupFactory.show_popup(
                    'Email Access User',
                    `This user has been invited to this Wiki via an email access link.<br><br>

Email access users can view any section of the Wiki they are given access to.<br><br>

Email access users can not edit this Wiki. If you want to give them edit access to the Wiki you will need to invite them to register for an account and join the user's Circle.<br/>`,
                    'OK'
                );
            },
            explainAccessLinkUser() {
                simplePopupFactory.show_popup(
                    'Wiki access link',
                    `This is a view only access link to this Wiki. This link is accessed by users via a secure URL and password and remains active (valid) for a set period of time.
<br/><br/>
Access link users can view any section of this Wiki that they are given access to.<br/>`,
                    'OK'
                );
            },
            sendReadReceipt() {
                this.$emit('sendReadReceipt', this.member);
            },
            showReadReceipts() {
                this.$emit('showReadReceipts', this.member);
            },
            openPermissionsTree() {
                this.$emit('openPermissionsTree', this.member);
            },
            toggleMemberAdminStatus() {
                this.$emit('toggleMemberAdminStatus', this.member);
            },
            removeMember() {
                this.$emit('removeMember', this.member);
            },
            updateAccessLinkTerm() {
                this.$emit('updateAccessLinkTerm', this.member);
            },
            protectAccessLinkByPassword() {
                this.$emit('protectAccessLinkByPassword', this.member);
            },
        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";

  .table-member {
    &__info-icon {
      margin: 3px;
      font-size: 21px;
    }
  }

  .wiki-table-member-read-receipts {
    &__add-icon {
      vertical-align: middle;
      color: $grass-green;
      font-size: $bigger-font-size*1.5;
      cursor: pointer;
    }

    &__counter {
      margin-left: 10px;
      vertical-align: middle;
      font-size: $bigger-font-size;
      cursor: pointer;
    }
  }
</style>
