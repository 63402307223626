import Vue from 'vue';

export default {
    methods: {
        defaultCallback(media_items_list) {
            if (!media_items_list.length > 0) {
                return;
            }
            const MediaFoldersResource = Vue.getAngularModule('MediaFoldersResource');

            let media_ids = [];
            for (let media_item of media_items_list) {
                this.media.unshift(media_item);
                media_ids.push(media_item.pk);
            }
            MediaFoldersResource.put_uploaded_objects_to_media(this.currentUser.id, media_ids, (this.activeFolder ? this.activeFolder.id : false)).then(
                () => {
                    Vue.notifications.success('Item' + (media_items_list.length > 1 ? 's' : '') + ' have been added to ' + (this.activeFolder ? 'folder' : 'My Media') + '.');
                }, (err) => {
                    Vue.notifications.error(err || 'Something went wrong. Please reload page and try again or contact us.');
                }
            );
        },
        processTelestreamUploadResult(result) {
            const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
            if (result?.length) {
                simplePopupFactory.show_popup('Uploaded', 'Your videos are being processed and will appear here soon.', 'OK', false);
            }
            this.defaultCallback(result);
        },
        processVideoRecordResult(result) {
            const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
            if (result?.length) {
                simplePopupFactory.show_popup('Uploaded', 'Your videos are being processed and will appear here soon.', 'OK', false);
            }
            this.defaultCallback(result);
        },
        processAudioRecordResult(result) {
            const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
            if (result?.length) {
                simplePopupFactory.show_popup('Recorded', 'Your audios are being processed and will appear here soon.', 'OK', false);
            }
            this.defaultCallback(result);
        },

        addMedia() {
            const addMedia = Vue.getAngularModule('addMedia');
            // if ($scope.filter) {
            //     $scope.set_filter(false);
            // }
            // if ($scope.search_mode) {
            //     $scope.disable_search();
            // }
            addMedia.add_media({allow_save_to_folders: false}, {
                default_callback: this.defaultCallback,
                process_telestream_upload_result: this.processTelestreamUploadResult,
                process_record_video_result: this.processVideoRecordResult,
                process_record_audio_result: this.processAudioRecordResult
            });
        }
    }
};