var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "event-members-page" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.admins.count,
              expression: "admins.count",
            },
          ],
        },
        [
          _c("div", { staticClass: "event-members-page__header" }, [
            _vm._v("\n            Admins\n        "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {},
            _vm._l(_vm.admins.list, function (member) {
              return _c("vue-stuff-preview", {
                key: member.id,
                attrs: {
                  "stuff-item": member,
                  "stuff-type": "user",
                  deletable: false,
                  animation: true,
                  "online-mark": member.online,
                  size: 70,
                },
                on: {
                  delete: function ($event) {
                    return _vm.deleteMember(member)
                  },
                },
              })
            }),
            1
          ),
          _vm._v(" "),
          _vm.admins.list.length < _vm.admins.count
            ? _c(
                "div",
                {
                  staticClass: "pointer text-right",
                  on: {
                    click: function ($event) {
                      return _vm.loadMoreMembers("admins")
                    },
                  },
                },
                [
                  _vm._v(
                    "Show " +
                      _vm._s(_vm.admins.count - _vm.admins.list.length) +
                      " more"
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.going.count,
              expression: "going.count",
            },
          ],
        },
        [
          _c("div", { staticClass: "event-members-page__header" }, [
            _vm._v("\n            Going\n        "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {},
            _vm._l(_vm.going.list, function (member) {
              return _c("vue-stuff-preview", {
                key: member.id,
                attrs: {
                  "stuff-item": member,
                  "stuff-type": "user",
                  deletable: false,
                  animation: true,
                  "online-mark": member.online,
                },
                on: {
                  delete: function ($event) {
                    return _vm.deleteMember(member)
                  },
                },
              })
            }),
            1
          ),
          _vm._v(" "),
          _vm.going.list.length < _vm.going.count
            ? _c(
                "div",
                {
                  staticClass: "pointer text-right",
                  on: {
                    click: function ($event) {
                      return _vm.loadMoreMembers("going")
                    },
                  },
                },
                [
                  _vm._v(
                    "Show " +
                      _vm._s(_vm.going.count - _vm.going.list.length) +
                      " more"
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.not_going.count,
              expression: "not_going.count",
            },
          ],
        },
        [
          _c("div", { staticClass: "event-members-page__header" }, [
            _vm._v("\n            Not Going\n        "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {},
            _vm._l(_vm.not_going.list, function (member) {
              return _c("vue-stuff-preview", {
                key: member.id,
                attrs: {
                  "stuff-item": member,
                  "stuff-type": "user",
                  deletable: member.deletable,
                  animation: true,
                  "online-mark": member.online,
                },
                on: {
                  delete: function ($event) {
                    return _vm.deleteMember(member)
                  },
                },
              })
            }),
            1
          ),
          _vm._v(" "),
          _vm.not_going.list.length < _vm.not_going.count
            ? _c(
                "div",
                {
                  staticClass: "pointer text-right",
                  on: {
                    click: function ($event) {
                      return _vm.loadMoreMembers("not_going")
                    },
                  },
                },
                [
                  _vm._v(
                    "Show " +
                      _vm._s(_vm.not_going.count - _vm.not_going.list.length) +
                      " more"
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.maybe_going.count,
              expression: "maybe_going.count",
            },
          ],
        },
        [
          _c("div", { staticClass: "event-members-page__header" }, [
            _vm._v("\n            Maybe going\n        "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {},
            _vm._l(_vm.maybe_going.list, function (member) {
              return _c("vue-stuff-preview", {
                key: member.id,
                attrs: {
                  "stuff-item": member,
                  "stuff-type": "user",
                  deletable: member.deletable,
                  animation: true,
                  "online-mark": member.online,
                },
                on: {
                  delete: function ($event) {
                    return _vm.deleteMember(member)
                  },
                },
              })
            }),
            1
          ),
          _vm._v(" "),
          _vm.maybe_going.list.length < _vm.maybe_going.count
            ? _c(
                "div",
                {
                  staticClass: "pointer text-right",
                  on: {
                    click: function ($event) {
                      return _vm.loadMoreMembers("maybe_going")
                    },
                  },
                },
                [
                  _vm._v(
                    "Show " +
                      _vm._s(
                        _vm.maybe_going.count - _vm.maybe_going.list.length
                      ) +
                      " more"
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.other.count,
              expression: "other.count",
            },
          ],
        },
        [
          _c("div", { staticClass: "event-members-page__header" }, [
            _vm._v("\n            Other\n        "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {},
            _vm._l(_vm.other.list, function (member) {
              return _c("vue-stuff-preview", {
                key: member.id,
                attrs: {
                  "stuff-item": member,
                  "stuff-type": "user",
                  deletable: member.deletable,
                  animation: true,
                  "online-mark": member.online,
                },
                on: {
                  delete: function ($event) {
                    return _vm.deleteMember(member)
                  },
                },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "infinite-scroll",
                rawName: "v-infinite-scroll",
                value: _vm.loadMoreMembers,
                expression: "loadMoreMembers",
              },
            ],
          }),
        ]
      ),
      _vm._v(" "),
      _c("event-detail-page-period-selector-popup", {
        ref: "join-type-selector",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }