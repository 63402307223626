import angular from 'angular';
import urls from 'DjangoUrls';
import * as _ from 'lodash';

let {DjangoUrls} = urls;
UserProfileCircleResource.$inject = ['$http'];

function UserProfileCircleResource($http) {
    return {
        get_users_groups,
        invite_to_circle_by_email,
        reg_by_invite,
        join_as_existed_user,
        reject_invitation,
        remove_user,
        get_users_for_invite,
        invite_user,
        get_buddies_for_manage,
        update_buddies,
        leave_users_circle,
        get_pending_invites,
        get_circle_members_for_manage,
        get_pending_invites_count,
    };

    function get_users_groups(user_id, params) {
        const url = DjangoUrls["api.v1:user_circle-get-circle-groups"](user_id);
        return $http({
            method: 'GET',
            url,
            params
        });
    }

    function invite_to_circle_by_email(user_id, data) {
        const url = DjangoUrls["api.v1:user_circle-invite-to-circle-by-email"](user_id);
        return $http({
            method: 'POST',
            url,
            data
        });
    }

    function remove_user(user_id, circle_member_id, data) {
        const url = DjangoUrls["api.v1:user_circle-remove-user"](user_id);
        return $http({
            method: 'POST',
            url,
            data: _.extend({}, {id: circle_member_id}, data)
        });
    }

    function get_users_for_invite(user_id, params) {
        const url = DjangoUrls["api.v1:user_circle-get-users-for-invite"](user_id);
        return $http({
            method: 'GET',
            url,
            params
        });
    }

    function get_buddies_for_manage(user_id, params) {
        const url = DjangoUrls["api.v1:user_circle-get-buddies-for-manage"](user_id);
        return $http({
            method: 'GET',
            url,
            params
        });
    }

    function update_buddies(user_id, data) {
        const url = DjangoUrls["api.v1:user_circle-update-buddies"](user_id);
        return $http({
            method: 'POST',
            url,
            data
        });
    }

    function invite_user(user_id, data) {
        const url = DjangoUrls["api.v1:user_circle-invite-user"](user_id);
        return $http({
            method: 'POST',
            url,
            data
        });
    }

    function reg_by_invite(user_id, data) {
        const url = DjangoUrls["api.v1:user_circle_registration-reg-by-invite"](user_id);
        return $http({
            method: 'POST',
            url,
            data
        });
    }

    function join_as_existed_user(user_id, invitation_code) {
        const url = DjangoUrls["api.v1:user_circle_registration-join-as-existed-user"](user_id);
        return $http({
            method: 'POST',
            url,
            data: {invitation_code}
        });
    }

    function reject_invitation(user_id, invitation_code) {
        const url = DjangoUrls["api.v1:user_circle_registration-reject-invitation"](user_id);
        return $http({
            method: 'POST',
            url,
            data: {invitation_code}
        });
    }

    function leave_users_circle(my_id, users_id) {
        const url = DjangoUrls["api.v1:user_circle-leave-users-circle"](my_id);
        return $http({
            method: 'POST',
            url,
            data: {id: users_id}
        });
    }

    function get_pending_invites(user_id, params) {
        const url = DjangoUrls["api.v1:user_circle-get-pending-invites"](user_id);
        return $http({
            method: 'GET',
            url,
            params
        });
    }

    function get_circle_members_for_manage(user_id, params) {
        const url = DjangoUrls["api.v1:user_circle-get-circle-members-for-manage"](user_id);
        return $http({
            method: 'GET',
            url,
            params
        });
    }

    function get_pending_invites_count(user_id) {
        const url = DjangoUrls["api.v1:user_circle-get-pending-invites-count"](user_id);
        return $http({
            method: 'GET',
            url
        });
    }
}

export default UserProfileCircleResource;

