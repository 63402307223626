<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 aria-label="Upload Audio"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">Upload Audio</h3>
        </div>
        <div class="row" v-for="file in filesToUpload" :key="file.name">
            <div class="col-sm-12">
                <div class="">{{ file.name }}</div>
                <div class="">
                    <progress-bar
                            v-model="file.progress"
                            striped
                            :active="file.uploading"
                            :type="file.uploaded?'success':undefined"/>
                </div>
            </div>
        </div>
        <input :multiple="maxCount>1" @change="filesChanged" type="file"
               accept="audio/mp4,audio/x-m4a,audio/*,.mp4,.aac"
               style="display: none"
               ref="hidden-input">
        <div slot="footer">
            <div class="row">
                <div class="col-sm-5 text-left">
                    <vue-switch-with-label :collapse-on-mobile="false" name="save_to_media" v-model="saveToMedia"
                                           label="Save to My Media"
                                           v-if="allowSaveToMedia"></vue-switch-with-label>
                </div>
                <div class="col-sm-7 text-right">
                    <button class="btn btn-mm btn-primary" @click="back()">Back</button>
                    <button class="btn-mm btn btn-green"
                            ref="uploadButton"
                            @click="upload"
                            v-if="uploadIsReady"
                            :disabled="uploadStarted"
                    >
                        Upload
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <span tabindex="0" aria-hidden="true" class="focus-catch-link" @focus="focusToClose">&nbsp;</span>
                </div>
            </div>

        </div>
    </basic-modal>
</template>

<script>
    import Vue from 'vue';

    import BasicModal from "../../../vue/common_components/BasicModal";
    import PopupPromiseMixin from "../../../vue/mixins/PopupPromiseMixin";
    import FileToDirectUpload from "./directUploadFile";

    export default {
        name: "telestreamUploadPopup",
        components: {BasicModal},
        mixins: [PopupPromiseMixin],
        data() {
            return {
                filesToUpload: [],
                indexToUpload: 0,
                uploadedFiles: [],

                saveToMedia: true,
                allowSaveToMedia: true,
                maxCount: 1,

                anonymous_token: 1,

                uploadStarted: false,
                uploadRejected: false,
            };
        },
        computed: {
            uploadIsReady() {
                return this.filesToUpload.length;
            }
        },
        mounted() {
            document.body.onfocus = this.checkForFiles;
        },
        methods: {
            checkForFiles() {
                this.$nextTick(() => {
                    setTimeout(() => {
                        if (this.filesToUpload.length === 0) {
                            document.body.onfocus = null;
                            this.back();
                        }
                    }, 500);
                });
            },
            resetData() {
                this.filesToUpload.filter(f => f.uploading).map(f => f.cancelUpload());
                this.uploadRejected = true;
                this.filesToUpload = [];
                this.uploadedFiles = [];
                this.indexToUpload = 0;
                this.saveToMedia = true;
                this.uploadStarted = false;
            },
            setInitial(config) {
                this.allowSaveToMedia = config.allowSaveToMedia;
                this.maxCount = config.maxCount;
                this.anonymous_token = config.anonymous_token;
                this.$nextTick(() => {
                    this.$refs['hidden-input'].click();
                });
            },
            filesChanged(e) {
                const rawFiles = (e.target || e.srcElement).files;
                for (let i = 0; i < rawFiles.length; i++) {
                    if (i === this.maxCount) {
                        break;
                    }
                    this.filesToUpload.push(new FileToDirectUpload(rawFiles[i], 'mp3', this.anonymous_token));
                }
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.$refs.uploadButton?.focus();
                    }, 100);
                });
            },
            back() {
                this.close('back');
            },
            onAllUploaded() {
                this.save(this.uploadedFiles);
            },
            onFileUploadDone(uploadId, fileName) {
                const addMediaResources = Vue.getAngularModule('addMediaResources');
                addMediaResources.add_uploaded_media(uploadId, fileName, this.allowSaveToMedia && this.saveToMedia, this.anonymous_token).then((resp) => {
                        this.uploadedFiles.push(resp.data);
                        this.indexToUpload++;
                        if (this.filesToUpload.length > this.indexToUpload) {
                            this.upload();
                        } else {
                            this.onAllUploaded();
                        }
                    },
                    err => {
                        Vue.notifications.error(err.data);
                    });
            },
            upload() {
                if (this.uploadRejected) {
                    return;
                }
                this.uploadStarted = true;
                this.filesToUpload[this.indexToUpload].startUpload()
                    .then((uploadId) => {
                        this.onFileUploadDone(uploadId, this.filesToUpload[this.indexToUpload].name);
                    }, err => {
                        this.close('err');
                    });
            }
        }
    };
</script>

<style scoped>

</style>