/**
 * Created by orion on 6/22/17.
 */
let angular = require('angular');
let _ = require('lodash');
import './joinRequest.scss'

angular.module('mmApp.shared.joinRequest', [])
    .directive("joinRequest", ['$rootScope', '$log', function ($rootScope, $log) {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                name: '@?',
                notJoinedText: '@?',
                isRequestPending: '=',
                requestPendingMessage: '=',
                onJoin: '&?'
            },
            template: require('./joinRequest.html'),
            link: function ($scope, element, attrs, controller) {

                $scope.get_not_joined_text = function () {
                    if ($scope.notJoinedText) {
                        return $scope.notJoinedText;
                    }
                    if ($scope.name) {
                        return `You are not member of this ${$scope.name}.`;
                    }
                    return `You are not member`;

                }

                $scope.join_request_pending_message = $scope.requestPendingMessage?$scope.requestPendingMessage:`You will join this ${$scope.name} after approval. We will notify you.`;

                $scope.join = function () {
                    ($scope.onJoin || _.noop)();
                }

            }
        }
    }
    ]);
export default 'mmApp.shared.joinRequest';