/**
 * Created by orion on 09.05.17.
 */

import angular from 'angular';

// mainFilters
import main_filters from '../shared/filters/filters_module';
import search_input from '../shared/inputs/search_input/search_input_module';
import preload from '../preload/preload';
//shared modules

import simplePopups from '../shared/simple_popups/simple_popup_module';
import notifications from '../shared/notifications/notifications_module';
import infiniteScroll from '../shared/infiniteScroll_directive';
import selectFactory from '../shared/select_factory/selectFactory_module';


//app modules
import add_media from '../add_media/add_media_module';
import view_media from '../view_media/view_media_module';
import events from '../events/events_module';
import backUrlGenerator from '../shared/utils/back_url_generator';
import events_module from '../events/events_module';


//pages
import groupEmailInvitationController from "./groupEmailInvitationController";
//resources


//directives


//factories
import LikeStoryResourceFactory from '../shared/story/resource_factory';
import GroupResource from './group_resource';
import createEditGroupFactory from './create_group/create_group_factory';
import GroupActivityPostResource from './group_activity_resource';
import likesResourceFactory from "../shared/common/likesResourceFactory";
import activityPostResourceFactory from "../shared/activity_post/activityPostResourceFactory";
import GroupEventsSubscriptionResource from "./group_events_subscription_resource";
import GroupEventsHolidaysResource from "./group_events_holidays_resource";
import GroupInviteResource from "./group_invite_resource";



angular.module('mmApp.groups',
    ['ui.bootstrap',
        preload, simplePopups, events_module, backUrlGenerator, notifications, add_media, view_media, main_filters, search_input, infiniteScroll, selectFactory, events])
    .factory('GroupStoryResource', LikeStoryResourceFactory('group_story'))
    .factory('GroupActivityPostStory', LikeStoryResourceFactory('group_activity_post_story'))
    .factory('GroupResource', GroupResource)
    .factory('GroupEventsHolidaysResource', GroupEventsHolidaysResource)
    .factory('createEditGroupFactory', createEditGroupFactory)
    .factory('GroupActivityPostResource', GroupActivityPostResource)
    .factory('GroupEventsSubscriptionResource', GroupEventsSubscriptionResource)
    .factory('GroupInviteResource', GroupInviteResource)

    .factory('GroupActivityPostLikesResource', likesResourceFactory('group_activity_post_likes'))
    .factory('GroupActivityPostManageResource', activityPostResourceFactory('group_activity_post_manage'))
    .controller('groupEmailInvitationController', groupEmailInvitationController)
;


export default 'mmApp.groups';