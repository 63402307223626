<template>
    <span><a :tabindex="tabIndex"
             ref="link"
             :href="targetLink"
             :style="linkStyle"
             v-tooltip="tooltip"
             @click="onClick"
             @keydown.enter="onClick"
    ><slot>{{
            target.name | cutName(cutName)
        }}</slot></a><span
            v-if="postfixLabel">{{ postfixLabel }}</span></span>
</template>

<script>
    import SimpleLinkMixin from './SimpleLinkMixin';
    import RouterCheckAvailabilityMixin from "./RouterCheckAvailabilityMixin";

    export default {
        name: "SimpleLink",
        mixins: [SimpleLinkMixin, RouterCheckAvailabilityMixin],
        props: {
            color: {
                type: String,
                required: false,
            }
        },
        computed: {
            availableInRouter() {
                return this.getRouterAvailability(this.target.link);
            },
            linkStyle() {
                const stl = {};
                if (this.color) {
                    stl.color = this.color;
                }
                return stl;
            },
        },
        methods: {
            onClick(e) {
                if (this.availableInRouter) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.$router.push(this.targetLink);
                }
            },
        }

    };
</script>