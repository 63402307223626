var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c("div", { staticClass: "alert alert-danger" }, [
        _vm._v(
          "\n        This event was deleted or does not occur on selected date.\n    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }