<template>
    <div>
        <div
                class="table-member__valid-label"
                :class="{
                              'table-member__valid-label_error':accessLinkValidityState.expired,
                              'table-member__valid-label_warning':accessLinkValidityState.expiring,
                              'table-member__valid-label_success':!(accessLinkValidityState.expired||accessLinkValidityState.expiring),
                            }"
        >
            <i>Valid until:</i>
            <span v-if="member.access_code_valid_till">{{ member.access_code_valid_till|gbdate }}</span>
            <span v-else>forever</span>
            <i
                    class="fa fa-redo pointer color-dark-blue  table-member__action-icon"
                    tabindex="0"
                    role="button"
                    aria-label="update access link"
                    @click="updateAccessLinkTerm"
            ></i>
        </div>
        <div>
            <a :href="member.access_link" target="_blank">access link</a>
            <i
                    class="fa fa-clipboard pointer color-dark-blue table-member__action-icon"
                    :data-clipboard-text="member.access_link"
                    ref="membersAccessLink"
                    tabindex="0"
                    role="button"
                    aria-label="Copy link to clipboard"
                    v-tooltip="'Copy link to clipboard'"
            ></i>
        </div>
        <div v-if="!member.access_code_password">
            <br>
            <strong class="pointer" @click="protectAccessLinkByPassword">Protect by password</strong>
            <i
                    class="fa fa-key-skeleton pointer color-dark-blue table-member__action-icon"
                    tabindex="0"
                    role="button"
                    aria-label="Enable password protection"
                    v-tooltip="'Enable password protection'"
                    @click="protectAccessLinkByPassword"
            ></i><br>
            <i>Password protection will be applied automatically on 08/05/23</i>
        </div>
        <div v-if="member.access_code_password">
            <span><i>access link password</i></span>
            <i
                    class="fa fa-clipboard pointer color-dark-blue table-member__action-icon"
                    :data-clipboard-text="member.access_code_password"
                    ref="membersAccessPassword"
                    tabindex="0"
                    role="button"
                    aria-label="Copy password to clipboard"
                    v-tooltip="'Copy password to clipboard'"
            ></i>
        </div>
    </div>
</template>

<script>
    import Clipboard from 'clipboard';
    import moment from 'moment';

    export default {
        name: 'MemberAccessLinkField',
        props: {
            member: Object,
        },
        data () {
            return {
                isClipboardSupported: Clipboard.isSupported(),
            };
        },
        computed: {
            accessLinkValidityState () {
                if (this.member.access_code_valid_till) {
                    const remainDays = -moment.tz().diff(this.member.access_code_valid_till, 'days');
                    return {
                        expired: remainDays < 0,
                        expiring: remainDays < 3,
                        maxDuration: remainDays > 29,
                    };
                }
                return {};
            },
        },
        async mounted () {
            if (this.isClipboardSupported) {
                const errorHandler = e => {
                    this.$notifications.error('You browser doesn\'t support auto copy. Please copy link manually');
                };
                const successHandler = e => {
                    this.$notifications.success('Copied');
                };
                await this.$nextTick();
                let clipboardIconLink = this.$refs.membersAccessLink;
                let clipboardLink = new Clipboard(clipboardIconLink);
                clipboardLink.on('success', successHandler);
                clipboardLink.on('error', errorHandler);
                if (this.$refs.membersAccessPassword) {
                    let clipboardIconPassword = this.$refs.membersAccessPassword;
                    let clipboardPassword = new Clipboard(clipboardIconPassword);
                    clipboardPassword.on('success', successHandler);
                    clipboardPassword.on('error', errorHandler);
                }
            }

        },
        methods: {
            updateAccessLinkTerm () {
                this.$emit('updateAccessLinkTerm', this.member);
            },
            protectAccessLinkByPassword () {
                this.$emit('protectAccessLinkByPassword', this.member);
            },
        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";

  .table-member {
    &__valid-label {
      font-weight: bold;

      &_success {
        color: $grass-green-dark;
      }

      &_warning {
        color: $color-story-dark;
      }

      &_error {
        color: $footer-red
      }
    }

    &__action-icon {
      font-size: 21px;
      margin: 3px;
    }
  }
</style>
