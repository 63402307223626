/**
 * Created by orion on 09.05.17.
 */

import angular from 'angular'

// mainFilters
import main_filters from '../shared/filters/filters_module'
import search_input from '../shared/inputs/search_input/search_input_module'
import preload from '../preload/preload'
//shared modules

import simplePopups from '../shared/simple_popups/simple_popup_module';
import notifications from '../shared/notifications/notifications_module';
import infiniteScroll from '../shared/infiniteScroll_directive'
import selectFactory from '../shared/select_factory/selectFactory_module'
import backUrlGenerator from '../shared/utils/back_url_generator'


//app modules
import add_media from '../add_media/add_media_module'
import view_media from '../view_media/view_media_module'

import attachments from '../shared/attachments/attachments_module'

//pages
import CollectionsListController from './myCollectionsListPageController'
import collectionDetailController from './CollectionDetailPage/collectionDetailController'
import CollectionAttachmentsController from './CollectionDetailPage/attachments/CollectuionAttachmentsController'
import CollectionMembersController from './CollectionDetailPage/members/CollectionMembersController'

//resources
import CollectionsResource from './collections_resource'

//directives


//factories
import AttachmentsResourceFactory from '../shared/attachments/attachments_resource_factory'
import MembersResourceFactory from '../shared/members/resource_factory'
import createNewCollectionFactory from './create_new_collection_popup/create_new_collection_factory'

angular.module('mmApp.collections',
    ['ui.bootstrap',
        preload, simplePopups, backUrlGenerator, notifications, add_media, view_media, main_filters, search_input, infiniteScroll, selectFactory, attachments])
    .factory('CollectionsResource', CollectionsResource)
    .factory('CollectionMembersResource', MembersResourceFactory('collection_membership'))
    .factory('createNewCollectionFactory', createNewCollectionFactory)
    .factory('CollectionAttachmentsResource', AttachmentsResourceFactory('collection_attachments'))

    .controller('CollectionsListController', CollectionsListController)
    .controller('collectionDetailController', collectionDetailController)
    .controller('CollectionMembersController', CollectionMembersController)
    .controller('CollectionAttachmentsController', CollectionAttachmentsController)
;


export default 'mmApp.collections'