<template>
    <div class="vue-folder-item__container" :style="[containerSize, dragStyle]"
         :class="{'delete-mode':selectedToDelete,'move-mode':selectedToMove}">
        <div v-if="selectedToDelete||selectedToMove"
             class="trash-holder"
             @click="itemClick"
             :style="actionIconSize"
        >
            <i
                    :class="{'fa-trash-o':selectedToDelete,'fa-check':selectedToMove}" class="fa"
                    aria-hidden="true"></i>
        </div>
        <div class="vue-folder-item__delete-button hidden-print" @click.prevent.stop="deleteClick"
             v-if="deletionAllowed">
            <i class="fa fa-trash-o"></i>
        </div>
        <div class="vue-folder-item__drug-button hidden-print"
             @mousedown.stop.prevent="dragGripClicked"
             @touchstart.stop.prevent="dragGripTouched"
             v-if="dragAllowed">
            <i class="fa fa-arrows-alt"></i>
        </div>
        <div class="vue-folder-item" v-tooltip="tooltipText">
            <div class="vue-folder-item__preview text-center" :style="previewStyle" @click="itemClick">
                <img v-if="!iconNeeded" class="visible-print vue-folder-item__attachment-print-image"
                     :src="attachment.thumbnail" alt="">
                <i v-if="iconNeeded"
                   class="fa vue-folder-item__preview__icon"
                   :class="iconClass"
                   :style="iconStyle"
                   aria-hidden="true"></i>
            </div>
            <div class="vue-folder-item__footer" :style="footerStyle">
                <div class="vue-folder-item__footer__name">
                    <span>{{attachment.name}}</span>
                </div>
                <div class="vue-folder-item__footer__icon">
                    <i :class="iconClass" class="fa" aria-hidden="true"></i>
                    <i class="fa fa-pencil" aria-hidden="true" v-if="allowEdit" @click="editNameClick"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MouseEventsMixin
        from '../../../../../vue/mixins/MouseEventsMixin.js';
    import FolderItemDragAspectMixin from './drag_aspect/FolderItemDragAspectMixin';

    const ICON_NEEDED_CHECKERS = {
        'folder': f => f.media_content_samples.length === 0,
        'uploaded_document': f => true,
        'uploaded_audio': f => true,
        'recorded_audio': f => true,
        'location_point': f => true,
        'link': f => !f.link.image_url,
        'upl_video': f => !f.upl_video.encoding_done,
        'image': f => false,
        'youtube_video': f => false,
        'vimeo_video': f => false,
    };

    const ICONS = {
        'image': 'fa-picture-o',
        'upl_video': 'fa-video-camera',
        'folder': 'fa-folder',
        'youtube_video': 'fa-youtube',
        'vimeo_video': 'fa-vimeo',
        'uploaded_document': 'fa-file-text-o',
        'link': 'fa-globe',
        'location_point': 'fa-map-marker',
        'uploaded_audio': 'fa-volume-up',
        'recorded_audio': 'fa-volume-up',
    };

    export default {
        name: "UsersMediaPageFolderItem",
        mixins: [MouseEventsMixin, FolderItemDragAspectMixin],
        props: {
            massActionsState: Object,

            attachment: Object,
            size: {
                default: 152,
                type: Number,
            },
            allowDelete: {
                type: Boolean,
                default: false,
            },
            allowEdit: {
                type: Boolean,
                default: true,
            },
            showTooltip: {
                type: Boolean,
                default: true,
            },
            allowDrag: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            selectedToDelete() {
                return this.massActionsState.deleteMode && this.massActionsState.selectedToDelete.indexOf(this.attachment.pk) !== -1;
            },
            selectedToMove() {
                return this.massActionsState.moveMode && this.massActionsState.selectedToMove.indexOf(this.attachment.pk) !== -1;
            },
            dragAllowed() {
                return this.allowDrag && !this.massActionsState.deleteMode && !this.massActionsState.moveMode;
            },
            deletionAllowed() {
                return this.allowDelete && !this.massActionsState.deleteMode && !this.massActionsState.moveMode;
            },
            tooltipText() {
                if (this.showTooltip) {
                    return this.attachment.name;
                }
            },
            currentSize() {
                return this.size;
            },
            iconNeeded() {
                return ICON_NEEDED_CHECKERS[this.attachment.type](this.attachment);
            },
            iconClass() {
                return ICONS[this.attachment.type];
            },
            containerSize() {
                return {
                    width: `${this.currentSize}px`,
                    height: `${this.currentSize}px`,
                };
            },
            actionIconSize() {
                return {
                    width: `${this.currentSize}px`,
                    height: `${this.currentSize}px`,
                    'font-size': `${this.currentSize}px`,
                };
            },
            previewStyle() {
                const style = {
                    'height': `${this.size - 42}px`
                };
                if (this.attachment.thumbnail) {
                    style['background-image'] = `url('${this.attachment.thumbnail}')`;
                    style['background-size'] = '100%';
                    style['background-repeat'] = 'no-repeat';
                    style['background-position'] = 'center';
                }
                return style;
            },
            footerStyle() {
                return {'height': '42px'};
            },
            iconStyle() {
                return {
                    'font-size': `${this.currentSize / 4 * 2.5}px`,
                    'margin-top': `${this.currentSize / 16}px`,
                };
            }
        },
        methods: {
            itemClick() {
                this.$emit('click');
            },
            deleteClick() {
                this.$emit('delete');
            },
            editNameClick() {
                this.$emit('editName');
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../../styles/const";
    @import "../../../styles/mixins";

    $folder_size: 160px;
    $folder_size_mobile: 120px;

    $folder_size_small: 120px;
    $folder_size_mobile_small: 90px;

    .vue-folder-item {
        font-size: 14px;
        border-radius: 5px;
        background-color: #FFF;
        overflow: hidden;
        cursor: pointer;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        height: 100%;
        @media print {
            border: solid 1px #333;
        }

        &__container {
            position: relative;

            margin: 10px;
            @media (max-width: $screen-xs-max) {
                margin: 10px 6px;
            }
            display: inline-block;
            border-radius: 5px;
            vertical-align: middle;

            &.delete-mode {
                background-color: #E6493B;

                .trash-holder {
                    color: #E6493B;
                }
            }

            &.move-mode {
                background-color: #0599f4;

                .trash-holder {
                    color: #0599f4;
                }
            }

            .trash-holder {
                position: absolute;
                text-align: center;
                cursor: pointer;
                background-color: rgba(15, 15, 15, 0.2);

                i {
                    position: relative;
                    top: -36px;
                }
            }
        }

        &__preview {
          font-size: 0;
            /*height: 75%;*/
            overflow: hidden;

            &__icon {
                color: #0599f4;
            }
        }

        &__footer {
            /*height: 25%;*/

            &__name {
                display: inline-block;
                height: 100%;
                width: calc(100% - 25px);
                overflow: hidden;
                vertical-align: top;
            }

            &__icon {
                height: 100%;
                display: inline-block;
                width: 20px;
                vertical-align: top;
                font-size: 18px;
                text-align: center;
                line-height: 21px;
            }
        }

        &__delete-button {
            @include circleDeleteButton;
        }

        &__drug-button {
            @include circleAdditionalButton(1, $blue, 'grab')
        }

        &__attachment-print-image {
            max-width: 100%;
            /*max-height: 100%;*/
            margin: auto;
            vertical-align: middle;
        }

    }
</style>
