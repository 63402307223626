/**
 * Created by orion on 08.03.17.
 */
import urls from 'DjangoUrls';

let {DjangoUrls} = urls;
MediaFoldersResource.$inject = ['$http'];
import _ from 'lodash';

function MediaFoldersResource($http) {
    return {
        get_folders_i_manage(params) {
            const url = DjangoUrls["api.v1:media_folders-get-folders-i-manage"]();

            return $http({
                method: 'GET',
                url: url,
                params
            });
        },
        get_shared_read_only_media(params) {
            const url = DjangoUrls["api.v1:media_folders-get-shared-read-only-media"]();

            return $http({
                method: 'GET',
                url: url,
                params
            });
        },
        hide_shared_folder(userId, folderId) {
            const url = DjangoUrls["api.v1:media_folders-hide-shared-folder"](userId);
            return $http({
                method: 'POST',
                url: url,
                data: {
                    id: folderId
                }
            });
        },
        get_main_folders_data(userId, parentFolderSlug = null, access_code = undefined, shared = false, managed=false) {
            const url = DjangoUrls["api.v1:media_folders-get-main-folders-data"](userId);
            return $http({
                method: 'GET',
                url: url,
                params: {
                    access_code,
                    shared,
                    managed,
                }
            });
        },
        get_in_folder_folders_data(userId, parentFolderSlug = null, access_code = undefined, shared = false) {
            const url = DjangoUrls["api.v1:media_folders-get-in-folder-folders-data"](userId);
            return $http({
                method: 'GET',
                url: url,
                params: {
                    folder: parentFolderSlug,
                    access_code,
                    shared
                }
            });
        },
        get_users_media(userId, parentFolderSlug = null, params = {}, access_code = undefined) {
            const url = DjangoUrls["api.v1:media_folders-get-users-media"](userId);
            return $http({
                method: 'GET',
                url: url,
                params: _.merge({folder: parentFolderSlug, access_code}, params)
            });
        },
        get_media_files_to_view_in_task(userId, idList) {
            const url = DjangoUrls["api.v1:media_folders-get-media-files-to-view-in-task"](userId);
            return $http({
                method: 'GET',
                url: url,
                params: {'id_list': idList}
            });
        },
        create_folder(userId, name, parentFolderSlug = null) {
            const url = DjangoUrls["api.v1:media_folders-create-folder"](userId);
            return $http({
                method: 'POST',
                url: url,
                data: {
                    name, folder: parentFolderSlug
                }
            });
        },
        get_my_status(userName, access_code = undefined) {
            const url = DjangoUrls["api.v1:media_folders-get-my-status"]();
            return $http({
                method: 'GET',
                url: url,
                params: {
                    name: userName,
                    access_code
                }
            });
        },
        put_uploaded_objects_to_media(userId, ids, folder_pk) {
            const url = DjangoUrls["api.v1:media_folders-put-uploaded-objects-to-media"](userId);
            let data = {
                ids: ids
            };
            if (folder_pk) {
                data.folder_pk = folder_pk;
            }
            return $http({
                method: 'POST',
                url: url,
                data: data
            });
        },
        move_media_to_folder(userId, mediaToDeleteIdList, targetFolderId) {
            const url = DjangoUrls["api.v1:media_folders-move-media-to-folder"](userId);
            let data = {
                media_to_move_id_list: mediaToDeleteIdList,
                target_folder_id: targetFolderId,
            };
            return $http({
                method: 'POST',
                url: url,
                data: data
            });
        },
        remove_media(userId, mediaToDeleteIdList) {
            const url = DjangoUrls["api.v1:media_folders-remove-media"](userId);
            let data = {
                id_list: mediaToDeleteIdList
            };
            return $http({
                method: 'POST',
                url: url,
                data: data
            });
        },
        rename_folder(userId, FolderId, newName) {
            const url = DjangoUrls["api.v1:media_folders-rename-folder"](userId);
            let data = {
                id: FolderId,
                name: newName
            };
            return $http({
                method: 'POST',
                url: url,
                data: data
            });
        },
        remove_folder(userId, FolderId, mode) {
            const url = DjangoUrls["api.v1:media_folders-remove-folder"](userId);
            let data = {
                id: FolderId,
                mode
            };
            return $http({
                method: 'POST',
                url: url,
                data: data
            });
        },
        rename_media(userId, mediaId, newName) {
            const url = DjangoUrls["api.v1:media_folders-rename-media"](userId);
            let data = {
                id: mediaId,
                name: newName
            };
            return $http({
                method: 'POST',
                url,
                data
            });
        },
        join_as_buddy(userId) {
            const url = DjangoUrls["api.v1:media_folders-join-as-buddy"](userId);
            return $http({
                method: 'POST',
                url: url
            });
        }
    };
}

export default MediaFoldersResource;