/**
 * Created by orion on 6/24/17.
 */
import axios from 'axios';
import { DjangoUrls } from 'DjangoUrls';
import { ACCESS_LINK_TOKEN_HEADER_NAME } from './common';

WikiResource.$inject = ['$http'];

function WikiResource($http) {
    return {
        create_new_wiki,
        apply_template_to_wiki,
        update_main_wiki_settings,
        make_wiki_main,
        delete_wiki,
        get_wiki_data_for_template_applying,
        get_my_wikis,
        get_wiki_membership_state,
        get_potential_editors,
        create_section,
        retrieve_sections_as_list,
        get_users_to_invite,
        invite_users,
        invite_by_email,
        create_protected_access_link_and_send_to_email,
        create_access_link,
        create_protected_access_link,
        send_circle_invitation,
        make_me_admin,

        check_access_link_state,
        authenticate_access_link,
        check_access_link_token,
    };

    ///////////////////

    function get_my_wikis() {
        const url = DjangoUrls['api.v1:wiki-get-my-wikis']();

        return axios({
            method: 'GET',
            url
        });
    }

    function get_wiki_data_for_template_applying(wikiId) {
        const url = DjangoUrls['api.v1:wiki-get-wiki-data-for-template-applying'](wikiId);

        return axios({
            method: 'GET',
            url
        });
    }

    function create_new_wiki(data) {
        const url = DjangoUrls['api.v1:wiki-create-new-wiki']();

        return axios({
            method: 'POST',
            url,
            data
        });
    }

    function apply_template_to_wiki(wikiId, templateId) {
        const url = DjangoUrls['api.v1:wiki-apply-template-to-wiki'](wikiId);

        return axios({
            method: 'POST',
            url,
            data: {
                id: templateId
            }
        });
    }

    function update_main_wiki_settings(wikiId, data, assistant = {}) {
        const url = DjangoUrls['api.v1:wiki-update-main-wiki-settings'](wikiId);

        return axios({
            method: 'POST',
            url,
            data: { ...data, ...assistant }
        });
    }

    function make_wiki_main(wikiId, assistant = {}) {
        const url = DjangoUrls['api.v1:wiki-make-wiki-main'](wikiId);

        return axios({
            method: 'POST',
            url,
            data:{...assistant}
        });
    }

    function delete_wiki(wikiId, assistant = {}) {
        const url = DjangoUrls['api.v1:wiki-delete-wiki'](wikiId);

        return axios({
            method: 'DELETE',
            url,
            data:{...assistant}
        });
    }

    function get_wiki_membership_state(wikiId, accessCode = undefined, accessToken = undefined) {
        const url = DjangoUrls['api.v1:wiki-get-wiki-membership-state'](wikiId);
        const headers = {};
        if (accessToken) {
            headers[ACCESS_LINK_TOKEN_HEADER_NAME] = accessToken;
        }
        return axios({
            method: 'GET',
            url: url,
            params: accessCode ? { access_code: accessCode } : undefined,
            headers,
        });
    }

    function create_section(wikiId, data, assistant = {}) {
        const url = DjangoUrls['api.v1:wiki-create-section'](wikiId);

        return axios({
            method: 'POST',
            url: url,
            data: { ...data, ...assistant }
        });
    }

    function retrieve_sections_as_list(wikiId, access_code, accessToken) {
        const url = DjangoUrls['api.v1:wiki-retrieve-sections-as-list'](wikiId);
        const headers = {};
        if (accessToken) {
            headers[ACCESS_LINK_TOKEN_HEADER_NAME] = accessToken;
        }
        const rq_config = {
            method: 'GET',
            url,
            headers,
        };
        if (access_code) {
            rq_config.params = { access_code };
        }
        return axios(rq_config);
    }

    function get_users_to_invite(wikiId, params) {
        const url = DjangoUrls['api.v1:wiki-get-users-to-invite'](wikiId);
        return axios({
            method: 'GET',
            url: url,
            params
        });
    }

    function invite_users(wikiId, data) {
        const url = DjangoUrls['api.v1:wiki-invite-users'](wikiId);
        return axios({
            method: 'post',
            url,
            data
        });
    }

    function invite_by_email(wikiId, email, role) {
        const url = DjangoUrls['api.v1:wiki-invite-by-email'](wikiId);
        return axios({
            method: 'post',
            url,
            data: {
                email,
                role
            }
        });
    }

    function create_protected_access_link_and_send_to_email(wikiId, data) {
        const url = DjangoUrls[`api.v1:wiki-create-protected-access-link-and-send-to-email`](wikiId);
        return axios({
            method: 'post',
            url,
            data
        });
    }

    function create_access_link(wikiId, name) {
        const url = DjangoUrls['api.v1:wiki-create-access-link'](wikiId);
        return axios({
            method: 'post',
            url,
            data: { name }
        });
    }

    function create_protected_access_link(wikiId, data) {
        const url = DjangoUrls[`api.v1:wiki-create-protected-access-link`](wikiId);
        return axios({
            method: 'post',
            url,
            data
        });
    }

    function send_circle_invitation(wikiId, data) {
        const url = DjangoUrls['api.v1:wiki-send-circle-invitation'](wikiId);
        return axios({
            method: 'post',
            url,
            data: data
        });
    }

    function make_me_admin(wikiId) {
        const url = DjangoUrls['api.v1:wiki-make-me-admin'](wikiId);
        return axios({
            method: 'post',
            url
        });
    }

    function check_access_link_state(wikiId, access_code) {
        const url = DjangoUrls[`api.v1:wiki-check-access-link-state`](wikiId);
        return axios({
            method: 'get',
            url,
            params: { access_code }
        });
    }

    function authenticate_access_link(wikiId, access_code, password) {
        const url = DjangoUrls[`api.v1:wiki-authenticate-access-link`](wikiId);
        return axios({
            method: 'POST',
            url,
            data: {
                access_code,
                password
            },
            noRedirectOnError: true,
        });
    }

    function check_access_link_token(wikiId, access_code, token) {
        const url = DjangoUrls[`api.v1:wiki-check-access-link-token`](wikiId);
        const headers = {};
        headers[ACCESS_LINK_TOKEN_HEADER_NAME] = token;
        return axios({
            method: 'POST',
            url,
            data: { access_code },
            noRedirectOnError: true,
            headers
        });
    }

    function get_potential_editors(wikiId, params) {
        const url = DjangoUrls['api.v1:wiki-get-potential-editors'](wikiId);
        return axios({
            method: 'GET',
            url: url,
            params
        });
    }

}

export default WikiResource;
