/**
 * Created by orion on 6/24/17.
 */
import urls from 'DjangoUrls';
import axios from "axios";
let {DjangoUrls} = urls;
UserEventsResource.$inject = ['$http'];

function UserEventsResource($http) {
    return {

        get_users_calendar_week,
        search_events,
        make_user_calendar_admin,
        unmake_user_calendar_admin,
        get_group_subscriptions,
        update_group_events_subscription,

        get_calendar_membership_state,
    };

    function get_users_calendar_week(user_id, date) {
        const url = DjangoUrls["api.v1:user_events-get-users-calendar-week"](user_id);
        return axios({
            method: 'GET',
            url,
            params: {
                date
            }
        });
    }

    function search_events(user_id, mode, params) {
        const url = DjangoUrls["api.v1:user_events-search-events"](user_id);
        return axios({
            method: 'GET',
            url,
            params: Object.assign({mode}, params),
        });
    }

    function make_user_calendar_admin(user_id) {
        const url = DjangoUrls["api.v1:user_events-make-user-calendar-admin"](user_id);
        return axios({
            method: 'PATCH',
            url
        });
    }

    function unmake_user_calendar_admin(user_id) {
        const url = DjangoUrls["api.v1:user_events-unmake-user-calendar-admin"](user_id);
        return axios({
            method: 'PATCH',
            url
        });
    }

    function get_group_subscriptions(userId, params) {
        const url = DjangoUrls["api.v1:user_events-get-group-subscriptions"](userId);
        return axios({
            method: 'GET',
            url,
            params
        });
    }

    function update_group_events_subscription(userId, new_checked_ids, new_unchecked_ids) {
        const url = DjangoUrls["api.v1:user_events-update-group-events-subscription"](userId);
        return axios({
            method: 'POST',
            url,
            data: {new_checked_ids, new_unchecked_ids}
        });
    }

    function get_calendar_membership_state(userId) {
        const url = DjangoUrls["api.v1:user_events-get-calendar-membership-state"](userId);
        return axios({
            method: 'GET',
            url,
        });
    }
}

export default UserEventsResource;