<template>
    <div class="comments-input-form hidden-print" v-hotkey="keymap">
        <div class="row">
            <div class="col-xs-12">
                <form id="reply-textarea">
                    <span v-if="replyTo" class="comments-input-form__reply-to-message">Reply to <strong>{{
                            replyTo.author.name
                        }}</strong>
                        (<a @click.stop.prevent="cancelReply" href="#">cancel</a>) : <i>{{ replyTo.messagePreview }}</i></span>
                    <textarea id="comments-input"
                              ref="commentsInput"
                              aria-label="Add Comment"
                              class="form-control comments-input-form__input-textarea"
                              :class="{'active':mediaAttachment||sticker||text||replyTo}"
                              v-model="text"
                              placeholder="Type your comment here..." rows="5" maxlength="5000"
                              @keydown.ctrl.enter="addMessage"
                              @keydown.alt.s="addSticker"
                              @keydown.alt.u="uploadPicture"
                              @keydown.alt.z="recordAudio"
                    ></textarea>
                </form>
            </div>
        </div>
        <div class="row bottom-buttons-panel-main">
            <div class="col-sm-9 col-xs-7" style="padding-right: 0" v-if="allowAddSticker||allowAddMedia">
                <add-media-sticker-emotion-panel
                        :allow-add-media="allowAddMedia"
                        :allow-add-emotion="allowAddEmotion"
                        :add-media-disabled="!!mediaAttachment"
                        :add-sticker-disabled="!!sticker"
                        :add-emotion-disabled="!!emotion"
                        @addMedia="addMedia"
                        @addSticker="addSticker"
                        @addEmotion="addEmotion"
                ></add-media-sticker-emotion-panel>
            </div>
            <div class="col-sm-3 col-xs-5 text-right " style="margin-top: 5px">
                <button
                        @click="addMessage"
                        :aria-hidden="postIsEmpty?'true':'false'"
                        :tabindex="postIsEmpty?-1:0"
                        aria-label="Post"
                        role="button"
                        class="btn btn-mm btn-green comments-input-form__send-message-button"
                        :class="{visible:!postIsEmpty}"
                        ref="sendButton"
                >
                    <i class="fa fa-paper-plane" aria-hidden="true"></i> Send
                </button>
            </div>
        </div>
        <div class="row" style="margin-top: 15px" v-if="mediaAttachment || sticker || emotion">
            <div class="col-sm-6">
                <media-preview :item='mediaAttachment'
                               :allow-delete="true"
                               @onDelete="deleteMedia"
                               v-if="mediaAttachment"
                               style="display: inline-block; margin-right: 15px;"></media-preview>
                <span v-if="sticker">
                 <render-sticker
                         :allow-delete="true"
                         @onDelete="deleteSticker"
                         :sticker="sticker"
                         style="width: 70px; height: 70px; display: inline-block;"></render-sticker>
                </span>
                <div v-if="emotion">
                    <entry-emotion
                            :emotion="emotion"
                            :show-delete="true"
                            :show-label="true"
                            :size="36"
                            @delete="deleteEmotion"
                    ></entry-emotion>
                </div>
            </div>
            <div class="col-sm-6"></div>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import RenderSticker from "../../add_media/stickers/render_sticker_directive/RenderSticker";
    import AddMediaStickerEmotionPanel from "shared/comments/AddMediaStickerEmotionPanel";
    import EmotionsFactory from "../../diary_page/common/blog_entry_emotions/emotionsFactory";
    import EntryEmotion from "../../blogolog/emotion_directive/EntryEmotion";
    import _ from "lodash";
    import CatchFocusMixin from "../../../../vue/mixins/CatchFocusMixin";

    export default {
        name: "CommentsInput",
        components: {EntryEmotion, AddMediaStickerEmotionPanel, RenderSticker},
        mixins:[CatchFocusMixin],
        props: {
            allowAddMedia: {
                type: Boolean,
                default: true,
            },
            allowAddSticker: {
                type: Boolean,
                default: true,
            },
            allowAddEmotion: {
                type: Boolean,
                default: false,
            },
            mediaAttachment: {
                type: Object,
                required: false,
            },
            replyTo: {
                type: Object,
                required: false,
            },
            emitTypingEvents: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                text: '',
                sticker: undefined,
                emotion: undefined,

                popupOpened: false,
            };
        },
        computed: {
            keymap() {
                return {
                    'alt+s': this.addSticker,
                };
            },
            postIsEmpty() {
                return !(this.text || this.sticker || this.mediaAttachment || this.emotion);
            },
        },
        methods: {
            setFocus() {
                this.$_catchFocus(this.$refs.commentsInput);
            },
            setFocusOnSendButton(){
                this.$_catchFocus(this.$refs.sendButton);
            },
            addMessage() {
                if (this.postIsEmpty) {
                    return;
                }
                const messageData = {
                    text: this.text,
                };
                if (this.sticker) {
                    const stickersFactory = Vue.getAngularModule('stickersFactory');
                    messageData['sticker'] = stickersFactory.get_sticker_code(this.sticker);
                }
                if (this.mediaAttachment) {
                    messageData['media_attachment_id'] = this.mediaAttachment.id;
                }
                if (this.replyTo) {
                    messageData['reply_to_comment_id'] = this.replyTo.commentId;
                }
                if (this.emotion) {
                    messageData['emotion'] = this.emotion;
                }
                this.$emit('addMessage', messageData);
            },
            addMedia() {
                this.$emit('addMedia');
            },
            deleteMedia() {
                this.$emit('deleteMedia');
            },
            uploadPicture() {
                this.$emit('uploadPicture');
            },
            recordAudio() {
                this.$emit('recordAudio');
            },
            addSticker() {
                if (this.popupOpened) {
                    return;
                }
                this.popupOpened = true;
                const stickersFactory = Vue.getAngularModule('stickersFactory');
                stickersFactory.select_sticker().then((sticker) => {
                    this.sticker = sticker;
                }, () => {
                })
                    .finally(() => {
                        this.popupOpened = false;
                    });
            },
            deleteSticker() {
                this.sticker = undefined;
            },
            addEmotion() {
                if (this.popupOpened) {
                    return;
                }
                this.popupOpened = true;
                new EmotionsFactory().showAddEmotionsPopup()
                    .then(emotion => {
                        this.emotion = emotion.code;
                    }, () => {
                    })
                    .finally(() => {
                        this.popupOpened = false;
                    });
            },
            deleteEmotion() {
                this.emotion = undefined;
            },
            clearFields() {
                this.sticker = undefined;
                this.text = '';
                this.emotion = undefined;
            },
            cancelReply() {
                this.$emit('cancelReply');
            },
            $_initTypingHandler() {
                this.$_stopTypingTime = 5000;

                this.$_continueTypingPingFunction = _.throttle(() => {
                    this.$emit('typingStateChanged', 'continue');
                }, 2500, {leading: false});

                this.$_stopTypingScheduler = _.debounce(() => {
                    this.$emit('typingStateChanged', 'stop');
                    this.$_isTyping = false;
                    this.$_continueTypingPingFunction.cancel();
                }, this.$_stopTypingTime);

            },
            $_handleTypingInputChanged(n) {
                if (n && !this.$_isTyping) {
                    this.$_isTyping = true;
                    this.$emit('typingStateChanged', 'start');
                    return;
                }

                if (!n) {
                    this.$_isTyping = false;
                    this.$_stopTypingScheduler.flush();
                    return;
                }

                if (n && this.$_isTyping) {
                    this.$_stopTypingScheduler();
                    this.$_continueTypingPingFunction();
                }
            }
        },
        watch: {
            text(n, o) {
                if (this.emitTypingEvents) {
                    this.$_handleTypingInputChanged(n);
                }
            }
        },
        mounted() {
            if (this.emitTypingEvents) {
                this.$_initTypingHandler();
            }
        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";

  .comments-input-form {
    font-size: 0;

    &__reply-to-message {
      font-size: $default-font-size;
    }

    &__input-textarea {
      transition: height 0.3s;
      height: 44px;

      &:active, &:focus, &.active {
        height: 140px;
      }
    }

    &__send-message-button {
      transition: opacity 0.3s ease;
      opacity: 0;
      pointer-events: none;

      &:hover {
        opacity: 0;
      }

      &.visible {
        pointer-events: all;
        opacity: 1;
      }
    }

    &__mobile-form {
      z-index: 1;
      @media (min-width: $screen-sm-min) {
        display: none;
      }
    }

    &__desktop-form {
      @media (max-width: $screen-xs-max) {
        display: none;
      }

    }
  }

  #comments-input {
    overflow: hidden;
  }

</style>
