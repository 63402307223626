var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "event-detail-info-add-to-calendar" },
    [
      _c("div", { staticClass: "margin-5-top margin-5-bottom" }, [
        _vm.membershipStatus.added_to_calendar
          ? _c("span", [
              _c("i", {
                staticClass:
                  "vertical-middle fa fa-check-circle-o color-grass-green event-detail-info-add-to-calendar__status-icon",
              }),
              _vm._v(" "),
              _c("span", { staticClass: "vertical-middle" }, [
                _vm.behalfMode
                  ? _c("span", [
                      _vm._v(
                        "\n                    This event is added to\n                    "
                      ),
                      _c(
                        "strong",
                        [
                          _c("simple-link", {
                            attrs: {
                              target: {
                                link: _vm.calendarLink,
                                name: this.calendarOwner.name + "'s calendar",
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _c("span", [
                      _c("span", [
                        _vm._v(
                          "This event is added to\n                        "
                        ),
                        _c(
                          "strong",
                          [
                            _c("simple-link", {
                              attrs: {
                                target: {
                                  name: "your calendar",
                                  link: _vm.calendarLink,
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
              ]),
            ])
          : _c("span", [
              _c("i", {
                staticClass:
                  "vertical-middle fa fa-times color-red event-detail-info-add-to-calendar__status-icon",
              }),
              _vm._v(" "),
              _c("span", { staticClass: "vertical-middle" }, [
                _vm.behalfMode
                  ? _c("span", [
                      _vm._v(
                        "\n                    This event is not added to\n                    "
                      ),
                      _c(
                        "strong",
                        [
                          _c("simple-link", {
                            attrs: {
                              target: {
                                link: _vm.calendarLink,
                                name: this.calendarOwner.name + "'s calendar",
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n                    This event is not added to\n                    "
                      ),
                      _c(
                        "strong",
                        [
                          _c("simple-link", {
                            attrs: {
                              target: {
                                name: "your calendar",
                                link: _vm.calendarLink,
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
              ]),
            ]),
      ]),
      _vm._v(" "),
      !_vm.membershipStatus.added_to_calendar
        ? _c(
            "div",
            {
              staticClass:
                "event-detail-info-add-to-calendar__action-icon inline-block",
              on: { click: _vm.onJoinEventClick },
            },
            [_vm._m(0), _vm._v(" "), _vm._m(1)]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.membershipStatus.added_to_calendar && _vm.allowRemoveFromCalendar
        ? _c(
            "div",
            {
              staticClass:
                "event-detail-info-add-to-calendar__action-icon inline-block",
              on: { click: _vm.onRemoveEventClick },
            },
            [_vm._m(2), _vm._v(" "), _vm._m(3)]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("event-detail-page-period-selector-popup", {
        ref: "join-type-selector",
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c("i", {
        staticClass:
          "fa fa-calendar-plus-o color-grass-green event-detail-info-add-to-calendar__action-icon__icon",
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c("strong", [_vm._v("Add")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c("i", {
        staticClass:
          "fa fa-calendar-minus-o color-red event-detail-info-add-to-calendar__action-icon__icon",
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c("span", [_vm._v("Remove")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }