/**
 * Created by orion on 02.05.17.
 */
let angular = require('angular');


function noTouchMove() {
    function onTouchMove(e) {
        e.preventDefault();
    }

    return {
        restrict: 'A',
        link: function (scope, element, attributes) {
            element.bind("touchmove", onTouchMove);
            // Raven.setExtraContext({ element:element[0] });
            // Raven.captureMessage('move');
        }
    };
}

angular.module('mmApp.shared.noTouchMove', [])
    .directive('noTouchMove', noTouchMove);

export default 'mmApp.shared.noTouchMove'