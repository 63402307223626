<template>
    <selector-popup
            ref="popup"
            :header="header"
            :items="items"
            @selected="onSelected"
    >
        <div slot="descriptionTop" v-if="info">
            {{ info }}
        </div>
        <template slot="invisibleForms" v-if="webcamStateDetected">
            <form enctype="multipart/form-data">
                <input type="file" ref="fileCapture" name="file" accept="image/*" capture="camera"
                       style="display:none;" @change="showCropper($event)"/>
                <input type="file" ref="fileUpload" name="file" accept="image/*"
                       style="display:none;" @change="showCropper($event)"/>
            </form>
            <avatar-crop-popup ref="AvatarCropPopup"></avatar-crop-popup>
            <picture-from-webcam-component ref="PictureFromWebcamComponent"></picture-from-webcam-component>
        </template>
        <div slot="footer">
            <button v-if="cancelButton" class="btn btn-mm btn-default" @click="closePopup"><i class="fa fa-times"></i>
                {{ cancelButton }}
            </button>
        </div>
    </selector-popup>
</template>

<script>
    import Vue from 'vue';
    import MobileDetect from 'mobile-detect';
    import Modernizr from '../../3dparty/modernizr-custom';
    import AvatarCropPopup from './AvatarCropPopup';
    import PictureFromWebcamComponent from './PictureFromWebcamComponent.vue';
    import SelectorPopup from '../../../vue/common_components/SelectorPopup/SelectorPopup';

    export default {
        name: 'AddAvatarByCropPopup',
        components: {
            SelectorPopup,
            AvatarCropPopup,
            PictureFromWebcamComponent
        },
        data() {
            return {
                header: '',
                info: '',
                cancelButton: undefined,
                allowAddFromMedia: true,

                allowWebcam: false,
                webcamStateDetected: false,

                rectangle: false,
                stencilProps: false,
                raw: false,
            };
        },
        computed: {
            allowCapture() {
                return Modernizr.capture || new MobileDetect(window.navigator.userAgent).mobile();
            },
            items() {
                if (!this.webcamStateDetected) {
                    return [];
                }
                const items = [];
                if (!this.allowCapture && this.allowWebcam) {
                    items.push({
                        label: 'Capture from<br/> webcam',
                        iconClass: 'fa-camera',
                        action: 'function',
                        function: 'captureByWebcam',
                    });
                }
                if (this.allowCapture) {
                    items.push({
                        label: 'Capture by<br/> device',
                        iconClass: 'fa-camera',
                        action: 'function',
                        noClose: true,
                        function: 'captureByDevice',
                    });
                }
                items.push({
                    label: 'Upload picture<br/> from device',
                    iconClass: 'fa-upload',
                    action: 'function',
                    function: 'uploadFromDevice',
                    noClose: true,
                });
                if (this.allowAddFromMedia) {
                    items.push({
                        label: 'Add picture<br/>from My Media',
                        iconClass: 'fa-folder-o',
                        action: 'function',
                        function: 'getFromLibrary',
                    });
                }
                return items;
            },
        },
        created() {
            function detectWebcam(callback) {
                let md = navigator.mediaDevices;
                if (!md || !md.enumerateDevices) return callback(false);
                md.enumerateDevices().then(devices => {
                    callback(devices.some(device => 'videoinput' === device.kind));
                });
            }

            detectWebcam((hasWebcam) => {
                this.allowWebcam = hasWebcam && window.innerWidth > 500;
                this.webcamStateDetected = true;
            });
        },
        methods: {
            uploadAvatar(avatarBase64value) {
                if (this.raw) {
                    this.resolve(avatarBase64value);
                } else {
                    const avatar = avatarBase64value.split(',')[1];
                    this.resolve(avatar);
                }
            },
            showCropper(event) {
                this.$refs.popup.tmpHide();
                // Reference to the DOM input element
                const input = event.target;
                // Ensure that you have a file before attempting to read it
                if (input.files && input.files[0]) {
                    // create a new FileReader to read this image and convert to base64 format
                    const reader = new FileReader();
                    // Define a callback function to run, when FileReader finishes its job
                    reader.onload = e => {
                        // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                        // Read image as base64 and set to imageData
                        // this.image = e.target.result;
                        try {
                            this.$refs.AvatarCropPopup.show(
                                e.target.result,
                                this.header,
                                this.rectangle,
                                false,
                                this.stencilProps,
                            )
                                .then(({ avatarBase64value }) => {
                                    this.uploadAvatar(avatarBase64value);
                                }, err => {
                                    this.reject();
                                });

                        } catch (e) {
                            console.log(e);
                        }
                    };
                    // Start the reader job - read file as a data url (base64 format)
                    reader.readAsDataURL(input.files[0]);
                }
            },
            captureByWebcam() {
                this.$refs.popup.tmpHide();
                this.$refs.PictureFromWebcamComponent.show()
                    .then(src => {
                        this.$refs.AvatarCropPopup.show(
                            src,
                            this.header,
                            this.rectangle,
                            false,
                            this.stencilProps,
                        )
                            .then(({ avatarBase64value }) => {
                                this.uploadAvatar(avatarBase64value);
                            }, err => {
                                this.reject();
                            });
                    }, reason => {
                        if (reason === 'back') {
                            this.$refs.popup.tmpShow();
                        } else {
                            this.reject('close');
                        }
                    });
            },
            captureByDevice() {
                this.$refs.fileCapture.click();
            },
            uploadFromDevice() {
                this.$refs.fileUpload.click();
            },
            getFromLibrary() {
                this.$refs.popup.tmpHide();
                const addMediaFromFolderFactory = Vue.getAngularModule('addMediaFromFolderFactory');
                const callback = ([mediaItem]) => {
                    const src = mediaItem.image.image_url;
                    this.$refs.AvatarCropPopup.show(
                        src,
                        this.header,
                        this.rectangle,
                        false,
                        this.stencilProps,
                    )
                        .then(({ avatarBase64value }) => {
                            this.uploadAvatar(avatarBase64value);
                        }, err => {
                            this.reject();
                        });
                };
                const cancelCallback = (res) => {
                    this.reject();
                };
                addMediaFromFolderFactory.add_media(callback, 'image', 1, cancelCallback);
            },
            onSelected(item) {
                if (item.action === 'function') {
                    this[item.function]();
                }
            },
            show(header = 'Change Avatar', info = '', cancelButton = undefined, allowAddFromMedia = true, rectangle = false, stencilProps = {}, raw = false) {
                this.header = header;
                this.info = info;
                this.cancelButton = cancelButton;
                this.allowAddFromMedia = allowAddFromMedia;
                this.rectangle = rectangle;
                this.stencilProps = stencilProps;
                this.raw = raw;
                return new Promise((resolve, reject) => {
                    this.$refs.popup.show()
                        .then(item => {
                            if (item.action === 'function') {
                                this[item.function]();
                            }
                        }, reason => {
                            reject(reason);
                        });
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            closePopup() {
                this.$refs.popup.close();
                this.reject('closePopup');
            }
        }
    };
</script>

<style scoped>

</style>
