export default {
    computed: {
        swamMinItemSize() {
            let MIN_SIZE = 130;
            if (this.elWidth > 400) {
                MIN_SIZE = 150;
            }
            if (this.elWidth > 610) {
                MIN_SIZE = 180;
            }
            return MIN_SIZE;
        },
        previewSize() {
            if (this.elWidth > 1000) {
                return 180;
            } else {
                return this.swamRecommendedItemSize;
            }
        },
        swamItemSize() {
            return this.previewSize;
        },
    }
};