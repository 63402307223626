<template>
    <div class="my-stuff-item-my">
        <div class="my-stuff-item-my__image-part">
            <router-link :to="link" v-if="routerNavigation">
                <img :src="imageUrl"
                     class="my-stuff-item-my__content-part__icon" alt="">
            </router-link>
            <a :href="link" v-else>
                <img :src="imageUrl"
                     class="my-stuff-item-my__content-part__icon" alt="">
            </a>
        </div>
        <div class="my-stuff-item-my__content-part">
            <div class="my-stuff-item-my__content-part__text-block">
                <div class="">
                    <router-link :to="link" v-if="routerNavigation">
                        {{name}}
                    </router-link>
                    <a :href="link" class="" v-else>
                        {{name}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MyStuffListMyItem",
        props: {
            link: String,
            name: String,
            imageUrl: String,
            routerNavigation: {
                type: Boolean,
                default: false
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../angular/app/styles/mixins.scss";

    .my-stuff-item-my {
        @include managedUserInList();

        &__image-part {
            padding: 15px;
        }

        &__content-part {
            @media all and (max-width: 549px) {
                text-align: center;
            }
            @media all and (max-width: 549px) {
                font-size: 30px;
            }
            @media all and (min-width: 550px) {
                font-size: 40px;
            }
        }
    }
</style>