import Vue from 'vue';

const EVENTS_BUS_EVENTS = {
    EVENT_EXPANDED: 'event_expanded',
    EVENT_FOCUSED: 'event_focused',
    EVENT_UNFOCUSED: 'event_unfocused',
    CREATE_EVENT_IN_DATE_AND_HOUR: 'create_event_in_date_and_hour',
    HOUR_SWITCHED_TO_CREATE_MODE: 'hour_switched_to_create_mode',
    LOAD_EVENTS_WEEK: 'load_events_week',
};

const eventsBus = new Vue({});

export {eventsBus, EVENTS_BUS_EVENTS};
