<template>
    <div class="">
        <div v-if="commentsEnabled">
            <comments-list-infinite-scroll
                    :target-id="eventId"
                    :comments-resource="commentsResource"
                    :comments-additional-params="commentsAdditionalParams"
                    :allow-add="allowAdd"
                    :allow-like="true"
                    :allow-delete="isAdmin"
                    :allow-delete-own="true"
                    :allow-reply-expand="true"

                    @onCommentSent="onCommentSent"
                    @onCommentRemoved="onCommentRemoved"

                    :initial-reply-to="initialReplyTo"
            ></comments-list-infinite-scroll>
        </div>

        <div class="alert alert-warning" v-if="commentsBlockedDueViewerIsNotMember">
            <div class="" v-if="!statusData">
                Sorry you can’t access Chats in this Event as you are not a member of this Event.
            </div>
            <div class="" v-else>

                <div class="" v-if="statusData.status==='NOT_COMMUNITY_MEMBER'">
                    <div class="alert alert-warning">
                        Sorry you can’t access Chats in this Event as you are not a member of this Group Event's
                        Community.
                    </div>
                </div>

                <div class="" v-if="statusData.status==='NOT_GROUP_MEMBER'">
                    <div class="event-join-form">
                        <div v-if="!statusData.is_join_request_pending">
                            <div class=""><span>Sorry you can’t access Chats in this Event as you are not a member of this Event’s Group <strong><simple-link
                                    :target="statusData.group"></simple-link></strong></span>
                                <span v-if="hasPermission('join-groups')">Request to join this Group to get access to this Event.</span>
                            </div>
                            <div class="text-center margin-15-top" v-if="hasPermission('join-groups')">
                                <button class="btn btn-mm" @click="sendGroupJoinRequest">Request to join</button>
                            </div>
                        </div>
                        <div v-else>
                            <div class="">
                                <span>Sorry you can’t access Chats in this Event as you are not a member of this Event’s Group <strong><simple-link
                                        :target="statusData.group"></simple-link></strong></span>
                                <br>
                                <span>You will join Group after approval. We will notify you.</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="" v-if="statusData.status==='NOT_CIRCLE_MEMBER'">
                    <div class="event-join-form">
                        <div v-if="!statusData.is_join_request_pending">
                            <div class=""><span>Sorry you can’t access Chats in this Event as you are not a member of the Event owner’s Circle</span>
                                <span v-if="hasPermission('add-friends')">Request to join <simple-link
                                        :target="statusData.circle_owner"></simple-link> Circle and access this Event</span>
                            </div>
                            <div class="text-center margin-15-top" v-if="hasPermission('add-friends')">
                                <button class="btn btn-mm" @click="sendCircleJoinRequest">Request to join</button>
                            </div>
                        </div>
                        <div v-else>
                            <div class="">
                                <span>Sorry you can’t access Chats in this Event as you are not a member of the Event owner's Circle</span><br>
                                <span>You will join Circle after approval.</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="" v-if="statusData.status==='PRIVATE_EVENT'">
                    <div class="event-join-form">
                        <div v-if="!statusData.is_join_request_pending">
                            <div class=""><span>Sorry you can’t access Chats in this Event as you are not invited to this event.</span>
                            </div>
                            <div class="text-center margin-15-top" v-if="hasPermission('join-events')">
                                <button class="btn btn-mm" @click="onJoinEventClick">Request access</button>
                            </div>
                        </div>
                        <div v-else>
                            <div class="">
                                <span>Sorry you can’t access Chats in this Event as you are not invited to this event.</span><br>
                                <span>You will get access after approval.</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <email-role-category-popup ref="email-role-category-popup"></email-role-category-popup>
        <event-detail-page-join-form-period-selector-popup
                ref="join-type-selector"></event-detail-page-join-form-period-selector-popup>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {VISUAL_DATE_FORMAT} from "../new_calendar/config";
    import EventDetailSubPageMixin from "./EventDetailSubPageMixin";
    import joinRequestsMixin from "./joinRequestsMixin";
    import EventDetailPageJoinMixin from "./EventDetailPageJoinMixin";
    import EventDetailPageJoinFormPeriodSelectorPopup from './EventDetailPagePeriodSelectorPopup.vue';
    import CommentsInfiniteScrollResourceFactory from "shared/comments/resource_infinite_scroll_factory";
    import {CurrentUserClass} from "shared/CurrentUser";
    import CommentsListInfiniteScroll from "shared/comments/CommentsListInfiniteScroll";
    import ActivityReplyToSupportMixin from "../../../../vue/mixins/ActivityReplyToSupportMixin";

    export default {
        name: "EventDetailPageComments",
        components: {CommentsListInfiniteScroll, EventDetailPageJoinFormPeriodSelectorPopup},
        mixins: [joinRequestsMixin, EventDetailPageJoinMixin, EventDetailSubPageMixin, ActivityReplyToSupportMixin],
        data() {
            return {
                statusData: undefined,
                commentsBlockedDueViewerIsNotMember: false,

                commentsEnabled: false,
            };
        },
        props: {
            eventId: [String, Number],
            date: String,
            calendarOwnerId: String,
            calendarOwnerName: String,
        },
        computed: {
            commentsResource() {
                const $http = Vue.getAngularModule('$http');
                return CommentsInfiniteScrollResourceFactory('event_comments_infinite_scroll')($http);
            },
            commentsAdditionalParams() {
                return {date: this.dateMoment.format(VISUAL_DATE_FORMAT)};
            },
            allowAdd() {
                return new CurrentUserClass().has_permission('post-comments');
            },
            dateMoment() {
                return this.$parent.dateMoment;
            },
            commentsAllowed() {
                return this.membershipStatus.viewer_has_access_to_event;
            },
            joinerId() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                return CurrentUser.id;
            },
        },
        methods: {
            hasPermission() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                return CurrentUser.has_permission();
            },
            onCommentSent() {
                this.$emit('increaseCommentsCounter');
            },
            onCommentRemoved() {
                this.$emit('decreaseCommentsCounter');
            },
            eventJoinedListener() {
                this.commentsBlockedDueViewerIsNotMember = false;
                this.commentsEnabled = true;
            },
        },
        mounted() {
            if (this.commentsAllowed) {
                this.commentsEnabled = true;
            } else {
                this.commentsBlockedDueViewerIsNotMember = true;
                let $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                this.checkJoinAbility(() => {
                    this.commentsBlockedDueViewerIsNotMember = false;
                    this.commentsEnabled = true;
                })
                    .finally($rootScope.hide_dimmer);
            }
        },
        beforeDestroy() {
        }
    };
</script>

<style scoped>

</style>