<template>
    <modal v-model="opened" :transition='0' :backdrop="false">
        <div slot="header">
            <a id="close_popup" @click="closePopup"></a>
            <h3 class="modal-title">{{header}}</h3>
        </div>
        <div class="select-menu-container center">
            <div class="select-menu-item" v-for="action in actions" :key="action.label">
                <div class="panel panel-primary">
                    <div class="panel-heading shortcuts" @click="select(action.action)">
                        <h3 class="panel-title" v-html="action.label"></h3>
                        <i class="select-menu-item__main-icon" :class="action.classes" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <button class="btn btn-mm" @click="closePopup">Cancel</button>
        </div>
    </modal>
</template>

<script>
    const DEFAULT_HEADER = 'What would you like to do?';
    // actions:[
    // {classes:'fa fa-share', label:'Share', action:'share'}
    // ]
    export default {
        name: "SelectFromOptionsPopup",
        data() {
            return {
                opened: false,
                header: '',
                actions: []
            };
        },
        methods: {
            closePopup() {
                this.opened = false;
                this.reject();
            },
            show(actions, header = DEFAULT_HEADER) {
                this.actions = actions;
                this.header = header;
                this.opened = true;
                return new Promise((res, rej) => {
                    this.resolve = res;
                    this.reject = rej;
                });
            },
            select(action) {
                this.opened = false;
                this.resolve(action);
            }
        }
    };
</script>

<style scoped>

</style>