import { render, staticRenderFns } from "./WikiReceiptTree.vue?vue&type=template&id=647e3b53&scoped=true&"
import script from "./WikiReceiptTree.vue?vue&type=script&lang=js&"
export * from "./WikiReceiptTree.vue?vue&type=script&lang=js&"
import style0 from "./WikiReceiptTree.vue?vue&type=style&index=0&id=647e3b53&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "647e3b53",
  null
  
)

export default component.exports