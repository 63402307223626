var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _c(
      "a",
      {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.tooltip,
            expression: "tooltip",
          },
        ],
        ref: "link",
        style: _vm.linkStyle,
        attrs: { tabindex: _vm.tabIndex, href: _vm.targetLink },
        on: {
          click: _vm.onClick,
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.onClick.apply(null, arguments)
          },
        },
      },
      [
        _vm._t("default", function () {
          return [
            _vm._v(_vm._s(_vm._f("cutName")(_vm.target.name, _vm.cutName))),
          ]
        }),
      ],
      2
    ),
    _vm.postfixLabel
      ? _c("span", [_vm._v(_vm._s(_vm.postfixLabel))])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }