<template>
    <span v-if="wikiSnapshotAfter">
        <span>deleted Wiki </span>
        <strong>
            #{{ wikiSnapshotAfter.id }} "{{ wikiSnapshotAfter.name }}"
        </strong>
    </span>
</template>

<script>
    import SnapshotLogMixin from "./common/SnapshotLogMixin";

    export default {
        name: "WikiDeleted",
        mixins:[SnapshotLogMixin],
    };
</script>