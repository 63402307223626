var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "render-activity-post-component" }, [
    _vm.isOneAttachmentPost
      ? _c(
          "div",
          [
            _c("render-post", {
              attrs: { post: _vm.simplePseudoPost, "max-media-height": "60vh" },
            }),
          ],
          1
        )
      : _c("div", [
          _vm.activityPost.sticker
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-12 text-center" },
                  [
                    _c("render-sticker", {
                      attrs: { sticker: _vm.activityPost.sticker },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.activityPost.text
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "render-activity-post-component__text-container",
                    },
                    [
                      !_vm.activityPost.is_header
                        ? _c("p", {
                            staticStyle: {
                              "text-align": "left",
                              "font-size": "18px",
                              "white-space": "pre-line",
                            },
                            domProps: { innerHTML: _vm._s(_vm.linkedText) },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.activityPost.is_header
                        ? _c("p", {
                            staticStyle: {
                              "font-size": "24px",
                              "white-space": "pre-line",
                            },
                            domProps: { innerHTML: _vm._s(_vm.linkedText) },
                          })
                        : _vm._e(),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.activityPost.preview_url || _vm.notDisplayedItemsCount
            ? _c(
                "div",
                {
                  staticClass:
                    "render-activity-post-component__image-container pointer",
                  attrs: { tabindex: "0", role: "button" },
                  on: {
                    click: _vm.showMediaItems,
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.showMediaItems.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "img-responsive",
                    staticStyle: { "max-height": "500px", margin: "auto" },
                    attrs: {
                      alt: "Entry Preview.",
                      src: _vm.activityPost.preview_url,
                    },
                  }),
                  _vm._v(" "),
                  _vm.activityPost.preview_url && _vm.notDisplayedItemsCount
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "render-activity-post-component__media-counter",
                          attrs: {
                            "aria-label": `${_vm.notDisplayedItemsCount} more images`,
                          },
                        },
                        [
                          _vm._v(
                            "+" +
                              _vm._s(_vm.notDisplayedItemsCount) +
                              "\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }