<template>
    <nav aria-label="Header navigation panel" style="z-index: 3; position: relative;">
        <div class="container" style="position: relative; z-index: 201;">
            <site-header-search ref="search"></site-header-search>
        </div>
        <div id="site-header-component" class="site-header-component hidden-print" v-if="show">
            <a :href="homeLink"
               @click="onHomeLinkClick"
               class="site-header-component__site_logo_link"
               :aria-label="ariaLabelActivity" role="link"
               @blur="helloSaid"
            >
                <img class="site-header-component__site_logo_image" src="/static/images/mm_icon.svg" alt="MultiMe logo"
                     aria-label="">
                <site-header-notifications-counter :value="counters.activity"
                                                   v-if="counters.activity>0"></site-header-notifications-counter>
            </a>

            <a href="javascript:;" class="site-header-component__nav-item"
               role="link"
               @click.stop.prevent="onSearch"
               aria-label="Search."
               v-if="authenticated">
                <div class="">
                    <span class="site-header-component__nav-icon search"></span>
                </div>
                <div class="site-header-component__nav-item__text">
                    <span>Search</span>
                </div>
            </a>

            <a :href="stuffLink" class="site-header-component__nav-item"
               aria-label="My Stuff."
               v-if="authenticated">
                <div class="">
                    <span class="site-header-component__nav-icon stuff"
                          aria-label="My Stuff"></span>
                </div>
                <div class="site-header-component__nav-item__text">
                    <span>My Stuff</span>
                </div>
            </a>

            <a class="site-header-component__nav-item"
               :href="chatsLink"
               @click="onChatsClick"
               :aria-label="ariaLabelChats"
               v-if="authenticated">
                <div class="">
                    <span class="site-header-component__nav-icon conversations" aria-label="Chats"></span>
                </div>
                <div class="site-header-component__nav-item__text">
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;Chats</span>
                </div>
                <site-header-notifications-counter :value="counters.chats"
                                                   v-if="counters.chats>0"></site-header-notifications-counter>
            </a>

            <a class="site-header-component__nav-item"
               :href="insightsLink"
               aria-label="Insights."
               v-if="showInsightsLink">
                <div class="">
                    <span class="site-header-component__nav-icon insights" aria-label="Insights">
                    </span>
                </div>
                <div class="site-header-component__nav-item__text">
                    <span>Insights</span>
                </div>
            </a>

            <a class="site-header-component__nav-item"
               :href="requestsLink"
               :aria-label="ariaLabelRequests"
               v-if="authenticated">
                <div class="">
                    <a class="site-header-component__nav-icon requests" aria-label="Alerts"></a>
                </div>
                <div class="site-header-component__nav-item__text">
                    <a>Alerts</a>
                </div>
                <site-header-notifications-counter
                        :value="counters.tasks"
                        v-if="counters.tasks>0"></site-header-notifications-counter>
            </a>

            <span
                    tabindex="0"
                    @keydown.enter="dropDownOpened=true"
                    role="menu"
                    aria-label="My Account Dropdown menu."
                    id="avatar-dropdown"
                    v-if="authenticated"
                    style="z-index: 1;"
                    class="site-header-component__user_avatar_link pointer"
            >
                <dropdown menu-right v-model="dropDownOpened" style="z-index: 200">
                    <img aria-label="" :src="avatarUrl" id="avatar_header_image"
                         alt="Your avatar"
                         class="site-header-component__user_avatar"/>
                    <template slot="dropdown">
                        <li class="site-header-component__avatar-menu__link" aria-label="About Me" role="menuitem"
                            aria-owns="avatar-dropdown">
                            <a :href="profileStoryLink" @click="onProfileStoryClick"
                               @keydown.shift.tab="dropDownOpened=false">
                                <i class="fal fa-user"></i> About Me
                            </a>
                        </li>
                        <li class="site-header-component__avatar-menu__link" role="menuitem"
                            aria-owns="avatar-dropdown">
                            <a :href="profileGroupsLink" @click="onProfileGroupsClick">
                                <i class="fal fa-users"></i> My Groups
                            </a>
                        </li>
                        <li class="site-header-component__avatar-menu__link" role="menuitem"
                            aria-owns="avatar-dropdown">
                            <a :href="profileCircleLink" @click="onProfileCircleClick">
                                <i class="fal fa-draw-circle"></i> My Circle
                            </a>
                        </li>
                        <li class="site-header-component__avatar-menu__link" v-if="showMyWikiLink" role="menuitem"
                            aria-owns="avatar-dropdown">
                            <a :href="myWikiLink">
                                <i class="fal fa-user-circle"></i> My Wiki
                            </a>
                        </li>
                        <li class="site-header-component__avatar-menu__link" v-if="showMyCalendarLink" role="menuitem"
                            aria-owns="avatar-dropdown">
                            <a :href="myCalendarLink">
                                <i class="fal fa-calendar-alt"></i> My Calendar
                            </a>
                        </li>
                        <li class="site-header-component__avatar-menu__link" v-if="showMyDiaryLink" role="menuitem"
                            aria-owns="avatar-dropdown">
                            <a :href="myDiaryLink">
                                <i class="fal fa-edit"></i> My Diary
                            </a>
                        </li>
                        <li class="site-header-component__avatar-menu__link" v-if="showMyGoalsLink" role="menuitem"
                            aria-owns="avatar-dropdown">
                            <a :href="myGoalsLink">
                                <i class="fal fa-bullseye-pointer"></i> My {{ "Goals"|customTerm }}
                            </a>
                        </li>
                        <li class="site-header-component__avatar-menu__link" v-if="showMyMediaLink" role="menuitem"
                            aria-owns="avatar-dropdown">
                            <a :href="myMediaLink">
                                <i class="fal fa-photo-video"></i> My Media
                            </a>
                        </li>
                        <li class="site-header-component__avatar-menu__link" role="menuitem"
                            aria-owns="avatar-dropdown">
                            <a :href="profileSettingsLink" @click="onProfileSettingsClick">
                                <i class="fal fa-cog"></i> Settings
                            </a>
                        </li>
                        <li class="site-header-component__avatar-menu__link" role="menuitem" aria-owns="avatar-dropdown"
                        >
                            <a href="/accounts/logout/" @keydown.tab="dropDownOpened=false">
                                <i class="fal fa-sign-out"></i> Log Out
                            </a>
                        </li>
                    </template>
                </dropdown>
        </span>
            <div class="site-header-component__nav-item" v-if="!authenticated&&logInVisible">
                <div class="">
                    <a :href="loginLink"
                       class="site-header-component__nav-icon login"
                       alt="Log in"></a>
                </div>
                <div class="site-header-component__nav-item__text">
                    <a :href="loginLink">Log in</a>
                </div>
            </div>

        </div>
    </nav>
</template>

<script>
    import Vue from 'vue';
    import {DjangoUrls} from 'DjangoUrls';
    import _ from 'lodash';
    import SiteHeaderSearch from './SiteHeaderSearch.vue';
    import SiteHeaderNotificationsCounter from './SiteHeaderNotificationsCounter.vue';
    import {CurrentUserClass} from "../shared/CurrentUser";
    import {DASHBOARD_ITEMS} from "../dashboard/dashboard";


    const CurrentUser = new CurrentUserClass();
    const SHOW_INSIGHT_LINK = CurrentUser.can_see_insights_link();


    export default {
        name: "SiteHeader",
        components: {
            SiteHeaderSearch,
            SiteHeaderNotificationsCounter,
        },
        data() {
            return {
                dropDownOpened: false,
                profileRouter: undefined,
                chatsRouter: undefined,
                avatarUrl: undefined,
                show: true,
                counters: {
                    activity: 0,
                    chats: 0,
                    tasks: 0,

                },
                authenticated: false,
                saidHello: false,
            };
        },
        computed: {
            ariaLabelChats() {
                if (this.counters.chats > 0) {
                    return `Chats: ${this.counters.chats} new messages.`;
                } else {
                    return 'Chats.';
                }
            },
            ariaLabelActivity() {
                let basicMessage = '';
                if (!this.saidHello) {
                    basicMessage = `Hello, ${CurrentUser.first_name_or_username}.`;
                }
                if (this.counters.activity > 0) {
                    return `${basicMessage} Recent activity: ${this.counters.activity} new notifications.`;
                } else {
                    return `${basicMessage} Recent activity.`;
                }
            },
            ariaLabelRequests() {
                if (this.counters.tasks > 0) {
                    return `Alerts: ${this.counters.tasks} new alerts.`;
                } else {
                    return 'Alerts.';
                }
            },

            showInsightsLink() {
                return this.authenticated && SHOW_INSIGHT_LINK;
            },
            dashboardConfig() {
                return CurrentUser.dashboard_config;
            },
            showMyCalendarLink() {
                return !this.dashboardConfig[DASHBOARD_ITEMS.CALENDAR];
            },
            showMyGoalsLink() {
                return !this.dashboardConfig[DASHBOARD_ITEMS.GOALS];
            },
            showMyDiaryLink() {
                return !this.dashboardConfig[DASHBOARD_ITEMS.DIARY];
            },
            showMyMediaLink() {
                return !this.dashboardConfig[DASHBOARD_ITEMS.MEDIA];
            },
            showMyWikiLink() {
                return !this.dashboardConfig[DASHBOARD_ITEMS.WIKI];
            },
            myDiaryLink() {
                return DjangoUrls['blog_detail'](CurrentUser.blog_id);
            },
            myWikiLink() {
                return DjangoUrls['users_wiki'](CurrentUser.wiki_id);
            },
            myGoalsLink() {
                return DjangoUrls['projects']();
            },
            myMediaLink() {
                return DjangoUrls['users_media'](CurrentUser.name);
            },
            myCalendarLink() {
                return DjangoUrls['users_calendar']('my');
            },
            requestsLink() {
                return DjangoUrls['tasks']();
            },
            insightsLink() {
                return DjangoUrls['stats_page']();
            },
            chatsLink() {
                return DjangoUrls['my_chats']();
            },
            stuffLink() {
                return DjangoUrls['user_dashboard']();
            },
            homeLink() {
                return DjangoUrls['user_detail']('me') + 'feed';
            },
            loginLink() {
                return DjangoUrls['auth_login']();
            },
            profileStoryLink() {
                return DjangoUrls['user_detail']('me') + 'profile';
            },
            profileGroupsLink() {
                return DjangoUrls['user_detail']('me') + 'groups-and-communities';
            },
            profileCircleLink() {
                return DjangoUrls['user_detail']('me') + 'circle';
            },
            profileSettingsLink() {
                return DjangoUrls['user_detail']('me') + 'settings';
            },
            logInVisible() {
                const uri = document.location.href;
                const NO_LOGIN_MARKERS = ['/registration/login/', '/accounts/login/', '/accounts/logout/', '/invitation/code/'];
                for (let marker of NO_LOGIN_MARKERS) {
                    if (uri.indexOf(marker) !== -1) {
                        return false;
                    }
                }
                return true;
            },
        },
        methods: {
            toggleHeaderVisibility(show) {
                this.show = show;
            },
            onSearch() {
                this.$refs['search'].show();
            },
            helloSaid() {
                this.$nextTick(() => {
                    this.saidHello = true;
                });
            },
            updateCounters() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                const UserProfileResource = Vue.getAngularModule('UserProfileResource');
                const run_on_feed_page = window.location.href.indexOf('/users/me/feed') !== -1;
                UserProfileResource.get_my_header_counters(CurrentUser.id, run_on_feed_page)
                    .then(resp => {
                        _.merge(this.counters, resp.data);
                    }, err => {
                        Vue.notifications.error('Can\'t load counters');
                    });
            },
            updateConversationCounter() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                const UserProfileResource = Vue.getAngularModule('UserProfileResource');
                UserProfileResource.get_unread_conversations_count(CurrentUser.id)
                    .then(resp => {
                        this.counters.chats = resp.data;
                    }, err => {
                        Vue.notifications.error('Can\'t load counter');
                    });
            },
            updateActivityCounter() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                const UserProfileResource = Vue.getAngularModule('UserProfileResource');
                UserProfileResource.get_unread_activity_count(CurrentUser.id)
                    .then(resp => {
                        this.counters.activity = resp.data;
                    }, err => {
                        Vue.notifications.error('Can\'t load counter');
                    });
            },
            updateTasksCounter() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                const UserProfileResource = Vue.getAngularModule('UserProfileResource');
                UserProfileResource.get_unread_tasks_count(CurrentUser.id)
                    .then(resp => {
                        this.counters.tasks = resp.data;
                    }, err => {
                        Vue.notifications.error('Can\'t load counter');
                    });
            },

            // listeners
            onConversationsCounterChanged(e, val) {
                this.counters.chats = val;
            },
            onConversationsCounterIncreased() {
                this.counters.chats++;
            },
            onTasksCounterChanged(e, val) {
                this.counters.tasks = val;
            },
            onUserAvatarChanged(e, val) {
                this.avatarUrl = val;
            },
            onProfilePageRouterDelegated(e, val) {
                this.profileRouter = val;
            },
            onProfilePageRouterUnload(e) {
                this.profileRouter = undefined;
            },
            onChatsPageRouterDelegated(e, val) {
                this.chatsRouter = val;
            },
            onChatsPageRouterUnload(e) {
                this.chatsRouter = undefined;
            },
            bindCountersListeners() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.$on('update_conversations_counters', this.updateConversationCounter);
                $rootScope.$on('update_activity_counters', this.updateActivityCounter);
                $rootScope.$on('update_tasks_counters', this.updateTasksCounter);
                $rootScope.$on('conversations_unread_count_changed', this.onConversationsCounterChanged);
                $rootScope.$on('task_count_changed', this.onTasksCounterChanged);
                $rootScope.$on('conversations_unread_count_increased', this.onConversationsCounterIncreased);
                $rootScope.$on('my_avatar_changed', this.onUserAvatarChanged);
                $rootScope.$on('delegate_profile_page_router', this.onProfilePageRouterDelegated);
                $rootScope.$on('unload_profile_page_router', this.onProfilePageRouterUnload);
                $rootScope.$on('delegate_chats_page_router', this.onChatsPageRouterDelegated);
                $rootScope.$on('unload_chats_page_router', this.onChatsPageRouterUnload);
            },
            // end listeners
            onProfileStoryClick(e) {
                if (this.profileRouter && !e.ctrlKey) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.dropDownOpened = false;
                    this.profileRouter.push({name: 'myDetailPage-profile'});
                }
            },
            onChatsClick(e) {
                if (this.chatsRouter && !e.ctrlKey) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.chatsRouter.push({name: 'myChatsListPage'});
                }
            },
            onProfileGroupsClick(e) {
                if (this.profileRouter && !e.ctrlKey) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.dropDownOpened = false;
                    this.profileRouter.push({name: 'myDetailPage-communities'});
                }
            },
            onProfileCircleClick(e) {
                if (this.profileRouter && !e.ctrlKey) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.dropDownOpened = false;
                    this.profileRouter.push({name: 'myDetailPage-circle'});
                }
            },
            onHomeLinkClick(e) {
                if (this.profileRouter && !e.ctrlKey) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.dropDownOpened = false;
                    const currentRoute = this.profileRouter.resolve({name: 'myDetailPage-feed'});
                    if (currentRoute.href === document.location.pathname) {
                        const $rootScope = Vue.getAngularModule('$rootScope');
                        $rootScope.$apply(() => {
                            $rootScope.show_dimmer();
                        });

                        setTimeout(() => {
                            document.location.reload();
                        }, 10);
                    }
                    this.profileRouter.push({name: 'myDetailPage-feed'});
                }
            },
            onProfileSettingsClick(e) {
                if (this.profileRouter && !e.ctrlKey) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.dropDownOpened = false;
                    this.profileRouter.push({name: 'myDetailPage-settings'});
                }
            },
            enableAriaHiddenElements() {
                window.document.querySelector('#site-footer')?.setAttribute('aria-hidden', 'false');
                window.document.querySelector('#vue-help-panel')?.setAttribute('aria-hidden', 'false');
            }
        },
        created() {
            const CurrentUser = Vue.getAngularModule('CurrentUser');
            this.avatarUrl = CurrentUser.avatar;
            if (CurrentUser.is_authenticated()) {
                this.authenticated = true;
                this.updateCounters();
                this.bindCountersListeners();
            }
        },
        mounted() {
            this.$nextTick(() => {
                setTimeout(() => {
                    this.enableAriaHiddenElements();
                }, 200);
            });
        }
    };
</script>

<style scoped lang="scss">
  @import "../styles/const";

  $small_mobile_icon_size: 35px;
  $mobile_icon_size: 40px;
  $icon_size: 55px;

  .site-header-component {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row;
    flex-wrap: wrap;

    position: relative;

    &__avatar-menu {
      &__link {
        font-size: 21px;

        & > a > i {
          width: 28px;
          text-align: center;
        }

        & > a:hover {
          background-image: none !important;
          background-color: $lightgray;
        }
      }
    }

    a {
      text-decoration: none !important;
      color: #333;
    }

    &__site_logo_link, &__user_avatar_link {
      width: $small_mobile_icon_size;
      height: $small_mobile_icon_size;
      position: relative;
      @media all and (max-width: 350px) {
        width: $small_mobile_icon_size;
        height: $small_mobile_icon_size;
      }
      @media all and (min-width: 351px) and (max-width: 500px) {
        width: $mobile_icon_size;
        height: $mobile_icon_size;
      }
      @media all and (min-width: 501px) {
        width: $icon_size + 10px;
        height: $icon_size + 10px;
      }
      vertical-align: middle;
      display: inline-block;
    }

    &__site_logo_image {
      width: $small_mobile_icon_size;
      height: $small_mobile_icon_size;
      @media all and (max-width: 350px) {
        width: $small_mobile_icon_size;
        height: $small_mobile_icon_size;
      }
      @media all and (min-width: 351px) and (max-width: 500px) {
        width: $mobile_icon_size;
        height: $mobile_icon_size;
      }
      @media all and (min-width: 501px) {
        width: $icon_size + 10px;
        height: $icon_size + 10px;
      }
      vertical-align: middle;
    }

    &__nav-icon {
      position: relative;
      width: $small_mobile_icon_size;
      height: $small_mobile_icon_size;
      @media all and (max-width: 350px) {
        width: $small_mobile_icon_size;
        height: $small_mobile_icon_size;
      }
      @media all and (min-width: 351px) and (max-width: 500px) {
        width: $mobile_icon_size;
        height: $mobile_icon_size;
      }
      @media all and (min-width: 501px) {
        width: $icon_size;
        height: $icon_size;
      }

      background-position: center;
      background-repeat: no-repeat;
      vertical-align: middle;
      display: block;
      margin: auto;

      &.stuff {
        background-image: url(./IMG_0647.png);
        background-size: 89%;
        background-position: center;
      }

      &.search {
        background-image: url(./IMG_0646.png);
        background-size: 96%;
      }

      &.conversations {
        background-image: url(./151515.png);
        background-size: 91%;

      }

      &.requests {
        background-image: url(./np_bell_70899_484848.png);
        background-size: 102%;
      }

      &.insights {
        text-align: center;
        color: #616061;
        font-size: 30px;
        background-image: url(./insights-custom-2.png);
        background-size: 92%;

        @media (max-width: 350px) {
          font-size: 26px;
        }
        @media (min-width: 351px) and (max-width: 500px) {
          font-size: 30px;
        }
        @media (min-width: 501px) {
          font-size: 41px;
        }

        &__icon {
          display: block;
          vertical-align: top;
        }
      }

      &.login {
        background-image: url(./np_login_1187014_23BD4F_2.png);
        background-size: 95%;
      }

    }

    &__user_avatar {
      display: inline-block;
      border: 3px solid $gray;
      width: $small_mobile_icon_size;
      height: $small_mobile_icon_size;

      @media all and (max-width: 350px) {
        width: $small_mobile_icon_size;
        height: $small_mobile_icon_size;
      }
      @media all and (min-width: 351px) and (max-width: 500px) {
        width: $mobile_icon_size;
        height: $mobile_icon_size;
      }
      @media all and (min-width: 501px) {
        width: $icon_size + 10px;
        height: $icon_size + 10px;
      }
      border-radius: 50%;
    }

    &__site_logo_link {
      &:focus, &:active {
        transform: scale(1.05, 1.05);
      }
    }

    &__user_avatar_link {
      &:focus, &:active {
        transform: scale(1.05, 1.05);
      }
    }

    &__nav-item {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      text-align: center;

      &:focus, &:active {
        transform: scale(1.05, 1.05);
      }

      &__text {
        margin: auto;
        text-align: center;
        font-weight: bolder;
        color: #333;
        display: none;
        @media all and (max-width: 350px) {
          display: none;
        }
        @media all and (min-width: 351px) and (max-width: 500px) {
          display: none;
        }
        @media all and (min-width: 501px) {
          font-size: 14px;
          display: inline-block;
        }
        //display: none;
      }
    }

  }

</style>
