<template>
    <simple-wrapper-link :to="to" :style="containerStyle">
        <div class="item_preview__avatar" :style="avatarStyle" :class="colorClass">
            <div v-if="unreadMark" :style="unreadMarkStyle"
                 class="item_preview__unread_mark">
                <i>NEW</i>
            </div>
            <div v-if="deletable"
                 tabindex="0"
                 role="button"
                 aria-label="Delete"
                 @click.stop.prevent="onDeleteClick"
                 @keydown.enter.stop.prevent="onDeleteClick"
                 class="item_preview__delete_mark"
            >
                <i class="fal fa-trash" aria-hidden="true"></i>
            </div>
            <div class="item_preview__super-avatar" v-if="superAvatar" :style="superAvatarStyle"></div>
            <div class="item_preview__audio-caption-container" v-if="audioCaption">
                <audio-toggle :audio-caption="audioCaption"></audio-toggle>
            </div>
        </div>
        <div class="item_preview__name">{{ name | cutName(cutNameLength) }}</div>
    </simple-wrapper-link>
</template>

<script>
    import mediaContentUtils from "../../angular/app/view_media/mediaContentUtils";
    import SimpleWrapperLink from "./simple_wrapper_link/SimpleWrapperLink";

    export default {
        name: "RectangleStuffPreview",
        components: {SimpleWrapperLink},
        props: {
            name: String,
            to: String,
            audioCaption: {
                type: Object,
                required: false
            },
            cutNameLength: {
                type: Number,
                default: 40
            },
            size: {
                type: Number,
                default: 186,
            },
            avatar: {
                type: String,
            },
            superAvatar: {
                type: String,
                required: false
            },
            mediaContentSamples: {
                type: Array,
                required: false
            },
            preferAvatar: {
                type: Boolean,
                default: false
            },
            colorClass: {
                type: String,
                required: false,
            },
            unreadMark: {
                type: Boolean,
                default: false
            },
            deletable: {
                type: Boolean,
                default: false
            },
            isRouterLink: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            containerStyle() {
                return {
                    'width': `${this.size}px`,
                };
            },
            unreadMarkStyle() {
                if (this.superAvatar) {
                    return {'top': '45px'};
                } else {
                    return {'top': '3px'};
                }
            },
            superAvatarStyle() {
                if (!this.superAvatar) {
                    return;
                }
                return {
                    'background-image': `url('${this.superAvatar}')`,
                };
            },
            avatarStyle() {
                let style = {};
                if (this.avatar && (this.preferAvatar || (!this.mediaContentSamples || !this.mediaContentSamples.length))) {
                    const isDefault = this.avatar.indexOf('/static/') !== -1;
                    if (isDefault) {
                        style = new mediaContentUtils().get_background_style_contain(this.avatar);
                    } else {
                        style = new mediaContentUtils().get_background_style(this.avatar, true);
                    }

                } else {
                    style = new mediaContentUtils().generate_group_background(this.avatar, this.mediaContentSamples);
                }
                style['width'] = `${this.size}px`;
                style['height'] = `${this.size / 1.33}px`;
                return style;
            }
        },
        methods: {
            onDeleteClick() {
                this.$emit('delete');
            }
        }
    };
</script>

<style scoped lang="scss">
  @import '../../angular/app/styles/const';

  .item_preview {
    vertical-align: top;
    display: inline-block;
    margin-top: 15px;
    cursor: pointer;
    overflow: hidden;

    $cs: &;

    &:hover, &:focus {
      filter: brightness(1.2);

      //#{$cs}__avatar {
      //  &.folder {
      //    border-color: $color-folder-dark;
      //  }
      //}
    }

    &__name {
      text-align: center;
      font-size: 15px;
      font-weight: bolder;
    }

    &__audio-caption-container {
      position: absolute;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      text-align: center;
      font-size: 30px;
      line-height: 45px;
      width: 45px;
      height: 45px;
      background-color: rgba(255, 255, 255, 0.5);
    }

    &__super-avatar {
      border-radius: 50%;
      position: absolute;
      left: 3px;
      top: 3px;
      width: 35px;
      height: 35px;
      background-size: cover;
      background-repeat: no-repeat;
      border: solid 2px #fff;
    }

    &__unread_mark {
      position: absolute;
      left: 3px;
      width: 35px;
      height: 35px;

      border-radius: 50%;
      color: white;
      text-align: center;
      font-size: 10px;
      line-height: 34px;
      background-color: $online-green;
      border: solid 2px #fff;
    }

    &__delete_mark {
      position: absolute;
      right: 3px;
      width: 35px;
      height: 35px;

      border-radius: 50%;
      color: white;
      text-align: center;
      font-size: 20px;
      line-height: 34px;
      background-color: $red;
      border: solid 2px #fff;
    }

    &__avatar {

      background-position: center;
      background-repeat: no-repeat;
      border: solid 4px;
      position: relative;
      transition: border-color 0.2s ease-out;
      border-radius: 3px;
      background-color: white;

      &.story {
        border-color: $color-story;
      }

      &.folder {
        border-color: $color-folder;
      }

      &.wiki {
        border-color: $color-wiki;
      }

      &.group_activity_post, &.activity_post {
        border-color: $color-group;
      }

      &.blog_entry {
        border-color: $color-diary;
      }
    }
  }
</style>