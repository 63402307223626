<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="header"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ header }}</h3>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div>
                    <span>Name of event</span>
                    <input ref="nameInput" v-model="eventName" maxlength="100" type="text" class="form-control"
                           placeholder="Name of event"
                           required/>
                </div>
            </div>
        </div>
        <div class="row margin-15-top" v-show="advancedMode">
            <div class="col-sm-12">
                <div>
                    <span>Tell us a bit more about your event</span>
                    <textarea v-model="eventDescription" maxlength="3000" type="text" class="form-control"
                              placeholder="Event description"></textarea>
                </div>
            </div>
        </div>
        <div class="row margin-15-top">
            <div class="col-sm-12">
                <!--<a href="javascript:;" @click="addAvatar" v-show="!mediaForAvatar">Add avatar</a>-->

                <media-preview :no-border="true" :item="mediaForAvatar" :allow-delete="true" v-if="mediaForAvatar"
                               @onDelete="cleanAvatar" :show-name="true"></media-preview>
                <media-preview-add-request :no-border="true" @click.native="addAvatar" text="+ Picture"
                                           icon-class="fa-picture-o"
                                           v-if="!mediaForAvatar"></media-preview-add-request>

                <media-preview-add-request :no-border="true" text="+ Location" @click.native="addLocation"
                                           icon-class="fa-map-marker" v-if="!locationData"></media-preview-add-request>
                <media-preview :no-border="true" @onDelete="cleanLocation" :show-name="true" :item="locationPreviewSrc"
                               :text='locationCaption' v-if="locationData" :allow-delete="true"></media-preview>

                <media-preview :no-border="true" :item="mediaForAudioCaption" :allow-delete="true"
                               v-if="mediaForAudioCaption"
                               @onDelete="cleanAudioCaption" :show-name="true"></media-preview>
                <media-preview-add-request :no-border="true" text="+ Audio"
                                           icon-class="fa-volume-down"
                                           @click.native="addAudio"
                                           v-if="!mediaForAudioCaption"></media-preview-add-request>

            </div>
        </div>
        <div class="row margin-15-top enlarged-calendar-14">
            <div class="col-sm-6">
                <form class="form">
                    <span>Start date</span>
                    <datepicker v-model="startDate"
                                @keydown.native.delete="startDateClear"
                                :disabled-dates="disabledDatesStart"
                                :format="customFormatter"
                                :width="pickerWidth"
                                input-class="create-event-date-picker"
                                :bootstrap-styling="true"
                                :typeable="false"
                                :highlighted="highlightedDates"
                                ref="start-date-picker"
                    >
                        <div slot="afterDateInput" class="input-group-btn"
                             @click.stop.prevent="toggleStartDateCalendar">
                            <btn class="dropdown-toggle create-event-date-picker"><i
                                    class="glyphicon glyphicon-calendar"></i></btn>
                        </div>
                    </datepicker>
                </form>
            </div>
            <div class="col-sm-6">
                <form class="form">
                    <span>Finish date</span>
                    <datepicker v-model="endDate"
                                @keydown.native.delete="endDateClear"
                                :disabled-dates="disabledDatesEnd"
                                :disabled="endDateDisabled"
                                :format="customFormatter"
                                :width="pickerWidth"
                                :highlighted="highlightedDates"
                                input-class="create-event-date-picker"
                                :bootstrap-styling="true"
                                :typeable="false"
                                ref="end-date-picker"
                    >
                        <div slot="afterDateInput" class="input-group-btn"
                             @click.stop.prevent="toggleEndDateCalendar">
                            <btn class="dropdown-toggle create-event-date-picker"
                                 :disabled="endDateDisabled"><i
                                    class="glyphicon glyphicon-calendar"></i></btn>
                        </div>
                    </datepicker>
                </form>
            </div>
        </div>

        <event-time-editor
                v-if="!advancedTimeScheduleEnabled"
                class="margin-15-top"
                v-model="time"
                :disabled="wholeDay"
        ></event-time-editor>
        <div class="row margin-15-top" v-show="!advancedMode">
            <div class="col-sm-12">
                <vue-switch-with-label v-model="wholeDay" name="whole-day"
                                       label="All day"></vue-switch-with-label>
            </div>
        </div>
        <div class="row margin-15-top" v-show="!advancedMode">
            <div class="col-sm-12">
                <a href="javascript:;" class="pointer" @click="enableAdvancedMode">Advanced Options</a>
            </div>
        </div>
        <div class="alert alert-info margin-15-top" v-if="allowToggleAdvancedTimeSchedule&&advancedTimeScheduleEnabled">
            Advanced Time schedule enabled. <strong class="pointer" @click="showAdvancedTimeSchedulePopup"><i
                class="fa fa-pencil"></i>Edit schedule</strong>
        </div>
        <div class="row margin-15-top" v-show="!advancedMode" v-if="showAddToEventSwitch">
            <div class="col-sm-12">
                <span>Settings</span>
                <div class="margin-5-top">
                    <vue-switch-with-label v-model="addUserToEvent"
                                           name="addUserToEvent"
                                           :disabled="!privateEvent"
                                           :label="addToEventLabel"></vue-switch-with-label>
                </div>
            </div>
        </div>

        <div class="row margin-15-top" v-show="advancedMode">
            <div class="" :class="{'col-sm-6':periodicEvent,'col-sm-12':!periodicEvent}">
                <span>Settings</span>
                <div class="margin-5-top">
                    <vue-switch-with-label v-model="wholeDay" name="whole-day"
                                           label="All day"></vue-switch-with-label>
                </div>

                <div class="margin-5-top">
                    <vue-switch-with-label v-model="periodicEvent" name="whole-day" :disabled="repeatDisabled"
                                           label="Repeat Event"></vue-switch-with-label>
                </div>
                <div class="margin-5-top">
                    <vue-switch-with-label v-model="privateEvent" name="private-event"
                                           :label="privateLabel" :help-text="privateHelpText"></vue-switch-with-label>
                </div>
                <div class="margin-5-top">
                    <vue-switch-with-label v-model="addUserToEvent"
                                           name="addUserToEvent"
                                           v-if="showAddToEventSwitch"
                                           :disabled="!privateEvent"
                                           :label="addToEventLabel"></vue-switch-with-label>
                </div>
                <div class="margin-5-top">
                    <vue-switch-with-label v-model="approveMessages" name="approve-messages"
                                           label="Approve messages"></vue-switch-with-label>
                </div>
            </div>
            <div class="col-sm-6" v-show="periodicEvent">
                <span>Repeat</span>
                <select class="form-control inline-block" v-model="repeatOption" @change="onRepeatOptionChange">
                    <option :value="repeatOption.code" v-for="repeatOption in repeatOptions">{{ repeatOption.label }}
                    </option>
                </select>
                <span v-show="showSkipRepeatPeriodSelector">Number:</span>
                <select
                        v-show="showSkipRepeatPeriodSelector"
                        name="skipRepeatPeriodSelector"
                        id="skipRepeatPeriodSelector"
                        v-model="skipRepeatPeriod"
                        class="form-control"
                >
                    <option :value="option.value" v-for="option in skipPeriodOptions">{{ option.text }}</option>
                </select>
                <span v-show="showDaysOfWeekSelector">Days of the week:</span>
                <multi-select
                        v-show="showDaysOfWeekSelector"
                        v-model="selectedDaysOfWeek"
                        :options="multiDaysOptions"
                        :block="true"
                        :collapse-selected="true"/>

                <div class="margin-5-top">
                    <vue-switch-with-label @input="toggleAdvancedTimeScheduleEnabled"
                                           :value="advancedTimeScheduleEnabled"
                                           name="advanced-schedule"
                                           v-if="allowToggleAdvancedTimeSchedule"
                                           label="Advanced Schedule"
                                           help-text="Allows set Start and Finish time separately for each day of week. Available only for 'Days of week' repeat type."
                    ></vue-switch-with-label>
                </div>

                <span>End on</span>
                <form action="" class="form-inline">
                    <div class="row">
                        <div class="col-xs-5 text-left" style="padding-right: 0">
                            <select name="" id="" class="form-control" v-model="periodicEventTermination">
                                <option value="never">Never</option>
                                <option value="after">After</option>
                                <option value="onDate">On Date</option>
                            </select>
                        </div>
                        <div class="col-xs-3 text-center" style="padding: 0"
                             v-show="periodicEventTermination==='after'">
                            <select class="form-control" v-model="periodicEventAmountBeforeTermination"
                            >
                                <option :value="afterAmountOption" v-for="afterAmountOption in afterAmountOptions">
                                    {{ afterAmountOption }}
                                </option>
                            </select>
                        </div>
                        <div class="col-xs-4" style="padding-left: 0" v-show="periodicEventTermination==='after'">
                            <select class="form-control" style="padding: 4px" v-model="periodicEventTerminationUnit"
                            >
                                <option :value="repeatUnit.code" v-for="repeatUnit in repeatUnits">
                                    {{ repeatUnit.label }}
                                </option>
                            </select>
                        </div>
                        <div class="col-xs-7" v-show="periodicEventTermination==='onDate'">
                            <datepicker v-model="periodEndDate"
                                        :highlighted="highlightedDates"
                                        @keydown.native.delete="periodEndDateClear"
                                        ref="period-end-picker"
                                        :disabled-dates="disabledDatesEnd"
                                        :format="customFormatter"
                                        :width="pickerWidth"
                                        input-class="create-event-date-picker"
                                        :bootstrap-styling="true"
                                        :typeable="false"
                            >
                                <div slot="afterDateInput" class="input-group-btn"
                                     @click.stop.prevent="toggleEndPeriodDateCalendar">
                                    <btn class="dropdown-toggle create-event-date-picker"><i
                                            class="glyphicon glyphicon-calendar"></i></btn>
                                </div>
                            </datepicker>
                        </div>
                    </div>


                    <span v-show="calculatedEndDate">
                        End Date: {{ calculatedEndDateFormatted }}
                    </span>
                </form>

            </div>
        </div>
        <advanced-time-schedule-popup ref="advanced-time-schedule-popup"></advanced-time-schedule-popup>
        <div slot="footer">
            <button class="btn btn-mm" @click="close">Cancel</button>
            <button class="btn btn-mm btn-green save-button" :disabled="!allowSave" @click="saveEvent">
                Create
            </button>
        </div>
    </basic-modal>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    import _ from 'lodash';
    import Vue from 'vue';

    const VISUAL_DATE_FORMAT = "DD/MM/YYYY";

    import moment from 'moment';
    import ScreenWidthAwareMixin from "../../../../vue/mixins/ScreenWidthAwareMixin";
    import AddMediaAudioLocationInPopupMixin from "../../../../vue/mixins/AddMediaAudioLocationInPopupMixin";
    import CreateEditPopupPeriodMixin from "./CreateEditPopupPeriodMixin";
    import {TIME_ZONE} from "../new_calendar/config";
    import {EVENT_PERIODS} from "../new_calendar/common";
    import EventTimeEditor from "./EventTimeEditor.vue";
    import AdvancedTimeSchedulePopup from "./AdvancedTimeSchedulePopup.vue";
    import PopupPromiseMixin from "../../../../vue/mixins/PopupPromiseMixin";
    import BasicModal from "../../../../vue/common_components/BasicModal";


    const DEFAULT_POPUP_CONFIG = {
        header: 'Create New event',
        privateLabel: 'Make private (invite only)',
        showAddToEventSwitch: false,
        username: undefined,
        privateHelpText: 'Making the event not private means others in my Circle can see it',
    };

    const DEFAULT_TIME = {
        start: {
            hour: 12,
            minute: '00',
        },
        end: {
            hour: 13,
            minute: '00',
        }
    };

    const DEFAULT_CONFIG = {
        startDate: undefined,
        endDate: undefined,
        wholeDay: false,
        periodicEvent: false,
        privateEvent: false,
        approveMessages: false,
        addUserToEvent: true,
        periodicEventTermination: 'never',
        periodEndDate: undefined,

        mediaForAvatar: undefined,
        mediaForAudioCaption: undefined,
        locationData: undefined,


        time: _.merge({}, DEFAULT_TIME),
        selectedDaysOfWeek: [],
        advancedTimeSchedule: {},

        eventName: '',
        eventDescription: '',

        advancedMode: false,

        advancedTimeScheduleEnabled: false,

    };

    export default {
        name: "CreateEventPopup",
        mixins: [PopupPromiseMixin, ScreenWidthAwareMixin, CreateEditPopupPeriodMixin, AddMediaAudioLocationInPopupMixin],
        components: {BasicModal, Datepicker, EventTimeEditor, AdvancedTimeSchedulePopup},
        data() {
            return _.merge({}, DEFAULT_CONFIG, DEFAULT_POPUP_CONFIG);
        },
        computed: {
            firstFocusEl() {
                return this.$refs.nameInput;
            },
            allowToggleAdvancedTimeSchedule() {
                return this.periodicEvent && !this.wholeDay && this.repeatOption === EVENT_PERIODS.WEEKLY_BY_DAY_NUMBERS && this.selectedDaysOfWeek.length > 1;
            },
            addToEventLabel() {
                if (!this.showAddToEventSwitch) {
                    return;
                }
                return `Add ${this.username} to event`;
            },
            pickerWidth() {
                return Math.min(340, this.windowWidth - 40);
            },

            startDateMoment() {
                if (this.startDate)
                    return moment(this.startDate);
            },

            endDateMoment() {
                if (this.endDate)
                    return moment(this.endDate);
            },
            isStartDateValid() {
                if (this.startDateMoment) {
                    return this.startDateMoment.isValid();
                } else {
                    return false;
                }

            },
            isEndDateValid() {
                if (this.endDateMoment) {
                    return this.endDateMoment.isValid();
                } else {
                    return false;
                }

            },
            repeatDisabled() {
                return !this.startDate || !this.isStartDateValid;
            },
            endDateDisabled() {
                return this.periodicEvent;
            },
            allowSave() {
                if (!(this.isStartDateValid && this.eventName)) {
                    return false;
                }
                if (!this.periodicEvent) {
                    if (!this.isEndDateValid) {
                        return false;
                    }
                }
                if (this.periodicEvent) {
                    if (this.periodicEventTermination !== 'never') {
                        if (this.periodicEventTermination === 'onDate') {
                            if (!this.isPeriodEndDateValid) {
                                return false;
                            }
                        } else {
                            if (!this.calculatedEndDate) {
                                return false;
                            }
                        }
                    }
                    if (this.repeatOption === EVENT_PERIODS.WEEKLY_BY_DAY_NUMBERS && this.selectedDaysOfWeek.length === 0) {
                        return false;
                    }
                    if (this.repeatOption === EVENT_PERIODS.ONCE_EVERY_N_WEEKS && !this.skipRepeatPeriod) {
                        return false;
                    }
                }
                if (!this.wholeDay) {
                    const startHour = parseInt(this.time.start.hour);
                    const endHour = parseInt(this.time.end.hour);
                    if (endHour < startHour) {
                        return false;
                    }
                    if (endHour === startHour) {
                        const startMinute = parseInt(this.time.start.minute);
                        const endMinute = parseInt(this.time.end.minute);
                        if (endMinute < (startMinute + 1)) {
                            return false;
                        }
                    }
                }
                return true;
            },
            limitStartFrom() {
                //return moment().format(VISUAL_DATE_FORMAT);
            },
            disabledDatesStart() {
                if (this.periodicEvent) {
                    return;
                }
                if (this.endDate) {
                    return {
                        from: this.endDate
                    };
                }
            },
            disabledDatesEnd() {
                if (this.startDate) {
                    return {to: this.startDate};
                }
            },

            highlightedDates() {
                return {
                    dates: [moment.tz(TIME_ZONE).toDate()],
                    includeDisabled: true,
                };
            },
        },
        methods: {
            onRepeatOptionChange(option) {
                this.$nextTick(() => {
                    if (this.repeatOption === EVENT_PERIODS.ONCE_EVERY_N_WEEKS) {
                        this.skipRepeatPeriod = 2;
                    }
                });
            },
            getInitialAdvancedTimeScheduleConfig() {
                let res = {};
                for (let d of this.selectedDaysOfWeek) {
                    res[d] = {};
                    if (this.advancedTimeSchedule[d] !== undefined) {
                        res[d].start = Object.assign({}, this.advancedTimeSchedule[d].start);
                        res[d].end = Object.assign({}, this.advancedTimeSchedule[d].end);
                    } else {
                        res[d].start = Object.assign({}, this.time.start);
                        res[d].end = Object.assign({}, this.time.end);
                    }

                }
                return res;
            },
            showAdvancedTimeSchedulePopup() {
                this.tmpHide();
                this.$refs['advanced-time-schedule-popup'].open(this.getInitialAdvancedTimeScheduleConfig())
                    .then(res => {
                        this.advancedTimeScheduleEnabled = true;
                        this.advancedTimeSchedule = res;
                        this.tmpShow();
                    }, rej => {
                        this.tmpShow();
                    });
            },
            toggleAdvancedTimeScheduleEnabled(val) {
                if (val) {
                    this.showAdvancedTimeSchedulePopup();
                } else {
                    this.advancedTimeScheduleEnabled = val;
                    this.advancedTimeSchedule = {};
                }
            },
            startDateClear(e) {
                this.startDate = undefined;
            },
            endDateClear(e) {
                this.endDate = undefined;
            },
            periodEndDateClear(e) {
                this.periodEndDate = undefined;
            },
            customFormatter(date) {
                return moment(date).format(VISUAL_DATE_FORMAT);
            },
            enableAdvancedMode() {
                this.advancedMode = true;
            },
            resetData() {
                for (let key in DEFAULT_CONFIG) {
                    this[key] = DEFAULT_CONFIG[key];
                }
                this.time = Object.assign({}, DEFAULT_TIME);
                this.selectedDaysOfWeek = [];
                DEFAULT_CONFIG.selectedDaysOfWeek = [];
            },
            setInitial(initial = {}, popupConfig = {}) {
                this.resetData();
                for (let key in initial) {
                    this[key] = initial[key];
                }
                if (initial.startHour) {
                    this.time.start.hour = initial.startHour;
                }
                if (initial.endHour) {
                    this.time.end.hour = initial.endHour;
                }
                const mergedPopupConfig = _.merge({}, DEFAULT_POPUP_CONFIG, popupConfig);
                mergedPopupConfig.privateHelpText = (popupConfig.privateHelpText === undefined) ? DEFAULT_POPUP_CONFIG.privateHelpText : undefined;
                for (let key in mergedPopupConfig) {
                    this[key] = mergedPopupConfig[key];
                }
            },
            showEndPicker() {
                if (this.endDateDisabled) {
                    return;
                }
                this.$refs['end-picker'].toggle();
            },
            saveEvent() {
                const res = {
                    name: this.eventName,
                    description: this.eventDescription,
                    start_date: this.startDateMoment.format(VISUAL_DATE_FORMAT),
                    whole_day: this.wholeDay,
                    is_periodic: this.periodicEvent,
                    is_private: this.privateEvent,
                    approve_messages: this.approveMessages,
                };

                if (this.showAddToEventSwitch) {
                    res['add_owner_to_event'] = this.addUserToEvent;
                }
                if (this.mediaForAvatar) {
                    res['media_for_avatar_id'] = this.mediaForAvatar.pk;
                }
                if (this.mediaForAudioCaption) {
                    res['media_for_audio_caption_id'] = this.mediaForAudioCaption.pk;
                }
                if (this.locationData) {
                    res['location_data'] = this.locationData;
                }
                if (this.endDate && !this.periodicEvent) {
                    res.end_date = this.endDateMoment.format(VISUAL_DATE_FORMAT);
                }
                if (!this.wholeDay) {
                    res.start_time = `${this.time.start.hour}:${this.time.start.minute}`;
                    res.end_time = `${this.time.end.hour}:${this.time.end.minute}`;
                    if (this.advancedTimeScheduleEnabled && Object.keys(this.advancedTimeSchedule).length > 0) {
                        res.advanced_time_schedule = this.advancedTimeSchedule;
                        res.advanced_time_schedule_enabled = true;
                    } else {
                        res.advanced_time_schedule_enabled = false;
                    }
                } else {
                    res.advanced_time_schedule_enabled = false;
                }
                if (this.periodicEvent) {
                    res.repeat_type = this.repeatOption;
                    res.days_of_week = this.selectedDaysOfWeek;
                    if (this.repeatOption === EVENT_PERIODS.ONCE_EVERY_N_WEEKS) {
                        res['skip_repeat_period'] = this.skipRepeatPeriod;
                    }
                    if (this.periodicEventTermination !== 'never') {
                        if (this.periodicEventTermination === 'onDate') {
                            res.period_end_date = moment(this.periodEndDate).format(VISUAL_DATE_FORMAT);
                        } else {
                            res.period_end_date = this.calculatedEndDateFormatted;
                        }
                    }
                }
                this.save(res);
            },
            toggleStartDateCalendar() {
                this.$refs['start-date-picker'].showCalendar();
            },
            toggleEndDateCalendar() {
                this.$refs['end-date-picker'].showCalendar();
            },
            toggleEndPeriodDateCalendar() {
                this.$refs['period-end-picker'].showCalendar();
            },
        },
        watch: {
            startDate(n) {
                if (n && !this.endDate) {
                    this.endDate = this.startDate;
                    // this.startDate = n;
                }
            },

        }
    };
</script>

<style>
    .create-event-date-picker {
        background-image: unset;
    }

    .create-event-date-picker:not(:disabled) {
        background-color: white !important;
    }


</style>