var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "event-detail-page" },
        [
          _c("vue-simple-header", [
            _c(
              "span",
              { attrs: { slot: "headerTitle" }, slot: "headerTitle" },
              [
                _vm._v(_vm._s(_vm.event.event_data.name) + "\n            "),
                _c("audio-toggle", {
                  attrs: {
                    "audio-caption": _vm.event.event_data.audio_caption,
                  },
                }),
                _vm._v(" "),
                _vm.isAdmin
                  ? _c("i", {
                      staticClass: "fa fa-pencil pointer",
                      on: { click: _vm.editEventData },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              { attrs: { slot: "headerLabel" }, slot: "headerLabel" },
              [
                _vm.event.creator
                  ? _c("span", { staticStyle: { "font-size": "14px" } }, [
                      _vm._v("\n                Event created by "),
                      _c(
                        "strong",
                        [
                          _c("simple-link", {
                            attrs: { target: _vm.event.creator },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.event.group
                        ? _c("span", [
                            _vm._v(" in Group "),
                            _c(
                              "strong",
                              [
                                _c("simple-link", {
                                  attrs: { target: _vm.event.group },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.event.community
                        ? _c("span", [
                            _vm._v(" in Community "),
                            _c(
                              "strong",
                              [
                                _c("simple-link", {
                                  attrs: { target: _vm.event.community },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.event.owner &&
                      _vm.event.owner.id !== _vm.event.creator.id
                        ? _c("span", [
                            _vm._v(" for "),
                            _c(
                              "strong",
                              [
                                _c("simple-link", {
                                  attrs: { target: _vm.event.owner },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(
                        "\n                on " +
                          _vm._s(_vm.event.created_at) +
                          ".\n            "
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { slot: "panelRight" }, slot: "panelRight" },
              [
                _c("vue-folder-button", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showInviteMemberIcon,
                      expression: "showInviteMemberIcon",
                    },
                  ],
                  staticStyle: { "margin-right": "16px" },
                  attrs: {
                    text: "Invite Users",
                    color: "dark-blue",
                    "icon-class": "fa-user-plus",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.inviteMember.apply(null, arguments)
                    },
                  },
                }),
                _vm._v(" "),
                _vm.eventAccessStatusIsOK
                  ? _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "info",
                            params: {
                              date: _vm.date,
                              calendarOwnerId: _vm.calendarOwnerId,
                              calendarOwnerName: _vm.calendarOwnerName,
                            },
                            hash: _vm.$route.hash,
                          },
                        },
                      },
                      [
                        _c("vue-folder-button", {
                          attrs: {
                            text: "Info",
                            "icon-class": "fa-info",
                            active: _vm.openedPage === "info",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.eventAccessStatusIsOK
                  ? _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "goals",
                            params: {
                              date: _vm.date,
                              calendarOwnerId: _vm.calendarOwnerId,
                              calendarOwnerName: _vm.calendarOwnerName,
                            },
                            hash: _vm.$route.hash,
                          },
                        },
                      },
                      [
                        _c("vue-folder-button", {
                          attrs: {
                            text: _vm.linkedGoalsIconText,
                            "custom-icon": "goal",
                            active: _vm.openedPage === "goals",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.eventAccessStatusIsOK
                  ? _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "comments",
                            params: {
                              date: _vm.date,
                              calendarOwnerId: _vm.calendarOwnerId,
                              calendarOwnerName: _vm.calendarOwnerName,
                            },
                            hash: _vm.$route.hash,
                          },
                        },
                      },
                      [
                        _c("vue-folder-button", {
                          attrs: {
                            text: _vm.commentsIconText,
                            "icon-class": "fa-comments-o",
                            active: _vm.openedPage === "comments",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.backUrlAvailableInRouter
                  ? _c(
                      "a",
                      { attrs: { href: _vm.backUrl } },
                      [
                        _c("vue-folder-button", {
                          attrs: {
                            text: "Back",
                            "icon-class": "fa-reply",
                            color: "blue",
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "router-link",
                      { attrs: { to: _vm.backUrl } },
                      [
                        _c("vue-folder-button", {
                          attrs: {
                            text: "Back",
                            "icon-class": "fa-reply",
                            color: "blue",
                          },
                        }),
                      ],
                      1
                    ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.buddyMode
            ? _c("div", { staticClass: "alert alert-info" }, [
                _c("strong", [_vm._v("Attention")]),
                _vm._v(". You are in "),
                _c("strong", [_vm._v(_vm._s(_vm.calendarOwner.name))]),
                _vm._v("'s Calendar now.\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("router-view", {
            on: {
              unsetAttendance: _vm.unsetAttendance,
              setAttendance: _vm.setAttendance,
              onEventJoined: _vm.onEventJoined,
              updateGoalsCounter: _vm.updateGoalsCounter,
              increaseCommentsCounter: _vm.increaseCommentsCounter,
              decreaseCommentsCounter: _vm.decreaseCommentsCounter,
            },
          }),
          _vm._v(" "),
          _c("delete-repeated-event-type-selector-popup", {
            ref: "delete-repeat-event-popup",
          }),
          _vm._v(" "),
          _c("update-repeated-event-type-selector-popup", {
            ref: "update-repeat-event-popup",
          }),
          _vm._v(" "),
          _c("edit-event-data-popup", { ref: "edit-event-data-popup" }),
          _vm._v(" "),
          _c("event-detail-page-period-selector-popup", {
            ref: "join-type-selector",
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }