var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "community-page-alternative-header",
        { attrs: { title: "Our Events", "left-part-size": 10 } },
        [
          _vm.viewerIsAdmin
            ? _c("vue-folder-button", {
                attrs: { "icon-class": "fa-plus" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.createNewEvent()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.allLoaded && !_vm.results.length
        ? _c("div", { staticClass: "alert alert-info" }, [
            _c("span", [
              _vm._v("This Community has not published any events yet"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "margin-15-top" },
        _vm._l(_vm.results, function (event) {
          return _c("group-events-page-event", {
            key: event.id,
            attrs: {
              event: event,
              "router-navigation": true,
              "show-membership-state": _vm.currentUserIsAuthenticated,
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "infinite-scroll",
            rawName: "v-infinite-scroll",
            value: _vm.loadMore,
            expression: "loadMore",
          },
        ],
      }),
      _vm._v(" "),
      _vm.viewerIsAdmin
        ? _c("create-event-popup", { ref: "create-event-popup" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }