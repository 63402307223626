// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./icons/stories.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./icons/collections.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./icons/wiki.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./icons/goals.png");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./icons/media-folder.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, ".left-panel-shared-item{display:inline-block;margin:15px}.left-panel-shared-item__icon{position:relative;width:70px;height:70px;border-radius:50%;background-color:white;background-repeat:no-repeat;background-position:center;background-size:42px 42px;cursor:pointer}.left-panel-shared-item__icon.story{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.left-panel-shared-item__icon.collection{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.left-panel-shared-item__icon.wiki{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.left-panel-shared-item__icon.goal{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.left-panel-shared-item__icon.folder{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}.left-panel-shared-item__icon__counter{width:25px;height:25px;border-radius:50%;color:white;text-align:center;background-color:#03538e;position:absolute;top:-3px;right:-2px;line-height:25px}.left-panel-shared-item__icon__new_marker{position:absolute;top:-3px;left:2px;width:25px;height:25px;line-height:25px;color:#fff;text-align:center;font-size:10px;background-color:#64bd63;border-radius:50%;background-size:cover}.left-panel-shared-item__text{margin-top:5px;color:white;text-align:center}\n", ""]);
// Exports
module.exports = exports;
