<template>
    <div class="">
        <community-page-alternative-header title="Our Stories" :left-part-size="10">
            <vue-folder-button icon-class="fa-plus" @click.native="createNewStory()"
                               v-if="viewerIsAdmin"></vue-folder-button>
        </community-page-alternative-header>
        <div class="alert alert-info" v-if="allLoaded&&!stories.length">
            <span>This Community has not published any stories yet</span>
        </div>
        <div class="">
            <router-link :to="{name:'community-stories-story', params:{storySlug:story.slug}}" v-for="story in stories"
                         :key="story.id"
                         style="text-decoration: none">
                <story-preview :story="story"></story-preview>
            </router-link>
        </div>
        <create-story-popup ref="createStoryPopup"></create-story-popup>
        <div v-infinite-scroll="loadMore"></div>
    </div>
</template>

<script>

    import Vue from 'vue';
    import CommunityPageAlternativeHeader from "./CommunityPageAlternativeHeader";
    import infiniteScrollMixin from "../../../vue/mixins/InfiniteScrollMixin";
    import CreateStoryPopup from "./popups/CreateStoryPopup";
    import StoryPreview from "../stories/story_list_directive/StoryPreview";

    export default {
        name: "CommunityPageStories",
        components: {CommunityPageAlternativeHeader, CreateStoryPopup, StoryPreview},
        mixins: [infiniteScrollMixin],
        data() {
            return {
                limit: 40
            };
        },
        computed: {
            community() {
                return this.$store.getters['communityPage/community'];
            },
            stories() {
                return this.results;
            },
            viewerIsAdmin() {
                return this.$store.getters['communityPage/viewerIsAdmin'];
            }
        },
        methods: {
            createNewStory() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                this.$refs.createStoryPopup.show()
                    .then(data => {
                        const CommunityStoriesResource = Vue.getAngularModule('CommunityStoriesResource');
                        $rootScope.show_dimmer();
                        return CommunityStoriesResource.create_story(this.community.id, data);
                    }, () => new Promise(() => {
                    }))
                    .then(resp => {
                        this.results.unshift(resp.data);
                        Vue.notifications.success('Created');
                    }, err => {
                        Vue.notifications.error('Error');
                    })
                    .finally(
                        $rootScope.hide_dimmer
                    );
            },
            getListEndpointArgs() {
                return [this.community.id];
            },
            getListEndpoint() {
                const CommunityStoriesResource = Vue.getAngularModule('CommunityStoriesResource');
                return CommunityStoriesResource.get_stories;
            }
        }
    };
</script>

<style scoped>

</style>