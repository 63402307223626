<template>
    <div class="">
        <div class="row">
            <div class="col-sm-10  side-padding-5">
                <div class="form-group">
                    <gmap-autocomplete
                            @input.native="onChange"
                            ref="input"
                            class="form-control"
                            placeholder="Location Address"
                            :component-restrictions="autocompleteOptions"
                            @place_changed="setPlace">
                    </gmap-autocomplete>
                    <span v-show="!allowAdd">For security reasons please do not use your private address.</span>
                    <p v-show="notFound" class="help-block">Incorrect address.</p>
                </div>
            </div>
            <div class="col-sm-2 side-padding-5">
                <div class="form-group">
                    <button class="btn btn-primary"
                            @click="search">Search
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 side-padding-5 modal-map-container">
                <GmapMap
                        :center="mapLocation.center"
                        :zoom="mapLocation.zoom"
                        :style="mapStyle"
                        :options="mapOptions"
                        ref="gMap"
                >
                </GmapMap>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 side-padding-5">
                <span v-show="allowAdd" class="help-block ">Use zoom in/zoom out and marker dragging to clarify yours location.</span>
            </div>
        </div>

    </div>
</template>

<script>
    import { gmapApi } from 'vue2-google-maps';
    import { MAP_BUS_ACTIONS, mapsBus } from './mapsBus.js';

    export default {
        name: 'placeFinder',
        data () {
            return {
                query: '',
                addressComponents: undefined,

                autocompleteOptions: { country: ['gb', 'ie'] },
                mapLocation: {
                    center: {
                        lat: 51.520144,
                        lng: -0.085338
                    },
                    zoom: 12
                },
                mapOptions: {
                    disableDefaultUI: true,
                    minZoom: 8,
                    maxZoom: 15,
                    mapTypeControl: false
                },
                placesService: undefined,
                marker: undefined,
                map: undefined,

                allowAdd: false,
                notFound: false,
            };
        },
        computed: {
            mapStyle () {
                return {
                    width: '100%',
                    height: '250px'
                };
            }
        },
        methods: {
            setPlace (setPlaceData) {
                this.query = this.$refs.input.$refs.input.value;
                this.addressComponents = setPlaceData.address_components;
                this.allowAdd = true;
                this.notFound = false;
                this.putViewPortOnMap(setPlaceData.geometry);
            },
            onChange (e) {
                this.notFound = false;
                this.allowAdd = false;
                this.addressComponents = undefined;
                this.query = (e.srcElement || e.target).value;
            },
            putViewPortOnMap (geometry) {
                if (geometry.viewport) {
                    this.map.fitBounds(geometry.viewport);
                }
                this.map.setCenter(geometry.location);
                if (!this.marker) {
                    const google = gmapApi();
                    this.marker = new google.maps.Marker({
                        position: geometry.location,
                        map: this.map,
                        draggable: true,
                        title: 'Drag me!',
                        animation: google.maps.Animation.DROP
                    });
                } else {
                    this.marker.setPosition(geometry.location);
                }
            },
            search () {
                const google = gmapApi();
                this.placesService.textSearch({
                    query: this.query,
                    bounds: new google.maps.LatLngBounds({
                        lat: 59.448672,
                        lng: -13.119778
                    }, {  //Uk
                        lat: 49.666776,
                        lng: 2.387520
                    })
                }, (results, status) => {
                    if (status === 'OK') {
                        const result = results[0];
                        // this.query = this.$refs.input.$refs.input.value = result.formatted_address;
                        this.putViewPortOnMap(result.geometry);
                        this.allowAdd = true;
                        this.notFound = false;
                    } else {
                        this.notFound = true;
                        this.allowAdd = false;
                    }
                });
            },
            getResult () {
                const res = {
                    address: this.query,
                    addressComponents: this.addressComponents,
                    point: this.marker.getPosition().toJSON(),
                    bounds: this.map.getBounds().toJSON(),
                    zoom: this.map.getZoom()
                };
                mapsBus.$emit(MAP_BUS_ACTIONS.RESULT_RESPONSE, res);
            }
        },
        mounted () {
            this.$refs['gMap'].$mapPromise.then((map) => {
                const google = gmapApi();
                this.placesService = new google.maps.places.PlacesService(map);
                this.map = map;
            });
            mapsBus.$on(MAP_BUS_ACTIONS.RESULT_REQUEST, this.getResult);
        },
        beforeDestroy () {
            mapsBus.$off(MAP_BUS_ACTIONS.RESULT_REQUEST, this.getResult);
        },
        watch: {
            allowAdd (n) {
                mapsBus.$emit(MAP_BUS_ACTIONS.TOGGLE_ALLOW_SAVE_ON_ADD_LOCATION_POPUP, n);
            }
        }
    };
</script>

<style scoped>

</style>
