/**UserProfileDeleteAccountResource
 * Created by orion on 6/18/17.
 */
taskRequest.$inject = ['$rootScope', '$log',];

function taskRequest($rootScope, $log) {
    return {
        restrict: 'E',
        scope: {
            task: '=',
            onApprove: '=',
            onReject: '=',
        },
        link: function ($scope, element, attrs) {
            $scope.approve = function () {
                $scope.onApprove($scope.task);
            };
            $scope.reject = function () {
                $scope.onReject($scope.task);
            };
        },
        template: require('./task_request_directive.html')
    };
}

export default taskRequest;

