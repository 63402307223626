/**
 * Created by orion on 6/24/17.
 */
import { DjangoUrls } from 'DjangoUrls';
import axios from 'axios';
import { ACCESS_LINK_TOKEN_HEADER_NAME } from '../common';

WikiSectionResource.$inject = ['$http'];

const PREFIX = `api.v1:wiki_section-`;

function WikiSectionResource($http) {
    return {
        delete_section,
        set_new_section_position,
        edit_section,
        retrieve_subsections,
        retrieve_child_sections,
        retrieve_section_and_slides,
        set_section_parent,
        reset_section_parent,
        unmake_submenu,
        reorder_subsections,
        get_target_sections_for_sections_move,
        move_sections_to_subsection,
        get_target_sections_for_slides_move,
        move_slides_to_section,
    };

    ///////////////////

    function delete_section(section_id, assistant= {}) {
        const url = DjangoUrls[`${PREFIX}detail`](section_id);

        return axios({
            method: 'delete',
            url,
            data: {...assistant }
        });
    }

    function set_new_section_position(section_id, data, assistant= {}) {
        const url = DjangoUrls[`${PREFIX}set-new-section-position`](section_id);

        return axios({
            method: 'post',
            url,
            data: { ...data, ...assistant }
        });
    }

    function edit_section(section_id, data, assistant = {}) {
        const url = DjangoUrls[`${PREFIX}edit-section`](section_id);

        return axios({
            method: 'post',
            url,
            data: { ...data, ...assistant }
        });
    }

    function retrieve_subsections(section_id, access_code) {
        const url = DjangoUrls[`${PREFIX}retrieve-subsections`](section_id);
        const rq_config = {
            method: 'get',
            url,
        };
        if (access_code) {
            rq_config.params = { access_code };
        }
        return axios(rq_config);
    }

    function retrieve_child_sections(section_id, access_code, accessToken) {
        const url = DjangoUrls[`${PREFIX}retrieve-child-sections`](section_id);
        const headers = {};
        if (accessToken) {
            headers[ACCESS_LINK_TOKEN_HEADER_NAME] = accessToken;
        }
        const rq_config = {
            method: 'get',
            url,
            headers,
        };
        if (access_code) {
            rq_config.params = { access_code };
        }
        return axios(rq_config);
    }

    function retrieve_section_and_slides(section_id, access_code, accessToken) {
        const url = DjangoUrls[`${PREFIX}retrieve-section-and-slides`](section_id);
        const headers = {};
        if (accessToken) {
            headers[ACCESS_LINK_TOKEN_HEADER_NAME] = accessToken;
        }
        const rq_config = {
            method: 'get',
            url,
            headers,
        };
        if (access_code) {
            rq_config.params = { access_code };
        }
        return axios(rq_config);
    }

    function set_section_parent(section_id, parent_id, assistant = {}) {
        const url = DjangoUrls[`${PREFIX}set-section-parent`](section_id);

        return axios({
            method: 'post',
            url,
            data: { id: parent_id, ...assistant }
        });
    }

    function reset_section_parent(section_id, assistant = {}) {
        const url = DjangoUrls[`${PREFIX}reset-section-parent`](section_id);

        return axios({
            method: 'post',
            url,
            data: { ...assistant }
        });
    }

    function unmake_submenu(section_id, assistant = {}) {
        const url = DjangoUrls[`${PREFIX}unmake-submenu`](section_id);

        return axios({
            method: 'post',
            url,
            data: { ...assistant }
        });
    }

    function reorder_subsections(section_id, ordered_id_list, assistant = {}) {
        const url = DjangoUrls[`${PREFIX}reorder-subsections`](section_id);

        return axios({
            method: 'post',
            url: url,
            data: {
                id_list: ordered_id_list,
                ...assistant
            }
        });
    }

    function get_target_sections_for_sections_move(sectionId, params) {
        const url = DjangoUrls[`${PREFIX}get-target-sections-for-sections-move`](sectionId);
        return axios({
            method: 'GET',
            url,
            params,
        });
    }

    function move_sections_to_subsection(targetSectionId, sectionsToMoveIdList, assistant = {}) {
        const url = DjangoUrls[`${PREFIX}move-sections-to-subsection`](targetSectionId);
        return axios({
            method: 'POST',
            url,
            data: { id_list: sectionsToMoveIdList, ...assistant },
        });
    }

    function get_target_sections_for_slides_move(sectionId, params) {
        const url = DjangoUrls[`${PREFIX}get-target-sections-for-slides-move`](sectionId);
        return axios({
            method: 'GET',
            url,
            params,
        });
    }

    function move_slides_to_section(targetSectionId, sectionsToMoveIdList, assistant = {}) {
        const url = DjangoUrls[`${PREFIX}move-slides-to-section`](targetSectionId);
        return axios({
            method: 'POST',
            url,
            data: { id_list: sectionsToMoveIdList, ...assistant },
        });
    }
}

export default WikiSectionResource;
