const SIZES = {
    EVENT_HEIGHT: 85,
    TIER_HEIGHT: 85 + 10,
    WHOLE_DAY_EVENT_HEIGHT: 35,
    TIMELINE_HEADER_HEIGHT: 25,
    TIMELINE_SIZE: 2000,
    TIMELINE_SCALE: 2000 / 1440,
    TOP_OFFSET_FOR_LINES: 30,

};
const VISUAL_DATE_FORMAT = "DD/MM/YYYY";
const URL_DATE_FORMAT = "YYYY-MM-DD";
const TIME_ZONE = "Europe/London";

const REGISTERED_EVENTS = [];
const MAIN_EVENT_URL_PREFIX = '/calendar/event/:eventId(\\d+)/';
const MAIN_CALENDAR_URL_PREFIX = '/calendar/:userId(my|\\d+)/';


const COLORS = {
    WHOLE_DAY_EVENTS_PALETTE: ['#9BDAF3', '#D8CCF4', '#FAC2BB', '#F8CD94', '#9EDDDD', '#FBE491', '#FFC4E4', '#C7E59A', '#D0D3D6'],

    getColorForEvent(event) {
        const idToCheck = event.template_event_id || event.id;
        if (REGISTERED_EVENTS.indexOf(idToCheck) === -1) {
            REGISTERED_EVENTS.push(idToCheck);
        }
        let i = REGISTERED_EVENTS.indexOf(idToCheck);
        while (i >= this.WHOLE_DAY_EVENTS_PALETTE.length) {
            i -= this.WHOLE_DAY_EVENTS_PALETTE.length;
        }
        return this.WHOLE_DAY_EVENTS_PALETTE[i];
    }
};

export {SIZES, COLORS, VISUAL_DATE_FORMAT, URL_DATE_FORMAT, MAIN_EVENT_URL_PREFIX, TIME_ZONE, MAIN_CALENDAR_URL_PREFIX};