import Vue from 'vue';
import EventDetailPage from './EventDetailPage.vue';
import VueRouter from 'vue-router';
import {MAIN_EVENT_URL_PREFIX} from '../new_calendar/config';
import EVENT_DETAIL_PAGE_CHILDREN from "./eventDetailPageChildren";

function EventDetailVueController() {
    let vm;
    const router = new VueRouter({
        mode: 'history',
        routes: [
            {
                path: MAIN_EVENT_URL_PREFIX + ':date(\\d{4}-\\d{2}-\\d{2})?/:calendarOwnerId(\\d+)?/:calendarOwnerName?/',
                component: EventDetailPage,
                props: true,
                children: EVENT_DETAIL_PAGE_CHILDREN
            },
            {
                path: '*',
                name: '404',
                component: {
                    template: '<p>Page Not Found</p>'
                },
                meta: {
                    isErrorPage: true
                }
            }
        ]
    });

    function initVue() {
        vm = new Vue({
            el: '#EventDetailVueController',
            router,
            components: {EventDetailPage},
            template: `
                <router-view></router-view>`
        });
    }

    initVue();
}

export default EventDetailVueController;