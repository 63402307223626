<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="header"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ createMode ? 'Create' : 'Update' }} {{ 'Goal' |customTerm }}</h3>
        </div>
        <div class="row" v-if="forUserForcedUsername">
            <div class="col-sm-12">
                <div class="alert alert-info">
                    <strong>Attention!</strong> You are creating a {{ 'Goal'|customTerm }} for <strong>{{ forUserForcedUsername }}</strong>.
                    You will be the admin, <strong>{{ forUserForcedUsername }}</strong> will be added as member.
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div>
                    <span>{{ goalNameLabel }}:</span>
                    <input ref="nameInput" v-model="goalName" maxlength="100" type="text" class="form-control"
                           :placeholder="goalNameLabel" :disabled="saving"
                           required/>
                </div>
            </div>
        </div>
        <div class="row margin-5-top">
            <div class="col-sm-12">
                <div>
                    <span>{{ 'Goal'| customTerm }} Category:</span>
                    <select v-model="selectedCategory" name="" id="" class="form-control">
                        <option :value="category.id" :key="category.id" v-for="category in availableCategories"
                                :disabled="saving">
                            {{ category.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row" v-if="!descriptionExpanded">
            <div class="col-sm-12" style="margin: 15px 0">
                <a href="#" role="button" @click.stop.prevent="descriptionExpanded=true">Add description</a>
            </div>
        </div>
        <div class="row margin-15-top" v-else>
            <div class="col-sm-12">
                Description:
                <textarea
                        type="text"
                        v-model="goalDescription"
                        :placeholder="goalDescriptionPlaceholder"
                        class="form-control"
                        minlength="2" maxlength="4000" rows="4" :disabled="saving"></textarea>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4" v-if="!goalAvatar">
                <button class="btn btn-mm btn-blue text-left" style="width: 173px; padding: 7px 15px 13px 0;"
                        @click="addAvatar" aria-label="Add a picture" :disabled="saving">
                    <span
                            class="vertical-middle"
                            aria-hidden="true"
                            style="text-align: center; display: inline-block;"
                    >
                        <i class="fa fa-image fa-2"></i>
                    </span>
                    <span class="vertical-middle"> + Add Picture</span>
                </button>
            </div>
            <div class="col-sm-8 hidden-xs" v-if="!goalAvatar">
                Add a picture.
            </div>
            <div class="col-sm-12" v-if="goalAvatar">
                Avatar:<br>
                <media-preview :item="goalAvatar" allow-delete @onDelete="resetAvatar"></media-preview>
            </div>
        </div>
        <div class="row margin-15-top">
            <div class="col-sm-4" v-if="!goalAudioCaption">
                <button class="btn btn-mm btn-blue text-left" style="width: 173px; padding: 7px 15px 13px 0;"
                        @click="addAudio" aria-label="Add a picture" :disabled="saving">
                    <span
                            class="vertical-middle"
                            aria-hidden="true"
                            style="text-align: center; display: inline-block;"
                    >
                        <i class="fa fa-microphone fa-2"></i>
                    </span>
                    <span class="vertical-middle"> + Add Audio</span>
                </button>
            </div>
            <div class="col-sm-8 hidden-xs" v-if="!goalAudioCaption">
                Add an audio caption so users can listen to the name.
            </div>
            <div class="col-sm-12" v-if="goalAudioCaption">
                Audio caption:<br>
                <media-preview :item="goalAudioCaption" allow-delete show-name @onDelete="resetAudio"></media-preview>
            </div>
        </div>
        <div class="row margin-15-top">
            <div class="col-sm-5">
                <vue-switch-with-label :label="hideTasksLabel" name="hideTasks" v-model="hideTasks"/>
            </div>
            <div class="col-sm-7">
                {{ 'Tasks'|customTerm }} will be visible only to their assignee. Admins will see
                all {{ 'Tasks'|customTerm }} anyway.
            </div>
        </div>
        <div class="row margin-15-top" v-if="editMode">
            <div class="col-sm-5">
                <vue-switch-with-label :label="finishGoalLabel" name="finishGoal" v-model="finishGoal"/>
            </div>
            <div class="col-sm-7">
                {{ 'Goal'|customTerm }} will be marked as finished for everyone.
            </div>
        </div>
        <div class="row margin-15-top" v-if="createMode&&forUserCreationAllowed">
            <div class="col-sm-4">
                <vue-switch-with-label label="Create for user" name="hideTasks" v-model="isForUser"/>
            </div>
            <div class="col-sm-8">
                {{ 'Goal'|customTerm }} will belong to selected user. You will be that {{ 'Goal'|customTerm }} admin.
                User will be added as member.
            </div>
        </div>
        <div class="row margin-15-top" v-if="isForUser&&forUserCreationAllowed&&createMode">
            <div class="col-sm-12">
                For User:
                <select v-model="forUser" class="form-control" aria-label="For user">
                    <option
                            v-for="username in forUserUsernamesList"
                            :value="username"
                            :key="username">{{ username }}
                    </option>
                </select>
            </div>
        </div>
        <div slot="footer">
            <div class="row">
                <div class="col-xs-4 text-left">
                    <button class="btn btn-mm btn-red" @click="deleteGoal" :disabled="saving">
                        Delete
                    </button>
                </div>
                <div class="col-xs-8 ">
                    <button class="btn btn-mm btn-default" @click="close" :disabled="saving">
                        Cancel
                    </button>
                    <button class="btn btn-mm btn-green save-button" :disabled="saving||!goalName" @click="ok"
                            style="min-width: 80px">
                        <span v-if="!saving">{{ editMode ? 'Save' : 'Create' }}</span>
                        <span v-else><i class="far fa-spinner fa-spin"></i></span>
                    </button>
                </div>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import PopupPromiseMixin from '~/vue/mixins/PopupPromiseMixin';
    import Vue from 'vue';
    import GoalResource from '~/angular/app/projects/project_resource';

    export default {
        mixins: [PopupPromiseMixin],
        props: {
            editMode: {
                type: Boolean,
                default: false,
            },
            allowDelete: {
                type: Boolean,
                default: false
            },
            availableCategories: {
                type: Array,
                default: () => ([])
            },

            forUserUsernamesList: {
                type: Array,
                default: () => ([])
            },
            forUserCreationAllowed: {
                type: Boolean,
                required: false,
            },

            forUserForcedUsername: {
                type: String,
                required: false,
            },

            additionalInfo: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                saving: false,
                descriptionExpanded: false,

                isForUser: false,
                forUser: undefined,

                goalName: '',
                goalAudioCaption: undefined,
                goalAvatar: undefined,
                goalDescription: '',
                selectedCategory: null,
                hideTasks: false,

                finishGoal: false,

                sourceGoalId: undefined, // for copy from goal or from goal editing

                selectOnFocus: false,
            };
        },
        computed: {
            createMode() {
                return !this.editMode;
            },
            goalNameLabel() {
                return `${this.$customTerm('Goal')} Name`;
            },
            hideTasksLabel() {
                return `Hide ${this.$customTerm('Tasks')}`;
            },
            finishGoalLabel() {
                return `Hide this ${this.$customTerm('Goal')}`;
            },
            goalDescriptionPlaceholder() {
                return `Add a description to this ${this.$customTerm('Goal')}`;
            },
            header() {
                if (this.editMode) {
                    return 'Edit Goal';
                } else {
                    return 'Create Goal';
                }
            },
            firstFocusEl() {
                return this.$refs.nameInput;
            }
        },
        created() {
            if(!this.editMode){
                this.selectOnFocus = true;
            }
        },
        methods: {
            setInitial(initialData) {
                this.populateLoadedData(initialData);
            },
            dumpDataForSave() {
                const data = {
                    name: this.goalName,
                    description: this.goalDescription,

                    hide_tasks: this.hideTasks,
                    category: this.selectedCategory,
                };
                if (this.goalAvatar) {
                    if (this.goalAvatar.id) {
                        data.media_for_avatar_id = this.goalAvatar.id;
                    } // can be string - then ignore
                } else {
                    data.reset_avatar = true;
                }
                if (this.goalAudioCaption) {
                    data.media_for_audio_caption_id = this.goalAudioCaption.id;
                } else {
                    data.reset_audio_caption = true;
                }
                if (this.forUserCreationAllowed && this.isForUser && this.forUser) {
                    data.for_user = true;
                    data.username = this.forUser;
                } else if (this.forUserForcedUsername) {
                    data.for_user = true;
                    data.username = this.forUserForcedUsername;
                }
                if (this.sourceGoalId && this.createMode) {
                    data.goal_to_copy = this.sourceGoalId;
                }
                if (this.editMode) {
                    data.is_finished = this.finishGoal;
                }
                //is_finished: $scope.is_finished,

                return data;
            },
            populateLoadedData(initialData = {}) {
                if (initialData.audio_caption) {
                    this.goalAudioCaption = initialData.audio_caption;
                }
                if (initialData.avatar && !initialData.uses_default_avatar && !initialData.uses_generated_avatar) {
                    this.goalAvatar = initialData.avatar;
                }
                this.selectedCategory = initialData.category;
                this.finishGoal = initialData.is_finished;
                this.goalDescription = initialData.description;
                if (this.goalDescription) {
                    this.descriptionExpanded = true;
                }
                this.hideTasks = initialData.hide_tasks;
                this.goalName = initialData.name;
                if (initialData.id) {
                    this.sourceGoalId = initialData.id;
                }
            },
            async addAvatar() {
                this.tmpHide();
                const addMedia = Vue.getAngularModule('addMedia');
                addMedia.add_media(
                    {
                        allow_add_from_library: true,
                        max_count: 1,
                        type: 'image',
                        popup_header: 'What sort of audio would you like to add?'
                    },
                    {
                        default_callback: ([mi]) => {
                            this.goalAvatar = mi;
                            this.tmpShow();
                        },
                        cancel_callback: () => {
                            this.tmpShow();
                        }
                    }
                );
            },
            resetAvatar() {
                this.goalAvatar = undefined;
            },
            async addAudio() {
                this.tmpHide();
                const addMedia = Vue.getAngularModule('addMedia');
                addMedia.add_media(
                    {
                        allow_add_from_library: true,
                        max_count: 1,
                        type: 'audio',
                        popup_header: 'What sort of audio would you like to add?'
                    },
                    {
                        default_callback: ([mi]) => {
                            this.goalAudioCaption = mi;
                            this.tmpShow();
                        },
                        cancel_callback: () => {
                            this.tmpShow();
                        }
                    }
                );
            },
            resetAudio() {
                this.goalAudioCaption = undefined;
            },
            async updateGoal() {
                const goalData = this.dumpDataForSave();
                this.save({
                    goalData,
                    action: 'update',
                });
            },
            deleteGoal() {
                this.save({
                    action: 'delete',
                });
            },
            async createGoal() {
                this.saving = true;
                try {
                    const { data } = await GoalResource().create_goal(this.dumpDataForSave());
                    this.$notifications.success('Created');
                    this.save({
                        goalData: data,
                        action: 'create',
                        wasCopied: !!this.sourceGoalId
                    });
                } catch (e) {
                    this.$notifications.error(e);
                } finally {
                    this.saving = false;
                }
            },
            ok() {
                if (this.createMode) {
                    this.createGoal();
                } else {
                    this.updateGoal();
                }
            },
        }
    };
</script>

<style scoped lang="scss">

</style>
