var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "vue-simple-header",
        {
          attrs: {
            "left-part-size": _vm.buddyMode ? 5 : 4,
            "right-part-size": _vm.buddyMode ? 7 : 8,
            "right-part-classes": ["no-left-padding-desktop"],
            "separation-mode": "lg",
          },
        },
        [
          !_vm.buddyMode
            ? _c(
                "span",
                {
                  staticClass: "pointer",
                  attrs: { slot: "headerTitle" },
                  on: {
                    click: function ($event) {
                      return _vm.loadEventsWeek()
                    },
                  },
                  slot: "headerTitle",
                },
                [_vm._v(_vm._s(_vm.headerLabel))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.buddyMode
            ? _c(
                "span",
                { attrs: { slot: "headerTitle" }, slot: "headerTitle" },
                [
                  _c("simple-link-with-avatar", {
                    attrs: {
                      target: _vm.profile,
                      "postfix-label": "'s Calendar",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.buddyMode
            ? _c(
                "span",
                { attrs: { slot: "headerLabel" }, slot: "headerLabel" },
                [
                  _c("vue-switch-with-label", {
                    staticClass: "hidden-xs",
                    staticStyle: { margin: "5px 0" },
                    attrs: {
                      "collapse-on-mobile": true,
                      label: "Is Admin",
                      name: "is_admin",
                      "help-text":
                        "Make username Admin of their own Calendar so that they have full access to all Calendar events (including private ones)",
                    },
                    on: { input: _vm.changeAdminStatus },
                    model: {
                      value: _vm.calendarOwnerIsOwnEventsAdmin,
                      callback: function ($$v) {
                        _vm.calendarOwnerIsOwnEventsAdmin = $$v
                      },
                      expression: "calendarOwnerIsOwnEventsAdmin",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "panelRight" }, slot: "panelRight" },
            [
              _vm.buddyMode
                ? _c("vue-switch-with-label", {
                    staticClass: "visible-xs-inline-block",
                    staticStyle: { float: "left" },
                    attrs: {
                      "collapse-on-mobile": true,
                      label: "Is Admin",
                      name: "is_admin",
                      "help-text":
                        "Make username Admin of their own Calendar so that they have full access to all Calendar events (including private ones)",
                    },
                    on: { input: _vm.changeAdminStatus },
                    model: {
                      value: _vm.calendarOwnerIsOwnEventsAdmin,
                      callback: function ($$v) {
                        _vm.calendarOwnerIsOwnEventsAdmin = $$v
                      },
                      expression: "calendarOwnerIsOwnEventsAdmin",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("vue-folder-button", {
                attrs: {
                  "no-text-on-mobile": true,
                  text: "Find events",
                  "icon-class": "fa-search",
                  top: -3,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.goToSearch.apply(null, arguments)
                  },
                },
              }),
              _vm._v(" "),
              _c("vue-folder-button", {
                class: { "hidden-xs": _vm.$route.name === "search" },
                attrs: {
                  "no-text-on-mobile": true,
                  text: "New event",
                  "icon-class": "fa-plus",
                  top: -3,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.createEvent.apply(null, arguments)
                  },
                },
              }),
              _vm._v(" "),
              _vm.isGroupsAdmin && !_vm.buddyMode
                ? _c("vue-folder-button", {
                    class: { "hidden-xs": _vm.$route.name === "search" },
                    attrs: {
                      "no-text-on-mobile": true,
                      text: "Create Group Holiday",
                      "icon-class": "fa-star-christmas",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.createGroupHoliday.apply(null, arguments)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.canManageSubscriptions
                ? _c("vue-folder-button", {
                    class: { "hidden-xs": _vm.$route.name === "search" },
                    attrs: {
                      "no-text-on-mobile": true,
                      text: "Manage Subscriptions",
                      "icon-class": "fa-users-cog fas",
                      color: "brown",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.manageGroupSubscriptions.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("vue-folder-button", {
                staticClass: "diary-detail__go-to-date-button",
                class: { "hidden-xs": _vm.$route.name === "search" },
                attrs: {
                  "no-text-on-mobile": true,
                  "icon-class": "fa-calendar",
                  text: "Go to date",
                  color: "blue",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.showDateSelector.apply(null, arguments)
                  },
                },
              }),
              _vm._v(" "),
              _vm.showBackToCalendarsButton
                ? _c("vue-folder-button", {
                    attrs: {
                      "no-text-on-mobile": true,
                      "icon-class": "fa-reply",
                      text: "Back to Calendars",
                      color: "blue",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.backToCalendars.apply(null, arguments)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loaded,
              expression: "loaded",
            },
          ],
        },
        [
          _c("calendar-page-weeks-navigation", {
            attrs: { "loaded-day": _vm.loadedDay },
            on: { previousWeek: _vm.previousWeek, nextWeek: _vm.nextWeek },
          }),
          _vm._v(" "),
          _vm._l(_vm.days, function (day) {
            return _c("calendar-page-day", {
              key: day.date,
              attrs: { day: day, profile: _vm.profile },
              on: { onHolidayChanged: _vm.onHolidayChanged },
            })
          }),
          _vm._v(" "),
          _c("calendar-page-weeks-navigation", {
            attrs: { "loaded-day": _vm.loadedDay },
            on: { previousWeek: _vm.previousWeek, nextWeek: _vm.nextWeek },
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("create-event-popup", { ref: "create-event-popup" }),
      _vm._v(" "),
      _vm.isGroupsAdmin && !_vm.buddyMode
        ? _c("group-events-page-add-holiday-popup", {
            ref: "group-events-page-add-holiday-popup",
          })
        : _vm._e(),
      _vm._v(" "),
      _c("create-event-target-selector-popup", {
        ref: "create-event-target-selector-popup",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }