<template>
    <div class="goal-create-panel">
        <button class="btn background-button-blue wizard-button inline-block"
                @click="runWizard"
                :class="{longTaskTerm}"
        >
            <i class="fa fa-magic"></i>
            <span class="wizard-button-label"> {{ "Task"|customTerm }} Wizard</span>
        </button>
        <button class="btn inline-block" v-if="allowFilter" @click="showFilterMenu" :class="filterButtonClass">
            <i class="fa fa-filter" v-tooltip="'Filter by Assignee'"></i>
        </button>
        <input type="text" v-model="taskName" class="form-control create-input inline-block "
               :class="{'with-filter':allowFilter, longTaskTerm}" maxlength="255"
               @keydown.prevent.13="createTask"
               :placeholder=quickCreateTaskLabel>
        <button class="btn background-button-blue go-button inline-block" @click="createTask">
            <span>Go</span>
        </button>
    </div>
</template>

<script>
    import {taskActionsBus, TASK_ACTION_BUS_ACTIONS} from './taskActionsBus.js';

    export default {
        props: {
            allowFilter: Boolean
        },
        data() {
            return {
                taskName: ''
            };
        },
        methods: {
            createTask() {
                taskActionsBus.$emit(TASK_ACTION_BUS_ACTIONS.QUICK_CREATE, this.taskName);
                this.$emit(TASK_ACTION_BUS_ACTIONS.QUICK_CREATE, this.taskName);
                this.taskName = '';
            },
            runWizard() {
                taskActionsBus.$emit(TASK_ACTION_BUS_ACTIONS.RUN_WIZARD);
                this.$emit(TASK_ACTION_BUS_ACTIONS.RUN_WIZARD);
            },
            showFilterMenu() {
                this.$emit('filterButtonClicked');
            }
        },
        computed: {
            longTaskTerm() {
                return this.$customTerm('Task').length > 5;
            },
            quickCreateTaskLabel() {
                return `Quickcreate: write name of ${this.$customTerm('Task')} and press Enter`;
            },
            assigneesInFilter() {
                return this.$store.state.goalDetailPageStore.assigneesInFilter;
            },
            filterIsActive() {
                return this.assigneesInFilter.length > 0;
            },
            filterButtonClass() {
                if (this.filterIsActive) {
                    return 'background-button-green';
                } else {
                    return 'background-button-blue';
                }
            }
        },
        name: "GoalCreatePanel"
    };
</script>

<style scoped lang="scss">
    .goal-create-panel {
        margin-bottom: 15px;
        position: relative;
    }

    $height: 35px;

    .btn {
        color: white;

        &:hover {
            color: white;
        }
    }

    .go-button {
        width: 50px;
        height: $height;
        vertical-align: top;
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 0 5px 5px 0 !important;
    }

    .wizard-button {
        &.longTaskTerm {
            @media all and (min-width: 600px) {
                width: 180px;
            }
        }

        @media all and (min-width: 600px) {
            width: 120px;
        }
        @media all and (max-width: 599px) {
            width: 38px;
        }

        height: $height;
        vertical-align: top;
    }

    .wizard-button-label {
        @media all and (max-width: 599px) {
            display: none;
        }
    }

    .create-input {
        &.with-filter {
            &.longTaskTerm {
                @media all and (min-width: 600px) {
                    width: calc(100% - 240px - 5px);
                }
            }

            @media all and (min-width: 600px) {
                width: calc(100% - 180px - 5px);
            }
            @media all and (max-width: 599px) {
                width: calc(100% - 100px - 5px);
            }
        }

        &.longTaskTerm {
            @media all and (min-width: 600px) {
                width: calc(100% - 180px - 5px);
            }
        }

        @media all and (min-width: 600px) {
            width: calc(100% - 120px - 5px);
        }
        @media all and (max-width: 599px) {
            width: calc(100% - 38px - 5px);
        }
        height: $height;
        vertical-align: top;
    }

</style>