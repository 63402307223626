/**
 * Created by orion on 09.05.17.
 */

import angular from 'angular'

// mainFilters
import main_filters from '../filters/filters_module'
import search_input from '../inputs/search_input/search_input_module'
import preload from '../../preload/preload'
//shared modules

import simplePopups from '../simple_popups/simple_popup_module';
import notifications from '../notifications/notifications_module';
import infiniteScroll from '../infiniteScroll_directive'
import selectFactory from '../select_factory/selectFactory_module'


//app modules
import add_media from '../../add_media/add_media_module'
import view_media from '../../view_media/view_media_module'
import CurrentUser from '../CurrentUser'
import userprofile_page from '../../userprofile/userprofile_module'

//pages

//resources

//directives

//factories

import sharedIcon from './shared_icon/directive'
import sharedPanel from './shared_panel'
import usersListPanel from './users_list_directive/directive'

angular.module('mmApp.shared.leftPanel',
    ['ui.bootstrap',
        preload,
        simplePopups,
        notifications,
        add_media,
        view_media,
        main_filters,
        search_input,
        infiniteScroll,
        selectFactory,
        CurrentUser,
        userprofile_page,

        sharedIcon,
        sharedPanel,
        usersListPanel
    ])
;

export default 'mmApp.shared.leftPanel'
