<template>
    <div class="sticker_directive" :style="style" :class="{'sticker_directive_responsive':responsive}"
         aria-label="Sticker">
        <img :src="stickerUrl" class="visible-print" width="100" height="100" style="border: 0" alt="Sticker image">
        <i
                class="fa fa-times story-slide-preview-image__delete_button"
                v-if="allowDelete"
                @click="del"
                @keydown.enter="del"
                role="button"
                tabindex="0"
                aria-label="Remove Sticker"
        ></i>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';

    export default {
        name: "RenderSticker",
        props: {
            sticker: {},
            allowDelete: {
                type: Boolean,
                default: false,
            },
            responsive: {
                type: Boolean,
                default: false
            },
            defaultSize: {
                type: Number,
                default: 135
            }
        },
        computed: {
            stickerObject() {
                if (!this.sticker) {
                    return;
                }
                const stickersFactory = Vue.getAngularModule('stickersFactory');
                if (_.isString(this.sticker)) {
                    return stickersFactory.get_sticker_by_code(this.sticker);
                } else {
                    return this.sticker;
                }
            },
            stickerUrl() {
                return this.stickerObject ? this.stickerObject.url : undefined;
            },
            style() {
                if (!this.sticker) {
                    return;
                }
                let style = {
                    'background-image': `url(${this.stickerUrl})`,
                };
                if (!this.responsive) {
                    style['width'] = `${this.defaultSize}px`;
                    style['height'] = `${this.defaultSize}px`;
                }
                if (this.allowDelete) {
                    style.position = 'relative';
                }
                return style;
            }
        },
        methods: {
            del() {
                this.$emit('onDelete', this.stickerObject);
            }
        }
    };
</script>

<style scoped lang="scss">
  @import "../../../styles/const";

  .sticker_directive {
    &_responsive {
      width: 80px;
      height: 80px;
      margin: 4px;
      @media (min-width: $screen-mobile-m-min) and (max-width: $screen-mobile-m-max) {
        width: 85px;
        height: 85px;
        margin: 10px;
      }
      @media (min-width: $screen-mobile-l-min) and (max-width: $screen-mobile-l-max) {
        width: 95px;
        height: 95px;
        margin: 10px;
      }
      @media (min-width: $screen-mobile-l-min + 1) {
        width: 115px;
        height: 115px;
        margin: 10px;
      }
    }

    background-size: contain;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
  }
</style>