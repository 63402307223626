var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    { class: { warning: !_vm.member.is_active, active: _vm.member.is_active } },
    [
      _c("td", [
        _vm.member.membership_type === "userprofile"
          ? _c(
              "strong",
              [
                _c("img", {
                  staticClass: "img-circle vertical-middle",
                  staticStyle: { width: "40px", height: "40px" },
                  attrs: {
                    src: _vm.member.userprofile.avatar,
                    alt: `${_vm.member.userprofile.name}'s avatar`,
                  },
                }),
                _vm._v(" "),
                _c("simple-link", {
                  staticClass: "vertical-middle",
                  attrs: { target: _vm.member.userprofile },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isOwner ? _c("i", [_vm._v("(Owner)")]) : _vm._e(),
        _vm._v(" "),
        _vm.member.membership_type === "access_link"
          ? _c("strong", [_vm._v(_vm._s(_vm.member.email || _vm.member.name))])
          : _vm._e(),
        _vm._v(" "),
        _vm.member.membership_type === "access_link"
          ? _c("div", { staticClass: "margin-5-top" }, [
              _vm.member.email
                ? _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "button-without-style",
                        on: { click: _vm.explainEmailUser },
                      },
                      [_vm._m(0)]
                    ),
                  ])
                : _vm.member.name
                ? _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "button-without-style",
                        on: { click: _vm.explainAccessLinkUser },
                      },
                      [_vm._m(1)]
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.member.membership_type === "account-holder"
          ? _c("strong", [
              _c("strong", [_vm._v(_vm._s(_vm.member.account_holder.name))]),
              _c("br"),
              _vm._v("(Portal)"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.member.membership_type === "circle"
          ? _c("strong", [_vm._v("My Circle")])
          : _vm._e(),
        _vm._v(" "),
        _vm.member.membership_type === "public"
          ? _c("strong", [
              _vm._m(2),
              _c("br"),
              _vm._v("(includes unauthenticated users)"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.member.membership_type === "group"
          ? _c("strong", [
              _c("strong", [
                _c("a", { attrs: { href: _vm.member.group.link } }, [
                  _vm._v(_vm._s(_vm.member.group.name)),
                ]),
              ]),
              _vm._v(" (Group)"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.member.membership_type === "circle-invitation"
          ? _c("strong", [
              _c("strong", [_vm._v(_vm._s(_vm.member.invitation.email))]),
              _c("br"),
              _vm._v(" (Pending Circle Invitation)"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.member.membership_type === "friend-request"
          ? _c("strong", [
              _c(
                "strong",
                [
                  _c("simple-link", {
                    attrs: { target: _vm.member.invitation.profile },
                  }),
                ],
                1
              ),
              _c("br"),
              _vm._v(" (Pending Circle Invitation)"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.member.membership_type === "access_link"
          ? _c(
              "div",
              [
                _c("member-access-link-field", {
                  attrs: { member: _vm.member },
                  on: {
                    updateAccessLinkTerm: _vm.updateAccessLinkTerm,
                    protectAccessLinkByPassword:
                      _vm.protectAccessLinkByPassword,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", {}, [
          !_vm.member.has_assigned_permissions
            ? _c("strong", { staticClass: "color-footer-red" }, [
                _vm._v("No access given"),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("td", [
        !_vm.member.role ? _c("span", [_vm._v("-")]) : _vm._e(),
        _vm._v(" "),
        _vm.member.role
          ? _c("span", [_vm._v(_vm._s(_vm.member.role))])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("td", [
        _vm.readReceiptApplicable
          ? _c("span", [
              _c("i", {
                staticClass:
                  "fa fa-plus-circle wiki-table-member-read-receipts__add-icon",
                attrs: { role: "button", "aria-label": "Create Read Receipt" },
                on: { click: _vm.sendReadReceipt },
              }),
              _vm._v(" "),
              _vm.member.received_read_receipts_count
                ? _c(
                    "span",
                    {
                      staticClass: "wiki-table-member-read-receipts__counter",
                      attrs: {
                        role: "button",
                        "aria-label": "Show Read Receipts",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          attrs: {
                            role: "button",
                            "aria-label": "Show Read Receipts",
                            tabindex: "0",
                          },
                          on: { click: _vm.showReadReceipts },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.member.completed_read_receipts_count)
                          ),
                          _vm.member.rejected_read_receipts_count &&
                          _vm.member.completed_read_receipts_count
                            ? _c("span", { staticClass: "color-footer-red" }, [
                                _vm._v("+"),
                              ])
                            : _vm._e(),
                          _vm.member.rejected_read_receipts_count
                            ? _c("span", { staticClass: "color-footer-red" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.member.rejected_read_receipts_count
                                  )
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(
                            "\n                    /\n                    " +
                              _vm._s(_vm.member.received_read_receipts_count) +
                              "\n                "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ])
          : _c("span", [_vm._v("-")]),
      ]),
      _vm._v(" "),
      _c("td", [
        _vm.member.membership_type !== "public"
          ? _c("span", [
              _vm._v(_vm._s(_vm._f("gbdate")(_vm.member.created_at))),
            ])
          : _c("span", [_vm._v("-")]),
        _vm._v(" "),
        _c(
          "small",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.member.is_active,
                expression: "!member.is_active",
              },
            ],
          },
          [_c("br"), _c("i", [_vm._v("(Waiting confirmation)")])]
        ),
      ]),
      _vm._v(" "),
      _c(
        "td",
        { staticClass: "text-center" },
        [
          _c("vue-switch", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.member.userprofile,
                expression: "member.userprofile",
              },
            ],
            attrs: { name: "is_admin" },
            on: {
              input: function ($event) {
                return _vm.toggleMemberAdminStatus(_vm.member)
              },
            },
            model: {
              value: _vm.member.is_admin,
              callback: function ($$v) {
                _vm.$set(_vm.member, "is_admin", $$v)
              },
              expression: "member.is_admin",
            },
          }),
          _vm._v(" "),
          !_vm.member.userprofile ? _c("span", [_vm._v("-")]) : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("td", { staticClass: "text-center" }, [
        _c(
          "button",
          {
            staticClass: "button-without-style",
            attrs: { disabled: _vm.member.is_admin },
            on: { click: _vm.openPermissionsTree },
          },
          [
            _c("i", {
              staticClass: "fa fa-pencil fa-2",
              class: {
                pointer: !_vm.member.is_admin,
                "color-gray": _vm.member.is_admin,
                "color-footer-red": !_vm.member.has_assigned_permissions,
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "text-center" }, [
        !_vm.isOwner &&
        !(_vm.member.membership_type === "public") &&
        !(_vm.member.membership_type === "account-holder")
          ? _c(
              "button",
              {
                staticClass: "button-without-style",
                attrs: { "aria-label": "Remove Member" },
                on: {
                  click: function ($event) {
                    return _vm.removeMember(_vm.member)
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-trash-o fa-2 color-red" })]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("strong", [
      _vm._v("Email\n                    access user "),
      _c("i", { staticClass: "fa fas fa-info color-dark-blue" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("strong", [
      _vm._v("Wiki access link "),
      _c("i", { staticClass: "fa fas fa-info color-dark-blue" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("strong", [
      _vm._v("Public "),
      _c("i", { staticClass: "fal fa-globe-europe" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }