import angular from 'angular'
import SessionHandler from './SessionHandler'
import CurrentUser from '../shared/CurrentUser'
import sessionExpireResource from './session_expire_resource'

angular.module('mmApp.session_expire', [CurrentUser,])
    .factory('sessionExpireResource', sessionExpireResource)
    .service('SessionHandler', SessionHandler)

;


export default 'mmApp.session_expire';