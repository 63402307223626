/**
 * Created by orion on 12.03.17.
 */
import angular from 'angular'
let $ = require('jquery');
infiniteScroll.$inject = ['$rootScope', '$window', '$timeout'];
function infiniteScroll($rootScope, $window, $timeout) {
  return {
      link: function (scope, elem, attrs) {
          var checkWhenEnabled, handler, scrollDistance, scrollEnabled;
          let windowEL = $($window);
          elem = $(elem);
          scrollDistance = 0;
          if (attrs.infiniteScrollDistance != null) {
              scope.$watch(attrs.infiniteScrollDistance, function (value) {
                  return scrollDistance = parseInt(value, 10);
              });
          }
          scrollEnabled = true;
          checkWhenEnabled = false;
          if (attrs.infiniteScrollDisabled != null) {
              scope.$watch(attrs.infiniteScrollDisabled, function (value) {
                  scrollEnabled = !value;
                  if (scrollEnabled && checkWhenEnabled) {
                      checkWhenEnabled = false;
                      return handler();
                  }
              });
          }
          handler = function () {
              var elementBottom, remaining, shouldScroll, windowBottom;
              windowBottom = windowEL.height() + windowEL.scrollTop();
              elementBottom = elem.offset().top + elem.height();
              remaining = elementBottom - windowBottom;
              shouldScroll = remaining <= windowEL.height() * scrollDistance;
              if (shouldScroll && scrollEnabled) {
                  if ($rootScope.$$phase) {
                      return scope.$eval(attrs.infiniteScroll);
                  } else {
                      return scope.$apply(attrs.infiniteScroll);
                  }
              } else if (shouldScroll) {
                  return checkWhenEnabled = true;
              }
          };
          windowEL.on('scroll', handler);
          scope.$on('$destroy', function () {
              return windowEL.off('scroll', handler);
          });
          return $timeout(function () {
              if (attrs.infiniteScrollImmediateCheck) {
                  if (scope.$eval(attrs.infiniteScrollImmediateCheck)) {
                      return handler();
                  }
              } else {
                  return handler();
              }
          }, 0);
      }
  };
}


angular.module('mmApp.blogolog.infiniteScroll', [])
    .directive('infiniteScroll', infiniteScroll);

export default 'mmApp.blogolog.infiniteScroll'