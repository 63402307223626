<template>
    <modal v-model="opened" :transition='0'>
        <div slot="header">
            <a id="close_popup" @click="close_popup"></a>
            <h3 class="modal-title">Delete Event</h3>
        </div>
        <div class="row" style="margin-bottom: 15px">
            <div class="col-sm-12">
                <div class="">
                    <p class="color-red">
                        NOTE: Deleting this Event will remove data such as Event details and comments etc... However,
                        all user’s Diary entries and {{ "Goals"|customTerm }} linked to the Event will remain intact.
                    </p>
                    <p>
                        This is a repeated Event. You have three delete options:
                    </p>

                </div>
                <div class="margin-5-top">
                    1) Delete the whole chain of Events. Every occurrence of this Event past, present and future will be deleted.
                </div>
                <div class="margin-5-top">
                    2) Delete this date only. Repeat Events will occur as defined, but this exact event (on <strong>{{formattedDate}}</strong>) will be deleted.
                    Only data related to this particular event will be lost.
                </div>
                <div class="margin-5-top">
                    3) Finish event. Events will not repeat/will be deleted after this date.
                    Data on and before <strong>{{formattedDate}}</strong> will remain, data from Event occurrences after this date will be lost.
                </div>
            </div>
        </div>
        <div class="select-menu-container center">
            <div class="select-menu-item">
                <div class="panel panel-danger">
                    <div class="panel-heading shortcuts" @click="select('whole')">
                        <h3 class="panel-title">Every<br>occurrence</h3>
                        <i class="fa fa-repeat select-menu-item__main-icon" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="select-menu-item">
                <div class="panel panel-primary">
                    <div class="panel-heading shortcuts" @click="select('exact')">
                        <h3 class="panel-title">Delete<br>{{ formattedDate }}</h3>
                        <i class="fa fa-calendar-o select-menu-item__main-icon" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="select-menu-item">
                <div class="panel panel-primary">
                    <div class="panel-heading shortcuts" @click="select('since')">
                        <h3 class="panel-title">Finish<br>Event</h3>
                        <i class="fa  fa-flag-checkered select-menu-item__main-icon" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer"></div>
    </modal>
</template>

<script>
    import PopupMixin from "../../../../vue/mixins/PopupMixin";

    export default {
        name: "DeleteRepeatedEventTypeSelectorPopup",
        mixins: [PopupMixin],
        data() {
            return {
                opened: false,
                resolve: undefined,
                reject: undefined,
                date: undefined
            };
        },
        computed: {
            formattedDate() {
                if (!this.date) {
                    return;
                }
                return this.date.format('Do MMM');
            },
        },
        methods: {

            show(date) {
                this.date = date;
                const self = this;
                this.opened = true;
                return new Promise((resolve, reject) => {
                    self.resolve = resolve;
                    self.reject = reject;
                });
            },
            select(what) {
                this.resolve(what);
                this.info = '';
                this.close();
            },
            close_popup() {
                this.info = '';
                this.reject('close');
                this.close();
            }
        }

    };
</script>

<style scoped>

</style>