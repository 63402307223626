/**
 * Created by orion on 6/19/17.
 */
import './renderPostDirective.scss'

let Autolinker = require('autolinker');
let angular = require('angular');
renderPost.$inject = ['$sce', '$sanitize', '$log'];

function renderPost($sce, $sanitize, $log) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            post: '=',
            renderQoute: '=?'
        },
        template: require('./renderPostTemplate.html'),
        link: function ($scope, element, attrs, controller) {
            $scope.get_text = get_text;

            function get_text() {
                let linkedText = Autolinker.link($scope.post.text, {truncate: 30});
                linkedText = $sce.trustAs($sce.HTML, $sanitize(linkedText))
                return linkedText;
            }
        }
    };
}

angular.module('mmApp.shared.renderPost', []).directive('renderPost', renderPost);

export default 'mmApp.shared.renderPost'