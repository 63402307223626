var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.wikiSnapshotAfter
    ? _c("span", [
        _c("span", [_vm._v("created Wiki ")]),
        _vm._v(" "),
        _c("strong", [
          _vm._v(
            "\n        #" +
              _vm._s(_vm.wikiSnapshotAfter.id) +
              ' "' +
              _vm._s(_vm.wikiSnapshotAfter.name) +
              '"\n    '
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }