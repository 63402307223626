/**
 * Created by orion on 6/20/17.
 */
import urls from 'DjangoUrls';
import axios from 'axios';

let {DjangoUrls} = urls;
SiteActivityResource.$inject = ['$http'];

function SiteActivityResource($http) {
    return {
        get_site_activity: function (params) {
            const url = DjangoUrls["api.v1:activity-get-site-activity"]();
            return axios({
                method: 'GET',
                url,
                params,
                cache: false
            })
        },
        get_site_activity_app: function (params) {
            const url = DjangoUrls["api.v1:activity-get-site-activity-app"]();
            return axios({
                method: 'GET',
                url,
                params,
                cache: false
            })
        },
        get_my_activity: function (params) {
            const url = DjangoUrls["api.v1:activity-get-my-activity"]();
            return axios({
                method: 'GET',
                url,
                params,
                cache: false
            })
        },
        get_users_activity: function (user_id, params) {
            const url = DjangoUrls["api.v1:activity-get-users-activity"]();
            params = Object.assign(params, {id: user_id});
            return axios({
                method: 'GET',
                url,
                params,
                cache: false
            })
        },
        get_community_activity: function (id, params) {
            const url = DjangoUrls["api.v1:community_activity-get-activity"](id);
            return axios({
                method: 'GET',
                url,
                params,
                cache: false
            })
        },
        get_group_activity: function (id, params) {
            const url = DjangoUrls["api.v1:group_activity-get-activity"](id);
            return axios({
                method: 'GET',
                url,
                params,
                cache: false
            })
        }
    }
}

export default SiteActivityResource
