import * as Sentry from "@sentry/browser";
import {CurrentUserClass} from "shared/CurrentUser";

const CurrentUser = new CurrentUserClass();
if (SENTRY_DSN && CurrentUser.is_authenticated()) {
    Sentry.init({
        dsn: SENTRY_DSN,
        enabled: true,
        maxBreadcrumbs: 50,
        debug: false,
        ignoreErrors:[
            "Non-Error",
            'not allowed by the user agent',
            'failed because the user didn',
            'Redirected when going from',
        ],
    });
    if (CurrentUser.is_authenticated()) {
        Sentry.setUser({
            id: CurrentUser.id,
            username: CurrentUser.username,
        });
    }
}
export default Sentry;