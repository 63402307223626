var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    { class: { warning: !_vm.member.is_active, active: _vm.member.is_active } },
    [
      _c("td", [
        _vm.member.membership_type === "userprofile"
          ? _c(
              "strong",
              [
                _c("simple-link", {
                  attrs: { target: _vm.member.userprofile },
                }),
                _c("br"),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isOwner ? _c("i", [_vm._v("(Owner)")]) : _vm._e(),
        _vm._v(" "),
        _vm.member.membership_type === "access_link"
          ? _c("span", [_vm._v(_vm._s(_vm.member.email || _vm.member.name))])
          : _vm._e(),
        _vm._v(" "),
        _vm.member.membership_type === "circle"
          ? _c("span", [_vm._v("My Circle")])
          : _vm._e(),
        _vm._v(" "),
        _vm.member.membership_type === "circle-invitation"
          ? _c("span", [
              _c("strong", [_vm._v(_vm._s(_vm.member.invitation.email))]),
              _c("br"),
              _vm._v(" (Pending Circle Invitation)"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.member.membership_type === "friend-request"
          ? _c("span", [
              _c(
                "strong",
                [
                  _c("simple-link", {
                    attrs: { target: _vm.member.invitation.profile },
                  }),
                ],
                1
              ),
              _c("br"),
              _vm._v(" (Pending Circle Invitation)"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.member.membership_type === "group"
          ? _c("span", [
              _c(
                "strong",
                [_c("simple-link", { attrs: { target: _vm.member.group } })],
                1
              ),
              _c("br"),
              _vm._v("  (Group)"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.member.membership_type === "community"
          ? _c("span", [
              _c(
                "strong",
                [
                  _c("simple-link", {
                    attrs: { target: _vm.member.community },
                  }),
                ],
                1
              ),
              _c("br"),
              _vm._v("  (Community)"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.member.membership_type === "access_link"
          ? _c("i", [
              _c("br"),
              _c(
                "a",
                { attrs: { href: _vm.member.access_link, target: "_blank" } },
                [_vm._v("access\n            link")]
              ),
              _vm._v(" "),
              _c("i", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Copy to Clipboard",
                    expression: "'Copy to Clipboard'",
                  },
                ],
                ref: "membersAccessLink",
                staticClass: "fa fa-clipboard pointer color-dark-blue",
                attrs: { "data-clipboard-text": _vm.member.access_link },
              }),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("td", [
        !_vm.member.role ? _c("span", [_vm._v("-")]) : _vm._e(),
        _vm._v(" "),
        _vm.member.role
          ? _c("span", [_vm._v(_vm._s(_vm._f("cutName")(_vm.member.role, 30)))])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("td", [
        _vm._v(_vm._s(_vm._f("gbdate")(_vm.member.created_at)) + "\n        "),
        _c(
          "small",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.member.is_active,
                expression: "!member.is_active",
              },
            ],
          },
          [_c("br"), _c("i", [_vm._v("(Waiting confirmation)")])]
        ),
      ]),
      _vm._v(" "),
      _c(
        "td",
        { staticClass: "text-center" },
        [
          _c("vue-switch", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.member.membership_type === "userprofile" &&
                  _vm.member.have_any_edit_access &&
                  !_vm.isMe,
                expression:
                  "member.membership_type==='userprofile' && member.have_any_edit_access && !isMe",
              },
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value:
                  "We will notify you about any new media files that have been uploaded by this member",
                expression:
                  "'We will notify you about any new media files that have been uploaded by this member'",
              },
            ],
            attrs: { name: "send_notifications" },
            on: {
              input: function ($event) {
                return _vm.toggleMemberNotificationsState(_vm.member)
              },
            },
            model: {
              value: _vm.member.send_notifications,
              callback: function ($$v) {
                _vm.$set(_vm.member, "send_notifications", $$v)
              },
              expression: "member.send_notifications",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "td",
        { staticClass: "text-center" },
        [
          _c("vue-switch", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.member.userprofile,
                expression: "member.userprofile",
              },
            ],
            attrs: { name: "is_admin" },
            on: {
              input: function ($event) {
                return _vm.toggleMemberAdminStatus(_vm.member)
              },
            },
            model: {
              value: _vm.member.is_admin,
              callback: function ($$v) {
                _vm.$set(_vm.member, "is_admin", $$v)
              },
              expression: "member.is_admin",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("td", { staticClass: "text-center" }, [
        _c("i", {
          staticClass: "fa fa-pencil fa-2",
          class: {
            pointer: !_vm.member.is_admin,
            "color-gray": _vm.member.is_admin,
          },
          on: { click: _vm.openPermissionsTree },
        }),
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "text-center" }, [
        !_vm.isOwner && !_vm.member.is_admin
          ? _c("i", {
              staticClass: "fa fa-trash-o fa-2 pointer color-red",
              on: {
                click: function ($event) {
                  return _vm.removeMember(_vm.member)
                },
              },
            })
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }