/**
 * Created by orion on 31.05.17.
 */
let _ = require('lodash');

StoryMembersController.$inject = ['$scope', 'CurrentUser', '$timeout', '$q', '$location', 'simplePopupFactory', 'notifications',
    '$rootScope', '$preloaded', '$log', 'addMedia', 'selectFactory', 'StoryMembersResource'];

function StoryMembersController($scope, CurrentUser, $timeout, $q, $location, simplePopupFactory, notifications,
                                $rootScope, $preloaded, $log, addMedia, selectFactory, StoryMembersResource) {
    let self = this;
    self.members = [];

    function init() {
        self.story = $preloaded.story;
        self.is_admin = $preloaded.is_admin;
        fetch_members();
    }

    self.can_invite_users = can_invite_users;

    function can_invite_users() {
        return self.is_admin && CurrentUser.has_permission('invite-users-to-join-stories');
    }

    $scope.$on('user_become_admin', function (e, user_id) {
        for (let member of self.members) {
            if (member.id === user_id) {
                member.is_admin = true;
                break;
            }
        }
        self.is_admin = true;
    });


    function fetch_members() {
        $rootScope.show_dimmer();
        StoryMembersResource.get_members_list_for_members_page(self.story.id)
            .then(resp => {
                self.members = resp.data;
            }, err => notifications.error(err || 'Error'))
            .finally($rootScope.hide_dimmer);
    }

    self.get_admins = function () {
        return self.members.filter(m => m.is_admin);
    };

    self.get_not_admins = function () {
        return self.members.filter(m => !m.is_admin);
    };

    self.delete_member = function (member) {
        simplePopupFactory.show_popup('Delete Member', `Are you sure you want to delete user '${member.name}' from this Story?`, 'Delete', 'Cancel')
            .then(() => {
                $rootScope.show_dimmer();
                return StoryMembersResource.delete_member(self.story.id, member.id);
            }, () => new Promise(()=>{}))
            .then(resp => {
                notifications.success('Deleted');
                self.members.splice(self.members.indexOf(member), 1);
                if (CurrentUser.id === member.id) {
                    $scope.$emit('user_deleted_himself');
                }
            }, err => notifications.error(err || 'Error'))
            .finally($rootScope.hide_dimmer);
    };

    self.manage_admins = function () {
        let do_unadmin = false;
        selectFactory
            .endpoint_selector(_.partial(StoryMembersResource.get_admins_for_manage, self.story.id), true, {
                header: 'Manage Admins',
                info: 'Select or de-select user from list to make them admins',
                button_yes: 'Save',
                button_no: 'Cancel',
                empty: 'You do not have any users to select'
            })
            .then(res => {
                let new_checked_ids = res[0];
                let new_unchecked_ids = res[1];
                $rootScope.show_dimmer();
                if (new_unchecked_ids.indexOf(CurrentUser.id) !== -1) {
                    do_unadmin = true;
                }
                return StoryMembersResource.update_admins(self.story.id, new_checked_ids, new_unchecked_ids)
            }, () => new Promise(()=>{}))
            .then(resp => {
                notifications.success('Updated');
                fetch_members();
                if (do_unadmin) {
                    self.is_admin = false;
                    $preloaded.is_admin = false;
                    $scope.$emit('user_unbecome_admin');
                }
            }, err => {
                $rootScope.hide_dimmer();
                notifications.error(err || 'Error');
            })
    };

    self.invite_users = function () {
        selectFactory
            .endpoint_selector(_.partial(StoryMembersResource.get_users_for_invite, self.story.id), true, {
                header: 'Invite Users',
                info: 'Select or de-select user from list to invite them to this Story',
                button_yes: 'Invite',
                button_no: 'Cancel',
                empty: 'You do not have any users to select'
            })
            .then(res => {
                let new_checked_ids = res[0];
                let new_unchecked_ids = res[1];
                $rootScope.show_dimmer();
                return StoryMembersResource.invite_users(self.story.id, new_checked_ids, new_unchecked_ids);
            }, () => new Promise(()=>{}))
            .then(resp => {
                notifications.success('Invited');
            }, err => {
                notifications.error(err || 'Error');
            }).finally($rootScope.hide_dimmer)
    };

    init();

}

export default StoryMembersController