var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "inline-block vue-media-preview__container",
      attrs: { "aria-label": _vm.visibleName, role: "figure" },
    },
    [
      _c(
        "div",
        {
          staticClass: "vue-media-preview__image",
          style: [_vm.slidePreviewStyle, _vm.imageSize, _vm.highLightStyle],
        },
        [
          _vm.allowDelete
            ? _c("i", {
                staticClass:
                  "fa fa-times vue-media-preview__image__delete_button",
                style: _vm.iconSize,
                attrs: {
                  tabindex: "0",
                  role: "button",
                  "aria-label": "Delete Media",
                },
                on: {
                  click: _vm.del,
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.del.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.allowEdit
            ? _c("i", {
                staticClass:
                  "fa fa-pencil vue-media-preview__image__edit_button",
                style: _vm.iconSize,
                attrs: {
                  tabindex: "0",
                  "aria-label": "Edit Media",
                  role: "button",
                },
                on: {
                  click: _vm.edit,
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.edit.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.item,
                expression: "!item",
              },
            ],
            staticClass: "fa fa-question",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v(" "),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isSlideEncodingProgressNow,
                expression: "isSlideEncodingProgressNow",
              },
            ],
            staticClass: "fa fa-hourglass-half",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v(" "),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isSlideTypeAudio,
                expression: "isSlideTypeAudio",
              },
            ],
            staticClass: "fa fa-volume-up",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v(" "),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isSlideTypeDocument,
                expression: "isSlideTypeDocument",
              },
            ],
            staticClass: "fa fa-file-text-o",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v(" "),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isSlideTypeMap,
                expression: "isSlideTypeMap",
              },
            ],
            staticClass: "fa fa-map-marker",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v(" "),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isSlideTypeVideo && _vm.isSlideEncodingProgressNow,
                expression: "isSlideTypeVideo&&isSlideEncodingProgressNow",
              },
            ],
            staticClass: "fa fa-video-camera",
            attrs: { "aria-hidden": "true" },
          }),
        ]
      ),
      _vm._v(" "),
      _vm.showName
        ? _c("div", {
            staticClass: "vue-media-preview__text",
            style: _vm.textStyle,
            domProps: { innerHTML: _vm._s(_vm.visibleName) },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }