<template>
    <div class="group-event-item" style="position: relative">
        <div class="group-event-item__preview">
            <div class="group-event-item__avatar" :style="avatarStyle">
                <i class="fa fa-repeat group-event-item__avatar__repeat-mark" v-if="showPeriodicMark"></i>
            </div>
            <div class="group-event-item__date">{{greenBoxDateLabel}}</div>
        </div>
        <span class="group-event-item__icon-delete pointer" v-if="deletable" @click="deleteEvent()">
        <!--<i class="fa" ng-class="get_delete_button_icon_class()"></i>-->
        </span>
        <span class="group-event-item__name">
            <router-link :to="eventLink | backHrefQuery">{{event.event_data.name}}</router-link>
            <i v-show="event.is_private" class="fa fa-eye-slash color-dark-blue"></i>
        </span><br>
        <strong>{{inListTimeRepresentationLabel}} </strong><br>
        <div v-if="event.owner">
            <div class="inline-block vertical-middle event-belonging-preview"
                 :style="getGroupEventPreviewBackground(event.owner)"></div>
            <strong class="inline-block vertical-middle">
                <simple-link :target="event.owner"></simple-link>
            </strong>
        </div>
        <div v-if="event.group">
            <div class="inline-block vertical-middle event-belonging-preview"
                 :style="getGroupEventPreviewBackground(event.group)"></div>
            <strong class="inline-block vertical-middle">
                <simple-link :target="event.group"></simple-link>
            </strong>
        </div>
        <p v-show="event.is_member">
            <span><i class="fa fa-check color-grass-green"></i> This Event has been added to <span v-if="isMyCalendar">your</span><span v-else><strong>{{profile.name}}</strong>'s</span> Calendar.</span>
        </p>
        <p v-show="!event.is_member">
            <span><i class="fa fa-times color-tag-gray"></i> This Event has not been added to <span v-if="isMyCalendar">your</span><span v-else><strong>{{profile.name}}</strong>'s</span> Calendar.</span>
        </p>
        <p>
            <i>{{event.event_data.description | cutName(300)}}</i>
        </p>
    </div>
</template>

<script>
    import moment from 'moment';
    import Vue from 'vue';
    import * as config from '../../events/new_calendar/config';
    import * as common from '../../events/new_calendar/common';
    import eventCommonFieldsMixin from "../../events/eventCommonFieldsMixin";

    export default {
        name: "CalendarPageSearchEvent",
        mixins: [eventCommonFieldsMixin],
        props: {
            profile: Object,
            event: Object,
            deletable: {
                default: false
            },
            isMyCalendar: Boolean
        },
        computed: {
            eventLink() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                const calendarOwner = (this.profile && CurrentUser.id !== this.profile.id) ? this.profile : undefined;
                let formattedDate;
                if (this.startDateMoment.isValid()) {
                    formattedDate = this.startDateMoment.format(config.URL_DATE_FORMAT);
                }
                return common.getEventUrl(this.event, formattedDate, calendarOwner);
            },
            showPeriodicMark() {
                return this.event.is_periodic;
            },
            avatarStyle() {
                return {
                    'background-image': `url(${this.event.event_data.avatar_url})`
                };
            },
            deleteButtonHint() {

            },
            startDateMoment() {
                return moment(this.event.start_date, config.VISUAL_DATE_FORMAT);
            },
            greenBoxDateLabel() {
                return this.startDateMoment.format('MMM D');
            }
        },
        methods: {
            getGroupEventPreviewBackground(group) {
                const mediaContentUtils = Vue.getAngularModule('mediaContentUtils');
                return mediaContentUtils.generate_group_background(group.avatar, group.members_avatars);
            }
        }
    };
</script>

<style scoped lang="scss">
    .event-belonging-preview {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
</style>