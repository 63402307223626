var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.header)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c(
            "div",
            {
              staticClass: "form-group",
              class: { "has-error": _vm.url_invalid },
            },
            [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "url_addr" } },
                [_vm._v("Paste your link here:")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.link,
                    expression: "link",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "url_addr",
                  placeholder: "http://example.com",
                  maxlength: "255",
                },
                domProps: { value: _vm.link },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.link = $event.target.value
                    },
                    _vm.process_link,
                  ],
                },
              }),
              _vm._v(" "),
              _vm.url_invalid
                ? _c("span", { staticClass: "help-block" }, [
                    _vm._v("Incorrect link."),
                  ])
                : _vm._e(),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _vm.url_processing
          ? _c("div", { staticClass: "col-sm-12 text-center" }, [
              _c("i", {
                staticClass: "fa fa-refresh fa-spin fa-3x fa-fw",
                attrs: { "aria-hidden": "true" },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.url_processed
          ? _c("div", { staticClass: "col-sm-12" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "site_name" } },
                [_vm._v("Link name:")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.site_name,
                    expression: "site_name",
                  },
                ],
                staticClass: "form-control",
                attrs: { id: "site_name" },
                domProps: { value: _vm.site_name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.site_name = $event.target.value
                  },
                },
              }),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.site_image
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", { style: _vm.get_image_style }),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("div", { staticClass: "col-sm-12 text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.back },
              },
              [_vm._v("Back")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-green",
                attrs: { type: "button", disabled: !_vm.allow_add },
                on: { click: _vm.addLink },
              },
              [_vm._v("Add link")]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }