var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "dashboard-list-item hvr-float-shadow",
      attrs: { href: _vm.link },
    },
    [
      _c(
        "div",
        { staticClass: "dashboard-list-item__image", style: _vm.imageStyle },
        [
          !_vm.white
            ? _c(
                "div",
                { staticClass: "dashboard-list-item__image__day-inside" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "dashboard-list-item__image__day-inside__weekday-label",
                    },
                    [_vm._v(_vm._s(_vm.weekdayLabel))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dashboard-list-item__image__day-inside__date-label",
                    },
                    [_vm._v(_vm._s(_vm.dateLabel))]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.white
            ? _c(
                "div",
                { staticClass: "dashboard-list-item__image__day-inside-white" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "dashboard-list-item__image__day-inside-white__weekday-label",
                    },
                    [_vm._v(_vm._s(_vm.weekdayLabel))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dashboard-list-item__image__day-inside-white__date-label",
                    },
                    [_vm._v(_vm._s(_vm.dateLabel))]
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "dashboard-list-item__label" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }