export default {
    data() {
        return {
            selectedCategory: '',
            userCategories: [
                {name: 'Friends', code: 'friends', icon:'/static/images/mm-icons/categories/friends2.png', size:100},
                {name: 'Family', code: 'family', icon:'/static/images/mm-icons/categories/family2.png', size:100},
                {name: 'Support', code: 'professionals', icon:'/static/images/mm-icons/categories/professionals2.png', size:100},
                {name: 'Other', code: 'other', icon:'/static/images/mm-icons/categories/other2.png', size:90},
            ]
        };
    }
};