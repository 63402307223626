var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sticker_directive",
      class: { sticker_directive_responsive: _vm.responsive },
      style: _vm.style,
      attrs: { "aria-label": "Sticker" },
    },
    [
      _c("img", {
        staticClass: "visible-print",
        staticStyle: { border: "0" },
        attrs: {
          src: _vm.stickerUrl,
          width: "100",
          height: "100",
          alt: "Sticker image",
        },
      }),
      _vm._v(" "),
      _vm.allowDelete
        ? _c("i", {
            staticClass: "fa fa-times story-slide-preview-image__delete_button",
            attrs: {
              role: "button",
              tabindex: "0",
              "aria-label": "Remove Sticker",
            },
            on: {
              click: _vm.del,
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.del.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }