var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.title, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.title))]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "col-xs-6",
            staticStyle: { "vertical-align": "bottom", height: "32px" },
          },
          [
            _c("div", { staticClass: "align-helper" }),
            _vm._v(" "),
            _vm.label
              ? _c("label", { staticStyle: { "vertical-align": "bottom" } }, [
                  _vm._v(_vm._s(_vm.label)),
                ])
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "col-xs-6 text-right",
            staticStyle: { "vertical-align": "bottom" },
          },
          [
            _c("vue-switch-with-label", {
              attrs: { label: "Header", name: "isHeader" },
              model: {
                value: _vm.isHeader,
                callback: function ($$v) {
                  _vm.isHeader = $$v
                },
                expression: "isHeader",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.text,
                expression: "text",
              },
            ],
            ref: "inputText",
            staticClass: "form-control input-text__textarea",
            class: { "input-text__textarea_header": _vm.isHeader },
            attrs: { id: "itextarea", rows: "5" },
            domProps: { value: _vm.text },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.text = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.close },
              },
              [_vm._v("Cancel")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-green",
                attrs: { disabled: _vm.saveDisabled, type: "button" },
                on: { click: _vm.saveChanges },
              },
              [_vm._v("\n                    Save\n                ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }