var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-sm-6" }, [
      _vm._v("\n        Start at "),
      _c("br"),
      _vm._v(" "),
      _c("form", { attrs: { action: "" } }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-6" }, [
            _c(
              "select",
              {
                staticClass: "form-control inline-block",
                attrs: { disabled: _vm.disabled },
                domProps: { value: _vm.value.start.hour },
                on: { input: _vm.onStartHourInput },
              },
              _vm._l(_vm.startHours, function (hour) {
                return _c("option", { domProps: { value: hour.value } }, [
                  _vm._v(_vm._s(hour.label)),
                ])
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-6" }, [
            _c(
              "select",
              {
                staticClass: "form-control inline-block",
                attrs: { disabled: _vm.disabled },
                domProps: { value: _vm.value.start.minute },
                on: { input: _vm.onStartMinuteInput },
              },
              _vm._l(_vm.startMinutes, function (minute) {
                return _c("option", { domProps: { value: minute } }, [
                  _vm._v(_vm._s(minute)),
                ])
              }),
              0
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-6" }, [
      _vm._v("\n        Finishes at"),
      _c("br"),
      _vm._v(" "),
      _c("form", { attrs: { action: "" } }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-6" }, [
            _c(
              "select",
              {
                staticClass: "form-control inline-block",
                attrs: { disabled: _vm.disabled },
                domProps: { value: _vm.value.end.hour },
                on: { change: _vm.onEndTimeChanged, input: _vm.onEndHourInput },
              },
              _vm._l(_vm.endHours, function (hour) {
                return _c("option", { domProps: { value: hour.value } }, [
                  _vm._v(_vm._s(hour.label)),
                ])
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-6" }, [
            _c(
              "select",
              {
                staticClass: "form-control inline-block",
                attrs: { disabled: _vm.disabled },
                domProps: { value: _vm.value.end.minute },
                on: {
                  change: _vm.onEndTimeChanged,
                  input: _vm.onEndMinuteInput,
                },
              },
              _vm._l(_vm.endMinutes, function (minute) {
                return _c("option", { domProps: { value: minute } }, [
                  _vm._v(_vm._s(minute)),
                ])
              }),
              0
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }