function groupGroupsByContainer(groups) {
    const containers = [];

    function appendToContainerListOrCreateNew(group) {
        const groupContainerType = group.is_circle_group ? 'profile' : 'community';
        const groupContainerTarget = (group.community || group.owner);
        const groupContainerId = groupContainerTarget.id;
        let containerFound = false;
        for (const container of containers) {
            if (container.containerType === groupContainerType && container.containerId === groupContainerId) {
                container.groups.push(group);
                containerFound = true;
                break;
            }
        }
        if (!containerFound) {
            containers.push({
                containerType: groupContainerType,
                containerId: groupContainerId,
                target: groupContainerTarget,
                community: group.community,
                profile: group.owner,
                groups: [group]
            });
        }
    }

    for (const group of groups) {
        appendToContainerListOrCreateNew(group);
    }
    return containers;
}

export default groupGroupsByContainer;