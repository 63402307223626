import Vue from 'vue';
import ShareMainTargetSelectPopup from "../../../../vue/common_components/sharing/ShareMainTargetSelectPopup";
import _ from "lodash";
import BlogEntrySharingResource from "../blog_entry_sharing_resource";


const shareBlogEntry = {
    showMainSharePopup(sharingOptions) {
        const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
        const {
            vm,
            popupComponent
        } = simplePopupFactory.mount_vue_popup(ShareMainTargetSelectPopup, {'title': 'Share Diary Entry'});
        return new Promise((res, rej) => {
            return popupComponent
                .show(sharingOptions)
                .then(what => {
                    res(what);
                }, close => {
                    rej('close');
                })
                .finally(() => {
                    if (vm) {
                        vm.$destroy();
                    }
                });
        });
    },
    getMainSharingStatus(entryId) {
        const $http = Vue.getAngularModule('$http');
        return new BlogEntrySharingResource($http).get_sharing_main_status(entryId);
    },
    setCircleSharingExistedEntry(entryId, isMyEntry = true, blogOwner = {}) {
        const $http = Vue.getAngularModule('$http');
        const selectFactory = Vue.getAngularModule('selectFactory');
        let modalResult = selectFactory.endpoint_selector(_.partial(new BlogEntrySharingResource($http).get_friends_for_share_existed_entry, entryId), true, {
            header: `Share Diary Entry with ${isMyEntry ? 'my' : blogOwner.name + '\'s'} Circle?`,
            info: `Select or de-select Members of ${isMyEntry ? 'your' : blogOwner.name + '\'s'} Circle below, or share with all ${isMyEntry ? 'your' : blogOwner.name + '\'s'} Circle automatically.`,
            button_yes: 'Update Circle sharing',
            button_no: 'Cancel',
            empty: `${isMyEntry ? 'You do' : `${blogOwner.name} does`} not have any Circle Members to select`,
            select_all_html: isMyEntry ? 'Share with all My Circle,<br>and new people I add' : `Share with all ${blogOwner.name}'s Circle,<br>and new people ${blogOwner.name} adds`
        }, true, true);
        return new Promise((res, rej) => {
            modalResult
                .then(([newChecked, newUnchecked, all]) => {
                    res([newChecked, newUnchecked, all]);
                }, cancel => {
                    rej('cancel');
                });
        });
    },
    setGroupsSharingExistedEntry(entryId, isMyEntry = true, blogOwner = {}) {
        const $http = Vue.getAngularModule('$http');
        const selectFactory = Vue.getAngularModule('selectFactory');
        let modalResult = selectFactory.endpoint_selector(_.partial(new BlogEntrySharingResource($http).get_groups_for_share_existed_entry, entryId), true, {
            header: `Share the Diary Entry with ${isMyEntry ? 'my' : blogOwner.name + '\'s'} Groups?`,
            info: `Choose this option to share the Diary Entry with whole Groups of people,\n click the Group Avatar to select or de-select the Group you\n wish to share or un-share with.`,
            button_yes: 'Update Group sharing',
            button_no: 'Cancel',
            empty: `${isMyEntry ? 'You do' : `${blogOwner.name} does`} not have any Groups to select`,
        }, true, false);
        return new Promise((res, rej) => {
            modalResult
                .then(([newChecked, newUnchecked, all]) => {
                    res([newChecked, newUnchecked, all]);
                }, cancel => {
                    rej('cancel');
                });
        });
    },
    updateCircleSharing(entryId, all = false, newChecked = [], newUnchecked = []) {
        const $http = Vue.getAngularModule('$http');
        return new BlogEntrySharingResource($http).update_entry_circle_sharing(entryId, all, newChecked, newUnchecked);
    },
    updateGroupSharing(entryId, newChecked = [], newUnchecked = []) {
        const $http = Vue.getAngularModule('$http');
        return new BlogEntrySharingResource($http).update_entry_group_sharing(entryId, newChecked, newUnchecked);
    },
    makePrivate(entryId) {
        const $http = Vue.getAngularModule('$http');
        return new BlogEntrySharingResource($http).make_private(entryId);
    }
};
export default shareBlogEntry;