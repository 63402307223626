<template>
    <span v-if="wikiSnapshotAfter">
        <span>created Wiki </span>
        <strong>
            #{{ wikiSnapshotAfter.id }} "{{ wikiSnapshotAfter.name }}"
        </strong>
    </span>
</template>

<script>
    import SnapshotLogMixin from "./common/SnapshotLogMixin";

    export default {
        name: "WikiCreated",
        mixins: [SnapshotLogMixin],
    };
</script>