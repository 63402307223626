<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="header"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ header }}</h3>
        </div>
        <form enctype="multipart/form-data">
            <input
                    v-show="config.max_count>1"
                    ref="multiFileUploadInput"
                    accept="image/*"
                    type="file"
                    name="file_multi"
                    multiple
                    @change="onUploadFileFieldChanged"
            />
            <input
                    v-show="config.max_count===1"
                    ref="singleFileUploadInput"
                    accept="image/*"
                    type="file"
                    name="file_single"
                    @change="onUploadFileFieldChanged"
            />
        </form>
        <div slot="footer">
            <button @click="close" type="button" class="btn btn-mm btn-default">
                Cancel
            </button>
        </div>
    </basic-modal>
</template>

<script>
    import BasicModal from "../../../../vue/common_components/BasicModal";
    import PopupPromiseMixin from "../../../../vue/mixins/PopupPromiseMixin";

    export default {
        name: "ImageUploadFilesSelectorPopup",
        mixins: [PopupPromiseMixin],
        components: {BasicModal},
        data() {
            return {
                header: 'Select Files',
                config: {},
            };
        },
        computed: {},
        methods: {
            setInitial(config) {
                this.config = config;
            },
            onUploadFileFieldChanged(e) {
                this.save({
                    action: 'files',
                    files: e.target?.files
                });
            },
            async triggerFilesUploadClick() {
                await this.$nextTick();
                if (this.config.max_count === 1) {
                    this.$refs.singleFileUploadInput.click();
                } else if (this.config.max_count > 1) {
                    this.$refs.multiFileUploadInput.click();
                }
            },
            showedHook() {
                this.triggerFilesUploadClick();
            }
        },
    };
</script>

<style scoped>

</style>
