/**
 * Created by orion on 6/24/17.
 */
import urls from 'DjangoUrls';
import _ from 'lodash';
import axios from 'axios';

let {DjangoUrls} = urls;
GroupResource.$inject = ['$http'];

function GroupResource($http) {
    return {
        //events:
        get_events,

        // / events
        get_group_posts,
        get_shared_counters,
        get_shared_stories,
        get_shared_collections,
        get_shared_media_folders,
        get_shared_wikis,
        unshare,
        get_admins_infinite_scroll,
        get_members_infinite_scroll,
        get_members_list_for_members_page,
        get_random_members,

        update_admins,
        delete_member,
        invite_users,
        invite_users_to_view,
        join,
        get_group_conversations,
        create_conversation,
        follow_group_chat,
        unfollow_group_chat,
        mark_group_conversations_as_read,
        get_last_group_conversation,
        get_group_events,

        leave_group,
        edit_group,
        get_group_settings,
        make_me_admin
    };

    ///////////////////

    //EVENTS:


    function get_events(group_id, actual = true, params) {
        const url = DjangoUrls["api.v1:group-get-events"](group_id);
        return axios({
            method: 'GET',
            url,
            params: _.merge({actual}, params)
        });
    }

    //////

    function get_group_posts(group_id, params = {}) {
        const url = DjangoUrls["api.v1:group-get-group-posts"](group_id);

        return axios({
            method: 'GET',
            url,
            params
        });
    }

    function leave_group(group_id) {
        const url = DjangoUrls["api.v1:group-leave-group"](group_id);

        return axios({
            method: 'POST',
            url: url
        });
    }

    function get_shared_counters(group_id) {
        const url = DjangoUrls["api.v1:group-get-shared-counters"](group_id);
        return axios({
            method: 'GET',
            url: url
        });
    }

    function get_shared_stories(group_id, params) {
        const url = DjangoUrls["api.v1:group-get-shared-stories"](group_id);

        return axios({
            method: 'GET',
            url,
            params
        });
    }

    function get_shared_collections(group_id) {
        const url = DjangoUrls["api.v1:group-get-shared-collections"](group_id);

        return axios({
            method: 'GET',
            url: url
        });
    }

    function get_shared_media_folders(group_id, params) {
        const url = DjangoUrls["api.v1:group-get-shared-media-folders"](group_id);

        return axios({
            method: 'GET',
            url,
            params,
        });
    }

    function get_shared_wikis(group_id, params) {
        const url = DjangoUrls["api.v1:group-get-shared-wikis"](group_id);

        return axios({
            method: 'GET',
            url,
            params,
        });
    }

    function unshare(group_id, what, id) {
        const url = DjangoUrls["api.v1:group-unshare"](group_id);

        return axios({
            method: 'PATCH',
            url: url,
            data: {
                what, id
            }
        });
    }

    function edit_group(group_id, data) {
        const url = DjangoUrls["api.v1:group-edit-group"](group_id);

        return axios({
            method: 'PATCH',
            url,
            data
        });
    }

    function get_group_settings(group_id) {
        const url = DjangoUrls["api.v1:group-get-group-settings"](group_id);

        return axios({
            method: 'GET',
            url,
        });
    }

    function get_members_list_for_members_page(group_id) {
        const url = DjangoUrls["api.v1:group-get-members-list-for-members-page"](group_id);
        return axios({
            method: 'GET',
            url
        });
    }

    function get_admins_infinite_scroll(group_id, params = {}) {
        const url = DjangoUrls["api.v1:group-get-admins-infinite-scroll"](group_id);
        return axios({
            method: 'GET',
            url,
            params,
        });
    }

    function get_members_infinite_scroll(group_id, params = {}) {
        const url = DjangoUrls["api.v1:group-get-members-infinite-scroll"](group_id);
        return axios({
            method: 'GET',
            url,
            params,
        });
    }

    function get_random_members(group_id) {
        const url = DjangoUrls["api.v1:group-get-random-members"](group_id);
        return axios({
            method: 'GET',
            url
        });
    }

    function update_admins(group_id, new_checked_ids, new_unchecked_ids) {
        const url = DjangoUrls["api.v1:group-update-admins"](group_id);
        return axios({
            method: 'PATCH',
            url,
            data: {
                new_checked_ids, new_unchecked_ids
            }
        });

    }


    function delete_member(group_id, user_id) {
        const url = DjangoUrls["api.v1:group-delete-member"](group_id);
        return axios({
            method: 'PATCH',
            url,
            data: {
                id: user_id
            }
        });
    }

    function invite_users(group_id, new_checked_ids, new_unchecked_ids) {
        const url = DjangoUrls["api.v1:group-invite-users"](group_id);
        return axios({
            method: 'POST',
            url,
            data: {
                new_checked_ids, new_unchecked_ids
            }
        });
    }

    function invite_users_to_view(group_id, new_checked_ids, new_unchecked_ids) {
        const url = DjangoUrls["api.v1:group-invite-users-to-view"](group_id);
        return axios({
            method: 'POST',
            url,
            data: {
                new_checked_ids, new_unchecked_ids
            }
        });
    }

    function join(id) {
        const url = DjangoUrls["api.v1:group-join"](id);
        return axios({
            method: 'POST',
            url
        });
    }


    function make_me_admin(group_id) {
        const url = DjangoUrls["api.v1:group-make-me-admin"](group_id);
        return axios({
            method: 'POST',
            url
        });
    }

    function get_group_conversations(group_id, params) {
        const url = DjangoUrls["api.v1:group_conversations-get-group-conversations"](group_id);
        return axios({
            method: 'GET',
            url,
            params
        });
    }

    function create_conversation(group_id, name) {
        const url = DjangoUrls["api.v1:group_conversations-create-conversation"](group_id);
        return axios({
            method: 'POST',
            url,
            data: {name}
        });
    }

    function follow_group_chat(group_id, id) {
        const url = DjangoUrls["api.v1:group_conversations-follow-group-chat"](group_id);
        return axios({
            method: 'POST',
            url,
            data: {id}
        });
    }

    function unfollow_group_chat(group_id, id) {
        const url = DjangoUrls["api.v1:group_conversations-unfollow-group-chat"](group_id);
        return axios({
            method: 'POST',
            url,
            data: {id}
        });
    }

    function mark_group_conversations_as_read(group_id) {
        const url = DjangoUrls["api.v1:group_conversations-mark-group-conversations-as-read"](group_id);
        return axios({
            method: 'POST',
            url
        });
    }

    function get_last_group_conversation(group_id) {
        const url = DjangoUrls["api.v1:group_conversations-get-last-group-conversation"](group_id);
        return axios({
            method: 'GET',
            url
        });
    }

    function get_group_events(group_id, params) {  // DEPRECATED
        const url = DjangoUrls["api.v1:group-get-group-events"](group_id);
        return axios({
            method: 'GET',
            url,
            params
        });
    }


}

export default GroupResource;
