<template>
    <div class="task-tags-list">
        <div class="task-tags-list__tag" v-for="tag in wrappedTags" :class="{active:tag.active}">{{ tag.name }}</div>
    </div>
</template>

<script>
    export default {
        name: "TaskTagsList",
        props: {
            tags: {
                type: Array
            }
        },
        computed: {
            wrappedTags() {
                return this.tags.map(t => ({
                    name: t,
                    active: (this.$store && this.$store.state.tagsInFilter) ? this.$store.state.tagsInFilter.indexOf(t) !== -1 : false
                }));
            }
        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";
  @import "../../styles/mixins";

  .task-tags-list {
    &__tag {
      @include tag;

    }
  }
</style>