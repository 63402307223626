var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { staticClass: "header-search__result" }, [
    _c(
      "a",
      {
        staticClass: "header-search__result__link",
        attrs: { href: _vm.result.value },
      },
      [
        _c("span", {
          staticClass:
            "inline-block vertical-middle header-search__result__link__avatar",
          style: _vm.previewStyle,
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "vertical-middle",
          domProps: {
            innerHTML: _vm._s(_vm.highlight(_vm.result.label, _vm.query)),
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }