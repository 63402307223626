import urls from 'DjangoUrls';
import axios from "axios";

let {DjangoUrls} = urls;

const PREFIX = 'api.v1:wiki_read_receipts_multiple-';

const wiki_read_receipts_multiple_resource = {
    create_receipt,
    get_receipts,
    get_receipt,
};

function create_receipt(wikiId, data) {
    const url = DjangoUrls[`${PREFIX}create-receipt`](wikiId);

    return axios({
        method: 'post',
        url,
        data,
    });
}

function get_receipts(wikiId, memberId) {
    const url = DjangoUrls[`${PREFIX}get-receipts`](wikiId);

    return axios({
        method: 'get',
        url,
        params: {
            id: memberId
        },
    });
}

function get_receipt(wikiId, receiptId) {
    const url = DjangoUrls[`${PREFIX}get-receipt`](wikiId);

    return axios({
        method: 'get',
        url,
        params: {
            id: receiptId
        },
    });
}

export default wiki_read_receipts_multiple_resource;