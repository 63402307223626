import _ from 'lodash';

const LISTENERS = {
    'mousemove': [],
    'mouseup': [],
    'mouseout': [],
    'touchmove': [],
};

function addListener(event, callback) {
    LISTENERS[event].push(callback);

}

function removeListener(event, listener) {
    const index = LISTENERS[event].indexOf(listener);
    if (index !== -1) {
        LISTENERS[event].splice(index, 1);
    }
}

const onMouseMove = _.throttle((e) => {
    for (let i = 0; i < LISTENERS['mousemove'].length; i++) {
        LISTENERS['mousemove'][i](e);
    }
}, 2);

const onTouchMove = _.throttle((e) => {
    for (let i = 0; i < LISTENERS['touchmove'].length; i++) {
        LISTENERS['touchmove'][i](e);
    }
}, 2);

function mouseUp(e) {
    for (let i = 0; i < LISTENERS['mouseup'].length; i++) {
        LISTENERS['mouseup'][i](e);
    }
}

function mouseOut(e) {
    for (let i = 0; i < LISTENERS['mouseout'].length; i++) {
        LISTENERS['mouseout'][i](e);
    }
}

document.addEventListener('mousemove', onMouseMove);
document.addEventListener('touchmove', onTouchMove);
document.addEventListener('mouseup', mouseUp);
document.addEventListener('touchend', mouseUp);
document.addEventListener('mouseout', mouseOut);

export default {
    data() {
        return {
            autoBindMouseEvents: true,
            mouseListenersBound: false,
        };
    },
    methods: {
        onMouseOut() { // Default if not special behaviour needed;
            this.onMouseUp();
        },
        bindListeners() {
            if (this.mouseListenersBound) {
                return;
            }
            this.mouseUp = () => {
                this.onMouseUp();
            };

            this.mouseOut = e => {
                let from = e.relatedTarget || e.toElement;
                if (!from || from.nodeName == "HTML") {
                    // stop your drag event here
                    // for now we can just use an alert
                    this.onMouseOut();
                }
            };

            this.onMouseMove = e => {
                // console.log('1');
                this.onPointerMove({x: e.clientX, y: e.clientY});
            };

            this.onTouchMove = e => {
                if (e.targetTouches.length !== 1) {
                    return;
                }
                this.onPointerMove({
                    x: Math.round(e.targetTouches[0].clientX),
                    y: Math.round(e.targetTouches[0].clientY)
                });
            };
            addListener('mousemove', this.onMouseMove);
            addListener('touchmove', this.onTouchMove);
            addListener('mouseup', this.mouseUp);
            addListener('mouseout', this.mouseOut);
            this.mouseListenersBound = true;
        },
        unBindListeners() {
            if (this.mouseListenersBound) {
                removeListener('mouseup', this.mouseUp);
                removeListener('mouseout', this.mouseOut);
                removeListener('mousemove', this.onMouseMove);
                removeListener('touchmove', this.onTouchMove);
                this.mouseListenersBound = false;
            }
        }
    },
    mounted() {
        if (this.autoBindMouseEvents) {
            this.bindListeners();
        }
    },
    beforeDestroy() {
        this.unBindListeners();
    }
};