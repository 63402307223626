var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "header-search",
      class: { "header-search_visible": _vm.searchIsVisible },
    },
    [
      _vm.searchIsVisible
        ? _c(
            "div",
            { staticClass: "header-search__block-input" },
            [
              _c(
                "dropdown",
                {
                  ref: "dropdownResults",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "not-close-elements": _vm.ele,
                    disabled: _vm.dropDownDisabled,
                  },
                  model: {
                    value: _vm.resultsOpened,
                    callback: function ($$v) {
                      _vm.resultsOpened = $$v
                    },
                    expression: "resultsOpened",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "input-group header-search__input-group" },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "input-group-addon header-search__input-addon",
                        },
                        [
                          _c("i", {
                            ref: "dropdownToggleIcon",
                            staticClass:
                              "fal fa-search header-search__input-addon__search-icon",
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.search_query,
                            expression: "search_query",
                          },
                        ],
                        ref: "search-input",
                        staticClass: "form-control header-search__input",
                        attrs: {
                          type: "text",
                          placeholder: "Search users and groups",
                        },
                        domProps: { value: _vm.search_query },
                        on: {
                          blur: _vm.onBlur,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.search_query = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "input-group-addon header-search__input-addon",
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fal fa-times header-search__input-addon__close-icon",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.close.apply(null, arguments)
                              },
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "dropdown" },
                    [
                      _vm.searchPerforming
                        ? _c(
                            "li",
                            {
                              staticClass:
                                "text-center header-search__tech-label",
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-spinner fa-spin",
                              }),
                              _vm._v(" Searching...\n                "),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.noResults
                        ? _c(
                            "li",
                            {
                              staticClass:
                                "text-center header-search__tech-label",
                            },
                            [_vm._v("No Results")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.results, function (result) {
                        return _c("site-header-search-result", {
                          key: result.value,
                          attrs: { query: _vm.search_query, result: result },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }