// import Vue from 'vue';
import SimplePopupFactoryClass from "shared/simple_popups/simplePopupFactory";
import addEmotionPopup from './addEmotionPopup.vue';

function EmotionsFactory() {
    function showAddEmotionsPopup() {

        const simplePopupFactory = new SimplePopupFactoryClass();
        const {popupComponent, vm, fEl} = simplePopupFactory.mount_vue_popup(addEmotionPopup);

        return popupComponent.show()
            .finally(() => {
                setTimeout(() => {
                    fEl?.focus();
                }, 1);
                if (vm) {
                    vm.$destroy();
                }
            });
    }

    return {
        showAddEmotionsPopup
    };
}

export default EmotionsFactory;