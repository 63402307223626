import {DjangoUrls} from 'DjangoUrls';

ManageAdminStateResource.$inject = ['$http'];

function ManageAdminStateResource($http) {
    return {
        get_my_status(circleOwnerId) {
            const url = DjangoUrls[`api.v1:manage_admin_state-get-my-status`](circleOwnerId);
            return $http({
                method: 'GET',
                url
            });
        },
        make_me_media_admin(circleOwnerId) {
            const url = DjangoUrls[`api.v1:manage_admin_state-make-me-media-admin`](circleOwnerId);
            return $http({
                method: 'PATCH',
                url
            });
        },
        make_me_diary_admin(circleOwnerId) {
            const url = DjangoUrls[`api.v1:manage_admin_state-make-me-diary-admin`](circleOwnerId);
            return $http({
                method: 'PATCH',
                url
            });
        },
        make_me_wiki_admin(circleOwnerId, wikiId) {
            const url = DjangoUrls[`api.v1:manage_admin_state-make-me-wiki-admin`](circleOwnerId);
            return $http({
                method: 'PATCH',
                url,
                data: {
                    id: wikiId
                }
            });
        },
    };
}


export default ManageAdminStateResource;