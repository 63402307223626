import UserProfileDeleteAccountResource from "../delete_account_resource";
import urls from 'DjangoUrls';
import picturePasswordFactory from "shared/picture_password/picture_password_factory";

let {DjangoUrls} = urls;
DeleteAccountController.$inject = ['$scope', '$q', '$rootScope', '$preloaded', 'simplePopupFactory', 'UserProfileDeleteAccountResource', 'notifications', '$sce'];

function DeleteAccountController($scope, $q, $rootScope, $preloaded, simplePopupFactory, UserProfileDeleteAccountResource, notifications, $sce) {
    const self = this;

    function init() {
        self.request_exists = $preloaded.request_exists;
        self.profile_to_delete = $preloaded.profile_to_delete;
        self.request_creator = $preloaded.request_creator;
        self.request_id = $preloaded.request_id;
        self.request_code = $preloaded.request_code;
        self.error = undefined;
        if (self.request_exists) {
            self.is_self_deletion = self.profile_to_delete.id === self.request_creator.id;
            if (self.is_self_deletion) {
                self.confirmation_message = $sce.trustAsHtml(`Are you sure you want to delete your profile?<br> Enter password below to delete your account immediately.`);
            } else {
                self.confirmation_message = $sce.trustAsHtml(`Are you sure you want to delete <strong>${self.profile_to_delete.name}</strong>'s profile?
            <br> Enter your password below to delete <strong>${self.profile_to_delete.name}</strong>'s account immediately.`);
            }
        }

    }

    init();

    function processPasswordError(err) {
        if (err.data && err.data.error) {
            if (err.data.type === 'wrong_password') {
                if (err.data.attempts_left <= 0) {
                    redirectToIndex();
                    return;
                }
                self.error = `Wrong Password. This confirmation page will be disabled after ${err.data.attempts_left} attempt${err.data.attempts_left > 1 ? 's' : ''} and new confirmation link will be sent to your email.`;
                $rootScope.hide_dimmer();
            }
            if (err.data.type === 'wrong_code' || err.data.type === 'not_pending') {
                redirectToIndex();
            }
        } else {
            $rootScope.hide_dimmer();
            notifications.error('Error');
        }
    }

    self.cancel_deletion = function () {
        $rootScope.show_dimmer();
        UserProfileDeleteAccountResource.cancel(self.request_id, self.request_code, self.password)
            .then(resp => {
                $rootScope.hide_dimmer();
                return simplePopupFactory.show_popup('Delete Account', `Delete request was canceled`, 'OK')
                    .then(redirectToIndex, redirectToIndex);

            }, err => {
                processPasswordError(err);
            });
    };

    self.confirm_deletion = function () {
        $rootScope.show_dimmer();
        UserProfileDeleteAccountResource.confirm(self.request_id, self.request_code, self.password)
            .then(resp => {
                $rootScope.hide_dimmer();
                return simplePopupFactory.show_popup('Delete Account', `The account has been deleted`, 'OK')
                    .then(redirectToIndex, redirectToIndex);
            }, err => {
                processPasswordError(err);
            });
    };

    function showConfirmationPopup() {
        let action;
        let confirmation_message;
        if (self.profile_to_delete.id === self.request_creator.id) {
            confirmation_message = `Are you sure you want to delete your profile?`;
        } else {
            confirmation_message = `Are you sure you want to delete ${self.profile_to_delete.name}'s profile?`;
        }
        simplePopupFactory.show_popup('Delete Account', confirmation_message, 'Delete', 'Cancel deletion', 'btn-red')
            .then(y => {
                action = 'delete';
                return UserProfileDeleteAccountResource.confirm(self.request_id, self.request_code);
            }, reason => {
                action = 'cancel';
                // if (reason === 'close') {
                //
                // }
                // if (reason === 'cancel') {
                //
                // }
                $rootScope.show_dimmer();
                return UserProfileDeleteAccountResource.cancel(self.request_id, self.request_code);
            })
            .then(resp => {
                $rootScope.hide_dimmer();
                if (action === 'cancel') {
                    return simplePopupFactory.show_popup('Cancelled', `Delete request was canceled`, 'OK');
                }
                if (action === 'delete') {
                    return simplePopupFactory.show_popup('Deleted', `Account was deleted`, 'OK');
                }
            }, err => {
                notifications.error(err || 'Error');
            })
            .then(redirectToIndex, redirectToIndex);
    }

    function redirectToIndex() {
        $rootScope.show_dimmer();
        document.location.href = DjangoUrls['index']();
    }

    self.input_picture_password = function () {
        picturePasswordFactory.input_password().then(function successCallback(pass) {
            self.password = pass;
        });
    };

    $scope.$watch(() => self.password, () => {
        self.error = undefined;
    });
}

export default DeleteAccountController;