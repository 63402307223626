var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { transition: 0, backdrop: false },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          attrs: { id: "close_popup" },
          on: { click: _vm.closePopup },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [
          _vm._v("What would you like to attach?"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "select-menu-container center" }, [
        _c("div", { staticClass: "select-menu-item" }, [
          _c("div", { staticClass: "panel panel-primary" }, [
            _c(
              "div",
              {
                staticClass: "panel-heading shortcuts",
                on: {
                  click: function ($event) {
                    return _vm.select("file")
                  },
                },
              },
              [
                _c("h3", { staticClass: "panel-title" }, [_vm._v("File")]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "far fa-file-alt select-menu-item__main-icon",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "select-menu-item" }, [
          _c("div", { staticClass: "panel panel-primary" }, [
            _c(
              "div",
              {
                staticClass: "panel-heading shortcuts",
                on: {
                  click: function ($event) {
                    return _vm.select("link")
                  },
                },
              },
              [
                _c("h3", { staticClass: "panel-title" }, [_vm._v("Link")]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "far fa-link select-menu-item__main-icon",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "select-menu-item" }, [
          _c("div", { staticClass: "panel panel-primary" }, [
            _c(
              "div",
              {
                staticClass: "panel-heading shortcuts",
                on: {
                  click: function ($event) {
                    return _vm.select("location")
                  },
                },
              },
              [
                _c("h3", { staticClass: "panel-title" }, [_vm._v("Location")]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "far fa-map-marker select-menu-item__main-icon",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }