<template>
    <tr :class="{'warning':!member.is_active,'active':member.is_active}">
        <td>
            <strong v-if="member.membership_type==='userprofile'">
                <simple-link :target="member.userprofile"></simple-link><br>
            </strong>  <i v-if="isOwner">(Owner)</i>
            <span v-if="member.membership_type==='access_link'">{{ member.email || member.name }}</span>
            <span v-if="member.membership_type==='circle'">My Circle</span>
            <span v-if="member.membership_type==='circle-invitation'"><strong>{{ member.invitation.email }}</strong><br> (Pending Circle Invitation)</span>
            <span v-if="member.membership_type==='friend-request'"><strong><simple-link :target="member.invitation.profile"></simple-link></strong><br> (Pending Circle Invitation)</span>
            <span v-if="member.membership_type==='group'"><strong><simple-link :target="member.group"></simple-link></strong><br>  (Group)</span>
            <span v-if="member.membership_type==='community'"><strong><simple-link :target="member.community"></simple-link></strong><br>  (Community)</span>
            <i v-if="member.membership_type==='access_link'"><br><a :href="member.access_link" target="_blank">access
                link</a> <i
                    class="fa fa-clipboard pointer color-dark-blue" :data-clipboard-text="member.access_link"
                    ref="membersAccessLink"
                    v-tooltip="'Copy to Clipboard'"></i></i>
        </td>
        <td>
            <span v-if="!member.role">-</span>
            <span v-if="member.role">{{ member.role |cutName(30) }}</span>
        </td>
        <td>{{ member.created_at | gbdate}}
            <small v-show="!member.is_active"><br><i>(Waiting confirmation)</i></small>
        </td>
        <td class="text-center">
            <vue-switch name="send_notifications"
                        v-model="member.send_notifications"
                        v-show="member.membership_type==='userprofile' && member.have_any_edit_access && !isMe"
                        v-tooltip="'We will notify you about any new media files that have been uploaded by this member'"
                        @input="toggleMemberNotificationsState(member)"></vue-switch>
        </td>
        <td class="text-center">
            <vue-switch name="is_admin" v-model="member.is_admin" v-show="member.userprofile"
                        @input="toggleMemberAdminStatus(member)"></vue-switch>
        </td>
        <td class="text-center">
            <i class="fa fa-pencil fa-2"
               :class="{'pointer':!member.is_admin,'color-gray':member.is_admin}"
               @click="openPermissionsTree"></i>
        </td>
        <td class="text-center">
            <i class="fa fa-trash-o fa-2 pointer color-red"
               v-if="!isOwner&&!member.is_admin"
               @click="removeMember(member)"
            ></i>
        </td>
    </tr>
</template>

<script>
    let Clipboard = require('clipboard');
    import Vue from 'vue';
    import MEDIA_CONFIG from '../config.js';

    export default {
        name: "MembersTableMember",
        props: {
            member: {
                type: Object
            },
            isOwner: {
                type: Boolean,
                default: false
            },
            owner: {
                type: Object
            }
        },
        data() {
            return {
                isClipboardSupported: Clipboard.isSupported()
            };

        },
        mounted() {
            if (this.member.membership_type === 'access_link' && this.isClipboardSupported) {
                let clipboardIcon = this.$refs.membersAccessLink;
                let clipboard = new Clipboard(clipboardIcon);
                clipboard.on('success', e => {
                    Vue.notifications.success('Copied');
                });

                clipboard.on('error', e => {
                    Vue.notifications.error('You browser doesn\'t support auto copy. Please copy link manually');
                });
            }

        },
        computed: {
            isMe() {
                if (this.member.membership_type === 'userprofile' && this.member.userprofile) {
                    const CurrentUser = Vue.getAngularModule('CurrentUser');
                    return this.member.userprofile.id === CurrentUser.id;
                }
            }
        },
        methods: {
            openPermissionsTree() {
                this.$emit('openPermissionsTree', this.member);
            },
            toggleMemberAdminStatus(member) {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                const $q = Vue.getAngularModule('$q');
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const MediaMembershipResource = Vue.getAngularModule('MediaMembershipResource');
                const notifications = Vue.getAngularModule('notifications');
                let resource;
                let unmakeSelfAdmin = false;
                if (!member.is_admin) {
                    resource = MediaMembershipResource.unmake_admin;
                    if (member.userprofile.id === CurrentUser.id) {
                        unmakeSelfAdmin = true;
                    }
                } else {
                    resource = MediaMembershipResource.make_admin;
                    Vue.set(member, 'tree_opened', false);
                }

                let confirmation = $q.defer();

                if (unmakeSelfAdmin) {
                    simplePopupFactory.show_popup('Warning!', 'Warning, you are about to remove yourself as Admin for this Media. This cannot be undone, are you sure?', 'Yes', 'Cancel')
                        .then(() => {
                            confirmation.resolve();
                        }, () => {
                            member.is_admin = true;
                            confirmation.reject();
                            return  new Promise(()=>{});
                        });
                } else {
                    confirmation.resolve();
                }
                confirmation.promise.then(() => {
                    $rootScope.show_dimmer();
                    return resource(this.owner.id, member.id);
                }, () => new Promise(()=>{}))
                    .then(resp => {
                        notifications.success('Updated');
                        if (!member.is_admin) {
                            if (!member.have_any_edit_access) {
                                member.have_any_edit_access = true;
                            }
                        }
                        if (unmakeSelfAdmin) {
                            this.$router.push(`${MEDIA_CONFIG.MAIN_MEDIA_PREFIX}${this.owner.name}/`);
                        }
                    }, err => {
                        member.is_admin = !member.is_admin;
                        notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            toggleMemberNotificationsState(member) {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const MediaMembershipResource = Vue.getAngularModule('MediaMembershipResource');
                const notifications = Vue.getAngularModule('notifications');
                let resource;
                if (!member.send_notifications) {
                    resource = MediaMembershipResource.disable_notifications;
                } else {
                    resource = MediaMembershipResource.enable_notifications;
                }
                resource(this.owner.id, member.id)
                    .then(resp => {
                        notifications.success('Updated');
                    }, err => {
                        notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            removeMember(member) {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                const $q = Vue.getAngularModule('$q');
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const MediaMembershipResource = Vue.getAngularModule('MediaMembershipResource');
                const notifications = Vue.getAngularModule('notifications');

                let removeSelf = false;
                if (member.userprofile && member.userprofile.id === CurrentUser.id) {
                    removeSelf = true;
                }
                simplePopupFactory.show_popup('Remove Member', 'Are you sure you want to remove this member from Media?', 'Remove', 'Cancel')
                    .then(y => {
                        $rootScope.show_dimmer();
                        return MediaMembershipResource.remove_member(this.owner.id, member.id);
                    }, () => new Promise(()=>{}))
                    .then(resp => {

                        notifications.success('Removed');
                        if (removeSelf) {
                            this.$router.push(`${MEDIA_CONFIG.MAIN_MEDIA_PREFIX}${this.owner.name}/`);
                        } else {
                            this.$emit('onDelete', member);
                        }
                    }, err => notifications.error(err || 'Error'))
                    .finally($rootScope.hide_dimmer);
            }
        }
    };
</script>

<style scoped>

</style>