<template>
    <div class="table-responsive">
        <table class="table table-bordered">
            <thead>
            <tr>
                <th>Shared with</th>
                <th>Role</th>
                <th>Read Receipts</th>
                <th>Date Invited</th>
                <th class="text-center">Is Admin</th>
                <th class="text-center">Access given</th>
                <th class="text-center">Remove Access</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="member in sortedMembers">
                <wiki-members-list-table-member
                        :member="member"
                        :key="member.id"
                        @removeMember="removeMember"
                        @updateAccessLinkTerm="updateAccessLinkTerm"
                        @protectAccessLinkByPassword="protectAccessLinkByPassword"
                        @toggleMemberAdminStatus="toggleMemberAdminStatus"
                        @openPermissionsTree="openPermissionsTree"
                        @sendReadReceipt="sendReadReceipt"
                        @showReadReceipts="showReadReceipts"
                        :isOwner="member.userprofile&&member.userprofile.id===owner.id"
                ></wiki-members-list-table-member>
                <tr v-if="member.tree_opened">
                    <td colspan="7">
                        <wiki-tree
                                :sections="sections"
                                :owner="owner"
                                :member="member"
                                :key="member.id"
                        ></wiki-tree>
                    </td>
                </tr>
            </template>
            </tbody>
        </table>
    </div>
</template>

<script>
    import _ from 'lodash';
    import WikiTree from './WikiTree.vue';
    import WikiMembersListTableMember from './WikiMembersListTableMember.vue';

    export default {
        name: "wiki-members-list-table",
        components: {
            WikiTree,
            WikiMembersListTableMember
        },
        data() {
            return {
                sortedMembers: []
            };
        },
        props: {
            members: {
                type: Array
            },
            sections: {
                type: Array
            },
            owner: {
                type: Object
            }
        },
        mounted() {
            console.log('mounted', this.members);

            // _.sortBy(this.sortedMembers, m => m.membership_type === 'public');
        },
        methods: {
            toggleMemberAdminStatus(member) {
                this.$emit('toggleMemberAdminStatus', member);
            },
            openPermissionsTree(member) {
                if (member.is_admin) {
                    return;
                }
                this.$emit('openTreeOnMember', member);
            },
            sendReadReceipt(member) {
                this.$emit('sendReadReceipt', member);
            },
            showReadReceipts(member) {
                this.$emit('showReadReceipts', member);
            },
            removeMember(member) {
                this.$emit('removeMember', member);
            },
            updateAccessLinkTerm(member) {
                this.$emit('updateAccessLinkTerm', member);
            },
            protectAccessLinkByPassword(member) {
                this.$emit('protectAccessLinkByPassword', member);
            },
        },
        watch: {
            members(n) {
                this.sortedMembers = _.sortBy(this.members, m => m.membership_type !== 'public');
            }
        }
    };
</script>

<style scoped>

</style>
