var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { transition: 0 },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          attrs: { id: "close_popup" },
          on: { click: _vm.close_popup },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [_vm._v("Invite By Email")]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row", staticStyle: { "margin-bottom": "15px" } },
        [
          _c("div", {
            staticClass: "col-sm-12",
            domProps: { innerHTML: _vm._s(_vm.info) },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "select-menu-container center" }, [
        _c("div", { staticClass: "select-menu-item" }, [
          _c("div", { staticClass: "panel panel-primary" }, [
            _c(
              "div",
              {
                staticClass: "panel-heading shortcuts",
                on: {
                  click: function ($event) {
                    return _vm.select("register")
                  },
                },
              },
              [
                _c("h3", { staticClass: "panel-title" }, [
                  _vm._v("Invite"),
                  _c("br"),
                  _vm._v("to Register"),
                ]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "fa fa-sign-in select-menu-item__main-icon",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "select-menu-item" }, [
          _c("div", { staticClass: "panel panel-primary" }, [
            _c(
              "div",
              {
                staticClass: "panel-heading shortcuts",
                on: {
                  click: function ($event) {
                    return _vm.select("access-link")
                  },
                },
              },
              [
                _c("h3", { staticClass: "panel-title" }, [
                  _vm._v("Send"),
                  _c("br"),
                  _vm._v("Access Link"),
                ]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "fa fa-link select-menu-item__main-icon",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }