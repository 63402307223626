<template>
    <div class="">
        <vue-simple-header>
            <span slot="headerTitle">Post Comments</span>
            <span slot="panelRight">
                    <vue-folder-button
                            color="blue"
                            :target="backUrl"
                            icon-class="fa-reply"
                            text="Back"
                            ref="backButton"
                    ></vue-folder-button>
            </span>
        </vue-simple-header>
        <comments-list-infinite-scroll
                :target-id="postId"
                :comments-resource="commentsResource"
                :allow-add="allowAdd"
                :allow-like="true"
                :allow-delete-own="true"
                :allow-delete="isAdmin"

                :initial-reply-to="initialReplyTo"
        ></comments-list-infinite-scroll>
        <a href="#" aria-hidden="true" @focus="focusToBackButton"></a>
    </div>
</template>

<script>
    import Vue from 'vue';
    import BackButtonMixin from "../../../../vue/mixins/BackButtonMixin";
    import CommentsInfiniteScrollResourceFactory from "shared/comments/resource_infinite_scroll_factory";
    import {CurrentUserClass} from "shared/CurrentUser";
    import CommentsListInfiniteScroll from "shared/comments/CommentsListInfiniteScroll";
    import ActivityReplyToSupportMixin from "../../../../vue/mixins/ActivityReplyToSupportMixin";

    export default {
        name: "UserActivityPostCommentsPage",
        components: {CommentsListInfiniteScroll},
        mixins: [BackButtonMixin, ActivityReplyToSupportMixin],
        props: {
            postId: Number,
            isAdmin: Boolean,
        },
        computed: {
            allowAdd() {
                return new CurrentUserClass().has_permission('post-comments');
            },
            commentsResource() {
                const $http = Vue.getAngularModule('$http');
                return CommentsInfiniteScrollResourceFactory('user_activity_post_comments')($http);
            },

        },
        methods:{
            focusToBackButton(){
                this.$refs.backButton?.$el?.focus();
            }
        }
    };
</script>