var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { transition: 0, "append-to-body": true, backdrop: false },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          attrs: { id: "close_popup" },
          on: { click: _vm.closePopup },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [
          _vm._v("Please review your Profile"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("p", { staticClass: "popup-paragraph" }, [_vm._v("Hello,")]),
          _vm._v(" "),
          _c("p", { staticClass: "popup-paragraph" }, [
            _vm._v("Your profile is currently set to ‘"),
            _c("strong", [_vm._v("public")]),
            _vm._v(
              "’ which means other users on Multi Me can search for you and invite you to join their Circles or Groups."
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "popup-paragraph" }, [
            _vm._v(
              "You can change this now to a private setting by choosing to ‘"
            ),
            _c("strong", [_vm._v("hide your profile")]),
            _vm._v(
              "’ using the switch below, or if you are happy for people to search and invite you please just close this pop up."
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "popup-paragraph" }, [
            _vm._v(
              "\n                You can update your profile settings at any time by going to your "
            ),
            _c("strong", [
              _c("a", { attrs: { href: _vm.accountSettingsLink } }, [
                _vm._v("Account Settings"),
              ]),
            ]),
            _vm._v(" page.\n            "),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12 margin-15-top" },
          [
            _c("vue-switch-with-label", {
              attrs: { label: "Hide my Profile", name: "hide_my_profile" },
              on: { input: _vm.onInput },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-green save-button",
            on: { click: _vm.save },
          },
          [_vm._v("\n            OK\n        ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }