var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.header)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.info,
              expression: "info",
            },
          ],
          staticClass: "row margin-5-bottom",
        },
        [
          _c("div", {
            staticClass: "col-sm-12",
            staticStyle: { "font-size": "18px" },
            domProps: { innerHTML: _vm._s(_vm.info) },
          }),
        ]
      ),
      _vm._v(" "),
      _vm.allowEmail
        ? _c("div", { staticClass: "row margin-15-bottom margin-10-top" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c(
                "div",
                {
                  class: {
                    "has-error": !_vm.emailIsValid && !_vm.emailIsEmpty,
                    "has-success": _vm.emailIsValid,
                  },
                },
                [
                  _c("label", [
                    _vm._v("Email: "),
                    _c(
                      "span",
                      {
                        class: {
                          "color-gray": !_vm.highLight.email,
                          "color-red": _vm.highLight.email,
                        },
                      },
                      [_vm._v("(required)")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email",
                      },
                    ],
                    ref: "emailField",
                    staticClass: "form-control",
                    attrs: {
                      type: "email",
                      placeholder: "email@example.com",
                      required: "",
                    },
                    domProps: { value: _vm.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.email = $event.target.value
                      },
                    },
                  }),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.allowCategory
        ? _c("div", { staticClass: "row margin-15-bottom" }, [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("label", [
                  _vm._v("Select category: "),
                  _c(
                    "span",
                    {
                      class: {
                        "color-gray": !_vm.highLight.category,
                        "color-red": _vm.highLight.category,
                      },
                    },
                    [_vm._v("(required)")]
                  ),
                ]),
                _vm._v(" "),
                _c("circle-member-category-selector", {
                  on: { change: _vm.focusToSaveButton },
                  model: {
                    value: _vm.selectedCategory,
                    callback: function ($$v) {
                      _vm.selectedCategory = $$v
                    },
                    expression: "selectedCategory",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.opened && !_vm.isRoleDisabled
        ? _c("div", { staticClass: "row margin-15-bottom" }, [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("label", [_vm._v("What role does this person play?")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.role,
                      expression: "role",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    id: "role-input",
                    disabled: _vm.isRoleDisabled,
                    placeholder: _vm.rolePlaceholder,
                  },
                  domProps: { value: _vm.role },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.role = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c("typeahead", {
                  attrs: { target: "#role-input", data: _vm.roles },
                  model: {
                    value: _vm.role,
                    callback: function ($$v) {
                      _vm.role = $$v
                    },
                    expression: "role",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.opened && _vm.allowTerm
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", { staticClass: "row margin-15-bottom" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c("div", [
                    _c("strong", [_vm._v("Link expires in:")]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.validityTerm,
                            expression: "validityTerm",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.validityTerm = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      _vm._l(_vm.accessLinkPossibleTerms, function (term) {
                        return _c(
                          "option",
                          { key: term.val, domProps: { value: term.val } },
                          [
                            _vm._v(
                              _vm._s(term.name) +
                                "\n                            "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("button", { staticClass: "btn btn-mm", on: { click: _vm.close } }, [
          _vm._v("Cancel"),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.saveButtonTooltip,
                expression: "saveButtonTooltip",
              },
            ],
            ref: "saveButton",
            staticClass: "btn btn-mm btn-green save-button",
            class: { disabled: _vm.saveDisabled },
            attrs: { disabled: _vm.saveDisabled },
            on: { click: _vm.send },
          },
          [_vm._v("\n            " + _vm._s(_vm.button_yes) + "\n        ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: { href: "/", "aria-hidden": "true" },
            on: { focus: _vm.focusToClose },
          },
          [_vm._v(" ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }