const PopupMixin = {
    data() {
        return {
            opened: false,
        };
    },
    methods: {
        close() {
            this.opened = false;
        }
    }
};

export default PopupMixin;