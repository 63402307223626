import * as common from "./new_calendar/common";

export default {
    computed: {
        timeLabel() {
            if (this.event.event_data.whole_day) {
                return 'All Day';
            } else {
                return `${this.event.event_data.start_time} - ${this.event.event_data.end_time}`;
            }
        },
        dateLabel() {
            if (this.event.end_date === this.event.start_date) {
                return `${this.event.end_date}`;
            } else {
                return `${this.event.start_date} - ${this.event.end_date}`;
            }
        },
        repeatLabel() {
            if (!this.event.period_data) {
                return;
            }
            return common.getPeriodTextRepresentation(this.event.period_data.period_start_date, this.event.period_data.repeat_type, this.event.period_data.days_of_week, this.event.period_data.skip_repeat_period);
        },
        inListTimeRepresentationLabel() {
            if (this.event.period_data) {
                return `${this.timeLabel}, ${this.repeatLabel}`;
            } else {
                return `${this.timeLabel}, ${this.dateLabel}`;
            }
        }
    }
};