<template>
    <div class="media-folder">
        <i class="fa pointer"
           v-show="folder.has_sub_items"
           :class="chevronClasses"
           @click="toggleExpandFolder"
        ></i>

        <i class="fa fa-pencil"
           v-if="allowAssignEditPermission"
           :class="editButtonClasses"
           @click="toggleFolderLevelEditPermission"></i>

        <i class="fa fa-eye"
           :class="viewButtonClasses"
           @click="toggleFolderLevelViewPermissionLevel"
        ></i>

        <i class="fa fa-folder-o"></i> {{folder.name}}
        <div class="media-folder__subfolders-list">
            <media-tree-folder
                    :current-user="currentUser"
                    :member="member"
                    :folder="subfolder"
                    :allowAssignEditPermission="allowAssignEditPermission"
                    :permissionsList="permissionsList"
                    @setFolderLevelPermission="setFolderLevelPermission"
                    v-show="isExpanded"
                    :inheritedPermissionLevel="resultPermissionLevel"
                    v-for="subfolder in folder.folders"
                    :key="subfolder.id"
            ></media-tree-folder>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';
    import editViewButtonsClassesMixin from '../../../wiki/wiki_settings_page/editViewButtonsClassesMixin';

    function checkIfSubfolderHasRedefinedPermissions(subfolder, permissionsList, triggerPermissions=[]) {
        if (!subfolder.folders.length) {
            return false;
        } else {
            let res = false;
            let directChildrenIdList = subfolder.folders.map(f => f.id);
            for (let directChildrenId of directChildrenIdList) {
                if (_.findIndex(permissionsList, p => (p.level === 'folder' && p.target_id === directChildrenId && triggerPermissions.includes(p.value))) !== -1) {
                    return true;
                }
            }
            for (let subsubfolder of subfolder.folders) {
                if (checkIfSubfolderHasRedefinedPermissions(subsubfolder, permissionsList)) {
                    res = true;
                }
            }
            return res;
        }
    }

    export default {
        mixins: [editViewButtonsClassesMixin],
        props: {
            folder: {
                type: Object
            },
            currentUser: Object,
            allowAssignEditPermission: Boolean,
            inheritedPermissionLevel: String,
            member: Object,
            permissionsList: Array,
        },
        data() {
            return {
                isExpanded: false
            };
        },
        computed: {
            ownPermissionLevel() {
                const ownPermissionIndex = _.findIndex(this.permissionsList, p => (p.level === 'folder' && p.target_id === this.folder.id));
                if (ownPermissionIndex !== -1) {
                    return this.permissionsList[ownPermissionIndex].value;
                }
                return 'unset';
            },
            isAccessCodeMembership() {
                return this.member.membership_type === 'access_link';
            },
            accessCodeIfAccessCodeMembership() {
                if (!this.isAccessCodeMembership) {
                    return;
                }
                return this.member.access_code;
            },
            resultPermissionLevel() {
                if (this.ownPermissionLevel === 'unset') {
                    return this.inheritedPermissionLevel;
                } else {
                    return this.ownPermissionLevel;
                }
            },
            canOwnEditPermissionBeChanged() {
                return true;
            },
            canOwnViewPermissionBeChanged() {
                return this.resultPermissionLevel !== 'edit';
            },
            hasRedefinedInChildViewProperty() {
                return checkIfSubfolderHasRedefinedPermissions(this.folder, this.permissionsList, this.triggerViewPermissions);
            },
            hasRedefinedInChildEditProperty() {
                return checkIfSubfolderHasRedefinedPermissions(this.folder, this.permissionsList, this.triggerEditPermissions);
            },
            chevronClasses() {
                const classes = [];
                if (this.isExpanded) {
                    classes.push('fa-chevron-down');
                } else {
                    classes.push('fa-chevron-right');
                }
                return classes;
            },
        },
        methods: {
            toggleExpandFolder() {
                this.isExpanded = !this.isExpanded;
            },
            toggleFolderLevelEditPermission() {
                if (!this.canOwnEditPermissionBeChanged) {
                    return;
                }
                if (this.resultPermissionLevel === 'edit') {
                    this.$emit('setFolderLevelPermission', {folder: this.folder, value: 'view'});
                    return;
                }
                if (this.resultPermissionLevel === 'view') {
                    this.$emit('setFolderLevelPermission', {folder: this.folder, value: 'edit'});
                    return;
                }
                if (this.ownPermissionLevel === 'unset') {
                    this.$emit('setFolderLevelPermission', {folder: this.folder, value: 'edit'});
                    return;
                }
                if (this.ownPermissionLevel === 'none') {
                    this.$emit('setFolderLevelPermission', {folder: this.folder, value: 'edit'});
                    return;
                }
            },
            toggleFolderLevelViewPermissionLevel() {
                if (!this.canOwnViewPermissionBeChanged) {
                    return;
                }
                if (this.ownPermissionLevel === 'unset') {
                    if (this.inheritedPermissionLevel === 'unset') {
                        this.$emit('setFolderLevelPermission', {folder: this.folder, value: 'view'});
                    }
                    if (this.inheritedPermissionLevel === 'view') {
                        this.$emit('setFolderLevelPermission', {folder: this.folder, value: 'none'});
                    }
                    if (this.inheritedPermissionLevel === 'none') {
                        this.$emit('setFolderLevelPermission', {folder: this.folder, value: 'view'});
                    }
                    if (this.inheritedPermissionLevel === 'edit') {
                        this.$emit('setFolderLevelPermission', {folder: this.folder, value: 'view'});
                    }
                }
                if (this.ownPermissionLevel === 'view') {
                    if (this.inheritedPermissionLevel === 'unset') {
                        this.$emit('setFolderLevelPermission', {folder: this.folder, value: 'unset'});
                    }
                    if (this.inheritedPermissionLevel === 'view') {
                        this.$emit('setFolderLevelPermission', {folder: this.folder, value: 'none'});
                    }
                    if (this.inheritedPermissionLevel === 'none') {
                        this.$emit('setFolderLevelPermission', {folder: this.folder, value: 'unset'});
                    }
                    if (this.inheritedPermissionLevel === 'edit') {
                        this.$emit('setFolderLevelPermission', {folder: this.folder, value: 'none'});
                    }
                }
                if (this.ownPermissionLevel === 'none') {
                    if (this.inheritedPermissionLevel === 'unset') {
                        this.$emit('setFolderLevelPermission', {folder: this.folder, value: 'unset'});
                    }
                    if (this.inheritedPermissionLevel === 'view') {
                        this.$emit('setFolderLevelPermission', {folder: this.folder, value: 'unset'});
                    }
                    if (this.inheritedPermissionLevel === 'none') {
                        this.$emit('setFolderLevelPermission', {folder: this.folder, value: 'view'});
                    }
                    if (this.inheritedPermissionLevel === 'edit') {
                        this.$emit('setFolderLevelPermission', {folder: this.folder, value: 'view'});
                    }
                }
            },
            setFolderLevelPermission(data) {
                this.$emit('setFolderLevelPermission', data);
            }
        },
        name: "MediaTreeFolder"
    };
</script>

<style scoped lang="scss">
    .media-folder {
        &__subfolders-list {
            margin-left: 15px;
        }
        .fa-eye {
            &.disabled {
                opacity: (0.5);
            }
        }
        .fa-pencil {
            &.disabled {
                opacity: (0.5);
            }

        }
    }
</style>