import Vue from 'vue';

const captionPlayerBus = new Vue();

class SoundPlayer {
    constructor() {
    }

    playSound(url) {
        if (!this.audio) {
            this.audio = new Audio();
        }
        this.audio.src = url;
        captionPlayerBus.$emit('onPlayStop');
        this.audio.load();

        this.audio.play();
        this.audio.onended = () => {
            captionPlayerBus.$emit('onPlayStop');
            captionPlayerBus.$emit('onPlayEnd');
        };
    }

    stopPlay() {
        if (!this.audio) {
            return;
        }
        captionPlayerBus.$emit('onPlayStop');
        this.audio.pause();
    }
}

const sp = new SoundPlayer();


captionPlayerBus.$on('playSound', url => {
    sp.playSound(url);
});
captionPlayerBus.$on('stopSound', url => {
    sp.stopPlay(url);
});


export default captionPlayerBus;