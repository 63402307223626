/**
 * Created by orion on 03.06.16.
 */
import urls from 'DjangoUrls';

let {DjangoUrls} = urls;

LoginPageController.$inject = ['$scope', '$rootScope', '$location', '$uibModal', '$http', '$timeout', '$preloaded', '$cookies', 'simplePopupFactory', 'AuthResource'];
import './style.scss';
import picturePasswordFactory from "shared/picture_password/picture_password_factory";

function LoginPageController($scope, $rootScope, $location, $uibModal, $http, $timeout, $preloaded, $cookies, simplePopupFactory, AuthResource) {
    $scope.pre_user = $preloaded['user'];
    if ($preloaded['user']) {
        $scope.username = $preloaded['user']['username'];
    }
    $scope.reset_pre_user = function () {
        delete $scope.pre_user;
        $cookies.remove('profile_id', {path: DjangoUrls['auth_login']()});
    };

    $scope.error = undefined;

    function getUrlVars() {
        let vars = [], hash;
        let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for (let i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
        return vars;
    }

    $scope.keepLoggedIn = false;
    $scope.toggleKeepLoggedIn = () => {
        $scope.keepLoggedIn = !$scope.keepLoggedIn;
    };

    $scope.login = function () {
        $rootScope.show_dimmer();

        const data = {
            username: $scope.username,
            password: $scope.password,
            keep_logged_in: $scope.keepLoggedIn,
        };

        let params = getUrlVars();
        if (params.next) {
            data.next = params.next
        }

        AuthResource.login(data).then(function successCallback(response) {
            if (response.data.success) {
                document.location.href = response.data.redirect_to;
            }
        }, function errorCallback(response) {
            $rootScope.hide_dimmer();
            if (response.data.message) {
                $scope.error = response.data.message;
            }
        });
    };

    $scope.input_picture_password = function () {

        picturePasswordFactory.input_password().then(function successCallback(pass) {
            $scope.password = pass;
            if ($scope.username) {
                $scope.login();
            }

        });
    };

    function notify_buddies(userprofile_id) {
        var url = DjangoUrls["api.v1:auth-notify-buddies"]();
        $rootScope.show_dimmer();
        $http({
            method: 'POST',
            url: url,
            data: {
                userprofile: userprofile_id

            }
        }).then(function successCallback(response) {
            $rootScope.hide_dimmer();
            simplePopupFactory.show_popup('Buddies notified', response.data.message, 'OK', null);
        }, function errorCallback(response) {
            $rootScope.show_dimmer();
            simplePopupFactory.show_popup('Error', response.data.message, 'OK', null);
        });
    }

    function show_email_sent_message() {
        simplePopupFactory.show_popup('Reset link sent.', 'Check your email and follow instructions.', 'OK', null);
    }

    function create_reset_request(userprofile_id) {
        var url = DjangoUrls["api.v1:auth-password-reset-request"]();
        $rootScope.show_dimmer();
        $http({
            method: 'POST',
            url: url,
            data: {
                userprofile: userprofile_id
            }
        }).then(function successCallback(response) {
            $rootScope.hide_dimmer();
            show_email_sent_message();
        }, function errorCallback(response) {
            $rootScope.show_dimmer();
            simplePopupFactory.show_popup('Caution.', response.data.message || 'Try again or notify support.', 'OK', null);
        });

    }

    function show_buddy_or_email_popup(userprofile_id) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'templates/register/ForgotPasswordEmailOrBuddy.html',
            controller: 'ForgotPasswordEmailOrBuddyController'
        });
        modalInstance.result.then(function successCallback(choose) {
            if (choose == 'email') {
                create_reset_request($scope.pre_user.id);
            } else {
                if (choose == 'buddy') {
                    notify_buddies(userprofile_id);
                }
            }
        });

    }


    function show_email_or_username_request() {
        return $uibModal.open({
            animation: true,
            templateUrl: 'templates/register/RequestUsernameOrEmail.html',
            controller: 'RequestUsernameOrEmailController'
        });
    }


    $scope.forgot_password = function (e) {
        e.preventDefault();
        e.stopPropagation();
        const base_url = DjangoUrls['password_reset_request']();
        if ($scope.pre_user) {
            document.location.href = base_url + '?username=' + $scope.pre_user.username;
        } else {
            document.location.href = base_url;
        }
        return false;
    };

}

export default LoginPageController;

// laApp.controller('ForgotPasswordEmailOrBuddyController', ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
//     $scope.close = function () {
//         $uibModalInstance.dismiss('closed');
//     };
//     $scope.email = function () {
//         $uibModalInstance.close('email')
//     };
//     $scope.buddy = function () {
//         $uibModalInstance.close('buddy')
//     };
//
//
// }]);
//
// laApp.controller('RequestUsernameOrEmailController', ['$scope', '$uibModalInstance', '$timeout', '$http', function ($scope, $uibModalInstance, $timeout, $http) {
//     $scope.close = function () {
//         $uibModalInstance.dismiss('closed');
//     };
//     $scope.query = undefined;
//
//     function reset_validation() {
//         $scope.error = false;
//         $scope.success = false;
//         $scope.userprofile = undefined;
//         $scope.message = undefined;
//     }
//
//     reset_validation(); // initial data
//
//     var validate_email_promise;
//
//     function _query_username_or_email() {
//         if (!$scope.query) {
//             return
//         }
//         var url = DjangoUrls["api.v1:auth-query-user"]();
//         $http({
//             method: 'POST',
//             url: url,
//             data: {
//                 query: $scope.query
//             }
//         }).then(function successCallback(response) {
//             $scope.success = true;
//             $scope.message = response.data.message;
//             $scope.userprofile = response.data.userprofile;
//         }, function errorCallback(response) {
//             $scope.error = true;
//             $scope.message = response.data.message;
//         });
//     }
//
//     $scope.query_username_or_email = function () {
//         reset_validation();
//         if (angular.isDefined(validate_email_promise)) {
//             $timeout.cancel(validate_email_promise);
//         }
//         validate_email_promise = $timeout(_query_username_or_email, 600, true, $scope.query);
//     };
//
//     $scope.reset_password = function () {
//         $uibModalInstance.close($scope.userprofile);
//     };
//
// }]