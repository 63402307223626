export default {
    data(){
      return {
          selectOnFocus: false,
      }
    },
    methods: {
        $_focusElIsVisible(el) {
            if (el?.$el) {
                return !!(el.$el.offsetWidth || el.$el.offsetHeight);
            }
            return !!(el?.offsetWidth || el?.offsetHeight);
        },
        $_catchFocus(focusEl, attempt = 1, multiAttempts = true) {
            const MAX_ATTEMPTS = 20;
            this.$nextTick(() => {
                if (focusEl && this.$_focusElIsVisible(focusEl)) {
                    focusEl?.focus();
                    setTimeout(() => {
                        focusEl?.focus();
                        if(this.selectOnFocus) {
                            focusEl?.select();
                        }
                    }, 100);
                } else {
                    if (multiAttempts && (attempt < MAX_ATTEMPTS)) {
                        setTimeout(() => {
                            this.$_catchFocus(focusEl, attempt + 1, multiAttempts);
                        }, 100);
                    }
                }
            });
        }
    }
};
