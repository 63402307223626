var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wiki-page-header", class: { row: !_vm.scaleByIconsSize } },
    [
      _c(
        "div",
        {
          staticClass: "side-padding-0",
          class: _vm.leftClass,
          style: _vm.leftPartStyle,
        },
        [
          _c(
            "span",
            { staticClass: "wiki-page-header__label visible-xs" },
            [
              _vm._t("title", function () {
                return [_vm._v(_vm._s(_vm.title))]
              }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "side-padding-0 text-right",
          class: _vm.rightClass,
          style: _vm.rightPartStyle,
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }