<template>
    <div class="entry-emotion inline-block" tabindex="-1" :aria-label="`I\'m Feeling ${emotionObject.description}`"
         role="figure">
        <div class="entry-emotion__label vertical-middle inline-block" v-if="showLabel" :style="iconLabelStyle">I'm
            Feeling:
        </div>
        <div class="entry-emotion__icon vertical-middle inline-block">
            <div class="entry-emotion__icon__icon inline-block vertical-middle" :style="iconStyle">
                <i class="fal" :class="emotionObject.iconClass"></i>
            </div>
            <div class="entry-emotion__icon__label inline-block vertical-middle" v-if="showLabel"
                 :style="iconLabelStyle">
                {{ emotionObject.description }}
            </div>
        </div>
        <div class="entry-emotion__delete-icon color-red" v-if="showDelete" @click="del">
            <i class="fa fa-times pointer color-red"></i>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import {getEmotionByCode} from "../../diary_page/common/blog_entry_emotions/diaryEntryEmotions";

    export default {
        props: {
            emotion: [String, Object],
            showDelete: Boolean,
            showLabel: {
                type: Boolean,
                default: true,
            },
            size: {
                type: Number,
                default: 44,
            }
        },
        computed: {
            emotionObject() {
                if (_.isString(this.emotion)) {
                    return getEmotionByCode(this.emotion);
                }
                return this.emotion;
            },
            iconStyle() {
                return {
                    'font-size': `${this.size}px`,
                    'line-height': `${this.size}px`,
                };
            },
            iconLabelStyle() {
                return {
                    'font-size': `${Math.round(this.size / 2 - 2)}px`,
                    'line-height': `${Math.round(this.size / 2 - 2)}px`,
                };
            },
        },
        methods: {
            del() {
                this.$emit('delete');
            },
        },
        name: "EntryEmotion"
    };
</script>

<style scoped lang="scss">
  .entry-emotion {
    position: relative;
    font-size: 22px;

    &__icon {
      text-align: center;
    }

    &__delete-icon {
      position: absolute;
      right: -15px;
      top: -5px;
      font-size: 25px;
    }
  }
</style>