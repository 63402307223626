var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.header)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("label", [_vm._v("Group name:")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name",
              },
            ],
            ref: "groupNameInput",
            staticClass: "form-control",
            attrs: { type: "text", placeholder: "Group name" },
            domProps: { value: _vm.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.name = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("label", [_vm._v("Group description:")]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.description,
                expression: "description",
              },
            ],
            staticClass: "form-control",
            attrs: { rows: "5", placeholder: "Group description" },
            domProps: { value: _vm.description },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.description = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("hr"),
        ]),
      ]),
      _vm._v(" "),
      _vm.uses_default_avatar || !_vm.avatar
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _vm._v("\n            Avatar: "),
              _c(
                "a",
                {
                  attrs: { href: "javascript:;" },
                  on: { click: _vm.addAvatar },
                },
                [_vm._v("Add avatar")]
              ),
            ]),
          ])
        : _c("div", { staticClass: "row margin-15-top" }, [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _vm._v("\n            Avatar: "),
                _c("br"),
                _vm._v(" "),
                _c("media-preview", {
                  staticStyle: { display: "inline-block" },
                  attrs: { "allow-delete": true, item: _vm.avatar },
                  on: { onDelete: _vm.resetAvatar },
                }),
              ],
              1
            ),
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("vue-switch-with-label", {
              attrs: {
                label: "Take thumbnail from members avatars if not set",
                name: "autoAvatar",
              },
              model: {
                value: _vm.auto_avatar,
                callback: function ($$v) {
                  _vm.auto_avatar = $$v
                },
                expression: "auto_avatar",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c(
              "vue-switch-with-label",
              {
                attrs: { label: "", name: "isPrivate" },
                model: {
                  value: _vm.is_private,
                  callback: function ($$v) {
                    _vm.is_private = $$v
                  },
                  expression: "is_private",
                },
              },
              [
                _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                  _vm._v(
                    " Make this a Private Group. (Private Groups cannot be seen by "
                  ),
                  _vm.isCircleGroup
                    ? _c("span", [_vm._v("Circle")])
                    : _c("span", [_vm._v("Community")]),
                  _vm._v(" Members unless they are added)"),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [_c("hr")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _vm._v(
            "\n            Please check what you would like to approve:\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("vue-switch-with-label", {
              attrs: {
                label: "User requests to join this Group",
                name: "veto_joins",
              },
              model: {
                value: _vm.veto_joins,
                callback: function ($$v) {
                  _vm.veto_joins = $$v
                },
                expression: "veto_joins",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("vue-switch-with-label", {
              attrs: {
                label:
                  "Members sharing Wikis, Stories and Media folders with Group",
                name: "veto_share",
              },
              model: {
                value: _vm.veto_share,
                callback: function ($$v) {
                  _vm.veto_share = $$v
                },
                expression: "veto_share",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("vue-switch-with-label", {
              attrs: {
                label: "Members creating new Group Chats",
                name: "veto_new_conversations",
              },
              model: {
                value: _vm.veto_new_conversations,
                callback: function ($$v) {
                  _vm.veto_new_conversations = $$v
                },
                expression: "veto_new_conversations",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("vue-switch-with-label", {
              attrs: {
                label: "Members posting into Group Chats",
                name: "veto_conversation_posts",
              },
              model: {
                value: _vm.veto_conversation_posts,
                callback: function ($$v) {
                  _vm.veto_conversation_posts = $$v
                },
                expression: "veto_conversation_posts",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("vue-switch-with-label", {
              attrs: {
                label: "Members posting Updates to the Group",
                name: "veto_activity_posts",
              },
              model: {
                value: _vm.veto_activity_posts,
                callback: function ($$v) {
                  _vm.veto_activity_posts = $$v
                },
                expression: "veto_activity_posts",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("button", { staticClass: "btn btn-mm", on: { click: _vm.close } }, [
          _vm._v("Cancel"),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-green",
            attrs: { disabled: !_vm.allowSave },
            on: { click: _vm.saveOrCreate },
          },
          [_vm._v(_vm._s(_vm.saveButtonText) + "\n        ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }