var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "selector-popup",
    { ref: "popup", attrs: { header: _vm.header, items: _vm.items } },
    [
      _c("template", { slot: "footer" }),
      _vm._v(" "),
      _c("i", { attrs: { slot: "descriptionTop" }, slot: "descriptionTop" }, [
        _vm._v("What sort of event do you want to create?"),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }