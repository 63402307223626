<template>
    <component
            v-bind:is="component"
            v-on="$listeners"
            :target="target"
            class="likefolder-action-button"
            :class="{
                'no-text-mobile':noTextOnMobile,
                'disabled':disabled,
            }"
            v-tooltip="tooltipText"
            :style="folderButtonStyle"
            @focus.native="onFocus"
            @blur.native="onBlur"
    >
        <div :class="[buttonColor, customIcon, buttonFocus]" :style="customIconSizeStyle"
             class="likefolder-action-button-icon">
            <slot name="icon">
                <i :class="[iconClass, iconPrefix]" v-show="iconClass" aria-hidden="true"
                   :style="iconStyle">
                </i>
            </slot>
        </div>
        <div class="likefolder-action-button-name"
             :class="{
            'no-text-mobile':noTextOnMobile,
            'disabled':disabled
        }"
        >
            <slot name="text">{{ text }}</slot>
        </div>
    </component>
</template>
<script>
    import FolderButtonSpan from './FolderButtonSpan';
    import FolderButtonLink from './FolderButtonLink';
    import FolderButtonRouterLink from './FolderButtonRouterLink';
    import RouterCheckAvailabilityMixin from '../RouterCheckAvailabilityMixin';

    const DEFAULT_MAX_WIDTH = 88;

    export default {
        name: 'VueFolderButton',
        mixins: [RouterCheckAvailabilityMixin],
        emits: ['onClick'],
        data() {
            return {
                hasFocus: false,
            };
        },
        props: {
            noTextOnMobile: {
                type: Boolean,
                default: false
            },
            text: {
                type: [String, Number],
                required: false
            },
            iconClass: {
                type: String,
                required: false
            },
            customIcon: {
                type: String,
                required: false
            },
            color: {
                type: String,
                required: false,
                default: 'tag-gray'
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            innerColor: {
                type: String,
                default: '#fff'
            },
            active: {
                type: Boolean,
                required: false,
                default: false
            },
            activeColor: {
                type: String,
                default: 'black'
            },
            helpText: {
                type: String,
                required: false
            },
            helpTextInfoIcon: {
                type: Boolean,
                default: false
            },
            top: {
                type: Number,
                default: -2
            },
            left: {
                type: Number,
                required: false
            },
            maxWidth: {
                required: false,
                type: Number
            },
            customIconSize: {
                type: Number,
                default: 54
            },
            target: {
                type: [String, Object],
                required: false,
            },
            isRouterLink: {
                type: Boolean,
                default: false,
            },
            noBackground: {
                type: Boolean,
                default: false,
            },
            iconPrefix: {
                type: String,
                default: 'fa',
            },
        },
        computed: {
            component() {
                if (this.target) {
                    if (this.isRouterLink) {
                        return FolderButtonRouterLink;
                    } else {
                        if (this.getRouterAvailability(this.target)) {
                            return FolderButtonRouterLink;
                        } else {
                            return FolderButtonLink;
                        }
                    }
                }
                return FolderButtonSpan;
            },
            customIconSizeStyle() {
                const style = { 'background-size': `${this.customIconSize}%` };
                if (this.left !== undefined) {
                    style['background-position-x'] = `${this.left}px`;
                }
                return style;
            },
            tooltipText() {
                if (this.helpText) {
                    if (this.helpTextInfoIcon) {
                        return `<i class='fa fa-info-circle'></i> ` + this.helpText;
                    } else {
                        return this.helpText;
                    }
                }
            },
            buttonFocus() {
                if (this.hasFocus) {
                    return 'has-focus';
                }
            },
            buttonColor() {
                if (this.noBackground) {
                    return;
                }
                if (this.disabled) {
                    return 'lightgray';
                }
                if (this.active) {
                    return this.activeColor;
                }
                return this.color;
            },
            iconStyle() {
                const style = {
                    'vertical-align': 'middle',
                    'top': `${this.top}px`,
                    color: this.noBackground ? '#707070' : this.innerColor,
                };
                return style;
            },
            folderButtonStyle() {
                const style = {};
                if (this.maxWidth) {
                    style['max-width'] = `${this.maxWidth}px`;
                }
                if (this.left !== undefined) {
                    style['left'] = `${this.left}px`;
                }
                return style;
            }
        },
        methods: {
            onFocus() {
                this.hasFocus = true;
            },
            onBlur() {
                this.hasFocus = false;
            }
        }
    };
</script>

<style scoped lang="scss">
  @import "../../../angular/app/styles/const";

  .likefolder-action-button-name {
    &.disabled {
      color: $tag-gray;
    }
  }

  .likefolder-action-button {


    &.disabled {
      cursor: not-allowed;
    }
  }

  .likefolder-action-button-icon {
    transition: background-color ease 0.2s;

    &:hover {
      box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
    }
  }
</style>
