var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "task-tags-list" },
    _vm._l(_vm.wrappedTags, function (tag) {
      return _c(
        "div",
        { staticClass: "task-tags-list__tag", class: { active: tag.active } },
        [_vm._v(_vm._s(tag.name))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }