<template>
    <div class="story-list-item__container"
         :class="{
                'story-list-item__container_under-drag':isCurrentlyUnderDrag,
                'story-list-item__container_edit-mode':editMode,
            }"
         :style="draggingStyle">
        <div class="story-list-item__buttons-panel" v-show="editMode">
            <div class="story-list-item__button story-list-item__button_updown"
                 @click="moveUp"
                 @keydown.enter="moveUp"
                 tabindex="0"
            ><i class="fa fa-arrow-up"></i></div>
            <div class="story-list-item__button story-list-item__button_drag"
                 @mousedown.stop.prevent="dragGripClicked"
                 @touchstart.stop.prevent="dragGripTouched"
                 :style="dragGripStyle"
            ><i class="fa fa-arrows-alt"></i></div>
            <div class="story-list-item__button story-list-item__button_pencil"
                 @click="edit"
                 @keydown.enter="edit"
                 tabindex="0"
            ><i class="fa fa-pencil"></i></div>
            <div class="story-list-item__button story-list-item__button_trash"
                 @click="del"
                 @keydown.enter="del"
                 tabindex="0"
            ><i class="fa fa-trash"></i></div>
            <div class="story-list-item__button story-list-item__button_updown"
                 @click="moveDown"
                 @keydown.enter="moveDown"
                 tabindex="0"
            ><i class="fa fa-arrow-down"></i></div>
        </div>
        <div class="story-list-item__content">
            <render-post max-media-height="60vh" :post="item" :lazy-load="true"></render-post>
        </div>
        <div class="story-list-item__bottom-panel">
            <span class="story-list-item__likes-panel"
                  :class="{'story-list-item__likes-panel_liked':item.liked_by_me}"
                  v-if="allowLike">
                <span class="story-list-item__likes-counter"
                      tabindex="0"
                      v-show="item.likes_count"
                      @click="showWhoLiked"
                      @keydown.enter="showWhoLiked"
                      aria-label="Show liked"
                      role="button"
                >{{
                        item.likes_count
                    }}</span>
                <span class="story-list-item__likes-icon"
                      tabindex="0"
                      @click="toggleLike"
                      @keydown.enter="toggleLike"
                      aria-label="Like"
                      role="button"
                ><i class="fa" :class="{
                    'fa-heart': item.liked_by_me,
                    'fa-heart-o': !item.liked_by_me,
                }"></i></span>
            </span>
        </div>
    </div>
</template>

<script>
    import RenderPost from "shared/render_post/RenderPost";
    import DragDropMixin from "../../../../vue/mixins/DragDropMixin";

    export default {
        name: "StoryListItem",
        mixins: [DragDropMixin],
        components: {RenderPost},
        props: {
            item: Object,
            editMode: Boolean,

            allowLike: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {};
        },
        computed: {},
        methods: {
            $_processDrop(draggedID, underDragID) {
                this.$emit('onDrop', draggedID, underDragID);
            },
            moveUp() {
                this.$emit('moveUp');
            },
            moveDown() {
                this.$emit('moveDown');
            },
            edit() {
                this.$emit('edit');
            },
            del() {
                this.$emit('del');
            },
            toggleLike() {
                if (this.item.liked_by_me) {
                    this.$emit('unlike');
                } else {
                    this.$emit('like');
                }
            },
            showWhoLiked() {
                this.$emit('showWhoLiked');
            },
        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";
  @import "../../styles/mixins";

  .story-list-item {
    &__container {
      position: relative;
      margin-top: 10px;
      margin-bottom: 10px;
      background-color: white;
      border-radius: 8px;
      padding: 10px;
      border-bottom: solid 1px $gray;

      &_edit-mode {
        border: 3px dashed $gray;
        padding: 7px;
        border-radius: 8px;
      }

      &_under-drag {
        box-shadow: $grass-green 0px 0px 10px 3px;
        //border: solid 1px $grass-green;
      }

      &_edit-mode {
        @media (max-width: $screen-sm-max) {
          min-height: 225px;
        }
        @media (min-width: $screen-md-min) {
          min-height: 325px;
        }
      }
    }

    &__buttons-panel {
      @include likeStoryEditPanel();
    }

    &__content {
      text-align: justify;
      margin: auto;
      overflow: hidden;
    }

    &__button {
      @include likeStoryEditPanelButton();
    }

    &__bottom-panel {
      text-align: right;
    }

    &__likes-panel {
      text-align: right;
      display: inline-block;
      font-size: 33px;
      line-height: 30px;
      cursor: pointer;
      min-width: 90px;
      color: $gray;
      transition: color 0.2s ease;

      &_liked {
        color: $blue;
      }
    }

    &__likes-counter {
      vertical-align: middle;
      cursor: pointer;
    }

    &__likes-icon {
      cursor: pointer;
      vertical-align: middle;
    }
  }
</style>