/**
 * Created by orion on 19.02.17.
 */
import _ from 'lodash';
import ShareWhereListPopupController from './popups/where_list/controller';

shareFactory.$inject = ['$rootScope', '$log', 'Sharing', 'notifications', 'simplePopupFactory', 'selectFactory', 'CurrentUser', '$q'];

function shareFactory($rootScope, $log, Sharing, notifications, simplePopupFactory, selectFactory, CurrentUser, $q) {
    let name_in_warning_text;

    return {
        share_this: share_this
    };

    //////////////////////

    function getPopupTextCircle(new_checked_ids, new_unchecked_ids, all, name_in_warning_text) {
        let POPUP_TEXT = `Are you sure you want to edit sharing of ${name_in_warning_text} for these users? <br><br> Through sharing this you are giving consent for these users to view the shared material and download any linked attachments. Users are not permitted to share this on with other users, or download or use any of the material for any other purposes.`;
        if (new_checked_ids.length > 1 || all && !new_unchecked_ids.length) {
            POPUP_TEXT = `Are you sure you want to share ${name_in_warning_text} with these users? <br><br> Through sharing this you are giving consent for these users to view the shared material and download any linked attachments. Users are not permitted to share this on with other users, or download or use any of the material for any other purposes.`;
        }
        if (new_checked_ids.length === 1 && !new_unchecked_ids.length) {
            POPUP_TEXT = `Are you sure you want to share ${name_in_warning_text} with this user? <br><br> Through sharing this you are giving consent for this user to view the shared material and download any linked attachments. This user is not permitted to share this on with other users, or download or use any of the material for any other purposes.`;
        }
        if (!new_checked_ids.length && !all && new_unchecked_ids.length > 1) {
            POPUP_TEXT = `Are you sure you want to remove sharing of ${name_in_warning_text} for these users?`;
        }
        if (!new_checked_ids.length && !all && new_unchecked_ids.length === 1) {
            POPUP_TEXT = `Are you sure you want to remove sharing of ${name_in_warning_text} for this user?`;
        }

        return POPUP_TEXT;
    }

    function getPopupTextGroups(new_checked_ids, new_unchecked_ids, all, name_in_warning_text) {
        let POPUP_TEXT = `Are you sure you want to edit sharing of ${name_in_warning_text} for these groups? <br><br> Through sharing this you are giving consent for these Groups to view the shared material and download any linked attachments. Users in these Groups are not permitted to share this on with other users, or download or use any of the material for any other purposes.`;
        if (new_checked_ids.length > 1 || all && !new_unchecked_ids.length) {
            POPUP_TEXT = `Are you sure you want to share ${name_in_warning_text} with these groups? <br><br> Through sharing this you are giving consent for these Groups to view the shared material and download any linked attachments. Users in these Groups are not permitted to share this on with other users, or download or use any of the material for any other purposes.`;
        }
        if (new_checked_ids.length === 1 && !new_unchecked_ids.length) {
            POPUP_TEXT = `Are you sure you want to share ${name_in_warning_text} with this group? <br><br> Through sharing this you are giving consent for this Group to view the shared material and download any linked attachments. Users in this Group are not permitted to share this on with other users, or download or use any of the material for any other purpose.`;
        }
        if (!new_checked_ids.length && !all && new_unchecked_ids.length > 1) {
            POPUP_TEXT = `Are you sure you want to remove sharing of ${name_in_warning_text} for these groups?`;
        }
        if (!new_checked_ids.length && !all && new_unchecked_ids.length === 1) {
            POPUP_TEXT = `Are you sure you want to remove sharing of ${name_in_warning_text} for this group?`;
        }

        return POPUP_TEXT;
    }

    function getPopupTextCommunities(new_checked_ids, new_unchecked_ids, all, name_in_warning_text) {
        let POPUP_TEXT = `Are you sure you want to edit sharing of ${name_in_warning_text} for these communities? <br><br> Through sharing this you are giving consent for these Communities to view the shared material and download any linked attachments. Users in these Communities are not permitted to share this on with other users, or download or use any of the material for any other purposes.`;
        if (new_checked_ids.length > 1 || all && !new_unchecked_ids.length) {
            POPUP_TEXT = `Are you sure you want to share ${name_in_warning_text} with these communities? <br><br> Through sharing this you are giving consent for these Communities to view the shared material and download any linked attachments. Users in these Communities are not permitted to share this on with other users, or download or use any of the material for any other purposes.`;
        }
        if (new_checked_ids.length === 1 && !new_unchecked_ids.length) {
            POPUP_TEXT = `Are you sure you want to share ${name_in_warning_text} with this community? <br><br> Through sharing this you are giving consent for this Community to view the shared material and download any linked attachments. Users in this Community are not permitted to share this on with other users, or download or use any of the material for any other purpose.`;
        }
        if (!new_checked_ids.length && !all && new_unchecked_ids.length > 1) {
            POPUP_TEXT = `Are you sure you want to remove sharing of ${name_in_warning_text} for these groups?`;
        }
        if (!new_checked_ids.length && !all && new_unchecked_ids.length === 1) {
            POPUP_TEXT = `Are you sure you want to remove sharing of ${name_in_warning_text} for this group?`;
        }

        return POPUP_TEXT;
    }


    function keep_private(sharer_id, sharer_name, type, id, allow_share_with_communities) {
        let res = simplePopupFactory.show_popup('Keep this Private?', 'Are you sure you want to remove all sharing of this item?<br/><br/>' +
            'If	you	had	shared this with anybody before, they wil not be ' +
            'able to access this any more if you click this option.', 'Keep	this Private', 'Cancel');
        res.then(
            function () {
                Sharing.make_private(sharer_id, type, id).then(function (resp) {
                        notifications.success('Sharing changes successfully saved');
                    },
                    function (err) {
                        notifications.error('Something went wrong. Please refresh page and try again.');
                    });
            }, _.noop
        );
    }


    function share_this(type, id, additional_messages = [], sharer_id = CurrentUser.id, sharer_name = CurrentUser.name, allow_share_with_communities = true) { // type in 'folder', 'story'
        name_in_warning_text = {'folder': 'this Media folder', 'story': 'this Story'}[type];
        $rootScope.show_dimmer();
        Sharing.get_sharing_main_status(sharer_id, type, id).then(function (resp) {
            $rootScope.hide_dimmer();
            let modalResult = simplePopupFactory.create_popup(ShareWhereListPopupController, require('./popups/where_list/template.html'),
                {
                    sharing_status: resp.data,
                    allow_share_with_communities: allow_share_with_communities,
                    behalf_of_user: sharer_id !== CurrentUser.id ? sharer_name : undefined,
                    additional_messages
                }, undefined, undefined, (allow_share_with_communities ? 'lg' : undefined)
            );

            modalResult.then(function (res) {
                if (res.select === 'private') {
                    keep_private(sharer_id, sharer_name, type, id, allow_share_with_communities);
                    return;
                }
                if (res.select === 'friends') {
                    share_with_friends(sharer_id, sharer_name, type, id, allow_share_with_communities);
                    return;
                }
                if (res.select === 'groups') {
                    share_with_groups(sharer_id, sharer_name, type, id, allow_share_with_communities);
                    return;
                }
                if (res.select === 'communities') {
                    share_with_communities(sharer_id, sharer_name, type, id, allow_share_with_communities);
                }
            }, function reject(reason) {
            });
        }, function error(resp) {
            $rootScope.hide_dimmer();
            notifications.error('Something went wrong. Please reload page and try again.');
        });
    }


    function share_with_friends(sharer_id, sharer_name, type, id, allow_share_with_communities) {
        let modalResult = selectFactory.endpoint_selector(_.partial(Sharing.get_friends_for_share, sharer_id, type, id), true, {
            header: `Share this with ${sharer_id === CurrentUser.id ? 'my' : sharer_name + '\'s'} Circle?`,
            info: `Select or de-select Members of ${sharer_id === CurrentUser.id ? 'your' : sharer_name + '\'s'} Circle below, or share with all ${sharer_id === CurrentUser.id ? 'your' : sharer_name + '\'s'} Circle automatically.`,
            button_yes: 'Update Circle sharing',
            button_no: 'Cancel',
            empty: `${sharer_id === CurrentUser.id ? 'You do' : `${sharer_name} does`} not have any Circle Members to select`,
            select_all_html: sharer_id === CurrentUser.id ? 'Share with all My Circle,<br>and new people I add' : `Share with all ${sharer_name}'s Circle,<br>and new people ${sharer_name} adds`
        }, true, true);

        const confirmationDeferred = $q.defer();
        let select_result;

        modalResult
            .then(res => {
                select_result = res;
                let new_checked_ids, new_unchecked_ids, all;
                [new_checked_ids, new_unchecked_ids, all] = select_result;
                const POPUP_TEXT = getPopupTextCircle(new_checked_ids, new_unchecked_ids, all, name_in_warning_text);
                return simplePopupFactory.show_popup('Share', POPUP_TEXT, 'Yes', 'Cancel');
            })
            .then(y => {
                confirmationDeferred.resolve(select_result);
            }, () => new Promise(()=>{}));

        confirmationDeferred.promise.then(function (res) {
            Sharing.share_with_friends(sharer_id, type, id, res[0], res[1], res[2]).then( // all_friends can be undef
                function (resp) {
                    notifications.success('Sharing changes successfully saved');
                },
                function (err) {
                    notifications.error('Something went wrong. Please refresh page and try again.');
                }
            );
        }, function err(reason) {
            if (reason === 'back') {
                share_this(type, id, [], sharer_id, sharer_name, allow_share_with_communities);
            }
        });

    }


    function share_with_groups(sharer_id, sharer_name, type, id, allow_share_with_communities) {
        let modalResult = selectFactory.endpoint_selector(_.partial(Sharing.get_groups_for_share, sharer_id, type, id), true, {
            header: `Share this with ${sharer_id === CurrentUser.id ? 'my' : sharer_name + '\'s'} Groups?`,
            info: 'Choose this option to share this with whole Groups of people,\n' +
            ' click the Group Avatar to select or de-select the Group you\n' +
            ' wish to share or un-share with.',
            button_yes: 'Update Group sharing',
            button_no: 'Cancel',
            empty: `${sharer_id === CurrentUser.id ? 'You do' : `${sharer_name} does`} not have any Groups to select`,
            long_text: true,
        }, true);

        const confirmationDeferred = $q.defer();
        let select_result;

        modalResult
            .then(res => {
                select_result = res;
                let new_checked_ids, new_unchecked_ids, all;
                [new_checked_ids, new_unchecked_ids, all] = select_result;
                const POPUP_TEXT = getPopupTextGroups(new_checked_ids, new_unchecked_ids, all, name_in_warning_text);
                return simplePopupFactory.show_popup('Share', POPUP_TEXT, 'Yes', 'Cancel');
            })
            .then(y => {
                confirmationDeferred.resolve(select_result);
            }, () => new Promise(()=>{}));

        confirmationDeferred.promise.then(function (res) {
            $log.debug(type, id, res.new_shares, res.remove_shares);
            Sharing.share_with_groups(sharer_id, type, id, res[0], res[1]).then(
                function (resp) {
                    notifications.success('Sharing changes successfully saved.');
                },
                function (err) {
                    notifications.error('Something went wrong. Please refresh page and try again.');
                }
            );
        }, function err(reason) {
            if (reason === 'back') {
                share_this(type, id, [], sharer_id, sharer_name, allow_share_with_communities);
            }
        });

    }

    function share_with_communities(sharer_id, sharer_name, type, id, allow_share_with_communities) {
        let modalResult = selectFactory.endpoint_selector(_.partial(Sharing.get_communities_for_share, sharer_id, type, id), true, {
            header: `Share this with ${sharer_id === CurrentUser.id ? 'my' : sharer_name + '\'s'} Communities?`,
            info: ' Choose this option to share this with whole Communities of people,\n' +
            '            click the group Avatar to select or de-select the Community you\n' +
            '            wish to share or un-share with.',
            button_yes: 'Update Community sharing',
            button_no: 'Cancel',
            empty: `${sharer_id === CurrentUser.id ? 'You do' : `${sharer_name} does`} not have any Communities to select`,
            long_text: true,
        }, true);
        const confirmationDeferred = $q.defer();
        let select_result;

        modalResult
            .then(res => {
                select_result = res;
                let new_checked_ids, new_unchecked_ids, all;
                [new_checked_ids, new_unchecked_ids, all] = select_result;
                const POPUP_TEXT = getPopupTextCommunities(new_checked_ids, new_unchecked_ids, all, name_in_warning_text);
                return simplePopupFactory.show_popup('Share', POPUP_TEXT, 'Yes', 'Cancel');
            })
            .then(y => {
                confirmationDeferred.resolve(select_result);
            }, () => new Promise(()=>{}));

        confirmationDeferred.promise.then(function (res) {
            Sharing.share_with_communities(sharer_id, type, id, res[0], res[1]).then(
                function (resp) {
                    notifications.success('Sharing changes successfully saved.');
                },
                function (err) {
                    notifications.error('Something went wrong. Please refresh page and try again.');
                }
            );
        }, function err(reason) {
            if (reason === 'back') {
                share_this(type, id, [], sharer_id, sharer_name, allow_share_with_communities);
            }
        });
    }
}

export default shareFactory;