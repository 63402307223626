<template>
    <router-link
            :to="to"
            class="wiki-section"
            :style="[sectionStyle, draggingStyle, gripStyle]"
            :class="{underDrag, 'active': isActive}"
    >
        <div class="wiki-section__check-button"
             v-if="editMode&&wikiEditable&&!section.is_fixed"
             :style="[iconSizeStyle]"
             tabindex="0"
             @click.stop.prevent="toggleTick"
             @keydown.enter.stop.prevent="toggleTick"
             @keydown.space.stop.prevent="toggleTick"
        >
            <i class="fas fa-check wiki-section__checked-button-icon" v-show="isTicked"></i>
            <i class="fas fa-arrow-from-top fa-rotate-180 wiki-section__check-button-icon" v-show="!isTicked"></i>
        </div>
        <div class="wiki-section__sound-and-edit-icon" v-if="section.audio_caption||editMode"
             :style="[soundEditDragMarkSizeStyle]"
             @click.stop.prevent="editOrAudioClick"
        >
            <audio-toggle
                    ref="sectionAudioToggle"
                    v-if="section.audio_caption&&!editMode"
                    :audio-caption="section.audio_caption"
                    fa-class="fal"
            ></audio-toggle>
            <i class="fal fa-pencil" v-if="editMode"></i>
        </div>
        <div class="wiki-section__drag-n-drop-icon"
             v-if="editMode && !section.is_fixed"
             :style="[soundEditDragMarkSizeStyle, dragMarkPositionStyle]"
             @click.stop.prevent
             @mousedown.stop.prevent="dragGripClicked"
             @mouseenter
             @touchstart.stop.prevent="dragGripTouched"
        >
            <i class="fal fa-arrows-alt" v-if="editMode" :style="dragGripStyle"></i>
        </div>
        <div
                :style="[iconSizeStyle]"
                v-if="!editMode&&(showChangedMark||showNewMark||showBothMarks)"
                class="wiki-section__unread-mark"
                :class="{
                    'wiki-section__unread-mark_changed':showChangedMark,
                    'wiki-section__unread-mark_new':showNewMark,
                    'wiki-section__unread-mark_both':showBothMarks,
                }"
        ><span v-if="showChangedMark">UPD</span><span v-if="showNewMark">NEW</span></div>
        <div class="wiki-section__avatar-container" :style="avatarStyle">
            <div class="wiki-section__avatar-outer" :class="{isSubmenu}"></div>
            <div class="wiki-section__avatar-inner" :class="{isSubmenu}">
                <wiki-section-page-section-background :content-samples="samples"
                                                      v-if="samples.length"></wiki-section-page-section-background>
                <div class=" wiki-section__avatar-inner__picture-placeholder" v-else :style="pictureStyle">
                    <i class="fa fa-picture-o"></i>
                </div>
            </div>
        </div>
        <div class="wiki-section__title-container" :style="textPartStyle">
            <div class="wiki-section__label">{{ sectionTitle }}
                <span
                        v-if="showCounters&&section.visible_content_count"
                        class="wiki-section__label-counter"
                >({{ section.visible_content_count }})</span></div>
        </div>
    </router-link>
</template>

<script>
    import Vue from 'vue';
    import WikiSectionPageSectionBackground from "./WikiSectionPageSectionBackground";
    import MouseEventsMixin from "../../../vue/mixins/MouseEventsMixin";
    import CatchFocusMixin from "../../../vue/mixins/CatchFocusMixin";


    export default {
        name: "WikiSectionPageSection",
        components: {WikiSectionPageSectionBackground},
        mixins: [CatchFocusMixin, MouseEventsMixin],
        props: {
            section: Object,
            size: {
                type: Number,
                default: 200,
            },
            to: [Object, String],
            isActive: {
                type: Boolean,
                default: false,
            },
            isTicked: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                offsetBeforeDrag: undefined,
                positionBeforeDragX: undefined,
                positionBeforeDragY: undefined,
                currentPositionX: undefined,
                currentPositionY: undefined,
                offset: 0
            };
        },
        computed: {
            $_iconSize() {
                return 41;
            },
            $_sectionWidth() {
                return this.size;
            },

            $_sectionHeight() {
                return Math.floor(this.size * 1.3);
            },
            sectionStyle() {
                return {
                    'width': `${this.$_sectionWidth}px`,
                    'height': `${this.$_sectionHeight}px`,
                };
            },
            $_avatarHeight() {
                return Math.floor(this.size / 1.4);
            },
            $_avatarWidth() {
                return Math.floor(this.size - this.size / 5);
            },
            $_textPartHeight() {
                return this.$_sectionHeight - this.$_avatarHeight;
            },
            gripStyle() {
                if (this.$store.getters['WikiStore/draggedSection']) {
                    return {'cursor': 'grabbing'};
                }
            },
            underDrag() {
                return this.$store.getters['WikiStore/underDragSection'] === this.section;
            },
            isDragged() {
                return this.$store.getters['WikiStore/draggedSection'] === this.section;
            },
            showCounters() {
                return this.$store.getters['WikiStore/wikiShowContentCounters'];
            },
            draggingStyle() {
                const style = {};
                if (!this.isDragged) {
                    return style;
                }
                if (!this.offsetBeforeDrag) {
                    this.offsetBeforeDrag = this.offset;
                }
                const xCoord = this.currentPositionX - this.positionBeforeDragX - (this.offsetBeforeDrag - this.offset);
                return {
                    'z-index': 9999,
                    'pointer-events': 'none',
                    'cursor': 'grabbing',
                    'opacity': '0.7',
                    'transform': `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, ${xCoord}, ${this.currentPositionY - this.positionBeforeDragY}, 0, 1)`
                };
            },
            dragGripStyle() {
                const style = {'cursor': 'grab'};
                if (this.isDragged) {
                    style['cursor'] = 'grabbing !important';
                }
                return style;
            },
            iconSizeStyle() {
                const size = this.$_iconSize;
                return {
                    width: `${size}px`,
                    height: `${size}px`,
                    'font-size': `${size / 3}px`,
                };
            },
            soundEditDragMarkSizeStyle() {
                const size = this.$_iconSize;
                return {
                    ...this.iconSizeStyle,
                    'line-height': `${size}px`,
                    'font-size': `${size / 1.6}px`,
                };
            },
            dragMarkPositionStyle() {
                const size = this.$_iconSize;
                return {
                    top: `${size + 10}px`,
                };
            },
            avatarStyle() {
                return {
                    'width': `${this.$_avatarWidth}px`,
                    'height': `${this.$_avatarHeight}px`,
                };
            },
            pictureStyle() {
                return {
                    'line-height': `${this.$_avatarHeight}px`,
                    'font-size': `${this.$_avatarHeight / 1.5}px`
                };
            },
            textPartStyle() {
                const getFontSize = () => {
                    if (this.size < 141) {
                        return 14;
                    }
                    if (this.size < 161) {
                        return 16;
                    }
                    if (this.size < 181) {
                        return 17;
                    }
                    return 18;
                };
                return {
                    'height': `${this.$_textPartHeight}px`,
                    'font-size': `${getFontSize()}px`,
                    'line-height': `${getFontSize() + 2}px`,
                    'padding-top': `${getFontSize() / 3}px`,
                };
            },

            editMode() {
                return this.$store.getters['WikiStore/editMode'];
            },
            wikiEditable() {
                return this.$store.getters['WikiStore/wikiEditable'];
            },
            isSubmenu() {
                return this.section.is_submenu;
            },
            sectionTitle() {
                return this.section.caption;
            },
            samples() {
                if (this.section.avatar?.image?.thumbnail_url) {
                    return [this.section.avatar.image.thumbnail_url];
                }
                return this.section.media_content_samples;
            },
            updateBadgesVisible() {
                return this.$store.getters['WikiStore/viewerState'].canSeeUpdateBadges;
            },
            showNewMark() {
                if (this.updateBadgesVisible) {
                    if (this.section.updates_state === 'NEW') {
                        return true;
                    }
                }
            },
            showChangedMark() {
                if (this.updateBadgesVisible) {
                    if (this.section.updates_state === 'UPDATED') {
                        return true;
                    }
                }
            },
            showBothMarks() {
                if (this.updateBadgesVisible) {
                    if (this.section.updates_state === 'NEW_AND_UPD') {
                        return true;
                    }
                }
            },
        },
        methods: {
            focus() {
                this.$_catchFocus(this.$el);
            },
            focusOnAudioOrElement() {
                if (this.$refs.sectionAudioToggle) {
                    this.$refs.sectionAudioToggle.focus();
                } else {
                    this.$_catchFocus(this.$el);
                }
            },
            toggleSound() {
                this.$refs.sectionAudioToggle?.toggleSound();
            },
            handleDragStart(coords) {
                this.$store.commit('WikiStore/setDraggedSection', this.section);
                this.currentPositionX = this.positionBeforeDragX = coords.x;
                this.currentPositionY = this.positionBeforeDragY = coords.y;
            },
            dragGripTouched(e) {
                if (e.targetTouches.length !== 1) {
                    return;
                }
                this.handleDragStart({x: e.targetTouches[0].clientX, y: e.targetTouches[0].clientY});
            },
            dragGripClicked(e) {
                this.handleDragStart({x: e.clientX, y: e.clientY});
            },
            onMouseUp() {
                if (this.isDragged) {
                    this.$store.dispatch('WikiStore/sectionDropped')
                        .then(() => {
                            this.$store.commit('WikiStore/setDraggedSection', undefined);
                            this.$store.commit('WikiStore/setUnderDragSection', undefined);
                        });
                }
            },
            onPointerMove(coords) {
                if (this.isDragged) {
                    this.currentPositionX = coords.x;
                    this.currentPositionY = coords.y;
                }
                if (!this.isDragged && this.$store.getters['WikiStore/draggedSection']) {
                    let rect = this.$el.getBoundingClientRect();
                    const inY = (rect.y < coords.y) && ((rect.y + rect.height) > coords.y);
                    const inX = (rect.x < coords.x) && ((rect.x + rect.width) > coords.x);
                    if (inX && inY) {
                        if (this.$store.getters['WikiStore/underDragSection'] !== this.section) {
                            this.$store.commit('WikiStore/setUnderDragSection', this.section);
                        }
                    } else {
                        if (this.$store.getters['WikiStore/underDragSection'] === this.section) {
                            this.$store.commit('WikiStore/setUnderDragSection', undefined);
                        }
                    }
                }
            },
            onTouchEnd(e) {
                if (!this.isDragged) {
                    return;
                }
                if (e.changedTouches.length !== 1) {
                    return;
                }
                let coords = {x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY};
                let el = document.elementFromPoint(coords.x, coords.y);
                let x = document.createEvent("MouseEvent");
                x.initMouseEvent("mouseup", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                el.dispatchEvent(x);
            },
            editOrAudioClick() {
                if (this.editMode) {
                    this.onEdit();
                } else {
                    if (this.$refs.sectionAudioToggle) {
                        this.$refs.sectionAudioToggle.toggleSound();
                    }
                }
            },
            toggleTick() {
                this.$emit('toggleSectionSelect');
            },
            onDelete() {
                this.$store.dispatch('WikiStore/deleteSection', this.section);
            },
            onEdit() {
                this.$store.dispatch('WikiStore/editSection', this.section);
            }
        },
        watch: {
            isActive(n) {
                if (n) {
                    this.$_catchFocus(this.$el);
                }
            }
        }
    };
</script>

<style scoped lang="scss">
  @import "../styles/const";
  @import "../styles/mixins";

  $icon-font-size: 41px;

  .wiki-section {
    overflow: hidden;
    position: relative;
    vertical-align: top;
    text-decoration: none;

    cursor: pointer;
    //@media (min-width: 768px) {
    //  margin-right: 10px;
    //  margin-left: 10px;
    //}
    //@media (max-width: 767px) {
    //  margin-right: 6px;
    //  margin-left: 6px;
    //}

    margin-bottom: 10px;
    display: inline-block;
    padding-top: 5px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: -10px 11px 12px -8px rgba(0, 0, 0, 0.5);
    transition: box-shadow 0.2s ease-in-out;
    border: 4px solid #fff;

    &.underDrag {
      box-shadow: -10px 11px 12px -8px rgb(60, 138, 61);
      opacity: 0.7;
    }

    &:focus, &.active {
      box-shadow: -10px 11px 12px -8px rgba(11, 149, 255, 0.9);
      border: 4px solid #23a0ff;
    }

    &__avatar-container {
      margin: auto;
      vertical-align: top;
      position: relative;
    }

    &__title-container {
      padding: 5px;
      overflow: hidden;
      display: block;
    }

    &__label {

      text-align: center;
      font-weight: bolder;
      color: $black;
    }

    &__label-counter {
      color: $tag-gray;
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 5px;
      display: inline-block;
    }

    &__avatar-outer {
      display: none;

      &.isSubmenu {
        display: block;
      }

      position: absolute;
      top: 5px;
      left: -5px;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      border: solid 3px;
      border-color: $gray;
    }

    &__avatar-inner {
      border-radius: 5px;
      border: solid 3px;
      border-color: $gray;
      background-color: #fff;


      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &__picture-placeholder {
        text-align: center;
        height: 100%;
        color: $gray;
      }
    }

    &__delete-icon {
      position: absolute;
      right: 5px;
      top: 5px;
      color: $red;
      font-size: 30px;
      z-index: 6;
    }

    &__edit-icon {
      position: absolute;
      right: 3px;
      top: 5px + $icon-font-size + 5px;
      color: $blue;
      font-size: 30px;
      line-height: $icon-font-size;
      z-index: 6;
      /*<!--@include circleAdditionalButton(1, $blue, 'pointer')-->*/
    }

    &__check-button {
      position: absolute;
      cursor: pointer;
      top: 5px;
      left: 5px;
      border-radius: 50%;
      background-color: #fff;
      z-index: 6;
      border: 3px solid $blue;
      text-align: center;
    }

    &__checked-button-icon {
      position: relative;
      top: -4px;
      color: $grass-green;
      font-size: $icon-font-size;
      line-height: $icon-font-size;
    }

    &__check-button-icon {
      position: relative;
      top: -4px;
      color: $lightgray;
      font-size: $icon-font-size / 1.5;
      line-height: $icon-font-size;
    }

    &__sound-and-edit-icon {
      top: 5px;
      right: 5px;
      border-radius: 50%;
      background-color: $gray;
      text-align: center;
      z-index: 5;
      color: white;
      position: absolute;

      &__audio-toggle {

      }
    }

    &__drag-n-drop-icon {
      cursor: grab;
      right: 5px;
      border-radius: 50%;
      background-color: $gray;
      text-align: center;
      z-index: 5;
      color: white;
      position: absolute;
    }

    &__unread-mark {
      position: absolute;
      cursor: pointer;
      top: 5px;
      left: 5px;
      border-radius: 50%;
      z-index: 6;
      border: 3px solid #fff;
      text-align: center;
      color: #fff;

      display: flex;
      align-items: center;
      justify-content: center;

      &_changed {
        background-color: $blue;
      }

      &_new {
        background-color: $online-green;
      }

      &_both {
        background: $online-green;
        background: linear-gradient(90deg, $online-green 50%, $blue 50%);
      }
    }
  }
</style>