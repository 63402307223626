/**
 * Created by orion on 21.05.17.
 */

import urls from 'DjangoUrls';

let { DjangoUrls } = urls;

import mapsStoryLocationPopup from './blog_entry_list/locations_popup/controller';
import BlogEntryGoalsListPopupController from './blog_entry_goals_list/goals_list_popup_controller';

blogEntryClassFactory.$inject = ['$log', '$q', 'viewMediaFactory', '$rootScope', 'BlogEntryResource',
    'BlogEntryGoalsResource', 'notifications', 'Maps', 'simplePopupFactory', 'selectFactory', 'linkToGoalFactory', 'CurrentUser', '$filter', 'BlogEntrySharingResource'];
let _ = require('lodash');
import { getEmotionByCode } from '../diary_page/common/blog_entry_emotions/diaryEntryEmotions';
import EmotionsFactory from '../diary_page/common/blog_entry_emotions/emotionsFactory';
import { customTermFilter } from '../../../vue/filters/CustomTerm';
import shareBlogEntry from './sharing/shareBlogEntry';

function blogEntryClassFactory ($log, $q, viewMediaFactory, $rootScope, BlogEntryResource,
  BlogEntryGoalsResource, notifications, Maps, simplePopupFactory, selectFactory, linkToGoalFactory, CurrentUser, $filter, BlogEntrySharingResource) {
    class BlogEntry {
        constructor (
          entry,
          edit_mode = false,
          access_to_blog_level = 'none',
          access_code = undefined,
          move_entry_handler = _.noop,
          on_delete_handler = _.noop,
          access_token = undefined,
        ) {
            this.entry = entry;
            this.access_code = access_code;
            this.access_token = access_token;
            this.edit_mode = edit_mode;
            this.access_to_blog_level = access_to_blog_level;
            this.move_entry_handler = move_entry_handler;
            this.on_delete_handler = on_delete_handler;

        }

        toggle_edit_mode (enabled) {
            this.edit_mode = enabled;
        }

        get activity_post () {
            return this.entry.activity_post;
        }

        get id () {
            return this.entry.id;
        }

        get event_entry () {
            return this.entry.event_entry;
        }

        get is_buddy_entry () {
            return this.entry.is_buddy_entry;
        }

        get is_proxy_entry () {
            return this.entry.is_proxy_entry;
        }

        get likes_count () {
            return this.entry.likes_count;
        }

        get liked_by_me () {
            return this.entry.liked_by_me;
        }

        get comments_count () {
            return this.entry.comments_count;
        }

        get location_points () {
            return this.entry.location_points;
        }

        get location_points_count () {
            return this.entry.location_points.length;
        }

        get goals_links_count () {
            return this.entry.goals_links_count;
        }

        get entry_items () {
            return this.entry.entry_items;
        }

        get comments_allowed () {
            return this.entry.allow_comments;
        }

        get emotion () {
            return getEmotionByCode(this.entry.emotion);
        }

        get isMyEntry () {
            return CurrentUser.id === this.entry.blog_owner.id;
        }

        get isSupportedUsersEntry () {
            return CurrentUser.is_guardian_of(this.entry.blog_owner.id);
        }

        open_comments_page () {
            $rootScope.show_dimmer();
            let url;
            if (this.access_code) {
                url = DjangoUrls['blog_entry_comments_code'](this.id, this.access_code);
            } else {
                url = DjangoUrls['blog_entry_comments'](this.id);
            }
            document.location.href = $filter('backHref')(url);
        }

        editShareOptions () {
            $rootScope.show_dimmer();
            shareBlogEntry.getMainSharingStatus(this.id)
              .then(resp => {
                  shareBlogEntry.showMainSharePopup(resp.data)
                    .then(what => {
                        if (what === 'circle') {
                            this.editCircleShareOptions();
                        }
                        if (what === 'groups') {
                            this.editGroupsShareOptions();
                        }
                        if (what === 'private') {
                            this.makePrivate();
                        }
                    }, close => {

                    });
              })
              .finally($rootScope.hide_dimmer);
        }

        editCircleShareOptions () {
            shareBlogEntry.setCircleSharingExistedEntry(this.id, this.isMyEntry, {
                name: this.entry.blog_owner.name,
                id: this.entry.blog_owner.id
            })
              .then(([newChecked, newUnchecked, all]) => {
                  $rootScope.show_dimmer();
                  shareBlogEntry.updateCircleSharing(this.id, all, newChecked, newUnchecked)
                    .then(resp => {
                        notifications.success('Sharing updated');
                    }, err => {
                        notifications.error('Failed to update sharing');
                    })
                    .finally($rootScope.hide_dimmer);
              }, cancel => {
                  this.editShareOptions();
              });
        }

        editGroupsShareOptions () {
            shareBlogEntry.setGroupsSharingExistedEntry(this.id, this.isMyEntry, {
                name: this.entry.blog_owner.name,
                id: this.entry.blog_owner.id
            })
              .then(([newChecked, newUnchecked, all]) => {
                  $rootScope.show_dimmer();
                  shareBlogEntry.updateGroupSharing(this.id, newChecked, newUnchecked)
                    .then(resp => {
                        notifications.success('Sharing updated');
                    }, err => {
                        notifications.error('Failed to update sharing');
                    })
                    .finally($rootScope.hide_dimmer);
              }, cancel => {
                  this.editShareOptions();
              });
        }

        makePrivate () {
            $rootScope.show_dimmer();
            shareBlogEntry.makePrivate(this.id)
              .then(resp => {
                  notifications.success('Made Private');
              }, err => {
                  notifications.error('Failed to update sharing');
              })
              .finally($rootScope.hide_dimmer);

        }

        like () {
            if (this.access_code) {
                return;
            }

            let already_liked = this.entry.liked_by_me;
            this.entry.liked_by_me = !already_liked;
            if (already_liked) {
                this.entry.likes_count--;
            } else {
                this.entry.likes_count++;
            }
            let factory = already_liked ? BlogEntryResource.unlike : BlogEntryResource.like;
            factory(this.id).then(resp => {
                this.entry.likes_count = resp.data.likes_count;
                notifications.success(already_liked ? 'Unliked' : 'Liked');
            }, err => {
                notifications.error(err || 'Error');
                this.entry.liked_by_me = already_liked;
                if (this.entry.liked_by_me) {
                    this.entry.likes_count--;
                } else {
                    this.entry.likes_count++;
                }
            });
        }

        add_place () {
            let result = $q.defer();
            Maps.find_location()
              .then(location => {
                  $rootScope.show_dimmer();
                  return BlogEntryResource.link_to_location(this.id, location.pk);
              }, () => new Promise(() => {
              }))
              .then(resp => {
                  this.entry.location_points.push(resp.data);
                  this.entry.location_points_count = this.entry.location_points.length;
                  result.resolve(resp.data);
                  notifications.success('Created');
              }, err => notifications.error(err || 'Error'))
              .finally(() => $rootScope.hide_dimmer());
            return result.promise;
        };

        show_locations () {
            let modalResultPromise = simplePopupFactory.create_popup(mapsStoryLocationPopup, require('./blog_entry_list/locations_popup/template.html'), {
                locations: this.location_points,
                ALLOW_ADD: this.access_to_blog_level === 'edit',
                ALLOW_REMOVE: this.access_to_blog_level === 'edit',
            });

            modalResultPromise.then(res => {
                if (res.do === 'add location') {
                    this.add_place().then(() => {
                        this.show_locations();
                    });
                } else {
                    if (res.do === 'delete location') {
                        let location_id = res.location_id;
                        $rootScope.show_dimmer();
                        BlogEntryResource.unlink_location(this.id, location_id).then(
                          resp => {
                              this.entry.location_points = this.entry.location_points.filter(l => l.id !== location_id);
                              this.entry.location_points_count = this.entry.location_points.length;
                              notifications.success('Removed');
                              this.show_locations();
                          },
                          err => {
                              notifications.error(err || 'Error');
                          }
                        ).finally(() => $rootScope.hide_dimmer());
                    }
                }
            });
        }

        move_up () {
            this.move_entry_handler(this, 'up');
        }

        goals_visible () {
            return this.access_to_blog_level === 'view' || this.access_to_blog_level === 'edit';
        }

        move_down () {
            this.move_entry_handler(this, 'down');
        }

        del () {
            simplePopupFactory.show_popup('Delete entry', 'Are you sure you want to delete this entry?', 'Delete', 'Cancel', 'btn-red')
              .then(() => {
                  return BlogEntryResource.delete_entry(this.id);
              }, err => new Promise(() => {
              }))
              .then((resp) => {
                  $rootScope.show_dimmer();
                  this.on_delete_handler(this);

              }, err => notifications.error(err || 'Error'))
              .finally(() => $rootScope.hide_dimmer());
        }

        remove_emotion () {
            $rootScope.show_dimmer();
            BlogEntryResource.set_emotion(this.id, null)
              .then(resp => {
                  this.entry.emotion = undefined;
              }, err => notifications.error(err.data | 'Error'))
              .finally(() => $rootScope.hide_dimmer());
        }

        add_emotion () {
            const ef = new EmotionsFactory();
            let emotion;
            ef.showAddEmotionsPopup()
              .then(em => {
                  emotion = em;
                  $rootScope.show_dimmer();
                  return BlogEntryResource.set_emotion(this.id, emotion.code);
              }, () => new Promise(() => {
              }))
              .then(resp => {
                  this.entry.emotion = emotion.code;
              })
              .finally(() => $rootScope.hide_dimmer());
        }

        details (edit_mode) {
            $rootScope.show_dimmer();
            let url;
            if (this.access_code) {
                url = DjangoUrls['blog_entry_detail_code'](this.id, this.access_code);
            } else {
                url = DjangoUrls['blog_entry_detail'](this.id);
            }
            document.location.href = $filter('backHrefQuery')(url);
        }

        goals () {
            let modalResultPromise = simplePopupFactory.create_popup(BlogEntryGoalsListPopupController,
              require('./blog_entry_goals_list/goals_list_popup.html'), {
                  linked_goals: BlogEntryGoalsResource.get_linked_goals(this.id, { access_code: this.access_code }, this.access_token),
                  ALLOW_ADD: !this.access_code && (this.access_to_blog_level === 'view' || this.access_to_blog_level === 'edit'),
                  ALLOW_REMOVE: this.access_to_blog_level === 'edit',
                  ALLOW_VIEW_GOAL: !this.access_code
              });

            modalResultPromise.then(res => {
                if (res.do === 'link_goal') {
                    const is_current_user = (CurrentUser.id === this.entry.blog_owner.id);
                    const is_owner_or_buddy = (is_current_user || CurrentUser.is_guardian_of(this.entry.blog_owner.id));
                    const blog_owner_username = this.entry.blog_owner.name;
                    link_entry_to_goal(this.id, is_owner_or_buddy, is_current_user ? undefined : blog_owner_username).then(goals_links_count => {
                        this.entry.goals_links_count = goals_links_count;
                        this.goals();
                    }, () => {
                        this.goals();
                    });
                } else {
                    if (res.do === 'delete_goal') {
                        let goal_id = res.id;
                        simplePopupFactory.show_popup(`Remove All Goals ${customTermFilter('Tasks')}`, `Are you sure you want to unlink all ${customTermFilter('Goal')} ${customTermFilter('Tasks')} from this entry?`, 'Yes', 'No', 'btn-red')
                          .then(() => {
                              $rootScope.show_dimmer();
                              return BlogEntryGoalsResource.unlink_all_blog_tasks(this.id, goal_id);
                          }, () => {
                              setTimeout(() => {
                                  this.goals();
                              }, 100);
                              return new Promise(() => {
                              });
                          })
                          .then(
                            resp => {
                                this.entry.goals_links_count = resp.data.goals_links_count;
                                notifications.success('Removed');
                                this.goals();
                            },
                            err => {
                                notifications.error(err || 'Error');
                            }
                          ).finally($rootScope.hide_dimmer);
                    }
                }
            });

        }
    }

    return {
        BlogEntry
    };

    function link_entry_to_goal (entry_id, allow_create, for_user = undefined) {
        let res = $q.defer();
        linkToGoalFactory.link_to_goal(BlogEntryGoalsResource, entry_id, allow_create, for_user)
          .then(res => {
              $rootScope.show_dimmer();
              let new_checked_ids = res[0];
              let new_unchecked_ids = res[1];
              return BlogEntryGoalsResource.link_to_task(entry_id, new_checked_ids, new_unchecked_ids);
          }, () => new Promise(() => {
          }))
          .then((resp) => {
              res.resolve(resp.data.goals_links_count);
              notifications.success('Linked');
          }, err => {
              notifications.error(err || 'Error');
          })
          .finally(() => $rootScope.hide_dimmer())
        ;

        return res.promise;
    }

}

export default blogEntryClassFactory;
