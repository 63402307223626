var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vue-folder-item__container",
      style: _vm.containerSize,
      attrs: {
        tabindex: "0",
        role: "figure",
        "aria-label": _vm.attachment.name,
      },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.itemClick.apply(null, arguments)
        },
      },
    },
    [
      _vm.allowDelete
        ? _c(
            "div",
            {
              staticClass: "vue-folder-item__delete-button hidden-print",
              attrs: { "aria-label": "Delete", role: "button", tabindex: "0" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.deleteClick.apply(null, arguments)
                },
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.deleteClick.apply(null, arguments)
                },
              },
            },
            [
              _c("i", {
                staticClass: "fa fa-trash-o",
                attrs: { "aria-hidden": "true" },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.tooltipText,
              expression: "tooltipText",
            },
          ],
          staticClass: "vue-folder-item",
        },
        [
          _c(
            "div",
            {
              staticClass: "vue-folder-item__preview text-center",
              style: _vm.previewStyle,
              on: { click: _vm.itemClick },
            },
            [
              !_vm.iconNeeded
                ? _c("img", {
                    staticClass:
                      "visible-print vue-folder-item__attachment-print-image",
                    attrs: { src: _vm.attachment.thumbnail, alt: "" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.iconNeeded
                ? _c("i", {
                    staticClass: "fa vue-folder-item__preview__icon",
                    class: _vm.iconClass,
                    style: _vm.iconStyle,
                    attrs: { "aria-hidden": "true" },
                  })
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "vue-folder-item__footer" }, [
            _c(
              "div",
              {
                staticClass: "vue-folder-item__footer__name",
                attrs: { id: `vue-folder-item__name_${_vm.attachment.id}` },
              },
              [_c("span", [_vm._v(_vm._s(_vm.attachment.name))])]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "vue-folder-item__footer__icon" }, [
              _c("i", {
                staticClass: "fa",
                class: _vm.iconClass,
                attrs: { "aria-hidden": "true" },
              }),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }