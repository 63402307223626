var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "simple-wrapper-link",
    { style: _vm.containerStyle, attrs: { to: _vm.to } },
    [
      _c(
        "div",
        {
          staticClass: "item_preview__avatar",
          class: _vm.colorClass,
          style: _vm.avatarStyle,
        },
        [
          _vm.unreadMark
            ? _c(
                "div",
                {
                  staticClass: "item_preview__unread_mark",
                  style: _vm.unreadMarkStyle,
                },
                [_c("i", [_vm._v("NEW")])]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.deletable
            ? _c(
                "div",
                {
                  staticClass: "item_preview__delete_mark",
                  attrs: {
                    tabindex: "0",
                    role: "button",
                    "aria-label": "Delete",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.onDeleteClick.apply(null, arguments)
                    },
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.onDeleteClick.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fal fa-trash",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.superAvatar
            ? _c("div", {
                staticClass: "item_preview__super-avatar",
                style: _vm.superAvatarStyle,
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.audioCaption
            ? _c(
                "div",
                { staticClass: "item_preview__audio-caption-container" },
                [
                  _c("audio-toggle", {
                    attrs: { "audio-caption": _vm.audioCaption },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "item_preview__name" }, [
        _vm._v(_vm._s(_vm._f("cutName")(_vm.name, _vm.cutNameLength))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }