import Vue from 'vue';
import {VISUAL_DATE_FORMAT} from "../new_calendar/config";

export default {
    data() {
        return {
            statusData: undefined
        };
    },
    methods: {
        sendCircleJoinRequest() {
            const $rootScope = Vue.getAngularModule('$rootScope');
            const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
            const notifications = Vue.getAngularModule('notifications');
            const $q = Vue.getAngularModule('$q');
            const UserProfileResource = Vue.getAngularModule('UserProfileResource');
            const CurrentUser = Vue.getAngularModule('CurrentUser');
            simplePopupFactory.show_popup('Add to My Circle', `Are you sure you want to add ${this.statusData.circle_owner.name} to your Circle?`, 'Yes', 'No')
                .then(res => {
                    if (CurrentUser.is_circle_user()) {
                        return this.$refs['email-role-category-popup'].show({
                            header: 'Add user',
                            button_yes: 'Add'
                        }, ['role', 'category']);
                    } else {
                        let pr = $q.defer();
                        pr.resolve({role: undefined, category: undefined});
                        return pr.promise;
                    }

                }, () => new Promise(()=>{}))
                .then(({role, category}) => {
                    $rootScope.show_dimmer();
                    return UserProfileResource.add_as_friend(this.statusData.circle_owner.id, {role, category});
                }, n => $q.defer().promise)
                .then(resp => {
                    if (resp.data.type === 'request_sent') {
                        this.statusData.is_join_request_pending = true;
                        notifications.success(`Your request to add ${this.statusData.circle_owner.name} to your Circle has been sent`);
                    } else {
                        if (resp.data.type === 'auto_added_due_counter_request') {
                            notifications.success(`User was added to your Circle.`);
                            this.$emit('onEventJoined');
                            this.redirectToInfoPage();
                        } else if (resp.data.type === 'blocking') {
                            notifications.error(`Can't send request due to blocking`);
                        }
                    }

                }, err => notifications.error(err || 'Error'))
                .finally($rootScope.hide_dimmer);
        },
        sendGroupJoinRequest() {
            const $rootScope = Vue.getAngularModule('$rootScope');
            const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
            const notifications = Vue.getAngularModule('notifications');
            const GroupResource = Vue.getAngularModule('GroupResource');
            $rootScope.show_dimmer();
            GroupResource.join(this.statusData.group.id)
                .then(resp => {
                    let status = resp.data.status; // 'joined' or 'wait_confirmation'
                    if (status === 'joined') {
                        notifications.success('Joined');
                        this.$emit('onEventJoined');
                        this.redirectToInfoPage();
                    }
                    if (status === 'wait_confirmation') {
                        $rootScope.hide_dimmer();
                        this.statusData.is_join_request_pending = true;
                        simplePopupFactory.show_popup('Join request sent.', 'You will join Group after approval. We will notify you.', 'OK');
                    }
                }, err => {
                    notifications.error(err || 'Error');
                    $rootScope.hide_dimmer();
                });
        },

        checkJoinAbility(hasAccessCallback) {
            const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
            const notifications = Vue.getAngularModule('notifications');
            return CalendarEventsResource.check_access_and_join_ability(this.eventId, this.dateMoment.format(VISUAL_DATE_FORMAT))
                .then(resp => {
                    this.statusData = resp.data;
                    if (this.statusData.status === 'CAN_VIEW') {
                        hasAccessCallback();
                    }
                }, err => {
                    notifications.error(err || 'Error');
                });
        },
        hasPermission() {
            const CurrentUser = Vue.getAngularModule('CurrentUser');
            return CurrentUser.has_permission();
        },

        eventJoinRequestSentListener() {
            this.statusData.is_join_request_pending = true;
        },
    },
    mounted() {
        this.$on('onJoinRequestSent', this.eventJoinRequestSentListener);
        this.$on('onEventJoined', this.eventJoinedListener);
    },
    beforeDestroy() {
        this.$off('onJoinRequestSent', this.eventJoinRequestSentListener);
        this.$off('onEventJoined', this.eventJoinedListener);
    }
};
