import Vue from 'vue';
import FullAddressPopup from "../../angular/app/events/create_new_event/FullAddressPopup";

export default {
    data() {
        return {
            opened: false,

            mediaForAvatar: undefined,
            mediaForAudioCaption: undefined,
            locationData: undefined,
        };
    },
    computed: {
        locationPreviewSrc() {
            if (!this.locationData) {
                return;
            }
            const Maps = Vue.getAngularModule('Maps');
            return Maps.get_static_map_href(this.locationData, "100x70");
        },
        locationCaption() {
            if (!this.locationData) {
                return;
            }
            return this.locationData.address;
        },
    },
    methods: {
        addAvatar() {
            const addMedia = Vue.getAngularModule('addMedia');
            this.tmpHide();

            let show_add_media_menu = (default_callback, cancel_callback) => {
                addMedia.add_media(
                    {
                        allow_add_from_library: true,
                        max_count: 1,
                        type: 'image',
                        popup_header: 'How you would like to add a picture?'
                    },
                    {
                        default_callback,
                        cancel_callback
                    }
                );
            };

            let add_media_items = (items_list) => {
                this.mediaForAvatar = items_list[0];
                this.tmpShow();
            };

            show_add_media_menu(add_media_items, () => {
                this.tmpShow();
            });
        },
        addAudio() {
            const addMedia = Vue.getAngularModule('addMedia');
            this.tmpHide();
            let show_add_media_menu = () => {
                addMedia.add_media(
                    {
                        allow_add_from_library: true,
                        max_count: 1,
                        type: 'audio',
                        popup_header: 'What sort of media would you like to add?'
                    },
                    {
                        default_callback: (items_list) => {
                            this.mediaForAudioCaption = items_list[0];
                            this.tmpShow();
                        },
                        cancel_callback: () => {
                            this.tmpShow();
                        }
                    }
                );
            };
            show_add_media_menu();
        },
        addLocation() {
            const Maps = Vue.getAngularModule('Maps');
            const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
            const $q = Vue.getAngularModule('$q');
            this.tmpHide();
            let point, address_line_1, address_line_2, region, country, city, postcode, lat, lng, zoom;

            function location_options(initial_data, popup_config = {}) {

                let DEFAULT_POPUP_CONFIG = {
                    button_yes: 'OK',
                };
                const {popupComponent, vm} = simplePopupFactory.mount_vue_popup(FullAddressPopup, {
                    initialData: initial_data,
                    popup_config: _.merge({}, DEFAULT_POPUP_CONFIG, popup_config)
                });
                return new Promise((resolve) => {
                    popupComponent.show().then((popup_result)=>{
                        vm?.$destroy();
                        resolve(popup_result);
                    });
                })
            }

            Maps.find_location_no_create()
                .then(
                    foundPoint => {
                        zoom = foundPoint.zoom;
                        lat = foundPoint.point.lat;
                        lng = foundPoint.point.lng;
                        if (foundPoint.addressComponents !== undefined) {
                            ({
                                postcode,
                                country,
                                region,
                                city
                            } = Maps.get_data_from_address_components(foundPoint.addressComponents));
                            address_line_1 = foundPoint.address;
                        } else {
                            let address_components = _.split(foundPoint.address, ', ');
                            if (address_components.length > 1) {
                                country = address_components.pop();
                            }
                            if (address_components.length > 1) {
                                city = address_components.pop();
                            }
                            address_line_1 = _.join(address_components, ', ');
                        }
                        return location_options({address_line_1, country, city, postcode, region});
                    }, () => $q.reject()
                )
                .then(location_details => {
                    ({address_line_1, address_line_2, region, country, city, postcode} = location_details);
                    this.locationData = {
                        address_line_1,
                        address_line_2,
                        region,
                        country,
                        city,
                        postcode,
                        lat,
                        lng,
                        zoom,
                    };
                }, () => $q.reject())
                .finally(this.tmpShow);
        },
        cleanAvatar() {
            this.mediaForAvatar = undefined;
        },
        cleanAudioCaption() {
            this.mediaForAudioCaption = undefined;
        },
        cleanLocation() {
            this.locationData = undefined;
        },
    }
};
