import MediaShareWhereListPopup
    from "~/angular/app/pages/folders/media_sharing/sharing_main_popup/MediaShareWhereListPopup.vue";

mediaShareFactory.$inject = ['$rootScope', '$q', 'notifications', 'simplePopupFactory', 'selectFactory', 'MediaFolderSharingResource'];

function mediaShareFactory($rootScope, $q, notifications, simplePopupFactory, selectFactory, MediaFolderSharingResource) {

    let activeResource;
    let header_text;
    let name_in_warning_text;

    return {
        // shareSlide(targetId) {
        //     activeResource = WikiSlideSharingResource;
        //     header_text = 'Share this page with?';
        //     name_in_warning_text = 'this Page and all it\'s attachments';
        //     showMainMenu(targetId);
        // },
        shareFolder(targetId, allowShareToCommunity = false) {
            activeResource = MediaFolderSharingResource;
            header_text = 'Share this Folder with?';
            name_in_warning_text = 'this Folder and all it\'s Folders and Items';
            showMainMenu(targetId, allowShareToCommunity);
        },
        // shareWiki(targetId) {
        //     activeResource = WholeWikiSharingResource;
        //     header_text = 'Share this whole Wiki with?';
        //     name_in_warning_text = 'this Wiki and all it\'s Sections';
        //     showMainMenu(targetId);
        // }
    };

    function showMainMenu(targetId, allowShareToCommunity = false) {
        $rootScope.show_dimmer();
        activeResource.get_sharing_main_status(targetId)
            .then(resp => {
                $rootScope.hide_dimmer();
                const {fEl, popupComponent, vm} = simplePopupFactory.mount_vue_popup(MediaShareWhereListPopup);
                return new Promise((resolve, reject) => {
                    popupComponent.show(header_text, resp.data, allowShareToCommunity)
                        .then(
                            selected => {
                                resolve(selected);
                            },
                            cancel => {
                                reject(cancel);
                            }
                        )
                        .finally(() => {
                            fEl?.focus();
                            vm?.$destroy();
                        });
                });
            }, err => {
                $rootScope.hide_dimmer();
                notifications.error(err || 'Error');
            })
            .then(result => {
                if (result === 'circle') {
                    shareTargetWithCircle(targetId);
                }
                if (result === 'groups') {
                    shareTargetWithGroups(targetId);
                }
                if (result === 'communities') {
                    shareTargetWithCommunities(targetId);
                }
                if (result === 'private') {
                    makeTargetPrivate(targetId);
                }
            }, () => new Promise(()=>{}));
    }


    function shareTargetWithCircle(targetId) {
        let modalResult = selectFactory.endpoint_selector(_.partial(activeResource.get_friends_for_share, targetId), true, {
            header: `Share this with my Circle?`,
            info: `Select or de-select Members of your Circle below, or share with all your Circle automatically.`,
            button_yes: 'Update Circle sharing',
            button_no: 'Cancel',
            empty: `You do not have any Circle Members to select`,
            select_all_html: 'Share with all My Circle,<br>and new people I add'
        }, true, true, false);

        let select_result;

        modalResult
            .then((res) => {
                select_result = res;
                let new_checked_ids, new_unchecked_ids, all;
                [new_checked_ids, new_unchecked_ids, all] = select_result;
                let POPUP_TEXT = `Are you sure you want to edit sharing of ${name_in_warning_text} for these users? <br><br> Through sharing this you are giving consent for these users to view the shared material and download any linked attachments. Users are not permitted to share this on with other users, or download or use any of the material for any other purposes.`;
                if (new_checked_ids.length > 1 || all && !new_unchecked_ids.length) {
                    POPUP_TEXT = `Are you sure you want to share ${name_in_warning_text} with these users? <br><br> Through sharing this you are giving consent for these users to view the shared material and download any linked attachments. Users are not permitted to share this on with other users, or download or use any of the material for any other purposes.`;
                }
                if (new_checked_ids.length === 1 && !new_unchecked_ids.length) {
                    POPUP_TEXT = `Are you sure you want to share ${name_in_warning_text} with this user? <br><br> Through sharing this you are giving consent for this user to view the shared material and download any linked attachments. This user is not permitted to share this on with other users, or download or use any of the material for any other purposes.`;
                }
                if (!new_checked_ids.length && !all && new_unchecked_ids.length > 1) {
                    POPUP_TEXT = `Are you sure you want to remove sharing of ${name_in_warning_text} for these users?`;
                }
                if (!new_checked_ids.length && !all && new_unchecked_ids.length === 1) {
                    POPUP_TEXT = `Are you sure you want to remove sharing of ${name_in_warning_text} for this user?`;
                }
                return simplePopupFactory.show_popup('Share', POPUP_TEXT, 'Yes', 'Cancel');

            }, () => new Promise(()=>{}))
            .then(y => {
                let new_checked_ids, new_unchecked_ids, all;
                [new_checked_ids, new_unchecked_ids, all] = select_result;
                $rootScope.show_dimmer();
                return activeResource.share_with_friends(targetId, {new_checked_ids, new_unchecked_ids, all});
            }, () => new Promise(()=>{}))
            .then(resp => {
                notifications.success('Sharing changes successfully saved.');
            }, err => notifications.error(err || 'Error'))
            .finally($rootScope.hide_dimmer);


    }

    function shareTargetWithGroups(targetId) {
        let modalResult = selectFactory.endpoint_selector(_.partial(activeResource.get_groups_for_share, targetId), true, {
            header: `Share this with my Groups?`,
            info: 'Choose this option to share this with whole Groups of people,\n' +
                ' click the Group Avatar to select or de-select the Group you\n' +
                ' wish to share or un-share with.',
            button_yes: 'Update Group sharing',
            button_no: 'Cancel',
            empty: `You do not have any Groups to select`,
            long_text: true,
        }, true);

        let select_result;

        modalResult
            .then((res) => {
                select_result = res;
                let new_checked_ids, new_unchecked_ids, all;
                [new_checked_ids, new_unchecked_ids, all] = res;
                let POPUP_TEXT = `Are you sure you want to edit sharing of ${name_in_warning_text} for these groups? <br><br> Through sharing this you are giving consent for these Groups to view the shared material and download any linked attachments. Users in these Groups are not permitted to share this on with other users, or download or use any of the material for any other purposes.`;
                if (new_checked_ids.length > 1 || all && !new_unchecked_ids.length) {
                    POPUP_TEXT = `Are you sure you want to share ${name_in_warning_text} with these groups? <br><br> Through sharing this you are giving consent for these Groups to view the shared material and download any linked attachments. Users in these Groups are not permitted to share this on with other users, or download or use any of the material for any other purposes.`;
                }
                if (new_checked_ids.length === 1 && !new_unchecked_ids.length) {
                    POPUP_TEXT = `Are you sure you want to share ${name_in_warning_text} with this group? <br><br> Through sharing this you are giving consent for this Group to view the shared material and download any linked attachments. Users in this Group are not permitted to share this on with other users, or download or use any of the material for any other purposes.`;
                }
                if (!new_checked_ids.length && !all && new_unchecked_ids.length > 1) {
                    POPUP_TEXT = `Are you sure you want to remove sharing of ${name_in_warning_text} for these groups?`;
                }
                if (!new_checked_ids.length && !all && new_unchecked_ids.length === 1) {
                    POPUP_TEXT = `Are you sure you want to remove sharing of ${name_in_warning_text} for this group?`;
                }
                return simplePopupFactory.show_popup('Share', POPUP_TEXT, 'Yes', 'Cancel');

            }, () => new Promise(()=>{}))
            .then(y => {
                let new_checked_ids, new_unchecked_ids, all;
                [new_checked_ids, new_unchecked_ids, all] = select_result;
                $rootScope.show_dimmer();
                activeResource.share_with_groups(targetId, {new_checked_ids, new_unchecked_ids, all});
            }, () => new Promise(()=>{}))
            .then(resp => {
                notifications.success('Sharing changes successfully saved.');
            }, err => notifications.error(err || 'Error'))
            .finally($rootScope.hide_dimmer);

    }

    function shareTargetWithCommunities(targetId) {
        let modalResult = selectFactory.endpoint_selector(_.partial(activeResource.get_communities_for_share, targetId), true, {
            header: `Share this with my Communities?`,
            info: 'Choose this option to share this with whole Community,\n' +
                ' click the Community Avatar to select or de-select the Community you\n' +
                ' wish to share or un-share with.',
            button_yes: 'Update Community sharing',
            button_no: 'Cancel',
            empty: `You do not have any Community to select`,
            long_text: true,
        }, true);

        let select_result;

        modalResult
            .then((res) => {
                select_result = res;
                let new_checked_ids, new_unchecked_ids, all;
                [new_checked_ids, new_unchecked_ids, all] = res;
                let POPUP_TEXT = `Are you sure you want to edit sharing of ${name_in_warning_text} for these groups? <br><br> Through sharing this you are giving consent for these Groups to view the shared material and download any linked attachments. Users in these Groups are not permitted to share this on with other users, or download or use any of the material for any other purposes.`;
                if (new_checked_ids.length > 1 || all && !new_unchecked_ids.length) {
                    POPUP_TEXT = `Are you sure you want to share ${name_in_warning_text} with these groups? <br><br> Through sharing this you are giving consent for these Groups to view the shared material and download any linked attachments. Users in these Groups are not permitted to share this on with other users, or download or use any of the material for any other purposes.`;
                }
                if (new_checked_ids.length === 1 && !new_unchecked_ids.length) {
                    POPUP_TEXT = `Are you sure you want to share ${name_in_warning_text} with this group? <br><br> Through sharing this you are giving consent for this Group to view the shared material and download any linked attachments. Users in this Group are not permitted to share this on with other users, or download or use any of the material for any other purposes.`;
                }
                if (!new_checked_ids.length && !all && new_unchecked_ids.length > 1) {
                    POPUP_TEXT = `Are you sure you want to remove sharing of ${name_in_warning_text} for these groups?`;
                }
                if (!new_checked_ids.length && !all && new_unchecked_ids.length === 1) {
                    POPUP_TEXT = `Are you sure you want to remove sharing of ${name_in_warning_text} for this group?`;
                }
                return simplePopupFactory.show_popup('Share', POPUP_TEXT, 'Yes', 'Cancel');

            }, () => new Promise(()=>{}))
            .then(y => {
                let new_checked_ids, new_unchecked_ids, all;
                [new_checked_ids, new_unchecked_ids, all] = select_result;
                $rootScope.show_dimmer();
                activeResource.share_with_communities(targetId, {new_checked_ids, new_unchecked_ids, all});
            }, () => new Promise(()=>{}))
            .then(resp => {
                notifications.success('Sharing changes successfully saved.');
            }, err => notifications.error(err || 'Error'))
            .finally($rootScope.hide_dimmer);

    }

    function makeTargetPrivate(targetId) {
        simplePopupFactory.show_popup('Share', 'Are you sure you want to remove all sharing of this page and its attachments for all users and groups?', 'Yes', 'Cancel')
            .then(y => {
                $rootScope.show_dimmer();
                return activeResource.keep_private(targetId);
            }, () => new Promise(()=>{}))
            .then(resp => {
                notifications.success('Sharing changes successfully saved.');
            }, err => notifications.error(err || 'Error'))
            .finally($rootScope.hide_dimmer);
    }
}

export default mediaShareFactory;
