var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _vm.allLoaded && !_vm.results.length
        ? _c("div", { staticClass: "margin-15-top" }, [_vm._t("empty")], 2)
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.results, function (item) {
        return _c("story-list-item", {
          key: item.id,
          attrs: {
            item: item,
            "edit-mode": _vm.editMode,
            "allow-like": _vm.allowLike,
          },
          on: {
            onDrop: _vm.onDrop,
            del: function ($event) {
              return _vm.del(item)
            },
            edit: function ($event) {
              return _vm.editStoryItem(item)
            },
            moveUp: function ($event) {
              return _vm.moveUp(item)
            },
            moveDown: function ($event) {
              return _vm.moveDown(item)
            },
            like: function ($event) {
              return _vm.like(item)
            },
            unlike: function ($event) {
              return _vm.unlike(item)
            },
            showWhoLiked: function ($event) {
              return _vm.showWhoLiked(item)
            },
          },
        })
      }),
      _vm._v(" "),
      _vm.allowAddContent
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12 text-right" }, [
              _vm.allowBulkCreate
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-mm btn-default margin-5-top",
                      on: { click: _vm.bulkUpload },
                    },
                    [
                      _c("i", { staticClass: "fa fa-plus" }),
                      _vm._v(" Bulk Media Upload\n            "),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-mm btn-green margin-5-top",
                  on: { click: _vm.addPost },
                },
                [
                  _c("i", { staticClass: "fa fa-plus" }),
                  _vm._v(" " + _vm._s(_vm.addButtonText) + "\n            "),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "infinite-scroll",
            rawName: "v-infinite-scroll",
            value: _vm.loadMore,
            expression: "loadMore",
          },
        ],
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }