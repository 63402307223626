import Sentry from './raven';

const isSentryDSNSet = !!SENTRY_DSN;
const RavenVuePlugin = {
    install(Vue) {
        Vue.raven = {
            message(message, level = 'error') {
                if (isSentryDSNSet) {
                    Sentry.captureMessage(message, {level: level});
                }
            },
            error(message) {
                this.message(message, 'error');
            },
            exception(e) {
                if (isSentryDSNSet) {
                    Sentry.captureException(e);
                }
            },
            endpointException(name, error, level = 'warning') {
                error.name = 'endpointException';
                Sentry.captureException(new Error(name), {
                    level: level,
                    extra: error,
                    tags: {eType: 'endpointException'}
                });
            }
        };
        window.Sentry = Sentry;
    }
};

export default RavenVuePlugin;