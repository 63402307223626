/**
 * Created by orion on 07.03.17.
 */
import Vue from 'vue';

function addDimmersToRootScope($rootScope) {
    $rootScope.loader = undefined;

    $rootScope.show_dimmer = function (dimmer) {
        Vue.loadingSpinner?.show();
    };

    $rootScope.hide_dimmer = function () {
        setTimeout(Vue.loadingSpinner?.hide, 10);
    };
}

export default addDimmersToRootScope;