var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.header)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isFutureEvent
        ? _c("div", { staticClass: "alert alert-info" }, [
            _c("strong", [_vm._v("Note:")]),
            _vm._v(" You are creating a Diary entry for a "),
            _c("strong", [_vm._v("future")]),
            _vm._v(" Event, the entry will\n        appear in the Diary on "),
            _c("strong", [_vm._v("today's")]),
            _vm._v(
              " date. A link to the entry will also appear on the Event\n        page.\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isPastEvent
        ? _c("div", { staticClass: "alert alert-info" }, [
            _c("strong", [_vm._v("Note:")]),
            _vm._v(" You are creating a Diary entry for a "),
            _c("strong", [_vm._v("past")]),
            _vm._v(
              " Event, the entry will\n        appear in the Diary on the "
            ),
            _c("strong", [_vm._v("date of the Event")]),
            _vm._v(
              ". A link to the entry will also appear on the\n        Event page.\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "create-entry-popup" },
        [
          _c(
            "tabs",
            {
              model: {
                value: _vm.openedTab,
                callback: function ($$v) {
                  _vm.openedTab = $$v
                },
                expression: "openedTab",
              },
            },
            [
              _c("tab", { attrs: { title: "Multimedia Blog" } }, [
                _c(
                  "div",
                  {},
                  [
                    _c("event-detail-page-create-diary-entry-popup-blog-part", {
                      ref: "blog-part",
                      attrs: {
                        "membership-status": _vm.membershipStatus,
                        "calendar-owner": _vm.calendarOwner,
                        "period-info": _vm.periodInfo,
                        "date-moment": _vm.dateMoment,
                        "buddy-mode": _vm.buddyMode,
                        "can-post-in-other-diaries": _vm.canPostInOtherDiaries,
                      },
                      on: {
                        hide: _vm.tmpHide,
                        show: _vm.tmpShow,
                        toggleSaveReady: _vm.toggleSaveReady,
                        selectedSUListUpdated: _vm.selectedSUListUpdated,
                        addToSuDiariesChanged: _vm.addToSuDiariesChanged,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tab", { attrs: { title: _vm.tabGoalsTitle } }, [
                _c(
                  "div",
                  { staticClass: "create-entry-popup__content-container" },
                  [
                    _c(
                      "event-detail-page-create-diary-entry-popup-goals-part",
                      {
                        ref: "goals-part",
                        attrs: {
                          "calendar-owner": _vm.calendarOwner,
                          "event-id": _vm.periodInfo.event_id,
                          "buddy-mode": _vm.buddyMode,
                          "calendar-owner-is-own-events-admin":
                            _vm.calendarOwnerIsOwnEventsAdmin,
                          "add-to-su-diaries": _vm.addToSuDiaries,
                          profilesToCreateProxyEntries:
                            _vm.profilesToCreateProxyEntries,
                        },
                        on: {
                          linkedTasksCountChanged: _vm.linkedTasksCountChanged,
                          hide: _vm.tmpHide,
                          show: _vm.tmpShow,
                        },
                      }
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.editableLocationsData.length
                ? _c("tab", { attrs: { title: "Log Notes" } }, [
                    _c(
                      "div",
                      { staticClass: "create-entry-popup__content-container" },
                      [
                        _c(
                          "event-detail-page-create-diary-entry-popup-logs-part",
                          {
                            ref: "locations-part",
                            attrs: { "calendar-owner": _vm.calendarOwner },
                          }
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("button", { staticClass: "btn btn-mm", on: { click: _vm.close } }, [
          _vm._v("Cancel"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.saveButtonTooltip,
                expression: "saveButtonTooltip",
              },
            ],
            staticClass: "inline-block",
            attrs: { "data-role": "trigger" },
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-green",
                attrs: { disabled: !_vm.saveButtonEnabled() },
                on: { click: _vm.saveEntry },
              },
              [_vm._v("Create Entry\n            ")]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }