<template>
    <div class="c-event" :style="eventStyle" @click="onClick" @touchstart.stop.prevent="onClick" :class="eventClass"
         v-tooltip="eventTooltip">
        <div class="c-event__inner" :style="innerBlockStyle">
            <div class="">
                <div class="c-event__info-part__name-label">
                    <strong @click.stop @touchstart.stop>
                        <router-link :to="eventLink |backHrefQuery">{{
                        event.event_data.name|cutName(32)}}</router-link>
                    </strong>
                </div>
            </div>
            <div class="">
                <div class="c-event__avatar-part">
                    <div class="c-event__avatar" :style="avatarStyle">
                        <i v-if="event.is_periodic"
                           class="fa fa-repeat c-event__avatar__periodic-mark"></i>
                    </div>

                </div>
                <div class="c-event__info-part">
                    <div class="inline-block vertical-top" style="min-width: 27px"
                         v-if="event.event_data.audio_caption">
                        <audio-toggle
                                @click.native.stop
                                :audio-caption="event.event_data.audio_caption"
                                style="font-size: 25px; vertical-align: top; margin-top: 3px; display: inline-block;"
                                fa-class="fal"
                        ></audio-toggle>
                    </div>
                    <div class="inline-block vertical-top" :style="iconsBlockStyle" style="margin-top: 8px">
                        <div class="inline-block c-event__counter-icon" v-if="event.comments_count>0">
                            <div class="c-event__counter-icon__icon">
                                <i class="fal fa-comment vertical-top"></i>
                            </div>
                            <div class="c-event__counter-icon__text">
                                {{event.comments_count}}
                            </div>
                        </div>
                        <div class="inline-block c-event__counter-icon" v-if="event.going_count>0">
                            <div class="c-event__counter-icon__icon">
                                <i class="fal fa-user-check vertical-top" style="position: relative; left: 5px;"></i>
                            </div>
                            <div class="c-event__counter-icon__text">
                                {{event.going_count}}
                            </div>
                        </div>
                        <div class="inline-block c-event__counter-icon" v-if="event.linked_goals_count>0">
                            <div class="c-event__counter-icon__icon">
                                <i class="fal fa-bullseye-pointer vertical-top"></i>
                            </div>
                            <div class="c-event__counter-icon__text">
                                {{event.linked_goals_count}}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="">
                <div class="c-event__time-label" :style="timeLabelStyle">
                    {{ timeLabel}}
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import * as config from './config';
    import Vue from 'vue';
    import {EVENTS_BUS_EVENTS, eventsBus} from './eventsBus';
    import moment from 'moment';
    import {getEventUrl, timeToAmPm} from "./common";

    export default {
        props: {
            event: Object,
            tier: Number,
            index: Number,
            date: String,
            profile: Object,
        },
        data() {
            return {
                expanded: false,
                focused: false,
            };
        },
        computed: {
            iconsBlockStyle() {
                let style = {};
                if (this.event.event_data.audio_caption) {
                    style = {'margin-left': '20px'};
                } else {
                    style = {'margin-left': '35px'};
                }
                return style;
            },
            dateMoment() {
                return moment(this.date, config.VISUAL_DATE_FORMAT);
            },
            eventLink() {
                const formattedDate = this.dateMoment.format(config.URL_DATE_FORMAT);
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                const calendarOwner = (this.profile && CurrentUser.id !== this.profile.id) ? this.profile : undefined;
                return getEventUrl(this.event, formattedDate, calendarOwner);
            },
            timeLabel() {
                if (this.event.event_data.whole_day || !this.event.event_data.start_time) {
                    return;
                }
                return `${timeToAmPm(this.event.event_data.start_time)}-${timeToAmPm(this.event.event_data.end_time)}`;
            },
            eventTooltip() {
                if (this.canBeExpanded && !this.expanded) {
                    return 'Click to expand.';
                }
            },
            timeLabelStyle() {
                if (this.expanded) {
                    return {
                        'font-weight': 'bold',
                        'font-size': '11px',
                    };
                }
            },
            eventClass() {
                return this.expanded ? 'expanded' : undefined;
            },
            canBeExpanded() {
                return this.event.event_data.end_time_in_minutes * config.SIZES.TIMELINE_SCALE - this.event.event_data.start_time_in_minutes * config.SIZES.TIMELINE_SCALE < (config.SIZES.TIMELINE_SIZE / 24) * 3;
            },
            realTop() {
                return this.tier * config.SIZES.TIER_HEIGHT + config.SIZES.TIMELINE_HEADER_HEIGHT + config.SIZES.TOP_OFFSET_FOR_LINES;
            },
            requiredWidth() {
                return this.event.event_data.end_time_in_minutes * config.SIZES.TIMELINE_SCALE - this.event.event_data.start_time_in_minutes * config.SIZES.TIMELINE_SCALE;
            },
            realWidth() {
                if (this.expanded) {
                    return (config.SIZES.TIMELINE_SIZE / 24) * 3;
                } else {
                    return this.requiredWidth - 1;
                }
            },
            realLeft() {
                if (this.expanded && this.startTimeInTwoLastHours) {
                    // subtract on difference between expanded and needed width
                    return this.requiredLeft - (this.realWidth - this.requiredWidth);
                }
                return this.requiredLeft;
            },
            requiredRight() {
                return config.SIZES.TIMELINE_SIZE - this.event.event_data.end_time_in_minutes * config.SIZES.TIMELINE_SCALE;
            },
            realRight() {
                if (this.expanded && !this.startTimeInTwoLastHours) {
                    return this.requiredRight - (this.realWidth - this.requiredWidth);
                }
                return this.requiredRight;
            },
            requiredLeft() {
                return this.event.event_data.start_time_in_minutes * config.SIZES.TIMELINE_SCALE;
            },
            startTimeInTwoLastHours() {
                return this.event.event_data.start_time_in_minutes * config.SIZES.TIMELINE_SCALE > (config.SIZES.TIMELINE_SIZE - (config.SIZES.TIMELINE_SIZE / 24) * 2);
            },
            eventStyle() {
                const style = {
                    width: `${this.realWidth}px`,
                    left: `${this.realLeft}px`,
                    top: `${this.realTop}px`,
                    height: `${config.SIZES.EVENT_HEIGHT}px`,
                    'background-color': config.COLORS.getColorForEvent(this.event)
                };

                if (this.startTimeInTwoLastHours) {
                    style['right'] = `${this.realRight}px`;
                    delete style['left'];
                }

                return style;
            },
            innerBlockStyle() {
                return {
                    height: `${config.SIZES.EVENT_HEIGHT}px`,
                    'white-space': 'nowrap'
                };
            },
            avatarStyle() {
                const style = {
                    'background-image': `url('${this.event.event_data.avatar_url}')`,
                    'height': `${config.SIZES.EVENT_HEIGHT - 40}px`,
                    'width': `${config.SIZES.EVENT_HEIGHT - 40}px`,
                    'border-radius': '50%',

                };
                if (this.event.event_data.uses_default_avatar) {
                    style['border-radius'] = '0';
                    style['background-size'] = '90%';
                    style['background-position'] = 'top';
                }

                return style;
            }
        },
        methods: {
            onClick() {
                if (this.canBeExpanded) {
                    if (this.expanded) {
                        this.collapse();
                    } else {
                        this.expand();
                    }
                }
                if (this.focused) {
                    this.unFocusEvent();
                } else {
                    this.focusEvent();
                }

            },
            onEventExpand(date, eventid) {
                if (date !== this.date) {
                    return;
                }
                if (eventid !== this.event.id) {
                    this.focused = false;
                    this.expanded = false;
                }
            },
            onEventFocus(date, eventid) {
                if (date !== this.date) {
                    return;
                }
                if (eventid !== this.event.id) {
                    this.focused = false;
                    this.expanded = false;
                }
            },
            collapse() {
                this.expanded = false;
            },
            expand() {
                this.expanded = true;
                eventsBus.$emit(EVENTS_BUS_EVENTS.EVENT_EXPANDED, this.date, this.event.id);
            },
            focusEvent() {
                this.focused = true;
                const eventCoords = {
                    real: {
                        top: this.realTop,
                        left: this.realLeft,
                        right: config.SIZES.TIMELINE_SIZE - this.realRight,
                        bottom: this.realTop + config.SIZES.EVENT_HEIGHT
                    },
                    required: {
                        top: this.realTop,
                        left: this.requiredLeft,
                        right: config.SIZES.TIMELINE_SIZE - this.requiredRight,
                        bottom: this.realTop + config.SIZES.EVENT_HEIGHT,
                    }
                };
                eventsBus.$emit(EVENTS_BUS_EVENTS.EVENT_FOCUSED, this.date, this.event.id, eventCoords);
            },
            unFocusEvent() {
                this.focused = false;
                eventsBus.$emit(EVENTS_BUS_EVENTS.EVENT_UNFOCUSED, this.date);
            },
        },
        mounted() {
            eventsBus.$on(EVENTS_BUS_EVENTS.EVENT_EXPANDED, this.onEventExpand);
            eventsBus.$on(EVENTS_BUS_EVENTS.EVENT_FOCUSED, this.onEventFocus);
        },
        beforeDestroy() {
            eventsBus.$off(EVENTS_BUS_EVENTS.EVENT_EXPANDED, this.onEventExpand);
            eventsBus.$off(EVENTS_BUS_EVENTS.EVENT_FOCUSED, this.onEventFocus);
        },
        name: "CalendarPageDayTimelineEvent"
    };
</script>

<style scoped lang="scss">
    @import "../../../app/styles/const";

    .c-event {
        padding: 3px;
        position: absolute;
        border-radius: 3px;
        cursor: pointer;
        transition: width ease 0.3s;
        overflow: hidden;

        &.expanded {
            /*<!--border: dotted 2px $tag-gray;-->*/
            z-index: 2;
            /*box-sizing: content-box;*/
        }

        &__counter-icon {
            height: 45px;
            vertical-align: middle;
            margin-right: 8px;

            &__icon {
                height: 25px;
                font-size: 25px;
                vertical-align: top;
            }

            &__text {
                height: 20px;
                text-align: center;
            }
        }

        &__avatar {
            vertical-align: middle;
            background-repeat: no-repeat;
            background-size: contain;
            margin: auto;
            background-position: center;
            position: relative;

            &__periodic-mark {
                position: absolute;
                top: 0;
                left: -1px;
                font-size: 16px;
                color: #0086ff;
                /*color: white;*/
            }
        }

        &__avatar-part {
            vertical-align: top;
            min-width: 45px;
            display: inline-block;
        }

        &__inner {
            min-width: 275px;
        }

        &__time-label {
            font-size: 10px;
        }


        &__info-part {
            height: 45px;
            vertical-align: top;
            display: inline-block;

            &__name-label {
                font-size: 16px;
                text-transform: capitalize;
                /*font-weight: bolder;*/

                a {
                    color: $black;
                }
            }

        }
    }
</style>