import angular from 'angular'
import LoginPageController from './LoginPageController';
import AuthResource from './auth_resource'
import ResetPasswordRequestController from './ResetPasswordController'
import passwordResetController from './passwordResetController'

angular.module('mmApp-reg', [])
    .controller('LoginPageController', LoginPageController)
    .controller('ResetPasswordController', ResetPasswordRequestController)
    .controller('passwordResetController', passwordResetController)
    .factory('AuthResource', AuthResource)
;

export default 'mmApp-reg';