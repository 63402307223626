<template>
    <div class="chat-style-comment-linked-entry">
        <span class="chat-style-comment-linked-entry__text"
              :class="{'chat-style-comment-linked-entry__text_white': moveAvatarToRight}">
            <strong>
                <simple-link :target="comment.userprofile"></simple-link>
            </strong> has linked an
            <strong v-if="!userActivityPost.accessible">Update</strong>
            <strong v-else>
                <a :href="userActivityPost.link">Update</a>
            </strong>:
            <br/>
        </span>
        <div>
            <br>
            <render-quote :limit-size="true" :quote-border-color="null">
                <render-activity-post
                        :activity-post="userActivityPost.data.activity_post"></render-activity-post>
            </render-quote>

        </div>
    </div>
</template>

<script>
    import RenderActivityPost from "../../activity/renderActivity/render_activity_post_directive/renderActivityPost";
    import RenderQuote from "shared/quote_directive/renderQuote";

    export default {
        name: "ChatStyleCommentLinkedUserActivityPost",
        components: {RenderQuote, RenderActivityPost},
        props: {
            userActivityPost: Object,
            comment: Object,
            moveAvatarToRight: {
                type: Boolean,
                default: false
            }
        }
    };
</script>

<style scoped lang="scss">
  .chat-style-comment-linked-entry {
    margin: 15px;
    &__text {

      font-size: 16px;

      &_white {
        color: #fff;
        &::v-deep {
          a {
            color: #fff !important;
          }
        }
      }
    }
  }
</style>