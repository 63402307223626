var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.header, size: "lg", role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.header)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "add-media-from-folder-popup" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "form",
              {
                staticClass: "form-inline",
                attrs: {
                  role: "form",
                  "aria-label": "Select media source panel.",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "form-group",
                    staticStyle: { "margin-bottom": "5px" },
                  },
                  [
                    _c("label", { attrs: { for: "folders_mode" } }, [
                      _vm._v("From:"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.folderMode,
                            expression: "folderMode",
                          },
                        ],
                        ref: "folderModeSelect",
                        staticClass: "form-control",
                        attrs: { role: "combobox", id: "folders_mode" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.folderMode = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.setActiveFolderMode,
                          ],
                        },
                      },
                      [
                        _c(
                          "option",
                          { domProps: { value: _vm.FOLDER_MODES.MY } },
                          [_vm._v("My Media")]
                        ),
                        _vm._v(" "),
                        _c(
                          "option",
                          { domProps: { value: _vm.FOLDER_MODES.SHARED } },
                          [_vm._v("Shared Media")]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.folderMode === _vm.FOLDER_MODES.SHARED
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group",
                        staticStyle: { "margin-bottom": "5px" },
                      },
                      [
                        _c("label", { attrs: { for: "active_user_pk" } }, [
                          _vm._v("  User:"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.activeSharerId,
                                expression: "activeSharerId",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { id: "active_user_pk" },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.activeSharerId = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                _vm.setActiveSharer,
                              ],
                            },
                          },
                          [
                            _c("option", { domProps: { value: undefined } }, [
                              _vm._v("All Users"),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.availableSharers, function (sharer) {
                              return _c(
                                "option",
                                { domProps: { value: sharer.id } },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm._f("cutName")(sharer.name, 26)
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "form-group",
                    staticStyle: { "margin-bottom": "5px" },
                  },
                  [
                    _c("label", { attrs: { for: "active_folder_pk" } }, [
                      _vm._v("  Folder:"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.activeFolderId,
                            expression: "activeFolderId",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { id: "active_folder_pk" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.activeFolderId = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.setActiveFolder,
                          ],
                        },
                      },
                      [
                        _c("option", { domProps: { value: undefined } }, [
                          _vm._v("All folders"),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.availableFolders, function (folder) {
                          return _c(
                            "option",
                            { domProps: { value: folder.id } },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm._f("cutName")(folder.name, 26)) +
                                  "\n                            "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("h4", [_vm._v("Click to items you want add")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "div",
              {
                ref: "itemsContainer",
                staticClass: "folder-items-container",
                style: _vm.itemsContainerStyle,
              },
              [
                _vm.holder
                  ? _c(
                      "span",
                      {
                        staticClass: "folder-items-container__holder",
                        style: _vm.holderStyle,
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.holder) +
                            "\n                    "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.items, function (folderItem) {
                  return _c(
                    "div",
                    {
                      key: folderItem.id,
                      staticClass: "folder-item__wrapper",
                      class: {
                        "folder-item__wrapper_selected":
                          _vm.isSelected(folderItem),
                      },
                      attrs: { tabindex: "0" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleItemSelect(folderItem)
                        },
                        keydown: [
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.toggleItemSelect(folderItem)
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            if (!$event.ctrlKey) return null
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.checkAndAddItem(folderItem)
                          },
                        ],
                      },
                    },
                    [
                      _vm.isSelected(folderItem)
                        ? _c(
                            "div",
                            {
                              staticClass: "folder-item__checked-mark",
                              style: _vm.checkMarkStyle,
                            },
                            [_c("i", { staticClass: "fa fa-check" })]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("folder-item", {
                        staticStyle: { "vertical-align": "top", margin: "0" },
                        attrs: {
                          tabindex: "-1",
                          attachment: folderItem,
                          size: _vm.folderItemSize,
                          "show-tooltip": false,
                        },
                      }),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _vm._l(_vm.fakeItems, function (fakeItem) {
                  return _c("div", {
                    key: fakeItem,
                    staticClass: "folder-item__fake-item",
                    style: _vm.fakeItemStyle,
                    attrs: { "aria-hidden": "true" },
                  })
                }),
              ],
              2
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-6 text-left" },
              [
                _vm.totalPage > 1
                  ? _c("pagination", {
                      attrs: { "total-page": _vm.totalPage, "max-size": 3 },
                      on: { change: _vm.onPageChanged },
                      model: {
                        value: _vm.currentPage,
                        callback: function ($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-sm-6 text-right add-media-from-folder-popup__right-buttons-panel",
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-mm btn-primary",
                    attrs: { type: "button", role: "button" },
                    on: { click: _vm.close },
                  },
                  [_vm._v("Cancel\n                    ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-mm btn-green",
                    attrs: {
                      disabled: !_vm.selectedItemsIdList.length,
                      type: "button",
                      role: "button",
                    },
                    on: { click: _vm.add },
                  },
                  [_vm._v("Add Media\n                    ")]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c(
                "span",
                {
                  staticClass: "focus-catch-link",
                  attrs: { tabindex: "0", "aria-hidden": "true" },
                  on: { focus: _vm.focusToClose },
                },
                [_vm._v(" ")]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }