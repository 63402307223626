export default {

    computed: {
        calendarOwner() {
            return this.$parent.calendarOwner;
        },
        buddyMode() {
            return this.$parent.buddyMode;
        },
        behalfMode() {
            return this.$parent.buddyMode;
        },
        event() {
            return this.$parent.event;
        },
        dateMoment() {
            return this.$parent.dateMoment;
        },
        periodInfo() {
            return this.$parent.periodInfo;
        },
        membershipStatus() {
            return this.$parent.membershipStatus;
        },
        calendarOwnerIsOwnEventsAdmin() {
            return this.$parent.calendarOwnerIsOwnEventsAdmin;
        },
        isAdmin() {
            return this.$parent.isAdmin;
        },
    }
};