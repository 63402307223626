var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "inline-block" }, [
    _c(
      "a",
      {
        staticStyle: { "text-decoration": "none" },
        attrs: { href: _vm.target.link },
      },
      [
        _c("img", {
          staticClass: "img-circle vertical-middle",
          attrs: {
            src: _vm.target.avatar,
            width: "40",
            height: "40",
            alt: "Avatar",
          },
        }),
        _vm._v(" "),
        _c("strong", { staticClass: "vertical-middle" }, [
          _vm._v(_vm._s(_vm.target.name)),
        ]),
      ]
    ),
    _vm.postfixLabel
      ? _c("span", { staticClass: "vertical-middle" }, [
          _vm._v(_vm._s(_vm.postfixLabel)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }