var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      staticClass: "edd-emotion-popup",
      attrs: { "aria-label": _vm.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          ref: "closeButton",
          attrs: {
            id: "close_popup",
            role: "button",
            "aria-label": "Close dialog",
            tabindex: "0",
          },
          on: {
            click: _vm.close,
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.close.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                )
                  return null
                if (!$event.shiftKey) return null
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.focusToClose.apply(null, arguments)
              },
            ],
          },
        }),
        _vm._v(" "),
        _c(
          "h3",
          {
            staticClass: "modal-title select-sticker-popup__modal-title",
            attrs: { id: "modal-header", tabindex: "-1" },
            on: {
              click: _vm.setFocusOnFirstSticker,
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.setFocusOnFirstSticker.apply(null, arguments)
              },
            },
          },
          [_vm._v(_vm._s(_vm.header))]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "select-sticker-popup" },
        [
          _c(
            "tabs",
            {
              attrs: { justified: true, "area-label": "Select sticker to add" },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            _vm._l(_vm.visibleStickerGroups, function (stickerGroup, i) {
              return _c("tab", { key: i, attrs: { index: i } }, [
                _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                  _c("img", {
                    staticClass: "select-sticker-popup__tab-header-image",
                    attrs: {
                      src: stickerGroup.title_sticker_href,
                      "aria-label": stickerGroup.name,
                      role: "tab",
                      alt: stickerGroup.name,
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: "stickersContainer",
                    refInFor: true,
                    staticClass: "select-sticker-popup__stickers-container",
                    attrs: {
                      "aria-label": "Select Sticker to add.",
                      "aria-live": "polite",
                    },
                  },
                  [
                    _vm.activeTab ===
                    _vm.visibleStickerGroups.indexOf(stickerGroup)
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "select-sticker-popup__stickers-inner-container",
                          },
                          _vm._l(
                            _vm.getVisibleStickers(stickerGroup),
                            function (sticker) {
                              return _c(
                                "div",
                                {
                                  ref: "sticker",
                                  refInFor: true,
                                  staticClass:
                                    "select-sticker-popup__sticker-wrapper",
                                  class: {
                                    "select-sticker-popup__sticker-wrapper_active":
                                      _vm.selectedSticker === sticker,
                                  },
                                  attrs: {
                                    tabindex: "0",
                                    "aria-label": sticker.file_name,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setSelectedSticker(sticker)
                                    },
                                    keydown: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.setSelectedSticker(sticker)
                                    },
                                  },
                                },
                                [
                                  _c("render-sticker", {
                                    attrs: {
                                      "aria-hidden": "true",
                                      responsive: true,
                                      sticker: sticker,
                                    },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]
                ),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "button",
              {
                ref: "selectButton",
                staticClass: "btn btn-mm",
                attrs: { disabled: !_vm.selectedSticker, role: "button" },
                on: { click: _vm.select },
              },
              [_vm._v("Select\n                ")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "span",
              {
                staticClass: "focus-catch-link",
                attrs: { tabindex: "0", "aria-hidden": "true" },
                on: { focus: _vm.focusToClose },
              },
              [_vm._v(" ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }