var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "event-linked-entry-preview" }, [
    _c(
      "a",
      {
        staticStyle: { "text-decoration": "none" },
        attrs: { href: _vm._f("backHref")(_vm.entry.link) },
      },
      [
        _c(
          "div",
          {
            staticClass: "event-linked-entry-preview__avatar",
            style: _vm.entryPreview.avatarStyle,
          },
          [
            _vm.entryPreview.sticker
              ? _c("render-sticker", {
                  staticStyle: {
                    width: "45px",
                    height: "45px",
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                  },
                  attrs: { sticker: _vm.entryPreview.sticker },
                })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "event-linked-entry-preview__text" }, [
      _vm.entryPreview.text || _vm.entry.emotion
        ? _c("div", {}, [
            _c("i", { staticClass: "fal fa-quote-left" }),
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm._f("cutName")(
                    _vm.entryPreview.text || _vm.entry.emotion,
                    180
                  )
                ) +
                "\n            "
            ),
            _c("i", { staticClass: "fal fa-quote-right" }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "event-linked-entry-preview__text__author" }, [
        _vm._v("\n            " + _vm._s(_vm.formattedDate) + " by "),
        _c(
          "strong",
          [
            _c("simple-link", {
              staticStyle: { color: "#333" },
              attrs: { target: _vm.entryPreview.author },
            }),
          ],
          1
        ),
        _vm._v(".\n\n            "),
        _c("strong", [
          _c(
            "a",
            { attrs: { href: _vm._f("backHrefQuery")(_vm.entry.link) } },
            [_vm._v("Open Details")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }