<template>
    <div class="main-container" :style="[containerSizeOffsetStyle, containerDragStyle]"
         @dragenter.prevent="onDragEnter"
         @dragover.prevent=""
         @dragstart="onDragStart"
         @dragleave="onDragLeave"
         @dragend="onDragEnd"
         @drop.prevent="onDrop"
    >

        <div href="javascript:;"
             v-if="section"
             class="main-container-image border-group pointer"
             :style="imageStyle"
             :class="{'main-container-image_active': isActive}"
             :aria-label="section.caption"
             ref="sectionAvatar"
             @click="openSection"
             @keydown.enter.stop.prevent="openSection"
             @focus="activateSection"
             tabindex="0"
        >
            <i class="fa fa-image main-container-image__empty-icon"
               v-if="!avatarUrl&&!section.media_content_samples.length"></i>
            <div v-if="editMode&&section.is_editable||section.audio_caption"
                 @click.stop.prevent="editOrAudioClick"
                 class="main-container-image__delete_button"
                 :style="deleteMarkStyle">
                <audio-toggle
                        ref="sectionAudioToggle"
                        v-if="!editMode"
                        :audio-caption="section.audio_caption"
                        fa-class="far"></audio-toggle>
                <i class="far fa-pencil pointer" v-if="editMode&&section.is_editable"
                ></i>
            </div>
            <div
                    class="main-container-image__unread-mark"
                    :style="changedMarkStyle"
                    v-if="showNewMark||showChangedMark||showBothMarks"
                    :class="{
                        'main-container-image__unread-mark_changed': showChangedMark,
                        'main-container-image__unread-mark_new': showNewMark,
                        'main-container-image__unread-mark_both': showBothMarks,
                    }"
            ><span v-if="showChangedMark">UPD</span><span v-if="showNewMark">NEW</span></div>
        </div>
        <div class="main-container__text" :style="[textStyle]">
            <span class="pointer main-container__text-caption" @click="openSection">
                {{ section.caption }}
                <span class="main-container__text-caption-counter" v-if="showCounters&&section.visible_content_count">
                    <br>
                    ({{ section.visible_content_count }})
                </span>
            </span>
        </div>
        <div class="align-helper"></div>

    </div>
</template>

<script>
    import _ from 'lodash';
    import WikiSectionMixin from './WikiSectionMixin';
    import DraggableMethodsMixin from "./DraggableMethodsMixin";
    import mediaContentUtils from "../../../../angular/app/view_media/mediaContentUtils";

    const vm = {
        props: {
            section: {
                type: Object
            },
            editMode: {
                type: Boolean,
                default: false
            }
        },
        mixins: [WikiSectionMixin, DraggableMethodsMixin],
        name: "wiki-section",
        data() {
            return {};
        },
        methods: {
            del() {
                this.$emit('onDelete');
            },
            onEdit() {
                this.$emit('onEdit');
            },
            openSection() {
                this.$emit('onOpen');
            },
            activateSection() {
                this.$emit('activateSection');
            },
        },
        computed: {
            $_captionFontSize() {
                if (this.width > 235) {
                    return 17;
                }
                if (this.width > 200) {
                    return 15;
                }
                if (this.width > 170) {
                    return 13;
                }
                return 10;
            },
            _textSectionWidth() {
                if (this.isTextOnSide) {
                    return Math.min(this.width - this.$_circleSize - 8, this.$_circleSize * 2);
                } else {
                    return this.width;
                }
            },
            containerDragStyle() {
                let resultStyle = {};

                if (this.dragOn && !this.isDragged) {
                    // resultStyle['background-color'] = 'green';
                }
                return resultStyle;
            },
            imageStyle() {
                let resultStyle = {
                    width: `${this.$_circleSize}px`,
                    height: `${this.$_circleSize}px`,
                    position: 'relative'
                };

                if (this.isTextOnSide || this.side === 'center') {
                    if (this.side === 'left') {
                        resultStyle['float'] = 'right';
                    }
                    if (this.side === 'right') {
                        resultStyle['float'] = 'left';
                    }
                    resultStyle['margin-top'] = `${(this.height - this.$_circleSize) / 2}px`;
                }
                if (this.avatarUrl || this.section.media_content_samples.length) {
                    _.merge(resultStyle, new mediaContentUtils().generate_group_background(this.avatarUrl, this.section.media_content_samples));
                    // resultStyle['background-image'] = `url('${this.avatarUrl}')`;
                    // resultStyle['background-repeat'] = 'no-repeat';
                    // resultStyle['background-size'] = 'cover';
                } else {
                    resultStyle['background-color'] = '#f2f4f9';
                    resultStyle['line-height'] = `${this.$_circleSize * 0.9}px`;
                    resultStyle['font-size'] = `${this.$_circleSize * 0.6}px`;
                }
                if (this.dragOn && !this.isDragged) {
                    resultStyle['box-shadow'] = '#64BD63 0 0 20px';
                    resultStyle['border-color'] = '#64BD63';
                }

                return resultStyle;
            },
            textStyle() {
                let resStyle = {
                    'font-size': `${this.$_captionFontSize}px`
                };
                if (this.isTextOnSide) {
                    resStyle['width'] = `${this._textSectionWidth}px`;
                    resStyle['display'] = `inline-block`;
                    resStyle['text-align'] = this.side === 'right' ? 'left' : 'right';
                }
                return resStyle;
            },
            deleteMarkStyle() {
                const divider = this.$_circleSize > 110 ? 3 : 1.8;
                const smallCircleRadius = this.$_circleSize / divider;
                const r = this.$_circleSize / 2;
                const correction = r / Math.sqrt(2);
                const zeroPoint = this.$_circleSize / 2 - smallCircleRadius / 2;
                return {
                    position: 'absolute',
                    width: `${smallCircleRadius}px`,
                    height: `${smallCircleRadius}px`,
                    'line-height': `${smallCircleRadius}px`,
                    'font-size': `${smallCircleRadius / 1.6}px`,
                    left: `${zeroPoint + correction}px`,
                    top: `${zeroPoint - correction}px`,
                };
            },
            changedMarkStyle() {
                const divider = this.$_circleSize > 110 ? 3 : 1.8;
                const smallCircleRadius = this.$_circleSize / divider;
                const r = this.$_circleSize / 2;
                const correction = r / Math.sqrt(2);
                const zeroPoint = this.$_circleSize / 2 - smallCircleRadius / 2;
                return {
                    position: 'absolute',
                    width: `${smallCircleRadius}px`,
                    height: `${smallCircleRadius}px`,
                    'line-height': `${smallCircleRadius}px`,
                    'font-size': `${smallCircleRadius / 3}px`,
                    left: `${zeroPoint - correction}px`,
                    top: `${zeroPoint - correction}px`,
                };
            },
            avatarUrl() {
                if (!this.section.avatar || !this.section.avatar.image) {
                    return undefined;
                }
                return this.section.avatar.image.thumbnail_url;
            },

        },
        mounted() {
        }
    };


    export default vm;
</script>

<style scoped lang="scss">
  @import "../../styles/const.scss";

  .main-container {
    &__text {
      font-weight: bolder;
      font-size: 16px;
      vertical-align: middle;

      .fa {
        font-size: 18px !important;
      }
    }

    &__text-caption {
      position: relative;
      text-align: center;
      display: inline-block;
    }

    &__text-caption-counter {
      color: $tag-gray;
    }
  }

  .main-container-image {
    display: inline-block;
    border-radius: 50%;
    text-align: center;

    &_active {
      box-shadow: 0 0 2px 2px rgba(11, 149, 255, 1);
    }

    &__empty-icon {
    }

    &.drag-enter {
      box-shadow: $grass-green 0 0 20px;
      border: solid 1px $grass-green;
    }

    &__delete_button {
      cursor: pointer;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.85);
      color: $black;
      text-align: center;
    }

    &__unread-mark {
      cursor: pointer;
      border-radius: 50%;
      text-align: center;
      border: solid 3px #fff;
      opacity: 0.85;
      color: #fff;

      display: flex;
      align-items: center;
      justify-content: center;

      &_changed {
        background-color: $blue;
      }

      &_new {
        background-color: $online-green;
      }

      &_both {
        background: $online-green;
        background: linear-gradient(90deg, $online-green 50%, $blue 50%);
      }
    }

  }
</style>