<template>
    <div class="">
        <span v-if="!allUsersSelected">
            <span v-if="usersList.length">
                Entry will be added to the following Diaries: <br>
            </span>
            <span v-for="(user, i) in usersList">
                <strong><simple-link :target="user"></simple-link></strong><span v-show="i!==(usersList.length-1)">, </span>
            </span>
        </span>
        <span v-else>
             Entry will be added to all Supported Users Diaries.
        </span>
    </div>
</template>

<script>
    export default {
        name: "CreateBlogEntryPopupUsersList",
        props: {
            allUsersSelected: {
                type: Boolean,
                default: false
            },
            usersList: {
                type: Array,
            }
        }
    };
</script>

<style scoped>

</style>