var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "community-page-menu" }, [
    _c(
      "div",
      { staticClass: "community-page-menu__avatar_and_logo" },
      [
        _c(
          "router-link",
          {
            staticStyle: { color: "#fff", "text-decoration": "none" },
            attrs: { to: { name: "community-info" } },
          },
          [
            _c("div", {
              staticClass:
                "community-page-menu__avatar_and_logo__avatar pointer",
              style: _vm.avatarStyle,
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "community-page-menu__avatar_and_logo__name-with-subscription",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "community-page-menu__avatar_and_logo__name-with-subscription__name",
                class: { unauth: !_vm.isAuthenticated },
              },
              [
                _c(
                  "router-link",
                  {
                    staticStyle: { color: "#fff", "text-decoration": "none" },
                    attrs: { to: { name: "community-info" } },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm._f("cutName")(_vm.name, 30)) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.showSettings
                  ? _c(
                      "router-link",
                      {
                        class: { unauth: !_vm.isAuthenticated },
                        staticStyle: { color: "#fff" },
                        attrs: { to: { name: "community-settings" } },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-gear hidden-sm hidden-xs",
                        }),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "community-page-menu__avatar_and_logo__name-with-subscription__subscription pointer",
                class: { unauth: !_vm.isAuthenticated },
              },
              [
                _vm.subscriptionState === true
                  ? _c(
                      "span",
                      { on: { click: _vm.showUnSubscribeConfirmation } },
                      [
                        _c("i", { staticClass: "fa fa-check" }),
                        _vm._v(" You are subscribed for Community updates"),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.subscriptionState === false
                  ? _c(
                      "span",
                      { on: { click: _vm.showSubscribeConfirmation } },
                      [
                        _c("i", { staticClass: "fa fa-times" }),
                        _vm._v(" You are not subscribed for Community updates"),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.subscriptionState === undefined
                  ? _c("span", [
                      _c("i", { staticClass: "fa fa-spinner fa-spin" }),
                      _vm._v(" Getting your subscription state"),
                    ])
                  : _vm._e(),
              ]
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "community-page-menu__icons" },
      _vm._l(_vm.icons, function (icon) {
        return !icon.mobileOnly
          ? _c(
              "div",
              {
                key: icon.classes,
                staticClass: "community-page-menu__icons__icon-container",
                class: { active: icon.to && _vm.$route.name === icon.to.name },
                on: {
                  click: function ($event) {
                    return _vm.onIconClick(icon)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "community-page-menu__icons__icon-container__icon",
                  },
                  [_c("i", { class: icon.classes })]
                ),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(icon.label))]),
              ]
            )
          : _vm._e()
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "community-page-menu__toggle-button",
        class: { pushed: _vm.isCollapsed },
        on: {
          click: function ($event) {
            _vm.isCollapsed = !_vm.isCollapsed
          },
        },
      },
      [_c("i", { staticClass: "fa fa-bars" })]
    ),
    _vm._v(" "),
    _vm.isCollapsed
      ? _c(
          "div",
          { staticClass: "community-page-menu__mobile-menu" },
          _vm._l(_vm.icons, function (icon) {
            return _c(
              "div",
              {
                key: icon.label,
                staticClass: "community-page-menu__mobile-menu__item",
                on: {
                  click: function ($event) {
                    return _vm.onIconClick(icon)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "community-page-menu__mobile-menu__item__icon",
                  },
                  [_c("i", { class: icon.classes })]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "community-page-menu__mobile-menu__item__label",
                  },
                  [_vm._v(_vm._s(icon.label))]
                ),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }