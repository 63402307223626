<template>
    <div class="">
        <vue-simple-header>
            <span slot="headerTitle">Manage User Access</span>
            <span slot="panelRight">
                                    <vue-folder-button @click.native="createAccessLink"
                                                       text="Create access link"
                                                       icon-class="fa-link"></vue-folder-button>
                                <vue-folder-button @click.native="inviteByEmail" text="Invite by Email"
                                                   icon-class="fa-envelope-o"></vue-folder-button>
                                    <vue-folder-button @click.native="inviteUsers" text="Invite Users"
                                                       icon-class="fa-user-plus"></vue-folder-button>

                                    <vue-folder-button color="blue" text="Back" @click.native="goToMedia"
                                                       icon-class="fa-reply"></vue-folder-button>



                        </span>
        </vue-simple-header>
        <div class="alert alert-warning">
            <strong>Important:</strong> through sharing any part of this Media Folders with a user or group of users you
            are
            giving them consent to view shared material and download any attachments. Users are not permitted to
            share this on with other users, or download or use any of the material for any other purposes.
        </div>
        <members-table
                :members="members"
                :owner="currentUser"
        ></members-table>
        <!--<wiki-members-list-table-->
        <!--:members="members"-->
        <!--:sections="sections"-->
        <!--:owner="owner"-->
        <!--:permissionsList="permissionsList"-->
        <!--@openTreeOnMember="openTreeOnMember"-->
        <!--@toggleMemberAdminStatus="toggleMemberAdminStatus"-->
        <!--@removeMember="removeMember"-->
        <!--&gt;</wiki-members-list-table>-->
        <invitation-type-select-popup ref="invitation-type-select-popup"></invitation-type-select-popup>
        <email-role-category-popup ref="email-role-category-popup"></email-role-category-popup>
    </div>
</template>

<script>
    import MEDIA_CONFIG from '../config.js';
    import Vue from 'vue';
    import MembersTable from './MembersTable.vue';
    import {CurrentUserClass} from "shared/CurrentUser";

    export default {
        name: "MediaManageAccessPage",
        components: {MembersTable},
        props: {
            userName: String,
            userId: String
        },
        data() {
            return {
                currentUser: undefined,
                members: []
            };
        },
        computed: {
            mediaPageLink() {
                return `${MEDIA_CONFIG.MAIN_MEDIA_PREFIX}${this.currentUser.name}/`;
            }
        },
        methods: {
            goToMedia() {
                this.$router.replace(this.mediaPageLink);
            },
            inviteUsers() {
                let $rootScope = Vue.getAngularModule('$rootScope');
                let selectFactory = Vue.getAngularModule('selectFactory');
                let MediaMembershipResource = Vue.getAngularModule('MediaMembershipResource');
                let simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                let $q = Vue.getAngularModule('$q');

                selectFactory
                    .endpoint_selector(_.partial(MediaMembershipResource.get_users_to_invite, this.currentUser.id), true, {
                        header: 'Invite Users',
                        info: 'Select or de-select multi me Users from this list to invite to join the Wiki',
                        button_yes: 'Add',
                        button_no: 'Cancel',
                        empty: 'You do not have any users to invite'
                    })
                    .then(res => {
                        let new_checked_ids = res[0];
                        $rootScope.show_dimmer();
                        return MediaMembershipResource.invite_users(this.currentUser.id, {
                            id_list: new_checked_ids
                        });
                    }, () => new Promise(()=>{}))
                    .then(resp => {
                        for (let new_member of resp.data) {
                            this.members.push(new_member);
                        }
                        simplePopupFactory.show_popup('Invite successfully sent.',
                            "Invite successfully sent. Please now define this users view or edit permissions in the 'Edit Access' column below.", 'OK');
                    }, err => Vue.notifications.error(err || 'Error'))
                    .finally($rootScope.hide_dimmer);
            },
            createAccessLink() {
                let $rootScope = Vue.getAngularModule('$rootScope');
                let simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                let MediaMembershipResource = Vue.getAngularModule('MediaMembershipResource');
                simplePopupFactory.simple_text_input_popup('Create access link', 'Name of Access Link', 'Create', 'Cancel', '', 255)
                    .then(name => {
                        $rootScope.show_dimmer();
                        return MediaMembershipResource.create_access_link(this.currentUser.id, name);
                    })
                    .then(resp => {
                        this.members.push(resp.data);
                        simplePopupFactory.show_popup('Access Link successfully created.',
                            "Access Link successfully created. Please now define this Link's view in the 'Edit Access' column below.", 'OK');
                    }, err => Vue.notifications.error(err || 'Error'))
                    .finally($rootScope.hide_dimmer);
            },
            showSendAccessLinkPopup() {
                let $rootScope = Vue.getAngularModule('$rootScope');
                let $q = Vue.getAngularModule('$q');
                let simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                let MediaMembershipResource = Vue.getAngularModule('MediaMembershipResource');
                let notifications = Vue.getAngularModule('notifications');
                this.$refs['email-role-category-popup'].show({
                    info: `<i>Invite somebody by email to join this Media.</i>`,
                    header: 'Add User with an email link'
                }, ['email', 'role'])
                    .then(({role, email}) => {
                        $rootScope.show_dimmer();
                        return MediaMembershipResource.send_access_link(this.currentUser.id, {role, email});
                    }, close => {
                        return $q.defer().promise;
                    })
                    .then(resp => {
                            this.members.push(resp.data);
                            simplePopupFactory.show_popup('Access Link successfully sent.',
                                "Access Link successfully sent. Please now define this Link's view in the 'Edit Access' column below.", 'OK');
                        },
                        err => {
                            notifications.error(err || 'Error');
                        })
                    .finally($rootScope.hide_dimmer);
            },
            showCircleInviteEmailPopup() { //
                let $rootScope = Vue.getAngularModule('$rootScope');
                let $q = Vue.getAngularModule('$q');
                let simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                let MediaMembershipResource = Vue.getAngularModule('MediaMembershipResource');
                let notifications = Vue.getAngularModule('notifications');
                this.$refs['email-role-category-popup'].show({
                    info: `<i>Invite somebody by email to join ${this.currentUser.name}'s Circle and this Media.</i>`,
                    header: 'Invite to Register'
                }, ['email', 'role', 'category'])
                    .then(({role, email, category}) => {
                        $rootScope.show_dimmer();
                        return MediaMembershipResource.send_circle_invitation(this.currentUser.id, {
                            role,
                            email,
                            category
                        });
                    }, close => {
                        return $q.defer().promise;
                    })
                    .then(resp => {
                            this.members.push(resp.data);
                            simplePopupFactory.show_popup('Invite successfully sent.',
                                "Invite successfully sent. Please now define this users view or edit permissions in the 'Edit Access' column below.", 'OK');
                        },
                        err => {
                            notifications.error(err || 'Error');
                        })
                    .finally($rootScope.hide_dimmer);
            },
            inviteByEmail() {
                let CurrentUser = Vue.getAngularModule('CurrentUser');
                if (CurrentUser.is_guardian_of(this.currentUser.id)) {
                    const text = `
                    <i>If you choose <strong>Invite to Register</strong> invitation to register on MultiMe will be sent.
                     The user will be able to have full access to this Media if given.</i><br/>
                     <i>If you choose <strong>Send Access Link</strong> (no registration required) The person will be able to only view the Media.</i>
                    `;
                    this.$refs['invitation-type-select-popup'].show(text)
                        .then(res => { // res = 'access-link' || 'register'
                            if (res === 'access-link') {
                                this.showSendAccessLinkPopup();
                            }
                            if (res === 'register') {
                                this.showCircleInviteEmailPopup();
                            }
                        }, close => {
                        });
                } else {
                    this.showSendAccessLinkPopup();
                }

            }
        },
        created() {
            let $rootScope = Vue.getAngularModule('$rootScope');
            let MediaMembershipResource = Vue.getAngularModule('MediaMembershipResource');
            this.currentUser = {id: parseInt(this.userId), name: this.userName};
            $rootScope.show_dimmer();
            MediaMembershipResource.get_members(this.currentUser.id)
                .then(resp => {
                    this.members = resp.data;
                }, err => {
                    Vue.notifications.error(err || 'Error');
                })
                .finally($rootScope.hide_dimmer);
        },
        metaInfo() {
            let title = 'Manage Media';
            if (this.currentUser.id === new CurrentUserClass().id) {
                title = 'Manage My Media';
            } else {
                if (this.currentUser) {
                    title = `Manage Media - ${this.currentUser.name}`;
                }
            }
            return {
                title,
            };
        }
    };
</script>

<style scoped>

</style>
