/**
 * Created by orion on 07.03.17.
 */

AddFromMyMedia.$inject = ['$http'];
import urls from 'DjangoUrls';

let {DjangoUrls} = urls;

const PREFIX = 'api.v1:add_from_my_media-';

function AddFromMyMedia($http) {
    return {
        get_main_shared_folders,
        get_my_folders,
        get_sharers,
        get_my_all_media,
        get_folder_media,
        get_shared_with_me_all_media,
        get_shared_with_me_sharers_media,
        get_sharers_folders,
    };

    function get_main_shared_folders() {
        const url = DjangoUrls[`${PREFIX}get-main-shared-folders`]();
        return $http({
            method: 'GET',
            url: url,
            data: {}
        });
    }

    function get_my_folders() {
        const url = DjangoUrls[`${PREFIX}get-my-folders`]();
        return $http({
            method: 'GET',
            url: url,
            data: {},
        });
    }

    function get_sharers_folders(sharerId) {
        const url = DjangoUrls[`${PREFIX}get-sharers-folders`](sharerId);
        return $http({
            method: 'GET',
            url: url,
            data: {},
        });
    }

    function get_sharers() {
        const url = DjangoUrls[`${PREFIX}get-sharers`]();
        return $http({
            method: 'GET',
            url: url,
            data: {},
        });
    }

    function get_my_all_media(filter, limit, offset) {
        const url = DjangoUrls[`${PREFIX}get-my-all-media`]();
        return $http({
            method: 'GET',
            url: url,
            params: {'type': filter, limit, offset}
        });
    }

    function get_shared_with_me_all_media(filter, limit, offset) {
        const url = DjangoUrls[`${PREFIX}get-shared-with-me-all-media`]();
        return $http({
            method: 'GET',
            url: url,
            params: {'type': filter, limit, offset}
        });
    }

    function get_shared_with_me_sharers_media(sharerId, filter, limit, offset) {
        const url = DjangoUrls[`${PREFIX}get-shared-with-me-sharers-media`](sharerId);
        return $http({
            method: 'GET',
            url: url,
            params: {'type': filter, limit, offset}
        });
    }

    function get_folder_media(folder_id, filter, limit, offset) {
        /**
         * @param folder_id {int} pk of folder(my or shared) to load items from
         * @param filter {string} in ['audio', 'image', 'document', 'video', 'folder']
         * @param query {string} search in filtered results with name __icontains
         * @type {{folder_id: int, filter: string, search_query:string,limit: int,offset:int }}
         */
        let data = {id: folder_id};
        if (limit) {
            data.limit = limit;
        }
        if (offset) {
            data.offset = offset;
        }
        if (filter) {
            data['type'] = filter;
        }
        const url = DjangoUrls[`${PREFIX}get-folder-media`]();
        return $http({
            method: 'GET',
            url: url,
            params: data
        });

    }
}

export default AddFromMyMedia;