import Vue from 'vue';
import CommunityPage from './CommunityPage.vue';
import VueRouter from "vue-router";
import CommunityPageGroups from './CommunityPageGroups.vue';
import CommunityPageEvents from './CommunityPageEvents.vue';
import CommunityPageEventsUnauthView from './CommunityPageEventsUnauthView.vue';
import CommunityPageAboutUs from './CommunityPageAboutUs.vue';
import CommunityPageStories from './CommunityPageStories.vue';
import CommunityPageStoriesStory from './CommunityPageStoriesStory.vue';
import CommunityPageSettings from './CommunityPageSettings.vue';
import CommunityPageManageWikiAccess from './CommunityPageManageWikiAccess.vue';
import CommunityPageWiki from './CommunityPageWiki.vue';
import store from '../store';
import communityPageStoreModule from './store';
import EventDetailPage from "../events/new_event/EventDetailPage";
import EVENT_DETAIL_PAGE_CHILDREN from "../events/new_event/eventDetailPageChildren";
import WikiStoreModule from "../wiki_page/store";
import {getMainWikiPageName, getWikiChildren} from "../wiki_page/WikiChildrenPages";
import {COMMUNITY_WIKI_ROUTER_PREFIX} from "./config";

const MAIN_PREFIX = '/community/';

CommunityPageController.$inject = ['$scope', '$rootScope', '$preloaded', 'CurrentUser'];

function CommunityPageController($scope, $rootScope, $preloaded, CurrentUser) {

    const getEventRoute = () => {
        if (CurrentUser.is_authenticated()) {
            return {
                name: 'community-events-event',
                path: 'events/:eventId(\\d+)/:date(\\d{4}-\\d{2}-\\d{2})?/:calendarOwnerId(\\d+)?/:calendarOwnerName?/',
                component: EventDetailPage,
                props: true,
                children: EVENT_DETAIL_PAGE_CHILDREN,
                meta: {name: 'events-event'}
            };
        } else {
            return {
                name: 'community-events-event-unauth',
                path: 'events/:eventId(\\d+)/:date(\\d{4}-\\d{2}-\\d{2})?/(info)?',
                component: CommunityPageEventsUnauthView,
                props: true,
                meta: {name: 'events-event-unauth'}
            };
        }
    };

    const COMMUNITY_PAGE_CHILDREN = [
        {
            name: 'community-info',
            path: '',
            component: CommunityPageAboutUs,
            props: true, meta: {name: 'info'}
        },
        {
            name: 'community-stories',
            path: 'stories',
            component: CommunityPageStories,
            props: true,
            meta: {name: 'stories'}
        },
        {
            name: 'community-stories-story',
            path: 'stories/:storySlug',
            component: CommunityPageStoriesStory,
            props: true,
            meta: {name: 'stories-story'}
        },
        {name: 'community-events', path: 'events', component: CommunityPageEvents, props: true, meta: {name: 'events'}},
        getEventRoute(),
        {name: 'community-groups', path: 'groups', component: CommunityPageGroups, props: true, meta: {name: 'groups'}},
        {
            name: 'community-settings',
            path: 'settings',
            component: CommunityPageSettings,
            props: true,
            meta: {name: 'settings'}
        },
        {
            name: 'community-wiki',
            path: 'wiki',
            component: CommunityPageWiki,
            props: true,
            meta: {name: 'wiki'},
            children: getWikiChildren(COMMUNITY_WIKI_ROUTER_PREFIX)
        },
        {
            name: 'community-wiki-access-page',
            path: 'wiki-access-page',
            component: CommunityPageManageWikiAccess,
            props: true,
            meta: {name: 'wiki-access-page'}
        },
    ];

    function initVue() {
        store.registerModule('communityPage', communityPageStoreModule);
        store.registerModule('WikiStore', WikiStoreModule);
        store.commit('communityPage/setCommunity', $preloaded.community);
        store.commit('communityPage/setViewerState', {isAdmin: $preloaded.is_admin, isMember: $preloaded.is_member});
        store.commit('communityPage/setAboutPage', $preloaded.uses_community_wiki);
        store.commit('communityPage/setCommunityWikiId', $preloaded.wiki_id);
        store.commit('communityPage/setCommunityWikiSubsectionsDisabled', $preloaded.wiki_subsections_disabled);
        store.dispatch('communityPage/getSubscriptionState');

        const router = new VueRouter({
            mode: 'history',
            routes: [
                {
                    path: MAIN_PREFIX + ':communitySlug/',
                    component: CommunityPage,
                    props: true,
                    children: COMMUNITY_PAGE_CHILDREN
                },
            ]
        });

        const vm = new Vue({
            template: `<router-view></router-view>`,
            el: '#CommunityPageController',
            router: router,
            store,
        });

        $scope.$on('$destroy', () => {
            if (vm) {
                vm.$destroy();
            }
        });
    }

    initVue();

    if (!CurrentUser.is_authenticated()) {
        setTimeout(() => {
            $rootScope.$broadcast('enableFooterLoginMode');
        }, 1);
    }
}

export default CommunityPageController;