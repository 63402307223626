import Vue from 'vue';
import SiteFooter from './SiteFooter.vue';

siteFooterController.$inject = ['$scope'];

function siteFooterController($scope) {
    const vm = new Vue({
        el: '#site-footer-component',
        name: `siteFooterController`,
        render(createElement) {
            const {props: attrs} = this;
            return createElement(SiteFooter, {attrs});
        }
    });

    $scope.$on('$destroy', () => {
        if (vm) {
            vm.$destroy();
        }
    });
}

export default siteFooterController;