import urls from 'DjangoUrls';
import axios from 'axios';

let {DjangoUrls} = urls;

questionPollChatMainResource.$inject = ['$http'];

const PREFIX = "api.v1:questions_poll_chat_main-";

function questionPollChatMainResource($http) {
    return {
        get_comments_list,
        add_message,
        delete_message,
        delete_poll_chat,
    };

    function get_comments_list(token, params) {
        const url = DjangoUrls[`${PREFIX}get-comments-list`](token);
        return axios({
            method: 'GET',
            url,
            params
        });
    }

    function add_message(chatToken, data) {
        const url = DjangoUrls[`${PREFIX}add-message`](chatToken);
        return axios({
            method: 'POST',
            url,
            data
        });
    }

    function delete_message(chatToken, messageId) {
        const url = DjangoUrls[`${PREFIX}delete-message`](chatToken);
        return axios({
            method: 'POST',
            url,
            data: {id: messageId}
        });
    }

    function delete_poll_chat(chatToken) {
        const url = DjangoUrls[`${PREFIX}delete-poll-chat`](chatToken);
        return axios({
            method: 'POST',
            url
        });
    }
}

export default questionPollChatMainResource;
