var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    {
      staticClass: "wiki-section",
      class: { underDrag: _vm.underDrag, active: _vm.isActive },
      style: [_vm.sectionStyle, _vm.draggingStyle, _vm.gripStyle],
      attrs: { to: _vm.to },
    },
    [
      _vm.editMode && _vm.wikiEditable && !_vm.section.is_fixed
        ? _c(
            "div",
            {
              staticClass: "wiki-section__check-button",
              style: [_vm.iconSizeStyle],
              attrs: { tabindex: "0" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.toggleTick.apply(null, arguments)
                },
                keydown: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.toggleTick.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar",
                      ])
                    )
                      return null
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.toggleTick.apply(null, arguments)
                  },
                ],
              },
            },
            [
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isTicked,
                    expression: "isTicked",
                  },
                ],
                staticClass: "fas fa-check wiki-section__checked-button-icon",
              }),
              _vm._v(" "),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isTicked,
                    expression: "!isTicked",
                  },
                ],
                staticClass:
                  "fas fa-arrow-from-top fa-rotate-180 wiki-section__check-button-icon",
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.section.audio_caption || _vm.editMode
        ? _c(
            "div",
            {
              staticClass: "wiki-section__sound-and-edit-icon",
              style: [_vm.soundEditDragMarkSizeStyle],
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.editOrAudioClick.apply(null, arguments)
                },
              },
            },
            [
              _vm.section.audio_caption && !_vm.editMode
                ? _c("audio-toggle", {
                    ref: "sectionAudioToggle",
                    attrs: {
                      "audio-caption": _vm.section.audio_caption,
                      "fa-class": "fal",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.editMode
                ? _c("i", { staticClass: "fal fa-pencil" })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.editMode && !_vm.section.is_fixed
        ? _c(
            "div",
            {
              staticClass: "wiki-section__drag-n-drop-icon",
              style: [
                _vm.soundEditDragMarkSizeStyle,
                _vm.dragMarkPositionStyle,
              ],
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                },
                mousedown: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.dragGripClicked.apply(null, arguments)
                },
                mouseenter: function ($event) {},
                touchstart: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.dragGripTouched.apply(null, arguments)
                },
              },
            },
            [
              _vm.editMode
                ? _c("i", {
                    staticClass: "fal fa-arrows-alt",
                    style: _vm.dragGripStyle,
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.editMode &&
      (_vm.showChangedMark || _vm.showNewMark || _vm.showBothMarks)
        ? _c(
            "div",
            {
              staticClass: "wiki-section__unread-mark",
              class: {
                "wiki-section__unread-mark_changed": _vm.showChangedMark,
                "wiki-section__unread-mark_new": _vm.showNewMark,
                "wiki-section__unread-mark_both": _vm.showBothMarks,
              },
              style: [_vm.iconSizeStyle],
            },
            [
              _vm.showChangedMark ? _c("span", [_vm._v("UPD")]) : _vm._e(),
              _vm.showNewMark ? _c("span", [_vm._v("NEW")]) : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "wiki-section__avatar-container",
          style: _vm.avatarStyle,
        },
        [
          _c("div", {
            staticClass: "wiki-section__avatar-outer",
            class: { isSubmenu: _vm.isSubmenu },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "wiki-section__avatar-inner",
              class: { isSubmenu: _vm.isSubmenu },
            },
            [
              _vm.samples.length
                ? _c("wiki-section-page-section-background", {
                    attrs: { "content-samples": _vm.samples },
                  })
                : _c(
                    "div",
                    {
                      staticClass:
                        "wiki-section__avatar-inner__picture-placeholder",
                      style: _vm.pictureStyle,
                    },
                    [_c("i", { staticClass: "fa fa-picture-o" })]
                  ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "wiki-section__title-container",
          style: _vm.textPartStyle,
        },
        [
          _c("div", { staticClass: "wiki-section__label" }, [
            _vm._v(_vm._s(_vm.sectionTitle) + "\n            "),
            _vm.showCounters && _vm.section.visible_content_count
              ? _c("span", { staticClass: "wiki-section__label-counter" }, [
                  _vm._v("(" + _vm._s(_vm.section.visible_content_count) + ")"),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }