var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "main-container",
      style: [_vm.containerSizeOffsetStyle, _vm.containerDragStyle],
      on: {
        dragenter: function ($event) {
          $event.preventDefault()
          return _vm.onDragEnter.apply(null, arguments)
        },
        dragover: function ($event) {
          $event.preventDefault()
        },
        dragstart: _vm.onDragStart,
        dragleave: _vm.onDragLeave,
        dragend: _vm.onDragEnd,
        drop: function ($event) {
          $event.preventDefault()
          return _vm.onDrop.apply(null, arguments)
        },
      },
    },
    [
      _vm.section
        ? _c(
            "div",
            {
              ref: "sectionAvatar",
              staticClass: "main-container-image border-group pointer",
              class: { "main-container-image_active": _vm.isActive },
              style: _vm.imageStyle,
              attrs: {
                href: "javascript:;",
                "aria-label": _vm.section.caption,
                tabindex: "0",
              },
              on: {
                click: _vm.openSection,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.openSection.apply(null, arguments)
                },
                focus: _vm.activateSection,
              },
            },
            [
              !_vm.avatarUrl && !_vm.section.media_content_samples.length
                ? _c("i", {
                    staticClass: "fa fa-image main-container-image__empty-icon",
                  })
                : _vm._e(),
              _vm._v(" "),
              (_vm.editMode && _vm.section.is_editable) ||
              _vm.section.audio_caption
                ? _c(
                    "div",
                    {
                      staticClass: "main-container-image__delete_button",
                      style: _vm.deleteMarkStyle,
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.editOrAudioClick.apply(null, arguments)
                        },
                      },
                    },
                    [
                      !_vm.editMode
                        ? _c("audio-toggle", {
                            ref: "sectionAudioToggle",
                            attrs: {
                              "audio-caption": _vm.section.audio_caption,
                              "fa-class": "far",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.editMode && _vm.section.is_editable
                        ? _c("i", { staticClass: "far fa-pencil pointer" })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showNewMark || _vm.showChangedMark || _vm.showBothMarks
                ? _c(
                    "div",
                    {
                      staticClass: "main-container-image__unread-mark",
                      class: {
                        "main-container-image__unread-mark_changed":
                          _vm.showChangedMark,
                        "main-container-image__unread-mark_new":
                          _vm.showNewMark,
                        "main-container-image__unread-mark_both":
                          _vm.showBothMarks,
                      },
                      style: _vm.changedMarkStyle,
                    },
                    [
                      _vm.showChangedMark
                        ? _c("span", [_vm._v("UPD")])
                        : _vm._e(),
                      _vm.showNewMark ? _c("span", [_vm._v("NEW")]) : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-container__text", style: [_vm.textStyle] },
        [
          _c(
            "span",
            {
              staticClass: "pointer main-container__text-caption",
              on: { click: _vm.openSection },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.section.caption) +
                  "\n            "
              ),
              _vm.showCounters && _vm.section.visible_content_count
                ? _c(
                    "span",
                    { staticClass: "main-container__text-caption-counter" },
                    [
                      _c("br"),
                      _vm._v(
                        "\n                (" +
                          _vm._s(_vm.section.visible_content_count) +
                          ")\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "align-helper" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }