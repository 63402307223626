<template>
    <div class="event-detail-info-add-to-calendar">
        <div class="margin-5-top margin-5-bottom">
            <span v-if="membershipStatus.added_to_calendar">
                <i class="vertical-middle fa fa-check-circle-o color-grass-green event-detail-info-add-to-calendar__status-icon"></i>
                <span class="vertical-middle">
                    <span v-if="behalfMode">
                        This event is added to
                        <strong>
                            <simple-link
                                    :target="{
                                        link: calendarLink,
                                        name: this.calendarOwner.name+'\'s calendar'
                                    }"
                            ></simple-link>
                        </strong>
                    </span>
                    <span v-else>
                        <span>This event is added to
                            <strong>
                                <simple-link
                                        :target="{
                                            name: 'your calendar',
                                            link: calendarLink,
                                        }"
                                ></simple-link>
                            </strong>
                        </span>
                    </span>
                </span>
            </span>
            <span v-else>
                <i class="vertical-middle fa fa-times color-red event-detail-info-add-to-calendar__status-icon"></i>
                <span class="vertical-middle">
                    <span v-if="behalfMode">
                        This event is not added to
                        <strong>
                            <simple-link
                                    :target="{
                                        link: calendarLink,
                                        name: this.calendarOwner.name+'\'s calendar'
                                    }"
                            ></simple-link>
                        </strong>
                    </span>
                    <span v-else>
                        This event is not added to
                        <strong>
                            <simple-link
                                    :target="{
                                           name: 'your calendar',
                                           link: calendarLink,
                                    }"
                            ></simple-link>
                        </strong>
                    </span>
                </span>
            </span>
        </div>
        <div class="event-detail-info-add-to-calendar__action-icon inline-block"
             v-if="!membershipStatus.added_to_calendar"
             @click="onJoinEventClick"
        >
            <div class="text-center">
                <i class="fa fa-calendar-plus-o color-grass-green event-detail-info-add-to-calendar__action-icon__icon"></i>
            </div>
            <div class="text-center">
                <strong>Add</strong>
            </div>
        </div>
        <div class="event-detail-info-add-to-calendar__action-icon inline-block"
             v-if="membershipStatus.added_to_calendar&&allowRemoveFromCalendar"
             @click="onRemoveEventClick"
        >
            <div class="text-center">
                <i class="fa fa-calendar-minus-o color-red event-detail-info-add-to-calendar__action-icon__icon"></i>
            </div>
            <div class="text-center">
                <span>Remove</span>
            </div>
        </div>
        <event-detail-page-period-selector-popup
                ref="join-type-selector"></event-detail-page-period-selector-popup>
    </div>
</template>

<script>
    import EventDetailSubPageMixin from "./EventDetailSubPageMixin";
    import EventDetailPagePeriodSelectorPopup from './EventDetailPagePeriodSelectorPopup.vue';
    import EventDetailPageJoinMixin from "./EventDetailPageJoinMixin";

    import * as config from '../new_calendar/config';
    import Vue from 'vue';
    import BackButtonMixin from "../../../../vue/mixins/BackButtonMixin";
    import {DjangoUrls} from "DjangoUrls";

    export default {
        name: "EventDetailPageInfoAddToCalendarPanel",
        mixins: [BackButtonMixin, EventDetailPageJoinMixin, EventDetailSubPageMixin],
        components: {EventDetailPagePeriodSelectorPopup},
        computed: {
            defaultBackUrl() {
                return this.calendarLink;
            },
            calendarLink() {
                return DjangoUrls['users_calendar'](this.behalfMode ? this.calendarOwner.id : 'my');
            },
            allowRemoveFromCalendar() {
                return this.buddyMode || this.calendarOwnerIsOwnEventsAdmin;
            }
        },
        methods: {
            removeEvent(id, date, hide_only_one_date = false) {
                const CalendarEventsResource = Vue.getAngularModule('CalendarEventsResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                $rootScope.show_dimmer();
                CalendarEventsResource.remove_from_calendar(id, this.calendarOwner.id, date, hide_only_one_date)
                    .then(resp => {
                        const result = resp.data; // {access:loosed}, {access:retained}
                        if (result.access === 'loosed') {
                            notifications.success('Removed');
                            this.goBack();
                        }
                        if (result.access === 'retained') {
                            this.membershipStatus.added_to_calendar = result.added_to_calendar;
                            this.membershipStatus.target_added_to_calendar = result.target_added_to_calendar;
                            this.membershipStatus.owner_has_access_to_event = result.owner_has_access_to_event;
                            this.membershipStatus.viewer_has_access_to_event = result.viewer_has_access_to_event;
                            this.membershipStatus.can_create_blog_entry = result.can_create_blog_entry;
                            $rootScope.hide_dimmer();
                        }
                    }, err => {
                        notifications.error(err || "Error");
                        $rootScope.hide_dimmer();
                    });
            },
            onRemoveEventClick() {
                if (this.periodInfo.is_periodic && this.dateMoment) {
                    this.$refs['join-type-selector'].show(this.dateMoment,
                        'Remove event from Calendar',
                        'This is a repeated Event. You can remove every occurrence of this event from the calendar or just this date')
                        .then(type => { // 'exact' or 'whole'
                            if (type === 'exact') {
                                this.removeEvent(this.periodInfo.event_id, this.dateMoment.format(config.VISUAL_DATE_FORMAT), true);
                            }
                            if (type === 'whole') {
                                this.removeEvent(this.periodInfo.event_id);
                            }
                        });
                }
                if (!this.periodInfo.is_periodic) {
                    this.removeEvent(this.periodInfo.event_id);
                }
            }
        }

    };
</script>

<style scoped lang="scss">
  .event-detail-info-add-to-calendar {
    &__status-icon {
      font-size: 21px;
    }

    &__action-icon {
      cursor: pointer;

      &__icon {
        font-size: 45px;
      }
    }
  }
</style>