/**
 * Created by orion on 12.05.17.
 */
import 'angular-simple-logger'
import angular from 'angular'
import 'angular-ui-bootstrap'

import simplePopups from '../shared/simple_popups/simple_popup_module';
import notifications from '../shared/notifications/notifications_module';

import placeFinder from './placeFinderDirective'
import maps from './maps_factory'

import MapResource from './maps_resource'

angular.module('mmApp.maps', ['ui.bootstrap', simplePopups, notifications])
    .directive("placeFinder", placeFinder)
    .factory('Maps', maps)
    .factory('MapResource', MapResource)

;


export default 'mmApp.maps'