export default {
    data() {
        return {
            currentlyDraggedMedia: undefined,
            currentlyUnderDragFolder: undefined,
        };
    },
    methods: {
        setCurrentlyDraggedMedia(media) {
            this.currentlyDraggedMedia = media;
        },
        resetCurrentlyDraggedMedia() {
            this.currentlyDraggedMedia = undefined;
        },
        setCurrentlyUnderDragFolder(folder) {
            this.currentlyUnderDragFolder = folder;
        },
        resetCurrentlyUnderDragFolder() {
            this.currentlyUnderDragFolder = undefined;
        },
        dropMedia() {
            let mediaToMoveId = this.currentlyDraggedMedia.pk;
            let targetFolderId = this.currentlyUnderDragFolder.id;
            this.moveMediaToFolder(mediaToMoveId, targetFolderId);
            this.currentlyUnderDragFolder = undefined;
            this.currentlyDraggedMedia = undefined;
        },
    }
};