/**
 * Created by orion on 13.03.17.
 */
import angular from 'angular'
import bytesFilter from './bytes'
import gbdateFilter from './gb_date'
import cutNameFilter from './name_cutter.filter'
import backHref from './add_back_href'
import backHrefQueryFilter from './add_back_href_query'
import resolveUrl from './resolveUrl'
import backUrlGenerator from '../utils/back_url_generator'
import apiDateFilter from "./api_date";
import renderEventPeriodData from "./renderEventPeriodData";
import customTerm from "./custom_term";


angular.module('mmApp.filters', [backUrlGenerator])
    .filter('bytes', bytesFilter)
    .filter('backHref', backHref)
    .filter('backHrefQuery', backHrefQueryFilter)
    .filter('gbdate', gbdateFilter)
    .filter('apiDate', apiDateFilter)
    .filter('resolveUrl', resolveUrl)
    .filter('cutName', cutNameFilter)
    .filter('customTerm', customTerm)
    .filter('renderEventPeriodData', renderEventPeriodData);

export default 'mmApp.filters'