var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-sm-12 text-center pointer" }, [
      _c("strong", { on: { click: _vm.toggleChildrenTasks } }, [
        _vm.value
          ? _c("span", [
              _c("i", { staticClass: "far fa-angle-double-down" }),
              _vm._v(
                " Show " +
                  _vm._s(_vm.duplicationsCount) +
                  " " +
                  _vm._s(_vm.duplicationPlural) +
                  " "
              ),
              _c("i", { staticClass: "far fa-angle-double-down" }),
            ])
          : _c("span", [
              _c("i", { staticClass: "far fa-angle-double-up" }),
              _vm._v(
                " Hide " +
                  _vm._s(_vm.duplicationsCount) +
                  " " +
                  _vm._s(_vm.duplicationPlural) +
                  " "
              ),
              _c("i", { staticClass: "far fa-angle-double-up" }),
            ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }