import angular from 'angular';
import ngTouch from 'angular-touch';
import 'angular-ui-bootstrap';
import 'angular-ui-switch';
import 'angular-ui-switch/angular-ui-switch.css';
import ngDraggable from '../../3dparty/ngDraggable';

import "angular-file-upload";

import preload from '../preload/preload';

//shared modules
import simplePopups from '../shared/simple_popups/simple_popup_module';
import notifications from '../shared/notifications/notifications_module';
import infiniteScroll from '../shared/infiniteScroll_directive';
import selectFactory from '../shared/select_factory/selectFactory_module';
import DragDropUtils from '../shared/drag_n_drop/utils';


//app modules
import add_media from '../add_media/add_media_module';
import view_media from '../view_media/view_media_module';

// mainFilters
import main_filters from '../shared/filters/filters_module';

import search_input from '../shared/inputs/search_input/search_input_module';

//pages

//popups

//resources
import BlogSettingsResource from './blog_settings_resource';
import BlogResource from './blog_resource';
import BlogEntryResource from './blog_entry_resource';
import BlogEntryGoalsResource from './blog_entry_goals_resource';
import BlogAnswerComment from './blog_answer_comment_resource';
import BlogEntryItemResource from './blog_entry_item_resource';
import activityPostResourceFactory from '../shared/activity_post/activityPostResourceFactory';
import ManageDiaryPermissionsResource from './blog_settings_page/manage_users/manage_permissions_resource';

//factiories
import blogEntryClassFactory from './blogEntryClass_factory';

//directives
import LikeStoryResourceFactory from "../shared/story/resource_factory";
import CommentsInfiniteScrollResourceFactory from "../shared/comments/resource_infinite_scroll_factory";
import entryEmotionDirective from "./emotion_directive/directive";
import BlogEntrySharingResource from "./blog_entry_sharing_resource";

angular.module('mmApp-blogolog',
    [ngTouch, 'uiSwitch', 'ui.bootstrap',
        preload, 'angularFileUpload', simplePopups, notifications, add_media, view_media,
        main_filters, search_input, infiniteScroll, selectFactory, ngDraggable, DragDropUtils])

    .factory('BlogSettingsResource', BlogSettingsResource)
    .factory('BlogResource', BlogResource)
    .factory('BlogEntryResource', BlogEntryResource)
    .factory('BlogEntryGoalsResource', BlogEntryGoalsResource)
    .factory('BlogEntryItemResource', BlogEntryItemResource)
    .factory('BlogEntrySharingResource', BlogEntrySharingResource)
    .factory('BlogAnswerComment', BlogAnswerComment)
    .factory('BlogEntryComments', CommentsInfiniteScrollResourceFactory('blog_entry_comments'))
    .factory('blogEntryClassFactory', blogEntryClassFactory)

    .factory('blogEntryPostManageResource', activityPostResourceFactory('blog_entry_post_manage'))
    .factory('blogEntryPostStory', LikeStoryResourceFactory('blog_entry_post_story'))
    .factory('ManageDiaryPermissionsResource', ManageDiaryPermissionsResource)

    .directive('entryEmotion', entryEmotionDirective)
;


export default 'mmApp-blogolog';