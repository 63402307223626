var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "add-profile-info-popup",
      attrs: { transition: 0, "append-to-body": true, backdrop: false },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          attrs: { id: "close_popup" },
          on: { click: _vm.closePopup },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.header))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-bottom" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("p", [_vm._v(" You haven’t added anything to your Profile yet!")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Your Profile is your page where you can add some information about you for others to see on Multi\n                Me."
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xs-12" }, [
            _c(
              "button",
              { staticClass: "btn btn-mm", on: { click: _vm.closePopup } },
              [
                _c("i", { staticClass: "fa fa-times" }),
                _vm._v(" Remind me later"),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { href: _vm.aboutMeHref },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.goToProfile.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "button",
                  { staticClass: "btn btn-mm btn-green save-button" },
                  [
                    _c("i", { staticClass: "fa fa-check" }),
                    _vm._v(" Go to Profile\n                    "),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }