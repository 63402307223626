var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "folder-filter__container",
      class: { "active-filter": _vm.isActive },
    },
    [
      _c("div", { staticClass: "folder-filter__icon" }, [
        _c("i", {
          staticClass: "fa",
          class: _vm.icon,
          attrs: { "aria-hidden": "true" },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "folder-filter__label hidden-xs" }, [
        _vm._v("\n        " + _vm._s(_vm.text) + "\n    "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }