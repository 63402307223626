<template>
<span
        class="switch"
        @click="toggle_switch()"
        @keydown.enter="toggle_switch()"
        @keydown.space.prevent="toggle_switch()"
        :aria-checked="value?'true':'false'"
        :class="classObject"
        role="checkbox"
        tabindex="0"
        ref="switchSpan"
>
    <small></small>
    <input type="checkbox" :name="name" style="display:none"/>
    <span class="switch-text"></span>
</span>
</template>
<script>
    export default {
        name: 'VueSwitch',
        props: {
            name: {
                type: String,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false
            },
            value: Boolean
        },
        data() {
            return {};
        },
        computed: {
            classObject: function () {
                return {
                    checked: this.value,
                    disabled: this.disabled
                };
            }
        },
        methods: {
            toggle_switch() {
                if (this.disabled) {
                    return;
                }
                this.$emit('input', !this.value);
                this.$emit('change');
            },
            focus() {
                this.$refs.switchSpan?.focus();
            },
        },
    };
</script>
<style>

</style>