/**
 * Created by orion on 04.05.17.
 */
import _ from 'lodash';

stickersFactory.$inject = ['simplePopupFactory', '$rootScope', 'stickersResources'];
import STICKERS from './stickers_list';
import AddStickerPopup from "./AddStickerPopup";
import {CurrentUserClass} from "shared/CurrentUser";

function stickersFactory(simplePopupFactory, $rootScope, stickersResources) {
    const CurrentUser = new CurrentUserClass();

    function select_sticker() {
        const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(AddStickerPopup);

        return new Promise((resolve, reject) => {

            let recentStickerPromise;
            if (CurrentUser.is_authenticated()) {
                $rootScope.show_dimmer();
                recentStickerPromise = stickersResources.get_my_recent_stickers(CurrentUser.id);
            } else {
                recentStickerPromise = new Promise((res) => {
                    res({data: []});
                });
            }
            recentStickerPromise
                .then(
                    resp => {
                        $rootScope.hide_dimmer();
                        return popupComponent.show(resp.data.length ? create_recent_group(resp.data) : undefined);
                    },
                    err => {
                        $rootScope.hide_dimmer();
                        throw Error('');
                    }
                )
                .then(res => {
                    const sticker_code = get_sticker_code(res);
                    if (CurrentUser.is_authenticated()) {
                        stickersResources.user_used_sticker(CurrentUser.id, sticker_code); //fail silently
                    }
                    resolve(Object.assign({code: sticker_code}, res));
                }, () => {
                    reject();
                })
                .finally(() => {
                    fEl?.focus();
                    if (vm) {
                        vm.$destroy();
                    }
                });
        });

    }

    function get_sticker_by_code(code) {
        let [folder, name] = code.split('__');
        let sticker_collection = STICKERS[_.findIndex(STICKERS, {folder})];
        if (!sticker_collection) {
            return;
        }
        let required_stickers_group = sticker_collection.stickers;
        return required_stickers_group[_.findIndex(required_stickers_group, {name})];
    }

    function get_sticker_code(sticker) {
        if (!sticker) {
            return null;
        }
        if (_.isString(sticker)) {
            return sticker;
        }
        if (sticker.folder && sticker.name) {
            return `${sticker.folder}__${sticker.name}`;
        }
    }

    function create_recent_group(codes_list) {
        function get_sticker_url(folder, file_name) {
            /**
             * @param {string} folder 'hands'||'monsters'||'teddys'
             * @param {string} file_name - sticker in group name
             */
            return `/static/images/stickers/${folder}/${file_name}`;
        }

        let res = {
            name: 'Recent',
            is_legacy: false,
            stickers: []
        };
        for (let stickerCode of codes_list) {
            res.stickers.push(get_sticker_by_code(stickerCode));
        }
        res.title_sticker = res.stickers[0]['name'];
        res.title_sticker_href = get_sticker_url(res.stickers[0]['folder'], res.stickers[0]['file_name']);
        return res;

    }

    return {
        select_sticker,
        get_sticker_by_code,
        get_sticker_code,
    };
}

export default stickersFactory;