var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.header)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", [
            _c("span", [_vm._v("Name of event")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.eventName,
                  expression: "eventName",
                },
              ],
              ref: "nameInput",
              staticClass: "form-control",
              attrs: {
                maxlength: "100",
                type: "text",
                placeholder: "Name of event",
                required: "",
              },
              domProps: { value: _vm.eventName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.eventName = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.advancedMode,
              expression: "advancedMode",
            },
          ],
          staticClass: "row margin-15-top",
        },
        [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("div", [
              _c("span", [_vm._v("Tell us a bit more about your event")]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.eventDescription,
                    expression: "eventDescription",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  maxlength: "3000",
                  type: "text",
                  placeholder: "Event description",
                },
                domProps: { value: _vm.eventDescription },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.eventDescription = $event.target.value
                  },
                },
              }),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _vm.mediaForAvatar
              ? _c("media-preview", {
                  attrs: {
                    "no-border": true,
                    item: _vm.mediaForAvatar,
                    "allow-delete": true,
                    "show-name": true,
                  },
                  on: { onDelete: _vm.cleanAvatar },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.mediaForAvatar
              ? _c("media-preview-add-request", {
                  attrs: {
                    "no-border": true,
                    text: "+ Picture",
                    "icon-class": "fa-picture-o",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addAvatar.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.locationData
              ? _c("media-preview-add-request", {
                  attrs: {
                    "no-border": true,
                    text: "+ Location",
                    "icon-class": "fa-map-marker",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addLocation.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.locationData
              ? _c("media-preview", {
                  attrs: {
                    "no-border": true,
                    "show-name": true,
                    item: _vm.locationPreviewSrc,
                    text: _vm.locationCaption,
                    "allow-delete": true,
                  },
                  on: { onDelete: _vm.cleanLocation },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.mediaForAudioCaption
              ? _c("media-preview", {
                  attrs: {
                    "no-border": true,
                    item: _vm.mediaForAudioCaption,
                    "allow-delete": true,
                    "show-name": true,
                  },
                  on: { onDelete: _vm.cleanAudioCaption },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.mediaForAudioCaption
              ? _c("media-preview-add-request", {
                  attrs: {
                    "no-border": true,
                    text: "+ Audio",
                    "icon-class": "fa-volume-down",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addAudio.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top enlarged-calendar-14" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c(
            "form",
            { staticClass: "form" },
            [
              _c("span", [_vm._v("Start date")]),
              _vm._v(" "),
              _c(
                "datepicker",
                {
                  ref: "start-date-picker",
                  attrs: {
                    "disabled-dates": _vm.disabledDatesStart,
                    format: _vm.customFormatter,
                    width: _vm.pickerWidth,
                    "input-class": "create-event-date-picker",
                    "bootstrap-styling": true,
                    typeable: false,
                    highlighted: _vm.highlightedDates,
                  },
                  nativeOn: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                          "Backspace",
                          "Delete",
                          "Del",
                        ])
                      )
                        return null
                      return _vm.startDateClear.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.startDate,
                    callback: function ($$v) {
                      _vm.startDate = $$v
                    },
                    expression: "startDate",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "input-group-btn",
                      attrs: { slot: "afterDateInput" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.toggleStartDateCalendar.apply(
                            null,
                            arguments
                          )
                        },
                      },
                      slot: "afterDateInput",
                    },
                    [
                      _c(
                        "btn",
                        {
                          staticClass:
                            "dropdown-toggle create-event-date-picker",
                        },
                        [
                          _c("i", {
                            staticClass: "glyphicon glyphicon-calendar",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-6" }, [
          _c(
            "form",
            { staticClass: "form" },
            [
              _c("span", [_vm._v("Finish date")]),
              _vm._v(" "),
              _c(
                "datepicker",
                {
                  ref: "end-date-picker",
                  attrs: {
                    "disabled-dates": _vm.disabledDatesEnd,
                    disabled: _vm.endDateDisabled,
                    format: _vm.customFormatter,
                    width: _vm.pickerWidth,
                    highlighted: _vm.highlightedDates,
                    "input-class": "create-event-date-picker",
                    "bootstrap-styling": true,
                    typeable: false,
                  },
                  nativeOn: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                          "Backspace",
                          "Delete",
                          "Del",
                        ])
                      )
                        return null
                      return _vm.endDateClear.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.endDate,
                    callback: function ($$v) {
                      _vm.endDate = $$v
                    },
                    expression: "endDate",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "input-group-btn",
                      attrs: { slot: "afterDateInput" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.toggleEndDateCalendar.apply(
                            null,
                            arguments
                          )
                        },
                      },
                      slot: "afterDateInput",
                    },
                    [
                      _c(
                        "btn",
                        {
                          staticClass:
                            "dropdown-toggle create-event-date-picker",
                          attrs: { disabled: _vm.endDateDisabled },
                        },
                        [
                          _c("i", {
                            staticClass: "glyphicon glyphicon-calendar",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      !_vm.advancedTimeScheduleEnabled
        ? _c("event-time-editor", {
            staticClass: "margin-15-top",
            attrs: { disabled: _vm.wholeDay },
            model: {
              value: _vm.time,
              callback: function ($$v) {
                _vm.time = $$v
              },
              expression: "time",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.advancedMode,
              expression: "!advancedMode",
            },
          ],
          staticClass: "row margin-15-top",
        },
        [
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c("vue-switch-with-label", {
                attrs: { name: "whole-day", label: "All day" },
                model: {
                  value: _vm.wholeDay,
                  callback: function ($$v) {
                    _vm.wholeDay = $$v
                  },
                  expression: "wholeDay",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.advancedMode,
              expression: "!advancedMode",
            },
          ],
          staticClass: "row margin-15-top",
        },
        [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "a",
              {
                staticClass: "pointer",
                attrs: { href: "javascript:;" },
                on: { click: _vm.enableAdvancedMode },
              },
              [_vm._v("Advanced Options")]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.allowToggleAdvancedTimeSchedule && _vm.advancedTimeScheduleEnabled
        ? _c("div", { staticClass: "alert alert-info margin-15-top" }, [
            _vm._v("\n        Advanced Time schedule enabled. "),
            _c(
              "strong",
              {
                staticClass: "pointer",
                on: { click: _vm.showAdvancedTimeSchedulePopup },
              },
              [
                _c("i", { staticClass: "fa fa-pencil" }),
                _vm._v("Edit schedule"),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showAddToEventSwitch
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.advancedMode,
                  expression: "!advancedMode",
                },
              ],
              staticClass: "row margin-15-top",
            },
            [
              _c("div", { staticClass: "col-sm-12" }, [
                _c("span", [_vm._v("Settings")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "margin-5-top" },
                  [
                    _c("vue-switch-with-label", {
                      attrs: {
                        name: "addUserToEvent",
                        disabled: !_vm.privateEvent,
                        label: _vm.addToEventLabel,
                      },
                      model: {
                        value: _vm.addUserToEvent,
                        callback: function ($$v) {
                          _vm.addUserToEvent = $$v
                        },
                        expression: "addUserToEvent",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.advancedMode,
              expression: "advancedMode",
            },
          ],
          staticClass: "row margin-15-top",
        },
        [
          _c(
            "div",
            {
              class: {
                "col-sm-6": _vm.periodicEvent,
                "col-sm-12": !_vm.periodicEvent,
              },
            },
            [
              _c("span", [_vm._v("Settings")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "margin-5-top" },
                [
                  _c("vue-switch-with-label", {
                    attrs: { name: "whole-day", label: "All day" },
                    model: {
                      value: _vm.wholeDay,
                      callback: function ($$v) {
                        _vm.wholeDay = $$v
                      },
                      expression: "wholeDay",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "margin-5-top" },
                [
                  _c("vue-switch-with-label", {
                    attrs: {
                      name: "whole-day",
                      disabled: _vm.repeatDisabled,
                      label: "Repeat Event",
                    },
                    model: {
                      value: _vm.periodicEvent,
                      callback: function ($$v) {
                        _vm.periodicEvent = $$v
                      },
                      expression: "periodicEvent",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "margin-5-top" },
                [
                  _c("vue-switch-with-label", {
                    attrs: {
                      name: "private-event",
                      label: _vm.privateLabel,
                      "help-text": _vm.privateHelpText,
                    },
                    model: {
                      value: _vm.privateEvent,
                      callback: function ($$v) {
                        _vm.privateEvent = $$v
                      },
                      expression: "privateEvent",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "margin-5-top" },
                [
                  _vm.showAddToEventSwitch
                    ? _c("vue-switch-with-label", {
                        attrs: {
                          name: "addUserToEvent",
                          disabled: !_vm.privateEvent,
                          label: _vm.addToEventLabel,
                        },
                        model: {
                          value: _vm.addUserToEvent,
                          callback: function ($$v) {
                            _vm.addUserToEvent = $$v
                          },
                          expression: "addUserToEvent",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "margin-5-top" },
                [
                  _c("vue-switch-with-label", {
                    attrs: {
                      name: "approve-messages",
                      label: "Approve messages",
                    },
                    model: {
                      value: _vm.approveMessages,
                      callback: function ($$v) {
                        _vm.approveMessages = $$v
                      },
                      expression: "approveMessages",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.periodicEvent,
                  expression: "periodicEvent",
                },
              ],
              staticClass: "col-sm-6",
            },
            [
              _c("span", [_vm._v("Repeat")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.repeatOption,
                      expression: "repeatOption",
                    },
                  ],
                  staticClass: "form-control inline-block",
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.repeatOption = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.onRepeatOptionChange,
                    ],
                  },
                },
                _vm._l(_vm.repeatOptions, function (repeatOption) {
                  return _c(
                    "option",
                    { domProps: { value: repeatOption.code } },
                    [_vm._v(_vm._s(repeatOption.label) + "\n                ")]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showSkipRepeatPeriodSelector,
                      expression: "showSkipRepeatPeriodSelector",
                    },
                  ],
                },
                [_vm._v("Number:")]
              ),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showSkipRepeatPeriodSelector,
                      expression: "showSkipRepeatPeriodSelector",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.skipRepeatPeriod,
                      expression: "skipRepeatPeriod",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    name: "skipRepeatPeriodSelector",
                    id: "skipRepeatPeriodSelector",
                  },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.skipRepeatPeriod = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.skipPeriodOptions, function (option) {
                  return _c("option", { domProps: { value: option.value } }, [
                    _vm._v(_vm._s(option.text)),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showDaysOfWeekSelector,
                      expression: "showDaysOfWeekSelector",
                    },
                  ],
                },
                [_vm._v("Days of the week:")]
              ),
              _vm._v(" "),
              _c("multi-select", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showDaysOfWeekSelector,
                    expression: "showDaysOfWeekSelector",
                  },
                ],
                attrs: {
                  options: _vm.multiDaysOptions,
                  block: true,
                  "collapse-selected": true,
                },
                model: {
                  value: _vm.selectedDaysOfWeek,
                  callback: function ($$v) {
                    _vm.selectedDaysOfWeek = $$v
                  },
                  expression: "selectedDaysOfWeek",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "margin-5-top" },
                [
                  _vm.allowToggleAdvancedTimeSchedule
                    ? _c("vue-switch-with-label", {
                        attrs: {
                          value: _vm.advancedTimeScheduleEnabled,
                          name: "advanced-schedule",
                          label: "Advanced Schedule",
                          "help-text":
                            "Allows set Start and Finish time separately for each day of week. Available only for 'Days of week' repeat type.",
                        },
                        on: { input: _vm.toggleAdvancedTimeScheduleEnabled },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("span", [_vm._v("End on")]),
              _vm._v(" "),
              _c(
                "form",
                { staticClass: "form-inline", attrs: { action: "" } },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-5 text-left",
                        staticStyle: { "padding-right": "0" },
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.periodicEventTermination,
                                expression: "periodicEventTermination",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { name: "", id: "" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.periodicEventTermination = $event.target
                                  .multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "never" } }, [
                              _vm._v("Never"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "after" } }, [
                              _vm._v("After"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "onDate" } }, [
                              _vm._v("On Date"),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.periodicEventTermination === "after",
                            expression: "periodicEventTermination==='after'",
                          },
                        ],
                        staticClass: "col-xs-3 text-center",
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.periodicEventAmountBeforeTermination,
                                expression:
                                  "periodicEventAmountBeforeTermination",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.periodicEventAmountBeforeTermination =
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(
                            _vm.afterAmountOptions,
                            function (afterAmountOption) {
                              return _c(
                                "option",
                                { domProps: { value: afterAmountOption } },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(afterAmountOption) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.periodicEventTermination === "after",
                            expression: "periodicEventTermination==='after'",
                          },
                        ],
                        staticClass: "col-xs-4",
                        staticStyle: { "padding-left": "0" },
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.periodicEventTerminationUnit,
                                expression: "periodicEventTerminationUnit",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: { padding: "4px" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.periodicEventTerminationUnit = $event.target
                                  .multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(_vm.repeatUnits, function (repeatUnit) {
                            return _c(
                              "option",
                              { domProps: { value: repeatUnit.code } },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(repeatUnit.label) +
                                    "\n                            "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.periodicEventTermination === "onDate",
                            expression: "periodicEventTermination==='onDate'",
                          },
                        ],
                        staticClass: "col-xs-7",
                      },
                      [
                        _c(
                          "datepicker",
                          {
                            ref: "period-end-picker",
                            attrs: {
                              highlighted: _vm.highlightedDates,
                              "disabled-dates": _vm.disabledDatesEnd,
                              format: _vm.customFormatter,
                              width: _vm.pickerWidth,
                              "input-class": "create-event-date-picker",
                              "bootstrap-styling": true,
                              typeable: false,
                            },
                            nativeOn: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "delete",
                                    [8, 46],
                                    $event.key,
                                    ["Backspace", "Delete", "Del"]
                                  )
                                )
                                  return null
                                return _vm.periodEndDateClear.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            model: {
                              value: _vm.periodEndDate,
                              callback: function ($$v) {
                                _vm.periodEndDate = $$v
                              },
                              expression: "periodEndDate",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "input-group-btn",
                                attrs: { slot: "afterDateInput" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.toggleEndPeriodDateCalendar.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                slot: "afterDateInput",
                              },
                              [
                                _c(
                                  "btn",
                                  {
                                    staticClass:
                                      "dropdown-toggle create-event-date-picker",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "glyphicon glyphicon-calendar",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.calculatedEndDate,
                          expression: "calculatedEndDate",
                        },
                      ],
                    },
                    [
                      _vm._v(
                        "\n                    End Date: " +
                          _vm._s(_vm.calculatedEndDateFormatted) +
                          "\n                "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("advanced-time-schedule-popup", {
        ref: "advanced-time-schedule-popup",
      }),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("button", { staticClass: "btn btn-mm", on: { click: _vm.close } }, [
          _vm._v("Cancel"),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-green save-button",
            attrs: { disabled: !_vm.allowSave },
            on: { click: _vm.saveEvent },
          },
          [_vm._v("\n            Create\n        ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }