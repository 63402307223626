var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "quote-component", class: { "limit-size": _vm.limitSize } },
    [
      _c("div", [_vm._t("comment")], 2),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "quote-component__quote", style: _vm.quoteBorderStyle },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }