<template>
    <div>
        <vue-simple-header>
            <span slot="headerTitle">Circles List</span>
            <div class="" slot="panelRight">
                <vue-search-input @search="onSearch" @cancel="onSearchCancel"></vue-search-input>
            </div>
        </vue-simple-header>
        <my-stuff-list-my-item
                v-show="!query"
                name="My Circle"
                :image-url="icon"
                :link="myCircleLink"
        ></my-stuff-list-my-item>
        <div class="row" v-show="users.length">
            <div class="col-sm-12 list_header" style="font-size: 21px; font-weight: bolder;">
                Circles I manage ({{totalCount}})
            </div>
        </div>
        <div class="row" v-infinite-scroll="loadMore">
            <div class="col-sm-12">
                <users-circle-in-list :user="user" v-for="user in users" :key="user.id"></users-circle-in-list>
            </div>
        </div>
        <div class="row text-center" v-show="allLoaded&&users.length===0">
            <div class="col-sm-12">
                <span style="font-size: 20px">No results...</span>
            </div>
        </div>
        <div class="text-center" v-if="loading"><span>Loading...</span></div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import InfiniteScrollMixin from "../../../vue/mixins/InfiniteScrollMixin";
    import UsersCircleInList from "./UsersCircleInList";
    import MyStuffListMyItem from "../../../vue/common_components/MyStuffListMyItem";
    import {DjangoUrls} from 'DjangoUrls';
    import {CurrentUserClass} from "../shared/CurrentUser";
    import {DASHBOARD_ICONS, DASHBOARD_ITEMS} from "../dashboard/dashboard";

    export default {
        name: "ManagedCirclesList",
        mixins: [InfiniteScrollMixin],
        components: {UsersCircleInList, MyStuffListMyItem},
        data() {
            return {
                limit: 20
            };
        },
        computed: {
            icon() {
                return DASHBOARD_ICONS[DASHBOARD_ITEMS.CIRCLE];
            },
            myCircleLink() {
                const CurrentUser = new CurrentUserClass();
                return DjangoUrls['user_detail'](CurrentUser.name) + 'circle/list';
            },
            users() {
                return this.results;
            }
        },
        methods: {
            getListEndpointArgs() {
                return [];
            },
            getListEndpoint() {
                const CirclesListResource = Vue.getAngularModule('CirclesListResource');
                return CirclesListResource.get_circles_i_manage;
            }
        }
    };
</script>

<style scoped>

</style>