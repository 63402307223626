<template>
    <div class="create-entry"
         :class="{'disabled': disabled}"
         @click="onClick">
        <div class="create-entry__icon-part"></div>
        <div class="create-entry__text-part"
        >Create a Diary entry about this event
        </div>
    </div>
</template>

<script>
    export default {
        name: "EventDetailPageInfoCreateEntryButton",
        props: {
            disabled: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            onClick() {
                if (!this.disabled) {
                    this.$emit('click');
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../styles/const";

    $avatar-width: 160px;
    .create-entry {
        @media (min-width: $screen-sm-min) {
            width: $avatar-width;
            margin-bottom: 15px;
            margin-top: 15px;
        }
        @media (max-width: $screen-xs-max) {
            margin-bottom: 10px;
            margin-top: 10px;
        }
        height: 80px;
        border-radius: 10px;
        border: solid 3px $color-diary-dark;
        background-color: white;

        cursor: pointer;

        &.disabled {
            /*background-color: lightgrey;*/
            opacity: 0.6;
            cursor: not-allowed;
        }

        &__icon-part {
            vertical-align: middle;
            display: inline-block;
            height: 100%;
            border-radius: 5px 0 0 5px;
            width: 40px;
            /*background-color: #00d500;*/
            background-image: url('/static/images/new_daschboard_icons/my_diary_sept19.png');
            background-size: 30px;
            background-position: center;
            background-repeat: no-repeat;
        }

        &__text-part {
            vertical-align: middle;
            display: inline-block;
            width: calc(100% - 46px);
            line-height: 16px;
            font-size: 14px;
            font-weight: normal;

        }
    }
</style>