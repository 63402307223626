<template>
    <a href="javascript:" class="spi__container inline-block" tabindex="0" role="button" :aria-label="item.label"
       :class="colorClass">
        <div class="spi__check-mark"
             v-if="showCheckMark"
             :class="{'spi__check-mark_yellow': checkMarkYellow}"
        ><i class="fa fa-check" aria-hidden="true"></i></div>
        <div class="spi__button">
            <div class="spi__title" v-html="item.label"></div>
            <div class="spi__icon-section" :class="item.customIcon">
                <i class="fa spi__fa-icon"
                   v-if="item.iconClass"
                   :class="item.iconClass"
                   aria-hidden="true"
                ></i>
            </div>
        </div>
    </a>
</template>

<script>
    export default {
        name: "SelectorPopupItem",
        props: {
            item: Object,
        },
        computed: {
            colorClass() {
                if (!this.item.color) {
                    return 'spi__container_blue';
                } else {
                    return `spi__container_${this.item.color}`;
                }
            },
            showCheckMark() {
                return this.item.checkMark;
            },
            checkMarkYellow() {
                return this.item.checkMark && this.item.checkMarkYellow;
            }
        }

    };
</script>

<style scoped lang="scss">
  @use "sass:color";

  @import "../../../angular/app/styles/const";
  @import "../../../angular/app/styles/mixins";

  .spi {

    &__check-mark {
      display: block;
      position: absolute;
      right: -19px;
      top: -8px;
      z-index: 10;

      &_yellow {
        background-color: $yellow;
      }

      background-color: $grass-green;

      width: 40px;
      height: 40px;
      border-radius: 50%;
      text-align: center;
      line-height: 40px;
      font-size: 40px;
      color: white;
    }

    &__container {
      position: relative;
      display: inline-block;
      border-radius: 5px;
      margin: 5px;
      padding: 5px;
      min-height: 120px;
      width: 120px;
      text-decoration: none;

      @mixin panelColors($basicColor) {
        background-color: $basicColor;

        &:focus, &:active {
          background-color: adjust-color($basicColor, $lightness: 5%);
        }

        &:hover {
          background-color: adjust-color($basicColor, $lightness: -5%);
        }
      }

      &_blue {
        @include panelColors(#337ab7);
      }

      &_red {
        @include panelColors($red);
      }

      &_yellow {
        @include panelColors($color-story);
      }

      &_lightblue {
        @include panelColors($blue);
      }

      &_green {
        @include panelColors($color-community);
      }


      color: white;

      text-align: center;

      @media (min-width: 400px) and (max-width: $screen-xs-max) {
        width: 130px;
        min-height: 130px;
      }
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        width: 130px;
        min-height: 130px;
      }
      @media (min-width: $screen-md-min) {
        margin: 10px;
        width: 140px;
        min-height: 140px;
      }
    }

    &__button {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      align-content: space-between;
    }

    &__title {
      font-size: 18px;
    }

    &__icon-section {
      margin-top: 5px;
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      min-height: 80px;
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        min-height: 90px;
      }
      @media (min-width: $screen-md-min) {
        min-height: 100px;
      }

      &.user-lock {
        background-image: url('/static/images/mm-icons/user-lock.png');
        background-size: 70%;
      }
      &.user {
        background-image: url('/static/images/overlays-icons/user-add-one.png');
        background-size: 70%;
      }

      &.circle {
        background-image: url('/static/images/overlays-icons/my-circle.png');
        background-size: 70%;
      }

      &.groups {
        background-image: url('/static/images/overlays-icons/groups_new_white.png');
        background-size: 65%;
      }
      &.buddy-group {
        background-image: url('/static/images/mm-icons/np_buddies_88194_white_1.png');
        background-size: 65%;
      }

      &.communities {
        background-image: url('/static/images/overlays-icons/community_new_white.png');
        background-size: 65%;
      }

      &.wiki {
        background-image: url('/static/images/new_daschboard_icons/2020/mywiki-small-white.png');
        background-size: 65%;
      }
      &.diary {
        background-image: url('/static/images/new_daschboard_icons/2020/mydiary-white.png');
        background-size: 65%;
      }

    }

    &__fa-icon {
      font-size: 60px;
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        font-size: 70px;
        line-height: 90px;

      }
      @media (min-width: $screen-md-min) {
        font-size: 80px;
        line-height: 100px;
      }
    }
  }
</style>
