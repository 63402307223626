<template>
    <div class="main-container" :style="containerSizeOffsetStyle"

    >
    </div>
</template>

<script>
    import WikiSectionMixin from './WikiSectionMixin';

    export default {
        name: "empty-section",
        mixins: [WikiSectionMixin],
        computed: {
        }
    };
</script>

<style scoped lang="scss">
    .main-container {
    }
</style>