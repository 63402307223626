var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _vm.commentsEnabled
        ? _c(
            "div",
            [
              _c("comments-list-infinite-scroll", {
                attrs: {
                  "target-id": _vm.eventId,
                  "comments-resource": _vm.commentsResource,
                  "comments-additional-params": _vm.commentsAdditionalParams,
                  "allow-add": _vm.allowAdd,
                  "allow-like": true,
                  "allow-delete": _vm.isAdmin,
                  "allow-delete-own": true,
                  "allow-reply-expand": true,
                  "initial-reply-to": _vm.initialReplyTo,
                },
                on: {
                  onCommentSent: _vm.onCommentSent,
                  onCommentRemoved: _vm.onCommentRemoved,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.commentsBlockedDueViewerIsNotMember
        ? _c("div", { staticClass: "alert alert-warning" }, [
            !_vm.statusData
              ? _c("div", {}, [
                  _vm._v(
                    "\n            Sorry you can’t access Chats in this Event as you are not a member of this Event.\n        "
                  ),
                ])
              : _c("div", {}, [
                  _vm.statusData.status === "NOT_COMMUNITY_MEMBER"
                    ? _c("div", {}, [
                        _c("div", { staticClass: "alert alert-warning" }, [
                          _vm._v(
                            "\n                    Sorry you can’t access Chats in this Event as you are not a member of this Group Event's\n                    Community.\n                "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.statusData.status === "NOT_GROUP_MEMBER"
                    ? _c("div", {}, [
                        _c("div", { staticClass: "event-join-form" }, [
                          !_vm.statusData.is_join_request_pending
                            ? _c("div", [
                                _c("div", {}, [
                                  _c("span", [
                                    _vm._v(
                                      "Sorry you can’t access Chats in this Event as you are not a member of this Event’s Group "
                                    ),
                                    _c(
                                      "strong",
                                      [
                                        _c("simple-link", {
                                          attrs: {
                                            target: _vm.statusData.group,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm.hasPermission("join-groups")
                                    ? _c("span", [
                                        _vm._v(
                                          "Request to join this Group to get access to this Event."
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _vm.hasPermission("join-groups")
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-center margin-15-top",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-mm",
                                            on: {
                                              click: _vm.sendGroupJoinRequest,
                                            },
                                          },
                                          [_vm._v("Request to join")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _c("div", [
                                _c("div", {}, [
                                  _c("span", [
                                    _vm._v(
                                      "Sorry you can’t access Chats in this Event as you are not a member of this Event’s Group "
                                    ),
                                    _c(
                                      "strong",
                                      [
                                        _c("simple-link", {
                                          attrs: {
                                            target: _vm.statusData.group,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "You will join Group after approval. We will notify you."
                                    ),
                                  ]),
                                ]),
                              ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.statusData.status === "NOT_CIRCLE_MEMBER"
                    ? _c("div", {}, [
                        _c("div", { staticClass: "event-join-form" }, [
                          !_vm.statusData.is_join_request_pending
                            ? _c("div", [
                                _c("div", {}, [
                                  _c("span", [
                                    _vm._v(
                                      "Sorry you can’t access Chats in this Event as you are not a member of the Event owner’s Circle"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm.hasPermission("add-friends")
                                    ? _c(
                                        "span",
                                        [
                                          _vm._v("Request to join "),
                                          _c("simple-link", {
                                            attrs: {
                                              target:
                                                _vm.statusData.circle_owner,
                                            },
                                          }),
                                          _vm._v(
                                            " Circle and access this Event"
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _vm.hasPermission("add-friends")
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-center margin-15-top",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-mm",
                                            on: {
                                              click: _vm.sendCircleJoinRequest,
                                            },
                                          },
                                          [_vm._v("Request to join")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _c("div", [_vm._m(0)]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.statusData.status === "PRIVATE_EVENT"
                    ? _c("div", {}, [
                        _c("div", { staticClass: "event-join-form" }, [
                          !_vm.statusData.is_join_request_pending
                            ? _c("div", [
                                _vm._m(1),
                                _vm._v(" "),
                                _vm.hasPermission("join-events")
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-center margin-15-top",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-mm",
                                            on: { click: _vm.onJoinEventClick },
                                          },
                                          [_vm._v("Request access")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _c("div", [_vm._m(2)]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("email-role-category-popup", { ref: "email-role-category-popup" }),
      _vm._v(" "),
      _c("event-detail-page-join-form-period-selector-popup", {
        ref: "join-type-selector",
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c("span", [
        _vm._v(
          "Sorry you can’t access Chats in this Event as you are not a member of the Event owner's Circle"
        ),
      ]),
      _c("br"),
      _vm._v(" "),
      _c("span", [_vm._v("You will join Circle after approval.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c("span", [
        _vm._v(
          "Sorry you can’t access Chats in this Event as you are not invited to this event."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c("span", [
        _vm._v(
          "Sorry you can’t access Chats in this Event as you are not invited to this event."
        ),
      ]),
      _c("br"),
      _vm._v(" "),
      _c("span", [_vm._v("You will get access after approval.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }