<template>
    <div class="stories-list">
        <div class="row">
            <!--<div class="col-sm-5 stories-list__create-button" style="margin-top: 5px">
                <select class="form-control" @change="sourceChange($event)" v-model="storiesSource"
                        v-if="allowFilter && filterCategories.length">
                    <option :value="filterCategory" v-for="filterCategory in filterCategories">
                        {{filterCategory.text}}
                    </option>
                </select>
            </div>-->
            <div class="col-sm-5 text-left">
                <slot name="header"><strong style="font-size: 28px">{{ header }}</strong></slot>
            </div>
            <div class="col-sm-7 text-right">
                <vue-folder-button text="Create Story" color="background-color-story" v-if="allowCreate"
                                   custom-icon="new-story" @click.native="createStory"></vue-folder-button>
                <vue-search-input
                        :min-letters-count="2"
                        :initial-query="initialQuery"
                        :class="searchFieldClasses"
                        @search="startSearch"
                        @cancel="cancelSearch"
                ></vue-search-input>
            </div>
        </div>
        <div class="row" v-if="allowCreate">
            <div class="col-sm-12">

            </div>
        </div>
        <div class="stories-list__stories-container" v-infinite-scroll="loadMore">
            <rectangle-stuff-preview
                    v-for="story in stories"
                    :key="story.content_type + story.id"

                    :audio-caption="story.audio_caption"
                    :to="wrapLink(story.link)"
                    :color-class="story.content_type"
                    :name="story.name"
                    :avatar="story.avatar"

                    :size="swamItemSize"
            ></rectangle-stuff-preview>
            <div
                    :style="swamFakeIconStyle"
                    v-for="fakeItem in swamGetFakeItems(stories.length)"
                    :key="fakeItem.id"
            ></div>
        </div>
        <slot name="emptyMessageSearch" v-if="!stories.length&&!showSpinner&&lastQuery">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <span style="font-size: 20px;">No Results...</span>
                </div>
            </div>
        </slot>
        <slot name="emptyMessage" v-if="!stories.length&&!showSpinner&&!lastQuery">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <span style="font-size: 20px;">No Posts...</span>
                </div>
            </div>
        </slot>
        <div style="height: 50px; margin-top:40px;">
            <div class="text-center" v-if="showSpinner"><span>Loading...</span></div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';
    import RectangleStuffPreview from "../../../../vue/common_components/RectangleStuffPreview";
    import SelfWidthAwareMixin from "../../../../vue/mixins/SelfWidthAwareMixin";
    import ItemSizingMixin from "../../group_page/sharing_lists/ItemSizingMixin";

    const LIMIT = 24;

    export default {
        name: "stories-list",
        mixins: [SelfWidthAwareMixin, ItemSizingMixin],
        components: {
            RectangleStuffPreview,
        },
        props: {
            allowCreate: {
                type: Boolean,
                required: false,
                default: false
            },
            allowFilter: {
                type: Boolean,
                required: false,
                default: false
            },
            filterCategories: {
                type: Array,
                required: false,
                default() {
                    return [];
                },
            },
            storiesListEndpoint: Function,
            createStoryEndpoint: Function,
            header: {
                type: String,
                required: false
            },
            trackQueryInUrl: {
                type: Boolean,
                default: false,
            },
            useQueryAsBackHref: {
                type: Boolean,
                default: false,
            },
            searchFieldClasses: {
                type: Array,
                default: () => {
                    return [];
                },
            },
        },
        data() {
            return {
                loadedAll: false,

                lastQuery: '',
                activeSource: '',

                initialSource: '',
                initialQuery: '',

                storiesSource: undefined,
                loadingBlock: false,

                stories: [],
            };
        },
        computed: {
            showSpinner() {
                return this.loadingBlock;
            }
        },

        mounted() {
            const $rootScope = Vue.getAngularModule('$rootScope');
            $rootScope.show_dimmer();
            this.$_fetchStories(this.lastQuery, this.activeSource);
        },
        methods: {
            $_fetchStories(query, active_source) {
                if (this.loadingBlock) {
                    return;
                }
                this.loadingBlock = true;
                this.storiesListEndpoint({
                    limit: LIMIT,
                    offset: this.stories.length,
                    query,
                    source: active_source

                })
                    .then(resp => {
                        this.stories = _.concat(this.stories, resp.data.results);
                        const stories_count = resp.data.count;
                        if (this.stories.length >= stories_count) {
                            this.loadedAll = true;
                        }
                    }).finally(() => {
                    this.loadingBlock = false;
                    const $rootScope = Vue.getAngularModule('$rootScope');
                    $rootScope.hide_dimmer();
                });
            },
            wrapLink(link) {
                if (link) {
                    if (this.useQueryAsBackHref) {
                        return this.$options.filters.backHrefQuery(link);
                    } else {
                        return this.$options.filters.backHref(link);
                    }
                }
            },
            createStory() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const $q = Vue.getAngularModule('$q');
                const $filter = Vue.getAngularModule('$filter');
                const notifications = Vue.getAngularModule('notifications');
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                simplePopupFactory.simple_text_input_popup('Create New Story', 'Story name:', 'Create', 'Cancel')
                    .then(name => {
                        $rootScope.show_dimmer();
                        const res = this.createStoryEndpoint(name);
                        return res;
                    }, () => new Promise(() => {
                    }))
                    .then(resp => {
                        document.location.href = $filter('backHref')(resp.data.link);
                    }, err => {
                        notifications.error(err || 'Error');
                        $rootScope.hide_dimmer();
                    });
            },
            loadMore() {
                if (this.loadedAll) {
                    return;
                }
                this.$_fetchStories(this.lastQuery, this.activeSource);
            },
            startSearch(query) {
                if (query === this.lastQuery) {
                    return;
                }
                this.stories = [];
                this.loadedAll = false;
                this.lastQuery = query;
                if (this.$router && this.trackQueryInUrl) {
                    this.$router.replace({query: {'stories-search-query': query}});
                }

                this.$_fetchStories(query, this.activeSource);
            },
            cancelSearch() {
                this.stories = [];
                if (this.$router && this.trackQueryInUrl) {
                    this.$router.replace({query: {'stories-search-query': undefined}});
                }
                this.loadedAll = false;
                this.lastQuery = undefined;
                this.$_fetchStories(undefined, this.activeSource);
            },
            sourceChange($event) {
                const source = this.storiesSource.code;
                this.stories = [];
                this.loadedAll = false;
                this.activeSource = source;
                if (this.$router) {
                    this.$router.replace({query: {'stories-source': source}});
                }
                this.$_fetchStories(this.lastQuery, source);
            }
        },
        created() {
            if (this.$router) {
                this.initialQuery = this.lastQuery = this.$route.query['stories-search-query'];
                this.initialSource = this.activeSource = this.$route.query['stories-source'] || 'all';
            }
            let selectedCategory = _.find(this.filterCategories, e => e.code === this.initialSource);
            if (selectedCategory) {
                this.storiesSource = selectedCategory;
            }
        }
    };
</script>

<style scoped lang="scss">
  .stories-list {
    &__stories-container {
      margin-top: 5px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__create-button {
      @media all and (max-width: 766px) {
        text-align: right;
      }
    }
  }

</style>