let _ = require('lodash');
import {DjangoUrls} from 'DjangoUrls';
groupEmailInvitationController.$inject = ['CurrentUser', '$rootScope', 'simplePopupFactory', '$q', '$preloaded', 'notifications', 'GroupInviteResource'];

function groupEmailInvitationController(CurrentUser, $rootScope, simplePopupFactory, $q, $preloaded, notifications, GroupInviteResource) {
    const self = this;
    let invitation_code, group_id;

    function init() {
        self.group_name = $preloaded.group_name;
        self.loginLink =  `${DjangoUrls['auth_login']()}?next=${document.location.pathname}`;
        self.is_authenticated = CurrentUser.is_authenticated();
        invitation_code = $preloaded.invitation_code;
        group_id = $preloaded.group_id;

        if (self.is_authenticated) {
            let message = `You have been invited to <strong>${self.group_name}</strong> Group. Click ‘<strong>Join</strong>’ to accept the invitation.`;

            simplePopupFactory.show_popup(`Join Group`, message, 'Join', 'Reject Invitation')
                .then(confirm => {
                    $rootScope.show_dimmer();
                    return GroupInviteResource.join_as_existed_user(group_id, invitation_code);
                }, rejectReason => {
                    $rootScope.show_dimmer();
                    if (rejectReason === 'cancel') {
                        return GroupInviteResource.reject_invitation(group_id, invitation_code);
                    }
                    if (rejectReason === 'close') {
                        document.location.href = DjangoUrls['index']();
                        return  new Promise(()=>{});
                    }

                })
                .then(resp => {
                    document.location.href = '/';
                }, err => {
                    notifications.error(err || 'Error');
                    $rootScope.hide_dimmer();
                });
        }
    }

    init();

    self.register = function (reg_data) {
        _.merge(reg_data, {invitation_code});
        $rootScope.show_dimmer();
        GroupInviteResource.reg_by_invite(group_id, reg_data)
            .then(resp => {
                notifications.success('Successfully registered');
                document.location.href = '/';
            }, err => {
                notifications.error(err || 'Error');
                $rootScope.hide_dimmer();
            });
    };


}

export default groupEmailInvitationController;