var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "set-name-popup",
      attrs: { transition: 0, "append-to-body": true, backdrop: false },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          attrs: { id: "close_popup" },
          on: { click: _vm.closePopup },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.header))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-bottom" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _vm._v(
            "\n            Please add your first name and last name so other users can easily identify you on Multi Me.\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-bottom" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", [
            _c("span", [_vm._v("First Name:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.firstName,
                  expression: "firstName",
                },
              ],
              ref: "name-input",
              staticClass: "form-control",
              attrs: {
                maxlength: "100",
                type: "text",
                placeholder: "First Name",
                required: "",
              },
              domProps: { value: _vm.firstName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.firstName = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-bottom" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", [
            _c("span", [_vm._v("Last Name:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.lastName,
                  expression: "lastName",
                },
              ],
              ref: "name-input",
              staticClass: "form-control",
              attrs: {
                maxlength: "100",
                type: "text",
                placeholder: "Last Name",
                required: "",
              },
              domProps: { value: _vm.lastName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.lastName = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xs-12" }, [
            _c(
              "button",
              { staticClass: "btn btn-mm", on: { click: _vm.closePopup } },
              [
                _c("i", { staticClass: "fa fa-times" }),
                _vm._v(" Remind me later"),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-green save-button",
                attrs: { disabled: !_vm.allowSave },
                on: { click: _vm.save },
              },
              [
                _c("i", { staticClass: "fa fa-check" }),
                _vm._v(" Save\n                "),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }