const SYMBOL_PASSWORD_BUTTONS = [
    [
        {cls: 'fa-plane', clr: 'blue', value: 'a7P%', label: 'Plane'},
        {cls: 'fa-tree', clr: 'green', value: '%fL5Fh', label: 'Tree'},
        {cls: 'fa-star', clr: 'orange', value: 'pg5?N#', label: 'Star'},
        {cls: 'fa-umbrella', clr: 'red', value: 'zy3m', label: 'Umbrella'},
        {cls: 'fa-clock-o', clr: 'black', value: 'd5dx', label: 'Clock'},
    ],
    [
        {cls: 'fa-paw', clr: 'brown', value: 'z5w3n', label: 'Paw'},
        {cls: 'fa-home', clr: 'darkgoldenrod', value: 'BEh+', label: 'Home'},
        {cls: 'fa-soccer-ball-o', clr: 'black', value: '3yaH', label: 'Ball'},
        {cls: 'fa-tint', clr: 'blue', value: 'HnES', label: 'Drop'},
        {cls: 'fa-coffee', clr: 'brown', value: 'P87q', label: 'Coffee cup'},
    ],
    [
        {cls: 'fa-bicycle', clr: 'red', value: '7Z&*', label: 'Bicycle'},
        {cls: 'fa-binoculars', clr: 'black', value: 'Wazk', label: 'Binoculars'},
        {cls: 'fa-bell', clr: 'orange', value: 'VHh6', label: 'Bell'},
        {cls: 'fa-beer', clr: '#BB5600', value: 'a$Y&', label: 'Big cup'},
        {cls: 'fa-bolt', clr: 'yellow', value: 'hQ6&', label: 'Bolt'},
    ],
    [
        {cls: 'fa-bug', clr: 'darkred', value: '2-@G', label: 'Bug'},
        {cls: 'fa-car', clr: '#8935D6', value: '3S!@', label: 'Car'},
        {cls: 'fa-eye', clr: 'blue', value: '86=n', label: 'Eye'},
        {cls: 'fa-heart', clr: 'red', value: '-kVx', label: 'Heart'},
        {cls: 'fa-graduation-cap', clr: 'black', value: '-oXr', label: 'Graduation cap'},
    ],
    [
        {cls: 'fa-balance-scale', clr: 'black', value: 'uZ67', label: 'Balance scale'},
        {cls: 'fa-anchor', clr: 'darkblue', value: 'kY$g', label: 'Anchor'},
        {cls: 'fa-birthday-cake', clr: 'brown', value: 'mn-4', label: 'Cake'},
        {cls: 'fa-fire-extinguisher', clr: 'red', value: 'z?sa', label: 'Fire extinguisher'},
        {cls: 'fa-puzzle-piece', clr: 'green', value: 'rq!a', label: 'Puzzle piece'},
    ]
];

export {SYMBOL_PASSWORD_BUTTONS};