/**
 * Created by orion on 07.03.17.
 */

import _ from 'lodash';

wsEventsHandlers.$inject = ['$rootScope', '$timeout', 'notifications', '$templateCache', '$log'];

function wsEventsHandlers($rootScope, $timeout, notifications, $templateCache, $log) {


    async function onMessage(rest) {
        $rootScope.onWsMessage(rest[0]);
    }

    $rootScope.$on('websocket-session-ready', (e, session) => {
        $log.debug('websocket-session-ready', session);
        session.subscribe('com.multime.my.conversations', onMessage);
        session.subscribe('com.multime.my.alerts', onMessage);
    });

    $templateCache.put('ws.conversation.newMessage.html', require('./ws_messages/ws.conversation.newMessage.html'));
    $templateCache.put('ws.alert.onNewAlert.html', require('./ws_messages/ws.alert.onNewAlert.html'));

    let audio = new Audio('/static/audio/message_sound.mp3');

    function playNotification() {
        audio.load();
        try {
            audio.play(); // ios no interaction error message possible.
        } catch (e) {
        }
    }

    let DEFAULT_HANDLERS = {
        'conversation:onMessageAdded': msg => {
            let notification_scope = $rootScope.$new();
            notification_scope.msg = msg;
            notifications.Notification({
                delay: 8000,
                title: 'New Message',
                templateUrl: "ws.conversation.newMessage.html",
                scope: notification_scope
            });
            playNotification();
            $rootScope.$broadcast('conversations_unread_count_increased', msg.conversation.id);
        },
        'alert:onNewAlert': async msg => {
            let notification_scope = $rootScope.$new();
            notification_scope.msg = msg;
            notifications.Notification({
                delay: 8000,
                title: 'New Message',
                templateUrl: "ws.alert.onNewAlert.html",
                scope: notification_scope
            });
            playNotification();
            $rootScope.$broadcast('update_tasks_counters')
        }
    };

    let CUSTOM_HANDLERS = {};


    $rootScope.addEventHandler = function (code, handler) {
        if (_.isArray(CUSTOM_HANDLERS[code])) {
            CUSTOM_HANDLERS[code].push(handler);
        } else {
            CUSTOM_HANDLERS[code] = [handler];
        }
    };

    $rootScope.removeEventHandler = function (code, handler) {
        if (_.isArray(CUSTOM_HANDLERS[code])) {
            _.remove(CUSTOM_HANDLERS[code], h => h === handler);
        }
    };

    $rootScope.onWsMessage = function (msg) {
        let event_code_detail = msg['event-code-detail'];
        let event_code_default = msg['event-code-default'];
        let callDefaultHandler = true;

        if (_.isArray(CUSTOM_HANDLERS[event_code_detail]) && CUSTOM_HANDLERS[event_code_detail].length > 0) {
            for (let handler of CUSTOM_HANDLERS[event_code_detail]) {
                if (handler(msg)) {
                    callDefaultHandler = false;
                }
            }
        }

        if (callDefaultHandler && DEFAULT_HANDLERS[event_code_default]) {
            DEFAULT_HANDLERS[event_code_default](msg);
        }

        $log.debug('$rootScope.onWsMessage', msg);
    };


}

export default wsEventsHandlers;
