<template>
    <a :href="story.link | backHref" style="text-decoration: none; margin-left: 15px;">
        <div class="story_preview">
            <div class="story_preview__avatar" :style="avatarStyle" :class="story.content_type">
                <div class="story_preview__audio-caption-container" v-if="story.audio_caption">
                    <audio-toggle :audio-caption="story.audio_caption"></audio-toggle>
                </div>
            </div>
            <div class="story_preview__name">{{story.name | cutName(40)}}</div>
        </div>
    </a>

</template>

<script>
    export default {
        name: "story-preview",
        props: {
            'story': {
                type: Object
            }
        },
        computed: {
            avatarStyle() {
                const isDefault = this.story.avatar.indexOf('/static/') !== -1;
                const res = {
                    'background-image': `url('${this.story.avatar}')`
                };
                if(isDefault){
                    res['background-size']='contain';
                }else{
                    res['background-size']='cover';
                }
                return res;
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../../styles/const';

    .story_preview {
        vertical-align: top;
        @media all and (min-width: 441px) {
            max-width: 186px;
        }
        @media all and (max-width: 440px) {
            max-width: 122px;
        }
        display: inline-block;
        margin-top: 15px;
        cursor: pointer;
        /*width: 200px;*/
        /*height: 200px;*/

        &__name {
            text-align: center;
            font-size: 15px;
            font-weight: bolder;
        }

        &__audio-caption-container{
            position: absolute;
            right: 5px;
            bottom: 5px;
            border-radius: 50%;
            text-align: center;
            font-size: 30px;
            line-height: 45px;
            width: 45px;
            height: 45px;
            background-color: rgba(255,255,255,0.5);
        }

        &__avatar {
            @media all and (min-width: 441px) {
                width: 197px;
                height: 150px;
            }
            @media all and (max-width: 440px) {
                width: 122px;
                height: 93px;
            }

            background-position: center;
            background-repeat: no-repeat;
            border: solid 4px;
            position: relative;
            &.story{
                border-color: $color-story;
                &:hover {
                    border-color: $color-story-dark;
                }
            }
            &.blog_entry{
                border-color: $color-diary;
                &:hover {
                    border-color: $color-diary-dark;
                }
            }
            &.activity_post{
                border-color: $color-group;
                &:hover {
                    border-color: $color-group-dark;
                }
            }

            &.group_activity_post{
                border-color: $color-group;
                &:hover {
                    border-color: $color-group-dark;
                }
            }


            transition: border-color 0.2s ease-out;
            border-radius: 3px;
            background-color: white;
        }
    }
</style>