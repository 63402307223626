import moment from 'moment';
import * as config from './config';
import _ from 'lodash';

const DAYS_OF_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const EVENT_PERIODS = {
    DAILY: 0,
    WEEKDAY: 1,
    WEEKEND: 2,
    MONTHLY_BY_DAY_NUMBER: 3,
    ANNUALLY_BY_DAY_AND_MONTH_NUMBER: 4,
    WEEKLY_BY_DAY_NUMBER: 5,
    MONTHLY_BY_WEEKDAY_NUMBER: 6,
    MONTHLY_LAST_WEEKDAY: 7,
    WEEKLY_BY_DAY_NUMBERS: 8,
    ONCE_EVERY_N_WEEKS: 9,
};

function isLastWeekdayInMonth(date) {
    const currentMonth = date.month();
    return date.clone().add(1, 'w').month() !== currentMonth;
}

function getWeekdayInMonthNumber(date) {
    const currentMonth = date.month();
    let weekdayNumber = 1;
    let iDay = date.clone();
    while (iDay.subtract(1, 'w').month() === currentMonth) {
        weekdayNumber++;
    }
    return weekdayNumber;
}

function getPeriodTextRepresentation(startDate, repeatType, daysOfWeek = undefined, skipRepeatPeriod = undefined) {
    if (repeatType === EVENT_PERIODS.DAILY) {
        return 'Daily';
    }
    if (repeatType === EVENT_PERIODS.WEEKDAY) {
        return 'Weekday';
    }
    if (repeatType === EVENT_PERIODS.WEEKEND) {
        return 'Weekend';
    }
    if (repeatType === EVENT_PERIODS.WEEKLY_BY_DAY_NUMBERS) {
        if (daysOfWeek) {
            const days = DAYS_OF_WEEK.map(d => d + 's');
            return `Weekly on ${days.filter(d => daysOfWeek.includes(days.indexOf(d))).join(', ')}`;
        } else {
            return 'Days of the week';
        }
    }

    const startDateMoment = moment(startDate, config.VISUAL_DATE_FORMAT);

    if (repeatType === EVENT_PERIODS.ONCE_EVERY_N_WEEKS) {
        if (skipRepeatPeriod === undefined) {
            return `Every number of weeks`;
        } else {
            return `Once every ${skipRepeatPeriod} weeks on ${startDateMoment.format('dddd')}s`;
        }
    }

    if (repeatType === EVENT_PERIODS.WEEKLY_BY_DAY_NUMBER) {
        return `Weekly on ${startDateMoment.format('dddd')}s`;
    }
    if (repeatType === EVENT_PERIODS.MONTHLY_BY_DAY_NUMBER) {
        return `Monthly on the ${startDateMoment.format('D')}`;
    }
    if (repeatType === EVENT_PERIODS.ANNUALLY_BY_DAY_AND_MONTH_NUMBER) {
        return `Annually on ${startDateMoment.format('MMM')} ${startDateMoment.format('D')}`;
    }

    if (repeatType === EVENT_PERIODS.MONTHLY_BY_WEEKDAY_NUMBER) {
        const numLabel = ['1st', '2nd', '3rd', '4th', '5th'][getWeekdayInMonthNumber(startDateMoment) - 1];

        return `On ${numLabel} ${startDateMoment.format('dddd')} of the month`;
    }

    if (repeatType === EVENT_PERIODS.MONTHLY_LAST_WEEKDAY) {
        return `On last ${startDateMoment.format('dddd')} of the month`;
    }

}

function getEventUrl(event, formattedDate, calendar_owner) {
    let url;
    if (event.community) {
        url = `${event.community.link}events/${event.id}/`;
    } else {
        url = `/calendar/event/${event.id}/`;
    }
    if (formattedDate) {
        url += `${formattedDate}/`;
    }
    if (calendar_owner) {
        url += `${calendar_owner.id}/${calendar_owner.name}/`;
    }
    return url;
}

const nullify = (i) => {
    if (_.isNumber(i)) {
        return i < 10 ? `0${i}` : `${i}`;
    }
    if (_.isString(i)) {
        if (i.length === 1) {
            return `0${i}`;
        } else {
            return i;
        }
    }
};

const getHrsLabel = (hourNum) => {
    if (hourNum < 13) {
        return `${hourNum === 0 ? 12 : hourNum} ${hourNum === 12 ? 'pm' : 'am'}`;
    } else {
        return `${hourNum - 12} pm`;
    }
};

function timeToAmPm(hhmmFormattedTime) {
    const time = moment(hhmmFormattedTime.substr(0, 5), 'HH:mm');
    const outputFormat = (time.minutes() === 0) ? 'ha' : 'h:mma';
    return time.format(outputFormat);
}

const TODAY = window.$today;

export {
    EVENT_PERIODS,
    DAYS_OF_WEEK,
    TODAY,
    getPeriodTextRepresentation,
    getEventUrl,
    isLastWeekdayInMonth,
    getWeekdayInMonthNumber,
    nullify,
    timeToAmPm,
    getHrsLabel,
};
