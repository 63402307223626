var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.popupConfig.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.popupConfig.header)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.sticker
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-12 text-center" },
              [
                _c("render-sticker", {
                  attrs: { sticker: _vm.sticker, "allow-delete": true },
                  on: { onDelete: _vm.removeSticker },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-xs-7", staticStyle: { height: "32px" } },
              [
                _c("span", { staticClass: "align-helper" }),
                _vm._v(" "),
                _c("label", { staticStyle: { "vertical-align": "bottom" } }, [
                  _vm._v("Add text entry below..."),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-xs-5 text-right" },
              [
                _c("vue-switch-with-label", {
                  attrs: { label: "Header", name: "is_header" },
                  model: {
                    value: _vm.isHeader,
                    callback: function ($$v) {
                      _vm.isHeader = $$v
                    },
                    expression: "isHeader",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-xs-12" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.text,
                    expression: "text",
                  },
                ],
                ref: "textArea",
                staticClass:
                  "form-control modal-text-input-textarea cepp__textarea",
                class: { cepp__textarea_header: _vm.isHeader },
                attrs: { rows: "5" },
                domProps: { value: _vm.text },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.text = $event.target.value
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row add-stuff-panel" }, [
        _c(
          "div",
          {
            staticClass:
              "col-sm-12 create_like_story_post_popup__button_panel justify",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "create_like_story_post_popup__attach_button green",
                class: { disabled: !!_vm.mediaAttachment },
                attrs: { tabindex: "0" },
                on: {
                  click: _vm.addMedia,
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.addMedia.apply(null, arguments)
                  },
                },
              },
              [
                _c("i", { staticClass: "far fa-film" }),
                _vm._v(" "),
                _c("i", { staticClass: "fab fa-itunes-note" }),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "create_like_story_post_popup__attach_button__label",
                  },
                  [
                    _vm._v("\n                        + "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "create_like_story_post_popup__attach_button__label__add-word",
                      },
                      [_vm._v("Add")]
                    ),
                    _vm._v(" Media\n                    "),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "create_like_story_post_popup__attach_button blue",
                class: { disabled: !!_vm.sticker },
                attrs: { tabindex: "0" },
                on: {
                  click: _vm.addSticker,
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.addSticker.apply(null, arguments)
                  },
                },
              },
              [
                _c("i", { staticClass: "far fa-thumbs-up" }),
                _vm._v(" "),
                _c("i", { staticClass: "far fa-heart" }),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "create_like_story_post_popup__attach_button__label",
                  },
                  [
                    _vm._v("\n                        + "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "create_like_story_post_popup__attach_button__label__add-word",
                      },
                      [_vm._v("Add")]
                    ),
                    _vm._v(" Sticker\n                    "),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.mediaAttachment
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("media-preview", {
                  attrs: {
                    "allow-delete": true,
                    item: _vm.mediaAttachment,
                    "no-border": true,
                  },
                  on: { onDelete: _vm.deleteMediaAttachment },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "button",
              { staticClass: "btn btn-mm", on: { click: _vm.close } },
              [_vm._v(_vm._s(_vm.popupConfig.button_no))]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "inline-block",
                attrs: { "data-role": "trigger" },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-mm btn-green",
                    attrs: { disabled: !_vm.isSaveButtonEnabled },
                    on: { click: _vm.ok },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.popupConfig.button_yes) +
                        "\n                    "
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "span",
              {
                staticClass: "focus-catch-link",
                attrs: { tabindex: "0", "aria-hidden": "true" },
                on: { focus: _vm.focusToClose },
              },
              [_vm._v(" ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }