<template>
    <div class="wiki-breadcrumbs-component">
        <div class="breadcrumb-image border-group pointer wiki-breadcrumbs-component__user-profile-link"
             :style="mainSectionAvatarStyle"
             @click="mainSectionClick"
             v-tooltip="avatarTooltipText"
        ></div>
        <div class="breadcrumb-circles-container">
            <wiki-breadcrumbs-section
                    :section="section.parent_section.parent_section"
                    v-if="section&&section.parent_section&&section.parent_section.parent_section"
                    @sectionClick="sectionClick"
                    :size="size"
            ></wiki-breadcrumbs-section>
            <wiki-breadcrumbs-section
                    :section="section.parent_section"
                    :size="size"
                    v-if="section&&section.parent_section"
                    @sectionClick="sectionClick"
            ></wiki-breadcrumbs-section>
            <wiki-breadcrumbs-section
                    :section="section"
                    :size="size"
                    v-if="section"
                    @sectionClick="sectionClick"
                    :isActive="activePart==='section'"
            ></wiki-breadcrumbs-section>
        </div>
        <div class="breadcrumb-label">{{breadcrumbLabel}}</div>
    </div>
</template>

<script>
    import mediaContentUtils from "../../../../angular/app/view_media/mediaContentUtils";
    import cutNameFilter from "../../shared/filters/name_cutter.filter";
    import WikiBreadcrumbsSection from './WikiBreadcrumbsSection.vue';
    import {DjangoUrls} from 'DjangoUrls';
    import Vue from 'vue';

    export default {
        name: "wiki-breadcrumbs",
        components: {
            WikiBreadcrumbsSection
        },
        data() {
            return {};
        },
        props: {
            wikiOwner: {
                type: Object,
                required: false
            },
            isCommunityWiki: {
                type: Boolean,
                required: false
            },
            section: {
                type: Object,
                required: false
            },
            slide: {
                type: Object,
                required: false
            },
            size: {
                type: Number,
                default: 40
            }
        },
        computed: {
            avatarTooltipText() {
                if (this.isCommunityWiki) {
                    return this.wikiOwner.name + ' Wiki';
                } else {
                    return this.wikiOwner.name + '\'s Wiki';
                }
            },
            get_left_part_size() {
                return `col-sm-${this.leftPartSize}`;
            },
            get_right_part_size() {
                return `col-sm-${this.rightPartSize}`;
            },
            mainSectionAvatarStyle() {
                return {
                    width: `${this.size}px`,
                    height: `${this.size}px`,
                };
            },
            slideAvatarStyle() {
                if (this.slide.media_content) {
                    return new mediaContentUtils().generate_background(this.slide.media_content);
                }
            },
            breadcrumbLabel() {
                let label;
                if (this.slide) {
                    if (this.slide.caption) {
                        label = `Page '${cutNameFilter()(this.slide.caption, 15)}' comments`;
                    } else {
                        label = 'Page comments';
                    }
                } else if (this.section) {
                    label = this.section.caption;
                } else if (this.subsection) {
                    label = this.subsection.caption;
                } else {
                    label = this.avatarTooltipText;
                }

                return cutNameFilter()(label, 30);

            },
            activePart() {
                if (this.slide) {
                    return 'slide';
                }
                if (this.section) {
                    return 'section';
                }
                return 'main';

            }
        },
        methods: {
            mainSectionClick() {
                this.$emit('mainSectionClick');
            },
            sectionClick(section) {
                this.$emit('sectionClick', section);
            },
            slideClick() {
                this.$emit('slideClick');
            }
        }
    };
</script>

<style lang="scss">
    @import "../../styles/const";

    .wiki-breadcrumbs-component {
        &__user-profile-link {
            background-image: url("./mini-wiki-icon-white-solid.png");
            background-color: $blue;
            background-size: contain !important;
            background-position: center;
            background-repeat: no-repeat;
        }

        .breadcrumb-image {
            vertical-align: middle;
            display: inline-block;
            border-radius: 50%;
            background-size: cover;
            background-repeat: no-repeat;
            font-size: 25px;
            line-height: 33px;
            border: solid 3px $blue;

            &.active {
                border: solid 3px $green;
                line-height: 38px;
            }
        }

        .breadcrumb-separator {
            display: inline-block;
            vertical-align: middle;
            @media (min-width: 800px) {
                width: 32px;
            }
            @media (max-width: 799px) {
                width: 16px;
            }
            height: 1px;
            border-bottom: dashed 5px $blue;
        }

        .breadcrumb-label {
            display: inline-block;


            @media (min-width: 610px) {
                font-size: 15px;
                line-height: 15px;
            }
            @media (max-width: 609px) {
                font-size: 17px;
                line-height: 17px;
                font-weight: bolder;
            }

            font-weight: bolder;
            max-width: 100px;
            vertical-align: middle;
        }

        .breadcrumb-circles-container {
            vertical-align: middle;
            @media (min-width: 660px) {
                display: inline-block;

            }
            @media (max-width: 659px) {
                display: none;
            }

        }
    }

</style>