/**
 * Created by orion on 28.05.17.
 */
import urls from 'DjangoUrls';
import _ from 'lodash';

let {DjangoUrls} = urls;
StoryResource.$inject = ['$http'];

function StoryResource($http) {
    return {
        get_my_stories: function (params) {
            const url = DjangoUrls["api.v1:stories-get-my-stories"]();
            return $http({
                method: 'GET',
                url,
                params,
                cache: false
            })
        },

        create_story: function (data) {
            const url = DjangoUrls["api.v1:stories-create-story"]();
            return $http({
                method: 'POST',
                url,
                data
            })
        },
        create_story_for_user: function (user_id, data) {
            const url = DjangoUrls["api.v1:stories-create-story-for-user"]();
            _.merge(data, {id: user_id});
            return $http({
                method: 'POST',
                url,
                data
            })
        },

        rename_story: function (story_id, name) {
            const url = DjangoUrls["api.v1:stories-rename-story"](story_id);
            return $http({
                method: 'POST',
                url,
                data: {name}
            })
        },

        remove_story: function (story_id) {
            const url = DjangoUrls["api.v1:stories-remove-story"](story_id);
            return $http({
                method: 'DELETE',
                url
            })
        }
    }
}

export default StoryResource