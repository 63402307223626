var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": "New Wiki", role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v("Filling Guidance"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.initialized && !_vm.results.length
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", { staticClass: "alert alert-info" }, [
                _vm._v(
                  "\n                There isn't anything yet\n            "
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row content-container" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          _vm._l(_vm.results, function (post) {
            return _c("filling-guidance-dialog-item", {
              key: post.id,
              attrs: { post: post, editable: _vm.editable },
              on: {
                remove: function ($event) {
                  return _vm.removePost(post)
                },
              },
            })
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-8 text-left" }, [
            _vm.editable
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-mm btn-default",
                    attrs: { disabled: _vm.results.length > 19 },
                    on: { click: _vm.addText },
                  },
                  [_vm._v("\n                    Add Text\n                ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.editable
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-mm btn-default",
                    attrs: { disabled: _vm.results.length > 19 },
                    on: { click: _vm.addMedia },
                  },
                  [_vm._v("Add Media\n                ")]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c(
              "button",
              { staticClass: "btn btn-mm btn-green", on: { click: _vm.close } },
              [_vm._v("Close")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }