var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("div", {}, [_vm._v("Add to Calendar")]),
      _vm._v(" "),
      _c("i", {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: "Google Calendar",
            expression: "'Google Calendar'",
          },
        ],
        staticClass: "fa fa-google pointer",
        staticStyle: { color: "#4285f4", "font-size": "18px" },
        on: {
          click: function ($event) {
            return _vm.addToCalendar("google")
          },
        },
      }),
      _vm._v(" "),
      _c("i", {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: "iCal",
            expression: "'iCal'",
          },
        ],
        staticClass: "fa fa-apple pointer",
        staticStyle: {
          color: "#505050",
          "font-size": "22px",
          "margin-left": "10px",
        },
        on: {
          click: function ($event) {
            return _vm.addToCalendar("apple")
          },
        },
      }),
      _vm._v(" "),
      _c("img", {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: "Outlook",
            expression: "'Outlook'",
          },
        ],
        staticClass: "pointer",
        staticStyle: { height: "20px", width: "auto", "margin-left": "10px" },
        attrs: { src: require("./Outlook.com_icon.svg"), alt: "" },
        on: {
          click: function ($event) {
            return _vm.addToICalendar("outlook")
          },
        },
      }),
      _vm._v(" "),
      _c("event-detail-page-period-selector-popup", {
        ref: "add-to-calendar-type-selector",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }