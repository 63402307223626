/**
 * Created by orion on 28.05.17.
 */
import urls from 'DjangoUrls';

let {DjangoUrls} = urls;
StoryMembersResource.$inject = ['$http'];

function StoryMembersResource($http) {
    return {
        get_members_list_for_members_page(story_id, params) {
            const url = DjangoUrls["api.v1:story_members-get-members-list-for-members-page"](story_id);
            return $http({
                method: 'GET',
                url,
                params
            })
        },

        get_users_for_invite(story_id, params) {
            const url = DjangoUrls["api.v1:story_members-get-users-for-invite"](story_id);
            return $http({
                method: 'GET',
                url,
                params
            })
        },

        get_admins_for_manage(story_id, params) {
            const url = DjangoUrls["api.v1:story_members-get-admins-for-manage"](story_id);
            return $http({
                method: 'GET',
                url,
                params
            })
        },

        invite_users(story_id, new_checked_ids, new_unchecked_ids) {
            const url = DjangoUrls["api.v1:story_members-invite-users"](story_id);
            return $http({
                method: 'POST',
                url,
                data: {
                    new_checked_ids, new_unchecked_ids
                }
            })
        },

        update_admins(id, new_checked_ids, new_unchecked_ids) {
            const url = DjangoUrls["api.v1:story_members-update-admins"](id);
            return $http({
                method: 'PATCH',
                url,
                data: {
                    new_checked_ids, new_unchecked_ids
                }
            })
        },

        delete_member(id, user_id) {
            const url = DjangoUrls["api.v1:story_members-delete-member"](id);
            return $http({
                method: 'PATCH',
                url,
                data: {
                    id: user_id
                }
            })
        },

        make_me_admin(story_id) {
            const url = DjangoUrls["api.v1:story_members-make-me-admin"](story_id);
            return $http({
                method: 'POST',
                url
            })
        },

        join(id) {
            const url = DjangoUrls["api.v1:story_members-join"](id);
            return $http({
                method: 'POST',
                url
            })
        }

    }
}

export default StoryMembersResource