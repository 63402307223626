import WikiDetailPage from "./WikiDetailPage.vue";
import WikiMainPageMenu from "./WikiMainPageMenu.vue";
import WikiSectionPage from "./WikiSectionPage.vue";

const getWikiChildren = (namePrefix = '', meta = {}) => {
    return [
        {
            path: '',
            name: namePrefix + 'MainWikiPage',
            props: true,
            component: WikiMainPageMenu,
            meta
        },
        {
            path: ':sectionId(\\d+)/',
            name: namePrefix + 'WikiSubsectionPage',
            props: true,
            component: WikiSectionPage,
            meta
        },
        {
            path: 'detail/:sectionId(\\d+)/:slideId(\\d+)?/',
            name: namePrefix + 'WikiSectionPageDetail',
            props: true,
            component: WikiDetailPage,
            meta
        },
    ];
};

const getMainWikiPageName = (namePrefix = '') => {
    return namePrefix + 'MainWikiPage';
};
const getSubsectionWikiPageName = (namePrefix = '') => {
    return namePrefix + 'WikiSubsectionPage';
};
const getSubsectionDetailWikiPageName = (namePrefix = '') => {
    return namePrefix + 'WikiSectionPageDetail';
};

export {getWikiChildren, getMainWikiPageName, getSubsectionDetailWikiPageName, getSubsectionWikiPageName};