/**
 * Created by orion on 06.04.17.
 */
import urls from 'DjangoUrls';

let {DjangoUrls} = urls;
import _ from 'lodash'

UserActivityPostGoalsResource.$inject = ['$http', '$log'];

function UserActivityPostGoalsResource($http, $log) {
    return {
        get_linked_goals,
        get_users_goals_for_link,
        link_to_task,
        get_users_tasks_for_link_in_goal,
        unlink_all_blog_tasks
    };

    function get_linked_goals(id, params) {
        const url = DjangoUrls["api.v1:user_activity_post_goals-get-linked-goals"](id);
        return $http({
            method: 'GET',
            url: url,
            params
        })
    }

    function get_users_goals_for_link(id, params) {
        const url = DjangoUrls["api.v1:user_activity_post_goals-get-users-goals-for-link"](id);
        return $http({
            method: 'GET',
            url: url,
            params
        })
    }

    function get_users_tasks_for_link_in_goal(id, goal_id, params) {
        const url = DjangoUrls["api.v1:user_activity_post_goals-get-users-tasks-for-link-in-goal"](id);
        return $http({
            method: 'GET',
            url: url,
            params: _.merge({}, {id: goal_id}, params)
        })
    }

    function link_to_task(id, new_checked_ids, new_unchecked_ids) {
        const url = DjangoUrls["api.v1:user_activity_post_goals-link-to-task"](id);
        return $http({
            method: 'POST',
            url: url,
            data: {
                new_checked_ids,
                new_unchecked_ids
            }
        })
    }

    function unlink_all_blog_tasks(id, goal_id) {
        const url = DjangoUrls["api.v1:user_activity_post_goals-unlink-all-blog-tasks"](id);
        return $http({
            method: 'POST',
            url: url,
            data: {
                id: goal_id
            }
        })
    }


}

export default UserActivityPostGoalsResource