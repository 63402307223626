<template>
    <span v-if="slideSnapshotAfter">
        <span>moved page </span>
        <strong>
            #{{ slideSnapshotAfter.id }} <span v-if="slideSnapshotAfter.caption">
            <wiki-log-possible-link
                    :target="wikiLog.target_wiki_slide"
                    :label="slideSnapshotAfter.caption"
            ></wiki-log-possible-link>
        </span>
        </strong>
        <span v-if="slideSnapshotBefore.section_name">
            from <strong>#{{ slideSnapshotBefore.section }}
            "{{ slideSnapshotBefore.section_name }}"
        </strong>
        </span>
        <span v-if="slideSnapshotAfter.section_name">
            to <strong>#{{ slideSnapshotAfter.section }}
            <wiki-log-possible-link
                    :target="wikiLog.target_wiki_section"
                    :label="slideSnapshotAfter.section_name"
            ></wiki-log-possible-link>
        </strong>
        </span>
    </span>
</template>

<script>
    import SnapshotLogMixin from "./common/SnapshotLogMixin";

    export default {
        name: "WikiSlideUpdatedMoved",
        mixins:[SnapshotLogMixin]
    };
</script>

<style scoped>

</style>