var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { transition: 0, "append-to-body": true, backdrop: false },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          attrs: { id: "close_popup" },
          on: { click: _vm.closePopup },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.header))]),
      ]),
      _vm._v(" "),
      _vm._l(_vm.days, function (day) {
        return _c(
          "div",
          {},
          [
            _c("strong", [_vm._v(_vm._s(_vm.dayToLabel(day)))]),
            _vm._v(":\n        "),
            _c("event-time-editor", {
              staticClass: "margin-15-bottom",
              attrs: { value: _vm.advancedTimeSchedule[day] },
              on: {
                input: function ($event) {
                  return _vm.timeChanged(day, $event)
                },
              },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          { staticClass: "btn btn-mm", on: { click: _vm.closePopup } },
          [_vm._v("Cancel")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-green save-button",
            attrs: { disabled: !_vm.allowSave() },
            on: { click: _vm.save },
          },
          [_vm._v("\n            OK\n        ")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }