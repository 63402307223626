<template>
    <div>
        <div v-if="!answer.answers_checked">
            <span v-html="answersWithLinks[0]" class="render-location-answer__text"></span>
        </div>
        <div v-else>
            <p
                    v-for="checkedAnswer in answersWithLinks"
                    :key="checkedAnswer"
            ><i class="fa fa-check"></i> {{ checkedAnswer }}</p>
        </div>
        <div v-if="commentWithLinks" class="render-location-answer__text">
            <span v-html="commentWithLinks"></span>
        </div>
        <div v-if="isEmpty"><i>*no content*</i></div>
    </div>
</template>

<script>
    import Autolinker from 'autolinker';

    export default {
        name: 'DiaryLogAnswer',
        props: {
            answer: Object,
        },
        computed: {
            isEmpty() {
                if (this.answer?.comment) {
                    return false;
                } else if (this.answer?.answers?.length && this.answer?.answers[0]) {
                    return false;
                }
                return true;
            },
            answersWithLinks() {
                const res = [];
                for (let answer of (this.answer?.answers || [])) {
                    res.push(Autolinker.link(answer, { truncate: 30 }));
                }
                return res;
            },
            commentWithLinks() {
                if (this.answer?.comment) {
                    return Autolinker.link(this.answer.comment, { truncate: 30 });
                }
            }
        },
        mounted() {
        },
        methods: {
            async viewFilledForm() {
                this.$emit('viewFilledForm');
            }
        }
    };
</script>

<style scoped lang="scss">
  .render-location-answer {
    &__text {
      &::v-deep a {
        font-weight: bold !important;
      }
    }
  }
</style>
