/**
 * Created by orion on 04.06.17.
 */
let _ = require('lodash');
import createPostPopupController from './create_post_popup/controller';

createPostFactory.$inject = ['$timeout', 'simplePopupFactory', 'notifications', '$rootScope', '$log', '$q', 'addMedia', 'stickersFactory'];

function createPostFactory($timeout, simplePopupFactory, notifications, $rootScope, $log, $q, addMedia, stickersFactory) {

    let result_container;

    let DEFERED;

    function reset_result_container() {
        result_container = {
            media_items: [],
            text: '',
            is_header: false,
            sticker: undefined,
            emotion: undefined,
            additional_option: undefined
        };
    }

    function set_result_container_from_post(post) {
        result_container = {
            media_items: post.media_attachment ? [post.media_attachment] : [],
            text: post.text,
            emotion: post.emotion,
            is_header: post.is_header,
            sticker: post.sticker ? stickersFactory.get_sticker_by_code(post.sticker) : undefined
        };
    }

    let DEFAULT_POPUP_CONFIG = {
        max_media_count: 1,
        allow_header: false,
        header: 'Add Post',
        button_no: 'Cancel',
        button_yes: 'Add',
        allow_emotion: false,
    };

    let _config = {};


    reset_result_container();

    return {
        create_post_through_popup,
        edit_post_through_popup
    };

    function create_post_through_popup(popup_config = {}) {
        /**
         * @param popup_config {Object}  see DEFAULT_POPUP_CONFIG for sample
         * @return {Object}
         * {
         * text:{String},
         * is_header:{boolean}
         * media_item_id_list:{Array} of {Number} - list of media id to add
         * sticker:{string} - code of sticker
         * additional_option?: {object} {name:string, text:string, value: boolean}
         * }
         */
        DEFERED = $q.defer();
        reset_result_container();
        _config = _.merge({}, DEFAULT_POPUP_CONFIG, popup_config);
        show_create_popup();
        return DEFERED.promise;
    }

    function edit_post_through_popup(post, popup_config = {}) {
        /**
         * @param post {Object} - storyLikePost. fields: [media_attachment, text, is_header, sticker]
         * @param popup_config {Object}  see DEFAULT_POPUP_CONFIG for sample
         * @return {Object}
         * {
         * text:{String},
         * is_header:{boolean}
         * media_item_id_list:{Array} of {Number} - list of media id to add
         * sticker:{string} - code of sticker
         * }
         */
        DEFERED = $q.defer();
        set_result_container_from_post(post);
        _config = _.merge({}, DEFAULT_POPUP_CONFIG, {header: 'Edit Post'}, popup_config);
        show_create_popup();
        return DEFERED.promise;
    }


    function show_create_popup() {
        let res = simplePopupFactory.create_popup(createPostPopupController, require('./create_post_popup/template.html'),
            {
                result_container,
                config: _config,
            }
        );
        res.then(function (res) {
            /**
             * @param Object res {type:string='add_media'|'save',data:Object}
             */

            if (res.type === 'add_media') {
                result_container = res.data;
                show_add_media_menu();
            }
            if (res.type === 'add_sticker') {
                result_container = res.data;
                show_add_sticker_menu();
            }
            if (res.type === 'create') {
                let entry_data = {};
                entry_data.text = res.data.text;
                entry_data.is_header = res.data.is_header;
                entry_data.emotion = res.data.emotion;
                entry_data.media_items = res.data.media_items;
                entry_data.media_item_id_list = res.data.media_items.map(mi => mi.pk);
                if (res.data.sticker) {
                    entry_data.sticker = stickersFactory.get_sticker_code(res.data.sticker);
                }
                if (res.data.additional_option) {
                    entry_data[res.data.additional_option.name] = res.data.additional_option.value;
                }
                DEFERED.resolve(entry_data);
            }
        }, function (reason) {
            DEFERED.reject(reason);
        });
    }

    function show_add_sticker_menu() {
        stickersFactory.select_sticker().then(function (sticker) {
            result_container.sticker = sticker;
            show_create_popup();
        }, show_create_popup);
    }

    function show_add_media_menu() {
        addMedia.add_media(
            {
                allow_add_from_library: true,
                max_count: _config.max_media_count,
                types: ['video', 'audio', 'image', 'document'],
                popup_header: 'What sort of media would you like to add?'
            },
            {
                default_callback: add_media_items,
                cancel_callback: show_create_popup
            }
        );
    }

    function add_media_items(items_list) {
        result_container.media_items = _.concat(result_container.media_items, items_list);
        show_create_popup();
    }

}

export default createPostFactory;