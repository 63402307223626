var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _vm.sticker
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12 text-center" }, [
            _c(
              "div",
              {
                staticClass: "create_like_story_post_popup__sticker_container",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "create_like_story_post_popup__remove-sticker-cross",
                    on: { click: _vm.removeSticker },
                  },
                  [_vm._v("x")]
                ),
                _vm._v(" "),
                _c("render-sticker", { attrs: { sticker: _vm.sticker } }),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.emotion
      ? _c("div", { staticClass: "row margin-15-top" }, [
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c("entry-emotion", { attrs: { emotion: _vm.emotion } }),
              _vm._v(" "),
              _c("i", {
                staticClass: "fa fa-times margin-5-left pointer",
                staticStyle: {
                  "vertical-align": "top",
                  "font-size": "22px",
                  color: "#8e8e8e",
                },
                on: {
                  click: function ($event) {
                    return _vm.resetEmotion()
                  },
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.text,
              expression: "text",
            },
          ],
          staticClass: "form-control modal-text-input-textarea",
          attrs: { rows: "5" },
          domProps: { value: _vm.text },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.text = $event.target.value
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row smile-panel" }, [
      _c(
        "div",
        { staticClass: "col-sm-6 add_activity_input_form__button_panel" },
        [
          _c(
            "div",
            {
              staticClass: "add_activity_input_form__attach_button green",
              class: { disabled: _vm.isMediaLimitReached },
              attrs: {
                role: "button",
                "aria-label": "Add Media",
                tabindex: "0",
              },
              on: {
                click: _vm.addMedia,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.addMedia.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { staticClass: "fa fa-film vertical-middle" }),
              _vm._v(" "),
              _c("i", { staticClass: "fab fa-itunes-note vertical-middle" }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "add_activity_input_form__attach_button blue",
              attrs: {
                role: "button",
                "aria-label": "Add Sticker",
                tabindex: "0",
              },
              on: {
                click: function ($event) {
                  return _vm.addSticker()
                },
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.addSticker()
                },
              },
            },
            [
              _c("i", { staticClass: "far fa-thumbs-up vertical-middle" }),
              _vm._v(" "),
              _c("i", { staticClass: "far fa-heart vertical-middle" }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "add_activity_input_form__attach_button yellow",
              attrs: {
                role: "button",
                "aria-label": "Add Emotion",
                tabindex: "0",
              },
              on: {
                click: function ($event) {
                  return _vm.addEmotion()
                },
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.addEmotion()
                },
              },
            },
            [
              _c("i", { staticClass: "far fa-smile-beam vertical-middle" }),
              _vm._v(" "),
              _c("i", { staticClass: "far fa-frown vertical-middle" }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6" }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-12" },
        [
          _vm._l(_vm.visibleMediaItems, function (postItem, i) {
            return _c(
              "div",
              {
                staticStyle: {
                  display: "inline-block",
                  "margin-top": "5px",
                  "margin-left": "3px",
                  position: "relative",
                  "vertical-align": "top",
                },
              },
              [
                i === _vm.visibleMediaItems.length - 1 &&
                _vm.countOfInvisibleMediaItems
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "create_like_story_post_popup__additional_count_indicator",
                        style: _vm.invisibleCounterStyle,
                      },
                      [
                        _vm._v(
                          "\n                    +" +
                            _vm._s(_vm.countOfInvisibleMediaItems) +
                            "\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("media-preview", {
                  attrs: {
                    "allow-delete": true,
                    "allow-edit": false,
                    item: postItem.mediaAttachment,
                    "no-border": true,
                  },
                  on: {
                    onDelete: function ($event) {
                      return _vm.deletePostItem(postItem)
                    },
                    onEdit: function ($event) {
                      return _vm.editPostItem(postItem)
                    },
                  },
                }),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c("div", {
            staticStyle: {
              display: "inline-block",
              width: "100%",
              height: "1px",
            },
          }),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    !!_vm.countOfInvisibleMediaItems && !_vm.showAllPostItems
      ? _c("div", { staticClass: "row text-right" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "a",
              {
                attrs: { href: "javascript:;" },
                on: { click: _vm.expandPostItems },
              },
              [_vm._v("+ Show All")]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.allowShareWithCircle,
            expression: "allowShareWithCircle",
          },
        ],
        staticClass: "row",
        staticStyle: { "margin-top": "5px" },
        attrs: { id: "save_to_diary_toggle" },
      },
      [
        _c("div", { staticClass: "col-sm-12" }, [
          _c(
            "div",
            { staticClass: "inline-block" },
            [
              _c("vue-switch-with-label", {
                attrs: { label: _vm.shareLabel, name: "saveToDiary" },
                model: {
                  value: _vm.saveToDiary,
                  callback: function ($$v) {
                    _vm.saveToDiary = $$v
                  },
                  expression: "saveToDiary",
                },
              }),
            ],
            1
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canPostInOtherDiaries,
                expression: "canPostInOtherDiaries",
              },
            ],
            staticClass: "row",
            staticStyle: { "margin-top": "5px" },
            attrs: { id: "otherUsersEntry" },
          },
          [
            _c("div", { staticClass: "col-sm-12" }, [
              _c(
                "div",
                { staticClass: "inline-block" },
                [
                  _c("vue-switch-with-label", {
                    attrs: {
                      value: _vm.addToSuDiaries,
                      label: "Add to user Diaries",
                      name: "switchAddToSuDiaries",
                    },
                    on: { input: _vm.switchAddToSuDiaries },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row margin-15-top" }, [
      _c(
        "div",
        { staticClass: "col-sm-12" },
        [
          _c("create-blog-entry-popup-users-list", {
            attrs: {
              "users-list": _vm.usersToAddToDiary,
              "all-users-selected": _vm.allUsersSelected,
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    !_vm.allowShareWithCircle && !_vm.membershipStatus.owner_has_access_to_event
      ? _c("div", { staticClass: "margin-5-top" }, [
          _c("strong", [_vm._v(_vm._s(_vm.calendarOwner.name))]),
          _vm._v(
            " will not see this Entry as they do not have access to this Event.\n    "
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-12", staticStyle: { height: "32px" } }, [
        _c("span", { staticClass: "align-helper" }),
        _vm._v(" "),
        _c("label", { staticStyle: { "vertical-align": "bottom" } }, [
          _vm._v("Add text entry below..."),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }