<template>
    <div class="chat-style-comment-linked-slide">
        <span class="chat-style-comment-linked-slide__text"
              :class="{'chat-style-comment-linked-slide__text_white': moveAvatarToRight}">
            <strong>
                <simple-link :target="comment.userprofile"></simple-link>
            </strong> has linked
            <strong v-if="!wikiSlide.accessible">Wiki slide</strong>
            <strong v-else>
                <a :href="wikiSlide.link | backHrefQuery">Wiki slide</a>
            </strong>:
        </span>
        <br/>
        <div>
            <render-quote :limit-size="true" :quote-border-color="moveAvatarToRight?'#fff':undefined">
                <wiki-detail-page-slide-header
                        :slide="wikiSlide.data"
                        :height="50"
                ></wiki-detail-page-slide-header>
                <vue-inline-media
                        v-if="wikiSlide.data.media_content"
                        :item="wikiSlide.data.media_content"
                        :lazy-load="true"
                        :key="wikiSlide.id"
                ></vue-inline-media>
                <wiki-detail-page-slide-description
                        :slide="wikiSlide.data"
                        :height="80"
                ></wiki-detail-page-slide-description>
            </render-quote>
        </div>
    </div>
</template>

<script>
    import RenderQuote from "shared/quote_directive/renderQuote";
    import WikiDetailPageSlideHeader from "../../wiki_page/WikiDetailPageSlideHeader";
    import WikiDetailPageSlideDescription from "../../wiki_page/WikiDetailPageSlideDescription";
    import VueInlineMedia from "../../view_media/vue_inline_media_render_directive/VueInlineMedia";

    export default {
        name: "ChatStyleCommentLinkedWikiSlide",
        components: {VueInlineMedia, WikiDetailPageSlideDescription, WikiDetailPageSlideHeader, RenderQuote},
        props: {
            wikiSlide: Object,
            comment: Object,
            moveAvatarToRight: {
                type: Boolean,
                default: false
            }
        }
    };
</script>

<style scoped lang="scss">
  .chat-style-comment-linked-slide {
    margin: 15px;

    &__text {

      font-size: 16px;

      &_white {
        color: #fff;

        &::v-deep {
          a {
            color: #fff !important;
          }
        }
      }
    }
  }
</style>