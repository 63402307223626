<template>
    <component
            v-bind:is="item.component"
            v-if="item"
            :label="item.label"
            :image="item.image"
            :whiteImage="item.whiteImage"
            :link="item.link"
            :enlarged="item.enlarged"
            :main-color="item.mainColor"
            :white="white||item.white"
            :white-mode="item.whiteMode"
            :backgroundSize="item.backgroundSize"
            :backgroundSizeWhite="item.backgroundSizeWhite"
    ></component>
</template>

<script>
    export default {
        name: "DashboardListItemWrapper",
        props: {
            item: Object,
            white: {
                type: Boolean,
                default: false
            }
        }
    };
</script>

<style scoped>

</style>