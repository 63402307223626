<template>
    <div class="">
        <div class="alert alert-danger">
            This event was deleted or does not occur on selected date.
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default {
        name: "EventDetailErrorDeleted",
        mounted() {
            const $rootScope = Vue.getAngularModule('$rootScope');
            $rootScope.hide_dimmer();
        }
    };
</script>

<style scoped>

</style>