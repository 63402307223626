<template>
    <span v-if="slideSnapshotAfter">
        <span>added a new page </span>
        <strong>
            <wiki-log-possible-link
                :target="wikiLog.target_wiki_slide"
                :label="`#${slideSnapshotAfter.id}`"
                no-quotes
            ></wiki-log-possible-link>
        </strong>
        <span v-if="slideSnapshotAfter.section_name">
            in <strong>#{{ slideSnapshotAfter.section }}
            <wiki-log-possible-link
                :target="wikiLog.target_wiki_section"
                :label="slideSnapshotAfter.section_name"
            ></wiki-log-possible-link>
            </strong>
        </span>
         <wiki-log-image-preview v-if="wikiLog.image_preview" :wiki-log="wikiLog"></wiki-log-image-preview>
    </span>
</template>

<script>
    import SnapshotLogMixin from "./common/SnapshotLogMixin";
    import WikiLogImagePreview from "./common/WikiLogImagePreview";

    export default {
        name: "WikiSlideCreated",
        components: {WikiLogImagePreview},
        mixins: [SnapshotLogMixin]
    };
</script>

<style scoped>

</style>