import urls from 'DjangoUrls';

let {DjangoUrls} = urls;
GroupInviteResource.$inject = ['$http'];

function GroupInviteResource($http) {
    return {
        request_invite,
        reg_by_invite,
        join_as_existed_user,
        reject_invitation,
        invite_by_email,

    };

    function request_invite(target_id, data) {
        const url = DjangoUrls["api.v1:group_invite-request-invite"](target_id);
        return $http({
            method: 'POST',
            url,
            data
        });
    }

    function reg_by_invite(target_id, data) {
        const url = DjangoUrls[`api.v1:group_invite-reg-by-invite`](target_id);
        return $http({
            method: 'POST',
            url,
            data
        });
    }

    function join_as_existed_user(target_id, invitation_code) {
        const url = DjangoUrls["api.v1:group_invite-join-as-existed-user"](target_id);
        return $http({
            method: 'POST',
            url,
            data: {invitation_code}
        });
    }

    function reject_invitation(target_id, invitation_code) {
        const url = DjangoUrls["api.v1:group_invite-reject-invitation"](target_id);
        return $http({
            method: 'POST',
            url,
            data: {invitation_code}
        });
    }
    function invite_by_email(target_id, data) {
        const url = DjangoUrls["api.v1:group_invite-invite-by-email"](target_id);
        return $http({
            method: 'POST',
            url,
            data
        });
    }
}

export default GroupInviteResource;

