ResetPasswordController.$inject = ['$preloaded', 'AuthResource', 'notifications', '$rootScope'];

function ResetPasswordController($preloaded, AuthResource, notifications, $rootScope) {

    let self = this;

    self.username_or_email = '';

    const
        INPUT_DATA = 'input_data',
        SELECT_USER_FROM_LIST = 'select_user_from_list',
        RESET_SUCCESS_EMAIL = 'reset_success_email',
        RESET_SUCCESS_AH_OR_BUDDY = 'reset_success_ah_or_buddy',
        RESET_ERROR = 'reset_error',
        CONFIRM_RESET = 'confirm_reset';

    self.current_view_type = INPUT_DATA; // input_data , select_user_from_list , confirm_reset , reset_success_email , reset_success_ah_or_buddy, reset_error

    self.not_found_error = '';
    self.found_profiles = [];
    self.profile_for_reset = undefined;

    function init() {
        self.profile = $preloaded.profile;
        if (self.profile) {
            self.username_or_email = self.profile.name;
        }
    }

    init();

    function isValidEmail(rawEmail) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(rawEmail);
    }

    self.select_for_reset = function (profile) {
        self.profile_for_reset = profile;
        self.current_view_type = CONFIRM_RESET;
    };

    self.reset_password_confirm = function () {
        $rootScope.show_dimmer();
        AuthResource.password_reset_request(self.profile_for_reset.id)
            .then(resp => {
                switch (resp.data.result) {
                    case 'email_sent': {
                        self.current_view_type = RESET_SUCCESS_EMAIL;
                        self.hidden_email = resp.data.hidden_email;
                        break;
                    }
                    case 'ah_task_created': {
                        self.current_view_type = RESET_SUCCESS_AH_OR_BUDDY;
                        break;
                    }
                    case 'fail_no_ah_no_email': {
                        self.current_view_type = RESET_ERROR;
                        break;
                    }
                }
            }, err => {
                notifications.error(err || 'Error');
            })
            .finally($rootScope.hide_dimmer)
    };


    self.checkUsersWithProvidedData = function () {
        const is_email = isValidEmail(self.username_or_email);
        let requestData;
        if (is_email) {
            requestData = {email: self.username_or_email};
        } else {
            requestData = {username: self.username_or_email};
        }

        $rootScope.show_dimmer();
        self.not_found_error = '';
        self.found_profiles = [];

        AuthResource.query_users(requestData)
            .then(resp => {
                switch (resp.data.result) {
                    case 'not_found': {
                        self.not_found_error = `User with this ${is_email ? 'email' : 'username'} not found`;
                        break;
                    }
                    case 'found_one': {
                        self.profile_for_reset = resp.data.profile;
                        self.current_view_type = CONFIRM_RESET;
                        break;
                    }
                    case 'found_multiple': {
                        self.found_profiles = resp.data.profiles;
                        self.current_view_type = SELECT_USER_FROM_LIST;
                        break;
                    }
                }
            }, err => {
                notifications.error(err || 'Error');
            })
            .finally($rootScope.hide_dimmer)
    }

}

export default ResetPasswordController