import {
    EVENT_PERIODS,
    getPeriodTextRepresentation,
    getWeekdayInMonthNumber,
    isLastWeekdayInMonth
} from "../new_calendar/common";
import moment from "moment";
import {VISUAL_DATE_FORMAT} from "../new_calendar/config";

const dateParser = strVal => {
    if (!strVal)
        return;
    return moment.utc(strVal, VISUAL_DATE_FORMAT).unix() * 1000; //.add(moment().utcOffset(), 'm')
};

export default {
    data() {
        return {
            dateParser,
            periodicEventTermination: 'never',
            periodicEventAmountBeforeTermination: 2,
            periodicEventTerminationUnit: 'times',
            repeatOption: EVENT_PERIODS.DAILY,
            periodEndDate: undefined,
            skipRepeatPeriod: undefined,
            multiDaysSelected: [],
        };
    },
    computed: {
        multiDaysOptions() {
            return [
                {value: 7, label: 'Sunday'},
                {value: 1, label: 'Monday'},
                {value: 2, label: 'Tuesday'},
                {value: 3, label: 'Wednesday'},
                {value: 4, label: 'Thursday'},
                {value: 5, label: 'Friday'},
                {value: 6, label: 'Saturday'},
            ];
        },
        showDaysOfWeekSelector() {
            return this.periodicEvent && this.repeatOption === EVENT_PERIODS.WEEKLY_BY_DAY_NUMBERS;
        },
        showSkipRepeatPeriodSelector() {
            return this.periodicEvent && this.repeatOption === EVENT_PERIODS.ONCE_EVERY_N_WEEKS;
        },
        skipPeriodOptions() {
            const options = [];
            for (let i = 2; i < 100; i++) {
                options.push({value: i, text: i});
            }
            return options;
        },
        afterAmountOptions() {
            const options = [2, 3, 4, 5, 6, 7, 8, 9];
            if (this.periodicEventTerminationUnit !== 'times') {
                options.unshift(1);
            }
            return options;
        },
        calculatedEndDate() {
            if (this.periodicEventTermination !== 'after') {
                return;
            }
            const clonedDate = this.startDateMoment.clone();
            if (this.periodicEventTerminationUnit === 'months') {
                return clonedDate.add(this.periodicEventAmountBeforeTermination, 'M');
            }
            if (this.periodicEventTerminationUnit === 'weeks') {
                return clonedDate.add(this.periodicEventAmountBeforeTermination, 'w');
            }
            if (this.periodicEventTerminationUnit === 'times') {
                if (this.repeatOption === EVENT_PERIODS.DAILY) {
                    return clonedDate.add(this.periodicEventAmountBeforeTermination - 1, 'd');
                }
                if (this.repeatOption === EVENT_PERIODS.ANNUALLY_BY_DAY_AND_MONTH_NUMBER) {
                    return clonedDate.add(this.periodicEventAmountBeforeTermination - 1, 'y');
                }
                if (this.repeatOption === EVENT_PERIODS.MONTHLY_BY_DAY_NUMBER) {
                    return clonedDate.add(this.periodicEventAmountBeforeTermination - 1, 'M');
                }
                if (this.repeatOption === EVENT_PERIODS.WEEKLY_BY_DAY_NUMBER) {
                    return clonedDate.add(this.periodicEventAmountBeforeTermination - 1, 'w');
                }
                if (this.repeatOption === EVENT_PERIODS.ONCE_EVERY_N_WEEKS) {
                    return clonedDate.add((this.periodicEventAmountBeforeTermination-1) * this.skipRepeatPeriod, 'w');
                }
                if (this.repeatOption === EVENT_PERIODS.WEEKDAY) {
                    const isWeekday = d => d.isoWeekday() < 6;
                    let daysToAdd = this.periodicEventAmountBeforeTermination - 1;
                    while (daysToAdd > 0) {
                        if (isWeekday(clonedDate.add(1, 'd'))) {
                            daysToAdd--;
                        }
                    }
                    return clonedDate;
                }
                if (this.repeatOption === EVENT_PERIODS.WEEKEND) {
                    const isWeekday = d => d.isoWeekday() < 6;
                    let daysToAdd = this.periodicEventAmountBeforeTermination - 1;
                    while (daysToAdd > 0) {
                        if (!isWeekday(clonedDate.add(1, 'd'))) {
                            daysToAdd--;
                        }
                    }
                    return clonedDate;
                }
                if (this.repeatOption === EVENT_PERIODS.MONTHLY_BY_WEEKDAY_NUMBER) {
                    const weekday = clonedDate.isoWeekday();
                    const weekdayNumber = getWeekdayInMonthNumber(clonedDate);
                    clonedDate.date(1);
                    clonedDate.add(this.periodicEventAmountBeforeTermination - 1, 'M');
                    while (clonedDate.isoWeekday() !== weekday) {
                        clonedDate.add(1, 'd');
                    }
                    while (getWeekdayInMonthNumber(clonedDate) !== weekdayNumber) {
                        clonedDate.add(1, 'w');
                    }
                    return clonedDate;
                }
                if (this.repeatOption === EVENT_PERIODS.MONTHLY_LAST_WEEKDAY) {
                    const weekday = clonedDate.isoWeekday();
                    clonedDate.add(this.periodicEventAmountBeforeTermination - 1, 'M');
                    clonedDate.date(clonedDate.daysInMonth());
                    while (clonedDate.isoWeekday() !== weekday) {
                        clonedDate.subtract(1, 'd');
                    }
                    return clonedDate;
                }
                if (this.repeatOption === EVENT_PERIODS.WEEKLY_BY_DAY_NUMBERS) {
                    let restOccurrencesCount = this.periodicEventAmountBeforeTermination;
                    if (this.multiDaysSelected.length === 0) {
                        return;
                    }
                    while (true) {
                        if (this.multiDaysSelected.includes(clonedDate.isoWeekday())) {
                            restOccurrencesCount--;
                        }
                        if (restOccurrencesCount === 0) {
                            return clonedDate;
                        }
                        clonedDate.add(1, 'd');
                    }
                }
            }
        },
        calculatedEndDateFormatted() {
            if (!this.calculatedEndDate) {
                return;
            }
            return this.calculatedEndDate.format(VISUAL_DATE_FORMAT);
        },

        periodEndDateMoment() {
            return moment(this.periodEndDate, VISUAL_DATE_FORMAT);
        },

        isPeriodEndDateValid() {
            return this.periodEndDateMoment.isValid();
        },

        repeatOptions() {
            if (!this.startDateMoment) {
                return [];
            }
            const options = [
                {code: EVENT_PERIODS.DAILY},
            ];
            if (this.startDateMoment.isoWeekday() < 6) {
                options.push({code: EVENT_PERIODS.WEEKDAY});
            } else {
                options.push({code: EVENT_PERIODS.WEEKEND});
            }

            options.push({code: EVENT_PERIODS.WEEKLY_BY_DAY_NUMBER});
            options.push({code: EVENT_PERIODS.ONCE_EVERY_N_WEEKS});
            options.push({code: EVENT_PERIODS.WEEKLY_BY_DAY_NUMBERS});
            options.push({
                code: EVENT_PERIODS.MONTHLY_BY_DAY_NUMBER
            });
            options.push({
                code: EVENT_PERIODS.ANNUALLY_BY_DAY_AND_MONTH_NUMBER
            });
            if (getWeekdayInMonthNumber(this.startDateMoment) < 5) {
                options.push({
                    code: EVENT_PERIODS.MONTHLY_BY_WEEKDAY_NUMBER // e.g. 2nd Thursday
                });
            }
            if (isLastWeekdayInMonth(this.startDateMoment)) {
                options.push({
                    code: EVENT_PERIODS.MONTHLY_LAST_WEEKDAY // e.g. last Thursday
                });
            }

            for (let i in options) {
                if (!options[i].label) {
                    options[i].label = getPeriodTextRepresentation(this.startDate, options[i].code);
                }
            }
            return options;
        },
        repeatUnits() {
            const options = [
                {code: 'times', label: 'Times'}
            ];
            if (this.repeatOption !== EVENT_PERIODS.MONTHLY_BY_DAY_NUMBER) {
                options.push({code: 'weeks', label: 'Weeks'});
            }
            if (this.repeatOption !== EVENT_PERIODS.ANNUALLY_BY_DAY_AND_MONTH_NUMBER) {
                options.push({code: 'months', label: 'Months'});
            }
            return options;
        }
    },
    methods: {
        showPeriodEndPicker() {
            this.$refs['period-end-picker'].toggle();
        },
        showStartPicker() {
            this.$refs['start-picker'].toggle();
        },
    },
    watch: {
        periodicEventTerminationUnit(n) {
            if (n === 'times' && this.periodicEventAmountBeforeTermination === 1) {
                this.periodicEventAmountBeforeTermination++;
            }
        },
        repeatOption(n) {
            if (n === 'annually' && this.periodicEventTerminationUnit !== 'times') {
                this.periodicEventTerminationUnit = 'times';
            }
            if (n === 'monthly' && this.periodicEventTerminationUnit === 'weeks') {
                this.periodicEventTerminationUnit = 'times';
            }
        },
        startDate(n) {
            if (this.repeatOption !== EVENT_PERIODS.WEEKEND && this.repeatOption !== EVENT_PERIODS.WEEKDAY) {
                return;
            }
            if (this.startDateMoment.isoWeekday() < 6) {
                if (this.repeatOption === EVENT_PERIODS.WEEKEND) {
                    this.repeatOption = EVENT_PERIODS.WEEKDAY;
                }
            } else {
                if (this.repeatOption === EVENT_PERIODS.WEEKDAY) {
                    this.repeatOption = EVENT_PERIODS.WEEKEND;
                }
            }
        }
    }

};