var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "goal-task-assignee-and-time",
      class: {
        "restrict-width":
          (!_vm.inChild && !_vm.task.uses_default_avatar) ||
          _vm.task.uses_generated_avatar,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "goal-task-assignee-and-time__assignee-part",
          class: {
            "goal-task-assignee-and-time__assignee-part_with-assignee":
              this.task.assignee,
          },
        },
        [
          _vm._v("\n        Who's doing it?\n        "),
          _c("div", { staticClass: "inline-block" }, [
            _c("div", { staticClass: "inline-block" }, [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value:
                        _vm.isEditable && _vm.editMode
                          ? "Edit Assignee"
                          : undefined,
                      expression:
                        "isEditable&&editMode?'Edit Assignee':undefined",
                    },
                  ],
                  class: { pointer: _vm.isAssigneeEditable && _vm.editMode },
                  on: { click: _vm.setAssignee },
                },
                [
                  !_vm.task.assignee
                    ? _c(
                        "span",
                        { staticStyle: { "text-decoration": "underline" } },
                        [_vm._v("Unassigned")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !!_vm.task.assignee
                    ? _c(
                        "span",
                        { staticStyle: { "text-decoration": "underline" } },
                        [_vm._v(_vm._s(_vm.task.assignee.name))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isAssigneeEditable && _vm.editMode,
                        expression: "isAssigneeEditable&&editMode",
                      },
                    ],
                    staticClass: "fal fa-pencil",
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.task.assignee
              ? _c("div", { staticClass: "inline-block" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.assigneeName,
                          expression: "assigneeName",
                        },
                      ],
                      staticClass: "goal-task-assignee-and-time__assignee",
                      class: {
                        pointer: _vm.isAssigneeEditable && _vm.editMode,
                      },
                      on: { click: _vm.setAssignee },
                    },
                    [
                      _c("img", {
                        staticClass: "img-circle",
                        attrs: {
                          src: _vm.assigneeAvatar,
                          alt: "",
                          width: "34",
                          height: "34",
                        },
                      }),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "goal-task-assignee-and-time__time-part",
          class: {
            "goal-task-assignee-and-time__time-part_with-assignee":
              this.task.assignee,
          },
        },
        [
          _c("span", { staticClass: "vertical-middle" }, [_vm._v("When?")]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "goal-task-assignee-and-time__time vertical-middle",
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value:
                        _vm.isEditable && _vm.editMode
                          ? "Edit Task Dates"
                          : undefined,
                      expression:
                        "isEditable&&editMode?'Edit Task Dates':undefined",
                    },
                  ],
                  staticClass: "vertical-middle",
                  class: { pointer: _vm.isEditable && _vm.editMode },
                  staticStyle: { "text-decoration": "underline" },
                  on: { click: _vm.editDates },
                },
                [_vm._v(_vm._s(_vm.timeLabel))]
              ),
              _vm._v(" "),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isEditable && _vm.editMode,
                    expression: "isEditable&&editMode",
                  },
                ],
                staticClass: "fal fa-pencil",
              }),
              _vm._v(" "),
              _vm._t("additional_counters"),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }