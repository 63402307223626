var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "render-post-component",
      staticStyle: { "max-height": "inherit" },
    },
    [
      _vm.post.sticker
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-12 text-center" },
              [_c("render-sticker", { attrs: { sticker: _vm.post.sticker } })],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.post.text
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c(
                "div",
                {
                  staticClass: "render-post-component__text-container",
                  class: {
                    "with-border":
                      (_vm.addBorderAroundText ===
                        _vm.BORDER_MODE.WITH_OTHER_CONTENT &&
                        (_vm.post.media_attachment || _vm.post.sticker)) ||
                      _vm.addBorderAroundText === _vm.BORDER_MODE.ALWAYS,
                  },
                },
                [
                  !_vm.post.is_header
                    ? _c("p", {
                        staticStyle: {
                          "text-align": "left",
                          "font-size": "18px",
                          "white-space": "pre-line",
                        },
                        domProps: { innerHTML: _vm._s(_vm.linkedText) },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.post.is_header
                    ? _c("p", {
                        staticStyle: {
                          "font-size": "24px",
                          "white-space": "pre-line",
                        },
                        domProps: { innerHTML: _vm._s(_vm.linkedText) },
                      })
                    : _vm._e(),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.post.media_attachment
        ? _c(
            "div",
            { staticClass: "row", staticStyle: { "max-height": "inherit" } },
            [
              _c(
                "div",
                {
                  staticClass: "col-sm-12",
                  staticStyle: { "max-height": "inherit" },
                  style: _vm.mediaContainerStyle,
                },
                [
                  _c(
                    "vue-inline-media",
                    _vm._b(
                      {
                        attrs: {
                          item: _vm.post.media_attachment,
                          "max-height": _vm.maxMediaHeight,
                        },
                      },
                      "vue-inline-media",
                      _vm.$attrs,
                      false
                    )
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.post.emotion
        ? _c("div", { staticClass: "row" }, [
            _vm.post.sticker || _vm.post.text
              ? _c("hr", { staticStyle: { margin: "10px 20px" } })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-sm-12 text-right",
                staticStyle: { "padding-right": "30px" },
              },
              [
                _c("entry-emotion", {
                  attrs: { size: 36, emotion: _vm.post.emotion },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }