var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      staticClass: "edd-emotion-popup",
      attrs: { "aria-label": _vm.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c(
            "h3",
            { staticClass: "modal-title", attrs: { id: "modal-header" } },
            [_vm._v(_vm._s(_vm.header))]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "col-xs-12 edd-emotion-popup__emotions-container",
            attrs: { "aria-label": "Select emotion from list.", role: "menu" },
          },
          _vm._l(_vm.availableIcons, function (icon) {
            return _c(
              "div",
              {
                key: icon.code,
                ref: "emotion",
                refInFor: true,
                staticClass: "edd-emotion-popup__emotion",
                class: {
                  selected: icon === _vm.selectedIcon,
                  inactive: _vm.selectedIcon && icon !== _vm.selectedIcon,
                },
                attrs: {
                  "aria-label": icon.description,
                  tabindex: "0",
                  role: "menuitemradio",
                  "aria-checked": icon === _vm.selectedIcon ? "true" : "false",
                },
                on: {
                  click: function ($event) {
                    return _vm.selectIcon(icon)
                  },
                  keydown: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.selectIcon(icon)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "space", 32, $event.key, [
                          " ",
                          "Spacebar",
                        ])
                      )
                        return null
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.selectIcon(icon)
                    },
                  ],
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "edd-emotion-popup__emotion__icon",
                    attrs: { "aria-hidden": "true" },
                  },
                  [
                    _c("i", {
                      staticClass: "fal",
                      class: icon.iconClass,
                      attrs: { "aria-label": icon.description },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "edd-emotion-popup__emotion__label",
                    attrs: { "aria-hidden": "true" },
                  },
                  [_vm._v(_vm._s(icon.description))]
                ),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "button",
              { staticClass: "btn btn-mm", on: { click: _vm.close } },
              [_vm._v("Cancel\n                ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "inline-block",
                attrs: { "data-role": "trigger" },
              },
              [
                _c(
                  "button",
                  {
                    ref: "saveButton",
                    staticClass: "btn btn-mm btn-green",
                    attrs: {
                      disabled: !_vm.isSaveButtonEnabled,
                      "aria-disabled": !_vm.isSaveButtonEnabled,
                    },
                    on: { click: _vm.addFeeling },
                  },
                  [_vm._v("Add Feeling\n                    ")]
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "span",
              {
                staticClass: "focus-catch-link",
                attrs: { tabindex: "0", "aria-hidden": "true" },
                on: { focus: _vm.focusToClose },
              },
              [_vm._v(" ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }