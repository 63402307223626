var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "event-linked-entry-preview" }, [
    _c("div", { staticClass: "event-linked-entry-preview__text" }, [
      _vm.notePreview.text
        ? _c("div", {}, [
            _c("i", { staticClass: "fal fa-quote-left" }),
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("cutName")(_vm.notePreview.text, 180)) +
                "\n            "
            ),
            _c("i", { staticClass: "fal fa-quote-right" }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "event-linked-entry-preview__text__author" }, [
        _vm._v("\n            In "),
        _c("strong", [_vm._v(_vm._s(_vm.notePreview.logName))]),
        _vm._v(" Log by "),
        _vm.notePreview.author.link
          ? _c(
              "strong",
              [
                _c("simple-link", {
                  staticStyle: { color: "#333" },
                  attrs: { target: _vm.notePreview.author },
                }),
              ],
              1
            )
          : _c("strong", [_vm._v(_vm._s(_vm.notePreview.author.name))]),
        _vm._v(" on " + _vm._s(_vm.formattedDate) + ".\n            "),
        _c("strong", [
          _c("a", { attrs: { href: _vm._f("backHref")(_vm.note.link) } }, [
            _vm._v("Open Details"),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }