var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": "Capture Picture Dialog", role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.title))]),
        ],
        1
      ),
      _vm._v(" "),
      _c("video", {
        ref: "webcam",
        staticStyle: { width: "100%", height: "auto" },
        attrs: { autoplay: "", playsinline: "" },
      }),
      _vm._v(" "),
      _c("canvas", { ref: "canvas", staticClass: "d-none" }),
      _vm._v(" "),
      _c("audio", {
        ref: "snapSound",
        attrs: { src: "/static/audio/shutter.mp3", preload: "auto" },
      }),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          { staticClass: "btn btn-mm btn-primary", on: { click: _vm.back } },
          [_vm._v("Back")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            ref: "takePhotoButton",
            staticClass: "btn btn-mm btn-green",
            on: {
              click: _vm.takePhoto,
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                )
                  return null
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.focusToClose.apply(null, arguments)
              },
            },
          },
          [_vm._v("Take Photo")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }