var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.component,
    _vm._g(
      {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.tooltipText,
            expression: "tooltipText",
          },
        ],
        tag: "component",
        staticClass: "likefolder-action-button",
        class: {
          "no-text-mobile": _vm.noTextOnMobile,
          disabled: _vm.disabled,
        },
        style: _vm.folderButtonStyle,
        attrs: { target: _vm.target },
        nativeOn: {
          focus: function ($event) {
            return _vm.onFocus.apply(null, arguments)
          },
          blur: function ($event) {
            return _vm.onBlur.apply(null, arguments)
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "div",
        {
          staticClass: "likefolder-action-button-icon",
          class: [_vm.buttonColor, _vm.customIcon, _vm.buttonFocus],
          style: _vm.customIconSizeStyle,
        },
        [
          _vm._t("icon", function () {
            return [
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.iconClass,
                    expression: "iconClass",
                  },
                ],
                class: [_vm.iconClass, _vm.iconPrefix],
                style: _vm.iconStyle,
                attrs: { "aria-hidden": "true" },
              }),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "likefolder-action-button-name",
          class: {
            "no-text-mobile": _vm.noTextOnMobile,
            disabled: _vm.disabled,
          },
        },
        [
          _vm._t("text", function () {
            return [_vm._v(_vm._s(_vm.text))]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }