var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "community-page-alternative-header",
        { attrs: { "scale-by-icons-size": "", "icons-count": 1 } },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _c(
              "span",
              [
                _c("span", { staticClass: "vertical-middle" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("cutName")(
                        _vm.event ? _vm.event.event_data.name : "",
                        40
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _vm.event && _vm.event.audio_caption
                  ? _c("audio-toggle", {
                      staticClass: "vertical-middle",
                      attrs: { "audio-caption": _vm.event.audio_caption },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticStyle: {
                  "font-size": "14px",
                  display: "block",
                  "text-decoration": "none",
                  "line-height": "18px",
                  "font-weight": "normal",
                  "font-style": "normal",
                  position: "relative",
                  top: "5px",
                },
              },
              [
                _c("span", { staticClass: "inline-block" }, [
                  _vm._v(_vm._s(_vm.event ? _vm.event.created_at : "")),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticStyle: { "text-decoration": "none" },
              attrs: { to: { name: "community-events" } },
            },
            [_c("vue-folder-button", { attrs: { "icon-class": "fa-reply" } })],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded
        ? _c("div", { staticClass: "event-detail-info" }, [
            _c("div", { staticClass: "event-detail-info__grid__container" }, [
              _c(
                "div",
                {
                  staticClass: "event-detail-info__grid__info-part",
                  staticStyle: { "margin-bottom": "10px" },
                },
                [
                  _c("div", {
                    staticClass: "event-detail-info__avatar",
                    style: _vm.avatarStyle,
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "event-detail-info__start-date-label margin-15-bottom",
                    },
                    [_vm._v(_vm._s(_vm.startDateLabel))]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "event-detail-info__grid__content-part" },
                [
                  _c(
                    "div",
                    { staticClass: "event-detail-info__data" },
                    [
                      _c(
                        "div",
                        { staticClass: "event-detail-info__data__description" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "event-detail-info__data__description__time-label",
                            },
                            [
                              _vm.event.event_data.location
                                ? _c(
                                    "div",
                                    {},
                                    [
                                      _c("strong", [_vm._v("Location:")]),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.locationLabel) +
                                          "\n                                "
                                      ),
                                      _c("location-with-marker", {
                                        attrs: {
                                          location:
                                            _vm.event.event_data.location,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.event.is_periodic
                                ? _c("div", {}, [
                                    _c("strong", [_vm._v("Repeat:")]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.repeatLabel)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "color-tag-gray" },
                                      [
                                        _vm._v(
                                          "(since " +
                                            _vm._s(_vm.sinceLabel) +
                                            ")"
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", {}, [
                                _c("strong", [_vm._v("Start:")]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.startTimeLabel)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", {}, [
                                _c("strong", [_vm._v("Finish:")]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.finishTimeLabel)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm.event.event_data
                                .advanced_time_schedule_enabled
                                ? _c("div", {}, [
                                    _c("strong", [_vm._v("Schedule:")]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { "margin-left": "3px" } },
                                      _vm._l(
                                        _vm.periodicEventScheduleItems,
                                        function (scheduleItem) {
                                          return _c(
                                            "div",
                                            {
                                              class: {
                                                "color-gray":
                                                  !scheduleItem.isActive,
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    scheduleItem.weekdayLabel
                                                  ) + ":"
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(scheduleItem.timeLabel)
                                                ),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.event.event_data.description,
                                  expression: "event.event_data.description",
                                },
                              ],
                              staticClass:
                                "event-detail-info__data__description__header",
                            },
                            [_c("strong", [_vm._v("Description:")])]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "event-detail-info__data__description__text",
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.event.event_data.description) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.attachments.length,
                              expression: "attachments.length",
                            },
                          ],
                        },
                        [_c("strong", [_vm._v("Attachments:")])]
                      ),
                      _vm._v(" "),
                      _c("attachments-panel", {
                        ref: "attachments-panel",
                        attrs: {
                          "can-edit-attachments": false,
                          size: 40,
                          margin: 0,
                        },
                      }),
                      _vm._v(" "),
                      _c("add-to-outer-calendars-panel", {
                        attrs: {
                          "event-id": _vm.eventId,
                          "date-for-api": _vm.dateForApi,
                          "is-periodic": _vm.periodInfo.is_periodic,
                          "date-moment": _vm.dateMoment,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("event-detail-page-period-selector-popup", {
        ref: "add-to-calendar-type-selector",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }