<template>
    <a :href="link" class="dashboard-list-item hvr-float-shadow">
        <div class="dashboard-list-item__image" :style="imageStyle">
            <div class="dashboard-list-item__image__day-inside" v-if="!white">
                <div class="dashboard-list-item__image__day-inside__weekday-label">{{weekdayLabel}}</div>
                <div class="dashboard-list-item__image__day-inside__date-label">{{dateLabel}}</div>
            </div>
            <div class="dashboard-list-item__image__day-inside-white" v-if="white">
                <div class="dashboard-list-item__image__day-inside-white__weekday-label">{{weekdayLabel}}</div>
                <div class="dashboard-list-item__image__day-inside-white__date-label">{{dateLabel}}</div>
            </div>
        </div>
        <div class="dashboard-list-item__label">{{label}}</div>
    </a>
</template>

<script>
    import Vue from 'vue';
    import moment from 'moment';
    import {TIME_ZONE} from "../events/new_calendar/config";

    export default {
        name: "DashboardListItemCalendar",
        props: {
            label: {
                type: String,
            },
            image: {
                type: String
            },
            whiteImage: {
                type: String
            },
            white: {
                type: Boolean
            },
            link: {
                type: String
            },
            mainColor: {
                type: String
            },
            backgroundSizeWhite: {
                type: String
            }
        },
        computed: {
            todayDateMoment() {
                return moment.tz(TIME_ZONE);
            },
            weekdayLabel() {
                return this.todayDateMoment.format('ddd');
            },
            dateLabel() {
                return this.todayDateMoment.format('DD');
            },
            imageStyle() {
                if (this.white) {
                    return {
                        'background-image': `url('${this.whiteImage}')`,
                        'background-size': this.backgroundSizeWhite,
                        'background-position': 'center',
                        'background-color': this.mainColor,
                        'border-radius': '10px'
                    };
                } else {
                    return {
                        'background-image': `url('${this.image}')`,
                        'position': 'relative',
                    };
                }

            },
            avatarStyle() {
                return {};
            },
            currentUserAvatar() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                return CurrentUser.avatar;
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../styles/const";

    .dashboard-list-item {
        vertical-align: top;
        @media (max-width: 600px) {
            display: inline-block;
        }
        @media (min-width: 601px) {
            display: block;
        }

        position: relative;
        cursor: pointer;
        text-decoration: none;
        color: #333;
        @media (min-width: 1000px) {
            margin-left: 40px;
            margin-right: 40px;
            margin-top: 50px;
        }
        @media (min-width: 842px) and (max-width: 999px) {
            margin-left: 50px;
            margin-right: 50px;
            margin-top: 50px;
        }
        @media (min-width: 601px) and (max-width: 841px) {
            margin-left: 30px;
            margin-right: 30px;
            margin-top: 40px;
        }
        @media (min-width: 560px) and (max-width: 600px) {
            margin-left: 30px;
            margin-right: 30px;
            margin-top: 30px;
        }
        @media (min-width: 410px) and (max-width: 559px) {
            margin-left: 30px;
            margin-right: 30px;
            margin-top: 30px;
        }
        @media (min-width: 320px) and (max-width: 409px) {
            margin-left: 5px;
            margin-right: 5px;
            margin-top: 30px;
        }

        &__image {
            &.mobile {
                @media (min-width: 700px) {
                    display: none;
                }
            }

            &.desktop {
                @media (max-width: 699px) {
                    display: none;
                }
            }

            position: relative;
            margin: auto;
            width: 128px;
            height: 128px;
            @media (min-width: 1000px) {
                &.enlarged {
                    width: 240px;
                    height: 230px;
                }
            }
            @media (min-width: 700px) and (max-width: 999px) {
                &.enlarged {
                    width: 170px;
                    height: 165px;
                }
            }

            &__day-inside {
                height: 84px;
                width: 108px;
                position: absolute;
                top: 36px;
                left: 10px;
                background-color: white;
                border-radius: 5px;


                &__weekday-label {

                    font-size: 26px;
                    line-height: 26px;
                    padding-top: 10px;

                    text-align: center;
                    color: $red;
                }

                &__date-label {

                    font-size: 38px;
                    line-height: 38px;
                    padding-top: 4px;

                    text-align: center;
                    /*font-weight: bold;*/
                }
            }

            &__day-inside-white {
                position: absolute;
                top:27%;
                width: 100%;

                text-align: center;
                &__weekday-label {
                    font-size: 20px;
                    line-height: 24px;
                    padding-top: 10px;
                    text-align: center;
                    color: #fff;
                }

                &__date-label {
                    color: #fff;
                    font-size: 30px;
                    line-height: 30px;
                    padding-top: 4px;
                    text-align: center;
                }
            }

            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        &__label {
            text-align: center;
            font-size: 17px;
            font-weight: bolder;
        }
    }
</style>