var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.eventTooltip,
          expression: "eventTooltip",
        },
      ],
      staticClass: "c-event",
      class: _vm.eventClass,
      style: _vm.eventStyle,
      on: {
        click: _vm.onClick,
        touchstart: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.onClick.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "c-event__inner", style: _vm.innerBlockStyle }, [
        _c("div", {}, [
          _c("div", { staticClass: "c-event__info-part__name-label" }, [
            _c(
              "strong",
              {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                  touchstart: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: _vm._f("backHrefQuery")(_vm.eventLink) } },
                  [
                    _vm._v(
                      _vm._s(_vm._f("cutName")(_vm.event.event_data.name, 32))
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", {}, [
          _c("div", { staticClass: "c-event__avatar-part" }, [
            _c(
              "div",
              { staticClass: "c-event__avatar", style: _vm.avatarStyle },
              [
                _vm.event.is_periodic
                  ? _c("i", {
                      staticClass:
                        "fa fa-repeat c-event__avatar__periodic-mark",
                    })
                  : _vm._e(),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "c-event__info-part" }, [
            _vm.event.event_data.audio_caption
              ? _c(
                  "div",
                  {
                    staticClass: "inline-block vertical-top",
                    staticStyle: { "min-width": "27px" },
                  },
                  [
                    _c("audio-toggle", {
                      staticStyle: {
                        "font-size": "25px",
                        "vertical-align": "top",
                        "margin-top": "3px",
                        display: "inline-block",
                      },
                      attrs: {
                        "audio-caption": _vm.event.event_data.audio_caption,
                        "fa-class": "fal",
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "inline-block vertical-top",
                staticStyle: { "margin-top": "8px" },
                style: _vm.iconsBlockStyle,
              },
              [
                _vm.event.comments_count > 0
                  ? _c(
                      "div",
                      { staticClass: "inline-block c-event__counter-icon" },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "c-event__counter-icon__text" },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.event.comments_count) +
                                "\n                        "
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.event.going_count > 0
                  ? _c(
                      "div",
                      { staticClass: "inline-block c-event__counter-icon" },
                      [
                        _vm._m(1),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "c-event__counter-icon__text" },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.event.going_count) +
                                "\n                        "
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.event.linked_goals_count > 0
                  ? _c(
                      "div",
                      { staticClass: "inline-block c-event__counter-icon" },
                      [
                        _vm._m(2),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "c-event__counter-icon__text" },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.event.linked_goals_count) +
                                "\n                        "
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", {}, [
          _c(
            "div",
            { staticClass: "c-event__time-label", style: _vm.timeLabelStyle },
            [
              _vm._v(
                "\n                " + _vm._s(_vm.timeLabel) + "\n            "
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-event__counter-icon__icon" }, [
      _c("i", { staticClass: "fal fa-comment vertical-top" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-event__counter-icon__icon" }, [
      _c("i", {
        staticClass: "fal fa-user-check vertical-top",
        staticStyle: { position: "relative", left: "5px" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-event__counter-icon__icon" }, [
      _c("i", { staticClass: "fal fa-bullseye-pointer vertical-top" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }