<template>
    <div class="goal-list-item" :class="{archived:goal.is_finished_by_page_owner||goal.is_finished}">
        <div class="goal-list-item__avatar" :style="goalAvatarStyle">
            <div class="goal-list-item__avatar__owner-avatar" :style="goalOwnerAvatarStyle"></div>
        </div>
        <div class="goal-list-item__info">
            <div class="goal-list-item__info__desktop-data" :class="{'mobile-hidden':longNameOrDescription}">
                <div class="goal-list-item__info__title">
                    <span class="vertical-top" style="float: right; display: inline-block;">
                        <slot name="buttonsPanel"></slot>
                    </span>
                    <span class="vertical-top">
                        <router-link :to="goalLink" v-if="routerNavigation&&goalNameIsLink">{{
                                goal.name | cutName(140)
                            }}</router-link>
                        <a :href="goalLink" v-else-if="goalNameIsLink">{{ goal.name | cutName(140) }}</a>
                        <span v-else>{{ goal.name | cutName(140) }}</span>
                        <span v-show="goal.is_finished||goal.is_finished_by_page_owner">(Finished {{
                                'Goal'|customTerm
                            }})</span>
                    </span>
                </div>
                <div class="goal-list-item__info__description">{{ goal.description| cutName(255) }}</div>
            </div>
            <simple-progress-bar :val="displayedProgress" size="large" bar-color="#51ac51"
                                 class="goal-list-item__info__progress-bar"
                                 :class="{'with-margin': longNameOrDescription}"></simple-progress-bar>
            <div class="row">
                <div class="col-xs-3 vertical-top">
                    <span v-if="goal.total_tasks_count">
                        {{ goal.completed_tasks_count }}/{{ goal.total_tasks_count }}
                    </span>
                </div>
                <div class="col-xs-9 vertical-top" v-if="finishGoalSwitchIsVisible">
                    <div class="text-right goal-list-item__archive-goal"
                         v-if="!goal.is_finished_by_page_owner">
                        <span @click="archiveGoal" class="pointer">Hide this {{ 'Goal'|customTerm }}?</span>
                    </div>
                    <div class="text-right goal-list-item__archive-goal pointer" @click="unArchiveGoal" v-else>
                        Unhide this {{ 'Goal'|customTerm }}
                    </div>
                </div>
            </div>
        </div>
        <div class="goal-list-item__info__mobile-data" v-show="longNameOrDescription">
            <div class="goal-list-item__info__title">
                <router-link :to="goalLink" v-if="routerNavigation">{{ goal.name | cutName(140) }}</router-link>
                <a :href="goalLink" v-else>{{ goal.name | cutName(140) }}</a> <span
                    v-show="goal.is_finished||goal.is_finished_by_page_owner">(Finished {{ 'Goal'|customTerm }})</span>
            </div>
            <div class="goal-list-item__info__description">{{ goal.description| cutName(255) }}</div>
        </div>
    </div>
</template>

<script>
    import SimpleProgressBar from 'vue-simple-progress';
    import RouterNavigationMixin from '../../../../vue/mixins/RouterNavigationMixin';
    import { STATIC_URL } from 'shared/constants';

    export default {
        components: { SimpleProgressBar },
        mixins: [RouterNavigationMixin],
        props: {
            goal: Object,
            allowArchiveGoal: Boolean,
            goalNameIsLink: {
                type: Boolean,
                default: true,
            },
            addAssigneeIdInFilter: {
                type: Number,
                required: false,
            },
            progress: {
                type: Number,
                required: false
            }
        },
        name: 'GoalsListItem',
        computed: {
            finishGoalSwitchIsVisible() {
                if (this.allowArchiveGoal) {
                    if (this.goal.is_finished) {
                        return this.goal.viewer_is_admin;
                    } else {
                        return true;
                    }
                }
                return false;
            },
            displayedProgress() {
                if (this.progress !== undefined) {
                    return this.progress;
                } else if (this.goal.progress) {
                    return this.goal.progress;
                } else {
                    return 0;
                }
            },
            goalLink() {
                const flt = this.routerNavigation ? 'backHrefQuery' : 'backHref';
                if (this.addAssigneeIdInFilter) {
                    return this.$options.filters[flt](this.goal.link + `?af=${this.addAssigneeIdInFilter}`);
                } else {
                    return this.$options.filters[flt](this.goal.link);
                }

            },
            goalAvatarStyle() {
                const stl = {
                    'background-image': `url('${this.goal.avatar}')`
                };
                if (this.goal?.avatar) {
                    if (this.goal.avatar.indexOf(STATIC_URL) === -1) {
                        stl['border-radius'] = '50%';
                        stl['border-style'] = 'solid';
                        stl['border-width'] = '3px';
                        stl['background-size'] = 'cover';
                    } else {
                        stl['background-size'] = '95%';
                    }
                }
                return stl;
            },
            goalOwnerAvatarStyle() {
                return { 'background-image': `url('${this.goal.owner.avatar}')` };
            },
            longNameOrDescription() {
                return (this.goal.name.length * 1.5 + (this.goal.description?.length || 0)) > 150;
            }
        },
        methods: {
            archiveGoal() {
                this.$emit('archiveGoal', this.goal);
            },
            unArchiveGoal() {
                this.$emit('unArchiveGoal', this.goal);
            }
        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";

  .goal-list-item {
    &.archived {
      opacity: 0.8;
    }

    padding-left: 15px;
    margin: 5px;
    padding-bottom: 5px;

    &:not(:last-child) {
      border-bottom: solid 1px $lightgray;
    }

    &__avatar {
      width: 80px;
      height: 80px;
      background-repeat: no-repeat;
      background-position: center;
      display: inline-block;
      vertical-align: top;
      border-color: $color-goal;

      position: relative;

      &__owner-avatar {
        position: absolute;
        left: -8px;
        top: -8px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: white;
        background-size: contain;
        border: solid 2px white;
      }
    }

    &__info {
      margin-left: 10px;
      vertical-align: top;
      width: calc(100% - 95px);
      display: inline-block;

      &__title {
        font-size: 22px;
        font-weight: bolder;

        a {
          color: #333;
          text-decoration: none;
        }
      }

      &__description {
        font-style: italic;
        font-size: 16px;
      }

      &__desktop-data {
        &.mobile-hidden {
          @media (max-width: 500px) {
            display: none;
          }
        }
      }

      &__mobile-data {
        @media (min-width: 501px) {
          display: none;
        }
      }

      &__mobile-data {
        text-align: justify;
      }

      &__progress-bar {
        &.with-margin {
          @media (max-width: 500px) {
            margin-top: 45px;
          }
        }
      }
    }

    &__archive-goal {
      color: $tag-gray;
    }

  }
</style>
