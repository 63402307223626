<template>
    <div class="task-steps__task"
         :class="{completed:step.completed, small, 'not-achieved': isCompletedButNotAchieved}"
         :style="stepStyle"
    >
        <i @click="onCompleteCheckboxClicked" ref="complete-checkbox-desktop"
           class="far fa-check-square task-steps__task__checkbox" :class="{pointer:completable}">
        </i>

        <span class="task-steps__task__name">{{ index ? index + '. ' : '' }}{{ step.name | cutName(450) }}</span>
        <goal-task-complete-dropdown
                ref="complete-dropdown"
        ></goal-task-complete-dropdown>
    </div>
</template>

<script>
    import GoalTaskCompleteDropdown from './GoalTaskCompleteDropdown.vue';
    import TaskStepMixin from './TaskStepMixin';

    export default {
        components: {GoalTaskCompleteDropdown},
        mixins: [TaskStepMixin],
        props: {
            availableCompletionOptions: Array,
            small: {
                type: Boolean,
                default: false,
            },
            index: {
                type: Number,
                required: false,
            }
        },
        name: "GoalTaskStepsStep.vue",
    };
</script>

<style scoped lang="scss">
    @import "../../../../../styles/const";

    .task-steps {
        &__task {
            $task-selector: &;

            &.completed {
                #{$task-selector}__name {
                    text-decoration: line-through;
                }
            }

            font-size: 18px;
            color: $tag-gray;

            &__checkbox {
                font-size: 20px;
            }

            &.small {
                font-size: 16px;

                #{$task-selector}__checkbox {
                    font-size: 18px;
                }
            }
        }

    }
</style>