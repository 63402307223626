<template>
    <div class="">
        <community-page-alternative-header title="Settings"></community-page-alternative-header>
        <div class="">
            <table class="table table-striped">
                <tbody>
                <tr>
                    <th>Community name</th>
                    <th><input type="text" v-model="mainSettings.name" class="form-control"/></th>
                </tr>
                <tr>
                    <th>Is Searchable</th>
                    <td>
                        <vue-switch name="is_searchable" v-model="mainSettings.is_searchable"></vue-switch>
                    </td>
                </tr>
                <tr v-if="canUseCommunityWiki">
                    <th>Use
                        <router-link class="color-blue" :to="{name:wikiPageName}">Wiki</router-link>
                        as About Us page
                    </th>
                    <td>
                        <vue-switch name="uses_community_wiki" v-model="mainSettings.uses_community_wiki"></vue-switch>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class="row" v-if="canUseCommunityWiki">
                <div class="col-sm-12">
                    <div class="alert alert-info" v-show="mainSettings.uses_community_wiki">
                        <strong>Attention!</strong> <span>Using Wiki as "About Us" page <strong>does not</strong> make Wiki public accessible automatically. Please make sure you have correctly configured access rights for <strong>Public</strong> on <strong>
                        <router-link class="color-blue" :to="{name:'community-wiki-access-page', query:{from:'settings'}}">Wiki Access</router-link>
                    </strong> page.</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <button class="btn btn-mm" @click="changeAvatar">Change Avatar</button>
                    <button class="btn btn-mm btn-green" :disabled="!allowSave" @click="saveMainSettings()">Update
                    </button>
                    <!--                    <button class="btn btn-mm btn-red" @click="removeCommunity">Remove Community</button>-->
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';
    import CommunityPageAlternativeHeader from "./CommunityPageAlternativeHeader.vue";
    import {getMainWikiPageName} from "../wiki_page/WikiChildrenPages";
    import {COMMUNITY_WIKI_ROUTER_PREFIX} from "./config";

    export default {
        name: "CommunityPageSettings",
        components: {CommunityPageAlternativeHeader},
        props: {},
        data() {
            return {
                mainSettings: {},
                previousSettings: {},
                canUseCommunityWiki: false,
            };
        },
        computed: {
            wikiPageName(){
              return getMainWikiPageName(COMMUNITY_WIKI_ROUTER_PREFIX)
            },
            community() {
                return this.$parent.community;
            },
            settingsChanged() {
                let changed = false;
                _.forOwn(this.mainSettings, (v, k) => {
                    if (this.mainSettings[k] !== this.previousSettings[k]) {
                        changed = true;
                    }
                });
                return changed;
            },

            allowSave() {
                return this.settingsChanged;
            }
        },
        methods: {
            init() {
                const CommunityResource = Vue.getAngularModule('CommunityResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                CommunityResource.get_community_settings(this.community.id)
                    .then(resp => {
                        this.mainSettings = resp.data.main_settings;
                        this.canUseCommunityWiki = resp.data.can_use_community_wiki;
                        this.previousSettings = _.merge({}, this.mainSettings);
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            $_changeAvatarCallback(media_items_list) {
                const CommunityResource = Vue.getAngularModule('CommunityResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                CommunityResource.update_avatar(this.community.id, media_items_list[0].pk).then((resp) => {
                    this.$store.commit('communityPage/updateCommunityAvatar', resp.data.avatar);
                    $rootScope.hide_dimmer();
                    Vue.notifications.success('Avatar Changed');
                }, $rootScope.hide_dimmer);
            },
            changeAvatar() {
                const addMedia = Vue.getAngularModule('addMedia');
                addMedia.add_media({
                    allow_add_from_library: true,
                    type: 'image',
                    max_count: 1
                }, {
                    default_callback: this.$_changeAvatarCallback
                });
            },
            saveMainSettings() {
                const CommunityResource = Vue.getAngularModule('CommunityResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                CommunityResource.save_main_settings(this.community.id, this.mainSettings)
                    .then(resp => {
                        if (this.$route.params.communitySlug !== resp.data.main_settings.slug) {
                            this.$router.replace({
                                name: this.$route.name,
                                params: {communitySlug: resp.data.main_settings.slug}
                            });
                        }
                        if (this.mainSettings.name !== this.community.name) {
                            this.$store.commit('communityPage/updateCommunityName', this.mainSettings.name);
                        }
                        this.$store.commit('communityPage/setAboutPage', this.mainSettings.uses_community_wiki);
                        Vue.notifications.success('Saved');
                        this.previousSettings = _.merge({}, this.mainSettings);
                    }, err => Vue.notifications.error(err || 'Error'))
                    .finally($rootScope.hide_dimmer);

            },
            removeCommunity() {

            }
        },
        mounted() {
            this.init();
        }
    };
</script>

<style scoped>

</style>