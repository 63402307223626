var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "section-background" }, [
    _vm.samplesCount === 0
      ? _c("div", { staticClass: "section-background__none" }, [
          _c("i", { staticClass: "fa fa-image" }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.samplesCount === 1
      ? _c("div", {
          staticClass: "section-background__single",
          style: _vm.firstBackgroundImage,
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.samplesCount === 2
      ? _c("div", { staticClass: "section-background__double" }, [
          _c("div", {
            staticClass: "section-background__double__first",
            style: _vm.firstBackgroundImage,
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "section-background__double__second",
            style: _vm.secondBackgroundImage,
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.samplesCount === 3
      ? _c("div", { staticClass: "section-background__triple" }, [
          _c("div", {
            staticClass: "section-background__triple__right",
            style: _vm.firstBackgroundImage,
          }),
          _vm._v(" "),
          _c("div", { staticClass: "section-background__triple__left" }, [
            _c("div", {
              staticClass: "section-background__triple__left__second",
              style: _vm.secondBackgroundImage,
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "section-background__triple__left__third",
              style: _vm.thirdBackgroundImage,
            }),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.samplesCount === 4
      ? _c("div", { staticClass: "section-background__quadruple" }, [
          _c("div", { staticClass: "section-background__quadruple__left" }, [
            _c("div", {
              staticClass: "section-background__quadruple__left__first",
              style: _vm.firstBackgroundImage,
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "section-background__quadruple__left__second",
              style: _vm.secondBackgroundImage,
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "section-background__quadruple__right" }, [
            _c("div", {
              staticClass: "section-background__quadruple__right__third",
              style: _vm.thirdBackgroundImage,
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "section-background__quadruple__right__fourth",
              style: _vm.fourthBackgroundImage,
            }),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }