<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 size="xlg"
                 class="show-posts-popup"
                 :footer="false"
                 aria-label="View Media Dialog"
                 role="dialog">
        <div slot="header">
            <basic-modal-close-button ref="closeButton" :return-focus="focusToNext"></basic-modal-close-button>
            <div class="row show-posts-popup__header">
                <div class="col-xs-12 text-center">
                    <a href="javascript:;"
                       @click="previous"
                       ref="prevArrow"
                       role="button"
                       aria-controls="show-posts-popup__content"
                       :class="{disabled:!prevAvailable}"
                       :aria-disabled="!prevAvailable"
                       class="view-media-navigation-arrow left"
                    >
                        <i class="fa fa-arrow-left" aria-hidden="true"></i> PREVIOUS
                    </a>
                    <div class="show-posts-popup__header__counter">{{ index + 1 }}/{{ posts.length }}</div>
                    <a href="javascript:;"
                       ref="nextArrow"
                       @click="next"
                       role="button"
                       aria-controls="show-posts-popup__content"
                       :class="{disabled:!nextAvailable}"
                       :aria-disabled="!nextAvailable"
                       class="view-media-navigation-arrow right">
                        NEXT
                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="row" aria-live="polite" id="show-posts-popup__content">
            <div class="col-sm-12">
                <div class="show-posts-popup__container" style="height: 100%"

                >
                    <div class="inline-block" style="width: 99%;height: 100%; font-size: 14px;">
                        <div :style="mediaPartStyle">
                            <render-sticker
                                    v-touch:swipe.right="previous"
                                    v-touch:swipe.left="next"
                                    v-if="sticker"
                                    :sticker="sticker"
                                    :class="stickerClass"
                                    class="show-posts-popup__sticker"
                            ></render-sticker>

                            <vue-inline-media
                                    v-touch:swipe.right="previous"
                                    v-touch:swipe.left="next"
                                    :lazy-load="true"
                                    :item="mediaAttachment"
                                    :autoplay="true"
                                    :key="mediaAttachmentId"
                            ></vue-inline-media>
                        </div>
                        <div class="" :style="textPartStyle" v-if="linkedText">
                            <span v-html="linkedText"></span>
                        </div>
                    </div>
                    <span class="align-helper"></span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <span tabindex="0" aria-hidden="true" class="focus-catch-link" @focus="focusToClose">&nbsp;</span>
            </div>
        </div>

    </basic-modal>
</template>

<script>
    import BasicModal from "../../../../vue/common_components/BasicModal";
    import PopupPromiseMixin from "../../../../vue/mixins/PopupPromiseMixin";
    import RenderSticker from "../../add_media/stickers/render_sticker_directive/RenderSticker";
    import VueInlineMedia from "../vue_inline_media_render_directive/VueInlineMedia";
    import Autolinker from "autolinker";

    export default {
        name: "showPostsPopup",
        components: {VueInlineMedia, RenderSticker, BasicModal},
        mixins: [PopupPromiseMixin],
        data() {
            return {
                index: 0,
                posts: [],
            };
        },
        computed: {
            mediaAttachment() {
                return this.posts[this.index]?.media_attachment;
            },
            mediaAttachmentId() {
                return this.mediaAttachment?.id;
            },
            sticker() {
                return this.posts[this.index]?.sticker;
            },
            noMediaInPost() {
                return !this.mediaAttachment;
            },
            isTextDescriptionPresents() {
                return this.posts[this.index]?.text;
            },
            mediaPartStyle() {
                let style = {
                    'position': 'relative',
                    'height': '73vh',
                };

                if (window.outerWidth >= 768 && !this.noMediaInPost) {
                    if (this.isTextDescriptionPresents) {
                        style['height'] = '73vh';
                    } else {
                        style['height'] = '80vh';
                    }
                }

                return style;
            },
            stickerClass() {
                if (!this.noMediaInPost) {
                    return 'with-media';
                }
                return 'without-media';
            },
            textPartStyle() {
                let style = {};
                if (this.isTextDescriptionPresents) {
                    style['height'] = '20%';
                } else {
                    style['display'] = 'none';
                }
                if (this.isTextDescriptionPresents && this.posts[this.index].text.length < 80) {
                    style['text-align'] = 'center';
                }
                if (this.isTextDescriptionPresents) {
                    if (this.posts[this.index].is_header) {
                        style['font-size'] = '30px';
                        style['font-weight'] = 'bolder';
                    } else {
                        style['font-size'] = '25px';
                    }
                }
                return style;
            },
            linkedText() {
                if (this.posts[this.index]?.text) {
                    return Autolinker.link(this.posts[this.index].text, {truncate: 30});
                }
                return '';
            },
            nextAvailable() {
                return this.index < (this.posts.length - 1);
            },
            prevAvailable() {
                return this.index > 0;
            },
            firstFocusEl() {
                return this.$refs.nextArrow;
            },
        },
        methods: {
            focusToClose() {
                this.$refs.closeButton?.focus();
            },
            focusToNext() {
                this.$refs.nextArrow?.focus();
            },
            setInitial(posts = [], index = 0) {
                this.posts = posts;
                this.index = index;
            },
            next() {
                if (this.nextAvailable) {
                    this.index++;
                }
            },
            previous() {
                if (this.prevAvailable) {
                    this.index--;
                }
            }
        },
    };
</script>

<style lang="scss">
  @import "../../styles/const";

  .show-posts-popup {
    &__header {
      .view-media-navigation-arrow {
        display: inline-block;
        font-size: 30px;
        line-height: 30px;

        &.left {
          float: left;
        }

        &.right {
          float: right;
          margin-right: 30px;
        }

        color: #0599f4;

        &.disabled {
          color: $lightgray;
          cursor: not-allowed;
        }


        @media (min-width: 600px) {
          font-size: 30px;
          line-height: 30px;
        }
        @media (max-width: 599px) and (min-width: 400px) {
          font-size: 20px;
          line-height: 20px;
        }
        @media (max-width: 399px) {
          font-size: 15px;
          line-height: 15px;
        }

      }

      &__counter {
        @media (min-width: 600px) {
          font-size: 30px;
          line-height: 30px;
        }
        @media (max-width: 599px) and (min-width: 400px) {
          font-size: 20px;
          line-height: 20px;
        }
        @media (max-width: 399px) {
          font-size: 15px;
          line-height: 15px;
        }
        position: absolute;
        left: calc(50% - 50px);
        font-size: 30px;
        line-height: 30px;
        width: 100px;
        text-align: center;
      }
    }

    &__container {
      position: relative;
      font-size: 0;
      padding: 5px;
      min-height: 74vh;


      #inline-render-media-image {
        max-height: 96%;
        @media (max-width: 1000px) {
          max-width: 100%;
        }
        @media (min-width: 1001px) {
          max-width: 80%;
        }

      }

      .inline_video_container {
        width: auto !important;
      }

      &.with-text-description {
        .youtube_video_container {
          @media (max-width: 740px) {
            width: 100%;
            height: 100%;
          }
          @media (min-width: 741px) and (max-width: 1000px) {
            width: 80%;
            height: 100%;
          }
          @media (min-width: 1001px) and (max-width: 1800px) {
            width: 60%;
            height: 100%;
          }
          @media (min-width: 1801px) {
            width: 60%;
            height: 100%;
          }
          //width: 75vw;
          //height: 40vw;
          padding-bottom: 0;
        }
      }

      &.without-text-description {
        .youtube_video_container {
          @media (max-width: 740px) {
            width: 100%;
            height: 100%;
          }
          @media (min-width: 741px) and (max-width: 1000px) {
            width: 90%;
            height: 100%;
          }
          @media (min-width: 1001px) and (max-width: 1800px) {
            width: 90%;
            height: 100%;
          }
          @media (min-width: 1801px) {
            width: 80%;
            height: 100%;
          }
          //width: 75vw;
          //height: 40vw;
          padding-bottom: 0;
        }
      }

      .inline_document_container {
        @media (min-width: 768px) {
          font-size: 36px !important;

        }
        @media (max-width: 768px) {
          font-size: 26px !important;
          margin-bottom: 40px;
        }

        padding-top: 10%;
      }
    }

    &__sticker {
      &.with-media {
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 15vw;
        max-height: 15vh;
        background-position: bottom;
      }

      &.without-media {
        display: block;
        margin: auto;
      }
    }
  }
</style>