<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="title"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ title }}</h3>
        </div>
        <div class="share-target-selector__container">
            <div class="share-target-selector__select-menu-container center">
                <div class="
                        share-target-selector__select-menu-item
                        share-target-selector__select-menu-item_private"
                     :class="{'share-target-selector__select-menu-item_two-lines':twoLines}"
                     style="z-index: 4"
                     tabindex="0"
                     ref="keepPrivateButton"
                     role="button"
                     aria-label="Keep Private"
                     @click="select('private')"
                     @keydown.enter="select('private')"
                >
                    <div class="share-target-selector__item-caption" aria-hidden="true"
                         :class="{'share-target-selector__item-caption_two-lines': twoLines}"
                    >Keep<br>Private
                    </div>
                    <div class="share-target-selector__item-icon"
                         :class="{'share-target-selector__item-icon_two-lines': twoLines}">
                        <i class="fa fa-hand-paper-o"></i>
                    </div>
                    <div class="share-target-item__shared-mark" v-if="keepPrivate"
                         :class="{'unconfirmed': keepPrivateUnconfirmed}">
                        <i class="fa fa-check"></i>
                    </div>
                </div>
                <div class="
                        share-target-selector__select-menu-item
                        share-target-selector__select-menu-item_circle"
                     :class="{'share-target-selector__select-menu-item_two-lines':twoLines}"
                     style="z-index: 3"
                     tabindex="0"
                     role="button"
                     aria-label="Share to My Circle"
                     @click="select('circle')"
                     @keydown.enter="select('circle')"
                >
                    <div class="share-target-selector__item-caption"
                         :class="{'share-target-selector__item-caption_two-lines': twoLines}"
                    >My<br>Circle
                    </div>
                    <div class="share-target-selector__item-icon"
                         :class="{'share-target-selector__item-icon_two-lines': twoLines}">
                        <div class="share-target-item__custom-icon share-target-item__custom-icon_circle"
                             :class="{'share-target-item__custom-icon_two-lines': twoLines}"></div>
                    </div>
                    <div class="share-target-item__shared-mark" v-if="sharingStatus.friends.shared"
                         :class="{'unconfirmed': sharingStatus.friends.has_unapproved}">
                        <i class="fa fa-check"></i>
                    </div>
                </div>
                <div class="" v-if="twoLines"></div>
                <div class="
                        share-target-selector__select-menu-item
                        share-target-selector__select-menu-item_groups"
                     style="z-index: 2"
                     :class="{'share-target-selector__select-menu-item_two-lines':twoLines}"
                     aria-label="Share to my groups"
                     tabindex="0"
                     role="button"
                     @click="select('groups')"
                     @keydown.enter="select('groups')"
                >
                    <div class="share-target-selector__item-caption" aria-hidden="true"
                         :class="{'share-target-selector__item-caption_two-lines': twoLines}"
                    >My<br>Groups
                    </div>
                    <div class="share-target-selector__item-icon"
                         :class="{'share-target-selector__item-icon_two-lines': twoLines}">
                        <div class="share-target-item__custom-icon share-target-item__custom-icon_groups"
                             :class="{'share-target-item__custom-icon_two-lines': twoLines}"></div>
                    </div>
                    <div class="share-target-item__shared-mark" v-if="sharingStatus.groups.shared"
                         :class="{'unconfirmed': sharingStatus.groups.has_unapproved}">
                        <i class="fa fa-check"></i>
                    </div>
                </div>
                <div class="
                    share-target-selector__select-menu-item
                    share-target-selector__select-menu-item_communities"
                     :class="{'share-target-selector__select-menu-item_two-lines':twoLines}"
                     style="z-index: 1"
                     v-if="allowShareToCommunities"
                     role="button"
                     tabindex="0"
                     aria-label="Share to my Communities"
                     @click="select('communities')"
                     @keydown.enter="select('communities')"
                >
                    <div class="share-target-selector__item-caption"
                         aria-hidden="true"
                         :class="{'share-target-selector__item-caption_two-lines': twoLines}"
                    >My<br>Communities
                    </div>
                    <div class="share-target-selector__item-icon"
                         :class="{'share-target-selector__item-icon_two-lines': twoLines}">
                        <div class="share-target-item__custom-icon share-target-item__custom-icon_communities"
                             :class="{'share-target-item__custom-icon_two-lines': twoLines}"></div>
                    </div>
                    <div class="share-target-item__shared-mark" v-if="sharingStatus.communities.shared"
                         :class="{'unconfirmed': sharingStatus.communities.has_unapproved}">
                        <i class="fa fa-check"></i>
                    </div>
                </div>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import _ from "lodash";
    import PopupPromiseMixin from "../../mixins/PopupPromiseMixin";
    import {defaultSharingState} from "../../../angular/app/sharing/common";
    import BasicModal from "../BasicModal";

    export default {
        name: "ShareMainTargetSelectPopup",
        components: {BasicModal},
        mixins: [PopupPromiseMixin],
        props: {
            allowShareToCommunities: {
                type: Boolean,
                default: false,
            },
            title: {
                type: String,
                default: 'Share',
            }
        },
        data() {
            return {
                sharingStatus: _.merge({}, defaultSharingState)
            };
        },
        computed: {
            firstFocusEl() {
                return this.$refs.keepPrivateButton;
            },
            keepPrivate() {
                return !(this.sharingStatus.friends.shared || this.sharingStatus.groups.shared || this.sharingStatus.communities.shared);
            },
            keepPrivateUnconfirmed() {
                return this.keepPrivate && this.sharingStatus.has_redefined_in_children;
            },
            twoLines() {
                return this.allowShareToCommunities;
            }
        },
        methods: {
            setInitial(...rest) {
                this.sharingStatus = rest[0];
            },
            select(what) {
                this.save(what);
            }
        }
    };
</script>

<style scoped lang="scss">
  @import "../../../angular/app/styles/mixins";
  @import "../../../angular/app/styles/const";

  $size-step-1: '(min-width: 350px) and (max-width: 369px)';
  $size-step-2: '(min-width: 370px) and (max-width: 423px)';
  $size-step-3: '(min-width: 424px) and (max-width: 599px)';
  $size-step-4: '(min-width: 600px)';

  .share-target-item {
    &__shared-mark {
      display: block;
      position: absolute;
      right: -19px;
      top: -8px;

      &.unconfirmed {
        background-color: $yellow;
      }

      background-color: $grass-green;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      text-align: center;
      line-height: 40px;
      color: white;
      font-size: 34px;
    }

    &__custom-icon {
      $cc: &;
      display: inline-block;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      vertical-align: top;

      $size: 50px;
      width: $size;
      height: $size;
      margin-top: 8px;

      &_two-lines {
        $t_size: 54px;
        width: $t_size;
        height: $t_size;
        margin-top: 12px;
      }

      @media #{$size-step-1} {
        $size: 54px;
        width: $size;
        height: $size;
        margin-top: 9px;

        &_two-lines#{$cc} {
          $t_size: 60px;
          width: $t_size;
          height: $t_size;
          margin-top: 14px;
        }
      }

      @media #{$size-step-2} {
        $size: 57px;
        width: $size;
        height: $size;
        margin-top: 10px;

        &_two-lines#{$cc} {
          $t_size: 65px;
          width: $t_size;
          height: $t_size;
          margin-top: 15px;
        }
      }

      @media #{$size-step-3} {
        $size: 65px;
        width: $size;
        height: $size;
        margin-top: 15px;

        &_two-lines#{$cc} {
          $t_size: 70px;
          width: $t_size;
          height: $t_size;
          margin-top: 20px;
        }
      }

      @media #{$size-step-4} {
        $size: 75px;
        width: $size;
        height: $size;
        margin-top: 23px;

        &_two-lines#{$cc} {
          $t_size: 75px;
          width: $t_size;
          height: $t_size;
          margin-top: 21px;
        }
      }


      &_circle {
        background-image: url('/static/images/overlays-icons/my-circle.png');
      }

      &_groups {
        background-image: url('/static/images/overlays-icons/groups_new_white.png');
      }

      &_communities {
        background-image: url('/static/images/overlays-icons/community_new_white.png');
      }
    }
  }

  .share-target-selector {
    &__select-menu-container {
      text-align: center;
    }


    &__item-icon {
      $cc: &;
      font-size: 46px;
      line-height: 66px;
      width: 100%;

      &_two-lines {
        font-size: 53px;
        line-height: 75px;
      }

      @media #{$size-step-1} {
        font-size: 53px;
        line-height: 73px;

        &_two-lines#{$cc} {
          font-size: 62px;
          line-height: 90px;
        }
      }
      @media #{$size-step-2} {
        font-size: 55px;
        line-height: 75px;
        &_two-lines#{$cc} {
          font-size: 68px;
          line-height: 96px;
        }
      }
      @media #{$size-step-3} {
        font-size: 65px;
        line-height: 90px;
        &_two-lines#{$cc} {
          font-size: 74px;
          line-height: 110px;
        }
      }
      @media #{$size-step-4} {
        font-size: 78px;
        line-height: 119px;
        &_two-lines#{$cc} {
          font-size: 84px;
          line-height: 118px;
        }
      }
    }

    &__item-caption {
      $cc: &;
      height: 30px;
      font-size: 14px;
      line-height: 15px;
      position: relative;
      top: 5px;

      &_two-lines {
        font-size: 14px;
        line-height: 15px;
      }

      @media #{$size-step-1} {
        height: 32px;
        font-size: 15px;
        line-height: 16px;
        &_two-lines#{$cc} {
          height: 35px;
          font-size: 15px;
          line-height: 16px;
        }
      }

      @media #{$size-step-2} {
        height: 35px;
        font-size: 15px;
        line-height: 16px;
        &_two-lines#{$cc} {
          height: 35px;
          font-size: 15px;
          line-height: 16px;
        }
      }
      @media #{$size-step-3} {
        height: 35px;
        font-size: 15px;
        line-height: 16px;
        &_two-lines#{$cc} {
          height: 35px;
          font-size: 15px;
          line-height: 16px;
        }
      }
      @media #{$size-step-4} {
        height: 37px;
        font-size: 17px;
        line-height: 18px;
        &_two-lines#{$cc} {
          height: 37px;
          font-size: 17px;
          line-height: 18px;
        }
      }
    }


    &__select-menu-item {
      $cc: &;
      cursor: pointer;
      position: relative;
      @media #{$size-step-1} {
        min-height: 105px;
        min-width: 85px;
        margin: 5px;

        &_two-lines#{$cc} {
          min-height: 125px;
          min-width: 115px;
          margin: 10px;
        }
      }
      @media #{$size-step-2} {
        &_two-lines#{$cc} {
          min-height: 130px;
          min-width: 120px;
          margin: 10px;
        }

        min-height: 110px;
        min-width: 90px;
        margin: 5px;
      }
      @media #{$size-step-3} {
        &_two-lines#{$cc} {
          min-height: 145px;
          min-width: 130px;
          margin: 10px;
        }

        min-height: 125px;
        min-width: 105px;
        margin: 5px;
      }
      @media #{$size-step-4} {
        min-height: 155px;
        min-width: 140px;
        margin: 10px;

        &_two-lines#{$cc} {
          min-height: 155px;
          min-width: 140px;
          margin: 10px;
        }
      }

      min-height: 95px;
      min-width: 75px;
      margin: 5px;

      &_two-lines {
        min-width: 100px;
        min-height: 105px;
        margin: 10px;
      }

      vertical-align: top;
      display: inline-block;
      position: relative;

      color: white;
      text-align: center;
      border-radius: 3px;
      transition: background-color ease 0.2s;

      &_private {
        background-color: #E6493B;

        &:hover, &:active {
          background-color: #b43a30;
        }
      }

      &_circle {
        background-color: #e7b645;

        &:hover, &:active {
          background-color: #cd971f;
        }
      }

      &_groups {
        background-color: #00b3ee;

        &:hover, &:active {
          background-color: #029acd;
        }
      }

      &_communities {
        background-color: #64bd63;

        &:hover, &:active {
          background-color: #449544;
        }

      }
    }

  }
</style>