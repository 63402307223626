var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row community-page__container" },
    [
      _c("div", { staticClass: "col-sm-12" }, [
        _c(
          "div",
          { staticClass: "community-page__menu" },
          [
            _c("community-page-menu-vertical", {
              attrs: {
                avatar: _vm.community.avatar,
                name: _vm.community.name,
                "show-settings": _vm.isAdmin,
              },
              on: { onShare: _vm.shareCommunityLink },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "community-page__content" },
          [_c("router-view")],
          1
        ),
      ]),
      _vm._v(" "),
      _c("way-to-share-selector-popup", {
        ref: "share-selector-popup",
        attrs: { link: _vm.community.link },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }