var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", {}, [_vm._v("Remind me about this Event")]),
      _vm._v(" "),
      !_vm.reminder
        ? _c(
            "div",
            { staticClass: "color-tag-gray-dark pointer" },
            [
              _c(
                "Dropdown",
                {
                  ref: "dropdown",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "dropdown",
                        fn: function () {
                          return [
                            _c(
                              "li",
                              {
                                staticClass: "dropdown-time-selector-header",
                                attrs: { disabled: "" },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "margin-5-left inline-block" },
                                  [_vm._v("Remind me")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("li", {
                              staticClass: "divider",
                              attrs: { role: "separator" },
                            }),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "dropdown-time-selector" },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { role: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSetReminder(
                                          _vm.POSSIBLE_REMINDER_GAPS.AS_STARTS
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("As it starts")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "dropdown-time-selector" },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { role: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSetReminder(
                                          _vm.POSSIBLE_REMINDER_GAPS
                                            .FIFTEEN_MINUTES_BEFORE
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("15 Minutes before")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "dropdown-time-selector" },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { role: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSetReminder(
                                          _vm.POSSIBLE_REMINDER_GAPS.HOUR_BEFORE
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("1 Hour before")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "dropdown-time-selector" },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { role: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSetReminder(
                                          _vm.POSSIBLE_REMINDER_GAPS.DAY_BEFORE
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("1 Day before")]
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    277706737
                  ),
                },
                [
                  _c(
                    "span",
                    {
                      ref: "dropdown-trigger",
                      attrs: { tabindex: "0", role: "button" },
                      on: {
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.$refs["dropdown-trigger"].click()
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "far fa-alarm-clock",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" Set up a reminder\n        "),
                    ]
                  ),
                ]
              ),
            ],
            1
          )
        : _c("div", [
            _c(
              "div",
              {
                staticClass: "color-grass-green-dark pointer",
                attrs: { role: "button", tabindex: "0" },
                on: {
                  click: _vm.cancelReminder,
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.cancelReminder.apply(null, arguments)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "far fa-alarm-clock",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.reminderLabel))]),
              ]
            ),
          ]),
      _vm._v(" "),
      _c("event-detail-page-period-selector-popup", {
        ref: "join-type-selector",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }