var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "stuff_preview",
      class: _vm.classObject,
      style: _vm.containerStyle,
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.displayName ? undefined : _vm.stuffItem.name,
              expression: "displayName?undefined:stuffItem.name",
            },
          ],
          staticClass: "stuff_preview__image_container",
          style: [_vm.styleObject, _vm.imageContainerStyle],
        },
        [
          _vm.onlineMark
            ? _c("div", {
                staticClass: "stuff_preview__unread_mark",
                style: _vm.onlineMarkStyle,
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showAdditionalMark
            ? _c(
                "div",
                {
                  class: [
                    "stuff_preview__additional_mark",
                    _vm.additionalMarkCustomIcon,
                    _vm.additionalMarkColor,
                  ],
                  style: _vm.additionalMarkStyle,
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.additional_mark.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.additionalMark
                    ? _c("i", { staticClass: "fa", class: _vm.additionalMark })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showAdditionalMark2
            ? _c(
                "div",
                {
                  class: [
                    "stuff_preview__additional_mark",
                    _vm.additionalMarkCustomIcon2,
                    _vm.additionalMarkColor2,
                  ],
                  style: _vm.additionalMark2Style,
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.additional_mark2.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.additionalMark2
                    ? _c("i", { staticClass: "fa", class: _vm.additionalMark2 })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.unreadMark
            ? _c(
                "div",
                {
                  staticClass: "stuff_preview__unread_mark",
                  style: _vm.additionalMarkStyle,
                },
                [_c("i", [_vm._v("NEW")])]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.deletable
            ? _c(
                "div",
                {
                  staticClass: "stuff_preview__delete_button",
                  style: _vm.deleteMarkStyle,
                  attrs: {
                    tabindex: "0",
                    role: "button",
                    "aria-label": "Delete",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.del.apply(null, arguments)
                    },
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      $event.preventDefault()
                      return _vm.del.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.deleteButton === "close"
                    ? _c("i", { staticClass: "fa fa-times" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.deleteButton === "leave"
                    ? _c("i", { staticClass: "fa fa-sign-out" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.deleteButton === "delete"
                    ? _c("i", { staticClass: "fa fa-trash-o" })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.displayName
        ? _c(
            "div",
            {
              staticClass: "stuff_preview__text_container text-center",
              style: _vm.textContainerStyle,
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm._f("cutName")(_vm.stuffItem.name, 53)) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }