/**
 * Created by orion on 20.04.17.
 */
import { DjangoUrls } from 'DjangoUrls';
import axios from 'axios';
import { ACCESS_LINK_TOKEN_HEADER_NAME } from '../wiki/common';

BlogAnswerComment.$inject = [];

const PREFIX = `api.v1:answer_comments-`;

function BlogAnswerComment () {
    return {
        get_comments_page: function (answer_pk, access_code, params, headers={}) {
            const url = DjangoUrls[`${PREFIX}get-comments-page`]();
            return axios({
                method: 'GET',
                url,
                headers,
                params: Object.assign({
                    answer_pk,
                    access_code
                }, params)
            });
        },
        add: function (answer_pk, text, sticker, media_attachment_pk, reply_to, access_code, accessToken) {
            const url = DjangoUrls[`${PREFIX}add`]();
            const headers = {};
            if (accessToken) {
                headers[ACCESS_LINK_TOKEN_HEADER_NAME] = accessToken;
            }
            return axios({
                method: 'POST',
                url,
                headers,
                data: {
                    reply_to,
                    attachment: media_attachment_pk,
                    text,
                    sticker
                },
                params: {
                    answer_pk,
                    access_code
                }
            });
        },
        delete_comment: function (comment_pk) {
            const url = DjangoUrls[`${PREFIX}delete`](comment_pk);
            return axios({
                method: 'DELETE',
                url
            });
        },
        like_comment: function (comment_pk) {
            const url = DjangoUrls[`${PREFIX}like`](comment_pk);
            return axios({
                method: 'PATCH',
                url
            });
        },
        unlike_comment: function (comment_pk) {
            const url = DjangoUrls[`${PREFIX}unlike`](comment_pk);
            return axios({
                method: 'PATCH',
                url
            });
        }
    };
}

export default BlogAnswerComment;
