import {DjangoUrls} from 'DjangoUrls';

GroupEventsHolidaysResource.$inject = ['$http'];

function GroupEventsHolidaysResource($http) {
    const PREFIX = "api.v1:group_events_holidays-";
    return {
        get_events_holidays_in_group(group_id) {
            const url = DjangoUrls[`${PREFIX}get-events-holidays-in-group`]();
            return $http({
                method: 'GET',
                url,
                params: {group_id}
            });
        },
        create_events_holidays_in_groups(groupsIdList, data) {
            const url = DjangoUrls[`${PREFIX}create-events-holidays-in-groups`]();
            return $http({
                method: 'POST',
                url,
                data: Object.assign({id_list: groupsIdList}, data)
            });
        },

        check_group_repeated_events_existence(group_id) {
            const url = DjangoUrls[`${PREFIX}check-group-repeated-events-existence`]();
            return $http({
                method: 'GET',
                url,
                params: {
                    group_id,
                }
            });
        },

        check_if_creating_holidays_in_another_groups_is_possible(group_id) {
            const url = DjangoUrls[`${PREFIX}check-if-creating-holidays-in-another-groups-is-possible`]();
            return $http({
                method: 'GET',
                url,
                params: {group_id}
            });
        },

        check_if_syncing_holidays_changes_is_possible(holidaysId, group_id) {
            const url = DjangoUrls[`${PREFIX}check-if-syncing-holidays-changes-is-possible`](holidaysId);
            return $http({
                method: 'GET',
                url,
                params: {
                    group_id,
                }
            });
        },

        get_groups_where_i_can_add_holidays(group_id, params) {
            const url = DjangoUrls[`${PREFIX}get-groups-where-i-can-add-holidays`]();
            return $http({
                method: 'GET',
                url,
                params: Object.assign(group_id ? {group_id} : {}, params)
            });
        },

        get_groups_where_i_can_sync_holiday_changes(holiday_id, group_id, params) {
            const url = DjangoUrls[`${PREFIX}get-groups-where-i-can-sync-holiday-changes`](holiday_id);
            return $http({
                method: 'GET',
                url,
                params: Object.assign(group_id ? {group_id} : {}, params)
            });
        },

        update_event_holidays(holiday_id, data) {
            const url = DjangoUrls[`${PREFIX}update-event-holidays`](holiday_id);
            return $http({
                method: 'POST',
                url,
                data,
            });
        },

        delete_events_holidays(holidays_id, data) {
            const url = DjangoUrls[`${PREFIX}delete-events-holidays`](holidays_id);
            return $http({
                method: 'POST',
                url,
                data,
            });
        },

        get_my_groups_affected_by_holiday(holiday_id, editable = false, params) {
            const url = DjangoUrls[`${PREFIX}get-my-groups-affected-by-holiday`](holiday_id);
            return $http({
                method: 'GET',
                url,
                params: Object.assign(editable ? {editable: true} : {}, params)
            });
        },

        get_editable_group(holiday_id,) {
            const url = DjangoUrls[`${PREFIX}get-editable-group`](holiday_id);
            return $http({
                method: 'GET',
                url
            });
        },
    };
}

export default GroupEventsHolidaysResource;
