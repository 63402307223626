import urls from 'DjangoUrls';
import _ from 'lodash';
let {DjangoUrls} = urls;

function activityPostResourceFactory(prefix) {
    ActivityPostResource.$inject = ['$http', '$log'];

    function ActivityPostResource($http, $log) {
        return {
            update_post: function (id, data) {
                const url = DjangoUrls[`api.v1:${prefix}-update-post`](id);
                return $http({
                    method: 'POST',
                    url,
                    data
                })
            },
            remove_post: function (id, data) {
                const url = DjangoUrls[`api.v1:${prefix}-remove-post`](id);
                return $http({
                    method: 'DELETE',
                    url
                })
            },



            link_to_location(id, location_id) {
                const url = DjangoUrls[`api.v1:${prefix}-link-to-location`](id);
                return $http({
                    method: 'POST',
                    url: url,
                    data: {
                        id: location_id
                    }
                })
            },

            unlink_location(id, location_id) {
                const url = DjangoUrls[`api.v1:${prefix}-unlink-location`](id);
                return $http({
                    method: 'POST',
                    url: url,
                    data: {
                        id: location_id
                    }
                })
            }
        }
    }

    return ActivityPostResource;
}

export default activityPostResourceFactory
