var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.sectionSnapshotAfter
    ? _c(
        "span",
        [
          _c("span", [
            _vm._v("added a new "),
            _vm.sectionSnapshotAfter.is_submenu
              ? _c("span", [_vm._v("submenu")])
              : _vm._e(),
            _vm._v(" section "),
          ]),
          _vm._v(" "),
          _c(
            "strong",
            [
              _vm._v(
                "\n        #" +
                  _vm._s(_vm.sectionSnapshotAfter.id) +
                  "\n        "
              ),
              _c("wiki-log-possible-link", {
                attrs: {
                  target: _vm.wikiLog.target_wiki_section,
                  label: _vm.sectionSnapshotAfter.caption,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.sectionSnapshotAfter.parent_section_name
            ? _c("span", [
                _vm._v("\n        in "),
                _c(
                  "strong",
                  [
                    _vm._v(
                      "#" +
                        _vm._s(_vm.sectionSnapshotAfter.parent_section_id) +
                        "\n        "
                    ),
                    _c("wiki-log-possible-link", {
                      attrs: {
                        target: _vm.wikiLog.target_wiki_slide,
                        label: _vm.sectionSnapshotAfter.parent_section_name,
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.wikiLog.image_preview
            ? _c("wiki-log-image-preview", {
                attrs: { "wiki-log": _vm.wikiLog },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }