/**
 * Created by orion on 6/18/17.
 */
import angular from 'angular';
import Vue from "vue";
import renderActivityPostVue from './renderActivityPost.vue';

renderActivityPost.$inject = [];

function renderActivityPost() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            activityPost: '=',
        },
        link: function ($scope, element, attrs) {
            function initVue() {
                const vm = new Vue({
                    el: element[0],
                    render(createElement) {
                        const attrs = {
                            activityPost: $scope.activityPost,
                        };
                        return createElement(renderActivityPostVue, {attrs});
                    },
                });

                $scope.$on('$destroy', () => {
                    if (vm) {
                        vm.$destroy();
                    }
                });
            }

            initVue();

        },
        template: '<div></div>'
    };
}

angular.module('mmApp.activity.renderActivityPost', [])
    .directive('renderActivityPost', renderActivityPost);
export default 'mmApp.activity.renderActivityPost';