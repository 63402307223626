<template>
    <basic-modal
            @hide="close"
            v-model="opened"
            :aria-label="header"
            role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ header }}</h3>
        </div>

        <label>Story name:</label>
        <input class="form-control" v-model="story_name"/>

        <div slot="footer" class="row">
            <div class="col-sm-5 text-left">
                <button class="btn btn-mm btn-red" @click="remove">Delete Story</button>
            </div>
            <div class="col-sm-7 text-right">
                <button class="btn btn-mm btn-default" @click="close">Cancel</button>
                <button class="btn btn-mm btn-green" :disabled="story_name === initial_collection_name || !story_name"
                        @click="rename">Rename
                </button>
            </div>
        </div>

    </basic-modal>
</template>

<script>
    import PopupPromiseMixin from "~/vue/mixins/PopupPromiseMixin";

    export default {
        name: "StorySettingsOrDeletePopup",
        mixins: [PopupPromiseMixin],
        data() {
            return {
                header: "Story Settings",
                initial_collection_name: undefined,
                story_name: undefined,
            }
        },
        methods: {
            setInitial(data) {
                this.initial_collection_name = data.story_name;
                this.story_name = data.story_name;
            },
            remove() {
                this.save({action: 'remove'})
            },
            rename() {
                this.save({
                    action: 'rename',
                    name: this.story_name
                })
            }
        }

    }
</script>
