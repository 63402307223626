<template>
    <div class="search_input__search-block" :style="inputStyle">
        <div class="search_input__search-block__input" :style="inputStyle">
            <input v-model="search_query" id="search_query" @blur="onBlur" maxlength="100"
                   class="form-control" placeholder="Search" type="search">
        </div>
        <div class="search_input__search-block__button" @click="searchIconClicked">
            <div class="search_input__action-button__icon  search_input__search-block__button__icon action-button-blue">
                <i class="fa fa-search" aria-hidden="true">
                </i>
            </div>
        </div>
    </div>
</template>
<script>
    import * as _ from 'lodash';

    export default {
        emits: ['search', 'cancel'],
        data() {
            return {
                search_query: '',
                expanded: true
            };
        },
        props: {
            maxWidth: {
                type: [Number, String],
                default: 200
            },
            minLettersCount: {
                required: false,
                type: Number,
                default: 3
            },
            initialQuery: {
                required: false,
                type: String
            },
            collapsable: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            inputStyle() {
                if (this.expanded) {
                    let unit = 'px';
                    if (typeof this.maxWidth === 'string' && this.maxWidth.indexOf('%') !== -1) {
                        unit = '';
                    }
                    return {
                        'width': `${this.maxWidth}${unit}`,
                    };
                } else {
                    return {
                        'width': `${40}px`
                    };
                }
            }
        },
        methods: {
            onBlur() {
                if (this.search_query) {
                    return;
                } else {
                    if (this.collapsable) {
                        this.expanded = false;
                    }
                }
            },
            searchIconClicked() {
                if (!this.expanded) {
                    this.expanded = true;
                } else {
                    if (this.search_query) {
                        this.search_changed(true);
                    } else {
                        if (this.collapsable && this.expanded) {
                            this.expanded = false;
                        }
                    }

                }
            },
            search_changed(forsed_execute = false) {
                if (this.search_query.length === 0) {
                    if (!_.isUndefined(this._search_timeout_id)) {
                        clearTimeout(this._search_timeout_id);
                    }
                    this.$emit('cancel');
                }
                if (this.search_query.length < this.minLettersCount || this.search_query.length > 100) {
                    return;
                }
                if (!_.isUndefined(this._search_timeout_id)) {
                    clearTimeout(this._search_timeout_id);
                }
                if (forsed_execute) {
                    this.$emit('search', this.search_query);
                } else {
                    this._search_timeout_id = setTimeout(() => {
                        this.$emit('search', this.search_query);
                    }, 700);
                }

            }
        },
        watch: {
            search_query(nval, oldval) {
                if (!oldval && nval === this.initialQuery) {
                    return;
                }
                this.search_changed();
            }
        },
        created() {
            this.expanded = !this.collapsable;
            this.search_query = this.initialQuery || '';
            if (this.search_query) {
                this.expanded = true;
            }
        },
        name: 'VueSearchInput',
    };
</script>

<style scoped lang="scss">
  @import "../../angular/app/styles/const";

  .search_input {
    &__search-block {
      display: inline-block;
      vertical-align: top;
      position: relative;
      //min-width: $search-field-size + 120px;
      //float: right;
      height: 40px;

      &__input {
        display: inline-block;
        vertical-align: super;
        height: 40px;

        input {
          height: 40px;
          border-radius: 20px;

        }

        transition: width ease 0.2s;
      }

      &__button {
        //min-width: 110px;
        //cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;

        &__icon {
          cursor: pointer;
          display: inline-block;
          vertical-align: middle;
        }

        &__name {
          display: inline-block;
          vertical-align: middle;
          font-size: 12px;
        }
      }
    }

    &__action-button {
      vertical-align: top;
      display: inline-block;
      cursor: pointer;

      &__icon {
        margin: auto;
        //display: inline-block;
        color: #fff;
        height: 40px;
        width: 40px;
        font-size: 21px;
        line-height: 40px;
        text-align: center;

        border-radius: 50%;

        &.action-button-blue {
          background-color: $tag-gray;
        }
      }
    }
  }
</style>
