var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "event-detail-info" },
    [
      _c("div", { staticClass: "event-detail-info__grid__container" }, [
        _c(
          "div",
          { staticClass: "event-detail-info__grid__info-part" },
          [
            _c("div", {
              staticClass: "event-detail-info__avatar",
              style: _vm.avatarStyle,
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "event-detail-info__start-date-label margin-15-bottom",
              },
              [_vm._v(_vm._s(_vm.startDateLabel))]
            ),
            _vm._v(" "),
            _c("event-detail-page-info-create-entry-button", {
              attrs: { disabled: !_vm.membershipStatus.can_create_blog_entry },
              on: { click: _vm.showCreateEntryPopup },
            }),
            _vm._v(" "),
            _c("event-detail-page-info-entry-list", {
              ref: "event-detail-page-info-entry-list",
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "event-detail-info__grid__content-part" }, [
          _c("div", { staticClass: "event-detail-info__data" }, [
            _c(
              "div",
              { staticClass: "event-detail-info__data__description" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "event-detail-info__data__description__time-label",
                  },
                  [
                    _vm.event.event_data.location
                      ? _c(
                          "div",
                          {},
                          [
                            _c("strong", [_vm._v("Location:")]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.locationLabel) +
                                "\n                            "
                            ),
                            _c("location-with-marker", {
                              attrs: {
                                location: _vm.event.event_data.location,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.event.is_periodic
                      ? _c("div", {}, [
                          _c("strong", [_vm._v("Repeat:")]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.repeatLabel))]),
                          _vm._v(" "),
                          _vm.isAdmin
                            ? _c("i", {
                                staticClass: "fa fa-pencil pointer",
                                on: { click: _vm.editEventPeriod },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", { staticClass: "color-tag-gray" }, [
                            _vm._v("(since " + _vm._s(_vm.sinceLabel)),
                            _vm.additionalPeriodInfoAvailable
                              ? _c("span", [
                                  _c("i", {
                                    staticClass: "fal fa-history pointer",
                                    on: { click: _vm.showAdditionalPeriodInfo },
                                  }),
                                ])
                              : _vm._e(),
                            _vm._v(")"),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", {}, [
                      _c("strong", [_vm._v("Start:")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.startTimeLabel))]),
                    ]),
                    _vm._v(" "),
                    _c("div", {}, [
                      _c("strong", [_vm._v("Finish:")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.finishTimeLabel))]),
                    ]),
                    _vm._v(" "),
                    _vm.event.event_data.advanced_time_schedule_enabled
                      ? _c("div", {}, [
                          _c("strong", [
                            _vm._v("Schedule: "),
                            _vm.isAdmin
                              ? _c("i", {
                                  staticClass: "fa fa-pencil pointer",
                                  on: { click: _vm.quickEditSchedule },
                                })
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "3px" } },
                            _vm._l(
                              _vm.periodicEventScheduleItems,
                              function (scheduleItem) {
                                return _c(
                                  "div",
                                  {
                                    class: {
                                      "color-gray": !scheduleItem.isActive,
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(scheduleItem.weekdayLabel) + ":"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(scheduleItem.timeLabel)),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.event.event_data.description,
                        expression: "event.event_data.description",
                      },
                    ],
                    staticClass: "event-detail-info__data__description__header",
                  },
                  [_c("strong", [_vm._v("Description:")])]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "event-detail-info__data__description__text" },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.event.event_data.description) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isAdmin || _vm.attachments.length,
                        expression: "isAdmin||attachments.length",
                      },
                    ],
                    staticClass: "margin-15-top",
                  },
                  [_c("strong", [_vm._v("Attachments:")])]
                ),
                _vm._v(" "),
                _c("attachments-panel", {
                  ref: "attachments-panel",
                  attrs: {
                    "can-edit-attachments": _vm.isAdmin,
                    "target-id": _vm.targetEventId,
                    "attachments-resource": _vm.attachmentsResource,
                    size: 40,
                    margin: 0,
                  },
                }),
                _vm._v(" "),
                _vm.membershipStatus.owner_has_access_to_event
                  ? _c("event-detail-page-info-add-to-calendar-panel", {
                      ref: "addToCalendarPanel",
                      staticClass: "margin-15-top",
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.membershipStatus.owner_has_access_to_event
                  ? _c("event-reminder-panel", {
                      staticClass: "margin-15-top",
                      attrs: {
                        "event-id": _vm.eventId,
                        "date-for-api": _vm.dateForApi,
                        "date-moment": _vm.dateMoment,
                        "is-periodic": _vm.periodInfo.is_periodic,
                        "calendar-owner": _vm.calendarOwner,
                      },
                      on: { reminderCreated: _vm.onReminderCreated },
                    })
                  : _c("div", { staticClass: "margin-15-top" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.calendarOwner.name) +
                          " doesn't have access to this event. "
                      ),
                      _c("strong", [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.addOwnerToEvent.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "Add\n                        " +
                                _vm._s(_vm.calendarOwner.name) +
                                " to this event"
                            ),
                          ]
                        ),
                      ]),
                    ]),
                _vm._v(" "),
                _vm.membershipStatus.added_to_calendar
                  ? _c("event-detail-page-info-attendance-panel", {
                      staticClass: "margin-15-top",
                      attrs: { attendance: _vm.membershipStatus.attendance },
                      on: {
                        setAttendance: _vm.setAttendance,
                        unsetAttendance: _vm.unsetAttendance,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "margin-15-top" },
                  [
                    _vm.goingMembers.length
                      ? _c("div", {}, [
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "div",
                            {},
                            _vm._l(_vm.goingMembers, function (member) {
                              return _c("vue-stuff-preview", {
                                key: member.id,
                                attrs: {
                                  "stuff-item": member,
                                  size: 60,
                                  "display-name": false,
                                  "stuff-type": "user",
                                  deletable: false,
                                  animation: false,
                                  "online-mark": member.online,
                                },
                              })
                            }),
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("add-to-outer-calendars-panel", {
                      staticClass: "margin-10-bottom",
                      attrs: {
                        "event-id": _vm.eventId,
                        "date-for-api": _vm.dateForApi,
                        "is-periodic": _vm.periodInfo.is_periodic,
                        "date-moment": _vm.dateMoment,
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "event-detail-info__see-members-label margin-15-top",
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticStyle: {
                              "text-decoration": "underline",
                              color: "#333",
                            },
                            attrs: {
                              to: {
                                name: "members",
                                params: {
                                  date: _vm.date,
                                  calendarOwnerId: _vm.calendarOwner.id,
                                  calendarOwnerName: _vm.calendarOwner.name,
                                },
                                hash: _vm.$route.hash,
                              },
                            },
                          },
                          [_c("strong", [_vm._v("See all members")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "event-detail-info__see-members-label__invite-label",
                          },
                          [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.allowInviteMember,
                                    expression: "allowInviteMember",
                                  },
                                ],
                                staticStyle: { "margin-left": "15px" },
                                attrs: { href: "javascript:;" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.inviteMember.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fa fa-user-plus event-detail-info__see-members-label__invite-label__icon",
                                }),
                                _c("span", {
                                  staticStyle: { "text-decoration": "none" },
                                }),
                                _vm._v("Invite somebody"),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("edit-event-period-popup", { ref: "edit-event-period-popup" }),
      _vm._v(" "),
      _c("event-detail-page-create-diary-entry-popup", {
        ref: "create-entry-popup",
      }),
      _vm._v(" "),
      _c("event-recurrence-info-popup", { ref: "event-recurrence-info-popup" }),
      _vm._v(" "),
      _c("advanced-time-schedule-popup", {
        ref: "advanced-time-schedule-popup",
      }),
      _vm._v(" "),
      _c("update-repeated-event-type-selector-popup", {
        ref: "update-repeat-event-popup",
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [_c("strong", [_vm._v("Going")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }