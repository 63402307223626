var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.header)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("form", { attrs: { enctype: "multipart/form-data" } }, [
        _c("input", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.config.max_count > 1,
              expression: "config.max_count>1",
            },
          ],
          ref: "multiFileUploadInput",
          attrs: {
            accept: "image/*",
            type: "file",
            name: "file_multi",
            multiple: "",
          },
          on: { change: _vm.onUploadFileFieldChanged },
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.config.max_count === 1,
              expression: "config.max_count===1",
            },
          ],
          ref: "singleFileUploadInput",
          attrs: { accept: "image/*", type: "file", name: "file_single" },
          on: { change: _vm.onUploadFileFieldChanged },
        }),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-default",
            attrs: { type: "button" },
            on: { click: _vm.close },
          },
          [_vm._v("\n            Cancel\n        ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }