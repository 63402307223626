<template>
    <span v-if="slideSnapshotAfter">
        <span>updated page </span>
        <strong>
            #{{ slideSnapshotAfter.id }}
            <span v-if="slideSnapshotAfter.caption">
                <wiki-log-possible-link
                        :target="wikiLog.target_wiki_slide"
                        :label="slideSnapshotAfter.caption"
                ></wiki-log-possible-link>
            </span>
            <span v-else>
                <wiki-log-possible-link
                        :target="wikiLog.target_wiki_slide"
                        :label="`#${slideSnapshotAfter.id}`"
                        no-quotes
                ></wiki-log-possible-link>
            </span>
        </strong>
        <span v-if="slideSnapshotAfter.section_name">
            in <strong>#{{ slideSnapshotAfter.section }}
            <wiki-log-possible-link
                    :target="wikiLog.target_wiki_section"
                    :label="slideSnapshotAfter.section_name"
            ></wiki-log-possible-link>
        </strong>
        </span>
        <span v-if="verbalChangesSubtypes">({{ verbalChangesSubtypes }})</span>
        <wiki-log-image-preview v-if="wikiLog.image_preview" :wiki-log="wikiLog"></wiki-log-image-preview>
    </span>
</template>

<script>
    import SnapshotLogMixin from "./common/SnapshotLogMixin";
    import WikiLogImagePreview from "./common/WikiLogImagePreview";

    export default {
        name: "WikiSlideUpdatedParamsChanged",
        components: {WikiLogImagePreview},
        mixins: [SnapshotLogMixin]
    };
</script>

<style scoped>

</style>