import Vue from 'vue';

/*
to prepare component:
add getListEndpoint method
add getListEndpointArgs method
add <div v-infinite-scroll="loadMore"></div> to template bottom

params {limit,offset,query} should always be last in resource
sample: function get_pending_invites(user_id, params) {}
then : getListEndpointArgs(){return [this.user.id]}, getListEndpoint(){return UserResource.get_pending_invites}
 */
const InfiniteScrollMixin = {
    data() {
        return {
            limit: 10,
            allLoaded: false,
            totalCount: undefined,
            query: undefined,
            results: [],
            loading: false,
            getResultsOnMount: true,
            initialized: false,
            loadingBlock: false,
            showDimmerOnLoadMore: true,
        };
    },
    methods: {
        onSearch(val) {
            this.beforeSearchPerformedHook();
            this.query = val;
            this.resetResults();
            this.getResults(true);
        },
        onSearchCancel() {
            this.beforeSearchPerformedHook();
            this.query = undefined;
            this.resetResults();
            this.getResults(true);
        },
        beforeSearchPerformedHook() {

        },
        getListEndpoint() {
        },
        resultsInitializedHook() {
            this.$emit('resultsInitialized');
        },
        getListEndpointArgs() {
            return [];
        },
        getListEndpointAdditionalParams() {
            return {};
        },
        getListEndpointAdditionalHeaders() {
            return {};
        },
        getResults(showDimmer) {
            if (this.loadingBlock || this.allLoaded) {
                return;
            } else {
                this.loadingBlock = true;
                this.loading = true;
            }
            if (showDimmer) {
                this.$loadingSpinner.show();
            }
            let r = this.getListEndpoint();
            if (!r) {
                return;
            }
            return r(...[...this.getListEndpointArgs(), Object.assign({
                limit: this.limit,
                offset: this.results.length,
                query: this.query
            }, this.getListEndpointAdditionalParams()), this.getListEndpointAdditionalHeaders()])
                .then(resp => {
                        this.results = this.results.concat(resp.data.results);
                        this.totalCount = resp.data.count;
                        this.allLoaded = (this.results.length >= this.totalCount) || (resp.data.results.length === 0);
                        if (!this.initialized) {
                            this.resultsInitializedHook();
                        }
                        this.initialized = true;
                    },
                    err => {
                        Vue.notifications.error(err.data || 'Error - can\'t load');
                    })
                .finally(() => {
                    if (showDimmer) {
                        this.$loadingSpinner.hide();
                    }
                    this.loadingBlock = false;
                    this.loading = false;
                });
        },
        loadMore() {
            if (!this.initialized || this.allLoaded) {
                return;
            }
            this.getResults(this.showDimmerOnLoadMore);
        },
        resetResults() {
            this.loadingBlock = false;
            this.initialized = false;
            this.loading = false;
            this.allLoaded = false;
            this.totalCount = undefined;
            this.results = [];
        }
    },
    mounted() {
        if (this.getResultsOnMount) {
            this.getResults(true);
        }
    }

};

export default InfiniteScrollMixin;
