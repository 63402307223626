<template>
    <div class="">
        <community-page-alternative-header title="About Us"></community-page-alternative-header>
        <div class="row">
            <div class="col-sm-12 text-right">
                <vue-switch-with-label
                        v-if="viewerIsAdmin"
                        :label="'Edit Mode'"
                        name="edit_mode"
                        v-model="editMode"
                ></vue-switch-with-label>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <story-list
                        :story-resource="storyResource"
                        :target-id="community.id"
                        :allow-add-content="viewerIsAdmin"
                        :edit-mode="editMode"
                >
                    <div class="alert-info alert" slot="empty">There has been nothing added to this Community's story
                        yet
                    </div>
                </story-list>
            </div>
        </div>
    </div>

</template>

<script>
    import Vue from 'vue';
    import CommunityPageAlternativeHeader from "./CommunityPageAlternativeHeader.vue";
    import StoryList from "shared/story/StoryList";

    export default {
        name: "CommunityPageAboutUsStory",
        components: {StoryList, CommunityPageAlternativeHeader},
        data() {
            return {
                initialized: false,
                editMode: false,
            };
        },
        computed: {
            community() {
                return this.$store.getters['communityPage/community'];
            },
            viewerIsAdmin() {
                return this.$store.getters['communityPage/viewerIsAdmin'];
            },
            storyResource() {
                return Vue.getAngularModule('CommunityStoryResource');
            },
        },
        mounted() {
        },
        methods: {
        }
    };
</script>