/**
 * Created by orion on 6/19/17.
 */
let angular = require('angular');
simpleLink.$inject = ['$filter'];

function simpleLink($filter) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            target: '=',
            newTab: '@',
            cutName: '@',
            addBackHref: '=?',
            addBackHrefQuery: '=?',
        },
        link: function ($scope, element, attrs) {
            if (angular.isDefined(attrs.newTab)) {
                element[0].target = '_blank';
            } else {
                element[0].target = '_self';
            }
            if ($scope.target && $scope.target.deleted) {
                $scope.tooltip = 'This user has deleted their account';
            }
            const len = ($scope.cutName ? parseInt($scope.cutName) : 60);
            const dn = $filter('cutName')($scope.target ? $scope.target.name : '', len);
            $scope.displayName = dn;
            if ($scope.target) {
                $scope.link = $scope.target.link;
                if ($scope.addBackHref) {
                    $scope.link = $filter('backHref')($scope.link);
                } else if ($scope.addBackHrefQuery) {
                    $scope.link = $filter('backHrefQuery')($scope.link);
                }
            }
        },
        template: '<a ng-href="{$ link $}" uib-tooltip="{$ tooltip $}">{$ displayName $}</a>'
    };
}

angular.module('mmApp.stuff_previews.simpleLink', [])
    .directive('simpleLink', simpleLink);

export default 'mmApp.stuff_previews.simpleLink';