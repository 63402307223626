<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="title"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ title }}</h3>
        </div>
        <div class="row">
            <div class="col-xs-6" style="vertical-align: bottom; height: 32px">
                <div class="align-helper"> </div>
                <label v-if="label" style="vertical-align: bottom">{{ label }}</label>
            </div>
            <div class="col-xs-6 text-right" style="vertical-align: bottom;">
                <vue-switch-with-label label="Header" name="isHeader" v-model="isHeader"></vue-switch-with-label>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <textarea
                        id="itextarea"
                        ref="inputText"
                        class="form-control
                            input-text__textarea"
                        :class="{'input-text__textarea_header':isHeader}"
                        v-model="text"
                        rows="5"
                ></textarea>
            </div>
        </div>

        <div slot="footer">
            <div class="row">
                <div class="col-sm-12">
                    <button @click="close" type="button" class="btn btn-mm  btn-primary">Cancel</button>
                    <button @click="saveChanges" :disabled="saveDisabled" type="button"
                            class="btn btn-mm  btn-green">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import BasicModal from "../../../../vue/common_components/BasicModal";
    import PopupPromiseMixin from "../../../../vue/mixins/PopupPromiseMixin";

    export default {
        name: "AddTextPopup",
        mixins: [PopupPromiseMixin],
        components: {BasicModal},
        data() {
            return {
                text: '',
                isHeader: false,

                allowHeader: true,
                allowBlank: true,
                editing: false,

                label: '',
            };
        },
        computed: {
            saveDisabled() {
                return !this.text && !this.allowBlank;
            },
            title() {
                return this.editing ? 'Edit text' : 'Add text';
            },
            firstFocusEl() {
                return this.$refs.inputText;
            },
        },
        methods: {
            setInitial(initial = {text: '', is_header: false}, allowHeader = true, allowBlank = true, label = '') {
                this.text = initial.text;
                this.isHeader = initial.is_header;
                this.allowHeader = allowHeader;
                this.allowBlank = allowBlank;
                this.editing = !!initial.text;
                this.label = label;
            },
            saveChanges() {
                if (this.saveDisabled) {
                    return;
                }
                this.save({text: this.text, is_header: this.isHeader});
            },
        }
    };
</script>

<style scoped lang="scss">
  .input-text__textarea {
    height: 150px;

    &_header {
      font-size: 21px;
      font-weight: bolder;
    }
  }
</style>