var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "selector-popup",
    {
      ref: "popup",
      attrs: {
        header: "How you would like to add a picture?",
        items: _vm.items,
      },
      on: { selected: _vm.onSelected },
    },
    [
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-primary",
            attrs: { type: "button" },
            on: { click: _vm.back },
          },
          [_vm._v("Back")]
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "invisibleForms" }, [
        _c(
          "form",
          {
            staticStyle: { display: "none" },
            attrs: { enctype: "multipart/form-data" },
          },
          [
            _c("input", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.config.max_count > 1,
                  expression: "config.max_count>1",
                },
              ],
              ref: "multiFileUploadInput",
              attrs: {
                accept: "image/jpeg,image/png,image/gif,image/webp,image/bmp",
                type: "file",
                name: "file_multi",
                multiple: "",
              },
              on: { change: _vm.onUploadFileFieldChanged },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.config.max_count === 1,
                  expression: "config.max_count===1",
                },
              ],
              ref: "singleFileUploadInput",
              attrs: {
                accept: "image/jpeg,image/png,image/gif,image/webp,image/bmp",
                type: "file",
                name: "file_single",
              },
              on: { change: _vm.onUploadFileFieldChanged },
            }),
          ]
        ),
        _vm._v(" "),
        _c("form", { attrs: { enctype: "multipart/form-data" } }, [
          _c("input", {
            ref: "capturePictureHiddenButton",
            staticStyle: { display: "none" },
            attrs: {
              type: "file",
              name: "file",
              accept: "image/*",
              capture: "user",
            },
            on: { change: _vm.onFileFieldChanged },
          }),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }