var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      ref: "switchSpan",
      staticClass: "switch",
      class: _vm.classObject,
      attrs: {
        "aria-checked": _vm.value ? "true" : "false",
        role: "checkbox",
        tabindex: "0",
      },
      on: {
        click: function ($event) {
          return _vm.toggle_switch()
        },
        keydown: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.toggle_switch()
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
            )
              return null
            $event.preventDefault()
            return _vm.toggle_switch()
          },
        ],
      },
    },
    [
      _c("small"),
      _vm._v(" "),
      _c("input", {
        staticStyle: { display: "none" },
        attrs: { type: "checkbox", name: _vm.name },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "switch-text" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }