var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "header-panel",
      class: { "with-background": _vm.withBackground },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            class: _vm.get_left_part_size,
            staticStyle: { "padding-left": "22px", "padding-right": "0" },
            attrs: { slot: "panelLeft" },
            slot: "panelLeft",
          },
          [
            _vm._t("panelLeft", function () {
              return [
                _c(
                  "div",
                  { staticClass: "header-panel__header_title" },
                  [_vm._t("headerTitle")],
                  2
                ),
                _vm._v(" "),
                _c("div", [_vm._t("headerLabel")], 2),
              ]
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "header-panel__panel-right text-right no-padding",
            class: _vm.get_right_part_classes,
            staticStyle: { "padding-left": "0" },
          },
          [_vm._t("panelRight")],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }