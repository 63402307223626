var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.totalEntriesCount > 0,
              expression: "totalEntriesCount>0",
            },
          ],
          staticClass: "event-detail-info__entry-list",
        },
        [
          _vm.entryPreview
            ? _c(
                "div",
                {
                  staticClass:
                    "event-detail-info__entry-list__last-entry-preview",
                },
                [
                  _c(
                    "a",
                    {
                      staticStyle: { "text-decoration": "none" },
                      attrs: {
                        href: _vm._f("backHref")(_vm.entryPreview.link),
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "event-detail-info__entry-list__last-entry-preview__avatar",
                          style: _vm.entryPreview.avatarStyle,
                        },
                        [
                          _vm.entryPreview.sticker
                            ? _c("render-sticker", {
                                staticStyle: {
                                  width: "45px",
                                  height: "45px",
                                  position: "absolute",
                                  bottom: "0",
                                  right: "0",
                                },
                                attrs: { sticker: _vm.entryPreview.sticker },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "event-detail-info__entry-list__last-entry-preview__text",
                    },
                    [
                      _vm.entryPreview.text || _vm.entryPreview.emotion
                        ? _c(
                            "a",
                            {
                              staticStyle: {
                                "text-decoration": "none",
                                color: "#333",
                              },
                              attrs: {
                                href: _vm._f("backHref")(_vm.entryPreview.link),
                              },
                            },
                            [
                              _c("i", { staticClass: "fal fa-quote-left" }),
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm._f("cutName")(
                                      _vm.entryPreview.text ||
                                        _vm.entryPreview.emotion,
                                      110
                                    )
                                  ) +
                                  "\n                    "
                              ),
                              _c("i", { staticClass: "fal fa-quote-right" }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "event-detail-info__entry-list__last-entry-preview__text__author",
                        },
                        [
                          _vm._v("\n                    by "),
                          _c(
                            "strong",
                            [
                              _c("simple-link", {
                                attrs: { target: _vm.entryPreview.author },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.totalEntriesCount > 1,
                  expression: "totalEntriesCount>1",
                },
              ],
              staticClass:
                "event-detail-info__entry-list__more-link text-center pointer",
              on: { click: _vm.showAllEntries },
            },
            [
              _vm._v(
                "\n            Show " +
                  _vm._s(_vm.totalEntriesCount - 1) +
                  " more...\n        "
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.totalNotesCount > 0
        ? _c("div", { staticClass: "event-detail-info__notes-list" }, [
            _vm.notePreview.text
              ? _c(
                  "a",
                  {
                    staticStyle: { "text-decoration": "none", color: "#333" },
                    attrs: { href: _vm._f("backHref")(_vm.notePreview.link) },
                  },
                  [
                    _c("i", { staticClass: "fal fa-quote-left" }),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm._f("cutName")(_vm.notePreview.text, 105)) +
                        "\n            "
                    ),
                    _c("i", { staticClass: "fal fa-quote-right" }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "event-detail-info__notes-list__author" },
              [
                _vm._v("\n            In "),
                _c("strong", [_vm._v(_vm._s(_vm.notePreview.logName))]),
                _vm._v(" Log by\n            "),
                _c(
                  "strong",
                  [
                    _vm.notePreview.author.link
                      ? _c("simple-link", {
                          attrs: { target: _vm.notePreview.author },
                        })
                      : _c("span", [
                          _vm._v(_vm._s(_vm.notePreview.author.name)),
                        ]),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.totalNotesCount > 1,
                    expression: "totalNotesCount>1",
                  },
                ],
                staticClass:
                  "event-detail-info__notes-list__more-link text-center pointer",
                on: { click: _vm.showAllNotes },
              },
              [
                _vm._v(
                  "\n            Show " +
                    _vm._s(_vm.totalNotesCount - 1) +
                    " more...\n        "
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showAllEntriesPopupOpened
        ? _c("event-detail-page-info-entry-list-show-all-popup", {
            ref: "show-all-entries-popup",
            attrs: {
              event: _vm.event,
              "calendar-owner": _vm.calendarOwner,
              "date-moment": _vm.dateMoment,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showAllNotesPopupOpened
        ? _c("event-detail-page-info-entry-list-show-all-notes-popup", {
            ref: "show-all-notes-popup",
            attrs: {
              event: _vm.event,
              "calendar-owner": _vm.calendarOwner,
              "date-moment": _vm.dateMoment,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }