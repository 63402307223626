activityPostGoalsFactory.$inject = ['notifications', '$rootScope', 'simplePopupFactory', '$log', '$q', 'UserActivityPostGoalsResource', 'selectFactory', 'linkToGoalFactory',];
import BlogEntryGoalsListPopupController from '../../blogolog/blog_entry_goals_list/goals_list_popup_controller';
import * as _ from 'lodash';
import {customTermFilter} from '../../../../vue/filters/CustomTerm';

function activityPostGoalsFactory(notifications, $rootScope, simplePopupFactory, $log, $q, UserActivityPostGoalsResource, selectFactory, linkToGoalFactory) {

    class UserActivityPostGoals {
        constructor(id, initial_linked_tasks_count = 0, allow_remove = false, for_user = undefined, post = undefined) {
            this.id = id;
            this.linked_tasks_count = initial_linked_tasks_count;
            this.allow_remove = allow_remove;
            this.allow_create = allow_remove; // same rule as above
            this.for_user = for_user;
            this.post = post;
        }

        get_linked_tasks_count() {
            return this.linked_tasks_count;
        }

        show_list() {
            let modalResultPromise = simplePopupFactory.create_popup(BlogEntryGoalsListPopupController,
                require('../../blogolog/blog_entry_goals_list/goals_list_popup.html'), {
                    linked_goals: UserActivityPostGoalsResource.get_linked_goals(this.id),
                    ALLOW_ADD: true,
                    ALLOW_REMOVE: this.allow_remove,
                    ALLOW_VIEW_GOAL: true
                });

            modalResultPromise.then(res => {
                if (res.do === 'link_goal') {
                    link_userpost_to_goal(this.id, this.allow_create, this.for_user).then(linked_tasks_count => {
                        this.linked_tasks_count = linked_tasks_count;
                        if (this.post) {
                            this.post.goals_links_count = this.linked_tasks_count;
                        }
                        this.show_list();
                    }, () => {
                        this.show_list();
                    });
                } else {
                    if (res.do === 'delete_goal') {
                        let goal_id = res.id;
                        simplePopupFactory.show_popup(`Remove All ${customTermFilter('Goals')} ${customTermFilter('Tasks')}`, `Are you sure you want to unlink all ${customTermFilter('Goal')} ${customTermFilter('Tasks')} from this Update?`, 'Yes', 'No')
                            .then(() => {
                                $rootScope.show_dimmer();
                                return UserActivityPostGoalsResource.unlink_all_blog_tasks(this.id, goal_id);
                            }, () => {
                                this.show_list();
                                return  new Promise(()=>{});
                            })
                            .then(
                                resp => {
                                    this.linked_tasks_count = resp.data.goals_links_count;
                                    if (this.post) {
                                        this.post.goals_links_count = this.linked_tasks_count;
                                    }
                                    notifications.success('Removed');
                                    this.show_list();
                                },
                                err => {
                                    notifications.error(err || 'Error');
                                }
                            ).finally($rootScope.hide_dimmer);
                    }
                }
            });
        }
    }

    return {UserActivityPostGoals};

    function link_userpost_to_goal(entry_id, allow_create, for_user) {
        const res = $q.defer();

        const tasts_to_link_id_list_promise = linkToGoalFactory.link_to_goal(UserActivityPostGoalsResource, entry_id, allow_create, for_user);

        tasts_to_link_id_list_promise
            .then(res => {
                $rootScope.show_dimmer();
                let new_checked_ids = res[0];
                let new_unchecked_ids = res[1];
                $log.debug('res-->', res);
                return UserActivityPostGoalsResource.link_to_task(entry_id, new_checked_ids, new_unchecked_ids);
            }, () => new Promise(()=>{}))
            .then((resp) => {
                res.resolve(resp.data.goals_links_count);
                notifications.success('Linked');
            }, err => {
                notifications.error(err || 'Error');
            })
            .finally(() => $rootScope.hide_dimmer())
        ;


        return res.promise;
    }
}

export default activityPostGoalsFactory;