import Vue from 'vue';
import placeFinder from './placeFinder.vue';

placeFinderDirective.$inject = [];

function placeFinderDirective() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            control: '='
        },
        template: `<div id="place-finder"></div>`,
        link: function ($scope, element, attrs, controller) {
            let vm = new Vue({
                components: {placeFinder},
                el: '#place-finder',
                template: `
                  <place-finder></place-finder>`
            });
            $scope.$on('$destroy', () => {
                if (vm) {
                    vm.$destroy();
                }
            });
        }
    };
}

export default placeFinderDirective;