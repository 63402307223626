<template>
    <div>
        <div class="">Remind me about this Event</div>
        <div class="color-tag-gray-dark pointer" v-if="!reminder">
            <Dropdown ref="dropdown">
            <span
                    tabindex="0"
                    role="button"
                    ref="dropdown-trigger"
                    @keydown.enter.stop.prevent="$refs['dropdown-trigger'].click();"
            >
                <i class="far fa-alarm-clock" aria-hidden="true"></i> Set up a reminder
            </span>
                <template #dropdown>
                    <li class="dropdown-time-selector-header" disabled>
                        <span class="margin-5-left inline-block">Remind me</span>
                    </li>
                    <li role="separator" class="divider"></li>
                    <li class="dropdown-time-selector">
                        <a role="button"
                           @click="onSetReminder(POSSIBLE_REMINDER_GAPS.AS_STARTS)">As it starts</a>
                    </li>
                    <li class="dropdown-time-selector">
                        <a role="button"
                           @click="onSetReminder(POSSIBLE_REMINDER_GAPS.FIFTEEN_MINUTES_BEFORE)">15 Minutes before</a>
                    </li>
                    <li class="dropdown-time-selector">
                        <a role="button"
                           @click="onSetReminder(POSSIBLE_REMINDER_GAPS.HOUR_BEFORE)">1 Hour before</a>
                    </li>
                    <li class="dropdown-time-selector">
                        <a role="button"
                           @click="onSetReminder(POSSIBLE_REMINDER_GAPS.DAY_BEFORE)">1 Day before</a>
                    </li>
                </template>
            </Dropdown>
        </div>
        <div v-else>
            <div class="color-grass-green-dark pointer"
                 role="button"
                 tabindex="0"
                 @click="cancelReminder"
                 @keydown.enter.prevent.stop="cancelReminder"
            >
                <i class="far fa-alarm-clock" aria-hidden="true"></i>
                <span>{{ reminderLabel }}</span>
            </div>
        </div>
        <event-detail-page-period-selector-popup
                ref="join-type-selector"
        ></event-detail-page-period-selector-popup>
    </div>
</template>

<script>
    import event_reminder_resource from "./event_reminder_resource";
    import EventDetailPagePeriodSelectorPopup from "../new_event/EventDetailPagePeriodSelectorPopup";
    import {simplePopupFactory} from "shared/simple_popups/simplePopupFactory";
    import {CurrentUserClass} from "shared/CurrentUser";

    const POSSIBLE_REMINDER_GAPS = {
        AS_STARTS: 0,
        FIFTEEN_MINUTES_BEFORE: 1,
        HOUR_BEFORE: 2,
        DAY_BEFORE: 3,
    };

    const POSSIBLE_TYPES = {
        SINGLE_EVENT: 0,
        EXACT_DATE: 1,
        ALL_OCCURRENCES: 2,
    };

    export default {
        name: "eventReminderPanel",
        components: {EventDetailPagePeriodSelectorPopup},
        props: {
            eventId: {
                type: [Number, String],
            },
            dateMoment: {
                type: Object
            },
            calendarOwner: {
                type: Object
            },
            dateForApi: {
                type: String,
            },
            isPeriodic: {
                type: Boolean,
            },
        },
        data() {
            return {
                reminder: undefined,
                POSSIBLE_REMINDER_GAPS
            };
        },
        computed: {
            reminderLabel() {
                let label = `We will remind ${(new CurrentUserClass().id === this.calendarOwner.id) ? 'you' : this.calendarOwner.name} about this Event `;
                if (this.reminder.reminder_gap === POSSIBLE_REMINDER_GAPS.AS_STARTS) {
                    label += 'as it starts';
                } else if (this.reminder.reminder_gap === POSSIBLE_REMINDER_GAPS.FIFTEEN_MINUTES_BEFORE) {
                    label += '15 minutes before it starts';
                } else if (this.reminder.reminder_gap === POSSIBLE_REMINDER_GAPS.HOUR_BEFORE) {
                    label += '1 hour before it starts';
                } else if (this.reminder.reminder_gap === POSSIBLE_REMINDER_GAPS.DAY_BEFORE) {
                    label += '1 day before it starts';
                }
                return label;
            },
        },
        methods: {
            async checkReminderState() {
                const {data: reminder} = await event_reminder_resource.check(this.eventId, this.dateForApi, this.calendarOwner.id);
                this.reminder = reminder;
            },
            async onSetReminder(gap) {
                if (this.isPeriodic && this.dateMoment) {
                    const periodType = await this.$refs['join-type-selector'].show(
                        this.dateMoment,
                        'Remind about Event',
                        'This is a repeated Event. You can a set reminder of this event for every occurrence or just this date'
                    );
                    if (periodType === 'exact') {
                        const {data: reminder} = await event_reminder_resource.set(this.eventId, this.dateForApi, this.calendarOwner.id, POSSIBLE_TYPES.EXACT_DATE, gap)
                            .catch(this.$notifications.error);
                        this.reminder = reminder;
                        this.$emit('reminderCreated', periodType);
                        this.$notifications.success('Reminder created');

                    }
                    if (periodType === 'whole') {
                        const {data: reminder} = await event_reminder_resource.set(this.eventId, this.dateForApi, this.calendarOwner.id, POSSIBLE_TYPES.ALL_OCCURRENCES, gap)
                            .catch(this.$notifications.error);
                        this.reminder = reminder;
                        this.$emit('reminderCreated', periodType);
                        this.$notifications.success('Reminder created');
                    }
                } else {
                    const {data: reminder} = await event_reminder_resource.set(this.eventId, this.dateForApi, this.calendarOwner.id, POSSIBLE_TYPES.SINGLE_EVENT, gap)
                        .catch(this.$notifications.error);
                    this.reminder = reminder;
                    this.$notifications.success('Reminder created');
                }
            },
            async cancelReminder() {
                const confirmed = await simplePopupFactory.show_popup(
                    'Remove Reminder',
                    'Are you sure you want to remove this reminder?',
                    'Remove',
                    'Cancel',
                    'btn-red').catch(() => {
                });
                if (!confirmed) {
                    return;
                }
                this.$loadingSpinner.show();
                try {
                    await event_reminder_resource.cancel(this.reminder.id);
                    this.reminder = undefined;
                    this.$notifications.success('Reminder cancelled');
                } catch (e) {
                    this.$notifications.error(e);
                }
                this.$loadingSpinner.hide();
            }
        },
        mounted() {
            this.checkReminderState();
        }
    };
</script>

<style scoped lang="scss">
  @import "styles/const.scss";

  li.dropdown-time-selector > a {
    &:hover, &:active, &:focus {
      background-color: $gray !important;
      background-image: none !important;
    }

    font-size: $bigger-font-size;
  }

  li.dropdown-time-selector-header {
    font-size: $bigger-font-size;
  }
</style>