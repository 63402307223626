activityPostLocationsFactory.$inject = ['notifications', '$rootScope', 'simplePopupFactory', '$log', '$q', 'UserActivityPostManageResource', 'selectFactory', 'Maps'];
import * as _ from 'lodash';
import mapsStoryLocationPopup from '../../blogolog/blog_entry_list/locations_popup/controller';

function activityPostLocationsFactory(notifications, $rootScope, simplePopupFactory, $log, $q, UserActivityPostManageResource, selectFactory, Maps) {

    class UserActivityPostLocations {
        constructor(id, location_points, allow_edit = false, resource = UserActivityPostManageResource, post = undefined) {
            this.id = id;
            this.location_points = location_points;
            this.allow_edit = allow_edit;
            this.resource = resource;
            this.post = post;
            this._location_points_count = 0;
        }

        set location_points_count(val) {
            this._location_points_count = val;
            if (this.post) {
                this.post.location_points_count = val;
            }
        }

        get location_points_count() {
            return this._location_points_count;
        }

        get_linked_locations_count() {
            return this.location_points.length;
        }

        add_place(assistant={}) {
            let result = $q.defer();
            Maps.find_location()
                .then(location => {
                    $rootScope.show_dimmer();
                    return this.resource.link_to_location(this.id, location.pk, assistant);
                }, () => new Promise(() => {
                }))
                .then(resp => {
                    this.location_points.push(resp.data);
                    this.location_points_count = this.location_points.length;
                    result.resolve(resp.data);
                    notifications.success('Created');
                }, err => {
                    console.error(err);
                    notifications.error(err || 'Error');
                })
                .catch(console.error)
                .finally(() => $rootScope.hide_dimmer());
            return result.promise;
        };

        show_list() {
            let modalResultPromise = simplePopupFactory.create_popup(mapsStoryLocationPopup, require('../../blogolog/blog_entry_list/locations_popup/template.html'), {
                locations: this.location_points,
                ALLOW_ADD: this.allow_edit,
                ALLOW_REMOVE: this.allow_edit,
            });

            modalResultPromise.then(res => {
                if (res.do === 'add location') {
                    this.add_place().then(() => {
                        this.show_locations();
                    });
                } else {
                    if (res.do === 'delete location') {
                        let location_id = res.location_id;
                        $rootScope.show_dimmer();
                        this.resource.unlink_location(this.id, location_id).then(
                            resp => {
                                _.remove(this.location_points, {id: location_id});
                                this.location_points_count = this.location_points.length;
                                notifications.success('Removed');
                                this.show_locations();
                            },
                            err => {
                                notifications.error(err || 'Error');
                            }
                        ).finally(() => $rootScope.hide_dimmer());
                    }
                }
            });
        }
    }

    return {UserActivityPostLocations};
}

export default activityPostLocationsFactory;
