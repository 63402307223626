<template>
    <div class="render-post-component" style="max-height: inherit">
        <div class="row" v-if="post.sticker">
            <div class="col-sm-12 text-center">
                <render-sticker :sticker="post.sticker"></render-sticker>
            </div>
        </div>
        <!--        <div class="row" v-if="post.text&&post.sticker">-->
        <!--            <div class="col-sm-12">-->
        <!--                <hr style="margin: 10px 20px">-->
        <!--            </div>-->
        <!--        </div>-->
        <div class="row" v-if="post.text">
            <div class="col-sm-12">
                <div class="render-post-component__text-container"
                     :class="{'with-border': ((addBorderAroundText===BORDER_MODE.WITH_OTHER_CONTENT)&&(post.media_attachment||post.sticker))||(addBorderAroundText===BORDER_MODE.ALWAYS)}">
                    <p v-if="!post.is_header" style="text-align: left; font-size: 18px; white-space: pre-line;"
                       v-html="linkedText">
                    </p>
                    <p v-if="post.is_header" v-html="linkedText"
                       style="font-size: 24px; white-space: pre-line;">
                    </p>
                </div>
            </div>
        </div>
        <div class="row" v-if="post.media_attachment" style="max-height: inherit">
            <div class="col-sm-12" style="max-height: inherit;" :style="mediaContainerStyle">
                <vue-inline-media :item="post.media_attachment" :max-height="maxMediaHeight" v-bind="$attrs"></vue-inline-media>
            </div>
        </div>
        <div class="row" v-if="post.emotion">
            <hr style="margin: 10px 20px" v-if="post.sticker||post.text">
            <div class="col-sm-12 text-right" style="padding-right: 30px">
                <entry-emotion :size="36" :emotion="post.emotion"></entry-emotion>
            </div>
        </div>
    </div>
</template>

<script>
    import Autolinker from "autolinker";
    import RenderSticker from "../../add_media/stickers/render_sticker_directive/RenderSticker";
    import VueInlineMedia from "../../view_media/vue_inline_media_render_directive/VueInlineMedia";
    import EntryEmotion from "../../blogolog/emotion_directive/EntryEmotion";

    const BORDER_MODE = {
        ALWAYS: 'ALWAYS',
        WITH_OTHER_CONTENT: 'WITH_OTHER_CONTENT',
        NEVER: 'NEVER',
    };

    export default {
        name: "RenderPost",
        components: {EntryEmotion, VueInlineMedia, RenderSticker},
        props: {
            post: Object,
            maxMediaHeight: {
                type: String,
                required: false,
            },
            addBorderAroundText: {
                type: String,
                default: BORDER_MODE.ALWAYS
            },
            mediaHeight: {
                type: String,
                required: false,
            }
        },
        data() {
            return {
                BORDER_MODE
            };
        },
        computed: {
            mediaContainerStyle() {
                if (this.mediaHeight) {
                    if (['image', 'youtube_video', 'upl_video'].includes(this.post.media_attachment.type)) {
                        return {height: this.mediaHeight};
                    }
                }
                return {};
            },
            linkedText() {
                if (!this.post || !this.post.text) {
                    return '';
                }
                return Autolinker.link(this.post.text, {truncate: 30});
            }
        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";

  .render-post-component {
    &__text-container {
      &.with-border {
        border: solid 1px $lightgray;
        margin: 15px 0;
      }

      padding: 15px;
      //margin: 5px;
      border-radius: 5px;
      font-size: 18px;
      text-align: justify;
      word-break: break-word;

      span, h3 {
        white-space: pre-line;
      }

    }

    img {
      //max-width: 500px;
      max-height: 600px;
    }

    .vue-youtube-inline {
      //max-width: 500px;
      min-height: 500px;
    }
  }

  .render-post-component ::v-deep{
    .vue-youtube-inline{
      min-height: 400px;
    }
  }

</style>