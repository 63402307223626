/**
 * Created by orion on 29.05.17.
 */
collectionSettingsOrDeletePopupController.$inject = ['$scope', '$uibModalInstance', '$timeout', '$log', 'collection_name'];
function collectionSettingsOrDeletePopupController($scope, $uibModalInstance, $timeout, $log, collection_name) {
    $scope.close = function () {
        $uibModalInstance.dismiss('closed');
    };

    $scope.initial_collection_name = collection_name;
    $scope.collection_name = collection_name;

    $scope.remove = function () {
        $uibModalInstance.close({action: 'remove'});
    };

    $scope.rename = function () {
        $uibModalInstance.close({
            action: 'rename',
            name: $scope.collection_name
        });
    };

}

export default collectionSettingsOrDeletePopupController