<template>
    <div class="users-circles-list">
        <div class="users-circles-list__image-part">
            <a :href="info_link" v-tooltip="'Go to About Page'">
                <img :src="user.avatar" class="img-circle users-circles-list__image-part__image"/></a>
        </div>
        <div class="users-circles-list__content-part">
            <a :href="circle_link">
                <img :src="icon"
                     class="users-circles-list__content-part__icon" alt="" v-tooltip="'Go to Circle Members Page'">
            </a>
            <a :href="circle_link">
                {{user.name}}'s Circle
            </a>
        </div>
    </div>
</template>

<script>
    import urls from 'DjangoUrls';
    import {DASHBOARD_ICONS, DASHBOARD_ITEMS} from "../dashboard/dashboard";

    let {DjangoUrls} = urls;

    export default {
        props: {
            user: {
                type: Object
            }
        },
        computed: {
            icon() {
                return DASHBOARD_ICONS[DASHBOARD_ITEMS.CIRCLE];
            },
            circle_link() {
                return DjangoUrls['user_detail'](this.user.name) + 'circle/list';
            },
            info_link() {
                return DjangoUrls['user_detail'](this.user.name) + '';
            },
        },
        name: "users-circles-list"
    };
</script>

<style scoped lang="scss">
    @import "../styles/mixins.scss";

    .users-circles-list {
        @include managedUserInList();
    }

</style>