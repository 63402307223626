<template>
    <div class="group-event-item" style="position: relative">
        <div class="group-event-item__preview">
            <div class="group-event-item__avatar" :style="avatarStyle">
                <i class="fa fa-repeat group-event-item__avatar__repeat-mark" v-if="showPeriodicMark"></i>
            </div>
            <div class="group-event-item__date">{{greenBoxDateLabel}}</div>
        </div>
        <span class="group-event-item__icon-delete pointer" v-if="deletable" @click="deleteEvent()">
        <!--<i class="fa" ng-class="get_delete_button_icon_class()"></i>-->
    </span>
        <span class="group-event-item__name">
            <router-link :to="eventLink | backHrefQuery" v-if="routerNavigation">{{event.event_data.name}}</router-link>
            <a :href="eventLink | backHrefQuery" v-else>{{event.event_data.name}}</a>
            <i v-show="event.is_private" class="fa fa-eye-slash color-dark-blue"></i>
        </span><br>
        <strong>{{inListTimeRepresentationLabel}} </strong><br>
        <p v-show="showMembershipState&&event.is_member">
            <span><i class="fa fa-check color-grass-green"></i> This Event has been added to your Calendar.</span>
        </p>
        <p v-show="showMembershipState&&!event.is_member">
            <span><i class="fa fa-times color-tag-gray"></i> This Event has not been added to your Calendar.</span>
        </p>
        <p>
            <i>{{event.event_data.description | cutName(300)}}</i>
        </p>
    </div>
</template>

<script>
    import moment from 'moment';
    import * as config from '../../events/new_calendar/config';
    import * as common from '../../events/new_calendar/common';
    import eventCommonFieldsMixin from "../../events/eventCommonFieldsMixin";

    export default {
        name: "GroupEventsPageEvent",
        mixins: [eventCommonFieldsMixin],
        props: {
            event: Object,
            deletable: {
                default: false
            },
            routerNavigation: {
                type: Boolean,
                default: false,
            },
            showMembershipState: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            eventLink() {
                let formattedDate;
                if (this.startDateMoment.isValid()) {
                    formattedDate = this.startDateMoment.format(config.URL_DATE_FORMAT);
                }
                return common.getEventUrl(this.event, formattedDate);
            },
            showPeriodicMark() {
                return this.event.is_periodic;
            },
            avatarStyle() {
                return {
                    'background-image': `url(${this.event.event_data.avatar_url})`
                };
            },
            deleteButtonHint() {

            },
            startDateMoment() {
                return moment(this.event.start_date, config.VISUAL_DATE_FORMAT);
            },
            greenBoxDateLabel() {
                return this.startDateMoment.format('MMM Do');
            }
        },
        methods: {
            deleteEvent() {

            }
        }
    };
</script>

<style lang="scss">
    @import "../../styles/const";
    @import "../../styles/mixins";

    .group-event-item {
        @include wide_item_container(145px);
        padding: 10px;

        &__preview {
            float: left;
            margin-right: 15px;
        }

        &__avatar {
            width: 100px;
            height: 100px;
            border-radius: 5px;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;

            &__repeat-mark {
                position: absolute;
                left: -5px;
                top: -5px;
                font-size: 22px;
                color: white;
                width: 30px;
                height: 30px;
                line-height: 30px;
                background-color: $blue;
                display: inline-block;
                border-radius: 50%;
                text-align: center;
            }
        }

        &__date {
            margin-top: 3px;
            border-radius: 3px;
            background-color: $color-event;
            color: white;
            text-align: center;
            font-size: 16px;
            font-weight: bolder;
        }

        &__name {
            font-size: 20px;
            font-weight: bold;
            padding-right: 35px;
        }

        &__icon-delete {
            display: inline-block;
            width: 30px;
            height: 25px;
            background-color: $red;
            color: white;
            text-align: center;
            line-height: 30px;
            position: absolute;
            right: 10px;
            top: 10px;
            border-radius: 3px;

            i {
                font-size: 20px;
            }
        }

        p {
            font-size: 16px;
        }

    }
</style>