import angular from 'angular';
import urls from 'DjangoUrls';
import * as _ from 'lodash'

let {DjangoUrls} = urls;
UserProfileGroupsResource.$inject = ['$http'];

function UserProfileGroupsResource($http) {
    return {
        get_users_groups,
        create_group,
        delete_group,
        edit_group
    };

    function get_users_groups(user_id, params) {
        const url = DjangoUrls["api.v1:circle_groups-get-users-groups"]();
        return $http({
            method: 'GET',
            url,
            params: _.extend({}, {id: user_id}, params)
        });
    }

    function create_group(user_id, data) {
        const url = DjangoUrls["api.v1:circle_groups-create-group"]();
        return $http({
            method: 'POST',
            url,
            data: _.extend({}, data, {id: user_id})
        });
    }

    function delete_group(group_id) {
        const url = DjangoUrls["api.v1:circle_groups-delete-group"](group_id);
        return $http({
            method: 'DELETE',
            url
        });
    }

    function edit_group(group_id, data) {
        const url = DjangoUrls["api.v1:circle_groups-edit-group"](group_id);

        return $http({
            method: 'PATCH',
            url,
            data
        });
    }


}

export default UserProfileGroupsResource;

