/**
 * Created by orion on 6/19/17.
 */
/**
 * Created by orion on 04.06.17.
 */
let angular = require('angular');
import './style.scss'
function renderQuote() {
    return {
        restrict: 'E',
        replace: true,
        transclude: {
            comment: '?comment',
            content: 'content',
        },
        scope: {
            limitSize: '=',
            showShadow: '='
        },
        template: require('./template.html'),
        link: function ($scope, element, attrs, controller) {

        }
    };
}

angular.module('mmApp.shared.renderQuoteDirective', [])
    .directive('renderQuote', renderQuote);

export default 'mmApp.shared.renderQuoteDirective'