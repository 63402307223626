import './folderItem.scss';

folderItem.$inject = ['mediaContentUtils', 'FolderItemResources', 'simplePopupFactory', 'notifications'];

function folderItem(mediaContentUtils, FolderItemResources, simplePopupFactory, notifications) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            item: '=',//'folder' or ....
            editForbidden: '=',
            size: '@?'
        },
        template: require('./folderItem.html'),
        link: function ($scope, element, attrs, controller) {
            $scope.edit_mode_active = false;
            $scope.edit_mode = function (enabled) {
                $scope.edit_mode_active = enabled;
                $scope.item.new_name = $scope.item.item.name;
            };

            $scope.item.get_style = function () {
                return mediaContentUtils.generate_background(this.item);
            };

            $scope.save_name = function (event) {

                if (event && event.which !== 13) {
                    return;
                }

                if ($scope.item.new_name.length > 0 && $scope.item.type != 'folder' && $scope.item.new_name.length < 127) {
                    if ($scope.item.new_name !== $scope.item.item.name) {
                        const old_name = $scope.item.item.name;
                        FolderItemResources.rename_media($scope.item.item.pk, $scope.item.new_name).then(function () {
                            let what = {'folder': 'Folder', 'upl_video': 'Video', 'image': 'Image'};
                            notifications.success(what[$scope.item.item.type] + ' ' + old_name + ' has been renamed to ' + $scope.item.new_name + '.');
                        }, function (error) {
                            notifications.error(error.data.name);
                        });
                        $scope.item.item.name = $scope.item.new_name;

                    }
                    $scope.edit_mode_active = false;
                } else {
                    simplePopupFactory.show_popup('Warning', 'Name can\'t be empty or longer then 127 characters', 'OK', 'Cancel rename').then(
                        function () {

                        }, function () {
                            $scope.edit_mode_active = false;
                        }
                    );
                }

            };

            $scope.folder_item_click = function () {
                $scope.item.onclick();
            };
            $scope.folder_item_dblclick = function () {
                $scope.item.ondblclick();
            };
            // console.log(scope.item);
        }


    };
}

export default folderItem;