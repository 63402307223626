/**
 * Created by orion on 6/24/17.
 */
import urls from 'DjangoUrls';
import axios from 'axios';

let { DjangoUrls } = urls;
WikiMembershipResource.$inject = ['$http'];

function WikiMembershipResource ($http) {
    return {
        get_members,
        get_wiki_tree,
        set_permission,
        get_members_permissions,
        make_admin,
        unmake_admin,
        remove_member,
        update_access_link_term,
        protect_link_by_password,
    };

    ///////////////////

    function get_members (wiki_id) {
        const url = DjangoUrls['api.v1:wiki_membership-get-members']();

        return axios({
            method: 'get',
            url,
            params: {
                id: wiki_id
            }
        });
    }

    function get_wiki_tree (wiki_id) {
        const url = DjangoUrls['api.v1:wiki_membership-get-wiki-tree']();

        return axios({
            method: 'get',
            url,
            params: {
                id: wiki_id
            }
        });
    }

    function get_members_permissions (membership_id) {
        const url = DjangoUrls['api.v1:wiki_membership-get-members-permissions'](membership_id);
        return axios({
            method: 'get',
            url
        });
    }

    function make_admin (membership_id) {
        const url = DjangoUrls['api.v1:wiki_membership-make-admin'](membership_id);
        return axios({
            method: 'patch',
            url
        });
    }

    function unmake_admin (membership_id) {
        const url = DjangoUrls['api.v1:wiki_membership-unmake-admin'](membership_id);
        return axios({
            method: 'patch',
            url
        });
    }

    function remove_member (membership_id) {
        const url = DjangoUrls['api.v1:wiki_membership-remove-member'](membership_id);
        return axios({
            method: 'delete',
            url
        });
    }

    function set_permission (membership_id, level, value, target_id) {
        const url = DjangoUrls['api.v1:wiki_membership-set-permission'](membership_id);

        return axios({
            method: 'post',
            url,
            data: {
                level,
                value,
                target_id
            }
        });
    }

    function update_access_link_term (membership_id, term_days) {
        const url = DjangoUrls[`api.v1:wiki_membership-update-access-link-term`](membership_id);

        return axios({
            method: 'patch',
            url,
            data: {
                term_days
            }
        });
    }

    function protect_link_by_password (memberId) {
        const url = DjangoUrls[`api.v1:wiki_membership-protect-link-by-password`](memberId);
        return axios({
            method: 'PATCH',
            url,
        });
    }

}

export default WikiMembershipResource;
