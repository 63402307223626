var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vue-simple-header", [
        _c("span", { attrs: { slot: "headerTitle" }, slot: "headerTitle" }, [
          _vm._v("Circles List"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { slot: "panelRight" }, slot: "panelRight" },
          [
            _c("vue-search-input", {
              on: { search: _vm.onSearch, cancel: _vm.onSearchCancel },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("my-stuff-list-my-item", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.query,
            expression: "!query",
          },
        ],
        attrs: {
          name: "My Circle",
          "image-url": _vm.icon,
          link: _vm.myCircleLink,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.users.length,
              expression: "users.length",
            },
          ],
          staticClass: "row",
        },
        [
          _c(
            "div",
            {
              staticClass: "col-sm-12 list_header",
              staticStyle: { "font-size": "21px", "font-weight": "bolder" },
            },
            [
              _vm._v(
                "\n            Circles I manage (" +
                  _vm._s(_vm.totalCount) +
                  ")\n        "
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "infinite-scroll",
              rawName: "v-infinite-scroll",
              value: _vm.loadMore,
              expression: "loadMore",
            },
          ],
          staticClass: "row",
        },
        [
          _c(
            "div",
            { staticClass: "col-sm-12" },
            _vm._l(_vm.users, function (user) {
              return _c("users-circle-in-list", {
                key: user.id,
                attrs: { user: user },
              })
            }),
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.allLoaded && _vm.users.length === 0,
              expression: "allLoaded&&users.length===0",
            },
          ],
          staticClass: "row text-center",
        },
        [_vm._m(0)]
      ),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("span", [_vm._v("Loading...")]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("span", { staticStyle: { "font-size": "20px" } }, [
        _vm._v("No results..."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }