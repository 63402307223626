/**
 * Created by orion on 04.06.17.
 */

let _ = require('lodash');
import './style.scss';

createPostPopupController.$inject = ['$scope', '$uibModalInstance', '$timeout', '$log', 'result_container', 'config'];

function createPostPopupController($scope, $uibModalInstance, $timeout, $log, result_container, config) {
    /**
     * @param {Object} result_container - {media_items: array, text: string}
     */
    const COUNT_OF_PREVIEWS = 5;

    $scope.close = function () {
        $uibModalInstance.dismiss('closed');
    };

    $scope.config = config;

    $scope.set_emotion = (emotion) => {
        if ($scope.emotion === emotion) {
            $scope.emotion = undefined;
        } else {
            $scope.emotion = emotion;
        }
    };

    $scope.get_invisible_media_items_style = get_invisible_media_items_style;

    function init() {
        $scope.all_media_items = result_container.media_items;
        $scope.visible_media_items = _.take($scope.all_media_items, COUNT_OF_PREVIEWS);
        $scope.count_of_invisible_media_items = $scope.all_media_items.length - $scope.visible_media_items.length;
        $scope.text = result_container.text;
        $scope.emotion = result_container.emotion;
        $scope.sticker = result_container.sticker;
        $scope.is_header = result_container.is_header;
        $scope.additional_option = result_container.additional_option || config.additional_option;
    }

    $scope.is_media_limit_reached = function () {
        return $scope.all_media_items.length >= $scope.config.max_media_count;
    };

    init();

    function dump_data() {
        return {
            text: $scope.text,
            media_items: $scope.all_media_items,
            sticker: $scope.sticker,
            is_header: $scope.is_header,
            additional_option: $scope.additional_option,
            emotion: $scope.emotion,
        };
    }


    $scope.create = create;
    $scope.add_media = add_media;
    $scope.add_sticker = add_sticker;
    $scope.remove_sticker = remove_sticker;
    $scope.delete_media_item = delete_media_item;

    function delete_media_item(media_item) {
        $scope.all_media_items.splice($scope.all_media_items.indexOf(media_item), 1);
        $scope.visible_media_items = _.take($scope.all_media_items, COUNT_OF_PREVIEWS);
        $scope.count_of_invisible_media_items = $scope.all_media_items.length - $scope.visible_media_items.length;
    }

    function create() {
        $uibModalInstance.close({
            type: 'create',
            data: dump_data()
        });
    }

    function add_media() {
        if ($scope.is_media_limit_reached()) {
            return;
        }
        $uibModalInstance.close({
            type: 'add_media',
            data: dump_data()
        });
    }

    function add_sticker() {
        $uibModalInstance.close({
            type: 'add_sticker',
            data: dump_data()
        });
    }

    function remove_sticker() {
        $scope.sticker = undefined;
    }

    function get_invisible_media_items_style() {
        if ($scope.count_of_invisible_media_items === 0) {
            return {};
        }
        if ($scope.count_of_invisible_media_items < 10) {
            return {left: '20px'};
        }
        if ($scope.count_of_invisible_media_items >= 10) {
            return {left: '6px'};
        }
    }


}

export default createPostPopupController;