<template>
    <modal v-model="opened" :transition='0'>
        <div slot="header">
            <a id="close_popup" @click="close_popup"></a>
            <h3 class="modal-title">Invite By Email</h3>
        </div>
        <div class="row" style="margin-bottom: 15px">
            <div class="col-sm-12" v-html="info">

            </div>
        </div>
        <div class="select-menu-container center">
            <div class="select-menu-item">
                <div class="panel panel-primary">
                    <div class="panel-heading shortcuts" @click="select('register')">
                        <h3 class="panel-title">Invite<br>to Register</h3>
                        <i class="fa fa-sign-in select-menu-item__main-icon" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="select-menu-item">
                <div class="panel panel-primary">
                    <div class="panel-heading shortcuts" @click="select('access-link')">
                        <h3 class="panel-title">Send<br>Access Link</h3>
                        <i class="fa fa-link select-menu-item__main-icon" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer"></div>
    </modal>
</template>

<script>
    import PopupMixin from "../../mixins/PopupMixin";

    export default {

        name: "InvitationTypeSelectPopup",
        mixins: [PopupMixin],
        data() {
            return {
                opened: false,
                resolve: undefined,
                reject: undefined,
                info: ''
            };
        },
        methods: {
            show(info) {
                this.info = info;
                const self = this;
                this.opened = true;
                return new Promise((resolve, reject) => {
                    self.resolve = resolve;
                    self.reject = reject;
                });
            },
            select(what) {
                this.resolve(what);
                this.info = '';
                this.close();
            },
            close_popup() {
                this.info = '';
                this.reject('close');
                this.close();
            }
        }

    };
</script>

<style scoped>

</style>