<template>
    <div class="row">
        <div class="col-sm-5">
            <div class="users-media-logo">
                <div class="users-media-logo__icon pointer" @click="goToMedia" v-if="!viewedByOtherUser">
                    <img src="/static/images/new_daschboard_icons/2020/mymedia-white.png"
                         class="users-media-logo__icon__image" alt=""/><span class="align-helper"></span>
                    <!--                    <i class="fa fa-film" aria-hidden="true"></i>-->
                </div>
                <div class="users-media-logo__icon avatar-as-logo pointer" @click="goToMedia" v-else>
                    <img :src="userAvatarUrl"
                         class="users-media-logo__icon__avatar" alt=""/><span class="align-helper"></span>
                </div>
                <div class="users-media-logo__label" :class="{'with-username':viewedByOtherUser}"
                     style="vertical-align: top;">
                    <span @click="goToMedia" class="pointer" v-if="!viewedByOtherUser">Media</span>
                    <span v-if="viewedByOtherUser">
                       <simple-link :cut-name="15" :target="currentUser" class="vertical-middle"></simple-link><span
                            class="vertical-middle">'s Media</span></span>
                    <div class="users-media-logo__label__sub" v-for="(folder, index) in foldersPath"
                         :style="getSubFolderStyle(folder)">
                        <a href="javascript:;" @click="goToFolder(folder)">
                            <i class="fa fa-folder-open-o" aria-hidden="true"></i>
                            <span v-if="viewedAsSharedFolder&&index===0">
                                <span>{{ folder.owner.name |cutName(15) }}'s</span> {{ folder.name |cutName(33) }}
                            </span>
                            <span v-else>
                                {{ folder.name |cutName(33) }}
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-7 folder_buttons_panel text-right">
            <div class="folder_buttons_panel__alert alert alert-danger inline-block" v-if="massActionsState.deleteMode"
                 style="margin-bottom: 0; width: 100%">
                <div class=""><strong v-html="deleteLabel"></strong></div>
                <div class="">
                    <button class="btn btn-mm-small btn-red" :disabled="massActionsState.selectedToDelete.length===0"
                            @click="deleteSelected">
                        Delete
                    </button>
                    <button class="btn btn-mm-small btn-lblue" @click="disableEditMode">Cancel</button>
                </div>
            </div>
            <div class="folder_buttons_panel__alert alert alert-info inline-block" v-if="massActionsState.moveMode"
                 style="margin-bottom: 0;width: 100%">
                <div class=""><strong v-html="moveLabel"></strong></div>
                <div class="">
                    <form action="" class="form-inline inline-block">
                        <select v-model="targetFolderToMove" :disabled="!foldersAvailableToMove.length"
                                class="form-control folder_buttons_panel__select1">
                            <option :value="folder" v-for="folder in foldersAvailableToMove" :key="folder.id">
                                {{ folder.name | cutName(25) }}
                            </option>
                        </select>
                    </form>
                    <button class="btn btn-mm-small btn-green" @click="moveSelected"
                            :disabled="massActionsState.selectedToMove.length===0||!targetFolderToMove">
                        Move
                    </button>
                    <button class="btn btn-mm-small btn-lblue" @click="disableMoveMode">Cancel</button>
                </div>
            </div>

            <div class="inline-block folder_buttons_panel__buttons" v-show="!specModeEnabled">
                <vue-search-input :collapsable="true" @search="onSearch" @cancel="onCancel" style="vertical-align: top"
                                  :initialQuery="initialQuery"></vue-search-input>
                <vue-folder-button
                        :no-text-on-mobile="true"
                        text="Share"
                        icon-class="fa-share-square"
                        v-show="!editMode"
                        v-if="isSharableByViewer"
                        @click.native="shareFolder"
                ></vue-folder-button>

                <vue-folder-button
                        :no-text-on-mobile="true"
                        text="Add Media"
                        icon-class="fa-plus"
                        v-show="!editMode"
                        v-if="isEditableByViewer"
                        @click.native="addMedia"
                ></vue-folder-button>

                <vue-folder-button
                        :no-text-on-mobile="true"
                        text="Create Folder"
                        icon-class="fa-folder"
                        :top="-2"
                        v-show="!editMode"
                        v-if="creationAllowed"
                        @click.native="createFolder"
                ></vue-folder-button>

                <vue-folder-button
                        :no-text-on-mobile="true"
                        v-show="allowMakeMeAdmin"
                        @click.native="makeMeAdmin"
                        text="Make Me Admin"
                        icon-class="fa-user-secret"
                ></vue-folder-button>


                <vue-folder-button
                        :no-text-on-mobile="true"
                        v-if="editMode"
                        color="blue"
                        text="Move"
                        @click.native="enableMoveMode"
                        icon-class="fa-arrows-alt"
                ></vue-folder-button>

                <vue-folder-button
                        :no-text-on-mobile="true"
                        v-if="editMode"
                        color="red"
                        text="Delete"

                        @click.native="enableDeleteMode"
                        icon-class="fa-trash-o"
                ></vue-folder-button>

                <vue-folder-button
                        :no-text-on-mobile="true"
                        v-if="settingsAllowed"
                        @click.native='folderSettingsOpenModal'
                        text="Settings"
                        icon-class="fa-cogs"
                ></vue-folder-button>

                <vue-folder-button
                        :no-text-on-mobile="true"
                        v-show="isEditableByViewer"
                        @click.native="toggleEditMode"
                        :text="editIconText"
                >
                    <span
                            v-if="editMode"
                            slot="icon"
                            class="fa-stack"
                            style="width: 32px;"
                    >
                        <i class="far fa-pencil fa-stack-1x" style="position: absolute"/>
                        <i class="far fa-slash fa-stack-1x fa-inverse" style="position: absolute"/>
                      </span>
                    <span
                            v-else
                            slot="icon"
                            class="fa-stack"
                            style="width: 32px;"
                    >
                        <i class="far fa-pencil fa-stack-1x" style="position: absolute"/>
                      </span>

                    <span slot="text" v-if="editMode">
                    Finish<br>Editing
                </span>
                    <span slot="text" v-else>
                    Edit
                </span>
                </vue-folder-button>

                <vue-folder-button
                        :no-text-on-mobile="true"
                        color="brown"
                        v-if="manageAccessAvailable"
                        text="Manage Access"
                        icon-class="fa-key"
                        @click.native="manageAccess"
                ></vue-folder-button>


                <vue-folder-button
                        :no-text-on-mobile="true"
                        v-if="backAvailable"
                        text="Back"
                        icon-class="fa-reply"
                        color="blue"
                        @click.native="goBack"
                ></vue-folder-button>
            </div>


        </div>
        <modal v-model="modalFolderSettingsOpened" title="Folder Settings"
               :transition="0">
            <div class="div">
                <div class="form">
                    <label for="renameFolder">Folder name:</label>
                    <input type="text"
                           maxlength="127"
                           id="renameFolder"
                           v-model="newFolderName"
                           @keydown.enter="((newFolderName.length>1)&&(newFolderName!==activeFolder.name))?folderSettingsSave():noop()"
                           class="form-control"
                    />
                </div>
            </div>
            <div slot="footer">
                <button class="btn btn-mm btn-red"
                        @click="folderSettingsDelete">Delete this Folder
                </button>
                <button class="btn btn-mm"
                        @click="folderSettingsCancel">Cancel
                </button>
                <button class="btn btn-mm btn-green"
                        @click="folderSettingsSave"
                        :disabled="(newFolderName.length<2)||(activeFolder&&newFolderName===activeFolder.name)"
                >Save
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {massActionsBus, MASS_ACTION_BUS_ACTIONS} from './delete_and_move_aspect/massActionsBus';
    import {CurrentUserClass} from "../../../shared/CurrentUser";

    export default {
        components: {},
        props: {
            foldersPath: {
                type: Array,
            },
            folders: Array,
            massActionsState: Object,
            currentUser: Object,
            viewerStatus: Object,
            isEditableByViewer: Boolean,
            editMode: Boolean,
            allowMakeMeAdmin: Boolean,
            initialQuery: String,
            fromUrl: {
                type: String,
                required: false,
            }
        },
        data() {
            return {
                targetFolderToMove: undefined,
                modalFolderSettingsOpened: false,
                newFolderName: '',
                noop: () => {
                }
            };
        },
        name: "UsersMediaPageHeader",
        computed: {
            manageAccessAvailable() {
                const cu = new CurrentUserClass();
                if (cu.is_circle_user()) {
                    return false;
                }
                return this.viewerStatus.isAdmin && !this.activeFolder;
            },
            viewedByOtherUser() {
                if (!this.currentUser) {
                    return;
                }
                const CurrentUser = new CurrentUserClass();
                return this.currentUser.id !== CurrentUser.id;
            },
            userAvatarUrl() {
                if (!this.viewedByOtherUser) {
                    return;
                }
                return this.currentUser.avatar;
            },
            getUserAvatarAsLogoStyle() {
                if (!this.viewedByOtherUser) {
                    return;
                }
                const style = {
                    'background-image': `url(${this.currentUser.avatar})`,
                };
                return style;
            },
            moveLabel() {
                if (!this.massActionsState.selectedToMove.length) {
                    return 'Click on items below to Move.';
                }
                if (!this.targetFolderToMove) {
                    return 'Select target folder.';
                }
                return `Click "Move" to move <strong>${this.massActionsState.selectedToMove.length}</strong> selected items to folder "<strong>${this.targetFolderToMove.name}</strong>".`;
            },
            deleteLabel() {
                if (!this.massActionsState.selectedToDelete.length) {
                    return 'Click on items below to Delete.';
                }
                return `Click "Delete" to delete <strong>${this.massActionsState.selectedToDelete.length}</strong> selected items.`;
            },
            foldersAvailableToMove() {
                let res = [];
                if (this.previousFolder && this.previousFolder.is_editable_by_viewer) {
                    res.push(this.previousFolder);
                }
                if (this.activeFolder && !this.previousFolder && this.viewerStatus.canEditMainMedia) {
                    res.push({id: null, name: `${this.currentUser.name}'s Media`});
                }
                res = res.concat(this.folders.filter(f => f.is_editable_by_viewer));
                return res;
            },
            specModeEnabled() {
                return this.massActionsState.deleteMode || this.massActionsState.moveMode;
            },
            activeFolder() {
                if (this.foldersPath.length) {
                    return this.foldersPath[this.foldersPath.length - 1];
                }
            },
            previousFolder() {
                if (this.foldersPath.length > 1) {
                    return this.foldersPath[this.foldersPath.length - 2];
                }
            },
            settingsAllowed() {
                return this.viewerStatus.isAdmin && this.activeFolder && this.editMode && !this.viewedAsSharedFolder;
            },
            creationAllowed() {
                if (!this.viewerStatus.isMember) {
                    return false;
                }
                if (this.foldersPath.length >= 3) {
                    return false;
                }
                if (!this.isEditableByViewer) {
                    return false;
                }
                if (this.activeFolder && this.activeFolder.level > 2) {
                    return false;
                }
                return true;
            },
            isSharableByViewer() {
                let CurrentUser = new CurrentUserClass();
                return this.activeFolder && this.activeFolder.owner.id === CurrentUser.id;
            },
            backAvailable() {
                if (this.fromUrl) {
                    return true;
                }
                return window.history.length && !this.activeFolder;
            },
            editIconClass() {
                return this.editMode ? 'fa-eye' : 'fa-pencil';
            },
            editIconText() {
                return this.editMode ? 'Finish Editing' : 'Edit';
            },
            viewedAsSharedFolder() {
                let CurrentUser = new CurrentUserClass();
                const itIsMyMedia = CurrentUser.id === this.currentUser.id;
                const imLookAtSharedFolder = itIsMyMedia && this.activeFolder && this.activeFolder.owner.id !== CurrentUser.id;
                return imLookAtSharedFolder;
            },

        },
        methods: {
            createFolder() {
                this.$emit('createFolder');
            },
            shareFolder() {
                const mediaShareFactory = Vue.getAngularModule('mediaShareFactory');
                let CurrentUser = new CurrentUserClass();
                const allowShareToCommunity = CurrentUser.is_admin_of_any_community();
                mediaShareFactory.shareFolder(this.activeFolder.id, allowShareToCommunity);
            },
            addMedia() {

                this.$emit('addMedia');
            },
            goBack() {
                if (this.specModeEnabled) {
                    return;
                }
                this.$emit('goBack');
            },
            onSearch(val) {
                this.$emit('onSearch', val);
            },
            onCancel() {
                this.$emit('onCancelSearch');
            },
            goToFolder(folder) {
                if (this.specModeEnabled) {
                    return;
                }
                this.$emit('goToFolder', folder);
            },
            goToMedia(folder) {
                if (this.specModeEnabled) {
                    return;
                }
                this.$emit('goToMedia', folder);
            },
            manageAccess(folder) {
                this.$emit('manageAccess', folder);
            },
            getSubFolderStyle(folder) {
                return {
                    'margin-left': `${(this.foldersPath.indexOf(folder) * 12) + 3}px`
                };
            },
            toggleEditMode() {
                this.$emit('toggleEditMode');
            },
            makeMeAdmin() {
                this.$emit('makeMeAdmin');
            },
            enableDeleteMode() {
                massActionsBus.$emit(MASS_ACTION_BUS_ACTIONS.ENABLE_DELETE_MODE);
            },
            disableEditMode() {
                massActionsBus.$emit(MASS_ACTION_BUS_ACTIONS.DISABLE_DELETE_MODE);
            },
            enableMoveMode() {
                massActionsBus.$emit(MASS_ACTION_BUS_ACTIONS.ENABLE_MOVE_MODE);
            },
            disableMoveMode() {
                this.targetFolderToMove = undefined;
                massActionsBus.$emit(MASS_ACTION_BUS_ACTIONS.DISABLE_MOVE_MODE);
            },
            deleteSelected() {
                massActionsBus.$emit(MASS_ACTION_BUS_ACTIONS.DELETE_SELECTED);
            },
            moveSelected() {
                massActionsBus.$emit(MASS_ACTION_BUS_ACTIONS.MOVE_SELECTED, this.targetFolderToMove);
            },
            folderSettingsOpenModal() {
                this.newFolderName = this.activeFolder.name;
                this.modalFolderSettingsOpened = true;
            },
            folderSettingsSave() {
                this.$emit('renameFolder', {folderId: this.activeFolder.id, newName: this.newFolderName});
                this.modalFolderSettingsOpened = false;
            },
            folderSettingsCancel() {
                this.modalFolderSettingsOpened = false;
            },
            folderSettingsDelete() {
                this.$emit('deleteFolder', this.activeFolder);
                this.modalFolderSettingsOpened = false;
            }
        },
        mounted() {
            massActionsBus.$on(MASS_ACTION_BUS_ACTIONS.MOVE_COMPLETED, () => {
                this.targetFolderToMove = undefined;
            });
        },
        beforeDestroy() {
            massActionsBus.$off(MASS_ACTION_BUS_ACTIONS.MOVE_COMPLETED);
        }
    };
</script>

<style scoped lang="scss">
  @import "../../../styles/const";

  $logo-size-desktop: 70px;
  $logo-size-mobile: 45px;

  .users-media-logo {
    width: 100%;
    font-size: 0;
    @media (max-width: $screen-sm-max) {
      max-height: 90px;
    }
    @media (min-width: $screen-md-min) {
      height: 90px;
    }

    @media (max-width: 991px) {
      padding-left: 13px;
    }


    &__icon {
      font-size: 0;

      &__image {
        vertical-align: middle;
        @media (max-width: 499px) {
          width: $logo-size-mobile/2;
          height: auto;
        }
        @media (min-width: 500px) {
          width: $logo-size-desktop/2;
          height: auto;
        }
      }

      &__avatar {
        border-radius: 50%;
        vertical-align: middle;
        @media (max-width: 499px) {
          width: $logo-size-mobile - 6px;
          height: auto;
        }
        @media (min-width: 500px) {
          width: $logo-size-desktop - 6px;
          height: auto;
        }
      }

      display: inline-block;
      @media (max-width: 499px) {
        width: $logo-size-mobile;
        height: $logo-size-mobile;
        /*font-size: 30px;*/
      }
      @media (min-width: 500px) {
        width: $logo-size-desktop;
        height: $logo-size-desktop;
        /*font-size: 48px;*/
      }

      text-align: center;
      border-radius: 50%;
      background-color: #943DC7;
      color: #fff;

      &.avatar-as-logo {
        background-position: center;
        background-repeat: no-repeat;
        border: solid 3px #943DC7;
        @media (max-width: 499px) {
          background-size: $logo-size-mobile - 6px;
        }

        @media (min-width: 500px) {
          background-size: $logo-size-desktop - 6px;
        }
      }
    }

    &__label {
      display: inline-block;
      font-size: 27px;
      font-weight: bolder;
      margin-top: -6px;
      overflow-x: hidden;

      @media (max-width: 499px) {
        width: calc(100% - #{$logo-size-mobile});
      }
      @media (min-width: 500px) {
        width: calc(100% - #{$logo-size-desktop});
      }

      &.with-username {
        font-size: 19px;
      }

      &__sub {
        a {
          color: #333;
        }

        font-weight: normal;
        overflow: hidden;
        font-size: 15px;
        height: 21px;
        display: block;
        position: relative;
        top: -3px;
      }
    }
  }

  .folder_buttons_panel {
    &__buttons {
      @media (max-width: $screen-sm-max) {
        margin: 5px 0;
      }
    }

    &__select1 {
      @media (max-width: $screen-mobile-s-max) {
        width: 130px;
      }
      @media (min-width: $screen-mobile-m-min) and (max-width: $screen-xs-max) {
        width: 150px;
      }
      @media (min-width: $screen-sm-min) {
        max-width: 250px;
      }
    }

    &__alert {
      @media (max-width: 499px) {
        padding: 10px;
        margin-top: 5px;
      }

    }
  }

</style>
