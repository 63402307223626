import VueRouter from "vue-router";

NewCalendarController.$inject = ['$scope'];
import Vue from 'vue';
import CalendarPage from './CalendarPage.vue';
import CalendarPageCalendar from './CalendarPageCalendar.vue';
import CalendarPageSearch from './CalendarPageSearch.vue';
import {MAIN_CALENDAR_URL_PREFIX, MAIN_EVENT_URL_PREFIX} from "./config";
import {CurrentUserClass} from "shared/CurrentUser";
import ManagedCalendarsList from "../ManagedCalendarsList";
import EventDetailPage from "../new_event/EventDetailPage";
import EVENT_DETAIL_PAGE_CHILDREN from "../new_event/eventDetailPageChildren";
import CalendarMainRouter from "./CalendarMainRouter";

function NewCalendarController($scope) {
    const CurrentUser = new CurrentUserClass();

    const CALENDAR_PAGE_CHILDREN = [
        {name: 'calendar', path: '', component: CalendarPageCalendar, props: true},
        {name: 'CalendarPageSearch', path: 'search/', component: CalendarPageSearch, props: true},
    ];

    const router = new VueRouter({
        mode: 'history',
        routes: [
            {
                path: MAIN_EVENT_URL_PREFIX + ':date(\\d{4}-\\d{2}-\\d{2})?/:calendarOwnerId(\\d+)?/:calendarOwnerName?/',
                component: EventDetailPage,
                props: true,
                children: EVENT_DETAIL_PAGE_CHILDREN
            },
            {
                path: MAIN_CALENDAR_URL_PREFIX,
                component: CalendarPage,
                props: route => {
                    return {userId: route.params.userId === 'my' ? CurrentUser.id : route.params.userId + ''};
                },
                children: CALENDAR_PAGE_CHILDREN
            },
            {
                path: '/calendar/list/',
                component: ManagedCalendarsList,
                props: true,
                name: 'CalendarsIManage'
            },
        ]
    });

    const vm = new Vue({
        el: '#NewCalendarController',
        router,
        render(createElement, context) {
            const {props: attrs} = this;
            return createElement(CalendarMainRouter, {attrs});
        }
    });

    $scope.$on('$destroy', () => {
        if (vm) {
            vm.$destroy();
        }
    });

}

export default NewCalendarController;