<template>
    <div>
        <vue-simple-header>
            <span slot="headerTitle">Media list</span>
            <div class="" slot="panelRight">
                <!--                <router-link :to="myFolderHref" style="text-decoration: none;" v-once>-->
                <!--                    <vue-folder-button color="color-folder" text="My Media" icon-class="fa-film"></vue-folder-button>-->
                <!--                </router-link>-->
                <vue-search-input
                        @search="onSearch"
                        @cancel="onSearchCancel"
                ></vue-search-input>
            </div>
        </vue-simple-header>
        <my-stuff-list-my-item
                v-show="!query"
                name="My Media"
                :image-url="icon"
                :router-navigation="true"
                :link="myFolderHref"
        ></my-stuff-list-my-item>
        <div class="row" v-show="users.length" v-if="managedItemsCount&&!sharedItemsCount">
            <div class="col-sm-12 list_header">
                Media I manage ({{ managedItemsCount }})
            </div>
        </div>
        <div class="row" v-show="users.length" v-if="!managedItemsCount&&sharedItemsCount">
            <div class="col-sm-12 list_header">
                Media shared with me ({{ sharedItemsCount }})
            </div>
        </div>
        <div class="row" v-if="managedItemsCount&&sharedItemsCount">
            <div class="col-sm-12">
                <tabs justified v-model="tabIndex" @before-change="changeTab">
                    <tab>
                        <p slot="title" style='font-size: 19px; font-weight: bold;'>Media I manage ({{ managedItemsCount}})</p>
                    </tab>
                    <tab>
                        <p slot="title" style='font-size: 19px; font-weight: bold;'>Media shared with me ({{sharedItemsCount}})</p>
                    </tab>
                </tabs>
            </div>
        </div>
        <div class="row" v-infinite-scroll="loadMore">
            <div class="col-sm-12">
                <folders-i-manage-list-user
                        :user="user"
                        v-for="user in users"
                        :key="user.id"></folders-i-manage-list-user>
            </div>
        </div>
        <div class="row text-center" v-show="allLoaded&&users.length===0">
            <div class="col-sm-12">
                <span style="font-size: 20px">No results...</span>
            </div>
        </div>
        <div class="text-center" v-if="loading"><span>Loading...</span></div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import FoldersIManageListUser from './FoldersIManageListUser.vue';
    import urls from 'DjangoUrls';
    import {CurrentUserClass} from "../../shared/CurrentUser";
    import MyStuffListMyItem from "../../../../vue/common_components/MyStuffListMyItem";
    import InfiniteScrollMixin from "../../../../vue/mixins/InfiniteScrollMixin";
    import {DASHBOARD_ICONS, DASHBOARD_ITEMS} from "../../dashboard/dashboard";

    let {DjangoUrls} = urls;

    export default {
        name: "FoldersIManageList",
        components: {MyStuffListMyItem, FoldersIManageListUser},
        mixins: [InfiniteScrollMixin],
        data() {
            return {
                limit: 20,
                tabIndex: 0,
                getResultsOnMount: false,
                managedItemsCount: 0,
                sharedItemsCount: 0,
            };
        },
        computed: {
            icon() {
                return DASHBOARD_ICONS[DASHBOARD_ITEMS.MEDIA];
            },
            users() {
                return this.results;
            },
            currentUser() {
                return new CurrentUserClass();
            },
            myFolderHref() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                return DjangoUrls['users_media'](CurrentUser.name);
            }
        },
        methods: {
            changeTab(indexFrom, indexTo, done) {
                this.resetResults();
                done();
                this.$nextTick(() => {
                    this.getResults(true);
                });
            },
            getListEndpointArgs() {
                return [];
            },
            getListEndpoint() {
                const MediaFoldersResource = Vue.getAngularModule('MediaFoldersResource');
                if (this.managedItemsCount && !this.sharedItemsCount) {
                    return MediaFoldersResource.get_folders_i_manage;
                } else if (!this.managedItemsCount && this.sharedItemsCount) {
                    return MediaFoldersResource.get_shared_read_only_media;

                } else if (this.managedItemsCount && this.sharedItemsCount) {
                    if (this.tabIndex === 0) {
                        return MediaFoldersResource.get_folders_i_manage;
                    } else {
                        return MediaFoldersResource.get_shared_read_only_media;
                    }
                }
            },
            getAvailableMediaTypes() {
                const CurrentUser = new CurrentUserClass();
                const UserProfileResource = Vue.getAngularModule('UserProfileResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                return UserProfileResource.get_my_dashboard_state(CurrentUser.id, [DASHBOARD_ITEMS.MEDIA])
                    .then(resp => {
                        this.managedItemsCount = resp.data[DASHBOARD_ITEMS.MEDIA]['admin'];
                        this.sharedItemsCount = 0; // resp.data[DASHBOARD_ITEMS.MEDIA]['shared'];
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                    });
            }
        },
        mounted() {
            this.getAvailableMediaTypes()
                .then(() => {
                    this.getResults(true);
                });
        },
        metaInfo() {
            return {
                title: 'Media List'
            };
        }
    };
</script>

<style scoped lang="scss">
  .list_header {
    font-size: 21px;
    font-weight: bolder;
  }
</style>