var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": "Read Receipts", role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [_vm._v("Read Receipts")]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "receipt__content-container" }, [
        _c("table", { staticClass: "table table-striped" }, [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v("Name")]),
              _vm._v(" "),
              _c("th", [_vm._v("Sent")]),
              _vm._v(" "),
              _c("th", [_vm._v("Link")]),
              _vm._v(" "),
              _c("th", [_vm._v("State")]),
              _vm._v(" "),
              _c("th", [_vm._v("Completed")]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            [
              _vm._l(_vm.receipts, function (receipt) {
                return [
                  _c(
                    "tr",
                    {
                      key: "m" + receipt.id,
                      class: {
                        receipt__row_active:
                          _vm.activeReceipt === receipt &&
                          _vm.activeReceiptDetails,
                      },
                    },
                    [
                      _c("td", [
                        _c(
                          "span",
                          {
                            staticClass: "pointer",
                            attrs: {
                              tabindex: "0",
                              role: "button",
                              "aria-label": "Show Receipt Details",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showReceiptDetails(receipt)
                              },
                            },
                          },
                          [_vm._v(_vm._s(receipt.name))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(_vm._s(_vm.formatTime(receipt.sent_at))),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("i", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: "Copy to Clipboard",
                              expression: "'Copy to Clipboard'",
                            },
                          ],
                          ref: "membersReceiptLink",
                          refInFor: true,
                          staticClass: "far fa-copy pointer",
                          attrs: {
                            "data-clipboard-text":
                              _vm.getFullReceiptLink(receipt),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        receipt.rejected_at
                          ? _c("i", {
                              staticClass: "far fa-times color-footer-red",
                            })
                          : receipt.completed_at
                          ? _c("i", {
                              staticClass: "far fa-check color-grass-green",
                            })
                          : _c("i", {
                              staticClass: "far fa-ellipsis-h color-blue",
                            }),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.formatTime(
                              receipt.completed_at || receipt.rejected_at
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.activeReceipt === receipt && _vm.activeReceiptDetails
                    ? _c("tr", { key: "d" + receipt.id }, [
                        _c(
                          "td",
                          { attrs: { colspan: "4" } },
                          [
                            _c("wiki-read-receipt-details", {
                              attrs: { receipt: _vm.activeReceiptDetails },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("div", { staticClass: "col-sm-12 text-right" }, [
            _c(
              "button",
              { staticClass: "btn btn-mm btn-green", on: { click: _vm.close } },
              [_vm._v("OK")]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }