var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vue-wiki-slide-header", style: _vm.headerStyle },
    [
      _c(
        "div",
        {
          staticClass: "vertical-top vue-wiki-slide-header__caption",
          style: _vm.captionStyle,
        },
        [
          _c(
            "span",
            { staticClass: "vertical-middle", on: { click: _vm.editCaption } },
            [_vm._v(_vm._s(_vm.captionText))]
          ),
          _vm._v(" "),
          _vm.audioCaption
            ? _c("audio-toggle", {
                ref: "audioCaption",
                staticClass: "vertical-middle",
                attrs: { "audio-caption": _vm.audioCaption, "fa-class": "far" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.editMode && !_vm.slide.is_fixed
            ? _c("i", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Edit Title",
                    expression: "'Edit Title'",
                  },
                ],
                staticClass:
                  "far fa-pencil wiki-slide-header__icon vertical-middle pointer",
                attrs: { role: "button", tabindex: "0" },
                on: {
                  click: _vm.editCaption,
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.editCaption.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }