var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": "Wiki Editor Name", role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v("I am helping " + _vm._s(_vm.userReadableName)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("alert", { attrs: { type: "info" } }, [
              _vm._v(
                "\n                 If you are is supporting the person to edit their Wiki, please confirm your details here\n             "
              ),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("span", [_vm._v("I am from the person's Circle on Multi Me")]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("input", {
                staticClass: "form-control",
                attrs: {
                  autocomplete: "off",
                  id: "input-fn",
                  type: "text",
                  placeholder: "Select user",
                },
              }),
              _vm._v(" "),
              _c("typeahead", {
                staticClass: "user-selector-input",
                attrs: {
                  target: "#input-fn",
                  data: _vm.fullNameCircleMembers,
                  "item-key": "fullName",
                },
                model: {
                  value: _vm.wikiEditor,
                  callback: function ($$v) {
                    _vm.wikiEditor = $$v
                  },
                  expression: "wikiEditor",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("span", [_vm._v("I am someone else")]),
          _vm._v(" "),
          _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.wikiEditorFirstName,
                  expression: "wikiEditorFirstName",
                },
              ],
              staticClass: "form-control",
              attrs: {
                id: "first_name",
                type: "text",
                placeholder: "First Name",
                disabled: !!_vm.wikiEditorId,
                autocomplete: "given-name",
                maxlength: "63",
              },
              domProps: { value: _vm.wikiEditorFirstName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.wikiEditorFirstName = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-10-top" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.wikiEditorLastName,
                expression: "wikiEditorLastName",
              },
            ],
            staticClass: "form-control",
            attrs: {
              id: "last_name",
              type: "text",
              placeholder: "Last Name",
              disabled: !!_vm.wikiEditorId,
              autocomplete: "family-name",
              maxlength: "63",
            },
            domProps: { value: _vm.wikiEditorLastName },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.wikiEditorLastName = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("span", [_vm._v("What is your relationship to the person?")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.wikiEditorRole,
                expression: "wikiEditorRole",
              },
            ],
            staticClass: "form-control",
            attrs: {
              id: "role",
              type: "text",
              placeholder: "Add role",
              maxlength: "127",
            },
            domProps: { value: _vm.wikiEditorRole },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.wikiEditorRole = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("vue-switch-with-label", {
              attrs: {
                name: "has_consent",
                label: `I have consent to edit ${_vm.username}'s Wiki `,
              },
              model: {
                value: _vm.haveConsent,
                callback: function ($$v) {
                  _vm.haveConsent = $$v
                },
                expression: "haveConsent",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-green",
            attrs: {
              disabled:
                (!_vm.wikiEditorId &&
                  !_vm.wikiEditorLastName &&
                  !_vm.wikiEditorFirstName) ||
                !_vm.haveConsent,
            },
            on: { click: _vm.saveChanges },
          },
          [_vm._v("\n              Confirm\n          ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }