/**
 * Created by orion on 07.03.17.
 */

import urls from 'DjangoUrls';

let {DjangoUrls} = urls;

stickersResources.$inject = ['$uibModal', '$http', '$rootScope', 'simplePopupFactory'];

function stickersResources($uibModal, $http, $rootScope, simplePopupFactory) {
    return {
        user_used_sticker,
        get_my_recent_stickers
    };

    function user_used_sticker(userProfileId, stickerCode) {

        const url = DjangoUrls["api.v1:users_stickers-user-used-sticker"](userProfileId);
        return $http({
            method: 'PATCH',
            url,
            data: {
                sticker_code: stickerCode
            }
        });

    }

    function get_my_recent_stickers(userProfileId) {

        const url = DjangoUrls["api.v1:users_stickers-get-my-recent-stickers"](userProfileId);
        return $http({
            method: 'GET',
            url
        });

    }


}

export default stickersResources;