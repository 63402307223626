let angular = require('angular');
function sharedPanel() {
    return {
        restrict: 'E',
        replace: true,
        transclude:{
          'icons':'sharedIcon'
        },
        scope: {
            header: '@',
        },
        template: `
        <div>
            <div class="row" style="margin: 15px 0">
                <div class="col-sm-12 text-center">
                    <span class="left_panel_directive__header">{$ header $}</span>
                </div>
            </div>
            <div style="text-align: center" ng-transclude="icons">
                
            </div>
        </div>
        `
        ,
        link: function ($scope, element, attrs, controller) {

        }
    };
}

angular.module('mmApp.shared.leftPanel.sharedPanel', [])
    .directive('sharedPanel', sharedPanel);

export default 'mmApp.shared.leftPanel.sharedPanel'

