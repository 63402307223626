var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vue-simple-header", [
        _c("span", { attrs: { slot: "headerTitle" }, slot: "headerTitle" }, [
          _vm._v("Media list"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { slot: "panelRight" }, slot: "panelRight" },
          [
            _c("vue-search-input", {
              on: { search: _vm.onSearch, cancel: _vm.onSearchCancel },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("my-stuff-list-my-item", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.query,
            expression: "!query",
          },
        ],
        attrs: {
          name: "My Media",
          "image-url": _vm.icon,
          "router-navigation": true,
          link: _vm.myFolderHref,
        },
      }),
      _vm._v(" "),
      _vm.managedItemsCount && !_vm.sharedItemsCount
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.users.length,
                  expression: "users.length",
                },
              ],
              staticClass: "row",
            },
            [
              _c("div", { staticClass: "col-sm-12 list_header" }, [
                _vm._v(
                  "\n            Media I manage (" +
                    _vm._s(_vm.managedItemsCount) +
                    ")\n        "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.managedItemsCount && _vm.sharedItemsCount
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.users.length,
                  expression: "users.length",
                },
              ],
              staticClass: "row",
            },
            [
              _c("div", { staticClass: "col-sm-12 list_header" }, [
                _vm._v(
                  "\n            Media shared with me (" +
                    _vm._s(_vm.sharedItemsCount) +
                    ")\n        "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.managedItemsCount && _vm.sharedItemsCount
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c(
                  "tabs",
                  {
                    attrs: { justified: "" },
                    on: { "before-change": _vm.changeTab },
                    model: {
                      value: _vm.tabIndex,
                      callback: function ($$v) {
                        _vm.tabIndex = $$v
                      },
                      expression: "tabIndex",
                    },
                  },
                  [
                    _c("tab", [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "font-size": "19px",
                            "font-weight": "bold",
                          },
                          attrs: { slot: "title" },
                          slot: "title",
                        },
                        [
                          _vm._v(
                            "Media I manage (" +
                              _vm._s(_vm.managedItemsCount) +
                              ")"
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tab", [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "font-size": "19px",
                            "font-weight": "bold",
                          },
                          attrs: { slot: "title" },
                          slot: "title",
                        },
                        [
                          _vm._v(
                            "Media shared with me (" +
                              _vm._s(_vm.sharedItemsCount) +
                              ")"
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "infinite-scroll",
              rawName: "v-infinite-scroll",
              value: _vm.loadMore,
              expression: "loadMore",
            },
          ],
          staticClass: "row",
        },
        [
          _c(
            "div",
            { staticClass: "col-sm-12" },
            _vm._l(_vm.users, function (user) {
              return _c("folders-i-manage-list-user", {
                key: user.id,
                attrs: { user: user },
              })
            }),
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.allLoaded && _vm.users.length === 0,
              expression: "allLoaded&&users.length===0",
            },
          ],
          staticClass: "row text-center",
        },
        [_vm._m(0)]
      ),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("span", [_vm._v("Loading...")]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("span", { staticStyle: { "font-size": "20px" } }, [
        _vm._v("No results..."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }