import urls from 'DjangoUrls';

let {DjangoUrls} = urls;
GroupEventsSubscriptionResource.$inject = ['$http'];

function GroupEventsSubscriptionResource($http) {
    return {
        get_events_subscription_status,
        subscribe_on_group_events,
        unsubscribe_from_group_events,
    };

    function get_events_subscription_status(group_id) {
        const url = DjangoUrls["api.v1:group_events_subscription-get-events-subscription-status"](group_id);

        return $http({
            method: 'GET',
            url: url
        });
    }

    function subscribe_on_group_events(group_id, loadedEventsIdList) {
        const url = DjangoUrls["api.v1:group_events_subscription-subscribe-on-group-events"](group_id);

        return $http({
            method: 'PATCH',
            url: url,
            data: {
                id_list: loadedEventsIdList
            }
        });
    }

    function unsubscribe_from_group_events(group_id, loadedEventsIdList) {
        const url = DjangoUrls["api.v1:group_events_subscription-unsubscribe-from-group-events"](group_id);

        return $http({
            method: 'PATCH',
            url: url,
            data: {
                id_list: loadedEventsIdList
            }
        });
    }
}

export default GroupEventsSubscriptionResource;

