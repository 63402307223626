<template>
    <selector-popup
            ref="popup"
            header="How you would like to add video?"
            :items="items"
    >
        <template slot="footer">
            <button @click="back" type="button" class="btn btn-mm  btn-primary">Back</button>
        </template>
        <template slot="invisibleForms">
            <form enctype="multipart/form-data">
                <input
                        ref="recordVideoHiddenButton"
                        type="file"
                        name="video"
                        accept="video/*"
                        capture="user"
                        id="record_video"
                        style="display: none"
                        @change="onFileFieldChanged"
                />
            </form>
        </template>
    </selector-popup>
</template>

<script>
    import SelectorPopup from "../../../../vue/common_components/SelectorPopup/SelectorPopup";
    import AddMediaSelectorPopupMixin from "./AddMediaSelectorPopupMixin";

    export default {
        name: "AddVideoPopup",
        components: {SelectorPopup},
        mixins: [AddMediaSelectorPopupMixin],
        data() {
            return {
                videoFile: undefined,
                config: Object,
                videoRecordByMediaStreamSupported: false,
            };
        },
        computed: {
            items() {
                if (!this.config?.types?.length) {
                    return [];
                }
                const items = [];
                if (this.videoRecordByMediaStreamSupported) {
                    items.push({
                        label: 'Record<br/>Video',
                        iconClass: 'fa-camera',
                        action: 'select',
                        selection: 'record',
                    });
                } else if (this.allowCapture) {
                    items.push({
                        label: 'Record<br/>Video',
                        iconClass: 'fa-camera',
                        action: 'function',
                        function: 'recordVideoByDevice',
                    });
                }
                items.push({
                    label: 'Upload<br/>from device',
                    iconClass: 'fa-upload',
                    action: 'select',
                    selection: 'device',
                });
                items.push({
                    label: 'Add from<br/>youtube',
                    iconClass: 'fa-youtube',
                    action: 'select',
                    selection: 'youtube',
                });
                if (this.config.allow_add_from_library) {
                    items.push({
                        label: 'Add from<br>My Media',
                        iconClass: 'fa-folder-o',
                        action: 'select',
                        selection: 'folder',
                    });
                }
                return items;
            },
        },
        methods: {
            show(config) {
                this.config = config;
                return new Promise((resolve, reject) => {
                    this.reject = reject;
                    this.resolve = resolve;
                    this.$refs.popup.show()
                        .then(item => {
                            if (item.action === 'select') {
                                resolve({
                                    action: 'select',
                                    selection: item.selection,
                                });
                            } else if (item.action === 'function') {
                                if (item.function === 'recordVideoByDevice') {
                                    this.recordVideoByDevice();
                                }
                            }
                        }, reason => {
                            reject(reason);
                        });
                });
            },
            back() {
                this.reject('back');
            },
            onFileFieldChanged(e) {
                this.resolve({
                    action: 'file',
                    file: e.target?.files[0]
                });
            },
            recordVideoByDevice() {
                setTimeout(() => {
                    this.$refs.recordVideoHiddenButton.click();
                }, 20);
            },
        },
        mounted() {
            this.$_detectMediaDeviseSupport('video')
                .then(supported => {
                    this.videoRecordByMediaStreamSupported = supported;
                });
        }
    };
</script>