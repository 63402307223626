BlogEntryGoalsListPopupController.$inject = ['$scope', '$uibModalInstance', 'ALLOW_ADD', 'ALLOW_REMOVE', 'ALLOW_VIEW_GOAL', 'linked_goals'];

function BlogEntryGoalsListPopupController($scope, $uibModalInstance, ALLOW_ADD, ALLOW_REMOVE, ALLOW_VIEW_GOAL, linked_goals) {
    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.close = function () {
        $uibModalInstance.dismiss('close');
    };

    $scope.add_goal = function () {
        $uibModalInstance.close({do: 'link_goal'});
    };

    $scope.delete_goal = function (goal) {
        $uibModalInstance.close({do: 'delete_goal', id: goal.id});
    };

    $scope.linked_goals = linked_goals.data;
    $scope.ALLOW_ADD = ALLOW_ADD;
    $scope.ALLOW_REMOVE = ALLOW_REMOVE;
    $scope.ALLOW_VIEW_GOAL = ALLOW_VIEW_GOAL;
}

export default BlogEntryGoalsListPopupController;