<template>
    <div :style="containerStyle" class="container">
        <line-drawer ref="lineDrawer"></line-drawer>
        <div class="row" v-hotkey="keymap">
            <div class="col-xs-4" :style='slotStyle'>
                <wiki-section
                    v-if="getSectionMode(6)==='filled'"
                    :height="sectionHeight"
                    :section="sections[6]"
                    :draggable="allowDrag&&editMode"
                    :editMode="editMode"
                    :pentagramMode="pentagramMode"
                    :is-active="activeSectionNumber===6"
                    :show-counters="showCounters"
                    @onDelete="deleteSection(6)"
                    @onDragStart="onDragStart(6)"
                    @onDrop="onDrop(6)"
                    @onEdit="onSectionEdit(6)"
                    @onOpen="onSectionOpen(sections[6])"
                    @activateSection="activateSection(6)"
                    :width="sectionWidth"
                    side="left"
                    row="top"
                    ref="section6"
                ></wiki-section>
                <empty-section v-if="getSectionMode(6)==='empty'" :height="sectionHeight"
                               :width="sectionWidth" side="left" row="top"></empty-section>
                <create-section v-if="allowCreate&&getSectionMode(6)==='create'" :height="sectionHeight"
                                :width="sectionWidth" side="left" row="top" ref="section6"
                                @onCreate="onCreate(6)"
                ></create-section>
            </div>
            <div class="col-xs-4" :style='slotStyle'>
                <div class="aboutMeTop" :style="aboutMeTopStyle" v-if="mainLabelOnTop">
                    <span class="aboutMeTop__caption" :style="captionStyle">
                        {{ mainSectionText }}
                        <span class="about_me__counter" v-if="showCounters&&sections[0]&&sections[0].visible_content_count">
                            ({{ sections[0].visible_content_count }})
                        </span>
                    </span>
                </div>
            </div>
            <div class="col-xs-4" :style='slotStyle'>
                <wiki-section
                    v-if="getSectionMode(1)==='filled'"
                    :is-active="activeSectionNumber===1"
                    :height="sectionHeight"
                    :section="sections[1]"
                    :pentagramMode="pentagramMode"
                    :draggable="allowDrag&&editMode"
                    :editMode="editMode"
                    :width="sectionWidth"
                    :show-counters="showCounters"
                    side="right"
                    row="top"
                    @onDelete="deleteSection(1)"
                    @onDragStart="onDragStart(1)"
                    @onDrop="onDrop(1)"
                    @onEdit="onSectionEdit(1)"
                    @onOpen="onSectionOpen(sections[1])"
                    @activateSection="activateSection(1)"
                    ref="section1"
                ></wiki-section>
                <empty-section v-if="getSectionMode(1)==='empty'" :height="sectionHeight"
                               :width="sectionWidth" side="right" row="top"></empty-section>
                <create-section v-if="allowCreate&&getSectionMode(1)==='create'" :height="sectionHeight"
                                :width="sectionWidth" side="right" row="top" ref="section1"
                                @onCreate="onCreate(1)"
                ></create-section>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4" :style='slotStyle'>
                <wiki-section
                    v-if="getSectionMode(5)==='filled'"
                    :is-active="activeSectionNumber===5"
                    :height="sectionHeight"
                    :section="sections[5]"
                    :pentagramMode="pentagramMode"
                    :draggable="allowDrag&&editMode"
                    :editMode="editMode"
                    :width="sectionWidth"
                    :show-counters="showCounters"
                    side="left"
                    row="center"
                    @onDelete="deleteSection(5)"
                    @onDragStart="onDragStart(5)"
                    @onDrop="onDrop(5)"
                    @onEdit="onSectionEdit(5)"
                    @onOpen="onSectionOpen(sections[5])"
                    @activateSection="activateSection(5)"
                    ref="section5"
                ></wiki-section>
                <empty-section v-if="getSectionMode(5)==='empty'" :height="sectionHeight"
                               :width="sectionWidth" side="left" row="center"></empty-section>
                <create-section v-if="allowCreate&&getSectionMode(5)==='create'" :height="sectionHeight"
                                :width="sectionWidth" side="left" row="center" ref="section5"
                                @onCreate="onCreate(5)"
                ></create-section>
            </div>
            <div class="col-xs-4" :style='slotStyle'>
                <wiki-main-section
                    :isTopSection="isTopSection"
                    :is-active="activeSectionNumber===0"
                    :section="sections[0]"
                    :editMode="editMode"
                    @onEdit="onSectionEdit(0)"
                    @onDrop="onDrop(0)"
                    :allowOpen="!!sections[0]"
                    @onOpen="onSectionOpen(sections[0])"
                    @activateSection="activateSection(0)"
                    :height="sectionHeight"
                    :width="sectionWidth"
                    :containerWidth="containerWidth"
                    :text="mainSectionText"
                    :avatar="mainSectionAvatar"
                    :mediaContentSamples="mainSectionContentSamples"
                    :show-counters="showCounters"
                    ref="section0"
                ></wiki-main-section>
            </div>
            <div class="col-xs-4" :style='slotStyle'>
                <wiki-section
                    v-if="getSectionMode(2)==='filled'"
                    :is-active="activeSectionNumber===2"
                    :height="sectionHeight"
                    :section="sections[2]"
                    :draggable="allowDrag&&editMode"
                    :editMode="editMode"
                    :pentagramMode="pentagramMode"
                    :show-counters="showCounters"
                    @onDelete="deleteSection(2)"
                    @onDragStart="onDragStart(2)"
                    @onDrop="onDrop(2)"
                    @onEdit="onSectionEdit(2)"
                    @onOpen="onSectionOpen(sections[2])"
                    @activateSection="activateSection(2)"
                    :width="sectionWidth"
                    side="right"
                    row="center"
                    ref="section2"
                ></wiki-section>
                <empty-section v-if="getSectionMode(2)==='empty'" :height="sectionHeight"
                               :width="sectionWidth" side="right" row="center"></empty-section>
                <create-section v-if="allowCreate&&getSectionMode(2)==='create'" :height="sectionHeight"
                                :width="sectionWidth" side="right" row="center" ref="section2"
                                @onCreate="onCreate(2)"
                ></create-section>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4" :style='slotStyle'>
                <wiki-section
                    v-if="getSectionMode(4)==='filled'"
                    :is-active="activeSectionNumber===4"
                    :height="sectionHeight"
                    :section="sections[4]"
                    :draggable="allowDrag&&editMode"
                    :editMode="editMode"
                    :pentagramMode="pentagramMode"
                    :show-counters="showCounters"
                    @onDelete="deleteSection(4)"
                    @onDragStart="onDragStart(4)"
                    @onDrop="onDrop(4)"
                    @onEdit="onSectionEdit(4)"
                    @onOpen="onSectionOpen(sections[4])"
                    @activateSection="activateSection(4)"
                    :width="sectionWidth"
                    side="left"
                    row="bottom"
                    ref="section4"
                ></wiki-section>
                <empty-section v-if="getSectionMode(4)==='empty'" :height="sectionHeight"
                               :width="sectionWidth" side="left" row="bottom"></empty-section>
                <create-section v-if="allowCreate&&getSectionMode(4)==='create'" :height="sectionHeight"
                                :width="sectionWidth" side="left" row="bottom" ref="section4"
                                @onCreate="onCreate(4)"
                ></create-section>
            </div>
            <div class="col-xs-4" :style='slotStyle'>
                <div class="aboutMeBottom" :style="aboutMeBottomStyle" v-if="mainLabelOnBottom">
                    <span class="aboutMeBottom__caption" :style="bottomCaptionStyle">
                        {{ mainSectionText }}
                        <span class="about_me__counter" v-if="showCounters&&sections[0]&&sections[0].visible_content_count">
                            ({{ sections[0].visible_content_count }})
                        </span>
                    </span>
                </div>
                <wiki-section
                    v-if="getSectionMode(7)==='filled'"
                    :is-active="activeSectionNumber===7"
                    :height="sectionHeight"
                    :section="sections[7]"
                    :draggable="allowDrag&&editMode"
                    :editMode="editMode"
                    :pentagramMode="pentagramMode"
                    :show-counters="showCounters"
                    @onDelete="deleteSection(7)"
                    @onDragStart="onDragStart(7)"
                    @onDrop="onDrop(7)"
                    @onEdit="onSectionEdit(7)"
                    @onOpen="onSectionOpen(sections[7])"
                    @activateSection="activateSection(7)"
                    :width="sectionWidth"
                    side="center"
                    row="bottom"
                    ref="section7"
                ></wiki-section>
                <create-section v-if="allowCreate&&getSectionMode(7)==='create'" :height="sectionHeight"
                                :width="sectionWidth" side="center" row="bottom" ref="section7"
                                @onCreate="onCreate(7)"
                ></create-section>
            </div>
            <div class="col-xs-4" :style='slotStyle'>
                <wiki-section
                    v-if="getSectionMode(3)==='filled'"
                    :is-active="activeSectionNumber===3"
                    :height="sectionHeight"
                    :section="sections[3]"
                    :draggable="allowDrag&&editMode"
                    :editMode="editMode"
                    :pentagramMode="pentagramMode"
                    :show-counters="showCounters"
                    @onDelete="deleteSection(3)"
                    @onDragStart="onDragStart(3)"
                    @onDrop="onDrop(3)"
                    @onEdit="onSectionEdit(3)"
                    @onOpen="onSectionOpen(sections[3])"
                    @activateSection="activateSection(3)"
                    :width="sectionWidth"
                    side="right"
                    row="bottom"
                    ref="section3"
                ></wiki-section>
                <empty-section v-if="getSectionMode(3)==='empty'" :height="sectionHeight"
                               :width="sectionWidth" side="right" row="bottom"></empty-section>
                <create-section v-if="allowCreate&&getSectionMode(3)==='create'" :height="sectionHeight"
                                :width="sectionWidth" side="right" row="bottom" ref="section3"
                                @onCreate="onCreate(3)"
                ></create-section>
            </div>
        </div>
    </div>
</template>

<script>
    import WikiSection from './WikiSection.vue';
    import WikiMainSection from './WikiMainSection.vue';
    import LineDrawer from '~/vue/common_components/LineDrawer.vue';
    import EmptySection from './EmptySection.vue';
    import CreateSection from './CreateSection.vue';

    let currentlyDraggedSection = undefined;

    export default {
        name: "wiki-main-menu",
        components: {
            WikiSection, WikiMainSection, LineDrawer, EmptySection, CreateSection
        },
        props: {
            containerHeight: {
                type: Number
            },
            containerWidth: {
                type: Number
            },
            mainSectionText: {
                type: String,
                default: 'About Me'
            },
            mainSectionAvatar: {
                type: String,
                required: false
            },
            mainSectionAudioCaption: {
                type: Object,
                required: false
            },
            mainSectionContentSamples: {
                type: Array,
                required: false,
                default: () => [],
                validator: function (value) {
                    return value.length <= 4;
                }
            },
            sections: {
                type: Object
            },
            editMode: {
                type: Boolean
            },
            isTopSection: {
                type: Boolean
            },
            allowCreate: {
                type: Boolean,
                default: false
            },
            allowDrag: {
                type: Boolean,
                default: false
            },
            pentagramMode: {
                type: Boolean,
                default: true
            },
            activeSectionNumber: {
                type: Number,
                required: false
            },
        },
        data() {
            return {
                adaptedContainerHeight: this._getAdaptedContainerHeight()
            };
        },
        computed: {
            keymap() {
                return {
                    'alt+0': this.focusToMainSection,
                    'alt+a': this.toggleSoundOnActiveSection,
                    'alt+o': this.openActiveSection,
                };
            },
            mainLabelOnTop() {
                return this.getSectionMode(7) === 'filled';
            },
            showCounters() {
                return this.$store.getters['WikiStore/wikiShowContentCounters'];
            },
            mainLabelOnBottom() {
                return !this.mainLabelOnTop;
            },
            $_captionFontSize() {
                if (this.sectionWidth > 235) {
                    return 17;
                }
                if (this.sectionWidth > 200) {
                    return 15;
                }
                if (this.sectionWidth > 170) {
                    return 13;
                }
                return 10;
            },
            sectionHeight() {
                return this.adaptedContainerHeight / 3;
            },

            sectionWidth() {
                return this.containerWidth / 3;
            },
            isTextOnSide() {
                return this.sectionWidth > (this.sectionHeight + 40);
            },
            aboutMeStyle() {
                if (this.isTextOnSide) {
                    return {
                        'margin-top': '15px'
                    };
                }
                let basicSize = Math.min(this.sectionWidth / 2, this.sectionHeight);
                return {
                    'position': 'relative',
                    'top': `-${Math.min(basicSize, this.sectionHeight * 0.8) / 2}px`
                };
            },
            aboutMeTopStyle() {
                if (!this.isTextOnSide) {
                    let basicSize = Math.min(this.sectionWidth / 2, this.sectionHeight);
                    return {
                        'bottom': `${Math.min(basicSize, this.sectionHeight * 0.8) / 2}px`
                    };
                }
            },
            aboutMeBottomStyle() {
                const style = {};
                const textOnSide = this.sectionWidth > (this.sectionHeight + 40);
                if (textOnSide) {
                    if (this.containerWidth >= 738) {
                        style['top'] = `${this.sectionHeight / 10}px`;
                    }
                } else {
                    let basicSize = Math.min(this.sectionWidth, this.sectionHeight);
                    style['top'] = `-${Math.min(basicSize, this.sectionHeight * 0.8) / 2 - 5}px`;
                }
                return style;
            },
            captionStyle() {
                let resStyle = {
                    'font-size': `${this.$_captionFontSize}px`,
                    'line-height': `${this.$_captionFontSize + 2}px`,
                };
                return resStyle;
            },
            bottomCaptionStyle() {
                let resStyle = {
                    'font-size': `${this.$_captionFontSize}px`,
                    'line-height': `${this.$_captionFontSize + 2}px`,
                };
                return resStyle;
            },
            containerStyle() {
                return {
                    'width': '100%',
                    'height': `${this.adaptedContainerHeight + 150}px`
                };
            },
            slotStyle() {
                return {
                    height: `${this.sectionHeight}px`,
                    'text-align': 'center'
                };
            },
            firstUnfilledSectionNumber() {
                switch (this.sections.length) {
                    case 0:
                        return 2;
                    case 1:
                        return 2;
                    case 2:
                        return 5;
                    case 3:
                        return 7;
                    case 4:
                        return 4;
                    case 5:
                        return 7;
                    case 6:
                        return 6;
                }
                for (let i of [1, 2, 3, 4, 5, 6, 7]) {
                    if (!this.sections[i]) {
                        return i;
                    }
                }
            }
        },
        mounted() {
            this._reDrawLines();
        },
        updated() {
            this._reDrawLines();
        },
        methods: {
            focusToMainSection() {
                this.$store.commit('WikiStore/setActiveSectionNumber', 0);
            },
            toggleSoundOnActiveSection() {
                if (this.activeSectionNumber !== undefined) {
                    this.$refs[`section${this.activeSectionNumber}`]?.toggleSound();
                }
            },
            openActiveSection() {
                if ((this.activeSectionNumber !== undefined) && (this.sections[this.activeSectionNumber])) {
                    this.onSectionOpen(this.sections[this.activeSectionNumber]);
                }
            },
            activateSection(n) {
                this.$store.commit('WikiStore/setActiveSectionNumber', n);
            },
            _reDrawLines() {
                this.$refs.lineDrawer.clearLines();
                if (this.sectionWidth > this.sectionHeight + 40) {
                    let centerX = parseInt(this.containerWidth / 2);
                    let centerY = parseInt(this.adaptedContainerHeight / 2);

                    for (let i of [4, 5, 6]) {
                        if (this.getSectionMode(i) === 'empty' || (this.getSectionMode(i) === 'create' && !this.allowCreate)) {
                            continue;
                        }
                        let {x: x1, y: y1} = this.$refs[`section${i}`].getCircleCenterCoords();
                        this.$refs.lineDrawer.drawLine(x1, y1, centerX, centerY);
                    }
                    for (let i of [1, 2, 3, 7]) {
                        if (this.getSectionMode(i) === 'empty' || (this.getSectionMode(i) === 'create' && !this.allowCreate)) {
                            continue;
                        }
                        let {x: x1, y: y1} = this.$refs[`section${i}`].getCircleCenterCoords();
                        this.$refs.lineDrawer.drawLine(centerX, centerY, x1, y1);
                    }
                }
            },
            onCreate(sectionNumber) {
                this.$emit('onSectionCreate', sectionNumber);
            },
            getSectionMode(sectionNumber) {
                if (this.sections[sectionNumber]) {
                    return 'filled'; // 'filled','empty','create'
                }
                if (this.editMode && sectionNumber === this.firstUnfilledSectionNumber) {
                    return 'empty';
                }
                return 'empty';
            },
            deleteSection(sectionNumber) {
                this.$emit('onSectionDelete', sectionNumber);
            },
            onDragStart(sectionNumber) {
                currentlyDraggedSection = sectionNumber;
            },
            onDrop(sectionNumber) {
                if (sectionNumber === 0) {
                    return;
                }
                if (!currentlyDraggedSection || currentlyDraggedSection === sectionNumber) {
                    return;
                }
                if (currentlyDraggedSection) {
                    this.$emit('onSwitchSections', {from: currentlyDraggedSection, to: sectionNumber});
                }
            },
            onSectionEdit(sectionNumber) {
                this.$emit('onSectionEdit', sectionNumber);

            },
            onSectionOpen(section) {
                this.$emit('onSectionOpen', section);
            },
            _getAdaptedContainerHeight() {
                if (this.containerHeight > this.containerWidth) {
                    return this.containerWidth;
                } else {
                    return this.containerHeight;
                }
            }
        },
        watch: {
            containerHeight(n) {
                this.adaptedContainerHeight = this._getAdaptedContainerHeight();
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../styles/const.scss";

    .container {
        position: relative;
    }

    .col-xs-4 {
        padding: 0;
    }

    .aboutMeTop {
        font-size: 18px;
        font-weight: bolder;
        display: inline-block;
        position: absolute;
        bottom: 0;
        width: 50%;
        left: 25%;
    }

    .aboutMeBottom {
        font-size: 18px;
        font-weight: bolder;
        display: inline-block;
        position: absolute;
        top: 0;
        width: 50%;
        left: 25%;

        &__caption {
            display: inline-block;
        }
    }

    .about_me {
        &__counter {
            color: $tag-gray;
        }
    }

    .aboutMe {
        font-size: 18px;
        font-weight: bolder;
        position: relative;
        max-width: 164px;
        display: inline-block;

        &__audio-icon {
            cursor: pointer;
            position: absolute;
            right: calc(50% - 18px);
            bottom: -29px;
            font-size: 28px;
        }

        &__edit-icon {
            cursor: pointer;
            position: absolute;
            right: -22px;
            top: 0;
        }
    }

    .aboutMeTop {
        font-size: 18px;

        &__caption {
            display: inline-block;
        }
    }

</style>
