var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wiki-receipt-tree-slide" }, [
    _c("i", {
      staticClass: "far wiki-receipt-tree-slide__icon",
      class: _vm.checkButtonClasses,
      on: { click: _vm.toggleSlideIncluded },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "wiki-receipt-tree-slide__avatar",
        style: _vm.avatarStyle,
      },
      [
        !_vm.avatarUrl
          ? _c("i", {
              staticClass: "fa wiki-receipt-tree-slide__avatar-inner-icon",
              class: _vm.holderIconClass,
            })
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "wiki-receipt-tree-slide__title" }, [
      _c("a", { attrs: { href: _vm.slide.link, target: "_blank" } }, [
        _c("strong", [_vm._v(_vm._s(_vm._f("cutName")(_vm.slideName, 30)))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }