var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slider" }, [
    _c(
      "div",
      {
        ref: "outerSlider",
        staticClass: "slider__outer",
        style: [_vm.gripStyle],
      },
      [
        _c(
          "div",
          { staticClass: "slider__inner", style: _vm.sliderInnerStyle },
          _vm._l(_vm.slides, function (slide) {
            return _c("wiki-detail-page-slider-slide", {
              key: slide.id,
              attrs: {
                "edit-mode": _vm.editMode,
                slide: slide,
                slides: _vm.slides,
                "active-slide": _vm.activeSlide,
              },
              on: {
                onClick: function ($event) {
                  return _vm.activateSlide(slide)
                },
              },
            })
          }),
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }