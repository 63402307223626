<template>
    <basic-modal
            @hide="close"
            v-model="opened"
            :aria-label="header"
            role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ header }}</h3>
        </div>
        <div class="row margin-15-top">
            <div class="col-sm-12">
                <label>Group name:</label>
                <input type="text"
                       v-model="name"
                       placeholder="Group name"
                       class="form-control"
                       ref="groupNameInput"
                />
            </div>
        </div>
        <div class="row margin-15-top">
            <div class="col-sm-12">
                <label>Group description:</label>
                <textarea
                        v-model="description"
                        rows="5"
                        class="form-control"
                        placeholder="Group description"></textarea>
                <hr>
            </div>
        </div>
        <div class="row margin-15-top" v-if="uses_default_avatar||!avatar">
            <div class="col-sm-12">
                Avatar: <a href="javascript:;" @click="addAvatar">Add avatar</a>

            </div>
        </div>
        <div class="row margin-15-top" v-else>
            <div class="col-sm-12">
                Avatar: <br>
                <media-preview
                        :allow-delete='true'
                        :item="avatar"
                        style="display: inline-block;"
                        @onDelete="resetAvatar"
                ></media-preview>
            </div>
        </div>

        <div class="row margin-15-top">
            <div class="col-sm-12">
                <vue-switch-with-label
                        v-model="auto_avatar"
                        label="Take thumbnail from members avatars if not set"
                        name="autoAvatar"
                ></vue-switch-with-label>
            </div>
        </div>

        <div class="row margin-15-top">
            <div class="col-sm-12">
                <vue-switch-with-label
                        v-model="is_private"
                        label=""
                        name="isPrivate"
                >
                    <span slot="label"> Make this a Private Group. (Private Groups cannot be seen by <span
                            v-if="isCircleGroup">Circle</span><span v-else>Community</span> Members unless they are added)</span>
                </vue-switch-with-label>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <hr>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                Please check what you would like to approve:
            </div>
        </div>

        <div class="row margin-15-top">
            <div class="col-sm-12">
                <vue-switch-with-label
                        v-model="veto_joins"
                        label="User requests to join this Group"
                        name="veto_joins"
                ></vue-switch-with-label>
            </div>
        </div>

        <div class="row margin-15-top">
            <div class="col-sm-12">
                <vue-switch-with-label
                        v-model="veto_share"
                        label="Members sharing Wikis, Stories and Media folders with Group"
                        name="veto_share"
                ></vue-switch-with-label>
            </div>
        </div>

        <div class="row margin-15-top">
            <div class="col-sm-12">
                <vue-switch-with-label
                        v-model="veto_new_conversations"
                        label="Members creating new Group Chats"
                        name="veto_new_conversations"
                ></vue-switch-with-label>
            </div>
        </div>

        <div class="row margin-15-top">
            <div class="col-sm-12">
                <vue-switch-with-label
                        v-model="veto_conversation_posts"
                        label="Members posting into Group Chats"
                        name="veto_conversation_posts"
                ></vue-switch-with-label>
            </div>
        </div>

        <div class="row margin-15-top">
            <div class="col-sm-12">
                <vue-switch-with-label
                        v-model="veto_activity_posts"
                        label="Members posting Updates to the Group"
                        name="veto_activity_posts"
                ></vue-switch-with-label>
            </div>
        </div>

        <div slot="footer">
            <button class="btn btn-mm" @click="close">Cancel</button>
            <button class="btn btn-mm btn-green"
                    :disabled="!allowSave"
                    @click="saveOrCreate"
            >{{ saveButtonText }}
            </button>
        </div>
    </basic-modal>
</template>

<script>
    import Vue from 'vue';
    import PopupPromiseMixin from "../../../../vue/mixins/PopupPromiseMixin";
    import BasicModal from "../../../../vue/common_components/BasicModal";

    const defaultGroupConfig = {
        header: 'Create a Group',
        saveButtonText: 'Create',
        isCircleGroup: false,

        name: '',
        description: '',
        avatar: undefined,
        auto_avatar: true,
        is_private: false,

        veto_conversation_posts: false,
        veto_joins: true,
        veto_share: false,
        veto_new_conversations: false,
        veto_activity_posts: false,

        uses_default_avatar: false,
        reset_avatar: false,
    };

    export default {
        components: {BasicModal},
        mixins: [PopupPromiseMixin],
        name: "GroupSettingsPopup",
        data() {
            return {
                ...defaultGroupConfig,
            };
        },
        computed: {
            firstFocusEl() {
                return this.$refs.groupNameInput;
            },
            allowSave() {
                return this.name;
            },
        },
        methods: {
            setInitial(popupConfig = {}) {
                for (let key of Object.keys(defaultGroupConfig)) {
                    if (popupConfig[key] !== undefined) {
                        this[key] = popupConfig[key];
                    } else {
                        this[key] = defaultGroupConfig[key];
                    }
                }
            },
            addAvatar() {
                const addMedia = Vue.getAngularModule('addMedia');
                const default_callback = (items) => {
                    this.uses_default_avatar = false;
                    this.avatar = items[0];
                    this.tmpShow();
                };
                this.tmpHide();
                addMedia.add_media(
                    {
                        allow_add_from_library: true,
                        max_count: 1,
                        type: 'image',
                        popup_header: 'What sort of media would you like to add?'
                    },
                    {
                        default_callback,
                        cancel_callback() {
                            this.tmpShow();
                        },
                    }
                );
            },
            resetAvatar() {
                this.avatar = undefined;
                this.reset_avatar = true;
            },
            dumpData() {
                const res = {
                    name: this.name,
                    description: this.description,
                    veto_conversation_posts: this.veto_conversation_posts,
                    veto_joins: this.veto_joins,
                    veto_share: this.veto_share,
                    veto_new_conversations: this.veto_new_conversations,
                    veto_activity_posts: this.veto_activity_posts,
                    auto_avatar: this.auto_avatar,
                    is_private: this.is_private,

                    reset_avatar: this.reset_avatar,
                };
                if (this.avatar) {
                    res.media_for_avatar_id = this.avatar.id;
                }
                return res;
            },
            saveOrCreate() {
                this.save(this.dumpData());
            }
        },
    };
</script>

<style scoped lang="scss">

</style>