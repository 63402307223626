var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "GmapMap",
        {
          ref: "gMap",
          style: _vm.mapStyle,
          attrs: {
            center: { lat: _vm.location.latitude, lng: _vm.location.longitude },
            zoom: _vm.location.zoom,
            options: _vm.mapOptions,
          },
        },
        [
          _c("GmapMarker", {
            key: 1,
            attrs: {
              position: {
                lat: _vm.location.latitude,
                lng: _vm.location.longitude,
              },
              clickable: false,
              draggable: false,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }