var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "timeline" }, [
    _c(
      "div",
      {
        ref: "container",
        staticClass:
          "timeline__scroll-box__container vue-timeline-component__noselect",
        style: _vm.containerStyle,
        on: {
          mousedown: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.mousedownOnTimeline.apply(null, arguments)
          },
          touchstart: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.touchstartOnTimeline.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "timeline__scroll-box__content",
            style: _vm.contentStyle,
          },
          [
            _c("line-drawer", { ref: "lineDrawer" }),
            _vm._v(" "),
            _vm._l(_vm.hours, function (hour) {
              return _c("calendar-page-day-timeline-hour", {
                key: hour,
                attrs: {
                  hour: hour,
                  date: _vm.day.date,
                  width: _vm.contentWidth / 24,
                  height: _vm.containerHeight,
                },
              })
            }),
            _vm._v(" "),
            _vm._l(_vm.eventsTiers, function (eventsTier, i) {
              return _vm._l(eventsTier, function (event, j) {
                return _c("calendar-page-day-timeline-event", {
                  key: event.id + _vm.day.date,
                  attrs: {
                    tier: i,
                    event: event,
                    profile: _vm.profile,
                    index: i * _vm.eventsTiers.length + j + 1,
                    date: _vm.day.date,
                  },
                })
              })
            }),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }