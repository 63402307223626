<template>
    <div class="category-selector" role="radiogroup" aria-label="Select Category">
        <div class="category-selector__icon inline-block"
             tabindex="0"
             role="menuitemradio"
             :aria-checked="isCategoryActive(category)"
             :aria-label="category.name"
             :style="getCategoryAvatarStyle(category)"
             v-for="category in userCategories"
             v-if="category.icon"
             @click="selectCategory(category)"
             @keydown.enter="selectCategory(category)"
        >
            <div class="category-selector__icon__tick"
                 v-show="isCategoryActive(category)"
                 aria-hidden="true"
            >
                <i class="fa fa-check"></i>
            </div>
            <div class="category-selector__label" aria-hidden="true">{{ category.name }}</div>
        </div>
    </div>
</template>

<script>
    import UserCategoriesMixin from "../../mixins/UserCategoriesMixin";

    export default {
        name: "CircleMemberCategorySelector",
        mixins: [UserCategoriesMixin],
        props: {
            value: {
                type: String,
                default: undefined
            }
        },
        data() {
            return {};
        },
        methods: {
            selectCategory(category) {
                this.$emit('input', category.code);
                this.$emit('change');
            },
            getCategoryAvatarStyle(category) {
                return {'background-image': `url(${category.icon})`, 'background-size': `${category.size}%`};
            },
            isCategoryActive(category) {
                return this.value === category.code;
            }
        }
    };
</script>

<style scoped lang="scss">
  @import "../../../angular/app/styles/const";

  .category-selector {
    margin-bottom: 15px;

    &__label {
      position: absolute;
      bottom: -15px;
      font-weight: bold;
      width: 100%;
      text-align: center;
    }

    &__icon {
      position: relative;
      width: 46px;
      height: 46px;
      @media (min-width: 400px) {
        width: 60px;
        height: 60px;
      }
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 5px;

      &__tick {
        position: absolute;
        background-color: $grass-green;
        font-size: 24px;
        right: -4px;
        top: -5px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        text-align: center;

        .fa {
          color: white;
          vertical-align: top;
        }
      }
    }
  }

</style>