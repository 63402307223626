<template>
    <span v-if="sectionSnapshotAfter">
        <span>
            reordered
            <span v-if="sectionSnapshotAfter.is_submenu">sections</span>
            <span v-else>pages</span>
            in
            <span v-if="sectionSnapshotAfter.is_submenu">submenu</span> section </span>
            <strong>
                #{{ sectionSnapshotAfter.id }}
                <wiki-log-possible-link
                    :target="wikiLog.target_wiki_section"
                    :label="sectionSnapshotAfter.caption"
                ></wiki-log-possible-link>
            </strong>
    </span>
</template>

<script>
    import SnapshotLogMixin from "./common/SnapshotLogMixin";

    export default {
        name: "WikiSectionUpdatedContentReordered",
        mixins: [SnapshotLogMixin]
    };
</script>

<style scoped>

</style>