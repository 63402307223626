/**
 * Created by orion on 12.05.17.
 */
import {MAP_BUS_ACTIONS, mapsBus} from './mapsBus.js';

mapsAddLocationPopup.$inject = ['$scope', '$uibModalInstance', '$q', '$timeout'];

function mapsAddLocationPopup($scope, $uibModalInstance, $q, $timeout) {
    $scope.close = function () {
        $uibModalInstance.dismiss();
    };
    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.allow_add = false;

    //types: route, locality, administrativeArea, postalCode, country


    $scope.control = {
        allow_add: false
        //get_result - defined in placeFinder for feedback
    };

    $scope.add_location = function () {
        mapsBus.$emit(MAP_BUS_ACTIONS.RESULT_REQUEST);
    };

    function receiveResult(res) {
        $timeout($uibModalInstance.close, 200, true, res); // coz iphone is buggy
    }

    function allowAddListener(val) {
        $scope.$apply(() => {
            $scope.control.allow_add = val;
        });
    }

    function bindListeners() {
        mapsBus.$on(MAP_BUS_ACTIONS.TOGGLE_ALLOW_SAVE_ON_ADD_LOCATION_POPUP, allowAddListener);
        mapsBus.$on(MAP_BUS_ACTIONS.RESULT_RESPONSE, receiveResult);
    }

    function unBindListeners() {
        mapsBus.$off(MAP_BUS_ACTIONS.TOGGLE_ALLOW_SAVE_ON_ADD_LOCATION_POPUP, allowAddListener);
        mapsBus.$off(MAP_BUS_ACTIONS.RESULT_RESPONSE, receiveResult);
    }

    bindListeners();

    $scope.$on('$destroy', unBindListeners);

}

export default mapsAddLocationPopup;