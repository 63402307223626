var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { transition: 0, backdrop: false },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          attrs: { id: "close_popup" },
          on: { click: _vm.closePopup },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [_vm._v("Event Settings")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", [
            _c("span", [_vm._v("Event title:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name",
                },
              ],
              ref: "name-input",
              staticClass: "form-control",
              attrs: {
                maxlength: "127",
                type: "text",
                placeholder: "Event Name",
                required: "",
              },
              domProps: { value: _vm.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.name = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", [
            _c("span", [_vm._v("Event Description:")]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.description,
                  expression: "description",
                },
              ],
              staticClass: "form-control",
              attrs: {
                maxlength: "3000",
                type: "text",
                placeholder: "Event Description",
              },
              domProps: { value: _vm.description },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.description = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _vm.avatar
              ? _c("media-preview", {
                  attrs: {
                    "no-border": true,
                    item: _vm.avatar,
                    "allow-delete": true,
                    "show-name": true,
                  },
                  on: { onDelete: _vm.cleanAvatar },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.avatar
              ? _c("media-preview-add-request", {
                  attrs: {
                    "no-border": true,
                    text: "+ Picture",
                    "icon-class": "fa-picture-o",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addAvatar.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.location && !_vm.locationData
              ? _c("media-preview-add-request", {
                  attrs: {
                    "no-border": true,
                    text: "+ Location",
                    "icon-class": "fa-map-marker",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addLocation.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.location || _vm.locationData
              ? _c("media-preview", {
                  attrs: {
                    "no-border": true,
                    "show-name": true,
                    item: _vm.locationPreviewSrc,
                    text: _vm.locationCaption,
                    "allow-delete": true,
                  },
                  on: { onDelete: _vm.cleanLocation },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.audio_caption
              ? _c("media-preview", {
                  attrs: {
                    "no-border": true,
                    item: _vm.audio_caption,
                    "allow-delete": true,
                    "show-name": true,
                  },
                  on: { onDelete: _vm.cleanAudioCaption },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.audio_caption
              ? _c("media-preview-add-request", {
                  attrs: {
                    "no-border": true,
                    text: "+ Audio",
                    "icon-class": "fa-volume-down",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addAudio.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      !_vm.is_periodic
        ? _c("div", { staticClass: "row margin-15-top enlarged-calendar-14" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c(
                    "form",
                    { staticClass: "form" },
                    [
                      _c("span", [_vm._v("Start Date:")]),
                      _vm._v(" "),
                      _c(
                        "dropdown",
                        { ref: "start-picker", staticClass: "form-group" },
                        [
                          _c("div", { staticClass: "input-group" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.startDate,
                                  expression: "startDate",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: { value: _vm.startDate },
                              on: {
                                click: _vm.showStartPicker,
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.startDate = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "input-group-btn" },
                              [
                                _c(
                                  "btn",
                                  {
                                    staticClass:
                                      "dropdown-toggle create-event-date-picker",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "glyphicon glyphicon-calendar",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("template", { slot: "dropdown" }, [
                            _c(
                              "li",
                              [
                                _c("date-picker", {
                                  attrs: {
                                    "limit-to": _vm.limitStartTo,
                                    "date-parser": _vm.dateParser,
                                    format: "dd/MM/yyyy",
                                    "week-starts-with": 1,
                                    "week-numbers": true,
                                    width: _vm.pickerWidth,
                                  },
                                  model: {
                                    value: _vm.startDate,
                                    callback: function ($$v) {
                                      _vm.startDate = $$v
                                    },
                                    expression: "startDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c(
                    "form",
                    { staticClass: "form" },
                    [
                      _c("span", [_vm._v("Finish Date:")]),
                      _vm._v(" "),
                      _c(
                        "dropdown",
                        { ref: "end-picker", staticClass: "form-group" },
                        [
                          _c("div", { staticClass: "input-group" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.endDate,
                                  expression: "endDate",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: { value: _vm.endDate },
                              on: {
                                click: _vm.showEndPicker,
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.endDate = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "input-group-btn" },
                              [
                                _c(
                                  "btn",
                                  {
                                    staticClass:
                                      "dropdown-toggle create-event-date-picker",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "glyphicon glyphicon-calendar",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("template", { slot: "dropdown" }, [
                            _c(
                              "li",
                              [
                                _c("date-picker", {
                                  attrs: {
                                    "limit-from": _vm.limitEndFrom,
                                    format: "dd/MM/yyyy",
                                    "date-parser": _vm.dateParser,
                                    "week-starts-with": 1,
                                    "week-numbers": true,
                                    width: _vm.pickerWidth,
                                  },
                                  model: {
                                    value: _vm.endDate,
                                    callback: function ($$v) {
                                      _vm.endDate = $$v
                                    },
                                    expression: "endDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.advancedTimeScheduleEnabled
        ? _c("event-time-editor", {
            staticClass: "margin-15-top",
            attrs: { disabled: _vm.whole_day },
            model: {
              value: _vm.time,
              callback: function ($$v) {
                _vm.time = $$v
              },
              expression: "time",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.whole_day &&
      _vm.allowToggleAdvancedTimeSchedule &&
      _vm.advancedTimeScheduleEnabled
        ? _c("div", { staticClass: "alert alert-info margin-15-top" }, [
            _vm._v("\n        Advanced Time schedule enabled. "),
            _c(
              "strong",
              {
                staticClass: "pointer",
                on: { click: _vm.showAdvancedTimeSchedulePopup },
              },
              [
                _c("i", { staticClass: "fa fa-pencil" }),
                _vm._v("Edit schedule"),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.advancedMode,
              expression: "!advancedMode",
            },
          ],
          staticClass: "row margin-15-top",
        },
        [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "a",
              {
                staticClass: "pointer",
                attrs: { href: "javascript:;" },
                on: { click: _vm.enableAdvancedMode },
              },
              [_vm._v("Advanced Options")]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.advancedMode,
              expression: "advancedMode",
            },
          ],
          staticClass: "row margin-15-top",
        },
        [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("span", [_vm._v("Settings:")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "margin-5-top" },
              [
                _vm.allowToggleAdvancedTimeSchedule
                  ? _c("vue-switch-with-label", {
                      attrs: {
                        value: _vm.advancedTimeScheduleEnabled,
                        disabled: _vm.whole_day,
                        name: "advanced-schedule",
                        label: "Advanced Schedule",
                        "help-text":
                          "Allows set Start and Finish time separately for each day of week. Available only for 'Days of week' repeat type.",
                      },
                      on: { input: _vm.toggleAdvancedTimeScheduleEnabled },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "margin-5-top" },
              [
                _c("vue-switch-with-label", {
                  attrs: { name: "whole-day", label: "All day" },
                  model: {
                    value: _vm.whole_day,
                    callback: function ($$v) {
                      _vm.whole_day = $$v
                    },
                    expression: "whole_day",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "margin-5-top" },
              [
                _c("vue-switch-with-label", {
                  attrs: {
                    name: "private-event",
                    label: "Make this a Private Event.",
                  },
                  model: {
                    value: _vm.is_private,
                    callback: function ($$v) {
                      _vm.is_private = $$v
                    },
                    expression: "is_private",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "margin-5-top" },
              [
                _c("vue-switch-with-label", {
                  attrs: {
                    name: "approve-messages",
                    label: "Approve messages",
                  },
                  model: {
                    value: _vm.veto_conversation_posts,
                    callback: function ($$v) {
                      _vm.veto_conversation_posts = $$v
                    },
                    expression: "veto_conversation_posts",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("advanced-time-schedule-popup", {
            ref: "advanced-time-schedule-popup",
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          { staticClass: "btn btn-mm btn-red", on: { click: _vm.deleteEvent } },
          [_vm._v("Delete Event")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-mm", on: { click: _vm.closePopup } },
          [_vm._v("Cancel")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-green save-button",
            attrs: { disabled: !_vm.allowSave },
            on: { click: _vm.save },
          },
          [_vm._v("\n            Save\n        ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }