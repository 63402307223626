<template>
  <basic-modal
      @hide="close"
      v-model="opened"
      :aria-label="header"
      role="dialog"
  >
    <div slot="header">
      <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
      <h3 class="modal-title">{{ header }}</h3>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>
            <span v-if="max_count === 1">Youtube link:</span>
            <span v-else>Youtube links:</span>
          </label>
          <input class="form-control" v-if="max_count===1" required
                 placeholder="https://www.youtube.com/watch?v=jito_NaFAtA" v-model="links">
          <textarea v-else required class="form-control" v-model="links" rows="5"
                    id="submitProviderInfo" placeholder="https://www.youtube.com/watch?v=jito_NaFAtA
https://www.youtube.com/watch?v=8-hqiTDp4_A"></textarea><!-- do not add spaces!! -->
        </div>
        <span v-if="max_count>1">You can add multiple links (one link per row).</span>
      </div>
    </div>

    <div slot="footer" class="row">
      <div class="col-sm-5 text-left">
        <vue-switch-with-label :collapse-on-mobile="false" name="save_to_media" v-model="saveToMedia"
                               label="Save to My Media"
                               v-if="allowSaveToMedia"></vue-switch-with-label>
      </div>
      <div class="col-sm-7 text-right">
        <button type="button" class="btn btn-mm btn-primary" @click="back">Back</button>
        <button type="button" class="btn btn-mm btn-green" @click="submit">Add</button>
      </div>
    </div>
  </basic-modal>
</template>

<script>
import PopupPromiseMixin from "../../../../vue/mixins/PopupPromiseMixin";

export default {
  name: "AddYoutubeVideoPopup",
  mixins: [PopupPromiseMixin],
  data() {
    return {
      header: "Add videos from youtube",
      max_count: undefined,
      links: '',
      saveToMedia: true,
      allowSaveToMedia: undefined,
    }
  },
  methods: {
    setInitial(data) {
      this.max_count = data.max_count;
      this.allowSaveToMedia = data.allow_save_to_folders;
    },
    submit() {
      let links_list = this.links.split('\n');
      if (links_list.length > this.max_count) {
        this.$notifications.error('You can\'t add more then ' + this.max_count + ' link' + (this.max_count === 1 ? '' : 's'));
      } else {
        this.save({
          save_to_library: this.saveToMedia,
          links: links_list
        });
      }
    },
    back() {
      this.reject('back');
    }
  }
}
</script>
