import {customTermFilter} from "../../../vue/filters/CustomTerm";

const PROVIDED_ASSISTANCE_OPTIONS_VALUES = {
    TASK_NOT_ACHIEVED: 0,
    WITH_VERBAL_PROMPTING: 1,
    WITH_VISUAL_SUPPORT: 2,
    WITH_PHYSICAL_SUPPORT: 3,
    WITH_GESTURAL_SUPPORT: 4,
    TASK_ACHIEVED: 5,
    TASK_EXCEEDED: 6,
};

const PROVIDED_ASSISTANCE_OPTIONS_LIST = [
    PROVIDED_ASSISTANCE_OPTIONS_VALUES.TASK_ACHIEVED,
    PROVIDED_ASSISTANCE_OPTIONS_VALUES.TASK_EXCEEDED,
    PROVIDED_ASSISTANCE_OPTIONS_VALUES.TASK_NOT_ACHIEVED,
    PROVIDED_ASSISTANCE_OPTIONS_VALUES.WITH_VERBAL_PROMPTING,
    PROVIDED_ASSISTANCE_OPTIONS_VALUES.WITH_VISUAL_SUPPORT,
    PROVIDED_ASSISTANCE_OPTIONS_VALUES.WITH_PHYSICAL_SUPPORT,
    PROVIDED_ASSISTANCE_OPTIONS_VALUES.WITH_GESTURAL_SUPPORT,
];

const PROVIDED_ASSISTANCE_OPTIONS_LABELS = {};

PROVIDED_ASSISTANCE_OPTIONS_LABELS[PROVIDED_ASSISTANCE_OPTIONS_VALUES.TASK_NOT_ACHIEVED] = `${customTermFilter('Task')} not met`;
PROVIDED_ASSISTANCE_OPTIONS_LABELS[PROVIDED_ASSISTANCE_OPTIONS_VALUES.TASK_ACHIEVED] = `${customTermFilter('Task')} met`;
PROVIDED_ASSISTANCE_OPTIONS_LABELS[PROVIDED_ASSISTANCE_OPTIONS_VALUES.TASK_EXCEEDED] = `${customTermFilter('Task')} exceeded`;
PROVIDED_ASSISTANCE_OPTIONS_LABELS[PROVIDED_ASSISTANCE_OPTIONS_VALUES.WITH_VERBAL_PROMPTING] = "With verbal prompting";
PROVIDED_ASSISTANCE_OPTIONS_LABELS[PROVIDED_ASSISTANCE_OPTIONS_VALUES.WITH_VISUAL_SUPPORT] = "With visual Support";
PROVIDED_ASSISTANCE_OPTIONS_LABELS[PROVIDED_ASSISTANCE_OPTIONS_VALUES.WITH_PHYSICAL_SUPPORT] = "With physical support";
PROVIDED_ASSISTANCE_OPTIONS_LABELS[PROVIDED_ASSISTANCE_OPTIONS_VALUES.WITH_GESTURAL_SUPPORT] = "With gestural support";

const ASSISTANCE_EXCLUSIVE_GROUP = [
    PROVIDED_ASSISTANCE_OPTIONS_VALUES.TASK_ACHIEVED,
    PROVIDED_ASSISTANCE_OPTIONS_VALUES.TASK_EXCEEDED,
    PROVIDED_ASSISTANCE_OPTIONS_VALUES.TASK_NOT_ACHIEVED,
];

export {
    PROVIDED_ASSISTANCE_OPTIONS_LABELS,
    PROVIDED_ASSISTANCE_OPTIONS_VALUES,
    PROVIDED_ASSISTANCE_OPTIONS_LIST,
    ASSISTANCE_EXCLUSIVE_GROUP
};