<template>
    <div class="vue-inline-image">
        <span class="align-helper"></span>
        <img id="inline-render-media-image"
             v-show="imageLoaded"
             ref="image"
             :class="{'full-size':fullSize}"
             class="img-responsive inline_image_container center-block"
             :style="[maxImageHeightStyle]"
             :src="item.image.image_url"
             :alt="item.name"
        >
        <span class="vertical-middle" style="font-size: 18px;" v-show="!imageLoaded" aria-hidden="true">loading...</span>
    </div>
</template>

<script>
    export default {
        name: "VueInlineMediaImage",
        props: {
            item: {
                type: Object
            },
            fullSize: {
                type: Boolean,
                default: false
            },
            maxHeight: {
                type: String,
                required: false
            }
        },
        computed: {
            maxImageHeightStyle() {
                if (this.maxHeight) {
                    return {'max-height': this.maxHeight};
                }
            }
        },
        data() {
            return {
                imageLoaded: false
            };
        },
        mounted() {
            this.$refs.image.addEventListener('load', () => {
                this.imageLoaded = true;
            });
        },
        watch: {
            item(n, o) {
                // console.log(n, o);
                // if(o){
                //     this.imageLoaded = false;
                // }

            }
        }
    };
</script>

<style scoped lang="scss">
  .vue-inline-image {
    font-size: 0;
    max-height: inherit;
  }

  img {
    max-height: inherit;
    width: auto;

    &.full-size {
      max-height: 100%;
    }

    @media print {
      max-height: 400px !important;
      &.full-size {
        max-height: 100% !important;
      }
    }


    @media (max-width: 1000px) {
      max-width: 100%;
    }
    @media (min-width: 1001px) {
      max-width: 80%;
      &.full-size {
        max-width: 100%;
      }
    }

  }

  .inline_image_container {
    vertical-align: middle;
    display: inline-block;
  }
</style>