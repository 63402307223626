/**
 * Created by orion on 19.02.17.
 */

shareFactoryWhereListPopupController.$inject = ['$scope', '$uibModalInstance',  'sharing_status', 'additional_messages'];
function shareFactoryWhereListPopupController($scope, $uibModalInstance, sharing_status, additional_messages) {
    $scope.close = function () {
        $uibModalInstance.dismiss();
    };
    $scope.select = function (select, share) { // private / friends / groups / public
        $uibModalInstance.close({'select': select, 'share': share}); // share paremetr now required only for story
    };

    $scope.additional_messages = additional_messages;

    $scope.sharing_status = sharing_status;


}
export default shareFactoryWhereListPopupController;