<template>
    <div>
        <div class="community-page-menu">
            <div class="community-page-menu__avatar" :style="avatarStyle"></div>
            <div class="community-page-menu__name">{{name}}
                <router-link v-if="showSettings" :to="{name: 'community-settings'}" style="color: #fff"><i class="fa fa-gear"></i>
                </router-link>
            </div>
            <div class="community-page-menu__buttons-panel">
                <div class="community-page-menu__buttons-panel__button" v-for="icon in icons">
                    <router-link :to="icon.to" v-if="icon.to&&!icon.onlyMobile">
                        <div class="community-page-menu__buttons-panel__button__image">
                            <i :class="icon.classes" v-if="icon.type==='fa'"></i>
                        </div>
                        <div class="community-page-menu__buttons-panel__button__label">
                            {{icon.label}}
                        </div>
                    </router-link>
                    <div class="" v-else-if="!icon.to&&!icon.onlyMobile">
                        <div class="community-page-menu__buttons-panel__button__image">
                            <i :class="icon.classes" v-if="icon.type==='fa'"></i>
                        </div>
                        <div class="community-page-menu__buttons-panel__button__label">
                            {{icon.label}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="community-page-menu__mobile">
            <div class="community-page-menu__mobile__header" :class="{opened:isCollapsed}">
                <div class="community-page-menu__mobile__header__avatar" :style="avatarStyle"></div>
                <div class="community-page-menu__mobile__header__name">{{ name }}</div>
                <div class="community-page-menu__mobile__header__toggle-button" @click="isCollapsed=!isCollapsed"
                     :class="{'pushed':isCollapsed}">
                    <i class="fa fa-bars"></i>
                </div>
            </div>
                <div class="community-page-menu__mobile__menu" v-if="isCollapsed">
                    <div class="community-page-menu__mobile__menu__item" v-for="icon in icons" :key="icon.label" @click="openPage(icon.to)">
                        <div class="community-page-menu__mobile__menu__item__icon">
                            <i :class="icon.classes"></i>
                        </div>
                        <div class="community-page-menu__mobile__menu__item__label">{{icon.label}}</div>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CommunityPageMenu",
        props: {
            avatar: String,
            name: String,
            showSettings: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isCollapsed: false,
                icons: [
                    {
                        label: 'About Us',
                        type: 'fa',
                        classes: 'far fa-info',
                        to: {name: 'community-info'},
                    },
                    {
                        label: 'Stories',
                        type: 'fa',
                        classes: 'far fa-book-open',
                        to: {name: 'community-stories'},
                    },
                    {
                        label: 'Events',
                        type: 'fa',
                        classes: 'far fa-calendar',
                        to: {name: 'community-events'},
                    },
                    {
                        label: 'Groups',
                        type: 'fa',
                        classes: 'far fa-users',
                        to: {name: 'community-groups'},
                    },
                ]
            };
        },
        computed: {
            avatarStyle() {
                return `background-image: url('${this.avatar}')`;
            }
        },
        methods:{
            openPage(to){
                this.isCollapsed = false;
                this.$router.push(to)
            },
        },
        mounted() {
            if (this.showSettings) {
                this.icons.push({
                    label: 'Settings',
                    type: 'fa',
                    classes: 'fa fa-gear',
                    onlyMobile: true,
                    to: {name: 'community-settings'},
                });
            }
        }
    };
</script>

<style scoped lang="scss">
    $avatar-offset: 50px;
    @import "../styles/const";

    .community-page-menu {
        min-height: 300px;
        background-color: #0065b0;
        margin-top: $avatar-offset;
        border-radius: 5px;
        @media (max-width: $screen-xs-max) {
            display: none;
        }

        &__mobile {
            overflow: visible;
            position: relative;
            margin-bottom: 10px;
            @media (min-width: $screen-sm-min) {
                display: none;
            }

            &__menu {
                z-index: 5;
                position: absolute;
                width: 100%;
                top: 55px;
                left: 0;
                background-color: #0065b0;
                padding-top: 10px;
                padding-bottom: 10px;

                &__item {
                    /*border-top: 1px solid #333;*/
                    font-size: 0;
                    padding: 2px 0;
                    min-height: 50px;
                    $icon-sector-width: 50px;
                    width: 100%;

                    &__icon {
                        line-height: $icon-sector-width;
                        vertical-align: middle;
                        width: $icon-sector-width;
                        color: #fff;
                        font-size: 22px;
                        display: inline-block;
                        height: 100%;
                        text-align: center;
                    }

                    &__label {
                        vertical-align: middle;
                        display: inline-block;
                        width: calc(100% - #{$icon-sector-width});
                        font-size: 22px;
                        color: #fff;
                        /*font-weight: bolder;*/
                    }
                }
            }

            &__header {
                font-size: 0;
                height: 55px;
                background-color: #0065b0;
                transition: border 0.2s ease;
                border-bottom: 1px solid #004b92;

                &__name {
                    padding-left: 5px;
                    overflow: hidden;
                    width: calc(100% - 54px - 55px);
                    display: inline-block;
                    height: 100%;
                    font-size: 20px;
                    vertical-align: top;
                    color: white;
                    line-height: 55px;
                    /*background-color: tan;*/
                }

                &__avatar {
                    vertical-align: top;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    border: 2px solid #c9c9c9;
                    display: inline-block;
                    margin: 2px;
                    background-color: white;
                    background-size: cover;
                }

                &__toggle-button {
                    vertical-align: top;
                    float: right;
                    background-color: #005ea9;
                    transition: background-color 0.2s ease;

                    &.pushed {
                        background-color: #004b92;
                    }

                    display: inline-block;
                    font-size: 35px;
                    border: solid 1px #333;
                    border-radius: 5px;
                    padding: 5px;
                    margin: 5px;
                    width: 45px;
                    height: 45px;
                    color: white;

                    i {
                        vertical-align: top;
                    }


                }
            }
        ;
        }

        &__avatar {
            border-radius: 50%;
            border: solid 3px $gray;
            position: relative;
            width: 200px;
            height: 200px;
            margin: auto;
            background-color: white;
            background-size: cover;
            background-repeat: no-repeat;
            top: -$avatar-offset;
            margin-bottom: -$avatar-offset + 15px;
        }

        &__name {
            color: white;
            font-weight: bolder;
            text-align: center;
            font-size: 21px;
        }

        &__buttons-panel {
            text-align: center;

            &__button {
                a {
                    color: white;
                }

                width: 80px;
                vertical-align: top;
                cursor: pointer;
                color: white;
                display: inline-block;
                margin: 7px 17px;

                &__image {
                    $icon-size: 50px;
                    width: $icon-size;
                    height: $icon-size;
                    border: 2px solid white;
                    border-radius: 50%;
                    margin: auto;
                    font-size: 25px;
                    line-height: $icon-size - 5px;
                }
            }
        }
    }
</style>