var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vue-folder-item__container",
      class: {
        "delete-mode": _vm.selectedToDelete,
        "move-mode": _vm.selectedToMove,
      },
      style: [_vm.containerSize, _vm.dragStyle],
    },
    [
      _vm.selectedToDelete || _vm.selectedToMove
        ? _c(
            "div",
            {
              staticClass: "trash-holder",
              style: _vm.actionIconSize,
              on: { click: _vm.itemClick },
            },
            [
              _c("i", {
                staticClass: "fa",
                class: {
                  "fa-trash-o": _vm.selectedToDelete,
                  "fa-check": _vm.selectedToMove,
                },
                attrs: { "aria-hidden": "true" },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.deletionAllowed
        ? _c(
            "div",
            {
              staticClass: "vue-folder-item__delete-button hidden-print",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.deleteClick.apply(null, arguments)
                },
              },
            },
            [_c("i", { staticClass: "fa fa-trash-o" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dragAllowed
        ? _c(
            "div",
            {
              staticClass: "vue-folder-item__drug-button hidden-print",
              on: {
                mousedown: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.dragGripClicked.apply(null, arguments)
                },
                touchstart: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.dragGripTouched.apply(null, arguments)
                },
              },
            },
            [_c("i", { staticClass: "fa fa-arrows-alt" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.tooltipText,
              expression: "tooltipText",
            },
          ],
          staticClass: "vue-folder-item",
        },
        [
          _c(
            "div",
            {
              staticClass: "vue-folder-item__preview text-center",
              style: _vm.previewStyle,
              on: { click: _vm.itemClick },
            },
            [
              !_vm.iconNeeded
                ? _c("img", {
                    staticClass:
                      "visible-print vue-folder-item__attachment-print-image",
                    attrs: { src: _vm.attachment.thumbnail, alt: "" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.iconNeeded
                ? _c("i", {
                    staticClass: "fa vue-folder-item__preview__icon",
                    class: _vm.iconClass,
                    style: _vm.iconStyle,
                    attrs: { "aria-hidden": "true" },
                  })
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "vue-folder-item__footer", style: _vm.footerStyle },
            [
              _c("div", { staticClass: "vue-folder-item__footer__name" }, [
                _c("span", [_vm._v(_vm._s(_vm.attachment.name))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "vue-folder-item__footer__icon" }, [
                _c("i", {
                  staticClass: "fa",
                  class: _vm.iconClass,
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _vm.allowEdit
                  ? _c("i", {
                      staticClass: "fa fa-pencil",
                      attrs: { "aria-hidden": "true" },
                      on: { click: _vm.editNameClick },
                    })
                  : _vm._e(),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }