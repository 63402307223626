import Vue from 'vue';
import MouseEventsMixin from './MouseEventsMixin';

let dragActive = false;

const drugEventsInnerBus = new Vue();

const SHARED_DRAG_EVENTS = {
    DRAG_STARTED: 'DRAG_STARTED',
    DRAG_STOPPED: 'DRAG_STOPPED',
    SET_DRAGGED_ELEMENT: 'SET_DRAGGED_ELEMENT',
    SET_UNDER_DRAG_ELEMENT: 'SET_UNDER_DRAG_ELEMENT',
};

const globalState = {
    currentlyDraggedElementID: false,
    currentlyUnderDragElementID: false,
    isDragModeActive: false,
};

function getStorage(prefix) {
    return globalState;
}

export default {
    mixins: [MouseEventsMixin],
    data() {
        return {
            isVisibleOnScreen: true,

            positionBeforeDragX: undefined,
            positionBeforeDragY: undefined,
            currentPositionX: undefined,
            currentPositionY: undefined,

            offsetXBeforeDrag: undefined,
            offsetYBeforeDrag: undefined,
            offsetX: 0,
            offsetY: 0,

            currentlyDraggedElementID: false,
            currentlyUnderDragElementID: false,
            isDragModeActive: false,
        };
    },
    computed: {
        autoScrollScreenY() {
            return true;
        },
        dragDropElementID() {
            return this.item.id;
        },
        isCurrentlyUnderDrag() {
            return this.currentlyUnderDragElementID === this.dragDropElementID;
        },
        isCurrentlyDragged() {
            return this.currentlyDraggedElementID === this.dragDropElementID;
        },

        draggingStyle() {
            const style = {};
            if (!this.isCurrentlyDragged) {
                return style;
            }
            const xCoord = this.currentPositionX - this.positionBeforeDragX - (this.offsetXBeforeDrag - this.offsetX);
            const yCoord = this.currentPositionY - this.positionBeforeDragY - (this.offsetYBeforeDrag - this.offsetY);
            return {
                'z-index': 9999,
                'pointer-events': 'none',
                'cursor': 'grabbing',
                'opacity': '0.7',
                'transform': `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, ${xCoord}, ${yCoord}, 0, 1)`
            };
        },
        dragGripStyle() {
            const style = {'cursor': 'grab'};
            if (this.isCurrentlyDragged) {
                style['cursor'] = 'grabbing !important';
            }
            return style;
        },
    },
    methods: {
        $_markCurrentElementAsDragged() {
            drugEventsInnerBus.$emit(SHARED_DRAG_EVENTS.SET_DRAGGED_ELEMENT, this.dragDropElementID);
        },
        $_markCurrentElementAsUnderDrag() {
            drugEventsInnerBus.$emit(SHARED_DRAG_EVENTS.SET_UNDER_DRAG_ELEMENT, this.dragDropElementID);
        },
        $_resetDraggedElement() {
            drugEventsInnerBus.$emit(SHARED_DRAG_EVENTS.SET_DRAGGED_ELEMENT, undefined);
        },
        $_resetUnderDragElement() {
            drugEventsInnerBus.$emit(SHARED_DRAG_EVENTS.SET_UNDER_DRAG_ELEMENT, undefined);
        },
        $_enableDragMode() {
            drugEventsInnerBus.$emit(SHARED_DRAG_EVENTS.DRAG_STARTED);
        },
        $_disableDragMode() {
            drugEventsInnerBus.$emit(SHARED_DRAG_EVENTS.DRAG_STOPPED);
        },
        $_processDrop(draggedID, underDragID) {
            console.log(draggedID, underDragID);
        },
        onMouseOut() {
            if (this.isCurrentlyDragged) {
                this.$_stopScreenScrollIfStarted();
                this.$_disableDragMode();
                this.$_resetDraggedElement();
                this.$_resetUnderDragElement();
            }
        },
        handleDragStart(coords) {
            dragActive = true;
            this.$_enableDragMode();
            this.$_markCurrentElementAsDragged();
            this.currentPositionX = this.positionBeforeDragX = coords.x;
            this.currentPositionY = this.positionBeforeDragY = coords.y;
            this.offsetXBeforeDrag = this.offsetX;
            this.offsetYBeforeDrag = this.offsetY;
        },
        dragGripClicked(e) {
            this.handleDragStart({x: e.clientX, y: e.clientY});
        },
        dragGripTouched(e) {
            if (e.targetTouches.length !== 1) {
                return;
            }
            this.handleDragStart({x: e.targetTouches[0].clientX, y: e.targetTouches[0].clientY});
        },
        onMouseUp() {
            if (this.isCurrentlyDragged) {
                this.$_stopScreenScrollIfStarted();
                if (this.currentlyDraggedElementID && this.currentlyUnderDragElementID && this.isCurrentlyDragged) {
                    this.$_processDrop(this.currentlyDraggedElementID, this.currentlyUnderDragElementID);
                }
                this.$_disableDragMode();
                this.$_resetDraggedElement();
                this.$_resetUnderDragElement();
                dragActive = false;
            }
        },
        onTouchEnd(e) {
            if (!this.isCurrentlyDragged) {
                return;
            }
            if (e.changedTouches.length !== 1) {
                return;
            }
            let coords = {x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY};
            let el = document.elementFromPoint(coords.x, coords.y);
            let x = document.createEvent("MouseEvent");
            x.initMouseEvent("mouseup", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            el.dispatchEvent(x);
        },

        onPointerMove(coords) {
            if (!dragActive) {
                return;
            }
            if (!this.isVisibleOnScreen) {
                return;
            }
            if (this.isCurrentlyDragged) {
                this.currentPositionX = coords.x;
                this.currentPositionY = coords.y;
            } else if (this.isDragModeActive) {
                let rect = this.$el.getBoundingClientRect();
                const inY = (rect.y < coords.y) && ((rect.y + rect.height) > coords.y);
                const inX = (rect.x < coords.x) && ((rect.x + rect.width) > coords.x);
                if (inX && inY) {
                    if (!this.isCurrentlyUnderDrag) {
                        this.$_markCurrentElementAsUnderDrag();
                    }
                } else {
                    if (this.isCurrentlyUnderDrag) {
                        this.$_resetUnderDragElement();
                    }
                }
            }
            if (this.autoScrollScreenY && this.isCurrentlyDragged) {
                this.$_watchScreenScrollY(coords);
            }
        },
        $_startOrContinueScreenScroll(direction, speed = 1) {
            this.$_scrollSpeedY = speed;

            // console.log('$_startOrContinueScreenScroll', direction, speed);
            if (this.scrollYintervalID !== undefined) {
                return;
            }
            let delta = 1;

            const maxScrollY = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - window.innerHeight;

            this.scrollYintervalID = setInterval(() => {
                if (direction === 'down' && window.scrollY < maxScrollY) {
                    window.scroll(window.scrollX, window.scrollY + (delta + 1) * this.$_scrollSpeedY);
                } else if (direction === 'up' && window.scrollY > 0) {
                    window.scroll(window.scrollX, window.scrollY - (delta + 1) * this.$_scrollSpeedY);
                }
            }, 20);
        },
        $_stopScreenScrollIfStarted() {
            // console.log('$_stopScreenScrollIfStarted');
            if (this.scrollYintervalID !== undefined) {
                clearInterval(this.scrollYintervalID);
            }
            this.scrollYintervalID = undefined;
        },
        $_watchScreenScrollY(coords) {
            const win = window,
                doc = document,
                docElem = doc.documentElement,
                x = win.innerWidth || docElem.clientWidth,
                y = win.innerHeight || docElem.clientHeight;
            // console.log(y,coords.y);
            if ((y - coords.y) < (y / 5)) {
                let speed = 2;
                if ((y - coords.y) < (y / 20)) {
                    speed = 4;
                } else if ((y - coords.y) < (y / 10)) {
                    speed = 3;
                }
                this.$_startOrContinueScreenScroll('down', speed);
            } else if (coords.y < (y / 5)) {
                let speed = 2;
                if (coords.y < (y / 20)) {
                    speed = 4;
                } else if (coords.y < (y / 10)) {
                    speed = 3;
                }
                this.$_startOrContinueScreenScroll('up', speed);
            } else {
                this.$_stopScreenScrollIfStarted();
            }
        },
        $_setCurrentlyDraggedElement(elementID) {
            getStorage().currentlyDraggedElementID = elementID;
            this.currentlyDraggedElementID = elementID;
        },
        $_setCurrentlyUnderDragElement(elementID) {
            getStorage().currentlyUnderDragElementID = elementID;
            this.currentlyUnderDragElementID = elementID;
        },
        $_activateDragMode() {
            getStorage().isDragModeActive = true;
            this.isDragModeActive = true;
        },
        $_deactivateDragMode() {
            getStorage().isDragModeActive = false;
            this.isDragModeActive = false;
        },
        $_bindListeners() {
            drugEventsInnerBus.$on(SHARED_DRAG_EVENTS.DRAG_STARTED, this.$_activateDragMode);
            drugEventsInnerBus.$on(SHARED_DRAG_EVENTS.DRAG_STOPPED, this.$_deactivateDragMode);
            drugEventsInnerBus.$on(SHARED_DRAG_EVENTS.SET_DRAGGED_ELEMENT, this.$_setCurrentlyDraggedElement);
            drugEventsInnerBus.$on(SHARED_DRAG_EVENTS.SET_UNDER_DRAG_ELEMENT, this.$_setCurrentlyUnderDragElement);
        },
        $_unbindListeners() {
            drugEventsInnerBus.$off(SHARED_DRAG_EVENTS.DRAG_STARTED, this.$_activateDragMode);
            drugEventsInnerBus.$off(SHARED_DRAG_EVENTS.DRAG_STOPPED, this.$_deactivateDragMode);
            drugEventsInnerBus.$off(SHARED_DRAG_EVENTS.SET_DRAGGED_ELEMENT, this.$_setCurrentlyDraggedElement);
            drugEventsInnerBus.$off(SHARED_DRAG_EVENTS.SET_UNDER_DRAG_ELEMENT, this.$_setCurrentlyUnderDragElement);
        },
        $_onYScroll() {
            this.offsetY = window.scrollY;
        },
        $_bindYScrollListener() {
            document.addEventListener('scroll', this.$_onYScroll);
        },
        $_unbindYScrollListener() {
            document.removeEventListener('scroll', this.$_onYScroll);
        },
        $_initValuesFromStorage() {
            this.isDragModeActive = getStorage().isDragModeActive;
            this.currentlyDraggedElementID = getStorage().currentlyDraggedElementID;
            this.currentlyUnderDragElementID = getStorage().currentlyUnderDragElementID;
        },
    },
    mounted() {
        this.$_initValuesFromStorage();
        this.$_bindYScrollListener();
        this.$_bindListeners();
    },
    beforeDestroy() {
        this.$_unbindYScrollListener();
        this.$_unbindListeners();
    }
};