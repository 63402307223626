<template>
    <div class="media-tree">
        <i class="fa fa-pencil"
           v-if="allowAssignEditPermission"
           :class="editButtonClasses"
           @click="toggleMediaLevelEditPermission()"></i>

        <i class="fa fa-eye"
           :class="viewButtonClasses"
           @click="toggleMediaLevelViewPermissionLevel()"></i>

        <i class="fa fa-folder"></i> {{currentUser.name}}'s Media ({{mediaCount}} Media files inside)
        <div class="media-tree__subfolders-list">
            <media-tree-folder
                    :current-user="currentUser"
                    :permissions-list="permissionsList"
                    :allow-assign-edit-permission="allowAssignEditPermission"
                    :member="member"
                    :folder="folder"
                    v-for="folder in folders"
                    @setFolderLevelPermission="setFolderLevelPermission"
                    :inheritedPermissionLevel="resultPermissionLevel"
                    :key="folder.id"></media-tree-folder>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';
    import MediaTreeFolder from './MediaTreeFolder.vue';
    import editViewButtonsClassesMixin from '../../../wiki/wiki_settings_page/editViewButtonsClassesMixin';

    export default {
        components: {MediaTreeFolder},
        mixins: [editViewButtonsClassesMixin],
        props: {
            member: Object,
            owner: {
                type: Object
            }
        },
        data() {
            return {
                currentUser: undefined,
                folders: [],
                permissionsList: [],
                mediaCount: undefined
            };
        },
        computed: {
            inheritedPermissionLevel() {
                return 'unset';
            },
            ownPermissionLevel() {
                const ownPermissionIndex = _.findIndex(this.permissionsList, p => p.level === 'whole-media');
                if (ownPermissionIndex !== -1) {
                    return this.permissionsList[ownPermissionIndex].value;
                }
                return 'unset';
            },
            isAccessCodeMembership() {
                return this.member.membership_type === 'access_link';
            },
            accessCodeIfAccessCodeMembership() {
                if (!this.isAccessCodeMembership) {
                    return;
                }
                return this.member.access_code;
            },
            resultPermissionLevel() {
                if (this.ownPermissionLevel === 'unset') {
                    return this.inheritedPermissionLevel;
                } else {
                    return this.ownPermissionLevel;
                }
            },
            canOwnEditPermissionBeChanged() {
                return true;
            },
            canOwnViewPermissionBeChanged() {
                return this.ownPermissionLevel !== 'edit';
            },
            hasRedefinedInChildViewProperty() {
                let triggerPermissions = this.triggerViewPermissions;
                if (_.findIndex(this.permissionsList, p => p.level === 'folder' && triggerPermissions.includes(p.value)) !== -1) {
                    return true;
                }
                if (_.findIndex(this.permissionsList, p => p.level === 'item' && triggerPermissions.includes(p.value)) !== -1) {
                    return true;
                }
                return false;
            },
            hasRedefinedInChildEditProperty() {
                let triggerPermissions = this.triggerEditPermissions;
                if (_.findIndex(this.permissionsList, p => p.level === 'folder' && triggerPermissions.includes(p.value)) !== -1) {
                    return true;
                }
                if (_.findIndex(this.permissionsList, p => p.level === 'item' && triggerPermissions.includes(p.value)) !== -1) {
                    return true;
                }
                return false;
            },
            allowAssignEditPermission() {
                return this.member.membership_type === 'userprofile' && !!this.member.userprofile;
            }
        },
        methods: {
            fetchFoldersList(fromFolder = null) {
                let MediaMembershipResource = Vue.getAngularModule('MediaMembershipResource');
                return MediaMembershipResource.get_folders(this.currentUser.id, this.member.id)
                    .then(resp => {
                        this.folders = resp.data.folders;
                        this.mediaCount = resp.data.media_count;
                    });
            },
            fetchPermissions() {
                let MediaMembershipResource = Vue.getAngularModule('MediaMembershipResource');
                return MediaMembershipResource.get_members_permissions(this.currentUser.id, this.member.id)
                    .then(
                        resp => {
                            this.permissionsList = resp.data;
                        },
                        err => Vue.notifications.error(err || 'Error')
                    );
            },
            fetchData() {
                let $rootScope = Vue.getAngularModule('$rootScope');
                let $q = Vue.getAngularModule('$q');
                $rootScope.show_dimmer();
                $q.all(this.fetchFoldersList(), this.fetchPermissions())
                    .finally($rootScope.hide_dimmer);
            },

            setPermissions(targetId, levelCode, value) {
                let $rootScope = Vue.getAngularModule('$rootScope');
                let MediaMembershipResource = Vue.getAngularModule('MediaMembershipResource');
                $rootScope.show_dimmer();
                MediaMembershipResource.set_permission(this.currentUser.id, this.member.id, targetId, levelCode, value)
                    .then(resp => {
                            if (value === 'edit') {
                                this.member.have_any_edit_access = true;
                            }
                            Vue.notifications.success('Updated');
                            this.permissionsList = resp.data;
                        },
                        err => Vue.notifications.error(err || 'Error'))
                    .finally($rootScope.hide_dimmer);
            },

            toggleMediaLevelEditPermission() {
                if (this.ownPermissionLevel === 'edit') {
                    this.setPermissions(this.currentUser.id, 'whole-media', 'view');
                }
                if (this.ownPermissionLevel === 'unset' || this.ownPermissionLevel === 'view' || this.ownPermissionLevel === 'none') {
                    this.setPermissions(this.currentUser.id, 'whole-media', 'edit');
                }
            },

            toggleMediaLevelViewPermissionLevel() {
                if (this.ownPermissionLevel === 'edit') {
                    return;
                }
                if (this.ownPermissionLevel === 'view') {
                    this.setPermissions(this.currentUser.id, 'whole-media', 'none');
                }
                if (this.ownPermissionLevel === 'unset' || this.ownPermissionLevel === 'none') {
                    this.setPermissions(this.currentUser.id, 'whole-media', 'view');
                }
            },
            setFolderLevelPermission({folder, value}) {
                this.setPermissions(folder.id, 'folder', value);
            }
        },
        created() {
            this.currentUser = this.owner;
            this.fetchData();
        },
        name: "MediaTree"
    };
</script>

<style scoped lang="scss">
    .media-tree {
        font-size: 20px;
        &__subfolders-list {
            margin-left: 15px;
        }
        .fa-eye {
            &.disabled {
                opacity: (0.5);
            }
        }
        .fa-pencil {
            &.disabled {
                opacity: (0.5);
            }

        }
    }
</style>