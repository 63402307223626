<template>
    <modal v-model="opened" :transition='0'>
        <div slot="header">
            <a id="close_popup" @click="close_popup"></a>
            <h3 class="modal-title">Invite successfully sent</h3>
        </div>
        <span>
            Invite successfully sent, you can pre-define access for this user in the Pending Invites section, would you like to go there now?
        </span>
        <div slot="footer">
            <button @click="close_popup" class="btn btn-mm btn-default">Not now</button>
            <button @click="go" class="btn btn-mm btn-green">OK</button>
        </div>
    </modal>
</template>

<script>
    import {DjangoUrls} from 'DjangoUrls';
    import Vue from 'vue';
    import PopupMixin from "../../mixins/PopupMixin";

    export default {

        name: "GoToManageInvitesPageProposalPopup",
        mixins: [PopupMixin],
        data() {
            return {
                opened: false,
                resolve: undefined,
                reject: undefined,
                doRedirect: false,
                circleOwnerName: undefined,
            };
        },
        methods: {
            show(doRedirect = false, circleOwnerName = undefined) {
                const self = this;
                this.opened = true;
                this.doRedirect = doRedirect;
                this.circleOwnerName = circleOwnerName;
                return new Promise((resolve, reject) => {
                    self.resolve = resolve;
                    self.reject = reject;
                });
            },
            go() {
                if (this.doRedirect && this.circleOwnerName) {
                    const $rootScope = Vue.getAngularModule('$rootScope');
                    $rootScope.show_dimmer();
                    document.location = DjangoUrls['user_detail'](this.circleOwnerName);
                    this.close();
                } else {
                    this.resolve('go');
                    this.close();
                }

            },
            close_popup() {
                this.reject('close');
                this.close();
            }
        }

    };
</script>

<style scoped>

</style>