<template>
    <selector-popup
            ref="popup"
            header="How you would like to add a picture?"
            :items="items"
            @selected="onSelected"
    >
        <template slot="footer">
            <button @click="back" type="button" class="btn btn-mm  btn-primary">Back</button>
        </template>
        <template slot="invisibleForms">
            <form enctype="multipart/form-data" style="display:none;">
                <input
                        v-show="config.max_count>1"
                        ref="multiFileUploadInput"
                        accept="image/jpeg,image/png,image/gif,image/webp,image/bmp"
                        type="file"
                        name="file_multi"
                        multiple
                        @change="onUploadFileFieldChanged"
                />
                <input
                        v-show="config.max_count===1"
                        ref="singleFileUploadInput"
                        accept="image/jpeg,image/png,image/gif,image/webp,image/bmp"
                        type="file"
                        name="file_single"
                        @change="onUploadFileFieldChanged"
                />
            </form>
            <form enctype="multipart/form-data">
                <input
                        ref="capturePictureHiddenButton"
                        type="file"
                        name="file"
                        accept="image/*"
                        capture="user"
                        @change="onFileFieldChanged"
                        style="display:none;"/>
            </form>
        </template>
    </selector-popup>
</template>

<script>
    import SelectorPopup from "../../../../vue/common_components/SelectorPopup/SelectorPopup";
    import AddMediaSelectorPopupMixin from "./AddMediaSelectorPopupMixin";

    export default {
        name: "AddPicturePopup",
        components: {SelectorPopup},
        mixins: [AddMediaSelectorPopupMixin],
        data() {
            return {
                videoFile: undefined,
                config: Object,
                videoRecordByMediaStreamSupported: false,
            };
        },
        computed: {
            items() {
                if (!this.config?.types?.length) {
                    return [];
                }
                const items = [];
                if (this.videoRecordByMediaStreamSupported && !this.allowCapture) {
                    items.push({
                        label: 'Capture from<br/> webcam',
                        iconClass: 'fa-camera',
                        action: 'select',
                        selection: 'capture',
                    });
                } else if (this.allowCapture) {
                    items.push({
                        label: 'Capture by<br/> device',
                        iconClass: 'fa-camera',
                        action: 'function',
                        function: 'captureByDevice',
                        noClose: true,
                    });
                }
                items.push({
                    label: 'Upload<br/>from device',
                    iconClass: 'fa-upload',
                    action: 'function',
                    function: 'uploadByDevice',
                    noClose: true,
                });
                if (this.config.allow_add_from_library) {
                    items.push({
                        label: 'Add from<br>My Media',
                        iconClass: 'fa-folder-o',
                        action: 'select',
                        selection: 'folder',
                    });
                }
                return items;
            },
        },
        methods: {
            onSelected(item) {
                if (item.action === 'select') {
                    this.resolve({
                        action: 'select',
                        selection: item.selection,
                    });
                } else if (item.action === 'function') {
                    if (item.function === 'captureByDevice') {
                        this.captureByDevice();
                    } else if (item.function === 'uploadByDevice') {
                        this.uploadByDevice();
                    }
                }
            },
            show(config) {
                this.config = config;
                return new Promise((resolve, reject) => {
                    this.reject = reject;
                    this.resolve = resolve;
                    this.$refs.popup.show()
                        .then(
                            (i) => this.onSelected(i),
                            reason => {
                                reject(reason);
                            }
                        );
                });
            },
            back() {
                this.reject('back');
            },
            onFileFieldChanged(e) {
                this.resolve({
                    action: 'file',
                    file: e.target?.files[0]
                });
                this.$refs.popup?.close();
            },
            onUploadFileFieldChanged(e) {
                this.resolve({
                    action: 'files',
                    files: e.target?.files
                });
                this.$refs.popup?.close();
            },
            captureByDevice() {
                setTimeout(() => {
                    this.$refs.capturePictureHiddenButton.click();
                }, 20);
            },
            uploadByDevice() {
                setTimeout(() => {
                    if (this.config.max_count === 1) {
                        this.$refs.singleFileUploadInput.click();
                    } else if (this.config.max_count > 1) {
                        this.$refs.multiFileUploadInput.click();
                    }
                }, 20);
            },
        },
        mounted() {
            this.$_detectMediaDeviseSupport('video')
                .then(supported => {
                    this.videoRecordByMediaStreamSupported = supported;
                });
        }
    };
</script>