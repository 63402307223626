var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      staticClass: "show-posts-popup",
      attrs: {
        size: "xlg",
        footer: false,
        "aria-label": "View Media Dialog",
        role: "dialog",
      },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", {
            ref: "closeButton",
            attrs: { "return-focus": _vm.focusToNext },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "row show-posts-popup__header" }, [
            _c("div", { staticClass: "col-xs-12 text-center" }, [
              _c(
                "a",
                {
                  ref: "prevArrow",
                  staticClass: "view-media-navigation-arrow left",
                  class: { disabled: !_vm.prevAvailable },
                  attrs: {
                    href: "javascript:;",
                    role: "button",
                    "aria-controls": "show-posts-popup__content",
                    "aria-disabled": !_vm.prevAvailable,
                  },
                  on: { click: _vm.previous },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-arrow-left",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" PREVIOUS\n                "),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "show-posts-popup__header__counter" }, [
                _vm._v(_vm._s(_vm.index + 1) + "/" + _vm._s(_vm.posts.length)),
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  ref: "nextArrow",
                  staticClass: "view-media-navigation-arrow right",
                  class: { disabled: !_vm.nextAvailable },
                  attrs: {
                    href: "javascript:;",
                    role: "button",
                    "aria-controls": "show-posts-popup__content",
                    "aria-disabled": !_vm.nextAvailable,
                  },
                  on: { click: _vm.next },
                },
                [
                  _vm._v("\n                    NEXT\n                    "),
                  _c("i", {
                    staticClass: "fa fa-arrow-right",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "row",
          attrs: { "aria-live": "polite", id: "show-posts-popup__content" },
        },
        [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "div",
              {
                staticClass: "show-posts-popup__container",
                staticStyle: { height: "100%" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "inline-block",
                    staticStyle: {
                      width: "99%",
                      height: "100%",
                      "font-size": "14px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { style: _vm.mediaPartStyle },
                      [
                        _vm.sticker
                          ? _c("render-sticker", {
                              directives: [
                                {
                                  name: "touch",
                                  rawName: "v-touch:swipe.right",
                                  value: _vm.previous,
                                  expression: "previous",
                                  arg: "swipe",
                                  modifiers: { right: true },
                                },
                                {
                                  name: "touch",
                                  rawName: "v-touch:swipe.left",
                                  value: _vm.next,
                                  expression: "next",
                                  arg: "swipe",
                                  modifiers: { left: true },
                                },
                              ],
                              staticClass: "show-posts-popup__sticker",
                              class: _vm.stickerClass,
                              attrs: { sticker: _vm.sticker },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("vue-inline-media", {
                          directives: [
                            {
                              name: "touch",
                              rawName: "v-touch:swipe.right",
                              value: _vm.previous,
                              expression: "previous",
                              arg: "swipe",
                              modifiers: { right: true },
                            },
                            {
                              name: "touch",
                              rawName: "v-touch:swipe.left",
                              value: _vm.next,
                              expression: "next",
                              arg: "swipe",
                              modifiers: { left: true },
                            },
                          ],
                          key: _vm.mediaAttachmentId,
                          attrs: {
                            "lazy-load": true,
                            item: _vm.mediaAttachment,
                            autoplay: true,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.linkedText
                      ? _c("div", { style: _vm.textPartStyle }, [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(_vm.linkedText) },
                          }),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "align-helper" }),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c(
            "span",
            {
              staticClass: "focus-catch-link",
              attrs: { tabindex: "0", "aria-hidden": "true" },
              on: { focus: _vm.focusToClose },
            },
            [_vm._v(" ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }