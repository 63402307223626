const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const STATIC_URL = '/static/'
const DEFAULT_AVATARS = {
    USER: `${STATIC_URL}images/new_daschboard_icons/ME.png`,
    GOAL: `${STATIC_URL}images/new_daschboard_icons/MY_GOALS.png`,
};


export {
    emailRegex,
    DEFAULT_AVATARS,
    STATIC_URL
};