<template>
    <div class="wiki-receipt-tree-slide">
        <i class="far wiki-receipt-tree-slide__icon"
           :class="checkButtonClasses"
           @click="toggleSlideIncluded"
        ></i>
        <div class="wiki-receipt-tree-slide__avatar" :style="avatarStyle">
            <i class="fa wiki-receipt-tree-slide__avatar-inner-icon" v-if="!avatarUrl" :class="holderIconClass"></i>
        </div>
        <div class="wiki-receipt-tree-slide__title">
            <a :href="slide.link" target="_blank"><strong>{{ slideName | cutName(30) }}</strong></a>
        </div>
    </div>
</template>

<script>
    import mediaContentUtils from "../../../view_media/mediaContentUtils";
    import TreePermissionDetectionMixinWikiSlide
        from "../tree_permission_detection_mixins/TreePermissionDetectionMixinWikiSlide";
    import {WIKI_PERMISSION_VALUE} from "../../../wiki_page/common/wikiPermissions";

    export default {
        name: "WikiReceiptTreeSlide",
        mixins: [TreePermissionDetectionMixinWikiSlide],
        props: {
            slide: Object,
            includedStuffConfig: Object,
        },
        data() {
            return {};
        },
        computed: {
            slideName() {
                return `${this.slide.position + 1}.${this.slide.caption || 'Untitled'}`;
            },
            currentSlideChecked() {
                return this.includedStuffConfig.slides.includes(this.slide.id);
            },
            checkButtonDisabled() {
                return ![WIKI_PERMISSION_VALUE.VIEW, WIKI_PERMISSION_VALUE.EDIT].includes(this.resultPermissionLevel);
            },
            checkButtonClasses() {
                const classes = [];
                if (this.checkButtonDisabled) {
                    classes.push('fa-eye-slash');
                    classes.push('color-gray');
                } else {
                    classes.push('fa-check');
                    classes.push('pointer');
                    if (this.currentSlideChecked) {
                        classes.push('color-grass-green');
                    }
                }
                return classes;
            },
            avatarUrl() {
                return mediaContentUtils().get_img_url(this.slide.media_content);
            },
            avatarStyle() {
                if (this.avatarUrl) {
                    return mediaContentUtils().generate_group_background(this.avatarUrl, []);
                }
                return {};
            },
            holderIconClass() {
                return mediaContentUtils().get_fa_icon_class(this.slide.media_content);
            },
        },
        methods: {
            toggleSlideIncluded() {
                if (this.checkButtonDisabled) {
                    return;
                }
                if (this.currentSlideChecked) {
                    this.includedStuffConfig.slides = this.includedStuffConfig.slides.filter(sId => sId !== this.slide.id);
                } else {
                    this.includedStuffConfig.slides.push(this.slide.id);
                }
                this.onIncludedStuffConfigUpdate(this.includedStuffConfig);
            },
            onIncludedStuffConfigUpdate(includedStuffConfig) {
                this.$emit('input', includedStuffConfig);
            },
        }
    };
</script>

<style scoped lang="scss">
  .wiki-receipt-tree-slide {
    margin-top: 6px;
    margin-bottom: 6px;

    &__avatar {
      text-align: center;
      vertical-align: middle;
      width: 60px;
      height: 50px;
      border-radius: 4px;
      border: solid 1px #333;
      display: inline-block;
    }

    &__avatar-inner-icon {
      line-height: 45px;
      font-size: 30px;
    }

    &__title {
      display: inline-block;
      vertical-align: middle;
    }

    &__icon {
      vertical-align: middle;
      font-size: 25px;
      margin: 0 4px;
      transition: color 0.2s ease;
    }
  }

</style>