/**
 * Created by orion on 07.03.17.
 */
import angular from 'angular';

//main shared modules
import 'angular-ui-bootstrap'

//shared modules
import simplePopups from './../../shared/simple_popups/simple_popup_module';
import notifications from './../../shared/notifications/notifications_module';

//popups

//app modules
import add_media from './../../add_media/add_media_module'
import view_media from './../../view_media/view_media_module'
import preload from './../../preload/preload'
import sharing_media from './../../sharing/sharing_module'

import './styles/folders.scss'

import infiniteScroll from '../../shared/infiniteScroll_directive'

//pages
import UsersMediaPageController from "./UsersMediaPageController";
import MediaFoldersResource from "./MediaFoldersResource";
import MediaMembershipResource from "./MediaMembershipResource";

import wikiSharingResourceFactory from "../../wiki/wiki_detail_page/wiki_sharing/sharing_resource_factory";
import mediaShareFactory from "./media_sharing/mediaShareFactory";

angular.module('mmApp.folders', [simplePopups, notifications, add_media, view_media, preload, sharing_media, infiniteScroll, 'ui.bootstrap'])
    .factory('MediaFoldersResource', MediaFoldersResource)
    .factory('MediaMembershipResource', MediaMembershipResource)
    .factory('mediaShareFactory', mediaShareFactory)
    .factory('MediaFolderSharingResource', wikiSharingResourceFactory('media_folder_sharing'))
    .controller('UsersMediaPageController', UsersMediaPageController);

export default 'mmApp.folders'