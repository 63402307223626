<template>
    <div class="">
        <div class="">Add to Calendar</div>
        <i
                class="fa fa-google pointer"
                v-tooltip="'Google Calendar'"
                style="color: #4285f4; font-size: 18px"
                @click="addToCalendar('google')"
        ></i>
        <i
                class="fa fa-apple pointer"
                v-tooltip="'iCal'"
                style="color: #505050; font-size: 22px; margin-left: 10px"
                @click="addToCalendar('apple')"
        ></i>
        <img src="./Outlook.com_icon.svg"
             class="pointer"
             v-tooltip="'Outlook'"
             alt=""
             @click="addToICalendar('outlook')"
             style="height: 20px; width: auto; margin-left: 10px"
        />
        <event-detail-page-period-selector-popup ref="add-to-calendar-type-selector"></event-detail-page-period-selector-popup>
    </div>
</template>

<script>
    import {DjangoUrls} from 'DjangoUrls';
    import Vue from 'vue';
    import EventDetailPagePeriodSelectorPopup from "../new_event/EventDetailPagePeriodSelectorPopup";

    export default {
        name: "AddToOuterCalendarsPanel",
        components: {EventDetailPagePeriodSelectorPopup},
        props: {
            eventId: {
                type: [Number, String],
            },
            dateMoment:{
                type: Object
            },
            dateForApi: {
                type: String,
            },
            isPeriodic: {
                type: Boolean,
            },

        },
        methods: {
            openInNewTab(url, newTab = false) {
                let win = window.open(url, newTab ? '_blank' : '_self');
                win.focus();
            },
            addToICalendar(whole) {
                const PREFIX = "api.v1:calendar_events_public-";
                const url = DjangoUrls[PREFIX + "get-add-to-icalendar-file"](this.eventId) + `?date=${this.dateForApi}&type=${whole ? 'whole' : 'exact'}`;
                this.openInNewTab(url);
            },
            addToGoogleCalendar(whole) {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const CalendarEventsPublicResource = Vue.getAngularModule('CalendarEventsPublicResource');
                $rootScope.show_dimmer();
                CalendarEventsPublicResource.get_add_to_google_calendar_link(this.eventId, this.dateForApi, whole ? 'whole' : 'exact')
                    .then(resp => {
                        this.openInNewTab(resp.data.link, false);
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            addToCalendar(provider) {
                const addToCalendarMethod = provider === "google" ? this.addToGoogleCalendar : this.addToICalendar;
                if (this.isPeriodic) {
                    this.$refs['add-to-calendar-type-selector'].show(this.dateMoment,
                        'Add Event to Calendar',
                        `This is a repeated Event. You can add every occurrence of this Event or just this one`)
                        .then(type => { // 'exact' or 'whole'
                            if (type === 'exact') {
                                addToCalendarMethod(false);
                            }
                            if (type === 'whole') {
                                addToCalendarMethod(true);
                            }
                        });
                } else {
                    addToCalendarMethod(false);
                }
            },
        }

    };
</script>

<style scoped>

</style>