<template>
    <modal :transition='0' :append-to-body="true" :backdrop="false" v-bind="$attrs" v-on="$listeners" @show="onShowed">
        <div slot="header">
            <slot name="header">

            </slot>
        </div>
        <div>
            <slot></slot>
        </div>
        <div slot="footer">
            <slot name="footer">

            </slot>
            <div class="row">
                <div class="col-sm-12" style="line-height: 2px;">
                    <span tabindex="0" aria-hidden="true" class="focus-catch-link" @focus="$parent.focusToClose">&nbsp;</span>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
    export default {
        name: "BasicModal",
        methods: {
            onShowed() {
                this.$parent.ariaHideRestAndCatchFocus();
            },
        }
    };
</script>
