var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chat-style-comment-linked-slide" }, [
    _c(
      "span",
      {
        staticClass: "chat-style-comment-linked-slide__text",
        class: {
          "chat-style-comment-linked-slide__text_white": _vm.moveAvatarToRight,
        },
      },
      [
        _c(
          "strong",
          [_c("simple-link", { attrs: { target: _vm.comment.userprofile } })],
          1
        ),
        _vm._v(" has linked\n        "),
        !_vm.wikiSlide.accessible
          ? _c("strong", [_vm._v("Wiki slide")])
          : _c("strong", [
              _c(
                "a",
                {
                  attrs: { href: _vm._f("backHrefQuery")(_vm.wikiSlide.link) },
                },
                [_vm._v("Wiki slide")]
              ),
            ]),
        _vm._v(":\n    "),
      ]
    ),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "render-quote",
          {
            attrs: {
              "limit-size": true,
              "quote-border-color": _vm.moveAvatarToRight ? "#fff" : undefined,
            },
          },
          [
            _c("wiki-detail-page-slide-header", {
              attrs: { slide: _vm.wikiSlide.data, height: 50 },
            }),
            _vm._v(" "),
            _vm.wikiSlide.data.media_content
              ? _c("vue-inline-media", {
                  key: _vm.wikiSlide.id,
                  attrs: {
                    item: _vm.wikiSlide.data.media_content,
                    "lazy-load": true,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("wiki-detail-page-slide-description", {
              attrs: { slide: _vm.wikiSlide.data, height: 80 },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }