import urls from 'DjangoUrls';
import _ from 'lodash';

let {DjangoUrls} = urls;
ManageExistingMembersResource.$inject = ['$http'];

function ManageExistingMembersResource($http) {
    const PREFIX = 'api.v1:manage_existing_members-';
    return {
        delete_group(circleOwnerId, membershipId, groupId) {
            const url = DjangoUrls[`${PREFIX}delete-group`](circleOwnerId);
            return $http({
                method: 'POST',
                url,
                params: {
                    member_id: membershipId,
                },
                data: {
                    id: groupId
                }
            });
        },

        load_more_groups(circleOwnerId, membershipId, params) {
            const url = DjangoUrls[`${PREFIX}load-more-groups`](circleOwnerId);
            return $http({
                method: 'GET',
                url,
                params: _.merge({member_id: membershipId}, params)
            });
        },
        get_groups_to_add(circleOwnerId, membershipId, params) {
            const url = DjangoUrls[`${PREFIX}get-groups-to-add`](circleOwnerId);
            return $http({
                method: 'GET',
                url,
                params: _.merge({member_id: membershipId}, params)
            });
        },
        add_groups(circleOwnerId, membershipId, id_list) {
            const url = DjangoUrls[`${PREFIX}add-groups`](circleOwnerId);
            return $http({
                method: 'POST',
                url,
                params: {
                    member_id: membershipId,
                },
                data: {
                    id_list
                }
            });
        },

        load_more_goals(circleOwnerId, membershipId, params) {
            const url = DjangoUrls[`${PREFIX}load-more-goals`](circleOwnerId);
            return $http({
                method: 'GET',
                url,
                params: _.merge({member_id: membershipId}, params)
            });
        },
        get_goals_to_add(circleOwnerId, membershipId, params) {
            const url = DjangoUrls[`${PREFIX}get-goals-to-add`](circleOwnerId);
            return $http({
                method: 'GET',
                url,
                params: _.merge({member_id: membershipId}, params)
            });
        },
        add_goals(circleOwnerId, membershipId, id_list) {
            const url = DjangoUrls[`${PREFIX}add-goals`](circleOwnerId);
            return $http({
                method: 'POST',
                url,
                params: {
                    member_id: membershipId,
                },
                data: {
                    id_list
                }
            });
        },
        delete_goal(circleOwnerId, membershipId, groupId) {
            const url = DjangoUrls[`${PREFIX}delete-goal`](circleOwnerId);
            return $http({
                method: 'POST',
                url,
                params: {
                    member_id: membershipId,
                },
                data: {
                    id: groupId
                }
            });
        },
        enable_media_membership(circleOwnerId, membershipId) {
            const url = DjangoUrls[`${PREFIX}enable-media-membership`](circleOwnerId);
            return $http({
                method: 'PATCH',
                params: {
                    member_id: membershipId,
                },
                url
            });
        },
        disable_media_membership(circleOwnerId, membershipId) {
            const url = DjangoUrls[`${PREFIX}disable-media-membership`](circleOwnerId);
            return $http({
                method: 'PATCH',
                params: {
                    member_id: membershipId,
                },
                url
            });
        },
        enable_wiki_membership(circleOwnerId, membershipId, wikiId) {
            const url = DjangoUrls[`${PREFIX}enable-wiki-membership`](circleOwnerId);
            return $http({
                method: 'PATCH',
                params: {
                    member_id: membershipId,
                    id: wikiId,
                },
                url
            });
        },
        disable_wiki_membership(circleOwnerId, membershipId, wikiId) {
            const url = DjangoUrls[`${PREFIX}disable-wiki-membership`](circleOwnerId);
            return $http({
                method: 'PATCH',
                params: {
                    member_id: membershipId,
                    id: wikiId,
                },
                url
            });
        },
        make_calendar_admin(circleOwnerId, membershipId) {
            const url = DjangoUrls[`${PREFIX}make-calendar-admin`](circleOwnerId);
            return $http({
                method: 'PATCH',
                url,
                params: {
                    member_id: membershipId,
                },
            });
        },
        make_calendar_not_admin(circleOwnerId, membershipId) {
            const url = DjangoUrls[`${PREFIX}make-calendar-not-admin`](circleOwnerId);
            return $http({
                method: 'PATCH',
                url,
                params: {
                    member_id: membershipId,
                },
            });
        },
        remove_explicit_calendar_admin_state(circleOwnerId, membershipId) {
            const url = DjangoUrls[`${PREFIX}remove-explicit-calendar-admin-state`](circleOwnerId);
            return $http({
                method: 'PATCH',
                url,
                params: {
                    member_id: membershipId,
                },
            });
        },
        enable_diary_membership(circleOwnerId, membershipId) {
            const url = DjangoUrls[`${PREFIX}enable-diary-membership`](circleOwnerId);
            return $http({
                method: 'PATCH',
                params: {
                    member_id: membershipId,
                },
                url
            });
        },
        disable_diary_membership(circleOwnerId, membershipId) {
            const url = DjangoUrls[`${PREFIX}disable-diary-membership`](circleOwnerId);
            return $http({
                method: 'PATCH',
                params: {
                    member_id: membershipId,
                },
                url
            });
        },
        set_circle_members_role(circleOwnerId, membershipId, {role}) {
            const url = DjangoUrls["api.v1:user-set-circle-members-role"](circleOwnerId);
            return $http({
                method: 'PATCH',
                url: url,
                data: {
                    id: membershipId,
                    role
                }
            });
        },
        set_circle_members_category(circleOwnerId, membershipId, category) {
            const url = DjangoUrls["api.v1:user-set-circle-members-category"](circleOwnerId);
            return $http({
                method: 'PATCH',
                url: url,
                data: {
                    id: membershipId,
                    category
                }
            });
        },
        get_info_about_media_membership(circleOwnerId, membershipId) {
            const url = DjangoUrls[`${PREFIX}get-info-about-media-membership`](circleOwnerId);
            return $http({
                method: 'GET',
                url: url,
                params: {
                    member_id: membershipId,
                },
            });
        },
        get_info_about_diary_membership(circleOwnerId, membershipId) {
            const url = DjangoUrls[`${PREFIX}get-info-about-diary-membership`](circleOwnerId);
            return $http({
                method: 'GET',
                url: url,
                params: {
                    member_id: membershipId,
                },
            });
        },
        get_info_about_wiki_membership(circleOwnerId, membershipId, wikiId) {
            const url = DjangoUrls[`${PREFIX}get-info-about-wiki-membership`](circleOwnerId);
            return $http({
                method: 'GET',
                url: url,
                params: {
                    member_id: membershipId,
                    id: wikiId,
                },
            });
        },

        get_diary_locations(circleOwnerId, membershipId) {
            const url = DjangoUrls[`${PREFIX}get-diary-locations`](circleOwnerId);
            return $http({
                method: 'GET',
                url: url,
                params: {
                    member_id: membershipId,
                },
            });
        },

        unmake_user_media_admin(circleOwnerId, membershipId) {
            const url = DjangoUrls[`${PREFIX}unmake-user-media-admin`](circleOwnerId);
            return $http({
                method: 'PATCH',
                url: url,
                params: {
                    member_id: membershipId,
                },
            });
        },

        make_user_media_admin(circleOwnerId, membershipId) {
            const url = DjangoUrls[`${PREFIX}make-user-media-admin`](circleOwnerId);
            return $http({
                method: 'PATCH',
                url: url,
                params: {
                    member_id: membershipId,
                },
            });
        },

        unmake_user_diary_admin(circleOwnerId, membershipId) {
            const url = DjangoUrls[`${PREFIX}unmake-user-diary-admin`](circleOwnerId);
            return $http({
                method: 'PATCH',
                url: url,
                params: {
                    member_id: membershipId,
                },
            });
        },

        make_user_diary_admin(circleOwnerId, membershipId) {
            const url = DjangoUrls[`${PREFIX}make-user-diary-admin`](circleOwnerId);
            return $http({
                method: 'PATCH',
                url: url,
                params: {
                    member_id: membershipId,
                },
            });
        },

        unmake_user_wiki_admin(circleOwnerId, membershipId, wikiId) {
            const url = DjangoUrls[`${PREFIX}unmake-user-wiki-admin`](circleOwnerId);
            return $http({
                method: 'PATCH',
                url: url,
                params: {
                    member_id: membershipId,
                },
                data: {
                    id: wikiId,
                }
            });
        },

        make_user_wiki_admin(circleOwnerId, membershipId, wikiId) {
            const url = DjangoUrls[`${PREFIX}make-user-wiki-admin`](circleOwnerId);
            return $http({
                method: 'PATCH',
                url: url,
                params: {
                    member_id: membershipId,
                },
                data: {
                    id: wikiId,
                }
            });
        },
        make_user_buddy(circleOwnerId, membershipId) {
            const url = DjangoUrls[`${PREFIX}make-user-buddy`](circleOwnerId);
            return $http({
                method: 'PATCH',
                url: url,
                params: {
                    member_id: membershipId,
                },
            });
        },
        unmake_user_buddy(circleOwnerId, membershipId) {
            const url = DjangoUrls[`${PREFIX}unmake-user-buddy`](circleOwnerId);
            return $http({
                method: 'PATCH',
                url: url,
                params: {
                    member_id: membershipId,
                },
            });
        },
        retrieve_membership_data(circleOwnerId, membershipId) {
            const url = DjangoUrls[`${PREFIX}retrieve-membership-data`](circleOwnerId);
            return $http({
                method: 'GET',
                url: url,
                params: {
                    member_id: membershipId,
                },
            });
        },
    };
}


export default ManageExistingMembersResource;