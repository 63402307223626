mediaPreview.$inject = ['mediaContentUtils', '$log'];
import './stories.scss';

let _ = require('lodash');

function mediaPreview(mediaContentUtils, $log) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            item: '=',
            noBorder: '=?',
            allowDelete: '=?',
            allowEdit: '=?',
            onDelete: '=?',
            onDeleted: '&?',
            onEdit: '&?',
            highLight: '=?'
        },
        template: require('./media_preview.html'),
        link: function ($scope, element, attrs, controller) {
            $scope.get_slide_preview_style = function () {
                if (_.isString($scope.item)) {
                    return {
                        'background-image': 'url(' + $scope.item + ')',
                        'background-position': 'center',
                        'background-size': '100%',
                        'background-repeat': 'no-repeat'
                    };
                }
                let res = {};
                if ($scope.allowDelete) {
                    res['position'] = 'relative';
                }
                return _.merge(res, mediaContentUtils.generate_background($scope.item));
            };

            $scope.is_slide_encoding_progress_now = function () {
                if (!$scope.item) {
                    return;
                }
                return mediaContentUtils.is_media_encoding_processing_now($scope.item);
            };

            $scope.is_slide_type_audio = function () {
                if (!$scope.item) {
                    return;
                }
                return $scope.item && ($scope.item.type == 'uploaded_audio' || $scope.item.type == 'recorded_audio');
            };
            $scope.is_slide_type_document = function () {
                if (!$scope.item) {
                    return;
                }
                return $scope.item && $scope.item.type == 'uploaded_document';
            };
            $scope.is_slide_type_video = function () {
                if (!$scope.item) {
                    return;
                }
                return $scope.item && $scope.item.type == 'upl_video';
            };

            $scope.del = function () {
                if($scope.onDeleted){
                    $scope.onDeleted();
                    return;
                }
                if ($scope.allowDelete) {
                    ($scope.onDelete || _.noop)($scope.item);
                }
            };
            $scope.edit = function () {
                if ($scope.allowEdit) {
                    ($scope.onEdit || _.noop)($scope.item);
                }
            };
        }
    };
}

export default mediaPreview;