/**
 * Created by orion on 11.06.17.
 */
import urls from 'DjangoUrls';
let {DjangoUrls} = urls;
CollectionsResource.$inject = ['$http'];
function CollectionsResource($http) {
    return {
        get_my_collections: function (params) {
            const url = DjangoUrls["api.v1:collections-get-my-collections"]();
            return $http({
                method: 'GET',
                url,
                params,
                cache: false
            })
        },

        create_collection: function (data) {
            const url = DjangoUrls["api.v1:collections-create-collection"]();
            return $http({
                method: 'POST',
                url,
                data
            })
        },

        rename_collection: function (id, name) {
            const url = DjangoUrls["api.v1:collections-rename-collection"](id);
            return $http({
                method: 'POST',
                url,
                data: {name}
            })
        },

        remove_collection: function (id) {
            const url = DjangoUrls["api.v1:collections-remove-collection"](id);
            return $http({
                method: 'DELETE',
                url
            })
        }
    }
}

export default CollectionsResource