<template>
    <div class="weeks-navigation">
        <div class="row margin-10-bottom weeks-navigation__mobile">
            <div class="col-xs-6 text-left">
                <button class="btn btn-mm-small background-tag-gray" @click="previousWeek">
                    Week Before
                </button>
            </div>
            <div class="col-xs-6 text-right">
                <button class="btn btn-mm-small background-tag-gray" @click="nextWeek">
                    Week After
                </button>
            </div>
        </div>
        <div class="row margin-10-bottom weeks-navigation__desktop">
            <div class="col-xs-3 text-left">
                <button class="btn btn-mm-small background-tag-gray" @click="previousWeek">
                    Week Before
                </button>
            </div>
            <div class="col-xs-6 text-center weeks-navigation__desktop__label">
                {{ loadedWeekLabel }}
            </div>
            <div class="col-xs-3 text-right">
                <button class="btn btn-mm-small background-tag-gray" @click="nextWeek">
                    Week After
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CalendarPageWeeksNavigation",
        props: {
            loadedDay: Object
        },
        computed: {
            loadedWeekLabel() {
                if (this.loadedDay) {
                    const firstDate = this.loadedDay;
                    const secondDate = this.loadedDay.clone().add('w', 1);
                    const firstDateFormat = secondDate.year() === firstDate.year() ? 'D MMMM' : 'D MMMM YYYY';
                    const firstDateFormatted = firstDate.format(firstDateFormat);
                    const secondDateFormatted = secondDate.format('D MMMM YYYY');
                    return `${firstDateFormatted} - ${secondDateFormatted}`;
                }
            }
        },
        methods: {
            previousWeek() {
                this.$emit('previousWeek');
            },
            nextWeek() {
                this.$emit('nextWeek');
            }
        }
    };
</script>

<style scoped lang="scss">
    .weeks-navigation {
        &__mobile {
            @media (min-width: 600px) {
                display: none;
            }
        }

        &__desktop {
            @media (max-width: 599px) {
                display: none;
            }

            &__label {
                font-weight: bolder;
                font-size: 21px;
                padding: 0;
            }
        }
    }

</style>