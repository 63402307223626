import {DjangoUrls} from 'DjangoUrls';
import axios from "axios";

const PREFIX = "api.v1:event_reminder-";

const event_reminder_resource = {
    check(eventId, date, calendarOwnerId) {
        const url = DjangoUrls[PREFIX + "check"]();
        console.log(url);
        return axios({
            method: 'GET',
            url: url,
            params: {
                event_id: eventId,
                date: date,
                calendar_owner_id: calendarOwnerId
            }
        });
    },
    set(eventId, date, calendarOwnerId, reminderType, reminderGap) {
        const url = DjangoUrls[PREFIX + "set"]();
        return axios({
            method: 'POST',
            url: url,
            data: {
                event_id: eventId,
                date: date,
                calendar_owner_id: calendarOwnerId,
                reminder_type: reminderType,
                reminder_gap: reminderGap,
            }
        });
    },
    cancel(reminderId) {
        const url = DjangoUrls[PREFIX + "cancel"](reminderId);
        return axios({
            method: 'DELETE',
            url
        });
    },
};

export default event_reminder_resource;