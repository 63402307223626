<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="header"
                 :size="(items.length>3)?'lg':undefined"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ header }}</h3>
        </div>
        <slot name="descriptionTop"></slot>
        <div class="sp__items-container">
            <selector-popup-item
                    ref="item"
                    :item="item"
                    v-for="item in items"
                    :key="item.label"
                    @click.native="onItemSelect(item)"
                    v-bind="item.attrs"
            ></selector-popup-item>
        </div>
        <div>
            <slot name="invisibleForms"></slot>
        </div>
        <div class="div" slot="footer">
            <div class="row">
                <div class="col-sm-12">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import BasicModal from "../BasicModal";
    import PopupPromiseMixin from "../../mixins/PopupPromiseMixin";
    import SelectorPopupItem from "./SelectorPopupItem";

    export default {
        name: "SelectorPopup",
        components: {SelectorPopupItem, BasicModal},
        mixins: [PopupPromiseMixin],
        computed: {
            firstFocusEl() {
                if (this.$refs.item?.length) {
                    return this.$refs.item[0]?.$el;
                } else {
                    return this.$refs.closeButton;
                }
            }
        },
        props: {
            header: String,
            items: Array,
        },
        methods: {
            onItemSelect(item) {
                if (item.noClose) {
                    this.$emit('selected', item);
                } else {
                    this.save(item);
                }
            }
        }

    };
</script>

<style scoped lang="scss">
  @import "../../../angular/app/styles/const";
  @import "../../../angular/app/styles/mixins";

  .sp {
    &__items-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-items: stretch;

    }
  }
</style>