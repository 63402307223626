/**
 * Created by orion on 06.04.17.
 */
import urls from 'DjangoUrls';
import axios from 'axios';
import { ACCESS_LINK_TOKEN_HEADER_NAME } from '../wiki/common';

let { DjangoUrls } = urls;

BlogEntryResource.$inject = ['$http'];

function BlogEntryResource ($http) {
    return {
        get_entry,
        get_entry_locations,
        get_entry_items,
        delete_entry,
        delete_entry_through_activity,
        update_fields,
        set_emotion,
        link_to_location,
        unlink_location,

        like,
        unlike,
        get_liked_users
    };

    function get_entry_items (entryId, access_code) {
        const url = DjangoUrls['api.v1:blog_entry-get-entry-items'](entryId);
        const params = {};
        if (access_code) {
            params.access_code = access_code;
        }
        return axios({
            method: 'GET',
            url,
            params,
        });
    }

    function get_entry (entry_id, access_code = undefined, accessToken) {
        const url = DjangoUrls['api.v1:blog_entry-get-entry'](entry_id);
        const headers = {};
        if (accessToken) {
            headers[ACCESS_LINK_TOKEN_HEADER_NAME] = accessToken;
        }
        const params = {};
        if (access_code) {
            params.access_code = access_code;
        }
        return axios({
            method: 'GET',
            url,
            headers,
            params,
        });
    }

    function get_entry_locations (entry_id, access_code = undefined, accessToken) {
        const url = DjangoUrls['api.v1:blog_entry-get-entry-locations'](entry_id);
        const headers = {};
        if (accessToken) {
            headers[ACCESS_LINK_TOKEN_HEADER_NAME] = accessToken;
        }
        const params = {};
        if (access_code) {
            params.access_code = access_code;
        }
        return axios({
            method: 'GET',
            url,
            headers,
            params,
        });
    }

    function delete_entry (entry_id) {
        const url = DjangoUrls['api.v1:blog_entry-delete-entry'](entry_id);
        return axios({
            method: 'DELETE',
            url: url
        });
    }

    function delete_entry_through_activity (entry_id) {
        const url = DjangoUrls['api.v1:blog_entry-delete-entry-through-activity'](entry_id);
        return axios({
            method: 'DELETE',
            url: url
        });
    }

    function like (entry_id) {
        const url = DjangoUrls['api.v1:blog_entry-like'](entry_id);
        return axios({
            method: 'PATCH',
            url: url
        });
    }

    function get_liked_users (entry_id, params) {
        const url = DjangoUrls['api.v1:blog_entry-get-liked-users'](entry_id);
        return axios({
            method: 'GET',
            url: url,
            params
        });
    }

    function unlike (entry_id) {
        const url = DjangoUrls['api.v1:blog_entry-unlike'](entry_id);
        return axios({
            method: 'PATCH',
            url: url
        });
    }

    function update_fields (entry_id, data) {
        const url = DjangoUrls['api.v1:blog_entry-detail'](entry_id);
        return axios({
            method: 'PATCH',
            url: url,
            data
        });
    }

    function set_emotion (entry_id, emotion) {
        const url = DjangoUrls['api.v1:blog_entry-set-emotion'](entry_id);
        return axios({
            method: 'POST',
            url: url,
            data: { emotion }
        });
    }

    function link_to_location (entry_id, location_id) {
        const url = DjangoUrls['api.v1:blog_entry-link-to-location'](entry_id);
        return axios({
            method: 'POST',
            url: url,
            data: {
                id: location_id
            }
        });
    }

    function unlink_location (entry_id, location_id) {
        const url = DjangoUrls['api.v1:blog_entry-unlink-location'](entry_id);
        return axios({
            method: 'POST',
            url: url,
            data: {
                id: location_id
            }
        });
    }

}

export default BlogEntryResource;
