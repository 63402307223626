var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "slide",
      class: { underDrag: _vm.underDrag },
      style: [_vm.draggingStyle],
    },
    [
      _c("media-preview", {
        attrs: {
          item: _vm.slide.media_content,
          text: _vm.slideName,
          "show-name": true,
          size: 100,
          "high-light": _vm.isActive || _vm.underDrag,
          "high-light-color": _vm.underDrag ? "#64bd63" : undefined,
        },
        nativeOn: {
          click: function ($event) {
            return _vm.onClick.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _c("i", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.editMode && !_vm.slide.is_fixed,
            expression: "editMode && !slide.is_fixed",
          },
        ],
        staticClass: "fa fa-arrows-alt slide__dragdrop-grip",
        style: _vm.dragGripStyle,
        on: {
          mousedown: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.dragGripClicked.apply(null, arguments)
          },
          mouseenter: function ($event) {},
          touchstart: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.dragGripTouched.apply(null, arguments)
          },
          click: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
          },
        },
      }),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade-out" } }, [
        (_vm.showChangedMark || _vm.showNewMark) &&
        !_vm.wasActive &&
        !_vm.editMode
          ? _c(
              "div",
              {
                staticClass: "slide__unread-mark",
                class: {
                  "slide__unread-mark_changed": _vm.showChangedMark,
                  "slide__unread-mark_new": _vm.showNewMark,
                },
              },
              [
                _vm.showChangedMark ? _c("span", [_vm._v("UPD")]) : _vm._e(),
                _vm.showNewMark ? _c("span", [_vm._v("NEW")]) : _vm._e(),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("i", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.editMode && _vm.wikiEditable && !_vm.slide.is_fixed,
            expression: "editMode&&wikiEditable && !slide.is_fixed",
          },
        ],
        staticClass: "fas fa-arrow-from-top fa-rotate-180 slide__move-icon",
        class: {
          "slide__move-icon_selected-to-move": _vm.isSelectedToMove,
        },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.toggleSlideSelect.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }