<template>
    <div class="center-block">
        <span class="align-helper"></span>
        <span v-if="!videoSrc" style="font-size: 18px;">This Video is not ready yet...</span>
        <video v-if="videoSrc"
               class="inline_video_container hidden-print"
               preload="auto"
               ref="video"
               :autoplay="autoplay"
               :poster="posterUrl"
               controls
               playsinline
               controlsList="nodownload"
               :style="[maxHeightStyle]"
        >
            <source :src="videoSrc" type="video/mp4" ref="videoSource">
            Your browser does not support the video tag.
        </video>
        <img v-if="posterUrl" :src="posterUrl" alt="" class="visible-print" height="350"
             style="margin: auto; display: block">
    </div>
</template>

<script>

    import captionPlayerBus from "../../shared/captionPlayer";

    function getVideoUrlFromMediaItem(mediaItem) {
        if (!mediaItem || !mediaItem[mediaItem.type]) {
            return undefined;
        }
        return mediaItem[mediaItem.type].video_url;
    }

    function getPosterUrlFromMediaItem(mediaItem) {
        if (!mediaItem || !mediaItem[mediaItem.type]) {
            return undefined;
        }
        return mediaItem[mediaItem.type].preview_url;
    }

    export default {
        name: "VueInlineMediaVideo",
        props: {
            item: Object,
            autoplay: {
                type: Boolean,
                default: false
            },
            maxHeight: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                videoUrlReceivedFromEncoding: undefined,
                posterUrlReceivedFromEncoding: undefined
            };
        },
        computed: {
            maxHeightStyle() {
                if (this.maxHeight) {
                    return {'max-height': this.maxHeight};
                }
            },
            videoSrc() {
                if (this.videoUrlReceivedFromEncoding) {
                    return this.videoUrlReceivedFromEncoding;
                }
                if (!this.item.upl_video.video_url) {
                    return;
                }
                return this.item.upl_video.video_url;
            },
            posterUrl() {
                if (this.posterUrlReceivedFromEncoding) {
                    return this.posterUrlReceivedFromEncoding;
                }
                if (!this.item.upl_video.video_url) {
                    return;
                }
                return this.item.upl_video.preview_url;
            }
        },
        watch: {
            videoSrc(n, o) {
                this.$nextTick(() => {
                    if (n) {
                        this.$refs.videoSource.src = n;
                        this.$refs.video.load();
                    } else {
                        this.$refs.video.pause();
                    }
                });
            }
        },
        mounted() {
            captionPlayerBus.$on('mediaEncoded', this.onMediaEncoded);
            this.$nextTick(() => {
                setTimeout(() => {
                    if (this.videoSrc && this.$refs.videoSource) {
                        this.$refs.videoSource.src = this.videoSrc;
                        this.$refs.video.load();
                    }
                }, 100);
            });
        },
        beforeDestroy() {
            captionPlayerBus.$off('mediaEncoded', this.onMediaEncoded);
        },
        methods: {
            onMediaEncoded(data) {
                if (this.item && this.item.type === 'upl_video' && this.item.upl_video.encoding_id === data.encoding_id) {
                    this.videoUrlReceivedFromEncoding = getVideoUrlFromMediaItem(data.encoding_media);
                    this.posterUrlReceivedFromEncoding = getPosterUrlFromMediaItem(data.encoding_media);
                    this.$nextTick(() => {
                        setTimeout(() => {
                            if (this.audioSrc) {
                                this.$refs.videoSource.src = this.videoSrc;
                                this.$refs.video.load();
                            }
                        }, 100);
                    });
                }
            },
            togglePlay() {
                const video = this.$refs.video;
                if (video) {
                    video.paused ? video.play() : video.pause();
                }
            },
        }
    };
</script>

<style scoped>
    .center-block {
        max-height: inherit;
        font-size: 0;
    }

    .inline_video_container {
        width: 100%;
        vertical-align: middle;
        display: inline-block;
        height: 100%;
        max-height: inherit;
        /*max-height: min(100%, 450px);*/
    }
</style>
