import BasicModalCloseButton from "../common_components/BasicModalCloseButton";
import CatchFocusMixin from "./CatchFocusMixin.js";
import BasicModal from "~/vue/common_components/BasicModal";

const elementHasParent = (el, parent) => {
    while (el.parentNode) {
        if (el.parentNode === parent) {
            return true;
        }
        el = el.parentNode;
    }
    return false;
};

const PopupPromiseMixin = {
    components: {BasicModalCloseButton, BasicModal},
    mixins: [CatchFocusMixin],
    data() {
        return {
            opened: false,
        };
    },
    computed: {
        firstFocusEl() {
            return this.$refs.closeButton;
        },
        catchFocusMultiAttempts() {
            return true;
        },
    },
    methods: {

        focusToClose() {
            this.$refs.closeButton?.focus();
        },
        setInitial(...rest) {
            // implement in child
        },
        resetData() {
            // implement in child
        },
        showedHook() {

        },
        ariaHideRestAndCatchFocus() {
            this.$nextTick(() => {
                setTimeout(() => {
                    this.$_catchFocus(this.firstFocusEl, 1, this.catchFocusMultiAttempts);
                    for (let el of window.document.body.children) {
                        el.setAttribute('aria-hidden', (el === this.$el) ? 'false' : 'true');
                    }
                    // this.startWatchFocus();
                }, 10);
            });
        },
        ariaShowRest() {
            for (let el of window.document.body.children) {
                el.removeAttribute('aria-hidden');
            }
        },
        closeHook() {

        },
        show(...rest) {
            this.setInitial(...rest);
            this.$nextTick(() => {
                setTimeout(() => {
                    this.opened = true;
                    this.showedHook();
                }, 1);
            });
            return new Promise((res, rej) => {
                this.resolve = res;
                this.reject = rej;
            });
        },
        showSilent(...rest){
            this.setInitial(...rest);
            this.$nextTick(() => {
                setTimeout(() => {
                    this.showedHook();
                }, 1);
            });
            return new Promise((res, rej) => {
                this.resolve = res;
                this.reject = rej;
            });
        },
        save(data) {
            this.closeHook();
            this.resolve(data);
            this.opened = false;
            this.resetData();
        },
        close(reason = 'close') {
            if (this.tmpHidden === true) {
                return;
            }
            // this.stopWatchFocus();
            this.ariaShowRest();
            this.closeHook();
            this.resetData();
            this.opened = false;
            this.reject(reason);
        },
        tmpHide() {
            this.tmpHidden = true;
            // this.stopWatchFocus();
            this.opened = false;
        },
        tmpShow() {
            this.tmpHidden = false;
            // this.startWatchFocus();
            this.opened = true;
        },
        onFocus(e) {
            console.log(document.activeElement, this.$el);
            const isFocusInsidePopup = () => {
                return elementHasParent(document.activeElement, this.$el);
            };
            setTimeout(() => {
                if (!isFocusInsidePopup()) {
                    this.$_catchFocus(this.firstFocusEl, 1, this.catchFocusMultiAttempts);
                }
            }, 10);
        },
        startWatchFocus() {
            document.addEventListener('focusout', this.onFocus);
        },
        stopWatchFocus() {
            document.removeEventListener('focusout', this.onFocus);
        }
    },
    mounted() {
        // this.startWatchFocus();
    },
    beforeDestroy() {
        // this.stopWatchFocus();
    }
};

export default PopupPromiseMixin;
