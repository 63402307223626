var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "hotkey",
          rawName: "v-hotkey",
          value: _vm.keymap,
          expression: "keymap",
        },
      ],
    },
    [
      _vm.allowAdd
        ? _c("comments-input", {
            ref: "CommentsInput",
            attrs: {
              replyTo: _vm.replyTo,
              mediaAttachment: _vm.mediaAttachment,
              "emit-typing-events": _vm.emitTypingEvents,
            },
            on: {
              addMedia: _vm.addMedia,
              uploadPicture: _vm.uploadPicture,
              recordAudio: _vm.recordAudio,
              deleteMedia: _vm.deleteMedia,
              addMessage: _vm.addMessage,
              cancelReply: _vm.cancelReply,
              typingStateChanged: _vm.typingStateChanged,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            "min-height": "36px",
            "padding-top": "5px",
            "padding-bottom": "5px",
            "font-size": "18px",
          },
        },
        [_vm._t("underInputContent")],
        2
      ),
      _vm._v(" "),
      _vm._l(_vm.results, function (comment) {
        return _c(
          "chat-style-comment",
          {
            key: comment.id,
            ref: `comment-${comment.id}`,
            refInFor: true,
            attrs: {
              comment: comment,
              author: comment.userprofile,
              "is-comment-right-side": _vm.isCommentRightSide,
              "selected-high-light":
                _vm.keyboardSelectedItem &&
                _vm.keyboardSelectedItem === comment,
            },
          },
          [
            _c(
              "div",
              {
                staticStyle: { "margin-top": "5px" },
                attrs: { slot: "bottomPanel" },
                slot: "bottomPanel",
              },
              [
                _vm.allowLike
                  ? _c(
                      "div",
                      {
                        staticClass: "bottom-panel-item hidden-print",
                        class: {
                          "color-gray": !comment.liked_by_me,
                          "color-blue": comment.liked_by_me,
                        },
                        style: {
                          cursor: _vm.allowLike ? "pointer" : "default",
                        },
                      },
                      [
                        _c("i", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip.bottom",
                              value: "Likes",
                              expression: "'Likes'",
                              modifiers: { bottom: true },
                            },
                          ],
                          staticClass: "fa",
                          class: {
                            "fa-heart-o": !comment.liked_by_me,
                            "fa-heart": comment.liked_by_me,
                          },
                          attrs: {
                            role: "button",
                            tabindex: "0",
                            "aria-label": "Like Comment",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toggleLike(comment)
                            },
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.toggleLike(comment)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: comment.likes_count,
                                expression: "comment.likes_count",
                              },
                            ],
                          },
                          [_vm._v(" " + _vm._s(comment.likes_count))]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.allowAdd
                  ? _c(
                      "div",
                      { staticClass: "bottom-panel-item hidden-print" },
                      [
                        _c("i", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip.bottom",
                              value: "Reply",
                              expression: "'Reply'",
                              modifiers: { bottom: true },
                            },
                          ],
                          staticClass: "fa fa-reply color-grass-green",
                          attrs: {
                            role: "button",
                            "aria-label": "Reply to comment",
                            tabindex: "0",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.reply(comment)
                            },
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.reply(comment)
                            },
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isCommentDeletable(comment)
                  ? _c(
                      "div",
                      { staticClass: "bottom-panel-item hidden-print" },
                      [
                        _c("i", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip.bottom",
                              value: "Delete",
                              expression: "'Delete'",
                              modifiers: { bottom: true },
                            },
                          ],
                          staticClass: "fa fa-trash-o color-red",
                          attrs: {
                            role: "button",
                            "aria-label": "Delete Comment",
                            tabindex: "0",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.del(comment)
                            },
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.del(comment)
                            },
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            comment.reply_to && comment.reply_to.linked_blog_entry
              ? _c("chat-style-comment-reply-linked-entry", {
                  attrs: {
                    slot: "reply",
                    comment: comment.reply_to,
                    "white-border": _vm.isCommentRightSide(comment),
                  },
                  slot: "reply",
                })
              : comment.reply_to && comment.reply_to.linked_blog_answer
              ? _c("chat-style-comment-reply-linked-blog-answer", {
                  attrs: {
                    slot: "reply",
                    comment: comment.reply_to,
                    "white-border": _vm.isCommentRightSide(comment),
                  },
                  slot: "reply",
                })
              : comment.reply_to
              ? _c("chat-style-comment-reply", {
                  attrs: {
                    slot: "reply",
                    comment: comment.reply_to,
                    "white-border": _vm.isCommentRightSide(comment),
                    "allow-expand": _vm.allowReplyExpand,
                  },
                  slot: "reply",
                })
              : comment.is_reply_to
              ? _c("chat-style-comment-reply", {
                  attrs: {
                    slot: "reply",
                    "show-author": false,
                    comment: { text: "Deleted" },
                    "white-border": _vm.isCommentRightSide(comment),
                  },
                  slot: "reply",
                })
              : _vm._e(),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.allLoaded && !_vm.results.length
        ? _vm._t("emptyMessage", function () {
            return [
              _c("div", { staticClass: "alert alert-info" }, [
                _vm._v("\n            There are no comments yet.\n        "),
              ]),
            ]
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "infinite-scroll",
            rawName: "v-infinite-scroll",
            value: _vm.loadMore,
            expression: "loadMore",
          },
        ],
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }