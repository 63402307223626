<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 aria-label="Crop Picture Dialog"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ title }}</h3>
        </div>
        <Cropper
                v-if="opened"
                ref="cropper"
                classname="cropper"
                :stencil-component="stencilComponent"
                :stencil-props="stencilProps"
                :src="image"
                :max-width="4800"
                :max-height="4800"
                :restrictions="pixelsRestriction"
        ></Cropper>

        <div slot="footer">
            <div class="row">
                <div class="col-sm-5 text-left">
                    <vue-switch-with-label :collapse-on-mobile="false" name="save_to_media" v-model="saveToMedia"
                                           label="Save to My Media"
                                           v-if="allowSaveToMedia"></vue-switch-with-label>
                </div>
                <div class="col-sm-7 text-right">
                    <button
                            type="button" @click="close()"
                            class="btn btn-mm
                             btn-primary"
                    >Cancel
                    </button>
                    <button
                            type="button"
                            ref="selectButton"
                            @click="select()"
                            @keydown.tab.stop.prevent="focusToClose"
                            class="btn btn-mm  btn-green"
                    >Select
                    </button>
                </div>
            </div>

        </div>
    </basic-modal>
</template>

<script>
    import {Cropper, CircleStencil, RectangleStencil} from 'vue-advanced-cropper';
    import BasicModal from "../../../vue/common_components/BasicModal";
    import PopupPromiseMixin from "../../../vue/mixins/PopupPromiseMixin";

    export default {
        components: {BasicModal, Cropper},
        mixins: [PopupPromiseMixin],
        data() {
            return {
                title: undefined,
                opened: false,
                image: undefined,
                stencilComponent: CircleStencil,
                stencilProps: {},
                allowSaveToMedia: false,
                saveToMedia: true,
            };
        },
        computed: {
            firstFocusEl() {
                return this.$refs.selectButton;
            }
        },
        methods: {
            setInitial(image, title = 'Avatar', rectangle = false, allowSaveToMedia = false, stencilProps={}) {

                // const getBase64FromImageUrl = (url) => {
                //     const img = new Image();
                //     const vm = this;
                //     img.setAttribute('crossOrigin', 'anonymous');
                //     img.onload = function() {
                //         const canvas = document.createElement("canvas");
                //         canvas.width = this.width;
                //         canvas.height = this.height;
                //
                //         const ctx = canvas.getContext("2d");
                //         ctx.drawImage(this, 0, 0);
                //         const dataURL = canvas.toDataURL("image/png");
                //         vm.image = dataURL;
                //         vm.opened = true;
                //     };
                //     img.src = url;
                // };

                this.allowSaveToMedia = allowSaveToMedia;
                if (rectangle) {
                    this.stencilComponent = RectangleStencil;
                    this.stencilProps = stencilProps;
                } else {
                    this.stencilComponent = CircleStencil;
                }
                this.title = title;

                // const isDataUrl = image.startsWith('data:');
                // if (isDataUrl) {
                this.image = image;
                this.opened = true;
                // } else {
                //     getBase64FromImageUrl(image);
                // }
            },
            select() {
                const {canvas} = this.$refs.cropper.getResult();
                const imageToUpload = canvas.toDataURL('image/jpeg', 1.0);
                this.save({
                    avatarBase64value: imageToUpload,
                    saveToMedia: this.saveToMedia && this.allowSaveToMedia
                });
            },
            pixelsRestriction({
                                  minWidth,
                                  minHeight,
                                  maxWidth,
                                  maxHeight,
                                  imageWidth,
                                  imageHeight
                              }) {
                return {
                    minWidth,
                    minHeight,
                    maxWidth,
                    maxHeight
                };
            }
        },
        name: "AvatarCropPopup"
    };
</script>

<style scoped>
    .cropper {
        /*border: solid 1px #EEE;*/
        height: 350px;
        width: 100%;
    }
</style>
