import urls from 'DjangoUrls';

let {DjangoUrls} = urls;
sessionExpireResource.$inject = ['$http', '$log'];

function sessionExpireResource($http, $log) {
    return {
        get_session_status,
        session_prevent_expire
    };

    function get_session_status() {
        const url = DjangoUrls["api.v1:get_session_status"]();
        return $http({
            method: 'GET',
            url
        })
    }

    function session_prevent_expire() {
        const url = DjangoUrls["api.v1:session_prevent_expire"]();
        return $http({
            method: 'GET',
            url
        })
    }
}

export default sessionExpireResource;