circleEmailInvitationController.$inject = ['$log', '$preloaded', 'UserProfileCircleResource', 'notifications', 'CurrentUser', 'simplePopupFactory', '$rootScope', '$q'];
import urls from 'DjangoUrls';

let {DjangoUrls} = urls;

function circleEmailInvitationController($log, $preloaded, UserProfileCircleResource, notifications, CurrentUser, simplePopupFactory, $rootScope, $q) {
    let self = this;

    function init() {
        self.circle_owner_name = $preloaded.circle_owner_name;
        self.groups_to_join_count = $preloaded.groups_to_join_count;
        self.circle_owner_id = $preloaded.circle_owner_id;
        self.invitation_code = $preloaded.invitation_code;
        self.loginLink = DjangoUrls['auth_login']() + `?next=${document.location.pathname}`;
        self.is_authenticated = CurrentUser.is_authenticated();

        if (self.is_authenticated) {
            let message = `You have been invited to ${self.circle_owner_name}'s CircleGROUPS_ADDITION. Click ‘<strong>Join</strong>’ to accept the invitation.`;
            if (self.groups_to_join_count) {
                if (self.groups_to_join_count === 1) {
                    message = message.replace('GROUPS_ADDITION', ' and 1 Group');
                } else {
                    message = message.replace('GROUPS_ADDITION', ` and ${self.groups_to_join_count} Groups`);
                }
            } else {
                message = message.replace('GROUPS_ADDITION', '');
            }


            simplePopupFactory.show_popup(`Join ${self.circle_owner_name}'s Circle`, message, 'Join', 'Reject Invitation')
                .then(confirm => {
                    $rootScope.show_dimmer();
                    return UserProfileCircleResource.join_as_existed_user(self.circle_owner_id, self.invitation_code);
                }, rejectReason => {
                    $rootScope.show_dimmer();
                    if (rejectReason === 'cancel') {
                        return UserProfileCircleResource.reject_invitation(self.circle_owner_id, self.invitation_code);
                    }
                    if (rejectReason === 'close') {
                        document.location.href = DjangoUrls['index']();
                        return  new Promise(()=>{});
                    }

                })
                .then(resp => {
                    document.location.href = '/';
                }, err => {
                    notifications.error(err || 'Error');
                    $rootScope.hide_dimmer();
                });
        }
    }

    init();

    self.onSave = function (data) {
        data.invitation_code = self.invitation_code;
        UserProfileCircleResource.reg_by_invite(self.circle_owner_id, data)
            .then(resp => {
                notifications.success('Successfully registered');
                document.location.href = '/';
            }, err => notifications.error(err || 'Error'));
    };
}

export default circleEmailInvitationController;