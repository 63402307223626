var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wiki-receipt-tree-section" }, [
    _c(
      "div",
      { staticClass: "wiki-receipt-tree-section__expand-icon-container" },
      [
        _c("i", {
          staticClass: "far wiki-receipt-tree-section__icon",
          class: _vm.chevronClasses,
          on: { click: _vm.toggleContentExpand },
        }),
      ]
    ),
    _vm._v(" "),
    _c("i", {
      staticClass: "far wiki-receipt-tree-section__icon",
      class: _vm.checkButtonClasses,
      on: { click: _vm.toggleSectionIncluded },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "wiki-receipt-tree-section__avatar border-group",
        style: _vm.sectionAvatarStyle,
      },
      [
        _vm.isAvatarEmpty
          ? _c("i", {
              staticClass:
                "fa fa-picture-o wiki-receipt-tree-section__avatar-icon",
            })
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c("span", [
      _c("a", { attrs: { href: _vm.section.link, target: "_blank" } }, [
        _c("strong", [
          _vm._v(_vm._s(_vm._f("cutName")(_vm.section.caption, 30))),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.subsectionsExpanded
      ? _c(
          "div",
          { staticClass: "wiki-receipt-tree-section__children-container" },
          [
            _vm._l(_vm.section.subsections, function (section) {
              return _c("wiki-receipt-tree-section", {
                key: section.id,
                attrs: {
                  section: section,
                  "inherited-permission-level": _vm.resultPermissionLevel,
                  "permissions-list": _vm.permissionsList,
                  "included-stuff-config": _vm.includedStuffConfig,
                },
                on: { input: _vm.onIncludedStuffConfigUpdate },
              })
            }),
            _vm._v(" "),
            _vm._l(_vm.section.slides, function (slide) {
              return _c("wiki-receipt-tree-slide", {
                key: slide.id,
                attrs: {
                  slide: slide,
                  "inherited-permission-level": _vm.resultPermissionLevel,
                  "included-stuff-config": _vm.includedStuffConfig,
                  "permissions-list": _vm.permissionsList,
                },
                on: { input: _vm.onIncludedStuffConfigUpdate },
              })
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }