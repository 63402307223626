var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vue-simple-header",
    {
      directives: [
        {
          name: "hotkey",
          rawName: "v-hotkey",
          value: _vm.keymap,
          expression: "keymap",
        },
      ],
      attrs: {
        "separation-mode": "sm",
        "right-part-size": 6,
        "left-part-size": 6,
      },
    },
    [
      _c(
        "span",
        { attrs: { slot: "panelLeft" }, slot: "panelLeft" },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "panelRight" }, slot: "panelRight" },
        [
          _vm.groupButtonsNeeded && !_vm.buttonsExpanded && !_vm.editMode
            ? _c("vue-folder-button", {
                attrs: {
                  "no-text-on-mobile": true,
                  text: "Show More",
                  "icon-class": "fa-ellipsis-h",
                },
                nativeOn: {
                  click: function ($event) {
                    _vm.buttonsExpanded = true
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.exportsAvailable && !_vm.editMode
            ? _c("vue-folder-button", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.buttonsExpanded || !_vm.groupButtonsNeeded,
                    expression: "buttonsExpanded||!groupButtonsNeeded",
                  },
                ],
                attrs: {
                  "no-text-on-mobile": true,
                  text: "Exports",
                  "icon-class": "fa-arrow-alt-down",
                  target: {
                    name: "MainWikiPageReports",
                    query: { from: _vm.backHrefFromParam },
                  },
                  "is-router-link": "",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.logAvailable && !_vm.editMode
            ? _c("vue-folder-button", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.buttonsExpanded || !_vm.groupButtonsNeeded,
                    expression: "buttonsExpanded||!groupButtonsNeeded",
                  },
                ],
                attrs: {
                  "no-text-on-mobile": true,
                  text: "Show Log",
                  "icon-class": "fa-file-contract",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.showWikiLog.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.manageAccessAvailable && !_vm.editMode
            ? _c("vue-folder-button", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.buttonsExpanded || !_vm.groupButtonsNeeded,
                    expression: "buttonsExpanded||!groupButtonsNeeded",
                  },
                ],
                attrs: {
                  color: "brown",
                  "no-text-on-mobile": true,
                  "max-width": 92,
                  text: "Manage Access",
                  "icon-class": "fa-key",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.goToManageAccess.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("vue-folder-button", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.editMode && !_vm.isMainSectionOpened && _vm.wikiEditable,
                expression: "editMode&&!isMainSectionOpened&&wikiEditable",
              },
            ],
            attrs: {
              "no-text-on-mobile": true,
              disabled: _vm.moveSectionsOrSlidesButtonDisabled,
              text: "Move",
              "icon-class": "fas fa-arrow-from-top fa-rotate-180",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.moveSectionsOrSlides.apply(null, arguments)
              },
            },
          }),
          _vm._v(" "),
          _c("vue-folder-button", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.editMode && _vm.createSectionVisible,
                expression: "editMode&&createSectionVisible",
              },
            ],
            attrs: {
              "no-text-on-mobile": true,
              text: "Create",
              "icon-class": "fa-plus",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.createSection.apply(null, arguments)
              },
            },
          }),
          _vm._v(" "),
          _c("vue-folder-button", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isWikiSharable && !_vm.editMode,
                expression: "isWikiSharable&&!editMode",
              },
            ],
            attrs: {
              "no-text-on-mobile": true,
              text: _vm.shareButtonText,
              "icon-class": "fa-share-square",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.shareSection.apply(null, arguments)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "vue-folder-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isEditable,
                  expression: "isEditable",
                },
              ],
              attrs: {
                "no-text-on-mobile": true,
                text: _vm.editIconText,
                "icon-class": _vm.editIconClass,
                "icon-prefix": "far",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.toggleEditMode.apply(null, arguments)
                },
              },
            },
            [
              _vm.editMode
                ? _c(
                    "span",
                    {
                      staticClass: "fa-stack",
                      staticStyle: { width: "32px" },
                      attrs: { slot: "icon" },
                      slot: "icon",
                    },
                    [
                      _c("i", {
                        staticClass: "far fa-pencil fa-stack-1x",
                        staticStyle: { position: "absolute" },
                      }),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "far fa-slash fa-stack-1x fa-inverse",
                        staticStyle: { position: "absolute" },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c("vue-folder-button", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canBecomeWikiAdmin,
                expression: "canBecomeWikiAdmin",
              },
            ],
            attrs: {
              color: "blue",
              text: "Make Me Admin",
              "no-text-on-mobile": true,
              "icon-class": "fa-user-secret",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.makeMeAdmin()
              },
            },
          }),
          _vm._v(" "),
          _c("vue-folder-button", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showBackButton,
                expression: "showBackButton",
              },
            ],
            attrs: {
              "no-text-on-mobile": true,
              color: "blue",
              text: "Back",
              "icon-class": "fa-reply",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.back.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }