CommunitySubscriptionsResource.$inject = ['$http'];
import {DjangoUrls} from 'DjangoUrls';

function CommunitySubscriptionsResource($http) {
    return {
        get_subscription_state,
        subscribe,
        unsubscribe,
    };


    function get_subscription_state(community_id) {
        const url = DjangoUrls["api.v1:community_subscriptions-get-subscription-state"](community_id);
        return $http({
            method: 'GET',
            url,
        });
    }

    function subscribe(community_id) {
        const url = DjangoUrls["api.v1:community_subscriptions-subscribe"](community_id);
        return $http({
            method: 'PATCH',
            url,
        });
    }

    function unsubscribe(community_id) {
        const url = DjangoUrls["api.v1:community_subscriptions-unsubscribe"](community_id);
        return $http({
            method: 'PATCH',
            url,
        });
    }
}

export default CommunitySubscriptionsResource;