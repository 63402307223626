var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wiki-tree" }, [
    _c("div", { staticClass: "wiki-tree__section" }, [
      _c("i", {
        staticClass: "fa",
        class: {
          "fa-chevron-down": _vm.mainSectionExpanded,
          "fa-chevron-right": !_vm.mainSectionExpanded,
        },
        on: { click: _vm.toggleMainSectionExpand },
      }),
      _vm._v(" "),
      _vm.allowAssignEditPermission
        ? _c("i", {
            staticClass: "fa fa-pencil",
            class: _vm.editButtonClasses,
            on: {
              click: function ($event) {
                return _vm.toggleWikiLevelEditPermission()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("i", {
        staticClass: "fa fa-eye",
        class: _vm.viewButtonClasses,
        on: {
          click: function ($event) {
            return _vm.toggleWikiLevelViewPermissionLevel()
          },
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "wiki-tree__section__owners-avatar",
        style: _vm.ownerAvatarStyle,
      }),
      _vm._v(" "),
      _c("strong", [_vm._v(_vm._s(_vm.mainTitle))]),
    ]),
    _vm._v(" "),
    _vm.mainSectionExpanded
      ? _c(
          "div",
          {},
          _vm._l(_vm.sections, function (section) {
            return _c("wiki-tree-section", {
              key: section.id,
              attrs: {
                avatarUrl:
                  section.position === 0 && !section.avatar
                    ? _vm.owner.avatar
                    : undefined,
                section: section,
                domain: _vm.member.domain,
                "access-code": _vm.accessCodeIfAccessCodeMembership,
                allowAssignEditPermission: _vm.allowAssignEditPermission,
                permissionsList: _vm.permissionsList,
                inheritedPermissionLevel: _vm.resultPermissionLevel,
              },
            })
          }),
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }