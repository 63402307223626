<template>
    <span>
        <i
                ref="icon"
                class="pointer"
                role="button"
                aria-label="Play Audio Caption"
                :class="iconClasses"
                tabindex="0"
                v-if="audioCaptionUrl"
                @click.stop.prevent="toggleSound"
                @touchstart.stop.prevent="toggleSound"
                @keydown.enter.stop.prevent="toggleSound"
                @keydown.space.stop.prevent="toggleSound"
        ></i>
    </span>
</template>

<script>
    import captionPlayerBus from '../../angular/app/shared/captionPlayer';
    import CatchFocusMixin from "../mixins/CatchFocusMixin";

    function getAudioUrlFromAudioCaptionObject(audioCaption) {
        if (!audioCaption || !audioCaption[audioCaption.type]) {
            return undefined;
        }
        return audioCaption[audioCaption.type].audio_url;
    }

    export default {
        name: "AudioToggle",
        mixins: [CatchFocusMixin],
        props: {
            audioCaption: {
                required: false,
                type: Object
            },
            faClass: {
                type: String,
                default: 'fa',
            }
        },
        data() {
            return {
                soundPlaying: false,
                audioUrlReceivedFromEncoding: undefined,

            };
        },
        computed: {
            iconClasses() {
                const res = [this.faClass];
                if (this.soundPlaying) {
                    res.push('fa-stop-circle');
                } else {
                    res.push('fa-volume');
                }
                return res;
            },
            isTouchDevise() {
                return ('ontouchstart' in window) || window.TouchEvent || window.DocumentTouch && document instanceof DocumentTouch;
            },
            audioCaptionUrl() {
                if (this.audioUrlReceivedFromEncoding) {
                    return this.audioUrlReceivedFromEncoding;
                }
                return getAudioUrlFromAudioCaptionObject(this.audioCaption);
            }
        },
        methods: {
            focus() {
                this.$_catchFocus(this.$refs.icon);
            },
            playSound() {
                if (!this.audioCaptionUrl) {
                    return;
                }
                captionPlayerBus.$emit('playSound', this.audioCaptionUrl);
                this.soundPlaying = true;
            },
            stopSound() {
                captionPlayerBus.$emit('stopSound');
            },
            toggleSound() { // for external call
                if (this.soundPlaying) {
                    this.stopSound();
                } else {
                    this.playSound();
                }
            },
            onPlayStop() {
                this.soundPlaying = false;
            },
            onMediaEncoded(data) {
                if (this.audioCaption && this.audioCaption.type === 'recorded_audio' && this.audioCaption.recorded_audio.encoding_id === data.encoding_id) {
                    this.audioUrlReceivedFromEncoding = getAudioUrlFromAudioCaptionObject(data.encoding_media);
                }
            }
        },

        mounted() {
            captionPlayerBus.$on('onPlayStop', this.onPlayStop);
            captionPlayerBus.$on('mediaEncoded', this.onMediaEncoded);
        },
        beforeDestroy() {
            this.stopSound();
            captionPlayerBus.$off('onPlayStop', this.onPlayStop);
            captionPlayerBus.$off('mediaEncoded', this.onMediaEncoded);
        }
    };
</script>

<style scoped>

</style>