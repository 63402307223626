<template>
    <modal v-model="opened" :transition='0' :append-to-body="true" :backdrop="false"
           class="add-profile-info-popup">
        <div slot="header">
            <a id="close_popup" @click="closePopup"></a>
            <h3 class="modal-title">Protect yourself!</h3>
        </div>
        <div class="row margin-15-bottom">
            <div class="col-sm-12">
                <p>Your password is not secure please make a better one of at least 8 characters with lower and upper case and at least one symbol or number.</p>
            </div>
        </div>
        <div slot="footer">
            <div class="row">
                <div class="col-xs-12 ">
                    <button class="btn btn-mm" @click="closePopup"><i class="fa fa-times"></i> Remind me later</button>
                    <a :href="aboutMeHref" @click.prevent.stop="goToProfile">
                        <button class="btn btn-mm btn-green save-button">
                            <i class="fa fa-check"></i> Go to Settings
                        </button>
                    </a>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
    import {DjangoUrls} from 'DjangoUrls';
    import {CurrentUserClass} from "../shared/CurrentUser";

    export default {
        name: "ImprovePasswordWarning",
        data() {
            return {
                firstName: '',
                lastName: '',
                opened: false,
                username: '',
            };
        },
        computed: {
            aboutMeHref() {
                return `${DjangoUrls['user_detail_me']()}profile`;
            },
            header() {
                const cu = new CurrentUserClass();
                const username = cu.first_name || cu.username;
                return `Hello ${username}`;
            },
            allowSave() {
                return this.firstName && this.lastName;
            }
        },
        methods: {
            closePopup() {
                this.opened = false;
                this.resolve('postpone');
            },
            show() {
                this.opened = true;
                return new Promise((res, rej) => {
                    this.resolve = res;
                    this.reject = rej;
                });
            },
            goToProfile() {
                this.opened = false;
                this.resolve('open_profile_settings');
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../styles/const";

    .add-profile-info-popup {
        font-size: $bigger-font-size;
    }

</style>