var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "media-folder" }, [
    _c("i", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.folder.has_sub_items,
          expression: "folder.has_sub_items",
        },
      ],
      staticClass: "fa pointer",
      class: _vm.chevronClasses,
      on: { click: _vm.toggleExpandFolder },
    }),
    _vm._v(" "),
    _vm.allowAssignEditPermission
      ? _c("i", {
          staticClass: "fa fa-pencil",
          class: _vm.editButtonClasses,
          on: { click: _vm.toggleFolderLevelEditPermission },
        })
      : _vm._e(),
    _vm._v(" "),
    _c("i", {
      staticClass: "fa fa-eye",
      class: _vm.viewButtonClasses,
      on: { click: _vm.toggleFolderLevelViewPermissionLevel },
    }),
    _vm._v(" "),
    _c("i", { staticClass: "fa fa-folder-o" }),
    _vm._v(" " + _vm._s(_vm.folder.name) + "\n    "),
    _c(
      "div",
      { staticClass: "media-folder__subfolders-list" },
      _vm._l(_vm.folder.folders, function (subfolder) {
        return _c("media-tree-folder", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isExpanded,
              expression: "isExpanded",
            },
          ],
          key: subfolder.id,
          attrs: {
            "current-user": _vm.currentUser,
            member: _vm.member,
            folder: subfolder,
            allowAssignEditPermission: _vm.allowAssignEditPermission,
            permissionsList: _vm.permissionsList,
            inheritedPermissionLevel: _vm.resultPermissionLevel,
          },
          on: { setFolderLevelPermission: _vm.setFolderLevelPermission },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }