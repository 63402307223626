<template>
    <div class="">
        <community-page-alternative-header title="Wiki Access" :left-part-size="7" scaling="xs">
            <vue-folder-button icon-class="fa-user-plus" @click.native="inviteUsers"></vue-folder-button>
            <router-link :to="{name: backRouteName}" v-if="viewerIsAdmin">
                <vue-folder-button color="blue" icon-class="fa-reply"></vue-folder-button>
            </router-link>
        </community-page-alternative-header>
        <div class="wiki-access-table">
            <wiki-members-list-table
                    :members="members"
                    :sections="sections"
                    :owner="owner"
                    @openTreeOnMember="openTreeOnMember"
                    @toggleMemberAdminStatus="toggleMemberAdminStatus"
                    @removeMember="removeMember"
                    @sendReadReceipt="sendReadReceipt"
                    @showReadReceipts="showReadReceipts"
            ></wiki-members-list-table>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {DjangoUrls} from 'DjangoUrls';
    import WikiMembersListTable from '../wiki/wiki_settings_page/WikiMembersListTable.vue';
    import WikiSettingsWrapperMixin from "../wiki/WikiSettingsWrapperMixin";
    import CommunityPageAlternativeHeader from "./CommunityPageAlternativeHeader.vue";
    import {getMainWikiPageName} from "../wiki_page/WikiChildrenPages";
    import {COMMUNITY_WIKI_ROUTER_PREFIX} from "./config";
    import WikiMembershipResource from "../wiki/wiki_settings_page/wiki_membership_resource";

    export default {
        name: "CommunityPageManageWikiAccess",
        components: {CommunityPageAlternativeHeader, WikiMembersListTable},
        mixins: [WikiSettingsWrapperMixin],
        data() {
            return {
                members: [],
                sections: [],
                owner: undefined,
                openedMember: undefined,
                wikiId: undefined,
            };
        },
        computed: {
            backRouteName() {
                return this.$route.query['from'] === 'settings' ? "community-settings" : getMainWikiPageName(COMMUNITY_WIKI_ROUTER_PREFIX);
            },
            wikiHref() {
                return DjangoUrls['users_wiki'](this.wikiId);
            },
            viewerIsAdmin() {
                return this.$store.getters['communityPage/viewerIsAdmin'];
            },
        },
        methods: {
            _getMembersAndTree() {
                WikiMembershipResource().get_members(this.wikiId).then(resp => {
                    this.members = resp.data;
                });

                WikiMembershipResource().get_wiki_tree(this.wikiId).then(resp => {
                    this.sections = resp.data.sections;
                });
            },
            inviteUsers() {
                const selectFactory = Vue.getAngularModule('selectFactory');
                const WikiResource = Vue.getAngularModule('WikiResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                selectFactory
                    .endpoint_selector(_.partial(WikiResource.get_users_to_invite, this.wikiId), true, {
                        header: 'Invite Users',
                        info: 'Select or de-select multi me Users from this list to invite to join the Wiki',
                        button_yes: 'Add',
                        button_no: 'Cancel',
                        empty: 'You do not have any users to invite'
                    })
                    .then(res => {
                        let new_checked_ids = res[0];
                        $rootScope.show_dimmer();
                        return WikiResource.invite_users(this.wikiId, {
                            id_list: new_checked_ids
                        });
                    }, () => new Promise(()=>{}))
                    .then(resp => {
                        for (let new_member of resp.data) {
                            this.members.push(new_member);
                        }
                        simplePopupFactory.show_popup('Invite successfully sent.',
                            "Invite successfully sent. Please now define this users view or edit permissions in the \'Edit Access\' column below", 'OK');
                    }, err => Vue.notifications.error(err || 'Error'))
                    .finally($rootScope.hide_dimmer);
            }
        },
        beforeMount() {
            const $preloaded = Vue.getAngularModule('$preloaded');
            this.owner = $preloaded.community;
            this.wikiId = $preloaded.wiki_id;
            this._getMembersAndTree();
        }
    };
</script>

<style scoped lang="scss">
    .wiki-access-table {
        font-size: 14px;
    }
</style>