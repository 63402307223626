/**
 * Created by orion on 09.05.17.
 */

import angular from 'angular';

// mainFilters
import main_filters from '../shared/filters/filters_module';
import search_input from '../shared/inputs/search_input/search_input_module';
import preload from '../preload/preload';
//shared modules

import simplePopups from '../shared/simple_popups/simple_popup_module';
import notifications from '../shared/notifications/notifications_module';
import infiniteScroll from '../shared/infiniteScroll_directive';
import selectFactory from '../shared/select_factory/selectFactory_module';
import backUrlGenerator from '../shared/utils/back_url_generator';


//app modules
import add_media from '../add_media/add_media_module';
import view_media from '../view_media/view_media_module';
import CurrentUser from '../shared/CurrentUser';
import communities from '../communities/communities_module';
import activity from '../activity/activity_module';

//pages

import CirclesListAsAdminPageController from './CirclesListAsAdminPageController';

//resources
import UserProfileResource from './userprofile_resource';
import UserProfileActivityResource from './userprofile_activity_resource';
import UserProfileSettingsResource from './userprofile_settings_resource';
import UserProfileGroupsResource from './userprofile_groups_resource';
import regValidationResource from '../shared/registration_resource';
import UserProfileCircleResource from './userprofile_circle_resource';
import BuddyResource from './user_manage/buiddy_resource';
import CirclesListResource from './circles_list_resource';
import UserProfileDeleteAccountResource from "./delete_account_resource";
//directives
import deleteAccountNotification from "./delete_account_schedule_notification/directive";

//factories
import LikeStoryResourceFactory from '../shared/story/resource_factory';
import DeleteAccountController from "./close_account/DeleteAccountController";
import manageCircleMembershipResourceFactory from "./user_friends_list/manage_circle_invites_resource_factory";
import ManageExistingMembersResource from "./user_friends_list/manage_existing_members_resource";
import circleEmailInvitationController from "./circle_email_invitation/circleEmailInvitationController";
import ManageAdminStateResource from "./user_friends_list/manage_admin_state_resource";
import FriendRequestResource from "./friend_request_resource";
import likesResourceFactory from "shared/common/likesResourceFactory";



angular.module('mmApp.userprofile_page',
    ['ui.bootstrap',
        preload, activity, simplePopups, communities, backUrlGenerator, CurrentUser, notifications, add_media, view_media, main_filters, search_input, infiniteScroll, selectFactory])
    .factory('UserStoryResource', LikeStoryResourceFactory('user_story'))
    .factory('UserStoryLikesResource', likesResourceFactory('user_story_likes'))
    .factory('UserProfileResource', UserProfileResource)
    .factory('UserProfileSettingsResource', UserProfileSettingsResource)
    .factory('UserProfileActivityResource', UserProfileActivityResource)
    .factory('UserProfileGroupsResource', UserProfileGroupsResource)
    .factory('UserProfileCircleResource', UserProfileCircleResource)
    .factory('regValidationResource', regValidationResource)
    .factory('BuddyResource', BuddyResource)
    .factory('CirclesListResource', CirclesListResource)
    .factory('UserProfileDeleteAccountResource', UserProfileDeleteAccountResource)
    .factory('FriendRequestResource', FriendRequestResource)

    .factory('ManageCircleInvitesResource', manageCircleMembershipResourceFactory('manage_circle_invites'))
    .factory('ManageFriendRequestsResource', manageCircleMembershipResourceFactory('manage_friend_requests'))
    .factory('ManageExistingMembersResource', ManageExistingMembersResource)
    .factory('ManageAdminStateResource', ManageAdminStateResource)

    .controller('circleEmailInvitationController', circleEmailInvitationController)
    .controller('CirclesListAsAdminPageController', CirclesListAsAdminPageController)
    .controller('DeleteAccountController', DeleteAccountController)
    .directive('deleteAccountNotification', deleteAccountNotification)

;


export default 'mmApp.userprofile_page';