/**
 * Created by orion on 04.06.17.
 */
let angular = require('angular');
let _ = require('lodash');
import './style.scss'
function storyItemWrapper() {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: {
            'editMode': '=',
            onDelete:'&?',
            onMoveUp:'&?',
            onMoveDown:'&?',
            onEdit:'&?'
        },
        template: require('./template.html'),
        link: function ($scope, element, attrs, controller) {
            $scope.move_up = function () {
                ($scope.onMoveUp||_.noop)();
            }
            $scope.move_down = function () {
                ($scope.onMoveDown||_.noop)();
            }
            $scope.del = function () {
                ($scope.onDelete||_.noop)();
            }
            $scope.edit = function () {
                ($scope.onEdit||_.noop)();
            }
        }
    };
}

angular.module('mmApp.shared.storyItemWrapper', [])
    .directive('storyItemWrapper', storyItemWrapper);

export default 'mmApp.shared.storyItemWrapper'