<template>
    <span v-if="sectionSnapshotAfter">
        <span>added a new <span
                v-if="sectionSnapshotAfter.is_submenu">submenu</span> section </span>
        <strong>
            #{{ sectionSnapshotAfter.id }}
            <wiki-log-possible-link
                :target="wikiLog.target_wiki_section"
                :label="sectionSnapshotAfter.caption"
            ></wiki-log-possible-link>
        </strong>
        <span v-if="sectionSnapshotAfter.parent_section_name">
            in <strong>#{{ sectionSnapshotAfter.parent_section_id }}
            <wiki-log-possible-link
                    :target="wikiLog.target_wiki_slide"
                    :label="sectionSnapshotAfter.parent_section_name"
            ></wiki-log-possible-link>
        </strong>
        </span>
        <wiki-log-image-preview v-if="wikiLog.image_preview" :wiki-log="wikiLog"></wiki-log-image-preview>
    </span>
</template>

<script>
    import SnapshotLogMixin from "./common/SnapshotLogMixin";
    import WikiLogImagePreview from "./common/WikiLogImagePreview";

    export default {
        name: "WikiSectionCreated",
        components: {WikiLogImagePreview},
        mixins:[SnapshotLogMixin],
    };
</script>