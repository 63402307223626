<template>
    <div class="calendar-page">
        <router-view v-if="initialized"
                :membershipState="membershipState"
        ></router-view>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default {
        components: {},
        props: {
            userId: [String, Number],
        },
        data() {
            return {
                initialized: false,
                membershipState: {}
            };
        },
        computed: {},
        name: "CalendarPage",
        methods: {
            loadMembershipState() {
                const UserEventsResource = Vue.getAngularModule('UserEventsResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                UserEventsResource.get_calendar_membership_state(this.userId)
                    .then(resp => {
                        this.membershipState.profile = resp.data.profile;
                        this.membershipState.buddyMode = resp.data.buddy_mode;
                        this.membershipState.calendarOwnerIsOwnEventsAdmin = resp.data.is_admin_of_own_events;
                        this.membershipState.isGroupsAdmin = resp.data.is_admin_of_any_groups;
                        this.membershipState.isCommunitiesAdmin = resp.data.is_admin_of_any_communities;
                        this.$nextTick(() => {
                            this.initialized = true;
                        });
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                        $rootScope.hide_dimmer();
                    });
            }
        },
        mounted() {
            this.loadMembershipState();
        },
        beforeDestroy() {
        }
    };
</script>

<style scoped lang="scss">
    .calendar-page {
    }

</style>