<template>
    <modal v-model="opened" :transition='0' :append-to-body="true" :backdrop="false">
        <div slot="header">
            <a id="close_popup" @click="close_popup"></a>
            <h3 class="modal-title">{{header}}</h3>
        </div>
        <div class="row" style="margin-bottom: 15px">
            <div class="col-sm-12">
                <i>
                    {{text}}
                    (<strong>{{formattedDate}}</strong>).
                </i>
            </div>
        </div>
        <div class="select-menu-container center">
            <div class="select-menu-item">
                <div class="panel panel-primary">
                    <div class="panel-heading shortcuts" @click="select('whole')">
                        <h3 class="panel-title">Every<br>occurrence</h3>
                        <i class="fa fa-repeat select-menu-item__main-icon" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="select-menu-item">
                <div class="panel panel-primary">
                    <div class="panel-heading shortcuts" @click="select('exact')">
                        <h3 class="panel-title">Only<br>{{ formattedDate }}</h3>
                        <i class="fa fa-calendar select-menu-item__main-icon" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="select-menu-item" v-if="anotherDateOptionEnabled">
                <div class="panel panel-primary">
                    <div class="panel-heading shortcuts" @click="findAnotherOccurrence">
                        <h3 class="panel-title">Another<br>occurrence</h3>
                        <i class="fa fa-search select-menu-item__main-icon" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer"></div>
        <event-find-occurrence-popup :allow-multiple="true" ref="find-occurrence-popup"></event-find-occurrence-popup>
    </modal>
</template>

<script>

    import PopupMixin from "../../../../vue/mixins/PopupMixin";
    import EventFindOccurrencePopup from './EventFindOccurrencePopup.vue';

    export default {

        name: "EventDetailPagePeriodSelectorPopupWithAnotherDateOption",
        mixins: [PopupMixin],
        components: {EventFindOccurrencePopup},
        data() {
            return {
                opened: false,
                resolve: undefined,
                reject: undefined,
                date: undefined,
                eventId: undefined,
                calendarOwnerId: undefined,
                anotherDateOptionEnabled: true,

                header: '',
                text: ''
            };
        },
        computed: {
            formattedDate() {
                if (!this.date) {
                    return;
                }
                return this.date.format('Do MMM');
            },
        },
        methods: {
            show(date,
                 header = 'Join Event',
                 text = 'This is a repeated Event. You can participate in every occurrence of this Event or only at this date',
                 eventId = undefined,
                 calendarOwnerId = undefined,
                 anotherDateOptionEnabled = true) {
                this.date = date;
                this.header = header;
                this.eventId = eventId;
                this.calendarOwnerId = calendarOwnerId;
                this.anotherDateOptionEnabled = anotherDateOptionEnabled;
                this.text = text;
                const self = this;
                this.opened = true;
                return new Promise((resolve, reject) => {
                    self.resolve = resolve;
                    self.reject = reject;
                });
            },
            findAnotherOccurrence() {
                this.opened = false;
                this.$refs['find-occurrence-popup'].show(this.calendarOwnerId, this.eventId, this.date)
                    .then(res => {
                        this.resolve({result: 'another', dates: res});
                    }, c => {
                        this.opened = true;
                    });
            },
            select(what) {
                this.resolve({result: what});
                this.info = '';
                this.close();
            },
            close_popup() {
                this.info = '';
                this.reject('close');
                this.close();
            }
        }

    };
</script>

<style scoped>

</style>