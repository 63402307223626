/**
 * Created by orion on 06.06.16.
 */
import angular from 'angular';
import urls from 'DjangoUrls';
import picturePasswordFactory from "shared/picture_password/picture_password_factory";

let {DjangoUrls} = urls;
passwordResetController.$inject = ['$scope', '$rootScope', '$timeout', '$preloaded',
    'notifications', 'simplePopupFactory', 'AuthResource', 'regValidationResource'];
const PASSWORD_STRENGTH_LABELS = ['Terrible!', 'Bad', 'OK', 'Good', 'Perfect!'];
const PASSWORD_STRENGTH_CLASSES = ['terrible', 'bad', 'ok', 'good', 'perfect'];

function passwordResetController($scope, $rootScope, $timeout, $preloaded,
                                 notifications, simplePopupFactory, AuthResource, regValidationResource) {
    $scope.pre_user = $preloaded['user'];
    $scope.value = $preloaded['value'];
    $scope.register_data = {
        use_picture_password: false,
        password: {value: '', validated: false, has_error: false, has_success: false, message: ''},
        passwordconfirmation: {value: '', validated: false, has_error: false, has_success: false, message: ''}
    };

    function set_validation_values(param, available, message) {
        $scope.register_data[param].message = message;
        $scope.register_data[param].has_success = available;
        $scope.register_data[param].has_error = !available;
        $scope.register_data[param].validated = true;
    }

    function reset_validation_values(param) {
        $scope.register_data[param].message = '';
        $scope.register_data[param].has_success = false;
        $scope.register_data[param].has_error = false;
        $scope.register_data[param].validated = false;
    }

    $scope.reset_passwords = function () {
        reset_validation_values('password');
        reset_validation_values('passwordconfirmation');
        $scope.register_data.passwordconfirmation.value = '';
        $scope.register_data.password.value = '';
        $scope.register_data.use_picture_password = false;
    };

    // <validate password section>
    $scope.validate_password = function () {
        if ($scope.register_data.password.value.length>7) {
            set_validation_values('password', true, '');
        } else {
            set_validation_values('password', false, 'At least 8 characters');
        }
    };

    let validate_password_confirmation_promise;

    const _validate_password_confirmation = function (passwordconfirmation) {
        if ($scope.register_data.password.value === passwordconfirmation) {
            set_validation_values('passwordconfirmation', true, '');
            set_validation_values('password', true, '');

        } else {
            set_validation_values('password', false, '');
            set_validation_values('passwordconfirmation', false, 'Password doesn\'t match confirmation');
        }
    };

    $scope.validate_password_confirmation = function () {

        if (angular.isDefined(validate_password_confirmation_promise)) {
            $timeout.cancel(validate_password_confirmation_promise);
        }
        validate_password_confirmation_promise = $timeout(_validate_password_confirmation, 800, true, $scope.register_data.passwordconfirmation.value);
    };

    // </validate password section>
    $scope.pass_reset_data_send = function () {
        $rootScope.show_dimmer();
        AuthResource.password_reset({
            password: $scope.register_data.password.value,
            value: $scope.value
        })
            .then(function successCallback(response) {
                $rootScope.hide_dimmer();
                const success_popup = simplePopupFactory.show_popup('Password changed', 'Your password successfully changed. Now you will be redirected t sign in page', 'OK', null);
                success_popup.then(function () {
                    document.location.href = DjangoUrls["auth_login"]();
                }, function () {
                    document.location.href = DjangoUrls["auth_login"]();
                });

            }, function errorCallback(response) {
                $rootScope.hide_dimmer();
                notifications.error(response.data.message || 'Something went wrong. try again');
            });
    };

    $scope.showPasswordStrength = function () {
        return !$scope.register_data.use_picture_password && $scope.register_data.password.value && ($scope.register_data.password.value.length > 3);
    };

    $scope.checkPasswordStrength = () => {
        $scope.passwordStrengthLabel = 'Checking...';
        $scope.passwordStrengthClass = 'checking';
        regValidationResource.password($scope.register_data.password.value)
            .then(resp => {
                $scope.passwordStrengthClass = PASSWORD_STRENGTH_CLASSES[resp.data];
                $scope.passwordStrengthLabel = PASSWORD_STRENGTH_LABELS[resp.data];
            });
    };

    $scope.password_changed = function () {
        $scope.register_data.use_picture_password = false;
        if ($scope.register_data.password.value) {
            const value = $scope.register_data.password.value;
            if (value.length < 3) {
                $scope.passwordStrengthLabel = 'Checking...';
                return;
            }
            if ($scope.checkingUsernameTimeoutHandle !== undefined) {
                clearTimeout($scope.checkingUsernameTimeoutHandle);
            }
            $scope.checkingUsernameTimeoutHandle = setTimeout($scope.checkPasswordStrength, 600);
        }
    };


    $scope.show_symbol_password_form = function () {
        if ($scope.register_data.passwordconfirmation.value) {
            $scope.reset_passwords();
        }

        picturePasswordFactory.input_password({repeat_forbidden: false})
            .then(function successCallback(pass) {
                if ($scope.register_data.password.value) {
                    $scope.register_data.passwordconfirmation.value = pass;
                    if ($scope.register_data.passwordconfirmation.value === $scope.register_data.password.value) {
                        set_validation_values('passwordconfirmation', true, '');
                        $scope.register_data.use_picture_password = true;
                    } else {
                        set_validation_values('password', false, '');
                        set_validation_values('passwordconfirmation', false, 'Password doesn\'t match confirmation');
                    }

                } else {
                    $scope.register_data.password.value = pass;
                    set_validation_values('password', true, '');
                    $scope.show_symbol_password_form();
                }

            });
    };

}

export default passwordResetController;