<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 aria-label="Inactivity"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">Inactivity</h3>
        </div>
        <div class="row">
            <div class="col-sm-12">
                Due to lack of activity the system will log you out in five minutes. If you want to stay logged into
                Multi Me please click <strong>continue</strong>
            </div>
        </div>
        <div slot="footer">
            <div class="row">
                <div class="col-sm-12">
                    <div class="inline-block" data-role="trigger">
                        <button @click="save(1)"
                                class="btn btn-mm btn-green">Continue ({{ countDownTimeFormatted }})
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import moment from 'moment';
    import BasicModal from '../../../vue/common_components/BasicModal.vue';
    import BasicModalCloseButton from '../../../vue/common_components/BasicModalCloseButton.vue';
    import PopupPromiseMixin from '../../../vue/mixins/PopupPromiseMixin';

    export default {
        name: 'CountDownPopup',
        mixins: [PopupPromiseMixin],
        components: {
            BasicModalCloseButton,
            BasicModal
        },
        data () {
            return {
                now: undefined,
                expireTime: undefined,
            };
        },
        computed: {
            countDownTimeSeconds () {
                if (this.expireTime && this.now) {
                    return this.expireTime.diff(this.now, 'seconds');
                } else {
                    return 5*60; // prevent auto close before initialized
                }
            },
            countDownTimeFormatted () {
                const seconds = this.countDownTimeSeconds % 60;
                return `${Math.floor(this.countDownTimeSeconds / 60)}:${(seconds < 10) ? '0' : ''}${seconds}`;
            },
        },
        mounted () {
            this.$_updateNowInterval = setInterval(this.updateNow, 1000);
        },
        beforeDestroy () {
            clearInterval(this.$_updateNowInterval);
        },
        watch: {
            countDownTimeSeconds (n) {
                if (n <= 0) {
                    this.close();
                }
            }
        },
        methods: {
            setInitial (secondsBeforeSessionExpire) {
                this.now = moment();
                this.expireTime = moment().add(secondsBeforeSessionExpire, 'seconds');
            },
            updateNow () {
                this.now = moment();
            },
        }
    };
</script>

<style scoped>

</style>
