var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container", style: _vm.containerStyle },
    [
      _c("line-drawer", { ref: "lineDrawer" }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "hotkey",
              rawName: "v-hotkey",
              value: _vm.keymap,
              expression: "keymap",
            },
          ],
          staticClass: "row",
        },
        [
          _c(
            "div",
            { staticClass: "col-xs-4", style: _vm.slotStyle },
            [
              _vm.getSectionMode(6) === "filled"
                ? _c("wiki-section", {
                    ref: "section6",
                    attrs: {
                      height: _vm.sectionHeight,
                      section: _vm.sections[6],
                      draggable: _vm.allowDrag && _vm.editMode,
                      editMode: _vm.editMode,
                      pentagramMode: _vm.pentagramMode,
                      "is-active": _vm.activeSectionNumber === 6,
                      "show-counters": _vm.showCounters,
                      width: _vm.sectionWidth,
                      side: "left",
                      row: "top",
                    },
                    on: {
                      onDelete: function ($event) {
                        return _vm.deleteSection(6)
                      },
                      onDragStart: function ($event) {
                        return _vm.onDragStart(6)
                      },
                      onDrop: function ($event) {
                        return _vm.onDrop(6)
                      },
                      onEdit: function ($event) {
                        return _vm.onSectionEdit(6)
                      },
                      onOpen: function ($event) {
                        return _vm.onSectionOpen(_vm.sections[6])
                      },
                      activateSection: function ($event) {
                        return _vm.activateSection(6)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.getSectionMode(6) === "empty"
                ? _c("empty-section", {
                    attrs: {
                      height: _vm.sectionHeight,
                      width: _vm.sectionWidth,
                      side: "left",
                      row: "top",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.allowCreate && _vm.getSectionMode(6) === "create"
                ? _c("create-section", {
                    ref: "section6",
                    attrs: {
                      height: _vm.sectionHeight,
                      width: _vm.sectionWidth,
                      side: "left",
                      row: "top",
                    },
                    on: {
                      onCreate: function ($event) {
                        return _vm.onCreate(6)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-4", style: _vm.slotStyle }, [
            _vm.mainLabelOnTop
              ? _c(
                  "div",
                  { staticClass: "aboutMeTop", style: _vm.aboutMeTopStyle },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "aboutMeTop__caption",
                        style: _vm.captionStyle,
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.mainSectionText) +
                            "\n                    "
                        ),
                        _vm.showCounters &&
                        _vm.sections[0] &&
                        _vm.sections[0].visible_content_count
                          ? _c("span", { staticClass: "about_me__counter" }, [
                              _vm._v(
                                "\n                        (" +
                                  _vm._s(
                                    _vm.sections[0].visible_content_count
                                  ) +
                                  ")\n                    "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xs-4", style: _vm.slotStyle },
            [
              _vm.getSectionMode(1) === "filled"
                ? _c("wiki-section", {
                    ref: "section1",
                    attrs: {
                      "is-active": _vm.activeSectionNumber === 1,
                      height: _vm.sectionHeight,
                      section: _vm.sections[1],
                      pentagramMode: _vm.pentagramMode,
                      draggable: _vm.allowDrag && _vm.editMode,
                      editMode: _vm.editMode,
                      width: _vm.sectionWidth,
                      "show-counters": _vm.showCounters,
                      side: "right",
                      row: "top",
                    },
                    on: {
                      onDelete: function ($event) {
                        return _vm.deleteSection(1)
                      },
                      onDragStart: function ($event) {
                        return _vm.onDragStart(1)
                      },
                      onDrop: function ($event) {
                        return _vm.onDrop(1)
                      },
                      onEdit: function ($event) {
                        return _vm.onSectionEdit(1)
                      },
                      onOpen: function ($event) {
                        return _vm.onSectionOpen(_vm.sections[1])
                      },
                      activateSection: function ($event) {
                        return _vm.activateSection(1)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.getSectionMode(1) === "empty"
                ? _c("empty-section", {
                    attrs: {
                      height: _vm.sectionHeight,
                      width: _vm.sectionWidth,
                      side: "right",
                      row: "top",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.allowCreate && _vm.getSectionMode(1) === "create"
                ? _c("create-section", {
                    ref: "section1",
                    attrs: {
                      height: _vm.sectionHeight,
                      width: _vm.sectionWidth,
                      side: "right",
                      row: "top",
                    },
                    on: {
                      onCreate: function ($event) {
                        return _vm.onCreate(1)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-4", style: _vm.slotStyle },
          [
            _vm.getSectionMode(5) === "filled"
              ? _c("wiki-section", {
                  ref: "section5",
                  attrs: {
                    "is-active": _vm.activeSectionNumber === 5,
                    height: _vm.sectionHeight,
                    section: _vm.sections[5],
                    pentagramMode: _vm.pentagramMode,
                    draggable: _vm.allowDrag && _vm.editMode,
                    editMode: _vm.editMode,
                    width: _vm.sectionWidth,
                    "show-counters": _vm.showCounters,
                    side: "left",
                    row: "center",
                  },
                  on: {
                    onDelete: function ($event) {
                      return _vm.deleteSection(5)
                    },
                    onDragStart: function ($event) {
                      return _vm.onDragStart(5)
                    },
                    onDrop: function ($event) {
                      return _vm.onDrop(5)
                    },
                    onEdit: function ($event) {
                      return _vm.onSectionEdit(5)
                    },
                    onOpen: function ($event) {
                      return _vm.onSectionOpen(_vm.sections[5])
                    },
                    activateSection: function ($event) {
                      return _vm.activateSection(5)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.getSectionMode(5) === "empty"
              ? _c("empty-section", {
                  attrs: {
                    height: _vm.sectionHeight,
                    width: _vm.sectionWidth,
                    side: "left",
                    row: "center",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allowCreate && _vm.getSectionMode(5) === "create"
              ? _c("create-section", {
                  ref: "section5",
                  attrs: {
                    height: _vm.sectionHeight,
                    width: _vm.sectionWidth,
                    side: "left",
                    row: "center",
                  },
                  on: {
                    onCreate: function ($event) {
                      return _vm.onCreate(5)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-xs-4", style: _vm.slotStyle },
          [
            _c("wiki-main-section", {
              ref: "section0",
              attrs: {
                isTopSection: _vm.isTopSection,
                "is-active": _vm.activeSectionNumber === 0,
                section: _vm.sections[0],
                editMode: _vm.editMode,
                allowOpen: !!_vm.sections[0],
                height: _vm.sectionHeight,
                width: _vm.sectionWidth,
                containerWidth: _vm.containerWidth,
                text: _vm.mainSectionText,
                avatar: _vm.mainSectionAvatar,
                mediaContentSamples: _vm.mainSectionContentSamples,
                "show-counters": _vm.showCounters,
              },
              on: {
                onEdit: function ($event) {
                  return _vm.onSectionEdit(0)
                },
                onDrop: function ($event) {
                  return _vm.onDrop(0)
                },
                onOpen: function ($event) {
                  return _vm.onSectionOpen(_vm.sections[0])
                },
                activateSection: function ($event) {
                  return _vm.activateSection(0)
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-xs-4", style: _vm.slotStyle },
          [
            _vm.getSectionMode(2) === "filled"
              ? _c("wiki-section", {
                  ref: "section2",
                  attrs: {
                    "is-active": _vm.activeSectionNumber === 2,
                    height: _vm.sectionHeight,
                    section: _vm.sections[2],
                    draggable: _vm.allowDrag && _vm.editMode,
                    editMode: _vm.editMode,
                    pentagramMode: _vm.pentagramMode,
                    "show-counters": _vm.showCounters,
                    width: _vm.sectionWidth,
                    side: "right",
                    row: "center",
                  },
                  on: {
                    onDelete: function ($event) {
                      return _vm.deleteSection(2)
                    },
                    onDragStart: function ($event) {
                      return _vm.onDragStart(2)
                    },
                    onDrop: function ($event) {
                      return _vm.onDrop(2)
                    },
                    onEdit: function ($event) {
                      return _vm.onSectionEdit(2)
                    },
                    onOpen: function ($event) {
                      return _vm.onSectionOpen(_vm.sections[2])
                    },
                    activateSection: function ($event) {
                      return _vm.activateSection(2)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.getSectionMode(2) === "empty"
              ? _c("empty-section", {
                  attrs: {
                    height: _vm.sectionHeight,
                    width: _vm.sectionWidth,
                    side: "right",
                    row: "center",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allowCreate && _vm.getSectionMode(2) === "create"
              ? _c("create-section", {
                  ref: "section2",
                  attrs: {
                    height: _vm.sectionHeight,
                    width: _vm.sectionWidth,
                    side: "right",
                    row: "center",
                  },
                  on: {
                    onCreate: function ($event) {
                      return _vm.onCreate(2)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-4", style: _vm.slotStyle },
          [
            _vm.getSectionMode(4) === "filled"
              ? _c("wiki-section", {
                  ref: "section4",
                  attrs: {
                    "is-active": _vm.activeSectionNumber === 4,
                    height: _vm.sectionHeight,
                    section: _vm.sections[4],
                    draggable: _vm.allowDrag && _vm.editMode,
                    editMode: _vm.editMode,
                    pentagramMode: _vm.pentagramMode,
                    "show-counters": _vm.showCounters,
                    width: _vm.sectionWidth,
                    side: "left",
                    row: "bottom",
                  },
                  on: {
                    onDelete: function ($event) {
                      return _vm.deleteSection(4)
                    },
                    onDragStart: function ($event) {
                      return _vm.onDragStart(4)
                    },
                    onDrop: function ($event) {
                      return _vm.onDrop(4)
                    },
                    onEdit: function ($event) {
                      return _vm.onSectionEdit(4)
                    },
                    onOpen: function ($event) {
                      return _vm.onSectionOpen(_vm.sections[4])
                    },
                    activateSection: function ($event) {
                      return _vm.activateSection(4)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.getSectionMode(4) === "empty"
              ? _c("empty-section", {
                  attrs: {
                    height: _vm.sectionHeight,
                    width: _vm.sectionWidth,
                    side: "left",
                    row: "bottom",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allowCreate && _vm.getSectionMode(4) === "create"
              ? _c("create-section", {
                  ref: "section4",
                  attrs: {
                    height: _vm.sectionHeight,
                    width: _vm.sectionWidth,
                    side: "left",
                    row: "bottom",
                  },
                  on: {
                    onCreate: function ($event) {
                      return _vm.onCreate(4)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-xs-4", style: _vm.slotStyle },
          [
            _vm.mainLabelOnBottom
              ? _c(
                  "div",
                  {
                    staticClass: "aboutMeBottom",
                    style: _vm.aboutMeBottomStyle,
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "aboutMeBottom__caption",
                        style: _vm.bottomCaptionStyle,
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.mainSectionText) +
                            "\n                    "
                        ),
                        _vm.showCounters &&
                        _vm.sections[0] &&
                        _vm.sections[0].visible_content_count
                          ? _c("span", { staticClass: "about_me__counter" }, [
                              _vm._v(
                                "\n                        (" +
                                  _vm._s(
                                    _vm.sections[0].visible_content_count
                                  ) +
                                  ")\n                    "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.getSectionMode(7) === "filled"
              ? _c("wiki-section", {
                  ref: "section7",
                  attrs: {
                    "is-active": _vm.activeSectionNumber === 7,
                    height: _vm.sectionHeight,
                    section: _vm.sections[7],
                    draggable: _vm.allowDrag && _vm.editMode,
                    editMode: _vm.editMode,
                    pentagramMode: _vm.pentagramMode,
                    "show-counters": _vm.showCounters,
                    width: _vm.sectionWidth,
                    side: "center",
                    row: "bottom",
                  },
                  on: {
                    onDelete: function ($event) {
                      return _vm.deleteSection(7)
                    },
                    onDragStart: function ($event) {
                      return _vm.onDragStart(7)
                    },
                    onDrop: function ($event) {
                      return _vm.onDrop(7)
                    },
                    onEdit: function ($event) {
                      return _vm.onSectionEdit(7)
                    },
                    onOpen: function ($event) {
                      return _vm.onSectionOpen(_vm.sections[7])
                    },
                    activateSection: function ($event) {
                      return _vm.activateSection(7)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allowCreate && _vm.getSectionMode(7) === "create"
              ? _c("create-section", {
                  ref: "section7",
                  attrs: {
                    height: _vm.sectionHeight,
                    width: _vm.sectionWidth,
                    side: "center",
                    row: "bottom",
                  },
                  on: {
                    onCreate: function ($event) {
                      return _vm.onCreate(7)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-xs-4", style: _vm.slotStyle },
          [
            _vm.getSectionMode(3) === "filled"
              ? _c("wiki-section", {
                  ref: "section3",
                  attrs: {
                    "is-active": _vm.activeSectionNumber === 3,
                    height: _vm.sectionHeight,
                    section: _vm.sections[3],
                    draggable: _vm.allowDrag && _vm.editMode,
                    editMode: _vm.editMode,
                    pentagramMode: _vm.pentagramMode,
                    "show-counters": _vm.showCounters,
                    width: _vm.sectionWidth,
                    side: "right",
                    row: "bottom",
                  },
                  on: {
                    onDelete: function ($event) {
                      return _vm.deleteSection(3)
                    },
                    onDragStart: function ($event) {
                      return _vm.onDragStart(3)
                    },
                    onDrop: function ($event) {
                      return _vm.onDrop(3)
                    },
                    onEdit: function ($event) {
                      return _vm.onSectionEdit(3)
                    },
                    onOpen: function ($event) {
                      return _vm.onSectionOpen(_vm.sections[3])
                    },
                    activateSection: function ($event) {
                      return _vm.activateSection(3)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.getSectionMode(3) === "empty"
              ? _c("empty-section", {
                  attrs: {
                    height: _vm.sectionHeight,
                    width: _vm.sectionWidth,
                    side: "right",
                    row: "bottom",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.allowCreate && _vm.getSectionMode(3) === "create"
              ? _c("create-section", {
                  ref: "section3",
                  attrs: {
                    height: _vm.sectionHeight,
                    width: _vm.sectionWidth,
                    side: "right",
                    row: "bottom",
                  },
                  on: {
                    onCreate: function ($event) {
                      return _vm.onCreate(3)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }