var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { transition: 0, backdrop: false },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          attrs: { id: "close_popup" },
          on: { click: _vm.closePopup },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [
          _vm._v("Start a new edit session on this Wiki"),
        ]),
      ]),
      _vm._v(" "),
      _c("h4", [
        _vm._v(
          "\n        If somebody is supporting you to edit this Wiki, please confirm who is helping\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "select-menu-container center" }, [
        _c(
          "div",
          {
            staticClass: "select-menu-item",
            staticStyle: { "min-width": "200px" },
          },
          [
            _c("div", { staticClass: "panel panel-primary" }, [
              _c(
                "div",
                {
                  staticClass: "panel-heading shortcuts",
                  on: {
                    click: function ($event) {
                      return _vm.select("me")
                    },
                  },
                },
                [
                  _c("h3", { staticClass: "panel-title" }, [
                    _vm._v("It’s just me "),
                    _c("br"),
                    _vm._v(" editing it on my own"),
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "margin-10-top img-circle",
                    attrs: {
                      src: _vm.currentUserAvatar,
                      alt: "My Avatar",
                      height: 100,
                    },
                  }),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "select-menu-item",
            staticStyle: { "min-width": "200px" },
          },
          [
            _c("div", { staticClass: "panel panel-primary" }, [
              _c(
                "div",
                {
                  staticClass: "panel-heading shortcuts",
                  on: {
                    click: function ($event) {
                      return _vm.select("other")
                    },
                  },
                },
                [
                  _c("h3", { staticClass: "panel-title" }, [
                    _vm._v("Someone is helping me "),
                    _c("br"),
                    _vm._v(" to edit my wiki"),
                  ]),
                  _vm._v(" "),
                  _c("i", {
                    staticClass:
                      "margin-10-top far fa-user-friends select-menu-item__main-icon",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-default",
            on: { click: _vm.closePopup },
          },
          [_vm._v("\n            Cancel\n        ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }