<template>
    <div class="">
        <community-page-alternative-header scale-by-icons-size :icons-count="1+viewerIsAdmin">
            <span slot="title">
                <span>
                    <span class="vertical-middle">{{ (story ? story.name : '')|cutName(40) }}</span>
                    <audio-toggle class="vertical-middle" :audio-caption="story.audio_caption"
                                  v-if="story&&story.audio_caption"></audio-toggle>
                </span>
                <span style="font-size: 14px; display: block; text-decoration: none; line-height: 18px; font-weight: normal; font-style: normal; position: relative; top: 5px;">
                    <span class="inline-block">{{ story ? story.created_at : '' }}</span>
                </span>
            </span>
            <vue-folder-button icon-class="fa-gears" @click.native="editStory" v-if="viewerIsAdmin"></vue-folder-button>
            <span v-if="!backUrl">
                <router-link :to="{name:'community-stories'}" style="text-decoration: none">
                    <vue-folder-button icon-class="fa-reply"></vue-folder-button>
                </router-link>
            </span>
            <a :href="backUrl" v-else style="text-decoration: none">
                <vue-folder-button icon-class="fa-reply"></vue-folder-button>
            </a>
        </community-page-alternative-header>
        <div class="alert alert-danger" v-if="notFound">
            Story not found
        </div>
        <div class="alert alert-info" v-if="story&&!story.published&&viewerIsAdmin">
            <div class="row">
                <div class="col-sm-12">
                    <strong>Attention.</strong>
                    This Story is <strong>not</strong> published, so only Community admins could see it. Press <strong>Publish</strong>
                    to make story visible for all users including unauthenticated.
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 text-right">
                    <button class="btn btn-mm btn-green" @click="publishStory">Publish</button>
                </div>
            </div>
        </div>
        <div class="alert alert-success" v-if="story&&story.published&&viewerIsAdmin">
            <div class="row">
                <div class="col-sm-12">
                    <strong>Attention.</strong>
                    This Story is currently published, so everyone including unauthenticated users have access to it.
                    Press <strong>Hide</strong>
                    to make story visible for Community admins only.
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 text-right">
                    <button class="btn btn-mm btn-green" @click="unpublishStory">Hide</button>
                </div>
            </div>
        </div>
        <div class="row" v-if="initialized">
            <div class="col-sm-12 text-right">
                <vue-switch-with-label
                        v-if="viewerIsAdmin"
                        :label="'Edit Mode'"
                        name="edit_mode"
                        v-model="editMode"
                ></vue-switch-with-label>
            </div>
        </div>
        <div class="row" v-if="initialized">
            <div class="col-sm-12">
                <story-list
                        :target-id="story.id"
                        :story-resource="storyResource"
                        :allow-add-content="viewerIsAdmin"
                        :allow-bulk-create="true"
                        :edit-mode="editMode"
                >
                    <div class="alert alert-info" slot="empty">There has been nothing added to this story yet</div>
                </story-list>
            </div>
        </div>
        <create-story-popup ref="create-popup"></create-story-popup>
    </div>
</template>

<script>
    import Vue from 'vue';
    import CommunityPageAlternativeHeader from "./CommunityPageAlternativeHeader.vue";
    import CreateStoryPopup from "./popups/CreateStoryPopup.vue";
    import StoryList from "shared/story/StoryList";

    export default {
        name: "CommunityPageStoriesStory",
        components: {StoryList, CommunityPageAlternativeHeader, CreateStoryPopup},
        data() {
            return {
                story: undefined,
                initialized: false,
                notFound: false,

                editMode: false,
            };
        },
        computed: {
            backUrl() {
                const hash = this.$route.hash;
                let from;
                if (hash.indexOf('from') !== -1) {
                    const from_url = hash.substr(hash.indexOf('from') + 5);
                    const backUrlGenerator = Vue.getAngularModule('backUrlGenerator');
                    from = backUrlGenerator.decode_function(from_url);
                }
                return from;
            },
            storyResource() {
                return Vue.getAngularModule('CommunityStoriesStoryResource');
            },
            community() {
                return this.$store.getters['communityPage/community'];
            },
            viewerIsAdmin() {
                return this.$store.getters['communityPage/viewerIsAdmin'];
            }
        },
        methods: {
            updateStory(data) {
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                const CommunityStoriesResource = Vue.getAngularModule('CommunityStoriesResource');
                CommunityStoriesResource.update_story(this.community.id, this.story.id, data)
                    .then(resp => {
                            if (this.$route.params.storySlug !== resp.data.slug) {
                                this.$router.replace({
                                    name: this.$route.name,
                                    params: {storySlug: resp.data.slug}
                                });
                            }
                            this.story = resp.data;
                            this.$store.commit('communityPage/setStory', resp.data);
                            Vue.notifications.success('Updated');
                        },
                        err => {
                            Vue.notifications.error(err || 'Error');
                        }
                    )
                    .finally($rootScope.hide_dimmer);
            },
            askForConfirmationAndDelete() {
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                simplePopupFactory.show_popup('Delete Story', `Are you sure you want to delete the Story <strong>${this.story.name}</strong> and all its contents?`, 'Delete', 'Cancel', 'btn-red')
                    .then(y => {
                        this.deleteStory();
                    }, () => {
                    });
            },
            deleteStory() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                const CommunityStoriesResource = Vue.getAngularModule('CommunityStoriesResource');
                CommunityStoriesResource.delete_story(this.community.id, this.story.id)
                    .then(resp => {
                            this.$router.push({name: 'community-stories'});
                        },
                        err => {
                            Vue.notifications.error(err || 'Error');
                            $rootScope.hide_dimmer();
                        }
                    );
            },
            editStory() {
                this.$refs['create-popup'].show(this.story)
                    .then(data => {
                            this.updateStory(data);
                        },
                        reason => {
                            if (reason === 'delete') {
                                this.askForConfirmationAndDelete();
                            }
                        });
            },
            publishStory() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                const CommunityStoriesResource = Vue.getAngularModule('CommunityStoriesResource');
                CommunityStoriesResource.publish_story(this.community.id, this.story.id)
                    .then(resp => {
                        Vue.notifications.success('Story published');
                        this.story.published = true;
                        this.$store.commit('communityPage/setStory', this.story);
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            unpublishStory() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                const CommunityStoriesResource = Vue.getAngularModule('CommunityStoriesResource');
                CommunityStoriesResource.unpublish_story(this.community.id, this.story.id)
                    .then(resp => {
                        Vue.notifications.success('Story hid');
                        this.story.published = false;
                        this.$store.commit('communityPage/setStory', this.story);
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            loadStory() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                const CommunityStoriesResource = Vue.getAngularModule('CommunityStoriesResource');
                CommunityStoriesResource.get_story(this.community.id, this.$route.params.storySlug)
                    .then(resp => {
                        this.story = resp.data;
                        this.$store.commit('communityPage/setStory', this.story);
                        this.initialized = true;
                    }, err => {
                        if (err.status === 404) {
                            this.notFound = true;
                        } else {
                            Vue.notifications.error(err || 'Error');
                        }
                        $rootScope.hide_dimmer();
                    });
            },
        },
        mounted() {
            this.loadStory();
        },
    };
</script>