/**
 * Created by orion on 12.05.17.
 */
import mapsAddLocationPopup from './mapsAddLocationPopup';

Maps.$inject = ['$rootScope', 'simplePopupFactory', '$q', '$log', 'MapResource'];

function Maps($rootScope, simplePopupFactory, $q, $log, MapResource) {
    return {
        find_location: function () {
            $log.debug('Maps-->find_location');
            let new_point = $q.defer();
            let modalResultPromise = simplePopupFactory.create_popup(mapsAddLocationPopup, require('./mapsAddLocationPopup.html'));
            modalResultPromise.then(function (point) {
                $log.debug('point', point);
                $rootScope.show_dimmer();
                MapResource.create_location_point(point).then(function (res) {
                    new_point.resolve(res.data);
                    $rootScope.hide_dimmer();
                });
            }, function (err) {
                new_point.reject(err);
                $rootScope.hide_dimmer();
            });
            return new_point.promise;
        },
        find_location_no_create: function () {
            $log.debug('Maps-->find_location_no_create');
            let new_point = $q.defer();
            let modalResultPromise = simplePopupFactory.create_popup(mapsAddLocationPopup, require('./mapsAddLocationPopup.html'));
            modalResultPromise.then(function (point) {
                $log.debug('point', point);
                new_point.resolve(point);
            }, function (err) {
                new_point.reject(err);
            });
            return new_point.promise;
        },
        get_data_from_address_components(addressComponents) {
            function findDataWithType(selectedType) {
                for (let component of addressComponents) {
                    if (component.types.includes(selectedType)) {
                        return component.long_name;
                    }
                }
                return '';
            }

            const postcode = findDataWithType('postal_code');
            const country = findDataWithType('administrative_area_level_1') || findDataWithType('country');
            const region = findDataWithType('administrative_area_level_2');
            const city = findDataWithType("postal_town");
            return {postcode, country, region, city};
        },
        get_gmaps_href: function (location) {
            if (!location) {
                return '';
            }
            return 'http://www.google.com/maps/place/' + location.lat + ',' + location.lng + '/@' + location.lat + ',' + location.lng + ',' + location.zoom + 'z';
        },
        get_static_map_href: function (location, size) {
            size = size === undefined ? "350x200" : size;
            var url = 'https://maps.googleapis.com/maps/api/staticmap?';
            var params = {
                center: location.lat + ',' + location.lng,
                zoom: location.zoom,
                size: size,
                format: 'JPEG',
                language: 'en',
                markers: 'color:red|' + location.lat + ',' + location.lng
            };
            if (GOOGLE_API_KEY) {  // defined in webpack.config
                params.key = GOOGLE_API_KEY;
            }
            var serializedParams = Object.keys(params).map(function (key) {
                return key + '=' + encodeURIComponent(params[key]);
            }).join('&');
            return url + serializedParams;
        }
    };

}

export default Maps;