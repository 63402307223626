/**
 * Created by orion on 16.03.17.
 */
import './admin_notification_style.scss';

adminNotification.$inject = ['$log', '$rootScope', '$sce'];

function adminNotification($log, $rootScope, $sce) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            notificationText: '@',
            color: '@',
            backgroundColor: '@',
        },
        template: `<div 
                    class="container-fluid hidden-print" 
                    id="admin_notification"
                    ng-bind-html="notif_txt"
                    ng-style="{color:clr, 'background-color':bgClr}"
                   ></div>`,
        link: function ($scope, element, attrs, controller) {
            $scope.notif_txt = $sce.trustAsHtml($scope.notificationText);
            $scope.clr = $scope.color || '#ffffff';
            $scope.bgClr = $scope.backgroundColor || '#0B3181';
        }


    };
}

export default adminNotification;
