/**
 * Created by orion on 04.05.17.
 */
import Vue from 'vue';
import RenderSticker from './RenderSticker.vue';

function renderSticker() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            'sticker': '=', // {string} or {Object} of Object then {url:string, ..} if string then 'folder__name'. see: ../stickers_list.js
            'responsive':'=',
            'allowDelete': '=?',
            'onDelete': '=?'
        },
        template: `<div></div>`,
        link: function ($scope, element, attrs, controller) {
            const vm = new Vue({
                el: element[0],
                components: {RenderSticker},
                name: 'renderStickerDirective',
                data() {
                    return {
                        sticker: $scope.sticker,
                        allowDelete: $scope.allowDelete,
                    };
                },
                template: `<render-sticker :responsive="responsive" :sticker="sticker" :allow-delete="allowDelete" @onDelete="onDelete" :style="directiveStyle"></render-sticker>`,
                computed: {
                    directiveStyle() {
                        if (attrs.style) {
                            return attrs.style;
                        }
                    },
                    responsive(){
                        return $scope.responsive!==undefined
                    }
                },
                methods: {
                    onDelete(stickerObject) {

                        if ($scope.onDelete !== undefined) {
                            $scope.onDelete(stickerObject);
                            $scope.$apply();
                            // $rootScope.$apply(() => {
                            //     console.log('onDeleteA',$scope.onDelete);
                            //     $scope.onDelete(stickerObject);
                            // });
                        }
                    }
                }
            });

            let stopWatch = $scope.$watch('sticker', (sticker) => {
                vm.sticker = sticker;
            });

            $scope.$on('$destroy', () => {
                stopWatch();
                if (vm) {
                    vm.$destroy();
                }
            });
        }
    };
}

export default renderSticker;