<template>
    <div class="center-block vue-inline-audio">
        <span class="align-helper"></span>
        <span class="vertical-middle vue-inline-audio__main-part inline-block">
            <span v-if="!audioSrc">This Audio is not ready yet...</span>
            <audio v-if="audioSrc" class="inline_audio_container hidden-print" controls :autoplay="autoplay"
                   preload="metadata" ref="audio"
                   controlsList="nodownload">
                <source :src="audioSrc" type="audio/mpeg" ref="audioSource">
                Your browser does not support the audio tag.
            </audio>
            <span class="visible-print"><i class="fa fa-volume-up fa-2"></i> {{ item.name }}</span>
        </span>
    </div>
</template>

<script>
    import captionPlayerBus from "../../shared/captionPlayer";

    function getAudioUrlFromAudioCaptionObject(audioCaption) {
        if (!audioCaption || !audioCaption[audioCaption.type]) {
            return undefined;
        }
        return audioCaption[audioCaption.type].audio_url;
    }

    export default {
        name: "VueInlineMediaAudio",
        data() {
            return {
                audioUrlReceivedFromEncoding: undefined
            };
        },
        props: {
            item: Object,
            autoplay: {
                type: Boolean,
                default: false
            },

        },
        computed: {
            audioSrc() {
                if (this.audioUrlReceivedFromEncoding) {
                    return this.audioUrlReceivedFromEncoding;
                }
                const audio_item = this.item.uploaded_audio || this.item.recorded_audio;
                if (!audio_item.audio_url) {
                    return;
                }
                return audio_item.audio_url;
            }
        },
        watch: {
            audioSrc(n, o) {
                if (n) {
                    if (this.$refs.audioSource) {
                        this.$refs.audioSource.src = n;
                        this.$refs.audio.load();
                    }
                } else {
                    this.$refs.audio.pause();
                }

            }
        },
        mounted() {
            captionPlayerBus.$on('mediaEncoded', this.onMediaEncoded);
            this.$nextTick(() => {
                setTimeout(() => {
                    if (this.audioSrc) {
                        this.$nextTick(() => {
                            this.$refs.audioSource.src = this.audioSrc;
                            this.$refs.audio.load();
                        });
                    }
                }, 50);
            });
        },
        beforeDestroy() {
            captionPlayerBus.$off('mediaEncoded', this.onMediaEncoded);
        },
        methods: {
            onMediaEncoded(data) {
                if (this.item && this.item.type === 'recorded_audio' && this.item.recorded_audio.encoding_id === data.encoding_id) {
                    this.audioUrlReceivedFromEncoding = getAudioUrlFromAudioCaptionObject(data.encoding_media);
                    this.$nextTick(() => {
                        setTimeout(() => {
                            if (this.audioSrc) {
                                this.$refs.audioSource.src = this.audioSrc;
                                this.$refs.audio.load();
                            }
                        }, 50);
                    });
                }
            },
            togglePlay() {
                const audio = this.$refs.audio;
                if (audio) {
                    audio.paused ? audio.play() : audio.pause();
                }
            },
        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";

  audio {
    max-width: 100%;
  }

  .vue-inline-audio {
    font-size: 0;

    &__main-part {
      font-size: $bigger-font-size;
    }
  }

  .inline_audio_container {
    margin-top: 30px;
    margin-bottom: 30px;
  }
</style>
