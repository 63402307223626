/**
 * Created by orion on 19.04.17.
 */

import simplePopups from 'shared/simple_popups/simple_popup_module';
import notifications from 'shared/notifications/notifications_module';
import angular from 'angular'
import search_input from 'shared/inputs/search_input/search_input_module'


//
import selectFactory from './select_factory'


angular.module('mmApp.shared.select-factory', [search_input, simplePopups, notifications])
    .factory('selectFactory', selectFactory);

export default 'mmApp.shared.select-factory'