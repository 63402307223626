var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "center-block" }, [
    _c("span", { staticClass: "align-helper" }),
    _vm._v(" "),
    !_vm.videoSrc
      ? _c("span", { staticStyle: { "font-size": "18px" } }, [
          _vm._v("This Video is not ready yet..."),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.videoSrc
      ? _c(
          "video",
          {
            ref: "video",
            staticClass: "inline_video_container hidden-print",
            style: [_vm.maxHeightStyle],
            attrs: {
              preload: "auto",
              autoplay: _vm.autoplay,
              poster: _vm.posterUrl,
              controls: "",
              playsinline: "",
              controlsList: "nodownload",
            },
          },
          [
            _c("source", {
              ref: "videoSource",
              attrs: { src: _vm.videoSrc, type: "video/mp4" },
            }),
            _vm._v(
              "\n        Your browser does not support the video tag.\n    "
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.posterUrl
      ? _c("img", {
          staticClass: "visible-print",
          staticStyle: { margin: "auto", display: "block" },
          attrs: { src: _vm.posterUrl, alt: "", height: "350" },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }