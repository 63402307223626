var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "div",
        [
          _vm.wikiEditor
            ? _c(
                "alert",
                {
                  staticClass: "margin-15-top margin-15-bottom",
                  attrs: { type: "info" },
                },
                [
                  _vm._v("Editing session is being conducted by "),
                  _c("b", [_vm._v(_vm._s(_vm.wikiEditor.name))]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("wiki-detail-page-slide-container", {
        attrs: {
          "full-screen": _vm.fullScreen,
          "active-slide": _vm.activeSlide,
          "edit-mode": _vm.editMode,
        },
      }),
      _vm._v(" "),
      _c("wiki-detail-page-slide-attachments", {
        attrs: { slide: _vm.activeSlide, "edit-mode": _vm.editMode },
      }),
      _vm._v(" "),
      _c("wiki-detail-page-slider", { attrs: { "edit-mode": _vm.editMode } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }