<template>
    <div class="row">
        <div class="col-sm-6">
            Start at <br/>
            <form action="">
                <div class="row">
                    <div class="col-sm-6">
                        <select class="form-control inline-block" @input="onStartHourInput" :value="value.start.hour"
                                :disabled="disabled">
                            <option :value="hour.value" v-for="hour in startHours">{{hour.label}}</option>
                        </select>
                    </div>
                    <div class="col-sm-6">
                        <select class="form-control inline-block" :value="value.start.minute" :disabled="disabled"
                                @input="onStartMinuteInput"
                        >
                            <option :value="minute" v-for="minute in startMinutes">{{minute}}</option>
                        </select>
                    </div>
                </div>
            </form>
        </div>

        <div class="col-sm-6">
            Finishes at<br/>
            <form action="">
                <div class="row">
                    <div class="col-sm-6">
                        <select @change="onEndTimeChanged" @input="onEndHourInput" :value="value.end.hour"
                                class="form-control inline-block"
                                :disabled="disabled">
                            <option :value="hour.value" v-for="hour in endHours">{{hour.label}}</option>
                        </select>
                    </div>
                    <div class="col-sm-6">
                        <select @change="onEndTimeChanged" @input="onEndMinuteInput" :value="value.end.minute"
                                class="form-control inline-block"
                                :disabled="disabled"
                        >
                            <option :value="minute" v-for="minute in endMinutes">{{minute}}</option>
                        </select>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';

    const nullify = (i) => {
        return i < 10 ? `0${i}` : `${i}`;
    };

    const getHrsLabel = (hourNum) => {
        if (hourNum < 13) {
            return `${hourNum === 0 ? 12 : hourNum} ${hourNum === 12 ? 'pm' : 'am'}`;
        } else {
            return `${hourNum - 12} pm`;
        }
    };

    export default {
        name: "EventTimeEditor",
        props: {
            value: {
                type: Object,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                endTimeWasChanged: false,
            };
        },
        computed: {
            startHours() {
                const hours = [];

                for (let i = 0; i < 24; i++) {
                    hours.push({value: nullify(i), label: getHrsLabel(i)});
                }
                return hours;
            },
            startMinutes() {
                const hours = [];
                for (let i = 0; i < 60; i++) {
                    hours.push(nullify(i));
                }
                return hours;
            },
            endHours() {
                const hours = [];
                for (let i = 0; i < 24; i++) {
                    hours.push({value: nullify(i), label: getHrsLabel(i)});
                }
                return hours;
            },
            endMinutes() {
                const hours = [];
                for (let i = 0; i < 60; i++) {
                    hours.push(nullify(i));
                }
                return hours;
            },
        },
        methods: {
            getValueFromEvent(e) {
                return (e.srcElement || e.target).value;
            },
            onStartHourInput(e) {
                let endTime;
                if (this.endTimeWasChanged) {
                    endTime = Object.assign({}, this.value.end);
                } else {
                    endTime = {
                        hour: this.getValueFromEvent(e),
                        minute: this.value.start.minute,
                    };
                    const hour = parseInt(this.getValueFromEvent(e));
                    if (hour < 23) {
                        endTime.hour = nullify(hour + 1);
                    }
                }
                this.$emit('input', {
                        start: {
                            hour: this.getValueFromEvent(e),
                            minute: this.value.start.minute,
                        },
                        end: endTime
                    }
                );
            },
            onStartMinuteInput(e) {
                this.$emit('input', {
                        start: {
                            hour: this.value.start.hour,
                            minute: this.getValueFromEvent(e),
                        },
                        end: _.merge({}, this.value.end)
                    }
                );
            },
            onEndHourInput(e) {
                this.$emit('input', {
                        start: _.merge({}, this.value.start),
                        end: {
                            hour: this.getValueFromEvent(e),
                            minute: this.value.end.minute,
                        }
                    }
                );
            },
            onEndMinuteInput(e) {
                this.$emit('input', {
                        start: _.merge({}, this.value.start),
                        end: {
                            hour: this.value.end.hour,
                            minute: this.getValueFromEvent(e),
                        }
                    }
                );
            },
            onEndTimeChanged() {
                this.endTimeWasChanged = true;
            }
        }
    };
</script>

<style scoped>

</style>