import Vue from 'vue';
import DashboardList from './DashboardList.vue';

function DashboardController() {
    const vm = new Vue({
        components: {DashboardList},
        el: '#DashboardController',
        data() {
            return {}
        },
        template: `<dashboard-list></dashboard-list>`
    });
}

export default DashboardController;