var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chat-style-comment-linked-entry" }, [
    _c(
      "span",
      {
        staticClass: "chat-style-comment-linked-entry__text",
        class: {
          "chat-style-comment-linked-entry__text_white": _vm.moveAvatarToRight,
        },
      },
      [
        _c(
          "strong",
          [_c("simple-link", { attrs: { target: _vm.comment.userprofile } })],
          1
        ),
        _vm._v(" has linked an\n        "),
        !_vm.userActivityPost.accessible
          ? _c("strong", [_vm._v("Update")])
          : _c("strong", [
              _c("a", { attrs: { href: _vm.userActivityPost.link } }, [
                _vm._v("Update"),
              ]),
            ]),
        _vm._v(":\n        "),
        _c("br"),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("br"),
        _vm._v(" "),
        _c(
          "render-quote",
          { attrs: { "limit-size": true, "quote-border-color": null } },
          [
            _c("render-activity-post", {
              attrs: {
                "activity-post": _vm.userActivityPost.data.activity_post,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }