<template>
    <div class="slider">
        <div class="slider__outer" ref="outerSlider" :style="[gripStyle]">
            <div class="slider__inner" :style="sliderInnerStyle">
                <wiki-detail-page-slider-slide
                        :edit-mode="editMode"
                        :slide="slide"
                        :slides="slides"
                        :active-slide="activeSlide"
                        @onClick="activateSlide(slide)"
                        v-for="slide of slides"
                        :key="slide.id"
                ></wiki-detail-page-slider-slide>
            </div>
        </div>

    </div>
</template>

<script>
    const SLIDE_WIDTH = 110;
    import WikiDetailPageSliderSlide from "./WikiDetailPageSliderSlide";
    import ScreenWidthAwareMixin from "../../../vue/mixins/ScreenWidthAwareMixin";

    export default {
        name: "WikiDetailPageSlider",
        components: {WikiDetailPageSliderSlide},
        mixins: [ScreenWidthAwareMixin],
        props: {
            editMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                outerWidth: 300
            };
        },
        computed: {
            gripStyle() {
                if (this.$store.getters['WikiStore/draggedSlide']) {
                    return {'cursor': 'grabbing'};
                }
            },
            slidesCapacity() {
                return Math.floor(this.outerWidth / SLIDE_WIDTH);
            },
            maxScrollOffset() {
                return this.sliderInnerWidth - this.outerWidth;
            },
            slides() {
                return this.$store.getters['WikiStore/slides'];
            },
            activeSlide() {
                return this.$store.getters['WikiStore/activeSlide'];
            },
            sliderInnerWidth() {
                return this.slides.length * SLIDE_WIDTH;
            },
            sliderInnerStyle() {
                return {
                    'width': `${this.sliderInnerWidth}px`
                };
            }
        },
        methods: {
            additionalSizeCalculations() {
                this.outerWidth = this.$refs.outerSlider.scrollWidth;
            },
            activateSlide(slide) {
                this.$store.commit('WikiStore/setActiveSlide', slide);
            },
            moveToActiveSlide(slide) {
                const activeSlideIndex = this.slides.indexOf(slide);
                const currentScrollOffset = this.$refs.outerSlider.scrollLeft;
                const firstCompletelyVisibleSlide = Math.ceil(currentScrollOffset / SLIDE_WIDTH);
                const lastCompletelyVisibleSlide = firstCompletelyVisibleSlide + this.slidesCapacity - 1;// -1?
                const isSlideVisibleNow = (activeSlideIndex >= firstCompletelyVisibleSlide) && (activeSlideIndex <= lastCompletelyVisibleSlide);
                if (!isSlideVisibleNow) {
                    const innerSlidePosition = activeSlideIndex * SLIDE_WIDTH;
                    let offsetWhereSlideWouldBeInCenter = Math.floor(innerSlidePosition - this.outerWidth / 2 + SLIDE_WIDTH / 2);
                    offsetWhereSlideWouldBeInCenter = Math.max(0, offsetWhereSlideWouldBeInCenter);
                    offsetWhereSlideWouldBeInCenter = Math.min(this.maxScrollOffset, offsetWhereSlideWouldBeInCenter);
                    if (this.$refs.outerSlider.scrollTo) {
                        this.$refs.outerSlider.scrollTo({
                            top: 0,
                            left: offsetWhereSlideWouldBeInCenter,
                            behavior: 'smooth'
                        });
                    } else {
                        this.$refs.outerSlider.scrollLeft = offsetWhereSlideWouldBeInCenter;
                    }

                }
            }

        },
        watch: {
            activeSlide(n) {
                if (n) {
                    this.$nextTick(() => {
                        this.moveToActiveSlide(n);
                    });
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    .slider {
        background-color: #E2E2E2;
        width: 100%;
        height: 110px;

        &__outer {
            width: 100%;
            height: 100%;
            overflow-x: auto;
            overflow-y: hidden;
        }

        &__inner {
            height: 100%;
        }
    }
</style>