<template>
    <selector-popup
            ref="popup"
            :header="header"
            :items="items">
        <template slot="footer">
            <button @click="cancel" type="button" class="btn btn-mm  btn-primary">Cancel</button>
        </template>
    </selector-popup>
</template>

<script>
    import SelectorPopup from "../../../../vue/common_components/SelectorPopup/SelectorPopup";

    export default {
        name: "CreateGoalTypeSelectorPopup",
        components: {SelectorPopup},
        computed: {
            header() {
                return `Create New ${this.$customTerm('Goal')}`;
            },
            items() {
                return [
                    {
                        label: 'From scratch',
                        iconClass: 'fa fa-plus',
                        action: 'select',
                        selection: 'scratch',
                    },
                    {
                        label: 'Copy/from template',
                        iconClass: 'fa fa-copy',
                        action: 'select',
                        selection: 'copy',
                    },
                ];
            }
        },
        methods: {
            show(header, shareState) {
                this.shareState = shareState;
                return new Promise((resolve, reject) => {
                    this.reject = reject;
                    this.resolve = resolve;
                    this.$refs.popup.show()
                        .then(item => {
                            resolve(item.selection);
                        }, reason => {
                            reject(reason);
                        });
                });
            },
            cancel() {
                this.reject('back');
            }
        }
    };
</script>

<style scoped>

</style>