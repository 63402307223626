import angular from 'angular';
import './search_input.scss'

angular.module('mmApp.search_input', []).directive('searchInput', searchInput);
import _ from 'lodash'
searchInput.$inject = ['$rootScope', '$timeout'];
function searchInput($rootScope, $timeout) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            searchFunction: '=',
            cancelFunction: '=?',
            minLettersCount: '@?'
        },
        template: require('./search_input.html'),
        link: function ($scope, element, attrs, controller) {

            $scope.minLettersCount = $scope.minLettersCount === undefined ? 3 : $scope.minLettersCount * 1;

            let search_promise;
            $scope.search_query = '';
            $scope.search_changed = search_changed;

            function search_changed(forsed_execute = false) {
                if ($scope.search_query.length === 0) {
                    if (angular.isDefined(search_promise)) {
                        $timeout.cancel(search_promise);
                    }
                    $scope.cancelFunction ? $scope.cancelFunction() : _.noop();
                }
                if ($scope.search_query.length < $scope.minLettersCount || $scope.search_query.length > 100) {
                    return;
                }
                if (angular.isDefined(search_promise)) {
                    $timeout.cancel(search_promise);
                }
                if (forsed_execute) {
                    $scope.searchFunction($scope.search_query);
                } else {
                    search_promise = $timeout($scope.searchFunction, 700, true, $scope.search_query);
                }

            }

        }
    };
}

export default 'mmApp.search_input'