var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chat-style-comment-reply", style: _vm.commentStyle },
    [
      _vm.showAuthor
        ? _c("div", [
            _c("strong", [_vm._v(_vm._s(_vm.authorName))]),
            _vm._v(" has linked Diary Log entry:"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.commentTextPreview
        ? _c("div", [_vm._v(_vm._s(_vm.commentTextPreview))])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }