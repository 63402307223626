var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { transition: 0 },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          attrs: { id: "close_popup" },
          on: { click: _vm.close_popup },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [
          _vm._v("Invite successfully sent"),
        ]),
      ]),
      _vm._v(" "),
      _c("span", [
        _vm._v(
          "\n        Invite successfully sent, you can pre-define access for this user in the Pending Invites section, would you like to go there now?\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-default",
            on: { click: _vm.close_popup },
          },
          [_vm._v("Not now")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-mm btn-green", on: { click: _vm.go } },
          [_vm._v("OK")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }