var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "goal-create-panel" }, [
    _c(
      "button",
      {
        staticClass: "btn background-button-blue wizard-button inline-block",
        class: { longTaskTerm: _vm.longTaskTerm },
        on: { click: _vm.runWizard },
      },
      [
        _c("i", { staticClass: "fa fa-magic" }),
        _vm._v(" "),
        _c("span", { staticClass: "wizard-button-label" }, [
          _vm._v(" " + _vm._s(_vm._f("customTerm")("Task")) + " Wizard"),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm.allowFilter
      ? _c(
          "button",
          {
            staticClass: "btn inline-block",
            class: _vm.filterButtonClass,
            on: { click: _vm.showFilterMenu },
          },
          [
            _c("i", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: "Filter by Assignee",
                  expression: "'Filter by Assignee'",
                },
              ],
              staticClass: "fa fa-filter",
            }),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.taskName,
          expression: "taskName",
        },
      ],
      staticClass: "form-control create-input inline-block",
      class: { "with-filter": _vm.allowFilter, longTaskTerm: _vm.longTaskTerm },
      attrs: {
        type: "text",
        maxlength: "255",
        placeholder: _vm.quickCreateTaskLabel,
      },
      domProps: { value: _vm.taskName },
      on: {
        keydown: function ($event) {
          if (!$event.type.indexOf("key") && $event.keyCode !== 13) return null
          $event.preventDefault()
          return _vm.createTask.apply(null, arguments)
        },
        input: function ($event) {
          if ($event.target.composing) return
          _vm.taskName = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn background-button-blue go-button inline-block",
        on: { click: _vm.createTask },
      },
      [_c("span", [_vm._v("Go")])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }