<template>
    <modal v-model="opened" :transition='0' class="commonGroupsListPopup">
        <div slot="header">
            <a id="close_popup" @click="close_popup"></a>
            <h3 class="modal-title">Common Groups List</h3>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <span v-for="groupContainer of groupContainers">
                    <strong><simple-link :target="groupContainer.target"></simple-link></strong>:<br>
                        <span v-for="(group, i) in groupContainer.groups">
                            <strong><simple-link :target="group" :new-tab="true"></simple-link></strong><span v-show="i<(groupContainer.groups.length-1)">, </span>
                        </span>
                    <br><br>
                </span>
            </div>
        </div>

        <div slot="footer">
            <button type="button" @click="close_popup()" class="btn btn-mm  btn-green">OK</button>
        </div>
    </modal>
</template>

<script>
    import groupGroupsByContainer from "./group_groups_by_container";

    export default {
        name: "CommonGroupsListPopup",
        data() {
            return {
                opened: false,
                groups: []
            };
        },
        computed:{
            groupContainers(){
                return groupGroupsByContainer(this.groups)
            }
        },
        methods: {
            show(groups) {
                this.opened = true;
                this.groups = groups;
            },
            close_popup() {
                this.opened = false;
                this.groups = [];
            }
        }
    };
</script>

<style scoped>
.commonGroupsListPopup{
    font-size: 16px;
}
</style>