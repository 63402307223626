<template>
    <modal v-model="opened" :transition='0' :append-to-body="true" :backdrop="false"
           class="create-holidays-popup">
        <div slot="header">
            <a id="close_popup" @click="closePopup"></a>
            <h3 class="modal-title">{{header}}</h3>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <i>By creating a Holiday you can snooze all repeated Events in a Group for a selected date
                    range.</i><br>
                <i><strong>Attention!</strong> All date-specific data (e.g. comments, attendance information,
                    invitations) will be lost in Group Events during this Holiday.</i>
            </div>
        </div>
        <div class="row margin-15-top">
            <div class="col-sm-6">
                <form class="form">
                    <strong>Start Date:</strong>
                    <datepicker v-model="startDate"
                                @keydown.native.delete="startDateClear"
                                :disabled-dates="disabledDatesStart"
                                :format="customFormatter"
                                :width="pickerWidth"
                                input-class="create-event-date-picker"
                                :bootstrap-styling="true"
                                :typeable="false"
                                :highlighted="highlightedDates"
                                ref="start-date-picker"
                    >
                        <div slot="afterDateInput" class="input-group-btn"
                             @click.stop.prevent="toggleStartDateCalendar">
                            <btn class="dropdown-toggle create-event-date-picker"><i
                                    class="glyphicon glyphicon-calendar"></i></btn>
                        </div>
                    </datepicker>

                </form>
            </div>
            <div class="col-sm-6">
                <form action="">
                    <strong>Finish Date:</strong>
                    <datepicker v-model="endDate"
                                @keydown.native.delete="endDateClear"
                                :disabled-dates="disabledDatesEnd"
                                :format="customFormatter"
                                :width="pickerWidth"
                                input-class="create-event-date-picker"
                                :bootstrap-styling="true"
                                :typeable="false"
                                :highlighted="highlightedDates"
                                ref="end-date-picker"
                    >
                        <div slot="afterDateInput" class="input-group-btn"
                             @click.stop.prevent="toggleEndDateCalendar">
                            <btn class="dropdown-toggle create-event-date-picker"><i
                                    class="glyphicon glyphicon-calendar"></i></btn>
                        </div>
                    </datepicker>
                </form>
            </div>
        </div>
        <div class="row margin-15-top">
            <div class="col-sm-12">
                <strong>Holiday Name:</strong>
                <input type="text" class="form-control create-holidays-popup__name-input"
                       placeholder="Name" v-model="name"
                       maxlength="100"
                       name="holiday_name"/>
                <div style="font-size: 13px; line-height: 18px; min-height: 20px;" v-show="!descriptionFieldIsVisible">
                    <a href="javascript:;" @click.prevent.stop="showDescriptionField" v-show="name">
                        + Add Description</a>
                </div>
            </div>
        </div>
        <div class="row margin-15-top" v-if="descriptionFieldIsVisible">
            <div class="col-sm-12">
                <strong>Add description:</strong>
                <textarea name="" id="" cols="30" rows="3" class="form-control" placeholder="Description"
                          v-model="description"
                          maxlength="3000"></textarea>
            </div>
        </div>
        <div class="row margin-15-top" v-if="allowCreateInAnotherGroups">
            <div class="col-sm-6">
                <vue-switch-with-label label="Include other Groups" :value="addInAnotherGroups"
                                       @input="onAddInAnotherGroupsChanged"
                                       name="addInAnotherGroups"></vue-switch-with-label>
            </div>
            <div class="col-sm-6" v-if="groupsToAddIdList.length" style="line-height: 31px">
                Holiday will be copied in <strong>{{groupsToAddIdList.length}}</strong> Group<span
                    v-if="groupsToAddIdList.length>1">s</span>.
            </div>
        </div>
        <div class="row margin-15-top" v-if="allowSyncWithAnotherGroups">
            <div class="col-sm-6">
                <vue-switch-with-label label="Sync with other Groups" :value="syncWithAnotherGroups"
                                       @input="onSyncWithAnotherGroupsChanged"
                                       name="syncWithAnotherGroups"></vue-switch-with-label>
            </div>
            <div class="col-sm-6" v-if="groupsToSyncIdList.length||groupsToSyncAllSelected" style="line-height: 31px">
                Holiday will be synced with
                <strong v-if="groupsToSyncAllSelected">all</strong>
                <strong v-else>{{groupsToSyncIdList.length}}</strong> Group<span
                    v-if="groupsToSyncAllSelected||groupsToSyncIdList.length>1">s</span>
            </div>
        </div>
        <div class="row margin-15-top"
             v-if="!allowCreateInAnotherGroups&&(groupsToAddIdList&&groupsToAddIdList.length||groupsToAddAllSelected)">
            <!-- for creation from calendar page -->
            <div class="col-sm-12">
                Holiday will be created in
                <strong v-if="!groupsToAddAllSelected">{{groupsToAddIdList.length}}</strong>
                <strong v-else>all</strong>
                Group<span
                    v-if="groupsToAddAllSelected||groupsToAddIdList.length>1">s</span>
            </div>
        </div>
        <div class="row margin-15-top"
             v-if="!allowSyncWithAnotherGroups&&(groupsToSyncIdList&&groupsToSyncIdList.length||groupsToSyncAllSelected)">
            <!-- for creation from calendar page -->
            <div class="col-sm-12">
                Holiday will be synced with
                <strong v-if="!groupsToSyncAllSelected">{{groupsToSyncIdList.length}}</strong>
                <strong v-else>all</strong>
                Group<span
                    v-if="groupsToSyncAllSelected||groupsToSyncIdList.length>1">s</span>
            </div>
        </div>
        <div slot="footer">
            <button class="btn btn-mm" @click="closePopup">Cancel</button>
            <button class="btn btn-mm btn-green save-button" :disabled="!allowSave" @click="save">
                {{saveLabel}}
            </button>
        </div>
    </modal>
</template>

<script>
    import Vue from 'vue';
    import Datepicker from 'vuejs-datepicker';
    import ScreenWidthAwareMixin from "../../../../vue/mixins/ScreenWidthAwareMixin";
    import {TIME_ZONE, URL_DATE_FORMAT, VISUAL_DATE_FORMAT} from "../../events/new_calendar/config";
    import moment from 'moment';

    const DEFAULT_HEADER = 'Create a Holiday';

    export default {
        name: "GroupEventsPageAddHolidayPopup",
        mixins: [ScreenWidthAwareMixin],
        components: {Datepicker},
        props: {
            groupId: Number,
        },
        data() {
            return {
                opened: false,

                startDate: undefined,
                endDate: undefined,
                name: '',
                description: '',
                addInAnotherGroups: false,
                syncWithAnotherGroups: false,

                groupsToAddIdList: [],
                groupsToAddAllSelected: false,
                groupsToSyncIdList: [],
                groupsToSyncAllSelected: false,

                holidaysId: undefined,

                descriptionFieldIsVisible: false,
                allowCreateInAnotherGroups: false,

                allowSyncWithAnotherGroups: false,

                saveLabel: 'Create',
                header: DEFAULT_HEADER
            };
        },
        computed: {
            allowSave() {
                return this.startDate && this.endDate;
            },
            pickerWidth() {
                return Math.min(340, this.windowWidth - 40);
            },
            highlightedDates() {
                return {
                    dates: [moment.tz(TIME_ZONE).toDate()],
                    includeDisabled: true,
                };
            },
            disabledDatesStart() {
                if (this.periodicEvent) {
                    return;
                }
                if (this.endDate) {
                    return {
                        from: this.endDate
                    };
                }
            },
            disabledDatesEnd() {
                if (this.startDate) {
                    return {to: (this.startDate > new Date()) ? this.startDate : new Date()};
                } else {
                    return {to: new Date()};
                }
            },
        },
        methods: {
            showGroupsSelectorCreate() {
                const GroupEventsHolidaysResource = Vue.getAngularModule('GroupEventsHolidaysResource');
                const selectFactory = Vue.getAngularModule('selectFactory');
                this.opened = false;
                let modalResult = selectFactory.endpoint_selector(_.partial(GroupEventsHolidaysResource.get_groups_where_i_can_add_holidays, this.groupId), true, {
                    header: `Add Holiday to other Groups`,
                    info: `Select or de-select Groups where you are admin to copy this Holiday to.`,
                    button_yes: 'OK',
                    button_no: 'Cancel',
                    empty: `You do not have any Groups to select`,
                }, false, false, false)
                    .then(
                        ([id_list, ...rest]) => {
                            this.addInAnotherGroups = true;
                            this.groupsToAddIdList = id_list;
                        },
                        cancel => {
                            this.groupsToAddIdList = [];
                            this.addInAnotherGroups = false;
                        }
                    )
                    .finally(() => {
                        this.opened = true;
                    });
            },
            showGroupsSelectorSyncChanges() {
                const GroupEventsHolidaysResource = Vue.getAngularModule('GroupEventsHolidaysResource');
                const selectFactory = Vue.getAngularModule('selectFactory');
                this.opened = false;
                let modalResult = selectFactory.endpoint_selector(_.partial(GroupEventsHolidaysResource.get_groups_where_i_can_sync_holiday_changes, this.holidaysId, this.groupId), true, {
                    header: `Sync changes in Holiday with other Groups`,
                    info: `Select or de-select Groups where you are admin to sync this Holiday too.`,
                    button_yes: 'OK',
                    button_no: 'Cancel',
                    empty: `You do not have any Groups to select`,
                    select_all_html: 'Select all',
                }, false, true, false)
                    .then(
                        ([id_list, __, all]) => {
                            this.syncWithAnotherGroups = true;
                            this.groupsToSyncIdList = id_list;
                            this.groupsToSyncAllSelected = all;
                        },
                        cancel => {
                            this.groupsToSyncIdList = [];
                            this.syncWithAnotherGroups = false;
                        }
                    )
                    .finally(() => {
                        this.opened = true;
                    });
            },
            onAddInAnotherGroupsChanged(val) {
                if (val) {
                    this.showGroupsSelectorCreate();
                } else {
                    this.groupsToAddIdList = [];
                    this.addInAnotherGroups = false;
                }
            },
            onSyncWithAnotherGroupsChanged(val) {
                if (val) {
                    this.showGroupsSelectorSyncChanges();
                } else {
                    this.groupsToSyncIdList = [];
                    this.syncWithAnotherGroups = false;
                }
            },
            resetData() {
                this.startDate = undefined;
                this.endDate = undefined;
                this.holidaysId = undefined;
                this.name = '';
                this.description = '';
                this.descriptionFieldIsVisible = false;
                this.allowCreateInAnotherGroups = false;
                this.allowSyncWithAnotherGroups = false;
                this.groupsToAddIdList = [];
                this.groupsToSyncIdList = [];
                this.groupsToAddAllSelected = false;
                this.groupsToSyncAllSelected = false;
                this.addInAnotherGroups = false;
                this.syncWithAnotherGroups = false;
                this.saveLabel = 'Create';
                this.header = DEFAULT_HEADER;
            },
            showDescriptionField() {
                this.descriptionFieldIsVisible = true;
            },
            startDateClear(e) {
                this.startDate = undefined;
            },
            endDateClear(e) {
                this.endDate = undefined;
            },
            create(allowCreateInAnotherGroups, initial = {}) {
                for (let f in initial) {
                    this[f] = initial[f];
                }
                this.allowCreateInAnotherGroups = allowCreateInAnotherGroups;
                this.opened = true;
                return new Promise((res, rej) => {
                    this.resolve = res;
                    this.reject = rej;
                });
            },
            edit(holiday, allowSync, initial = {}) {

                this.startDate = moment(holiday.start, VISUAL_DATE_FORMAT).toDate();
                this.endDate = moment(holiday.end, VISUAL_DATE_FORMAT).toDate();
                this.name = holiday.name;
                this.holidaysId = holiday.id;
                this.description = holiday.description;
                this.allowSyncWithAnotherGroups = allowSync;
                if (holiday.description) {
                    this.descriptionFieldIsVisible = true;
                }
                this.saveLabel = 'Save';
                this.header = 'Edit Holiday';

                for (let f in initial) {
                    this[f] = initial[f];
                }

                this.opened = true;
                return new Promise((res, rej) => {
                    this.resolve = res;
                    this.reject = rej;
                });
            },
            closePopup() {
                this.resetData();
                this.opened = false;
                this.reject();
            },
            save() {
                const res = {
                    startDate: this.startDate,
                    endDate: this.endDate,
                    name: this.name,
                    description: this.description
                };
                if (this.addInAnotherGroups && this.groupsToAddIdList.length) {
                    res.addToGroupsIdList = this.groupsToAddIdList;
                }
                if (this.syncWithAnotherGroups && this.groupsToSyncIdList.length) {
                    res.syncWithGroupsIdList = this.groupsToSyncIdList;
                }
                if (this.syncWithAnotherGroups && this.groupsToSyncAllSelected) {
                    res.groupsToSyncAllSelected = true;
                }
                this.resolve(res);
                this.resetData();
                this.opened = false;
            },
            customFormatter(date) {
                return moment(date).format(VISUAL_DATE_FORMAT);
            },
            toggleStartDateCalendar() {
                this.$refs['start-date-picker'].showCalendar();
            },
            toggleEndDateCalendar() {
                this.$refs['end-date-picker'].showCalendar();
            },
        }
    };
</script>

<style scoped lang="scss">
    .create-holidays-popup {
        &__name-input {
        }
    }
</style>