<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="header"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ header }}</h3>
        </div>
        <div class="row">
            <div class="col-sm-12" v-html="message"></div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <input
                        ref="textInput"
                        :maxlength="maxLength"
                        type="text"
                        class="form-control"
                        v-model="inputText"
                        v-if="element===POSSIBLE_ELEMTNTS.INPUT"
                        @keydown.enter="ok"
                />
                <textarea
                        ref="textInput"
                        rows="8"
                        :maxlength="maxLength"
                        type="text"
                        class="form-control"
                        v-model="inputText"
                        v-if="element===POSSIBLE_ELEMTNTS.TEXTAREA"
                        @keydown.ctrl.enter="ok"
                ></textarea>
            </div>
        </div>
        <div class="" slot="footer">
            <div class="row">
                <div class="col-sm-12">
                    <button v-if="buttonNo" class="btn btn-mm  btn-primary" type="button" @click="no">{{ buttonNo }}
                    </button>
                    <button
                            class="btn btn-mm  btn-green"
                            type="button"
                            @click="ok"
                            :disabled="!inputText.length"
                            ref="saveButton"
                    >
                        {{ buttonYes }}
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <span tabindex="0" aria-hidden="true" class="focus-catch-link" @focus="focusToClose">&nbsp;</span>
                </div>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import BasicModal from "../../../../vue/common_components/BasicModal";
    import PopupPromiseMixin from "../../../../vue/mixins/PopupPromiseMixin";
    import BasicModalCloseButton from "../../../../vue/common_components/BasicModalCloseButton";

    const POSSIBLE_ELEMTNTS = {
        INPUT: 'input',
        TEXTAREA: 'textarea',
    };

    export default {
        name: "simpleTextInputPopup",
        mixins: [PopupPromiseMixin],
        components: {BasicModalCloseButton, BasicModal},
        data() {
            return {
                header: '',
                message: '',
                inputText: '',
                element: POSSIBLE_ELEMTNTS.INPUT,
                maxLength: 255,
                buttonYes: '',
                buttonNo: '',

                firstFocusToSave: true,
            };
        },
        computed: {
            firstFocusEl() {
                if (this.firstFocusToSave && this.inputText.length) {
                    return this.$refs.saveButton;
                } else {
                    return this.$refs.textInput;
                }
            },
            POSSIBLE_ELEMTNTS() {
                return POSSIBLE_ELEMTNTS;
            },
        },
        methods: {
            setInitial(
                header,
                message = '',
                buttonYes = 'OK',
                buttonNo = undefined,
                initial = '',
                maxLength = 255,
                element = POSSIBLE_ELEMTNTS.INPUT,
                firstFocusToSave = true,
            ) {
                this.header = header;
                this.message = message;
                this.buttonYes = buttonYes;
                this.buttonNo = buttonNo;
                this.inputText = initial;
                this.maxLength = maxLength;
                this.element = element;
                this.firstFocusToSave = firstFocusToSave;
            },
            ok() {
                if (this.inputText.length) {
                    this.save(this.inputText);
                }
            },
            no() {
                this.close();
            },
        }
    };
</script>