/**
 * Created by orion on 05.03.17.
 */
import angular from 'angular'

angular.module('mm.preloaded', []).constant('$preloaded', {}).config([
        '$preloaded', function (preloaded) {
            let data, i, results, script, scripts;
            scripts = document.getElementsByTagName('script');
            i = 0;
            results = [];
            while (i < scripts.length) {
                script = scripts[i];
                if (script.type === 'text/preloaded') {
                    data = JSON.parse(script.innerHTML);
                    angular.extend(preloaded, data);
                }
                results.push(i++);
            }
            return results;
        }
    ]);

export default 'mm.preloaded'