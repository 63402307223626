/**
 * Created by orion on 29.05.17.
 */
let _ = require('lodash');
import createNewCollectionPopupController from './controller'
createNewCollectionFactory.$inject = ['simplePopupFactory', '$q', 'CollectionsResource', '$rootScope', 'notifications', '$log'];

function createNewCollectionFactory(simplePopupFactory, $q, CollectionsResource, $rootScope, notifications, $log) {


    let DEFAULT_COLLECTION_DATA = {
        name: '',
        for_user: false,
        username: undefined
    };

    let promise;

    let dep_names = [];

    let collection_data = _.merge({}, DEFAULT_COLLECTION_DATA);


    function show_create_goal_popup() {
        let popup_promise = simplePopupFactory.create_popup(createNewCollectionPopupController, require('./template.html'), {
            'dependants_names': dep_names,
            'collection_data': collection_data
        });

        popup_promise.then(res => {
            if (res.type === 'create_collection') {
                $log.debug('res.type-->create_collection');
                CollectionsResource.create_collection({
                    name: res.collection_data.name,
                    for_user: res.collection_data.for_user,
                    username: res.collection_data.username
                }).then(resp => {
                    promise.resolve(resp.data);
                }, err => {
                    notifications.error(err || 'Error');
                    promise.reject(err || 'Error');
                }).finally($rootScope.hide_dimmer);
            }
        }, err => {
            promise.reject(err);
        })
    }

    function create_collection(dependants_names) {
        $log.debug('create_collection-->', dependants_names);
        promise = $q.defer();
        dep_names = dependants_names;
        collection_data = _.merge({}, DEFAULT_COLLECTION_DATA);
        show_create_goal_popup(dependants_names);
        return promise.promise;
    }

    return {create_collection}
}


export default createNewCollectionFactory