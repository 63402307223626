/**
 * Created by orion on 05.06.17.
 */
import './style.scss'

attachToGoalPopupController.$inject = ['$scope', '$uibModalInstance', '$timeout', '$log'];
function attachToGoalPopupController($scope, $uibModalInstance,) {
    $scope.close = function () {
        $uibModalInstance.dismiss('closed');
    };

    $scope.select = function (type) {
        $uibModalInstance.close(type);
    };
}

export default attachToGoalPopupController;