<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 aria-label="New Wiki"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">Filling Guidance</h3>
        </div>
        <div class="row margin-15-top" v-if="initialized&&!results.length">
            <div class="col-sm-12">
                <div class="alert alert-info">
                    There isn't anything yet
                </div>
            </div>
        </div>
        <div class="row content-container">
            <div class="col-sm-12">
                <filling-guidance-dialog-item
                        v-for="post in results"
                        :key="post.id"
                        :post="post"
                        :editable="editable"
                        @remove="removePost(post)"
                />
            </div>
        </div>
        <div slot="footer">
            <div class="row">
                <div class="col-sm-8 text-left">
                    <button v-if="editable" :disabled="results.length>19" class="btn btn-mm btn-default"
                            @click="addText">
                        Add Text
                    </button>
                    <button v-if="editable" :disabled="results.length>19" class="btn btn-mm btn-default"
                            @click="addMedia">Add Media
                    </button>
                </div>
                <div class="col-sm-4">
                    <button class="btn btn-mm btn-green" @click="close">Close</button>
                </div>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import PopupPromiseMixin from '~/vue/mixins/PopupPromiseMixin';
    import InfiniteScrollMixin from '~/vue/mixins/InfiniteScrollMixin';
    import LikeStoryResourceFactory from '~/angular/app/shared/story/resource_factory';
    import FillingGuidanceDialogItem from '~/angular/app/wiki_page/popups/FillingGuidanceDialogItem.vue';
    import { simplePopupFactory } from '~/angular/app/shared/simple_popups/simplePopupFactory';
    import Vue from 'vue';

    const wiki_slide_guidance_resource = LikeStoryResourceFactory('wiki_slide_guidance')();
    const wiki_slide_guidance_resource_read = LikeStoryResourceFactory('wiki_slide_guidance_read')();

    export default {
        name: 'FillingGuidanceDialog',
        components: { FillingGuidanceDialogItem },
        mixins: [PopupPromiseMixin, InfiniteScrollMixin],
        data() {
            return {
                slide: undefined,
                getResultsOnMount: false,
                editable: false,
                limit: 20,
            };
        },
        methods: {
            setInitial({
                slide,
                editable = true
            }) {
                this.slide = slide;
                this.editable = editable;
                this.getResults();
            },
            getListEndpoint() {
                if (this.editable) {
                    return wiki_slide_guidance_resource.get_story_infinite;
                } else {
                    return wiki_slide_guidance_resource_read.get_story_infinite;
                }
            },
            getListEndpointArgs() {
                return [this.slide?.id];
            },
            async removePost(post) {
                const confirmed = await simplePopupFactory.show_popup('Remove post', 'Are you sure you want to remove this post?', 'Remove', 'Cancel', 'btn-red').catch(() => {
                });
                if (confirmed) {
                    this.$loadingSpinner.show();
                    const resp = await wiki_slide_guidance_resource.remove_item(this.slide.id, post.id);
                    this.totalCount = resp.data.count;
                    this.results.splice(this.results.indexOf(post), 1);
                    this.$loadingSpinner.hide();
                }
            },
            async addText() {
                this.tmpHide();
                const res = await simplePopupFactory.simple_text_input_popup(
                    'Add Text',
                    '',
                    'Add',
                    'Cancel',
                    '',
                    3000,
                    'textarea',
                    false).catch(() => {
                });
                if (res) {
                    const text = res;
                    this.$loadingSpinner.show();
                    const resp = await wiki_slide_guidance_resource.add_to_story(this.slide.id, { text });
                    this.results.push(resp.data.item);
                    this.totalCount = resp.data.count;
                    this.$loadingSpinner.hide();
                }
                this.tmpShow();
            },
            async addMedia() {
                this.tmpHide();
                const addMedia = Vue.getAngularModule('addMedia');
                addMedia.add_media(
                    {
                        allow_add_from_library: true,
                        max_count: 1,
                        types: ['video', 'audio', 'image', 'document'],
                        popup_header: 'What sort of media would you like to add?'
                    },
                    {
                        default_callback: async (mediaItems) => {
                            if (mediaItems) {
                                this.$loadingSpinner.show();
                                const resp = await wiki_slide_guidance_resource.add_to_story(this.slide.id, { media_attachment_id: mediaItems[0].id });
                                this.results.push(resp.data.item);
                                this.totalCount = resp.data.count;
                                this.$loadingSpinner.hide();
                            }
                            this.tmpShow();
                        },
                        cancel_callback: () => {
                            this.tmpShow();
                        }
                    }
                );
            },
        }
    };

</script>

<style scoped lang="scss">
  .content-container {
    max-height: 80vh;
    overflow-y: auto;
  }
</style>
