import urls from 'DjangoUrls';

let {DjangoUrls} = urls;
CalendarListResource.$inject = ['$http'];

function CalendarListResource($http) {
    return {
        get_calendars_i_manage,
    };

    function get_calendars_i_manage(params) {
        const url = DjangoUrls["api.v1:buddy_users_lists-get-calendars-i-manage"]();
        return $http({
            method: 'GET',
            url,
            params
        });
    }
}


export default CalendarListResource;