<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="header"
                 class="edd-emotion-popup"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 id="modal-header" class="modal-title">{{ header }}</h3>
        </div>
        <div class="row"
        >
            <div class="col-xs-12 edd-emotion-popup__emotions-container"
                 aria-label="Select emotion from list." role="menu">
                <div class="edd-emotion-popup__emotion"
                     ref="emotion"
                     :aria-label="icon.description"
                     tabindex="0"
                     :class="{
                     selected: icon===selectedIcon,
                     inactive: selectedIcon&&icon!==selectedIcon
                     }"
                     v-for="icon in availableIcons"
                     :key="icon.code"
                     role="menuitemradio"
                     :aria-checked="icon===selectedIcon?'true':'false'"
                     @click="selectIcon(icon)"
                     @keydown.enter="selectIcon(icon)"
                     @keydown.space.stop.prevent="selectIcon(icon)"
                >
                    <div class="edd-emotion-popup__emotion__icon"
                         aria-hidden="true"
                    >
                        <i class="fal" :class="icon.iconClass" :aria-label="icon.description"></i>
                    </div>
                    <div aria-hidden="true" class="edd-emotion-popup__emotion__label">{{ icon.description }}</div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="row">
                <div class="col-sm-12">
                    <button
                            @click="close"
                            class="btn btn-mm"
                    >Cancel
                    </button>
                    <div class="inline-block" data-role="trigger">
                        <button
                                :disabled="!isSaveButtonEnabled"
                                :aria-disabled="!isSaveButtonEnabled"
                                ref="saveButton"
                                @click="addFeeling"
                                class="btn btn-mm btn-green">Add Feeling
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <span tabindex="0" aria-hidden="true" class="focus-catch-link" @focus="focusToClose">&nbsp;</span>
                </div>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import {ICONS} from './diaryEntryEmotions';
    import BasicModal from "../../../../../vue/common_components/BasicModal";
    import PopupPromiseMixin from "../../../../../vue/mixins/PopupPromiseMixin";

    export default {
        name: "addEmotionPopup",
        components: {BasicModal},
        mixins: [PopupPromiseMixin],
        data() {
            return {
                header: 'How are you Feeling?',
                selectedIcon: undefined
            };
        },
        computed: {
            availableIcons() {
                return ICONS;
            },
            isSaveButtonEnabled() {
                return this.selectedIcon;
            },
            firstFocusEl() {
                return this.$refs.emotion[0];
            },
        },
        methods: {
            focusToFirst() {
                this.$refs.firstEl?.focus();
            },
            addFeeling() {
                this.save(this.selectedIcon);
            },
            selectIcon(icon) {
                this.selectedIcon = icon;
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.$refs.saveButton?.focus();
                    }, 50);
                });
            }
        }
    };
</script>

<style scoped lang="scss">
  @import "../../../styles/const";

  .edd-emotion-popup ::v-deep {

    .modal-dialog {
      @media (min-width: 768px) {
        width: 500px;
      }
    }
  }

  .edd-emotion-popup {

    .modal-dialog ::v-deep {
      @media (min-width: 768px) {
        width: 500px;
      }
    }

    &__emotions-container {
      text-align: center;
    }

    &__emotion {
      text-align: center;
      display: inline-block;

      $mobile-xs-1-max: 352px;
      $mobile-xs-2-min: 353px;
      $mobile-xs-2-max: 390px;
      $mobile-xs-3-min: 391px;
      $mobile-xs-3-max: 475px;
      $mobile-xs-4-min: 476px;
      $mobile-xs-4-max: 575px;

      @media (max-width: $mobile-xs-1-max) {
        min-width: 60px;
        margin-bottom: 10px;
      }
      @media (min-width: $mobile-xs-2-min) and (max-width: $mobile-xs-2-max) {
        min-width: 70px;
        margin-bottom: 10px;
      }
      @media (min-width: $mobile-xs-3-min) and (max-width: $mobile-xs-3-max) {
        min-width: 85px;
        margin-bottom: 15px;
      }
      @media (min-width: $mobile-xs-4-min) and (max-width: $mobile-xs-4-max) {
        min-width: 105px;
        margin-bottom: 15px;
      }

      min-width: 112px;
      margin-bottom: 20px;

      color: $tag-gray;
      opacity: 0.8;
      transition: all ease 0.2s;
      cursor: pointer;

      &.inactive {
        opacity: 0.6;
        color: $tag-gray;
      }

      &:hover, &:active, &.selected, &:focus {
        opacity: 1;
        color: $tag-gray-dark;
      }

      &.selected {
        opacity: 1;
        color: $black;
      }

      &__icon {
        @media (max-width: $mobile-xs-1-max) {
          font-size: 32px;
          line-height: 32px;
        }
        @media (min-width: $mobile-xs-2-min) and (max-width: $mobile-xs-2-max) {
          font-size: 38px;
          line-height: 38px;
        }
        @media (min-width: $mobile-xs-3-min) and (max-width: $mobile-xs-3-max) {
          font-size: 42px;
          line-height: 42px;
        }
        font-size: 50px;
        line-height: 50px;
      }

      &__label {
        @media (max-width: $mobile-xs-1-max) {
          font-size: 12px;
        }
        @media (min-width: $mobile-xs-2-min) and (max-width: $mobile-xs-2-max) {
          font-size: 16px;
        }
        @media (min-width: $mobile-xs-3-min) and (max-width: $mobile-xs-3-max) {
          font-size: 18px;
        }
        font-size: 22px;
      }
    }
  }
</style>