var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("vue-simple-header", [
        _c("span", { attrs: { slot: "headerTitle" }, slot: "headerTitle" }, [
          _vm._v("Manage User Access"),
        ]),
        _vm._v(" "),
        _c(
          "span",
          { attrs: { slot: "panelRight" }, slot: "panelRight" },
          [
            _c("vue-folder-button", {
              attrs: { text: "Create access link", "icon-class": "fa-link" },
              nativeOn: {
                click: function ($event) {
                  return _vm.createAccessLink.apply(null, arguments)
                },
              },
            }),
            _vm._v(" "),
            _c("vue-folder-button", {
              attrs: { text: "Invite by Email", "icon-class": "fa-envelope-o" },
              nativeOn: {
                click: function ($event) {
                  return _vm.inviteByEmail.apply(null, arguments)
                },
              },
            }),
            _vm._v(" "),
            _c("vue-folder-button", {
              attrs: { text: "Invite Users", "icon-class": "fa-user-plus" },
              nativeOn: {
                click: function ($event) {
                  return _vm.inviteUsers.apply(null, arguments)
                },
              },
            }),
            _vm._v(" "),
            _c("vue-folder-button", {
              attrs: { color: "blue", text: "Back", "icon-class": "fa-reply" },
              nativeOn: {
                click: function ($event) {
                  return _vm.goToMedia.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("members-table", {
        attrs: { members: _vm.members, owner: _vm.currentUser },
      }),
      _vm._v(" "),
      _c("invitation-type-select-popup", {
        ref: "invitation-type-select-popup",
      }),
      _vm._v(" "),
      _c("email-role-category-popup", { ref: "email-role-category-popup" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert alert-warning" }, [
      _c("strong", [_vm._v("Important:")]),
      _vm._v(
        " through sharing any part of this Media Folders with a user or group of users you\n        are\n        giving them consent to view shared material and download any attachments. Users are not permitted to\n        share this on with other users, or download or use any of the material for any other purposes.\n    "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }