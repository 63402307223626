import moment from 'moment';
import {DAYS_OF_WEEK, nullify, timeToAmPm} from "../new_calendar/common";
import * as config from '../new_calendar/config';
import Vue from 'vue';

export default {
    data() {
        return {
            attachments: []
        };
    },
    computed: {
        avatarStyle() {
            const style = {
                'background-image': `url('${this.event.event_data.avatar_url}')`,
            };
            if (this.event.event_data.uses_default_avatar) {
                style['background-size'] = '90%';
            }
            return style;
        },
        targetEventId() {
            return this.periodInfo.event_id;
        },
        sinceLabel() {
            return `${moment(this.periodInfo.start_date, config.VISUAL_DATE_FORMAT).format('D MMM YYYY')}`;
        },
        additionalPeriodInfoAvailable() {
            return this.periodInfo.additional_period_info_available; // if Event was deleted in some days or repeat rule was changed
        },
        startDateMoment() {
            return moment(this.event.start_date, config.VISUAL_DATE_FORMAT);
        },
        endDateMoment() {
            return moment(this.event.end_date, config.VISUAL_DATE_FORMAT);
        },
        startTimeLabel() {
            let res = `${this.startDateMoment.format('D MMM YYYY')}`;
            if (!this.event.event_data.whole_day) {
                res += `, ${timeToAmPm(this.event.event_data.start_time)}`;
            } else {
                res += ', 00:00';
            }
            return res;
        },
        startDateLabel() {
            if (this.startDateMoment) {
                return this.startDateMoment.format('MMM D');
            }
        },
        periodicEventScheduleItems() {
            if (!this.event.event_data.advanced_time_schedule_enabled) {
                return;
            }
            const currentDayOfWeekKey = this.startDateMoment.format('E');
            const res = [];
            for (let weekday in this.event.event_data.advanced_time_schedule) {
                const thisWeekdayTimeData = this.event.event_data.advanced_time_schedule[weekday];
                const nullifiedStartTime = `${nullify(thisWeekdayTimeData.start.hour)}:${nullify(thisWeekdayTimeData.start.minute)}`;
                const nullifiedEndTime = `${nullify(thisWeekdayTimeData.end.hour)}:${nullify(thisWeekdayTimeData.end.minute)}`;
                const timeLabel = `${timeToAmPm(nullifiedStartTime)} - ${timeToAmPm(nullifiedEndTime)}`;
                res.push({
                    weekdayLabel: DAYS_OF_WEEK[parseInt(weekday) - 1],
                    timeLabel: timeLabel,
                    isActive: weekday === currentDayOfWeekKey,
                });
            }
            return res;
        },
        finishTimeLabel() {
            let res = `${this.endDateMoment.format('D MMM YYYY')}`;
            if (!this.event.event_data.whole_day) {
                res += `, ${timeToAmPm(this.event.event_data.end_time)}`;
            } else {
                res += ', 11:59pm';
            }
            return res;
        },
        locationLabel() {
            const location_fields = ['address_line_1', 'address_line_2', 'city', 'country', 'post_code'];
            const to_display = [];
            for (let i in location_fields) {
                const field = location_fields[i];
                if (this.event.event_data.location[field]) {
                    if (to_display.length === 0 || to_display[0].indexOf(this.event.event_data.location[field]) === -1) {
                        to_display.push(this.event.event_data.location[field]);
                    }
                }
            }
            return to_display.join(', ');
        },
    },
    methods: {
        loadAttachments() {
            const $rootScope = Vue.getAngularModule('$rootScope');
            const notifications = Vue.getAngularModule('notifications');
            $rootScope.show_dimmer();
            this.attachmentsResource.get_attachments(this.targetEventId)
                .then(resp => {
                    this.$refs['attachments-panel'].setAttachments(resp.data);
                    this.attachments = resp.data;
                }, err => {
                    notifications.error(err || 'Error');
                })
                .finally($rootScope.hide_dimmer);
        },
    }
};