import urls from 'DjangoUrls';

let {DjangoUrls} = urls;
AuthResource.$inject = ['$http'];

function AuthResource($http) {
    return {
        login: function (data) {
            const url = DjangoUrls["api.v1:auth-login"]();
            return $http({
                method: 'POST',
                url,
                data,
                cache: false
            })
        },
        register: function (data) {
            const url = DjangoUrls["api.v1:auth-register"]();
            return $http({
                method: 'POST',
                url,
                data
            })
        },
        query_users: function (data) {
            const url = DjangoUrls["api.v1:auth-query-users"]();
            return $http({
                method: 'POST',
                url,
                data
            })
        },
        password_reset_request: function (id) {
            const url = DjangoUrls["api.v1:auth-password-reset-request"]();
            return $http({
                method: 'POST',
                url,
                data: {id}
            })
        },
        password_reset: function (data) {
            const url = DjangoUrls["api.v1:auth-password-reset"]();
            return $http({
                method: 'POST',
                url,
                data
            })
        }
    }
}

export default AuthResource;