<template>
    <div class="subsection">
        <i class="fa"
           :class="chevronClasses"
           @click="toggleSubsectionsExpand"
        ></i>
        <i class="fa fa-pencil" v-if="allowAssignEditPermission" :class="editButtonClasses"
           @click="toggleSectionLevelEditPermission"></i>
        <i class="fa fa-eye" :class="viewButtonClasses" @click="toggleSectionLevelViewPermissionLevel"></i>
        <div class="subsection__section-avatar border-group"
             :style="sectionAvatarStyle">
            <i class="fa fa-picture-o" v-if="isAvatarEmpty"></i>
        </div>
        <span>
            <a :href="section.link" target="_blank"><strong>{{ section.caption | cutName(30) }}</strong></a>
            <i v-if="accessCode&&isClipboardSupported&&sectionIsAvailable"
               v-tooltip="'Copy direct access code link'"
               class="fa fa-clipboard pointer color-dark-blue"
               style="font-size: 14px"
               @click.stop.prevent="copySectionAccessCode"
               :ref="'accessCodeIcon'+section.id"
               :data-clipboard-text="directAccessLink"
            ></i>
        </span>

        <div v-if="subsectionsExpanded">
            <wiki-tree-section
                    :section="sect"
                    :accessCode="accessCode"
                    :domain="domain"
                    :inheritedPermissionLevel="resultPermissionLevel"
                    :permissionsList="permissionsList"
                    :allowAssignEditPermission="allowAssignEditPermission"
                    v-for="sect in section.subsections"
                    :key="sect.id"
            ></wiki-tree-section>
            <wiki-tree-section-slide
                    :slide="slide"
                    :permissionsList="permissionsList"
                    :inheritedPermissionLevel="resultPermissionLevel"
                    v-for="slide in section.slides" :key="slide.id"
            ></wiki-tree-section-slide>
        </div>
    </div>
</template>

<script>
    import mediaContentUtils from "../../view_media/mediaContentUtils";
    import WikiTreeSectionSlide from './WikiTreeSectionSlide.vue';
    import editViewButtonsClassesMixin from './editViewButtonsClassesMixin';
    import wikiMemberPermissionBus from './wikiMemberPermissionBus';
    import Vue from 'vue';
    import urls from 'DjangoUrls';
    import {
        WIKI_PERMISSION_VALUE,
        WIKI_PERMISSION_LEVEL,
        WIKI_PERMISSION_SIGNAL
    } from "../../wiki_page/common/wikiPermissions";
    import TreePermissionDetectionMixinWikiSection
        from "./tree_permission_detection_mixins/TreePermissionDetectionMixinWikiSection";

    let {DjangoUrls} = urls;

    let Clipboard = require('clipboard');
    const mcu = mediaContentUtils();

    export default {
        name: "wiki-tree-section",
        components: {
            WikiTreeSectionSlide
        },
        mixins: [editViewButtonsClassesMixin, TreePermissionDetectionMixinWikiSection],
        data() {
            return {
                subsectionsExpanded: false,
                isClipboardSupported: Clipboard.isSupported()
            };
        },
        props: {
            section: {
                type: Object
            },
            accessCode: {
                type: String,
                required: false
            },
            allowAssignEditPermission: {
                type: Boolean,
                default: false
            },
            avatarUrl: {
                type: String,
                required: false
            },
            domain: {
                type: String,
                required: false
            }
        },
        computed: {
            directAccessLink() {
                if (!this.accessCode || !this.isClipboardSupported) {
                    return;
                }
                if (this.section.is_submenu) {
                    return this.domain + DjangoUrls['users_wiki_code'](this.section.wiki.id, this.accessCode) + `#!?sectionId=${this.section.id}`;

                } else {
                    return this.domain + DjangoUrls['wiki_section_detail_code'](this.section.id, this.accessCode);
                }
            },
            chevronClasses() {
                const classes = [];
                if (!(this.section.slides.length || this.section.subsections.length)) {
                    classes.push('color-gray');
                }
                if (this.subsectionsExpanded) {
                    classes.push('fa-chevron-down');
                } else {
                    classes.push('fa-chevron-right');
                }
                return classes;
            },
            sectionAvatarStyle() {
                if (this.avatarUrl) {
                    return {'background-image': `url(${this.avatarUrl})`};
                }
                let resultStyle = {};
                let avatarUrl = mcu.get_img_url(this.section.avatar);
                if (avatarUrl || (this.section.media_content_samples && this.section.media_content_samples.length)) {
                    _.merge(resultStyle, mcu.generate_group_background(avatarUrl, this.section.media_content_samples));
                }
                return resultStyle;
            },
            sectionIsAvailable() {
                return this.resultPermissionLevel === WIKI_PERMISSION_VALUE.VIEW || this.resultPermissionLevel === WIKI_PERMISSION_VALUE.EDIT || this.hasRedefinedInChildEditProperty || this.hasRedefinedInChildViewProperty;
            },
            isAvatarEmpty() {
                if (this.avatarUrl) {
                    return false;
                }
                return !(this.section.avatar || (this.section.media_content_samples && this.section.media_content_samples.length));
            },
            hasRedefinedInChildEditProperty() {
                let triggerPermissions = this.triggerEditPermissions;
                let subsectionsIdList = [];
                for (let subsection of this.section.subsections) {
                    subsectionsIdList.push(subsection.id);
                    for (let subsubsection of subsection.subsections) {
                        subsectionsIdList.push(subsubsection.id);
                        for (let subsubsubsection of subsubsection.subsections) {
                            subsectionsIdList.push(subsubsubsection.id);
                        }
                    }
                }
                for (let subsectionId of subsectionsIdList) {
                    if (_.findIndex(this.permissionsList, p => (p.level === WIKI_PERMISSION_LEVEL.SECTION && p.target_id === subsectionId && triggerPermissions.includes(p.value))) !== -1) {
                        return true;
                    }
                }
                let slidesIdList = [];
                for (let slide of this.section.slides) {
                    slidesIdList.push(slide.id);
                }
                for (let subsection of this.section.subsections) {
                    for (let slide of subsection.slides) {
                        slidesIdList.push(slide.id);
                    }
                    for (let subsubsection of subsection.subsections) {
                        for (let slide of subsubsection.slides) {
                            slidesIdList.push(slide.id);
                        }
                    }
                }
                for (let slideId of slidesIdList) {
                    if (_.findIndex(this.permissionsList, p => (p.level === WIKI_PERMISSION_LEVEL.SLIDE && p.target_id === slideId && triggerPermissions.includes(p.value))) !== -1) {
                        return true;
                    }
                }
                return false;
            },
            hasRedefinedInChildViewProperty() {
                let triggerPermissions = this.triggerViewPermissions;
                let subsectionsIdList = [];
                for (let subsection of this.section.subsections) {
                    subsectionsIdList.push(subsection.id);
                    for (let subsubsection of subsection.subsections) {
                        subsectionsIdList.push(subsubsection.id);
                        for (let subsubsubsection of subsubsection.subsections) {
                            subsectionsIdList.push(subsubsubsection.id);
                        }
                    }
                }
                for (let subsectionId of subsectionsIdList) {
                    if (_.findIndex(this.permissionsList, p => (p.level === WIKI_PERMISSION_LEVEL.SECTION && p.target_id === subsectionId && triggerPermissions.includes(p.value))) !== -1) {
                        return true;
                    }
                }
                let slidesIdList = [];
                for (let slide of this.section.slides) {
                    slidesIdList.push(slide.id);
                }
                for (let subsection of this.section.subsections) {
                    for (let slide of subsection.slides) {
                        slidesIdList.push(slide.id);
                    }
                    for (let subsubsection of subsection.subsections) {
                        for (let slide of subsubsection.slides) {
                            slidesIdList.push(slide.id);
                        }
                    }
                }
                for (let slideId of slidesIdList) {
                    if (_.findIndex(this.permissionsList, p => (p.level === WIKI_PERMISSION_LEVEL.SLIDE && p.target_id === slideId && triggerPermissions.includes(p.value))) !== -1) {
                        return true;
                    }
                }
                return false;
            },
            canOwnEditPermissionBeChanged() {
                return this.inheritedPermissionLevel !== WIKI_PERMISSION_VALUE.EDIT;
            },
            canOwnViewPermissionBeChanged() {
                if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.EDIT) {
                    return false;
                }
                return this.ownPermissionLevel !== WIKI_PERMISSION_VALUE.EDIT;
            }
        },
        methods: {
            toggleSubsectionsExpand() {
                if (!(this.section.subsections.length || this.section.slides.length)) {
                    return;
                }
                this.subsectionsExpanded = !this.subsectionsExpanded;
            },
            copySectionAccessCode() {

            },
            toggleSectionLevelEditPermission() {
                if (!this.canOwnEditPermissionBeChanged) {
                    return;
                }
                if (this.ownPermissionLevel === WIKI_PERMISSION_VALUE.EDIT) {
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.VIEW) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SECTION, WIKI_PERMISSION_VALUE.UNSET, this.section.id);
                    } else {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SECTION, WIKI_PERMISSION_VALUE.VIEW, this.section.id);
                    }
                    return;
                }
                if (this.ownPermissionLevel === WIKI_PERMISSION_VALUE.VIEW) {
                    wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SECTION, WIKI_PERMISSION_VALUE.EDIT, this.section.id);
                    return;
                }
                if (this.ownPermissionLevel === WIKI_PERMISSION_VALUE.UNSET) {
                    wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SECTION, WIKI_PERMISSION_VALUE.EDIT, this.section.id);
                    return;
                }
                if (this.ownPermissionLevel === WIKI_PERMISSION_VALUE.NONE) {
                    wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SECTION, WIKI_PERMISSION_VALUE.EDIT, this.section.id);
                    return;
                }
            },
            toggleSectionLevelViewPermissionLevel() {
                if (!this.canOwnViewPermissionBeChanged) {
                    return;
                }
                if (this.ownPermissionLevel === WIKI_PERMISSION_VALUE.UNSET) {
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.UNSET) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SECTION, WIKI_PERMISSION_VALUE.VIEW, this.section.id);
                    }
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.VIEW) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SECTION, WIKI_PERMISSION_VALUE.NONE, this.section.id);
                    }
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.NONE) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SECTION, WIKI_PERMISSION_VALUE.VIEW, this.section.id);
                    }
                }
                if (this.ownPermissionLevel === WIKI_PERMISSION_VALUE.VIEW) {
                    if (this.inheritedPermissionLevel === 'unset') {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SECTION, WIKI_PERMISSION_VALUE.UNSET, this.section.id);
                    }
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.VIEW) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SECTION, WIKI_PERMISSION_VALUE.UNSET, this.section.id);
                    }
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.NONE) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SECTION, WIKI_PERMISSION_VALUE.UNSET, this.section.id);
                    }
                }
                if (this.ownPermissionLevel === WIKI_PERMISSION_VALUE.NONE) {
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.UNSET) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SECTION, WIKI_PERMISSION_VALUE.UNSET, this.section.id);
                    }
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.VIEW) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SECTION, WIKI_PERMISSION_VALUE.UNSET, this.section.id);
                    }
                    if (this.inheritedPermissionLevel === WIKI_PERMISSION_VALUE.NONE) {
                        wikiMemberPermissionBus.$emit(WIKI_PERMISSION_SIGNAL.SET_WIKI_PERMISSION, WIKI_PERMISSION_LEVEL.SECTION, WIKI_PERMISSION_VALUE.VIEW, this.section.id);
                    }
                }
            },
            bindClipBoard() {
                if (this.accessCode && this.isClipboardSupported) {
                    let clipboardIcon = this.$refs['accessCodeIcon' + this.section.id];
                    if (!clipboardIcon || this.clipBoardBound) {
                        return;
                    }
                    this.clipBoardBound = true;
                    let clipboard = new Clipboard(clipboardIcon);
                    clipboard.on('success', e => {
                        Vue.notifications.success('Copied');
                        console.log(this.section);
                    });

                    clipboard.on('error', e => {
                        Vue.notifications.error('You browser doesn\'t support auto copy. Please copy link manually');
                    });
                }
            }
        },
        updated() {
            this.bindClipBoard();
        },
        mounted() {
            this.bindClipBoard();
        }
    };
</script>

<style scoped lang="scss">
  .subsection {
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 40px;

    .fa {
      vertical-align: middle;
      font-size: 25px;
      margin: 0 4px;
    }

    .fa-chevron-right, .fa-chevron-down {
      cursor: pointer;
    }

    .fa-eye {
      &.disabled {
        opacity: (0.5);
      }
    }

    .fa-pencil {
      &.disabled {
        opacity: (0.5);
      }

    }

    .fa-chevron-right {
      width: 25px;
      text-align: center;
    }

    &__section-avatar {
      display: inline-block;
      vertical-align: middle;
      background-size: cover;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      text-align: center;

      .fa {
        line-height: 45px;
        font-size: 30px;
      }

    }
  }
</style>