var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticStyle: { "text-decoration": "none", "margin-left": "15px" },
      attrs: { href: _vm._f("backHref")(_vm.story.link) },
    },
    [
      _c("div", { staticClass: "story_preview" }, [
        _c(
          "div",
          {
            staticClass: "story_preview__avatar",
            class: _vm.story.content_type,
            style: _vm.avatarStyle,
          },
          [
            _vm.story.audio_caption
              ? _c(
                  "div",
                  { staticClass: "story_preview__audio-caption-container" },
                  [
                    _c("audio-toggle", {
                      attrs: { "audio-caption": _vm.story.audio_caption },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "story_preview__name" }, [
          _vm._v(_vm._s(_vm._f("cutName")(_vm.story.name, 40))),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }