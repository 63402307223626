var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": "New Wiki", role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [_vm._v("New Wiki")]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", [
            _c("span", [_vm._v("Wiki Name:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name",
                },
              ],
              ref: "name-input",
              staticClass: "form-control",
              attrs: {
                maxlength: "127",
                type: "text",
                placeholder: "Wiki Name",
                required: "",
              },
              domProps: { value: _vm.name },
              on: {
                keydown: function ($event) {
                  _vm.nameManuallyChanged = true
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.name = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", [
            _c("span", [_vm._v("Wiki Description:")]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.description,
                  expression: "description",
                },
              ],
              staticClass: "form-control",
              attrs: {
                maxlength: "1023",
                type: "text",
                placeholder: "Wiki Description",
              },
              domProps: { value: _vm.description },
              on: {
                keydown: function ($event) {
                  _vm.descriptionManuallyChanged = true
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.description = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.templates.length
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", [
                _c("span", [_vm._v("Wiki Template:")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedTemplate,
                        expression: "selectedTemplate",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedTemplate = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.templates, function (template) {
                    return _c(
                      "option",
                      { key: template.id, domProps: { value: template } },
                      [
                        _vm._v(
                          _vm._s(_vm.getTemplateVisibleName(template)) +
                            "\n                    "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c(
          "div",
          { staticClass: "col-sm-5" },
          [
            _c("vue-switch-with-label", {
              attrs: { name: "for_user", label: "Show content count" },
              model: {
                value: _vm.showContentCounters,
                callback: function ($$v) {
                  _vm.showContentCounters = $$v
                },
                expression: "showContentCounters",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-7" }, [
          _vm._v("\n            Show content count of each section.\n        "),
        ]),
      ]),
      _vm._v(" "),
      _vm.dependents.length
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c(
              "div",
              { staticClass: "col-sm-5" },
              [
                _c("vue-switch-with-label", {
                  attrs: { name: "for_user", label: "Create for user" },
                  model: {
                    value: _vm.forUser,
                    callback: function ($$v) {
                      _vm.forUser = $$v
                    },
                    expression: "forUser",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-7" }, [
              _vm._v(
                "\n            Wiki will belong to selected user. You will be that Wiki's admin.\n        "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.forUser
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedUser,
                      expression: "selectedUser",
                    },
                  ],
                  staticClass: "form-control",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedUser = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.dependents, function (dependent) {
                  return _c(
                    "option",
                    { key: dependent.id, domProps: { value: dependent } },
                    [_vm._v(_vm._s(dependent.name) + "\n                ")]
                  )
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-green",
            attrs: { disabled: !_vm.allowCreate },
            on: { click: _vm.createWiki },
          },
          [_vm._v("Create Wiki")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }