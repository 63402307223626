var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.wrapperComponent,
    _vm._g(
      _vm._b(
        { tag: "component", attrs: { to: _vm.to } },
        "component",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }