import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Vue from "vue";

Vue.use(VueLoading, {
        // props
        color: '#fff',
        backgroundColor: '#000',
        loader: 'spinner',
        opacity: 0.85,
        width: 94,
        height: 94,
    },
//     {
//     get default() {
//         return new Vue().$createElement('i', {
//             class: ['fad', 'fa-spinner', 'fa-spin'],
//             style: {color: '#fff', 'font-size': '94px'}
//         });
//     },
// }
);

let ACTIVE_LOADERS = [];

let loader = undefined;

const showLoader = (name = 'default', options = {}) => {
    // show loader only if not already active;
    ACTIVE_LOADERS.push(name);
    if (loader === undefined) {
        loader = Vue.$loading.show(options);
    }
};

const hideLoader = (name = 'default') => {
    ACTIVE_LOADERS = ACTIVE_LOADERS.filter(l => l !== name);
    if (ACTIVE_LOADERS.length === 0) {
        if (loader !== undefined) {
            loader.hide();
            loader = undefined;
        }
    }
};

const VueLoadingSpinner = {
    install(vue, options) {
        vue.loadingSpinner = {
            show: showLoader,
            hide: hideLoader,
        };
        vue.prototype.$loadingSpinner = {
            show: showLoader,
            hide: hideLoader,
        };
    }
};

export default VueLoadingSpinner;