var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item
    ? _c(
        "div",
        { staticClass: "text-center media-renderer-inline" },
        [
          _c(
            _vm.componentType,
            _vm._b(
              {
                ref: "mediaComponent",
                tag: "component",
                attrs: {
                  item: _vm.item,
                  autoplay: _vm.autoplay,
                  "max-height": _vm.maxHeight,
                },
              },
              "component",
              _vm.$attrs,
              false
            )
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }