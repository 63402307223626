<template>
    <div :id="`comment-${comment.id}`" class="chat-style-comment-component">
        <div class="row">
            <div class="col-xs-12">
                <div class="chat-style-comment-component__comment">
                    <div class="chat-style-comment-component__avatar-part"
                         :class="{'chat-style-comment-component__avatar-part_right':moveAvatarToRight}">
                        <a :href="author.link" v-if="author.link" :aria-label="author.name" tabindex="-1">
                            <img :src="author.avatar"
                                 class="img-circle chat-style-comment-component__avatar">
                        </a>
                        <span v-else>
                            <img :src="author.avatar"
                                 :alt="author.name"
                                 class="img-circle chat-style-comment-component__avatar">
                        </span>
                    </div>
                    <div class="chat-style-comment-component__content-part">

                        <div class="chat-style-comment-component__author-and-time chat-style-comment-component__author-and-time_right"
                             v-if="moveAvatarToRight">
                           <span class="chat-style-comment-component__approval-message"
                                 v-if="!comment.approved"><br><i>Waiting for approval.</i></span>
                            <span class="chat-style-comment-component__created-time">({{
                                    comment.created_at | GBDateTime
                                }})</span>
                            <strong>
                                <simple-link :target="author" v-if="author.link"></simple-link>
                                <span v-else>{{ author.name }}</span>
                            </strong>
                        </div>
                        <div class="chat-style-comment-component__author-and-time" v-else>
                            <strong>
                                <simple-link :target="author" v-if="author.link"></simple-link>
                                <span v-else>{{ author.name }}</span>
                            </strong>
                            <span class="chat-style-comment-component__created-time">({{
                                    comment.created_at | GBDateTime
                                }})</span>
                            <span class="chat-style-comment-component__approval-message"
                                  v-if="!comment.approved"><br><i>Waiting for approval.</i></span>
                        </div>
                        <div class="chat-style-comment-component__content"
                             :class="{
                            'chat-style-comment-component__content_my': moveAvatarToRight,
                            'chat-style-comment-component__content_new': comment.new,
                            'chat-style-comment-component__content_selected-highlight': activeClass,
                            'chat-style-comment-component__content_selected-active': isActive,
                        }"
                        >
                            <slot name="postContent">
                                <div class="row">
                                    <div class="col-sm-12" style="">
                                        <chat-style-comment-linked-blog-entry
                                                v-if="comment.linked_blog_entry"
                                                :comment="comment"
                                                :moveAvatarToRight="moveAvatarToRight"
                                                :blog-entry="comment.linked_blog_entry"
                                        ></chat-style-comment-linked-blog-entry>
                                        <chat-style-comment-linked-user-activity-post
                                                v-else-if="comment.linked_user_activity_post"
                                                :comment="comment"
                                                :moveAvatarToRight="moveAvatarToRight"
                                                :user-activity-post="comment.linked_user_activity_post"
                                        ></chat-style-comment-linked-user-activity-post>
                                        <chat-style-comment-linked-blog-answer
                                                v-else-if="comment.linked_blog_answer"
                                                :comment="comment"
                                                :blog-answer="comment.linked_blog_answer"
                                                :moveAvatarToRight="moveAvatarToRight"
                                        ></chat-style-comment-linked-blog-answer>
                                        <chat-style-comment-linked-wiki-slide
                                                v-else-if="comment.linked_wiki_slide"
                                                :comment="comment"
                                                :wiki-slide="comment.linked_wiki_slide"
                                                :moveAvatarToRight="moveAvatarToRight"
                                        ></chat-style-comment-linked-wiki-slide>
                                        <render-post
                                                v-else
                                                :post="comment"
                                                style="max-height: 100%;padding: 0 15px;"
                                                max-media-height="500px"
                                                add-border-around-text="WITH_OTHER_CONTENT"
                                        ></render-post>
                                    </div>
                                </div>
                                <slot name="reply"></slot>
                            </slot>
                        </div>
                        <div class="chat-style-comment-component__bottom-panel">
                            <slot name="bottomPanel"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import RenderPost from "shared/render_post/RenderPost";
    import ChatStyleCommentLinkedBlogEntry from "shared/comments/ChatStyleCommentLinkedBlogEntry";
    import ChatStyleCommentLinkedBlogAnswer from "shared/comments/ChatStyleCommentLinkedBlogAnswer";
    import ChatStyleCommentLinkedUserActivityPost from "shared/comments/ChatStyleCommentLinkedUserActivityPost";
    import ChatStyleCommentLinkedWikiSlide from "shared/comments/ChatStyleCommentLinkedWikiSlide";

    export default {
        name: "ChatStyleComment",
        components: {
            ChatStyleCommentLinkedWikiSlide,
            ChatStyleCommentLinkedUserActivityPost,
            ChatStyleCommentLinkedBlogAnswer, ChatStyleCommentLinkedBlogEntry, RenderPost
        },
        props: {
            comment: Object,
            isCommentRightSide: {
                type: Function,
                default: () => false,
            },
            author: {
                Object,
                default() {
                    return {
                        name: 'Anonymous User',
                        link: null,
                        avatar: ''
                    };
                }
            },
            selectedHighLight: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                isActive: false,
                activeClass: false,
            };

        },
        computed: {
            authorLink() {
                if (this.comment.userprofile) {
                    return this.comment.userprofile.link;
                }
            },
            authorAvatar() {
                if (this.comment.userprofile) {
                    return this.comment.userprofile.avatar;
                }
            },
            moveAvatarToRight() {
                return this.isCommentRightSide(this.comment);
            },
        },
        methods: {
            $_processScrollEvent(activityId) {
                if (this.activity.id === activityId) {
                    if (this.$el.scrollIntoView) {
                        this.$nextTick(() => {
                            this.$el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
                        });
                    }
                    this.activeClass = true;
                    this.isActive = true;
                    setTimeout(() => {
                        this.activeClass = false;
                    }, 8000);
                } else {
                    if (this.activeClass) {
                        this.activeClass = false;
                        this.isActive = false;
                    }
                }
            },
        },
        watch: {
            selectedHighLight(n) {
                if (n) {
                    if (this.$el.scrollIntoView) {
                        this.$nextTick(() => {
                            this.$el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
                        });
                    }
                    this.activeClass = true;
                    this.isActive = true;
                    setTimeout(() => {
                        this.activeClass = false;
                    }, 8000);
                } else {
                    this.activeClass = false;
                    this.isActive = false;
                }
            }
        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";

  .chat-style-comment-component {
    margin-bottom: 15px;

    &__comment {
      font-size: 0;
    }

    &__author-and-time {
      &_right {
        text-align: right;
      }
    }

    &__created-time {
      font-size: 12px;
    }

    &__avatar-part {
      display: inline-block;
      width: 45px;
      vertical-align: top;
      @media (min-width: 500px) {
        width: 60px;
      }

      &_right {
        float: right;
      }
    }

    &__avatar {
      width: 40px;
      height: 40px;
      @media (min-width: 500px) {
        width: 50px;
        height: 50px;
      }
    }

    &__content-part {
      width: calc(100% - 50px);
      @media (min-width: 500px) {
        width: calc(100% - 65px);
      }
      vertical-align: top;
      display: inline-block;
      font-size: 14px;
    }

    &__content {
      &_new {
        background-color: #fff1b8 !important;
        color: #333 !important;
      }

      &_selected-highlight {
        box-shadow: 0 0 3px 3px rgba(11, 149, 255, 1);
      }

      &_selected-active {
        border: solid 1px #53acf6;
      }

      border-radius: 0 10px 10px 10px;

      &_my {
        border-radius: 10px 0 10px 10px;
        background-color: #2993fa;
        color: white;
      }

      @media print {
        border: 1px solid #333;
      }
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      transition: background-color 1s ease, color 1s ease, box-shadow 0.3s ease-in-out;
      background-color: #fff;
      color: #333;
      padding-bottom: 10px;
      padding-top: 10px;

      a {
        color: white;
        font-weight: bold;
        text-decoration: underline;
      }
    }

    &__bottom-panel {

    }
  }

</style>