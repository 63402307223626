/**
 * Created by orion on 05.03.17.
 */

import angular from 'angular';
import ngSanitize from 'angular-sanitize';
import ngTouch from 'angular-touch';
import ngCookies from 'angular-cookies';
import 'angular-ui-bootstrap';
import 'angular-ui-switch';
import 'angular-ui-switch/angular-ui-switch.css';

import "angular-file-upload";

import preload from './preload/preload';

import mmAppConfig from './mmApp.config';
import addDimmersToRootScope from './add_dimmers';

//shared modules
import simplePopups from './shared/simple_popups/simple_popup_module';
import notifications from './shared/notifications/notifications_module';
import folderButton from './shared/folder_button_directive/folder_button_directive';
import noTouchMove from './shared/drag_n_drop/noTouchMoveIphoneHack';
import notificationCounter from './shared/notifications_counter';
import stuff_previews from './shared/stuff_preview_directives/stuff_preview_module';
import storyItemWrapper from './shared/storyItemWrapper/storyItemWrapper';
import storyList from './shared/story/story_list_directive/storyList';
import storyHeader from './shared/story/story_header/directive';
import focusOn from './shared/focusOn';
import compileDirective from './shared/compile';
import CurrentUser from './shared/CurrentUser';
import joinRequest from './shared/join_request_directive/joinRequest';
import shared_forms_module from './shared/forms/forms_module';
import MMCommon from './shared/common/MMCommon';
import visibilityDetector from "./visibilityDetector";

//app modules
import add_media from './add_media/add_media_module';
import view_media from './view_media/view_media_module';
import admin_notification from './admin_notification/admin_notification_module';
import blogolog from './blogolog/blogolog_module';
import mmApp_maps from './maps_module/maps_module';
import headerDirective from './shared/headers/header_directive/header_directive';
// import comments from './shared/comments/comments_module';
import renderPost from './shared/render_post/renderPostDirective';
import renderQuoteDirective from './shared/quote_directive/quote_directive_module';
import activity_module from './activity/activity_module';
import leftPanel from './shared/left_panel/left_panel_module';
import activityPost from './shared/activity_post/activity_post_module';
import registrationForm from './shared/registration_form_directive/directive';
import session_expire from './session_expire_handler/session_expiration_module';
import gdpr_module from './gdpr_handler/gdpr_module';
import LogoutConfirmationController from './logout_confirmation_controller';
import reg_module from './reg/reg_module';
import wsEventsHandlers from "./ws_events_processor";

// mainFilters
import main_filters from './shared/filters/filters_module';

// pages
import folders_page from './pages/folders/my_folders_module';
// import conversations_page from './conversations/conversations_module';
import userprofile_page from './userprofile/userprofile_module';
import communities_module from './communities/communities_module';
import groups_module from './groups/groups_module';
import story_module from './stories/story_module';
import collections_module from './collections/collections_module';
import events_module from './events/events_module';
import tasks_module from './tasks/tasks_module';
import siteHeaderController from './site_header/headerController';
import siteFooterController from './site_footer/footerController';

import DashboardController from './dashboard/DashboardController';

let mmApp = angular.module('mmApp',
    [ngTouch, ngSanitize, ngCookies, 'uiSwitch', 'ui.bootstrap', session_expire,
        preload, 'angularFileUpload', simplePopups, notifications, add_media, view_media, mmApp_maps,
        folders_page, main_filters, admin_notification, blogolog, folderButton, noTouchMove,
        notificationCounter, stuff_previews, storyItemWrapper, storyList, storyHeader, userprofile_page,
        communities_module, tasks_module, groups_module, story_module, events_module, collections_module,
        headerDirective, focusOn, renderPost, renderQuoteDirective, activity_module, compileDirective,
        CurrentUser, joinRequest, leftPanel, shared_forms_module, activityPost, registrationForm, reg_module, gdpr_module])
    .controller('DashboardController', DashboardController)
    .controller('siteHeaderController', siteHeaderController)
    .controller('siteFooterController', siteFooterController)
    .controller('LogoutConfirmationController', LogoutConfirmationController)

    .factory('MMCommon', MMCommon)
    .run(['SessionHandler', (SessionHandler) => {
    }])
    .run(['GDPRPopupService', (GDPRPopupService) => {
    }]);
mmAppConfig(mmApp);

mmApp.run(['$rootScope', '$timeout', addDimmersToRootScope]);
mmApp.run(wsEventsHandlers);
mmApp.run(visibilityDetector);
import Vue from 'vue';
import GDPRPopupService from "./gdpr_handler/GDPRPopupService";


mmApp.run(['$filter', $filter => {
    Vue.filter('gbdate', date => $filter('date')(date, 'dd/MM/yyyy'));
}]);
mmApp.run(['$filter', $filter => {
    Vue.filter('GBDateTime', date => $filter('date')(date, 'dd/MM/yyyy h:mm a'));
}]);
mmApp.run(['$filter', $filter => {
    Vue.filter('gbtime', date => $filter('date')(date, 'h:mm a'));
}]);
mmApp.run(['$filter', $filter => {
    Vue.filter('activityDate', date => $filter('date')(date, 'd MMM y, h:mm:ss a'));
}]);
mmApp.run(['notifications', notifications => {
    let NotificationBusPlugin = {};
    NotificationBusPlugin.install = function (vue, options) {
        vue.notifications = notifications;
        vue.prototype.$notifications = notifications;
    };
    Vue.use(NotificationBusPlugin);
}]);

mmApp.run(['$injector', $injector => {
    let InjectorPluginPlugin = {};
    InjectorPluginPlugin.install = function (vue, options) {
        vue.injector = $injector;
        vue.getAngularModule = function (moduleName) {
            return vue.injector.get(moduleName);
        };
    };
    Vue.use(InjectorPluginPlugin);
}]);

mmApp.config(['$compileProvider', function ($compileProvider) {
    $compileProvider.debugInfoEnabled(DEBUG_INFO_ENABLED);
}]);

mmApp.directive('myEnter', function () {
    return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
            if (event.which === 13) {
                scope.$apply(function () {
                    scope.$eval(attrs.myEnter);
                });

                event.preventDefault();
            }
        });
    };
});

export default mmApp;
