/**
 * Created by orion on 24.05.17.
 */
angular.module('mmApp.notificationsCounter', [])
    .directive("notificationsCounter", ['$rootScope', '$log', function ($rootScope, $log) {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                initial: '@',
                event: '@',
                increaseEvent: '@',
            },
            template: `<span class="icon-counter" ng-show="unactioned_count>0">{$ unactioned_count $}</span>`,
            link: function ($scope, element, attrs, controller) {
                $scope.unactioned_count = $scope.initial || 0;

                $scope.$on($scope.event, function (event, count) {
                    $scope.unactioned_count = count;
                });

                $scope.$on($scope.increaseEvent, function (event) {
                    $scope.$apply(() => {
                        $scope.unactioned_count = $scope.unactioned_count * 1 + 1;
                    });
                });
            }
        };
    }
    ]);
export default 'mmApp.notificationsCounter';