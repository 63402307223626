import { render, staticRenderFns } from "./RectangleStuffPreview.vue?vue&type=template&id=3540c305&scoped=true&"
import script from "./RectangleStuffPreview.vue?vue&type=script&lang=js&"
export * from "./RectangleStuffPreview.vue?vue&type=script&lang=js&"
import style0 from "./RectangleStuffPreview.vue?vue&type=style&index=0&id=3540c305&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3540c305",
  null
  
)

export default component.exports