var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    !_vm.allUsersSelected
      ? _c(
          "span",
          [
            _vm.usersList.length
              ? _c("span", [
                  _vm._v(
                    "\n            Entry will be added to the following Diaries: "
                  ),
                  _c("br"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.usersList, function (user, i) {
              return _c("span", [
                _c(
                  "strong",
                  [_c("simple-link", { attrs: { target: user } })],
                  1
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: i !== _vm.usersList.length - 1,
                        expression: "i!==(usersList.length-1)",
                      },
                    ],
                  },
                  [_vm._v(", ")]
                ),
              ])
            }),
          ],
          2
        )
      : _c("span", [
          _vm._v(
            "\n         Entry will be added to all Supported Users Diaries.\n    "
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }