/**
 * Created by orion on 07.03.17.
 */
import NotificationModule from "angular-ui-notification";
import 'angular-ui-notification/dist/angular-ui-notification.css';
import angular from 'angular';
import _ from 'lodash';

angular.module('mmApp.notifications', [NotificationModule])
    .factory('notifications', ['$log', '$templateCache', 'Notification', function message($log, $templateCache, Notification) {
        $templateCache.put('angular-ui-notification.html', `
        <div class="ui-notification">
            <h3 ng-show="title" ng-bind-html="title"></h3>
            <div class="message" ng-bind-html="message" role="status"></div>
        </div>
        `);
        return {
            success: function (message, duration = 2500, container = 'body') {
                Notification.success(
                    {
                        message,
                        delay: duration,
                        container,
                        templateUrl: 'angular-ui-notification.html',
                    }
                );
            },
            error: function (message="Error", duration = 2500, container = 'body') {
                let _message = '';
                if (message?.response) {
                    message = message.response; // axios fix
                }
                if (message instanceof Error) {
                    message = message.name + ': ' + message.message;
                }
                if (_.isObject(message) && message.data) {
                    message = message.data;
                }
                if (_.isObject(message)) {
                    if (_.isArray(message)) {
                        for (const val of message) {
                            _message += val;
                        }
                    } else {
                        for (const field of Object.values(message)) {
                            _message += field;
                        }
                    }

                } else {
                    _message = message;
                }
                if ((_message?.length > 3000) || (_message === undefined)) {
                    _message = 'Error! Refresh page and try again please. Contact us if the error persists';
                }
                Notification.error(
                    {
                        message: _message,
                        delay: duration
                    }
                );
            },
            warning: function (message, duration = 2500, container = 'body') {
                Notification.warning(
                    {
                        message,
                        delay: duration
                    }
                );
            },
            message: function (message, duration = 2500, container = 'body') {
                Notification.primary(
                    {
                        message,
                        delay: duration
                    }
                );
            },
            Notification
        };
    }]);

export default 'mmApp.notifications';
