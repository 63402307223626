import _ from 'lodash';

function mediaContentUtils() {

    const DEFAULT_AVATAR_URL = '/static/images/default-book.png';
    const DEFAULT_AVATAR_ICON = '/static/images/icons/icon-stories.png';


    return {
        get_img_url: get_img_url,
        get_background_style: get_background_style,
        get_background_style_contain: get_background_style_contain,
        generate_background: generate_background,
        is_media_encoding_processing_now: is_media_encoding_processing_now,
        generate_folder_background: generate_folder_background,
        generate_group_background: generate_group_background,
        generate_rectangle_background_from_content_samples: generate_rectangle_background_from_content_samples,
        get_fa_icon_class,
        getAudioUrlorUndefinedFromMediaContent
    };

    function generate_rectangle_background_from_content_samples(content_samples) {
        if (content_samples.length === 1) {
            return {
                'background-image': `url('${content_samples[0]}')`,
                'background-size': 'cover',
                'background-repeat': 'no-repeat'
            };
        }
        if (content_samples.length === 2) {
            return {
                'background-image': `url('${content_samples[1]}'), url('${content_samples[0]}')`,
                'background-size': '50%, 100%',
                'background-position': '50% 50%, 50% 50%',
                'background-repeat': 'no-repeat'
            };
        }
    }

    function get_fa_icon_class(media_content) {
        if (!media_content) {
            return 'fa-question-circle';
        }
        if (media_content.type === 'upl_video') {
            return 'fa-video-camera';
        }
        if (media_content.type === 'uploaded_audio' || media_content.type === 'recorded_audio') {
            return 'fa-volume-up';
        }
        if (media_content.type === 'uploaded_document') {
            return 'fa-file-text-o';
        }
        return 'fa-question-circle';
    }

    function getAudioUrlorUndefinedFromMediaContent(media_content) {
        if (!media_content) {
            return;
        }
        if (media_content.uploaded_audio) {
            if (media_content.uploaded_audio.audio_url) {
                return media_content.uploaded_audio.audio_url;
            }
        }
        if (media_content.recorded_audio) {
            if (media_content.recorded_audio.audio_url) {
                return media_content.recorded_audio.audio_url;
            }
        }
    }

    function generate_bg_image_url(media_content, full_size) {
        /*
         @param media_content @type: Object
         @param full_size @type: boolean
         @returns url @type: string
         */
        if (_.isString(media_content)) {
            return media_content;
        }
        if (media_content.type === 'upl_video' && media_content.upl_video.encoding_done) {
            return media_content.upl_video.preview_url;
        }
        if (media_content.type === 'link' && media_content.link.image_url) {
            return media_content.link.image_url;
        }
        if (media_content.type === 'image') {
            return full_size === undefined ? media_content.image.thumbnail_url : media_content.image.image_url;
        }
        if (media_content.type === 'youtube_video') {
            let preview = media_content.youtube_video.preview_url;
            if (full_size) {
                preview = preview.replace('mqdefault.jpg', 'hqdefault.jpg');
            }
            return preview;
        }
        if (media_content.type === "vimeo_video") {
            return media_content.vimeo_video.preview_url;
        }

    }

    function generate_group_background(default_avatar, media_previews) {
        if (!media_previews || !media_previews.length) {
            return {
                'background-image': `url('${default_avatar}')`,
                'background-size': 'cover',
                'background-repeat': 'no-repeat',
                'background-position': 'center'
            };
        } else {
            switch (media_previews.length) {
                case 1:
                    return {
                        'background-image': `url('${media_previews[0]}')`,
                        'background-size': 'cover',
                    };
                case 4:
                    return {
                        'background-image': 'url(' + media_previews[0] + '),' +
                            'url(' + media_previews[1] + '),' +
                            'url(' + media_previews[2] + '),' +
                            'url(' + media_previews[3] + ')',
                        'background-position': '0 0,  0 102%, 102% 0, 102% 102%',
                        'background-size': '54%',
                        'background-repeat': 'no-repeat'
                    };
                case 3:
                    return {
                        'background-image':
                            'url(' + media_previews[1] + '),' +
                            'url(' + media_previews[2] + '),' + 'url(' + media_previews[0] + ')',
                        'background-position': '0 102%, 102% 102%, 0 0',
                        'background-size': '54%, 54%, cover',
                        'background-repeat': 'no-repeat'
                    };
                case 2:
                    return {
                        'background-image': 'url(' + media_previews[0] + '),' +
                            'url(' + media_previews[1] + ')',
                        'background-position': '0 0, 102% 102%',
                        'background-size': '54%',
                        'background-repeat': 'no-repeat'
                    };
                default: // 3 or 2
                    return {
                        'background-image': `url('${media_previews[0]}')`,
                        'background-size': '100%',
                    };
            }
        }
    }

    function generate_folder_background(folder) {
        if (folder.media_content_samples.length) {
            if (folder.media_content_samples.length === 3) {
                return {
                    'background-image': 'url(' + generate_bg_image_url(folder.media_content_samples[0]) + '),' +
                        'url(' + generate_bg_image_url(folder.media_content_samples[1]) + '),' +
                        'url(' + generate_bg_image_url(folder.media_content_samples[2]) + ')',
                    'background-position': 'center, top left, bottom right',
                    'background-size': '70%',
                    'background-repeat': 'no-repeat'
                };
            }
            if (folder.media_content_samples.length === 2) {
                return {
                    'background-image': 'url(' + generate_bg_image_url(folder.media_content_samples[0]) + '),' +
                        'url(' + generate_bg_image_url(folder.media_content_samples[1]) + ')',
                    'background-position': 'top left, bottom right',
                    'background-size': '80%',
                    'background-repeat': 'no-repeat'
                };
            }
            if (folder.media_content_samples.length === 1) {
                return {
                    'background-image': 'url(' + generate_bg_image_url(folder.media_content_samples[0]) + ')',
                    'background-position': 'center',
                    'background-size': '85%',
                    'background-repeat': 'no-repeat'
                };
            }
        } else {
            return {};
        }
    }


    function is_media_encoding_processing_now(media_content) {
        return media_content &&
            (
                (media_content.type === 'upl_video' && !media_content.upl_video.encoding_done) || (media_content.type === 'recorded_audio' && !media_content.recorded_audio.encoding_done)
            );
    }

    function generate_background(media_content) {
        if (!media_content || !media_content.type) {
            return {};
        }

        if ((media_content.type === 'upl_video' && media_content.upl_video.encoding_done) || media_content.type === 'image' || media_content.type === 'youtube_video' || media_content.type === 'vimeo_video' || (media_content.type === 'link' && media_content.link.image_url)) {
            return {
                'background-image': 'url(' + generate_bg_image_url(media_content) + ')',
                'background-position': 'center',
                'background-size': 'cover',
                'background-repeat': 'no-repeat'
            };
        }
        if (media_content.type === 'folder') {
            return generate_folder_background(media_content.folder);
        }
        return {};

    }

    function get_background_style(url, cover) {
        cover = cover === undefined;
        return {
            'background-image': 'url(' + (url || DEFAULT_AVATAR_ICON) + ')',
            'background-position': 'center',
            'background-size': (cover ? 'cover' : '102%'),
            'background-repeat': 'no-repeat'
        };
    }

    function get_background_style_contain(url) {
        return {
            'background-image': 'url(' + (url || DEFAULT_AVATAR_ICON) + ')',
            'background-position': 'center',
            'background-size': 'contain',
            'background-repeat': 'no-repeat'
        };
    }

    function get_img_url(media_content) {
        if (!media_content || !media_content.type) {
            // for story
            return;
        }
        return generate_bg_image_url(media_content);
    }
}

export default mediaContentUtils;
