<template>
    <div>
        <vue-simple-header>
            <span slot="headerTitle">Calendars list</span>
            <div class="" slot="panelRight">
                <vue-search-input @search="onSearch" @cancel="onSearchCancel"></vue-search-input>
            </div>
        </vue-simple-header>
        <my-stuff-list-my-item
                v-show="!query"
                name="My Calendar"
                image-url="/static/images/new_daschboard_icons/calendar_600_1.png"
                :link="myCalendarHref"
                :router-navigation="true"
        ></my-stuff-list-my-item>
        <div class="row" v-show="users.length">
            <div class="col-sm-12 list_header" style="font-size: 21px; font-weight: bolder;">
                Calendars I manage <span>({{ totalCount }})</span>
            </div>
        </div>
        <div class="row" v-infinite-scroll="loadMore">
            <div class="col-sm-12">
                <event-users-calendar :user="user" v-for="user in users" :key="user.id"></event-users-calendar>
            </div>
        </div>
        <div class="row text-center" v-show="allLoaded&&users.length===0">
            <div class="col-sm-12">
                <span style="font-size: 20px">No results...</span>
            </div>
        </div>
        <div class="text-center" v-if="loading"><span>Loading...</span></div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import EventUsersCalendar from "./EventUsersCalendar";
    import MyStuffListMyItem from "../../../vue/common_components/MyStuffListMyItem";
    import InfiniteScrollMixin from "../../../vue/mixins/InfiniteScrollMixin";
    import {DjangoUrls} from 'DjangoUrls';
    import {CurrentUserClass} from "../shared/CurrentUser";


    export default {
        name: "ManagedCalendarsList",
        components: {EventUsersCalendar, MyStuffListMyItem},
        mixins: [InfiniteScrollMixin],
        data() {
            return {
                limit: 20
            };
        },
        computed: {
            myCalendarHref() {
                return DjangoUrls['users_calendar']('my') + '?fl';
            },
            users() {
                return this.results;
            },
        },
        methods: {
            getListEndpointArgs() {
                return [];
            },
            getListEndpoint() {
                const CalendarListResource = Vue.getAngularModule('CalendarListResource');
                return CalendarListResource.get_calendars_i_manage;
            }
        },
        metaInfo() {
            return {title: 'Calendars'};
        }
    };
</script>

<style scoped>

</style>