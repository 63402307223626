<template>
    <div class="">
        <GmapMap
                ref="gMap"
                :center="{lat:location.latitude, lng:location.longitude}"
                :zoom="location.zoom"
                :style="mapStyle"
                :options="mapOptions"
        >
            <GmapMarker
                    :key="1"
                    :position="{lat:location.latitude, lng:location.longitude}"
                    :clickable="false"
                    :draggable="false"
            />
        </GmapMap>
    </div>
</template>

<script>
    export default {
        name: "LocationWithMarker",
        props: {
            location: {
                type: Object,
                required: true
            }
        },
        computed: {
            mapOptions() {
                return {
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                    disableDefaultUi: false
                };
            },
            mapStyle() {
                // this.windowWidth
                return {
                    width: `100%`,
                    height: `300px`
                };
            },
        }
    };
</script>

<style scoped>

</style>