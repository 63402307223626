<template>
    <div class="col-md-2 folder_filters_panel" :class="{'blocked-for-deletion':blocked}">
        <users-media-page-filter-panel-button
                v-for="button in filterButtons"
                :key="button.text"
                :icon="button.icon"
                :is-active="filter.type==button.value"
                @click.native="set_filter(button.value)"
                :text="button.text"
        ></users-media-page-filter-panel-button>
        <users-media-page-filter-panel-button
                v-if="isMyMedia&&isRootFolder"
                icon="fa-folder-open-o"
                :is-active="showShared"
                @click.native="changeSharedMode"
                text="Shared"
        ></users-media-page-filter-panel-button>
    </div>
</template>

<script>
    import UsersMediaPageFilterPanelButton from './UsersMediaPageFilterPanelButton.vue';

    export default {
        name: "UsersMediaPageFilterPanel",
        components: {UsersMediaPageFilterPanelButton},
        props: ['filter', 'isMyMedia', 'isRootFolder', 'massActionsState','noMedia'],
        data() {
            return {
                sharedFilterOpened: false,
                showShared: true,
                filterButtons: [
                    {text: 'All', icon: 'fa-asterisk', value: undefined},
                    {text: 'Sounds', icon: 'fa-volume-up', value: 'audio'},
                    {text: 'Photos', icon: 'fa-camera-retro', value: 'image'},
                    {text: 'Docs', icon: 'fa-file-text-o', value: 'document'},
                    {text: 'Videos', icon: 'fa-video-camera', value: 'video'},
                ]
            };
        },
        computed: {
            blocked() {
                return this.massActionsState.deleteMode || this.massActionsState.moveMode || this.noMedia;
            },
        },
        methods: {
            set_filter(val) {
                if (this.blocked) {
                    return;
                }
                this.$emit('setFilter', val);
            },
            changeSharedMode(val) {
                if (this.blocked) {
                    return;
                }
                this.showShared = !this.showShared;
                this.$emit('changeSharedMode', this.showShared);
            },
            showSharedFilter() {

            }
        },
        created() {
            this.showShared = this.filter.shared;
        }
    };
</script>

<style scoped lang="scss">
    @import "../../../styles/const";

    .folder_filters_panel {
        &.blocked-for-deletion {
            @media (max-width: 499px) {
                display: none;
            }
            @media (min-width: 500px) {
                opacity: 0.5;
                cursor: not-allowed !important;
            }
        }
        @media (min-width: $screen-md-min) {
            padding-right: 0;
        }
        @media (max-width: $screen-xs-max) {
            margin-bottom: 10px;
        }
    }


</style>