export default {
    props:
        ['currentlyDraggedMedia',
            'currentlyUnderDragFolder',]
    ,
    data() {
        return {
            dragOverMe: false,
            autoBindMouseEvents: false
        };
    },
    computed: {
        dragStyle() {
            const style = {};
            if (this.currentlyDraggedMedia) {
                if (this.isPossibleDragTarget) {
                    const shadow = '0px 0px 8px 0px rgba(0,179,238,0.75)';
                    style['-webkit-box-shadow'] = shadow;
                    style['-moz-box-shadow'] = shadow;
                    style['box-shadow'] = shadow;
                } else {
                    style['opacity'] = 0.5;
                }

            }
            if (this.dragOverMe && this.isPossibleDragTarget) {
                style['color'] = 'white';
                style['background-color'] = 'rgb(0,179,238)';
            }

            return style;
        },
        isPossibleDragTarget() {
            return this.folder.is_editable_by_viewer;
        }
    },
    methods: {
        onPointerMove(pointerCoords) {
            if (!this.currentlyDraggedMedia) {
                return;
            }
            this.checkIfDragOver(pointerCoords);
        },
        checkIfDragOver(pointerCoords) {
            const myCoords = this.$el.getBoundingClientRect();
            if (myCoords.x < pointerCoords.x && (myCoords.x + myCoords.width) > pointerCoords.x && myCoords.y < pointerCoords.y && (myCoords.y + myCoords.height) > pointerCoords.y) {
                this.dragOverMe = true;
                if (this.currentlyUnderDragFolder !== this.folder) {
                    this.$emit('setCurrentlyUnderDragFolder', this.folder);
                }
            } else {
                this.dragOverMe = false;
                if (this.currentlyUnderDragFolder === this.folder) {
                    this.$emit('resetCurrentlyUnderDragFolder');
                }
            }
        },
        onMouseOut() {
        },
        onMouseUp() {
        }
    },
    watch: {
        currentlyDraggedMedia(val) {
            if (val) {
                this.bindListeners();
            } else {
                this.dragOverMe = false;
                this.$nextTick(() => {
                    this.unBindListeners();
                });
            }
        }
    }
};