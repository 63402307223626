import * as _ from "lodash";
import VisibilityChangesAcceptPopup from './visibility_changes_accept_popup/VisibilityChangesAcceptPopup.vue';
import SetFirstLastNamePopup from "./SetFirstLastNamePopup.vue";
import AddProfileInfoRequestPopup from "./AddProfileInfoRequestPopup.vue";
import ImprovePasswordWarning from "./ImprovePasswordWarning.vue";
import GDPRPopup from "./GDPRPopup.vue";
import {simplePopupFactory} from "shared/simple_popups/simplePopupFactory";


class GDPRPopupService {
    constructor($rootScope, $cookies, $timeout, $interval, CurrentUser, notifications, simplePopupFactory, UserProfileResource, UserProfileSettingsResource, addMedia) {
        this.simplePopupFactory = simplePopupFactory;
        this.CurrentUser = CurrentUser;
        this.$timeout = $timeout;
        this.notifications = notifications;
        this.$rootScope = $rootScope;
        this.addMedia = addMedia;
        this.UserProfileResource = UserProfileResource;
        this.UserProfileSettingsResource = UserProfileSettingsResource;

        let showPolicyPopup = false;
        let showVisibilityChangesPopup = false;
        let showAddNamePopup = false;
        let showAddAvatarPopup = false;
        let showAddProfileInfoPopup = false;
        let showWeakPasswordPopup = false;

        if (this.CurrentUser.is_authenticated()) {
            if (!this.CurrentUser.is_policy_signed) {
                showPolicyPopup = true;
            }
            if (!this.CurrentUser.visibility_changes_signed && !this.CurrentUser.is_circle_user()) {
                showVisibilityChangesPopup = true;
            }
            if (!this.CurrentUser.first_and_last_name_set_postponed && !this.CurrentUser.first_name && !this.CurrentUser.last_name) {
                showAddNamePopup = true;
            }
            if (!this.CurrentUser.add_avatar_postponed && (this.CurrentUser.avatar.indexOf('/static/') !== -1)) {
                showAddAvatarPopup = true;
            }
            if (!this.CurrentUser.fill_profile_story_postponed && this.CurrentUser.is_my_story_empty && (document.location.pathname !== '/users/me/profile')) {
                showAddProfileInfoPopup = true;
            }
            if (this.CurrentUser.password_marked_as_weak && !this.CurrentUser.weak_password_reminder_postponed && (document.location.pathname !== '/users/me/settings')) {
                showWeakPasswordPopup = true;
            }
        }

        const popupsCallers = [this.showPolicyPopup, this.showVisibilityChangesPopup, this.showAddNamePopup, this.showAddAvatarPopup, this.showFillProfileStoryRequestPopup, this.showImprovePasswordWarningPopup];
        const popupRequiredMarks = [showPolicyPopup, showVisibilityChangesPopup, showAddNamePopup, showAddAvatarPopup, showAddProfileInfoPopup, showWeakPasswordPopup];

        this.checkIfShowPopupRequired = (fromIndex = 0) => {
            for (let i = fromIndex; i < popupRequiredMarks.length; i++) {
                if (popupRequiredMarks[i]) {
                    popupsCallers[i].call(this);
                    break;
                }
            }
        };
        this.checkIfShowPopupRequired();

        //
        // if (showPolicyPopup) {
        //     this.showPolicyPopup();
        // } else if (showVisibilityChangesPopup) {
        //     this.showVisibilityChangesPopup();
        // } else if (showAddNamePopup) {
        //     this.showAddNamePopup();
        // } else if (showAddProfileInfoPopup) {
        //     this.showFillProfileStoryRequestPopup();
        // }

    }

    showVisibilityChangesPopup() {
        const {vm, popupComponent} = this.simplePopupFactory.mount_vue_popup(VisibilityChangesAcceptPopup);
        popupComponent.show(this.CurrentUser.id, !this.CurrentUser.is_searchable).finally(() => {
            this.signVisibilityChanges();
            if (vm) {
                vm.$destroy();
            }
            this.checkIfShowPopupRequired(2);
        });
    }

    showImprovePasswordWarningPopup() {
        const {vm, popupComponent} = this.simplePopupFactory.mount_vue_popup(ImprovePasswordWarning);
        popupComponent.show()
            .then(res => {
                if (res === 'postpone') {
                    return this.UserProfileSettingsResource.postpone_weak_password_reminder(this.CurrentUser.id)
                        .then(resp => {
                            this.notifications.success('Postponed');
                        });
                } else if (res === 'open_profile_settings') {
                    document.location.href = '/users/me/settings?at=2';
                }
            }, err => {
            })
            .finally(() => {
                if (vm) {
                    vm.$destroy();
                }
                this.checkIfShowPopupRequired(6);
            });
    }


    showAddAvatarPopup() {
        this.addMedia
            .addAvatarByCrop(
                'Add your profile Pic!',
                'Please add a profile picture so people can easily identify you on Multi Me',
                'Remind me later',
                !this.CurrentUser.is_my_media_empty
            )
            .then(avatarBase64value => {
                this.$rootScope.show_dimmer();
                return this.UserProfileResource.update_avatar_by_crop(this.CurrentUser.id, avatarBase64value)
                    .then(resp => {
                        this.$rootScope.$broadcast('my_avatar_changed', resp.data.avatar);
                        this.notifications.success('Avatar changed');
                    }, err => {
                        this.notifications.error(err || 'Error');
                    });
            }, cancel => {
                return this.UserProfileSettingsResource.postpone_add_avatar()
                    .then(resp => {
                        this.notifications.success('We will remind you on next log in');
                    });
            })
            .finally(() => {
                this.checkIfShowPopupRequired(4);
                this.$rootScope.hide_dimmer();
            });
    }

    showAddNamePopup() {
        const {vm, popupComponent} = this.simplePopupFactory.mount_vue_popup(SetFirstLastNamePopup);
        popupComponent.show(this.CurrentUser)
            .then(({first_name, last_name}) => {
                this.$rootScope.show_dimmer();
                this.CurrentUser.first_name = first_name;
                this.CurrentUser.last_name = last_name;
                return this.UserProfileSettingsResource.update_first_and_last_name(this.CurrentUser.id, {
                    first_name,
                    last_name
                })
                    .then(resp => {
                        this.notifications.success('Updated');
                        this.$rootScope.$broadcast('profile_data_changed');
                    });
            }, cancel => {
                return this.UserProfileSettingsResource.postpone_first_and_last_name_update()
                    .then(resp => {
                        this.notifications.success('We will remind you on next log in');
                    });
            })
            .finally(() => {
                this.checkIfShowPopupRequired(3);
                this.$rootScope.hide_dimmer();
                if (vm) {
                    vm.$destroy();
                }
            });
    }

    showFillProfileStoryRequestPopup() {
        const {vm, popupComponent} = this.simplePopupFactory.mount_vue_popup(AddProfileInfoRequestPopup);
        popupComponent.show(this.CurrentUser)
            .then(yes => {
            }, cancel => {
                return this.UserProfileSettingsResource.postpone_fill_profile_story()
                    .then(resp => {
                        this.notifications.success('We will remind you on next log in');
                    });
            })
            .finally(() => {
                this.checkIfShowPopupRequired(5);
                if (vm) {
                    vm.$destroy();
                }
            });
    }

    signVisibilityChanges() {
        this.UserProfileResource.sign_visibility_changes(this.CurrentUser.id)
            .then(resp => {
                this.CurrentUser.visibility_changes_signed = true;
            });
    }

    showPolicyPopup() {
        const {popupComponent, vm} = simplePopupFactory.mount_vue_popup(GDPRPopup);
        popupComponent.show()
            .then(y => {
                this.acceptPolicy.call(this);
            }, close => {
                this.$timeout(() => {
                    this.showPolicyPopup.call(this);
                }, 1000 * 60 * 5);
                this.checkIfShowPopupRequired(1);
            })
            .finally(() => {
                vm?.$destroy();
            });
    }

    acceptPolicy() {
        this.$rootScope.show_dimmer();
        this.UserProfileResource.sign_policy(this.CurrentUser.id)
            .then(
                resp => {
                    this.CurrentUser.is_policy_signed = true;
                    this.notifications.success('Continue');
                    this.$rootScope.hide_dimmer();
                    let message;
                    if (this.CurrentUser.email) {
                        message = `Thanks, a confirmation email has been sent to you`;
                        return this.simplePopupFactory.show_popup('Thanks!', message, 'OK');
                    }
                },
                err => {
                    this.$rootScope.hide_dimmer();
                    this.notifications.error(err || 'Error');
                    this.$timeout(() => {
                        this.showPolicyPopup.call(this);
                    }, 1000 * 60 * 5);
                }).catch(err => console.error(err))
            .finally(() => {
                this.checkIfShowPopupRequired(1);
            });
    }
}

GDPRPopupService.$inject = ['$rootScope', '$cookies', '$timeout', '$interval', 'CurrentUser', 'notifications', 'simplePopupFactory', 'UserProfileResource', 'UserProfileSettingsResource', 'addMedia'];

export default GDPRPopupService;
